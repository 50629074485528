import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Container } from 'App/Styled'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

const newLegendClickHandler = (e, legendItem, legend) => {
  const index = legendItem.datasetIndex
  const ci = legend.chart

  let meta = ci.getDatasetMeta(index)

  // See controller.isDatasetVisible comment
  meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null

  ci.update()
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      onClick: newLegendClickHandler,
    },
  },
}
const colors = [
  `#780415`,
  `#D41350`,
  `#79D179`,
  `#94D11B`,
  `#261542`,
  `#3E1B96`,
  `#e3d14b`,
]
export const QuotesByStatus = ({ quotesByStatus }) => {
  const [dataSet, setDataSet] = useState({
    labels: [],
    datasets: [
      {
        payment: [],
        backgroundColor: [],
      },
    ],
    borderWidth: 1,
  })
  useEffect(() => {
    const quoteInfo = {
      labels: [`Status`],
      datasets: quotesByStatus?.map((item, index) => {
        return {
          label: item?.quoteStatus,
          data: [item?.noOfQuotes],
          backgroundColor: colors[index],
        }
      }),
    }
    setDataSet(quoteInfo)
  }, [quotesByStatus])

  return (
    <Container>
      <div className="py-9 relative">
        <div className="font-bold absolute left-4 2xl:left-6 top-5">
          Quotes By Status
        </div>
      </div>

      <div className="h-280 m-6">
        {dataSet.labels !== undefined ? (
          <Bar data={dataSet} options={options} />
        ) : null}
      </div>
    </Container>
  )
}
