import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { CardField } from 'App/Components/Common/CardField'
import { Spinner } from 'App/Components/Common/Spinner'
import { loadOffset } from 'App/Services/General/loadOffset'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import dateFormat from 'App/Services/General/dateFormat'

const tableColumns = [
  { label: `First Name`, value: `firstName` },
  { label: `Last Name`, value: `lastName` },
  { label: `Location`, value: `Location` },
  { label: `Type`, value: `type` },
  { label: `Leads`, value: `lead` },
  { label: `Quotes`, value: `quote` },
  { label: `Created`, value: `createdAt` },
  { label: `Updated`, value: `updatedAt` },
]

export const ListCustomers = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { searchQuery } = useSelector(({ globalSearch }) => globalSearch)
  const [isOffset, setIsOffset] = useState(``)
  const [active, setActive] = useState(false)
  const [collapseIndex, setCollapseIndex] = useState(``)

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!active) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }

  const {
    data: customers,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery(
    `globalSearchApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/customers/list-customers?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }"}&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res?.data?.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  useEffect(() => {
    setCurrentPage(1)
    refetch()
  }, [searchQuery])
  useEffect(() => {
    refetch()
  }, [currentPage, isOffset])

  return (
    <Container shadow="yes">
      <Spinner loading={isLoading || isFetching} />
      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (isLoading || isFetching) && `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => refetch()} />
        ) : (
          <>
            <PageHeader bgcolor="bg-white">
              <div className="flex items-center ">
                <div className="py-1 px-2">
                  <ReactSelectField
                    placeholder="10"
                    value={isOffset}
                    setValue={(e) => {
                      setCurrentPage(1)
                      setIsOffset(e)
                    }}
                    isMulti={false}
                    loadOptions={loadOffset}
                  />
                </div>
              </div>
            </PageHeader>
            <div className={`bg-white sm:mb-0 `}>
              <div className="overflow-x-auto  rounded-t-md">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className=" cursor-pointer select-none text-sm flex truncate">
                              <div className={`flex `}>{item.label}</div>
                            </div>
                          </TableCell>
                        )
                      })}
                      <TableCell className="select-none text-sm float-right px-6">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {!customers?.customers?.length && !isLoading ? (
                      <tr>
                        <td colSpan="12" className="text-center p-6">
                          No Customer Found
                        </td>
                      </tr>
                    ) : (
                      customers?.customers?.map((_customer, index) => {
                        return (
                          <>
                            <TableRow className="hover:bg-gray-100">
                              <td className="px-4 py-0.5 capitalize truncate text-sm">
                                {_customer?.firstName ? (
                                  _customer?.firstName?.toLowerCase()
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </td>
                              <td className="px-4 py-0.5 capitalize truncate text-sm">
                                {_customer?.lastName ? (
                                  _customer?.lastName?.toLowerCase()
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </td>
                              <td className="px-4 py-0.5 capitalize truncate text-sm">
                                {_customer?._location?.label ? (
                                  _customer?._location?.label?.toLowerCase()
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </td>

                              <td className="px-4 py-0.5 capitalize text-sm">
                                {_customer?.type ? (
                                  _customer?.type?.toLowerCase()
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </td>

                              <td className="px-4 py-0.5 text-sm">
                                {_customer?._leads ? (
                                  <Badge>{_customer?._leads}</Badge>
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </td>
                              <td className="px-4 py-0.5 w-auto text-sm">
                                {_customer?._quotes ? (
                                  <Badge>{_customer?._quotes}</Badge>
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </td>

                              <td className="px-4 py-0.5 w-auto text-sm truncate">
                                {_customer?.createdAt ? (
                                  dateFormat(_customer?.createdAt)
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </td>
                              <td className="px-4 py-0.5 w-auto text-sm truncate">
                                {_customer?.updatedAt ? (
                                  dateFormat(_customer?.updatedAt)
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </td>
                              <td className="px-7 py-2 z-0 text-sm flex float-right text-gray-400">
                                <span data-tip="Show" className="tooltip">
                                  <FontAwesomeIcon
                                    className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                      collapseIndex === index &&
                                      `rotate-180 text-blue-600`
                                    }`}
                                    icon={faChevronCircleDown}
                                    onClick={(e) => {
                                      setActive(!active)
                                      handleCollapse(index, e)
                                    }}
                                  />
                                </span>
                              </td>
                            </TableRow>
                            {/*Collapsable Row  */}
                            {collapseIndex === index && (
                              <TableRow>
                                {!_customer._policies?.length ? (
                                  <td
                                    colSpan={12}
                                    className="w-full text-center font-bold py-1"
                                  >
                                    <p>No Policy Found</p>
                                  </td>
                                ) : (
                                  <td colSpan={6}>
                                    {_customer._policies?.map((_eachPolicy) => {
                                      return (
                                        <div className="my-2 ml-3">
                                          {_eachPolicy?.policyNo && (
                                            <div className="flex">
                                              <CardField
                                                title="Policy No:    "
                                                value={
                                                  ` ` + _eachPolicy?.policyNo
                                                }
                                              />
                                              <CardField
                                                title="Carrier"
                                                value={
                                                  _eachPolicy?._carrier?.name
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )
                                    })}
                                  </td>
                                )}
                              </TableRow>
                            )}
                          </>
                        )
                      })
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={customers?.totalPages}
                  totalRecords={customers?.totalRecords}
                  curerntPageRecords={customers?.currentPageRecords}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
