import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Container } from 'App/Styled'
import { ReactSelectField } from '../../Common/ReactSelect'

ChartJS.register(ArcElement, Tooltip, Legend)
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
}
export const SalesByAgent = ({ salesByAgent }) => {
  const [dataSet, setDataSet] = useState(``)
  const [totalSales, setTotalSales] = useState(``)
  const [selectAgent, setSelectAgent] = useState([])

  useEffect(() => {
    if (salesByAgent?.length) {
      let labels = selectAgent.length
        ? selectAgent.map((item) => item.label)
        : salesByAgent.map((item) => item.agentName)
      let values = selectAgent.length
        ? selectAgent.map((item) => item.sales)
        : salesByAgent.map((item) => item.agentSales)

      let totalSales = values.reduce(
        (partialSum, sales) => partialSum + sales,
        0,
      )
      let backgroundColors = salesByAgent.map(() => {
        let randomColor = Math.floor(Math.random() * 16777215).toString(16)
        return `#` + randomColor
      })
      if (labels.length && values.length && backgroundColors.length)
        setDataSet({
          labels: labels,
          datasets: [
            {
              label: `# of Votes`,
              data: values,
              backgroundColor: backgroundColors,
              borderWidth: 1,
            },
          ],
        })
      setTotalSales(totalSales)
    }
  }, [salesByAgent, selectAgent])

  const option = salesByAgent?.map((item) => {
    return {
      value: item.agentName,
      sales: item.agentSales,
      label: item.agentName,
    }
  })
  const loadAgent = (search) => {
    const options = {
      options: search ? filterAgent(search) : option,
      hasMore: false,
    }
    return options
  }

  const filterAgent = (search) => {
    return (
      option
        //some labels are null that is why we used double filters
        .filter((item) => item.label !== null)
        .filter((item) => {
          return item.label.toLowerCase().includes(search.toLowerCase())
        })
    )
  }
  const currencyConvert = (number) => {
    return new Intl.NumberFormat(`en-US`, {
      style: `currency`,
      currency: `USD`,
      notation: `compact`,
    }).format(number)
  }

  return (
    <Container className="relative">
      <div className="absolute font-bold text-md top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
        <div className="mt-16">{`${
          totalSales > 0 ? currencyConvert(totalSales) : `No Data`
        }`}</div>
      </div>
      <div className="flex py-9 h-1/4 relative ">
        <div className="font-bold absolute left-4 2xl:left-6 top-5">
          <span className="font-bold text-md">Agent's Sales</span>
        </div>

        <div className="w-1/2 absolute right-4 top-4 z-0 ">
          <ReactSelectField
            placeholder="Select Agent"
            value={selectAgent}
            setValue={(e) => {
              setSelectAgent(e)
            }}
            isMulti={true}
            loadOptions={loadAgent}
            isSearchable={true}
            isScrollable={true}
          />
        </div>
      </div>
      {/* <div className="h-0.5" /> */}

      <div className="text-center flex justify-center h-3/4">
        <div className="w-300 h-310">
          {dataSet && (
            <Doughnut
              data={dataSet}
              options={options}
              width={300}
              height={200}
            />
          )}
        </div>
      </div>
    </Container>
  )
}
