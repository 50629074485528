import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TOne } from 'App/Styled'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { setPaymentActiveActivityTab } from 'App/Redux/actions'
import { PaymentNote } from 'App/Components/Payment/PaymentNote'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
]

export const PaymentActivity = () => {
  const { activeActivityTab } = useSelector(({ payment }) => payment)
  const dispatch = useDispatch()
  return (
    <div className="px-2">
      <Tabs2
        openTab={activeActivityTab}
        tabs={activityTabs}
        getActiveTab={(val) => dispatch(setPaymentActiveActivityTab(val))}
      />
      <div className="tab-content tab-space">
        <TOne opentab={activeActivityTab}>
          <PaymentNote />
        </TOne>
      </div>
    </div>
  )
}
