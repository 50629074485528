/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import { ReactModal } from 'App/Components/Common/Modal'
import { DocumentsPreview } from 'App/Components/Policies/PolicyPreview/Tabs/VerifyDocuments/VerifyDocumentsPreview'
import { useMutation } from 'react-query'
import { SubmitButton } from 'App/Styled'
import { fileToBase64 } from 'App/Services'
import { AQS_BACKEND_API, AxiosInstance } from 'App/Config'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PolicyContext } from '../..'
import { NotificationManager } from 'react-notifications'

export const VerifyDocuments = () => {
  const { isLoading, isFetching, refetch, error } = useContext(PolicyContext)
  const [previewModel, setPreviewModel] = useState(false)
  // const [documentsResponse, setDocumentsResponse] = useState(``)
  const [docsAnalyze, setDocsAnalyze] = useState({
    docs: ``,
    carrier: ``,
    fileName: ``,
  })
  const [errors, setErrors] = useState({
    formSubmit: false,
    docsError: ``,
    carrierError: ``,
  })

  const { currentPolicy } = useSelector(({ policy }) => policy)

  const { customerId } = useParams()

  const {
    isError,
    isLoading: loadingDocs,
    mutate,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AQS_BACKEND_API}/analyze/docs`, {
        docs: payload.docs,
        carrier: `alliance-united`,
        path: `${currentPolicy?._customer?.value}/Policies/${currentPolicy?.policyNo}`,
        fileName: payload.fileName,
      }).then((res) => res.data.data),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        refetch()
        // setDocumentsResponse(DocsVerificationReport)
        setDocsAnalyze({
          docs: ``,
          carrier: ``,
          fileName: ``,
        })
        setPreviewModel(false)
      },
      onError: (error) => {
        if (error.response.status == 413) {
          NotificationManager.error(`File size is too large`)
        } else if (
          error?.response?.data?.error?.error.includes(
            `'docs' failed custom validation because it is not 'zip' file`,
          )
        ) {
          NotificationManager.error(`Document should be in a ZIP file format`)
        } else {
          NotificationManager.error(error.response?.data.message)
        }
      },
    },
  )
  useEffect(() => {
    if (isError) {
      setPreviewModel(true)
    }
  }, [isError])

  const handleFileChange = (file) => {
    fileToBase64(file, (err, base64) => {
      if (base64) {
        setDocsAnalyze((preState) => ({
          ...preState,
          [`docs`]: base64,
        }))
        setDocsAnalyze((preState) => ({
          ...preState,
          [`fileName`]: file.name,
        }))
      }
    })
  }

  const loadCarrier = async () => {
    return {
      options: [
        {
          value: currentPolicy?._carrier?.value,
          label: currentPolicy?._carrier?.label,
        },
      ],
      hasMore: false,
    }
  }
  const handleError = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const validateForm = () => {
    if (!docsAnalyze?.carrier?.value?.length) {
      handleError(`carrierError`, `Select Carrier`)
    } else {
      handleError(`carrierError`, ``)
    }
    if (!docsAnalyze?.docs) {
      handleError(`docsError`, `Select File`)
    } else {
      handleError(`docsError`, ``)
    }

    handleError(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors?.docsError?.length &&
      !errors?.carrierError?.length
    ) {
      mutate(docsAnalyze)
      setPreviewModel(false)
      handleError(`formSubmit`, false)
    }
  }, [errors])

  return (
    <>
      {previewModel ? (
        <ReactModal
          title="Analyze Documents"
          closeButton={false}
          closeModal={() => setPreviewModel(false)}
        >
          <div className="flex flex-col">
            <div className="flex-1 relative p-4">
              <div className="relative">
                <ReactSelectField
                  title="Carrier"
                  placeholder="Select Carrier"
                  errorMessage={errors.carrierError}
                  value={docsAnalyze.carrier}
                  setValue={(value) => {
                    setDocsAnalyze((preState) => ({
                      ...preState,
                      [`carrier`]: value,
                    }))
                  }}
                  isMulti={false}
                  loadOptions={loadCarrier}
                  isSearchable={true}
                />
              </div>
              <div className="cursor-pointer relative ">
                <input
                  type="file"
                  accept=".zip"
                  className="cursor-pointer relative block opacity-0 w-full h-full p-6 z-50"
                  onChange={(e) => {
                    handleFileChange(e.target.files[0])
                    e.target.value = null
                  }}
                />
                <FontAwesomeIcon
                  icon={faCopy}
                  size="2x"
                  className={`text-center ${
                    docsAnalyze.docs
                      ? `text-green-500`
                      : errors?.docsError?.length
                      ? `text-red-500`
                      : `text-gray-600`
                  } p-10 absolute top-0 right-0 left-0 m-auto`}
                />
                <p className="text-center">
                  {docsAnalyze.docs ? `File is Selected` : `Upload File`}
                </p>
              </div>
            </div>
            <div className="text-center pb-2 px-5">
              <SubmitButton
                onClick={() => {
                  validateForm()
                }}
              >
                Verify
              </SubmitButton>
            </div>
          </div>
        </ReactModal>
      ) : (
        <DocumentsPreview
          setPreviewModal={(e) => setPreviewModel(e)}
          isLoading={isLoading || loadingDocs || isFetching}
        />
      )}
    </>
  )
}
