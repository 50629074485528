import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadZipCodes = async (
  search,
  loadedOptions,
  { page, operatingState },
) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AMS_BACKEND_API}/api/get/zipCodes/?search=${
      search && search
    }&page=${page}&offset=10${
      operatingState ? `&state=${operatingState}` : ``
    }`,
  )

  if (data?.zipCodes) {
    const formattedZipCodes = data?.zipCodes.map((zip) => {
      return {
        value: zip,
        label: zip,
      }
    })
    return {
      options: formattedZipCodes,
      hasMore: data.totalPages - page > 0,
      additional: {
        operatingState,
        page: page + 1,
      },
    }
  }
}
