import React from 'react'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { useRouteMatch } from 'react-router'
import { CardField } from 'App/Components/Common/CardField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Container, PageHeader, EditStyledButton } from 'App/Styled'
import { AxiosInstance, AMS_BACKEND_API } from 'App/Config'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrentGroupCarrier } from 'App/Redux/actions'

export const AboutGroupCarrier = () => {
  const {
    url,
    params: { groupCarrierId },
  } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    data: groupCarrierData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `GroupCarrierPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/carriers-groups/${groupCarrierId}`,
      ).then((res) => res.data?.data),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentGroupCarrier(res))
      },
    },
  )
  return (
    <Container>
      <PageHeader>
        <div>Group Carrier</div>
        {!isLoading && (
          <EditStyledButton onClick={() => history.push(`${url}/update`)}>
            <span>Edit</span>
            <FontAwesomeIcon
              icon={faPen}
              className="text-white mt-0.5"
              size="sm"
            />
          </EditStyledButton>
        )}
      </PageHeader>
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <>
          <div className="grid grid-cols-12 px-4 py-2">
            <div className="col-span-6 text-xl">Info</div>
          </div>
          <hr />
          <div className="flex py-2 px-4">
            <div className="flex-1">
              <CardField
                title="Group Id"
                value={groupCarrierData?.group?._groupId}
              />
              <CardField
                title="Name"
                value={groupCarrierData?.group?.name}
              />
              <CardField
                title="State"
                value={`${groupCarrierData?.group?.state}`}
              />
            </div>


          </div>
          <div className="flex py-2 px-4">
            <div className="flex-1">

              <CardField
                title="Creater Name"
                value={groupCarrierData?.group?._creator?.label}
              />
              <CardField
                title="Carriers"
                value={groupCarrierData?.group?.carriers?.map(carrier => carrier.label)}
                multiple={true}
              />
            </div>
          </div>
        </>
      )}
    </Container>
  )
}
