/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import { useDebouncedCallback } from 'use-debounce/lib'
// import { PdfTron } from 'App/Services/PdfTron'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { Reload } from 'App/Components/Common/Reload'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { PdfModal } from 'App/Components/Common/PdfTronModal'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useSelector } from 'react-redux'
import { permissionGranted } from 'App/Services'
import { TableHeader, TableBody, TableRow, TableCell } from '@windmill/react-ui'
import {
  faSortDown,
  faSortUp,
  faFilePdf,
  faTrash,
  faEdit,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'

import { useParams } from 'react-router-dom'
import { PdfFieldsMapModal } from 'App/Components/Common/PdfFieldsMapModal'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import dateFormat from 'App/Services/General/dateFormat'
const tableColumns = [
  { label: `Name`, value: `filename` },
  { label: `Type`, value: `type` },
  { label: `Created At`, value: `createdAt` },
  { label: `Uploaded At`, value: `uploadedAt` },
]

export const MyFiles = () => {
  // const { instance } = useContext(WebViewerContext)
  const { currentPolicy } = useSelector(({ policy }) => policy)
  const { customerId } = useParams()

  const fileref = useRef(null)

  const [instance, setInstance] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [popUp, setPopUp] = useState(false)
  const [modalValue, setModalValue] = useState(``)
  const [anotationList, setanotationList] = useState([])
  const [isDeletingAnot] = useState(false)
  const [isUpdatingFile, setIsUpdatingFile] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedAnnotation, setSelectedAnnotation] = useState(false)
  const [isModifyingAnot, setIsModifyingAnot] = useState(``)
  const [fileLoaded, setFileLoaded] = useState(false)
  const [hasErrors, sethasErrors] = useState(false)
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)
  const [customSearching, setCustomSearching] = useState(false)
  const [flattenedObj, setFlattenedObj] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)

  const { profile } = useSelector(({ user: { user } }) => user)

  const handleClosePopUp = () => {
    setPopUp(false)
  }

  const closeFile = () => {
    setFileLoaded(false)
    setShowModal(false)
    setPopUp(false)
  }

  useEffect(() => {
    if (instance) {
      const { documentViewer, annotationManager } = instance.Core
      annotationManager.setReadOnly(`true`)
      documentViewer.addEventListener(`documentLoaded`, async () => {
        setFileLoaded(true)
      })
    }
  }, [instance])

  useEffect(() => {
    if (instance && selectedFile !== `` && selectedFile != null) {
      setShowModal(true)
      instance.UI.loadDocument(selectedFile)
    }
  }, [instance, selectedFile])

  const blobToBase64 = (blob) => {
    return new Promise((resolve) => {
      const fileReader = new FileReader()

      fileReader.readAsDataURL(blob)
      fileReader.readAsArrayBuffer
      fileReader.onloadend = function () {
        resolve(fileReader.result)
      }
    })
  }
  const handleSaveFile = async () => {
    if (instance && instance.Core) {
      const doc = instance.Core.documentViewer.getDocument()
      const xfdfString =
        await instance.Core.annotationManager.exportAnnotations()
      const options = {
        xfdfString,
        downloadType: `pdf`,
      }
      const data = await doc.getFileData(options)
      const array = new Uint8Array(data)
      let blob = new Blob([array], { type: `application/pdf` })
      const file = new File([blob], selectedFile?.name, { type: blob.type })

      const base64File = await blobToBase64(blob)
      base64File &&
        updateFilesMutation({
          file: base64File,
          path: `path=${customerId}/Policies/${currentPolicy?.policyNo}/files`,
          fileName: selectedFile?.name
            ? selectedFile?.name?.split(`.`)[0]
            : isUpdatingFile?.filename,
        })
      setIsUpdatingFile(null)
    }
  }

  const {
    mutate: fileUploadMutation,
    isLoading: fileUploading,
    isError: fileUploadError,
    error: fileUploadErrorMessage,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/cloudinary/upload`, {
        ...payload,
      }).then((res) => {
        if (res.hasError) {
          sethasErrors(true)
          setShowModal(false)
        } else {
          getAllFiles()
        }
      }),
    {
      enabled: false,
    },
  )

  const {
    refetch: getAllFiles,
    data: allFiles,
    isLoading: gettingFiles,
    isError: gettingFilesError,
    isFetching: templatesFetching,
  } = useQuery(
    `allFileApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/cloudinary/folder?path=${customerId}/Policies/${
          currentPolicy?.policyNo
        }/files&search={"query": "${
          searchQuery.length ? searchQuery : ``
        }"}&sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      )
        .then((res) => {
          if (!res.hasError) {
            setShowModal(false)
            setCustomSearching(false)
            return res.data.response
          }
        })
        .catch((err) => {
          console.log(`File Error`, err)
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  )

  const {
    mutate: deleteFilesMutation,
    isLoading: deletingFiles,
    isError: deletionError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/cloudinary/delete`, {
        ...payload,
      })
        .then((res) => {
          getAllFiles()
        })
        .catch(() => {
          sethasErrors(true)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const { mutate: updateFilesMutation, isLoading: updatingFiles } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/cloudinary/update?path=${
          profile._franchise?._id
        }/Files&search=${searchQuery.length ? searchQuery : ``}`,
        {
          ...payload,
        },
      )
        .then(() => {
          getAllFiles()
        })
        .catch(() => {
          sethasErrors(true)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllFiles()
    }
  }, [sort, currentPage, isOffset])

  const callTemplateQuery = useDebouncedCallback(() => {
    getAllFiles()
  }, 700)

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  return (
    <div>
      <PageHeader>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              setValue={(e) => {
                setSearchQuery(e.target.value)
                setCurrentPage(1)
                setCustomSearching(true)
                callTemplateQuery()
              }}
              disabled={gettingFiles}
            />
          </div>
        </div>
      </PageHeader>
      {(deletionError || fileUploadError || hasErrors) && (
        <ReactNotification
          action="error"
          message={fileUploadErrorMessage?.response?.data?.error?.error}
        />
      )}
      <input
        type="file"
        name="file"
        ref={fileref}
        className="hidden"
        onChange={(e) => {
          setSelectedFile(e.target.files[0])
          setShowModal(true)
          e.target.value = null
        }}
        accept="application/pdf"
      />

      <div className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2`}>
        {deletingFiles ||
        fileUploading ||
        updatingFiles ||
        gettingFiles ||
        templatesFetching ? (
          <div className="text-center py-10">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : gettingFilesError ? (
          <Reload refetch={() => getAllFiles()} />
        ) : (
          <>
            <div className={`overflow-x-auto rounded-t-md`}>
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div
                            className={`${
                              sort.activeSort === item.label
                                ? `text-red-500`
                                : ``
                            } cursor-pointer select-none text-sm flex truncate`}
                          >
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(permissionGranted([
                      `pdf-templates:update`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) ||
                      permissionGranted([
                        `pdf-templates:delete`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ])) && (
                      <TableCell className="text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!gettingFiles && !allFiles?.docs?.length ? (
                    <tr>
                      <td colSpan="12">
                        <div className="w-full text-center p-6  ">
                          <p>No File Found</p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    allFiles?.docs?.map((fileObj, index) => (
                      <TableRow
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={(e) => {
                          if (
                            permissionGranted([
                              `pdf-templates:update`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])
                          ) {
                            e.stopPropagation()
                            setIsUpdatingFile({
                              path: fileObj.folder,
                              filename: fileObj.filename,
                            })
                            setShowModal(true)
                            setSelectedFile(fileObj?.secure_url)
                            setIsReadOnly(false)
                          }
                        }}
                      >
                        <td className="px-4 py-0.5 text-sm capitalize truncate">
                          {fileObj?.filename?.toLowerCase()}
                        </td>
                        <td className="px-4 py-0.5 text-sm">
                          {fileObj?.format.toString() === `pdf` ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                className="text-red-500"
                                size="lg"
                              />
                            </span>
                          ) : (
                            ``
                          )}
                        </td>

                        <td className="px-4 py-0.5 text-sm truncate">
                          {dateFormat(fileObj.createdAt)}
                        </td>
                        <td className="px-4 py-0.5 text-sm truncate">
                          {dateFormat(fileObj.uploaded_at)}
                        </td>

                        {/* Actions TD */}
                        <td className="px-6 py-0.5 text-sm z-0 flex float-right text-gray-400">
                          {/* {permissionGranted([
                              `pdf-templates:delete`,
                              `admin-franchise`,
                              `admin-master-franchise`,
                            ]) && (
                                <span data-tip="Delete" className="tooltip">
                                  <FontAwesomeIcon
                                    className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                    icon={faTrash}
                                    size="1x"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      deleteFilesMutation({
                                        path: fileObj.folder,
                                        files: [fileObj.public_id],
                                      })
                                    }
                                    }
                                  />
                                </span>
                              )} */}
                        </td>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={allFiles ? allFiles?.totalPages : 0}
                totalRecords={allFiles ? allFiles?.totalRecords : 0}
                curerntPageRecords={allFiles ? allFiles?.currentPageRecords : 0}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>

      <Container>
        <div>
          <PdfModal
            showModal={showModal}
            setShowModal={closeFile}
            saveFile={handleSaveFile}
            isFileLoaded={fileLoaded}
            isReadOnly={isReadOnly}
            updatingApi={gettingFiles || fileUploading || updatingFiles}
            disableSaveButton={true}
          >
            {popUp && (
              <PdfFieldsMapModal
                modalStatus={popUp}
                setModalValue={setModalValue}
                handleClose={handleClosePopUp}
                isDeleting={isDeletingAnot}
              />
            )}
            {/* <PdfTron handleInstance={setInstance} /> */}
          </PdfModal>
        </div>
      </Container>
    </div>
  )
}
