import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentRole: ``,
  activeActivityTab: 1,
}

const systemRoleSlice = createSlice({
  name: `systemRole`,

  initialState,
  reducers: {
    setCurrentRole: (state, { payload }) => {
      state.currentRole = payload
    },
    setRoleActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
  },
})

export const { setCurrentRole, setRoleActivityActiveTab } =
  systemRoleSlice.actions

export const systemRole = systemRoleSlice.reducer
