import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import { TableHeader, TableBody, TableRow, TableCell } from '@windmill/react-ui'
import { loadOffset } from 'App/Services/General/loadOffset'
import {
  faPlusCircle,
  faSortUp,
  faSortDown,
  // faEye,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { isSuperUser, loadStates, permissionGranted } from 'App/Services'
import { ReactModal } from 'App/Components/Common/Modal'
import { useSelector } from 'react-redux'
// import { setCurrentRatingTemplate } from 'App/Redux/actions'
import { RatingTemplate } from '../RatingTemplate'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { useDebouncedCallback } from 'use-debounce/lib'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { NotificationManager } from 'react-notifications'
import searchQueryParser from 'App/Services/General/searchQueryParser'

const tableColumns = [
  { label: `State`, value: `rateState` },
  { label: `Template Name`, value: `name` },
  { label: `Description`, value: `description` },
]

export const AllRatingTemplates = () => {
  const user = useSelector(({ user: { user } }) => user)
  const state = {
    value: user?.profile?.operatingState,
    label: user?.profile?.operatingState,
  }
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)
  const [stateFilter, setStateFilter] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [preview, setPreview] = useState(false)
  const [currentRatingTemplate, setCurrentRatingTemplate] = useState(``)

  const [sort, setSort] = useState(``)
  const history = useHistory()
  const { url } = useRouteMatch()
  // const dispatch = useDispatch()

  // fetching all templates

  const {
    isLoading,
    data: ratingTemplateData,
    refetch: getAllRatingTemplates,
    isFetching,
    isError,
    stateValue = isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)
      ? stateFilter?.value
      : state.value,
  } = useQuery(
    `MyRatingTemplateApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/rating-templates/all?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }state="${stateValue ? stateValue : ``}"&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res?.data?.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  // delete franchise
  const {
    mutate: deleteTemplate,
    isLoading: deletingTemplate,
    isError: deletionError,
    error: reponseError,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/rating-templates/${id}`,
      ).then((res) => {
        res.data && getAllRatingTemplates()
        NotificationManager.success(`Successfully Deleted`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllRatingTemplates()
    }
  }, [sort, currentPage, isOffset])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  // const previewTemplate = (id) => {
  //   const template = ratingTemplateData.ratingTemplates.find(
  //     (template) => template._id == id,
  //   )
  //   dispatch(setCurrentRatingTemplate(template))
  //   setPreview(true)
  // }

  const callTemplatesQuery = useDebouncedCallback(() => {
    getAllRatingTemplates()
  }, 700)

  const handleStateChange = useDebouncedCallback(() => {
    getAllRatingTemplates()
  }, 200)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteTemplate(id)
  }

  return (
    <Container>
      {(isError || deletionError || reponseError) && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading || isFetching || deletingTemplate} />
      {preview && (
        <ReactModal
          title="Rating Template"
          closeModal={() => setPreview(false)}
        >
          <RatingTemplate currentRatingTemplate={currentRatingTemplate} />
        </ReactModal>
      )}

      <PageHeader>
        <div>Rating Templates</div>
        <div className="flex items-center">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callTemplatesQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div className="flex justify-between items-center p-2">
        {!(isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)) ? (
          <div className="w-52">
            <ReactSelectField
              placeholder="Select State"
              value={state}
              // setValue={(val) => {
              //   setStateFilter(val)
              //   setCurrentPage(1)
              //   setCustomSearching(true)
              //   handleStateChange()
              // }}
              disabled={true}
            />
          </div>
        ) : (
          <div className="w-52">
            <ReactSelectField
              placeholder="Select State"
              value={stateFilter}
              setValue={(val) => {
                setStateFilter(val)
                setCurrentPage(1)
                setCustomSearching(true)
                handleStateChange()
              }}
              loadOptions={loadStates}
            />
          </div>
        )}

        {permissionGranted([
          `rating-templates:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      {isError ? (
        <Reload refetch={() => getAllRatingTemplates()} />
      ) : (
        <div
          className={`mb-1 sm:mb-0 justify-between w-full px-2 ${
            (isLoading || isFetching || deletingTemplate) &&
            ` opacity-30 pointer-events-none`
          } `}
        >
          <div className="overflow-x-auto  rounded-t-md">
            <table className="w-full border-collapse bg-gray-50">
              <TableHeader>
                <TableRow>
                  {tableColumns.map((item, idx) => {
                    return (
                      <TableCell key={idx}>
                        <div className="cursor-pointer select-none text-sm flex truncate">
                          <div
                            onClick={() => {
                              handleSorting(item.value)
                            }}
                            className={`flex ${
                              item.value === sort ? `text-red-500` : ``
                            }`}
                          >
                            {item.label}
                          </div>
                          <div
                            className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                            onClick={() => handleSorting(`-` + item.value)}
                          >
                            <FontAwesomeIcon
                              icon={
                                sort.includes(`-`) && sort === `-` + item.value
                                  ? faSortUp
                                  : faSortDown
                              }
                              size="lg"
                              className={`${
                                sort.includes(`-`) && sort === `-` + item.value
                                  ? `text-red-500`
                                  : `-mt-1.5`
                              }`}
                            />
                          </div>
                        </div>
                      </TableCell>
                    )
                  })}
                  {permissionGranted([
                    `rating-templates:delete`,
                    `corporate-manager:*`,
                    `manager:*`,
                    `admin:*`,
                  ]) && (
                    <TableCell className="text-sm float-right">
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                {ratingTemplateData?.ratingTemplates?.length != 0 ? (
                  ratingTemplateData?.ratingTemplates?.map((temp, index) => (
                    <TableRow
                      key={index.toString()}
                      className="hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        setPreview(true)
                        setCurrentRatingTemplate(temp?._id)
                      }}
                    >
                      <td className="px-4 py-0.5 text-sm z-0 break-words max-w-px-140 cursor-pointer font-semibold font-mono hover:text-gray-500">
                        {temp?.rateState}
                      </td>
                      <td className="px-4 py-0.5 text-sm z-0 break-words max-w-px-140 whitespace-nowrap">
                        {temp?.name}
                      </td>
                      <td className="px-4 py-0.5 text-sm z-0 truncate max-w-xs">
                        {temp?.description}
                      </td>
                      {/* Actions TD */}
                      <td className="px-6 py-0.5 z-0 text-sm flex float-right text-gray-400">
                        {/* <span data-tip="View" className="tooltip">
                          <FontAwesomeIcon
                            className=" mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer "
                            icon={faEye}
                          />
                        </span> */}
                        {permissionGranted([
                          `rating-templates:delete`,
                          `corporate-manager:*`,
                          `manager:*`,
                          `admin:*`,
                        ]) && (
                          <span data-tip="Delete" className="tooltip">
                            <FontAwesomeIcon
                              className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                              icon={faTrash}
                              size="1x"
                              onClick={(e) => handleDelete(temp._id, e)}
                            />
                          </span>
                        )}
                      </td>
                    </TableRow>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center p-6">
                      <div>No Template Found</div>
                    </td>
                  </tr>
                )}
              </TableBody>
            </table>
          </div>
          <div className="px-4 py-2">
            <ReactPagination
              offset={isOffset?.value ? isOffset?.value : offset}
              currentPage={currentPage}
              totalPages={ratingTemplateData?.totalPages}
              totalRecords={ratingTemplateData?.totalRecords}
              curerntPageRecords={ratingTemplateData?.currentPageRecords}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      )}
    </Container>
  )
}
