/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { MaskField } from 'App/Components/Common/MaskField'
import { InputField } from 'App/Components/Common/InputField'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import {
  loadFranchises,
  ValidateZip,
  ValidateDuplicateLocation,
  ValidateDuplicateLocEmail,
  permissionGranted,
  isSuperUser,
  loadStates,
  loadZipCodes,
  fileResizer,
} from 'App/Services'
import { useHistory, useRouteMatch } from 'react-router'
import {
  Container,
  Image,
  Label,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useDebouncedCallback } from 'use-debounce/lib'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import { loadGroupCredentials } from 'App/Services/Credentials'
import { defaultImage } from 'App/Assets'

export const AddLocation = () => {
  const [franchise, setFranchise] = useState(``)
  const [location, setLocation] = useState({
    img: ``,
    name: ``,
    phone: ``,
    ext: ``,
    email: ``,
    fax: ``,
    lobs: ``,
    workPhone: ``,
    credentialGroup: ``,
    businessName: ``,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    streetNo: ``,
    city: ``,
    zip: [],
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    franchiseError: ``,
    nameError: ``,
    phoneError: ``,
    stateForPhoneError: ``,
    extError: ``,
    emailError: ``,
    faxError: ``,
    workPhoneError: ``,
    lobsError: ``,
    credentialGroupError: ``,
    streetNoError: ``,
    cityError: ``,
    zipError: ``,
    stateError: ``,
    coordinatesError: false,
    businessNameError: ``,
  })

  // const [franchisePhone, setFranchisePhone] = useState(``)
  const [stateForPhone, setStateForPhone] = useState(``)
  const [locNameValidating, setLocNameValidating] = useState(false)
  const [businessNameValidating, setBusinessNameValidating] = useState(false)
  const [locEmailValidating, setLocEmailValidating] = useState(false)
  const [locZipValidating, setLocZipValidating] = useState(false)
  const [firstZip, setFirstZip] = useState(``)

  const history = useHistory()
  const {
    url,
    params: { fId },
  } = useRouteMatch()
  const user = useSelector(({ user: { user } }) => user)

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/locations`, {
        ...payload,
      }).then((res) => {
        if (res.data) {
          if (url.includes(`franchises`)) {
            NotificationManager.success(`Created Successfully`)
            history.push(`/admin/franchises/${fId}`)
          } else {
            NotificationManager.success(`Created Successfully`)
            history.push(`/admin/locations`)
          }
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const loadAvailableNumbers = async (search, loadedOptions, { page }) => {
    try {
      const {
        data: { data },
      } = await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/get/phone-numbers/?search=${
          search && search
        }&state=${stateForPhone && stateForPhone?.value}`,
      )
      if (data) {
        const payload = data?.result?.map((number) => ({
          value: number.phoneNumber,
          label: number.phoneNumber,
        }))
        return {
          options: payload,
        }
      }
    } catch (error) {
      if (error?.response?.data?.hasError) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        }
      }
    }
  }

  const handleLocationInput = (name, value) => {
    setLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleEmailInput = (e) => {
    const { name, value } = e.target
    setLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (value) {
      validateLocationEmail(value)
    }
  }

  const validateLocationEmail = useDebouncedCallback(async (value) => {
    setLocEmailValidating(true)

    const response = await ValidateDuplicateLocation({ email: value })

    if (response?.hasError) {
      handleErrors(`formSubmit`, false)

      handleErrors(`emailError`, `Email Already Exists`)
      setLocEmailValidating(false)
    } else {
      if (!regEx.test(location.email.toLowerCase())) {
        handleErrors(`emailError`, `Enter Valid Email`)
      } else {
        handleErrors(`emailError`, ``)
      }
      handleErrors(`formSubmit`, false)
      setLocEmailValidating(false)
    }
  }, 2500)

  const handleNameInput = (e) => {
    const { name, value } = e.target
    setLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (value) {
      validateLocName(value)
    }
  }

  const validateLocName = useDebouncedCallback(async (value) => {
    setLocNameValidating(true)
    const response = await ValidateDuplicateLocation({ name: value })
    if (response?.hasError) {
      setLocNameValidating(false)
      handleErrors(`formSubmit`, false)
      handleErrors(`nameError`, `Name already exists`)
    } else {
      setLocNameValidating(false)
      handleErrors(`formSubmit`, false)
      handleErrors(`nameError`, ``)
    }
  }, 2500)

  const handleBusinessNameInput = (e) => {
    const { name, value } = e.target
    setLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (value) {
      validateBusinessName(value)
    }
  }

  const validateBusinessName = useDebouncedCallback(async (value) => {
    setBusinessNameValidating(true)
    const response = await ValidateDuplicateLocation({ businessName: value })
    if (response?.hasError) {
      setBusinessNameValidating(false)
      handleErrors(`formSubmit`, false)
      handleErrors(`businessNameError`, `Name already exists`)
    } else {
      setBusinessNameValidating(false)
      handleErrors(`formSubmit`, false)
      handleErrors(`businessNameError`, ``)
    }
  }, 2500)

  const handleAddressInput = async (e, name) => {
    if (name) {
      setLocZipValidating(true)
      const data = await ValidateZip(e)

      if (data) {
        setLocZipValidating(false)
        const res = data.address
        setAddress((prevState) => ({
          ...prevState,
          [`city`]: firstZip ? res.city : ``,
          [`state`]: firstZip ? res.state : ``,
        }))
        // setAddress((prevState) => ({
        //   ...prevState,
        //   [name]: e,
        // }))

        //setting coordinates
        setCoordinates((prevState) => ({
          ...prevState,
          [`lat`]: res.lat,
          [`lng`]: res.long,
        }))
      }
    } else {
      const { name, value } = e.target
      setAddress((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const preparedPayload = () => {
    const formattedLobs = location?.lobs?.map((lob) => {
      return lob.value
    })
    let data = {
      _franchise: fId ? fId : franchise.value,
      name: location?.name,
      phone: location?.phone?.value,
      ext: location?.ext,
      email: location?.email,
      fax: location?.fax,
      workPhone: location?.workPhone,
      _credentialGroup: location?.credentialGroup?.value,
      businessName: location.businessName,
      lobs: formattedLobs,
      address: {
        street: address?.streetNo,
        city: address?.city,
        zip: address?.zip?.map((zip) => zip?.value),
        state: address?.state,
        coordinates: {
          lat: coordinates.lat.toString(),
          long: coordinates.lng.toString(),
        },
      },
    }
    if (address.unitNo?.length) {
      data.address.unitNo = address?.unitNo
    }
    if (location?.img?.length) {
      data.image = location?.img
    }

    return data
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.franchiseError.length &&
      !errors.nameError.length &&
      !errors.businessNameError.length &&
      !errors.phoneError.length &&
      !errors.stateForPhoneError.length &&
      !errors.extError.length &&
      !errors.emailError.length &&
      !errors.faxError.length &&
      !errors.workPhoneError.length &&
      !errors.lobsError.length &&
      !errors.credentialGroupError.length &&
      !errors.zipError.length &&
      !errors.streetNoError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.coordinatesError
    ) {
      mutate(preparedPayload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const ValidateForm = () => {
    if (!url.includes(`franchises`)) {
      if (!franchise?.value?.length) {
        handleErrors(`franchiseError`, `Select Franchise`)
      } else {
        handleErrors(`franchiseError`, ``)
      }
    }

    if (!location.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else if (location.name.length && errors.nameError.length) {
      return
    } else {
      handleErrors(`nameError`, ``)
    }
    if (!location.businessName.length) {
      handleErrors(`businessNameError`, `Enter Name`)
    } else if (
      location.businessName.length &&
      errors.businessNameError.length
    ) {
      return
    } else {
      handleErrors(`businessNameError`, ``)
    }

    if (!location?.phone?.value?.length) {
      handleErrors(`phoneError`, `Select Phone No`)
    } else {
      handleErrors(`phoneError`, ``)
    }
    if (!stateForPhone?.value?.length) {
      handleErrors(`stateForPhoneError`, `Select State`)
    } else {
      handleErrors(`stateForPhoneError`, ``)
    }

    if (!location?.ext?.length) {
      handleErrors(`extError`, `Enter Extension`)
    } else if (location?.ext?.length) {
      if (isNaN(location?.ext)) {
        handleErrors(`extError`, `Ext Should be a number`)
      } else if (location?.ext?.length < 4) {
        handleErrors(`extError`, `Ext length should be less than 4`)
      } else {
        handleErrors(`extError`, ``)
      }
    } else {
      handleErrors(`extError`, ``)
    }

    if (!location?.email?.length) {
      handleErrors(`emailError`, `Enter Email`)
    } else if (location?.email?.length) {
      if (
        errors?.emailError?.length &&
        errors?.emailError.includes(`Email Already Exists`)
      ) {
        handleErrors(`emailError`, `Email Already Exists`)
      } else {
        if (!regEx.test(location.email.toLowerCase())) {
          handleErrors(`emailError`, `Enter Valid Email`)
        } else {
          handleErrors(`emailError`, ``)
        }
      }
    } else {
      handleErrors(`emailError`, ``)
    }

    if (!location.fax.length) {
      handleErrors(`faxError`, `Enter Fax`)
    } else if (location.fax.length && location.fax.length < 12) {
      handleErrors(`faxError`, `Enter Valid Fax`)
    } else {
      handleErrors(`faxError`, ``)
    }

    if (!location.workPhone.length) {
      handleErrors(`workPhoneError`, `Enter Phone`)
    } else if (
      location?.workPhone?.length &&
      location?.workPhone?.length < 12
    ) {
      handleErrors(`workPhoneError`, `Enter Valid Phone`)
    } else {
      handleErrors(`workPhoneError`, ``)
    }

    if (!location.lobs?.length && !Object.keys(location.lobs).length) {
      handleErrors(`lobsError`, `Select Lobs`)
    } else {
      handleErrors(`lobsError`, ``)
    }

    if (!location?.credentialGroup?.value?.length) {
      handleErrors(`credentialGroupError`, `Select Group`)
    } else {
      handleErrors(`credentialGroupError`, ``)
    }

    if (!address.zip?.length && !Object.keys(address.zip).length) {
      handleErrors(`zipError`, `Select Zip Code`)
    } else {
      handleErrors(`zipError`, ``)
    }

    if (!address.streetNo.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }

    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }

    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, true)
    } else {
      handleErrors(`coordinatesError`, false)
    }
    handleErrors(`formSubmit`, true)
  }

  // load zipcodes specific to the franchise
  const loadRegionZipCodes = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        url.includes(`franchises`) ? fId : franchise.value
      }/regions/list/?search=${search && search}&page=${page}&offset=10`,
    )
    if (data?.regions) {
      return {
        options: data?.regions,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  // load lobs specific to the franchise
  const loadLobs = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        url.includes(`franchises`) ? fId : franchise.value
      }/lobs/list/?search=${search && search}&page=${page}&offset=10`,
    )
    if (data?.lobs) {
      return {
        options: data?.lobs,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  const handleLogo = async (file) => {
    try {
      const base64 = await fileResizer(file[0])
      setLocation((prevState) => ({
        ...prevState,
        img: base64,
      }))
    } catch (e) {
      NotificationManager.error(`Selected file must be image!`)
    }
  }
  useEffect(() => {
    if (
      permissionGranted([
        `locations:read`,
        `locations:create`,
        `locations:*`,
        `manager:*`,
      ]) &&
      !(isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`))
    ) {
      setFranchise({
        label: user?.profile?._franchise?.name,
        value: user?.profile?._franchise?._id,
      })
    }

    if (url.includes(`franchises`)) {
      AxiosInstance.get(`${AMS_BACKEND_API}/api/franchises/${fId}`).then(
        ({ data: { data } }) => {
          setFranchise({
            label: data.franchise.name,
            value: fId,
          })
        },
      )
    }
  }, [])

  useEffect(() => {
    address?.zip?.length ? setFirstZip(address?.zip[0]?.value) : setFirstZip(``)
  }, [address?.zip?.length])

  useEffect(() => {
    if (firstZip) {
      handleAddressInput(firstZip, `zip`)
    } else {
      setAddress((prevState) => ({
        ...prevState,
        [`city`]: ``,
        [`state`]: ``,
      }))
    }
  }, [firstZip])

  return (
    <Container>
      <PageHeader padding="true">Add Location</PageHeader>
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div
        className={`px-4 bg-white ${
          isLoading && ` opacity-30 pointer-events-none`
        } `}
      >
        {/* Locations basic form */}
        <div className="flex justify-center items-center my-0 py-3 ">
          <div className="w-50">
            <Label>
              <Image
                alt="fImage"
                className="items-center my-0 py-3"
                src={location?.img ? location?.img : defaultImage}
              />

              <input
                type="file"
                onChange={(e) => handleLogo(e.target.files)}
                className="hidden"
                accept="image/*"
              />
            </Label>
          </div>
        </div>

        {/* Locations basic form */}
        <div className="flex gap-6 mt-2">
          {permissionGranted([
            `locations:create`,
            `locations:*`,
            `manager:*`,
          ]) &&
          !(isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)) ? (
            <div className="flex-1 relative">
              <ReactSelectField
                title="Franchise"
                placeholder="Select Franchise"
                errorMessage={errors.franchiseError}
                value={franchise}
                setValue={(e) => setFranchise(e)}
                isMulti={false}
                // loadOptions={loadFranchises}
                isSearchable={true}
                disabled={!url.includes(`franchises`)}
              />
            </div>
          ) : (
            <div className="flex-1 relative">
              <ReactSelectField
                title="Franchise *"
                placeholder="Select Franchise"
                errorMessage={errors.franchiseError}
                value={franchise}
                setValue={(e) => setFranchise(e)}
                isMulti={false}
                loadOptions={loadFranchises}
                isSearchable={true}
                disabled={url.includes(`franchises`)}
              />
            </div>
          )}

          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name *"
              name="name"
              placeholder="Enter Name"
              validating={locNameValidating}
              errorMessage={errors.nameError}
              value={location.name}
              setValue={(e) => handleNameInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="email"
              title="Email *"
              name="email"
              validating={locEmailValidating}
              placeholder="Enter Email"
              errorMessage={errors.emailError}
              value={location.email}
              setValue={(e) => handleEmailInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Work Phone *"
              placeholder="Enter Phone"
              errorMessage={errors.workPhoneError}
              value={location.workPhone}
              setValue={(e) => handleLocationInput(`workPhone`, e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Extension *"
              placeholder="Enter Extension"
              errorMessage={errors.extError}
              value={location.lExt}
              setValue={(e) => handleLocationInput(`ext`, e.target.value)}
              mask="9999"
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Fax *"
              placeholder="Enter Fax"
              errorMessage={errors.faxError}
              value={location.fax}
              setValue={(e) => handleLocationInput(`fax`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Lobs *"
              placeholder="Select Lobs"
              errorMessage={errors.lobsError}
              value={location.lobs}
              setValue={(e) => handleLocationInput(`lobs`, e)}
              isMulti={true}
              loadOptions={
                url.includes(`franchises`)
                  ? loadLobs
                  : franchise.value && loadLobs
              }
              isSearchable={false}
              cacheUniqs={franchise.value}
            />
          </div>
          {/* <div className="flex-1 relative"></div> */}
          <div className="flex-1 relative">
            <ReactSelectField
              title="Group Credentials *"
              placeholder="Select Group"
              errorMessage={errors.credentialGroupError}
              value={location?.credentialGroup}
              setValue={(e) => handleLocationInput(`credentialGroup`, e)}
              isMulti={false}
              loadOptions={loadGroupCredentials}
              isSearchable={true}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Business Name *"
              name="businessName"
              placeholder="Enter Name"
              validating={businessNameValidating}
              errorMessage={errors.businessNameError}
              value={location.businessName}
              setValue={(e) => handleBusinessNameInput(e)}
            />
          </div>
          <div className="flex-1"></div>
          <div className="flex-1"></div>
          <div className="flex-1"></div>
        </div>

        {/* Buy Number For Franchise */}
        <SecondaryHeading>Select Phone</SecondaryHeading>

        <div className="grid grid-cols-3 gap-x-6 gap-y-2 mt-0.5 text-xs">
          <div className="flex-1 relative">
            <ReactSelectField
              title="State *"
              placeholder="Select State"
              value={stateForPhone}
              errorMessage={errors.stateForPhoneError}
              loadOptions={loadStates}
              setValue={(value) => {
                setStateForPhone(value)
                if (value?.value !== stateForPhone?.value) {
                  handleLocationInput(`phone`, ``)
                }
              }}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Phone *"
              placeholder="Select Phone"
              errorMessage={errors.phoneError}
              value={location.phone}
              setValue={(value) => {
                handleLocationInput(`phone`, value)
              }}
              loadOptions={stateForPhone?.value && loadAvailableNumbers}
              isSearchable={true}
              cacheUniqs={stateForPhone}
              disabled={!stateForPhone.value}
            />
          </div>
        </div>

        {/* Location Address starts from here */}
        <SecondaryHeading>Address</SecondaryHeading>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street *"
              name="streetNo"
              placeholder="Enter Street"
              errorMessage={errors.streetNoError}
              value={address.streetNo}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              placeholder="Select Zip"
              errorMessage={errors.zipError}
              value={address.zip}
              setValue={(e) => {
                setAddress((prevState) => ({
                  ...prevState,
                  [`zip`]: e,
                }))
              }}
              isMulti={true}
              loadOptions={
                // show all zipcodes to US OFFIces franchise
                url.includes(`franchises`)
                  ? loadRegionZipCodes
                  : franchise &&
                    (franchise?.label === `US OFFICES`
                      ? loadZipCodes
                      : loadRegionZipCodes)
              }
              isSearchable={true}
              cacheUniqs={franchise.value}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              name="unitNo"
              placeholder="Enter Unit No"
              // errorMessage={errors.unitNoError}
              value={address.unitNo}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>

          <div className="flex-1 relative">
            <InputField
              type="text"
              title="City *"
              name="city"
              placeholder="Enter City"
              errorMessage={errors.cityError}
              validating={locZipValidating}
              disabled={true}
              value={address.city}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="State *"
              name="state"
              placeholder="Enter State"
              errorMessage={errors.stateError}
              validating={locZipValidating}
              disabled={true}
              value={address.state}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
        </div>
        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView center={[coordinates.lat, coordinates.lng]} zoom={3} />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div>
        <div className="flex justify-center">
          <div className="text-center w-96 my-4">
            <SubmitButton onClick={ValidateForm} disabled={locZipValidating}>
              <div>Create Location</div>
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
