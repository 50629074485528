import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faSortUp,
  faSortDown,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { useQuery } from 'react-query'
// import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useHistory } from 'react-router-dom'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import dateFormat from 'App/Services/General/dateFormat'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import Filters from './Filters'

const tableColumns = [
  { label: `Policy`, value: `policyNo` },
  { label: `Carrier`, value: `_carrier.label` },
  { label: `Creator`, value: `_creator.label` },
  { label: `Premium`, value: `totalPremium` },
  { label: `Customer`, value: `_customer.label` },
  { label: `Eft Date`, value: `effectiveDate` },
  { label: `Exp Date`, value: `expiryDate` },
  { label: `Created`, value: `createdAt` },
  { label: `Updated`, value: `updatedAt` },
]

export const AllPolicies = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)

  const [isOffset, setIsOffset] = useState(``)
  const [policyFilters, setPolicyFilters] = useState({
    policyNo: ``,
    _policyId: ``,
    premium: ``,
  })

  const handleResetFilters = () => {
    setPolicyFilters({
      policyNo: ``,
      _policyId: ``,
      premium: ``,
    })
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: `selection`,
      },
    ])
    setExpiryDateRange([
      {
        startDate: null,
        endDate: null,
        key: `selection`,
      },
    ])
  }

  const handleFiltersChange = (filters) => {
    setCurrentPage(1)
    setPolicyFilters(filters)
  }

  //  date range picker
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
    },
  ])
  const [expiryDateRange, setExpiryDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
    },
  ])

  const handleDateRange = (range) => {
    setDateRange(range)
  }
  const handleExpiryDateRange = (range) => {
    setExpiryDateRange(range)
  }

  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }
    if (policyFilters?.policyNo?.length) {
      data.filters.policyNo = policyFilters?.policyNo
    }
    if (policyFilters?._policyId?.length) {
      data.filters._policyId = policyFilters?._policyId
    }
    if (policyFilters?.premium?.length) {
      data.filters.premium = policyFilters?.premium
    }
    if (dateRange[0].isSelected) {
      data.filters.effectiveDate = [
        dateFormat(dateRange[0].startDate),
        dateFormat(dateRange[0].endDate),
      ]
    }
    if (expiryDateRange[0].isSelected) {
      data.filters.expiryDate = [
        dateFormat(expiryDateRange[0].startDate),
        dateFormat(expiryDateRange[0].endDate),
      ]
    }
    return JSON.stringify(data)
  }

  const {
    url,
    params: { customerId },
  } = useRouteMatch()
  const history = useHistory()

  const {
    isLoading,
    data: policiesData,
    error,
    refetch: getAllPolicies,
    isFetching,
  } = useQuery(
    `PoliciesApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/policies${
          customerId !== undefined ? `/customer/${customerId}` : ``
        }?search=${searchFilters()}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (!customSearching) {
      getAllPolicies()
    }
  }, [sort, currentPage, isOffset, policyFilters])

  return (
    <Container>
      {/* {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )} */}
      <PageHeader>
        {customerId === undefined && <div>Policies</div>}

        <div className="flex">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>

          {/* <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              setValue={(e) => {
                setSearchQuery(e.target.value)
                setCurrentPage(1)
                setCustomSearching(true)
                callPoliciesQuery()
              }}
              disabled={isFetching}
            />
          </div> */}
        </div>
      </PageHeader>

      {customerId === undefined && (
        <Filters
          ledgerFilters={policyFilters}
          handleFilters={handleFiltersChange}
          resetFilters={handleResetFilters}
          _dateRange={dateRange}
          _expiryDateRange={expiryDateRange}
          handleDateChange={handleDateRange}
          handleExpiryDateChange={handleExpiryDateRange}
        />
      )}

      <div className={`bg-white mb-1 sm:mb-0 justify-center items-center`}>
        {isLoading || isFetching ? (
          <div className="text-center py-10">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => getAllPolicies()} />
        ) : (
          <>
            <div className="overflow-x-auto  mt-2 rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {/* {(permissionGranted([
                      `policies:read`,
                      `admin-franchise`,
                      `admin-master-franchise`,
                    ]) ||
                      permissionGranted([
                        `policies:delete`,
                        `admin-franchise`,
                        `admin-master-franchise`,
                      ])) && (
                      <TableCell className="float-right">Actions</TableCell>
                    )} */}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {policiesData?.policies?.length != 0 ? (
                    policiesData?.policies?.map((policy, index) => (
                      <TableRow
                        key={index.toString()}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          if (
                            permissionGranted([
                              `policies:read`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])
                          ) {
                            if (customerId) {
                              history.push(`${url}/policies/${policy._id}`)
                            } else {
                              history.push(`${url}/${policy._id}`)
                            }
                          }
                        }}
                      >
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <span className="font-medium truncate">
                            <Badge>
                              {policy.policyNo ? policy.policyNo : `N/A`}
                            </Badge>
                          </span>
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate capitalize">
                          {policy._carrier?.label ? (
                            policy._carrier?.label
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          {policy?._creator?.label ? (
                            policy?._creator?.label
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          {policy.totalPremium ? (
                            `$` + policy.totalPremium
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          {policy._customer?.label ? (
                            policy._customer?.label
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          <Badge type="success">
                            {policy.effectiveDate ? (
                              <span>{dateFormat(policy.effectiveDate)}</span>
                            ) : (
                              `N / A`
                            )}
                          </Badge>
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          <Badge type="danger">
                            {policy.expiryDate ? (
                              <span>{dateFormat(policy.expiryDate)}</span>
                            ) : (
                              `N / A`
                            )}
                          </Badge>
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          <span>{dateFormat(policy.createdAt)}</span>
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          <span>{dateFormat(policy.updatedAt)}</span>
                        </td>
                        {/* Actions TD */}
                        {/* <td className="px-6 py-0.5 z-0 text-sm flex float-right text-gray-400">
                          {permissionGranted([
                            `policies:delete`,
                            `admin-franchise`,
                            `admin-master-franchise`,
                          ]) && (
                            <span data-tip="Delete" className="tooltip">
                              <FontAwesomeIcon
                                className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                icon={faTrash}
                                size="1x"
                                onClick={(e) => handleDelete(policy._id, e)}
                              />
                            </span>
                          )}
                        </td> */}
                      </TableRow>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Policy Found</div>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={policiesData?.totalPages}
                totalRecords={policiesData?.totalRecords}
                curerntPageRecords={policiesData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
