import React from 'react'
import { backwardArrow, forwardArrow } from 'App/Assets'

export const ReactPagination = ({
  currentPage,
  offset,
  totalPages,
  totalRecords,
  onChange,
  curerntPageRecords,
}) => {
  const handleNextPage = () => {
    if (currentPage !== totalPages) {
      onChange(currentPage + 1)
    }
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onChange(currentPage - 1)
    }
  }

  const handlePages = () => {
    let pages = []
    for (let index = 1; index < totalPages + 1; index++) {
      pages.push(
        <span
          key={index.toString()}
          onClick={() => onChange(index)}
          className={`relative inline-flex items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md ${
            currentPage === index
              ? `z-10 border bg-blue-500 border-blue-500 text-white`
              : `text-gray-500 hover:bg-gray-50`
          }`}
        >
          {index}
        </span>,
      )
    }

    return pages
  }

  return (
    <div className="bg-white py-3 flex items-center justify-between">
      <div className="flex-1 flex justify-between sm:hidden">
        <a className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Previous
        </a>
        <a className="ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
          Next
        </a>
      </div>
      {curerntPageRecords !== undefined && curerntPageRecords > 0 && (
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <p className="text-xs text-gray-600 font-bold">
            Showing {currentPage * offset - offset + 1} -
            {curerntPageRecords == 10
              ? currentPage * offset
              : currentPage * offset - offset + curerntPageRecords}
            {` OF ` + totalRecords}
          </p>

          <div>
            <div
              className="relative z-0 inline-flex rounded-md -space-x-px"
              aria-label="Pagination"
            >
              <span
                onClick={() => handlePrevPage()}
                className={`${
                  currentPage == 1 ? `cursor-not-allowed` : `cursor-pointer`
                } relative inline-flex items-center px-2 py-2 rounded-l-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
              >
                <img src={backwardArrow} alt="img" />
              </span>
              {totalPages < 8 ? (
                handlePages()
              ) : currentPage > totalPages - 4 ? (
                <>
                  <span
                    onClick={() => onChange(1)}
                    className={`relative inline-flex items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md ${
                      currentPage === 1
                        ? `z-10 border bg-blue-500 border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    1
                  </span>
                  <span className="relative inline-flex items-center px-3 py-1 bg-white text-sm font-medium text-gray-700">
                    ...
                  </span>
                  <span
                    onClick={() => onChange(totalPages - 4)}
                    className={`bg-white relative inline-flex items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md ${
                      currentPage === totalPages - 4
                        ? `z-10 border bg-blue-500 border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    {totalPages - 4}
                  </span>
                  <span
                    onClick={() => onChange(totalPages - 3)}
                    className={`bg-white hidden md:inline-flex relative items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md ${
                      currentPage === totalPages - 3
                        ? `z-10 border bg-blue-500 border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    {totalPages - 3}
                  </span>
                  <span
                    onClick={() => onChange(totalPages - 2)}
                    className={`bg-white hidden md:inline-flex relative items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md ${
                      currentPage === totalPages - 2
                        ? `z-10 bg-blue-500 border border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    {totalPages - 2}
                  </span>
                  <span
                    onClick={() => onChange(totalPages - 1)}
                    className={`bg-white hidden md:inline-flex relative items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md ${
                      currentPage === totalPages - 1
                        ? `z-10 bg-blue-500 border border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    {totalPages - 1}
                  </span>
                  <span
                    onClick={() => onChange(totalPages)}
                    className={`bg-white relative inline-flex items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md ${
                      currentPage === totalPages
                        ? `z-10 bg-blue-500 border border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    {totalPages}
                  </span>
                </>
              ) : currentPage > 4 ? (
                <>
                  <span
                    onClick={() => onChange(1)}
                    className="z-10 relative inline-flex items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md"
                  >
                    1
                  </span>
                  <span className="relative inline-flex items-center px-3 py-1 bg-white text-sm font-medium text-gray-700 rounded-md">
                    ...
                  </span>
                  <span
                    onClick={() => onChange(currentPage - 1)}
                    className="bg-white text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md"
                  >
                    {currentPage - 1}
                  </span>
                  <span
                    onClick={() => onChange(currentPage)}
                    className="bg-white hover:bg-gray-50 hidden md:inline-flex relative items-center px-3 py-1 border text-sm font-medium bg-blue-500 border-blue-500 z-10 rounded-md text-white cursor-pointer"
                  >
                    {currentPage}
                  </span>
                  <span
                    onClick={() => onChange(currentPage + 1)}
                    className="bg-white text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md"
                  >
                    {currentPage + 1}
                  </span>
                  <span className="relative inline-flex items-center px-3 py-1 bg-white text-sm font-medium text-gray-700">
                    ...
                  </span>
                  <span
                    onClick={() => onChange(totalPages)}
                    className="bg-white text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md"
                  >
                    {totalPages}
                  </span>
                </>
              ) : (
                <>
                  <span
                    onClick={() => onChange(1)}
                    className={`z-10 relative inline-flex items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md ${
                      currentPage === 1
                        ? `z-10 bg-blue-500 border border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    1
                  </span>
                  <span
                    onClick={() => onChange(2)}
                    className={`bg-white relative inline-flex items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md ${
                      currentPage === 2
                        ? `z-10 bg-blue-500 border border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    2
                  </span>
                  <span
                    onClick={() => onChange(3)}
                    className={`bg-white hidden md:inline-flex relative items-center px-3 py-1 text-sm font-medium rounded-md cursor-pointer ${
                      currentPage === 3
                        ? `z-10 bg-blue-500 border border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    3
                  </span>
                  <span
                    onClick={() => onChange(4)}
                    className={`bg-white hidden md:inline-flex relative items-center px-3 py-1 text-sm font-medium rounded-md cursor-pointer ${
                      currentPage === 4
                        ? `z-10 bg-blue-500 border border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    4
                  </span>
                  <span
                    onClick={() => onChange(5)}
                    className={`bg-white hidden md:inline-flex relative items-center px-3 py-1 text-sm font-medium rounded-md cursor-pointer ${
                      currentPage === 5
                        ? `z-10 bg-blue-500 border border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    5
                  </span>
                  <span className="relative inline-flex items-center px-3 py-1 bg-white text-sm font-medium text-gray-700">
                    ...
                  </span>
                  <span
                    onClick={() => onChange(totalPages)}
                    className={`bg-white relative inline-flex items-center px-3 py-1 text-sm font-medium cursor-pointer rounded-md ${
                      currentPage === totalPages
                        ? `z-10 bg-blue-500 border border-blue-500 text-white`
                        : `text-gray-500 hover:bg-gray-50`
                    }`}
                  >
                    {totalPages}
                  </span>
                </>
              )}

              <span
                onClick={() => handleNextPage()}
                className={`${
                  currentPage === totalPages
                    ? `cursor-not-allowed`
                    : `cursor-pointer`
                } relative inline-flex items-center px-2 py-2 rounded-r-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
              >
                <img src={forwardArrow} alt="img" />
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
