import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  paymentTemplate: ``,
}

const paymentTemplateSlice = createSlice({
  name: `paymentTemplate`,

  initialState,
  reducers: {
    setCurrentPaymentTemplate: (state, { payload }) => {
      state.paymentTemplate = payload
    },
  },
})

export const { setCurrentPaymentTemplate } = paymentTemplateSlice.actions

export const paymentTemplate = paymentTemplateSlice.reducer
