import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadLienHolderInterests = async (
  search,
  loadedOptions,
  { page },
) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AMS_BACKEND_API}/api/get/lien-holder-interests?search=${
      search && search
    }&offset=10&page=${page}`,
  )
  if (data) {
    return {
      options: data.interests,
      hasMore: data.totalPages - page > 0,
      additional: {
        page: page + 1,
      },
    }
  }
}
