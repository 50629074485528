import { combineReducers } from 'redux'
import { user } from './Slices/User'
import { agent } from './Slices/Agent'
import { asset } from './Slices/Asset'
import { carrier } from './Slices/Carrier'
import { location } from './Slices/Location'
import { claim } from './Slices/Claim'
import { customer } from './Slices/Customer'
import { franchise } from './Slices/Franchise'
import { endorsements } from './Slices/Endorsment'
import { invoice } from './Slices/Invoice'
import { payment } from './Slices/Payment'
import { paymentTemplate } from './Slices/PaymentTemplates'
import { dataTransactions } from './Slices/DataTransactions'
import { policy } from './Slices/Policy'
import { notification } from './Slices/Notifications'
import { dailyLedger } from './Slices/DailyLedger'
import { communication } from './Slices/Communication'
import { gmail } from './Slices/Communication/gmails'
import { video } from './Slices/Communication/video'

import { ratingTemplate } from './Slices/Ratings/RatingTemplates'
import { quoteForm } from './Slices/Ratings/QuoteForm'
import { preferences } from './Slices/Ratings/MyPreferences'
import { myquotes } from './Slices/Ratings/MyQuotes'
import { carDealer } from './Slices/CarDealer'
import { credentials } from './Slices/Credentials'
import { currentCommunication } from './Slices/CurrentCommunicationCredential'
import { lead } from './Slices/Leads'
import { aqsQuote } from './Slices/AqdQuote'
import { globalSearch } from './Slices/GlobalSearch'
import { monthlyGoal } from './Slices/MonthlyGoals'
import { ticketCategory } from './Slices/TicketCategory'
import { globalCommunications } from './Slices/Communication/globalCommunication'
import { campaignGroup } from './Slices/Campaigns'
import { assessment } from './Slices/Assessment'
import { tickets } from './Slices/TicketsCenter'
import { tasks } from './Slices/TaskManager'
import { systemRole } from './Slices/SystemRoles'
import { messenger } from './Slices/Messenger'
import { policyRequest } from './Slices/PolicyRequest'
import { generalNotification } from './Slices/GeneralNotification'

import storage from './presistStorage'

const appReducer = combineReducers({
  user,
  asset,
  franchise,
  customer,
  location,
  systemRole,
  claim,
  agent,
  carrier,
  endorsements,
  invoice,
  payment,
  paymentTemplate,
  dataTransactions,
  policy,
  notification,
  dailyLedger,
  communication,
  quoteForm,
  ratingTemplate,
  preferences,
  myquotes,
  gmail,
  video,
  carDealer,
  credentials,
  currentCommunication,
  lead,
  aqsQuote,
  globalSearch,
  monthlyGoal,
  globalCommunications,
  campaignGroup,
  assessment,
  tickets,
  tasks,
  ticketCategory,
  messenger,
  policyRequest,
  generalNotification
})

const rootReducer = (state, action) => {
  if (action.type === `user/resetPersistantState`) {
    storage.removeItem(`persist:PresistStorage`)
    state = {}
  }
  return appReducer(state, action)
}

export { rootReducer }
