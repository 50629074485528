import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { StyledButton } from 'App/Styled'
import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
// import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { NotificationManager } from 'react-notifications'

export const UpdateDesignation = ({
  currentDesignation,
  closeModal,
  designationUpdated,
}) => {
  const [designationUp, setDesignationUp] = useState({
    name: ``,
    type: ``,
    rate: ``,
  })

  const [errors, setErrors] = useState({
    nameError: ``,
    commissionTypeError: ``,
    commissionRateError: ``,
    formSubmit: false,
  })

  useEffect(() => {
    if (currentDesignation) {
      handleChange(`name`, currentDesignation?.name)
      handleChange(`type`, {
        value: currentDesignation?.commission?.type,
        label: currentDesignation?.commission?.type,
      })
      handleChange(`rate`, currentDesignation?.commission?.rate)
    }
  }, [currentDesignation])

  const { mutate, isLoading } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/designations/${currentDesignation._id}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          if (res.data) {
            NotificationManager.success(`Operation successful`)
            closeModal()
            designationUpdated()
          }
        })
        .catch((error) => {

          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            }
            else {
              NotificationManager.error(error.response?.data?.message)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleChange = (name, value) => {
    setDesignationUp((preState) => ({
      ...preState,
      [name]: value,
    }))
  }
  const loadTypes = async () => {
    return {
      options: [
        { value: `%`, label: `%` },
        { value: `FLAT`, label: `FLAT` },
      ],
      hasMore: false,
    }
  }
  const ValidateForm = () => {
    if (!designationUp.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }

    if (
      designationUp?.type?.value?.length &&
      designationUp?.type?.value === `%`
    ) {
      if (!designationUp?.rate) {
        handleErrors(`commissionRateError`, `Enter Rate`)
      } else if (designationUp?.rate > 100) {
        handleErrors(`commissionRateError`, `Rate must be around 100`)
      } else if (designationUp?.rate < 1) {
        handleErrors(`commissionRateError`, `Rate must be more than 0`)
      } else {
        handleErrors(`commissionRateError`, ``)
      }
    } else {
      if (!designationUp?.rate) {
        handleErrors(`commissionRateError`, `Enter Rate`)
      } else if (designationUp?.rate < 1) {
        handleErrors(`commissionRateError`, `Rate must be more than 0`)
      } else {
        handleErrors(`commissionRateError`, ``)
      }
    }
    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.commissionTypeError.length &&
      !errors.commissionRateError.length
    ) {
      mutate(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const updatePayload = () => {
    let data = {}
    if (
      designationUp?.name?.length &&
      designationUp?.name !== currentDesignation?.name
    ) {
      data.name = designationUp?.name
    }
    if (
      designationUp?.type?.value !== currentDesignation?.commission?.type ||
      designationUp?.rate?.toString() !==
      currentDesignation?.commission?.rate?.toString()
    ) {
      data.commission = {}
      if (designationUp?.type?.value !== currentDesignation?.commission?.type) {
        data.commission.type = designationUp?.type?.value
      }
      if (
        designationUp?.rate?.toString() !==
        currentDesignation?.commission?.rate?.toString()
      ) {
        data.commission.rate = designationUp?.rate
      }
    }

    return data
  }
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col px-4">
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="name"
              title="Name *"
              placeholder="Enter Name"
              errorMessage={errors.nameError}
              value={designationUp.name}
              setValue={(e) => handleChange(`name`, e.target.value)}
            />
          </div>
          <div className="flex gap-4">
            <div className="flex-1 relative">
              <ReactSelectField
                name="commission"
                title="Commission Type *"
                loadOptions={loadTypes}
                errorMessage={errors.commissionTypeError}
                value={designationUp?.type}
                isMulti={false}
                isSearchable={true}
                setValue={(value) => {
                  handleChange(`type`, value)
                }}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="number"
                name="Rate"
                title="Commission Rate *"
                placeholder="Enter Rate"
                errorMessage={errors.commissionRateError}
                value={designationUp.rate}
                setValue={(e) => handleChange(`rate`, e.target.value)}
                onKeyPress={(e) =>
                  [`e`, `E`, `+`, `-`, `.`].includes(e.key) &&
                  e.preventDefault()
                }
              />
            </div>
          </div>
        </div>
        <div className="pb-2 px-4 text-right">
          <StyledButton onClick={ValidateForm}>
            <div className="flex gap-2 text-white">
              <span>Update</span>
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </StyledButton>
        </div>
      </div>
    </>
  )
}
