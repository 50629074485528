/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {
    email: null,
    firstName: null,
    lastName: null,
    id: null,
  },
  preferredLocation: {
    _id: ``,
    name: ``,
    phone: ``,
    always: false,
    currentlySet: false,
  },
  isLoadingUser: false,
  googleAuthorizationCode: ``,
  googleSignIn: false,
  switchLocation: false,
}

const userSlice = createSlice({
  name: `user`,

  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload
      state.isLoadingUser = false
    },
    setUserAccessToken: (state, { payload }) => {
      state.user.accessToken = payload
      state.isLoadingUser = false
    },

    unsetUser: (state) => {
      state.user = initialState.user
      state.isLoadingUser = false
    },
    loadingUser: (state) => {
      state.isLoadingUser = true
    },
    setAuthorizationCode: (state, { payload }) => {
      state.googleAuthorizationCode = payload
    },
    setIsUserSignInWithGmail: (state, { payload }) => {
      state.googleSignIn = payload
    },
    setPreferredLocation: (state, { payload }) => {
      state.preferredLocation = payload
    },
    setSwitchLocation: (state, { payload }) => {
      state.switchLocation = payload
    },

    resetPersistantState() {},
  },
})

export const {
  setUser,
  unsetUser,
  loadingUser,
  setAuthorizationCode,
  setIsUserSignInWithGmail,
  resetPersistantState,
  setPreferredLocation,
  setUserAccessToken,
  setSwitchLocation,
} = userSlice.actions

export const user = userSlice.reducer
