/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { defaultImage } from 'App/Assets'
import { Reload } from 'App/Components/Common/Reload'
import { useHistory } from 'react-router'
import { CardField } from 'App/Components/Common/CardField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faMapMarkerAlt,
  faPen,
  faPhoneAlt,
  faSpinner,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { Container, EditStyledButton, Image, PageHeader } from 'App/Styled'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Badge } from '@windmill/react-ui'
import { permissionGranted } from 'App/Services'
import { PreferredLocation } from '../../Layout/PreferredLocation/index'
import { setAgentProfile, setPreferredLocation } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'
import cloneDeep from 'clone-deep'

import { useRouteMatch } from 'react-router'
import { getCookie } from 'App/Services/utils/getCookie'
export const Profile = () => {
  const user = useSelector(({ user: { user } }) => user)
  const preferredLocation = useSelector(
    ({ user: { preferredLocation } }) => preferredLocation,
  )
  const { url } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const [showPreferredLocation, setShowPreferredLocation] = useState(false)

  const {
    data: agentPreviewApi,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `AgentPreviewApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/users/profile`).then(
        (res) => {
          let user = res.data.data.user
          let roundedCoordinates = {
            lat: user.address?.coordinates?.lat.toFixed(3),
            long: user.address?.coordinates?.long.toFixed(3),
          }
          if (user.address?.coordinates) {
            user.address.coordinates = roundedCoordinates
          }
          dispatch(setAgentProfile(user))
          return user
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  const rememberedLocation = getCookie(`preferredLocation`)
    ? JSON.parse(getCookie(`preferredLocation`))
    : `{}`

  const handleChangePreferredLocation = (preferredLocation) => {
    let currentLocation = cloneDeep(preferredLocation)
    currentLocation.always = false
    currentLocation.currentlySet = false
    currentLocation.switchLocation = false
    currentLocation.profileLocation = true
    document.cookie = `preferredLocation=${JSON.stringify({
      ...rememberedLocation,
      always: false,
    })}; path=/`

    dispatch(setPreferredLocation(currentLocation))
    setShowPreferredLocation(true)
  }

  return (
    <Container>
      {showPreferredLocation && (
        <PreferredLocation
          setShowPreferredLocation={() => {
            setShowPreferredLocation(false)
          }}
        />
      )}
      {/* <PageHeader>
        <div></div>

        {permissionGranted([`users:update`]) && (
            <EditStyledButton onClick={() => history.push(`${url}/update`)}>
              <span>Update Profile</span>
              <FontAwesomeIcon
                icon={faPen}
                className="text-white pt-1"
                size="sm"
              />
            </EditStyledButton>
        )}
      </PageHeader> */}
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <>
          <div className="flex flex-row m-3  ">
            <div className="p-1 mr-4">
              <Image
                alt="agentImage"
                src={agentPreviewApi?.img ? agentPreviewApi?.img : defaultImage}
              />
            </div>
            <div className="flex-1">
              {/* Basic Info Start name email and gender */}
              <div className="flex justify-between">
                <div>
                  <div className="text-lg py-0.5 font-bold">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-gray-400 mt-0.5"
                      size="sm"
                    />
                    &nbsp;&nbsp;
                    {agentPreviewApi?.firstName +
                      ` ` +
                      agentPreviewApi.lastName}
                  </div>
                  <div className="text-sm py-0.5 font-bold">
                    <FontAwesomeIcon
                      icon={faPhoneAlt}
                      className="text-gray-400 mt-0.5"
                      size="sm"
                    />
                    &nbsp;&nbsp;
                    {agentPreviewApi?.contactInfo?.phone}
                  </div>
                  <div className="text-sm py-0.5 font-bold">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-gray-400 mt-0.5"
                      size="sm"
                    />
                    &nbsp;&nbsp;
                    {agentPreviewApi?.contactInfo?.email}
                  </div>
                  <div className="text-sm py-0.5 font-bold">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-gray-400 mt-0.5"
                      size="sm"
                    />
                    &nbsp;&nbsp;
                    { agentPreviewApi?.address?.unitNo ? `${agentPreviewApi?.address?.unitNo},`: ``} 
                    {agentPreviewApi?.address?.street},
                    {agentPreviewApi?.address?.city} ,
                    {agentPreviewApi?.address?.state},
                    {agentPreviewApi?.address?.zip}
                  </div>
                </div>
                <div className="">
                  <EditStyledButton
                    onClick={() => history.push(`${url}/update`)}
                  >
                    <span>Edit</span>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="text-white pt-1"
                      size="sm"
                    />
                  </EditStyledButton>
                </div>
              </div>
              {/* end basic Info */}

              <div className="rounded-md overflow-hidden py-4">
                <div className="flex items-start justify-evenly">
                  <div className="flex-1 mx-2 rounded">
                    <div className="grid grid-cols-12 p-2">
                      <div className="col-span-6 text-xl">More Info</div>
                    </div>
                    <hr />

                    <div className="flex flex-col md:flex-row px-2">
                      <div className="flex-1">
                        <CardField
                          title="Franchise"
                          value={agentPreviewApi?.franchiseName}
                        />
                        <CardField
                          title="Office Phone"
                          value={preferredLocation?.phone}
                        />

                        <CardField
                          title="Gender"
                          value={agentPreviewApi?.gender}
                        />

                        <CardField
                          title="Extension"
                          value={agentPreviewApi?.contactInfo?.workPhoneExt}
                        />
                        {/* {agentPreviewApi?.password &&
                          <CardField
                            title="Password"
                            value={agentPreviewApi?.password}
                          />
                        } */}
                        <CardField
                          title="Work Phone"
                          value={agentPreviewApi?.contactInfo?.workPhone}
                        />
                        <CardField
                          title="Location"
                          value={agentPreviewApi?.locationNames?.map((loc) => {
                            return loc.label
                          })}
                          multiple={true}
                        />
                      </div>
                      <div className="flex-1">
                        <CardField
                          title="Fax"
                          value={agentPreviewApi?.contactInfo?.fax}
                        />

                        <CardField
                          title="Roles"
                          value={agentPreviewApi?.systemRoles?.map((role) => {
                            return role?.label
                          })}
                          multiple={true}
                        />
                        <CardField
                          title="Coordinates"
                          value={agentPreviewApi?.address?.coordinates}
                          coord={true}
                        />
                        <CardField
                          title="Commission"
                          value={
                            agentPreviewApi?.commission?.rate ? (
                              agentPreviewApi?.commission?.rate +
                              ` ` +
                              agentPreviewApi?.commission?.type
                            ) : (
                              <Badge type="success">N/A</Badge>
                            )
                          }
                        />
                        <span>
                          <div className="grid grid-cols-5 py-1.5 justify-items-start place-content-center text-sm">
                            <div className="col-span-2 text-gray-500 font-medium capitalize">
                              Preferred Location
                            </div>
                            <div className="col-span-3 flex flex-wrap font-medium my-auto">
                              <span
                                className={`py-1 px-1.5 break-words max-w-px-180 whitespace-pre text-sm rounded-xl border border-gray-400`}
                              >
                                {preferredLocation?.name}
                              </span>
                            </div>
                          </div>
                        </span>
                        {rememberedLocation?.always && (
                          <span>
                            <div className="grid grid-cols-5 py-1.5 justify-items-start place-content-center text-sm">
                              <div className="col-span-2 text-gray-500 font-medium capitalize">
                                Remembered Location
                              </div>
                              <div className="col-span-3 flex flex-wrap font-medium my-auto">
                                <span
                                  className={`py-1 px-1.5 break-words max-w-px-180 whitespace-pre text-sm rounded-xl border border-gray-400`}
                                >
                                  {rememberedLocation?.name}
                                </span>
                                <span
                                  onClick={() =>
                                    handleChangePreferredLocation(
                                      preferredLocation,
                                    )
                                  }
                                  data-tip="Change"
                                  className="cursor-pointer hover:bg-gray-300 rounded-full mx-1 tooltip"
                                >
                                  <FontAwesomeIcon
                                    icon={faPen}
                                    className="p-2"
                                    size="sm"
                                  />
                                </span>
                              </div>
                            </div>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  )
}
