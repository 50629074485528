import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { useRouteMatch, useHistory } from 'react-router'
import { CardField } from 'App/Components/Common/CardField'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faPen,
  faShare,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { Container, EditStyledButton, FormTab, PageHeader } from 'App/Styled'
import { AxiosInstance, AMS_BACKEND_API } from 'App/Config'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { ReactModal } from 'App/Components/Common/Modal'
import { UpdateLead } from '../../UpdateLead'
import {
  setCommunicationCredentials,
  setCurrentLead,
  setLeadData,
} from 'App/Redux/actions'
import { useDispatch } from 'react-redux'
import { permissionGranted } from 'App/Services'
import { utcToPst } from 'App/Services/utils/pstTimeZone'
import dateFormat from 'App/Services/General/dateFormat'

export const AboutLead = () => {
  const {
    params: { leadId },
  } = useRouteMatch()

  const history = useHistory()
  const [updateStatusModal, setUpdateStatusModal] = useState(false)
  const [leadUpdated, setLeadUpdated] = useState(false)
  const [leadVerify, setLeadVerify] = useState(false)
  const dispatch = useDispatch()

  const [driversTabs, setDriversTab] = useState(``)
  const [activeDriverTab, setActiveDriverTab] = useState(1)

  const [vehiclesTabs, setVehiclesTab] = useState(``)
  const [activeVehicleTab, setActiveVehicleTab] = useState(1)

  const {
    data: leadData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `LeadPreviewApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/leads/${leadId}`).then(
        (res) => res?.data?.data?.lead,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        // sort drivers tabs

        if (res?.drivers?.length > 0) {
          setDriversTab(
            res?.drivers?.map((driver, index) => {
              return {
                name: driver?.firstName + ` ` + driver?.lastName,
                number: index + 1,
              }
            }),
          )
        }

        // sort vehicles tabs

        if (res?.cars?.length > 0) {
          setVehiclesTab(
            res?.cars?.map((vehicle, index) => {
              return {
                name: vehicle?.model,
                number: index + 1,
              }
            }),
          )
        }
        dispatch(
          setCommunicationCredentials({
            id: res._id,
            phone: res.customerData?.contactInfo?.phone,
            customerId: res._customer,
            img: res.img,
            doNotContact: res?.customerData?.doNotContact,
            preferredContactMethods:
              res?.customerData?.preferredContactMethods?.map((item) =>
                item.toUpperCase(),
              ),
            email: res.customerData?.contactInfo?.email,
          }),
        )

        dispatch(setCurrentLead(res))
      },
    },
  )

  useEffect(() => {
    if (leadUpdated) {
      refetch()
      setLeadUpdated(false)
    }
  }, [leadUpdated])

  const handleVerify = () => {
    setLeadVerify(true)
  }
  const handleStatus = () => {
    if (leadData?.status !== `SOLD`) {
      setUpdateStatusModal(true)
    }
  }

  const handleTransfer = () => {
    if (leadData?._leadId) {
      dispatch(setLeadData(leadData))
      history.push(`/raters/quoteform`)
    }
  }

  return (
    <Container>
      <PageHeader>
        <div>Lead</div>

        {(!isLoading || !isFetching) && (
          <>
            {permissionGranted([
              `leads:update`,
              `corporate-manager:*`,
              `manager:*`,
              `admin:*`,
            ]) && (
              <div className="flex">
                {leadData?.lob === `AUTO` && (
                  <EditStyledButton
                    onClick={() => handleTransfer()}
                    data-tip={`Transfer To Quote Form`}
                    className="tooltip"
                  >
                    <span>Transfer</span>
                    <FontAwesomeIcon icon={faShare} className="my-auto" />
                  </EditStyledButton>
                )}
                {permissionGranted([
                  `super-admin:*`,
                  `manager:*`,
                  `corporate-manager:*`,
                  `accountant:*`,
                ]) && (
                  <EditStyledButton
                    onClick={handleVerify}
                    data-tip={`Transfer To Quote Form`}
                    className="tooltip"
                  >
                    <span>Verify</span>
                    <FontAwesomeIcon icon={faCheckCircle} className="my-auto" />
                  </EditStyledButton>
                )}
                <EditStyledButton
                  onClick={handleStatus}
                  data-tip={`${
                    leadData?.status === `SOLD` ? `Sold Already` : `Update`
                  }`}
                  disabled={leadData?.status === `SOLD`}
                  className="tooltip"
                >
                  <span>Update</span>
                  <FontAwesomeIcon
                    icon={faPen}
                    className="text-white mt-0.5"
                    size="sm"
                  />
                </EditStyledButton>
              </div>
            )}
          </>
        )}
      </PageHeader>

      {leadVerify && (
        <ReactModal title="Verify" closeModal={() => setLeadVerify(false)}>
          <UpdateLead
            selectedLead={leadData}
            closeModal={() => setLeadVerify(false)}
            setLeadUpdated={() => setLeadUpdated(true)}
            isVerifyLead
          />
        </ReactModal>
      )}
      {updateStatusModal && (
        <ReactModal
          title="Update Lead"
          closeModal={() => setUpdateStatusModal(false)}
        >
          <UpdateLead
            selectedLead={leadData}
            closeModal={() => setUpdateStatusModal(false)}
            setLeadUpdated={() => setLeadUpdated(true)}
          />
        </ReactModal>
      )}
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-600"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <div className="p-2">
          <div className="flex items-start gap-2 justify-evenly pb-2">
            <div className="flex-1 rounded">
              <div className="grid grid-cols-12 px-4 py-2">
                <div className="col-span-6 text-xl">Info</div>
              </div>
              <hr />
              <div className="flex px-4">
                <div className="flex-1">
                  <CardField title="ID" value={leadData?._leadId} />
                  {leadData?.policyMetData?.priceSold ? (
                    <CardField
                      title="Price Sold"
                      value={
                        leadData?.policyMetData?.priceSold
                          ? `$` + leadData?.policyMetData?.priceSold
                          : null
                      }
                    />
                  ) : (
                    ``
                  )}
                  {leadData.customerData?.firstName ? (
                    <CardField
                      title="First Name"
                      value={leadData.customerData?.firstName}
                    />
                  ) : (
                    ``
                  )}
                  {leadData.customerData?.address?.city ? (
                    <CardField
                      title="City"
                      value={leadData.customerData?.address?.city}
                    />
                  ) : (
                    ``
                  )}
                  {leadData?.customerData?.dob ? (
                    <CardField
                      title="DOB"
                      value={dateFormat(leadData?.customerData?.dob)}
                    />
                  ) : (
                    ``
                  )}
                  {leadData.customerData?.address ? (
                    <CardField
                      title="State"
                      value={
                        leadData.customerData?.address?.zip
                          ? leadData.customerData?.address?.state +
                            `  ` +
                            leadData.customerData?.address?.zip
                          : `Not Provided`
                      }
                    />
                  ) : (
                    ``
                  )}

                  <CardField
                    title="Created At"
                    // value={utcToPst(leadData?.createdAt)}
                    value={dateFormat(leadData?.createdAt)}
                  />
                  {leadData._turboRaterId ? (
                    <CardField
                      title="Turbo Rater Id"
                      value={leadData._turboRaterId}
                    />
                  ) : (
                    ``
                  )}
                  {leadData.turboRaterUrl ? (
                    <CardField
                      title="Turbo Rater Url"
                      value={leadData.turboRaterUrl}
                      link={true}
                    />
                  ) : (
                    ``
                  )}
                  <CardField
                    title="Target Amount"
                    value={`$${leadData?.targetAmount}`}
                  />
                  <CardField
                    title="Monthly Budget"
                    value={`$${leadData?.monthlyBudget}`}
                  />
                </div>
                <div className="flex-1">
                  {leadData?.status ? (
                    <CardField title="Status" value={leadData?.status} />
                  ) : (
                    ``
                  )}
                  {leadData?.metadata?.source ? (
                    <CardField
                      title="Source"
                      value={leadData?.metadata?.source}
                    />
                  ) : (
                    ``
                  )}
                  {leadData.customerData?.lastName ? (
                    <CardField
                      title="Last Name"
                      value={leadData.customerData?.lastName}
                    />
                  ) : (
                    ``
                  )}
                  {leadData.customerData?.contactInfo?.phone ? (
                    <CardField
                      title="Phone"
                      value={leadData.customerData?.contactInfo?.phone}
                    />
                  ) : (
                    ``
                  )}
                  {leadData.customerData?.contactInfo?.email ? (
                    <CardField
                      title="Email"
                      value={leadData.customerData?.contactInfo?.email}
                    />
                  ) : (
                    ``
                  )}
                  {leadData.customerData?.designation ? (
                    <CardField
                      title="Designation"
                      value={leadData.customerData?.designation}
                    />
                  ) : (
                    ``
                  )}
                  {leadData?.quoteOffers.length ? (
                    <CardField
                      title="Quote Offers"
                      value={leadData?.quoteOffers}
                      multiple={true}
                    />
                  ) : (
                    ``
                  )}
                  {leadData?.preferredQuote != 0 ? (
                    <CardField
                      title="Preferred Quote"
                      value={`$${leadData?.preferredQuote}`}
                      // multiple={true}
                    />
                  ) : (
                    ``
                  )}

                  <CardField
                    title="Verification"
                    value={leadData?.verificationStatus}
                  />
                  <CardField title="LOB" value={leadData?.lob} />
                  <CardField
                    title="Contact Method"
                    value={leadData.customerData?.preferredContactMethods}
                    multiple={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {leadData?.drivers?.length > 0 && (
            <div className="pb-1">
              <Collapsable title="Drivers">
                <Tabs2
                  openTab={activeDriverTab}
                  tabs={driversTabs}
                  getActiveTab={(val) => setActiveDriverTab(val)}
                />
                {leadData?.drivers?.map((driver, index) => (
                  <FormTab
                    key={index}
                    currenttab={index + 1}
                    opentab={activeDriverTab}
                    className="mt-2 shadow-sm rounded-md relative"
                  >
                    <CardField title="Gender" value={driver?.gender} />
                    {driver?.education?.educationLevel ? (
                      <CardField
                        title="Education"
                        value={driver?.education?.educationLevel}
                      />
                    ) : (
                      ``
                    )}
                    {driver?.employment?.isEmployed ? (
                      <CardField
                        title="Employed"
                        value={driver?.employment?.isEmployed?.toString()}
                      />
                    ) : (
                      ``
                    )}
                    {driver?.relation ? (
                      <CardField title="Relation" value={driver?.relation} />
                    ) : (
                      ``
                    )}
                    <CardField
                      title="Date of Birth"
                      value={utcToPst(driver?.dob)}
                    />
                  </FormTab>
                ))}
              </Collapsable>
            </div>
          )}

          {leadData?.cars?.length > 0 && (
            <div className="pb-1">
              <Collapsable title="Vehicles">
                <Tabs2
                  openTab={activeVehicleTab}
                  tabs={vehiclesTabs}
                  getActiveTab={(val) => setActiveVehicleTab(val)}
                />
                {leadData.cars?.map((vehicle, index) => (
                  <FormTab
                    key={index}
                    currenttab={index + 1}
                    opentab={activeVehicleTab}
                    className="mt-2 shadow-sm rounded-md relative"
                  >
                    {vehicle?.make ? (
                      <CardField title="Make" value={vehicle?.make} />
                    ) : (
                      ``
                    )}
                    <CardField title="Model" value={vehicle?.model} />
                    <CardField title="Year" value={vehicle?.year} />
                    {vehicle?.vin ? (
                      <CardField title="Vin" value={vehicle?.vin} />
                    ) : (
                      ``
                    )}
                    {vehicle?.usage.annualMileage ? (
                      <CardField
                        title="Daily Mileage"
                        value={vehicle?.usage.annualMileage + ` Miles`}
                      />
                    ) : (
                      ``
                    )}
                    {vehicle?.coverages.length ? (
                      <CardField
                        title="Additional Coverages"
                        value={vehicle?.coverages?.map(
                          (cover) => cover.name + ` - ` + cover.deductible,
                        )}
                        multiple={true}
                      />
                    ) : (
                      ``
                    )}
                  </FormTab>
                ))}
              </Collapsable>
            </div>
          )}
        </div>
      )}
    </Container>
  )
}
