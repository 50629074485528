/* eslint-disable no-unused-vars */
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AddFormButton, RemoveDriver } from '../style'
import { FormTab } from 'App/Styled'
import {
  loadMaritalStatus,
  loadGender,
  loadRelationship,
  loadForeignLicensed,
  loadReasonFilling,
  loadBinaryOptions,
  loadEmploymentStatus,
  loadEducationLevel,
  loadPriorLiabilityLimits,
  loadPriorTransferLevel,
  loadDriverType,
  loadCarriers,
} from 'App/Services/quoteForm'

import { AQS_BACKEND_API, AxiosInstance } from 'App/Config'
import moment from 'moment'
import { InputFieldRef } from 'App/Components/Common/InputFieldRef'
import { MaskFieldRef } from 'App/Components/Common/MaskFieldRef'
import { useSelector } from 'react-redux'
import cloneDeep from 'clone-deep'
import { InputField } from 'App/Components/Common/InputField'
import { setDriversInfo, setDriversInsured } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'
import { MaskField } from 'App/Components/Common/MaskField'

export const DriverForm = forwardRef(
  ({ getData, selectedState, carrierQuestion }, ref) => {
    const { firstDriverInfo, supportedViolations } = useSelector(
      ({ quoteForm }) => quoteForm,
    )
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({ formSubmit: false })
    const [selectedStateViolation, setSelectedStateViolation] = useState(``)
    const [activeDriverTab, setActiveDriverTab] = useState(1)
    const [activeViolationTab, setActiveViolationTab] = useState(0)
    const { leadData } = useSelector(({ lead }) => lead)
    const [rerateQuoteData, setRerateQuoteData] = useState(``)

    const driverFirstNameRef = useRef([])
    const driverMiddleNameRef = useRef([])
    const driverLastNameRef = useRef([])
    const driverMilesToWorkRef = useRef([])
    const driverLisceneNoRef = useRef([])

    const defaultOptions = {
      yes: { value: `YES`, label: `YES` },
      no: { value: `NO`, label: `NO` },
    }

    const [drivers, setDrivers] = useState([
      {
        isExcluded: ``,
        isExcludedError: ``,
        driverType: { value: `Rated`, label: `Rated` },
        dob: ``,
        gender: ``,
        maritalStatus: ``,
        isCivilUnion: defaultOptions.no,
        relation: ``,
        violations: [],
        priorInsurance: {
          wasInsured: ``,
          durationInMonths: ``,
          // daysLapsed: ``,
          expiryDate: ``,
          priorInAgency: ``,
          carrier: ``,
          liabilityLimits: ``,
          transferLevel: ``,
        },
        sr22: {
          isFiled: defaultOptions.no,
          reason: ``,
          filingDate: ``,
          caseNo: ``,
        },
        employment: { employmentStatus: ``, phone: `` },
        propertyInsurance: defaultOptions.no,
        milesToWorkError: ``,

        caseNoError: ``,
        occasionalOperator: defaultOptions.no,
        singleParent: defaultOptions.no,
        firstNameError: ``,
        lastNameError: ``,
        dobError: ``,
        genderError: ``,
        maritalStatusError: ``,
        relationError: ``,
        licensedError: ``,
        wasInsureError: ``,
        expireDateError: ``,
        timeSinceError: ``,
        phoneError: ``,
        ssn: ``,
        ssnError: ``,
        vTabs: [],
        license: {
          isLicensed: defaultOptions.yes,
          isSuspended: defaultOptions.no,
          suspendedMonths: ``,
          usLicensedMonths: ``,
          stateLicensedMonths: ``,
          usMVRExperienceMonths: ``,
          isForeignLicensed: defaultOptions.no,
          foreignLicenseCountry: ``,
          foreignLicensedMonths: ``,
        },
        education: {
          educationLevel: {
            value: `HIGH SCHOOL DIPLOMA`,
            label: `High School Diploma`,
          },
          driverTraining: defaultOptions.no,
          defensiveDriving: defaultOptions.no,
          drugAwareness: defaultOptions.no,
        },
      },
    ])

    const addNewDriver = {
      isExcluded: ``,
      isExcludedError: ``,
      driverType: { value: `Rated`, label: `Rated` },
      dob: ``,
      gender: ``,
      maritalStatus: ``,
      isCivilUnion: defaultOptions.no,
      relation: ``,

      license: {
        isLicensed: defaultOptions.yes,
        isSuspended: defaultOptions.no,
        suspendedMonths: ``,
        usLicensedMonths: ``,
        stateLicensedMonths: ``,
        usMVRExperienceMonths: ``,
        isForeignLicensed: defaultOptions.no,
        foreignLicenseCountry: ``,
        foreignLicensedMonths: ``,
      },

      priorInsurance: {
        wasInsured: ``,
        durationInMonths: ``,
        // daysLapsed: ``,
        expiryDate: ``,
        priorInAgency: ``,
        carrier: ``,
        liabilityLimits: ``,
        transferLevel: ``,
      },
      sr22: {
        isFiled: defaultOptions.no,
        reason: ``,
        filingDate: ``,
        caseNo: ``,
      },
      education: {
        educationLevel: {
          value: `HIGH SCHOOL DIPLOMA`,
          label: `High School Diploma`,
        },
        driverTraining: defaultOptions.no,
        defensiveDriving: defaultOptions.no,
        drugAwareness: defaultOptions.no,
      },
      employment: { employmentStatus: ``, phone: `` },
      violations: [],
      propertyInsurance: defaultOptions.no,
      milesToWorkError: ``,

      caseNoError: ``,
      occasionalOperator: defaultOptions.no,
      singleParent: defaultOptions.no,
      firstNameError: ``,
      lastNameError: ``,
      dobError: ``,
      genderError: ``,
      maritalStatusError: ``,
      relationError: ``,
      licensedError: ``,
      wasInsureError: ``,
      expireDateError: ``,
      timeSinceError: ``,
      phoneError: ``,
      ssnError: ``,
      ssn: ``,
      vTabs: [],
      carrierQuestion: carrierQuestion,
    }

    const leadSchema = {
      BACHELORS: `BACHELORS DEGREE`,
      MASTERS: `MASTER DEGREE`,
      'HIGH SCHOOL': `HIGH SCHOOL DIPLOMA`,
      NONBINARY: `OTHER`,
    }

    const [dTabs, setDTabs] = useState([
      {
        tName: `Driver 1`,
        tNumber: 1,
        permission: [`quotes:create`],
      },
    ])

    const handleDriverChange = useDebouncedCallback(
      (name, value, ind, nestedAttributes, vind, violat) => {
        const newDriver = drivers?.map((driver, index) => {
          if (index === ind) {
            if (violat) {
              driver.violations.map((violation, idx) => {
                if (idx === vind) {
                  violation[name] = value
                }
                return violation
              })
            }
            if (nestedAttributes === `prior`) {
              driver.priorInsurance[name] = value
            } else if (nestedAttributes === `license`) {
              driver.license[name] = value
            } else if (nestedAttributes === `education`) {
              driver.education[name] = value
            } else if (nestedAttributes === `employment`) {
              driver.employment[name] = value
            } else if (nestedAttributes === `sr22`) {
              driver.sr22[name] = value
            } else if (name === `dob` && value) {
              driver.license.usLicensedMonths = new Date(
                moment(value).add(16, `years`),
              )
              driver.license.stateLicensedMonths = new Date(
                moment(value).add(16, `years`),
              )
              driver.license.usMVRExperienceMonths = new Date(
                moment(value).add(16, `years`),
              )
              driver[name] = value
            } else {
              driver[name] = value
            }
          }

          return driver
        })
        setDrivers(newDriver)
      },
      30,
    )

    const handleRemoveViolation = (vind, Windex) => {
      const newDrivers = drivers?.map((driver, index) => {
        if (index === Windex) {
          if (driver.length != 1) {
            if (driver.violations.length != 0) {
              driver.violations.splice(vind, 1)
              driver.vTabs.pop()
              setActiveViolationTab(driver.vTabs.length)
            }
          }
        }
        return driver
      })
      setDrivers(newDrivers)
    }

    const removeReferenceFields = (ind) => {
      // Remove Instance of Ref

      driverFirstNameRef.current.forEach((item, index) => {
        if (index >= ind && driverFirstNameRef.current[index + 1]) {
          driverFirstNameRef.current[index].value =
            driverFirstNameRef.current[index + 1]?.value
        }
      })

      driverMiddleNameRef.current.forEach((item, index) => {
        if (index >= ind && driverMiddleNameRef.current[index + 1]) {
          driverMiddleNameRef.current[index].value =
            driverMiddleNameRef.current[index + 1]?.value
        }
      })
      driverLastNameRef.current.forEach((item, index) => {
        if (index >= ind && driverLastNameRef.current[index + 1]) {
          driverLastNameRef.current[index].value =
            driverLastNameRef.current[index + 1]?.value
        }
      })

      driverMilesToWorkRef.current.forEach((item, index) => {
        if (index >= ind && driverMilesToWorkRef.current[index + 1]) {
          driverMilesToWorkRef.current[index].value =
            driverMilesToWorkRef.current[index + 1]?.value
        }
      })
      driverLisceneNoRef.current.forEach((item, index) => {
        if (index >= ind && driverLisceneNoRef?.current[index + 1]) {
          driverLisceneNoRef.current[index].value =
            driverLisceneNoRef?.current[index + 1]?.value
        }
      })
    }

    useEffect(() => {
      driverMilesToWorkRef?.current.forEach((node) => {
        if (node && node.value === ``) node.value = 1
      })
    }, [drivers?.length])

    const handleRemoveDriver = (ind) => {
      // Delete Input Refs from ref array
      removeReferenceFields(ind)
      const newDrivers = [...drivers]
      if (newDrivers.length !== 1) {
        newDrivers.splice(ind, 1)
        setDrivers(newDrivers)
      }
      const newDriversTab = [...dTabs]
      if (newDriversTab.length !== 1) {
        newDriversTab.pop()
        setDTabs(newDriversTab)
      }
    }

    const addDriver = () => {
      if (drivers?.length < 6) {
        setDTabs([
          ...dTabs,
          {
            tName: `Driver ${dTabs.length + 1}`,
            tNumber: dTabs.length + 1,
            permission: [`quotes:create`],
          },
        ])
        setDrivers([...drivers, addNewDriver])
      }
    }

    const addViolations = (index) => {
      const newDriver = drivers?.map((driver, ind) => {
        if (index === ind) {
          driver?.vTabs.push({
            tName: `Violations ${driver.vTabs.length + 1}`,
            tNumber: driver.vTabs.length + 1,
            permission: [`quotes:create`],
          })
          setActiveViolationTab(driver?.vTabs.length)
          driver?.violations.push({
            violationCode: ``,
            violationDate: ``,
            convictionDate: ``,
            violationCodeError: ``,
            convictionDateError: ``,
            violationDateError: ``,
          })
        }
        return driver
      })
      setDrivers(newDriver)
    }

    const handleErrors = (ind, name, val, vindex, vError) => {
      const newDriver = drivers?.map((driver, index) => {
        if (index == ind) {
          if (vError) {
            driver?.violations?.forEach((violation, idx) => {
              if (idx == vindex) {
                violation[name] = val
              }
            })
          } else {
            driver[name] = val
          }
        }
        return driver
      })
      setDrivers(newDriver)
    }

    const loadViolationsCode = async (search) => {
      if (supportedViolations) {
        const formattedModel = supportedViolations.map((make) => {
          return {
            value: make.value,
            label: `${make.name} (${make.value})`,
          }
        })

        const filteredData = formattedModel.filter(
          (item) => item.value.includes(search) || item.label.includes(search),
        )
        return {
          options: search ? filteredData : formattedModel,
          hasMore: false,
        }
      }
    }

    useImperativeHandle(ref, () => ({
      validateForm() {
        for (const index in drivers) {
          const driver = drivers[index]
          if (!driver.isExcluded?.value?.length) {
            handleErrors(index, `isExcludedError`, `Select Excluded`)
          } else {
            handleErrors(index, `isExcludedError`, ``)
          }
          if (!driverFirstNameRef?.current[index]?.value?.length) {
            handleErrors(index, `firstNameError`, `Enter First Name`)
          } else {
            handleErrors(index, `firstNameError`, ``)
          }
          if (!driverLastNameRef?.current[index]?.value?.length) {
            handleErrors(index, `lastNameError`, `Enter Last Name`)
          } else {
            handleErrors(index, `lastNameError`, ``)
          }
          if (!driver?.dob) {
            handleErrors(index, `dobError`, `Enter DOB`)
          } else {
            handleErrors(index, `dobError`, ``)
          }
          if (!driver?.gender?.value?.length) {
            handleErrors(index, `genderError`, `Select Gender`)
          } else {
            handleErrors(index, `genderError`, ``)
          }
          if (!driver?.maritalStatus?.value?.length) {
            handleErrors(index, `maritalStatusError`, `Select Marital Status`)
          } else {
            handleErrors(index, `maritalStatusError`, ``)
          }
          if (!driver?.relation?.value?.length) {
            handleErrors(index, `relationError`, `Select RelationShip`)
          } else {
            handleErrors(index, `relationError`, ``)
          }
          if (!driver?.license?.isLicensed?.value?.length) {
            handleErrors(index, `licensedError`, `Select Licensed`)
          } else {
            handleErrors(index, `licensedError`, ``)
          }

          if (
            driver?.employment?.phone?.length &&
            driver?.employment?.phone?.length < 12
          ) {
            handleErrors(index, `phoneError`, `Enter Valid Phone`)
          } else {
            handleErrors(index, `phoneError`, ``)
          }

          if (driverMilesToWorkRef?.current[index]?.value.length) {
            if (JSON.parse(driverMilesToWorkRef?.current[index]?.value) < 0) {
              handleErrors(index, `milesToWorkError`, `Add At least 1`)
            } else if (
              JSON.parse(driverMilesToWorkRef?.current[index]?.value) > 1000
            ) {
              handleErrors(
                index,
                `milesToWorkError`,
                `Should be less than 1000`,
              )
            } else {
              handleErrors(index, `milesToWorkError`, ``)
            }
          }

          if (driver?.sr22?.caseNo?.length) {
            if (parseInt(driver?.sr22?.caseNo) < 1) {
              handleErrors(index, `caseNoError`, `Should be More than 0`)
            } else {
              handleErrors(index, `caseNoError`, ``)
            }
          } else {
            handleErrors(index, `caseNoError`, ``)
          }

          if (driver?.ssn?.length && driver?.ssn?.length < 11) {
            handleErrors(index, `ssnError`, `Enter Valid SSS`)
          } else {
            handleErrors(index, `ssnError`, ``)
          }

          if (driver?.violations?.length > 0) {
            for (const vindex in driver?.violations) {
              const violation = driver?.violations[vindex]

              if (!violation.violationCode?.value?.length) {
                handleErrors(
                  index,
                  `violationCodeError`,
                  `Select Code`,
                  vindex,
                  `vError`,
                )
              } else {
                handleErrors(index, `violationCodeError`, ``, vindex, `vError`)
              }
              if (!violation.convictionDate) {
                handleErrors(
                  index,
                  `convictionDateError`,
                  `Select Date`,
                  vindex,
                  `vError`,
                )
              } else {
                handleErrors(index, `convictionDateError`, ``, vindex, `vError`)
              }
              if (!violation.violationDate) {
                handleErrors(
                  index,
                  `violationDateError`,
                  `Select Date`,
                  vindex,
                  `vError`,
                )
              } else {
                handleErrors(index, `violationDateError`, ``, vindex, `vError`)
              }
            }
          }
          if (!driver?.priorInsurance?.wasInsured?.value?.length) {
            handleErrors(index, `wasInsureError`, `Select Prior Insurance`)
          } else {
            handleErrors(index, `wasInsureError`, ``)
          }

          if (
            driver?.priorInsurance?.wasInsured?.value === `YES` &&
            !driver?.priorInsurance?.expiryDate
          ) {
            handleErrors(index, `expireDateError`, `Select Expire Date`)
          } else {
            handleErrors(index, `expireDateError`, ``)
          }

          if (
            driver?.priorInsurance?.wasInsured?.value === `YES` &&
            !driver?.priorInsurance?.durationInMonths
          ) {
            handleErrors(index, `timeSinceError`, `Select Time Since`)
          } else {
            handleErrors(index, `timeSinceError`, ``)
          }
        }
        setErrors((preState) => ({
          ...preState,
          [`formSubmit`]: true,
        }))
      },
      updateFormValues(data) {
        setRerateQuoteData(data)
        const originalDriversObject = JSON.parse(JSON.stringify(drivers[0]))
        setDTabs(
          data.map((d, index) => {
            return {
              tName: `Driver ${index + 1}`,
              tNumber: index + 1,
              permission: [`quotes:create`],
            }
          }),
        )
        const updatedDrivers = data.map((el, index) => {
          if (driverMilesToWorkRef.current[index]) {
            driverMilesToWorkRef.current[index].value = el?.milesToWork ?? ``
          }
          if (driverLisceneNoRef.current[index]) {
            driverLisceneNoRef.current[index].value =
              el.license?.licenseNo ?? ``
          }

          return {
            ...originalDriversObject,
            ...el,
            isExcluded: { label: el.isExcluded, value: el.isExcluded },
            driverType: el?.driverType
              ? { label: el.driverType, value: el.driverType }
              : { label: `RATED`, value: `RATED` },
            gender: { label: el.gender, value: el.gender },
            maritalStatus: { label: el.maritalStatus, value: el.maritalStatus },
            isCivilUnion: { label: el.isCivilUnion, value: el.isCivilUnion },
            relation: { label: el.relation, value: el.relation },
            license: {
              ...el.license,
              licenseNo: el?.license?.licenseNo,
              isLicensed: {
                label: el.license?.isLicensed ?? ``,
                value: el.license?.isLicensed ?? ``,
              },
              isForeignLicensed: {
                label: el.license?.isForeignLicensed ?? ``,
                value: el.license?.isForeignLicensed ?? ``,
              },
              foreignLicenseCountry: {
                label: el.license?.foreignLicenseCountry ?? `none`,
                value: el.license?.foreignLicenseCountry ?? `none`,
              },
              foreignLicensedMonths: el.license?.foreignLicensedMonths ?? ``,
            },
            education: {
              ...el.education,
              educationLevel: {
                value: el.education.educationLevel,
                label: el.education.educationLevel,
              },
              driverTraining: {
                value: el.education.driverTraining,
                label: el.education.driverTraining,
              },
              defensiveDriving: {
                value: el.education.defensiveDriving,
                label: el.education.defensiveDriving,
              },
              drugAwareness: {
                value: el.education.drugAwareness,
                label: el.education.drugAwareness,
              },
            },
            propertyInsurance: {
              label: el.hasPropertyInsurance,
              value: el.hasPropertyInsurance,
            },
            singleParent: {
              label: el.isSingleParent,
              value: el.isSingleParent,
            },
            priorInsurance: {
              ...el.priorInsurance,
              transferLevel: {
                label: el.priorInsurance.transferLevel,
                value: el.priorInsurance.transferLevel,
              },
              carrier: {
                label: el.priorInsurance.carrier,
                value: el.priorInsurance.carrier,
              },
              wasInsured: {
                label: el.priorInsurance.wasInsured,
                value: el.priorInsurance.wasInsured,
              },
              priorInAgency: {
                label: el.priorInsurance.priorInAgency,
                value: el.priorInsurance.priorInAgency,
              },
              liabilityLimits: {
                label: el.priorInsurance.liabilityLimits,
                value: el.priorInsurance.liabilityLimits,
              },
            },
            sr22: {
              ...el.sr22,
              isFiled: {
                label: el.sr22.isFiled,
                value: el.sr22.isFiled,
              },
              reason: {
                label: el.sr22.reason,
                value: el.sr22.reason,
              },
            },
            employment: {
              ...el.employment,
              employmentStatus: {
                label: el.employment.employmentStatus,
                value: el.employment.employmentStatus,
              },
            },
            violations: el.violations.map((violation) => ({
              ...violation,
              violationCode: {
                label: violation.code,
                value: violation.code,
              },
            })),
            vTabs: el.violations.map((violation, index) => ({
              tName: `Violations ${index + 1}`,
              tNumber: index + 1,
              permission: [`quotes:create`],
            })),
          }
        })
        setDrivers(updatedDrivers)
      },
    }))
    useEffect(() => {
      if (rerateQuoteData) {
        rerateQuoteData?.forEach((el, index) => {
          if (driverLastNameRef.current[index]) {
            driverLastNameRef.current[index].value = el?.lastName ?? ``
          }
          if (driverFirstNameRef.current[index]) {
            driverFirstNameRef.current[index].value = el?.firstName ?? ``
          }
          if (driverMiddleNameRef.current[index]) {
            driverMiddleNameRef.current[index].value = el?.middleName ?? ``
          }
        })
      }
    }, [
      driverLastNameRef,
      driverMiddleNameRef,
      driverFirstNameRef,
      rerateQuoteData,
    ])
    const convertDate = (date) => {
      const currentDate = new Date()
      let a = moment(date)
      let b = moment(currentDate)
      const xYear = b.diff(a, `years`)
      const xMonth = b.diff(a, `months`) - xYear * 12

      const diff = xYear * 12 + xMonth
      return diff
    }

    useEffect(() => {
      let driversValidated = []
      let violationsValidated = []
      driversValidated = drivers?.map((driver) => {
        if (
          errors.formSubmit &&
          !driver?.firstNameError.length &&
          !driver?.lastNameError.length &&
          !driver?.dobError.length &&
          !driver?.genderError.length &&
          !driver?.maritalStatusError.length &&
          !driver?.relationError.length &&
          !driver?.licensedError.length &&
          !driver?.wasInsureError.length &&
          !driver?.expireDateError.length &&
          !driver?.timeSinceError.length &&
          !driver?.phoneError.length &&
          !driver?.ssnError.length &&
          !driver?.caseNoError.length &&
          !driver?.milesToWorkError.length
        ) {
          // driversValidated = true
          if (driver?.violations.length > 0) {
            violationsValidated = driver.violations?.map((violation) => {
              if (
                !violation.violationCodeError.length &&
                !violation.convictionDateError.length &&
                !violation.violationDateError.length
              ) {
                // violationsValidated = true

                return true
              }
            })
          }

          return true
        }
      })

      if (errors.formSubmit) {
        if (
          !driversValidated?.includes(undefined) &&
          !violationsValidated?.includes(undefined)
        ) {
          getData(`drivers`, prepareDriversPayload())
          dispatch(setDriversInsured(prepareDriversPayload()))
          setErrors((preState) => ({
            ...preState,
            [`formSubmit`]: false,
          }))
        } else {
          getData(`drivers`, null)
          setErrors((preState) => ({
            ...preState,
            [`formSubmit`]: false,
          }))
        }
      }
    }, [errors])

    const prepareDriversPayload = () => {
      if (drivers?.length > 0) {
        return drivers?.map((driver, index) => {
          let payload = {
            violations: [],
            priorInsurance: {},
            sr22: {},
            employment: { employer: {} },
            license: {},
            education: {},
          }

          if (driver?.isExcluded?.value?.length) {
            payload.isExcluded = driver?.isExcluded?.value
          }

          if (driverFirstNameRef?.current[index]?.value?.length) {
            payload.firstName = driverFirstNameRef?.current[index]?.value
          }
          if (driverMiddleNameRef?.current[index]?.value?.length) {
            payload.middleName = driverMiddleNameRef?.current[index]?.value
          }
          if (driverLastNameRef?.current[index]?.value?.length) {
            payload.lastName = driverLastNameRef?.current[index]?.value
          }
          if (driver?.relation?.value?.length) {
            payload.relation = driver?.relation?.value
          }
          if (driver?.dob) {
            payload.dob = driver?.dob
          }
          if (driver?.ssn?.length) {
            payload.ssn = driver?.ssn?.value
          }
          if (driver?.gender?.value?.length) {
            payload.gender = driver?.gender?.value
          }
          if (driver?.maritalStatus?.value?.length) {
            payload.maritalStatus = driver?.maritalStatus?.value
          }
          if (driver?.isCivilUnion?.value?.length) {
            payload.isCivilUnion = driver?.isCivilUnion?.value
          }
          if (driver?.license?.isLicensed?.value?.length) {
            payload.license.isLicensed = driver?.license?.isLicensed?.value
          }

          payload.questions = driver?.carrierQuestion

          if (driver?.license?.isLicensed?.value === `YES`) {
            if (driverLisceneNoRef?.current[index]?.value?.length) {
              payload.license.licenseNo =
                driverLisceneNoRef?.current[index]?.value
            }
            if (driver?.license?.isSuspended?.length) {
              payload.license.isSuspended = driver?.license?.isSuspended
            }
            if (driver?.license?.suspendedMonths) {
              payload.license.suspendedMonths = convertDate(
                driver?.license?.suspendedMonths,
              )
            }
            if (driver?.license?.usLicensedMonths) {
              payload.license.usLicensedMonths = convertDate(
                driver?.license?.usLicensedMonths,
              )
            }
            if (driver?.license?.stateLicensedMonths) {
              payload.license.stateLicensedMonths = convertDate(
                driver?.license?.stateLicensedMonths,
              )
            }
            if (driver?.license?.usMVRExperienceMonths) {
              payload.license.usMVRExperienceMonths = convertDate(
                driver?.license?.usMVRExperienceMonths,
              )
            }
            if (driver?.license?.foreignLicensedMonths) {
              payload.license.foreignLicensedMonths = convertDate(
                driver?.license?.foreignLicensedMonths,
              )
            }
            if (driver?.license?.foreignLicenseCountry?.length) {
              payload.license.foreignLicenseCountry =
                driver?.license?.foreignLicenseCountry
            }
            if (driver?.license?.isForeignLicensed?.value?.length) {
              payload.license.isForeignLicensed =
                driver?.license?.isForeignLicensed?.value
            }
          }
          if (driver?.priorInsurance?.wasInsured?.value?.length) {
            payload.priorInsurance.wasInsured =
              driver?.priorInsurance?.wasInsured?.value
          }
          if (driver?.priorInsurance?.wasInsured?.value === `YES`) {
            if (driver?.priorInsurance?.durationInMonths) {
              payload.priorInsurance.durationInMonths = convertDate(
                driver?.priorInsurance?.durationInMonths,
              )
            }
            // if (driver?.priorInsurance?.daysLapsed) {
            //   payload.priorInsurance.daysLapsed =
            //     driver?.priorInsurance?.daysLapsed
            // }
            if (driver?.priorInsurance?.expiryDate) {
              payload.priorInsurance.expiryDate =
                driver?.priorInsurance?.expiryDate
            }
            if (driver?.priorInsurance?.priorInAgency?.value?.length) {
              payload.priorInsurance.priorInAgency =
                driver?.priorInsurance?.priorInAgency?.value
            }
            if (driver?.priorInsurance?.carrier?.value?.length) {
              payload.priorInsurance.carrier =
                driver?.priorInsurance?.carrier?.value
            }
            if (driver?.priorInsurance?.liabilityLimits?.value?.length) {
              payload.priorInsurance.liabilityLimits =
                driver?.priorInsurance?.liabilityLimits?.value
            }
            if (driver?.priorInsurance?.transferLevel?.value?.length) {
              payload.priorInsurance.transferLevel =
                driver?.priorInsurance?.transferLevel?.value
            }
          }
          if (driver?.sr22?.isFiled?.value?.length) {
            payload.sr22.isFiled = driver?.sr22?.isFiled?.value
          }
          if (driver?.sr22?.isFiled?.value === `YES`) {
            payload.sr22.filingState = selectedState

            if (driver?.sr22?.reason?.value?.length) {
              payload.sr22.reason = driver?.sr22?.reason?.value
            }
            if (driver?.sr22?.caseNo?.length) {
              payload.sr22.caseNo = driver?.sr22?.caseNo
            }
            if (driver?.sr22?.filingDate) {
              payload.sr22.filingDate = driver?.sr22?.filingDate
            }
          }
          if (driver?.education?.educationLevel?.value?.length) {
            payload.education.educationLevel =
              driver?.education?.educationLevel?.value
          }

          if (driver?.education?.driverTraining?.value?.length) {
            payload.education.driverTraining =
              driver?.education?.driverTraining?.value
          }

          if (driver?.education?.defensiveDriving?.value?.length) {
            payload.education.defensiveDriving =
              driver?.education?.defensiveDriving?.value
          }

          if (driver?.education?.drugAwareness?.value?.length) {
            payload.education.drugAwareness =
              driver?.education?.drugAwareness?.value
          }

          if (driver?.employment?.employmentStatus?.value?.length) {
            payload.employment.employmentStatus =
              driver?.employment?.employmentStatus?.value
          }
          if (driver?.employment?.phone?.length) {
            payload.employment.employer.phone = driver?.employment?.phone
          }
          if (driverMilesToWorkRef?.current[index]?.value) {
            payload.milesToWork = driverMilesToWorkRef?.current[index]?.value
          }
          if (driver?.occasionalOperator?.value?.length) {
            payload.isOccasionalDriver = driver?.occasionalOperator?.value
          }
          if (driver?.singleParent?.value?.length) {
            payload.isSingleParent = driver?.singleParent?.value
          }
          if (driver?.propertyInsurance?.value?.length) {
            payload.hasPropertyInsurance = driver?.propertyInsurance?.value
          }

          if (driver?.violations?.length > 0) {
            payload.violations = driver?.violations.map((violation) => {
              let violationPayload = {}
              if (violation?.violationCode?.value?.length) {
                violationPayload.code = violation?.violationCode?.value
              }
              if (violation?.violationDate) {
                violationPayload.violationDate = violation?.violationDate
              }
              if (violation?.convictionDate) {
                violationPayload.convictionDate = violation?.convictionDate
              }
              return violationPayload
            })
          }
          return payload
        })
      }
    }
    const setCarrierQuestionDefaultValues = () => {
      const newDriver = drivers.map((driver) => {
        const newCarrierQuestions = {}
        for (let cQuestion in carrierQuestion) {
          newCarrierQuestions[cQuestion] = carrierQuestion[cQuestion].map(
            (item) => ({
              question: item.question,
              FieldName: item.name,
              options: item.options,
              selectedValue: item.default
                ? { value: item.default, label: item.default }
                : new Date(),
              mask: item.mask ?? ``,
              default: item.default ?? ``,
              scope: item.scope,
            }),
          )
        }
        driver.carrierQuestion = newCarrierQuestions
        return driver
      })
      setDrivers(newDriver)
    }
    useEffect(() => {
      setCarrierQuestionDefaultValues()
    }, [carrierQuestion])

    useEffect(() => {
      const newDriver = drivers?.map((driver) => {
        const newCarrierQuestions = {}
        for (let cQuestion in driver?.carrierQuestion) {
          newCarrierQuestions[cQuestion] = driver?.carrierQuestion[
            cQuestion
          ].map((item) => ({
            question: item.question,
            FieldName: item.name,
            options: item.options,
            selectedValue: item?.selectedValue
              ? item?.selectedValue
              : item.default
              ? { value: item.default, label: item.default }
              : new Date(),
            mask: item.mask ?? ``,
            default: item.default ?? ``,
            scope: item.scope,
          }))
        }
        driver.carrierQuestion = newCarrierQuestions
        return driver
      })
      setDrivers(newDriver)
    }, [drivers?.length])

    const handleQuestionChange = (value, index, carrier, q, dindex) => {
      const newDriver = drivers.map((driver, ind) => {
        if (dindex === ind) {
          const newCarrierQuestions = {}
          for (let cQuestion in driver?.carrierQuestion) {
            newCarrierQuestions[cQuestion] = driver?.carrierQuestion[
              cQuestion
            ].map((item, qind) => {
              if (index === qind && carrier === cQuestion) {
                return {
                  ...item,
                  selectedValue:
                    q === item.question && typeof value === `string`
                      ? { value: value }
                      : value,
                }
              } else return item
            })
          }
          driver.carrierQuestion = newCarrierQuestions
        }

        return driver
      })
      setDrivers(newDriver)
    }

    useEffect(() => {
      dispatch(
        setDriversInfo(
          driverFirstNameRef.current.map((driver, index) => {
            if (driver) {
              return {
                value: index,
                label: driver?.value,
              }
            }
          }),
        ),
      )
    }, [
      driverFirstNameRef.current,
      driverFirstNameRef.current[driverFirstNameRef.current.length - 1]?.value,
      drivers?.length,
    ])

    useEffect(() => {
      setSelectedStateViolation(selectedState)
    }, [selectedState])

    useEffect(() => {
      drivers[0].relation = { value: `INSURED`, label: `INSURED` }
      drivers[0].isExcluded = { value: `NO`, label: `NO` }
    }, [])

    useEffect(() => {
      setActiveDriverTab(dTabs.length)
    }, [dTabs.length])

    useEffect(() => {
      // Set Name for first driver from customer info
      driverFirstNameRef.current[0].value = firstDriverInfo?.firstName
      driverLastNameRef.current[0].value = firstDriverInfo?.lastName
      driverMiddleNameRef.current[0].value = firstDriverInfo?.middleName
    }, [
      firstDriverInfo?.firstName,
      firstDriverInfo?.lastName,
      firstDriverInfo?.middleName,
    ])

    useEffect(() => {
      if (leadData) {
        let leadDrivers = leadData?.drivers
          ? [leadData.customerData, ...leadData?.drivers]
          : [leadData.customerData]
        if (leadDrivers?.length) {
          const preparedLeadDrivers = leadDrivers?.map((_driver, index) => {
            let driver = cloneDeep(addNewDriver)

            driver.gender = _driver?.gender
              ? {
                  value: leadSchema[_driver?.gender] ?? _driver.gender,
                  label: leadSchema[_driver?.gender] ?? _driver.gender,
                }
              : ``
            if (_driver?.education || _driver?.educationLevel) {
              driver.education.educationLevel = _driver.educationLevel
                ? {
                    value:
                      leadSchema[_driver.educationLevel] ??
                      _driver.educationLevel,
                    label:
                      leadSchema[_driver.educationLevel] ??
                      _driver.educationLevel,
                  }
                : {
                    value:
                      leadSchema[_driver.education?.educationLevel] ??
                      _driver.education?.educationLevel,
                    label:
                      leadSchema[_driver.education?.educationLevel] ??
                      _driver.education?.educationLevel,
                  }
            }
            driver.maritalStatus = _driver.maritalStatus
              ? {
                  value: _driver?.maritalStatus,
                  label: _driver?.maritalStatus,
                }
              : ``
            driver.dob = _driver?.dob ? new Date(_driver?.dob) : ``
            driver.license.usLicensedMonths = new Date(
              moment(_driver?.dob).add(16, `years`),
            )
            driver.license.stateLicensedMonths = new Date(
              moment(_driver?.dob).add(16, `years`),
            )
            driver.license.usMVRExperienceMonths = new Date(
              moment(_driver?.dob).add(16, `years`),
            )
            setTimeout(() => {
              driverFirstNameRef.current[index].value = _driver?.firstName
                ? _driver?.firstName
                : ``
              driverLastNameRef.current[index].value = _driver?.lastName
                ? _driver?.lastName
                : ``
              if (index === 0) {
                driver.relation = { value: `INSURED`, label: `INSURED` }
              }
            }, 100)
            const newCarrierQuestions = {}
            for (let cQuestion in carrierQuestion) {
              newCarrierQuestions[cQuestion] = carrierQuestion[cQuestion].map(
                (item) => ({
                  question: item.question,
                  FieldName: item.name,
                  options: item.options,
                  selectedValue: item.default
                    ? { value: item.default, label: item.default }
                    : new Date(),
                  mask: item.mask ?? ``,
                  default: item.default ?? ``,
                  scope: item.scope,
                }),
              )
            }
            driver.carrierQuestion = newCarrierQuestions

            return driver
          })

          setDTabs(() =>
            leadDrivers.map((item, index) => ({
              tName: `Driver ${index + 1}`,
              tNumber: index + 1,
            })),
          )
          setDrivers(preparedLeadDrivers)
          drivers[0].isExcluded = { value: `NO`, label: `NO` }
        }
      }
    }, [leadData])
    return (
      <div>
        <CollapsableRef title="Driver Information" isActive={true}>
          {/* tabs here */}
          <div className="flex items-center">
            <TabsHeaders
              openTab={activeDriverTab}
              tabs={dTabs}
              getActiveTab={(val) => setActiveDriverTab(val)}
              customTabs="YES"
            />
            <div className="px-2">
              <AddFormButton onClick={() => addDriver()}>
                <FontAwesomeIcon icon={faPlus} />
              </AddFormButton>
            </div>
          </div>
          {/* tabs end herer */}

          <div className="grid grid-cols-1 gap-10">
            {drivers?.length > 0 &&
              drivers?.map((driver, index) => (
                <FormTab
                  key={index}
                  currenttab={index + 1}
                  opentab={activeDriverTab}
                  className="border-2 border-gray-100 shadow-sm rounded-md relative"
                >
                  <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                    <div className="flex-1">Driver {index + 1}</div>
                    <div className="flex-1">
                      {index > 0 && (
                        <div className="float-right">
                          <RemoveDriver
                            onClick={() => {
                              handleRemoveDriver(index)
                              if (index > 0) setActiveDriverTab(index)
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                          </RemoveDriver>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-5 gap-x-6 gap-y-2 p-2">
                    <div className=" relative">
                      <ReactSelectField
                        title="Excluded *"
                        placeholder="Excluded"
                        isMulti={false}
                        value={driver?.isExcluded}
                        errorMessage={driver?.isExcludedError}
                        requiredValue={!driver.isExcluded?.value?.length}
                        setValue={(value) =>
                          handleDriverChange(`isExcluded`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                      />
                    </div>
                    <div className=" relative">
                      <ReactSelectField
                        title="Driver Type"
                        placeholder="Driver Type"
                        isMulti={false}
                        value={driver?.driverType}
                        setValue={(value) =>
                          handleDriverChange(`driverType`, value, index)
                        }
                        loadOptions={loadDriverType}
                        isSearchable={true}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputFieldRef
                        placeholder="First Name"
                        type="text"
                        title="First Name *"
                        requiredValue={
                          !driverFirstNameRef.current[index]?.value?.length
                        }
                        inputRef={(element) => {
                          driverFirstNameRef.current[index] = element
                        }}
                        errorMessage={driver?.firstNameError}
                        disabled={!index}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputFieldRef
                        placeholder="Middle Name"
                        type="text"
                        title="Middle Name"
                        inputRef={(element) => {
                          driverMiddleNameRef.current[index] = element
                        }}
                        disabled={!index}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputFieldRef
                        placeholder="Last Name"
                        type="text"
                        title="Last Name *"
                        errorMessage={driver?.lastNameError}
                        inputRef={(element) => {
                          driverLastNameRef.current[index] = element
                        }}
                        requiredValue={
                          !driverLastNameRef.current[index]?.value?.length
                        }
                        disabled={!index}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <DatePickerField
                        title="DOB *"
                        placeholder="DOB"
                        setValue={(e) => {
                          // if(e === null)
                          const date = e === null ? `` : e
                          handleDriverChange(`dob`, date, index)
                        }}
                        value={driver?.dob !== `` ? new Date(driver?.dob) : ``}
                        errorMessage={driver?.dobError}
                        maxDate={new Date(moment().subtract(16, `years`))}
                        requiredValue={!driver?.dob}
                      />
                    </div>
                    <div className=" relative">
                      <ReactSelectField
                        title="Gender *"
                        placeholder="Gender"
                        isMulti={false}
                        value={driver?.gender}
                        errorMessage={driver?.genderError}
                        requiredValue={!driver?.gender?.value?.length}
                        setValue={(value) =>
                          handleDriverChange(`gender`, value, index)
                        }
                        loadOptions={loadGender}
                        isSearchable={true}
                      />
                    </div>
                    <div className=" relative">
                      <ReactSelectField
                        title="Marital Status *"
                        placeholder="Marital Status"
                        isMulti={false}
                        value={driver?.maritalStatus}
                        errorMessage={driver?.maritalStatusError}
                        requiredValue={!driver?.maritalStatus?.value?.length}
                        setValue={(value) =>
                          handleDriverChange(`maritalStatus`, value, index)
                        }
                        loadOptions={loadMaritalStatus}
                        isSearchable={true}
                      />
                    </div>
                    <div className=" relative">
                      <ReactSelectField
                        title="Civil Union"
                        placeholder="Civil Union"
                        isMulti={false}
                        value={driver?.isCivilUnion}
                        setValue={(value) =>
                          handleDriverChange(`isCivilUnion`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className=" relative">
                      <ReactSelectField
                        title="Relationship *"
                        placeholder="Relationship"
                        isMulti={false}
                        value={driver?.relation}
                        errorMessage={driver?.relationError}
                        setValue={(value) =>
                          handleDriverChange(`relation`, value, index)
                        }
                        requiredValue={!driver?.relation?.value?.length}
                        loadOptions={loadRelationship}
                        isSearchable={true}
                        disabled={!index}
                      />
                    </div>

                    <div className="relative">
                      <MaskField
                        placeholder="SSN"
                        type="text"
                        title="SSN"
                        mask="999-99-9999"
                        setValue={(e) =>
                          handleDriverChange(`ssn`, e.target.value, index)
                        }
                        value={driver?.ssn}
                        errorMessage={driver?.ssnError}
                      />
                    </div>

                    <div className=" relative">
                      <ReactSelectField
                        title="Licensed"
                        placeholder="Licensed"
                        isMulti={false}
                        value={driver?.license?.isLicensed}
                        errorMessage={driver?.licensedError}
                        setValue={(value) =>
                          handleDriverChange(
                            `isLicensed`,
                            value,
                            index,
                            `license`,
                          )
                        }
                        loadOptions={loadBinaryOptions}
                      />
                    </div>
                    <div></div>
                    <div></div>
                  </div>
                  <div className="flex flex-col">
                    {driver?.license?.isLicensed?.value === `YES` && (
                      <>
                        <div className="pl-2 my-1">
                          <span className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                            License
                          </span>
                        </div>
                        <div className="grid grid-cols-5 gap-x-6 gap-y-2 p-2">
                          <div className="relative">
                            <InputFieldRef
                              placeholder="License Number"
                              type="text"
                              title="License Number"
                              inputRef={(element) => {
                                driverLisceneNoRef.current[index] = element
                              }}
                            />
                          </div>
                          <div className=" relative">
                            <ReactSelectField
                              title="Suspended"
                              placeholder="Suspended"
                              isMulti={false}
                              value={driver?.license?.isSuspended}
                              setValue={(value) =>
                                handleDriverChange(
                                  `isSuspended`,
                                  value,
                                  index,
                                  `license`,
                                )
                              }
                              loadOptions={loadBinaryOptions}
                            />
                          </div>
                          {driver?.license?.isSuspended?.value === `YES` && (
                            <div className="relative">
                              <DatePickerField
                                title="Suspended Since"
                                placeholder="Select Date"
                                value={
                                  driver.license?.suspendedMonths !== `` &&
                                  driver.license?.suspendedMonths !== undefined
                                    ? new Date(driver.license?.suspendedMonths)
                                    : ``
                                }
                                errorMessage={errors.dueDateError}
                                setValue={(value) =>
                                  handleDriverChange(
                                    `suspendedMonths`,
                                    value,
                                    index,
                                    `license`,
                                  )
                                }
                                maxDate={new Date()}
                              />
                            </div>
                          )}
                          <div className="flex-1 relative">
                            <DatePickerField
                              title="U.S Licensed"
                              placeholder="U.S Licensed"
                              setValue={(e) =>
                                handleDriverChange(
                                  `usLicensedMonths`,
                                  e,
                                  index,
                                  `license`,
                                )
                              }
                              disabled={
                                driver?.license?.isSuspended?.value === `YES` &&
                                !driver?.license?.suspendedMonths
                              }
                              value={
                                driver?.license?.usLicensedMonths !== `` &&
                                driver?.license?.usLicensedMonths !== undefined
                                  ? new Date(driver?.license?.usLicensedMonths)
                                  : ``
                              }
                              maxDate={
                                driver?.license?.isSuspended?.value === `YES`
                                  ? driver?.license?.suspendedMonths
                                  : new Date()
                              }
                            />
                          </div>
                          <div className="flex-1 relative">
                            <DatePickerField
                              title="State Licensed"
                              placeholder="State Licensed"
                              setValue={(e) =>
                                handleDriverChange(
                                  `stateLicensedMonths`,
                                  e,
                                  index,
                                  `license`,
                                )
                              }
                              disabled={
                                driver?.license?.isSuspended?.value === `YES` &&
                                !driver?.license?.suspendedMonths
                              }
                              value={
                                driver?.license?.stateLicensedMonths !== `` &&
                                driver?.license?.stateLicensedMonths !==
                                  undefined
                                  ? new Date(
                                      driver?.license?.stateLicensedMonths,
                                    )
                                  : ``
                              }
                              maxDate={
                                driver?.license?.isSuspended?.value === `YES`
                                  ? driver?.license?.suspendedMonths
                                  : new Date()
                              }
                            />
                          </div>
                          <div className="flex-1 relative">
                            <DatePickerField
                              title="MVR Experience U.S."
                              placeholder="MVR Experience U.S."
                              setValue={(e) =>
                                handleDriverChange(
                                  `usMVRExperienceMonths`,
                                  e,
                                  index,
                                  `license`,
                                )
                              }
                              disabled={
                                driver?.license?.isSuspended?.value === `YES` &&
                                !driver.license?.suspendedMonths
                              }
                              value={
                                driver?.license?.usMVRExperienceMonths !== `` &&
                                driver?.license?.usMVRExperienceMonths !==
                                  undefined
                                  ? new Date(
                                      driver?.license?.usMVRExperienceMonths,
                                    )
                                  : ``
                              }
                              maxDate={
                                driver?.license?.isSuspended?.value === `YES`
                                  ? driver?.license?.suspendedMonths
                                  : new Date()
                              }
                              // minDate={
                              //   driver.license?.isSuspended?.value === `YES`
                              //     ? new Date(driver.license?.suspendedMonths)
                              //     : ``
                              // }
                            />
                          </div>
                        </div>
                        <div className="grid  grid-cols-3 gap-x-6 gap-y-2 p-2">
                          <div className="flex-1 relative">
                            <ReactSelectField
                              title="Foreign Licensed"
                              placeholder="Foreign Licensed"
                              isMulti={false}
                              value={driver?.license?.isForeignLicensed}
                              setValue={(value) =>
                                handleDriverChange(
                                  `isForeignLicensed`,
                                  value,
                                  index,
                                  `license`,
                                )
                              }
                              loadOptions={loadBinaryOptions}
                              isSearchable={true}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <ReactSelectField
                              title="Foreign License Type"
                              placeholder="Foreign License Type"
                              isMulti={false}
                              value={driver?.license?.foreignLicenseCountry}
                              setValue={(value) =>
                                handleDriverChange(
                                  `foreignLicenseCountry`,
                                  value,
                                  index,
                                  `license`,
                                )
                              }
                              loadOptions={
                                driver?.license?.isForeignLicensed &&
                                loadForeignLicensed
                              }
                              disabled={
                                driver?.license?.isForeignLicensed?.value ===
                                `NO`
                              }
                              isSearchable={true}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <DatePickerField
                              title="Foreign License Experience"
                              placeholder="Foreign License Experience"
                              disabled={
                                driver?.license?.isForeignLicensed?.value ===
                                `NO`
                              }
                              setValue={(e) =>
                                handleDriverChange(
                                  `foreignLicensedMonths`,
                                  e,
                                  index,
                                  `license`,
                                )
                              }
                              value={
                                driver?.license?.foreignLicensedMonths !== `` &&
                                driver?.license?.foreignLicensedMonths !==
                                  undefined
                                  ? new Date(
                                      driver?.license?.foreignLicensedMonths,
                                    )
                                  : ``
                              }
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="text-sm font-medium py-1 px-4 mx-2 bg-red-500  rounded-t-md text-white flex my-2">
                    <div className="flex-1">Violations</div>
                  </div>
                  <div className="flex items-center px-4">
                    <TabsHeaders
                      openTab={activeViolationTab}
                      tabs={driver?.vTabs?.length > 0 ? driver?.vTabs : []}
                      getActiveTab={(val) => setActiveViolationTab(val)}
                      customTabs="YES"
                    />
                    {driver?.vTabs?.length ? (
                      <div className="px-2">
                        <AddFormButton onClick={() => addViolations(index)}>
                          <FontAwesomeIcon icon={faPlus} />
                        </AddFormButton>
                      </div>
                    ) : (
                      <div className="py-4 mx-auto">
                        <AddFormButton onClick={() => addViolations(index)}>
                          <FontAwesomeIcon icon={faPlus} />
                        </AddFormButton>
                      </div>
                    )}
                  </div>
                  {/* violations start here */}
                  {driver?.violations?.map((Violation, vind) => (
                    <FormTab
                      key={vind}
                      currenttab={vind + 1}
                      opentab={activeViolationTab}
                      className="border-2 border-gray-100 shadow-sm rounded-md relative px-4"
                    >
                      <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                        <div className="flex-1">Violation {vind + 1}</div>
                        <div className="flex-1">
                          <div className="float-right">
                            <RemoveDriver
                              onClick={() => handleRemoveViolation(vind, index)}
                            >
                              <FontAwesomeIcon icon={faTimes} size="1x" />
                            </RemoveDriver>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-x-6 p-2">
                        <div className="flex-1 relative">
                          <ReactSelectField
                            title="Violation Code *"
                            placeholder="Violation Code"
                            isMulti={false}
                            value={Violation.violationCode}
                            errorMessage={Violation.violationCodeError}
                            requiredValue={
                              !Violation.violationCode?.value?.length
                            }
                            setValue={(value) =>
                              handleDriverChange(
                                `violationCode`,
                                value,
                                index,
                                ``,
                                vind,
                                `violation`,
                              )
                            }
                            loadOptions={loadViolationsCode}
                            cacheUniqs={selectedStateViolation}
                            isSearchable={true}
                          />
                        </div>
                        <div className="flex-1 relative">
                          <DatePickerField
                            title="Violation Date *"
                            placeholder="Violation Date"
                            setValue={(e) =>
                              handleDriverChange(
                                `violationDate`,
                                e,
                                index,
                                ``,
                                vind,
                                `violation`,
                              )
                            }
                            value={
                              Violation?.violationDate !== `` &&
                              Violation?.violationDate !== undefined
                                ? new Date(Violation?.violationDate)
                                : ``
                            }
                            requiredValue={!Violation.violationDate}
                            errorMessage={Violation.violationDateError}
                            maxDate={new Date(moment().subtract(10, `days`))}
                          />
                        </div>
                        <div className="flex-1 relative">
                          <DatePickerField
                            title="Conviction Date *"
                            placeholder="Conviction Date"
                            setValue={(e) =>
                              handleDriverChange(
                                `convictionDate`,
                                e,
                                index,
                                ``,
                                vind,
                                `violation`,
                              )
                            }
                            value={
                              Violation?.convictionDate !== `` &&
                              Violation?.convictionDate !== undefined
                                ? new Date(Violation?.convictionDate)
                                : ``
                            }
                            requiredValue={!Violation.convictionDate}
                            errorMessage={Violation.convictionDateError}
                            disabled={!Violation.violationDate}
                            maxDate={new Date(moment().subtract(1, `days`))}
                            minDate={new Date(Violation.violationDate)}
                          />
                        </div>
                      </div>
                    </FormTab>
                  ))}
                  {/* violations ends here */}

                  <div className="text-sm font-medium py-1 px-4 mx-2 bg-red-500  rounded-t-md text-white flex my-2">
                    <div className="flex-1">Drivers Attribute</div>
                  </div>
                  <div className="grid grid-cols-4 gap-x-6 gap-y-2 p-2">
                    <div className="flex flex-col col-span-4">
                      <div className="pl-2 my-1">
                        <span className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                          Education
                        </span>
                      </div>
                      <div className="grid grid-cols-4 gap-x-6 gap-y-2 ">
                        <div className="relative">
                          <ReactSelectField
                            title="Education Level"
                            placeholder="Education Level"
                            isMulti={false}
                            value={driver?.education?.educationLevel}
                            setValue={(value) =>
                              handleDriverChange(
                                `educationLevel`,
                                value,
                                index,
                                `education`,
                              )
                            }
                            loadOptions={loadEducationLevel}
                            isSearchable={true}
                          />
                        </div>
                        <div className="relative">
                          <ReactSelectField
                            title="Driver's Training"
                            placeholder="Driver's Training"
                            isMulti={false}
                            value={driver?.education?.driverTraining}
                            setValue={(value) =>
                              handleDriverChange(
                                `driverTraining`,
                                value,
                                index,
                                `education`,
                              )
                            }
                            loadOptions={loadBinaryOptions}
                            isSearchable={true}
                          />
                        </div>
                        <div className="relative">
                          <ReactSelectField
                            title="Drug Awareness"
                            placeholder="Drug Awareness"
                            isMulti={false}
                            value={driver?.education?.drugAwareness}
                            setValue={(value) =>
                              handleDriverChange(
                                `drugAwareness`,
                                value,
                                index,
                                `education`,
                              )
                            }
                            loadOptions={loadBinaryOptions}
                            isSearchable={true}
                          />
                        </div>
                        <div className="relative">
                          <ReactSelectField
                            title="Defensive Driving"
                            placeholder="Defensive Driving"
                            isMulti={false}
                            value={driver?.defensiveDriving}
                            setValue={(value) =>
                              handleDriverChange(
                                `defensiveDriving`,
                                value,
                                index,
                              )
                            }
                            loadOptions={loadBinaryOptions}
                            isSearchable={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" relative">
                      <ReactSelectField
                        title="Prior Insurance *"
                        placeholder="Prior Insurance"
                        isMulti={false}
                        value={driver?.priorInsurance?.wasInsured}
                        requiredValue={
                          !driver?.priorInsurance?.wasInsured?.value?.length
                        }
                        errorMessage={driver?.wasInsureError}
                        setValue={(value) =>
                          handleDriverChange(
                            `wasInsured`,
                            value,
                            index,
                            `prior`,
                          )
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    {driver?.priorInsurance?.wasInsured?.value === `YES` ||
                    !driver?.priorInsurance?.wasInsured?.value === `` ? (
                      <div className="flex flex-col col-span-4">
                        <div className="pl-2 my-1">
                          <span className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                            Prior Insurance
                          </span>
                        </div>
                        <div className="grid grid-cols-4 gap-x-6 gap-y-2 ">
                          <div className="flex-1 relative">
                            <DatePickerField
                              title="Time Since *"
                              placeholder="Time Since"
                              setValue={(e) => {
                                convertDate(e)
                                handleDriverChange(
                                  `durationInMonths`,
                                  e,
                                  index,
                                  `prior`,
                                )
                              }}
                              errorMessage={driver?.timeSinceError}
                              value={
                                driver?.priorInsurance?.durationInMonths !==
                                  `` &&
                                driver?.priorInsurance?.durationInMonths !==
                                  undefined
                                  ? new Date(
                                      driver?.priorInsurance?.durationInMonths,
                                    )
                                  : ``
                              }
                              maxDate={new Date()}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <DatePickerField
                              title="Expire Date *"
                              placeholder="Expire Date"
                              setValue={(e) =>
                                handleDriverChange(
                                  `expiryDate`,
                                  e,
                                  index,
                                  `prior`,
                                )
                              }
                              value={
                                driver?.priorInsurance?.expiryDate !== `` &&
                                driver?.priorInsurance?.expiryDate !== undefined
                                  ? new Date(driver?.priorInsurance?.expiryDate)
                                  : ``
                              }
                              errorMessage={driver?.expireDateError}
                              maxDate={
                                new Date(
                                  moment(
                                    driver?.priorInsurance?.durationInMonths,
                                  ).subtract(1, `week`),
                                )
                              }
                            />
                          </div>
                          <div className="relative">
                            <ReactSelectField
                              title="Prior In Agency"
                              placeholder="Prior In Agency"
                              isMulti={false}
                              value={driver?.priorInsurance?.priorInAgency}
                              setValue={(value) =>
                                handleDriverChange(
                                  `priorInAgency`,
                                  value,
                                  index,
                                  `prior`,
                                )
                              }
                              loadOptions={loadBinaryOptions}
                              isSearchable={true}
                            />
                          </div>
                          <div className="relative">
                            <ReactSelectField
                              title="Carrier"
                              placeholder="Carrier"
                              isMulti={false}
                              value={driver?.priorInsurance?.carrier}
                              setValue={(value) =>
                                handleDriverChange(
                                  `carrier`,
                                  value,
                                  index,
                                  `prior`,
                                )
                              }
                              loadOptions={loadCarriers}
                              isSearchable={true}
                            />
                          </div>
                          <div className="relative">
                            <ReactSelectField
                              title="Liability Limits"
                              placeholder="Liability Limits"
                              isMulti={false}
                              value={driver?.priorInsurance?.liabilityLimits}
                              setValue={(value) =>
                                handleDriverChange(
                                  `liabilityLimits`,
                                  value,
                                  index,
                                  `prior`,
                                )
                              }
                              loadOptions={loadPriorLiabilityLimits}
                              isSearchable={true}
                            />
                          </div>
                          <div className="relative">
                            <ReactSelectField
                              title="Transfer Level"
                              placeholder="Transfer Level"
                              isMulti={false}
                              value={driver?.priorInsurance?.transferLevel}
                              setValue={(value) =>
                                handleDriverChange(
                                  `transferLevel`,
                                  value,
                                  index,
                                  `prior`,
                                )
                              }
                              loadOptions={loadPriorTransferLevel}
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="relative">
                      <ReactSelectField
                        title="SR-22"
                        placeholder="SR-22"
                        isMulti={false}
                        value={driver?.sr22.isFiled}
                        setValue={(value) =>
                          handleDriverChange(`isFiled`, value, index, `sr22`)
                        }
                        loadOptions={loadBinaryOptions}
                      />
                    </div>
                    {driver?.sr22?.isFiled?.value === `YES` ? (
                      <div className="flex flex-col col-span-4">
                        <div className="pl-2 my-1">
                          <span className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                            SR22
                          </span>
                        </div>
                        <div className="grid grid-cols-4 gap-x-6 gap-y-2">
                          <div className="relative">
                            <InputField
                              // placeholder="Filing State"
                              type="text"
                              title="Filing State"
                              value={selectedState}
                              disabled={`yes`}
                            />
                          </div>

                          <div className="relative">
                            <DatePickerField
                              title="Filling Date"
                              placeholder="Filling Date"
                              setValue={(e) =>
                                handleDriverChange(
                                  `filingDate`,
                                  e,
                                  index,
                                  `sr22`,
                                )
                              }
                              value={
                                driver?.sr22?.filingDate !== `` &&
                                driver?.sr22?.filingDate !== undefined
                                  ? new Date(driver?.sr22?.filingDate)
                                  : ``
                              }
                              maxDate={new Date(moment().subtract(1, `week`))}
                            />
                          </div>
                          <div className="relative">
                            <MaskField
                              placeholder="Case No"
                              type="text"
                              title="Case No"
                              mask="9999999"
                              errorMessage={driver?.caseNoError}
                              value={driver?.sr22?.caseNo}
                              setValue={(e) =>
                                handleDriverChange(
                                  `caseNo`,
                                  e.target.value,
                                  index,
                                  `sr22`,
                                )
                              }
                            />
                          </div>
                          <div className="relative">
                            <ReactSelectField
                              title="Reason Filling"
                              placeholder="Reason Filling"
                              isMulti={false}
                              value={driver?.sr22?.reason}
                              setValue={(value) =>
                                handleDriverChange(
                                  `reason`,
                                  value,
                                  index,
                                  `sr22`,
                                )
                              }
                              loadOptions={loadReasonFilling}
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="relative">
                      <ReactSelectField
                        title="Employment Status"
                        placeholder="Select Option"
                        isMulti={false}
                        value={driver?.employment?.employmentStatus}
                        setValue={(value) =>
                          handleDriverChange(
                            `employmentStatus`,
                            value,
                            index,
                            `employment`,
                          )
                        }
                        loadOptions={loadEmploymentStatus}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <MaskField
                        type="text"
                        title="Phone"
                        placeholder="Phone"
                        disabled={!driver?.employment?.employmentStatus?.value}
                        value={driver?.employment?.phone}
                        errorMessage={driver?.phoneError}
                        setValue={(e) =>
                          handleDriverChange(
                            `phone`,
                            e.target.value,
                            index,
                            `employment`,
                          )
                        }
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Property Insurance"
                        placeholder="Select Option"
                        isMulti={false}
                        value={driver?.propertyInsurance}
                        setValue={(value) =>
                          handleDriverChange(`propertyInsurance`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>

                    <div className="relative">
                      <InputFieldRef
                        title="Miles to Work"
                        placeholder="Miles"
                        errorMessage={driver?.milesToWorkError}
                        type="number"
                        inputRef={(element) => {
                          driverMilesToWorkRef.current[index] = element
                        }}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Occasional Operator"
                        placeholder="Select Option"
                        isMulti={false}
                        value={driver?.occasionalOperator}
                        setValue={(value) =>
                          handleDriverChange(`occasionalOperator`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Single Parent"
                        placeholder="Select Option"
                        isMulti={false}
                        value={driver?.singleParent}
                        setValue={(value) =>
                          handleDriverChange(`singleParent`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="text-sm font-medium py-1 px-4 mx-2 bg-red-500  rounded-t-md text-white flex my-2">
                    <div className="flex-1">Carrier Questions</div>
                  </div>

                  {driver?.carrierQuestion &&
                  Object.keys(driver?.carrierQuestion).length > 0 ? (
                    Object.keys(driver?.carrierQuestion).map(
                      (carrier, cindex) => (
                        <div className="px-4" key={cindex}>
                          <div className="pl-2 my-1">
                            <span className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              {carrier}
                            </span>
                          </div>

                          <span className="grid grid-cols-2">
                            {driver?.carrierQuestion[carrier]?.map(
                              (q, qindex) => (
                                <div
                                  className="grid grid-cols-12 my-2 mx-6"
                                  key={qindex}
                                >
                                  <div className="flex  items-center col-span-6">
                                    {q?.question}
                                  </div>
                                  {!q?.mask ? (
                                    <div className="relative mx-4  col-span-6">
                                      <ReactSelectField
                                        // title="Car Type"
                                        // placeholder="Car Type"
                                        isMulti={false}
                                        value={q?.selectedValue}
                                        setValue={(value) =>
                                          handleQuestionChange(
                                            value,
                                            qindex,
                                            carrier,
                                            q.question,
                                            index,
                                          )
                                        }
                                        loadOptions={() => ({
                                          options: q?.options,
                                          hasMore: false,
                                        })}
                                        cacheUniqs={q?.options}
                                        isSearchable={true}
                                      />
                                    </div>
                                  ) : (
                                    <div className="relative mx-4  col-span-6">
                                      <DatePickerField
                                        // title="Filling Date"
                                        placeholder="Date"
                                        setValue={(value) =>
                                          handleQuestionChange(
                                            value,
                                            qindex,
                                            carrier,
                                            q.question,
                                            index,
                                          )
                                        }
                                        value={q?.selectedValue}
                                        // maxDate={
                                        //   new Date(moment().subtract(1, `week`))
                                        // }
                                      />
                                    </div>
                                  )}
                                </div>
                              ),
                            )}
                          </span>
                        </div>
                      ),
                    )
                  ) : (
                    <div className="text-center p-6">
                      No carrier questions found
                    </div>
                  )}
                </FormTab>
              ))}
          </div>
        </CollapsableRef>
      </div>
    )
  },
)

DriverForm.displayName = `DriverForm`
