import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { NotificationManager } from 'react-notifications'
import { InputField } from 'App/Components/Common/InputField'
import { ReactModal } from 'App/Components/Common/Modal'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const CreateFolder = ({ open, setOpen, folderPath, refetch }) => {
  const [folderName, setFoldername] = useState(``)

  const { mutate, isLoading } = useMutation(
    async () =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/cloudinary/create`, {
        path: folderPath,
        folderName: folderName,
      }).then(({ data }) => {
        if (!data.hasError) {
          NotificationManager.success(`Folder Created Successfully`)
          setFoldername(``)
          setOpen(false)
          refetch()
        } else {
          NotificationManager.error(`Something went wrong`)
          setFoldername(``)
          setOpen(false)
          refetch()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  return open ? (
    <ReactModal
      title="Create Folder"
      closeModal={() => setOpen(false)}
      disabled={folderName}
      loading={isLoading}
      callMutation={mutate}
      icon={faPlusCircle}
      toolTip="Create Folder"
      className="py-2 px-10"
    >
      <InputField
        type="text"
        placeholder="Folder Name"
        tipdata="Create Folder"
        value={folderName}
        setValue={(e) => {
          setFoldername(e.target.value)
        }}
      />
    </ReactModal>
  ) : (
    <></>
  )
}
