import React, { useEffect, useState, Fragment } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useRouteMatch, useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Container, PageHeader } from 'App/Styled'
import { permissionGranted } from 'App/Services'
import { TableHeader, TableBody, TableRow, TableCell } from '@windmill/react-ui'
import {
  faSortDown,
  faSortUp,
  faTrash,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { ReactModal } from 'App/Components/Common/Modal'
import { UpdateClaimStatus } from '../UpdateClaims'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { NotificationManager } from 'react-notifications'
import { timeFormatPST } from 'App/Services/General/dateFormat'
const tableColumns = [
  { label: `claim Id`, value: `claimId` },
  { label: `Name`, value: `nameofCustomer` },
  { label: `policy No`, value: `policyNo` },
  { label: `status`, value: `status` },
  { label: `created At`, value: `createdAt` },
]

export const Claims = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [claimUpdated, setClaimUpdated] = useState(false)
  const [updateStatusModal, setUpdateStatusModal] = useState(false)
  const [claimStatus, setClaimStatus] = useState(``)
  const {
    data: claimsData,
    isLoading,
    refetch: getAllClaims,
    isFetching,
    error,
  } = useQuery(
    `ClaimApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/claim/all?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }"}&sort=-createdAt&page=1&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    mutate: deleteClaim,
    isLoading: deletingClaim,
    isError: deletionError,
    error: errorResponse,
  } = useMutation(
    async (ids) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/claim/${ids.lId}`,
      ).then((res) => {
        !res.hasError && getAllClaims()
        NotificationManager.success(`Deleted Successfully`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  useEffect(() => {
    if (claimUpdated) {
      getAllClaims()
      setClaimUpdated(false)
    }
  }, [claimUpdated])
  useEffect(() => {
    if (!customSearching) {
      getAllClaims()
    }
  }, [sort, currentPage, isOffset])

  const callClaimsQuery = useDebouncedCallback(() => {
    getAllClaims()
  }, 700)

  const handleStatus = (item, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (item.status !== `SETTLEMENT`) {
      setClaimStatus(item)
      setUpdateStatusModal(true)
    }
  }
  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteClaim(id)
  }

  return (
    <Container>
      <Spinner loading={deletingClaim || isLoading || isFetching} />
      {updateStatusModal && (
        <ReactModal
          title="Update Status"
          closeModal={() => setUpdateStatusModal(false)}
        >
          <UpdateClaimStatus
            selectedClaim={claimStatus}
            closeModal={() => setUpdateStatusModal(false)}
            setClaimUpdated={() => setClaimUpdated(true)}
          />
        </ReactModal>
      )}
      {deletionError && (
        <ReactNotification
          action="error"
          message={errorResponse?.response?.data.error?.error}
        />
      )}
      <PageHeader>
        <div>Claims</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>

          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callClaimsQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div
        className={` bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (deletingClaim || isLoading || isFetching) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllClaims()} />
        ) : (
          <>
            <div className="overflow-x-auto mt-12 xl:overflow-hidden rounded-t-md">
              <table className="w-full  border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort === `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort === `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(permissionGranted([
                      `claims:read`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) ||
                      permissionGranted([
                        `claims:delete`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) ||
                      permissionGranted([
                        `claims:update`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ])) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!isLoading && !claimsData?.claims?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Claims Found</div>
                      </td>
                    </tr>
                  ) : (
                    claimsData?.claims
                      ?.filter((item) => item.isDeleted !== true)
                      ?.map((item, index) => (
                        <Fragment key={index}>
                          <TableRow
                            key={index}
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              if (
                                permissionGranted([
                                  `claims:read`,
                                  `corporate-manager:*`,
                                  `manager:*`,
                                  `admin:*`,
                                ])
                              ) {
                                history.push(`${url}/${item._id}`)
                              }
                            }}
                          >
                            <td className="px-4 py-0.5 capitalize truncate text-sm">
                              {item._claimId}
                            </td>
                            <td className="px-4 py-0.5 capitalize truncate text-sm">
                              {item.customer?.label?.toLowerCase()}
                            </td>
                            <td className="px-4 py-0.5 text-sm">
                              {item.policyNo}
                            </td>
                            <td className="px-4 py-0.5 capitalize truncate text-sm">
                              {item.status?.toLowerCase()}
                            </td>

                            <td className="px-4 py-0.5 capitalize truncate text-sm">
                              <span>{timeFormatPST(item?.createdAt)}</span>
                            </td>

                            {/* Actions TD */}
                            <td className="px-6 py-0.5 z-0 text-sm flex float-right text-gray-400">
                              {permissionGranted([
                                `claims:delete`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ]) && (
                                <span data-tip="Delete" className="tooltip">
                                  <FontAwesomeIcon
                                    className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                    icon={faTrash}
                                    size="1x"
                                    onClick={(e) =>
                                      handleDelete(
                                        {
                                          lId: item._id,
                                        },
                                        e,
                                      )
                                    }
                                  />
                                </span>
                              )}
                              {permissionGranted([
                                `claims:update`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ]) && (
                                <span
                                  data-tip={`${
                                    item.status !== `SETTLEMENT`
                                      ? `Update`
                                      : `Settlement`
                                  }`}
                                  className="tooltip"
                                >
                                  <FontAwesomeIcon
                                    className={` mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer ${
                                      item?.status === `SETTLEMENT`
                                        ? `cursor-not-allowed`
                                        : `cursor-pointer`
                                    }`}
                                    icon={faEdit}
                                    onClick={(e) => handleStatus(item, e)}
                                  />
                                </span>
                              )}
                            </td>
                          </TableRow>
                        </Fragment>
                      ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={claimsData?.totalPages}
                totalRecords={claimsData?.totalRecords}
                curerntPageRecords={claimsData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
