import { createSlice } from '@reduxjs/toolkit'

// const initialState = {
//   paymentsByPaymentMethod: [
//     {
//       paymentMethod: `CASH`,
//       amount: 250.52,
//     },
//     {
//       paymentMethod: `CHEQUE`,
//       amount: 5000.0,
//     },
//     {
//       paymentMethod: `CREDIT/DEBIT_CARD`,
//       amount: 96314.04,
//     },
//   ],
//   paymentsByType: [
//     {
//       paymentType: `PREMIUM`,
//       amount: `76314.04`,
//     },
//     {
//       paymentType: `NON_PREMIUM`,
//       amount: `25001.54`,
//     },
//   ],
//   paymentsByAgent: [
//     {
//       agent: {
//         _id: `875412154054s5adas14`,
//         fullName: `TEST USER`,
//       },
//       amount: 5480.5,
//     },
//     {
//       agent: {
//         _id: `a5a412154054s5adas14`,
//         fullName: `JOHN BILLY`,
//       },
//       amount: 8650.44,
//     },
//     {
//       agent: {
//         _id: `8754a4aq4054s5adas14`,
//         fullName: `BEN STOKES`,
//       },
//       amount: 9630.14,
//     },
//   ],
//   paymentsByFeeType: [
//     {
//       feeType: `RENEWAL_FEE`,
//       amount: 54201.22,
//     },
//     {
//       feeType: `STATE_TAX`,
//       amount: 4201.21,
//     },
//     {
//       feeType: `DOWN_PAYMENT`,
//       amount: 9420.78,
//     },
//   ],
// }

const initialState = {
  currentDailyLedger: ``,
}

const dailyLedgerSlice = createSlice({
  name: `dailyLedger`,

  initialState,
  reducers: {
    setCurrentDailyLedger: (state, { payload }) => {
      state.currentDailyLedger = payload
    },
  },
})

export const { setCurrentDailyLedger } = dailyLedgerSlice.actions

export const dailyLedger = dailyLedgerSlice.reducer
