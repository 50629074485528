import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'
import React from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router'
import { Container, EditStyledButton, PageHeader } from 'App/Styled'
import { setCurrentRole } from 'App/Redux/actions'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useDispatch, useSelector } from 'react-redux'
import { permissionGranted } from 'App/Services'
import { useHistory } from 'react-router-dom'
import dateFormat from 'App/Services/General/dateFormat'
export const PreviewSystemRole = () => {
  const { sub: currentUser } = useSelector(({ user }) => user?.user?.profile)
  const dispatch = useDispatch()
  const {
    params: { roleId },
  } = useRouteMatch()
  const history = useHistory()
  const {
    data: previewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `SystemRolePreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/system-roles/${roleId}`,
      ).then((res) => {
        return res.data.data.systemRole
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (response) => {
        dispatch(setCurrentRole(response))
      },
    },
  )
  return (
    <Container>
      <PageHeader className="mx-40">
        <div>System Role</div>
        {permissionGranted([
          `system-roles:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <div className="flex">
            <EditStyledButton
              disabled={
                isLoading ||
                isFetching ||
                currentUser !== previewData?._creator?._id
              }
              onClick={() =>
                history.push(`/admin/system-roles/${roleId}/update`)
              }
            >
              <span>Edit</span>
              <FontAwesomeIcon
                icon={faPen}
                className="text-white pt-1"
                size="sm"
              />
            </EditStyledButton>
          </div>
        )}
      </PageHeader>

      <div className="bg-white sm:mb-0  w-full px-2 rounded-md">
        {isLoading || isFetching ? (
          <div className="text-center p-6">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : (
          <div className="rounded-md overflow-hidden py-4 w-3/4 mx-auto">
            <div className="flex flex-col md:flex-row border-1 items-start justify-evenly">
              <div className="flex-1 mx-2  rounded">
                <div className="grid grid-cols-2  px-2">
                  <CardField title="Role Name" value={previewData?.name} />
                  <CardField
                    title="Created By"
                    value={
                      previewData?._creator?.firstName +
                      ` ` +
                      previewData?._creator?.lastName
                    }
                  />
                  <CardField
                    title="Created At"
                    value={dateFormat(previewData?.createdAt)}
                  />
                </div>

                <div className="grid grid-cols-1 px-2">
                  <h1 className="text-gray-500 font-medium capitalize text-sm">
                    Description:
                  </h1>
                  <p className="text-xs mt-2 ">{previewData?.description}</p>
                </div>

                <hr />
                <div>
                  {!previewData?.permissions?.length ? (
                    <p className=" text-center p-6">No Data Found</p>
                  ) : (
                    <div className="px-2 py-5 gap-x-2">
                      <div className="py-1">
                        <div>
                          <div className="py-2">
                            <div className="font-semibold text-sm bg-gray-500 text-gray-100 px-2 py-1 rounded-sm">
                              {`Permissions`}
                            </div>
                          </div>
                          <hr />
                          <div className="grid grid-cols-2 px-2">
                            {previewData?.permissions?.map(
                              (permission, index) => (
                                <>
                                  <div className="flex gap-x-2">
                                    <div
                                      key={index}
                                      className="text-justify pr-2 text-gray-600 "
                                    >
                                      {`◉ ` + permission}
                                    </div>
                                  </div>
                                </>
                              ),
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}
