export const loadPrefPaymentOptions = () => {
  const options = {
    options: [
      { value: `Installments `, label: `Installments` },
      { value: `Installments EFT`, label: `Installments EFT` },
      { value: `Paid In Full`, label: `Paid In Full` },
      { value: `Paid In Full EFT`, label: `Paid In Full EFT` },
    ],
    hasMore: false,
  }
  return options
}
