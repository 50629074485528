export const utcToPst = (localDate, isDate) => {
  if (!localDate) return
  let date = new Date(localDate)
  let pstTime = date?.toLocaleString(`en-US`, {
    timeZone: `America/Los_Angeles`,
    year: `numeric`,
    month: `2-digit`,
    day: `2-digit`,
    hour: `2-digit`,
    minute: `2-digit`,
  })
  let pstDate = date?.toLocaleString(`en-US`, {
    timeZone: `America/Los_Angeles`,
    year: `numeric`,
    month: `2-digit`,
    day: `2-digit`,
  })
  return isDate ? pstDate : pstTime
}
