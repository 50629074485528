import { AMS_BACKEND_API, AxiosInstance, allowedSystemPermissions } from 'App/Config'
import { StyledButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { NotificationManager } from 'react-notifications'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { loadFranchises, permissionGranted } from 'App/Services'
import { useSelector } from 'react-redux'
export const UpdateTeam = ({ teamId, closeModal, teamUpdated }) => {
  const [currentTeam, setCurrentTeam] = useState(``)
  const [teams, setTeams] = useState({
    name: ``,
    teamMembers: ``,
    franchise: ``,
    location: ``,
  })

  const [errors, setErrors] = useState({
    nameError: ``,
    membersError: ``,
    locationError: ``,
  })

  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)
  const {
    user: {
      profile: { _locations },
    },
  } = useSelector(({ user }) => user)
  const loadAgentLocations = () => {
    const options = _locations.map((item) => {
      return { value: item._id, label: item.name }
    })

    return {
      options,
      // hasMore: false,
    }
  }
  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/assessment-team/${teamId}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          if (res.data) {
            NotificationManager.success(`Updated Successfully`)
            closeModal()
            teamUpdated()
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            } else if (
              error.response.data.error.error.includes(
                `Team update failed due to duplicate teamName.`,
              )
            ) {
              NotificationManager.error(`Duplicate Team Name`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  // get locatons on franchise base
  const loadLocations = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${teams?.franchise.value
        ? teams?.franchise.value
        : _franchise?._id
      }/locations/list/?search=${search && search}&page=${page}&offset=10`,
    )
    if (data) {
      return {
        options: data.locations,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  const loadAgents = async (search, loadedOptions, { page }) => {
    try {
      const {
        data: { data },
      } = await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/users/v2/list/?search={"query":"${search.length ? search : ``
        }"}&page=${page}&offset=10`,
        {
          franchiseId: teams?.franchise?.value ? teams?.franchise?.value : _franchise?._id,
          locations: [teams?.location?.value],
          permissions: allowedSystemPermissions.assessment
        },)

      if (data) {
        return {
          options: data.agents,
          hasMore: data.totalPages - page > 0,
          additional: {
            page: page + 1,
          },
        }
      }
    }
    catch (error) {
      if (error.response?.data?.hasError) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        }
      }
    }


  }

  const { refetch: teamPreview, isLoading: teamLoading } = useQuery(
    `teamPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/assessment-team/${teamId}`,
      ).then((res) => {
        return res?.data?.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCurrentTeam(res?.team)
      },
    },
  )

  useEffect(() => {
    teamPreview()
  }, [])

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.locationError.length &&
      !errors.membersError.length
    ) {
      mutate(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])
  useEffect(() => {
    if (currentTeam) {
      setTeams((preState) => ({
        ...preState,
        [`teamMembers`]: currentTeam?.members.map((member) => ({
          value: member?.value,
          label: member?.label,
        })),
        [`name`]: currentTeam?.teamName,
        [`location`]: currentTeam?.location,
        [`franchise`]: currentTeam?.franchise,
      }))
    }
  }, [currentTeam])

  const updatePayload = () => {
    let data = {}
    if (teams?.name !== currentTeam?.teamName) {
      data.teamName = teams?.name
    }
    if (teams?.franchise?.value !== currentTeam?.franchise?.value) {
      data._franchise = teams?.franchise?.value
    }
    if (teams?.location?.value !== currentTeam?.location?.value) {
      data._location = teams?.location?.value
    }
    let isMembers
    if (teams?.members?.length === currentTeam.members.length) {
      isMembers = teams?.members.some((eachmembers, index) => {
        return eachmembers.value === currentTeam?.members[index]
      })
    } else {
      isMembers = true
    }
    if (isMembers) {
      data.teamMembers = teams?.teamMembers?.map((member) => member.value)
    }

    return data
  }

  const validateForm = () => {
    if (!teams?.teamMembers?.length) {
      handleErrors(`membersError`, `Select member`)
    } else {
      handleErrors(`membersError`, ``)
    }
    if (!teams?.location?.value) {
      handleErrors(`locationError`, `Select Location`)
    } else {
      handleErrors(`locationError`, ``)
    }
    if (!teams?.name?.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }
    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  return (
    <>
      {updationError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <div
        className={`px-4 bg-white ${teamLoading && `opacity-30 pointer-events-none`
          } `}
      >
        <div className="flex flex-col">
          <div className="flex flex-col px-4">
            <div className="flex gap-4">
              <div className="flex-1 relative w-48">
                <InputField
                  type="text"
                  name="Name"
                  title="Name*"
                  placeholder="Enter Name"
                  value={teams.name}
                  setValue={(e) => {
                    setTeams((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }}
                  errorMessage={errors.nameError}
                />
              </div>
              {permissionGranted([`super-admin:*`]) && (
                <>
                  <div className="flex-1 relative w-60">
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Franchise *"
                        placeholder="Select Franchise"
                        value={teams?.franchise}
                        setValue={(value) => {
                          setTeams((preState) => ({
                            ...preState,
                            franchise: value,
                            teamMembers: [],
                            location: ``,
                          }))
                        }}
                        isMulti={false}
                        loadOptions={loadFranchises}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {teamLoading && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                className="flex justify-center mt-8"
              />
            )}
            <div className="flex gap-4">
              <div className="flex gap-4">
                <div className="flex-1 relative w-56">
                  <ReactSelectField
                    title="Location *"
                    placeholder="Select Location"
                    errorMessage={errors.locationError}
                    value={teams?.location}
                    setValue={(value) => {
                      setTeams((preState) => ({
                        ...preState,
                        location: value,
                        teamMembers: [],
                      }))
                    }}
                    isMulti={false}
                    loadOptions={
                      permissionGranted([`super-admin:*`])
                        ? loadLocations
                        : loadAgentLocations
                    }
                    isSearchable={true}
                    cacheUniqs={teams?.franchise}
                  />
                </div>
                <div className="flex-1 relative w-60">
                  <ReactSelectField
                    title="Select Members*"
                    placeholder="Select Members"
                    value={teams.teamMembers}
                    errorMessage={errors.membersError}
                    setValue={(value) => {
                      setTeams((preState) => ({
                        ...preState,
                        teamMembers: value,
                      }))
                    }}
                    isMulti={true}
                    loadOptions={teams?.location?.value ? loadAgents : ``}
                    isSearchable={true}
                    cacheUniqs={teams?.location}
                  />
                </div>

              </div>
            </div>
          </div>
          <div className="pb-2 px-4 text-right">
            <StyledButton onClick={validateForm}>
              <div className="flex gap-2 text-white">
                <span>Update</span>
                {isLoading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    className="my-auto"
                  />
                )}
              </div>
            </StyledButton>
          </div>
        </div>
      </div>
    </>
  )
}
