import { useMemo } from 'react'

export function paymentAmountTotal(amounts) {
  const results = useMemo(() => {
    return amounts
      ? amounts?.reduce((total, item) => {
          const amountDecimal = item.amount ? parseFloat(item.amount) : 0

          return total + amountDecimal
        }, 0)
      : 0.0
  }, [amounts])

  return results.toFixed(2)
}
