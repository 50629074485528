import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  chatRoom: ``,
}

const messengerSlice = createSlice({
  name: `messenger`,

  initialState,
  reducers: {
    setChatRoomId: (state, { payload }) => {
      state.chatRoom = payload
    },
  },
})

export const { setChatRoomId } = messengerSlice.actions

export const messenger = messengerSlice.reducer
