import { Collapsable } from 'App/Components/Common/Collapsable'
import { InputField } from 'App/Components/Common/InputField'
import { SubmitButton } from 'App/Styled'
import React, { useState } from 'react'

const FiltersAgents = ({
  filtersProps,
  handleFilters,
  resetFilters,
  isLoading,
}) => {
  const [filters, setFilters] = useState({ ...filtersProps })

  const handleChangeFilters = (value, name) => {
    {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  return (
    <div
      className={`bg-white mt-2 mb-1 sm:mb-0 justify-center items-center px-2 pt-1 `}
    >
      <Collapsable title="Filters">
        <div className="grid grid-cols-4 gap-4">
          {/* name: ``,
    email: ``,
    phone: ``,
    _userId: ``, */}
          <div className="relative">
            <InputField
              type="text"
              title="Name"
              placeholder="Enter name"
              value={filters?.name ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `name`)
              }}
              policyNo
            />
          </div>
          <div className="relative">
            <InputField
              type="text"
              title="Email"
              placeholder="Enter email"
              value={filters?.email ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `email`)
              }}
            />
          </div>

          <div className="relative">
            <InputField
              type="number"
              title="Phone"
              placeholder="Enter phone"
              value={filters?.phone ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `phone`)
              }}
            />
          </div>
          <div className="relative">
            <InputField
              type="number"
              title="Agent Id"
              placeholder="Enter Id"
              value={filters?._userId ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `_userId`)
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-4">
          <div className="flex  gap-4">
            <SubmitButton
              disabled={isLoading}
              onClick={() => {
                handleFilters(filters)
              }}
            >
              Search
            </SubmitButton>

            <SubmitButton
              disabled={isLoading}
              onClick={() => {
                if (
                  filters?.name?.length ||
                  filters?.phone?.length ||
                  filters?.email?.length ||
                  filters?._userId
                ) {
                  setFilters({})
                  resetFilters()
                }
              }}
            >
              Reset
            </SubmitButton>
          </div>
        </div>
      </Collapsable>
    </div>
  )
}

export default FiltersAgents
