import thunk from 'redux-thunk'
import storage from './presistStorage'
import { rootReducer } from './reducers'
import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'

const initialState = {}

// presist the state of user after refreshing the app.
const persistConfig = {
  key: `PresistStorage`, // variable name in storage
  storage,
  whitelist: [
    `user`,
    `asset`,
    `franchise`,
    `customer`,
    `location`,
    `systemRole`,
    `agent`,
    `carrier`,
    `invoice`,
    `payment`,
    `paymentTemplate`,
    `dataTransactions`,
    `policy`,
    `notification`,
    `prefernces`,
    `ratingTemplates`,
    `myquotes`,
    `gmail`,
    `video`,
    `carDealer`,
    `credentials`,
    `currentCommunication`,
    `lead`,
    `aqsQuote`,
    `preferences`,
    `monthlyGoal`,
    `campaignGroup`,
    `claim`,
    `tickets`,
    `tasks`,
    `ticketCategory`,
    `policyRequest`
  ], // which reducer want to store
}
const persistReducerState = persistReducer(persistConfig, rootReducer)
const middleware = [thunk]
const store = createStore(
  persistReducerState,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
)

const persistor = persistStore(store)
export { store, persistor }
