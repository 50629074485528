import {
  faPlusCircle,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { GenerateNotificationsModal } from '../GenerateNotificationsModal'
import { ReactPagination } from '../../Common/Pagination'
import { TableBody, TableCell, TableHeader, TableRow } from '@windmill/react-ui'
import { SearchInputField } from '../../Common/SearchInputField'
import { useQuery } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { Spinner } from '../../Common/Spinner'
import { Reload } from '../../Common/Reload'
import { useDebouncedCallback } from 'use-debounce'
import { timeFormatPST } from 'App/Services/General/dateFormat'
import { ReactSelectField } from '../../Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import { useHistory, useRouteMatch } from 'react-router'
import { useDispatch } from 'react-redux'
import { setGeneralNotificationId } from 'App/Redux/actions'

export const GeneralNotifications = () => {
  const history = useHistory()

  const { url } = useRouteMatch()
  const dispatch = useDispatch()

  //State to handle "Generate Notification" modal Toggle
  const [generateNotificationModal, setGenerateNotificationModal] =
    useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)

  const tableColumns = [
    { label: `Title`, value: `title` },
    { label: `Body`, value: `description` },
    { label: `Total Recipients`, value: `totalRecipients` },
    { label: `Created At`, value: `createdAt` },
  ]

  //Api call to get general notifications list
  const {
    isLoading,
    data: generalNotifications,
    error,
    refetch: getGeneralNotifications,
    isFetching,
  } = useQuery(
    `generalNotificationsDataApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/general-notifications/all?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }"}&sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getGeneralNotifications()
    }
  }, [sort, currentPage, isOffset])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const callInvoicesQuery = useDebouncedCallback(() => {
    getGeneralNotifications()
  }, 2500)

  return (
    <Container shadow="yes">
      <Spinner loading={isLoading || isFetching} />

      <PageHeader bgcolor="bg-white">
        <div>General Notifications</div>
        {/* Search of offSet elements */}
        <div className="flex items-center gap-2 px-4">
          <div className="w-48">
            <SearchInputField
              type="text"
              placeholder="Search..."
              value={searchQuery}
              disabled={isLoading || isFetching}
              setValue={(e) => {
                setSearchQuery(e.target.value)
                setCurrentPage(1)
                setCustomSearching(true)
                callInvoicesQuery()
              }}
            />
          </div>
          {/* Show offSet dropdown only if records are > 10 */}
          {generalNotifications?.totalRecords > 10 && (
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          )}
        </div>
      </PageHeader>

      <div className="flex justify-end items-center px-2 mb-2">
        <StyledButton
          disabled={isLoading || isFetching}
          onClick={() => setGenerateNotificationModal(true)}
        >
          <div className={`flex gap-2 text-white items-center`}>
            <span>Generate Notifications</span>
            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
          </div>
        </StyledButton>
      </div>

      {/* Generate Notification Modal */}
      {generateNotificationModal && (
        <GenerateNotificationsModal
          setGenerateNotificationModal={setGenerateNotificationModal}
        />
      )}

      {/* Table for listing General notifications list  */}
      {error ? (
        <Reload refetch={() => getGeneralNotifications()} />
      ) : (
        <div
          className={`my-1 sm:mb-0 justify-between ${
            (isLoading || isFetching) && ` opacity-30 pointer-events-none`
          } `}
        >
          <div className="overflow-x-auto rounded-t-md">
            <table className="w-full border-collapse">
              <TableHeader>
                <TableRow>
                  {tableColumns.map((item, idx) => {
                    return (
                      <TableCell key={idx}>
                        <div className="cursor-pointer select-none text-sm flex items-center">
                          <div
                            onClick={() => {
                              handleSorting(item.value)
                            }}
                            className={`flex ${
                              item.value === sort ? `text-red-500` : ``
                            }`}
                          >
                            {item.label}
                          </div>
                          <div
                            className="ml-1 rounded-sm cursor-pointer flex relative"
                            onClick={() => handleSorting(item.value)}
                          >
                            <FontAwesomeIcon
                              icon={
                                sort.includes(`-`) && sort == item.value
                                  ? faSortUp
                                  : faSortDown
                              }
                              size="lg"
                              className={`${
                                sort.includes(`-`) && sort == item.value
                                  ? `text-red-500`
                                  : `-mt-1.5`
                              }`}
                            />
                          </div>
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHeader>
              <TableBody>
                {generalNotifications?.generalNotifications?.length ? (
                  generalNotifications.generalNotifications.map(
                    (gNotification, index) => (
                      <TableRow
                        key={index.toString()}
                        className="hover:bg-gray-100 hover:cursor-pointers"
                        onClick={() => {
                          history.push(`${url}/${gNotification._id}`)
                          dispatch(setGeneralNotificationId(gNotification._id))
                        }}
                      >
                        <td className="py-2.5 text-sm pl-4 z-0 break-words capitalize truncate">
                          {gNotification?.title || `N/A`}
                        </td>
                        <td className="py-2.5 text-sm pl-4 z-0 break-words capitalize max-w-xs overflow-hidden text-ellipsis whitespace-nowrap">
                          {gNotification?.description || `N/A`}
                        </td>
                        <td className="py-2.5 text-sm pl-8 z-0 break-words capitalize truncate ">
                          {gNotification?.totalRecipients || `N/A`}
                        </td>
                        <td className="py-2.5 px-6 text-sm pl-4 z-0 truncate">
                          {timeFormatPST(gNotification?.createdAt) || `N/A`}
                        </td>
                      </TableRow>
                    ),
                  )
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center p-6">
                      <div>No General Notifications Found</div>
                    </td>
                  </tr>
                )}
              </TableBody>
            </table>
          </div>
          <div className="px-4 py-2">
            <ReactPagination
              offset={offset}
              currentPage={currentPage}
              totalPages={generalNotifications?.totalPages}
              totalRecords={generalNotifications?.totalRecords}
              curerntPageRecords={generalNotifications?.currentPageRecords}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      )}
    </Container>
  )
}
