import React, { useState, useEffect } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { StyledButton } from 'App/Styled'
import { loadChannel } from 'App/Services/Broadcast'
import { AxiosInstance, AMS_BACKEND_API } from 'App/Config'
import { useMutation } from 'react-query'
import { RichTextEditor } from 'App/Components/Common/RichTextEditor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { NotificationManager } from 'react-notifications'

export const UpdateBroadcastingTemplates = ({
  closeModal,
  refetchTemplates,
  currentTemplate,
}) => {
  const [messageTemplate, setMessageTemplate] = useState({
    name: ``,
    channel: ``,
    template: ``,
  })

  const [templateBody, setTemplateBody] = useState(``)

  const [errors, setErrors] = useState({
    nameError: ``,
    channelError: ``,
    bodyError: ``,
    formSubmit: ``,
  })

  const {
    mutate: updateBroadCastingTemplate,
    isLoading: addingTemplate,
    isError: additionError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/templates/${currentTemplate?._id}`,
        {
          ...payload,
        },
      ).then((res) => {
        if (!res.hasError) {
          refetchTemplates()
          closeModal()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const ValidateForm = () => {
    if (!messageTemplate.name.length) {
      handleErrors(`nameError`, `Enter Name`)
      handleErrors(`formSubmit`, false)
    } else {
      handleErrors(`nameError`, ``)
      handleErrors(`formSubmit`, false)
    }

    if (!messageTemplate.channel?.value?.length) {
      handleErrors(`channelError`, `Select Channel`)
    } else {
      handleErrors(`channelError`, ``)
    }

    let tempTemplateBody = templateBody.replace(/<[^>]*>/g, ``)

    if (!tempTemplateBody?.length) {
      handleErrors(`bodyError`, `Enter Template Body`)
    } else {
      handleErrors(`bodyError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }
  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.bodyError.length &&
      !errors.nameError.length &&
      !errors.channelError.length
    ) {
      updateBroadCastingTemplate({
        name: messageTemplate.name,
        channel: messageTemplate?.channel?.value,
        template:
          messageTemplate?.channel?.value === `SMS`
            ? templateBody.replace(/<[^>]*>/g, ``)
            : templateBody,
      })
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  useEffect(() => {
    if (currentTemplate) {
      setMessageTemplate((preValue) => ({
        ...preValue,
        [`name`]: currentTemplate?.name,
        [`channel`]: {
          label: currentTemplate?.channel,
          value: currentTemplate?.channel,
        },
      }))
      setTemplateBody(currentTemplate?.template)
    }
  }, [currentTemplate])

  useEffect(() => {
    if (messageTemplate?.channel?.value === `SMS`) {
      setTemplateBody(templateBody.replace(/<[^>]*>/g, ``))
    }
  }, [messageTemplate?.channel?.value])

  const verifyChannelDisable = () => {
    if (
      currentTemplate?.channel === `MMS` ||
      currentTemplate?.channel === `PDF`
    ) {
      return false
    }
    return true
  }
  return (
    <div
      className=" px-6 py-4"
      style={{
        width: `50vw`,
        minWidth: `40vw`,
      }}
    >
      {additionError && NotificationManager.error(`Something went wrong`)}

      <div className="flex  flex-col gap-6 mt-2 text-xs">
        <div className="flex-1 relative">
          <InputField
            type="text"
            title="Name *"
            name="name"
            placeholder="Enter Name"
            errorMessage={errors.nameError}
            value={messageTemplate.name}
            setValue={(e) =>
              setMessageTemplate((prevState) => ({
                ...prevState,
                [`name`]: e.target.value,
              }))
            }
          />
        </div>
        <div className="flex-1 relative">
          <ReactSelectField
            title="Select Channel *"
            placeholder="Select Option"
            value={messageTemplate.channel}
            setValue={(value) =>
              setMessageTemplate((prevState) => ({
                ...prevState,
                [`channel`]: value,
              }))
            }
            errorMessage={errors.channelError}
            isOptionDisabled={true}
            loadOptions={verifyChannelDisable() && loadChannel}
            isSearchable={false}
            isMulti={false}
          />
        </div>
        <div className="flex-1 relative">
          {messageTemplate?.channel?.value === `SMS` && (
            <>
              <RichTextEditor
                setEditorValue={setTemplateBody}
                className="hide-toolbar"
                defaultValue={currentTemplate?.template}
              />
              {errors.bodyError?.length ? (
                <span className="text-red-500">Enter Body</span>
              ) : null}
            </>
          )}
          {messageTemplate?.channel?.value === `MAIL` && (
            <RichTextEditor
              setEditorValue={setTemplateBody}
              defaultValue={currentTemplate?.template}
            />
          )}
        </div>
      </div>
      <div>
        <div className="flex justify-end items-center p-2">
          <StyledButton onClick={() => ValidateForm()}>
            <div className="flex  gap-2 text-white">
              <span>{!addingTemplate ? `Update` : `Updating...`} </span>
              {addingTemplate && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </StyledButton>
        </div>
      </div>
    </div>
  )
}
