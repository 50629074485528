/* eslint-disable no-unused-vars */
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const Spinner = ({ loading }) => {
  return loading ? (
    // <div className="relative">
    //   <div className="absolute top-80 bottom-80 w-full h-full flex justify-center items-center  z-40  p-4 text-2xl text-red-500">
    //     <FontAwesomeIcon size={size} icon={faSpinner} spin={true} />
    //   </div>
    // </div>
    <div className="relative flex justify-center items-center">
      <div className="loader-animation absolute top-64 bottom-80 w-full h-full flex z-30 justify-center items-center mx-auto"></div>
    </div>
  ) : (
    <></>
  )
}
