import {
  faFilePdf,
  faFileWord,
  faPen,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'
import { ReactModal } from 'App/Components/Common/Modal'
import { Reload } from 'App/Components/Common/Reload'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { AMS_BACKEND_API, AxiosInstance, isDate } from 'App/Config'
import { setCurrentPolicyRequest } from 'App/Redux/actions'
import dateFormat from 'App/Services/General/dateFormat'
import { Container, EditButton, FormTab, PageHeader } from 'App/Styled'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { UpdatePolicyRequestStatus } from '../UpdateRequest'
import { ImageModal } from 'App/Components/Common/ImageModal'
import FileSaver from 'file-saver'

const Attachment = ({ attachment }) => {
  const [preview, setPreview] = useState(false)
  const [currentImage, setCurrentImage] = useState(``)
  return (
    <>
      {preview && currentImage && (
        <ImageModal
          currentImage={currentImage}
          setPreview={setPreview}
          setCurrentImage={setCurrentImage}
        />
      )}
      {(attachment?.includes(`.jpg`) ||
        attachment?.includes(`.jpeg`) ||
        attachment?.includes(`.png`)) && (
        <img
          src={attachment}
          alt="Image failed to load"
          className="rounded-md h-24vh w-17vw cursor-pointer mt-2 "
          onClick={() => {
            setPreview(true)
            setCurrentImage(attachment)
          }}
        />
      )}
      {attachment?.includes(`.pdf`) && (
        <div className="block mb-2">
          <div
            className={`flex items-center justify-between  gap-1 w-auto text-red-500`}
          >
            {/* <span>Document</span> */}
            <div
              className="flex gap-2 tooltip tooltip-right"
              data-tip="Download file"
            >
              <FontAwesomeIcon
                icon={faFilePdf}
                size="10x"
                onClick={() => FileSaver.saveAs(attachment)}
                className="cursor-pointer shadow-md hover:text-red-700 "
              />
            </div>
          </div>
        </div>
      )}
      {(attachment?.includes(`.doc`) || attachment?.includes(`.docx`)) && (
        <div className="block mb-2">
          <div
            className={`flex items-center justify-between  gap-1 w-auto text-blue-500`}
          >
            {/* <span>Document</span> */}
            <div
              className="flex gap-2 tooltip tooltip-right"
              data-tip="Download file"
            >
              <FontAwesomeIcon
                icon={faFileWord}
                size="10x"
                onClick={() => FileSaver.saveAs(attachment)}
                className="cursor-pointer shadow-md hover:text-blue-700 "
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const PolicyRequest = () => {
  const { requestId } = useParams()

  const dispatch = useDispatch()

  const [generalTabs, setGeneralTab] = useState(``)
  const [activeGeneralTab, setActiveGeneralTab] = useState(1)
  const [updateStatusModal, setUpdateStatusModal] = useState(false)

  const {
    data: policyRequestData,
    isLoading,
    error,
    isFetching,
    refetch,
  } = useQuery(
    `PolicyRequest`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/policies/requests/${requestId}`,
      ).then((res) => {
        return res.data.data.request
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentPolicyRequest(res))
        if (res.carDetails.length > 0) {
          setGeneralTab(
            res.carDetails.map((car, index) => ({
              name: car.model,
              number: index + 1,
            })),
          )
        }
        if (res.coverageDetails.length > 0) {
          setGeneralTab(
            res.coverageDetails.map((coverage, index) => ({
              name: coverage.name,
              number: index + 1,
            })),
          )
        }
        if (res.driverDetails.length > 0) {
          setGeneralTab(
            res.driverDetails.map((driver, index) => ({
              name: driver.firstName,
              number: index + 1,
            })),
          )
        }
      },
    },
  )

  const {
    status,
    _customer: { label: customerName },
    _policy: { label: policyNo },
    requestFor,
    operation,
    _requestId,
    createdAt,
    description,
    remarks,
    carDetails,
    driverDetails,
    coverageDetails,
  } = policyRequestData || { _customer: {}, _policy: {} }

  const requestCardData = {
    status,
    'Customer Name': customerName,
    policyNo,
    'Request For': requestFor,
    operation,
    'Request Id': _requestId,
    'Created Date': dateFormat(createdAt),
  }

  return (
    <Container>
      {updateStatusModal && (
        <ReactModal
          title="Update Status"
          closeModal={() => setUpdateStatusModal(false)}
        >
          <UpdatePolicyRequestStatus
            selectedRequest={policyRequestData}
            closeModal={() => setUpdateStatusModal(false)}
            setRequestUpdated={() => refetch()}
          />
        </ReactModal>
      )}
      <PageHeader>
        <div>Policy Request</div>
        <EditButton
          onClick={() => setUpdateStatusModal(true)}
          disabled={status === `APPROVED` || isFetching || isLoading}
        >
          <span>Edit</span>
          <FontAwesomeIcon icon={faPen} className="text-white pt-1" size="sm" />
        </EditButton>
      </PageHeader>

      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <>
          <div className="rounded-md overflow-hidden py-4">
            <div className="flex items-start justify-evenly">
              <div className="flex-1 rounded">
                <div className="grid grid-cols-12 px-4 py-2">
                  <div className="col-span-6 text-xl">Request Info</div>
                </div>
                <hr />
                <div className="grid grid-cols-2 px-8">
                  {Object?.keys(requestCardData)?.map((key, index) => (
                    <CardField
                      key={index}
                      title={key}
                      value={requestCardData[key]}
                    />
                  ))}
                </div>

                <div className="grid grid-cols-2 px-8">
                  {remarks && (
                    <div>
                      <CardField title="Remarks" value={remarks} />
                    </div>
                  )}
                  {description && (
                    <div>
                      <CardField title="Descriptions" value={description} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {carDetails.length > 0 && (
              <>
                <div className="grid grid-cols-12 px-4 py-2">
                  <div className="col-span-6 text-xl">Car Details</div>
                </div>
                <hr />

                {carDetails?.length > 0 && (
                  <div className="my-2 mx-4">
                    <Tabs2
                      openTab={activeGeneralTab}
                      tabs={generalTabs}
                      getActiveTab={(val) => setActiveGeneralTab(val)}
                    />
                    {carDetails.map((car, idx) => {
                      const { attachment, ..._carDetails } = car
                      return (
                        <FormTab
                          key={idx}
                          currenttab={idx + 1}
                          opentab={activeGeneralTab}
                          className="gird grid-cols-2 mt-2 mx-4 shadow-sm rounded-md relative"
                        >
                          {Object.keys(_carDetails).map((key, index) => {
                            const _car = _carDetails[key]
                            return (
                              <CardField
                                key={index}
                                title={key}
                                value={isDate(_car) ? dateFormat(_car) : _car}
                              />
                            )
                          })}
                          <Attachment attachment={attachment} />
                        </FormTab>
                      )
                    })}
                  </div>
                )}
              </>
            )}
            {driverDetails.length > 0 && (
              <>
                <div className="grid grid-cols-12 px-4 py-2">
                  <div className="col-span-6 text-xl">Driver Details</div>
                </div>
                <hr />

                {driverDetails?.length > 0 && (
                  <div className="my-2 mx-4">
                    <Tabs2
                      openTab={activeGeneralTab}
                      tabs={generalTabs}
                      getActiveTab={(val) => setActiveGeneralTab(val)}
                    />
                    {driverDetails.map((driver, idx) => {
                      const { attachment, ..._driverDetails } = driver
                      return (
                        <FormTab
                          key={idx}
                          currenttab={idx + 1}
                          opentab={activeGeneralTab}
                          className="gird grid-cols-2 mt-2 mx-4 shadow-sm rounded-md relative"
                        >
                          {Object.keys(_driverDetails).map((key, index) => {
                            const _driver = _driverDetails[key]
                            return (
                              <CardField
                                key={index}
                                title={key}
                                value={
                                  isDate(_driver)
                                    ? dateFormat(_driver)
                                    : _driver
                                }
                              />
                            )
                          })}
                          <Attachment attachment={attachment} />
                        </FormTab>
                      )
                    })}
                  </div>
                )}
              </>
            )}
            {coverageDetails.length > 0 && (
              <>
                <div className="grid grid-cols-12 px-4 py-2">
                  <div className="col-span-6 text-xl">Coverage Details</div>
                </div>
                <hr />

                {coverageDetails?.length > 0 && (
                  <div className="my-2 mx-4">
                    <Tabs2
                      openTab={activeGeneralTab}
                      tabs={generalTabs}
                      getActiveTab={(val) => setActiveGeneralTab(val)}
                    />
                    {coverageDetails.map((coverage, idx) => {
                      const _coverageDetails = coverage
                      return (
                        <FormTab
                          key={idx}
                          currenttab={idx + 1}
                          opentab={activeGeneralTab}
                          className="gird grid-cols-2 mt-2 mx-4 shadow-sm rounded-md relative"
                        >
                          {Object.keys(_coverageDetails).map((key, index) => (
                            <CardField
                              key={index}
                              title={key}
                              value={_coverageDetails[key]}
                            />
                          ))}
                        </FormTab>
                      )
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </Container>
  )
}

export default PolicyRequest
