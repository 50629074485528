import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faSortUp,
  faSortDown,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { useQuery } from 'react-query'
// import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useHistory } from 'react-router-dom'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import dateFormat from 'App/Services/General/dateFormat'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import Filters from './Filters'

const tableColumns = [
  { label: `Request`, value: `_requestId` },
  { label: `Request Type`, value: `operation` },
  { label: `Request For`, value: `requestFor` },
  { label: `Status`, value: `status` },
  { label: `Customer`, value: `_customer.label` },
  { label: `CreatedAt`, value: `createdAt` },
]

export const AllRequests = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)

  const [isOffset, setIsOffset] = useState(``)
  const [requestFilters, setRequestFilters] = useState({
    _requestId: ``,
    status: ``,
    requestFor: ``,
  })

  const handleResetFilters = () => {
    setRequestFilters({
      _requestId: ``,
      status: ``,
      requestFor: ``,
    })
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: `selection`,
      },
    ])
  }

  const handleFiltersChange = (filters) => {
    setCurrentPage(1)
    setRequestFilters(filters)
  }

  //  date range picker
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
    },
  ])
  const handleDateRange = (range) => {
    setDateRange(range)
  }
  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }

    if (requestFilters?._requestId?.length) {
      data.filters._requestId = requestFilters?._requestId
    }
    if (requestFilters?.status?.value?.length) {
      data.filters.status = requestFilters?.status?.value
    }
    if (requestFilters?.requestFor?.value?.length) {
      data.filters.requestFor = requestFilters?.requestFor?.value
    }
    if (dateRange[0].isSelected) {
      data.filters.createdAt = [
        dateFormat(dateRange[0].startDate),
        dateFormat(dateRange[0].endDate),
      ]
    }
    return JSON.stringify(data)
  }

  const {
    url,
    params: { customerId },
  } = useRouteMatch()
  const history = useHistory()

  const {
    isLoading,
    data: policiesData,
    error,
    refetch: getAllRequests,
    isFetching,
  } = useQuery(
    `PoliciesRequestApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/policies/requests?search=${searchFilters()}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (!customSearching) {
      getAllRequests()
    }
  }, [sort, currentPage, isOffset, requestFilters])

  return (
    <Container>
      {/* {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )} */}
      <PageHeader>
        {customerId === undefined && <div>Requests</div>}

        <div className="flex">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>

          {/* <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              setValue={(e) => {
                setSearchQuery(e.target.value)
                setCurrentPage(1)
                setCustomSearching(true)
                callPoliciesQuery()
              }}
              disabled={isFetching}
            />
          </div> */}
        </div>
      </PageHeader>

      {customerId === undefined && (
        <Filters
          requestFilters={requestFilters}
          handleFilters={handleFiltersChange}
          resetFilters={handleResetFilters}
          _dateRange={dateRange}
          handleDateChange={handleDateRange}
        />
      )}

      <div className={`bg-white mb-1 sm:mb-0 justify-center items-center`}>
        {isLoading || isFetching ? (
          <div className="text-center py-10">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => getAllRequests()} />
        ) : (
          <>
            <div className="overflow-x-auto  mt-2 rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {/* {(permissionGranted([
                      `policies:read`,
                      `admin-franchise`,
                      `admin-master-franchise`,
                    ]) ||
                      permissionGranted([
                        `policies:delete`,
                        `admin-franchise`,
                        `admin-master-franchise`,
                      ])) && (
                      <TableCell className="float-right">Actions</TableCell>
                    )} */}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {policiesData?.requests?.length != 0 ? (
                    policiesData?.requests?.map((request, index) => (
                      <TableRow
                        key={index.toString()}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          if (
                            permissionGranted([
                              `policies:read`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])
                          ) {
                            history.push(`${url}/${request._id}`)
                          }
                        }}
                      >
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <span className="font-medium truncate">
                            <Badge>
                              {request._requestId ? request._requestId : `N/A`}
                            </Badge>
                          </span>
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate capitalize">
                          {request.operation ? (
                            request.operation
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          {request?.requestFor ? (
                            request?.requestFor
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          {request?.status ? (
                            request?.status
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          {request._customer?.label ? (
                            request._customer?.label
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          <span>{dateFormat(request.createdAt)}</span>
                        </td>
                        {/* Actions TD */}
                        {/* <td className="px-6 py-0.5 z-0 text-sm flex float-right text-gray-400">
                          {permissionGranted([
                            `policies:delete`,
                            `admin-franchise`,
                            `admin-master-franchise`,
                          ]) && (
                            <span data-tip="Delete" className="tooltip">
                              <FontAwesomeIcon
                                className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                icon={faTrash}
                                size="1x"
                                onClick={(e) => handleDelete(request._id, e)}
                              />
                            </span>
                          )}
                        </td> */}
                      </TableRow>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Policy Found</div>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={policiesData?.totalPages}
                totalRecords={policiesData?.totalRecords}
                curerntPageRecords={policiesData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
