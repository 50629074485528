/* eslint-disable no-unused-vars */
import {
  faMicrophone,
  faMicrophoneSlash,
  faPhone,
  faPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { smallLogo } from 'App/Assets'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import {
  setActiveCall,
  setCurrentCall,
  setCurrentCallStatus,
  setCurrentInvoice,
  setGlobalCallModalActive,
  setOutboundMessages,
  setUnknownCaller,
} from 'App/Redux/actions'
import {
  CallBlock,
  Container,
  FormTab,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { NotificationManager } from 'react-notifications'
import { useLocation } from 'react-router-dom'
import { Loader } from 'App/Components/Common/Loader'
import { newSocket } from 'App/HOC/MainLayout'
import { InputField } from 'App/Components/Common/InputField'
import {
  loadAgents,
  loadFeePaymentTemplate,
  loadPaymentFeeTypes,
  loadPaymentLocations,
  permissionGranted,
} from 'App/Services'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import {
  AddFormButton,
  RemoveDriver,
} from 'App/Components/Raters/QuoteForm/style'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'
import { paymentAmountTotal } from 'App/hooks'

export const PaymentCall = ({
  invoiceCall,
  customClass,
  topBar,
  closeModal,
  isCreateInvoice,
  refetchInvoices,
}) => {
  const { activeCall } = useSelector(({ customer }) => customer)
  const { communicationCredentials, currentActiveCall, currentIncomingCall } =
    useSelector(({ currentCommunication }) => currentCommunication)

  const {
    params: { customerId },
  } = useRouteMatch()

  const { currentCustomer } = useSelector(({ customer }) => customer)
  const { twilioDevice } = useSelector(({ communication }) => communication)
  const { user, preferredLocation } = useSelector(({ user }) => user)
  const [isCall, setIsCall] = useState(false)
  const [deviceStatus, setDeviceStatus] = useState(twilioDevice?.isBusy)
  const [loading, setLoading] = useState(false)
  const [incomingCall, setIncomingCall] = useState(``)
  const [outboundCall, setOutboundCall] = useState(``)
  const [isMuted, setIsMuted] = useState(false)
  const [amount, setAmount] = useState(``)
  const [language, setLanguage] = useState(``)
  const dispatch = useDispatch()
  const location = useLocation()
  const [seconds, setSeconds] = useState(0)
  const [errors, setErrors] = useState({
    amountError: ``,
    languageError: ``,
    insuredError: ``,
    policyError: ``,
    dueDateError: ``,
    carrierError: ``,
    agentError: ``,
    primaryOfficeError: ``,
    customerError: ``,
    salesInError: ``,
    salesTypeError: ``,
    lastSeenError: ``,
    amountMatchError: ``,
    formSubmit: false,
  })

  const { sub: agentId, full_name: agentName } = useSelector(
    ({ user }) => user.user.profile,
  )
  const selectedAgent = { label: agentName, value: agentId }

  //  if create payment invoice with call
  const [invoice, setInvoice] = useState({
    insured: ``,
    policy: ``,
    carrier: ``,
    dueDate: new Date(),
    description: ``,
  })

  const [feeTemplateList, setFeeTemplateList] = useState(``)
  const [policyAmount, setPolicyAmount] = useState(``)
  const [fees, setFees] = useState([
    {
      feeType: ``,
      feeTypeError: ``,
      amount: ``,
      amountError: ``,
    },
  ])

  const [transactions, setTransactions] = useState({
    transactionDate: new Date(),
    agent: selectedAgent,
    primaryOffice: {
      label: preferredLocation.name,
      value: preferredLocation._id,
    },
  })

  const premium = [
    `NB EFT TO COMPANY`,
    `END HOMEOWNER PREM`,
    `NB HOMEOWNER PREMIUM`,
    `PREMIUM TO ROBERT MKORENO`,
    `END COMMERCIAL EFT`,
    `MP EFT TO COMPANY`,
    `MP HOMEOWNER PREMIUM`,
    `Other`,
    `MP COMMERCIAL EFT`,
    `NB COMMERCIAL CHECK TO CO`,
    `END EFT TO COMPANY`,
    `RENEWAL PREMIUM`,
    `END COMMERCIAL CHECK TO CO`,
    `INSURED REFUND PREMIUM`,
    `NB COMMERCIAL EFT`,
    `RE-INSTATE PREMIUM`,
  ]

  const [fTabs, setFTabs] = useState([
    {
      tName: `Fee 1`,
      tNumber: 1,
    },
  ])

  const [activeFeeTab, setActiveFeeTab] = useState(1)

  const [paymentTemplateList, setPaymentTemplateList] = useState([])

  const loadLanguages = async () => {
    return {
      options: [
        { value: `english`, label: `English` },
        { value: `spanish`, label: `Spanish` },
      ],
      hasMore: false,
    }
  }

  const { mutate: addAmount } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/customers-chat/call-another`,
        {
          ...payload,
        },
      ).then((res) => {
        console.log(`res====>`, res)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const { mutate: holdApi } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/customers-chat/hold-participant`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res) {
          addAmount({
            amount: parseInt(amount),

            CallSid: twilioDevice?._activeCall?.parameters?.CallSid,

            ...(isCreateInvoice
              ? { invoiceData: createPaymentCallPayload() }
              : {
                  invoiceId: invoiceCall?._id,
                  remainingAmount: parseInt(invoiceCall?.remainingAmount),
                  paidAmount: parseInt(invoiceCall?.paidAmount),
                }),
            language: language.value,
            callInfo: {
              To: invoiceCall?._insured?.phone,
              From: preferredLocation.phone,
              _agentId: user?.profile?.sub,
              customerId: customerId,
              agentName: user?.profile?.full_name,
              agentImg: user?.profile?.dp,
              _franchise: user?.profile?._franchise?._id,
              _location: preferredLocation?._id,
              _direction: `OUTBOUND`,
            },
          })
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const {
    mutate: unHoldApi,
    // isLoading,
    // isError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/customers-chat/hold-participant`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res) {
          console.log(`res`, res)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const { mutate: muteApi } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/customers-chat/mute-participant`,
        {
          ...payload,
        },
      ).then((res) => {
        console.log(`res`, res)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const callMute = () => {
    setIsMuted(!isMuted)
    muteApi({
      isMute: !isMuted,
      CallSid: twilioDevice?._activeCall?.parameters?.CallSid,
      customerId: customerId,
    })
  }
  const startCall = async () => {
    if (twilioDevice && !twilioDevice?.isBusy) {
      twilioDevice.on(`error`, (twilioError) => {
        if (twilioError.code === 31005) {
          endCall()
          twilioDevice?.disconnectAll()
        }

        NotificationManager.error(`An error has occurred: `, `Try Again!`)
      })
      setLoading(true)
      if (!deviceStatus) {
        const call = await twilioDevice.connect({
          params: {
            To:
              invoiceCall?._insured?.phone ??
              currentCustomer?.contactInfo?.phone,
            From: preferredLocation.phone,
            callType: `Conference`,
            customerId: customerId,
            _agentId: user?.profile?.sub,
            agentName: user?.profile?.full_name,
            agentImg: user?.profile?.dp,
            _franchise: user?.profile?._franchise?._id,
            _location: preferredLocation?._id,
            _direction: `OUTBOUND`,
          },
        })
        setOutboundCall(call)
      }
      dispatch(setActiveCall(true))
      // dispatch(setUnknownCaller(false))
      setLoading(false)
      setDeviceStatus(twilioDevice?.isBusy)
      dispatch(setCurrentInvoice(invoiceCall))
      dispatch(
        setCurrentCall({
          callerId: communicationCredentials?.id,
          activeCall: twilioDevice?.isBusy,
        }),
      )
    }
  }

  useEffect(() => {
    if (outboundCall) {
      outboundCall.on(`error`, () => {
        endCall()
        NotificationManager.error(`An error has occurred: `, `Try Again!`)
        setOutboundCall(``)
      })
    }
  }, [outboundCall])

  useEffect(() => {
    if (newSocket) {
      newSocket?.on(`PAYMENT_RES`, (message) => {
        NotificationManager.success(message.replace(`,`, ``))
        refetchInvoices()
        setIsCall(false)
        unHoldApi({
          isMute: false,
          CallSid: twilioDevice?._activeCall?.parameters?.CallSid,
          customerId: customerId,
        })
      })
      newSocket?.on(`PAYMENT_CANCEL`, (message) => {
        NotificationManager.error(message.replace(`,`, ``))
        refetchInvoices()
        setIsCall(false)
        unHoldApi({
          isMute: false,
          CallSid: twilioDevice?._activeCall?.parameters?.CallSid,
          customerId: customerId,
        })
      })

      newSocket?.on(`OUTBOUND_VOICE_CALL_RES`, (message) => {
        if (
          message?._id &&
          communicationCredentials?.id === message?._customerId
        ) {
          dispatch(setOutboundMessages(message))
        }
        dispatch(setCurrentCallStatus(message?.status))
        if (
          message?.status === `no-answer` ||
          message?.status === `completed` ||
          message?.status === `busy` ||
          message?.status === `failed` ||
          message?.status === `cancelled`
        ) {
          endCall()
          dispatch(setActiveCall(false))
          dispatch(setGlobalCallModalActive(false))
          setDeviceStatus(twilioDevice?.isBusy)
          dispatch(
            setCurrentCall({
              callerId: ``,
              activeCall: twilioDevice?.isBusy,
            }),
          )
        }
      })
    }

    return () => {
      newSocket?.off(`PAYMENT_RES`)
      newSocket?.off(`PAYMENT_CANCEL`)
      newSocket?.off(`OUTBOUND_VOICE_CALL_RES`)
    }
  }, [newSocket?.connected])

  const endCall = async () => {
    twilioDevice?.disconnectAll()
    dispatch(setActiveCall(false))
    setDeviceStatus(twilioDevice?.isBusy)
    dispatch(
      setCurrentCall({
        callerId: ``,
        activeCall: twilioDevice?.isBusy,
      }),
    )
    dispatch(setGlobalCallModalActive(false))
    dispatch(setUnknownCaller(false))
    dispatch(setCurrentInvoice(``))
    closeModal()
    setOutboundCall(``)
  }

  useEffect(() => {
    if (!outboundCall && !twilioDevice?.isBusy) {
      startCall()
    }
  }, [])
  // call custom hook to get sum of fees amount
  const totalAmountToBePaid = paymentAmountTotal(fees)

  const validateFrom = () => {
    let totalFees = 0
    let invoiceAmount = 0

    for (const index in fees) {
      const fee = fees[index]

      invoiceAmount = invoiceAmount + JSON.parse(fee.amount ? fee.amount : 0)

      if (!fee.feeType?.value?.length) {
        handleFeesErrors(`feeTypeError`, `Select Fee Type`, index)
      } else {
        handleFeesErrors(`feeTypeError`, ``, index)
      }

      if (!fee.amount) {
        handleFeesErrors(`amountError`, `Enter Amount`, index)
      } else if (fee.amount && JSON.parse(fee.amount) <= 0) {
        handleFeesErrors(`amountError`, `Amount must be greater than 0`, index)
      } else {
        handleFeesErrors(`amountError`, ``, index)
        totalFees += parseFloat(fee.amount)
      }

      if (
        isCreateInvoice &&
        amount?.length &&
        !(parseFloat(amount) <= totalFees)
      ) {
        handleErrors(`amountError`, `Amount should be ${totalFees} or less`)
      }
      // if (
      //   fee.amount &&
      //   premium?.includes(fee?.feeType?.value) &&
      //   fee?.amount > policyAmount
      // ) {
      //   handleFeesErrors(
      //     `amountError`,
      //     `Payment Amount Must be less than Policy amount`,
      //     index,
      //   )
      // }
    }

    if (!amount?.length) {
      handleErrors(`amountError`, `Enter Amount`)
    } else if (isCreateInvoice && parseFloat(amount) > totalAmountToBePaid) {
      handleErrors(
        `amountError`,
        `Amount should be ${totalAmountToBePaid} or less`,
      )
    } else {
      handleErrors(`amountError`, ``)
    }
    if (!language?.value?.length) {
      handleErrors(`languageError`, `Select Language`)
    } else {
      handleErrors(`languageError`, ``)
    }

    if (!invoice.carrier?.value?.length) {
      handleErrors(`carrierError`, `Select Carrier`)
    } else {
      handleErrors(`carrierError`, ``)
    }

    if (!invoice.policy?.value?.length) {
      handleErrors(`policyError`, `Select Policy`)
    } else {
      handleErrors(`policyError`, ``)
    }

    if (!invoice.dueDate) {
      handleErrors(`dueDateError`, `Select Due Date`)
    } else {
      handleErrors(`dueDateError`, ``)
    }

    if (!invoice.agent?.value?.length) {
      handleErrors(`invoiceAgentError`, `Select Agent`)
    } else {
      handleErrors(`invoiceAgentError`, ``)
    }

    if (!transactions.agent?.value?.length) {
      handleErrors(`agentError`, `Select Agent`)
    } else {
      handleErrors(`agentError`, ``)
    }

    if (
      !transactions.primaryOffice?.value?.length &&
      !preferredLocation.value
    ) {
      handleErrors(`primaryOfficeError`, `Select Office`)
    } else {
      handleErrors(`primaryOfficeError`, ``)
    }

    setErrors((preState) => ({
      ...preState,
      formSubmit: true,
    }))
  }
  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  // useEffect(() => {
  //   if (twilioDevice && currentActiveCall?.status !== `in-progress`) {
  //     twilioDevice.on(`incoming`, (call) => {
  //       setIncomingCall(call)
  //     })
  //   }
  // }, [twilioDevice])

  useEffect(() => {
    let feeFormValidated = []
    let isFormValidated = false
    if (
      errors.formSubmit &&
      !errors.amountError.length &&
      !errors.policyError?.length &&
      !errors.dueDateError?.length &&
      !errors.agentError?.length &&
      !errors.primaryOfficeError?.length
    ) {
      isFormValidated = true
    }

    if (isFormValidated) {
      feeFormValidated = fees?.map((fee) => {
        if (!fee.feeTypeError.length && !fee.amountError.length) {
          return true
        }
      })
    }
    // for create new invoice on call
    if (!feeFormValidated.includes(undefined) && isFormValidated) {
      setIsCall(true)
      holdApi({
        isMute: true,
        CallSid: twilioDevice?._activeCall?.parameters?.CallSid,
        customerId: customerId,
      })
    }
    // for existing invoice
    if (errors.formSubmit && !errors.amountError.length && !isCreateInvoice) {
      setIsCall(true)
      holdApi({
        isMute: true,
        CallSid: twilioDevice?._activeCall?.parameters?.CallSid,
        customerId: customerId,
      })
    }
  }, [errors])

  useEffect(() => {
    let timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1)
    }, 1000)

    if (currentActiveCall?.status === `ringing` && seconds === 40) {
      endCall() // Replace with your desired action or component update
    }

    return () => {
      clearInterval(timer)
      timer = null
    }
  }, [seconds, currentActiveCall?.status])
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)

  const handleInvoice = (value, name, ind) => {
    if (ind !== undefined) {
      const newFees = fees?.map((fee, index) => {
        if (index === ind) {
          fee[name] = value
        }
        return fee
      })
      setFees(newFees)
    } else {
      setInvoice((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const { mutate: getPolicy, isLoading: loadingPolicy } = useMutation(
    `PolicyApi`,
    async (payload) =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/policies/minimal/${payload}`,
      ).then((res) => {
        return res.data.data.policy
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setPolicyAmount(res.remainingAmount)

        handleInvoice(
          { value: res?._creator?._id, label: res?._creator?.name },
          `agent`,
        )
        handleInvoice(
          { value: res?._customer?._id, label: res?._customer?.name },
          `insured`,
        )
        handleInvoice(
          { value: res?._carrier?._id, label: res?._carrier?.name },
          `carrier`,
        )
      },
    },
  )

  // fee validation
  useEffect(() => {
    if (invoice.policy) {
      getPolicy(invoice?.policy?.value)
    }
  }, [invoice.policy])

  useEffect(() => {
    if (!paymentTemplateList.length) {
      setFees([
        {
          feeType: ``,
          feeTypeError: ``,
          amount: ``,
          amountError: ``,
        },
      ])

      setFTabs([
        {
          tName: `Fee 1`,
          tNumber: 1,
        },
      ])
    } else {
      const feesList = paymentTemplateList?.map((_fee) => ({
        feeType: { value: _fee?.type, label: _fee?.type },
        feeTypeError: ``,
        amount: _fee?.amount.toString(),
        amountError: ``,
        disabled: true,
      }))

      setFees(feesList)

      const feeTabs = paymentTemplateList?.map((_fee, index) => ({
        tName: `Fee ${index + 1}`,
        tNumber: index + 1,
      }))

      setFTabs([...feeTabs])
    }
  }, [paymentTemplateList])

  useEffect(() => {
    setActiveFeeTab(fTabs.length)
  }, [fTabs.length])
  const handleFeesErrors = (name, error, ind) => {
    if (ind !== undefined) {
      const newFees = fees?.map((fee, index) => {
        if (index == ind) {
          fee[name] = error
        }
        return fee
      })
      setFees(newFees)
    }
  }

  const addFee = () => {
    setFees([
      ...fees,
      {
        feeType: ``,
        feeTypeError: ``,
        amount: ``,
        amountError: ``,
      },
    ])

    setFTabs([
      ...fTabs,
      {
        tName: `Fee ${fTabs.length + 1}`,
        tNumber: fTabs.length + 1,
      },
    ])
  }

  const removeFee = (ind) => {
    const newFees = [...fees]
    if (newFees.length != 1) {
      newFees.splice(ind, 1)
      setFees(newFees)
    }
    const newFeesTab = [...fTabs]
    if (newFeesTab.length !== 1) {
      newFeesTab.pop()
      setFTabs(newFeesTab)
    }
  }

  const handleTransactions = (value, name) => {
    setTransactions((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const loadPaymentPolicies = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/customers/${customerId}/policies/list/?search=${
        search && search
      }&page=${page}&offset=10`,
    )

    if (data) {
      return {
        options: data.policies,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  const { mutate: mutatePaymentTemplate, isLoading: isLoadingPaymentTemplate } =
    useMutation(
      async (templateId) =>
        await AxiosInstance.get(
          `${AMS_BACKEND_API}/api/payment-templates/${templateId}`,
        ).then((res) => {
          return res?.data?.data?.paymentTemplate
        }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (response) => {
          if (response) {
            setPaymentTemplateList(response?.templates)
          }
        },
      },
    )

  const createPaymentCallPayload = () => {
    let data = {
      _insured: customerId,
      issueDate: new Date(),
      // additional: {},
      payments: {},
    }

    if (invoice?.policy?.value) {
      data._policy = invoice.policy.value
    }
    // if (invoice?.carrier?.value) {
    //   data._carrier = invoice.carrier.value
    // }
    // if (invoice?.agent?.value) {
    //   data._agent = invoice.agent.value
    // }
    if (invoice?.dueDate) {
      data.dueDate = invoice.dueDate
    }
    if (invoice?.policy?.description) {
      data.description = invoice.policy.description
    }

    data.items = fees.map((fee) => {
      return {
        feeType: fee?.feeType.value,
        amount: parseFloat(fee?.amount),
      }
    })
    if (feeTemplateList.value) {
      data.paymentTemplateId = feeTemplateList.value
    }
    if (transactions.transactionDate) {
      data.payments.transactionDate = transactions.transactionDate
    }
    if (transactions?.agent?.value) {
      data.payments._agent = transactions?.agent?.value
    }

    if (transactions?.primaryOffice?.value) {
      data.payments._location = transactions?.primaryOffice?.value
    }

    return data
  }

  useEffect(() => {
    if (
      feeTemplateList.label &&
      feeTemplateList?.label?.toUpperCase() != `NONE`
    ) {
      mutatePaymentTemplate(feeTemplateList?.value)
    } else {
      setFees([
        {
          feeType: ``,
          feeTypeError: ``,
          amount: ``,
          amountError: ``,
        },
      ])

      setFTabs([
        {
          tName: `Fee 1`,
          tNumber: 1,
        },
      ])
    }
  }, [feeTemplateList])
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {communicationCredentials?.preferredContactMethods?.length &&
          !communicationCredentials?.preferredContactMethods?.includes(
            `CALL`,
          ) ? (
            <div className="flex justify-center items-center h-96 max-h-xl font-semibold text-sm text-gray-500 w-80">
              <p>Not Preferred</p>
            </div>
          ) : communicationCredentials?.phone || topBar ? (
            <div
              className={`flex flex-col justify-center items-center ${
                activeCall ? `gap-20 ` : `gap-40 px-12`
              } pt-14 `}
            >
              {!isCreateInvoice && (
                <div className="flex items-center px-14 gap-2 w-auto relative">
                  <img
                    className="bg-gray-100 z-10 border-1 object-cover rounded-full h-20 w-20 "
                    alt="fImage"
                    src={
                      communicationCredentials?.img
                        ? communicationCredentials?.img
                        : smallLogo
                    }
                  />
                  {(activeCall &&
                    location?.pathname.split(`/`)[2] ===
                      currentActiveCall?.callerId) ||
                    (activeCall &&
                      location?.pathname.split(`/`)[3] ===
                        currentActiveCall?.callerId &&
                      currentActiveCall?.activeCall && (
                        <div className="absolute mx-auto  h-20 w-20 rounded-full bg-gray-500  animate-call"></div>
                      ))}
                </div>
              )}

              <div className="flex flex-col items-center m-1 px-6 gap-6 -mt-16  w-auto ">
                {twilioDevice?.isBusy ? (
                  //   location?.pathname.split(`/`)[2] ===
                  //     currentActiveCall?.callerId) ||
                  // (activeCall &&
                  //   location?.pathname.split(`/`)[3] ===
                  //     currentActiveCall?.callerId) ||
                  <div className={`${isCreateInvoice && `flex`}`}>
                    {isCreateInvoice && (
                      <div className="w-full">
                        {/* <AddPayment
                          isCreateInvoice={isCreateInvoice}
                          ref={addPaymentRef}
                          getPaymentPayload={(data) => {
                            setPaymentPayloadData(data)
                          }}
                        /> */}
                        <Container>
                          <PageHeader>
                            <div>Generate Payment</div>
                            <div className="col-span-6 justify-self-end">
                              {policyAmount?.toString().length > 0 && (
                                <div>Remaining: ${policyAmount}</div>
                              )}
                            </div>
                          </PageHeader>

                          <div className={`p-3 bg-white `}>
                            <PageHeader className="mt-4">
                              <div>Invoice</div>
                            </PageHeader>
                            <div className="px-2">
                              <div
                                className={`grid ${
                                  isCreateInvoice
                                    ? `grid-cols-3`
                                    : `grid-cols-4`
                                } gap-6 mt-2`}
                              >
                                <div className="flex-1 relative">
                                  <ReactSelectField
                                    title="Policy*"
                                    placeholder="Select Policy"
                                    errorMessage={errors.policyError}
                                    value={invoice.policy}
                                    setValue={(value) =>
                                      handleInvoice(value, `policy`)
                                    }
                                    isMulti={false}
                                    loadOptions={loadPaymentPolicies}
                                    validating={loadingPolicy}
                                    autoFocus
                                    isSearchable={false}
                                  />
                                </div>

                                <div className="flex-1 relative">
                                  <InputField
                                    type="text"
                                    title="Description"
                                    value={invoice.description}
                                    setValue={(e) =>
                                      handleInvoice(
                                        e.target.value,
                                        `description`,
                                      )
                                    }
                                    placeholder="Enter Value"
                                  />
                                </div>
                                <div className="flex-1 relative">
                                  <DatePickerField
                                    title="Due Date*"
                                    placeholder="Select Date"
                                    value={invoice.dueDate}
                                    errorMessage={errors.dueDateError}
                                    setValue={(date) =>
                                      handleInvoice(date, `dueDate`)
                                    }
                                    minDate={new Date()}
                                  />
                                </div>

                                <div className="flex-1 relative">
                                  <ReactSelectField
                                    title="Fee Template"
                                    placeholder="Select Template"
                                    value={feeTemplateList}
                                    setValue={(value) =>
                                      setFeeTemplateList(value)
                                    }
                                    isMulti={false}
                                    loadOptions={loadFeePaymentTemplate}
                                    validating={isLoadingPaymentTemplate}
                                  />
                                </div>
                                <div className="flex-1 relative"></div>
                              </div>
                              <SecondaryHeading>Fees</SecondaryHeading>
                              {/* tabs here */}
                              <div className="flex items-center">
                                <TabsHeaders
                                  openTab={activeFeeTab}
                                  tabs={fTabs}
                                  getActiveTab={(val) => setActiveFeeTab(val)}
                                  customTabs="YES"
                                />
                                <div className="px-2">
                                  <AddFormButton
                                    onClick={() => {
                                      addFee()
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPlus} />
                                  </AddFormButton>
                                </div>
                              </div>
                              {/* tabs end herer */}
                              {fees?.length > 0 &&
                                fees?.map((item, index) => (
                                  <FormTab
                                    key={index}
                                    currenttab={index + 1}
                                    opentab={activeFeeTab}
                                    className="border-2 border-gray-100 shadow-sm rounded-md relative"
                                  >
                                    <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                                      <div className="flex-1">
                                        Fee {index + 1}
                                      </div>
                                      <div className="flex-1">
                                        <div className="float-right">
                                          <RemoveDriver
                                            onClick={() => {
                                              if (!item?.disabled) {
                                                removeFee(index)
                                                if (index > 0)
                                                  setActiveFeeTab(index)
                                              }
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTimes}
                                              size="1x"
                                            />
                                          </RemoveDriver>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="grid grid-cols-12 px-2 pb-4">
                                      <div className="col-span-12 flex gap-6 ">
                                        <div className="flex-1 relative">
                                          <ReactSelectField
                                            title="Memos Type*"
                                            placeholder="Select Type"
                                            value={item.feeType}
                                            errorMessage={item.feeTypeError}
                                            setValue={(e) =>
                                              handleInvoice(e, `feeType`, index)
                                            }
                                            isMulti={false}
                                            loadOptions={loadPaymentFeeTypes}
                                            isSearchable={true}
                                            disabled={item?.disabled}
                                            additionalFilters={fees}
                                            cacheUniqs={fees}
                                          />
                                        </div>
                                        <div className="flex-1 relative">
                                          <InputField
                                            type="number"
                                            title="Amount*"
                                            placeholder="Enter Amount"
                                            value={item.amount}
                                            errorMessage={item.amountError}
                                            setValue={(e) =>
                                              handleInvoice(
                                                e.target.value,
                                                `amount`,
                                                index,
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </FormTab>
                                ))}
                            </div>

                            <div className="col-span-6 float-right ">
                              {totalAmountToBePaid ? (
                                <div>
                                  Total memos amount: ${totalAmountToBePaid}
                                </div>
                              ) : null}
                            </div>

                            <PageHeader className="mt-4">
                              <div>Transactions</div>
                            </PageHeader>
                            <div className="px-2">
                              <div className="flex gap-6 mt-2">
                                <div className="flex-1 relative">
                                  <DatePickerField
                                    title="Transaction Date"
                                    placeholder="Select Date"
                                    value={transactions.transactionDate}
                                    setValue={(date) =>
                                      handleTransactions(
                                        date,
                                        `transactionDate`,
                                      )
                                    }
                                    disabled={true}
                                  />
                                </div>
                                <div className="flex-1 relative">
                                  <ReactSelectField
                                    title="Agent*"
                                    placeholder="Select Agent"
                                    errorMessage={errors.agentError}
                                    value={transactions?.agent}
                                    setValue={(value) =>
                                      handleTransactions(value, `agent`)
                                    }
                                    isMulti={false}
                                    loadOptions={
                                      permissionGranted([`admin:*`]) &&
                                      loadAgents
                                    }
                                    cacheUniqs={transactions?.primaryOffice}
                                    disabled={!permissionGranted([`admin:*`])}
                                    additionalFilters={{
                                      franchiseId: _franchise?._id,
                                      locations: [
                                        transactions?.primaryOffice?.value,
                                      ],
                                    }}
                                  />
                                </div>
                                <div className="flex-1 relative">
                                  <ReactSelectField
                                    title="Primary Office*"
                                    placeholder="Select Office"
                                    errorMessage={errors.primaryOfficeError}
                                    value={transactions?.primaryOffice}
                                    setValue={(value) => {
                                      handleTransactions(value, `primaryOffice`)
                                      handleTransactions(``, `agent`)
                                    }}
                                    isMulti={false}
                                    loadOptions={
                                      permissionGranted([`admin:*`]) &&
                                      loadPaymentLocations
                                    }
                                    isSearchable={true}
                                    disabled={!permissionGranted([`admin:*`])}
                                  />
                                </div>
                              </div>
                              <div className="flex-1 relative"></div>
                            </div>
                          </div>
                        </Container>
                      </div>
                    )}
                    <div
                      className={`flex flex-col  items-center ${
                        isCreateInvoice ? `justify-end pb-20` : `justify-center`
                      }`}
                    >
                      {totalAmountToBePaid && amount && (
                        <div className="mb-16 text-sm">
                          Remaining amount: $
                          {(totalAmountToBePaid - amount).toFixed(2)}
                        </div>
                      )}

                      <div className="flex flex-col px-4">
                        {/* <Container className="w-full"> */}
                        <div
                          className={`flex ${
                            isCreateInvoice
                              ? `flex-col gap-1
                          `
                              : `gap-4`
                          } `}
                        >
                          <div
                            className={`relative ${
                              isCreateInvoice ? `w-full` : `w-2/3`
                            } `}
                          >
                            <ReactSelectField
                              title="Language"
                              placeholder="Select Language"
                              setValue={(value) => {
                                setLanguage(value)
                              }}
                              loadOptions={loadLanguages}
                              errorMessage={errors.languageError}
                              isMulti={false}
                              isSearchable={true}
                            />
                          </div>
                          <div
                            className={`relative ${
                              isCreateInvoice ? `w-full` : `w-2/5 pb-4`
                            } `}
                          >
                            <InputField
                              title="Amount"
                              type="number"
                              placeholder="Enter Amount"
                              setValue={(e) => setAmount(e.target.value)}
                              value={amount}
                              errorMessage={errors.amountError}
                            />
                          </div>
                        </div>
                        <SubmitButton
                          onClick={validateFrom}
                          disabled={
                            isCall ||
                            !currentActiveCall?.status?.includes(`progress`)
                          }
                          className="rounded-lg  mt-1"
                        >
                          <span className="text-sm ">Add</span>
                        </SubmitButton>
                      </div>
                      <div>
                        <CallBlock className="capitalize">
                          {currentActiveCall?.status === undefined ||
                          currentActiveCall?.status === `initiated`
                            ? `Calling...`
                            : currentActiveCall?.status}
                        </CallBlock>
                      </div>
                      <div className="space-x-10">
                        <CallBlock
                          data-tip={`${isMuted ? `Unmute` : `Mute`}`}
                          className={`tooltip hover:text-gray-600 ${
                            isMuted ? `text-red-600` : `text-gray-600`
                          }`}
                          onClick={callMute}
                        >
                          <FontAwesomeIcon
                            icon={isMuted ? faMicrophoneSlash : faMicrophone}
                            className="cursor-pointer  hover:text-gray-800"
                            size="lg"
                          />
                        </CallBlock>
                        <CallBlock
                          data-tip="Hang Up"
                          className="tooltip hover:text-red-500"
                          onClick={() => {
                            endCall()
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPhone}
                            className="cursor-pointer "
                            size="lg"
                          />
                        </CallBlock>
                      </div>
                    </div>
                  </div>
                ) : incomingCall?._status === `open` &&
                  currentIncomingCall === `in-progress` ? (
                  <span>Incoming Call is in Progress</span>
                ) : (
                  <span>Call is in Progress</span>
                )}
              </div>
            </div>
          ) : (
            <div
              className={`${customClass} flex justify-center items-center h-96 max-h-xl font-semibold text-sm text-gray-500`}
            >
              <p>Contact info is not provided</p>
            </div>
          )}
        </>
      )}
    </>
  )
}
