import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadLobs = async () => {
  const {
    data: { data },
  } = await AxiosInstance.post(`${AMS_BACKEND_API}/api/get/lobs`, {
    selectedLobs: [],
  })

  if (data) {
    return {
      options: data.lobs,
      hasMore: false,
    }
  }
}
