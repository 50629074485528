import { Reload } from 'App/Components/Common/Reload'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { Spinner } from 'App/Components/Common/Spinner'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { setNonCustomerNumber } from 'App/Redux/actions'
import { newSocket } from 'App/HOC/MainLayout'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce/lib'

export const AllCustomer = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customerList, setCustomerList] = useState([])
  const [searchQuery, setSearchQuery] = useState(``)
  const [sort] = useState({
    sorting: false,
    activeSort: ``,
  })

  const { number } = useSelector(
    ({ globalCommunications }) => globalCommunications,
  )

  const dispatch = useDispatch()
  //   getting all customers
  const {
    isLoading,
    // data: customersData,
    refetch: getAllCustomers,
    isFetching,
    error,
  } = useQuery(
    `CustomersNameApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/customers-chat/unknown-numbers?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }", "onlyMasterCustomers": ${false}}&sort="${
          sort.sorting ? sort.activeSort : ``
        }"&page=${currentPage}&offset=${offset}`,
      ).then((res) => {
        return res.data.data.unknownNumbers
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomerList(res)
      },
    },
  )

  const callCustomersQuery = useDebouncedCallback(() => {
    getAllCustomers()
  }, 700)
  // const testRef = useRef(true)
  useEffect(() => {
    if (newSocket) {
      newSocket?.on(`NUMBERS_LIST`, (message) => {
        setCustomerList(message)
      })
    }
    return () => {
      newSocket?.off(`INBOUND_MESSAGE`)
      newSocket?.off(`NUMBERS_LIST`)
      // dispatch(clearAllMessage())
    }
  }, [newSocket])
  return (
    <div className="h-88vh overflow-y-scroll">
      <div className="px-2 pt-2 relative">
        <SearchInputField
          type="text"
          placeholder="Search ..."
          value={searchQuery}
          setValue={(e) => {
            setSearchQuery(e.target.value)
            setCurrentPage(1)
            callCustomersQuery()
          }}
          disabled={isFetching}
          // validating={isFetching}
        />
      </div>
      <Spinner loading={isLoading} />
      <div
        className={`px-2 py-1  ${
          isLoading &&
          `opacity-30 pointer-events-none overflow-y-scroll hide-scroller hide-scroller-firefox`
        }`}
      >
        {error ? (
          <Reload refetch={() => getAllCustomers()} />
        ) : customerList?.length != 0 ? (
          customerList?.map((customer, index) => (
            <div
              className={`relative h-14 my-2 shadow-sm border-1 rounded-md cursor-pointer font-bold flex px-2 hover:bg-blue-300 
                ${
                  index ===
                  customerList?.findIndex(
                    (customer) => customer?.number === number,
                  )
                    ? `bg-blue-500 text-white hover:bg-blue-500 hover:text-white`
                    : ``
                } overflow-y-scroll hide-scroller hide-scroller-firefox`}
              key={index}
              onClick={() => {
                dispatch(setNonCustomerNumber(customer?.number))
                // setCurrentActive(false)
              }}
            >
              {/* <div>
                  <UserImage
                    alt="profile"
                    src={customer.dp ? customer.dp : defaultImage}
                    style={{ height: `40px`, width: `40px` }}
                  />
                </div> */}
              <div className="py-4 px-2">{customer?.number}</div>
              {customer?.unread !== 0 && (
                <span className="absolute top-5 right-2 -mr-0.5 px-1  bg-red-500 rounded-full text-xs text-white">
                  {customer?.unread > 9 ? `9+` : customer?.unread}
                </span>
              )}
            </div>
          ))
        ) : (
          <div className="text-center p-6 py-96 text-xs text-gray-400">
            No Communications
          </div>
        )}
      </div>
    </div>
  )
}
