import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import searchQueryParser from 'App/Services/General/searchQueryParser'

export const loadCampaignTemplates = async (
  search,
  loadedOptions,
  { page },
) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AMS_BACKEND_API}/api/templates?${
      search.length ? `search=${searchQueryParser(search)}&` : ``
    }sort=&page=${page}&offset=${offset}`,
  )
  if (data?.templates) {
    const formattedtemplateNames = data?.templates.map((template) => {
      return {
        value: template?._id,
        label: template?.name,
      }
    })
    return {
      options: formattedtemplateNames,
      hasMore: data.totalPages - page?.page > 0,
      additional: {
        page: page?.page + 1,
      },
    }
  }
}
