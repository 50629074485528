import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { setTaskActivityActiveTab } from 'App/Redux/actions'
import { TOne, TTwo, TThree } from 'App/Styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { TaskNote } from '../../TaskNote'
import { MediaFiles } from '../Media'
import { permissionGranted } from 'App/Services'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  {
    name: `Logs`,
    number: 2,
  },
  {
    name: `Media`,
    number: 3,
  },
]

const activityTabs2 = [
  {
    name: `Notes`,
    number: 1,
  },
  {
    name: `Logs`,
    number: 2,
  },
]

const activityTabs3 = [
  {
    name: `Logs`,
    number: 1,
  },
  {
    name: `Media`,
    number: 2,
  },
]
const activityTabs4 = [
  {
    name: `Logs`,
    number: 1,
  },
]
export const TaskActivity = () => {
  const { currentTask, activeActivityTab } = useSelector(({ tasks }) => tasks)

  const dispatch = useDispatch()
  return (
    <div className="px-1">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([`uploadFile:read`]) &&
          !permissionGranted([`notes:read`])
            ? activityTabs3
            : permissionGranted([`notes:read`]) &&
              !permissionGranted([`uploadFile:read`])
            ? activityTabs2
            : permissionGranted(
                [
                  `notes:read`,
                  `corporate-manager:*`,
                  `manager:*`,
                  `admin:*`,
                ] && [
                  `uploadFile:read`,
                  `corporate-manager:*`,
                  `manager:*`,
                  `admin:*`,
                ],
              )
            ? activityTabs
            : activityTabs4
        }
        getActiveTab={(val) => dispatch(setTaskActivityActiveTab(val))}
      />

      {permissionGranted([`uploadFile:read`]) &&
      !permissionGranted([`notes:read`]) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <UpdateLogs
              logs={currentTask?.updateLogs ? currentTask?.updateLogs : []}
            />
          </TOne>
          <TTwo opentab={activeActivityTab}>
            <MediaFiles />
          </TTwo>
        </div>
      ) : permissionGranted([`notes:read`]) &&
        !permissionGranted([`uploadFile:read`]) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <TaskNote />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <UpdateLogs
              logs={currentTask?.updateLogs ? currentTask?.updateLogs : []}
            />
          </TTwo>
        </div>
      ) : permissionGranted(
          [`notes:read`, `corporate-manager:*`, `manager:*`, `admin:*`] && [
            `uploadFile:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ],
        ) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <TaskNote />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <UpdateLogs
              logs={currentTask?.updateLogs ? currentTask?.updateLogs : []}
            />
          </TTwo>
          <TThree opentab={activeActivityTab}>
            <MediaFiles />
          </TThree>
        </div>
      ) : (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <UpdateLogs
              logs={currentTask?.updateLogs ? currentTask?.updateLogs : []}
            />
          </TOne>
        </div>
      )}
    </div>
  )
}
