import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from '../style'
import { PolicyReportPdfHeader } from 'App/Components/Common/PolicyReportPdfHeader'
import dateFormat from 'App/Services/General/dateFormat'

export const Pdf = ({ carrierData, dateRange = [] }) => {
  return (
    <Document subject="Report" author="Reports">
      <Page style={styles.page} size="A3" wrap>
        <View style={styles.section}>
          <Text style={styles.pageHeading}>Policies Overviewed By Insured</Text>
          <Text style={styles.smallSpace}></Text>
          <PolicyReportPdfHeader dateRange={dateRange} />
          <Text style={styles.smallSpace}></Text>

          <View style={styles.headingRow}>
            <Text style={styles.pageTitles}> Policy No</Text>
            <Text style={styles.pageTitles}> LOB</Text>
            <Text style={styles.pageTitles}> Insured</Text>
            <Text style={styles.pageTitles}> Eff. Date</Text>
            <Text style={styles.pageTitles}> Exp. Date</Text>
            <Text style={styles.pageTitles}> Premium</Text>
            <Text style={styles.pageTitles}> Carrier</Text>
          </View>

          {carrierData.length > 0 &&
            carrierData.map((carrierData, index) => (
              <View style={styles.agent} key={index}>
                <View>
                  <Text style={styles.agentName}>{carrierData._id} </Text>
                </View>
                {carrierData.policies.length > 0 &&
                  carrierData.policies.map((policy) => (
                    <View style={styles.headingRow2}>
                      <Text style={styles.pageTitles2}>{policy?.policyNo}</Text>
                      <Text style={styles.pageTitles2}>
                        {policy?.lob ? policy?.lob : `N/A`}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {policy?.customer?.fullName
                          ? policy?.customer?.fullName
                          : `N/A`}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {dateFormat(policy?.effectiveDate)}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {dateFormat(policy?.expiryDate)}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {policy?.totalPremium
                          ? `$ ${policy?.totalPremium?.toFixed(2)}`
                          : `-`}
                      </Text>
                      <Text style={styles.pageTitles2}>{policy?.carrier}</Text>
                    </View>
                  ))}
              </View>
            ))}
        </View>
      </Page>
    </Document>
  )
}
