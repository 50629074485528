import React, { useState, useEffect } from 'react'
import FileSaver from 'file-saver'
import { Container } from 'App/Styled'
import { useMutation } from 'react-query'
import { useRouteMatch } from 'react-router'
import { ChonkyIconFA } from 'chonky-icon-fontawesome'
import { Spinner } from 'App/Components/Common/Spinner'
import { UploadFile } from './Action Butons/UploadFile'
import { RenameFile } from './Action Butons/RenameFile'
import { PreviewFile } from './Action Butons/PreviewFile'
import { CreateFolder } from './Action Butons/CreateFolder'
import { NotificationManager } from 'react-notifications'
import {
  ChonkyActions,
  setChonkyDefaults,
  FileBrowser,
  FileContextMenu,
  FileList,
  FileNavbar,
  FileToolbar,
  defineFileAction,
  ChonkyIconName,
} from 'chonky'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
setChonkyDefaults({ iconComponent: ChonkyIconFA })

export const FileManager = () => {
  const [upload, setUpload] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [filesTree, setFilesTree] = useState([])
  const [currentDir, setCurrentDir] = useState(``)
  const [preview, setPreview] = useState({})
  const [openRename, setOpenRename] = useState(false)
  const [oldFileName, newOldFileName] = useState(``)
  const [myFileActions, setMyFileActions] = useState([])

  const {
    params: { customerId },
  } = useRouteMatch()
  const [folderChain, setFolderChain] = useState([
    {
      id: customerId + `/home`,
      isDir: true,
      name: `Home`,
      thumbnailUrl: undefined,
    },
  ])

  const renameFile = defineFileAction({
    id: `rename_file`,
    requiresSelection: true,
    button: {
      name: `Rename File`,
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName.code,
    },
  })

  useEffect(() => {
    let filesDir = folderChain.findIndex(
      (folder) => folder.id == `${customerId}/Files`,
    )
    if (filesDir !== -1) {
      setMyFileActions([
        ChonkyActions.UploadFiles,
        ChonkyActions.DownloadFiles,
        renameFile,
        ChonkyActions.CreateFolder,
      ])
    } else setMyFileActions([ChonkyActions.DownloadFiles])
  }, [folderChain])

  const { mutate: getFiles, isLoading: gettingFiles } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/cloudinary${
          payload.endPoint ? `/` + payload.endPoint : ``
        }`,
        handlePayload(payload),
      ).then(({ data }) => {
        if (!data.hasError) {
          structureFileTree(data.response)
        } else {
          folderChain.forEach((folder, index) => {
            if (
              folder.id !== customerId + `/home` &&
              folder.id == customerId + payload.path.split(`/`)[0]
            ) {
              let newFolderChain = [...folderChain]
              newFolderChain.splice(index + 1)

              setFolderChain(newFolderChain)
            }
          })
          NotificationManager.error(`Something went wrong`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    setFolderChain([
      {
        id: customerId + `/home`,
        isDir: true,
        name: `Home`,
        thumbnailUrl: undefined,
      },
    ])
    getFiles({ path: customerId })
  }, [])

  const handlePayload = (data) =>
    Object.keys(data)
      .filter((key) => key !== `endPoint`)
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: data[key] })
      }, {})

  const structureFileTree = (data) => {
    const fileFetched = data?.map((folder) => ({
      id: folder.path || folder.asset_id,
      name: folder.name || `${folder?.filename}`,
      isDir: folder.isDir,
      thumbnailUrl: folder.secure_url,
      format: folder.format,
    }))
    setFilesTree(fileFetched)
  }
  const handleAction = (data) => {
    switch (data.id) {
      case `open_files`:
        if (
          !data?.payload?.targetFile?.isDir &&
          !data?.payload?.files[0]?.isDir
        ) {
          if (data?.payload?.targetFile?.format === `zip`) {
            FileSaver.saveAs(
              data?.payload?.targetFile?.thumbnailUrl,
              data?.payload?.targetFile?.name,
            )
            NotificationManager.success(`You have to download this files`)
          } else {
            setPreview({
              url: data?.payload?.targetFile?.thumbnailUrl,
              fileName: data?.payload?.targetFile?.name,
              format: data?.payload?.targetFile?.format,
            })
          }
        } else {
          if (data.payload.targetFile?.id == customerId + `/home`) {
            getFiles({ path: customerId.split(`/`)[0] })
            setFolderChain([
              {
                id: customerId + `/home`,
                isDir: true,
                name: `Home`,
                thumbnailUrl: undefined,
              },
            ])
          } else {
            let folderExist = folderChain.findIndex(
              (folder) => folder.id == data.payload.targetFile?.id,
            )
            if (folderExist == -1) {
              setFolderChain((state) => [...state, data?.payload?.targetFile])
            } else {
              folderChain.forEach((folder, index) => {
                if (folder.id == data.payload.targetFile?.id) {
                  let newFolderChain = [...folderChain]
                  newFolderChain.splice(index + 1)

                  setFolderChain(newFolderChain)
                }
              })
            }
            setCurrentDir(data?.payload?.targetFile?.id)
            getFiles({
              endPoint: `folder`,
              path: data?.payload?.targetFile?.id,
            })
          }
        }
        break
      case `upload_files`:
        setUpload(true)
        break
      case `create_folder`:
        setShowModal(true)

        break
      // case `delete_files`:
      //   fetch(`${process.env.REACT_APP_CLOUDINARY_API}/delete`, {
      //     method: `POST`,
      //     headers: { 'Content-Type': `application/json` },
      //     body: JSON.stringify({
      //       path: currentDir,
      //       files: data?.state?.selectedFiles?.map(
      //         (fileName) => `${currentDir}/${fileName.name}`,
      //       ),
      //     }),
      //   })
      //     .then((response) => {
      //       return response.json()
      //     })
      //     .then((data) => {
      //       structureFileTree(data?.assets)
      //     })
      //   break
      case `download_files`:
        if (!data?.state?.selectedFiles[0].isDir) {
          FileSaver.saveAs(
            data?.state?.selectedFiles[0].thumbnailUrl,
            data?.state?.selectedFiles[0].name,
          )
        } else {
          NotificationManager.error(`You can download files only`)
        }
        break
      case `rename_file`:
        if (!data?.state?.selectedFiles[0].isDir) {
          setOpenRename(true)
          newOldFileName(data?.state?.selectedFilesForAction[0].name)
        } else {
          NotificationManager.error(`Only Files Will b renamed`)
        }
        break
      case `move_files`:
        if (!data?.payload?.draggedFile?.isDir) {
          getFiles({
            endPoint: `move`,
            path: currentDir,
            file: `${currentDir}/${data.payload.draggedFile.name}`,
            destination: `${data.payload.destination.id}/${data.payload.draggedFile.name}`,
          })
        } else {
          NotificationManager.error(`Can not move a folder`)
        }

        break
      default:
        break
    }
  }

  return (
    <Container>
      <Spinner loading={gettingFiles} size="sm" />

      <div
        className={`h-96 ${gettingFiles && `opacity-30 pointer-events-none`} `}
      >
        <FileBrowser
          files={filesTree}
          folderChain={folderChain}
          fileActions={myFileActions}
          onFileAction={handleAction}
          disableDefaultFileActions={[
            ChonkyActions.OpenSelection.id,
            ChonkyActions.SelectAllFiles.id,
            ChonkyActions.ToggleHiddenFiles.id,
          ]}
        >
          <FileNavbar />
          <FileToolbar />
          <FileList />
          <FileContextMenu />
        </FileBrowser>
      </div>
      <CreateFolder
        open={showModal}
        setOpen={setShowModal}
        folderPath={currentDir}
        refetch={() =>
          getFiles({
            path: currentDir,
            endPoint: `folder`,
          })
        }
      />
      <UploadFile
        upload={upload}
        setUpload={setUpload}
        folderPath={currentDir}
        refetch={() =>
          getFiles({
            path: currentDir,
            endPoint: `folder`,
          })
        }
      />
      <PreviewFile preview={preview} setPreview={() => setPreview({})} />
      <RenameFile
        open={openRename}
        setOpen={setOpenRename}
        folderPath={currentDir}
        refetch={() =>
          getFiles({
            path: currentDir,
            endPoint: `folder`,
          })
        }
        oldName={oldFileName}
      />
    </Container>
  )
}
