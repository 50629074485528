import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export const ReactModal = ({
  title,
  closeModal,
  disabled,
  loading,
  callMutation,
  className,
  icon,
  children,
  toolTip,
  width = `auto`,
  height = `auto`,
  scroll,
  maxWidth = `3xl`,
  closeButton,
  preferLocation,
}) => (
  <>
    <div
      className={`flex justify-center h-${height} mt-10 ml-8 m-auto items-center  overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}
    >
      <div
        className={`relative w-${width} my-2 mx-auto max-w-${maxWidth} ${
          scroll ? `h-85vh` : `h-auto`
        }`}
      >
        <div
          className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full ${
            preferLocation ? `h-50vh` : `h-auto`
          }  bg-white outline-none focus:outline-none`}
        >
          <div className="flex items-start justify-between p-3 bg-red-500 text-white border-b border-solid border-blueGray-200 rounded-t-lg px-4">
            {title}
            {!closeButton && (
              <button
                className="bg-transparent border-0 text-black float-right text-4xl leading-none font-semibold outline-none focus:outline-none"
                onClick={closeModal}
              >
                <span className="text-white h-8 w-6 block outline-none focus:outline-none -mt-2">
                  ×
                </span>
              </button>
            )}
          </div>
          <div
            className={`relative flex w-full flex-wrap items-stretch ${className} ${
              preferLocation && `overflow-scroll `
            }`}
          >
            {children}
          </div>
          {icon && (
            <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className={`${
                  disabled ? `bg-red-500` : `bg-gray-500 cursor-not-allowed`
                } text-white tooltip active:bg-emerald-400 font-semibold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={callMutation}
                data-tip={toolTip}
                disabled={disabled ? false : true}
              >
                <div className="flex gap-2 text-white">
                  <FontAwesomeIcon
                    icon={loading ? faSpinner : icon}
                    size="lg"
                    spin={loading}
                  />
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
)
