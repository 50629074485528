import { Collapsable } from 'App/Components/Common/Collapsable'
import { InputField } from 'App/Components/Common/InputField'
import { SubmitButton } from 'App/Styled'
import React, { useState } from 'react'

const CredentialFilters = ({
  filtersProps,
  handleFilters,
  resetFilters,
  isLoading,
  isScrapper,
}) => {
  const [filters, setFilters] = useState({ ...filtersProps })

  const handleChangeFilters = (value, name) => {
    {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  return (
    <div
      className={`bg-white mt-2 mb-1 sm:mb-0 justify-center items-center px-2 pt-1 `}
    >
      <Collapsable title="Filters">
        <div className="grid grid-cols-4 gap-4">
          {isScrapper ? (
            <>
              <div className="relative">
                <InputField
                  type="text"
                  title="Carrier"
                  placeholder="Enter carrier"
                  value={filters?.carrier ?? ``}
                  setValue={(e) => {
                    handleChangeFilters(e.target.value, `carrier`)
                  }}
                  policyNo
                />
              </div>
              <div className="relative">
                <InputField
                  type="text"
                  title="User Name"
                  placeholder="Enter username"
                  value={filters?.userName ?? ``}
                  setValue={(e) => {
                    handleChangeFilters(e.target.value, `userName`)
                  }}
                />
              </div>
            </>
          ) : (
            <div className="relative">
              <InputField
                type="text"
                title="Name"
                placeholder="Enter name"
                value={filters?.name ?? ``}
                setValue={(e) => {
                  handleChangeFilters(e.target.value, `name`)
                }}
              />
            </div>
          )}

          <div className="relative">
            <InputField
              type="text"
              title="Producer Code"
              placeholder="Enter code"
              value={filters?.producerCode ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `producerCode`)
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-4">
          <div className="flex  gap-4">
            <SubmitButton
              disabled={isLoading}
              onClick={() => {
                handleFilters(filters)
              }}
            >
              Search
            </SubmitButton>

            <SubmitButton
              disabled={isLoading}
              onClick={() => {
                if (
                  filters?.carrier?.length ||
                  filters?.producerCode?.length ||
                  filters?.name?.length ||
                  filters?.userName?.length
                ) {
                  setFilters({})
                  resetFilters()
                }
              }}
            >
              Reset
            </SubmitButton>
          </div>
        </div>
      </Collapsable>
    </div>
  )
}

export default CredentialFilters
