import tw from 'tailwind-styled-components'

export const Container = tw.div`
${({ display }) => display && `flex p-4`}
${({ tabscontainer }) => !tabscontainer && `my-3 `}
${({ shadow }) => shadow != `yes` && `shadow`}
${({ nomargin }) => (nomargin === `true` ? `` : `ml-2`)}
${({ height }) => (height ? height : `max-w-full`)}

    bg-white 
    mr-2
    mt-3
    rounded
`
export const MenuButton = tw.div`
    relative 
    w-8 h-8
    flex 
    justify-center  
    items-center 
    rounded-full  
    text-gray-500 
    hover:text-gray-800 
    hover:bg-gray-300
`
export const Input = tw.input`
    ${({ errormessage }) => errormessage && `ring-red-500 `}
    ${({ requiredvalue }) =>
        !requiredvalue ? `border-gray-300` : `border-red-500 `}
    ${({ disabled }) =>
        disabled
            ? `bg-gray-100 text-gray-400 cursor-not-allowed`
            : `text-gray-700`}    
    rounded-md
    flex-1 
    border-solid
    w-full 
    mt-0.5
    text-sm h-8
    px-2 
    bg-white 
    placeholder-gray-400 
    shadow-sm 
    focus:ring-blue-600 
`
export const DateInput = tw.input`
    ${({ errormessage }) => errormessage && `ring-red-500 `}
    ${({ disabled }) =>
        disabled
            ? `bg-gray-100 text-gray-400 cursor-pointer`
            : `text-gray-700`}    
    rounded-md
    flex-1 
    border-solid
    border-gray-300 
    w-full 
    mt-0.5
    text-sm h-8
    px-2 
    bg-white 
    placeholder-gray-400 
    shadow-sm 
    focus:ring-blue-600 
`
export const Image = tw.img`
${({ overview }) =>
        overview
            ? `bg-white 
    object-cover 
    rounded-lg 
    h-42 
    w-64 `
            : `bg-gray-100
    border-1
    object-cover 
    rounded-full 
    cursor-pointer
    h-28 
    w-28
    `}
`
export const Label = tw.label`
    flex 
    flex-col 
    items-center 
    px-2 
    pt-1
`
export const StyledButton = tw.button`
${({ disabled }) =>
        disabled
            ? `bg-gray-400 cursor-not-allowed`
            : `hover:bg-blue-600  
    transform 
    hover:-translate-y-0 
    hover:scale-105 
    bg-blue-500 focus:ring-offset-blue-200 focus:ring-blue-500 cursor-pointer`}

    ${({ simple }) => (simple === true ? `float-right` : `mt-6`)} 
    py-1
    px-1.5
    rounded 
    transition 
    duration-500 
    ease-in-out 
    
    text-white
`

export const EditButton = tw.button`
${({ disabled }) =>
        disabled
            ? `bg-gray-400 cursor-not-allowed flex 
    gap-2 
    border-2 
    border-white 
    my-1 
    transition 
    duration-500 
    ease-in-out
    transform 
    hover:-translate-y-0 
    hover:scale-110
    cursor-pointer 
    rounded
    px-2 
    py-1
    text-sm
    text-white`
            : `flex 
    gap-2 
    border-2 
    border-white 
    my-1 
    transition 
    duration-500 
    ease-in-out
    transform 
    hover:-translate-y-0 
    hover:scale-110
    hover:bg-blue-600 
    bg-blue-500
    cursor-pointer 
    rounded
    px-2 
    py-1
    text-sm
    text-white`}

  
`

export const EditStyledButton = tw.button`
${({ disabled }) =>
        disabled
            ? `bg-gray-400 cursor-not-allowed flex  gap-2 
    border-2 
    border-white 
    my-1 
    transition 
    duration-500 
    ease-in-out
    transform 
    hover:-translate-y-0 
    hover:scale-110
    rounded
    px-2 
    py-1
    text-sm
    text-white
    cursor-not-allowed
    `
            : `flex 
    gap-2 
    border-2 
    border-white 
    my-1 
    transition 
    duration-500 
    ease-in-out
    transform 
    hover:-translate-y-0 
    hover:scale-110
    hover:bg-blue-600 
    bg-blue-500
    cursor-pointer 
    rounded
    px-2 
    py-1
    text-sm
    text-white
`}`
export const AddStyledButton = tw.div`
    flex 
    gap-2
    transition 
    duration-500 
    ease-in-out
    transform 
    hover:-translate-y-0 
    hover:scale-110
    hover:bg-blue-600 
    bg-blue-500
    cursor-pointer 
    rounded
    p-1 
    text-sm
    text-white
`

export const AddButton = tw.button`
${({ disabled }) =>
        disabled
            ? `cursor-not-allowed   flex 
  gap-2
  transition 
  duration-500 
  ease-in-out
  transform 
  hover:-translate-y-0 
  hover:scale-110
  bg-gray-400
  bg-gray-400
  cursor-pointer 
  rounded
  p-1 
  text-sm
  text-white`
            : ` flex 
  gap-2
  transition 
  duration-500 
  ease-in-out
  transform 
  hover:-translate-y-0 
  hover:scale-110
  hover:bg-blue-600 
  bg-blue-500
  cursor-pointer 
  rounded
  p-1 
  text-sm
  text-white`}
`

export const ButtonSecondary = tw.button`
    bg-gray-300 
    float-right 
    mx-1
    py-1
    px-1.5
    mt-6
    rounded-lg 
    transition 
    duration-500 
    ease-in-out 
    hover:bg-gray-400 
    transform 
    hover:-translate-y-0 
    hover:scale-105 
    cursor-pointer
`

export const AddFContainer = tw.div`
    w-auto 
    text-center 
    text-white 
    relative 
    my-auto
`

export const AddFranchise = tw.div`
    bg-red-500 
    w-auto 
    py-1.5 
    px-2
    rounded-lg
`

export const PageHeader = tw.div`
${({ padding }) => (padding === `true` ? `px-4` : `px-2`)}
${({ quote }) => (quote === `true` ? `text-md` : `pt-3 font-medium text-xl`)}
    flex
    flex-row 
    justify-between
    items-center   
    py-0.5
    rounded-t-md 
`
export const SecondaryHeading = tw.div`
    text-base
    font-medium
    my-2 
`

export const Form = tw.form`
    flex
    md:flex-row
    w-3/4 
    md:w-full
    max-w-sm
    space-y-3
    md:space-y-0
`
export const SearchInput = tw.input`
${({ disabled }) =>
        disabled ? `bg-gray-100 text-gray-400 cursor-not-allowed` : `text-gray-700 `}
    rounded-md
    flex-1 
    border
    border-gray-300 
    w-full 
    mt-1
    h-8
    mb-1.5
    px-4
    bg-white 
    text-gray-700 
    placeholder-gray-400 
    shadow-sm 
    text-base
    focus:outline-none 
    focus:ring-2 
    focus:ring-red-500 
    focus:border-transparent

`
export const FilterButton = tw.button`
    px-4 
    text-base
    font-semibold
    text-white bg-blue-500 border-2  border-white
    rounded-r-md
    shadow-md 
    hover:bg-blue-500
    focus:outline-none focus:ring-2
    focus:ring-white-100
`

export const SubmitButton = tw.button`
${({ disabled }) =>
        disabled
            ? `bg-gray-400 cursor-not-allowed`
            : `hover:bg-blue-600  bg-blue-500 focus:ring-offset-blue-200 focus:ring-blue-500`}
    py-2 
    px-2 
    flex 
    justify-center 
    items-center 
    w-full 
    text-white  
    transition 
    ease-in 
    duration-200 
    text-center 
    text-base 
    font-semibold 
    shadow
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2  
    rounded
`

export const PageTitle = tw.div`
    text-2xl
    font-medium
    py-4
    px-4
    bg-red-500
    rounded-t-md
    text-white
`

export const RemoveForm = tw.div`
    bg-red-500 
    text-center  
    transition 
    duration-500 
    ease-in-out 
    hover:bg-red-500 
    transform 
    hover:-translate-y-0
    hover:scale-125
    cursor-pointer
`

export const CallBlock = tw.div`
flex-1 
hover:bg-gray-200
bg-gray-100
rounded-full 
text-center 
p-3.5 
cursor-pointer 
text-gray-500
relative
`

export const VideoCallBlock = tw.a`
flex-1 
hover:bg-gray-200
hover:text-red-500
bg-gray-100
rounded-full 
text-center 
p-3.5 
cursor-pointer 
text-gray-500
transform
hover:scale-105
transition
transition-transform
`

export const AddForm = tw.div`
    transition 
    duration-500 
    ease-in-out 
    transform 
    hover:-translate-y-0
    hover:scale-150
    cursor-pointer
`
export const TOne = tw.div`
    ${({ opentab }) => (opentab === 1 ? `block` : `hidden`)}
`
export const TTwo = tw.div`
    ${({ opentab }) => (opentab === 2 ? `block` : `hidden`)}
`
export const TThree = tw.div`
    ${({ opentab }) => (opentab === 3 ? `block` : `hidden`)}
`
export const TFour = tw.div`
    ${({ opentab }) => (opentab === 4 ? `block` : `hidden`)}
`
export const TFive = tw.div`
    ${({ opentab }) => (opentab === 5 ? `block` : `hidden`)}
`
export const TSix = tw.div`
    ${({ opentab }) => (opentab === 6 ? `block` : `hidden`)}
`
export const TSeven = tw.div`
    ${({ opentab }) => (opentab === 7 ? `block` : `hidden`)}
`
export const ActivityWrapper = tw.div`
  ${({ collapsed }) => (collapsed === `true` ? `w-14` : `w-full md:w-1/3`)}
  ${({ collapsed }) => (collapsed === `true` ? `h-content` : `h-500`)}
  mt-3
  mr-2
  rounded
  transition-width duration-200 ease-in-out
  transition-height duration-200 ease-in-out
  right-0
  z-0  
  sm:block   
  shadow-sm
  sm-2:block
  bg-white
`
export const FormTab = tw.div`
    ${({ currenttab, opentab }) =>
        opentab === currenttab ? `block` : `hidden`}
`

export const AddTabButton = tw.div`
    transition 
    duration-500 
    ease-in-out 
    transform 
    hover:-translate-y-0
    hover:scale-150
    cursor-pointer
`
export const CImage = tw.img`
    object-cover 
    rounded-full 
    h-10
    w-10
    min-w-10
    bg-gray-300 
    border-1
`

export const Sticker = tw.div`
    col-span-4 
    justify-self-center 
    text-white 
    font-bold 
    rounded-md 
    max-w-48
    w-36
    py-8
    px-2 
    text-center 
    shadow-lg
`

export const StickerSkelton = tw.div`
    col-span-4 
    justify-self-center 
    text-white 
    font-bold 
    rounded-md 
    max-w-48
    w-72
    h-32
    py-8
    px-2 
    text-center 
    bg-gray-100
    animate-pulse
`
export const Skeleton = tw.div`
             rounded-sm
             w-3/6
             h-2 
             bg-gray-300 
             animate-pulse
`
export const SubHeader = tw.span`
text-sm border-t-1 border-b-1 border-gray-200 text-gray-700 px-2 py-1  font-normal -ml-1
`
export const ReportsSticker = tw.div`
${({ checked }) =>
        checked === `yes` ? `bg-red-500 text-white` : `bg-gray-50 text-gray-500 `}
    col-span-2
    justify-self-center 
    font-bold 
    rounded-md 
    max-w-48
    w-56
    truncate
    py-3
    px-2 
    m-2
    text-center 
    shadow-md
    transform 
    hover:-translate-y-0 
    hover:scale-105 
    duration-500 
    cursor-pointer
`
