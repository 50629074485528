/* eslint-disable camelcase */
import { useEffect } from 'react'
import { resetPersistantState } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const logOut = localStorage.getItem(`isLogOut`)
    if (logOut) {
      dispatch(resetPersistantState())
      localStorage.clear()
    }
  }, [])
  if (!process.browser) return children
  return children
}
