import { brandLogo } from 'App/Assets'
import { Container } from 'App/Styled'
import React, { useEffect, useRef } from 'react'
import { Reload } from 'App/Components/Common/Reload'
import { TableBody, TableHeader, TableRow } from '@windmill/react-ui'
import { useReactToPrint } from 'react-to-print'
import { CardField } from 'App/Components/Common/CardField'
// import { quoteData } from './data'
import dateFormat from 'App/Services/General/dateFormat'

export const Pdf = ({ quoteData }) => {
  const componentRef = useRef()
  const isDataAvailable = quoteData && Object.entries(quoteData).length
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey) {
        e.preventDefault()
        handlePrint()
      }
    }
    document.addEventListener(`keydown`, handleKeyPress)

    return () => {
      document.removeEventListener(`keydown`, handleKeyPress)
    }
  }, [])

  return (
    <Container>
      {!isDataAvailable ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <>
          <div className="px-3 text-right">
            <button
              onClick={handlePrint}
              className="bg-red-500 text-white rounded-md px-2 mt-2"
            >
              Print
            </button>
          </div>
          <div className="" ref={componentRef}>
            <div className="grid w-full">
              <img
                src={brandLogo}
                width={250}
                height={170}
                alt="Brand Logo"
                className="ml-2 my-6"
              />
            </div>
            <div className="flex flex-col w-full gap-2 justify-evenly pb-2">
              <TableHeader>
                <TableRow className="p-2  grid grid-cols-12">
                  <tr className="col-span-4 font-semibold pl-2">Agency</tr>
                  <tr className="col-span-2  pl-2"></tr>
                  <tr className="col-span-6 font-semibold pl-2">Pay Plan</tr>
                </TableRow>
              </TableHeader>
              <div className="w-full rounded">
                <div className="flex justify-between px-2">
                  <div className="flex-1 px-2">
                    <CardField title="Name" value={quoteData?.agencyName} />
                    <CardField
                      title="Address "
                      value={quoteData?.agencyAddress1}
                    />
                    {quoteData?.agencyAddress2?.length > 0 && (
                      <CardField
                        title="Address 2"
                        value={quoteData?.agencyAddress2}
                      />
                    )}
                    <CardField title="City" value={quoteData?.agencyCity} />
                    <CardField title="State" value={quoteData?.agencyState} />
                    <CardField title="Zip" value={quoteData?.agencyZip} />
                    <CardField title="Phone" value={quoteData?.agencyPhone} />
                    <CardField
                      title="Marketing Line"
                      value={quoteData?.agencyMarketingLine}
                    />
                  </div>
                  <div className="flex-1 px-2">
                    <CardField
                      title="Percent Down"
                      value={quoteData?.payPlanPercentDown?.toString()}
                    />
                    <CardField
                      title="Service Fees "
                      value={quoteData?.payPlanServiceFees?.toString()}
                    />
                    <CardField
                      title="Payment Total"
                      value={quoteData?.payPlanPaymentTotal?.toString()}
                    />
                    <CardField
                      title="FullyRated"
                      value={quoteData?.payPlanWasFullyRated ? `Yes` : `No`}
                    />
                    <CardField
                      title="Is Qualified"
                      value={quoteData?.payPlanIsQualified ? `Yes` : `No`}
                    />
                    <CardField
                      title="Message"
                      value={quoteData?.payPlanMessage?.toString()}
                    />
                    <CardField
                      title="effectiveDate"
                      value={dateFormat(quoteData?.effectiveDate?.toString())}
                    />
                    <CardField
                      title="expirationDate"
                      value={dateFormat(quoteData?.expirationDate?.toString())}
                    />
                  </div>
                </div>
              </div>
              <TableHeader>
                <TableRow className="p-2  grid grid-cols-9">
                  <tr className="col-span-4 font-semibold pl-2">Plan</tr>
                  <tr className="col-span-3  pl-2"></tr>
                  <tr className="col-span-4 font-semibold pl-2"></tr>
                </TableRow>
              </TableHeader>
              <div className="w-full rounded">
                <div className="flex justify-between px-2">
                  <div className="flex-1 px-2">
                    <CardField
                      title="payments"
                      value={quoteData?.plan?.payments?.toString()}
                    />
                    <CardField
                      title="description "
                      value={quoteData?.plan?.description?.toString()}
                    />
                    <CardField
                      title="total Premium"
                      value={`$${quoteData?.plan?.totalPremium?.toString()}`}
                    />
                    <CardField
                      title="down Payment"
                      value={`$${quoteData?.plan?.downPayment?.toString()}`}
                    />
                    <CardField
                      title="amount Per Payment"
                      value={`$${quoteData?.plan?.amountPerPayment?.toString()}`}
                    />
                  </div>
                </div>
              </div>
              <TableHeader>
                <TableRow className="p-2  grid grid-cols-12">
                  <tr className="col-span-4 font-semibold pl-2">sr22 Fee</tr>
                  <tr className="col-span-4  pl-2">atpa Fee</tr>
                  <tr className="col-span-4 font-semibold pl-2">fr44 Fee</tr>
                </TableRow>
              </TableHeader>

              <TableBody>
                <TableRow className="p-2  grid grid-cols-12">
                  <td className="pl-4 col-span-4">
                    ${quoteData?.sr22Fee?.toString()}
                  </td>
                  <td className="pl-4 col-span-4">
                    ${quoteData?.atpaFee?.toString()}
                  </td>
                  <td className="pl-4 col-span-4">
                    ${quoteData?.fr44Fee?.toString()}
                  </td>
                </TableRow>
              </TableBody>
            </div>

            {quoteData?.cars.length > 0 && (
              <div className="px-4 my-2 overflow-x-auto  rounded-t-md col-span-2">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow className="p-2 grid grid-cols-12">
                      <tr className="col-span-1">Car Number</tr>
                      <tr className="text-center col-span-2">VIN</tr>
                      <tr className="text-center col-span-2">Year</tr>
                      <tr className="text-center col-span-2">Make</tr>
                      <tr className="text-center col-span-2">Model</tr>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {quoteData?.cars?.map((item, index) => (
                      <TableRow
                        className="grid grid-cols-12 py-1 px-2 whitespace-nowrap"
                        key={index}
                      >
                        <td className="col-span-1 text-sm">
                          {item?.CarNumber}
                        </td>
                        <td className="text-center col-span-2">{item?.VIN}</td>
                        <td className="text-center col-span-2">{item?.Year}</td>
                        <td className="text-center col-span-2">{item?.Make}</td>
                        <td className=" col-span-2 text-right pl-5">
                          {item?.Model}
                        </td>
                      </TableRow>
                    ))}
                  </TableBody>
                </table>
              </div>
            )}
            {quoteData?.drivers.length > 0 && (
              <div className="px-4 my-2 overflow-x-auto  rounded-t-md col-span-2">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow className="p-2 grid grid-cols-9">
                      <tr className="col-span-1">Driver Number</tr>
                      <tr className="text-center col-span-1">Points</tr>
                      <tr className="text-center col-span-1">Age</tr>
                      <tr className="text-center col-span-1">Sex</tr>
                      <tr className="text-center col-span-1">Marital</tr>
                      <tr className="text-center col-span-1">Relation</tr>
                      <tr className="text-center col-span-1">Driver Class</tr>
                      <tr className="text-center col-span-1">Credit Score</tr>
                      <tr className="text-center col-span-1">Assigned Car</tr>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {quoteData?.drivers?.map((item, index) => (
                      <TableRow
                        className="grid grid-cols-9 py-1 px-2"
                        key={index}
                      >
                        <td className="col-span-1 text-sm">
                          {item?.DriverNumber}
                        </td>
                        <td className="text-center col-span-1">
                          {item?.Points}
                        </td>
                        <td className="text-center col-span-1">{item?.Age}</td>
                        <td className="text-center col-span-1">{item?.Sex}</td>
                        <td className="text-center col-span-1">
                          {item?.Marital}
                        </td>
                        <td className="text-center col-span-1">
                          {item?.Relation}
                        </td>
                        <td className="text-center col-span-1">
                          {item?.DriverClass}
                        </td>
                        <td className="text-center col-span-1">
                          {item?.CreditScore}
                        </td>
                        <td className="text-center col-span-1">
                          {item?.AssignedCar}
                        </td>
                      </TableRow>
                    ))}
                  </TableBody>
                </table>
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  )
}
