import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import {
  Container,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import { MaskField } from 'App/Components/Common/MaskField'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import {
  loadGenderOptions,
  loadZipCodes,
  ValidateDuplicateAgentEmail,
  ValidateZip,
} from 'App/Services'
import { Spinner } from 'App/Components/Common/Spinner'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API } from '../../../../Config/backendApis'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { useHistory } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { useDebouncedCallback } from 'use-debounce'

export const AddCarDealer = () => {
  const history = useHistory()

  const [locZipValidating, setLocZipValidating] = useState(false)
  const [agentEmailValidating, setAgentEmailValidating] = useState(false)
  const [agentPhoneValidating, setAgentPhoneValidating] = useState(false)

  const [businessName, setbusinessName] = useState(``)
  const [carDealerInfo, setCarDealerInfo] = useState({
    email: ``,
    phone: ``,
    ext: ``,
    fax: ``,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    street: ``,
    city: ``,
    state: ``,
    zip: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [mapCoordinates, setMapCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [contactPerson, setContactPerson] = useState({
    firstName: ``,
    middleName: ``,
    lastName: ``,
    gender: ``,
  })

  const [contactPersonInfo, setContactPersonInfo] = useState({
    email: ``,
    phone: ``,
    ext: ``,
    fax: ``,
  })

  const [comission, setComission] = useState({
    type: ``,
    rate: ``,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    businessNameError: ``,
    dealerEmailError: ``,
    dealerPhoneError: ``,
    dealerExtError: ``,
    dealerFaxError: ``,

    contactPersonFirstNameError: ``,
    contactPersonLastNameError: ``,
    contactPersonDesignationError: ``,

    contactPersonEmailError: ``,
    contactPersonPhoneError: ``,
    contactPersonExtError: ``,
    contactPersonFaxError: ``,

    //    address
    zipError: ``,
    streetNoError: ``,
    cityError: ``,
    stateError: ``,
    coordinatesError: ``,
    typeError: ``,
    rateError: ``,
    genderError: ``,
  })

  const loadComissionTypes = () => {
    const options = {
      options: [
        {
          value: `PERCENTAGE`,
          label: `%`,
        },
        {
          value: `FLAT_RATE`,
          label: `Flat`,
        },
      ],
      hasMore: false,
    }
    return options
  }

  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/car-dealers`, {
        ...payload,
      }).then((res) => {
        if (res.data) {
          res.data && history.push(`/crm/cardealers`)
          NotificationManager.success(`Operation Successful`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const validateAgentEmail = useDebouncedCallback(async (value) => {
    setAgentEmailValidating(true)
    const response = await ValidateDuplicateAgentEmail({ email: value })
    if (response?.hasError) {
      setAgentEmailValidating(false)
      handleErrors(`formSubmit`, false)
      handleErrors(`contactPersonEmailError`, `Email Already Exists`)
    } else {
      if (!regEx.test(value.toLowerCase())) {
        setAgentEmailValidating(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`contactPersonEmailError`, `Enter Valid Email`)
      } else {
        setAgentEmailValidating(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`contactPersonEmailError`, ``)
      }
    }
  }, 2500)

  const validateAgentPhone = useDebouncedCallback(async (value) => {
    setAgentPhoneValidating(true)
    const response = await ValidateDuplicateAgentEmail({ phone: value })
    if (response?.hasError) {
      setAgentPhoneValidating(false)
      handleErrors(`formSubmit`, false)
      handleErrors(`contactPersonPhoneError`, `Phone Already Exists`)
    } else {
      setAgentPhoneValidating(false)
      handleErrors(`formSubmit`, false)
      handleErrors(`contactPersonPhoneError`, ``)
    }
  }, 2500)
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const ValidateForm = () => {
    const nonNumericRegex = /^[0-9@#$%^&*()_+={}[\]:;<>,.?~\\-]+$/
    if (!businessName.length) {
      handleErrors(`businessNameError`, `Enter Bussiness`)
    } else if (nonNumericRegex.test(businessName)) {
      handleErrors(
        `businessNameError`,
        `Bussiness Name cannot contain numeric characters only`,
      )
    } else {
      handleErrors(`businessNameError`, ``)
    }

    if (!carDealerInfo?.email.length) {
      handleErrors(`dealerEmailError`, `Enter Email`)
    } else if (!regEx.test(carDealerInfo?.email.toLowerCase())) {
      handleErrors(`dealerEmailError`, `Enter Valid Email`)
    } else {
      handleErrors(`dealerEmailError`, ``)
    }

    if (!carDealerInfo?.phone.length) {
      handleErrors(`dealerPhoneError`, `Enter Phone`)
    } else if (carDealerInfo.phone.length < 12) {
      handleErrors(`dealerPhoneError`, `Enter Valid Phone Number`)
    } else {
      handleErrors(`dealerPhoneError`, ``)
    }

    if (carDealerInfo.fax.length && carDealerInfo.fax.length < 12) {
      handleErrors(`dealerFaxError`, `Enter Valid Fax`)
    } else {
      handleErrors(`dealerFaxError`, ``)
    }

    if (carDealerInfo?.ext?.length) {
      if (isNaN(carDealerInfo.ext)) {
        handleErrors(`dealerExtError`, `Ext Should be a number`)
      } else if (carDealerInfo.ext.length < 4) {
        handleErrors(`dealerExtError`, `Ext should be 4 digit`)
      } else {
        handleErrors(`dealerExtError`, ``)
      }
    } else {
      handleErrors(`dealerExtError`, ``)
    }

    // if (!address.unitNo.length) {
    //   handleErrors(`unitNoError`, `Enter Unit No`)
    // } else {
    //   handleErrors(`unitNoError`, ``)
    // }

    if (!address.zip?.length && !Object.keys(address.zip).length) {
      handleErrors(`zipError`, `Select Zip Code`)
    } else {
      handleErrors(`zipError`, ``)
    }

    if (!address?.street?.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }

    // if (!address.city.length) {
    //   handleErrors(`cityError`, `Enter City`)
    // } else {
    //   handleErrors(`cityError`, ``)
    // }

    if (!contactPerson.gender?.value?.length) {
      handleErrors(`genderError`, `Select gender`)
    } else {
      handleErrors(`genderError`, ``)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, true)
    } else if (
      coordinates.lat !== mapCoordinates.lat ||
      coordinates.lng !== mapCoordinates.lng
    ) {
      handleErrors(`coordinatesError`, `Coordinates not matched`)
    } else {
      handleErrors(`coordinatesError`, false)
    }

    if (!contactPerson.firstName.length) {
      handleErrors(`contactPersonFirstNameError`, `Enter First Name`)
    } else if (contactPerson.firstName.length < 3) {
      handleErrors(
        `contactPersonFirstNameError`,
        `First Name at least 3 letters`,
      )
    } else if (nonNumericRegex.test(contactPerson.firstName)) {
      handleErrors(
        `contactPersonFirstNameError`,
        `First Name cannot contain numeric characters only`,
      )
    } else {
      handleErrors(`contactPersonFirstNameError`, ``)
    }

    if (!contactPerson.lastName.length) {
      handleErrors(`contactPersonLastNameError`, `Enter Last Name`)
    } else if (contactPerson.lastName.length < 3) {
      handleErrors(`contactPersonLastNameError`, `Last Name at least 3 letters`)
    } else if (nonNumericRegex.test(contactPerson.lastName)) {
      handleErrors(
        `contactPersonLastNameError`,
        `Last Name cannot contain numeric characters only`,
      )
    } else {
      handleErrors(`contactPersonLastNameError`, ``)
    }

    if (!contactPersonInfo.email?.length) {
      handleErrors(`contactPersonEmailError`, `Enter Email`)
    } else if (!regEx.test(contactPersonInfo?.email.toLowerCase())) {
      handleErrors(`contactPersonEmailError`, `Enter Valid Email`)
    } else {
      if (errors.contactPersonEmailError !== `Email Already Exists`) {
        handleErrors(`contactPersonEmailError`, ``)
      }
      // handleErrors(`contactPersonEmailError`, ``)
    }

    if (!contactPersonInfo.phone?.length) {
      handleErrors(`contactPersonPhoneError`, `Enter Phone`)
    } else if (contactPersonInfo.phone?.length < 12) {
      handleErrors(`contactPersonPhoneError`, `Enter Valid Phone Number`)
    } else {
      if (errors.contactPersonPhoneError !== `Phone Already Exists`) {
        handleErrors(`contactPersonPhoneError`, ``)
      }
      // handleErrors(`contactPersonPhoneError`, ``)
    }

    if (contactPersonInfo?.ext?.length) {
      if (isNaN(contactPersonInfo.ext)) {
        handleErrors(`contactPersonExtError`, `Ext Should be a number`)
      } else if (contactPersonInfo.ext.length < 4) {
        handleErrors(`contactPersonExtError`, `Ext should be 4 digits`)
      } else {
        handleErrors(`contactPersonExtError`, ``)
      }
    } else {
      handleErrors(`contactPersonExtError`, ``)
    }

    if (contactPersonInfo.fax?.length && contactPersonInfo.fax?.length < 12) {
      handleErrors(`contactPersonFaxError`, `Enter Valid Fax`)
    } else {
      handleErrors(`contactPersonFaxError`, ``)
    }

    if (!comission.type?.value?.length) {
      handleErrors(`typeError`, `Select Option`)
    } else {
      handleErrors(`typeError`, ``)
    }

    if (!comission.rate?.length) {
      handleErrors(`rateError`, `Enter Value`)
    } else if (comission.type?.value?.length) {
      if (
        comission.type?.value === `PERCENTAGE` &&
        parseInt(comission.rate) > 100
      ) {
        handleErrors(`rateError`, `Value Must be Upto 100`)
      } else if (comission?.rate <= 0) {
        handleErrors(`rateError`, `Enter Valid Rate`)
      } else {
        handleErrors(`rateError`, ``)
      }
    } else {
      handleErrors(`rateError`, ``)
    }
    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.businessNameError.length &&
      !errors.dealerEmailError.length &&
      !errors.dealerPhoneError.length &&
      // !errors.dealerExtError.length &&
      !errors.dealerFaxError.length &&
      !errors.contactPersonFirstNameError.length &&
      !errors.contactPersonLastNameError.length &&
      !errors.contactPersonDesignationError.length &&
      !errors.contactPersonEmailError.length &&
      !errors.contactPersonPhoneError.length &&
      // !errors.contactPersonExtError.length &&
      !errors.contactPersonFaxError.length &&
      // !errors.unitNoError.length &&
      !errors.zipError.length &&
      !errors.streetNoError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.genderError.length &&
      !errors.coordinatesError.length &&
      !errors.typeError.length &&
      !errors.rateError.length
    ) {
      mutate(preparePayload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.coordinatesError === `Coordinates not matched`) {
      NotificationManager.error(`Coordinates not matched`)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const preparePayload = () => {
    let data = {
      businessName: businessName,
      contactInfo: {
        email: carDealerInfo.email,
        phone: carDealerInfo.phone,
      },
      address: {
        street: address.street,
        city: address.city,
        state: address.state,
        zip: address.zip.value,
        coordinates: {
          lat: coordinates.lat,
          long: coordinates.lng,
        },
      },
      contactPerson: {
        firstName: contactPerson.firstName,
        lastName: contactPerson.lastName,
        gender: contactPerson.gender.value,
        contactInfo: {
          email: contactPersonInfo.email,
          phone: contactPersonInfo.phone,
        },
      },
      commission: {
        type: comission.type.value,
        rate: comission.rate,
      },
    }

    if (address?.unitNo?.length) {
      data.address.unitNo = address?.unitNo
    }

    if (carDealerInfo.ext?.length) {
      data.contactInfo.ext = carDealerInfo.ext
    }

    if (carDealerInfo.fax.length) {
      data.contactInfo.fax = carDealerInfo.fax
    }

    if (contactPerson.middleName.length) {
      data.contactPerson.middleName = contactPerson.middleName
    }

    if (contactPersonInfo.ext.length) {
      data.contactPerson.contactInfo.ext = contactPersonInfo.ext
    }

    if (contactPersonInfo.fax.length) {
      data.contactPerson.contactInfo.fax = contactPersonInfo.fax
    }
    return data
  }

  const handleAddressInput = async (e, name) => {
    if (name) {
      setLocZipValidating(true)
      const data = await ValidateZip(e?.value)
      if (data) {
        setLocZipValidating(false)
        const res = data.address
        setAddress((prevState) => ({
          ...prevState,
          [`city`]: res.city,
          [`state`]: res.state,
        }))
        setAddress((prevState) => ({
          ...prevState,
          [name]: e,
        }))

        //setting coordinates
        setCoordinates((prevState) => ({
          ...prevState,
          [`lat`]: res.lat,
          [`lng`]: res.long,
        }))
        setMapCoordinates((prevState) => ({
          ...prevState,
          [`lat`]: res.lat,
          [`lng`]: res.long,
        }))
      }
    } else {
      const { name, value } = e.target

      setAddress((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }
  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  return (
    <>
      <Container>
        <PageHeader padding="true">Add Car Dealer</PageHeader>
        {updationError && (
          <ReactNotification action="error" message="Something went wrong" />
        )}
        <Spinner loading={isLoading} />
        <div
          className={`px-4 bg-white ${
            isLoading && `opacity-30 pointer-events-none`
          } `}
        >
          {/* Locations basic form */}
          <div className="flex gap-6 my-2">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Business Name *"
                placeholder="Enter Name"
                value={businessName}
                errorMessage={errors.businessNameError}
                setValue={(e) => setbusinessName(e.target.value)}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="email"
                title="Email *"
                name="email"
                placeholder="Enter Email"
                errorMessage={errors.dealerEmailError}
                value={carDealerInfo.email}
                setValue={(e) => {
                  setCarDealerInfo((preState) => ({
                    ...preState,
                    [`email`]: e.target.value,
                  }))
                }}
              />
            </div>
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Phone *"
                placeholder="Enter Phone No"
                errorMessage={errors.dealerPhoneError}
                value={carDealerInfo.phone}
                setValue={(e) =>
                  setCarDealerInfo((preState) => ({
                    ...preState,
                    [`phone`]: e.target.value,
                  }))
                }
              />
            </div>
            {/* <div className="flex-1 relative">
              <InputField
                type="text"
                title="Extension"
                placeholder="Enter Extension"
                errorMessage={errors.dealerExtError}
                value={carDealerInfo.ext}
                setValue={(e) =>
                  setCarDealerInfo((preState) => ({
                    ...preState,
                    [`ext`]: e.target.value,
                  }))
                }
              />
            </div> */}
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Fax"
                placeholder="Enter Fax"
                errorMessage={errors.dealerFaxError}
                value={carDealerInfo.fax}
                setValue={(e) => {
                  setCarDealerInfo((preState) => ({
                    ...preState,
                    fax: e.target.value,
                  }))
                }}
              />
            </div>
          </div>

          <SecondaryHeading>Contact Person</SecondaryHeading>

          <div className="flex gap-6 my-1">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="First Name *"
                placeholder="Enter Name"
                errorMessage={errors.contactPersonFirstNameError}
                value={contactPerson.firstName}
                setValue={(e) =>
                  setContactPerson((preState) => ({
                    ...preState,
                    [`firstName`]: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Middle Name"
                placeholder="Enter Name"
                errorMessage={errors.contactPersonMiddleNameError}
                value={contactPerson.middleName}
                setValue={(e) =>
                  setContactPerson((preState) => ({
                    ...preState,
                    [`middleName`]: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Last Name *"
                placeholder="Enter Name"
                errorMessage={errors.contactPersonLastNameError}
                value={contactPerson.lastName}
                setValue={(e) =>
                  setContactPerson((preState) => ({
                    ...preState,
                    [`lastName`]: e.target.value,
                  }))
                }
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Gender *"
                placeholder="Select gendder"
                errorMessage={errors.genderError}
                value={contactPerson.type}
                setValue={(value) => {
                  setContactPerson((preState) => ({
                    ...preState,
                    [`gender`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadGenderOptions}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="flex gap-6 my-1">
            <div className="flex-1 relative">
              <InputField
                type="email"
                title="Email *"
                name="email"
                placeholder="Enter Email"
                errorMessage={errors.contactPersonEmailError}
                value={contactPersonInfo.email}
                validating={agentEmailValidating}
                setValue={(e) => {
                  e.target.value && validateAgentEmail(e.target.value)
                  setContactPersonInfo((preState) => ({
                    ...preState,
                    [`email`]: e.target.value,
                  }))
                }}
              />
            </div>
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Phone *"
                placeholder="Enter Phone No"
                errorMessage={errors.contactPersonPhoneError}
                value={contactPersonInfo.phone}
                validating={agentPhoneValidating}
                setValue={(e) => {
                  e.target.value && validateAgentPhone(e.target.value)
                  setContactPersonInfo((preState) => ({
                    ...preState,
                    [`phone`]: e.target.value,
                  }))
                }}
              />
            </div>
            {/* <div className="flex-1 relative">
              <InputField
                type="text"
                title="Extension"
                placeholder="Enter Extension"
                errorMessage={errors.contactPersonExtError}
                value={contactPersonInfo.ext}
                setValue={(e) =>
                  setContactPersonInfo((preState) => ({
                    ...preState,
                    [`ext`]: e.target.value,
                  }))
                }
              />
            </div> */}
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Fax"
                placeholder="Enter Fax"
                errorMessage={errors.contactPersonFaxError}
                value={contactPersonInfo.fax}
                setValue={(e) =>
                  setContactPersonInfo((preState) => ({
                    ...preState,
                    [`fax`]: e.target.value,
                  }))
                }
              />
            </div>
          </div>

          <SecondaryHeading>Comission</SecondaryHeading>

          <div className="flex gap-6 my-1">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Comission Type *"
                placeholder="Select Type"
                errorMessage={errors.typeError}
                value={comission.type}
                setValue={(value) => {
                  setComission((preState) => ({
                    ...preState,
                    [`type`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadComissionTypes}
                isSearchable={false}
              />
            </div>

            <div className="flex-1 relative">
              <InputField
                type="number"
                title="Rate *"
                placeholder="Enter Rate"
                errorMessage={errors.rateError}
                value={comission.rate}
                setValue={(e) => {
                  setComission((preState) => ({
                    ...preState,
                    [`rate`]: e.target.value,
                  }))
                }}
              />
            </div>
          </div>

          <SecondaryHeading>Address</SecondaryHeading>
          <div className="flex gap-6 my-1">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Street *"
                name="street"
                placeholder="Enter Street"
                errorMessage={errors.streetNoError}
                value={address.street}
                setValue={(e) => handleAddressInput(e)}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Zip *"
                placeholder="Select Zip"
                errorMessage={errors.zipError}
                validating={locZipValidating}
                value={address.zip}
                setValue={(e) => handleAddressInput(e, `zip`)}
                isMulti={false}
                loadOptions={loadZipCodes}
                isSearchable={true}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Unit No"
                name="unitNo"
                placeholder="Enter Unit No"
                // errorMessage={errors.unitNoError}
                value={address.unitNo}
                setValue={(e) => handleAddressInput(e)}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="City *"
                name="city"
                placeholder="Enter City"
                errorMessage={errors.cityError}
                disabled={true}
                value={address.city}
                setValue={(e) => handleAddressInput(e)}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="State *"
                name="state"
                placeholder="Enter State"
                errorMessage={errors.stateError}
                disabled={true}
                value={address.state}
                setValue={(e) => handleAddressInput(e)}
              />
            </div>
          </div>
          <div className="rounded-md grid grid-cols-1">
            <label className="text-sm mt-2 text-gray-700 font-medium">
              Locate Yourself
              {/* {errors.coordinatesError && (
                <span className="text-red-500 required-dot ml-2">*</span>
              )} */}
            </label>
            <MapContainer
              center={[coordinates.lat, coordinates.lng]}
              zoom={3}
              scrollWheelZoom={true}
            >
              <ChangeView
                center={[coordinates.lat, coordinates.lng]}
                zoom={3}
              />
              <TileLayer url={LEAFLET_URL} />
              <DraggableMarker
                coord={coordinates}
                setCoordinates={(e) => setCoordinates(e)}
              />
            </MapContainer>
          </div>

          {/* Location Address starts from here */}
          <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
            <SubmitButton onClick={() => ValidateForm()}>
              Add Dealer
            </SubmitButton>
          </div>
        </div>
      </Container>
    </>
  )
}
