import React, { useEffect, useState } from 'react'
import { CardField } from 'App/Components/Common/CardField'
import { DocumentsBadge } from 'App/Components/Common/DocumentsBadge'
import {
  Container,
  EditStyledButton,
  PageHeader,
  FormTab,
  SubHeader,
} from 'App/Styled'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { Spinner } from 'App/Components/Common/Spinner'
import { useSelector } from 'react-redux'

export const DocumentsPreview = ({ setPreviewModal, isLoading }) => {
  const [activeVehicleTab, setActiveVehicleTab] = useState(1)
  const [activeDocumentTab, setActiveDocumentTab] = useState(1)
  const [vehiclesTabs, setVehiclesTab] = useState(``)
  const [_activeVehicleTab, _setActiveVehicleTab] = useState(1)
  const [_vehiclesTabs, _setVehiclesTab] = useState(``)
  const [activeDriverTab, setActiveDriverTab] = useState(1)
  const [driversTabs, setDriversTab] = useState(``)
  const { currentPolicy } = useSelector(({ policy }) => policy)

  const {
    companyInformation,
    coverageInformation,
    vehicleInformation,
    driverInformation,
    lineOfBusiness,
    paymentInformation,
    policyInformation,
    insuredInformation,
    signature,
    promiseToProvideInformation,
    summary,
  } = currentPolicy?.analyzedData || {}

  const documentsTabs = [
    {
      name: `Company Information`,
      number: 1,
    },
    {
      name: `Coverage Information`,
      number: 2,
    },
    {
      name: `Vehicle Information`,
      number: 3,
    },
    {
      name: `Driver Information`,
      number: 4,
    },
    {
      name: `Insured Information`,
      number: 5,
    },
    {
      name: `Line Of Business`,
      number: 8,
    },
    {
      name: `Payment Information`,
      number: 7,
    },
    {
      name: `Policy Information`,
      number: 6,
    },
    {
      name: `Promise To Provide Information`,
      number: 9,
    },
    {
      name: `Signature Information`,
      number: 10,
    },
    {
      name: `Summary`,
      number: 11,
    },
  ]

  useEffect(() => {
    setVehiclesTab(
      coverageInformation?.vehicles?.map((vehicle, index) => {
        return {
          name: `Vehicle ${index + 1}`,
          number: index + 1,
        }
      }),
    )
    setDriversTab(
      driverInformation?.drivers?.map((driver, index) => {
        return {
          name: `Driver ${index + 1}`,
          number: index + 1,
        }
      }),
    )
    _setVehiclesTab(
      vehicleInformation?.vehicles?.map((vehicle, index) => {
        return {
          name: `Vehicle ${index + 1}`,
          number: index + 1,
        }
      }),
    )
  }, [currentPolicy?.analyzedData])

  return (
    <Container>
      <Spinner loading={isLoading} />

      <>
        <PageHeader>
          <div></div>

          <EditStyledButton onClick={() => setPreviewModal(true)}>
            <span>Upload File</span>
          </EditStyledButton>
        </PageHeader>
        {currentPolicy?.analyzedData ? (
          <>
            <Tabs2
              openTab={activeDocumentTab}
              tabs={documentsTabs}
              getActiveTab={(val) => setActiveDocumentTab(val)}
            />

            {documentsTabs?.map((vehicle, index) => (
              <FormTab
                key={index}
                currenttab={index + 1}
                opentab={activeDocumentTab}
                className="mt-2 shadow-sm rounded-md relative"
              >
                <div className="rounded-md overflow-hidden py-4">
                  <div className="mx-2">
                    {activeDocumentTab === 1 && (
                      <div className="px-4">
                        <CardField
                          title="Source"
                          value={companyInformation?.name?.source}
                        />
                        <CardField
                          title="Non Owners Letter"
                          value={
                            <DocumentsBadge
                              value={
                                companyInformation?.name?.target
                                  ?.nonOwnersLetter
                              }
                            />
                          }
                        />
                        <CardField
                          title="Pleasure Use Letter"
                          value={
                            <DocumentsBadge
                              value={
                                companyInformation?.name?.target
                                  ?.pleasureUseLetter
                              }
                            />
                          }
                        />
                      </div>
                    )}
                    {activeDocumentTab === 2 && (
                      <div className="grid grid-cols-3 gap-4">
                        <div>
                          <div className="w-full mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Property Damage
                            </p>
                          </div>
                          <div className="px-4">
                            <CardField
                              title="Source"
                              value={
                                coverageInformation?.propertyDamage?.source
                              }
                            />
                            <CardField
                              title="Insurance Application"
                              value={
                                <DocumentsBadge
                                  value={
                                    coverageInformation?.propertyDamage?.target
                                      ?.insuranceApplication
                                  }
                                />
                              }
                            />
                          </div>
                          <div>
                            <Tabs2
                              openTab={activeVehicleTab}
                              tabs={vehiclesTabs}
                              getActiveTab={(val) => setActiveVehicleTab(val)}
                            />
                            {coverageInformation?.vehicles?.map(
                              (vehicle, index) => (
                                <FormTab
                                  key={index}
                                  currenttab={index + 1}
                                  opentab={activeVehicleTab}
                                  className="mt-2 shadow-sm rounded-md relative"
                                >
                                  <div className="flex justify-evenly">
                                    <div className="px-4">
                                      <SubHeader>
                                        Comprehensive Deductible
                                      </SubHeader>
                                      <CardField
                                        title="Source"
                                        value={
                                          vehicle?.comprehensiveDeductible
                                            ?.source
                                        }
                                      />
                                      <CardField
                                        title="Insurance Application"
                                        value={
                                          <DocumentsBadge
                                            value={
                                              vehicle?.comprehensiveDeductible
                                                ?.target?.insuranceApplication
                                            }
                                          />
                                        }
                                      />
                                    </div>
                                    <div className="px-4">
                                      <SubHeader>
                                        Collision Deductible
                                      </SubHeader>
                                      <CardField
                                        title="Source"
                                        value={
                                          vehicle?.collisionDeductible?.source
                                        }
                                      />
                                      <CardField
                                        title="Insurance Application"
                                        value={
                                          <DocumentsBadge
                                            value={
                                              vehicle?.collisionDeductible
                                                ?.target?.insuranceApplication
                                            }
                                          />
                                        }
                                      />
                                    </div>
                                  </div>
                                </FormTab>
                              ),
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Bodily Injury
                            </p>
                          </div>

                          <div className="px-4">
                            <CardField
                              title="Source"
                              value={coverageInformation?.bodilyInjury?.source}
                            />
                            <CardField
                              title="Insurance Application"
                              value={
                                <DocumentsBadge
                                  value={
                                    coverageInformation?.bodilyInjury?.target
                                      ?.insuranceApplication
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Uninsured Bodily Injury
                            </p>
                          </div>
                          <div className="px-4">
                            <CardField
                              title="Uninsured Bodily Injury"
                              value={
                                <DocumentsBadge
                                  value={
                                    coverageInformation?.uninsuredBodilyInjury
                                  }
                                />
                              }
                            />
                            <CardField
                              title="Uninsured Property Damage"
                              value={
                                <DocumentsBadge
                                  value={
                                    coverageInformation?.uninsuredPropertyDamage
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeDocumentTab === 3 && (
                      <div>
                        <div>
                          <Tabs2
                            openTab={_activeVehicleTab}
                            tabs={_vehiclesTabs}
                            getActiveTab={(val) => _setActiveVehicleTab(val)}
                          />
                          {vehicleInformation?.vehicles?.map(
                            (vehicle, index) => (
                              <FormTab
                                key={index}
                                currenttab={index + 1}
                                opentab={_activeVehicleTab}
                                className="mt-2 shadow-sm rounded-md relative"
                              >
                                <div className="px-4">
                                  <CardField
                                    title="Make"
                                    value={
                                      <DocumentsBadge value={vehicle?.make} />
                                    }
                                  />
                                  <CardField
                                    title="Model"
                                    value={
                                      <DocumentsBadge value={vehicle?.model} />
                                    }
                                  />
                                  <CardField
                                    title="Vin"
                                    value={
                                      <DocumentsBadge value={vehicle?.vin} />
                                    }
                                  />
                                  <CardField
                                    title="Year"
                                    value={
                                      <DocumentsBadge value={vehicle?.year} />
                                    }
                                  />
                                  <CardField
                                    title="Valid"
                                    value={
                                      <DocumentsBadge
                                        value={vehicle?.isValid}
                                      />
                                    }
                                  />
                                </div>
                              </FormTab>
                            ),
                          )}
                        </div>
                      </div>
                    )}
                    {activeDocumentTab === 4 && (
                      <div>
                        <Tabs2
                          openTab={activeDriverTab}
                          tabs={driversTabs}
                          getActiveTab={(val) => setActiveDriverTab(val)}
                        />
                        {driverInformation?.drivers?.map((driver, index) => (
                          <FormTab
                            key={index}
                            currenttab={index + 1}
                            opentab={activeDriverTab}
                            className="mt-2 shadow-sm rounded-md relative"
                          >
                            <div className="grid grid-cols-3 grid-rows-2 gap-4">
                              <div className="row-span-2">
                                <div className="mt-1 mb-2">
                                  <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                                    Name
                                  </p>
                                </div>
                                <div className="px-4">
                                  <CardField
                                    title="Source"
                                    value={driver?.name?.source}
                                  />
                                  <CardField
                                    title="Insurance Application"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.name?.target
                                            ?.insuranceApplication
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Crm Receipt"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.name?.target?.crmReceipt}
                                      />
                                    }
                                  />
                                  <CardField
                                    title="ITC"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.name?.target?.itc}
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Pleasure Use Letter"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.name?.target
                                            ?.pleasureUseLetter
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Broker Package"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.name?.target?.brokerPackage
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="MVR"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.name?.target?.mvr}
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Artisan Use Letter"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.name?.target?.artisanUseLetter
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Non Owners Letter"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.name?.target?.nonOwnersLetter
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Registration"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.name?.target?.registration
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="VR"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.name?.target?.vr}
                                      />
                                    }
                                  />
                                </div>
                              </div>

                              <div>
                                <div className="mt-1 mb-2">
                                  <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                                    Date Of Birth
                                  </p>
                                </div>
                                <div className="px-4">
                                  <CardField
                                    title="Source"
                                    value={driver?.dateOfBirth?.source}
                                  />
                                  <CardField
                                    title="Insurance Application"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.dateOfBirth?.target
                                            ?.insuranceApplication
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="MVR"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.dateOfBirth?.target?.mvr}
                                      />
                                    }
                                  />
                                  <CardField
                                    title="ITC"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.dateOfBirth?.target?.itc}
                                      />
                                    }
                                  />
                                </div>
                              </div>

                              <div>
                                <div className="mt-1 mb-2">
                                  <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                                    Gender
                                  </p>
                                </div>
                                <div className="px-4">
                                  <CardField
                                    title="Source"
                                    value={driver?.gender?.source}
                                  />
                                  <CardField
                                    title="Insurance Application"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.gender?.target
                                            ?.insuranceApplication
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="MVR"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.gender?.target?.mvr}
                                      />
                                    }
                                  />
                                  <CardField
                                    title="ITC"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.gender?.target?.itc}
                                      />
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="mt-1 mb-2">
                                  <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                                    License Number
                                  </p>
                                </div>
                                <div className="px-4">
                                  <CardField
                                    title="Source"
                                    value={driver?.licenseNumber?.source}
                                  />
                                  <CardField
                                    title="Insurance Application"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.licenseNumber?.target
                                            ?.insuranceApplication
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="MVR"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.licenseNumber?.target?.mvr
                                        }
                                      />
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="mt-1 mb-2">
                                  <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                                    Relationship
                                  </p>
                                </div>
                                <div className="px-4">
                                  <CardField
                                    title="Source"
                                    value={driver?.relationship?.source}
                                  />
                                  <CardField
                                    title="Insurance Application"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          driver?.relationship?.target
                                            ?.insuranceApplication
                                        }
                                      />
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="mt-1 mb-2">
                                  <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                                    Additional Information
                                  </p>
                                </div>
                                <div className="px-4">
                                  <CardField
                                    title="Status"
                                    value={
                                      <DocumentsBadge value={driver?.status} />
                                    }
                                  />
                                  <CardField
                                    title="Marital Status"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.maritalStatus}
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Driving Experience"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.drivingExperience}
                                      />
                                    }
                                  />
                                  <CardField
                                    title="SR Filing"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.srFiling}
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Number Of Violation"
                                    value={
                                      <DocumentsBadge
                                        value={driver?.numberOfViolation}
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </FormTab>
                        ))}
                      </div>
                    )}
                    {activeDocumentTab === 5 && (
                      <div className="grid grid-cols-3 grid-rows-2 gap-4">
                        <div className="row-span-2">
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Name
                            </p>
                          </div>
                          <div className="px-4">
                            <CardField
                              title="Source"
                              value={insuredInformation?.name?.source}
                            />
                            <CardField
                              title="Insurance Application"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.name?.target
                                      ?.insuranceApplication
                                  }
                                />
                              }
                            />
                            <CardField
                              title="Crm Receipt"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.name?.target?.crmReceipt
                                  }
                                />
                              }
                            />
                            <CardField
                              title="ITC"
                              value={
                                <DocumentsBadge
                                  value={insuredInformation?.name?.target?.itc}
                                />
                              }
                            />
                            <CardField
                              title="Pleasure Use Letter"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.name?.target
                                      ?.pleasureUseLetter
                                  }
                                />
                              }
                            />
                            <CardField
                              title="Broker Package"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.name?.target
                                      ?.brokerPackage
                                  }
                                />
                              }
                            />
                            <CardField
                              title="MVR"
                              value={
                                <DocumentsBadge
                                  value={insuredInformation?.name?.target?.mvr}
                                />
                              }
                            />
                            <CardField
                              title="Artisan Use Letter"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.name?.target
                                      ?.artisanUseLetter
                                  }
                                />
                              }
                            />
                            <CardField
                              title="Non Owners Letter"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.name?.target
                                      ?.nonOwnersLetter
                                  }
                                />
                              }
                            />
                            <CardField
                              title="EFT"
                              value={
                                <DocumentsBadge
                                  value={insuredInformation?.name?.target?.eft}
                                />
                              }
                            />
                            <CardField
                              title="Registration"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.name?.target
                                      ?.registration
                                  }
                                />
                              }
                            />
                            <CardField
                              title="VR"
                              value={
                                <DocumentsBadge
                                  value={insuredInformation?.name?.target?.vr}
                                />
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Address
                            </p>
                          </div>
                          <div className="px-4">
                            <CardField
                              title="Source"
                              value={insuredInformation?.address?.source}
                            />
                            <CardField
                              title="Insurance Application"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.address?.target
                                      ?.insuranceApplication
                                  }
                                />
                              }
                            />
                            <CardField
                              title="CRM Receipt"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.address?.target
                                      ?.crmReceipt
                                  }
                                />
                              }
                            />
                            <CardField
                              title="ITC"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.address?.target?.itc
                                  }
                                />
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Date Of Birth
                            </p>
                          </div>
                          <div className="px-4">
                            <CardField
                              title="Source"
                              value={insuredInformation?.dateOfBirth?.source}
                            />
                            <CardField
                              title="Insurance Application"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.dateOfBirth?.target
                                      ?.insuranceApplication
                                  }
                                />
                              }
                            />
                            <CardField
                              title="MVR"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.dateOfBirth?.target?.mvr
                                  }
                                />
                              }
                            />
                            <CardField
                              title="ITC"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.dateOfBirth?.target?.itc
                                  }
                                />
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              License Number
                            </p>
                          </div>
                          <div>
                            <CardField
                              title="Source"
                              value={insuredInformation?.licenseNumber?.source}
                            />
                            <CardField
                              title="Insurance Application"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.licenseNumber?.target
                                      ?.insuranceApplication
                                  }
                                />
                              }
                            />
                            <CardField
                              title="MVR"
                              value={
                                <DocumentsBadge
                                  value={
                                    insuredInformation?.licenseNumber?.target
                                      ?.mvr
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeDocumentTab === 6 && (
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Policy Number
                            </p>
                          </div>
                          <div className="px-4">
                            <CardField
                              title="Source"
                              value={policyInformation?.policyNumber?.source}
                            />
                            <CardField
                              title="CRM Receipt"
                              value={
                                <DocumentsBadge
                                  value={
                                    policyInformation?.policyNumber?.target
                                      ?.crmReceipt
                                  }
                                />
                              }
                            />
                            <CardField
                              title="Pleasure Use Letter"
                              value={
                                <DocumentsBadge
                                  value={
                                    policyInformation?.policyNumber?.target
                                      ?.pleasureUseLetter
                                  }
                                />
                              }
                            />
                            <CardField
                              title="Artisan Use Letter"
                              value={
                                <DocumentsBadge
                                  value={
                                    policyInformation?.policyNumber?.target
                                      ?.artisanUseLetter
                                  }
                                />
                              }
                            />
                            <CardField
                              title="Non Owners Letter"
                              value={
                                <DocumentsBadge
                                  value={
                                    policyInformation?.policyNumber?.target
                                      ?.nonOwnersLetter
                                  }
                                />
                              }
                            />
                            <CardField
                              title="EFT"
                              value={
                                <DocumentsBadge
                                  value={
                                    policyInformation?.policyNumber?.target?.eft
                                  }
                                />
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Policy Term
                            </p>
                          </div>
                          <div className="px-4">
                            <CardField
                              title="Source"
                              value={policyInformation?.policyTerm?.source}
                            />
                            <CardField
                              title="ITC"
                              value={
                                <DocumentsBadge
                                  value={
                                    policyInformation?.policyTerm?.target?.itc
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeDocumentTab === 7 && (
                      <div className="grid grid-rows-2 grid-cols-2 gap-4">
                        <div className="row-span-2">
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Payment
                            </p>
                          </div>
                          <div className="px-4">
                            <CardField
                              title="Payment Date"
                              value={
                                <DocumentsBadge
                                  value={paymentInformation?.paymentDate}
                                />
                              }
                            />
                            <CardField
                              title="VR Fee"
                              value={
                                <DocumentsBadge
                                  value={paymentInformation?.vrFee}
                                />
                              }
                            />
                            <CardField
                              title="Reference Number"
                              value={
                                <DocumentsBadge
                                  value={paymentInformation?.referenceNumber}
                                />
                              }
                            />
                            <CardField
                              title="Amount Paid"
                              value={
                                <DocumentsBadge
                                  value={paymentInformation?.amountPaid}
                                />
                              }
                            />
                            <CardField
                              title="Payment Method"
                              value={
                                <DocumentsBadge
                                  value={paymentInformation?.paymentMethod}
                                />
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Down Payment
                            </p>
                          </div>
                          <div className="px-4">
                            <CardField
                              title="Source"
                              value={paymentInformation?.downPayment?.source}
                            />
                            <CardField
                              title="CRM Receipt"
                              value={
                                <DocumentsBadge
                                  value={
                                    paymentInformation?.downPayment?.target
                                      ?.crmReceipt
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                        <div>
                          <div className="mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Broker Fee
                            </p>
                          </div>
                          <div className="px-4">
                            <CardField
                              title="Source"
                              value={paymentInformation?.brokerFee?.source}
                            />
                            <CardField
                              title="Broker Package"
                              value={
                                <DocumentsBadge
                                  value={
                                    paymentInformation?.brokerFee?.target
                                      ?.brokerPackage
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {activeDocumentTab === 8 && (
                      <div className="px-4">
                        <CardField
                          title="Source"
                          value={lineOfBusiness?.source}
                        />
                        <CardField
                          title="CRM Receipt"
                          value={
                            <DocumentsBadge
                              value={lineOfBusiness?.target?.crmReceipt}
                            />
                          }
                        />
                      </div>
                    )}
                    {activeDocumentTab === 9 && (
                      <div className="px-4">
                        <CardField
                          title="Promise To Provide Information"
                          value={
                            <DocumentsBadge
                              value={promiseToProvideInformation}
                            />
                          }
                        />
                      </div>
                    )}
                    {activeDocumentTab === 10 && (
                      <div className="col-span-3">
                        <div>
                          <div className="w-full mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Insurance Application
                            </p>
                          </div>
                          <div className="grid grid-cols-4 px-4">
                            <div>
                              <SubHeader>Third Party Designation</SubHeader>
                              {signature?.signature?.insuranceApplication
                                ?.thirdPartyDesignation === null ? (
                                <CardField
                                  title="Consumer Disclosure"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.thirdPartyDesignation?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.thirdPartyDesignation?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>
                                Disclosure Of All Household Members
                              </SubHeader>
                              {signature?.signature?.insuranceApplication
                                ?.disclosureOfAllHouseholdMembers === null ? (
                                <CardField
                                  title="Consumer Disclosure"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.disclosureOfAllHouseholdMembers
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.disclosureOfAllHouseholdMembers
                                            ?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>Rejection Of Bi Coverage</SubHeader>
                              {signature?.signature?.insuranceApplication
                                ?.rejectionOfBiCoverage === null ? (
                                <CardField
                                  title="Consumer Disclosure"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.rejectionOfBiCoverage?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.rejectionOfBiCoverage?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>Rejection Of Pd Coverage</SubHeader>

                              {signature?.signature?.insuranceApplication
                                ?.rejectionOfPdCoverage === null ? (
                                <CardField
                                  title="Consumer Disclosure"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.rejectionOfPdCoverage?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.rejectionOfPdCoverage?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>
                                Vehicle Condition Certification
                              </SubHeader>
                              {signature?.signature?.insuranceApplication
                                ?.vehicleConditionCertification === null ? (
                                <CardField
                                  title="Consumer Disclosure"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.vehicleConditionCertification
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.vehicleConditionCertification
                                            ?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>
                                Acknowledgements By Applicant
                              </SubHeader>

                              {signature?.signature?.insuranceApplication
                                ?.acknowledgementsByApplicant === null ? (
                                <CardField
                                  title="Consumer Disclosure"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.acknowledgementsByApplicant
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.acknowledgementsByApplicant
                                            ?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>
                                Acknowledgement Of Programs Offered
                              </SubHeader>

                              {signature?.signature?.insuranceApplication
                                ?.acknowledgementOfProgramsOffered === null ? (
                                <CardField
                                  title="Consumer Disclosure"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.acknowledgementOfProgramsOffered
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.acknowledgementOfProgramsOffered
                                            ?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>Not Insurance Contract</SubHeader>

                              {signature?.signature?.insuranceApplication
                                ?.notInsuranceContract === null ? (
                                <CardField
                                  title="Not Insurance Contract"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.notInsuranceContract?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.notInsuranceContract?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>Named Driver Exclusion</SubHeader>

                              {signature?.signature?.insuranceApplication
                                ?.namedDriverExclusion === null ? (
                                <CardField
                                  title="Named Driver Exclusion"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.namedDriverExclusion?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.namedDriverExclusion?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>
                                No Fault Accident Declaration
                              </SubHeader>
                              {signature?.signature?.insuranceApplication
                                ?.noFaultAccidentDeclaration === null ? (
                                <CardField
                                  title="No Fault Accident Declaration"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.noFaultAccidentDeclaration
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.noFaultAccidentDeclaration
                                            ?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>Consumer Disclosure</SubHeader>
                              {signature?.signature?.insuranceApplication
                                ?.consumerDisclosure === null ? (
                                <CardField
                                  title="Consumer Disclosure"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.consumerDisclosure?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.consumerDisclosure?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>
                                Non Owned Vehicle Coverage Endorsement
                              </SubHeader>
                              {signature?.signature?.insuranceApplication
                                ?.nonOwnedVehicleCoverageEndorsement ===
                              null ? (
                                <CardField
                                  title="Vehicle Coverage Endorsement"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.nonOwnedVehicleCoverageEndorsement
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.nonOwnedVehicleCoverageEndorsement
                                            ?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>Cancellation Request</SubHeader>
                              {signature?.signature?.insuranceApplication
                                ?.cancellationRequest === null ? (
                                <CardField
                                  title="Cancellation Request"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.cancellationRequest?.isSigned
                                        }
                                      />
                                    }
                                  />
                                  <CardField
                                    title="Dated"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature
                                            ?.insuranceApplication
                                            ?.cancellationRequest?.isDated
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="w-full mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              ITC
                            </p>
                          </div>
                          <div className="grid grid-cols-4 px-4">
                            <div>
                              <SubHeader>Insured Information</SubHeader>

                              {signature?.signature?.itc?.insuredInformation ===
                              null ? (
                                <CardField
                                  title="Insured Information"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.itc
                                            ?.insuredInformation?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>Vehicle Information</SubHeader>
                              {signature?.signature?.itc?.vehicleInformation ===
                              null ? (
                                <CardField
                                  title="Vehicle Information"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.itc
                                            ?.vehicleInformation?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <SubHeader>EOD Information</SubHeader>
                              {signature?.signature?.itc?.eodInformation ===
                              null ? (
                                <CardField
                                  title="EOD Information"
                                  value="N/A"
                                />
                              ) : (
                                <>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.itc
                                            ?.eodInformation?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="w-full mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md ">
                              Broker Package
                            </p>
                          </div>
                          <div className="grid grid-cols-4 gap-1 px-2">
                            <div>
                              <div className="w-full mt-1 mb-2">
                                <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md ">
                                  Disclosures
                                </p>
                              </div>
                              <div className="px-2">
                                <SubHeader>Coverages</SubHeader>
                                <CardField
                                  title="Signed"
                                  value={
                                    <DocumentsBadge
                                      value={
                                        signature?.signature?.brokerPackage
                                          ?.disclosures?.coverages?.isSigned
                                      }
                                    />
                                  }
                                />
                              </div>
                              <div className="px-2">
                                <SubHeader>
                                  Disclosure Of Driving Record
                                </SubHeader>
                                <CardField
                                  title="Signed"
                                  value={
                                    <DocumentsBadge
                                      value={
                                        signature?.signature?.brokerPackage
                                          ?.disclosures
                                          ?.disclosureOfDrivingRecord?.isSigned
                                      }
                                    />
                                  }
                                />
                              </div>
                              <div>
                                <div className="w-full mt-1 mb-2 px-2">
                                  <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md ">
                                    Exclusion
                                  </p>
                                </div>
                                <div className="px-4">
                                  <SubHeader>
                                    Uninsured Bi Or Pd Coverage
                                  </SubHeader>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.brokerPackage
                                            ?.disclosures?.exclusion
                                            ?.uninsuredBiOrPdCoverage?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </div>
                                <div className="px-4">
                                  <SubHeader>
                                    Comprehensive And Collision Coverage
                                  </SubHeader>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.brokerPackage
                                            ?.disclosures?.exclusion
                                            ?.comprehensiveAndCollisionCoverage
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </div>
                                <div className="px-4">
                                  <SubHeader>
                                    Business Or CommercialUse
                                  </SubHeader>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.brokerPackage
                                            ?.disclosures?.exclusion
                                            ?.businessOrCommercialUse?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </div>
                                <div className="px-4">
                                  <SubHeader>
                                    Named Drivers Limitation
                                  </SubHeader>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.brokerPackage
                                            ?.disclosures?.exclusion
                                            ?.namedDriversLimitation?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div className="w-full mt-1 mb-2">
                                  <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md ">
                                    Standard Broker Fee Disclosure
                                  </p>
                                </div>
                                <div className="px-4">
                                  <SubHeader>
                                    Standard Broker Fee Disclosure
                                  </SubHeader>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.brokerPackage
                                            ?.standardBrokerFeeDisclosure
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div className="w-full mt-1 mb-2">
                                  <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md ">
                                    Text Messaging Consent Agreement
                                  </p>
                                </div>
                                <div className="px-4">
                                  <SubHeader>
                                    Text Messaging Consent Agreement
                                  </SubHeader>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.brokerPackage
                                            ?.textMessagingConsentAgreement
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div className="w-full mt-1 mb-2">
                                  <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md ">
                                    Broker Fee Agreement
                                  </p>
                                </div>
                                <div className="px-4">
                                  <SubHeader>Client Initials</SubHeader>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.brokerPackage
                                            ?.brokerFeeAgreement?.clientInitials
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </div>
                                <div className="px-4">
                                  <SubHeader>Client Signature</SubHeader>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.brokerPackage
                                            ?.brokerFeeAgreement
                                            ?.clientSignature?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </div>
                                <div className="px-4">
                                  <SubHeader>
                                    Condition And Acknowledgement Agreement
                                  </SubHeader>
                                  <CardField
                                    title="Signed"
                                    value={
                                      <DocumentsBadge
                                        value={
                                          signature?.signature?.brokerPackage
                                            ?.brokerFeeAgreement
                                            ?.conditionAndAcknowledgementAgreement
                                            ?.isSigned
                                        }
                                      />
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="w-full mt-1 mb-2">
                            <p className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              Additional Documents
                            </p>
                          </div>
                          <div className="grid grid-cols-3 px-4">
                            <div>
                              <SubHeader>Artisan Use Letter</SubHeader>
                              <CardField
                                title="Signed"
                                value={
                                  <DocumentsBadge
                                    value={
                                      signature?.signature?.artisanUseLetter
                                    }
                                  />
                                }
                              />
                            </div>
                            <div>
                              <SubHeader>EFT</SubHeader>
                              <CardField
                                title="Signed"
                                value={
                                  <DocumentsBadge
                                    value={signature?.signature?.eft}
                                  />
                                }
                              />
                            </div>
                            <div>
                              <SubHeader>Non Owners Letter</SubHeader>
                              <CardField
                                title="Signed"
                                value={
                                  <DocumentsBadge
                                    value={
                                      signature?.signature?.nonOwnersLetter
                                    }
                                  />
                                }
                              />
                            </div>
                            <div>
                              <SubHeader>Pleasure Use Letter</SubHeader>
                              <CardField
                                title="Signed"
                                value={
                                  <DocumentsBadge
                                    value={
                                      signature?.signature?.pleasureUseLetter
                                        ?.isSigned
                                    }
                                  />
                                }
                              />
                            </div>
                            <div>
                              <SubHeader>Promise To Provide</SubHeader>
                              <CardField
                                title="Signed"
                                value={
                                  <DocumentsBadge
                                    value={
                                      signature?.signature?.promiseToProvide
                                    }
                                  />
                                }
                              />
                            </div>
                            <div>
                              <SubHeader>MVR</SubHeader>
                              <CardField
                                title="Signed"
                                value={
                                  <DocumentsBadge
                                    value={signature?.signature?.mvr?.isSigned}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeDocumentTab === 11 && (
                      <div className="px-4">
                        <CardField
                          title="Total Documents"
                          value={
                            summary?.verified +
                            summary?.unVerified +
                            summary?.notFound
                          }
                        />
                        <CardField title="verified" value={summary?.verified} />
                        <CardField
                          title="Unverified"
                          value={summary?.unVerified}
                        />
                        <CardField
                          title="Not Found"
                          value={summary?.notFound}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </FormTab>
            ))}
          </>
        ) : (
          !isLoading && (
            <div className="text-center py-10">No File to verify</div>
          )
        )}
      </>
    </Container>
  )
}
