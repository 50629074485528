import React, { useEffect, useState } from 'react'
import { defaultImage } from 'App/Assets'
import { useMutation, useQuery } from 'react-query'
// import { Toggle } from 'App/Components/Common/Toggle'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Avatar,
} from '@windmill/react-ui'
import {
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import dateFormat from 'App/Services/General/dateFormat'

const tableColumns = [
  { label: `First Name`, value: `firstName` },
  { label: `Last Name`, value: `lastName` },
  { label: `Type`, value: `type` },
  { label: `Email`, value: `email` },
  { label: `Phone`, value: `phone` },
  { label: `Policies`, value: `policies` },
  { label: `Created`, value: `createdAt` },
  { label: `Updated`, value: `updatedAt` },
]

export const FCustomers = () => {
  const history = useHistory()
  const {
    params: { id },
  } = useRouteMatch()

  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [isOffset, setIsOffset] = useState(``)

  // const [isMasterFrenchise, setIsMasterFrenchise] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)

  //   getting all customers
  const {
    isLoading,
    data: customersData,
    refetch: getFCustomers,
    isFetching,
    error,
  } = useQuery(
    `FCustomersApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/franchises/${id}/customers?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }", "onlyMasterCustomers": ${false}}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    mutate: deleteCustomer,
    isLoading: deletingCustomer,
    isError: deletionError,
  } = useMutation(
    async (cId) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/customers/${cId}`,
      ).then((res) => {
        res.data && getFCustomers()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  useEffect(() => {
    if (!customSearching) {
      getFCustomers()
    }
  }, [sort, currentPage, isOffset])

  const callCustomerQuery = useDebouncedCallback(() => {
    getFCustomers()
  }, 700)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteCustomer(id)
  }

  return (
    <Container>
      {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <PageHeader>
        <div>Customers</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          {/* <Toggle value={isMasterFrenchise} setValue={setIsMasterFrenchise} /> */}
          <div className="text-end ">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              setValue={(e) => {
                setSearchQuery(e.target.value)
                setCurrentPage(1)
                setCustomSearching(true)
                callCustomerQuery()
              }}
            />
          </div>
        </div>
      </PageHeader>

      <Spinner loading={deletingCustomer || isLoading || isFetching} />

      {/* {permissionGranted([`customers:create`,`admin-franchise`,`admin-master-franchise`]) && (
        <div className="flex justify-end items-center p-2 ">
          <StyledButton onClick={() => history.push(`/customers/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        </div>
      )} */}

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (deletingCustomer || isLoading || isFetching) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getFCustomers()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    <TableCell className="select-none text-sm">Image</TableCell>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item?.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {permissionGranted([`corporate-manager:*`]) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !customersData?.customers?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        No Customer Found
                      </td>
                    </tr>
                  ) : (
                    customersData?.customers?.map((_customer, index) => (
                      <TableRow
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          if (permissionGranted([`corporate-manager:*`])) {
                            history.push(`/customers/${_customer._id}`)
                          }
                        }}
                      >
                        <td className="px-5 py-1.5 text-sm">
                          <div className="flex items-center text-sm">
                            <Avatar
                              src={_customer.img ? _customer.img : defaultImage}
                              alt="Judith"
                            />
                          </div>
                        </td>
                        <td className="px-4 py-0.5 text-sm capitalize truncate">
                          {_customer.firstName ? (
                            _customer.firstName?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 text-sm capitalize truncate">
                          {_customer.lastName ? (
                            _customer.lastName?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 capitalize text-sm">
                          {_customer.type ? (
                            _customer.type?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>

                        <td className="px-4 py-0.5 text-sm">
                          {_customer.contactInfo?.email ? (
                            _customer.contactInfo?.email
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 text-sm w-auto">
                          {_customer.contactInfo?.phone ? (
                            _customer.contactInfo?.phone
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 w-auto text-sm">
                          {_customer._policies ? (
                            <Badge>{_customer._policies.length}</Badge>
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 w-auto text-sm truncate">
                          {_customer.createdAt ? (
                            dateFormat(_customer.createdAt)
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 w-auto text-sm truncate">
                          {_customer.updatedAt ? (
                            dateFormat(_customer.updatedAt)
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        {/* Actions TD */}
                        <td className="px-8 py-2.5 z-0 flex text-sm float-right text-gray-400">
                          {permissionGranted([`corporate-manager:*`]) && (
                            <span data-tip="Delete" className="tooltip">
                              <FontAwesomeIcon
                                className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                icon={faTrash}
                                size="1x"
                                onClick={(e) => handleDelete(_customer._id, e)}
                              />
                            </span>
                          )}
                        </td>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={customersData?.totalPages}
                totalRecords={customersData?.totalRecords}
                curerntPageRecords={customersData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
