import React, { useEffect, useState } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, StyledButton, PageHeader } from 'App/Styled'
import { UpdateQuoteStatus } from '../UpdateQuote/UpdateQuoteStatus'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faSortDown,
  faSortUp,
  faEdit,
  faSpinner,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { ReactModal } from 'App/Components/Common/Modal'
import { useMutation, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { setMyAllQuotes } from 'App/Redux/actions'
import { StartCampaign } from 'App/Components/StartCampaign'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { permissionGranted } from 'App/Services'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { NotificationManager } from 'react-notifications'
import { loadPreferredContact } from 'App/Services/quoteForm'
import { loadOffset } from 'App/Services/General/loadOffset'
import dateFormat from 'App/Services/General/dateFormat'
import FiltersQuotes from '../FiltersQuotes'

const tableColumns = [
  { label: `Quote No`, value: `_quoteId` },
  { label: `TurboID`, value: `_turboRaterId` },
  { label: `Customer`, value: `policyData._customer.firstName` },
  { label: `Customer Phone`, value: `policyData._customer.contactInfo.phone` },
  { label: `Type`, value: `quoteType` },
  { label: `Status`, value: `status` },
  { label: `Price`, value: `policyMetadata.priceSold` },
  { label: `Created`, value: `createdAt` },
]

export const MyQuotes = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const dispatch = useDispatch()
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [inActiveCheckedAll, setinActiveCheckedAll] = useState([])
  const [startCampaignModal, setStartCampaignModal] = useState(false)
  const [updateContactMethod, setUpdateContactMethod] = useState(false)
  const [selectedChannel, setSelectedChannel] = useState(``)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)
  const [quoteContactMethods, setQuoteContactMethods] = useState(``)
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
      isSelected: false,
    },
  ])

  const [quoteFilters, setQuoteFilters] = useState({
    quoteId: ``,
    status: ``,
    type: ``,
    price: ``,
    _turboRaterId: ``,
  })

  const [currentQuote, setCurrentQuote] = useState(``)

  const [quoteUpdated, setQuoteUpdated] = useState(false)
  const [updateStatusModal, setUpdateStatusModal] = useState(false)

  const [stateChecked, setStateChecked] = useState({
    allSelected: false,
    isCheckedAll: false,
    selected: [],
    unselected: [],
  })
  //Offset
  const [isOffset, setIsOffset] = useState(``)

  //   getting all quotes
  const {
    isLoading,
    data: quotesResponse,
    refetch: getMyQuotes,
    isFetching,
    error,
  } = useQuery(
    `MyQuotesApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/quotes/all?search=${searchFilters()}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomSearching(false)
        if (res) {
          dispatch(setMyAllQuotes(res?.quotes))
        }

        setStateChecked((prevState) => ({
          ...prevState,
          isCheckedAll: handleIsCheckedAllSelected(res.quotes),
        }))
        if (stateChecked.allSelected) {
          setStateChecked((prevState) => ({
            ...prevState,
            isCheckedAll: !inActiveCheckedAll.includes(currentPage),
            selected: handleAddSelected(res.quotes),
          }))
        }
      },
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  useEffect(() => {
    if (!customSearching) {
      getMyQuotes()
    }
  }, [sort, currentPage, isOffset, quoteFilters])

  useEffect(() => {
    if (quoteUpdated) {
      getMyQuotes()
      setQuoteUpdated(false)
    }
  }, [quoteUpdated])
  useEffect(() => {
    setQuoteContactMethods(
      currentQuote?.metadata?.preferredContactMethods?.map((method) => ({
        value: method,
        label: method.toUpperCase(),
      })),
    )
  }, [currentQuote])

  const handleResetFilters = () => {
    setQuoteFilters({
      quoteId: ``,
      status: ``,
      type: ``,
      price: ``,
      _turboRaterId: ``,
    })
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: `selection`,
      },
    ])
  }

  const handleFiltersChange = (filters) => {
    setCurrentPage(1)
    setQuoteFilters(filters)
  }

  const handleDateRange = (range) => {
    setDateRange(range)
  }

  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }

    if (quoteFilters?.quoteId?.length) {
      data.filters.quoteId = quoteFilters?.quoteId
    }
    if (quoteFilters?.status?.length) {
      data.filters.status = quoteFilters?.status
    }
    if (quoteFilters?.type?.length) {
      data.filters.type = quoteFilters?.type
    }
    if (quoteFilters?.price?.length) {
      data.filters.price = quoteFilters?.price
    }
    if (quoteFilters?._turboRaterId) {
      data.filters._turboRaterId = quoteFilters?._turboRaterId
    }
    if (dateRange[0].isSelected) {
      data.filters.createdAt = [
        dateFormat(dateRange[0].startDate),
        dateFormat(dateRange[0].endDate),
      ]
    }
    return JSON.stringify(data)
  }

  const handleQuoteUpdate = (quote, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    // if (quote.status !== `POLICY`) {
    //   setCurrentQuote(quote)
    //   setUpdateStatusModal(true)
    // }
    history.push(`${url}/${quote._id}/update`)
  }

  const handleContactMethods = (quote, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (quote?.status !== `POLICY`) {
      setCurrentQuote(quote)
      setUpdateContactMethod(true)
      setQuoteContactMethods(
        currentQuote.metadata?.preferredContactMethods?.map((method) => ({
          value: method,
          label: method.toUpperCase(),
        })),
      )
    }
  }

  useEffect(() => {
    if (stateChecked.allSelected) {
      setStateChecked({
        allSelected: true,
        isCheckedAll: true,
        selected: quotesResponse.quotes?.map((lead) => lead._id),
        unselected: [],
      })
    } else {
      setStateChecked({
        allSelected: false,
        isCheckedAll: false,
        selected: [],
        unselected: [],
      })
    }
  }, [stateChecked.allSelected])

  const handleIsCheckedAllSelected = (currentLeads) => {
    const filterdSelected = currentLeads
      .map((lead) => {
        if (!stateChecked.selected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    if (filterdSelected.length) {
      return false
    } else {
      return true
    }
  }

  const handleAddSelected = (currentLeads) => {
    let filterdUnselected
    if (stateChecked.unselected.length) {
      filterdUnselected = currentLeads
        .map((lead) => {
          if (!stateChecked.unselected.includes(lead._id)) {
            return lead._id
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    } else {
      filterdUnselected = currentLeads.map((lead) => lead._id)
    }

    const filterdSelected = filterdUnselected
      .map((lead) => {
        if (!stateChecked.selected.includes(lead)) {
          return lead
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.selected, ...filterdSelected]
  }

  const handleIndividualChekbox = (e, id) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!e.target.checked) {
      setStateChecked((prevState) => ({
        ...prevState,
        selected: stateChecked.selected.filter((lead) => lead !== id),
        unselected: [...stateChecked.unselected, id],
      }))
    } else {
      setStateChecked((prevState) => ({
        ...prevState,
        selected: [...stateChecked.selected, id],
        unselected: stateChecked.unselected.filter((lead) => lead !== id),
      }))
    }
  }

  const handleCheckAll = (e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (e.target.checked) {
      setinActiveCheckedAll(
        inActiveCheckedAll.filter((el) => el !== currentPage),
      )
      setStateChecked((prevState) => ({
        ...prevState,
        isCheckedAll: true,
        selected: handleCheckedAllSelected(),
        unselected: removeOneArrayFromAnother(stateChecked.unselected),
      }))
    } else {
      setinActiveCheckedAll([...inActiveCheckedAll, currentPage])
      setStateChecked((prevState) => ({
        ...prevState,
        isCheckedAll: false,
        selected: removeOneArrayFromAnother(stateChecked.selected),
        unselected: handleRemvoeUnSelected(),
      }))
    }
  }

  const handleCheckedAllSelected = () => {
    const filterdSelected = quotesResponse.quotes
      .map((lead) => {
        if (!stateChecked.selected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.selected, ...filterdSelected]
  }

  const handleRemvoeUnSelected = () => {
    const filterdSelected = quotesResponse.quotes
      .map((lead) => {
        if (!stateChecked.unselected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.unselected, ...filterdSelected]
  }

  const removeOneArrayFromAnother = (removeFromArray) => {
    const leadsToBeRemoved = quotesResponse.quotes.map((lead) => lead._id)
    return removeFromArray.filter((el) => {
      return !leadsToBeRemoved.includes(el)
    })
  }

  const {
    mutate: updateQuote,
    isLoading: updateLoading,
    // isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/quotes/${payload?.quoteId}`,
        {
          preferredContactMethods: payload?.preferredContactMethods,
        },
      )
        .then((res) => {
          if (res.data) {
            getMyQuotes()
            setUpdateContactMethod(false)
          }
        })
        .catch(() => NotificationManager.error(`Something went wrong!`)),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  return (
    <Container>
      {updateContactMethod && (
        <ReactModal
          title="Update Contact Methods"
          closeModal={() => setUpdateContactMethod(false)}
        >
          <div className="w-96 px-4 py-1">
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Contact Methods"
                  placeholder="Select Methods"
                  // errorMessage={errors.leadStatusError}
                  value={quoteContactMethods}
                  setValue={(e) => setQuoteContactMethods(e)}
                  isMulti={true}
                  loadOptions={loadPreferredContact}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="pb-0.5 text-right">
              <StyledButton
                onClick={() =>
                  updateQuote({
                    preferredContactMethods: quoteContactMethods.map(
                      (method) => method.value,
                    ),
                    quoteId: currentQuote?._id,
                  })
                }
                disabled={!quoteContactMethods?.length}
              >
                <div className="flex gap-2 text-white">
                  <span>Update</span>
                  {updateLoading && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin={true}
                      className="my-auto"
                    />
                  )}
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}
      {startCampaignModal && (
        <ReactModal
          title="Start Campaign"
          closeModal={() => setStartCampaignModal(false)}
          scroll={selectedChannel === `MAIL` ? true : false}
        >
          <StartCampaign
            usersList={stateChecked}
            closeModal={() => setStartCampaignModal(false)}
            source="quotes"
            count={
              quotesResponse?.totalRecords - stateChecked.unselected.length
            }
            selectedChannel={setSelectedChannel}
          />
        </ReactModal>
      )}

      {updateStatusModal && (
        <ReactModal
          title="Update Quote"
          closeModal={() => setUpdateStatusModal(false)}
          maxWidth="5xl"
          scroll
          height="96"
        >
          <UpdateQuoteStatus
            selectedQuote={currentQuote}
            closeModal={() => setUpdateStatusModal(false)}
            quoteUpdated={() => setQuoteUpdated(true)}
          />
        </ReactModal>
      )}
      <Spinner loading={isLoading || isFetching || updateLoading} />
      <PageHeader>
        <div>My Quotes</div>
        <div className="flex items-center ">
          <div className="text-end ">
            <div className="flex">
              <div className="py-1 px-2">
                <ReactSelectField
                  placeholder="10"
                  value={isOffset}
                  setValue={(e) => {
                    setCurrentPage(1)
                    setIsOffset(e)
                  }}
                  isMulti={false}
                  loadOptions={loadOffset}
                />
              </div>
            </div>
          </div>
        </div>
      </PageHeader>
      <FiltersQuotes
        filtersProps={quoteFilters}
        handleFilters={handleFiltersChange}
        resetFilters={handleResetFilters}
        _dateRange={dateRange}
        handleDateChange={handleDateRange}
        isLoading={isLoading || isFetching}
      />

      {/* filters */}
      <>
        <div
          className={`mb-1 sm:mb-0 justify-between w-full px-2  ${
            (isLoading || isFetching || updateLoading) &&
            ` opacity-30 pointer-events-none`
          }`}
        >
          {permissionGranted([
            `broadcasts:create`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && (
            <div className="flex justify-between items-center pb-2">
              <StyledButton
                onClick={() =>
                  setStateChecked((prevState) => ({
                    ...prevState,
                    allSelected: !stateChecked.allSelected,
                  }))
                }
              >
                <div className="flex gap-2 text-white">
                  <span>
                    {stateChecked?.allSelected ? `Selected ` : `Select All `}
                    {quotesResponse?.totalRecords -
                      stateChecked.unselected.length}
                  </span>
                </div>
              </StyledButton>

              <StyledButton
                disabled={!stateChecked?.selected?.length}
                onClick={() => setStartCampaignModal(true)}
              >
                <div className="flex gap-2 text-white">
                  <span>Start Campaign </span>
                </div>
              </StyledButton>
            </div>
          )}

          {error ? (
            <Reload refetch={() => getMyQuotes()} />
          ) : (
            <>
              <div className="overflow-x-auto overflow-y-hidden  rounded-t-md">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow>
                      {permissionGranted([
                        `broadcasts:create`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) && (
                        <TableCell className="text-center">
                          <div className="flex-1">
                            <Checkbox
                              name="checkAll"
                              value={stateChecked?.isCheckedAll}
                              setValue={(e) => handleCheckAll(e)}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                              disabled={
                                !permissionGranted([
                                  `broadcasts:create`,
                                  `corporate-manager:*`,
                                  `manager:*`,
                                  `admin:*`,
                                ])
                              }
                            />
                          </div>
                        </TableCell>
                      )}
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div
                              className={`${
                                sort.activeSort === item.label && `text-red-500`
                              } cursor-pointer select-none text-sm flex truncate`}
                            >
                              <div
                                onClick={() => {
                                  handleSorting(item.value)
                                }}
                                className={`flex ${
                                  item.value === sort ? `text-red-500` : ``
                                }`}
                              >
                                {item.label}
                              </div>
                              <div
                                className="ml-2 px-1 rounded-sm cursor-pointer flex relative"
                                onClick={() => handleSorting(`-` + item.value)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    sort.includes(`-`) &&
                                    sort == `-` + item.value
                                      ? faSortUp
                                      : faSortDown
                                  }
                                  size="lg"
                                  className={`${
                                    sort.includes(`-`) &&
                                    sort == `-` + item.value
                                      ? `text-red-500`
                                      : `-mt-1.5`
                                  }`}
                                />
                              </div>
                            </div>
                          </TableCell>
                        )
                      })}
                      {permissionGranted([
                        `quotes:update`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) && (
                        <TableCell className="select-none text-sm">
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {!isLoading && !quotesResponse?.quotes?.length ? (
                      <tr>
                        <td colSpan="12">
                          <div className="w-full text-center p-6  ">
                            <p>No Quotes Found</p>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      quotesResponse?.quotes?.map((quote, index) => (
                        <TableRow
                          key={index}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => history.push(`${url}/${quote._id}`)}
                        >
                          {permissionGranted([
                            `broadcasts:create`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ]) && (
                            <td
                              className="px-4 text-sm"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Checkbox
                                name={quote?._id}
                                value={stateChecked?.selected.includes(
                                  quote?._id,
                                )}
                                setValue={(e) =>
                                  handleIndividualChekbox(e, quote?._id)
                                }
                                disabled={
                                  !permissionGranted([
                                    `broadcasts:create`,
                                    `corporate-manager:*`,
                                    `manager:*`,
                                    `admin:*`,
                                  ])
                                }
                              />
                            </td>
                          )}

                          <td className="px-4 py-0.5 text-sm">
                            {quote._quoteId ? (
                              quote._quoteId
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {quote._turboRaterId}
                          </td>
                          <td className="px-4 py-0.5 text-sm whitespace-nowrap">
                            {quote?.policyData?._customer?.firstName ? (
                              quote?.policyData?._customer?.firstName +
                              ` ` +
                              quote?.policyData?._customer?.lastName
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {quote?.policyData?._customer?.contactInfo
                              ?.phone ? (
                              quote?.policyData?._customer?.contactInfo?.phone
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm whitespace-nowrap ">
                            {quote?.quoteType ? (
                              <Badge>{quote?.quoteType}</Badge>
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            <Badge>{quote?.status}</Badge>
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {quote?.policyMetadata?.priceSold ? (
                              `$` + quote?.policyMetadata?.priceSold
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>

                          <td className="px-4 py-0.5 text-sm">
                            {dateFormat(quote?.createdAt)}
                          </td>

                          {/* Actions TD */}
                          <td className="px-4 pt-3 text-sm z-0 float-right text-gray-400">
                            {permissionGranted([
                              `quotes:update`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ]) && (
                              <>
                                <span
                                  data-tip="Contact Methods"
                                  className="tooltip tooltip-left"
                                >
                                  {updateLoading &&
                                  currentQuote?._id === quote?._id ? (
                                    <FontAwesomeIcon
                                      icon={faSpinner}
                                      spin={true}
                                      className="my-auto mx-1"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className={`mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 ${
                                        quote?.status === `POLICY`
                                          ? `cursor-not-allowed`
                                          : `cursor-pointer`
                                      } `}
                                      icon={faMobileAlt}
                                      size="1x"
                                      onClick={(e) => {
                                        handleContactMethods(quote, e)
                                      }}
                                    />
                                  )}
                                </span>
                                <span
                                  data-tip={`${
                                    quote.status !== `POLICY`
                                      ? `Update Status`
                                      : `Quote Sold`
                                  }`}
                                  className="tooltip tooltip-left"
                                >
                                  <FontAwesomeIcon
                                    className={`mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 ${
                                      quote.status !== `POLICY`
                                        ? `cursor-pointer`
                                        : `cursor-not-allowed`
                                    }`}
                                    icon={faEdit}
                                    size="1x"
                                    onClick={(e) => handleQuoteUpdate(quote, e)}
                                  />
                                </span>
                              </>
                            )}
                          </td>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={quotesResponse?.totalPages}
                  totalRecords={quotesResponse?.totalRecords}
                  curerntPageRecords={quotesResponse?.currentPageRecords}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          )}
        </div>
      </>
    </Container>
  )
}
