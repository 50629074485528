import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputField } from 'App/Components/Common/InputField'
import { PageHeader, SubmitButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'

const Lobby = ({ getUserName, isLoadingRoom, participantLength }) => {
  const [userName, setUserName] = useState(``)

  const [errors, setErrors] = useState({
    nameError: ``,
    participantsLengthError: ``,
    formSubmit: false,
  })

  const validateForm = () => {
    if (!userName.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }
    if (userName.length && participantLength > 4) {
      NotificationManager.error(`Room Is Full`)
      handleErrors(`participantsLengthError`, `Room Is Full`)
    } else {
      handleErrors(`participantsLengthError`, ``)
    }
    handleErrors(`formSubmit`, true)
  }
  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.participantsLengthError.length
    ) {
      getUserName(userName)
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  return (
    <div className={`h-500 w-96 mx-auto lg:w-800 overflow-hidden lg:mx-auto`}>
      <div className="max-w-xs mx-auto py-24 ">
        <PageHeader>Video Call Room</PageHeader>

        <div>
          <div className="flex flex-row">
            <div className="relative mt-4 mx-auto">
              <InputField
                title="Enter Name"
                placeholder="Name"
                value={userName}
                setKeyDownValue={(e) => e.key === `Enter` && validateForm()}
                setValue={(e) => setUserName(e.target.value)}
                errorMessage={errors?.nameError}
              />
              {errors.participantsLengthError.length ? (
                <p className="text-sm text-red-500">
                  {errors.participantsLengthError}
                </p>
              ) : (
                ``
              )}
            </div>
          </div>

          <SubmitButton
            disabled={isLoadingRoom}
            className={`mt-4`}
            onClick={() => validateForm()}
          >
            {isLoadingRoom && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                size="1x"
                className="mx-4"
              />
            )}
            {isLoadingRoom ? `Processing...` : `Submit`}
          </SubmitButton>
        </div>
      </div>
    </div>
  )
}

export default Lobby
