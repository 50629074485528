import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

export const Graph = ({ index, MoveGraph, graph }) => {
  const ref = useRef(null)

  const [{ isDragging }, drag] = useDrag({
    item: { type: `BOX`, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [, drop] = useDrop({
    accept: `BOX`,
    hover(item) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex == hoverIndex) {
        return
      }

      MoveGraph(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))
  return (
    <div
      ref={ref}
      style={{
        opacity,
      }}
    >
      {graph.LeadsGraph}
    </div>
  )
}
