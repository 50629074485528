import tw from 'tailwind-styled-components'

export const TabUl = tw.ul`
    ${({ customtabs }) => customtabs !== `YES` && `ml-1`}
    flex 
    mb-0 
    list-none 
    flex-wrap 
    pb-1
    mr-2
    flex-row
`

export const TabLi = tw.li`
    ${({ customtabs }) => (customtabs !== `YES` ? `mx-1` : `mr-2`)}
    -mb-px
    last:mr-0 
    flex-auto 
    text-center
    cursor-pointer
`
export const TabItem = tw.div`
    text-xs 
    font-bold 
    uppercase 
    px-2 
    py-2
    shadow 
    rounded 
    block 
    leading-normal 
    hover:text-white
    text-white transition 
    ease-in duration-200 
    ${({ opentab, tnumber, isCustomColor }) =>
        opentab === tnumber
            ? `text-white ${isCustomColor ? `bg-blue-500` : `bg-red-500`}`
            : `${isCustomColor ? `text-blue-500` : `text-red-500`} bg-white`}
    ${({ isCustomColor }) =>
        isCustomColor
            ? `hover:bg-blue-500 
    focus:ring-blue-500 
    focus:ring-offset-blue-200 `
            : `hover:bg-red-500 
    focus:ring-red-500 
    focus:ring-offset-red-200 `}
`
