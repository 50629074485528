import tw from 'tailwind-styled-components'

export const UserImage = tw.img`
${({ latest }) => (latest === `true` ? `ring-red-500` : `ring-gray-300`)}  
  mx-auto 
  object-cover 
  border-1
  rounded 
  cursor-pointer
`
export const DropDown = tw.div`
${({ profile }) => (profile === `yes` ? `right-9` : `right-6`)}  
  origin-bottom-right
  transition all
  absolute 
  rounded-md 
  shadow-lg
  bg-white 
  ring-1 
  ring-black 
  ring-opacity-5
  z-10
  transition-all
  ease-in
  duration-500
`

export const AppMenu = tw.div`
  origin-bottom-right
  absolute 
  rounded-md 
  shadow-lg
  bg-white 
  ring-1 
  ring-black 
  ring-opacity-5
  right-2
  top-7
  z-10
`

export const ProfileMenuItem = tw.div`
  flex 
  flex-row 
  space-x-2 
  items-center
  px-4 
  py-2 
  text-md
  rounded-md
  hover:bg-red-500 
  hover:text-white  
  hover:rounded-md
  text-gray-700 
  cursor-pointer
  gap-1
  text-md
  font-medium
`

export const SecondayProfileMenuItem = tw.div`
  flex 
  flex-row 
  space-x-2 
  items-center
  pl-10 
  py-0.5
  text-md
  rounded-md
  hover:bg-red-500 
  hover:text-white  
  hover:rounded-md
  text-gray-700 
  cursor-pointer
  gap-1
  text-xs
`

export const AppBlock = tw.div`
  flex-1
  rounded-md 
  hover:bg-gray-100 
  text-center 
  py-2.5
  px-3.5
  cursor-pointer
  text-red-500
`
