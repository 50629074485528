import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentCategory: ``,
  activeActivityTab: 1,
}

const ticketCatgorySlice = createSlice({
  name: `ticketCategory`,

  initialState,
  reducers: {
    setCurrentCategory: (state, { payload }) => {
      state.currentCategory = payload
    },
    setCategoryActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
  },
})

export const { setCurrentCategory, setCategoryActivityActiveTab } =
ticketCatgorySlice.actions

export const ticketCategory = ticketCatgorySlice.reducer
