import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import dateFormat from 'App/Services/General/dateFormat'

const tableColumns = [
  { label: `Quote No`, value: `_quoteId` },
  { label: `TurboID`, value: `_turboRaterId` },
  { label: `Customer`, value: `customerName` },
  { label: `Customer Phone`, value: `customerPhone` },
  { label: `Type`, value: `quoteType` },
  { label: `Status`, value: `quoteStatus` },
  { label: `Price`, value: `policyMetadata.priceSold` },
  { label: `DCN`, value: `DCN` },
  { label: `PCM`, value: `PCM` },
  { label: `Created`, value: `createdAt` },
]

export const SearchQuotes = () => {
  const history = useHistory()
  const { searchQuery } = useSelector(({ globalSearch }) => globalSearch)
  const [isOffset, setIsOffset] = useState(``)
  const [currentPage, setCurrentPage] = useState(1)

  const {
    isLoading,
    data: quotesResponse,
    refetch: getMyQuotes,
    isFetching,
    error,
  } = useQuery(
    `MyQuotesApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/quotes/all?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }", "filters":""}&sort=""&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  useEffect(() => {
    getMyQuotes()
  }, [currentPage, isOffset])

  return (
    <Container shadow="yes">
      <div className="relative">
        {error ? (
          <Reload refetch={() => getMyQuotes()} />
        ) : (
          <>
            {(isLoading || isFetching) && (
              <div className="text-center py-10 absolute top-1/3 left-1/2 z-40">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="1x"
                  className="text-red-600"
                />
              </div>
            )}
            <PageHeader bgcolor="bg-white">
              <div className="flex items-center ">
                <div className="py-1 px-2">
                  <ReactSelectField
                    placeholder="10"
                    value={isOffset}
                    setValue={(e) => {
                      setCurrentPage(1)
                      setIsOffset(e)
                    }}
                    isMulti={false}
                    loadOptions={loadOffset}
                  />
                </div>
              </div>
            </PageHeader>
            <div
              className={`mb-1 sm:mb-0 ${
                (isLoading || isFetching) && `opacity-30 pointer-events-none`
              }`}
            >
              <div className="overflow-x-auto overflow-y-hidden  rounded-t-md">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div
                              className={`cursor-pointer select-none text-sm flex truncate`}
                            >
                              <div className={`flex =`}>{item.label}</div>
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {!isLoading && !quotesResponse?.quotes?.length ? (
                      <tr>
                        <td colSpan="12">
                          <div className="w-full text-center p-6  ">
                            <p>No Quotes Found</p>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      quotesResponse?.quotes?.map((quote, index) => (
                        <TableRow
                          key={index}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() =>
                            history.push(`/raters/myquotes/${quote._id}`)
                          }
                        >
                          <td className="px-4 py-0.5 text-sm truncate">
                            {quote._quoteId ? (
                              quote._quoteId
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm truncate">
                            {quote._turboRaterId}
                          </td>
                          <td className="px-4 py-0.5 text-sm whitespace-nowrap">
                            {quote?.policyData?._customer?.firstName ? (
                              quote?.policyData?._customer?.firstName +
                              `  ` +
                              quote?.policyData?._customer?.lastName
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {quote?.policyData?._customer?.contactInfo
                              ?.phone ? (
                              quote?.policyData?._customer?.contactInfo?.phone
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm truncate">
                            {quote?.quoteType ? (
                              <Badge>{quote?.quoteType}</Badge>
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm truncate">
                            <Badge>{quote?.status}</Badge>
                          </td>
                          <td className="px-4 py-0.5 text-sm truncate">
                            {quote?.policyMetadata?.priceSold ? (
                              `$` + quote?.policyMetadata?.priceSold
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm truncate">
                            <Badge type="warning">
                              {quote.metadata?.doNotContact
                                ? `ENABLED`
                                : `DISABLED`}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 text-sm truncate">
                            <Badge>
                              {quote.metadata?.preferredContactMethod
                                ? quote.metadata?.preferredContactMethod?.toUpperCase()
                                : `N/A`}
                            </Badge>
                          </td>

                          <td className="px-4 py-0.5 text-sm truncate">
                            {dateFormat(quote?.createdAt)}
                          </td>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={quotesResponse?.totalPages}
                  totalRecords={quotesResponse?.totalRecords}
                  curerntPageRecords={quotesResponse?.currentPageRecords}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
