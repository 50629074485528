import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Container } from 'App/Styled'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

const newLegendClickHandler = (e, legendItem, legend) => {
  const index = legendItem.datasetIndex
  const ci = legend.chart

  let meta = ci.getDatasetMeta(index)

  // See controller.isDatasetVisible comment
  meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null

  ci.update()
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      onClick: newLegendClickHandler,
    },
  },
}
const colors = [`#20308E `, `#8950FC`, `#3EFA75`, `#2D9241`]
export const LeadsByType = ({ leadsByType }) => {
  const [payment, setPayment] = useState({
    labels: [],
    datasets: [
      {
        payment: [],
        backgroundColor: [],
      },
    ],
    borderWidth: 1,
  })
  useEffect(() => {
    const filteredLeadsByType = leadsByType.filter(item => item.type !== null && item.type !== ``);
    const leadsInfo = {
      labels: [``],
      datasets: filteredLeadsByType?.map((item, index) => {
        return {
          label: item.type,
          data: [item.leads],
          backgroundColor: colors[index],
        }
      }),
    }
    setPayment(leadsInfo)
  }, [leadsByType])

  return (
    <Container>
      <div className="py-9 relative">
        <div className="font-bold absolute left-4 2xl:left-6 top-5">
          Leads by Types
        </div>
      </div>

      <div className="h-280 m-6">
        {payment.labels !== undefined ? (
          <Bar data={payment} options={options} />
        ) : null}
      </div>
    </Container>
  )
}
