import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import {
  Container,
  FormTab,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import {
  lasLastSeenTypes,
  loadAgents,
  loadCustomerTypes,
  loadPaymentFeeTypes,
  loadPaymentLocations,
  loadPaymentMethods,
  loadSalesInTypes,
  loadSalesTypes,
  permissionGranted,
} from 'App/Services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { AMS_BACKEND_API, AxiosInstance, STRIPE_PUBLIC_KEY } from 'App/Config'

import { loadFeePaymentTemplate } from 'App/Services/Payment/loadFeePaymentTemplate'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import {
  AddFormButton,
  RemoveDriver,
} from 'App/Components/Raters/QuoteForm/style'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import { paymentAmountTotal } from 'App/hooks'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { StripeForm } from 'App/Components/Transactions/Inspection/Stripe'
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

const loadInvoiceType = () => {
  const options = [
    { value: `POLICY`, label: `Policy` },
    { value: `DMV`, label: `DMV` },
  ]

  return {
    options,
    hasMore: false,
  }
}

const loadDMVFeeType = () => {
  const options = [
    { value: `DMV`, label: `DMV` },
    { value: `AVRS_FEE_REGISTRATION`, label: `AVRS FEE REGISTRATION` },
    { value: `BF_REGISTRATION`, label: `BF REGISTRATION` },
    { value: `SUSPENSION_DMV`, label: `SUSPENSION DMV` },
    { value: `BF_SUSPENSION`, label: `BF SUSPENSION` },
    { value: `AVRS_FEE_TITLE`, label: `AVRS FEE TITLE` },
    { value: `BF_TITLE_CHANGE`, label: `BF TITLE CHANGE` },
  ]

  return {
    options,
    hasMore: false,
  }
}

const feeTypes = {
  DMV: ``,
  AVRS_FEE_REGISTRATION: `10`,
  BF_REGISTRATION: ``,
  SUSPENSION_DMV: `14`,
  BF_SUSPENSION: `22`,
  AVRS_FEE_TITLE: `12.5`,
  BF_TITLE_CHANGE: ``,
}

export const AddPayment = () => {
  const history = useHistory()
  const { firstName: firstName, lastName: lastName } = useSelector(
    ({ customer }) => customer?.currentCustomer,
  )
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)
  const customerName = {
    label: firstName + ` ${lastName}`,
    value: firstName + lastName,
  }
  const location = useSelector(({ user }) => user.preferredLocation)
  const preferredLocation = { label: location.name, value: location._id }
  const { sub: agentId, full_name: agentName } = useSelector(
    ({ user }) => user.user.profile,
  )
  const selectedAgent = { label: agentName, value: agentId }

  const {
    params: { customerId },
  } = useRouteMatch()

  const [invoice, setInvoice] = useState({
    insured: ``,
    policy: ``,
    invoiceType: { value: `POLICY`, label: `Policy` },
    carrier: ``,
    dueDate: new Date(),
    description: ``,
  })

  const [feeTemplateList, setFeeTemplateList] = useState(``)
  const [policyAmount, setPolicyAmount] = useState(``)

  //YES means payment is must.
  const [addPayment, setAddPayment] = useState({
    value: `YES`,
    label: `YES`,
  })

  const [fees, setFees] = useState([
    {
      feeType: ``,
      feeTypeError: ``,
      amount: ``,
      amountError: ``,
    },
  ])

  const [transactions, setTransactions] = useState({
    transactionDate: new Date(),
    agent: selectedAgent,
    primaryOffice: preferredLocation,
  })

  const [transactionTypes, setTransactionTypes] = useState([
    {
      amount: ``,
      amountError: ``,
      paymentMethod: ``,
      paymentMethodError: ``,
      confirmRefNo: ``,
      confirmRefNoError: ``,
      paymentNote: ``,
    },
  ])

  const [additionals, setAdditionals] = useState({
    customer: ``,
    salesIn: ``,
    salesType: ``,
    lastSeen: ``,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    insuredError: ``,
    policyError: ``,
    dueDateError: ``,
    carrierError: ``,
    agentError: ``,
    primaryOfficeError: ``,
    customerError: ``,
    salesInError: ``,
    salesTypeError: ``,
    lastSeenError: ``,
    amountMatchError: ``,
    refundMatchError: ``,
    addPaymentError: ``,
  })

  const [fTabs, setFTabs] = useState([
    {
      tName: `Fee 1`,
      tNumber: 1,
    },
  ])

  const [pTabs, setPTabs] = useState([
    {
      tName: `Method 1`,
      tNumber: 1,
    },
  ])

  const [activeFeeTab, setActiveFeeTab] = useState(1)
  const [activePaymentMethodeTab, setActivePaymentMethodeTab] = useState(1)
  const [stripeToken, setStripeToken] = useState([])
  const [confirmStripeLoader, setConfirmStripeLoader] = useState(false)

  const isDMVType = invoice.invoiceType?.value.includes(`DMV`)
  const handleInvoice = (value, name, ind) => {
    if (ind !== undefined) {
      const newFees = fees?.map((fee, index) => {
        if (index === ind) {
          fee[name] = value
          if (isDMVType && feeTypes[value.value]) {
            fee.amount = feeTypes[value.value]
          }
        }
        return fee
      })
      setFees(newFees)
    } else {
      setInvoice((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const paymentOptions = async () => {
    return {
      options: [
        { value: `NO`, label: `NO` },
        { value: `YES`, label: `YES` },
      ],
      hasMore: false,
    }
  }

  const { mutate: getPolicy, isLoading: loadingPolicy } = useMutation(
    `PolicyApi`,
    async (payload) =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/policies/minimal/${payload}`,
      ).then((res) => {
        return res.data.data.policy
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setPolicyAmount(res.remainingAmount)

        handleInvoice(
          { value: res?._creator?._id, label: res?._creator?.name },
          `agent`,
        )
        handleInvoice(
          { value: res?._customer?._id, label: res?._customer?.name },
          `insured`,
        )
        handleInvoice(
          { value: res?._carrier?._id, label: res?._carrier?.name },
          `carrier`,
        )
      },
    },
  )

  const { isLoading: loadingStripe, mutate: initiateStripe } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/payments/stripe`,
        payload,
      )
        .then((res) => {
          return res.data.data
        })
        .catch(() => NotificationManager.error(`Something is went wrong!`)),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        const tokenArray = res?.tokens?.map((res, ind) => ({
          clientSecret: res.token,
          _amount: res?.amount,
          isPaymentDone: false,
          transactionIndex: paymentByCreditCard[ind]?.index,
          transactionId: res?.transactionId,
        }))
        setStripeToken(tokenArray)
      },
    },
  )

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const handleFeesErrors = (name, error, ind) => {
    if (ind !== undefined) {
      const newFees = fees?.map((fee, index) => {
        if (index == ind) {
          fee[name] = error
        }
        return fee
      })
      setFees(newFees)
    }
  }
  const handleTransactionTypesErros = (name, error, ind) => {
    if (ind !== undefined) {
      const newTransactionTypes = transactionTypes?.map(
        (transaction, index) => {
          if (index == ind) {
            transaction[name] = error
          }
          return transaction
        },
      )
      setTransactionTypes(newTransactionTypes)
    }
  }

  const addFee = () => {
    setFees([
      ...fees,
      {
        feeType: ``,
        feeTypeError: ``,
        amount: ``,
        amountError: ``,
      },
    ])

    setFTabs([
      ...fTabs,
      {
        tName: `Fee ${fTabs.length + 1}`,
        tNumber: fTabs.length + 1,
      },
    ])
  }

  const removeFee = (ind) => {
    const newFees = [...fees]
    if (newFees.length != 1) {
      newFees.splice(ind, 1)
      setFees(newFees)
    }
    const newFeesTab = [...fTabs]
    if (newFeesTab.length !== 1) {
      newFeesTab.pop()
      setFTabs(newFeesTab)
    }
  }

  //If user selects no in "Add Payment" dropdown, then reset transaction values  and tabs if already saved
  useEffect(() => {
    if (addPayment.value === `NO`) {
      //Reset transactions tabs
      setPTabs([
        {
          tName: `Method 1`,
          tNumber: 1,
        },
      ])

      //Reset transactions values
      setTransactionTypes([
        {
          amount: ``,
          amountError: ``,
          paymentMethod: ``,
          paymentMethodError: ``,
          confirmRefNo: ``,
          confirmRefNoError: ``,
          paymentNote: ``,
        },
      ])
    }
  }, [addPayment])

  const addTransactionType = () => {
    setTransactionTypes([
      ...transactionTypes,
      {
        paymentMethod: ``,
        paymentMethodError: ``,
        amount: ``,
        amountError: ``,
        confirmRefNo: ``,
        confirmRefNoError: ``,
        paymentNote: ``,
      },
    ])

    setPTabs([
      ...pTabs,
      {
        tName: `Method ${pTabs.length + 1}`,
        tNumber: pTabs.length + 1,
      },
    ])
  }

  const removeTransactionType = (ind) => {
    const newPaymentMethodeTab = [...pTabs]
    if (newPaymentMethodeTab.length !== 1) {
      newPaymentMethodeTab.pop()
      setPTabs(newPaymentMethodeTab)
    }
    const newTransactionType = [...transactionTypes]
    if (newTransactionType.length != 1) {
      newTransactionType.splice(ind, 1)
      setTransactionTypes(newTransactionType)
    }
  }

  const handleTransactions = (value, name, ind) => {
    if (ind !== undefined) {
      const newTransactionTypes = transactionTypes?.map(
        (transactionType, index) => {
          if (index === ind) {
            transactionType[name] = value
          }
          return transactionType
        },
      )
      setTransactionTypes(newTransactionTypes)
    } else {
      setTransactions((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleAdditionals = (value, name) => {
    setAdditionals((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const loadPaymentPolicies = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/customers/${customerId}/policies/list/?search=${
        search && search
      }&page=${page}&offset=10`,
    )

    if (data) {
      return {
        options: data.policies,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/invoices/create`, {
        ...payload,
      }).then((res) => {
        if (res.data) {
          history.push(`/customers/${customerId}`)
          NotificationManager.success(`Payment Added Successfully`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const { mutate: mutatePaymentTemplate, isLoading: isLoadingPaymentTemplate } =
    useMutation(
      async (templateId) =>
        await AxiosInstance.get(
          `${AMS_BACKEND_API}/api/payment-templates/${templateId}`,
        ).then((res) => {
          return res?.data?.data?.paymentTemplate
        }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (response) => {
          if (response) {
            const paymentTemplateList = response.templates
            const feesList = paymentTemplateList?.map((_fee) => ({
              feeType: { value: _fee?.type, label: _fee?.type },
              feeTypeError: ``,
              amount: _fee?.amount.toString(),
              amountError: ``,
              disabled: true,
            }))
            setFees(feesList)

            const feeTabs = paymentTemplateList?.map((_fee, index) => ({
              tName: `Fee ${index + 1}`,
              tNumber: index + 1,
            }))

            setFTabs([...feeTabs])
          }
        },
      },
    )

  const updatePayload = () => {
    let data = {
      _insured: customerId,
      issueDate: new Date(),
      additional: {},
    }
    if (
      transactionTypes[0].amount &&
      transactionTypes[0].paymentMethod?.value
    ) {
      data.payments = {}
    }
    if (invoice?.policy?.value) {
      data._policy = invoice.policy.value
    }
    if (invoice?.invoiceType?.value) {
      data.invoiceType = invoice.invoiceType?.value
    }
    if (invoice?.carrier?.value) {
      data._carrier = invoice.carrier.value
    }
    if (invoice?.agent?.value) {
      data._agent = invoice.agent.value
    }
    if (invoice?.dueDate) {
      data.dueDate = invoice.dueDate
    }
    if (invoice?.policy?.description) {
      data.description = invoice.policy.description
    }

    data.items = fees.map((fee) => {
      return {
        feeType: fee?.feeType.value,
        amount: parseFloat(fee?.amount),
      }
    })
    if (feeTemplateList.value) {
      data.paymentTemplateId = feeTemplateList.value
    }
    if (
      transactions.transactionDate &&
      transactionTypes[0].amount &&
      transactionTypes[0].paymentMethod?.value
    ) {
      data.payments.transactionDate = transactions.transactionDate
    }
    if (
      transactions?.agent?.value &&
      transactionTypes[0]?.amount &&
      transactionTypes[0]?.paymentMethod?.value
    ) {
      data.payments._agent = transactions?.agent?.value
    }
    //  else if (
    //   selectedAgent.value &&
    //   transactionTypes[0].amount &&
    //   transactionTypes[0].paymentMethod?.value
    // ) {
    //   data.payments._agent = selectedAgent.value
    // }

    if (
      transactions?.primaryOffice?.value &&
      transactionTypes[0]?.amount &&
      transactionTypes[0]?.paymentMethod?.value
    ) {
      data.payments._location = transactions?.primaryOffice?.value
    }
    //  else if (
    //   preferredLocation.value &&
    //   transactionTypes[0].amount &&
    //   transactionTypes[0].paymentMethod?.value
    // ) {
    //   data.payments._location = preferredLocation.value
    // }

    if (
      transactionTypes[0].amount &&
      transactionTypes[0].paymentMethod?.value
    ) {
      data.payments.paymentItems = transactionTypes.map((transactionType) => {
        const paymentItem = {
          paymentMethod: transactionType?.paymentMethod?.value,
          amount: parseFloat(transactionType?.amount),
          paymentNote: transactionType?.paymentNote,
        }

        if (transactionType?.confirmRefNo) {
          paymentItem.confirmRefNo = transactionType.confirmRefNo
        }
        if (transactionType?.transactionId) {
          paymentItem.transactionId = transactionType.transactionId
        }

        return paymentItem
      })
    }

    if (additionals.customer.value) {
      data.additional.customer = additionals.customer.value
    }

    if (additionals.salesIn.value) {
      data.additional.salesIn = additionals.salesIn.value
    }

    if (additionals.salesType.value) {
      data.additional.salesType = additionals.salesType.value
    }

    if (additionals.lastSeen.value) {
      data.additional.lastSeen = additionals.lastSeen.value
    }

    return data
  }

  const ValidateForm = () => {
    let invoiceAmount = 0
    let transactionAmount = 0

    if (!isDMVType && !invoice.carrier?.value?.length) {
      handleErrors(`carrierError`, `Select Carrier`)
    } else {
      handleErrors(`carrierError`, ``)
    }
    // check if DMV ignore the validation for policy
    if (!isDMVType && !invoice.policy?.value?.length) {
      handleErrors(`policyError`, `Select Policy`)
    } else {
      handleErrors(`policyError`, ``)
    }

    if (!invoice.dueDate) {
      handleErrors(`dueDateError`, `Select Due Date`)
    } else {
      handleErrors(`dueDateError`, ``)
    }

    if (!isDMVType && !invoice.agent?.value?.length) {
      handleErrors(`invoiceAgentError`, `Select Agent`)
    } else {
      handleErrors(`invoiceAgentError`, ``)
    }
    if (!addPayment?.value?.length) {
      handleErrors(`addPaymentError`, `Select Payment Option`)
    } else {
      handleErrors(`addPaymentError`, ``)
    }

    let totalFees = 0

    for (const index in fees) {
      const fee = fees[index]

      invoiceAmount = invoiceAmount + JSON.parse(fee.amount ? fee.amount : 0)

      if (!fee.feeType?.value?.length) {
        handleFeesErrors(`feeTypeError`, `Select Fee Type`, index)
      } else {
        handleFeesErrors(`feeTypeError`, ``, index)
      }

      if (!fee.amount) {
        handleFeesErrors(`amountError`, `Enter Amount`, index)
      } else if (fee.amount && JSON.parse(fee.amount) <= 0) {
        handleFeesErrors(`amountError`, `Amount must be greater than 0`, index)
      } else {
        handleFeesErrors(`amountError`, ``, index)
        totalFees += parseFloat(fee.amount)
      }
      // removed validation for policy amount with respect to amount
      // if (
      //   fee.amount &&
      //   premium?.includes(fee?.feeType?.value) &&
      //   fee?.amount > policyAmount
      // ) {
      //   handleFeesErrors(
      //     `amountError`,
      //     `Payment Amount Must be less than Policy amount`,
      //     index,
      //   )
      // }
    }

    if (!transactions.agent?.value?.length) {
      handleErrors(`agentError`, `Select Agent`)
    } else {
      handleErrors(`agentError`, ``)
    }

    if (
      !transactions.primaryOffice?.value?.length &&
      !preferredLocation.value
    ) {
      handleErrors(`primaryOfficeError`, `Select Office`)
    } else {
      handleErrors(`primaryOfficeError`, ``)
    }

    //Payment Methods validation logic
    let transectionTotal = 0
    for (const index in transactionTypes) {
      const transactionType = transactionTypes[index]
      transactionAmount =
        transactionAmount +
        parseFloat(transactionType?.amount ? transactionType?.amount : 0)
      if (!transactionType.amount && addPayment.value === `YES`) {
        handleTransactionTypesErros(`amountError`, `Enter Amount`, index)
      } else if (
        transactionType.amount &&
        parseFloat(transactionType.amount) <= 0 &&
        addPayment.value === `YES`
      ) {
        handleTransactionTypesErros(
          `amountError`,
          `Amount must be greater than 0`,
          index,
        )
      } else {
        handleTransactionTypesErros(`amountError`, ``, index)
        transectionTotal += parseFloat(transactionType.amount)
      }
      if (
        !transactionType.paymentMethod?.value?.length &&
        addPayment.value === `YES`
      ) {
        handleTransactionTypesErros(`paymentMethodError`, `Select Type`, index)
      } else {
        handleTransactionTypesErros(`paymentMethodError`, ``, index)
      }
      if (
        !transactionType.confirmRefNo?.length &&
        transactionType?.paymentMethod?.value !== `CASH` &&
        !transactionType?.paymentMethod?.value?.includes(`CREDIT`) &&
        addPayment.value === `YES`
      ) {
        handleTransactionTypesErros(
          `confirmRefNoError`,
          `Select Ref No.`,
          index,
        )
      } else {
        handleTransactionTypesErros(`confirmRefNoError`, ``, index)
      }
    }

    const isRefundFeeType = fees.some((item) =>
      item.feeType.value?.includes(`REFUND`),
    )

    if (totalFees < transectionTotal && !isRefundFeeType) {
      NotificationManager.error(`Paid amount must not be greater than fee.`)
      handleErrors(`amountMatchError`, `error`)
    } else {
      handleErrors(`amountMatchError`, ``)
    }

    if (totalFees !== transectionTotal && isRefundFeeType) {
      NotificationManager.error(`Refund amount must be equal to fee.`)
      handleErrors(`refundMatchError`, `Refund amount must be equal to fee.`)
    } else {
      handleErrors(`refundMatchError`, ``)
    }

    if (!additionals.customer?.value?.length) {
      handleErrors(`customerError`, `Select Option`)
    } else {
      handleErrors(`customerError`, ``)
    }

    if (!additionals.salesIn?.value?.length) {
      handleErrors(`salesInError`, `Select Option`)
    } else {
      handleErrors(`salesInError`, ``)
    }

    if (!additionals.salesType?.value?.length) {
      handleErrors(`salesTypeError`, `Select Option`)
    } else {
      handleErrors(`salesTypeError`, ``)
    }

    if (!additionals.lastSeen?.value?.length) {
      handleErrors(`lastSeenError`, `Select Option`)
    } else {
      handleErrors(`lastSeenError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      feeTemplateList.label &&
      feeTemplateList?.label?.toUpperCase() != `NONE`
    ) {
      mutatePaymentTemplate(feeTemplateList?.value)
    } else {
      setFees([
        {
          feeType: ``,
          feeTypeError: ``,
          amount: ``,
          amountError: ``,
        },
      ])

      setFTabs([
        {
          tName: `Fee 1`,
          tNumber: 1,
        },
      ])
    }
  }, [feeTemplateList])

  useEffect(() => {
    let feeFormValidated = []
    let PaymentFormValidated = []
    let isFormValidated = false
    if (
      errors.formSubmit &&
      !errors.policyError?.length &&
      !errors.dueDateError?.length &&
      !errors.carrierError?.length &&
      !errors.invoiceAgentError?.length &&
      !errors.agentError?.length &&
      !errors.primaryOfficeError?.length &&
      !errors.customerError?.length &&
      !errors.salesInError?.length &&
      !errors.salesTypeError?.length &&
      !errors.lastSeenError?.length &&
      !errors.refundMatchError?.length &&
      !errors.amountMatchError?.length &&
      !errors.addPaymentError?.length
    ) {
      isFormValidated = true
    }

    if (isFormValidated) {
      feeFormValidated = fees?.map((fee) => {
        if (!fee.feeTypeError.length && !fee.amountError.length) {
          return true
        }
      })
      PaymentFormValidated = transactionTypes?.map((transaction) => {
        if (
          !transaction.paymentMethodError.length &&
          !transaction.amountError.length &&
          !transaction.confirmRefNoError.length
        ) {
          return true
        }
      })
    }

    if (
      !feeFormValidated.includes(undefined) &&
      !PaymentFormValidated.includes(undefined) &&
      isFormValidated
    ) {
      if (paymentByCreditCard?.length) {
        const stripePayLoad = paymentByCreditCard.map((types) => ({
          amount: parseInt(
            new Intl.NumberFormat()
              .format(parseFloat(types.amount) * 100)
              .replace(`,`, ``),
            10,
          ),
          customerId,
        }))
        initiateStripe(stripePayLoad)
      } else {
        mutate(updatePayload())
        handleErrors(`formSubmit`, false)
      }
    }
  }, [errors])

  useEffect(() => {
    if (invoice.policy) {
      getPolicy(invoice?.policy?.value)
    }
  }, [invoice.policy])

  useEffect(() => {
    setActiveFeeTab(fTabs.length)
  }, [fTabs.length])

  useEffect(() => {
    setActivePaymentMethodeTab(pTabs.length)
  }, [pTabs.length])
  const totalAmountToBePaid = paymentAmountTotal(fees)
  const totalRemainingAmountToBePaid = paymentAmountTotal(transactionTypes)
  const paymentByCreditCard = transactionTypes.filter((types, ind) => {
    if (
      types.paymentMethod?.value?.includes(`CREDIT`) &&
      !types?.confirmRefNo?.length
    ) {
      types.index = ind
      return types
    }
  })

  useEffect(() => {
    if (stripeToken?.length) {
      const isCreditPaymentsDone = stripeToken.every(
        (item) => item?.paymentConfirmId?.length,
      )
      const transactionTypesDone = transactionTypes.every((type) =>
        !type?.paymentMethod?.value?.includes(`CREDIT`)
          ? true
          : type?.paymentMethod?.value?.includes(`CREDIT`) &&
            type?.confirmRefNo?.length,
      )
      if (isCreditPaymentsDone && transactionTypesDone) {
        mutate(updatePayload())
      }
    }
  }, [stripeToken, transactionTypes])
  return (
    <Container>
      <Spinner loading={isLoading || loadingStripe || confirmStripeLoader} />
      <PageHeader>
        <div>Generate Payment</div>
        <div className="col-span-6 justify-self-end">
          {policyAmount?.toString().length > 0 && (
            <div>Remaining: ${policyAmount}</div>
          )}
        </div>
      </PageHeader>

      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <div
        className={`p-3 bg-white ${
          (isLoading || loadingStripe || confirmStripeLoader) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        <PageHeader className="mt-4">
          <div>Invoice</div>
        </PageHeader>
        <div className="px-2">
          <div className={`grid grid-cols-4 gap-6 mt-2`}>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Customer"
                placeholder="Select Customer"
                errorMessage={errors.insuredError}
                value={customerName}
                isMulti={false}
                // loadOptions={loadCustomers}
                disabled={true}
              />
            </div>

            <div className="flex-1 relative">
              <ReactSelectField
                title="Invoice Type*"
                placeholder="Select type"
                errorMessage={errors.invoiceTypeError}
                value={invoice.invoiceType}
                setValue={(value) => handleInvoice(value, `invoiceType`)}
                loadOptions={loadInvoiceType}
                autoFocus
              />
            </div>

            <>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Policy*"
                  placeholder="Select Policy"
                  errorMessage={errors.policyError}
                  value={invoice.policy}
                  setValue={(value) => handleInvoice(value, `policy`)}
                  isMulti={false}
                  loadOptions={loadPaymentPolicies}
                  validating={loadingPolicy}
                  autoFocus
                  isSearchable={false}
                  disabled={isDMVType}
                />
              </div>

              <div className="flex-1 relative">
                <ReactSelectField
                  title="Carrier*"
                  placeholder="Select Carrier"
                  errorMessage={errors.carrierError}
                  value={invoice.carrier}
                  isMulti={false}
                  disabled={true}
                />
              </div>

              <div className="flex-1 relative">
                <ReactSelectField
                  title="Agent*"
                  placeholder="Select Agent"
                  errorMessage={errors.invoiceAgentError}
                  value={invoice.agent}
                  isMulti={false}
                  disabled={true}
                />
              </div>
            </>

            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Description"
                value={invoice.description}
                setValue={(e) => handleInvoice(e.target.value, `description`)}
                placeholder="Enter Value"
              />
            </div>
            <div className="flex-1 relative">
              <DatePickerField
                title="Due Date*"
                placeholder="Select Date"
                value={invoice.dueDate}
                errorMessage={errors.dueDateError}
                setValue={(date) => handleInvoice(date, `dueDate`)}
                minDate={new Date()}
              />
            </div>

            <div className="flex-1 relative">
              <ReactSelectField
                title="Fee Template"
                placeholder="Select Template"
                value={feeTemplateList}
                setValue={(value) => setFeeTemplateList(value)}
                isMulti={false}
                loadOptions={loadFeePaymentTemplate}
                validating={isLoadingPaymentTemplate}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Add Payment*"
                placeholder="Select payment option"
                value={addPayment}
                setValue={(value) => setAddPayment(value)}
                isMulti={false}
                loadOptions={paymentOptions}
                errorMessage={errors.addPaymentError}
              />
            </div>
            <div className="flex-1 relative"></div>
          </div>
          <SecondaryHeading>Fees</SecondaryHeading>
          {/* tabs here */}
          <div className="flex items-center">
            <TabsHeaders
              openTab={activeFeeTab}
              tabs={fTabs}
              getActiveTab={(val) => setActiveFeeTab(val)}
              customTabs="YES"
            />
            <div className="px-2">
              <AddFormButton
                onClick={() => {
                  addFee()
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </AddFormButton>
            </div>
          </div>
          {/* tabs end herer */}
          {fees?.length > 0 &&
            fees?.map((item, index) => (
              <FormTab
                key={index}
                currenttab={index + 1}
                opentab={activeFeeTab}
                className="border-2 border-gray-100 shadow-sm rounded-md relative"
              >
                <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                  <div className="flex-1">Fee {index + 1}</div>
                  <div className="flex-1">
                    {!item?.confirmRefNo && !item?.transactionId && (
                      <div className="float-right">
                        <RemoveDriver
                          onClick={() => {
                            if (!item?.disabled) {
                              removeFee(index)
                              if (index > 0) setActiveFeeTab(index)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} size="1x" />
                        </RemoveDriver>
                      </div>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-12 px-2 pb-4">
                  <div className="col-span-12 flex gap-6 ">
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Memos Type*"
                        placeholder="Select Type"
                        value={item.feeType}
                        errorMessage={item.feeTypeError}
                        setValue={(e) => handleInvoice(e, `feeType`, index)}
                        isMulti={false}
                        loadOptions={
                          invoice?.invoiceType?.value === `DMV`
                            ? loadDMVFeeType
                            : loadPaymentFeeTypes
                        }
                        isSearchable={true}
                        disabled={item?.disabled}
                        additionalFilters={fees}
                        cacheUniqs={fees || invoice?.invoiceType?.value}
                        key={invoice?.invoiceType?.value}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="number"
                        title="Amount*"
                        placeholder="Enter Amount"
                        value={item.amount}
                        errorMessage={item.amountError}
                        setValue={(e) =>
                          handleInvoice(e.target.value, `amount`, index)
                        }
                        disabled={isDMVType && feeTypes[item.feeType.value]}
                      />
                    </div>
                  </div>
                </div>
              </FormTab>
            ))}
          {/* <div class> */}
          <div className="col-span-6 float-right ">
            {totalAmountToBePaid ? (
              <div>Total memos amount: ${totalAmountToBePaid}</div>
            ) : null}
            {/* </div> */}
          </div>
        </div>

        <PageHeader className="mt-4">
          <div>Transactions</div>
        </PageHeader>
        <div className="px-2">
          <div className="flex gap-6 mt-2">
            <div className="flex-1 relative">
              <DatePickerField
                title="Transaction Date"
                placeholder="Select Date"
                value={transactions.transactionDate}
                setValue={(date) => handleTransactions(date, `transactionDate`)}
                disabled={true}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Agent*"
                placeholder="Select Agent"
                errorMessage={errors.agentError}
                value={transactions?.agent}
                setValue={(value) => handleTransactions(value, `agent`)}
                isMulti={false}
                loadOptions={permissionGranted([`admin:*`]) && loadAgents}
                cacheUniqs={transactions?.primaryOffice}
                disabled={!permissionGranted([`admin:*`])}
                additionalFilters={{
                  franchiseId: _franchise?._id,
                  locations: [transactions?.primaryOffice?.value],
                }}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Primary Office*"
                placeholder="Select Office"
                errorMessage={errors.primaryOfficeError}
                value={transactions?.primaryOffice}
                setValue={(value) => {
                  handleTransactions(value, `primaryOffice`)
                  handleTransactions(``, `agent`)
                }}
                isMulti={false}
                loadOptions={
                  permissionGranted([`admin:*`]) && loadPaymentLocations
                }
                isSearchable={true}
                disabled={!permissionGranted([`admin:*`])}
              />
            </div>
          </div>
          <div className="flex-1 relative"></div>
        </div>

        <>
          {/* Let user to add payment methods if invoice type is non empty*/}
          {addPayment.value === `YES` && (
            <div className="px-2">
              <SecondaryHeading>Payment Methods</SecondaryHeading>
              {/* tabs here */}
              <div className="flex items-center">
                <TabsHeaders
                  openTab={activePaymentMethodeTab}
                  tabs={pTabs}
                  getActiveTab={(val) => setActivePaymentMethodeTab(val)}
                  customTabs="YES"
                />
                <div className="px-2">
                  <AddFormButton
                    onClick={() => {
                      addTransactionType()
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </AddFormButton>
                </div>
              </div>
              {/* tabs end herer */}
              {transactionTypes?.length > 0 &&
                transactionTypes?.map((item, index) => (
                  <FormTab
                    key={index}
                    currenttab={index + 1}
                    opentab={activePaymentMethodeTab}
                    className="border-2 border-gray-100 shadow-sm rounded-md relative"
                  >
                    <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                      <div className="flex-1">Payment Method {index + 1}</div>
                      <div className="flex-1">
                        <div className="float-right">
                          <RemoveDriver
                            onClick={() => {
                              if (!item?.disabled) {
                                removeTransactionType(index)
                                if (index > 0) setActivePaymentMethodeTab(index)
                              }
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                          </RemoveDriver>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-12 px-2 pb-4">
                      <div className="col-span-12  flex gap-6 ">
                        <div className="flex-1 relative">
                          <ReactSelectField
                            title="Payment Method *"
                            placeholder="Select Method"
                            value={item?.paymentMethod}
                            setValue={(e) =>
                              handleTransactions(e, `paymentMethod`, index)
                            }
                            errorMessage={item?.paymentMethodError}
                            isMulti={false}
                            loadOptions={loadPaymentMethods}
                            isSearchable={true}
                          />
                        </div>
                        <div className="flex-1 relative">
                          <InputField
                            type="number"
                            title="Paid Amount * "
                            placeholder="Enter Amount"
                            value={item?.amount}
                            errorMessage={
                              item?.amountError || errors?.refundMatchError
                            }
                            setValue={(e) =>
                              handleTransactions(
                                e.target.value,
                                `amount`,
                                index,
                              )
                            }
                          />
                        </div>

                        <div className="flex-1 relative">
                          <InputField
                            type="text"
                            title={`${
                              item?.paymentMethod?.label == `CASH` ||
                              item?.paymentMethod?.label?.includes(`CREDIT`)
                                ? `Confirm Ref No`
                                : `Confirm Ref No *`
                            }`}
                            placeholder="Enter Number"
                            value={item?.confirmRefNo}
                            errorMessage={item?.confirmRefNoError}
                            setValue={(e) =>
                              handleTransactions(
                                e.target.value,
                                `confirmRefNo`,
                                index,
                              )
                            }
                            maxLength={10}
                            disabled={item?.paymentMethod?.label?.includes(
                              `CREDIT`,
                            )}
                          />
                        </div>

                        <div className="flex-1 relative">
                          <InputField
                            type="text"
                            title="Payment Note"
                            placeholder="Enter..."
                            value={item?.paymentNote}
                            setValue={(e) =>
                              handleTransactions(
                                e.target.value,
                                `paymentNote`,
                                index,
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </FormTab>
                ))}
              <div className="col-span-6 float-right ">
                {totalAmountToBePaid ? (
                  <div>
                    Remaining amount: $
                    {(
                      totalAmountToBePaid - totalRemainingAmountToBePaid
                    ).toFixed(2)}
                  </div>
                ) : null}
                {/* </div> */}
              </div>
            </div>
          )}

          {stripeToken?.length > 0 &&
            stripeToken.map((token, index) => (
              <div key={index} className="flex">
                <Elements
                  stripe={stripePromise}
                  options={token}
                  key={token?.clientSecret}
                >
                  <StripeForm
                    openModal={!token.isPaymentDone}
                    setOpenModal={setStripeToken}
                    stripeTokenIndex={index}
                    stripeAmount={token?._amount}
                    transactionId={token?.transactionId}
                    setValue={(e) => {
                      // set confirmRefNo w.r.t its index store when generating tokens
                      if (e) {
                        handleTransactions(
                          e,
                          `confirmRefNo`,
                          token?.transactionIndex,
                        )
                        handleTransactions(
                          token?.transactionId,
                          `transactionId`,
                          token?.transactionIndex,
                        )
                      } else {
                        handleErrors(`formSubmit`, false)
                      }
                    }}
                    setConfirmStripeLoader={setConfirmStripeLoader}
                  />
                </Elements>
              </div>
            ))}

          {/* additionals */}
          <PageHeader className="mt-4">
            <div>Additionals</div>
          </PageHeader>
          <div className="px-2">
            <div className="flex gap-6 mt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Customer*"
                  placeholder="Select Customer"
                  value={additionals.customer}
                  errorMessage={errors.customerError}
                  setValue={(value) => handleAdditionals(value, `customer`)}
                  isMulti={false}
                  loadOptions={loadCustomerTypes}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Sales In*"
                  placeholder="Select Option"
                  value={additionals.salesIn}
                  errorMessage={errors.salesInError}
                  setValue={(value) => handleAdditionals(value, `salesIn`)}
                  isMulti={false}
                  loadOptions={loadSalesInTypes}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Where did customer remember seeing us last*"
                  placeholder="Select Option"
                  value={additionals.lastSeen}
                  errorMessage={errors.lastSeenError}
                  setValue={(value) => handleAdditionals(value, `lastSeen`)}
                  isMulti={false}
                  loadOptions={lasLastSeenTypes}
                />
              </div>
            </div>
            <div className="flex gap-6 mt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Sales Type*"
                  placeholder="Select Type"
                  value={additionals.salesType}
                  errorMessage={errors.salesTypeError}
                  setValue={(value) => handleAdditionals(value, `salesType`)}
                  isMulti={false}
                  loadOptions={loadSalesTypes}
                />
              </div>
              <div className="flex-1 relative"></div>
              <div className="flex-1 relative"></div>
            </div>
          </div>
        </>

        <div className="flex justify-center mt-10">
          <div className="text-center w-96 my-4">
            <SubmitButton onClick={ValidateForm}>
              <div>Generate</div>
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
