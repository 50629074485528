import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { MaskField } from 'App/Components/Common/MaskField'
import { InputField } from 'App/Components/Common/InputField'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import {
  loadGenderOptions,
  loadMaritalStatusOptions,
  loadOccupations,
  loadZipCodes,
  ValidateZip,
} from 'App/Services'
import {
  loadDriverRelationtoApplic,
  loadYears,
  loadDriverTypeCode,
  loadRisksReasonCode,
  loadIndustries,
  loadDLStates,
} from 'App/Services'
import { useDebouncedCallback } from 'use-debounce/lib'

export const AddDriverAsset = () => {
  const history = useHistory()
  const {
    params: { customerId },
  } = useRouteMatch()
  const [driverAsset, setDriverAsset] = useState({
    name: ``,
    prefix: ``,
    suffix: ``,
    gender: ``,
    dateOfBirth: ``,
    maritalStatus: ``,
    email: ``,
    phone: ``,
    ext: ``,
    hireDate: ``,
    terminationDate: ``,
    DLYear: ``,
    DLNumber: ``,
    DLState: ``,
    ssn: ``,
    industry: ``,
    occupations: ``,
    companyDriverNumber: ``,
    countryCode: ``,
    firstLicensedCurrentState: ``,
    licensedDate: ``,
    assignedRiskReasonCode: ``,
    defensiveDriverDate: ``,
    defensiveDriverCode: ``,
    defensiveDriverExpirationDate: ``,
    distantStudentIndicator: ``,
    driverAssignedRiskIndicator: ``,
    driverRelationshipToApplicantCode: ``,
    driverTrainingCompletionDate: ``,
    driverTrainingIndicator: ``,
    driverTypeCode: ``,
    reinstatementdate: ``,
    restrictedIndicator: ``,
    suspensionRevocationDate: ``,
    suspensionRevocationReasonCode: ``,
    isExecuted: false,
    isCreateContact: false,
  })
  const [education, setEducation] = useState({
    educationLevel: ``,
    driverTraining: ``,
    defensiveDriving: ``,
    drugAwareness: ``,
  })
  const [violations, setViolations] = useState([
    {
      driverViolationCode: ``,
      driverViolationCodeError: ``,
      description: ``,
      descriptionError: ``,
      violationDate: ``,
      violationDateError: ``,
    },
  ])
  const [errors, setErrors] = useState({
    formSubmit: false,
    nameError: ``,
    prefixError: ``,
    suffixError: ``,
    genderError: ``,
    dateOfBirthError: ``,
    maritalStatusError: ``,
    educationLevelError: ``,
    driverTrainingError: ``,
    defensiveDrivingError: ``,
    drugAwarenessError: ``,
    emailError: ``,
    phoneError: ``,
    extError: ``,
    hireDateError: ``,
    terminationDateError: ``,
    DLYearError: ``,
    DLNumberError: ``,
    DLStateError: ``,
    ssnError: ``,
    industryError: ``,
    occupationsError: ``,
    companyDriverNumberError: ``,
    countryCodeError: ``,
    firstLicensedCurrentStateError: ``,
    licensedDateError: ``,
    assignedRiskReasonCodeError: ``,
    defensiveDriverDateError: ``,
    defensiveDriverCodeError: ``,
    defensiveDriverExpirationDateError: ``,
    distantStudentIndicatorError: ``,
    driverAssignedRiskIndicatorError: ``,
    driverRelationshipToApplicantCodeError: ``,
    driverTrainingCompletionDateError: ``,
    driverTrainingIndicatorError: ``,
    driverTypeCodeError: ``,
    reinstatementdateError: ``,
    restrictedIndicatorError: ``,
    suspensionRevocationDateError: ``,
    suspensionRevocationReasonCodeError: ``,
    isExecutedError: false,
    isCreateContactError: false,

    //    address
    unitNoError: ``,
    zipError: ``,
    streetNoError: ``,
    cityError: ``,
    stateError: ``,
    coordinatesError: ``,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    zip: ``,
    streetNo: ``,
    city: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const handleAddressChange = useDebouncedCallback((name, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  //handle zip code
  const handleZipChange = async (val) => {
    const data = await ValidateZip(val.value)
    if (data) {
      const res = data.address

      setAddress((prevState) => ({
        ...prevState,
        [`city`]: res.city,
        [`state`]: res.state,
        [`zip`]: { value: res.zipCode, label: res.zipCode },
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        [`lat`]: res.lat,
        [`lng`]: res.long,
      }))
    }
  }
  // handle map
  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  const handleDriverAssetChange = useDebouncedCallback((name, value) => {
    setDriverAsset((driverAsset) => ({ ...driverAsset, [name]: value }))
  }, 5)

  const handleEducationChange = useDebouncedCallback((name, value) => {
    setEducation((education) => ({ ...education, [name]: value }))
  }, 5)

  const handleViolationsFormChange = useDebouncedCallback(
    (value, name, ind) => {
      const newViolations = violations?.map((violation, index) => {
        if (index === ind) {
          violation[name] = value
        }
        return violation
      })
      setViolations(newViolations)
    },
    5,
  )

  //   violation Form Handler
  const addViolationForm = () => {
    setViolations([
      ...violations,
      { driverViolationCode: ``, description: ``, violationDate: `` },
    ])
  }
  const removeViolationForm = (e, index) => {
    const violationsArr = [...violations]
    if (violations.length === 1) {
      return
    }
    violationsArr.splice(index, 1)
    setViolations(violationsArr)
  }
  //  Form Validations
  const ValidForm = () => {
    if (!driverAsset.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }

    if (!driverAsset.prefix.length) {
      handleErrors(`prefixError`, `Enter Prefix`)
    } else {
      handleErrors(`prefixError`, ``)
    }

    if (!driverAsset.suffix.length) {
      handleErrors(`suffixError`, `Enter Suffix`)
    } else {
      handleErrors(`suffixError`, ``)
    }

    if (!driverAsset.gender?.value?.length) {
      handleErrors(`genderError`, `Enter Gender`)
    } else {
      handleErrors(`genderError`, ``)
    }

    if (!driverAsset.dateOfBirth) {
      handleErrors(`dateOfBirthError`, `Select Date`)
    } else {
      handleErrors(`dateOfBirthError`, ``)
    }

    if (!driverAsset.maritalStatus?.value?.length) {
      handleErrors(`maritalStatusError`, `Select Status`)
    } else {
      handleErrors(`maritalStatusError`, ``)
    }

    if (!driverAsset.email.length) {
      handleErrors(`emailError`, `Enter Email`)
    } else if (!regEx.test(driverAsset.email.toLowerCase())) {
      handleErrors(`emailError`, `Enter Valid Email`)
    } else {
      handleErrors(`emailError`, ``)
    }

    if (!driverAsset.phone.length) {
      handleErrors(`phoneError`, `Enter Phone`)
    } else if (driverAsset.phone.length < 12) {
      handleErrors(`phoneError`, `Enter Valid Phone`)
    } else {
      handleErrors(`phoneError`, ``)
    }

    if (!driverAsset.ext.length) {
      handleErrors(`extError`, `Enter Extension`)
    } else if (driverAsset.ext.length > 3) {
      handleErrors(`extError`, `Length Must Be 3 characters`)
    } else {
      handleErrors(`extError`, ``)
    }

    //education
    if (!education.educationLevel?.length) {
      handleErrors(`educationLevelError`, `Enter Education Level`)
    } else {
      handleErrors(`educationLevelError`, ``)
    }

    if (!education.driverTraining?.length) {
      handleErrors(`driverTrainingError`, `Enter Drivers Training`)
    } else {
      handleErrors(`driverTrainingError`, ``)
    }

    if (!education.defensiveDriving?.length) {
      handleErrors(`defensiveDrivingError`, `Enter Defensive Driving`)
    } else {
      handleErrors(`defensiveDrivingError`, ``)
    }

    if (!education.drugAwareness?.length) {
      handleErrors(`drugAwarenessError`, `Enter Drug Awareness`)
    } else {
      handleErrors(`drugAwarenessError`, ``)
    }

    //  General
    if (!driverAsset.hireDate) {
      handleErrors(`hireDateError`, `Select Date`)
    } else {
      handleErrors(`hireDateError`, ``)
    }
    if (!driverAsset.terminationDate) {
      handleErrors(`terminationDateError`, `Select Date`)
    } else {
      handleErrors(`terminationDateError`, ``)
    }

    if (!driverAsset.DLYear?.value) {
      handleErrors(`DLYearError`, `Select Year`)
    } else {
      handleErrors(`DLYearError`, ``)
    }
    if (!driverAsset.DLNumber.length) {
      handleErrors(`DLNumberError`, `Enter Number`)
    } else {
      handleErrors(`DLNumberError`, ``)
    }
    if (!driverAsset.DLState?.value) {
      handleErrors(`DLStateError`, `Select State`)
    } else {
      handleErrors(`DLStateError`, ``)
    }
    if (!driverAsset.ssn.length) {
      handleErrors(`ssnError`, `Enter Social Security No`)
    } else {
      handleErrors(`ssnError`, ``)
    }
    if (!driverAsset.industry.length) {
      handleErrors(`industryError`, `Enter Industry Name`)
    } else {
      handleErrors(`industryError`, ``)
    }
    if (!driverAsset.industry?.value) {
      handleErrors(`industryError`, `Enter Industry Name`)
    } else {
      handleErrors(`industryError`, ``)
    }

    if (!driverAsset.occupations?.length) {
      handleErrors(`occupationsError`, `Select Occupation`)
    } else {
      handleErrors(`occupationsError`, ``)
    }
    if (!driverAsset.companyDriverNumber.length) {
      handleErrors(`companyDriverNumberError`, `Enter Number`)
    } else {
      handleErrors(`companyDriverNumberError`, ``)
    }
    if (!driverAsset.countryCode.length) {
      handleErrors(`countryCodeError`, `Enter Code`)
    } else {
      handleErrors(`countryCodeError`, ``)
    }
    if (!driverAsset.firstLicensedCurrentState.length) {
      handleErrors(`firstLicensedCurrentStateError`, `Enter Current State`)
    } else {
      handleErrors(`firstLicensedCurrentStateError`, ``)
    }
    if (!driverAsset.licensedDate) {
      handleErrors(`licensedDateError`, `Select Date`)
    } else {
      handleErrors(`licensedDateError`, ``)
    }
    if (!driverAsset.assignedRiskReasonCode?.value) {
      handleErrors(`assignedRiskReasonCodeError`, `Select Code`)
    } else {
      handleErrors(`assignedRiskReasonCodeError`, ``)
    }
    if (!driverAsset.defensiveDriverDate) {
      handleErrors(`defensiveDriverDateError`, `Select Code`)
    } else {
      handleErrors(`defensiveDriverDateError`, ``)
    }
    if (!driverAsset.defensiveDriverCode.length) {
      handleErrors(`defensiveDriverCodeError`, `Enter Code`)
    } else {
      handleErrors(`defensiveDriverCodeError`, ``)
    }
    if (!driverAsset.defensiveDriverExpirationDate) {
      handleErrors(`defensiveDriverExpirationDateError`, `Select Date`)
    } else {
      handleErrors(`defensiveDriverExpirationDateError`, ``)
    }
    if (!driverAsset.distantStudentIndicator.length) {
      handleErrors(`distantStudentIndicatorError`, `Enter Value`)
    } else {
      handleErrors(`distantStudentIndicatorError`, ``)
    }
    if (!driverAsset.driverAssignedRiskIndicator.length) {
      handleErrors(`driverAssignedRiskIndicatorError`, `Enter Value`)
    } else {
      handleErrors(`driverAssignedRiskIndicatorError`, ``)
    }
    if (!driverAsset.driverRelationshipToApplicantCode?.value) {
      handleErrors(`driverRelationshipToApplicantCodeError`, `Enter Code`)
    } else {
      handleErrors(`driverRelationshipToApplicantCodeError`, ``)
    }
    if (!driverAsset.driverTrainingCompletionDate) {
      handleErrors(`driverTrainingCompletionDateError`, `Select Date`)
    } else {
      handleErrors(`driverTrainingCompletionDateError`, ``)
    }
    if (!driverAsset.driverTrainingIndicator.length) {
      handleErrors(`driverTrainingIndicatorError`, `Enter Value`)
    } else {
      handleErrors(`driverTrainingIndicatorError`, ``)
    }
    if (!driverAsset.driverTypeCode?.value) {
      handleErrors(`driverTypeCodeError`, `Select Code`)
    } else {
      handleErrors(`driverTypeCodeError`, ``)
    }
    if (!driverAsset.reinstatementdate) {
      handleErrors(`reinstatementdateError`, `Select Date`)
    } else {
      handleErrors(`reinstatementdateError`, ``)
    }
    if (!driverAsset.restrictedIndicator.length) {
      handleErrors(`restrictedIndicatorError`, `Enter Value`)
    } else {
      handleErrors(`restrictedIndicatorError`, ``)
    }
    if (!driverAsset.suspensionRevocationDate) {
      handleErrors(`suspensionRevocationDateError`, `Select Date`)
    } else {
      handleErrors(`suspensionRevocationDateError`, ``)
    }
    if (!driverAsset.suspensionRevocationReasonCode.length) {
      handleErrors(`suspensionRevocationReasonCodeError`, `Enter Code`)
    } else {
      handleErrors(`suspensionRevocationReasonCodeError`, ``)
    }

    //    address error
    if (!address.unitNo.length) {
      handleErrors(`unitNoError`, `Enter Unit No`)
    } else {
      handleErrors(`unitNoError`, ``)
    }

    if (!address.zip?.value?.length) {
      handleErrors(`zipError`, `Select Zip`)
    } else {
      handleErrors(`zipError`, ``)
    }
    if (!address.streetNo.length) {
      handleErrors(`streetNoError`, `Enter Street `)
    } else {
      handleErrors(`streetNoError`, ``)
    }
    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }
    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }
    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, `Select Coordinates`)
    } else {
      handleErrors(`coordinatesError`, ``)
    }

    for (const index in violations) {
      const violation = violations[index]

      if (!violation.driverViolationCode.length) {
        handleViolationsErrors(index, `driverViolationCodeError`, `Enter Code`)
      } else {
        handleViolationsErrors(index, `driverViolationCodeError`, ``)
      }

      if (!violation.description.length) {
        handleViolationsErrors(index, `descriptionError`, `Enter Description`)
      } else {
        handleViolationsErrors(index, `descriptionError`, ``)
      }

      if (!violation.violationDate) {
        handleViolationsErrors(index, `violationDateError`, `Select Date`)
      } else {
        handleViolationsErrors(index, `violationDateError`, ``)
      }
    }
    handleErrors(`formSubmit`, true)
  }
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const handleViolationsErrors = (ind, name, val) => {
    const newViolations = violations?.map((violation, index) => {
      if (index == ind) {
        violation[name] = val
      }
      return violation
    })
    setViolations(newViolations)
  }

  useEffect(() => {
    let isFormvalidated = false
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.prefixError.length &&
      !errors.suffixError.length &&
      !errors.genderError.length &&
      !errors.dateOfBirthError.length &&
      !errors.maritalStatusError.length &&
      !errors.educationLevelError.length &&
      !errors.driverTrainingError.length &&
      !errors.defensiveDrivingError.length &&
      !errors.drugAwarenessError.length &&
      !errors.emailError.length &&
      !errors.phoneError.length &&
      !errors.extError.length &&
      !errors.hireDateError.length &&
      !errors.terminationDateError.length &&
      !errors.DLYearError.length &&
      !errors.DLNumberError.length &&
      !errors.DLStateError.length &&
      !errors.ssnError.length &&
      !errors.industryError.length &&
      !errors.occupationsError.length &&
      !errors.companyDriverNumberError.length &&
      !errors.countryCodeError.length &&
      !errors.firstLicensedCurrentStateError.length &&
      !errors.licensedDateError.length &&
      !errors.assignedRiskReasonCodeError.length &&
      !errors.defensiveDriverDateError.length &&
      !errors.defensiveDriverCodeError.length &&
      !errors.defensiveDriverExpirationDateError.length &&
      !errors.distantStudentIndicatorError.length &&
      !errors.driverAssignedRiskIndicatorError.length &&
      !errors.driverRelationshipToApplicantCodeError.length &&
      !errors.driverTrainingCompletionDateError.length &&
      !errors.driverTrainingIndicatorError.length &&
      !errors.driverTypeCodeError.length &&
      !errors.reinstatementdateError.length &&
      !errors.suspensionRevocationDateError.length &&
      !errors.suspensionRevocationReasonCodeError.length &&
      !errors.isExecutedError.length &&
      !errors.isCreateContactError.length &&
      !errors.unitNoError.length &&
      !errors.zipError.length &&
      !errors.streetNoError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.coordinatesError.length
    ) {
      violations.forEach((_eachViolation) => {
        if (
          !_eachViolation.driverViolationCodeError.length &&
          !_eachViolation.descriptionError.length &&
          !_eachViolation.violationDateError
        ) {
          isFormvalidated = true
        } else {
          isFormvalidated = false
        }
      })
    }
    const driverEducation = {
      educationLevel: education.educationLevel,
      driverTraining: education.driverTraining,
      defensiveDriving: education.defensiveDriving,
      drugAwareness: education.drugAwareness,
    }
    const driverAddress = {
      unitNo: address.unitNo,
      zip: address.zip.value,
      street: address.streetNo,
      city: address.city,
      state: address.state,
      coordinates: {
        lat: coordinates.lat.toString(),
        long: coordinates.lng.toString(),
      },
    }

    if (isFormvalidated) {
      const violationsPayload = violations.map((_eachViolation) => {
        return {
          driverViolationCode: _eachViolation.driverViolationCode,
          description: _eachViolation.description,
          violationDate: _eachViolation.violationDate,
        }
      })

      addDriver({
        name: driverAsset.name,
        prefix: driverAsset.prefix,
        sufix: driverAsset.suffix,
        gender: driverAsset.gender.value,
        dateOfBirth: driverAsset.dateOfBirth,
        maritalStatus: driverAsset.maritalStatus.value,
        education: driverEducation,
        email: driverAsset.email,
        phone: driverAsset.phone,
        ext: driverAsset.ext,
        hireDate: driverAsset.hireDate,
        terminationDate: driverAsset.terminationDate,
        DLYear: driverAsset.DLYear.value,
        DLNumber: driverAsset.DLNumber,
        DLState: driverAsset.DLState.value,
        ssn: driverAsset.ssn,
        address: driverAddress,
        industry: driverAsset.industry.value,
        occupations: driverAsset.occupations.map(
          (_occupation) => _occupation.value,
        ),
        companyDriverNumber: driverAsset.companyDriverNumber,
        countryCode: driverAsset.countryCode,
        firstLicensedCurrentState: driverAsset.firstLicensedCurrentState,
        licensedDate: driverAsset.licensedDate,
        assignedRiskReasonCode: driverAsset.assignedRiskReasonCode.value,
        defensiveDriverDate: driverAsset.defensiveDriverDate,
        defensiveDriverCode: driverAsset.defensiveDriverCode,
        defensiveDriverExpirationDate:
          driverAsset.defensiveDriverExpirationDate,
        distantStudentIndicator: driverAsset.distantStudentIndicator,
        driverAssignedRiskIndicator: driverAsset.driverAssignedRiskIndicator,
        driverRelationshipToApplicantCode:
          driverAsset.driverRelationshipToApplicantCode.value,
        driverTrainingCompletionDate: driverAsset.driverTrainingCompletionDate,
        driverTrainingIndicator: driverAsset.driverTrainingIndicator,
        driverTypeCode: driverAsset.driverTypeCode.value,
        reinstatementdate: driverAsset.reinstatementdate,
        restrictedIndicator: driverAsset.restrictedIndicator,
        suspensionRevocationDate: driverAsset.suspensionRevocationDate,
        suspensionRevocationReasonCode:
          driverAsset.suspensionRevocationReasonCode,
        isExecuted: driverAsset.isExecuted,
        isCreateContact: driverAsset.isCreateContact,
        violations: violationsPayload,
      })
      handleErrors(`formSubmit`, false)
    }
  }, [errors])
  //driver add mutation
  const {
    mutate: addDriver,
    isLoading: addingDriver,
    isError: addDriverError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/assets/${customerId}/type/drivers`,
        {
          driver: { ...payload },
        },
      ).then((res) => {
        !res.hasError && history.push(`/customers/${customerId}`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  return (
    <Container>
      {addDriverError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <PageHeader>
        <div>Driver Assets</div>
      </PageHeader>
      <Spinner loading={addingDriver} />

      <div
        className={`p-4 ${addingDriver && ` opacity-30 pointer-events-none`} `}
      >
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="name"
              title="Name"
              placeholder="Enter Name"
              value={driverAsset.name}
              errorMessage={errors.nameError}
              setValue={(e) => handleDriverAssetChange(`name`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="prefix"
              title="Prefix"
              placeholder="Enter Name Prefix"
              value={driverAsset.prefix}
              errorMessage={errors.prefixError}
              setValue={(e) =>
                handleDriverAssetChange(`prefix`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="suffix"
              title="Suffix"
              placeholder="Enter Name Suffix"
              value={driverAsset.suffix}
              errorMessage={errors.suffixError}
              setValue={(e) =>
                handleDriverAssetChange(`suffix`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Gender"
              placeholder="Select Gender"
              value={driverAsset.gender}
              errorMessage={errors.genderError}
              setValue={(value) =>
                setDriverAsset((prevState) => ({
                  ...prevState,
                  [`gender`]: value,
                }))
              }
              isMulti={false}
              loadOptions={loadGenderOptions}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <DatePickerField
              name="dateOfBirth"
              title="DOB"
              placeholder="Enter Date Of Birth"
              value={driverAsset.dateOfBirth}
              errorMessage={errors.dateOfBirthError}
              setValue={(date) => {
                setDriverAsset((preState) => ({
                  ...preState,
                  [`dateOfBirth`]: date,
                }))
              }}
            />
          </div>
        </div>
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Marital Status"
              placeholder="Select  Status"
              value={driverAsset.maritalStatus}
              errorMessage={errors.maritalStatusError}
              setValue={(value) =>
                setDriverAsset((prevState) => ({
                  ...prevState,
                  [`maritalStatus`]: value,
                }))
              }
              isMulti={false}
              loadOptions={loadMaritalStatusOptions}
              isSearchable={false}
            />
          </div>

          <div className="flex-1 relative">
            <InputField
              type="text"
              name="email"
              title="Email"
              placeholder="Enter Email"
              value={driverAsset.email}
              errorMessage={errors.emailError}
              setValue={(e) => handleDriverAssetChange(`email`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Phone"
              name="phone"
              placeholder="Enter Phone No"
              value={driverAsset.phone}
              errorMessage={errors.phoneError}
              setValue={(e) => handleDriverAssetChange(`phone`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="ext"
              title="Extension"
              placeholder="Enter Extension"
              value={driverAsset.ext}
              errorMessage={errors.extError}
              setValue={(e) => handleDriverAssetChange(`ext`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative"></div>
        </div>

        <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Education
        </div>

        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="education"
              title="Education"
              placeholder="Enter Education"
              value={education.educationLevel}
              errorMessage={errors.educationLevelError}
              setValue={(e) =>
                handleEducationChange(`educationLevel`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Driver Training"
              placeholder="Enter Value"
              value={education.driverTraining}
              errorMessage={errors.driverTrainingError}
              setValue={(e) =>
                handleEducationChange(`driverTraining`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Defensive Driving"
              placeholder="Enter Value"
              value={education.defensiveDriving}
              errorMessage={errors.defensiveDrivingError}
              setValue={(e) =>
                handleEducationChange(`defensiveDriving`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Drug Awareness"
              placeholder="Enter Value"
              value={education.drugAwareness}
              errorMessage={errors.drugAwarenessError}
              setValue={(e) =>
                handleEducationChange(`drugAwareness`, e.target.value)
              }
            />
          </div>
        </div>

        <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Address
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="unitNo"
              title="Unit No"
              placeholder="Enter Unit No"
              value={address.unitNo}
              errorMessage={errors.unitNoError}
              setValue={(e) => handleAddressChange(`unitNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip"
              name="zip"
              placeholder="Select Zip"
              isMulti={false}
              value={address.zip}
              errorMessage={errors.zipError}
              setValue={(e) => handleZipChange(e)}
              loadOptions={loadZipCodes}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street"
              placeholder="Enter Street"
              value={address.streetNo}
              errorMessage={errors.streetNoError}
              setValue={(e) => handleAddressChange(`streetNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="city"
              title="City"
              placeholder="Enter City"
              value={address.city}
              errorMessage={errors.cityError}
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="state"
              title="State"
              placeholder="Enter State"
              value={address.state}
              errorMessage={errors.stateError}
              id="lAddress"
              disabled={true}
            />
          </div>
        </div>
        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError.length !== 0 && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView center={[coordinates.lat, coordinates.lng]} zoom={3} />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div>

        {/* Start General */}
        <div className="mt-4">
          <Collapsable title="General">
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <DatePickerField
                  name="hireDate"
                  title="Hire Date"
                  placeholder="Select Hire Date"
                  value={driverAsset.hireDate}
                  errorMessage={errors.hireDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`hireDate`]: date,
                    }))
                  }}
                />
              </div>

              <div className="flex-1 relative">
                <DatePickerField
                  name="terminationDate"
                  title="Termination Date"
                  placeholder="Select Termination Date"
                  value={driverAsset.terminationDate}
                  errorMessage={errors.terminationDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`terminationDate`]: date,
                    }))
                  }}
                />
              </div>

              <div className="flex-1 relative">
                <ReactSelectField
                  title="DL Year"
                  placeholder="Select Year"
                  value={driverAsset.DLYear}
                  errorMessage={errors.DLYearError}
                  setValue={(value) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`DLYear`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadYears}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="DLNumber"
                  title="DL Number"
                  placeholder="Enter DLNumber"
                  value={driverAsset.DLNumber}
                  errorMessage={errors.DLNumberError}
                  setValue={(e) =>
                    handleDriverAssetChange(`DLNumber`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="DL State"
                  placeholder="Select DLState"
                  value={driverAsset.DLState}
                  errorMessage={errors.DLStateError}
                  setValue={(value) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`DLState`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadDLStates}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="ssn"
                  title="Social Security No"
                  placeholder="Enter Social Security No"
                  value={driverAsset.ssn}
                  errorMessage={errors.ssnError}
                  setValue={(e) =>
                    handleDriverAssetChange(`ssn`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Industry"
                  placeholder="Select "
                  value={driverAsset.industry}
                  errorMessage={errors.industryError}
                  setValue={(industry) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`industry`]: industry,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadIndustries}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Occupation"
                  placeholder="Select Occupat"
                  value={driverAsset.occupations}
                  errorMessage={errors.occupationsError}
                  setValue={(occupation) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`occupations`]: occupation,
                    }))
                  }
                  isMulti={true}
                  loadOptions={loadOccupations}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="companyDriverNumber"
                  title="Company Driver Number"
                  placeholder="Enter Company Driver Number"
                  value={driverAsset.companyDriverNumber}
                  errorMessage={errors.companyDriverNumberError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `companyDriverNumber`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="countryCode"
                  title="Country Code"
                  placeholder="Enter Country Code"
                  value={driverAsset.countryCode}
                  errorMessage={errors.countryCodeError}
                  setValue={(e) =>
                    handleDriverAssetChange(`countryCode`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="firstLicensedCurrentState"
                  title="First Licensed  State"
                  placeholder="Enter First Licensed Current State"
                  value={driverAsset.firstLicensedCurrentState}
                  errorMessage={errors.firstLicensedCurrentStateError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `firstLicensedCurrentState`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <DatePickerField
                  title="Licensed Date"
                  name="licensedDate"
                  placeholder="Select License Year"
                  value={driverAsset.licensedDate}
                  errorMessage={errors.licensedDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`licensedDate`]: date,
                    }))
                  }}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Assigned Risk ReasonCode"
                  placeholder="Select Reason"
                  value={driverAsset.assignedRiskReasonCode}
                  errorMessage={errors.assignedRiskReasonCodeError}
                  setValue={(value) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`assignedRiskReasonCode`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadRisksReasonCode}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <DatePickerField
                  title="Defensive Driver Date"
                  name="defensiveDriverDate"
                  placeholder="Select Date"
                  value={driverAsset.defensiveDriverDate}
                  errorMessage={errors.defensiveDriverDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`defensiveDriverDate`]: date,
                    }))
                  }}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="defensiveDriverCode"
                  title="Defensive Driver Code"
                  placeholder="Enter Defensive Driver Code"
                  value={driverAsset.defensiveDriverCode}
                  errorMessage={errors.defensiveDriverCodeError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `defensiveDriverCode`,
                      e.target.value,
                    )
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <DatePickerField
                  title="Defensive Driver Exp Date"
                  name="defensiveDriverExpirationDate"
                  placeholder="Select Date"
                  value={driverAsset.defensiveDriverExpirationDate}
                  errorMessage={errors.defensiveDriverExpirationDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`defensiveDriverExpirationDate`]: date,
                    }))
                  }}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="distantStudentIndicator"
                  title="Distant Student Indicator"
                  placeholder="Enter Distant Student Indicator"
                  value={driverAsset.distantStudentIndicator}
                  errorMessage={errors.distantStudentIndicatorError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `distantStudentIndicator`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="driverAssignedRiskIndicator"
                  title="Driver Assigned Risk Indicator"
                  placeholder="Enter Driver Assigned Risk Indicator"
                  value={driverAsset.driverAssignedRiskIndicator}
                  errorMessage={errors.driverAssignedRiskIndicatorError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `driverAssignedRiskIndicator`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Driver Relation To Applicant Code"
                  placeholder="Select Relation"
                  value={driverAsset.driverRelationshipToApplicantCode}
                  errorMessage={errors.driverRelationshipToApplicantCodeError}
                  setValue={(value) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`driverRelationshipToApplicantCode`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadDriverRelationtoApplic}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <DatePickerField
                  title="Driver Training Completion Date"
                  name="driverTrainingCompletionDate"
                  placeholder="Select Completion Date"
                  value={driverAsset.driverTrainingCompletionDate}
                  errorMessage={errors.driverTrainingCompletionDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`driverTrainingCompletionDate`]: date,
                    }))
                  }}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="driverTrainingIndicator"
                  title="Driver Training Indicator"
                  placeholder="Enter Driver Training Indicator"
                  value={driverAsset.driverTrainingIndicator}
                  errorMessage={errors.driverTrainingIndicatorError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `driverTrainingIndicator`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Driver Type Code"
                  placeholder="Select  Code"
                  value={driverAsset.driverTypeCode}
                  errorMessage={errors.driverTypeCodeError}
                  setValue={(value) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`driverTypeCode`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadDriverTypeCode}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <DatePickerField
                  title="Reinstatement Date"
                  name="reinstatementdate"
                  placeholder="Select Date"
                  value={driverAsset.reinstatementdate}
                  errorMessage={errors.reinstatementdateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`reinstatementdate`]: date,
                    }))
                  }}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="restrictedIndicator"
                  title="Restricted Indicator"
                  placeholder="Enter Restricted Indicator"
                  value={driverAsset.restrictedIndicator}
                  errorMessage={errors.restrictedIndicatorError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `restrictedIndicator`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <DatePickerField
                  title="Suspension Revocation Date"
                  name="suspensionRevocationDate"
                  placeholder="Select Date"
                  value={driverAsset.suspensionRevocationDate}
                  errorMessage={errors.suspensionRevocationDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`suspensionRevocationDate`]: date,
                    }))
                  }}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="suspensionRevocationReasonCode"
                  title="Suspension Revocation Reason"
                  placeholder="Value"
                  value={driverAsset.suspensionRevocationReasonCode}
                  errorMessage={errors.suspensionRevocationReasonCodeError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `suspensionRevocationReasonCode`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative ">
                <Checkbox
                  title="isExecuted"
                  name="isExecuted"
                  value={driverAsset.isExecuted}
                  errorMessage={errors.isExecutedError}
                  setValue={() =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`isExecuted`]: !driverAsset.isExecuted,
                    }))
                  }
                />
              </div>
              <div className="flex-1 relative ">
                <Checkbox
                  title="isCreateContact"
                  name="isCreateContact"
                  value={driverAsset.isCreateContact}
                  errorMessage={errors.isCreateContactError}
                  setValue={() =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`isCreateContact`]: !driverAsset.isCreateContact,
                    }))
                  }
                />
              </div>
              <div className="flex-1 relative "></div>
              <div className="flex-1 relative "></div>
            </div>
          </Collapsable>
        </div>
        {/* End General */}

        {/* Start Violations */}
        <div className="my-4">
          <Collapsable title="Violations">
            {violations?.map((violations, index) => (
              <div key={index} className="grid grid-cols-12">
                <div className="col-span-10 flex gap-6 ">
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Driver Violation Code"
                      placeholder="Enter Driver Violation Code"
                      value={violations.driverViolationCode}
                      errorMessage={violations.driverViolationCodeError}
                      setValue={(e) =>
                        handleViolationsFormChange(
                          e.target.value,
                          `driverViolationCode`,
                          index,
                        )
                      }
                    />
                  </div>
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Description"
                      placeholder="Enter Description"
                      value={violations.description}
                      errorMessage={violations.descriptionError}
                      setValue={(e) =>
                        handleViolationsFormChange(
                          e.target.value,
                          `description`,
                          index,
                        )
                      }
                    />
                  </div>
                  <div className="flex-1 relative">
                    <DatePickerField
                      title="Violation Date"
                      placeholder="Enter Violation Date"
                      value={violations.violationDate}
                      errorMessage={violations.violationDateError}
                      setValue={(e) =>
                        handleViolationsFormChange(e, `violationDate`, index)
                      }
                    />
                  </div>
                </div>
                <div className="col-span-2 mt-7">
                  <div className="flex items-center justify-center ">
                    <span
                      onClick={() => addViolationForm()}
                      data-tip="Add Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500  cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="transform transition  hover:-translate-y-0  hover:scale-105"
                      />
                    </span>
                    <span
                      onClick={() => removeViolationForm(index)}
                      data-tip="Remove Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500  cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="transform transition  hover:-translate-y-0  hover:scale-105"
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Collapsable>
        </div>
        {/*end Violations */}
      </div>
      <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
        <SubmitButton onClick={ValidForm}>Create Driver Asset</SubmitButton>
      </div>
    </Container>
  )
}
