import React from 'react'
import { Text } from '@react-pdf/renderer'
import moment from 'moment'
import { StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  text: {
    fontSize: `12px`,
    color: `black`,
    borderRadius: `2px`,
    padding: 2,
    width: `90%`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    marginHorizontal: 10,
  },
})

export const PolicyReportPdfHeader = ({
  title = `Insurance Agency - Veronica's Insurance`,
  dateRange = [],
  showTransactionDate = true,
}) => {
  return (
    <>
      <Text style={styles.text}>
        {`Execution Date - ${moment().format(`DD/MM/YYYY`)}`}
      </Text>
      <Text style={styles.text}>{title}</Text>
      {showTransactionDate ? (
        <Text style={styles.text}>{`Transaction Date Between - ${
          dateRange[0]?.startDate
            ? moment(dateRange[0]?.startDate).format(`DD/MM/YYYY`)
            : ``
        } ${dateRange[0]?.startDate ? `-` : ``} ${
          dateRange[0]?.endDate
            ? moment(dateRange[0]?.endDate).format(`DD/MM/YYYY`)
            : `N/A`
        }`}</Text>
      ) : (
        <></>
      )}
    </>
  )
}
