export const loadPreferredContact = async () => {
  return {
    options: [
      // { value: `CHAT`, label: `Chat` },
      { value: `EMAIL`, label: `Email` },
      { value: `SMS`, label: `SMS` },
      { value: `CALL`, label: `Call` },
      { value: `IN_PERSON`, label: `In Person` },
    ],
    hasMore: false,
  }
}

export const loadLeadPreferredContact = async () => {
  return {
    options: [
      { value: `EMAIL`, label: `Email` },
      { value: `SMS`, label: `SMS` },
      { value: `CALL`, label: `Call` },
      { value: `IN_PERSON`, label: `In Person` },
    ],
    hasMore: false,
  }
}

export const loadLeadSource = async () => {
  return {
    options: [
      { value: `ONLINE_AD`, label: `Online Ad` },
      { value: `NEWSPAPER`, label: `Newspaper` },
      { value: `SOCIAL_MEDIA`, label: `Other` },
      { value: `RADIO`, label: `Radio` },
      { value: `REFERRAL`, label: `Referral` },
      { value: `RE_WRITE`, label: `Re Write` },
      { value: `TV`, label: `TV` },
      { value: `WALK_IN`, label: `Walk-in` },
      { value: `YELLOW_PAGES`, label: `Yellow Pages` },
      { value: `EMAIL`, label: `Email` },
      { value: `SMS`, label: `SMS` },
    ],
    hasMore: false,
  }
}

export const loadQuoteDescription = async () => {
  return {
    options: [
      { value: `ENDORSEMENT`, label: `Endorsement` },
      { value: `NEW_BUSINESS`, label: `New Business` },
      { value: `OTHER`, label: `Other` },
      { value: `RENEWAL`, label: `Renewal` },
      { value: `RE_WRITE`, label: `Re-Write` },
    ],
    hasMore: false,
  }
}

export const loadLanguages = async () => {
  return {
    options: [
      { value: `ENGLISH`, label: ` English` },
      { value: `SPANISH`, label: ` Spanish` },
      { value: `GERMAN`, label: `German` },
      { value: `KOREAN`, label: `Korean` },
      { value: `POLISH`, label: `Polish` },
      { value: `FRENCH`, label: `French` },
    ],
    hasMore: false,
  }
}

export const loadStatuses = async () => {
  return {
    options: [
      { value: `LOST`, label: `Lost` },
      { value: `NOT ANSWERED`, label: `Not Answered` },
      { value: `IN PROGRESS`, label: `In Progress` },
      { value: `MANUAL`, label: `Manual` },
      { value: `PENDING`, label: `Pending` },
    ],
    hasMore: false,
  }
}
