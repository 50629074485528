import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { DateInput } from 'App/Styled'

export const DateRangeInputField = ({
  type,
  value,
  name,
  title,
  id,
  disabled,
  placeholder,
  errorMessage,
  // setValue,
  setKeyDownValue = () => {},
  tipdata,
  validating,
  ref,
  minDate,
  readOnly = false,
}) => {
  return (
    <>
      <label
        data-tip={tipdata}
        className={` ${tipdata && `tooltip`} text-gray-700 font-medium text-sm`}
      >
        {title}
        {tipdata && <span className="tooltip"></span>}

        {errorMessage && <span className="text-red-500 required-dot"></span>}
      </label>
      <DateInput
        type={type}
        name={name}
        id={id}
        ref={ref}
        disabled={disabled || validating}
        placeholder={placeholder}
        value={value}
        // onChange={(e) => setValue(e)}
        onKeyDown={(e) => setKeyDownValue(e)}
        minDate={minDate}
        readOnly={readOnly}
      />

      {validating ? (
        <FontAwesomeIcon
          icon={faSpinner}
          className="absolute text-red-500 right-2 mt-3"
          spin={true}
        />
      ) : (
        errorMessage && (
          <>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="absolute text-red-500 right-2 mt-3"
            />
            <p className="text-sm text-red-500">{errorMessage}</p>
          </>
        )
      )}
    </>
  )
}
