import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  searchedData: [],
  isLoading: true,
  searchQuery: ``,
  globalCustomerFilter: false,
}

const searchSlice = createSlice({
  name: `globalSearch`,

  initialState,
  reducers: {
    setGlobalSearchData: (state, { payload }) => {
      state.searchedData = payload
    },
    setIsDataLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setQuerySearch: (state, { payload }) => {
      state.searchQuery = payload
    },
    setGlobalCustomerFilter: (state, { payload }) => {
      state.globalCustomerFilter = payload
    },
  },
})

export const {
  setGlobalSearchData,
  setIsDataLoading,
  setQuerySearch,
  setGlobalCustomerFilter,
} = searchSlice.actions

export const globalSearch = searchSlice.reducer
