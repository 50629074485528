import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentLocation: ``,
  activeActivityTab: 1,
}

const locationSlice = createSlice({
  name: `location`,

  initialState,
  reducers: {
    setCurrentLocation: (state, { payload }) => {
      state.currentLocation = payload
    },
    setLocationActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
  },
})

export const { setCurrentLocation, setLocationActivityActiveTab } =
  locationSlice.actions

export const location = locationSlice.reducer
