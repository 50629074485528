/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { InputField } from 'App/Components/Common/InputField'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { useMutation } from 'react-query'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import {
  loadLobs,
  // loadLocZipCodes,
  ValidateDuplicateLocEmail,
  ValidateDuplicateLocation,
  ValidateDuplicateFranchise,
  ValidateZip,
  LoadComissionTypes,
  fileResizer,
  // loadAreaCodes,
  loadZipCodes,
  loadStates,
  isSuperUser,
} from 'App/Services'
import { MaskField } from 'App/Components/Common/MaskField'
import { useHistory } from 'react-router'
import { defaultImage } from 'App/Assets'
import {
  Image,
  Label,
  Container,
  PageHeader,
  SubmitButton,
  AddTabButton,
  FormTab,
  SecondaryHeading,
  RemoveForm,
} from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useDebouncedCallback } from 'use-debounce/lib'
import { RadioButton } from 'App/Components/Common/RadionButton'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import { AddManager } from './AddFranchiseAgent'
import { loadGroupCredentials } from 'App/Services/Credentials'

export const AddFranchise = () => {
  const user = useSelector(({ user: { user } }) => user)
  const operatingState = {
    value: user?.profile?.operatingState,
    label: user?.profile?.operatingState,
  }
  const addManagerRef = useRef(null)
  const agentFormData = useRef(null)
  const [fName, setFName] = useState(``)
  const [fRegions, setFRegions] = useState(``)
  const [fState, setFState] = useState(operatingState ?? ``)
  const [fLogo, setFLogo] = useState(``)
  const [agentValidating, setAgentValidating] = useState(false)

  const [isCorporate, setIsCorporate] = useState({ value: false, label: `NO` })
  const [firstZip, setFirstZip] = useState(``)
  const [commission, setCommission] = useState({
    type: ``,
    rate: ``,
  })
  const [queryError, setQueryError] = useState(false)
  const [errors, setErrors] = useState({
    formSubmit: false,
    fNameError: ``,
    fRegionsError: ``,
    fStateError: ``,
    franchisePhoneError: ``,
  })
  const [lTabs, setLTabs] = useState([
    {
      tName: `Location 1`,
      tNumber: 1,
      permission: [`corporate-manager:*`],
    },
  ])
  const [activeLocationTab, setActiveLocationTab] = useState(1)
  const [fLocations, setFLocations] = useState([
    {
      lName: ``,
      lNameError: ``,
      lBusinessName: ``,
      lBusinessNameError: ``,
      stateForPhone: ``,
      credentialGroup: ``,
      credentialGroupError: ``,
      lPhone: ``,
      lPhoneError: ``,
      lExt: ``,
      lWorkPhone: ``,
      lWorkPhoneError: ``,
      lExtError: ``,
      lEmail: ``,
      lEmailError: ``,
      lFax: ``,
      lFaxError: ``,
      lLobs: ``,
      lLobsError: ``,
      IsMaster: true,
      lAddress: {
        unitNo: ``,
        streetNo: ``,
        streetNoError: ``,
        city: ``,
        cityError: ``,
        zip: ``,
        zipError: ``,
        state: ``,
        stateError: ``,
        coordinates: {
          lat: 0,
          lng: 0,
        },
        coordinatesError: ``,
      },
    },
  ])

  const [validating, setValidating] = useState(false)
  const [stateValidating, setStateValidating] = useState(false)
  const [locNameValidating, setLocNameValidating] = useState(false)
  const [businessNameValidating, setBusinessNameValidating] = useState(false)
  const [locEmailValidating, setLocEmailValidating] = useState(false)
  const [locZipValidating, setLocZipValidating] = useState(false)
  const [managerPayload, setManagerPayload] = useState(null)

  const history = useHistory()

  //handle change input
  const handleChangeInput = useDebouncedCallback(
    (name, value, e, ind, coord) => {
      const newFLocations = fLocations?.map((i, index) => {
        if (index === ind) {
          if (coord) {
            i.lAddress.coordinates = e
          } else if (e && e?.target?.id === `lAddress`) {
            i.lAddress[name] = value
          } else {
            i[name] = value
          }
        }
        return i
      })
      setFLocations(newFLocations)
    },
    5,
  )

  //handle location change input
  const handleChangeLocationName = useDebouncedCallback(
    (name, value, e, ind) => {
      const newFLocations = fLocations?.map((i, index) => {
        if (index === ind) {
          i[name] = value
          validateLocationName(e, ind)
        }
        return i
      })
      setFLocations(newFLocations)
    },
    5,
  )

  const validateLocationName = useDebouncedCallback(async (e, ind) => {
    setLocNameValidating(true)
    const response = await ValidateDuplicateLocation({ name: e.target.value })
    const newFLocations = fLocations?.map((i, index) => {
      if (index === ind) {
        if (response?.hasError) {
          setLocNameValidating(false)
          handleErrors(`formSubmit`, false)
          i[`lNameError`] = `Location Name Already Exists`
        } else {
          setLocNameValidating(false)
          handleErrors(`formSubmit`, false)
          i[`lNameError`] = ``
        }
      }
      return i
    })
    setFLocations(newFLocations)
  }, 2500)

  const handleChangeLocationEmail = useDebouncedCallback(
    (name, value, e, ind) => {
      const newFLocations = fLocations?.map((i, index) => {
        if (index === ind) {
          i[name] = value
          validateLocationEmail(e, ind)
        }
        return i
      })
      setFLocations(newFLocations)
    },
    5,
  )
  const handleChangeLocationBusinessName = useDebouncedCallback(
    (name, value, e, ind) => {
      const newFLocations = fLocations?.map((i, index) => {
        if (index === ind) {
          i[name] = value
          validateLocationBusinessName(e, ind)
        }
        return i
      })
      setFLocations(newFLocations)
    },
    5,
  )

  const validateLocationEmail = useDebouncedCallback(async (e, ind) => {
    setLocEmailValidating(true)
    const response = await ValidateDuplicateLocation({ email: e.target.value })
    const newFLocations = fLocations?.map((i, index) => {
      if (index === ind) {
        if (response?.hasError) {
          setLocEmailValidating(false)
          handleErrors(`formSubmit`, false)
          i[`lEmailError`] = `Email Already Exists`
        } else {
          if (!regEx.test(i[`lEmail`].toLowerCase())) {
            i[`lEmailError`] = `Enter Valid Email`
            setLocEmailValidating(false)
            handleErrors(`formSubmit`, false)
          } else {
            setLocEmailValidating(false)
            handleErrors(`formSubmit`, false)
            i[`lEmailError`] = ``
          }
        }
      }
      return i
    })
    setFLocations(newFLocations)
  }, 2500)
  const validateLocationBusinessName = useDebouncedCallback(async (e, ind) => {
    setBusinessNameValidating(true)
    const response = await ValidateDuplicateLocation({
      businessName: e.target.value,
    })
    const newFLocations = fLocations?.map((i, index) => {
      if (index === ind) {
        if (response?.hasError) {
          setBusinessNameValidating(false)
          handleErrors(`formSubmit`, false)
          i[`lBusinessNameError`] = `Business Name Already Exists`
        } else {
          setBusinessNameValidating(false)
          handleErrors(`formSubmit`, false)
          i[`lBusinessNameError`] = ``
        }
      }
      return i
    })
    setFLocations(newFLocations)
  }, 2500)

  // lobs change
  const handleLobsChange = (val, ind) => {
    const newFLocations = fLocations?.map((i, index) => {
      if (index === ind) {
        i[`lLobs`] = val
      }
      return i
    })
    setFLocations(newFLocations)
  }

  //handle zip code
  const handleZipChange = async (val, ind) => {
    if (val?.length === 0) {
      const newFLocations = fLocations?.map((i, index) => {
        // if (index === ind) {
        i.lAddress[`city`] = ``
        i.lAddress[`state`] = ``
        i.lAddress[`zip`] = ``
        i.lAddress[`coordinates`][`lat`] = ``
        i.lAddress[`coordinates`][`lng`] = ``
        // }
        return i
      })
      setFLocations(newFLocations)
    }
    if (val.length > 0 && val[0]?.value) {
      setLocZipValidating(true)
      const data = await ValidateZip(val[0]?.value)
      if (data) {
        setLocZipValidating(false)
        const res = data.address
        const newFLocations = fLocations?.map((i, index) => {
          if (index === ind) {
            i.lAddress[`city`] = res.city
            i.lAddress[`state`] = res.state
            i.lAddress[`zip`] = val
            i.lAddress[`coordinates`][`lat`] = res.lat
            i.lAddress[`coordinates`][`lng`] = res.long
          }
          return i
        })
        setFLocations(newFLocations)
      }
    } else {
      const newFLocations = fLocations?.map((i, index) => {
        if (index === ind) {
          i.lAddress[`zip`] = val
          i.lAddress[`city`] = ``
          i.lAddress[`state`] = ``
          i.lAddress[`coordinates`][`lat`] = 33.1444
          i.lAddress[`coordinates`][`lng`] = -117.1697
        }
        return i
      })
      setFLocations(newFLocations)
    }
  }

  //remove location
  const handleRemoveLocation = (ind) => {
    const values = [...fLocations]
    if (values.length !== 1) {
      values.splice(ind, 1)
      setFLocations(values)
    }

    const newLocationTab = [...lTabs]
    if (newLocationTab.length !== 1) {
      newLocationTab.pop()
      setLTabs(newLocationTab)
    }
  }

  //add location
  const handleAddLocation = () => {
    if (lTabs.length < 6) {
      setLTabs([
        ...lTabs,
        {
          tName: `Location ${lTabs.length + 1}`,
          tNumber: lTabs.length + 1,
          permission: [`corporate-manager:*`],
        },
      ])
      setFLocations([
        ...fLocations,
        {
          lName: ``,
          lNameError: ``,
          lPhone: ``,
          lPhoneError: ``,
          lExt: ``,
          lExtError: ``,
          lWorkPhone: ``,
          lWorkPhoneError: ``,
          lEmail: ``,
          lEmailError: ``,
          lFax: ``,
          lFaxError: ``,
          lLobs: ``,
          lLobsError: ``,
          credentialGroup: ``,
          credentialGroupError: ``,
          stateForPhone: ``,
          // stateForPhoneError: ``,
          lAddress: {
            unitNo: ``,
            streetNo: ``,
            streetNoError: ``,
            city: ``,
            cityError: ``,
            zip: ``,
            zipError: ``,
            state: ``,
            stateError: ``,
            coordinates: {
              lat: 0,
              lng: 0,
            },
            coordinatesError: ``,
          },
        },
      ])
    }
  }

  useEffect(() => {
    setActiveLocationTab(lTabs.length)
  }, [lTabs.length])

  const handleLocationErrors = (ind, name, val, address) => {
    const newFLocations = fLocations?.map((location, index) => {
      if (index == ind) {
        if (address) {
          location.lAddress[name] = val
        } else {
          location[name] = val
        }
      }
      return location
    })
    setFLocations(newFLocations)
  }
  const { mutate, isLoading } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/franchises`, {
        ...payload,
      }).then((res) => {
        if (res.hasError) {
          setQueryError(true)
        } else {
          NotificationManager.success(`Operation successful`)
          res.data && history.push(`/admin/franchises`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  //validate form on click
  const ValidForm = () => {
    if (!fName.length) {
      handleErrors(`fNameError`, `Enter Name`)
    } else if (fName.length && errors.fNameError.length) {
      return
    } else {
      handleErrors(`fNameError`, ``)
    }
    if (!isCorporate?.value && !fRegions.length) {
      handleErrors(`fRegionsError`, `Select Region`)
    } else {
      handleErrors(`fRegionsError`, ``)
    }
    if (isCorporate?.value && !fState?.value?.length) {
      handleErrors(`fStateError`, `Select State`)
    } else if (
      isCorporate?.value &&
      fState?.value?.length &&
      errors.fStateError.length
    ) {
      handleErrors(`fStateError`, errors.fStateError)
    } else {
      handleErrors(`fStateError`, ``)
    }

    if (commission?.type?.value?.length && commission?.type?.value === `%`) {
      if (!commission?.rate?.toString().length) {
        handleErrors(`commissionRateError`, `Enter Rate`)
      } else if (commission?.rate > 50) {
        handleErrors(
          `commissionRateError`,
          `Rate must be less than or equal to 50`,
        )
      } else if (commission?.rate < 1) {
        handleErrors(`commissionRateError`, `Rate must be more than 0`)
      } else {
        handleErrors(`commissionRateError`, ``)
      }
    } else if (
      commission?.type?.value?.length &&
      commission?.type?.value === `Flat`
    ) {
      if (commission?.rate < 1) {
        handleErrors(`commissionRateError`, `Rate must be more than 0`)
      } else {
        handleErrors(`commissionRateError`, ``)
      }
    } else {
      if (!commission?.rate?.length) {
        handleErrors(`commissionRateError`, `Enter Rate`)
      } else {
        handleErrors(`commissionRateError`, ``)
      }
    }

    if (!commission?.type?.value?.length) {
      handleErrors(`commissionTypeError`, `Select Type`)
    } else {
      handleErrors(`commissionTypeError`, ``)
    }

    for (const index in fLocations) {
      const location = fLocations[index]
      // if (index < 1) {
      //   if (!(`isMaster` in location)) {
      //     handleLocationErrors(index, `lIsMasterError`, `error`)
      //   } else {
      //     handleLocationErrors(index, `lIsMasterError`, ``)
      //   }
      // }

      if (!location.lName.length) {
        handleLocationErrors(index, `lNameError`, `Enter Name`)
      } else if (
        location.lName.length &&
        location.lNameError.includes(`Location Name Already Exists`)
      ) {
        handleLocationErrors(
          index,
          `lNameError`,
          `Location Name Already Exists`,
        )
      } else if (location.lName.length) {
        if (fLocations.length > 1) {
          fLocations?.forEach((loc, i) => {
            if (i != index) {
              if (loc.lName.toLowerCase() === location.lName.toLowerCase()) {
                handleLocationErrors(index, `lNameError`, `Name must be unique`)
              } else {
                handleLocationErrors(index, `lNameError`, ``)
              }
            }
          })
        } else {
          handleLocationErrors(index, `lNameError`, ``)
        }
      } else {
        handleLocationErrors(index, `lNameError`, ``)
      }
      if (!location.lBusinessName.length) {
        handleLocationErrors(index, `lBusinessNameError`, `Enter Name`)
      } else if (
        location.lBusinessName.length &&
        location.lBusinessNameError.includes(`Business Name Already Exists`)
      ) {
        handleLocationErrors(
          index,
          `lBusinessNameError`,
          `Business Name Already Exists`,
        )
      } else if (location.lBusinessName.length) {
        if (fLocations.length > 1) {
          fLocations?.forEach((loc, i) => {
            if (i != index) {
              if (
                loc.lBusinessName.toLowerCase() ===
                location.lBusinessName.toLowerCase()
              ) {
                handleLocationErrors(
                  index,
                  `lBusinessNameError`,
                  `Name must be unique`,
                )
              } else {
                handleLocationErrors(index, `lBusinessNameError`, ``)
              }
            }
          })
        } else {
          handleLocationErrors(index, `lBusinessNameError`, ``)
        }
      } else {
        handleLocationErrors(index, `lBusinessNameError`, ``)
      }

      if (!location.lPhone?.value?.length) {
        handleLocationErrors(index, `lPhoneError`, `Select Phone No`)
      } else {
        handleLocationErrors(index, `lPhoneError`, ``)
      }
      if (!location.credentialGroup?.value?.length) {
        handleLocationErrors(index, `credentialGroupError`, `Select Group`)
      } else {
        handleLocationErrors(index, `credentialGroupError`, ``)
      }

      if (!location.lExt.length) {
        handleLocationErrors(index, `lExtError`, `Enter Extension`)
      } else if (location.lExt.length) {
        if (location?.lExt?.length < 4) {
          handleLocationErrors(
            index,
            `lExtError`,
            `Ext length should be less than 4`,
          )
        } else {
          handleLocationErrors(index, `lExtError`, ``)
        }
      } else {
        handleLocationErrors(index, `lExtError`, ``)
      }

      if (!location.lEmail.length) {
        handleLocationErrors(index, `lEmailError`, `Enter Email`)
      } else if (
        location.lEmail.length &&
        location.lEmailError.includes(`Email Already Exists`)
      ) {
        handleLocationErrors(index, `lEmailError`, `Email Already Exists`)
      } else if (fLocations.length > 1) {
        fLocations?.forEach((loc, i) => {
          if (i != index) {
            if (loc.lEmail.toLowerCase() === location.lEmail.toLowerCase()) {
              handleLocationErrors(index, `lEmailError`, `Email must be unique`)
            } else {
              handleLocationErrors(index, `lEmailError`, ``)
            }
          }
        })
      } else {
        handleLocationErrors(index, `lEmailError`, ``)
      }
      if (location.lEmail.length) {
        if (!regEx.test(location.lEmail.toLowerCase())) {
          handleLocationErrors(index, `lEmailError`, `Enter Valid Email`)
        } else {
          handleLocationErrors(index, `lEmailError`, ``)
        }
      }

      if (!location.lFax.length) {
        handleLocationErrors(index, `lFaxError`, `Enter Fax`)
      } else if (location.lFax.length && location.lFax.length < 12) {
        handleLocationErrors(index, `lFaxError`, `Enter Valid Fax`)
      } else {
        handleLocationErrors(index, `lFaxError`, ``)
      }

      if (!location.lWorkPhone.length) {
        handleLocationErrors(index, `lWorkPhoneError`, `Enter Phone`)
      } else if (
        location.lWorkPhone.length &&
        location.lWorkPhone.length < 12
      ) {
        handleLocationErrors(index, `lWorkPhoneError`, `Enter Valid Phone`)
      } else {
        handleLocationErrors(index, `lWorkPhoneError`, ``)
      }

      if (!location.lLobs.length) {
        handleLocationErrors(index, `lLobsError`, `Select Lobs`)
      } else {
        handleLocationErrors(index, `lLobsError`, ``)
      }

      if (!location.lAddress.zip[0]?.value?.length) {
        handleLocationErrors(index, `zipError`, `Select Zip`, true)
      } else {
        handleLocationErrors(index, `zipError`, ``, true)
      }

      if (!location.lAddress.streetNo.length) {
        handleLocationErrors(index, `streetNoError`, `Enter Street`, true)
      } else {
        handleLocationErrors(index, `streetNoError`, ``, true)
      }

      // if (!location.lAddress.city.length) {
      //   handleLocationErrors(index, `cityError`, `Enter City`, true)
      // } else {
      //   handleLocationErrors(index, `cityError`, ``, true)
      // }

      // if (!location.lAddress.state.length) {
      //   handleLocationErrors(index, `stateError`, `Enter State`, true)
      // } else {
      //   handleLocationErrors(index, `stateError`, ``, true)
      // }

      if (
        !location.lAddress.coordinates.lat &&
        !location.lAddress.coordinates.lng
      ) {
        handleLocationErrors(
          index,
          `coordinatesError`,
          `Enter Coordinates`,
          true,
        )
      } else {
        handleLocationErrors(index, `coordinatesError`, ``, true)
      }
    }
    handleErrors(`formSubmit`, true)
  }
  useEffect(() => {
    let formValidated = false

    if (
      errors.formSubmit &&
      !errors.fNameError.length &&
      !errors.fRegionsError.length &&
      !errors.fStateError.length &&
      agentFormData?.current
    ) {
      const checkLocationError = (location) => {
        if (
          !location.lNameError.length &&
          !location.lPhoneError.length &&
          !location.lBusinessNameError.length &&
          !location.lExtError.length &&
          !location.lEmailError.length &&
          !location.lFaxError.length &&
          !location.lWorkPhoneError.length &&
          !location.lLobsError.length &&
          !location.lAddress.zipError.length &&
          !location.lAddress.streetNoError.length &&
          !location.lAddress.cityError.length &&
          !location.lAddress.stateError.length &&
          !location.lAddress.coordinatesError.length
        ) {
          return true
        } else {
          return false
        }
      }
      formValidated = fLocations?.every(checkLocationError)
    }

    if (formValidated) {
      const payloadLocations = fLocations?.map((location, index) => {
        let data = {
          name: location.lName,
          phone: location.lPhone?.value,
          businessName: location.lBusinessName,
          workPhone: location.lWorkPhone,
          ext: location.lExt,
          email: location.lEmail,
          fax: location.lFax,
          lobs: location.lLobs.length && location.lLobs.map((lob) => lob.value),
          _credentialGroup: location?.credentialGroup?.value,
          address: {
            street: location.lAddress.streetNo,
            city: location.lAddress.city,
            zip: location.lAddress?.zip?.map((zip) => zip?.value?.toString()),
            state: location.lAddress.state,
            coordinates: {
              lat: location.lAddress.coordinates.lat.toString(),
              long: location.lAddress.coordinates.lng.toString(),
            },
          },
        }

        if (location?.lAddress?.unitNo?.length) {
          data.address.unitNo = location?.lAddress?.unitNo
        }

        if (location.lExt) {
          data.ext = location.lExt
        }
        if (index === 0) {
          data.isMasterLocation = true
        }
        return data
      })

      const payloadFranchise = () => {
        let data = {}

        if (fLogo?.length) {
          data.logo = fLogo
        }
        if (isCorporate?.value) {
          if (fState.value?.length) {
            data.state = fState.value
          }
        } else if (fRegions?.length) {
          data.regions = fRegions?.map((region) => region.value)
          data.state =
            isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)
              ? fState?.value
              : operatingState?.value
        }

        if (fName?.length) {
          data.name = fName
        }
        if (fName) {
          data.isCorporateFranchise = isCorporate?.value
        }

        if (payloadLocations?.length) {
          data.locations = payloadLocations
        }
        data.user = agentFormData?.current
        return data
      }
      if (payloadLocations?.length) {
        mutate(payloadFranchise())
        handleErrors(`formSubmit`, false)
        setQueryError(false)
      }
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  const handleFLogo = async (file) => {
    const base64 = await fileResizer(file[0])
    setFLogo(base64)
  }

  const handleFNameInput = useDebouncedCallback(async (value) => {
    if (value) {
      setValidating(true)
      const response = await ValidateDuplicateFranchise(`name`, value)
      if (response?.hasError) {
        setValidating(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`fNameError`, `Franchise Name Already Exists`)
      } else {
        setValidating(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`fNameError`, ``)
      }
    }
  }, 700)

  const handleFState = async (value) => {
    handleErrors(`fStateError`, ``)
    if (value) {
      setStateValidating(true)
      const response = await ValidateDuplicateFranchise(`state`, value)
      if (response?.hasError) {
        setStateValidating(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`fStateError`, `Corporate Already Exits in ${value}`)
      } else {
        setStateValidating(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`fStateError`, ``)
      }
    }
  }

  const loadSelectedLobs = () => {
    const options = {
      options: fLocations[0].lLobs.length ? fLocations[0].lLobs : [],
      hasMore: false,
    }
    return options
  }

  const loadSelectedRegion = () => {
    const regions = fRegions.map((region) => region.value)
    // const location = fLocations.map((location) => location.lAddress?.zip?.value)

    // let unique1 = regions.filter((o) => location.indexOf(o) === -1)
    // let unique2 = location.filter((o) => regions.indexOf(o) === -1)

    //  loadOptions = unique1.concat(unique2)
    // loadOptions = loadOptions.filter(function (element) {
    //   return element !== undefined
    // })

    let loadOptions = regions.map((option) => ({
      value: option,
      label: option,
    }))

    const options = {
      options: loadOptions,
      hasMore: false,
    }
    return options
  }
  useEffect(() => {
    const updatedLocations = [...fLocations]
    updatedLocations.forEach((location) => {
      location.lAddress.zip = ``
      location.lAddress.city = ``
      location.lAddress.state = ``
    })
    setFLocations(updatedLocations)
  }, [fRegions])

  const loadAvailableNumbers = async (
    search,
    loadedOptions,
    { currentIndex, page },
  ) => {
    try {
      const {
        data: { data },
      } = await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/get/phone-numbers/?search=${
          search && search
        }&state=${fState && fState?.value}`,
      )

      const payload = data?.result?.map((number) => ({
        value: number.phoneNumber,
        label: number.phoneNumber,
      }))

      return {
        options: payload,
      }
    } catch (error) {
      if (error?.response?.data?.hasError) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        }
      }
    }
  }

  const loadBinaryOptions = async () => {
    return {
      options: [
        { value: true, label: `YES` },
        { value: false, label: `NO` },
      ],
      hasMore: false,
    }
  }
  return (
    <>
      <Spinner loading={isLoading} />
      <Container className="relative">
        <PageHeader padding="true">
          <div>Add Franchise</div>
        </PageHeader>
        {queryError && (
          <ReactNotification action="error" message="Something went wrong" />
        )}

        {/* Franchise basic forms */}
        <div
          className={`px-4 py-1 ${
            isLoading && `opacity-30 pointer-events-none`
          } `}
        >
          <div className="flex justify-center items-center my-0 py-3">
            <div className="w-50">
              <Label flogo={fLogo}>
                <Image alt="fImage" src={fLogo ? fLogo : defaultImage} />
                <input
                  type="file"
                  onChange={(e) => handleFLogo(e.target.files)}
                  className="hidden"
                  accept="image/*"
                />
              </Label>
            </div>
          </div>

          <div className="flex gap-6 pt-2">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Name *"
                placeholder="Enter Name"
                errorMessage={errors.fNameError}
                value={fName}
                validating={validating}
                setValue={(e) => {
                  setFName(e.target.value)
                  handleFNameInput(e.target.value)
                }}
              />
            </div>
            {(isSuperUser(`super-admin:*`) ||
              isSuperUser(`system-admin:*`)) && (
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Corporate"
                  placeholder="Select Corporate"
                  disabled={true}
                  value={isCorporate}
                  setValue={(e) => {
                    setIsCorporate(e)

                    if (e.value !== isCorporate?.value) {
                      handleZipChange([])
                      setFState(``)
                      setFRegions(``)
                    }
                  }}
                  loadOptions={loadBinaryOptions}
                />
              </div>
            )}
            {(isSuperUser(`super-admin:*`) ||
              isSuperUser(`system-admin:*`)) && (
              <div className="flex-1 relative">
                <ReactSelectField
                  title="State *"
                  placeholder="Select State"
                  errorMessage={errors.fStateError}
                  value={fState}
                  setValue={(e) => {
                    setFState(e)
                    if (fState?.value !== e?.value) {
                      isCorporate?.value && handleFState(e?.value)
                      setFRegions(``)
                      handleZipChange([])
                      const newFLocations = fLocations?.map((i) => {
                        i[`lPhone`] = ``
                        return i
                      })
                      setFLocations(newFLocations)
                    }
                  }}
                  loadOptions={loadStates}
                  cacheUniqs={isCorporate}
                  isSearchable={false}
                  validating={stateValidating}
                />
              </div>
            )}
            {!isCorporate?.value && (
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Regions *"
                  placeholder="Select Regions"
                  errorMessage={errors.fRegionsError}
                  value={fRegions}
                  setValue={(e) => {
                    setFRegions(e)
                  }}
                  isMulti={true}
                  loadOptions={loadZipCodes}
                  isSearchable={true}
                  operatingState={
                    isSuperUser(`super-admin:*`) ||
                    isSuperUser(`system-admin:*`)
                      ? fState?.value
                      : operatingState?.value
                  }
                  cacheUniqs={fState?.value}
                  disabled={
                    !fState?.value &&
                    (isSuperUser(`super-admin:*`) ||
                      isSuperUser(`system-admin:*`))
                  }
                />
              </div>
            )}
          </div>
          {/* <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Commission Type *"
              placeholder="Select Commission Type"
              errorMessage={errors.commissionTypeError}
              value={commission.type}
              setValue={(value) => {
                setCommission((preState) => ({
                  ...preState,
                  [`type`]: value,
                }))
              }}
              isMulti={false}
              loadOptions={LoadComissionTypes}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              title="Commission Rate *"
              placeholder="Enter Rate"
              value={commission.rate}
              errorMessage={errors.commissionRateError}
              setValue={(e) => {
                setCommission((preState) => ({
                  ...preState,
                  [`rate`]: e.target.value,
                }))
              }}
            />
          </div>
        </div> */}

          {/* Locations starts from here */}
          <SecondaryHeading>Locations</SecondaryHeading>

          <div className="flex items-center py-2">
            <TabsHeaders
              openTab={activeLocationTab}
              tabs={lTabs}
              getActiveTab={(val) => setActiveLocationTab(val)}
              customTabs="YES"
            />
            {!locZipValidating && (
              <div className="px-2">
                <AddTabButton onClick={() => handleAddLocation()}>
                  <FontAwesomeIcon icon={faPlus} />
                </AddTabButton>
              </div>
            )}
          </div>
          {/* Locations Tabs End */}
          <div className="grid gap-10 ">
            {fLocations.length > 0 &&
              fLocations?.map((location, index) => (
                <FormTab
                  key={index}
                  currenttab={index + 1}
                  opentab={activeLocationTab}
                  className="border-2 border-gray-100 shadow-sm rounded-md relative"
                >
                  <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                    <div className="flex-1">Location {index + 1}</div>
                    <div className="flex-1">
                      <div className="float-right">
                        <RemoveForm
                          onClick={() => {
                            handleRemoveLocation(index)
                            if (index > 0) setActiveLocationTab(index)
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} size="1x" />
                        </RemoveForm>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    {/* Locations basic form */}
                    <div className="grid grid-cols-4 gap-x-4 gap-y-2 mt-0.5 text-xs">
                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          title="Name *"
                          placeholder="Enter Name"
                          errorMessage={location.lNameError}
                          value={location.lName}
                          validating={locNameValidating}
                          setValue={(e) =>
                            handleChangeLocationName(
                              `lName`,
                              e.target.value,
                              e,
                              index,
                            )
                          }
                        />
                      </div>
                      {/* <div className="flex-1 relative">
                      <MaskField
                        type="text"
                        title="Phone"
                        placeholder="Enter Phone No"
                        errorMessage={location.lPhoneError}
                        value={location.lPhone}
                        setValue={(e) =>
                          handleChangeInput(`lPhone`, e.target.value, e, index)
                        }
                      />
                    </div> */}

                      <div className="flex-1 relative">
                        <InputField
                          type="email"
                          title="Email *"
                          placeholder="Enter Email"
                          errorMessage={location.lEmailError}
                          value={location.lEmail}
                          validating={locEmailValidating}
                          setValue={(e) =>
                            handleChangeLocationEmail(
                              `lEmail`,
                              e.target.value,
                              e,
                              index,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1 relative">
                        <MaskField
                          type="text"
                          title="Work Phone *"
                          placeholder="Enter Phone"
                          errorMessage={location.lWorkPhoneError}
                          value={location.lWorkPhone}
                          setValue={(e) =>
                            handleChangeInput(
                              `lWorkPhone`,
                              e.target.value,
                              e,
                              index,
                            )
                          }
                        />
                      </div>

                      <div className="flex-1 relative">
                        <MaskField
                          type="text"
                          title="Extension *"
                          placeholder="Enter Extension"
                          errorMessage={location.lExtError}
                          value={location.lExt}
                          setValue={(e) =>
                            handleChangeInput(`lExt`, e.target.value, e, index)
                          }
                          mask="9999"
                        />
                      </div>
                      <div className="flex-1 relative">
                        <MaskField
                          type="text"
                          title="Fax *"
                          placeholder="Enter Fax"
                          errorMessage={location.lFaxError}
                          value={location.lFax}
                          setValue={(e) =>
                            handleChangeInput(`lFax`, e.target.value, e, index)
                          }
                        />
                      </div>
                      <div className="flex-1 relative">
                        <ReactSelectField
                          title="Lobs *"
                          placeholder="Select Lobs"
                          errorMessage={location.lLobsError}
                          value={location.lLobs}
                          setValue={(e) => handleLobsChange(e, index)}
                          isMulti={true}
                          loadOptions={
                            index === 0
                              ? loadLobs
                              : fLocations[0]?.lLobs?.length && loadSelectedLobs
                          }
                          cacheUniqs={fLocations[0]?.lLobs}
                          isSearchable={false}
                        />
                      </div>
                      <div className="flex-1 relative">
                        <ReactSelectField
                          title="Phone *"
                          placeholder="Select Phone"
                          errorMessage={location.lPhoneError}
                          value={location?.lPhone}
                          setValue={(value) => {
                            handleChangeInput(`lPhone`, value, value, index)
                          }}
                          loadOptions={
                            fState?.value?.length && loadAvailableNumbers
                          }
                          currentIndex={index}
                          isSearchable={true}
                          cacheUniqs={fState}
                          disabled={!fState.value}
                        />
                      </div>
                      {index === 0 && (
                        <div className="flex gap-6 mt-4 text-xs">
                          <div className="flex-1 relative">
                            <RadioButton
                              title="Master *"
                              name="isMaster"
                              value={location.isMaster}
                              id="isMaster"
                              // errorMessage={location.lIsMasterError}
                              checked={true}
                              // setValue={(e) =>
                              //   handleMasterLocation(e.target.value, index)
                              // }
                            />
                          </div>
                        </div>
                      )}
                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          title="Business Name *"
                          placeholder="Enter Name"
                          errorMessage={location.lBusinessNameError}
                          value={location.lBusinessName}
                          validating={businessNameValidating}
                          setValue={(e) =>
                            handleChangeLocationBusinessName(
                              `lBusinessName`,
                              e.target.value,
                              e,
                              index,
                            )
                          }
                        />
                      </div>
                      <div className="col-span-4 relative">
                        <ReactSelectField
                          title="Group Credentials *"
                          placeholder="Select Group"
                          errorMessage={location.credentialGroupError}
                          value={location?.credentialGroup}
                          setValue={(value) =>
                            handleChangeInput(
                              `credentialGroup`,
                              value,
                              value,
                              index,
                            )
                          }
                          isMulti={false}
                          loadOptions={loadGroupCredentials}
                          isSearchable={true}
                        />
                      </div>
                    </div>

                    {/* Location Address starts from here */}
                    <SecondaryHeading>Address</SecondaryHeading>
                    <div className="grid grid-cols-5 gap-6 mt-2 text-xs">
                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          title="Street *"
                          placeholder="Enter Street"
                          errorMessage={location.lAddress.streetNoError}
                          id="lAddress"
                          value={location.lAddress.streetNo}
                          setValue={(e) =>
                            handleChangeInput(
                              `streetNo`,
                              e.target.value,
                              e,
                              index,
                            )
                          }
                        />
                      </div>

                      <div className="flex-1 relative">
                        <ReactSelectField
                          title="Zip *"
                          placeholder="Select Zip"
                          value={location?.lAddress?.zip}
                          errorMessage={location.lAddress.zipError}
                          setValue={(e) => handleZipChange(e, index)}
                          isMulti={true}
                          operatingState={fState?.value}
                          loadOptions={
                            isCorporate?.value && fState?.value
                              ? loadZipCodes
                              : loadSelectedRegion
                          }
                          isSearchable={true}
                          cacheUniqs={
                            isCorporate?.value ? fState?.value : fRegions
                          }
                          disabled={
                            (isCorporate?.value && !fState?.value) ||
                            locZipValidating
                          }
                        />
                      </div>

                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          title="Unit No"
                          placeholder="Enter Unit No"
                          id="lAddress"
                          value={location.lAddress.unitNo}
                          setValue={(e) =>
                            handleChangeInput(
                              `unitNo`,
                              e.target.value,
                              e,
                              index,
                            )
                          }
                        />
                      </div>

                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          title="City *"
                          placeholder="Enter City"
                          errorMessage={location.lAddress.cityError}
                          id="lAddress"
                          disabled={true}
                          validating={locZipValidating}
                          value={location.lAddress.city}
                          setValue={(e) =>
                            handleChangeInput(`city`, e.target.value, e, index)
                          }
                        />
                      </div>
                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          title="State *"
                          placeholder="Enter State"
                          errorMessage={location.lAddress.stateError}
                          id="lAddress"
                          disabled={true}
                          validating={locZipValidating}
                          value={location.lAddress.state}
                          setValue={(e) =>
                            handleChangeInput(`state`, e.target.value, e, index)
                          }
                        />
                      </div>
                    </div>
                    <div className="rounded-md grid grid-cols-1">
                      <label className="text-sm mt-2 text-gray-700 font-medium">
                        Locate Yourself
                        {location.lAddress.coordinatesError.length !== 0 && (
                          <span className="text-red-500 required-dot ml-2">
                            *
                          </span>
                        )}
                      </label>
                      <MapContainer
                        center={[
                          location.lAddress.coordinates.lat,
                          location.lAddress.coordinates.lng,
                        ]}
                        zoom={3}
                        scrollWheelZoom={true}
                      >
                        <ChangeView
                          center={[
                            location.lAddress.coordinates.lat,
                            location.lAddress.coordinates.lng,
                          ]}
                          zoom={3}
                        />
                        <TileLayer url={LEAFLET_URL} />
                        <DraggableMarker
                          coord={location.lAddress.coordinates}
                          setCoordinates={(e) =>
                            handleChangeInput(e, index, true)
                          }
                        />
                      </MapContainer>
                    </div>
                  </div>
                </FormTab>
              ))}
          </div>

          {/* Add Manager for this location */}

          <div className="mt-4 text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
            <div className="flex-1">Agent </div>
          </div>

          <AddManager
            ref={addManagerRef}
            getManagerPayload={agentFormData}
            agentLoadingHandler={setAgentValidating}
          />

          <div className="flex justify-center">
            <div className="text-center w-96 my-4">
              <SubmitButton
                onClick={() => {
                  ValidForm()
                  addManagerRef.current.validateForm()
                }}
                disabled={locZipValidating || agentValidating}
              >
                <div>Create Franchise</div>
              </SubmitButton>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}
