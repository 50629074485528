import React, { useEffect, useState } from 'react'
import { defaultImage } from 'App/Assets'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Avatar,
} from '@windmill/react-ui'
import {
  faChevronCircleDown,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { permissionGranted } from 'App/Services'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useSelector } from 'react-redux'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
const tableColumns = [
  { label: `First Name`, value: `firstName` },
  { label: `Last Name`, value: `lastName` },
  { label: `Email`, value: `email` },
  { label: `Phone`, value: `phone` },
  { label: `Franchise`, value: `_franchise.name` },
  { label: `Locations`, value: `_franchise.locations` },
  { label: `Commission`, value: `commission.rate` },
  // { label: `Role`, value: `role` },
]

export const SearchAgents = () => {
  const history = useHistory()

  const [collapseIndex, setCollapseIndex] = useState(``)

  const [active, setActive] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [isOffset, setIsOffset] = useState(``)
  const { searchQuery } = useSelector(({ globalSearch }) => globalSearch)

  const {
    isLoading,
    isFetching,
    data: allAgents,
    refetch: getSearchAgents,
    isError,
  } = useQuery(
    `AgentsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/users/all?search=${
          searchQuery.length ? searchQuery : ``
        }&sort=""&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!active) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }

  useEffect(() => {
    if (!customSearching) {
      getSearchAgents()
    }
  }, [currentPage, isOffset])

  return (
    <Container shadow="yes">
      <div className="relative">
        {isError ? (
          <Reload refetch={() => getSearchAgents()} />
        ) : (
          <>
            {(isLoading || isFetching) && (
              <div className="text-center py-10 absolute top-1/3 left-1/2 z-40">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="1x"
                  className="text-red-600"
                />
              </div>
            )}
            <PageHeader bgcolor="bg-white">
              <div className="flex items-center ">
                <div className="py-1 px-2">
                  <ReactSelectField
                    placeholder="10"
                    value={isOffset}
                    setValue={(e) => {
                      setCurrentPage(1)
                      setIsOffset(e)
                    }}
                    isMulti={false}
                    loadOptions={loadOffset}
                  />
                </div>
              </div>
            </PageHeader>
            <div
              className={`bg-white sm:mb-0 ${
                (isLoading || isFetching) && ` opacity-30 pointer-events-none`
              }`}
            >
              <div className="overflow-x-auto  rounded-t-md">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow>
                      <TableCell className="text-sm">Image</TableCell>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className="cursor-pointer select-none text-sm flex truncate">
                              <div className={`flex`}>{item.label}</div>
                            </div>
                          </TableCell>
                        )
                      })}
                      {(permissionGranted([
                        `users:read`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) ||
                        permissionGranted([
                          `users:delete`,
                          `corporate-manager:*`,
                          `manager:*`,
                          `admin:*`,
                        ])) && (
                        <TableCell className="px-6 text-sm float-right">
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {!allAgents?.users?.length ? (
                      <tr>
                        <td colSpan="12">
                          <div className="w-full text-center p-6  ">
                            <p>No Agent Found</p>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      allAgents?.users?.map((user, index) => (
                        <>
                          <TableRow
                            key={index}
                            className="hover:bg-gray-100 whitespace-nowrap cursor-pointer"
                            onClick={() => {
                              if (
                                permissionGranted([
                                  `users:read`,
                                  `corporate-manager:*`,
                                  `manager:*`,
                                  `admin:*`,
                                ])
                              ) {
                                history.push(`/admin/agents/${user._id}`)
                              }
                            }}
                          >
                            <td className="px-4 py-1">
                              <div className="flex items-center ">
                                <Avatar
                                  src={user.img ? user.img : defaultImage}
                                  alt="Judith"
                                />
                              </div>
                            </td>
                            <td className="px-4 py-0.5 capitalize text-sm">
                              {user?.firstName ? (
                                user?.firstName?.toLowerCase()
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 capitalize text-sm">
                              {user?.lastName ? (
                                user?.lastName?.toLowerCase()
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 text-sm">
                              {user?.contactInfo?.email ? (
                                user?.contactInfo?.email
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 text-sm">
                              {user?.contactInfo?.phone ? (
                                user?.contactInfo?.phone
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5  whitespace-nowrap">
                              {user?._franchise ? (
                                <Badge>{user?._franchise?.name}</Badge>
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 ">
                              <span>
                                {user._franchise?.locations?.length ? (
                                  <Badge type="success">
                                    {user._franchise?.locations[0]}
                                  </Badge>
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </span>
                            </td>
                            <td className="px-4 py-0.5">
                              <Badge type="warning">
                                {user?.commission?.rate
                                  ? user?.commission?.rate +
                                    ` ` +
                                    user.commission?.type
                                  : `N/A`}
                              </Badge>
                            </td>

                            {/* Actions TD */}
                            <td className="px-7 py-2 z-0 text-sm flex float-right text-gray-400">
                              <span data-tip="Show" className="tooltip">
                                <FontAwesomeIcon
                                  className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                    collapseIndex === index &&
                                    `rotate-180 text-blue-600`
                                  }`}
                                  icon={faChevronCircleDown}
                                  onClick={(e) => {
                                    setActive(!active)
                                    handleCollapse(index, e)
                                  }}
                                />
                              </span>
                            </td>
                          </TableRow>
                          {collapseIndex === index && (
                            <TableRow>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>

                              <td className="px-4 py-0.5 max-w-sm">
                                <span className="px-2">
                                  {user._franchise?.locations?.length ? (
                                    user._franchise?.locations.map(
                                      (location, idx) => {
                                        return (
                                          <Badge type="success" key={idx}>
                                            {location}
                                          </Badge>
                                        )
                                      },
                                    )
                                  ) : (
                                    <Badge>N/A</Badge>
                                  )}
                                </span>
                              </td>
                            </TableRow>
                          )}
                        </>
                      ))
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={allAgents?.totalPages}
                  totalRecords={allAgents?.totalRecords}
                  curerntPageRecords={allAgents?.currentPageRecords}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
