import {
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Badge,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@windmill/react-ui'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Reload } from 'App/Components/Common/Reload'
import { Spinner } from 'App/Components/Common/Spinner'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { loadOffset } from 'App/Services/General/loadOffset'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import React, { Fragment, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
import dateFormat from 'App/Services/General/dateFormat'
import { loadGoalFor } from 'App/Services/General/loadGoalFor'
import FiltersGoal from '../FiltersGoal'

const tableColumns = [
  { label: `Title`, value: `title` },
  { label: `Payments`, value: `targetPayment` },
  { label: `Leads`, value: `targetLeads` },
  { label: `Policies`, value: `targetPolicies` },
  { label: `Status`, value: `goalStatus` },
  { label: `Assigned To`, value: `assignedTo.label` },
  { label: `Created By`, value: `createdBy.label` },
  { label: `Created`, value: `createdAt` },
  { label: `End Date`, value: `endDate` },
  { label: `Duration`, value: `dayDifference` },
]

export const AllMonthlyGoals = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { sub: userId } = useSelector(({ user }) => user?.user?.profile)

  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [goalFor, setGoalFor] = useState(``)
  const [sort, setSort] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [goalFilters, setGoalFilters] = useState({
    title: ``,
    status: ``,
    payment: ``,
  })

  const [createdRange, setCreatedRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
      isSelected: false,
    },
  ])
  const [endRange, setEndRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
      isSelected: false,
    },
  ])

  //   getting all customers
  const {
    isLoading,
    data: monthlyGoalsData,
    refetch: getAllMonthlyGoals,
    isFetching,
    error,
  } = useQuery(
    `allMonthlygoalsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/goals?search=${searchFilters()}&sort="${
          sort.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }&goalFor=${goalFor?.value ?? `AGENT`}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )
  const {
    mutate: deleteGoal,
    isLoading: deletingGoal,
    isError: deletionGoalError,
  } = useMutation(
    async (cId) =>
      await AxiosInstance.delete(`${AMS_BACKEND_API}/api/goals/${cId}`).then(
        (res) => {
          res.data && getAllMonthlyGoals()
          NotificationManager.success(`Successfully Deleted`)
        },
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllMonthlyGoals()
    }
  }, [sort, currentPage, isOffset, goalFor, goalFilters])

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteGoal(id)
  }

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const handleResetFilters = () => {
    setGoalFilters({
      title: ``,
      status: ``,
      payment: ``,
    })
    setCreatedRange([
      {
        startDate: null,
        endDate: null,
        key: `selection`,
      },
    ])
    setEndRange([
      {
        startDate: null,
        endDate: null,
        key: `selection`,
      },
    ])
  }

  const handleFiltersChange = (filters) => {
    setCurrentPage(1)
    setGoalFilters(filters)
  }

  const handleDateRange = (range) => {
    setCreatedRange(range)
  }
  const handleEndRange = (range) => {
    setEndRange(range)
  }

  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }

    if (goalFilters?.title?.length) {
      data.filters.title = goalFilters?.title
    }
    if (goalFilters?.status?.length) {
      data.filters.status = goalFilters?.status
    }

    if (goalFilters?.payment?.length) {
      data.filters.payment = goalFilters?.payment
    }

    if (createdRange[0].isSelected) {
      data.filters.createdAt = [
        dateFormat(createdRange[0].startDate),
        dateFormat(createdRange[0].endDate),
      ]
    }
    if (endRange[0].isSelected) {
      data.filters.endDate = [
        dateFormat(endRange[0].startDate),
        dateFormat(endRange[0].endDate),
      ]
    }
    return JSON.stringify(data)
  }

  return (
    <Container>
      {deletionGoalError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <PageHeader bgcolor="bg-white">
        <div>Monthly Goals</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          {permissionGranted([
            `manager:*`,
            `corporate-manager:*`,
            `accountant:*`,
          ]) && (
            <div className="py-1 px-2">
              <ReactSelectField
                placeholder="Agent"
                value={goalFor}
                setValue={(e) => {
                  setCurrentPage(1)
                  setGoalFor(e)
                }}
                isMulti={false}
                loadOptions={loadGoalFor}
                disabled={isLoading || isFetching}
              />
            </div>
          )}
        </div>
      </PageHeader>
      <FiltersGoal
        filtersProps={goalFilters}
        handleFilters={handleFiltersChange}
        resetFilters={handleResetFilters}
        _dateRange={createdRange}
        handleDateChange={handleDateRange}
        _endDateRange={endRange}
        handleEndRangeChange={handleEndRange}
        isLoading={isLoading || isFetching}
      />
      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `monthly-goals:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <Spinner loading={isLoading || isFetching || deletingGoal} />
      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (isLoading || isFetching || deletingGoal) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllMonthlyGoals()} />
        ) : (
          <>
            <div className="overflow-x-auto rounded-t-md mt-1">
              <table className="w-full border-collapse bg-slate-50">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className=" cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item?.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {permissionGranted([
                      `monthly-goals:delete`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !monthlyGoalsData?.goals?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        No Monthly Goal Found
                      </td>
                    </tr>
                  ) : (
                    monthlyGoalsData?.goals?.map((_goal, index) => (
                      <Fragment key={index}>
                        <TableRow
                          key={index}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            if (
                              permissionGranted([
                                `monthly-goals:read`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ])
                            ) {
                              history.push(`${url}/${_goal._id}`)
                            }
                          }}
                        >
                          <td
                            className="px-4 py-0.5 cursor-pointer capitalize truncate text-sm"
                            // onClick={() => {
                            //   history.push(`${url}/${_goal._id}`)
                            // }}
                          >
                            {_goal?.title}
                          </td>

                          <td className="px-4 py-0.5 capitalize text-sm">
                            <Badge type="danger">
                              {_goal?.targetPayment
                                ? _goal?.targetPayment
                                : `N/A`}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 capitalize text-sm">
                            <Badge type="danger">
                              {_goal?.targetLeads ? _goal?.targetLeads : `N/A`}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 capitalize text-sm">
                            <Badge type="danger">
                              {_goal?.targetPolicies
                                ? _goal?.targetPolicies
                                : `N/A`}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 w-auto text-sm truncate">
                            <Badge
                              type={`${
                                _goal?.goalStatus === `COMPLETED`
                                  ? `success`
                                  : _goal?.goalStatus === `EXPIRED`
                                  ? `danger`
                                  : `primary`
                              }`}
                            >
                              {_goal?.goalStatus}
                            </Badge>
                          </td>

                          <td className="px-4 py-0.5 capitalize text-sm truncate">
                            {_goal.assignedTo?.label}
                          </td>
                          <td className="px-4 py-0.5 capitalize text-sm truncate">
                            {_goal.createdBy?.label}
                          </td>
                          <td className="px-4 py-0.5 w-auto text-sm truncate">
                            {_goal.createdAt ? (
                              dateFormat(_goal.createdAt)
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>

                          <td className="px-4 py-0.5 w-auto text-sm truncate font-semibold text-red-500">
                            {_goal.endDate ? (
                              dateFormat(_goal.endDate)
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>

                          <td className="px-4 py-0.5 capitalize text-sm">
                            <Badge className="ml-1">
                              {`${_goal?.dayDifference} Days`}
                            </Badge>
                          </td>

                          {/* Actions TD */}
                          {permissionGranted([
                            `monthly-goals:delete`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ]) && (
                            <td className="px-6 py-2 text-sm z-0 flex float-right text-gray-400">
                              <span data-tip="Delete" className="tooltip">
                                <FontAwesomeIcon
                                  className={`mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 ${
                                    _goal.createdBy?.value == userId
                                      ? `cursor-pointer`
                                      : `cursor-not-allowed`
                                  } `}
                                  icon={faTrash}
                                  size="1x"
                                  onClick={(e) => {
                                    if (_goal.createdBy?.value == userId) {
                                      handleDelete(_goal._id, e)
                                    } else {
                                      e.stopPropagation()
                                    }
                                  }}
                                />
                              </span>
                            </td>
                          )}
                        </TableRow>
                      </Fragment>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={monthlyGoalsData?.totalPages}
                totalRecords={monthlyGoalsData?.totalRecords}
                curerntPageRecords={monthlyGoalsData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
