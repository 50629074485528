import {
  faMapMarkerAlt,
  faPen,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { setCurrentCarrier } from 'App/Redux/actions'
import { permissionGranted } from 'App/Services'
import { Container, PageHeader, EditStyledButton, FormTab } from 'App/Styled'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import dateFormat from 'App/Services/General/dateFormat'

export const AboutCarrier = () => {
  const {
    url,
    params: { carrierId },
  } = useRouteMatch()

  const isImportedCarrier = url.includes(`/imported-carrier`)

  const [contactsTabs, setContactsTab] = useState(``)
  const [activeContactTab, setActiveContactTab] = useState(1)

  const [repTabs, setRepTab] = useState(``)
  const [activeRepTab, setActiveRepTab] = useState(1)

  const history = useHistory()
  const dispatch = useDispatch()
  const {
    data: carrierData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `CarrierPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/${
          isImportedCarrier ? `imported-carriers` : `carriers`
        }/${carrierId}`,
      ).then((res) => {
        let carrier = res.data.data.carrier
        let roundedCoordinates = {
          lat: carrier.address?.coordinates?.lat.toFixed(3),
          long: carrier.address?.coordinates?.long.toFixed(3),
        }
        if (carrier.address?.coordinates) {
          carrier.address.coordinates = roundedCoordinates
        }
        return carrier
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (res.contacts.length > 0) {
          setContactsTab(
            res.contacts?.map((contact, index) => {
              return {
                name: contact.name,
                number: index + 1,
              }
            }),
          )
        }
        if (res.representatives.length > 0) {
          setRepTab(
            res.representatives?.map((rep, index) => {
              return {
                name: rep.firstName + ` ` + rep.lastName,
                number: index + 1,
              }
            }),
          )
        }
        dispatch(setCurrentCarrier(res))
      },
    },
  )

  return (
    <Container>
      <PageHeader>
        <div>Carrier</div>
        {permissionGranted([
          `carriers:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <EditStyledButton onClick={() => history.push(`${url}/update`)}>
            <span>Edit</span>
            <FontAwesomeIcon
              icon={faPen}
              className="text-white mt-0.5"
              size="sm"
            />
          </EditStyledButton>
        )}
      </PageHeader>

      <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md">
        {isLoading || isFetching ? (
          <div className="text-center py-10">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : carrierData.length ? (
          <p className=" text-center p-6">No Data Found</p>
        ) : (
          <div className="m-2 pb-4 ">
            {/* Basic Info Start name email and gender */}
            <div className="px-2 pb-2">
              <div className="text-lg py-0.5 font-bold">
                {carrierData?.name}
              </div>
              {carrierData?.address ? (
                <div className="text-sm py-0.5 font-bold">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="text-gray-400 mt-0.5"
                    size="sm"
                  />
                  &nbsp;&nbsp;
                  {carrierData?.address ? `` : `Not Provided`}
                  {carrierData?.address?.unitNo
                    ? carrierData?.address?.unitNo + `,`
                    : ``}
                  {carrierData?.address?.street
                    ? carrierData?.address?.street + `,`
                    : ``}
                  {carrierData?.address?.city
                    ? carrierData?.address?.city + `,`
                    : ``}
                  {carrierData?.address?.state
                    ? carrierData?.address?.state + `,`
                    : ``}
                  {carrierData?.address?.zip ? carrierData?.address?.zip : ``}
                </div>
              ) : (
                ``
              )}
            </div>

            {/* end basic Info */}
            <div className="flex items-start justify-evenly ">
              <div className="flex-1 mx-2 rounded">
                <div className="grid grid-cols-12 p-2">
                  <div className="col-span-6 text-xl">Carrier Info</div>
                </div>
                <hr />
                <div className="flex px-2">
                  <div className="flex-1">
                    {carrierData?.naic ? (
                      <CardField title="NAIC" value={carrierData?.naic} />
                    ) : (
                      ``
                    )}
                    {carrierData?.sic ? (
                      <CardField title="SIC" value={carrierData?.sic} />
                    ) : (
                      ``
                    )}
                    {carrierData?.fein ? (
                      <CardField title="FEIN" value={carrierData?.fein} />
                    ) : (
                      ``
                    )}
                    {carrierData?.yearBusinessStarted ? (
                      <CardField
                        title="Year Business Started"
                        value={carrierData?.yearBusinessStarted}
                      />
                    ) : (
                      ``
                    )}

                    <CardField
                      title="Status"
                      value={carrierData?.isActive ? `Active` : `InActive`}
                    />
                    {carrierData?.financeSearchCode ? (
                      <CardField
                        title="Finance Search Code"
                        value={carrierData?.financeSearchCode}
                      />
                    ) : (
                      ``
                    )}
                    {carrierData?.description ? (
                      <CardField
                        title="Description"
                        value={carrierData?.description}
                      />
                    ) : (
                      ``
                    )}
                  </div>

                  <div className="flex-1">
                    {carrierData?.lobs.length ? (
                      <CardField
                        title="LOBS"
                        value={carrierData?.lobs}
                        multiple={true}
                      />
                    ) : (
                      ``
                    )}
                    {carrierData?.address?.coordinates ? (
                      <CardField
                        title="Coordinates"
                        value={carrierData?.address?.coordinates}
                        coord={true}
                      />
                    ) : (
                      ``
                    )}
                    {carrierData?.createdAt ? (
                      <CardField
                        title="Created At"
                        value={dateFormat(carrierData?.createdAt)}
                      />
                    ) : (
                      ``
                    )}
                  </div>
                </div>
              </div>
            </div>
            {carrierData?.contacts.length > 0 && (
              <div className="mt-2 mx-2 shadow-lg rounded">
                <Collapsable title="Contacts">
                  <Tabs2
                    openTab={activeContactTab}
                    tabs={contactsTabs}
                    getActiveTab={(val) => setActiveContactTab(val)}
                  />
                  {carrierData?.contacts?.map((contact, index) => (
                    <FormTab
                      key={index}
                      currenttab={index + 1}
                      opentab={activeContactTab}
                      className="mt-2 shadow-sm rounded-md relative"
                    >
                      <CardField
                        title="Designation"
                        value={
                          contact?.designation ? contact?.designation : `N/A`
                        }
                      />
                      <CardField
                        title="Mobile"
                        value={contact?.mobile ? contact?.mobile : `N/A`}
                      />
                      <CardField
                        title="Phone"
                        value={contact?.phone ? contact?.phone : `N/A`}
                      />
                      <CardField
                        title="Extension"
                        value={contact?.ext ? contact?.ext : `N/A`}
                      />
                      <CardField
                        title="Fax"
                        value={contact?.fax ? contact?.fax : `N/A`}
                      />
                      <CardField
                        title="Email"
                        value={contact?.email ? contact?.email : `N/A`}
                      />
                      <CardField
                        title="Main Website"
                        value={
                          contact?.mainWebsiteUrl
                            ? contact?.mainWebsiteUrl
                            : `N/A`
                        }
                      />
                      <CardField
                        title="Agent Portal"
                        value={
                          contact?.agentPortalUrl
                            ? contact?.agentPortalUrl
                            : `N/A`
                        }
                      />
                      <CardField
                        title="User Name"
                        value={contact?.username ? contact?.username : `N/A`}
                      />
                      <CardField
                        title="Password"
                        value={contact?.password ? contact?.password : `N/A`}
                      />
                    </FormTab>
                  ))}
                </Collapsable>
              </div>
            )}
            {carrierData?.representatives.length > 0 && (
              <div className="mt-2 mx-2 shadow-lg rounded">
                <Collapsable title="Representatives">
                  <Tabs2
                    openTab={activeRepTab}
                    tabs={repTabs}
                    getActiveTab={(val) => setActiveRepTab(val)}
                  />
                  {carrierData?.representatives?.map((_rep, index) => (
                    <FormTab
                      key={index}
                      currenttab={index + 1}
                      opentab={activeRepTab}
                      className="mt-2 shadow-sm rounded-md relative"
                    >
                      {/* <CardField title="MI Number" value={_rep?.mi} /> */}
                      <CardField
                        title="User Name"
                        value={_rep?.firstName + ` ` + _rep?.lastName}
                      />
                      {/* <CardField title="Main Website" value={_rep?.lastName} /> */}
                      <CardField
                        title="Email"
                        value={_rep?.contactInfo?.email}
                      />
                      {/* <CardField title="Fax" value={_rep?.phone} /> */}
                      <CardField
                        title="Cell Phone"
                        value={_rep?.contactInfo?.phone}
                      />
                    </FormTab>
                  ))}
                </Collapsable>
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  )
}
