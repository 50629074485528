/* eslint-disable no-unused-vars */
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Spinner } from 'App/Components/Common/Spinner'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import dateFormat, { timeFormatUTC } from 'App/Services/General/dateFormat'
import { loadVerifyOptions } from 'App/Services/General/loadVerifyStatus'
import { StyledButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useMutation, useQueryClient } from 'react-query'

export const VerifyPayment = ({ previewData, closeModal }) => {
  const [invoiceData, setInvoiceData] = useState([])
  const queryClient = useQueryClient()

  useEffect(() => {
    setInvoiceData(
      previewData?.payments?.map((item) => ({
        ...item,
        verificationStatus: {
          value: item?.verificationStatus,
          label: item?.verificationStatus,
        },
        isSelected: false,
      })),
    )
  }, [previewData])
  const {
    mutate,
    isLoading,
    isError: verifyError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/invoices/status/${previewData._id}`,

        payload,
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async () => {
        await queryClient.refetchQueries(`InvoicePreviewApi`)
        closeModal()
      },
      onError: (error) => {
        closeModal()
        NotificationManager.error(`Some thing went wrong!`)
      },
    },
  )

  const handleVerify = (value, name, ind) => {
    const newStatus = invoiceData.map((item, index) => {
      if (index === ind) {
        item[name] = value
      }
      return item
    })
    setInvoiceData(newStatus)
  }
  const handleVerified = () => {
    const payload = invoiceData
      ?.filter((invoice) => invoice?.isSelected)
      ?.map((item) => ({
        verificationStatus: item?.verificationStatus?.value,
        paymentId: item?._id,
      }))

    mutate(payload)
  }
  return (
    <div className="w-680">
      {isLoading ? (
        <div className="flex justify-center py-10">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            className="my-auto text-red-500"
          />
        </div>
      ) : (
        <div className="relative mb-4">
          <div className="flex justify-between px-2 py-1 gap-3 z-50 bg-gray-200 sticky top-0">
            <div className="flex-1">Agent</div>
            <div className="flex-1">Status </div>
            <div className="flex-1">Date</div>
            <div className="">Select</div>
          </div>
          {invoiceData?.map((item, index) => (
            <div
              className="flex justify-between items-center gap-3 px-2 py-1"
              key={index}
            >
              <div className="flex-1">{item?._agent?.label}</div>

              <div className="flex-1 relative">
                <ReactSelectField
                  // title="Status"
                  placeholder="Select Status"
                  // errorMessage={errors?.leadVerifyError}
                  value={item?.verificationStatus}
                  setValue={(e) => handleVerify(e, `verificationStatus`, index)}
                  isMulti={false}
                  loadOptions={loadVerifyOptions}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1">
                {`${dateFormat(item?.createdAt)}, ${timeFormatUTC(
                  item?.createdAt,
                )}`}
              </div>
              {/* <div className="flex-1"> */}
              <Checkbox
                type="checkbox"
                name="verified"
                // title="Assigned to Self *"
                value={item?.isSelected}
                // errorMessage={errors.isActiveError}
                setValue={() =>
                  handleVerify(!item?.isSelected, `isSelected`, index)
                }
              />
              {/* </div> */}
            </div>
          ))}
        </div>
      )}

      <StyledButton
        disabled={invoiceData?.every((item) => !item?.isSelected) || isLoading}
        className="float-right -mt-3 mx-4 mb-4"
        onClick={handleVerified}
      >
        Update
      </StyledButton>
    </div>
  )
}
