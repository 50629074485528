import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  number: ``,
  currentConversation: [],
  unknownCaller: false,
}

const globalCommunicationsSlice = createSlice({
  name: `globalCommunications`,
  initialState,

  reducers: {
    setNonCustomerNumber: (state, { payload }) => {
      state.number = payload
    },
    setUnknownCaller: (state, { payload }) => {
      state.unknownCaller = payload
    },
    setNonCustomerChatHistory: (state, { payload }) => {
      state.currentConversation=payload
    },
    setNonCustomerNewMessage: (state, { payload }) => {
      let index = state.currentConversation.findIndex(
        (message) => message._id === payload._id,
      )
      if (index === -1) {
        state.currentConversation.push(payload)
      } else {
        state.currentConversation[index] = payload
      }
    },
  },
})

export const {
  setNonCustomerChatHistory,
  setNonCustomerNumber,
  setNonCustomerNewMessage,
  setUnknownCaller,
} = globalCommunicationsSlice.actions

export const globalCommunications = globalCommunicationsSlice.reducer
