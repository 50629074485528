import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Container } from 'App/Styled'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

const newLegendClickHandler = (e, legendItem, legend) => {
  const index = legendItem.datasetIndex
  const ci = legend.chart

  let meta = ci.getDatasetMeta(index)

  // See controller.isDatasetVisible comment
  meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null

  ci.update()
}

const options = {
  cutoutPercentage: 70,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: `top`,
      onClick: newLegendClickHandler,
    },
  },
}

export const LeadsBySourceLineGraph = ({ leadsBySource }) => {
  const [dataset, setDataSet] = useState({
    labels: [],
    datasets: [
      {
        data: [],
      },
    ],
    borderWidth: 1,
  })

  useEffect(() => {
    const labels = leadsBySource?.map((item) => item.month)
    const values = leadsBySource?.map((item) => item.leads)

    const android = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.source === `ANDROID`)[0]
        ?.leads
    })
    const ios = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.source === `IOS`)[0]?.leads
    })
    const messenger = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.source === `MESSENGER`)[0]
        ?.leads
    })
    const other = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.source === `OTHER`)[0]
        ?.leads
    })
    const pwa = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.source === `PWA`)[0]?.leads
    })
    const web = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.source === `WEB`)[0]?.leads
    })
    const zapier = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.source === `ZAPIER`)[0]
        ?.leads
    })
    const monthNames = [
      `Jan`,
      `Feb`,
      `Mar`,
      `Apr`,
      `May`,
      `Jun`,
      `Jul`,
      `Aug`,
      `Sep`,
      `Oct`,
      `Nov`,
      `Dec`,
    ]
    const newMonthName = []
    for (let i = 0; i < labels?.length; i++) {
      newMonthName.push(monthNames[i])
    }

    const leadsInfo = {
      labels: newMonthName,
      datasets: [
        {
          label: `ANDROID`,
          data: android,
          backgroundColor: `#08a657`,
          borderColor: `#08a657`,
          borderWidth: 1,
          fill: true,
        },
        {
          label: `IOS`,
          data: ios,
          backgroundColor: `#8950fc`,
          borderColor: `#8950fc`,
          borderWidth: 1,
          fill: true,
        },
        {
          label: `MESSENGER`,
          data: messenger,
          backgroundColor: `#f354de`,
          borderColor: `#f354de`,
          borderWidth: 1,
          fill: true,
        },
        {
          label: `OTHER`,
          data: other,
          backgroundColor: `#181c32`,
          borderColor: `#181c32`,
          borderWidth: 1,
          fill: true,
        },
        {
          label: `PWA`,
          data: pwa,
          backgroundColor: (context) => {
            const ctx = context.chart.ctx
            const gradient = ctx.createLinearGradient(0, 0, 0, 300)
            gradient.addColorStop(0.25, `#547FF3`)
            gradient.addColorStop(1, `#42B7FF`)
            return gradient
          },
          pointBackgroundColor: `white`,
          borderColor: `#547FF3`,
          borderWidth: 2,
          fill: true,
          tension: 0.3,
        },
        {
          label: `WEB`,
          data: web,
          backgroundColor: (context) => {
            const ctx = context.chart.ctx
            const gradient = ctx.createLinearGradient(0, 0, 0, 300)
            gradient.addColorStop(0.25, `#FF9861`)
            gradient.addColorStop(1, `#FFDD00`)
            return gradient
          },
          borderColor: `#FF9861`,
          borderWidth: 2,
          fill: true,
          tension: 0.3,
        },
        {
          label: `ZAPIER`,
          data: zapier,
          backgroundColor: `#ff3400`,
          borderColor: `#ff3400`,
          borderWidth: 3,
          fill: true,
        },
      ],
    }

    setDataSet(leadsInfo)
  }, [leadsBySource])

  return (
    <Container>
      <div className="py-9 relative">
        <div className="font-bold absolute left-4 2xl:left-6 top-5">
          Leads by Source
        </div>
      </div>

      <div className="h-280 m-6">
        {dataset.labels !== undefined ? (
          <Line data={dataset} options={options} />
        ) : null}
      </div>
    </Container>
  )
}
