import React, { useRef, useState, useEffect } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  Container,
  FormTab,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import {
  loadZipCodes,
  ValidateZip,
  loadGenderOptions,
  loadCustomers,
} from 'App/Services'
import { useHistory } from 'react-router'
import { useMutation } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'

import {
  AMS_BACKEND_API,
  AQS_BACKEND_API,
  AxiosInstance,
  regEx,
} from 'App/Config'
import { useDebouncedCallback } from 'use-debounce/lib'
import { InputFieldRef } from 'App/Components/Common/InputFieldRef'
import { MaskField } from 'App/Components/Common/MaskField'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'

import {
  loadBinaryOptions,
  loadForeignLicensed,
  loadMakes,
  loadMakeYears,
  loadMaritalStatus,
  loadModel,
  loadPolicyTerm,
  loadRelationship,
} from 'App/Services/quoteForm'
import {
  AddFormButton,
  RemoveDriver,
} from 'App/Components/Raters/QuoteForm/style'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import moment from 'moment'
import { loadCarrier } from 'App/Services/Customer/loadCarriers'
import { ReactModal } from 'App/Components/Common/Modal'
import { NotificationManager } from 'react-notifications'
import { loadEducationLevelOptions } from 'App/Services/General/loadEducationLevelOptions'

export const AddPolicy = () => {
  const [activeDriverTab, setActiveDriverTab] = useState(1)
  const [activeVehicleTab, setActiveVehicleTab] = useState(1)
  const [gotVinNumbers, setGotVinNumbers] = useState(false)
  const [dTabs, setDTabs] = useState([
    {
      tName: `Driver 1`,
      tNumber: 1,
      permission: [
        `policy:create`,
        `corporate-manager:*`,
        `manager:*`,
        `admin:*`,
      ],
    },
  ])
  useEffect(() => {
    setActiveDriverTab(dTabs?.length)
  }, [dTabs.length])

  const driverFirstNameRef = useRef([])
  const driverMiddleNameRef = useRef([])
  const driverLastNameRef = useRef([])
  const driverLisceneNoRef = useRef([])
  const street = useRef(null)
  const firstName = useRef(null)
  const middleName = useRef(null)
  const lastName = useRef(null)
  const email = useRef(null)
  const totalPremium = useRef(null)

  const [drivers, setDrivers] = useState([
    {
      dob: ``,
      gender: ``,
      maritalStatus: ``,
      relation: ``,
      firstNameError: ``,
      lastNameError: ``,
      dobError: ``,
      genderError: ``,
      maritalStatusError: ``,
      relationError: ``,
      licensedError: ``,
      expireDateError: ``,

      license: {
        isLicensed: ``,
        isSuspended: ``,
        suspendedMonths: ``,
        usLicensedMonths: ``,
        stateLicensedMonths: ``,
        usMVRExperienceMonths: ``,
        isForeignLicensed: ``,
        foreignLicenseCountry: ``,
        foreignLicensedMonths: ``,
      },
      education: {
        educationLevel: ``,
      },
    },
  ])
  const [vehicles, setVehicles] = useState([
    {
      vin: ``,
      vinError: ``,
      modelYear: ``,
      yearError: ``,
      model: ``,
      modelError: ``,
      make: ``,
      makeError: ``,
      purchaseDate: ``,
      purchaseDateError: ``,
    },
  ])

  const addNewVehicle = {
    vin: ``,
    vinError: ``,
    modelYear: ``,
    yearError: ``,
    model: ``,
    modelError: ``,
    make: ``,
    makeError: ``,
    purchaseDate: ``,
    purchaseDateError: ``,
  }

  const addNewDriver = {
    dob: ``,
    gender: ``,
    maritalStatus: ``,
    relation: ``,
    license: {
      isLicensed: ``,
      isSuspended: ``,
      suspendedMonths: ``,
      usLicensedMonths: ``,
      stateLicensedMonths: ``,
      usMVRExperienceMonths: ``,
      isForeignLicensed: ``,
      foreignLicenseCountry: ``,
    },

    education: {
      educationLevel: ``,
    },

    firstNameError: ``,
    lastNameError: ``,
    dobError: ``,
    genderError: ``,
    maritalStatusError: ``,
    relationError: ``,
    licensedError: ``,
  }

  const handleDriverChange = useDebouncedCallback(
    (name, value, ind, nestedAttributes) => {
      const newDriver = drivers?.map((driver, index) => {
        if (index === ind) {
          if (nestedAttributes === `prior`) {
            driver.priorInsurance[name] = value
          } else if (nestedAttributes === `license`) {
            driver.license[name] = value
          } else if (nestedAttributes === `education`) {
            driver.education[name] = value
          } else {
            driver[name] = value
          }
        }
        return driver
      })
      setDrivers(newDriver)
    },
    30,
  )

  const history = useHistory()
  const [policyNo, setPolicyNo] = useState(``)
  const [validatingPolicyNo, setValidatingPolicyNo] = useState(false)

  const [errors, setErrors] = useState({
    formSubmit: false,
    firstNameError: ``,
    lastNameError: ``,
    PhoneError: ``,
    emailError: ``,
    zipError: ``,
    streetNoError: ``,
    workPhoneError: ``,
    faxError: ``,
    workPhoneExtError: ``,
    effectiveDateError: ``,
    expireDateError: ``,
    policyTermError: ``,
    carrierError: ``,
    policyNoError: ``,
  })
  const [outOfFocus, setOutOfFocus] = useState(``)

  const [customer, setCustomer] = useState({
    phone: ``,
    workPhone: ``,
    workPhoneExt: ``,
    fax: ``,
    expireDate: ``,
    effectiveDate: ``,
    carrier: ``,
    customerId: ``,
    policyTerm: { value: `6`, label: `Semi Annual` },
  })
  const [address, setAddress] = useState({
    unitNo: ``,
    city: ``,
    zip: ``,
    state: ``,
    lat: ``,
    lng: ``,
  })
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCustomerChange = useDebouncedCallback((name, value) => {
    setCustomer((preState) => ({
      ...preState,
      [name]: value,
    }))
  }, 1)

  const handleCustomerZipChange = async (e) => {
    const data = await ValidateZip(e?.value)
    if (data) {
      const res = data?.address
      setAddress((prevState) => ({
        ...prevState,
        city: res.city,
        state: res.state,
        zip: { value: res.zipCode, label: res.zipCode },
        lat: res.lat,
        lng: res.long,
      }))
    }
  }

  const handleDriverErrors = (ind, name, val, vindex, vError) => {
    const newDriver = drivers?.map((driver, index) => {
      if (index == ind) {
        if (vError) {
          driver?.violations?.forEach((violation, idx) => {
            if (idx == vindex) {
              violation[name] = val
            }
          })
        } else {
          driver[name] = val
        }
      }
      return driver
    })
    setDrivers(newDriver)
  }

  const addDriver = () => {
    if (drivers.length < 6) {
      setDTabs([
        ...dTabs,
        {
          tName: `Driver ${dTabs.length + 1}`,
          tNumber: dTabs.length + 1,
          permission: [
            `policy:create`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ],
        },
      ])
      setDrivers([...drivers, addNewDriver])
    }
  }

  const removeReferenceFields = (ind) => {
    // Remove Instance of Ref

    driverFirstNameRef.current.forEach((item, index) => {
      if (index >= ind && driverFirstNameRef.current[index + 1]) {
        driverFirstNameRef.current[index].value =
          driverFirstNameRef.current[index + 1]?.value
      }
    })
    driverMiddleNameRef.current.forEach((item, index) => {
      if (index >= ind && driverMiddleNameRef.current[index + 1]) {
        driverMiddleNameRef.current[index].value =
          driverMiddleNameRef.current[index + 1]?.value
      }
    })
    driverLastNameRef.current.forEach((item, index) => {
      if (index >= ind && driverLastNameRef.current[index + 1]) {
        driverLastNameRef.current[index].value =
          driverLastNameRef.current[index + 1]?.value
      }
    })

    driverLisceneNoRef.current.forEach((item, index) => {
      if (index >= ind && driverLisceneNoRef?.current[index + 1]) {
        driverLisceneNoRef.current[index].value =
          driverLisceneNoRef?.current[index + 1]?.value
      }
    })
  }

  const handleRemoveDriver = (ind) => {
    // Delete Input Refs from ref array
    removeReferenceFields(ind)
    const newDrivers = [...drivers]
    if (newDrivers.length !== 1) {
      newDrivers.splice(ind, 1)
      setDrivers(newDrivers)
    }
    const newDriversTab = [...dTabs]
    if (newDriversTab.length !== 1) {
      newDriversTab.pop()
      setDTabs(newDriversTab)
    }
  }

  const convertDate = (date) => {
    const currentDate = new Date()
    let a = moment(date)
    let b = moment(currentDate)
    const xYear = b.diff(a, `years`)
    const xMonth = b.diff(a, `months`) - xYear * 12

    const diff = xYear * 12 + xMonth
    return diff
  }

  const handlePolicyNo = (value) => {
    setPolicyNo(value)
    if (value) validateName(value)
  }

  const ValidateDuplicatePolicyNo = async (value) => {
    if (value) {
      return await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/validate/policy-payloads`,
        {
          policyNo: value,
        },
      )
        .then((response) => {
          return response?.data
        })
        .catch((error) => {
          return error?.response?.data
        })
    }
  }

  const validateName = useDebouncedCallback(async (value) => {
    if (value) {
      setValidatingPolicyNo(true)
      const response = await ValidateDuplicatePolicyNo(value)
      if (response?.hasError) {
        setValidatingPolicyNo(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`policyNoError`, `Policy No Already Exists`)
      } else {
        setValidatingPolicyNo(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`policyNoError`, ``)
      }
    }
  }, 2500)

  useEffect(() => {
    // Set Name for first driver from customer info
    driverFirstNameRef.current[0].value = firstName?.current?.value ?? ``
    driverLastNameRef.current[0].value = lastName?.current?.value ?? ``
    driverMiddleNameRef.current[0].value = middleName?.current?.value ?? ``
  }, [outOfFocus])

  useEffect(() => {
    drivers[0].relation = { value: `INSURED`, label: `INSURED` }
  }, [])

  // ``````````````````````````` Vehicles ````````````````````

  const [vTabs, setVTabs] = useState([
    {
      tName: `Vehicle 1`,
      tNumber: 1,
      permission: [
        `policy:create`,
        `corporate-manager:*`,
        `manager:*`,
        `admin:*`,
      ],
    },
  ])

  const handleRemoveVehicle = (ind) => {
    const newVehicle = [...vehicles]
    if (newVehicle.length !== 1) {
      newVehicle.splice(ind, 1)
      setVehicles(newVehicle)
    }

    const newVehicleTab = [...vTabs]
    if (newVehicleTab.length !== 1) {
      newVehicleTab.pop()
      setVTabs(newVehicleTab)
    }
  }

  const handleVehicleChange = useDebouncedCallback((name, value, ind) => {
    const newVehicle = vehicles?.map((vehicle, index) => {
      if (index === ind) {
        vehicle[name] = value
      }
      return vehicle
    })
    setVehicles(newVehicle)
  }, 10)

  const handleVinNumber = useDebouncedCallback((name, value, ind) => {
    const newVehicle = vehicles?.map((vehicle, index) => {
      if (index === ind) {
        vehicle[name] = value
      }
      return vehicle
    })
    setVehicles(newVehicle)

    if (value.length > 9) {
      getVehicleDetailsQuery(value, ind)
    }
  }, 10)

  const handleVehicleErrors = (ind, name, val) => {
    const newVehicle = vehicles?.map((vehicle, index) => {
      if (index == ind) {
        vehicle[name] = val
      }
      return vehicle
    })
    setVehicles(newVehicle)
  }

  const getVehicleDetailsQuery = useDebouncedCallback((value, ind) => {
    getVehicleDetails({ value: value, ind: ind })
  }, 1500)

  const { mutate: getVehicleDetails, isLoading: vinLoading } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AQS_BACKEND_API}/analyze/car/vin`, {
        vin: payload.value,
      }).then((res) => {
        const result = res.data.data.result
        const newVehicle = vehicles?.map((vehicle, index) => {
          if (index === payload.ind) {
            vehicle.modelYear = { value: result.year, label: result.year }
            vehicle.model = { value: result.model, label: result.model }
            vehicle.make = { value: result.make, label: result.make }
            vehicle.msrp = result?.msrp ?? ``
            vehicle.numberOfCylinders = result?.cylinders ?? ``
            vehicle.fuelType = result.fuelType
              ? {
                  value: result.fuelType,
                  label: result.fuelType,
                }
              : ``
            vehicle.turboCharged = result.turbo
              ? { value: result.turbo, label: result.turbo }
              : { value: `NO`, label: `NO` }
            vehicle.passiveRestraint = result.passiveRestraint
              ? {
                  value: result.passiveRestraint,
                  label: result.passiveRestraint,
                }
              : ``
            vehicle.truckSize = result.truckSize
              ? {
                  value: result.truckSize,
                  label: result.truckSize,
                }
              : ``
            vehicle.antiLockBrakes = result.antiLock
              ? {
                  value: result.antiLock,
                  label: result.antiLock,
                }
              : ``
            vehicle.vehicleType = result.vehicleType
              ? {
                  value: result.vehicleType,
                  label: result.vehicleType,
                }
              : ``
            vehicle.fourWheelDrive = result[`4wd`]
              ? {
                  value: result[`4wd`],
                  label: result[`4wd`],
                }
              : {
                  value: `NO`,
                  label: `NO`,
                }
          }
          return vehicle
        })
        setVehicles(newVehicle)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleCarModel = useDebouncedCallback((name, value, ind) => {
    const newVehicle = vehicles?.map((vehicle, index) => {
      if (index === ind) {
        vehicle[name] = value
      }
      return vehicle
    })
    setVehicles(newVehicle)

    if (
      vehicles[ind].modelYear?.value &&
      vehicles[ind].make?.label &&
      value.value
    ) {
      callVehiclesVinNumberQuery(value, ind)
    }
  }, 10)

  const callVehiclesVinNumberQuery = useDebouncedCallback((model, ind) => {
    getVehicleVinNumvers({ value: model.value, ind: ind })
  }, 1500)

  const {
    mutate: getVehicleVinNumvers,
    data: vinNumbersData,
    isLoading: validateModel,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AQS_BACKEND_API}/analyze/car/ymm`, {
        year: `` + vehicles[payload.ind]?.modelYear?.value,
        make: vehicles[payload.ind]?.make?.label,
        model: vehicles[payload.ind]?.model?.label,
      }).then((res) => {
        if (res.data.data.result.length) {
          setGotVinNumbers(payload.ind)
        }
        return res.data.data.result
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const addVehicle = () => {
    if (vehicles.length < 6 && vTabs.length < 6) {
      setVTabs([
        ...vTabs,
        {
          tName: `Vehicle ${vTabs.length + 1}`,
          tNumber: vTabs.length + 1,
          permission: [
            `policy:create`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ],
        },
      ])

      setVehicles([...vehicles, addNewVehicle])
    }
  }

  useEffect(() => {
    setActiveVehicleTab(vTabs?.length)
  }, [vTabs.length])

  const validateFrom = () => {
    if (!firstName?.current?.value?.length) {
      handleErrors(`firstNameError`, `Enter First Name`)
    } else {
      handleErrors(`firstNameError`, ``)
    }

    if (!lastName?.current?.value?.length) {
      handleErrors(`lastNameError`, `Enter Last Name`)
    } else {
      handleErrors(`lastNameError`, ``)
    }

    if (!customer?.phone?.length && !customer?.customerId?.value?.length) {
      handleErrors(`phoneError`, `Enter Phone`)
    } else if (
      customer?.phone?.length < 12 &&
      !customer?.customerId?.value?.length
    ) {
      handleErrors(`phoneError`, `Enter Valid Phone`)
    } else {
      handleErrors(`phoneError`, ``)
    }

    if (!address?.zip?.value?.length) {
      handleErrors(`zipError`, `Select Zip`)
    } else {
      handleErrors(`zipError`, ``)
    }
    if (!customer?.carrier?.value?.length) {
      handleErrors(`carrierError`, `Select Carrier`)
    } else {
      handleErrors(`carrierError`, ``)
    }

    if (!street?.current?.value?.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }

    if (!totalPremium?.current?.value?.length) {
      handleErrors(`totalPremiumError`, `Enter Premium`)
    } else if (
      +totalPremium?.current?.value < 1 ||
      +totalPremium?.current?.value > 9999999
    ) {
      handleErrors(`totalPremiumError`, `Enter Valid Value`)
    } else {
      handleErrors(`totalPremiumError`, ``)
    }

    if (!policyNo?.length) {
      handleErrors(`policyNoError`, `Enter Policy No`)
    } else if (
      policyNo.length &&
      errors.policyNoError.includes(`Policy No Already Exists`)
    ) {
      handleErrors(`policyNoError`, `Policy No Already Exists`)
    } else {
      handleErrors(`policyNoError`, ``)
    }

    if (!customer?.policyTerm?.value?.length) {
      handleErrors(`policyTermError`, `Enter Term`)
    } else {
      handleErrors(`policyTermError`, ``)
    }
    if (!customer?.expireDate) {
      handleErrors(`expireDateError`, `Select Date`)
    } else {
      handleErrors(`expireDateError`, ``)
    }
    if (!customer?.effectiveDate) {
      handleErrors(`effectiveDateError`, `Select Date`)
    } else {
      handleErrors(`effectiveDateError`, ``)
    }

    if (customer?.workPhoneExt?.length) {
      if (customer?.workPhoneExt?.length < 4) {
        handleErrors(`workPhoneExtError`, `Ext Should be 4 digit`)
      } else {
        handleErrors(`workPhoneExtError`, ``)
      }
    } else {
      handleErrors(`workPhoneExtError`, ``)
    }

    if (customer?.workPhone?.length && customer?.workPhone?.length < 12) {
      handleErrors(`workPhoneError`, `Enter Valid Phone`)
    } else {
      handleErrors(`workPhoneError`, ``)
    }

    if (customer?.fax?.length && customer?.fax?.length < 12) {
      handleErrors(`faxError`, `Enter Valid Fax`)
    } else {
      handleErrors(`faxError`, ``)
    }

    if (email?.current?.value?.length) {
      if (
        errors?.emailError?.length &&
        errors?.emailError?.includes(`Email Already Exists`)
      ) {
        handleErrors(`emailError`, `Email Already Exists`)
      } else {
        if (!regEx.test(email?.current?.value?.toLowerCase())) {
          handleErrors(`emailError`, `Enter Valid Email`)
        } else {
          handleErrors(`emailError`, ``)
        }
      }
    } else {
      handleErrors(`emailError`, ``)
    }

    for (const index in drivers) {
      const driver = drivers[index]
      if (!driverFirstNameRef?.current[index]?.value?.length) {
        handleDriverErrors(index, `firstNameError`, `Enter First Name`)
      } else {
        handleDriverErrors(index, `firstNameError`, ``)
      }
      if (!driverLastNameRef?.current[index]?.value?.length) {
        handleDriverErrors(index, `lastNameError`, `Enter Last Name`)
      } else {
        handleDriverErrors(index, `lastNameError`, ``)
      }
      if (!driver.dob) {
        handleDriverErrors(index, `dobError`, `Enter DOB`)
      } else {
        handleDriverErrors(index, `dobError`, ``)
      }
      if (!driver.gender?.value?.length) {
        handleDriverErrors(index, `genderError`, `Select Gender`)
      } else {
        handleDriverErrors(index, `genderError`, ``)
      }
      if (!driver.maritalStatus?.value?.length) {
        handleDriverErrors(index, `maritalStatusError`, `Select Marital Status`)
      } else {
        handleDriverErrors(index, `maritalStatusError`, ``)
      }
      if (!driver.relation?.value?.length) {
        handleDriverErrors(index, `relationError`, `Select RelationShip`)
      } else {
        handleDriverErrors(index, `relationError`, ``)
      }
      if (!driver.license?.isLicensed?.value?.length) {
        handleDriverErrors(index, `licensedError`, `Select License`)
      } else {
        handleDriverErrors(index, `licensedError`, ``)
      }
    }

    for (const index in vehicles) {
      const vehicle = vehicles[index]
      if (!vehicle?.vin?.length) {
        handleVehicleErrors(index, `vinError`, `Enter VIN`)
      } else {
        handleVehicleErrors(index, `vinError`, ``)
      }

      if (!vehicle?.make?.value?.length) {
        handleVehicleErrors(index, `makeError`, `Select Make`)
      } else {
        handleVehicleErrors(index, `makeError`, ``)
      }
      if (!vehicle?.model?.value?.length) {
        handleVehicleErrors(index, `modelError`, `Select Model`)
      } else {
        handleVehicleErrors(index, `modelError`, ``)
      }
      if (!vehicle?.purchaseDate) {
        handleVehicleErrors(index, `purchaseDateError`, `Select Date`)
      } else {
        handleVehicleErrors(index, `purchaseDateError`, ``)
      }

      if (!vehicle?.modelYear?.value?.toString()?.length) {
        handleVehicleErrors(index, `modelYearError`, `Select Model Year`)
      } else {
        handleVehicleErrors(index, `modelYearError`, ``)
      }
    }

    handleErrors(`formSubmit`, true)
  }

  const prepareDriversPayload = () => {
    if (drivers?.length > 0) {
      return drivers?.map((driver, index) => {
        let payload = {
          license: {},
          education: {},
        }

        if (driverFirstNameRef?.current[index]?.value?.length) {
          payload.firstName = driverFirstNameRef?.current[index]?.value
        }
        if (driverMiddleNameRef?.current[index]?.value?.length) {
          payload.middleName = driverMiddleNameRef?.current[index]?.value
        }
        if (driverLastNameRef?.current[index]?.value?.length) {
          payload.lastName = driverLastNameRef?.current[index]?.value
        }
        if (driver?.relation?.value?.length) {
          payload.relation = driver?.relation?.value
        }
        if (driver?.dob) {
          payload.dob = driver?.dob
        }

        if (driver?.gender?.value?.length) {
          payload.gender = driver?.gender?.value
        }
        if (driver?.maritalStatus?.value?.length) {
          payload.maritalStatus = driver?.maritalStatus?.value
        }

        if (driver?.license?.isLicensed?.value?.length) {
          payload.license.isLicensed = driver?.license?.isLicensed?.value
        }

        if (driver?.license?.isLicensed?.value === `YES`) {
          if (driverLisceneNoRef?.current[index]?.value?.length) {
            payload.license.licenseNo =
              driverLisceneNoRef?.current[index]?.value
          }
          if (driver?.license?.isSuspended?.length) {
            payload.license.isSuspended = driver?.license?.isSuspended
          }
          if (driver?.license?.suspendedMonths) {
            payload.license.suspendedMonths = convertDate(
              driver?.license?.suspendedMonths,
            )
          }
          if (driver?.license?.usLicensedMonths) {
            payload.license.usLicensedMonths = convertDate(
              driver?.license?.usLicensedMonths,
            )
          }
          if (driver?.license?.stateLicensedMonths) {
            payload.license.stateLicensedMonths = convertDate(
              driver?.license?.stateLicensedMonths,
            )
          }
          if (driver?.license?.usMVRExperienceMonths) {
            payload.license.usMVRExperienceMonths = convertDate(
              driver?.license?.usMVRExperienceMonths,
            )
          }

          if (driver?.license?.foreignLicenseCountry?.length) {
            payload.license.foreignLicenseCountry =
              driver?.license?.foreignLicenseCountry
          }
          if (driver?.license?.isForeignLicensed?.value?.length) {
            payload.license.isForeignLicensed =
              driver?.license?.isForeignLicensed?.value
          }

          if (driver?.license?.foreignLicensedMonths) {
            payload.license.foreignLicensedMonths = convertDate(
              driver?.license?.foreignLicensedMonths,
            )
          }
        }

        if (driver?.education?.educationLevel?.value?.length) {
          payload.education.educationLevel =
            driver.education?.educationLevel?.value
        }
        return payload
      })
    }
  }

  const preparedVehiclePayload = () => {
    if (vehicles?.length > 0) {
      return vehicles.map((vehicle) => {
        let payload = { modelDetails: {}, purchase: {} }
        if (vehicle?.modelYear?.value?.toString()?.length) {
          payload.year = vehicle?.modelYear?.value
        }
        if (vehicle?.make?.value?.length) {
          payload.make = vehicle?.make?.value
        }
        if (vehicle?.model?.value?.length) {
          payload.model = vehicle?.model?.value
        }
        if (vehicle?.vin?.length) {
          payload.vin = vehicle?.vin
        }
        if (vehicle?.purchaseDate) {
          payload.purchase.date = vehicle?.purchaseDate
        }

        if (vehicle?.vehicleType?.value?.length) {
          payload.modelDetails.vehicleType = vehicle?.vehicleType?.value
        }
        if (vehicle?.fuelType?.value?.length) {
          payload.modelDetails.fuelType = vehicle?.fuelType?.value
        }
        if (vehicle?.truckSize?.value?.length) {
          payload.modelDetails.truckSize = vehicle?.truckSize?.value
        }
        if (vehicle?.numberOfCylinders?.length) {
          payload.modelDetails.cylinders = vehicle?.numberOfCylinders
        }
        if (vehicle?.fourWheelDrive?.value?.length) {
          payload.modelDetails.is4wd = vehicle?.fourWheelDrive?.value
        }

        if (vehicle?.fourWheelSteering?.value?.length) {
          payload.modelDetails.is4ws = vehicle?.fourWheelSteering?.value
        }
        if (vehicle?.turboCharged?.value?.length) {
          payload.modelDetails.isTurboCharged = vehicle?.turboCharged?.value
        }
        if (vehicle?.airBags?.value?.length) {
          payload.modelDetails.airBags = vehicle?.airBags?.value
        }
        if (vehicle?.antiLockBrakes?.value?.length) {
          payload.modelDetails.antiLock = vehicle?.antiLockBrakes?.value
        }
        if (vehicle?.antiTheft?.value?.length) {
          payload.modelDetails.antiTheft = vehicle?.antiTheft?.value
        }
        if (vehicle?.passiveRestraint?.value?.length) {
          payload.modelDetails.passiveRestraint =
            vehicle?.passiveRestraint?.value
        }

        return payload
      })
    }
  }

  const preparedCustomerPayload = () => {
    let payload = { contactInfo: {}, address: {} }

    if (customer?.carrier?.value?.length) {
      payload._carrier = customer?.carrier?.value
    }
    if (totalPremium?.current?.value?.length) {
      payload.totalPremium = totalPremium?.current?.value
    }
    if (firstName?.current?.value?.length) {
      payload.firstName = firstName?.current?.value
    }
    if (lastName?.current?.value?.length) {
      payload.lastName = lastName?.current?.value
    }
    if (middleName?.current?.value?.length) {
      payload.middleName = middleName?.current?.value
    }
    if (policyNo?.length) {
      payload.policyNo = policyNo
    }
    if (customer?.customerId?.value?.length) {
      payload._customer = customer?.customerId?.value
    }

    // ``````````````````contactInfo`````````````````

    if (customer?.workPhone?.length) {
      payload.contactInfo.workPhone = customer?.workPhone
    }
    if (customer?.workPhoneExt?.length) {
      payload.contactInfo.workPhoneExt = customer?.workPhoneExt
    }
    if (customer?.fax?.length) {
      payload.contactInfo.fax = customer?.fax
    }
    if (email?.current?.value?.length) {
      payload.contactInfo.email = email?.current?.value
    }
    if (customer?.phone?.length) {
      payload.contactInfo.phone = customer?.phone
    }

    // ``````````````````address`````````````````

    if (address?.unitNo?.length) {
      payload.address.unitNo = address?.unitNo
    }
    if (address?.city?.length) {
      payload.address.city = address?.city
    }
    if (address?.state?.length) {
      payload.address.state = address?.state
    }
    if (address?.zip?.value?.length) {
      payload.address.zip = address?.zip?.value
    }
    if (street?.current?.value?.length) {
      payload.address.street = street?.current?.value
    }
    if (address?.lat && address?.lng) {
      payload.address.coordinates = {}

      if (address?.lat) {
        payload.address.coordinates.lat = address?.lat
      }
      if (address?.lng) {
        payload.address.coordinates.long = address?.lng
      }
    }

    return payload
  }

  const handleAddressChange = (name, value) => {
    setAddress((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const { isError, isLoading, mutate } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/policies `, {
        ...payload,
      })
        .then((res) => {
          if (res.data) {
            NotificationManager.success(`Policy Added Successfully.`)
            history.push(`/customers`)
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.error?.error?.includes(
              `Customer with this phone already exist.`,
            )
          )
            NotificationManager.error(`Customer with this phone already exist.`)
          error?.response?.data?.error?.error
            ? NotificationManager.error(error?.response?.data?.error?.error)
            : NotificationManager.error(`Something went wrong!`)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const { mutate: getCustomerDetails, isLoading: loadingCustomerDetails } =
    useMutation(
      async (payload) =>
        await AxiosInstance.get(
          `${AMS_BACKEND_API}/api/customers/${payload}`,
        ).then((res) => {
          if (res) {
            let customerDetails = res.data.data.customer

            setCustomer((prev) => ({
              ...prev,
              phone: customerDetails?.contactInfo?.phone ?? ``,
              workPhone: customerDetails?.contactInfo?.workPhone ?? ``,
              workPhoneExt: customerDetails?.contactInfo?.workPhoneExt ?? ``,
              fax: customerDetails?.contactInfo?.fax ?? ``,
            }))

            setAddress({
              unitNo: customerDetails?.address?.unitNo ?? ``,
              city: customerDetails?.address?.city ?? ``,
              zip: customerDetails?.address?.zip
                ? {
                    value: customerDetails?.address?.zip,
                    label: customerDetails?.address?.zip,
                  }
                : ``,
              state: customerDetails?.address?.state ?? ``,
            })

            setDrivers((prev) =>
              prev.map((driver, index) => {
                if (index === 0) {
                  driver.gender = customerDetails?.gender
                    ? {
                        value: customerDetails?.gender,
                        label: customerDetails?.gender,
                      }
                    : ``
                  driver.maritalStatus = customerDetails?.maritalStatus
                    ? {
                        value: customerDetails?.maritalStatus,
                        label: customerDetails?.maritalStatus,
                      }
                    : ``
                  driver.relation = { value: `INSURED`, label: `INSURED` }
                  driver.education = {
                    educationLevel: customerDetails?.educationLevel
                      ? {
                          value: customerDetails?.educationLevel,
                          label: customerDetails?.educationLevel,
                        }
                      : ``,
                  }
                }
                return driver
              }),
            )

            firstName.current.value = customerDetails?.firstName
            lastName.current.value = customerDetails?.lastName
            middleName.current.value = customerDetails?.middleName ?? ``
            email.current.value = customerDetails?.contactInfo?.email ?? ``
            street.current.value = customerDetails?.address?.street ?? ``

            if (customerDetails?.firstName) {
              driverFirstNameRef.current[0].value =
                customerDetails?.firstName ?? ``
              driverMiddleNameRef.current[0].value =
                customerDetails?.middleName ?? ``
              driverLastNameRef.current[0].value =
                customerDetails?.lastName ?? ``
            }
          }
        }),
      {
        refetchOnWindowFocus: false,
        cacheTime: 1,
      },
    )
  const {
    mutate: getCustomerDetailsWithPhone,
    isLoading: loadingCustomerDetailsWithPhone,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/customers/fetch-customer`,
        { ...payload },
      ).then((res) => {
        if (res) {
          let customerDetails = res.data.data.customer

          handleCustomerChange(
            `phone`,
            customerDetails?.contactInfo?.phone ?? ``,
          )
          handleCustomerChange(
            `workPhone`,
            customerDetails?.contactInfo?.workPhone ?? ``,
          )
          handleCustomerChange(
            `workPhoneExt`,
            customerDetails?.contactInfo?.workPhoneExt ?? ``,
          )
          handleCustomerChange(`fax`, customerDetails?.contactInfo?.fax ?? ``)

          setAddress({
            unitNo: customerDetails?.address?.unitNo ?? ``,
            city: customerDetails?.address?.city ?? ``,
            zip: customerDetails?.address?.zip
              ? {
                  value: customerDetails?.address?.zip,
                  label: customerDetails?.address?.zip,
                }
              : ``,
            state: customerDetails?.address?.state ?? ``,
          })

          setDrivers((prev) =>
            prev.map((driver, index) => {
              if (index === 0) {
                driver.gender = customerDetails?.gender
                  ? {
                      value: customerDetails?.gender,
                      label: customerDetails?.gender,
                    }
                  : ``
                driver.maritalStatus = customerDetails?.maritalStatus
                  ? {
                      value: customerDetails?.maritalStatus,
                      label: customerDetails?.maritalStatus,
                    }
                  : ``
                driver.relation = { value: `INSURED`, label: `INSURED` }
                driver.education = {
                  educationLevel: customerDetails?.educationLevel
                    ? {
                        value: customerDetails?.educationLevel,
                        label: customerDetails?.educationLevel,
                      }
                    : ``,
                }
              }
              return driver
            }),
          )

          firstName.current.value = customerDetails?.firstName
          lastName.current.value = customerDetails?.lastName
          middleName.current.value = customerDetails?.middleName ?? ``
          email.current.value = customerDetails?.contactInfo?.email ?? ``
          street.current.value = customerDetails?.address?.street ?? ``

          if (customerDetails?.firstName) {
            driverFirstNameRef.current[0].value =
              customerDetails?.firstName ?? ``
            driverMiddleNameRef.current[0].value =
              customerDetails?.middleName ?? ``
            driverLastNameRef.current[0].value = customerDetails?.lastName ?? ``
          }
        }
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  useEffect(() => {
    if (customer?.phone?.length === 12 && !customer?.customerId?.value) {
      getCustomerDetailsWithPhone({ contactInfo: { phone: customer?.phone } })
    }
  }, [customer?.phone])

  useEffect(() => {
    if (customer?.customerId?.value) {
      getCustomerDetails(customer?.customerId?.value)
    }
  }, [customer?.customerId?.value])

  useEffect(() => {
    let driversValidated = []
    let vehiclesValidated = []

    driversValidated = drivers?.map((driver) => {
      if (
        errors.formSubmit &&
        !driver.firstNameError?.length &&
        !driver.lastNameError?.length &&
        !driver.dobError?.length &&
        !driver.genderError?.length &&
        !driver.maritalStatusError?.length &&
        !driver.relationError?.length &&
        !driver.licensedError?.length &&
        !driver.expireDateError?.length
      ) {
        return true
      }
    })
    vehiclesValidated = vehicles?.map((vehicle) => {
      if (
        !vehicle.vinError?.length &&
        !vehicle.yearError?.length &&
        !vehicle.makeError?.length &&
        !vehicle.purchaseDateError?.length &&
        !vehicle.modelError?.length
      ) {
        return true
      }
    })

    if (
      errors.formSubmit &&
      !errors.emailError.length &&
      !errors.firstNameError.length &&
      !errors.lastNameError.length &&
      !errors.phoneError.length &&
      !errors.streetNoError.length &&
      !errors.zipError.length &&
      !errors.workPhoneError.length &&
      !errors.faxError.length &&
      !errors.workPhoneExtError?.length &&
      !errors.effectiveDateError?.length &&
      !errors.expireDateError?.length &&
      !errors.policyTermError?.length &&
      !errors.carrierError?.length
    ) {
      if (
        !driversValidated?.includes(undefined) &&
        !vehiclesValidated?.includes(undefined)
      ) {
        mutate({
          ...preparedCustomerPayload(),
          policyData: {
            expiryDate: customer.expireDate,
            effectiveDate: customer.effectiveDate,
            policyTerm: customer?.policyTerm?.value,
            drivers: prepareDriversPayload(),
            cars: preparedVehiclePayload(),
          },
        })
        handleErrors(`formSubmit`, false)
      }
    }
  }, [errors])
  return (
    <Container>
      {gotVinNumbers !== false && (
        <ReactModal
          title="Vin Numbers"
          closeModal={() => setGotVinNumbers(false)}
          width="3/4"
        >
          <div className="flex w-full text-center p-2 font-md text-md">
            <div className="flex-1">Vin</div>
            <div className="flex-1">Type</div>
            <div className="flex-1">Feul</div>
            <div className="flex-1">Cylinders</div>
            <div className="flex-1">Ton Rating</div>
            <div className="flex-1">MSRP</div>
            <div className="flex-1"></div>
          </div>

          {vinNumbersData?.map((vinNumber, index) => (
            <div
              className="flex w-full text-center p-2 text-sm border-t-1"
              key={index}
            >
              <div className="flex-1">{vinNumber.vin}</div>
              <div className="flex-1">{vinNumber.vehicleType}</div>
              <div className="flex-1">{vinNumber.fuelType}</div>
              <div className="flex-1">{vinNumber.cylinders}</div>
              <div className="flex-1">{vinNumber.truckSize}</div>
              <div className="flex-1">{vinNumber.msrp}</div>
              <div className="flex-1">
                <span
                  className="px-2 py-1 bg-blue-500 rounded-md text-white cursor-pointer"
                  onClick={() => {
                    handleVinNumber(`vin`, vinNumber.vin, gotVinNumbers)
                    setGotVinNumbers(false)
                  }}
                >
                  Add
                </span>
              </div>
            </div>
          ))}
        </ReactModal>
      )}
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <PageHeader padding="true">Add Policy</PageHeader>
      <Spinner
        loading={
          isLoading || loadingCustomerDetailsWithPhone || loadingCustomerDetails
        }
      />
      <div
        className={`grid px-4 py-1 gap-y-2 ${
          (isLoading ||
            loadingCustomerDetailsWithPhone ||
            loadingCustomerDetails) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        <div className="grid grid-cols-4 gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Carrier *"
              placeholder="Select Carrier"
              value={customer?.carrier}
              errorMessage={errors.carrierError}
              setValue={(value) => handleCustomerChange(`carrier`, value)}
              isMulti={false}
              loadOptions={loadCarrier}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              placeholder="Policy Number"
              type="text"
              title="Policy Number *"
              value={policyNo}
              setValue={(e) => handlePolicyNo(e.target.value)}
              errorMessage={errors?.policyNoError}
              validating={validatingPolicyNo}
            />
          </div>
          <div className="flex-1 relative">
            <InputFieldRef
              placeholder="Total Premium"
              type="number"
              min="1"
              title="Total Premium *"
              value={totalPremium?.current?.value}
              inputRef={totalPremium}
              errorMessage={errors?.totalPremiumError}
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="Policy Term *"
              placeholder="Select Policy Term"
              value={customer?.policyTerm}
              errorMessage={errors?.policyTermError}
              setValue={(value) => handleCustomerChange(`policyTerm`, value)}
              isMulti={false}
              loadOptions={loadPolicyTerm}
              isSearchable={true}
            />
          </div>

          <div className="flex-1 relative">
            <DatePickerField
              title="Effective Date *"
              placeholder="Enter Date"
              value={customer?.effectiveDate}
              errorMessage={errors.effectiveDateError}
              setValue={(date) => handleCustomerChange(`effectiveDate`, date)}
              minDate={new Date()}
            />
          </div>
          <div className="flex-1 relative">
            <DatePickerField
              title="Expiration Date *"
              placeholder="Enter Date"
              value={customer?.expireDate}
              errorMessage={errors.expireDateError}
              setValue={(date) => handleCustomerChange(`expireDate`, date)}
              minDate={
                new Date(moment(customer?.effectiveDate ?? ``).add(30, `days`))
              }
              disabled={!customer?.effectiveDate}
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="Customer"
              placeholder="Select Customer"
              value={customer?.customerId}
              // errorMessage={errors.customerIdError}
              setValue={(value) => handleCustomerChange(`customerId`, value)}
              isMulti={false}
              loadOptions={loadCustomers}
              isSearchable={true}
            />
          </div>
        </div>
        <div>
          <CollapsableRef title="Info">
            <div className="flex gap-6 mt-2 text-xs">
              <div className="flex-1 relative">
                <InputFieldRef
                  placeholder="First Name"
                  type="text"
                  title="First Name *"
                  inputRef={firstName}
                  value={firstName?.current?.value}
                  errorMessage={errors?.firstNameError}
                  checkFocus={true}
                  setFocusOut={(value) => setOutOfFocus(value)}
                />
              </div>
              <div className="flex-1">
                <InputFieldRef
                  placeholder="Middle Name"
                  type="text"
                  title="Middle Name"
                  inputRef={middleName}
                  value={middleName?.current?.value}
                  checkFocus={true}
                  setFocusOut={(value) => setOutOfFocus(value)}
                />
              </div>

              <div className="flex-1 relative">
                <InputFieldRef
                  placeholder="Last Name"
                  type="text"
                  title="Last Name *"
                  inputRef={lastName}
                  value={lastName?.current?.value}
                  errorMessage={errors?.lastNameError}
                  checkFocus={true}
                  setFocusOut={(value) => setOutOfFocus(value)}
                />
              </div>

              <div className="flex-1 relative">
                <InputFieldRef
                  title="Email"
                  placeholder="Email"
                  errorMessage={errors?.emailError}
                  type="text"
                  inputRef={email}
                />
              </div>
            </div>

            <div className="flex gap-6 mt-2 text-xs">
              <div className="flex-1 relative">
                <MaskField
                  type="text"
                  title="Phone *"
                  placeholder="Enter Phone No"
                  errorMessage={errors?.phoneError}
                  value={customer?.phone}
                  setValue={(e) => {
                    handleCustomerChange(`phone`, e.target.value)
                  }}
                  disabled={customer?.customerId?.value}
                  validating={loadingCustomerDetailsWithPhone}
                />
              </div>
              <div className="flex-1 relative">
                <MaskField
                  title="Work Phone"
                  placeholder="Work Phone"
                  errorMessage={errors?.workPhoneError}
                  type="text"
                  value={customer?.workPhone}
                  setValue={(e) => {
                    handleCustomerChange(`workPhone`, e.target.value)
                  }}
                />
              </div>

              <div className="flex-1 relative">
                <MaskField
                  mask="9999"
                  title="Work Phone Ext"
                  placeholder="Work Phone Ext"
                  errorMessage={errors?.workPhoneExtError}
                  type="text"
                  value={customer?.workPhoneExt}
                  setValue={(e) => {
                    handleCustomerChange(`workPhoneExt`, e.target.value)
                  }}
                />
              </div>

              <div className="flex-1 relative">
                <MaskField
                  title="Fax"
                  placeholder="Fax"
                  errorMessage={errors?.faxError}
                  type="text"
                  value={customer?.fax}
                  setValue={(e) => {
                    handleCustomerChange(`fax`, e.target.value)
                  }}
                />
              </div>
            </div>

            <SecondaryHeading>Address</SecondaryHeading>
            <div className="flex gap-6 mt-2 text-xs">
              <div className="flex-1 relative">
                <InputFieldRef
                  inputRef={street}
                  type="text"
                  title="Street *"
                  placeholder="Enter Street"
                  errorMessage={errors?.streetNoError}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Zip *"
                  placeholder="Select Zip"
                  errorMessage={errors?.zipError}
                  isMulti={false}
                  value={address.zip}
                  setValue={(e) => handleCustomerZipChange(e)}
                  loadOptions={loadZipCodes}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="Unit No"
                  placeholder="Enter No"
                  name="unitNo"
                  value={address?.unitNo}
                  setValue={(e) =>
                    handleAddressChange(`unitNo`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="City *"
                  placeholder="Enter City"
                  errorMessage={errors?.cityError}
                  disabled={true}
                  name="city"
                  value={address?.city}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="State *"
                  placeholder="Enter State"
                  errorMessage={errors?.stateError}
                  disabled={true}
                  name="state"
                  value={address?.state}
                />
              </div>
            </div>
          </CollapsableRef>
        </div>

        <div>
          <CollapsableRef title="Drivers">
            {/* tabs here */}
            <div className="flex items-center">
              <TabsHeaders
                openTab={activeDriverTab}
                tabs={dTabs}
                getActiveTab={(val) => setActiveDriverTab(val)}
                customTabs="YES"
              />
              <div className="px-2">
                <AddFormButton onClick={() => addDriver()}>
                  <FontAwesomeIcon icon={faPlus} />
                </AddFormButton>
              </div>
            </div>
            {/* tabs end herer */}

            <div className="grid grid-cols-1 gap-10">
              {drivers.length > 0 &&
                drivers?.map((driver, index) => (
                  <FormTab
                    key={index}
                    currenttab={index + 1}
                    opentab={activeDriverTab}
                    className="border-2 border-gray-100 shadow-sm rounded-md relative"
                  >
                    <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                      <div className="flex-1">Driver {index + 1}</div>
                      <div className="flex-1">
                        {index > 0 && (
                          <div className="float-right">
                            <RemoveDriver
                              onClick={() => {
                                handleRemoveDriver(index)
                                if (index > 0) setActiveDriverTab(index)
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} size="1x" />
                            </RemoveDriver>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-5 gap-x-6 gap-y-2 p-2">
                      <div className="flex-1 relative">
                        <InputFieldRef
                          placeholder="First Name"
                          type="text"
                          title="First Name *"
                          inputRef={(element) => {
                            driverFirstNameRef.current[index] = element
                          }}
                          errorMessage={driver?.firstNameError}
                          // disabled={!index}
                        />
                      </div>
                      <div className="flex-1 relative">
                        <InputFieldRef
                          placeholder="Middle Name"
                          type="text"
                          title="Middle Name"
                          inputRef={(element) => {
                            driverMiddleNameRef.current[index] = element
                          }}
                          // disabled={!index}
                        />
                      </div>
                      <div className="flex-1 relative">
                        <InputFieldRef
                          placeholder="Last Name"
                          type="text"
                          title="Last Name *"
                          errorMessage={driver?.lastNameError}
                          inputRef={(element) => {
                            driverLastNameRef.current[index] = element
                          }}
                          // disabled={!index}
                        />
                      </div>
                      <div className="flex-1 relative">
                        <DatePickerField
                          title="DOB *"
                          placeholder="DOB"
                          setValue={(e) => handleDriverChange(`dob`, e, index)}
                          value={driver.dob}
                          errorMessage={driver.dobError}
                          maxDate={new Date(moment().subtract(16, `years`))}

                          // maxDate={subYears(new Date(), 18)}
                        />
                      </div>
                      <div className=" relative">
                        <ReactSelectField
                          title="Gender *"
                          placeholder="Gender"
                          isMulti={false}
                          value={driver.gender}
                          errorMessage={driver.genderError}
                          setValue={(value) =>
                            handleDriverChange(`gender`, value, index)
                          }
                          loadOptions={loadGenderOptions}
                          isSearchable={true}
                        />
                      </div>
                      <div className=" relative">
                        <ReactSelectField
                          title="Marital Status"
                          placeholder="Marital Status"
                          isMulti={false}
                          value={driver.maritalStatus}
                          errorMessage={driver.maritalStatusError}
                          setValue={(value) =>
                            handleDriverChange(`maritalStatus`, value, index)
                          }
                          loadOptions={loadMaritalStatus}
                          isSearchable={true}
                        />
                      </div>

                      <div className=" relative">
                        <ReactSelectField
                          title="Relationship"
                          placeholder="Relationship"
                          isMulti={false}
                          value={driver.relation}
                          errorMessage={driver.relationError}
                          setValue={(value) =>
                            handleDriverChange(`relation`, value, index)
                          }
                          loadOptions={loadRelationship}
                          isSearchable={true}
                          disabled={!index}
                        />
                      </div>
                      <div className="relative">
                        <ReactSelectField
                          title="Education Level"
                          placeholder="Education Level"
                          isMulti={false}
                          value={driver?.education?.educationLevel}
                          setValue={(value) =>
                            handleDriverChange(
                              `educationLevel`,
                              value,
                              index,
                              `education`,
                            )
                          }
                          loadOptions={loadEducationLevelOptions}
                          isSearchable={true}
                        />
                      </div>
                      <div className=" relative">
                        <ReactSelectField
                          title="Licensed"
                          placeholder="Licensed"
                          isMulti={false}
                          value={driver.license?.isLicensed}
                          errorMessage={driver.licensedError}
                          setValue={(value) =>
                            handleDriverChange(
                              `isLicensed`,
                              value,
                              index,
                              `license`,
                            )
                          }
                          loadOptions={loadBinaryOptions}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      {driver?.license?.isLicensed?.value === `YES` && (
                        <>
                          <div className="pl-2 my-1">
                            <span className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              License
                            </span>
                          </div>
                          <div className="grid grid-cols-5 gap-x-6 gap-y-2 p-2">
                            <div className="relative">
                              <InputFieldRef
                                placeholder="License Number"
                                type="text"
                                title="License Number"
                                inputRef={(element) => {
                                  driverLisceneNoRef.current[index] = element
                                }}
                              />
                            </div>
                            <div className=" relative">
                              <ReactSelectField
                                title="Suspended"
                                placeholder="Suspended"
                                isMulti={false}
                                value={driver.license?.isSuspended}
                                setValue={(value) =>
                                  handleDriverChange(
                                    `isSuspended`,
                                    value,
                                    index,
                                    `license`,
                                  )
                                }
                                loadOptions={loadBinaryOptions}
                              />
                            </div>
                            {driver.license?.isSuspended?.value === `YES` && (
                              <div className="relative">
                                <DatePickerField
                                  title="Suspended Since"
                                  placeholder="Select Date"
                                  value={driver.license?.suspendedMonths}
                                  errorMessage={errors.dueDateError}
                                  setValue={(value) =>
                                    handleDriverChange(
                                      `suspendedMonths`,
                                      value,
                                      index,
                                      `license`,
                                    )
                                  }
                                  maxDate={new Date()}
                                />
                              </div>
                            )}
                            <div className="flex-1 relative">
                              <DatePickerField
                                title="U.S Licensed"
                                placeholder="U.S Licensed"
                                setValue={(e) =>
                                  handleDriverChange(
                                    `usLicensedMonths`,
                                    e,
                                    index,
                                    `license`,
                                  )
                                }
                                disabled={
                                  driver.license?.isSuspended?.value ===
                                    `YES` && !driver.license?.suspendedMonths
                                }
                                value={driver?.license?.usLicensedMonths}
                                maxDate={
                                  driver.license?.isSuspended?.value === `YES`
                                    ? driver.license?.suspendedMonths
                                    : new Date()
                                }
                              />
                            </div>
                            <div className="flex-1 relative">
                              <DatePickerField
                                title="State Licensed"
                                placeholder="State Licensed"
                                setValue={(e) =>
                                  handleDriverChange(
                                    `stateLicensedMonths`,
                                    e,
                                    index,
                                    `license`,
                                  )
                                }
                                disabled={
                                  driver.license?.isSuspended?.value ===
                                    `YES` && !driver.license?.suspendedMonths
                                }
                                value={driver?.license?.stateLicensedMonths}
                                maxDate={
                                  driver.license?.isSuspended?.value === `YES`
                                    ? driver.license?.suspendedMonths
                                    : new Date()
                                }
                              />
                            </div>
                            <div className="flex-1 relative">
                              <DatePickerField
                                title="MVR Experience U.S."
                                placeholder="MVR Experience U.S."
                                setValue={(e) =>
                                  handleDriverChange(
                                    `usMVRExperienceMonths`,
                                    e,
                                    index,
                                    `license`,
                                  )
                                }
                                disabled={
                                  driver.license?.isSuspended?.value ===
                                    `YES` && !driver.license?.suspendedMonths
                                }
                                value={driver.license?.usMVRExperienceMonths}
                                maxDate={
                                  driver.license?.isSuspended?.value === `YES`
                                    ? driver.license?.suspendedMonths
                                    : new Date()
                                }
                              />
                            </div>
                            <div className="relative">
                              <ReactSelectField
                                title="Foreign Licensed"
                                placeholder="Foreign Licensed"
                                isMulti={false}
                                value={driver?.license?.isForeignLicensed}
                                setValue={(value) =>
                                  handleDriverChange(
                                    `isForeignLicensed`,
                                    value,
                                    index,
                                    `license`,
                                  )
                                }
                                loadOptions={loadBinaryOptions}
                                isSearchable={true}
                              />
                            </div>
                            <div className="relative">
                              <ReactSelectField
                                title="Foreign License Type"
                                placeholder="Foreign License Type"
                                isMulti={false}
                                value={driver?.license?.foreignLicenseCountry}
                                setValue={(value) =>
                                  handleDriverChange(
                                    `foreignLicenseCountry`,
                                    value,
                                    index,
                                    `license`,
                                  )
                                }
                                loadOptions={
                                  driver?.license?.isForeignLicensed &&
                                  loadForeignLicensed
                                }
                                disabled={
                                  driver?.license?.isForeignLicensed?.value ===
                                  `NO`
                                }
                                isSearchable={true}
                              />
                            </div>
                            <div className="relative">
                              <DatePickerField
                                title="Foreign License Experience"
                                placeholder="Foreign License Experience"
                                disabled={
                                  driver?.license?.isForeignLicensed?.value ===
                                  `NO`
                                }
                                setValue={(e) =>
                                  handleDriverChange(
                                    `foreignLicensedMonths`,
                                    e,
                                    index,
                                    `license`,
                                  )
                                }
                                value={driver.license?.foreignLicensedMonths}
                                maxDate={new Date()}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </FormTab>
                ))}
            </div>
          </CollapsableRef>
        </div>
        <div>
          <CollapsableRef title="Vehicles">
            {/* tabs here */}
            <div className="flex items-center">
              <TabsHeaders
                openTab={activeVehicleTab}
                tabs={vTabs}
                getActiveTab={(val) => setActiveVehicleTab(val)}
                customTabs="YES"
              />

              {!vinLoading && (
                <div className="px-2">
                  <AddFormButton onClick={() => addVehicle()}>
                    <FontAwesomeIcon icon={faPlus} />
                  </AddFormButton>
                </div>
              )}
            </div>
            {/* tabs end herer */}

            <div className="grid grid-cols-1 gap-10">
              {vehicles.length > 0 &&
                vehicles?.map((vehicle, index) => (
                  <FormTab
                    key={index}
                    currenttab={index + 1}
                    opentab={activeVehicleTab}
                    className="border-2 border-gray-100 shadow-sm rounded-md relative"
                  >
                    <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                      <div className="flex-1">Vehicle {index + 1}</div>
                      <div className="flex-1">
                        <div className="float-right">
                          <RemoveDriver
                            onClick={() => {
                              handleRemoveVehicle(index)
                              if (index > 0) setActiveVehicleTab(index)
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                          </RemoveDriver>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-4 gap-x-6">
                      <div className="relative">
                        <ReactSelectField
                          title="Model Year *"
                          placeholder="Model Year"
                          isMulti={false}
                          value={vehicle?.modelYear}
                          errorMessage={vehicle?.modelYearError}
                          setValue={(value) =>
                            handleVehicleChange(`modelYear`, value, index)
                          }
                          loadOptions={loadMakeYears}
                          isSearchable={true}
                        />
                      </div>
                      <div className="relative">
                        <ReactSelectField
                          title="Make *"
                          placeholder="Make"
                          isMulti={false}
                          value={vehicle?.make}
                          errorMessage={vehicle?.makeError}
                          setValue={(value) =>
                            handleVehicleChange(`make`, value, index)
                          }
                          loadOptions={vehicle?.modelYear?.value && loadMakes}
                          additionalFilters={{
                            year: vehicle?.modelYear?.value,
                          }}
                          cacheUniqs={vehicle?.modelYear}
                          isSearchable={true}
                        />
                      </div>
                      <div className="relative">
                        <ReactSelectField
                          title="Model *"
                          placeholder="Model"
                          isMulti={false}
                          value={vehicle?.model}
                          errorMessage={vehicle?.modelError}
                          setValue={(value) =>
                            handleCarModel(`model`, value, index)
                          }
                          loadOptions={
                            vehicle?.modelYear && vehicle?.make && loadModel
                          }
                          additionalFilters={{
                            year: vehicle?.modelYear?.value,
                            make: vehicle?.make?.label,
                          }}
                          cacheUniqs={vehicle?.make}
                          isSearchable={true}
                        />
                      </div>
                      <div className="relative">
                        <InputField
                          title="VIN *"
                          type="text"
                          name="vin"
                          placeholder="Enter Vin"
                          value={vehicle?.vin}
                          validating={vinLoading || validateModel}
                          errorMessage={vehicle?.vinError}
                          setValue={(e) =>
                            handleVinNumber(`vin`, e.target.value, index)
                          }
                        />
                      </div>
                      <div className="relative">
                        <DatePickerField
                          title="Purchase Date *"
                          placeholder="Purchase Date"
                          value={
                            vehicle?.purchaseDate !== ``
                              ? new Date(vehicle?.purchaseDate)
                              : ``
                          }
                          errorMessage={vehicle?.purchaseDateError}
                          maxDate={new Date(moment().subtract(1, `week`))}
                          setValue={(e) => {
                            const date = e === null ? `` : e
                            handleVehicleChange(`purchaseDate`, date, index)
                          }}
                        />
                      </div>
                    </div>
                  </FormTab>
                ))}
            </div>
          </CollapsableRef>
        </div>

        {/* important dates */}

        <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
          <SubmitButton
            onClick={validateFrom}
            disabled={vinLoading || validateModel || validatingPolicyNo}
          >
            Add Policy
          </SubmitButton>
        </div>
      </div>
    </Container>
  )
}
