import {
  AMS_BACKEND_API,
  AxiosInstance,
  allowedSystemPermissions,
} from 'App/Config'
import { StyledButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { NotificationManager } from 'react-notifications'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { loadAgents, loadFranchises, permissionGranted } from 'App/Services'

export const UpdateCategory = ({ categoryId, closeModal, categoryUpdated }) => {
  const [currentCategory, setCurrentCategory] = useState(``)
  const [categories, setCategories] = useState({
    agent: [],
    name: ``,
    franchise: ``,
    location: ``,
  })

  const [errors, setErrors] = useState({
    agentError: ``,
    nameError: ``,
    franchiseError: ``,
    locationError: ``,
  })
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)

  const {
    user: {
      profile: { _locations },
    },
  } = useSelector(({ user }) => user)

  const loadAgentLocations = () => {
    const options = _locations.map((item) => {
      return { value: item._id, label: item.name }
    })

    return {
      options,
      // hasMore: false,
    }
  }

  const loadLocations = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        categories?.franchise.value
          ? categories?.franchise.value
          : _franchise?._id
      }/locations/list/?search=${search && search}&page=${page}&offset=10`,
    )
    if (data) {
      return {
        options: data.locations,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  // const loadAgents = async (search, loadedOptions, { page }) => {
  //   try {
  //     const {
  //       data: { data },
  //     } = await AxiosInstance.post(
  //       `${AMS_BACKEND_API}/api/users/v2/list/?search={"query":"${search.length ? search : ``
  //       }"}&page=${page}&offset=10`,
  //       {
  //         franchiseId: categories?.franchise?.value ? categories?.franchise?.value : _franchise?._id,
  //         locations: [categories?.location?.value],
  //         permissions: allowedSystemPermissions.ticket
  //       },)

  //     if (data) {
  //       return {
  //         options: data.agents,
  //         hasMore: data.totalPages - page > 0,
  //         additional: {
  //           page: page + 1,
  //         },
  //       }
  //     }
  //   }
  //   catch (error) {
  //     if (error.response?.data?.hasError) {
  //       return {
  //         options: [],
  //         hasMore: false,
  //         additional: {
  //           page: page,
  //         },
  //       }
  //     }
  //   }

  // }

  const {
    mutate,
    isLoading,
    // isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/ticket-category/${categoryId}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          if (res.data) {
            NotificationManager.success(`Updated Successfully`)
            closeModal()
            categoryUpdated()
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            } else if (
              error.response.data.error.error.includes(
                `Category update failed due to duplicate categoryName`,
              )
            ) {
              NotificationManager.error(`Duplicate Category Name`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const {
    // data: categoryPriviewData,
    refetch: categoryPreview,
    isLoading: catgoryLoading,
  } = useQuery(
    `categoryPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/ticket-category/${categoryId}`,
      ).then((res) => {
        return res?.data?.data?.category
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCurrentCategory(res)
      },
    },
  )
  useEffect(() => {
    categoryPreview()
  }, [])

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.franchiseError.length &&
      !errors.locationError.length &&
      !errors.agentError.length
    ) {
      mutate(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  useEffect(() => {
    if (currentCategory) {
      setCategories((preState) => ({
        ...preState,
        [`agent`]: currentCategory?.users.map((agent) => ({
          value: agent?.value,
          label: agent?.label,
        })),
        [`name`]: currentCategory?.categoryName,
        [`franchise`]: currentCategory?.franchises,
        [`location`]: currentCategory?._location,
      }))
    }
  }, [currentCategory])

  const updatePayload = () => {
    let data = {}
    if (categories?.name !== currentCategory?.categoryName) {
      data.categoryName = categories?.name
    }

    let isAgents
    if (categories?.agent?.length === currentCategory.users.length) {
      isAgents = categories?.agent.some((eachSystemRole, index) => {
        return eachSystemRole.value !== currentCategory?.users[index]
      })
    } else {
      isAgents = true
    }
    if (isAgents) {
      data.assignTo = categories?.agent?.map((agent) => agent.value)
    }
    if (
      categories?.franchise?.value?.length &&
      categories?.franchise?.value !== currentCategory?.franchises?.value
    ) {
      data._franchise = categories?.franchise?.value
    }
    //  else {
    //   data._franchise = _franchise?._id
    // }
    if (
      categories?.location?.value?.length &&
      categories?.location?.value !== currentCategory?._location?.value
    ) {
      data._location = categories?.location?.value
    }
    return data
  }

  const validateForm = () => {
    if (!categories?.agent?.length) {
      handleErrors(`agentError`, `Select Agent`)
    } else {
      handleErrors(`agentError`, ``)
    }
    if (!categories?.name?.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }
    if (!categories?.location?.value?.length) {
      handleErrors(`locationError`, `Select Location`)
    } else {
      handleErrors(`locationError`, ``)
    }

    permissionGranted([`super-admin:*`]) && !categories?.franchise.value?.length
      ? handleErrors(`franchiseError`, `Select Franchise`)
      : handleErrors(`franchiseError`, ``)
    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  return (
    <>
      <div
        className={`px-4 bg-white ${
          catgoryLoading && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="flex flex-col">
          <div className="flex flex-col px-4">
            <div className="flex gap-4">
              <div className="flex-1 relative w-60">
                <InputField
                  type="text"
                  name="Name"
                  title="Name"
                  placeholder="Enter Name"
                  value={categories.name}
                  setValue={(e) => {
                    setCategories((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }}
                  errorMessage={errors.nameError}
                />
              </div>
              {permissionGranted([`super-admin:*`]) && (
                <div className="flex-1 relative w-60">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Franchise *"
                      placeholder="Select Franchise"
                      errorMessage={errors.franchiseError}
                      value={categories?.franchise}
                      setValue={(value) => {
                        setCategories((preState) => ({
                          ...preState,
                          franchise: value,
                          agent: [],
                          location: ``,
                        }))
                      }}
                      isMulti={false}
                      loadOptions={loadFranchises}
                      isSearchable={true}
                    />
                  </div>
                </div>
              )}
            </div>
            {catgoryLoading && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                className="flex justify-center mt-8"
              />
            )}
            <div className="flex gap-4">
              <div className="flex gap-4">
                <div className="flex-1 relative w-60 ">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Location *"
                      placeholder="Select Location"
                      errorMessage={errors.locationError}
                      value={categories?.location}
                      setValue={(value) => {
                        setCategories((preState) => ({
                          ...preState,
                          location: value,
                          agent: [],
                        }))
                      }}
                      isMulti={false}
                      loadOptions={
                        permissionGranted([`super-admin:*`])
                          ? loadLocations
                          : loadAgentLocations
                      }
                      isSearchable={true}
                      cacheUniqs={categories?.franchise}
                    />
                  </div>
                </div>
              </div>

              <div className="flex-1 relative w-60">
                <ReactSelectField
                  title="Assign To *"
                  placeholder="Select Agent"
                  errorMessage={errors.agentError}
                  value={categories?.agent?.map((item) => item)}
                  setValue={(value) => {
                    setCategories((prevState) => ({
                      ...prevState,
                      agent: value,
                    }))
                  }}
                  isMulti={true}
                  loadOptions={categories?.location?.value ? loadAgents : ``}
                  isSearchable={true}
                  disabled={false}
                  cacheUniqs={categories?.location}
                  additionalFilters={{
                    franchiseId: categories?.franchise?.value
                      ? categories?.franchise?.value
                      : _franchise?._id,
                    locations: [categories?.location?.value],
                    permissions: allowedSystemPermissions.ticket,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pb-2 px-4 text-right">
            <StyledButton onClick={validateForm}>
              <div className="flex gap-2 text-white">
                <span>Update</span>
                {isLoading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    className="my-auto"
                  />
                )}
              </div>
            </StyledButton>
          </div>
        </div>
      </div>
    </>
  )
}
