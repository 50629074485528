import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const ValidateDuplicateLocEmail = async (email) => {
  if (email) {
    return await AxiosInstance.post(
      `${AMS_BACKEND_API}/api/validate/location-payloads`,
      {
        email: email,
      },
    )
      .then((res) => {
        return res?.data
      })
      .catch((error) => {
        return error?.response?.data
      })
  }
}
