import React, { useState } from 'react'

export const ReadMoreText = ({ text, textLength }) => {
  const [showFullText, setShowFullText] = useState(false)

  const toggleTextVisibility = () => {
    setShowFullText(!showFullText)
  }

  const truncatedText = showFullText ? text : text.slice(0, textLength ?? 150)
  const buttonText = showFullText ? `...read less` : `read more...`

  return (
    <div className="py-2">
      <span>{truncatedText}</span>
      {text.length > 150 && (
        <span
          onClick={toggleTextVisibility}
          className="ml-3 text-blue-500 cursor-pointer"
        >
          {buttonText}
        </span>
      )}
    </div>
  )
}
