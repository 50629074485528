import React, { createContext } from 'react'
import { Tabs } from './Tabs'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Header } from '../../Common/Header'
import { setCurrentPolicy } from 'App/Redux/actions'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const PolicyContext = createContext(null)

export const OtherPolicyPreview = () => {
  const {
    params: { policyId },
  } = useRouteMatch()
  const dispatch = useDispatch()

  const { isLoading, data, refetch, isFetching, error } = useQuery(
    `PolicyPreviewData`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/other-policies/${policyId}`,
      ).then((res) => {
        return res.data.data.policy
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentPolicy(res))
      },
    },
  )
  return (
    <PolicyContext.Provider value={{ isLoading, isFetching, refetch, error }}>
      <Header
        name={data?.policyNo}
        noImage={false}
        noBalance={true}
        isLoading={isLoading || isFetching}
      />

      <Tabs />
    </PolicyContext.Provider>
  )
}
