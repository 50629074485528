import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMutation } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { InputField } from 'App/Components/Common/InputField'
import { Image, Label, Container, PageHeader, SubmitButton } from 'App/Styled'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  ValidateDuplicateFranchise,
  fileResizer,
  loadZipCodes,
} from 'App/Services'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useDebouncedCallback } from 'use-debounce/lib'
import { defaultImage } from 'App/Assets'
import { NotificationManager } from 'react-notifications'

export const UpdateFranchise = () => {
  const [name, setName] = useState(``)
  const [currentFName, setCurrentFName] = useState(``)
  const [legacyId, setLegacyId] = useState(``)
  const [regions, setRegions] = useState(``)
  const [status, setStatus] = useState(``)

  const [logo, setLogo] = useState(``)
  const [errors, setErrors] = useState({
    formSubmit: false,
    logoError: ``,
    nameError: ``,
    regionsError: ``,
    statusError: ``,
  })

  const [validatingFName, setValidatingFName] = useState(false)

  const history = useHistory()
  const {
    params: { id },
  } = useRouteMatch()
  const { currentFranchise } = useSelector(({ franchise }) => franchise)
  const [isFormChanged, setIsFormChanged] = useState(false)
  useEffect(() => {
    if (currentFranchise) {
      const formattedRegions = currentFranchise?.regions?.map((region) => {
        return {
          value: region,
          label: region,
        }
      })

      setCurrentFName(currentFranchise?.name)
      setName(currentFranchise?.name)
      setLegacyId(currentFranchise?.legacyId)
      setRegions(formattedRegions)
      setLogo(currentFranchise?.logo)
      setStatus({
        value: currentFranchise?.status,
        label: currentFranchise?.status,
      })
    }
  }, [currentFranchise])

  const handleLogo = async (file) => {
    const base64 = await fileResizer(file[0])
    setLogo(base64)
  }

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(`${AMS_BACKEND_API}/api/franchises/${id}`, {
        ...payload,
      })
        .then(() => {
          NotificationManager.success(`Operation successful`)
          history.push(`/admin/franchises/${id}`)
        })
        .catch((error) => {
          if (
            error?.response?.data?.error?.error.includes(
              `Incoming request body can't be empty.`,
            )
          ) {
            setIsFormChanged(true)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  const checkRegion = (_eachRegion, index) => {
    return _eachRegion.value !== currentFranchise.regions[index]
  }

  const updatePayload = () => {
    let data = {}
    if (logo !== currentFranchise.logo) {
      data.logo = logo
    }

    if (name.trim() !== currentFranchise.name) {
      data.name = name
    }
    if (legacyId !== currentFranchise.legacyId) {
      data.legacyId = legacyId
    }
    if (status.value !== currentFranchise.status) {
      data.status = status.value
    }
    // Commission

    // if (
    //   commission.type.value !== currentFranchise?.commission?.type ||
    //   commission.rate.toString() !==
    //     currentFranchise?.commission?.rate.toString()
    // ) {
    //   data.commission = {}
    //   if (commission.type.value !== currentFranchise?.commission?.type) {
    //     data.commission.type = commission.type.value
    //   }
    //   if (
    //     commission.rate.toString() !==
    //     currentFranchise?.commission?.rate.toString()
    //   ) {
    //     data.commission.rate = commission.rate
    //   }
    // }

    let isUpdatedRegion
    if (regions?.length === currentFranchise?.regions?.length) {
      isUpdatedRegion = regions.some(checkRegion)
    } else {
      isUpdatedRegion = true
    }

    if (isUpdatedRegion) {
      data.regions = regions.map((reg) => reg.value)
    }
    setIsFormChanged(false)
    return data
  }

  const updateFranchise = () => {
    if (!name?.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else if (
      name.length &&
      errors.nameError.includes(`Franchise Name Already Exists`)
    ) {
      handleErrors(`nameError`, `Franchise Name Already Exists`)
    } else {
      handleErrors(`nameError`, ``)
    }

    if (!status.length && !status.value) {
      handleErrors(`statusError`, `Select Status`)
    } else {
      handleErrors(`statusError`, ``)
    }
    handleErrors(`formSubmit`, true)
  }

  const loadOptions = () => {
    const options = {
      options: [
        { value: `ACTIVE`, label: `ACTIVE` },
        { value: `INACTIVE`, label: `INACTIVE` },
      ],
      hasMore: false,
    }
    return options
  }

  const handleNameInput = async (value) => {
    setName(value)
    if (value.trim() !== currentFName && value) {
      validateName(value)
    }
  }

  const validateName = useDebouncedCallback(async (value) => {
    if (value) {
      setValidatingFName(true)
      const response = await ValidateDuplicateFranchise(`name`, value)
      if (response?.hasError) {
        setValidatingFName(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`nameError`, `Franchise Name Already Exists`)
      } else {
        setValidatingFName(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`nameError`, ``)
      }
    }
  }, 2500)

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.regionsError.length &&
      !errors.statusError.length
    ) {
      mutate(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])
  return (
    <Container className="relative">
      {isFormChanged && (
        <ReactNotification action="error" message="No Field Changed" />
      )}
      <PageHeader>
        <div>Update Franchises</div>
      </PageHeader>
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div className={`${isLoading && `opacity-30 pointer-events-none`}`}>
        <div className="p-4">
          <div className="flex justify-center items-center my-0 py-3">
            <div className="w-50">
              <Label>
                <Image alt="fImage" src={logo ? logo : defaultImage} />

                <input
                  type="file"
                  onChange={(e) => handleLogo(e.target.files)}
                  className="hidden"
                  accept="image/*"
                />
                {errors.logoError && (
                  <p className="text-sm text-red-500 mb-1">
                    {errors.logoError}
                    <span className="text-red-500 required-dot">*</span>
                  </p>
                )}
              </Label>
            </div>
          </div>

          <div className="flex gap-6 py-3">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Name *"
                placeholder="Enter Name"
                errorMessage={errors.nameError}
                value={name}
                validating={validatingFName}
                setValue={(e) => handleNameInput(e.target.value)}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Status *"
                placeholder="Select Status"
                errorMessage={errors.statusError}
                value={status}
                setValue={(e) => setStatus(e)}
                isMulti={false}
                loadOptions={loadOptions}
                isSearchable={true}
              />
            </div>
            {/* <div className="flex-1 relative">
              <InputField
                type="text"
                title="Legacy ID"
                placeholder="Enter Legacy ID"
                value={legacyId}
                setValue={(e) => setLegacyId(e.target.value)}
              />
            </div> */}
          </div>
          <div className="flex gap-6 py-3">
            {currentFranchise?.name !== `US OFFICES` && (
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Regions *"
                  placeholder="Select Regions"
                  errorMessage={errors.regionsError}
                  value={regions}
                  setValue={(e) => setRegions(e)}
                  isMulti={true}
                  isSearchable={true}
                  operatingState={
                    // show all zipcodes to US OFFIces franchise
                    currentFranchise?.name === `US OFFICES`
                      ? ``
                      : currentFranchise?.state
                  }
                  loadOptions={loadZipCodes}
                />
              </div>
            )}
            <div className="flex-1 relative"></div>
          </div>
          {/* <div className="flex gap-6 py-3">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Commission Type *"
                placeholder="Select Commission Type"
                errorMessage={errors.commissionTypeError}
                value={commission.type}
                setValue={(value) => {
                  setCommission((preState) => ({
                    ...preState,
                    [`type`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={LoadComissionTypes}
                isSearchable={false}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="number"
                title="Commission Rate *"
                placeholder="Enter Rate"
                value={commission.rate}
                errorMessage={errors.commissionRateError}
                setValue={(e) => {
                  setCommission((preState) => ({
                    ...preState,
                    [`rate`]: e.target.value,
                  }))
                }}
              />
            </div>
          </div> */}
          <div className="text-center xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
            <SubmitButton onClick={updateFranchise}>
              Update Franchise
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
