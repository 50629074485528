import React, { useEffect, useState, Fragment } from 'react'
import { Toggle } from 'App/Components/Common/Toggle'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faPlusCircle,
  faSortUp,
  faSortDown,
  faTrash,
  faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
import { useSelector } from 'react-redux'
const tableColumns = [
  { label: `ID`, value: `_locationId` },
  { label: `Name`, value: `name` },
  { label: `Email`, value: `email` },
  { label: `Phone`, value: `phone` },
  { label: `Work Phone`, value: `workPhone` },
  { label: `Lobs`, value: `lobs` },
]

export const Locations = () => {
  const history = useHistory()
  const {
    params: { id },
    url,
  } = useRouteMatch()

  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)

  const [collapseIndex, setCollapseIndex] = useState(``)
  const [active, setActive] = useState(false)
  const [isMasterFrenchise, setIsMasterFrenchise] = useState(true)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const franchiseStatus = useSelector(
    ({ franchise }) => franchise?.currentFranchise?.status,
  )
  const {
    data: flocationsData,
    isLoading: isLoadedFrenchise,
    refetch: getAllfLocations,
    isFetching,
    error,
  } = useQuery(
    `FlocationsPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/franchises/${id}/locations?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }", "onlyMasterLocations": ${isMasterFrenchise}}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => setCustomSearching(false),
    },
  )

  const {
    mutate: deleteLocation,
    isLoading: deletingLocation,
    isError: deletionError,
  } = useMutation(
    async (lId) =>
      await AxiosInstance.delete(`${AMS_BACKEND_API}/api/locations/${lId}`)
        .then((res) => {
          res.data && getAllfLocations()
          NotificationManager.success(`Deleted Successfully`)
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response?.data?.error?.error?.includes(
                `Unable to proceed. Location is assigned to some user(s) in database.`,
              )
            ) {
              NotificationManager.error(
                `Operation Fail, Please delete agent first`,
              )
            } else if (
              error.response?.data?.error?.error?.includes(
                `Operation not allowed.`,
              )
            ) {
              NotificationManager.error(`Operation not allowed`)
            }
          }
          // NotificationManager.error(`Operation Fail, Please delete agent first`)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  useEffect(() => {
    if (!customSearching) {
      getAllfLocations()
    }
  }, [isMasterFrenchise, sort, currentPage, isOffset])

  const callFlocationsQuery = useDebouncedCallback(() => {
    getAllfLocations()
  }, 700)

  const handleDelete = (location, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    !location?.isMasterLocation && deleteLocation(location._id)
  }

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!active) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }

  return (
    <Container>
      <Spinner
        className="absolute"
        loading={deletingLocation || isLoadedFrenchise || isFetching}
      />
      <div className="w-full  ">
        {deletionError && (
          <ReactNotification action="error" message="Something went wrong" />
        )}
        <PageHeader>
          <div>Locations</div>
          <div className="flex items-center ">
            <div className="py-1 px-2">
              <ReactSelectField
                placeholder="10"
                value={isOffset}
                setValue={(e) => {
                  setCurrentPage(1)
                  setIsOffset(e)
                }}
                isMulti={false}
                loadOptions={loadOffset}
              />
            </div>
            <Toggle
              value={!isMasterFrenchise}
              setValue={() => {
                setCurrentPage(1)
                setIsMasterFrenchise(!isMasterFrenchise)
              }}
            />
            <div className="text-end">
              <SearchInputField
                type="text"
                placeholder="Type here..."
                value={searchQuery}
                setValue={(e) => {
                  setSearchQuery(e.target.value)
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callFlocationsQuery()
                }}
              />
            </div>
          </div>
        </PageHeader>
        {permissionGranted([`corporate-manager:*`]) && (
          <div className="flex justify-end items-center p-2">
            <StyledButton
              onClick={() => history.push(`${url}/location/add`)}
              disabled={franchiseStatus !== `INACTIVE` ? false : true}
            >
              <div className="flex gap-2 text-white">
                <span>Add </span>
                <FontAwesomeIcon icon={faPlusCircle} size="lg" />
              </div>
            </StyledButton>
          </div>
        )}

        <div
          className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
            (isLoadedFrenchise || deletingLocation || isFetching) &&
            ` opacity-30 pointer-events-none`
          } `}
        >
          {error ? (
            <Reload refetch={() => getAllfLocations()} />
          ) : (
            <>
              <div className="overflow-x-auto  rounded-t-md">
                <table className="min-w-full leading-normal ">
                  <TableHeader>
                    <TableRow>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className="cursor-pointer select-none text-sm flex">
                              <div
                                onClick={() => {
                                  handleSorting(item.value)
                                }}
                                className={`flex ${
                                  item.value === sort ? `text-red-500` : ``
                                }`}
                              >
                                {item.label}
                              </div>
                              <div
                                className="ml-2 px-1 rounded-sm cursor-pointer flex"
                                onClick={() => handleSorting(`-` + item?.value)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    sort.includes(`-`) &&
                                    sort == `-` + item?.value
                                      ? faSortUp
                                      : faSortDown
                                  }
                                  size="lg"
                                  className={`${
                                    sort.includes(`-`) &&
                                    sort == `-` + item?.value
                                      ? `text-red-500`
                                      : `-mt-1.5`
                                  }`}
                                />
                              </div>
                            </div>
                          </TableCell>
                        )
                      })}
                      {permissionGranted([`corporate-manager:*`]) && (
                        <TableCell className="select-none text-sm float-right">
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {flocationsData?.locations?.length ? (
                      flocationsData?.locations?.map((location, index) => (
                        <Fragment key={index}>
                          <TableRow
                            key={index}
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              if (permissionGranted([`corporate-manager:*`])) {
                                history.push(`${url}/location/${location._id}`)
                              }
                            }}
                          >
                            <td className="px-4 py-0.5 text-sm truncate">
                              {location?._locationId ? (
                                location?._locationId
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 text-sm capitalize truncate">
                              {location.name?.toLowerCase() ? (
                                location.name?.toLowerCase()
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 text-sm">
                              {location?.email ? (
                                location?.email
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-5 py-0.5 text-sm">
                              {location?.phone ? (
                                location?.phone
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 text-sm">
                              {location?.workPhone ? (
                                location?.workPhone
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-5 py-0.5 text-sm">
                              {location.lobs.length ? (
                                location.lobs && (
                                  <Badge>{location.lobs[0]}</Badge>
                                )
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>

                            <td className="px-6 py-0.5 text-sm z-0 flex float-right text-gray-400">
                              {location.lobs.length > 1 ? (
                                <span data-tip="Show" className="tooltip">
                                  <FontAwesomeIcon
                                    className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                      collapseIndex === index &&
                                      `rotate-180 text-blue-600`
                                    }`}
                                    icon={faChevronCircleDown}
                                    onClick={(e) => {
                                      setActive(!active)
                                      handleCollapse(index, e)
                                    }}
                                  />
                                </span>
                              ) : (
                                ``
                              )}
                              {permissionGranted([`corporate-manager:*`]) && (
                                <span
                                  data-tip={`${
                                    !location?.isMasterLocation
                                      ? `Delete`
                                      : `Not Allowed`
                                  }`}
                                  className="tooltip"
                                >
                                  <FontAwesomeIcon
                                    className={`mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105  ${
                                      !location?.isMasterLocation
                                        ? `cursor-pointer`
                                        : `cursor-not-allowed`
                                    }`}
                                    icon={faTrash}
                                    size="1x"
                                    onClick={(e) => handleDelete(location, e)}
                                  />
                                </span>
                              )}
                            </td>
                          </TableRow>
                          {collapseIndex === index && (
                            <TableRow>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="px-6 py-0.5 z-0 max-w-sm">
                                {location.lobs.length > 1 &&
                                  location.lobs?.map((lob, idx) => (
                                    <Badge key={idx} className="ml-1">
                                      {lob}
                                    </Badge>
                                  ))}
                              </td>
                            </TableRow>
                          )}
                        </Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="12" className=" text-center p-6">
                          <div>No Location Found</div>
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={flocationsData?.totalPages}
                  totalRecords={flocationsData?.totalRecords}
                  curerntPageRecords={flocationsData?.currentPageRecords}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  )
}
