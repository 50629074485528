import { Collapsable } from 'App/Components/Common/Collapsable'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  loadBinaryOptions,
  loadLanguages,
  loadLeadSource,
  loadPreferredContact,
  loadQuoteDescription,
} from 'App/Services/quoteForm'

import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useEffect } from 'react'

import { useDebouncedCallback } from 'use-debounce/lib'

export const QuoteDetails = forwardRef(({ getData, carrierQuestion }, ref) => {
  const [quoteDetail, setQuoteDetail] = useState({
    contactMethod: { value: `SMS`, label: `SMS` },
    preferredContact: [
      { value: `SMS`, label: `SMS` },
      { value: `CALL`, label: `CALL` },
    ],
    leadSource: { value: `WALK_IN`, label: `Walk-in` },
    acquisitionBy: { value: `CALL`, label: ` Call` },
    quoteDescription: { value: `NEW_BUSINESS`, label: `New Business` },
    nativeLanguage: { value: `ENGLISH`, label: ` English` },
    paperlessDiscount: { value: `NO`, label: ` NO` },
  })

  const [errors, setErrors] = useState({
    contactMethodError: ``,
    preferredContactError: ``,
    leadSourceError: ``,
    quoteDescriptionError: ``,
    acquisitionByError: ``,
    paperlessDiscountError: ``,
    nativeLanguageError: ``,
    formSubmit: false,
  })

  const [carrierQuestions, setCarrierQuestions] = useState({})

  useImperativeHandle(ref, () => ({
    validateForm() {
      if (!quoteDetail.contactMethod?.value?.length) {
        handleErrors(`contactMethodError`, `Select Contact Method`)
      } else {
        handleErrors(`contactMethodError`, ``)
      }
      if (!quoteDetail.preferredContact?.length) {
        handleErrors(`preferredContactError`, `Select Preferred Contact`)
      } else {
        handleErrors(`preferredContactError`, ``)
      }
      if (!quoteDetail.leadSource?.value?.length) {
        handleErrors(`leadSourceError`, `Select Lead Source`)
      } else {
        handleErrors(`leadSourceError`, ``)
      }
      if (!quoteDetail.acquisitionBy?.value?.length) {
        handleErrors(`acquisitionByError`, `Select Quote Acquisition`)
      } else {
        handleErrors(`acquisitionByError`, ``)
      }
      if (!quoteDetail.paperlessDiscount?.value?.length) {
        handleErrors(`paperlessDiscountError`, `Select Paperless Discount`)
      } else {
        handleErrors(`paperlessDiscountError`, ``)
      }
      if (!quoteDetail.contactMethod?.value?.length) {
        handleErrors(`contactMethodError`, `Select Contact Method`)
      } else {
        handleErrors(`contactMethodError`, ``)
      }
      if (!quoteDetail.nativeLanguage?.value?.length) {
        handleErrors(`nativeLanguageError`, `Select Native Language`)
      } else {
        handleErrors(`nativeLanguageError`, ``)
      }
      if (!quoteDetail.quoteDescription?.value?.length) {
        handleErrors(`quoteDescriptionError`, `Select Quote Description`)
      } else {
        handleErrors(`quoteDescriptionError`, ``)
      }
      handleErrors(`formSubmit`, true)
    },
    updateFormValues(data) {
      // Setting Quote Details
      setQuoteDetail({
        contactMethod: {
          value: data?.contactMethod ?? ``,
          label: data?.contactMethod ?? ``,
        },
        preferredContact: data?.preferredContactMethods?.map((contact) => ({
          label: contact,
          value: contact,
        })),
        leadSource: {
          value: data?.leadSource ?? ``,
          label: data?.leadSource ?? ``,
        },
        acquisitionBy: {
          value: data?.acquisitionBy ?? ``,
          label: data?.acquisitionBy ?? ``,
        },
        quoteDescription: {
          value: data?.quoteDescription ?? ``,
          label: data?.quoteDescription ?? ``,
        },
        nativeLanguage: {
          value: data?.nativeLanguage ?? ``,
          label: data?.nativeLanguage ?? ``,
        },
        paperlessDiscount: {
          value: data?.paperlessDiscount ?? ``,
          label: data?.paperlessDiscount ?? ``,
        },
      })
    },
  }))

  const handleQuoteDetailChange = useDebouncedCallback((name, value) => {
    setQuoteDetail((preState) => ({
      ...preState,
      [name]: value,
    }))
  }, 20)

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const handleQuestionChange = (value, index, carrier, q) => {
    const newCarrierQuestions = {}
    for (let cQuestion in carrierQuestions) {
      newCarrierQuestions[cQuestion] = carrierQuestions[cQuestion].map(
        (item, ind) => {
          if (index === ind && carrier === cQuestion && q === item.question) {
            return {
              ...item,
              selectedValue: value,
            }
          } else {
            return item
          }
        },
      )
    }
    setCarrierQuestions(newCarrierQuestions)
  }

  useEffect(() => {
    setCarrierQuestions(carrierQuestion)
  }, [])

  useEffect(() => {
    setCarrierQuestions(carrierQuestion)
  }, [carrierQuestion])

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.quoteDescriptionError.length &&
      !errors.preferredContactError.length &&
      !errors.leadSourceError.length &&
      !errors.acquisitionByError.length &&
      !errors.paperlessDiscountError.length &&
      !errors.nativeLanguageError.length &&
      !errors.contactMethodError.length
    ) {
      getData(`quoteDetail`, { ...quoteDetail, questions: carrierQuestions })
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  return (
    <div className="">
      <div className="flex-1">
        <CollapsableRef title="Quote Details" isActive={true}>
          <div className="grid grid-cols-4 gap-x-6 gap-y-2">
            <div className=" relative">
              <ReactSelectField
                title="Contact Method *"
                placeholder="Contact Method"
                isMulti={false}
                value={quoteDetail.contactMethod}
                errorMessage={errors.contactMethodError}
                setValue={(e) => handleQuoteDetailChange(`contactMethod`, e)}
                loadOptions={loadPreferredContact}
                isSearchable={true}
              />
            </div>
            <div className=" relative">
              <ReactSelectField
                title="Preferred Contact *"
                placeholder="Preferred Contact"
                isMulti={true}
                value={quoteDetail.preferredContact}
                errorMessage={errors.preferredContactError}
                setValue={(e) => handleQuoteDetailChange(`preferredContact`, e)}
                loadOptions={loadPreferredContact}
                isSearchable={true}
              />
            </div>
            <div className=" relative">
              <ReactSelectField
                title="Lead Source *"
                placeholder="Lead Source"
                isMulti={false}
                value={quoteDetail.leadSource}
                errorMessage={errors.leadSourceError}
                setValue={(e) => handleQuoteDetailChange(`leadSource`, e)}
                loadOptions={loadLeadSource}
                isSearchable={true}
              />
            </div>
            <div className=" relative">
              <ReactSelectField
                title="Acquisition By *"
                placeholder="Acquisition By"
                isMulti={false}
                errorMessage={errors.acquisitionByError}
                value={quoteDetail.acquisitionBy}
                setValue={(e) => handleQuoteDetailChange(`acquisitionBy`, e)}
                loadOptions={loadPreferredContact}
                isSearchable={true}
              />
            </div>
            <div className=" relative">
              <ReactSelectField
                title="Quote Description *"
                placeholder="Quote Description"
                isMulti={false}
                value={quoteDetail.quoteDescription}
                errorMessage={errors.quoteDescriptionError}
                setValue={(e) => handleQuoteDetailChange(`quoteDescription`, e)}
                loadOptions={loadQuoteDescription}
                isSearchable={true}
              />
            </div>
            <div className=" relative">
              <ReactSelectField
                title="Native Language *"
                placeholder="Native Language"
                isMulti={false}
                value={quoteDetail.nativeLanguage}
                errorMessage={errors.nativeLanguageError}
                setValue={(e) => handleQuoteDetailChange(`nativeLanguage`, e)}
                loadOptions={loadLanguages}
                isSearchable={true}
              />
            </div>
            <div className=" relative">
              <ReactSelectField
                title="Paperless Discount *"
                placeholder="Paperless Discount"
                isMulti={false}
                value={quoteDetail.paperlessDiscount}
                errorMessage={errors.paperlessDiscountError}
                setValue={(e) =>
                  handleQuoteDetailChange(`paperlessDiscount`, e)
                }
                loadOptions={loadBinaryOptions}
                isSearchable={true}
              />
            </div>
          </div>
        </CollapsableRef>
      </div>
      <div className="mt-2">
        <Collapsable title="Carrier Questions">
          {carrierQuestions && Object.keys(carrierQuestions)?.length > 0 ? (
            Object.keys(carrierQuestions).map((carrier, index) => (
              <div className="" key={index}>
                <div className="pl-2 my-1">
                  <span className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                    {carrier}
                  </span>
                </div>

                <span className="grid grid-cols-2">
                  {carrierQuestions[carrier]?.map((q, index) => (
                    <div className="grid grid-cols-12 m-2" key={index}>
                      <div className="flex  items-center col-span-6">
                        {q?.question}
                      </div>
                      {!q?.mask && (
                        <div className="relative mx-4  col-span-6">
                          <ReactSelectField
                            // title="Car Type"
                            // placeholder="Car Type"
                            isMulti={false}
                            value={q?.selectedValue}
                            setValue={(value) =>
                              handleQuestionChange(
                                value,
                                index,
                                carrier,
                                q.question,
                              )
                            }
                            loadOptions={() => ({
                              options: q?.options,
                              hasMore: false,
                            })}
                            cacheUniqs={q?.options}
                            isSearchable={true}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </span>
              </div>
            ))
          ) : (
            <div className="text-center p-6">No carrier questions found</div>
          )}
        </Collapsable>
      </div>
    </div>
  )
})

QuoteDetails.displayName = `QuoteDetails`
