/* eslint-disable no-unused-vars */
import React from 'react'
import { Reload } from 'App/Components/Common/Reload'
import { Pdf } from './pdf'

export const QuoteOfferPdf = ({ quoteOfferedData }) => {
  return (
    <div>
      {Object.entries(quoteOfferedData).length === 0 ? (
        <Reload refetch={() => refetch()} />
      ) : Object.entries(quoteOfferedData).length > 0 ? (
        <div className="px-2">
          <Pdf quoteData={quoteOfferedData} />
        </div>
      ) : (
        <div className="text-center p-10">
          <p>No Data Found</p>
        </div>
      )}
    </div>
  )
}
