import React from 'react'
import { Container } from 'App/Styled'
import { AllCustomer } from './Customers'
import { GlobalMessage } from './Messages'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'

export const GlobalCommunications = () => {
  const { number } = useSelector(
    ({ globalCommunications }) => globalCommunications,
  )

  return (
    <div className="flex">
      <div className="w-1/4">
        <Container>
          <AllCustomer />
        </Container>
      </div>
      <div className="w-3/4">
        {number ? (
          <Container nomargin="true">
            <GlobalMessage />
          </Container>
        ) : (
          <div className="flex justify-center items-center h-88vh">
            <FontAwesomeIcon
              icon={faEnvelopeOpenText}
              size="3x"
              className="text-blue-500 cursor-pointer"
              title="Lets see who reached you?"
            />
          </div>
        )}
      </div>
    </div>
  )
}
