import React, { useEffect } from 'react'
import { useAuth } from 'oidc-react'
import { useHistory } from 'react-router-dom'
import Lottie from 'lottie-react'
import Mylogo from 'App/Assets/lotties/customLoader.json'
import { UserManager } from 'App/Config'
import { resetPersistantState } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'

export const OidcLogoutRedirectPage = () => {
  const oidcAuth = useAuth()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!oidcAuth?.userData) {
      dispatch(resetPersistantState())
      UserManager.revokeAccessToken()
      UserManager.clearStaleState()
      history.push(`/login`)
    } else {
      history.push(`/dashboard`)
    }
  }, [process.browser, UserManager])

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <Lottie
        animationData={Mylogo}
        loop={true}
        autoplay={true}
        className="h-40"
      />
      <p className="-bottom-44 text-xl font-bold capitalize">
        Logging Out, See You Soon
      </p>
    </div>
  )
}
