/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import { ContactForm } from '../ContactForm'
import { CoveragesForm } from '../CoveragesForm'
import { DriverForm } from '../Driver'
import { QuoteDetails } from '../QuoteDetails'
import { VehicleForm } from '../Vehicle'

import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Container, PageHeader, StyledButton, SubmitButton } from 'App/Styled'
import { ReactModal } from 'App/Components/Common/Modal'
import { AQS_BACKEND_API, AxiosInstance } from 'App/Config'
import { ReactTable } from 'App/Components/Raters/Preferences/ReactTable'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  setCommunicationCredentials,
  setCurrentSavedQuotes,
  setLeadData,
  setPreferedCarriers,
  setPreferences,
  setQuoteForm,
  setQuoteOffers,
  setRatingCarriers,
  setSelectedRatingTemplate,
  setTurboRaterUrl,
  setVehicleCoverage,
} from 'App/Redux/actions'
import { useMutation, useQuery } from 'react-query'

import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { QuoteCard } from '../QuoteCard'
import { Prompt } from 'react-router-dom'
import { QuoteOffers } from '../QuoteOffers'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { Loader } from 'App/Components/Common/Loader'
import { CarrierQuestions } from '../CarrierQuestions'
import { NotificationManager } from 'react-notifications'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const QuoteFormTabs = [
  {
    name: `Single Screen`,
    number: 1,
  },
  {
    name: `Client Contact Info`,
    number: 2,
  },
  {
    name: `General Info/Coverages`,
    number: 3,
  },
  {
    name: `Quote Details`,
    number: 4,
  },
  {
    name: `Drivers`,
    number: 5,
  },
  {
    name: `Vehicles`,
    number: 6,
  },
]

export const RerateQuoteForm = () => {
  const [tableModal, setTableModal] = useState(false)
  const [switchQuoteForm, setSwitchQuoteForm] = useState(false)

  const contactRef1 = useRef(null)
  const coveragesRef1 = useRef(null)
  const quoteDetailRef1 = useRef(null)
  const driverRef1 = useRef(null)
  const vehicleRef1 = useRef(null)

  const [data, setData] = useState({})
  const [selectedState, setSelectedState] = useState(``)
  const [isStateChange, setIsStateChange] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [templatesData, setTemplatesData] = useState([])
  const [policyScope, setPolicyScope] = useState([])
  const [vehicleScope, _setVehicleCoverage] = useState([])
  const [preferedCarriersSet, setPreferredCarrierSet] = useState(false)
  const [reRateQuote, setReRateQuote] = useState(false)
  const [stateLoading, setStateLoading] = useState(false)
  const [quoteOfferModel, setQuoteOfferModel] = useState(false)
  const [carrierQuestions, setCarrierQuestions] = useState(``)
  const [policyQuestions, setPolicyQuestions] = useState({})
  const [activeTab, setActiveTab] = useState(1)

  // const navigate = useNavigate()
  const allTimePreferedCarriers = useSelector(
    ({ preferences: { myPreferedCarriers } }) => myPreferedCarriers,
  )
  // const { ratingCarriers } = useSelector(({ quoteForm }) => quoteForm)
  const { currentQuote } = useSelector(({ myquotes }) => myquotes)

  const [nonOwner, setNonOwner] = useState({
    value: currentQuote?.policyData?.nonOwner,
    label: currentQuote?.policyData?.nonOwner,
  })

  const history = useHistory()

  const dispatch = useDispatch()

  // setting preferences data
  const preferenceData = currentQuote?.ratingData?.ratingPrefs

  // setting contact data
  const contactData = currentQuote?.policyData?._customer

  // setting quote details
  const quoteDetails = currentQuote?.metadata

  // setting quote details
  const driverDetails = currentQuote?.policyData?.drivers

  // setting coverages
  const coveragesDetails = currentQuote?.policyData?.coverages

  // setting vehicles
  const vehiclesDetails = currentQuote?.policyData?.cars
  // setting form data
  const getData = (name, data) => {
    setData((preState) => ({
      ...preState,
      [name]: data,
    }))
  }

  // validating form data
  useEffect(() => {
    const isCarrierSelected = allTimePreferedCarriers?.some((rat) => {
      return rat?.rated === `YES`
    })
    const keys = Object.keys(data)
    if (keys.length === 7) {
      let dataReady = keys.every((key) => data[key] != null)
      if (dataReady && keys?.length == 7 && isCarrierSelected) {
        reRateQuoteFormApi(preparePayload())
      } else {
        NotificationManager.error(`Please fill required fields!`)
        !isCarrierSelected &&
          NotificationManager.error(`Please select at least one Carrier!`)
      }
    }
  }, [data])

  useEffect(() => {
    if (nonOwner?.value?.includes(`YES`) && QuoteFormTabs?.length === 6) {
      QuoteFormTabs.pop()
      setActiveTab((prev) => prev)
      getData(`vehicles`, [])
    } else if (QuoteFormTabs?.length < 6) {
      QuoteFormTabs.push({ name: `Vehicles`, number: 6 })
      setActiveTab((prev) => prev)
    }
    if (nonOwner?.value?.includes(`NO`)) {
      vehicleRef1?.current?.updateFormValues(vehiclesDetails)
    }
  }, [nonOwner])

  const { quoteId } = useParams()

  const {
    isLoading: reRateQuoteFormLoading,
    data: quoteFormData,
    mutate: reRateQuoteFormApi,
    isError: reRateError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AQS_BACKEND_API}/quotes/rerate`, {
        ...payload,
        quoteId,
      })
        .then((res) => {
          if (res.data) {
            setQuoteOfferModel(true)
            return res?.data?.data
          }
        })
        .catch((error) => {
          NotificationManager.error(error?.response?.data?.message)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        if (res) {
          dispatch(setQuoteOffers(res?.quoteOffers))
          dispatch(setCurrentSavedQuotes(res?.updatedQuote))
          setSwitchQuoteForm(true)
          setReRateQuote(true)
          dispatch(setLeadData(``))
          dispatch(
            setCommunicationCredentials({
              id: res?.updatedQuote?._quoteId,
              phone: res.updatedQuote?._customer?.contactInfo?.phone,
              customerId: res.updatedQuote?._customer?._id,
              preferredContactMethods:
                res?.updatedQuote?._customer?.preferredContactMethods,
            }),
          )
        }
      },
    },
  )

  // api call for Carrier Questions

  const { mutate: getCarrierQuestions, isLoading: loadingQuestions } =
    useMutation(
      async (payload) =>
        await AxiosInstance.post(`${AQS_BACKEND_API}/quotes/carrierQuestions`, {
          ...payload,
        })
          .then((res) => {
            const carrierQuestions = res.data.data.carrierQuestions
            setCarrierQuestions(carrierQuestions)
            const newCarrierQuestions = {}
            if (
              carrierQuestions?.pol &&
              Object.keys(carrierQuestions?.pol)?.length > 0 &&
              Object.keys(carrierQuestions)?.length > 0
            ) {
              for (let cQuestion in carrierQuestions?.pol) {
                newCarrierQuestions[cQuestion] = carrierQuestions.pol[
                  cQuestion
                ].map((item) => ({
                  question: item.question,
                  FieldName: item.name,
                  options: item.options,
                  selectedValue: { value: item.default, label: item.default },
                  mask: item.mask ?? ``,
                  default: item.default ?? ``,
                  scope: item.scope,
                }))
              }
              setPolicyQuestions(newCarrierQuestions)
            } else {
              setPolicyQuestions({})
            }
          })
          .catch((error) => {
            error?.response?.data?.message &&
              NotificationManager.error(error?.response?.data?.message)
          }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
      },
    )

  // apis calling
  // api to get coverages
  const { isLoading, mutate: getAllCoverages } = useMutation(
    `StateCarriersApi`,
    async (state) =>
      await AxiosInstance.get(
        `${AQS_BACKEND_API}/get/fetchables/${contactData?.address?.state}`,
      )
        .then((res) => {
          setStateLoading(false)
          return res.data.data
        })
        .catch(() => {
          setStateLoading(false)
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (preferedCarriersSet) {
          handleModifyCarriersField(res.supportedCarriers)
        }

        if (!selectedTemplate) {
          const policyScopped = res?.supportedCoverages?.policyScoped.map(
            (policy, index) => ({
              code: policy.code,
              deductibleOption: () => ({
                options: policy.deductible?.length
                  ? policy.deductible.map((deductible) => ({
                      value: deductible.key,
                      label: deductible.name,
                    }))
                  : [],
                hasMore: false,
              }),
              _deductibleOptions:
                policy?.deductible?.length &&
                policy?.deductible.map((deductible) => ({
                  value: deductible.key,
                  label: deductible.name,
                })),
              deductible: coveragesDetails[index]?.deductible
                ? {
                    value: coveragesDetails[index]?.deductible,
                    label: coveragesDetails[index]?.deductible,
                  }
                : ``,
              deductibleError: ``,
              emptyDeductibles: policy.deductible?.length ? false : true,
              free: policy.free,
              name: policy.name,
              required: policy.required,
              limit: coveragesDetails[index]?.limits
                ? {
                    value: coveragesDetails[index]?.limits,
                    label: coveragesDetails[index]?.limits,
                  }
                : ``,
              limitError: ``,
              limitsOptions: () => ({
                options: policy.limits?.length
                  ? policy.limits.map((limit) => ({
                      value: limit.key,
                      label: limit.name,
                    }))
                  : [],
                hasMore: false,
              }),
              _limitOptions:
                policy?.limits?.length &&
                policy?.limits.map((limit) => ({
                  value: limit.key,
                  label: limit.name,
                })),
              emptyLimits: policy.limits?.length ? false : true,
            }),
          )

          const vehicleScopped = res?.supportedCoverages?.vehicleScoped.map(
            (vehicle) => ({
              code: vehicle.code,
              deductibleOption: () => ({
                options: vehicle.deductible?.length
                  ? vehicle.deductible.map((deductible) => ({
                      value: deductible.key,
                      label: deductible.name,
                    }))
                  : [],
                hasMore: false,
              }),
              _deductibleOptions:
                vehicle?.deductible?.length &&
                vehicle?.deductible.map((deductible) => ({
                  value: deductible.key,
                  label: deductible.name,
                })),
              deductible: ``,
              deductibleError: ``,
              emptyDeductibles: vehicle.deductible?.length ? false : true,
              free: vehicle.free,
              name: vehicle.name,
              required: vehicle.required,
              limit: ``,
              limitError: ``,
              limitsOptions: () => ({
                options: vehicle.limits?.length
                  ? vehicle.limits.map((limit) => ({
                      value: limit.key,
                      label: limit.name,
                    }))
                  : [],
                hasMore: false,
              }),
              _limitOptions:
                vehicle?.limits?.length &&
                vehicle?.limits.map((limit) => ({
                  value: limit.key,
                  label: limit.name,
                })),
              emptyLimits: vehicle.limits?.length ? false : true,
            }),
          )
          setPolicyScope(policyScopped)
          dispatch(setVehicleCoverage(vehicleScopped))
        }
        setPreferredCarrierSet(true)
      },
    },
  )

  useEffect(() => {
    // setting preferences
    setSelectedState({
      value: contactData?.address?.state,
      label: contactData?.address?.state,
    })
    // getCarrierQuestions({ state: preferenceData.state, type: `ALL` })
    dispatch(setPreferences(preferenceData))
    dispatch(setPreferedCarriers(preferenceData?.carriers))
    dispatch(setRatingCarriers(preferenceData?.carriers))

    // setting contact data
    contactRef1?.current?.updateFormValues(contactData)

    // setting quote details
    quoteDetailRef1?.current?.updateFormValues(quoteDetails)

    // setting drivers details
    driverRef1?.current?.updateFormValues(driverDetails)

    // setting coverages details
    coveragesRef1?.current?.updateFormValues(coveragesDetails)

    // setting vehicles details
    if (nonOwner?.value?.includes(`NO`)) {
      vehicleRef1?.current?.updateFormValues(vehiclesDetails)
    }
  }, [])
  // preparing payload for api
  const preparePayload = () => {
    const {
      customer,
      customerAddress,
      coverages,
      policyCoverages,
      drivers,
      quoteDetail,
      vehicles,
    } = data

    let payload = {
      title: `Form 1`,
      ratingPrefs: {
        // carriers: isStateChange
        //   ?

        carriers: allTimePreferedCarriers?.map((rat) => {
          return {
            _carrier: rat.id,
            rated: rat?.rated,
            ratingType: rat?.ratingType?.toUpperCase(),
            orderCredit: rat?.orderCredit,
            paymentFilter: rat?.paymentFilter,
          }
        }),
        // : allTimePreferedCarriers?.map((rat) => {
        //     return {
        //       _carrier: rat.id,
        //       rated: rat?.rated,
        //       ratingType: rat?.ratingType?.toUpperCase(),
        //       orderCredit: rat?.orderCredit,
        //       paymentFilter: rat?.paymentFilter,
        //     }
        //   }),
      },
      policyData: {
        coverages: [],
        customer: {
          contactInfo: {},
          address: {},
          priorAddress: {},
        },
      },
      quoteData: {},
      drivers: drivers,
      cars: nonOwner?.value?.includes(`NO`) ? vehicles : [],
      quoteType: `PERSONAL AUTO`,
    }

    if (coverages?.agencyFee.toString()) {
      payload.ratingPrefs.agencyFee = coverages?.agencyFee
    }
    if (preferenceData?.allowCreditScore?.length) {
      payload.ratingPrefs.allowCreditScore = preferenceData?.allowCreditScore
    }
    if (preferenceData?.estimateTerm) {
      payload.ratingPrefs.estimateTerm = preferenceData?.estimateTerm
    }
    if (preferenceData?.bumpLimits) {
      payload.ratingPrefs.bumpLimits = preferenceData?.bumpLimits
    }
    if (selectedState?.value?.length) {
      payload.ratingPrefs.state = selectedState?.value
    }

    // preparing policy data
    if (coverages?.effectiveDate) {
      payload.policyData.effectiveDate = coverages.effectiveDate
    }
    if (coverages?.policyTerm?.value?.length) {
      payload.policyData.policyTerm = coverages.policyTerm?.value
    }

    if (coverages?.exclusion?.value?.length) {
      payload.policyData.exclusions = coverages.exclusion?.value
    }

    if (nonOwner.value?.length) {
      payload.policyData.nonOwner = nonOwner.value
    }

    if (coverages?.broadForm.value?.length) {
      payload.policyData.broadform = coverages.broadForm.value
    }

    // customer starts from here
    if (customer?.firstName?.current?.value?.length) {
      payload.policyData.customer.firstName =
        customer?.firstName?.current?.value
    }

    if (customer?.middleName?.current?.value?.length) {
      payload.policyData.customer.middleName =
        customer.middleName?.current?.value
    }

    if (customer?.lastName?.current?.value?.length) {
      payload.policyData.customer.lastName = customer.lastName?.current?.value
    }
    if (customer?.residenceStatus?.value?.length) {
      payload.policyData.customer.residenceStatus =
        customer.residenceStatus?.value
    }
    if (customer?.residenceType?.value?.length) {
      payload.policyData.customer.residenceType = customer.residenceType?.value
    }
    // residence location

    if (customerAddress?.street?.current?.value?.length) {
      payload.policyData.customer.address.street =
        customerAddress.street?.current?.value
    }

    if (customerAddress?.city?.length) {
      payload.policyData.customer.address.city = customerAddress.city
    }

    if (customerAddress?.state?.length) {
      payload.policyData.customer.address.state = customerAddress.state
    }

    if (customerAddress?.zip?.value?.length) {
      payload.policyData.customer.address.zip = customerAddress.zip.value
    }
    // residence location ends here

    if (customer?.monthsAtCurrentLocation?.length) {
      payload.policyData.customer.monthsAtCurrentAddress =
        customer.monthsAtCurrentLocation
    }

    if (customer?.isPriorAddress?.value?.length) {
      payload.policyData.customer.sameCurrentPriorAddress =
        customer.isPriorAddress.value
    }

    if (customer?.isPriorAddress?.value == `NO`) {
      payload.policyData.customer.priorAddress = {
        street: customerAddress?.street?.current?.value,
        city: customerAddress?.city,
        state: customerAddress?.state,
        zip: customerAddress?.zip.value,
      }
    } else {
      payload.policyData.customer.priorAddress = {
        street: customer?.priorStreetAddress?.current?.value,
        city: customer?.priorAddressCity,
        state: customer?.priorAddressState,
        zip: customer?.priorAddressZip.value,
      }
    }

    if (customer?.cellPhone?.length) {
      payload.policyData.customer.contactInfo.phone = customer?.cellPhone
    }

    if (customer?.workPhone?.length) {
      payload.policyData.customer.contactInfo.workPhone = customer?.workPhone
    }

    // if (customer?.homePhone?.length) {
    //   payload.policyData.customer.contactInfo.homePhone = customer?.homePhone
    // }

    if (customer?.workPhoneExt?.length) {
      payload.policyData.customer.contactInfo.workPhoneExt =
        customer?.workPhoneExt
    }

    if (customer?.fax?.length) {
      payload.policyData.customer.contactInfo.fax = customer?.fax
    }

    if (customer?.email?.current?.value?.length) {
      payload.policyData.customer.contactInfo.email =
        customer?.email?.current?.value
    }
    //  Prepared Vehicle Coverages
    if (policyCoverages.length) {
      const prepareCoverages = policyCoverages.map((coverage) => {
        return {
          deductibleOptions:
            coverage?._deductibleOptions === 0
              ? []
              : coverage?._deductibleOptions,
          limitsOptions:
            coverage?._limitOptions === 0 ? [] : coverage?._limitOptions,
          rated:
            (selectedState?.value === `CO` || selectedState?.value === `FL`) &&
            (coverage.code === `PIP` || coverage.limit.value == `NONE`)
              ? `NO`
              : (selectedState?.value === `CA` ||
                  selectedState?.value === `TX`) &&
                coverage.code === `UMPD`
              ? `YES`
              : (selectedState?.value === `CO` ||
                  selectedState?.value === `CA`) &&
                (coverage.code === `UNDUM` || coverage.limit.value == `NONE`)
              ? `NO`
              : selectedState?.value === `UT` &&
                (coverage.code === `UMPD` || coverage.limit.value == `NONE`)
              ? `NO`
              : selectedState?.value === `UT` && coverage.code === `PD`
              ? `YES`
              : coverage.deductible.value !== `NONE` ||
                coverage.limit.value !== `NONE`
              ? `YES`
              : `NO`,
          name: coverage.code,
          code: coverage.code,
          limits:
            selectedState?.value === `CA` && coverage.code === `UMPD`
              ? `3500`
              : //   : selectedState?.value === `UT` && coverage.code === `UMPD`
                //   ? `250`
                //   : selectedState?.value === `TX` && coverage.code === `UMPD`
                //   ? `25`
                //   : selectedState?.value === `CA` && coverage.code === `PD`
                //   ? `5`
                //   : selectedState?.value === `FL` && coverage.code === `PD`
                //   ? `10`
                coverage.limit?.value,
          deductible: coverage.deductible?.value,
        }
      })
      payload.policyData.coverages = prepareCoverages
    }
    payload.policyData.questions = quoteDetail?.questions ?? {}
    // prepare quote details
    if (quoteDetail?.acquisitionBy?.value?.length) {
      payload.quoteData.acquisitionBy = quoteDetail.acquisitionBy?.value
    }

    if (quoteDetail?.contactMethod?.value?.length) {
      payload.quoteData.contactMethod = quoteDetail.contactMethod?.value
    }

    if (quoteDetail?.leadSource?.value?.length) {
      payload.quoteData.leadSource = quoteDetail.leadSource?.value
    }

    if (quoteDetail?.nativeLanguage?.value?.length) {
      payload.quoteData.nativeLanguage = quoteDetail.nativeLanguage?.value
    }

    if (quoteDetail?.paperlessDiscount?.value?.length) {
      payload.quoteData.paperlessDiscount = quoteDetail.paperlessDiscount?.value
    }

    if (quoteDetail?.preferredContact?.length) {
      payload.quoteData.preferredContactMethods =
        quoteDetail.preferredContact.map((item) => item.value)
    }

    if (quoteDetail?.quoteDescription?.value?.length) {
      payload.quoteData.quoteDescription = quoteDetail.quoteDescription?.value
    }

    dispatch(setQuoteForm(payload))
    return payload
  }
  useEffect(() => {
    window.addEventListener(`beforeunload`, alertUser)
    return () => {
      window.removeEventListener(`beforeunload`, alertUser)
      dispatch(setLeadData(``))
    }
  }, [])
  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ``
  }

  // manupilating carriers getting from api
  const handleModifyCarriersField = (carrierFields) => {
    const modifiedCarriers = carrierFields.map((carrier) => {
      return {
        ...carrier,
        rated: `YES`,
        orderCredit: `YES`,
        ratingType: carrier?.type,
      }
    })

    if (modifiedCarriers.length) {
      dispatch(setRatingCarriers(modifiedCarriers))
    }
  }

  // refetch coverages when state changes
  useEffect(() => {
    if (!preferedCarriersSet) {
      setStateLoading(true)
      getAllCoverages()
      setIsStateChange(true)
    }
    if (selectedState?.value?.length) {
      getCarrierQuestions({ state: selectedState?.value ?? `CA`, type: `ALL` })
    }
  }, [])

  // setting template selected
  //   useEffect(() => {
  //     dispatch(
  //       setSelectedRatingTemplate(
  //         templatesData?.find(
  //           (template) => template?._id === selectedTemplate?.value,
  //         ),
  //       ),
  //     )
  //     templatesData.forEach((template) => {
  //       if (template?._id === selectedTemplate?.value) {
  //         setSelectedState({
  //           value: template?.rateState,
  //           label: template?.rateState,
  //         })
  //       }
  //     })
  //   }, [selectedTemplate])

  // React select options

  // dropdown for ratingTemplate Select
  // const loadAllRatingTemplates = async (search, loadedOptions, { page }) => {
  //   const {
  //     data: { data },
  //   } = await AxiosInstance.get(
  //     `${AQS_BACKEND_API}/templates/my?search={"query": "${search}", "state": ""}&sort=""&page=${page}&offset=${10}`,
  //   )
  //   if (data?.ratingTemplates) {
  //     setTemplatesData(data?.ratingTemplates)
  //     const formattedModel = data?.ratingTemplates.map((template) => {
  //       return {
  //         label: `${template?.rateState}-${template?.name}`,
  //         value: template?._id,
  //       }
  //     })
  //     return {
  //       options: formattedModel,
  //       hasMore: data?.totalPages - page > 0,
  //       additional: {
  //         page: page + 1,
  //       },
  //     }
  //   }
  // }

  // options for state
  const loadStates = () => {
    const options = {
      options: [
        { value: `CA`, label: `CA` },
        { value: `FL`, label: `FL` },
        { value: `TX`, label: `TXS` },
      ],
      hasMore: false,
    }
    return options
  }

  return (
    <Container>
      {quoteFormData && (
        <Prompt message={() => `Are you sure you want to leave this page?`} />
      )}
      {tableModal && (
        <ReactModal
          closeButton={false}
          closeModal={() => {
            getCarrierQuestions({
              state: selectedState?.value ?? `CA`,
              type: `ALL`,
              preferredCarriers: allTimePreferedCarriers,
            })
            setTableModal(!tableModal)
          }}
          title="Carriers"
        >
          <div className="px-6 py-2 h-80  overflow-auto w-full hide-scroller hide-scroller-firefox ">
            <ReactTable
              carriersdata={allTimePreferedCarriers}
              isStateChange={isStateChange}
              isLoading={isLoading}
            />
          </div>
        </ReactModal>
      )}

      <>
        {switchQuoteForm && (
          <QuoteOffers
            goBack={() => setSwitchQuoteForm(false)}
            carriersdata={allTimePreferedCarriers}
            isStateChange={isStateChange}
          />
        )}

        <div className={switchQuoteForm ? `hidden` : ``}>
          {reRateError && (
            <ReactNotification action="error" message="Something went wrong" />
          )}
          <Spinner
            loading={stateLoading || reRateQuoteFormLoading || loadingQuestions}
          />
          <PageHeader>
            <div className="px-2">Quote Form</div>
            <div className="text-black py-1 pr-2 justify-items-end">
              <div className="relative">
                <ReactSelectField
                  placeholder="Select State"
                  isMulti={false}
                  setValue={(value) => setSelectedState(value)}
                  loadOptions={loadStates}
                  isSearchable={true}
                  disabled={true}
                  value={selectedState}
                />
              </div>
            </div>
          </PageHeader>
          <div
            className={`bg-white ${
              (stateLoading || reRateQuoteFormLoading || loadingQuestions) &&
              `opacity-30 pointer-events-none`
            } `}
          >
            <div className="flex relative items-center py-2 px-4">
              <div className=" justify-self-start">
                <StyledButton
                  onClick={() => {
                    // refetchPref()
                    setTableModal(!tableModal)
                  }}
                >
                  Carriers
                </StyledButton>
              </div>
              <div className="fixed top-18 right-6 z-20">
                <StyledButton
                  onClick={() => {
                    contactRef1.current.validateFrom()
                    coveragesRef1.current.validateForm()
                    quoteDetailRef1.current.validateForm()
                    driverRef1.current.validateForm()
                    if (nonOwner?.value?.includes(`NO`))
                      vehicleRef1.current.validateForm()
                  }}
                >
                  Submit
                </StyledButton>
              </div>
            </div>

            <div className="h-78vh overflow-auto hide-scroller hide-scroller-firefox">
              {/* tabs will go here */}
              <div className="px-4 mb-2">
                <Tabs2
                  openTab={activeTab}
                  tabs={QuoteFormTabs}
                  getActiveTab={(val) => setActiveTab(val)}
                />
                <>
                  <div
                    className={
                      activeTab == 1 || activeTab == 2
                        ? `grid grid-cols-2 gap-2 h mt-2`
                        : `hidden`
                    }
                  >
                    <div>
                      <ContactForm
                        ref={contactRef1}
                        getData={getData}
                        reRated={true}
                        selectedState={selectedState?.value}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      activeTab == 1 || activeTab == 3
                        ? `grid grid-cols-1 gap-2 h mt-2`
                        : `hidden`
                    }
                  >
                    <div>
                      <CoveragesForm
                        ref={coveragesRef1}
                        getData={getData}
                        policyScope={policyScope}
                        preference={preferenceData}
                        nonOwner={nonOwner}
                        setNonOwner={setNonOwner}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      activeTab == 1 || activeTab == 4 ? `mt-2` : `hidden`
                    }
                  >
                    <QuoteDetails
                      ref={quoteDetailRef1}
                      getData={getData}
                      carrierQuestion={policyQuestions}
                    />
                  </div>
                </>
                <div
                  className={
                    activeTab == 1 || activeTab == 5 ? `mt-2` : `hidden`
                  }
                >
                  <DriverForm
                    ref={driverRef1}
                    getData={getData}
                    selectedState={selectedState?.value}
                    carrierQuestion={carrierQuestions?.drv}
                  />
                </div>
                {nonOwner?.value?.includes(`NO`) && (
                  <div
                    className={
                      activeTab == 1 || activeTab == 6 ? `my-2` : `hidden`
                    }
                  >
                    <VehicleForm
                      ref={vehicleRef1}
                      getData={getData}
                      selectedState={selectedState}
                      carrierQuestion={carrierQuestions?.car}
                      reRated={true}
                    />
                  </div>
                )}
                {/* <div className={activeTab == 7 ? `my-2` : `hidden`}>
                    <CarrierQuestions />
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    </Container>
  )
}
