import React, { useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Container,
  FormTab,
  TFive,
  TFour,
  TOne,
  TSix,
  TThree,
  TTwo,
} from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'

import { Collapsable } from 'App/Components/Common/Collapsable'
import { CardField } from 'App/Components/Common/CardField'

import { Tabs2 } from 'App/Components/Common/Tabs2'
import { PolicyContext } from '../../..'
import dateFormat from 'App/Services/General/dateFormat'

const VehicleCredentialsTabs = [
  {
    name: `Lien Holder`,
    number: 1,
  },
  {
    name: `Usage`,
    number: 2,
  },
  {
    name: `Model Details`,
    number: 3,
  },
  {
    name: `Location`,
    number: 4,
  },
  {
    name: `Garage`,
    number: 5,
  },
  {
    name: `Purchase`,
    number: 6,
  },
]

const DriversCredentialsTabs = [
  {
    name: `SR22`,
    number: 1,
  },
  {
    name: `Education`,
    number: 2,
  },
  {
    name: `Employment`,
    number: 3,
  },
  {
    name: `License`,
    number: 4,
  },
  {
    name: `Prior Insurance`,
    number: 5,
  },
  {
    name: `Violations`,
    number: 6,
  },
]
export const PolicyAbout = () => {
  const [driversTabs, setDriversTab] = useState(``)
  const [activeDriverTab, setActiveDriverTab] = useState(1)
  const [activeDriversCredentialsTabsTab, setActiveDriversCredentialsTabsTab] =
    useState(1)

  // vehicles tabs
  const [vehiclesTabs, setVehiclesTab] = useState(``)
  const [activeVehicleTab, setActiveVehicleTab] = useState(1)
  const [activeVehicleCredentialsTabsTab, setActiveVehicleCredentialsTabsTab] =
    useState(1)

  // coverages tabs
  const [coveragesTabs, setCoveragesTab] = useState(``)
  const [activeCoveragesTab, setActiveCoverageTab] = useState(1)

  const { currentPolicy } = useSelector(({ policy }) => policy)
  const { isLoading, isFetching, refetch, error } = useContext(PolicyContext)

  useEffect(() => {
    if (currentPolicy) {
      // sort drivers tabs
      if (currentPolicy?.policyData.drivers.length > 0) {
        setDriversTab(
          currentPolicy?.policyData.drivers.map((driver, index) => {
            return {
              name: driver.firstName + ` ` + driver.lastName,
              number: index + 1,
            }
          }),
        )
      }

      // sort vehicles tabs
      if (currentPolicy?.policyData.cars.length > 0) {
        setVehiclesTab(
          currentPolicy?.policyData.cars.map((vehicle, index) => {
            return {
              name: vehicle.model,
              number: index + 1,
            }
          }),
        )
      }

      // sort vehicles tabs
      if (currentPolicy?.policyData?.coverages?.length > 0) {
        setCoveragesTab(
          currentPolicy?.policyData.coverages.map((coverage, index) => {
            return {
              name: coverage.code,
              number: index + 1,
            }
          }),
        )
      }
    }
  }, [currentPolicy])

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="text-center py-10">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <Container>
          <div className="p-2">
            <div className="my-2">
              <div className="grid grid-cols-12 px-4 py-2">
                <div className="col-span-5 text-xl">Policy Info</div>
                <div className="mx-4 col-span-4 justify-between flex flex-col gap-4 lg:flex-row">
                  <div className=" text-sm py-1.5 flex justify-start gap-3 whitespace-nowrap">
                    <span className="text-gray-500 font-medium  capitalize">
                      Last Checked
                    </span>
                    <span className="font-medium">
                      {dateFormat(currentPolicy?.paymentInfo?.lastChecked) ||
                        `Not Provided`}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div className="flex px-4">
                <div className="flex-1">
                  <CardField
                    title="Policy Id"
                    value={currentPolicy?._policyId}
                  />
                  <CardField
                    title="Customer"
                    value={
                      currentPolicy?._customer?.firstName +
                      ` ` +
                      currentPolicy?._customer?.lastName
                    }
                  />
                  <CardField
                    title="Carrier"
                    value={currentPolicy?._carrier.toUpperCase()}
                  />
                  <CardField
                    title="Creator"
                    value={
                      currentPolicy?._creator?.firstName
                        ? `${
                            currentPolicy?._creator?.firstName +
                            ` ` +
                            currentPolicy?._creator?.lastName
                          }`
                        : ``
                    }
                  />
                  <CardField title="Lob" value={currentPolicy?.lob} />
                  <CardField
                    title="Quote Status"
                    value={currentPolicy?.status}
                  />
                  <CardField
                    title="Quote Type"
                    value={currentPolicy?.quoteData?.type}
                  />
                  <CardField
                    title="Quote No"
                    value={currentPolicy?.quoteData?._id}
                  />
                </div>
                <div className="flex-1">
                  <CardField
                    title="Created At"
                    value={dateFormat(currentPolicy.createdAt)}
                  />
                  <CardField
                    title="Updated At"
                    value={dateFormat(currentPolicy.updatedAt)}
                  />
                  <CardField
                    title="Premium"
                    value={`$` + currentPolicy?.totalPremium}
                  />
                  {/* <CardField
                    title="Turbo Rater Id"
                    value={currentPolicy?._turboRaterId}
                  />
                  <CardField
                    title="Turbo Rater URL"
                    value={currentPolicy?.turboRaterUrl}
                    link
                  /> */}
                </div>
              </div>
            </div>
            <div className="my-2">
              <Collapsable title="Policy Data">
                <CardField title="Policy No" value={currentPolicy?.policyNo} />
                <CardField
                  title="Exclusion"
                  value={currentPolicy?.policyData?.exclusions}
                />
                <CardField
                  title="Non Owner"
                  value={currentPolicy?.policyData?.nonOwner}
                />
                <CardField
                  title="Broadform"
                  value={currentPolicy?.policyData?.broadform}
                />
                <CardField
                  title="Effective Date"
                  value={dateFormat(currentPolicy.policyData?.effectiveDate)}
                />
                <CardField
                  title="Expiry Date"
                  value={dateFormat(currentPolicy.policyData?.expiryDate)}
                />
                {/* <CardField
                  title="Policy Term"
                  value={currentPolicy?.policyData?.policyTerm}
                /> */}
                {/* coverages */}
                {currentPolicy?.policyData?.coverages?.length > 0 && (
                  <div className="my-2">
                    <Collapsable title="Coverages">
                      <Tabs2
                        openTab={activeCoveragesTab}
                        tabs={coveragesTabs}
                        getActiveTab={(val) => setActiveCoverageTab(val)}
                      />
                      {currentPolicy?.policyData?.coverages.map(
                        (coverage, idx) => (
                          <FormTab
                            key={idx}
                            currenttab={idx + 1}
                            opentab={activeCoveragesTab}
                            className="mt-2 shadow-sm rounded-md relative"
                          >
                            <CardField title="Name" value={coverage.name} />
                            <CardField title="Limits" value={coverage.limits} />
                            <CardField
                              title="Deductible"
                              value={coverage.deductible}
                            />
                            <CardField
                              title="Co Pays"
                              value={coverage.coPays}
                            />
                            <CardField title="Type" value={coverage.type} />
                            <CardField title="Rated" value={coverage.rated} />
                            <CardField title="Code" value={coverage.code} />
                          </FormTab>
                        ),
                      )}
                    </Collapsable>
                  </div>
                )}

                {/* cars */}
                {currentPolicy?.policyData?.cars?.length > 0 && (
                  <div className="my-2">
                    <Collapsable title="Cars">
                      <Tabs2
                        openTab={activeVehicleTab}
                        tabs={vehiclesTabs}
                        getActiveTab={(val) => setActiveVehicleTab(val)}
                      />
                      {currentPolicy?.policyData?.cars.map((car, idx) => (
                        <FormTab
                          key={idx}
                          currenttab={idx + 1}
                          opentab={activeVehicleTab}
                          className="mt-2 shadow-sm rounded-md relative"
                        >
                          <CardField title="Model" value={car.model} />
                          <CardField
                            title="License Plate No"
                            value={car.licensePlateNo}
                          />
                          <CardField title="Make" value={car.make} />
                          <CardField title="Year" value={car.year} />
                          <CardField title="VIN" value={car.vin} />
                          <CardField title="FR Bond" value={car.frBond} />
                          <CardField
                            title="Actual Cash Value"
                            value={car.actualCashValue}
                          />

                          <Tabs2
                            openTab={activeVehicleCredentialsTabsTab}
                            tabs={VehicleCredentialsTabs}
                            getActiveTab={(val) =>
                              setActiveVehicleCredentialsTabsTab(val)
                            }
                          />
                          <TOne opentab={activeVehicleCredentialsTabsTab}>
                            <CardField
                              title="Name"
                              value={car?.lienHolder?.name}
                            />
                            <CardField
                              title="Lien holder"
                              value={car?.lienHolder?.hasLienholder}
                            />
                            <CardField
                              title="Type"
                              value={car?.lienHolder?.type}
                            />
                            <CardField
                              title="Phone"
                              value={car?.lienHolder?.phone}
                            />
                            <CardField
                              title="Fax"
                              value={car?.lienHolder?.fax}
                            />

                            <CardField
                              title="Unit No"
                              value={car?.lienHolder?.location?.unitNo}
                            />
                            <CardField
                              title="State"
                              value={car?.lienHolder?.location?.state}
                            />
                            <CardField
                              title="City"
                              value={car?.lienHolder?.location?.city}
                            />
                            <CardField
                              title="Street"
                              value={car?.lienHolder?.location?.street}
                            />
                            <CardField
                              title="Zip"
                              value={car?.lienHolder?.location?.zip}
                            />
                          </TOne>
                          <TTwo opentab={activeVehicleCredentialsTabsTab}>
                            <CardField title="Model" value={car.model} />
                            <CardField
                              title="Basic Use"
                              value={car?.usage?.basicUse}
                            />
                            <CardField
                              title="Used For Ride Sharing"
                              value={car?.usage?.isUsedForRideSharing}
                            />
                            <CardField
                              title="Primary Operator"
                              value={car?.usage?.primaryOperator}
                            />
                            <CardField
                              title="Percent Driven To Work"
                              value={car?.usage?.percentDrivenToWork}
                            />
                            <CardField
                              title="Miles Driven To Work"
                              value={car?.usage?.milesDrivenToWork}
                            />
                            <CardField
                              title="Annual Mileage"
                              value={car?.usage?.annualMileage}
                            />
                            <CardField
                              title="Odometer Reading"
                              value={car?.usage?.odometerReading}
                            />
                          </TTwo>

                          <TThree opentab={activeVehicleCredentialsTabsTab}>
                            <CardField
                              title="Vehicle Type"
                              value={car?.modelDetails?.vehicleType}
                            />
                            <CardField
                              title="Body Type"
                              value={car?.modelDetails?.bodyType}
                            />
                            <CardField
                              title="Feul Type"
                              value={car?.modelDetails?.fuelType}
                            />
                            <CardField
                              title="Truck Size"
                              value={car?.modelDetails?.truckSize}
                            />
                            <CardField
                              title="Doors"
                              value={car?.modelDetails?.doors}
                            />
                            <CardField
                              title="Cylinders"
                              value={car?.modelDetails?.cylinders}
                            />
                            <CardField
                              title="Hatchback"
                              value={car?.modelDetails?.isHatchback}
                            />
                            <CardField
                              title="Convertible"
                              value={car?.modelDetails?.isConvertible}
                            />
                            <CardField
                              title="4wd"
                              value={car?.modelDetails?.is4wd}
                            />
                            <CardField
                              title="4ws"
                              value={car?.modelDetails?.is4ws}
                            />
                            <CardField
                              title="Fwd"
                              value={car?.modelDetails?.isFwd}
                            />
                            <CardField
                              title="TurboCharged"
                              value={car?.modelDetails?.isTurboCharged}
                            />
                            <CardField
                              title="Air Bags"
                              value={car?.modelDetails?.airBags}
                            />
                            <CardField
                              title="Anti Lock"
                              value={car?.modelDetails?.antiLock}
                            />
                            <CardField
                              title="Anti Theft"
                              value={car?.modelDetails?.antiTheft}
                            />
                            <CardField
                              title="Passive Restraint"
                              value={car?.modelDetails?.passiveRestraint}
                            />
                            <CardField
                              title="Leased"
                              value={car?.modelDetails?.isLeased}
                            />
                            <CardField
                              title="Salvaged"
                              value={car?.modelDetails?.isSalvaged}
                            />
                            <CardField
                              title="Grey Market Vehicle"
                              value={car?.modelDetails?.isGreyMarketVehicle}
                            />
                            <CardField
                              title="Has Monitoring Device"
                              value={car?.modelDetails?.hasMonitoringDevice}
                            />
                            <CardField
                              title="Dualie"
                              value={car?.modelDetails?.isDualie}
                            />
                            <CardField
                              title="Has Running Lights"
                              value={car?.modelDetails?.hasRunningLights}
                            />
                            <CardField
                              title="Has Hood Locking Device"
                              value={car?.modelDetails?.hasHoodLockingDevice}
                            />
                            <CardField
                              title="Has Homing Device"
                              value={car?.modelDetails?.hasHomingDevice}
                            />
                            <CardField
                              title="Has Vin Etching"
                              value={car?.modelDetails?.hasVinEtching}
                            />
                            <CardField
                              title="Color"
                              value={car?.modelDetails?.color}
                            />
                            <CardField
                              title="Unique Symbol Code"
                              value={car?.modelDetails?.uniqueSymbolCode}
                            />
                            <CardField
                              title="Model Group Code"
                              value={car?.modelDetails?.modelGroupCode}
                            />
                            <CardField
                              title="MRSP"
                              value={car?.modelDetails?.mrsp}
                            />
                          </TThree>
                          <TFour opentab={activeVehicleCredentialsTabsTab}>
                            <CardField
                              title="State"
                              value={car.location?.state}
                            />
                            <CardField
                              title="City"
                              value={car.location?.city}
                            />
                            <CardField
                              title="Street"
                              value={car.location?.street}
                            />
                            <CardField
                              title="Unit No"
                              value={car.location?.unitNo}
                            />
                            <CardField
                              title="Zip"
                              value={car.location?.zip}
                              badge={true}
                            />
                          </TFour>
                          <TFive opentab={activeVehicleCredentialsTabsTab}>
                            <CardField
                              title="Same Garage Reside Locations"
                              value={car?.garage?.sameGarageResideLocations}
                            />
                            <CardField
                              title="Garaged"
                              value={car?.garage?.isGaraged}
                            />
                            <Collapsable title="Location">
                              <CardField
                                title="State"
                                value={car?.garage?.location.state}
                              />
                              <CardField
                                title="City"
                                value={car?.garage?.location.city}
                              />
                              <CardField
                                title="Street"
                                value={car?.garage?.location.street}
                              />
                              <CardField
                                title="Unit No"
                                value={car?.garage?.location.unitNo}
                              />
                              <CardField
                                title="Zip"
                                value={car?.garage?.location.zip}
                                badge={true}
                              />
                            </Collapsable>
                          </TFive>
                          <TSix opentab={activeVehicleCredentialsTabsTab}>
                            <CardField
                              title="Type"
                              value={car.purchase?.type}
                            />
                            <CardField
                              title="Cost"
                              value={car.purchase?.cost}
                            />
                            <CardField
                              title="Date"
                              value={dateFormat(car.purchase?.date)}
                            />
                          </TSix>
                        </FormTab>
                      ))}
                    </Collapsable>
                  </div>
                )}

                {/* drivers */}
                {currentPolicy?.policyData?.drivers?.length > 0 && (
                  <div className="my-2">
                    <Collapsable title="Drivers">
                      <Tabs2
                        openTab={activeDriverTab}
                        tabs={driversTabs}
                        getActiveTab={(val) => setActiveDriverTab(val)}
                      />
                      {currentPolicy?.policyData?.drivers?.map(
                        (driver, idx) => (
                          <FormTab
                            key={idx}
                            currenttab={idx + 1}
                            opentab={activeDriverTab}
                            className="mt-2 shadow-sm rounded-md relative"
                          >
                            <CardField
                              title="First Name"
                              value={driver.firstName}
                            />
                            <CardField
                              title="Middle Name"
                              value={driver?.middleName}
                            />
                            <CardField
                              title="Last Name"
                              value={driver.lastName}
                            />
                            <CardField
                              title="DATE OF BIRTH"
                              value={dateFormat(driver.dob)}
                            />
                            <CardField
                              title="Relation"
                              value={driver?.relation}
                            />
                            <CardField
                              title="Gender"
                              value={driver?.maritalStatus}
                            />
                            <CardField
                              title="Co Pays"
                              value={driver?.milesToWork}
                            />

                            <CardField
                              title="Occasional Driver"
                              value={driver?.isOccasionalDriver}
                            />
                            <CardField
                              title="Property Insurance"
                              value={driver?.hasPropertyInsurance}
                            />

                            <CardField
                              title="Civil Union"
                              value={driver?.isCivilUnion}
                            />
                            <CardField title="SSN" value={driver?.ssn} />
                            <CardField
                              title="Single Parent"
                              value={driver?.isSingleParent}
                            />
                            <CardField
                              title="Primary Car"
                              value={driver?.primaryCar}
                            />
                            <CardField
                              title="Miles To Work"
                              value={driver?.milesToWork}
                            />
                            <CardField
                              title="Excluded"
                              value={driver?.isExcluded}
                            />

                            <Tabs2
                              openTab={activeDriversCredentialsTabsTab}
                              tabs={DriversCredentialsTabs}
                              getActiveTab={(val) =>
                                setActiveDriversCredentialsTabsTab(val)
                              }
                            />
                            <TOne opentab={activeDriversCredentialsTabsTab}>
                              <CardField
                                title="Filed"
                                value={driver?.sr22?.isFiled}
                              />
                              <CardField
                                title="Filing State"
                                value={driver?.sr22?.filingState}
                              />
                              <CardField
                                title="Reason"
                                value={driver?.sr22?.reason}
                              />
                              <CardField
                                title="Case No"
                                value={driver?.sr22?.caseNo}
                              />
                              <CardField
                                title="Filing Date"
                                value={dateFormat(driver?.sr22?.filingDate)}
                              />
                            </TOne>
                            <TTwo opentab={activeDriversCredentialsTabsTab}>
                              <CardField
                                title="Education Level"
                                value={driver?.education?.educationLevel}
                              />
                              <CardField
                                title="Driver Training"
                                value={driver?.education?.driverTraining}
                              />
                              <CardField
                                title="Defensive Driving"
                                value={driver?.education?.defensiveDriving}
                              />
                              <CardField
                                title="Drug Awareness"
                                value={driver?.education?.drugAwareness}
                              />
                            </TTwo>
                            <TThree opentab={activeDriversCredentialsTabsTab}>
                              <CardField
                                title="Employed"
                                value={driver?.employment?.isEmployed}
                              />
                              <CardField
                                title="Employment Status"
                                value={driver?.employment?.employmentStatus}
                              />
                              <CardField
                                title="e5Or Higher Rank"
                                value={driver?.employment?.e5OrHigherRank}
                              />
                              <CardField
                                title="Industry"
                                value={driver?.employment?.industry}
                              />
                              <CardField
                                title="Occupation"
                                value={driver?.employment?.occupation}
                              />
                              <CardField
                                title="Occupation Industry Code"
                                value={
                                  driver?.employment?.occupationIndustryCode
                                }
                              />
                              <CardField
                                title="Employed Months"
                                value={driver?.employment?.employedMonths}
                              />
                              <div className="flex-1">
                                <Collapsable title="Employer">
                                  <CardField
                                    title="Name"
                                    value={driver?.employment?.employer?.name}
                                  />
                                  <CardField
                                    title="Phone"
                                    value={driver?.employment?.employer?.phone}
                                  />
                                  <CardField
                                    title="Unit No"
                                    value={
                                      driver?.employment?.employer?.location
                                        ?.unitNo
                                    }
                                  />
                                  <CardField
                                    title="Street"
                                    value={
                                      driver?.employment?.employer?.location
                                        ?.street
                                    }
                                  />
                                  <CardField
                                    title="City"
                                    value={
                                      driver?.employment?.employer?.location
                                        ?.city
                                    }
                                  />
                                  <CardField
                                    title="State"
                                    value={
                                      driver?.employment?.employer?.location
                                        ?.state
                                    }
                                  />
                                  <CardField
                                    title="Zip"
                                    value={
                                      driver?.employment?.employer?.location
                                        ?.zip
                                    }
                                  />
                                </Collapsable>
                              </div>
                            </TThree>
                            <TFour opentab={activeDriversCredentialsTabsTab}>
                              <CardField
                                title="Licensed"
                                value={driver?.license?.isLicensed}
                              />
                              <CardField
                                title="License No"
                                value={driver?.license?.licenseNo}
                              />
                              <CardField
                                title="Suspended"
                                value={driver?.license?.isSuspended}
                              />
                              <CardField
                                title="Suspended Months"
                                value={driver?.license?.suspendedMonths}
                              />
                              <CardField
                                title="US Licensed Months"
                                value={driver?.license?.usLicensedMonths}
                              />
                              <CardField
                                title="State Licensed Months"
                                value={driver?.license?.stateLicensedMonths}
                              />
                              <CardField
                                title="US MVR Experience Months"
                                value={driver?.license?.usMVRExperienceMonths}
                              />
                              <CardField
                                title="Foreign Licensed"
                                value={driver?.license?.isForeignLicensed}
                              />
                              <CardField
                                title="Foreign License Country"
                                value={driver?.license?.foreignLicenseCountry}
                              />
                              <CardField
                                title="Foreign Licensed Months"
                                value={driver?.license?.foreignLicensedMonths}
                              />
                            </TFour>
                            <TFive opentab={activeDriversCredentialsTabsTab}>
                              <CardField
                                title="Duration In Months"
                                value={driver?.priorInsurance?.durationInMonths}
                              />
                              <CardField
                                title="DaysLapsed"
                                value={driver?.priorInsurance?.daysLapsed}
                              />
                              <CardField
                                title="WasInsured"
                                value={driver?.priorInsurance?.wasInsured}
                              />
                              <CardField
                                title="ExpiryDate"
                                value={driver?.priorInsurance?.expiryDate}
                              />
                              <CardField
                                title="Transfer Level"
                                value={driver?.priorInsurance?.transferLevel}
                              />
                              <CardField
                                title="Carrier"
                                value={driver?.priorInsurance?.carrier}
                              />
                              <CardField
                                title="Liability Limits"
                                value={driver?.priorInsurance?.liabilityLimits}
                              />
                            </TFive>
                            <TSix opentab={activeDriversCredentialsTabsTab}>
                              {driver?.violations?.map((violation, index) => (
                                <div key={index}>
                                  <Collapsable title={idx + 1}>
                                    <CardField
                                      title="Description"
                                      value={violation?.description}
                                    />
                                    <CardField
                                      title="At Fault"
                                      value={violation?.wasAtFault}
                                    />
                                    <CardField
                                      title="Convicted"
                                      value={violation?.wasConvicted}
                                    />
                                    <CardField
                                      title="Conviction Date"
                                      value={violation?.convictionDate}
                                    />
                                    <CardField
                                      title="Reported To Police"
                                      value={violation?.wasReportedToPolice}
                                    />
                                    <CardField
                                      title="Prayer For Judgment"
                                      value={violation?.prayerForJudgment}
                                    />
                                    <CardField
                                      title="Same Day"
                                      value={violation?.sameDay}
                                    />
                                    <CardField
                                      title="Input Method"
                                      value={violation?.inputMethod}
                                    />
                                    <CardField
                                      title="attr1"
                                      value={violation?.attr1}
                                    />
                                    <CardField
                                      title="attr2"
                                      value={violation?.attr2}
                                    />
                                    <CardField
                                      title="attr3"
                                      value={violation?.attr3}
                                    />
                                    <CardField
                                      title="Violation Points"
                                      value={violation?.violationPoints}
                                    />
                                    <CardField
                                      title="Code"
                                      value={violation?.code}
                                    />
                                    <CardField
                                      title="Violation Date"
                                      value={violation?.violationDate}
                                    />
                                    <div className="flex-1">
                                      <Collapsable title="Location">
                                        <CardField
                                          title="Unit No"
                                          value={violation?.location?.unitNo}
                                        />
                                        <CardField
                                          title="Street"
                                          value={violation?.location?.street}
                                        />
                                        <CardField
                                          title="City"
                                          value={violation?.location?.city}
                                        />
                                        <CardField
                                          title="State"
                                          value={violation?.location?.state}
                                        />
                                        <CardField
                                          title="Zip"
                                          value={violation?.location?.zip}
                                        />
                                      </Collapsable>
                                    </div>
                                  </Collapsable>
                                </div>
                              ))}
                            </TSix>
                          </FormTab>
                        ),
                      )}
                    </Collapsable>
                  </div>
                )}
              </Collapsable>
            </div>

            {/* Quote  data */}
            {/* <div className="my-2">
              <Collapsable title="Qoute Data">
                <CardField
                  title="Contact Method"
                  value={currentPolicy?.quoteData?.contactMethod}
                />
                <CardField
                  title="Preferred ContactMethod"
                  value={currentPolicy?.quoteData?.preferredContactMethod}
                />
                <CardField
                  title="Acquisition By"
                  value={currentPolicy?.quoteData?.acquisitionBy}
                />
                <CardField
                  title="Lead Source"
                  value={currentPolicy?.quoteData?.leadSource}
                />

                <CardField
                  title="Quote Description"
                  value={currentPolicy?.quoteData?.quoteDescription}
                />
                <CardField
                  title="Native Language"
                  value={currentPolicy?.quoteData?.nativeLanguage}
                />
                <CardField
                  title="Paperless Discount"
                  value={currentPolicy?.quoteData?.paperlessDiscount}
                />
              </Collapsable>
            </div> */}
            {/* rating data */}
            {/* <div className="my-2">
              <Collapsable title="Rating Data">
                <CardField
                  title="Rater"
                  value={currentPolicy?.ratingData?._rater}
                />
                <CardField
                  title="Rated At"
                  value={currentPolicy?.ratingData?.ratedAt}
                />
                <Collapsable title="Rating Preference">
                  <CardField
                    title="Agency Fee"
                    value={currentPolicy?.ratingData?.ratingPrefs?.agencyFee}
                  />
                  <CardField
                    title="State"
                    value={currentPolicy?.ratingData?.ratingPrefs?.state}
                  />
                  <CardField
                    title="Allow Credit Score"
                    value={
                      currentPolicy?.ratingData?.ratingPrefs?.allowCreditScore
                    }
                  />
                  <CardField
                    title="Bump Limits"
                    value={currentPolicy?.ratingData?.ratingPrefs?.bumpLimits}
                  />
                  <CardField
                    title="Estimate Term"
                    value={currentPolicy?.ratingData?.ratingPrefs?.estimateTerm}
                  />
                  {currentPolicy?.ratingData?.ratingPrefs?.carriers.length >
                    0 && (
                    <Collapsable title="Coverages">
                      <div className="grid grid-cols-2 gap-2 mt-0.5">
                        {currentPolicy?.ratingData?.ratingPrefs?.carriers.map(
                          (carrier, idx) => (
                            <div
                              key={idx}
                              className="flex items-start justify-evenly"
                            >
                              <div className="flex-1 mx-2 shadow-lg rounded">
                                <Collapsable title={carrier.carrier}>
                                  <CardField
                                    title="Carrier"
                                    value={carrier.carrier}
                                    badge={true}
                                  />
                                  <CardField
                                    title="Rated"
                                    value={carrier.rated}
                                  />
                                  <CardField
                                    title="Order Credit"
                                    value={carrier.orderCredit}
                                  />
                                  <CardField
                                    title="Rating Type"
                                    value={carrier.ratingType}
                                  />
                                  <CardField
                                    title="Payment Filter"
                                    value={carrier.paymentFilter}
                                  />
                                </Collapsable>
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    </Collapsable>
                  )}
                </Collapsable>
              </Collapsable>
            </div> */}
          </div>
        </Container>
      )}
    </div>
  )
}
