import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  threads: ``,
  currentThread: ``,
}

const gmailSlice = createSlice({
  name: `gmail`,
  initialState,

  reducers: {
    setThreads: (state, { payload }) => {
      state.threads = payload
    },
    setCurrentThreads: (state, { payload }) => {
      state.currentThread = payload
    },
  },
})

export const { setThreads, setCurrentThreads } = gmailSlice.actions

export const gmail = gmailSlice.reducer
