import dateFormat from 'App/Services/General/dateFormat'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const Goal = ({ goalsData }) => {
  const history = useHistory()
  return (
    <div className="w-full flex flex-col border-gray-400">
      <div
        className="select-none cursor-pointer bg-white flex flex-1 items-center py-1"
        onClick={() => {
          history.push(`/admin/monthlygoals/${goalsData?._id}`)
        }}
      >
        <div className="mr-6 pl-1 text-xs font-bold text-gray-500">
          <div className="font-medium dark:text-white">
            {goalsData?.createdBy?.label}
          </div>
        </div>
        <div className="text-gray-600 w-10/12 dark:text-gray-200 text-xs whitespace-nowrap">
          {dateFormat(goalsData?.startDate) +
            ` / ` +
            dateFormat(goalsData?.endDate)}
        </div>

        {/* <div className="mx-4">
          <Badge
            type={goalsData?.goalStatus === `COMPLETED` ? `success` : `primary`}
          >
            {goalsData?.goalStatus}
          </Badge>
        </div> */}

        {/* <div className="w-full rounded-full">
          <span className="text-xs font-bold text-gray-500">
            {goalsData?.type}
          </span>
          {goalsData?.goalStatus === `COMPLETED` ? (
            <div className="w-full h-4 rounded-md mt-1">
              <div className="text-center text-xs text-white bg-green-400 rounded-md">
                {goalsData?.goalStatus === `COMPLETED`
                  ? `100%`
                  : ((goalsData?.total / goalsData?.target) * 100).toFixed(2) +
                  `%`}
              </div>
            </div>
          ) : (
            <div className={`w-full h-4 rounded-md mt-1 bg-gray-400 flex`}>
              <div
                className="bg-red-500 text-xs text-white rounded-md"
                style={{
                  width: `${(goalsData?.total / goalsData?.target) * 100}%`,
                }}
              >
                {((goalsData?.total / goalsData?.target) * 100).toFixed(1) +
                  `%`}
              </div>
              <div
                className="bg-gray-400 rounded-md"
                style={{
                  width: `${100 -
                    ((goalsData?.total / goalsData?.target) * 100).toFixed(2)
                    }%`,
                }}
              ></div>
            </div>
          )}
        </div> */}
        <div className="flex flex-col w-full">
          {[`Payment`, `Leads`, `Policies`].map((item, index) => (
            <div className="flex justify-between" key={index}>
              <span className="text-sm text-gray-500">{item}</span>
              <span className="text-sm text-gray-800 ">
                {goalsData?.agentsProgress[`verified${item}`]}/
                {goalsData[`target${item}`]}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
