import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export const DatePickerField = ({
  title,
  placeholder,
  errorMessage,
  value,
  setValue,
  disabled = false,
  maxDate,
  minDate,
  requiredValue,
  timePicker,
  dateFormat,
  onKeyDown = () => {},
}) => {
  return (
    <>
      <label className="text-gray-700 font-medium text-sm">
        {title}
        {(errorMessage || requiredValue) && (
          <span className="text-red-500 required-dot"></span>
        )}
      </label>
      <DatePicker
        className={`
        rounded-md
        h-8 text-sm
        border-transparent 
        flex-1 
        appearance-none 
        border 
        ${requiredValue ? `border-red-500 bg-yellow-50` : `border-gray-300`}
        w-full 
        mt-0.5
        px-2
        bg-white 
        text-gray-700 
        placeholder-gray-400 
        shadow-sm 
        focus:outline-none 
        focus:ring-2 
        focus:ring-blue-600 
        focus:border-transparent ${
          disabled
            ? `bg-gray-100 text-gray-400 cursor-not-allowed`
            : `text-gray-700 `
        } `}
        placeholderText={placeholder}
        onChange={setValue}
        selected={value}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        scrollableYearDropdown
        showTimeSelect={timePicker}
        yearDropdownItemNumber={150}
        dateFormat={dateFormat}
        onKeyDown={onKeyDown}
      />
      {errorMessage && (
        <>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="absolute text-red-500 right-2 top-9"
          />
          <p className=" text-sm text-red-500 mb-1">{errorMessage}</p>
        </>
      )}
    </>
  )
}
