import React from 'react'
import Switch from 'react-switch'

export const Toggle = ({ value, setValue, toggleValue, disabled }) => {
  return (
    <label htmlFor="small-radius-switch" className="mt-1.5 mx-2">
      <Switch
        checked={value}
        onChange={() => setValue(!value)}
        offColor="#FFF"
        onColor="#FFF"
        offHandleColor="#3F83F8"
        onHandleColor="#008000"
        height={30}
        width={65}
        handleDiameter={25}
        activeBoxShadow="0px 0px 0px 0px #fffc35"
        disabled={disabled}
        uncheckedHandleIcon={
          <div
            style={{
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              height: `100%`,
              fontSize: 20,
              color: `white`,
            }}
          >
            {toggleValue ? toggleValue[0] : `M`}
          </div>
        }
        checkedHandleIcon={
          <div
            style={{
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`,
              height: `100%`,
              fontSize: 20,
              borderRadius: `100%`,
              color: `white`,
            }}
          >
            {toggleValue ? toggleValue[1] : `C`}
          </div>
        }
        className="react-switch border-1 rounded-none"
        id="small-radius-switch "
      />
    </label>
  )
}
