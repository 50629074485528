import React, { useState, useEffect } from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PDFViewer } from '@react-pdf/renderer'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useQuery } from 'react-query'
import { Pdf } from './pdf'
import { ReactPagination } from 'App/Components/Common/Pagination'
import moment from 'moment'

export const LineOfBusiness = ({ dateRange }) => {
  const [currentPage, setCurrentPage] = useState(1)

  const { data, isLoading, error, refetch, isFetching } = useQuery(
    `LineOfBusinessApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/reports/line-of-business?search=${searchFilters()}&sort=""&page=${currentPage}&offset=25`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }

    if (dateRange[0].startDate) {
      data.filters.dateRange = [
        `${moment(dateRange[0].startDate).format(`YYYY-MM-DD`)}`,
        `${moment(dateRange[0].endDate).format(`YYYY-MM-DD`)}`,
      ]
    }
    return JSON.stringify(data)
  }

  useEffect(() => {
    refetch()
  }, [dateRange, currentPage])

  return (
    <div>
      <div className="p-2">
        <ReactPagination
          offset={25}
          currentPage={currentPage}
          totalPages={data?.totalPages}
          totalRecords={data?.totalRecords}
          curerntPageRecords={data?.reports?.length}
          onChange={(page) => setCurrentPage(page)}
        />
      </div>
      {isLoading || isFetching ? (
        <div className="flex justify-center items-center">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : data.reports?.length ? (
        <div className="px-2" style={{ minWidth: `50vw` }}>
          <PDFViewer width="100%" height="750px">
            <Pdf carrierData={data.reports} dateRange={dateRange} />
          </PDFViewer>
        </div>
      ) : (
        <div className="text-center p-10">
          <p>No Report In Given Date Range</p>
        </div>
      )}
    </div>
  )
}
