export const loadGenderOptions = () => {
  const options = {
    options: [
      { value: `MALE`, label: `MALE` },
      { value: `FEMALE`, label: `FEMALE` },
      { value: `NON_BINARY`, label: `NON BINARY` },
    ],
    hasMore: false,
  }
  return options
}
