/* eslint-disable no-unused-vars */
import { InputField } from 'App/Components/Common/InputField'
import { MaskFieldRef } from 'App/Components/Common/MaskFieldRef'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { AQS_BACKEND_API, AxiosInstance, regEx } from 'App/Config'
import { NotificationManager } from 'react-notifications'
import { loadZipCodes, ValidateZip } from 'App/Services'
import {
  loadBinaryOptions,
  loadResidenceStatus,
  loadResidenceType,
} from 'App/Services/quoteForm'

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'
import { InputFieldRef } from 'App/Components/Common/InputFieldRef'
import { AddressValidate } from 'App/Components/Common/AddressValidateInput'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import { useSelector } from 'react-redux'
import { MaskField } from 'App/Components/Common/MaskField'
import { setFirstDriver } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'
import { TrustProductsEvaluationsList } from 'twilio/lib/rest/trusthub/v1/trustProducts/trustProductsEvaluations'

export const ContactForm = forwardRef(
  ({ getData, reRated, selectedState, reRateQuote }, ref) => {
    const { leadData } = useSelector(({ lead }) => lead)
    const menuRef = useRef(null)
    const { customerData } = leadData
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({
      formSubmit: false,
      firstNameError: ``,
      lastNameError: ``,
      cellPhoneError: ``,
      residenceTypeError: ``,
      residenceStatusError: ``,
      monthsAtCurrentLocationError: ``,
      emailError: ``,
      zipError: ``,
      isPriorAddressError: ``,
      priorAddressZipError: ``,
      priorStreetAddressError: ``,
      streetNoError: ``,
      workPhoneError: ``,
      homePhoneError: ``,
      faxError: ``,
      workPhoneExtError: ``,
    })

    const [customer, setCustomer] = useState({
      firstName: useRef(null),
      middleName: useRef(null),
      lastName: useRef(null),
      cellPhone: ``,
      residenceType: { value: `APARTMENT`, label: `Apartment` },
      residenceStatus: { value: `RENT`, label: `Rent` },
      monthsAtCurrentLocation: ``,
      homePhone: ``,
      workPhone: ``,
      workPhoneExt: ``,
      fax: ``,
      email: useRef(null),
      priorAddressZip: ``,
      priorAddressState: ``,
      priorAddressCity: ``,
      priorStreetAddress: useRef(null),
      isPriorAddress: { value: `NO`, label: `NO` },
    })

    const [address, setAddress] = useState({
      unitNo: ``,
      street: useRef(null),
      city: ``,
      zip: ``,
      state: ``,
    })
    const [validatePriorStreetAddress, setValidatePriorStreetAddress] =
      useState(false)
    const [validateStreetAddress, setValidateStreetAddress] = useState(false)
    const [outOfFocus, setOutOfFocus] = useState(``)
    const [rerateQuote, setRerateQuote] = useState(false)
    const [rerateQuoteData, setRerateQuoteData] = useState(null)

    useEffect(() => {
      if (leadData && customerData) {
        setAddress((prev) => ({
          ...prev,
          zip: customerData?.address?.zip
            ? {
                value: customerData?.address?.zip,
                label: customerData?.address?.zip,
              }
            : ``,
          // street: useRef(),
          city: customerData?.address?.city ? customerData?.address?.city : ``,
          state: customerData?.address?.state
            ? customerData?.address?.state
            : ``,
        }))

        setTimeout(() => {
          if (customerData?.address?.zip) {
            address.street.current.value = customerData?.address?.street
              ? customerData?.address?.street
              : ``
          }
        }, 100)

        customer.cellPhone = customerData?.contactInfo?.phone
          ? customerData?.contactInfo?.phone
          : ``
        customer.firstName.current.value = customerData?.firstName
          ? customerData?.firstName
          : ``
        customer.lastName.current.value = customerData?.lastName
          ? customerData?.lastName
          : ``
        customer.email.current.value = customerData?.contactInfo?.email
          ? customerData?.contactInfo?.email
          : ``
      }
    }, [leadData])
    useImperativeHandle(ref, () => ({
      validateFrom() {
        const nonNumericRegex = /.*[^\d].*/
        if (!customer?.firstName?.current?.value) {
          handleErrors(`firstNameError`, `Enter First Name`)
        } else if (!nonNumericRegex.test(customer?.firstName?.current?.value)) {
          handleErrors(
            `firstNameError`,
            `First Name cannot contain numeric characters only`,
          )
        } else {
          handleErrors(`firstNameError`, ``)
        }
        if (!customer?.lastName?.current?.value?.length) {
          handleErrors(`lastNameError`, `Enter Last Name`)
        } else if (!nonNumericRegex.test(customer?.lastName?.current?.value)) {
          handleErrors(
            `lastNameError`,
            `Last Name cannot contain numeric characters only`,
          )
        } else {
          handleErrors(`lastNameError`, ``)
        }

        if (!customer?.cellPhone?.length) {
          handleErrors(`cellPhoneError`, `Enter Phone`)
        } else if (customer?.cellPhone?.length < 12) {
          handleErrors(`cellPhoneError`, `Enter Valid Phone`)
        } else {
          handleErrors(`cellPhoneError`, ``)
        }
        if (!customer.residenceType?.value?.length) {
          handleErrors(`residenceTypeError`, `Select Residence Type`)
        } else {
          handleErrors(`residenceTypeError`, ``)
        }
        if (!customer?.residenceStatus?.value?.length) {
          handleErrors(`residenceStatusError`, `Select Residence Status`)
        } else {
          handleErrors(`residenceStatusError`, ``)
        }
        if (!customer?.monthsAtCurrentLocation?.length) {
          handleErrors(`monthsAtCurrentLocationError`, `Enter Months`)
        } else if (customer?.monthsAtCurrentLocation < 1) {
          handleErrors(
            `monthsAtCurrentLocationError`,
            `Should be greater than 0`,
          )
        } else {
          handleErrors(`monthsAtCurrentLocationError`, ``)
        }

        if (!address?.zip?.value?.length) {
          handleErrors(`zipError`, `Enter Zip`)
        } else if (address?.state !== selectedState) {
          handleErrors(`zipError`, `Select valid zipcode for ${selectedState}`)
        } else {
          handleErrors(`zipError`, ``)
        }
        if (!errors?.streetNoError?.length) {
          if (!address?.street?.current?.value?.length) {
            handleErrors(`streetNoError`, `Enter Street Address`)
          } else {
            handleErrors(`streetNoError`, ``)
          }
        }
        if (!customer?.isPriorAddress?.value?.length) {
          handleErrors(`isPriorAddressError`, `Select Option`)
        } else {
          handleErrors(`isPriorAddressError`, ``)
        }
        if (
          customer?.isPriorAddress?.value?.length &&
          customer?.isPriorAddress?.value === `YES`
        ) {
          if (!customer?.priorAddressZip?.value?.length) {
            handleErrors(`priorAddressZipError`, `Select Option`)
          } else {
            handleErrors(`priorAddressZipError`, ``)
          }

          if (!customer?.priorStreetAddress?.current?.value?.length) {
            handleErrors(`priorStreetAddressError`, `Enter Street`)
          } else {
            handleErrors(`priorStreetAddressError`, ``)
          }
        } else {
          handleErrors(`priorStreetAddressError`, ``)
          handleErrors(`priorAddressZipError`, ``)
        }

        if (customer?.workPhoneExt?.current?.value?.length) {
          if (customer?.workPhoneExt?.current?.value?.length < 4) {
            handleErrors(`workPhoneExtError`, `Ext should be 4 digit`)
          } else {
            handleErrors(`workPhoneExtError`, ``)
          }
        } else {
          handleErrors(`workPhoneExtError`, ``)
        }

        if (customer?.workPhone?.length && customer?.workPhone?.length < 12) {
          handleErrors(`workPhoneError`, `Enter Valid Phone`)
        } else {
          handleErrors(`workPhoneError`, ``)
        }

        if (customer?.homePhone?.length && customer?.homePhone?.length < 12) {
          handleErrors(`homePhoneError`, `Enter Valid Phone`)
        } else {
          handleErrors(`homePhoneError`, ``)
        }
        if (
          customer?.fax?.current?.value?.length &&
          customer?.fax?.current?.value?.length < 12
        ) {
          handleErrors(`faxError`, `Enter Valid Fax`)
        } else {
          handleErrors(`faxError`, ``)
        }

        // if (!customer?.email?.current?.value?.length) {
        //   handleErrors(`emailError`, `Enter Email`)
        // }
        if (customer?.email?.current?.value?.length) {
          if (
            errors?.emailError?.length &&
            errors?.emailError?.includes(`Email Already Exists`)
          ) {
            handleErrors(`emailError`, `Email Already Exists`)
          } else {
            if (!regEx.test(customer?.email?.current?.value?.toLowerCase())) {
              handleErrors(`emailError`, `Enter Valid Email`)
            } else {
              handleErrors(`emailError`, ``)
            }
          }
        } else {
          handleErrors(`emailError`, ``)
        }
        handleErrors(`formSubmit`, true)
      },
      updateFormValues(data) {
        setRerateQuote(true)
        setRerateQuoteData(data)

        // Setting Contact Info
        const contactData = {
          firstName: data?.firstName ?? ``,
          middleName: data?.middleName ?? ``,
          lastName: data?.lastName ?? ``,
          cellPhone: data?.contactInfo?.phone ?? ``,
          residenceType: {
            value: data?.residenceType,
            label: data?.residenceType,
          },
          residenceStatus: {
            value: data?.residenceStatus,
            label: data?.residenceStatus,
          },
          monthsAtCurrentLocation: data?.monthsAtCurrentAddress ?? ``,
          homePhone: data?.contactInfo?.homePhone ?? ``,
          workPhone: data?.contactInfo?.workPhone ?? ``,
          workPhoneExt: data?.contactInfo?.workPhoneExt ?? ``,
          fax: data?.contactInfo?.fax ?? ``,
          email: data?.contactInfo?.email ?? ``,
          priorAddressZip: {
            label: data?.priorAddress?.zip,
            value: data?.priorAddress?.zip,
          },
          priorAddressState: data?.priorAddress?.state,
          priorAddressCity: data?.priorAddress?.city,
          isPriorAddress: {
            value: data?.sameCurrentPriorAddress,
            label: data?.sameCurrentPriorAddress,
          },
        }

        for (const key in contactData) {
          if (customer[key] && customer[key].current) {
            customer[key].current.value = contactData[key]
          } else if (!customer[key].current) {
            setCustomer((prevState) => {
              return { ...prevState, [key]: contactData[key] }
            })
          }
        }

        setAddress((prevState) => ({
          ...prevState,
          unitNo: data?.address?.unitNo,
          city: data?.address?.city,
          zip: { label: data?.address?.zip, value: data?.address?.zip },
          state: data?.address?.state,
        }))
      },
    }))

    useEffect(() => {
      // Setting Contact Info(Address/Zip)
      if (rerateQuote) {
        if (address.street.current) {
          address.street.current.value = rerateQuoteData?.address?.street
        }

        if (customer.priorStreetAddress.current) {
          customer.priorStreetAddress.current.value =
            rerateQuoteData?.priorAddress.street
        }
      }
    }, [address?.street?.current, customer?.priorStreetAddress?.current])

    const handleErrors = (name, error) => {
      setErrors((prevState) => ({
        ...prevState,
        [name]: error,
      }))
    }

    const handleCustomerChange = useDebouncedCallback((name, value) => {
      setCustomer((preState) => ({
        ...preState,
        [name]: value,
      }))
    }, 20)

    const handleCustomerZipChange = async (e, prior) => {
      if (prior === `priorAddress`) {
        const data = await ValidateZip(e?.value)
        if (data) {
          const res = data?.address

          setCustomer((prevState) => ({
            ...prevState,
            [`priorAddressCity`]: res.city,
            [`priorAddressState`]: res.state,
            [`priorAddressZip`]: { value: res.zipCode, label: res.zipCode },
          }))
        }
      } else {
        const data = await ValidateZip(e?.value)
        if (data) {
          const res = data?.address
          if (res.state !== selectedState) {
            handleErrors(
              `zipError`,
              `Select valid zipcode for ${selectedState}`,
            )
            setAddress((prevState) => ({
              ...prevState,
              [`city`]: ``,
              [`state`]: res.state,
              [`zip`]: { value: res.zipCode, label: res.zipCode },
            }))
          } else {
            handleErrors(`zipError`, ``)
            setAddress((prevState) => ({
              ...prevState,
              [`city`]: res.city,
              [`state`]: res.state,
              [`zip`]: { value: res.zipCode, label: res.zipCode },
            }))
          }
        }
      }
    }

    const validateAddressPrior = useDebouncedCallback(async () => {
      setValidatePriorStreetAddress(true)
      const data = await ValidateStreetAddress({
        street: customer?.priorStreetAddress?.current?.value,
        city: customer?.priorAddressCity,
        zip: customer?.priorAddressZip?.value,
        state: customer?.priorAddressState,
      })
      if (!data?.result?.isValid) {
        setValidatePriorStreetAddress(false)
        handleErrors(`priorStreetAddressError`, `Enter Valid Street`)
      } else {
        setValidatePriorStreetAddress(false)
        handleErrors(`priorStreetAddressError`, ``)
      }
    }, 700)

    const validateAddress = useDebouncedCallback(async () => {
      setValidateStreetAddress(true)
      const data = await ValidateStreetAddress({
        street: address?.street?.current?.value,
        city: address?.city,
        zip: address?.zip?.value,
        state: address?.state,
      })

      if (!data?.result?.isValid) {
        setValidateStreetAddress(false)
        handleErrors(`streetNoError`, `Enter Valid Street`)
      } else {
        setValidateStreetAddress(false)
        handleErrors(`streetNoError`, ``)
      }
    }, 700)

    const handleAddressChange = async (streetAddress) => {
      if (streetAddress === `priorStreetAddress`) {
        handleErrors(`priorStreetAddressError`, ``)

        if (customer?.priorStreetAddress?.current?.value) {
          validateAddressPrior()
        }
      } else {
        handleErrors(`streetNoError`, ``)

        if (address?.street?.current?.value) {
          validateAddress()
        }
      }
    }

    useEffect(() => {
      let formValidated = false
      if (errors.formSubmit) {
        if (
          !errors.emailError.length &&
          !errors.firstNameError.length &&
          !errors.lastNameError.length &&
          !errors.cellPhoneError.length &&
          !errors.residenceTypeError.length &&
          !errors.residenceStatusError.length &&
          !errors.monthsAtCurrentLocationError.length &&
          !errors.streetNoError.length &&
          !errors.zipError.length &&
          !errors.isPriorAddressError.length &&
          !errors.priorAddressZipError.length &&
          !errors.priorStreetAddressError.length &&
          !errors.workPhoneError.length &&
          !errors.homePhoneError.length &&
          !errors.faxError.length &&
          !errors.workPhoneExtError.length
        ) {
          formValidated = true
        } else {
          formValidated = false
        }

        if (formValidated) {
          getData(`customer`, customer)
          getData(`customerAddress`, address)
          handleErrors(`formSubmit`, false)
        } else {
          getData(`customer`, null)
          getData(`customerAddress`, null)
          handleErrors(`formSubmit`, false)
        }
      }
    }, [errors])

    const ValidateStreetAddress = async (address) => {
      try {
        const {
          data: { data },
        } = await AxiosInstance.post(`${AQS_BACKEND_API}/validate/address`, {
          address: {
            ...address,
          },
        })
        return data
      } catch (error) {
        NotificationManager.error(error?.response?.data?.message)
      }
    }

    useEffect(() => {
      dispatch(
        setFirstDriver({
          firstName: customer?.firstName?.current?.value,
          middleName: customer?.middleName?.current?.value,
          lastName: customer?.lastName?.current?.value,
          address: {
            state: address.state,
            zip: address.zip,
            city: address.city,
            street:
              address.street?.current?.value ?? customerData?.address?.street,
          },
        }),
      )
    }, [outOfFocus, address.zip, leadData])

    return (
      <CollapsableRef title="Client Contact Info" isActive={true}>
        <div className="flex gap-6 mt-2 text-xs" ref={menuRef}>
          <div className="flex-1 relative">
            <InputFieldRef
              placeholder="First Name"
              type="text"
              title="First Name *"
              inputRef={customer?.firstName}
              value={customer?.firstName?.current?.value}
              requiredValue={!customer?.firstName?.current?.value?.length}
              errorMessage={errors?.firstNameError}
              checkFocus={true}
              setFocusOut={(value) => setOutOfFocus(value)}
              disabled={reRated || reRateQuote}
            />
          </div>
          <div className="flex-1">
            <InputFieldRef
              placeholder="Middle Name"
              type="text"
              title="Middle Name"
              inputRef={customer?.middleName}
              value={customer?.middleName?.current?.value}
              checkFocus={true}
              setFocusOut={(value) => setOutOfFocus(value)}
              disabled={reRated}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <InputFieldRef
              placeholder="Last Name"
              type="text"
              title="Last Name *"
              inputRef={customer?.lastName}
              errorMessage={errors?.lastNameError}
              requiredValue={!customer?.lastName?.current?.value?.length}
              checkFocus={true}
              setFocusOut={(value) => setOutOfFocus(value)}
              disabled={reRated}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              mask="+19999999999"
              type="text"
              title="Phone *"
              placeholder="Enter Phone"
              errorMessage={errors?.cellPhoneError}
              requiredValue={!customer?.cellPhone?.length}
              value={customer?.cellPhone}
              setValue={(e) => {
                handleCustomerChange(`cellPhone`, e.target.value)
              }}
              disabled={reRated}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              placeholder="Select Zip"
              errorMessage={errors?.zipError}
              isMulti={false}
              value={address.zip}
              setValue={(e) => handleCustomerZipChange(e)}
              requiredValue={!address.zip?.value?.length}
              loadOptions={selectedState && loadZipCodes}
              isSearchable={true}
              disabled={reRated}
              operatingState={selectedState}
              cacheUniqs={selectedState}
            />
          </div>
          {address?.zip?.value?.length && !errors?.zipError?.length && (
            <div className="flex-1 relative">
              <AddressValidate
                inputRef={address?.street}
                type="text"
                title="Street *"
                validating={validateStreetAddress}
                requiredValue={!address?.street?.current?.value?.length}
                placeholder="Enter Street"
                errorMessage={errors?.streetNoError}
                setValue={() => handleAddressChange()}
                checkFocus={true}
                setFocusOut={(value) => setOutOfFocus(value)}
                disabled={reRated}
              />
            </div>
          )}
        </div>
        {address?.zip?.value?.length && !errors?.zipError?.length && (
          <div className="flex gap-6 mt-2 text-xs">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="City"
                placeholder="Enter City"
                errorMessage={errors?.cityError}
                disabled={true}
                name="city"
                value={address?.city}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="State"
                placeholder="Enter State"
                errorMessage={errors?.stateError}
                disabled={true}
                name="state"
                value={address?.state}
              />
            </div>
          </div>
        )}
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Prior Address"
              placeholder="Select Option"
              value={customer?.isPriorAddress}
              setValue={(value) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  isPriorAddress: value,
                }))
              }}
              errorMessage={errors?.isPriorAddressError}
              requiredValue={!customer?.isPriorAddress?.value?.length}
              loadOptions={loadBinaryOptions}
              isSearchable={false}
              isMulti={false}
              disabled={reRated}
            />
          </div>
        </div>
        {customer?.isPriorAddress?.value == `YES` && (
          <>
            <div className="flex gap-6 mt-2 text-xs">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Prior Address Zip"
                  placeholder="Select Zip"
                  errorMessage={errors?.priorAddressZipError}
                  requiredValue={!customer?.priorAddressZip?.value?.length}
                  isMulti={false}
                  value={customer?.priorAddressZip}
                  setValue={(e) => handleCustomerZipChange(e, `priorAddress`)}
                  loadOptions={selectedState && loadZipCodes}
                  isSearchable={true}
                  disabled={reRated}
                  operatingState={selectedState}
                />
              </div>
              <div className="flex-1 relative">
                <AddressValidate
                  placeholder="Prior Street Address"
                  validating={validatePriorStreetAddress}
                  type="text"
                  title="Prior Street Address"
                  inputRef={customer?.priorStreetAddress}
                  errorMessage={errors?.priorStreetAddressError}
                  requiredValue={
                    !customer?.priorStreetAddress?.current?.value?.length
                  }
                  setValue={() => handleAddressChange(`priorStreetAddress`)}
                  disabled={reRated}
                />
              </div>
            </div>
            <div className="flex gap-6 mt-2 text-xs">
              <div className="flex-1 relative">
                <InputField
                  placeholder="Prior Address City"
                  type="text"
                  title="Prior Address City"
                  disabled={true}
                  value={customer?.priorAddressCity}
                  setValue={(e) =>
                    handleCustomerChange(`priorAddressCity`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  placeholder="Prior Address State"
                  type="text"
                  title="Prior Address State"
                  disabled={true}
                  value={customer?.priorAddressState}
                  setValue={(e) =>
                    handleCustomerChange(`priorAddressState`, e.target.value)
                  }
                />
              </div>
            </div>
          </>
        )}
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Residence Type"
              placeholder="Residence Type"
              isMulti={false}
              value={customer.residenceType}
              errorMessage={errors?.residenceTypeError}
              requiredValue={!customer?.residenceType?.value?.length}
              setValue={(e) => handleCustomerChange(`residenceType`, e)}
              loadOptions={loadResidenceType}
              isSearchable={true}
              disabled={reRated}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Residence Status"
              placeholder="Residence Status"
              isMulti={false}
              value={customer.residenceStatus}
              requiredValue={!customer?.residenceStatus?.value?.length}
              errorMessage={errors?.residenceStatusError}
              setValue={(e) => handleCustomerChange(`residenceStatus`, e)}
              loadOptions={loadResidenceStatus}
              isSearchable={true}
              disabled={reRated}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Time at Residence *"
              placeholder="Time at Residence (in Months) "
              errorMessage={errors?.monthsAtCurrentLocationError}
              mask="999"
              value={customer?.monthsAtCurrentLocation}
              setValue={(e) => {
                handleCustomerChange(`monthsAtCurrentLocation`, e.target.value)
              }}
              disabled={reRated}
              requiredValue={!customer?.monthsAtCurrentLocation?.length}
            />
            {/* <MaskFieldRef
            title="Time at Residence *"
            placeholder="Time at Residence (in Months) "
            setValue={(e) =>
              handleCustomerChange(`monthsAtCurrentLocation`, e.target.value)
            }
            inputRef={(element) => {
              customer.monthsAtCurrentLocation.current = element
            }}
            // requiredValue={
            //   !customer?.monthsAtCurrentLocation.current?.value?.length
            // }
            errorMessage={errors?.monthsAtCurrentLocationError}
            type="text"
            mask="999"
          /> */}
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Home Phone"
              placeholder="Home Phone"
              errorMessage={errors?.homePhoneError}
              value={customer?.homePhone}
              setValue={(e) => {
                handleCustomerChange(`homePhone`, e.target.value)
              }}
              disabled={reRated}
            />
            {/* <MaskFieldRef
            title="Home Phone"
            placeholder="Home Phone"
            errorMessage={errors?.homePhoneError}
            type="text"
            inputRef={(element) => {
              customer.homePhone.current = element
            }}
          /> */}
          </div>
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Work Phone"
              placeholder="Work Phone"
              errorMessage={errors?.workPhoneError}
              value={customer?.workPhone}
              setValue={(e) => {
                handleCustomerChange(`workPhone`, e.target.value)
              }}
              disabled={reRated}
            />
            {/* <MaskFieldRef
            title="Work Phone"
            placeholder="Work Phone"
            errorMessage={errors?.workPhoneError}
            type="text"
            inputRef={(element) => {
              customer.workPhone.current = element
            }}
          /> */}
          </div>

          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Work Phone Ext"
              mask="9999"
              placeholder="Work Phone Ext"
              errorMessage={errors?.workPhoneExtError}
              value={customer?.workPhoneExt}
              setValue={(e) => {
                handleCustomerChange(`workPhoneExt`, e.target.value)
              }}
              disabled={reRated}
            />
            {/* <MaskFieldRef
            mask="9999"
            title="Work Phone Ext"
            placeholder="Work Phone Ext"
            errorMessage={errors?.workPhoneExtError}
            type="text"
            inputRef={(element) => {
              customer.workPhoneExt.current = element
            }}
          /> */}
          </div>
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Fax"
              placeholder="Fax"
              errorMessage={errors?.faxError}
              value={customer?.fax}
              setValue={(e) => {
                handleCustomerChange(`fax`, e.target.value)
              }}
              disabled={reRated}
            />
            {/* <MaskFieldRef
            title="Fax"
            placeholder="Fax"
            errorMessage={errors?.faxError}
            type="text"
            inputRef={(element) => {
              customer.fax.current = element
            }}
          /> */}
          </div>
          <div className="flex-1 relative">
            <InputFieldRef
              title="Email"
              placeholder="Email"
              errorMessage={errors?.emailError}
              // requiredValue={!customer?.email?.current?.value?.length}
              type="text"
              inputRef={customer?.email}
              disabled={reRated}
            />
          </div>
        </div>
      </CollapsableRef>
    )
  },
)

ContactForm.displayName = `ContactForm`
