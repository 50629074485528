import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeDriverTab: 1,
  activeViolationTab: 1,
  activeVehicleTab: 1,
  vehicleCoverage: [],
  drivers: [],
  _drivers: [],
  ratingCarriers: [],
  firstDriverInfo: ``,
  vehiclesData: [],
  supportedViolations: [],
}

const quoteFormSlice = createSlice({
  name: `quoteForm`,

  initialState,
  reducers: {
    setActiveDriverTab: (state, { payload }) => {
      state.activeDriverTab = payload
    },
    setActiveViolationTab: (state, { payload }) => {
      state.activeViolationTab = payload
    },
    setActiveVehicleTab: (state, { payload }) => {
      state.activeVehicleTab = payload
    },
    setVehicleCoverage: (state, { payload }) => {
      state.vehicleCoverage = payload
    },
    setDriversInfo: (state, { payload }) => {
      state.drivers = payload
    },
    setDriversInsured: (state, { payload }) => {
      state._drivers = payload
    },
    setFirstDriver: (state, { payload }) => {
      state.firstDriverInfo = payload
    },
    setRatingCarriers: (state, { payload }) => {
      state.ratingCarriers = payload
    },
    setVehicleData: (state, { payload }) => {
      state.vehiclesData = payload
    },
    setUpdateRatingCarrier: (state, { payload }) => {
      const updatedCarriers = state.ratingCarriers.map((_eachCarrier) => {
        if (
          _eachCarrier.carrierName === payload?.row?.carrierName &&
          _eachCarrier.id === payload?.row?.id
        ) {
          _eachCarrier[payload.fieldName] = payload.fieldValue.value
        }

        return _eachCarrier
      })
      state.ratingCarriers = updatedCarriers
    },
    setSupportedViolations: (state, { payload }) => {
      state.supportedViolations = payload
    },
  },
})

export const {
  setActiveDriverTab,
  setActiveViolationTab,
  setActiveVehicleTab,
  setVehicleCoverage,
  setDriversInfo,
  setDriversInsured,
  setFirstDriver,
  setRatingCarriers,
  setUpdateRatingCarrier,
  setVehicleData,
  setSupportedViolations,
} = quoteFormSlice.actions

export const quoteForm = quoteFormSlice.reducer
