import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { DriverNote } from '../DriverNote'
import { Logs } from 'App/Components/Common/Logs'
import { setDriverAsset } from 'App/Redux/actions'
import { Reload } from 'App/Components/Common/Reload'
import { useHistory, useRouteMatch } from 'react-router'
import { CardField } from 'App/Components/Common/CardField'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Container, EditStyledButton, PageHeader } from 'App/Styled'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { permissionGranted } from 'App/Services'

export const Driver = () => {
  const {
    url,
    params: { driverId, customerId },
  } = useRouteMatch()

  const history = useHistory()
  const dispatch = useDispatch()

  const {
    data: driverData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `DriverApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/assets/${customerId}/type/drivers/item/${driverId}`,
      ).then((res) => {
        return res.data.data.item.drivers
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setDriverAsset(res))
      },
    },
  )

  return (
    <div>
      <Container>
        <PageHeader>
          <div>Driver</div>
          {permissionGranted([
            `assets:update`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && (
            <EditStyledButton onClick={() => history.push(`${url}/update`)}>
              <span>Edit</span>
              <FontAwesomeIcon
                icon={faPen}
                className="text-white mt-0.5"
                size="sm"
              />
            </EditStyledButton>
          )}
        </PageHeader>
        <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md">
          {isLoading || isFetching ? (
            <div className="text-center py-10">
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                size="1x"
                className="text-red-500"
              />
            </div>
          ) : error ? (
            <Reload refetch={() => refetch()} />
          ) : driverData?.location?.length ? (
            <p className=" text-center p-6">No Location Found</p>
          ) : (
            <div className="m-2 pb-4 ">
              <div className="flex items-start justify-evenly ">
                <div className="flex-1 mx-2 shadow-lg rounded">
                  <Collapsable title="about">
                    <CardField title="Driver Name" value={driverData?.name} />
                    <CardField title="Prefix" value={driverData?.prefix} />
                    <CardField title="Suffix" value={driverData?.sufix} />
                    <CardField title="Gender" value={driverData.gender} />
                    <CardField title="Driver Email" value={driverData.email} />
                    <CardField title="DOB" value={driverData.dateOfBirth} />
                    <CardField title="Education" value={driverData.education} />
                    <CardField
                      title="Marital Status"
                      value={driverData.maritalStatus}
                    />
                  </Collapsable>
                </div>
                <div className="flex-1 mx-2 shadow-lg rounded">
                  <Collapsable title="Address">
                    <CardField
                      title="Unit No"
                      value={driverData?.address?.unitNo}
                    />
                    <CardField title="Zip" value={driverData?.address?.zip} />
                    <CardField
                      title="Street"
                      value={driverData?.address?.street}
                    />
                    <CardField title="City" value={driverData?.address?.city} />
                    <CardField
                      title="State"
                      value={driverData?.address?.state}
                    />
                    <CardField
                      title="Coordinates"
                      value={driverData?.address?.coordinates}
                      coord={true}
                    />
                  </Collapsable>
                </div>
              </div>
              <div className="flex items-start justify-evenly mt-4">
                <div className="flex-1 mx-2 shadow-lg rounded">
                  <Collapsable title="Driver License Info">
                    <CardField
                      title="Driving License Year"
                      value={driverData?.DLYear}
                    />
                    <CardField
                      title="Driving License Number"
                      value={driverData?.DLNumber}
                    />
                    <CardField
                      title="Driving License State"
                      value={driverData?.DLState}
                    />
                    <CardField
                      title="Driving License Date"
                      value={driverData.licensedDate}
                    />
                    <CardField
                      title="First Licensed Current State"
                      value={driverData.firstLicensedCurrentState}
                    />
                  </Collapsable>
                </div>
                <div className="flex-1 mx-2 shadow-lg rounded">
                  <Collapsable title="Industry Info">
                    <CardField title="Industry" value={driverData?.industry} />
                    <CardField title="Hire Date" value={driverData?.hireDate} />
                    <CardField
                      title="Termination Date"
                      value={driverData?.terminationDate}
                    />
                    <CardField
                      title="Company Driver No"
                      value={driverData?.companyDriverNumber}
                    />
                    <CardField
                      title="Country Code"
                      value={driverData?.countryCode}
                    />
                  </Collapsable>
                </div>
              </div>
              <div className="flex items-start justify-evenly mt-4">
                <div className="flex-1 mx-2 shadow-lg rounded">
                  <Collapsable title="General">
                    <CardField title="Extension" value={driverData?.ext} />
                    <CardField title="SSN" value={driverData?.ssn} />
                    <CardField
                      title="Assigned Risk Reason Code"
                      value={driverData?.assignedRiskReasonCode}
                    />
                    <CardField
                      title="Driver Assigned Risk Indicator"
                      value={driverData?.driverAssignedRiskIndicator}
                    />
                    <CardField
                      title="Defensive Driver Date"
                      value={driverData.defensiveDriverDate}
                    />
                    <CardField
                      title="Defensive Driver Code"
                      value={driverData.defensiveDriverCode}
                    />
                    <CardField
                      title="Defensive Driver Expiration Date"
                      value={driverData.defensiveDriverExpirationDate}
                    />
                    <CardField
                      title="Distant Student Indicator"
                      value={driverData.distantStudentIndicator}
                    />
                    <CardField
                      title="Driver Relationship To Applicant Code"
                      value={driverData.driverRelationshipToApplicantCode}
                    />
                    <CardField
                      title="Driver Training Completion Date"
                      value={driverData.driverTrainingCompletionDate}
                    />
                    <CardField
                      title="Driver Training Indicator"
                      value={driverData.driverTrainingIndicator}
                    />
                    <CardField
                      title="Driver Type Code"
                      value={driverData.driverTypeCode}
                    />
                    <CardField
                      title="Reinstatement Date"
                      value={driverData.reinstatementdate}
                    />
                    <CardField
                      title="Restricted Indicator"
                      value={driverData.restrictedIndicator}
                    />
                    <CardField
                      title="Suspension Revocation Date"
                      value={driverData.suspensionRevocationDate}
                    />
                    <CardField
                      title="Suspension Revocation Reason Code"
                      value={driverData.suspensionRevocationReasonCode}
                    />
                  </Collapsable>
                </div>
                <div className="flex-1 mx-2 shadow-lg rounded">
                  <Collapsable title="Violations">
                    {driverData?.violations.map((violaion) => {
                      return (
                        <div key={violaion._id}>
                          <CardField
                            title="Extension"
                            value={violaion?.driverViolationCode}
                          />
                          <CardField
                            title="SSN"
                            value={violaion?.description}
                          />
                          <CardField
                            title="Assigned Risk Reason Code"
                            value={violaion?.violationDate}
                          />
                        </div>
                      )
                    })}
                  </Collapsable>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
      <div className="flex -mt-3">
        <div className="flex-1">
          <DriverNote />
        </div>
        <div className="flex-1">
          <Logs logs={driverData?.updateLogs ? driverData?.updateLogs : []} />
        </div>
      </div>
    </div>
  )
}
