import React, { useContext } from 'react'
import { Container } from 'App/Styled'
import { CardField } from 'App/Components/Common/CardField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { useRouteMatch } from 'react-router'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { Reload } from 'App/Components/Common/Reload'
// import { AllPayments } from 'App/Components/Payment/AllPayments'
// import { AllPolicies } from 'App/Components/OtherPolicies/AllPolicies'
import { CustomerContext } from '../../..'
import dateFormat from 'App/Services/General/dateFormat'

export const About = () => {
  // const {
  //   params: { customerId },
  // } = useRouteMatch()

  const { currentCustomer } = useSelector(({ customer }) => customer)
  const { isLoading, isFetching, refetch, error } = useContext(CustomerContext)
  return (
    <>
      <Container>
        <div className="flex flex-col gap-0 ml-16">
          <div className="grid grid-cols-12 px-4 py-2">
            <div className="col-span-6 text-xl">Customer Info</div>
          </div>
          <hr />

          <div className="flex flex-col px-2">
            {isLoading || isFetching ? (
              <div className="text-center py-10">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="1x"
                  className="text-red-500"
                />
              </div>
            ) : error ? (
              <Reload refetch={() => refetch()} />
            ) : (
              <div className="flex flex-row">
                <div className="flex-1">
                  <div className="rounded-md overflow-hidden py-2">
                    <div className="flex items-start justify-evenly">
                      <div className="flex-1 mx-2 rounded">
                        <div className="flex">
                          <div className="flex-1">
                            <CardField
                              title="Gender"
                              value={currentCustomer?.gender?.replace(`_`, ` `)}
                            />

                            {/* <CardField
                              title="Education"
                              value={currentCustomer?.educationLevel}
                            /> */}
                            {/* <CardField
                              title="Insured Id"
                              value={currentCustomer?.insuredId}
                            /> */}
                            <CardField
                              title="Status"
                              value={currentCustomer?.status}
                            />

                            {/* <CardField
                              title="Franchise"
                              value={currentCustomer?._location?.franchiseName}
                            /> */}

                            <CardField
                              title="Work Phone"
                              value={currentCustomer?.contactInfo?.workPhone}
                            />
                            {/* <CardField
                              title="Work Phone Ext"
                              value={currentCustomer?.contactInfo?.workPhoneExt}
                            /> */}
                            <CardField
                              title="Fax"
                              value={currentCustomer?.contactInfo?.fax}
                            />
                            {/* <CardField
                              title="Language"
                              value={currentCustomer?.preferredLanguage}
                            /> */}
                            {/* <CardField
                              title="Date of birth"
                              value={
                                currentCustomer?.dob &&
                                moment(currentCustomer?.dob).format(
                                  `YYYY-MM-DD`,
                                )
                              }
                            /> */}
                          </div>
                          <div className="flex-1">
                            {/* <CardField
                              title="FEIN"
                              value={currentCustomer?.fein}
                            /> */}
                            {/* <CardField
                              title="SIC"
                              value={currentCustomer?.sic}
                            /> */}

                            {/* <CardField
                              title="Type of Business"
                              value={currentCustomer?.typeOfBusiness}
                            /> */}
                            {/* <CardField
                              title="Marital Status"
                              value={currentCustomer?.maritalStatus}
                            /> */}

                            <CardField
                              title="Legacy Id"
                              value={currentCustomer?.legacyId}
                            />
                            <CardField
                              title="PCM"
                              value={currentCustomer?.preferredContactMethods}
                              multiple={true}
                            />
                            {/* <CardField
                              title="Occupation"
                              value={currentCustomer?.occupation}
                            /> */}
                            {/* <CardField
                              title="Customer Type"
                              value={currentCustomer?.type}
                            /> */}
                            {/* <CardField
                              title="Years of Business Started"
                              value={currentCustomer?.yearBusinessStarted}
                            /> */}
                            {/* <CardField
                              title="Coordinates"
                              value={currentCustomer?.address?.coordinates}
                              coord={true}
                            /> */}

                            {/* <CardField
                              title="Acquisition Date"
                              value={
                                currentCustomer?.acquisitionDate &&
                                moment(currentCustomer?.acquisitionDate).format(
                                  `YYYY-MM-DD`,
                                )
                              }
                            /> */}
                            <CardField
                              title="Created"
                              value={dateFormat(currentCustomer?.createdAt)}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="flex-1 mx-2 shadow-lg rounded">
                        <Collapsable title="Address">
                          <CardField
                            title="Unit No"
                            value={currentCustomer?.address.unitNo}
                          />

                          <CardField
                            title="Zip Code"
                            value={currentCustomer?.address.zip}
                            badge={true}
                          />

                          <CardField
                            title="Street"
                            value={currentCustomer?.address.street}
                          />

                          <CardField
                            title="City"
                            value={currentCustomer?.address.city}
                          />

                          <CardField
                            title="State"
                            value={currentCustomer?.address.state}
                          />

                          <CardField
                            title="Coordinates"
                            value={currentCustomer?.address.coordinates}
                            coord={true}
                          />
                        </Collapsable>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="ml-12 mr-10">
          <div className="mt-2 mb-2">
            <CollapsableRef title="Policies">
              <AllPolicies headerColor="yes" />
            </CollapsableRef>
          </div>
        </div> */}
      </Container>
    </>
  )
}
