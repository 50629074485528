import React from 'react'

export const Tabs2 = ({ openTab, tabs, getActiveTab, dashboardTab }) => {
  return (
    <div className="tabs">
      {tabs.length > 0 &&
        tabs?.map((tab, index) => (
          <div key={index}>
            <div
              onClick={(e) => {
                e.preventDefault()
                getActiveTab(tab.number)
              }}
              className={`tab tab-lifted text-md font-bold ${
                openTab
                  ? openTab === tab.number && `tab-active primary`
                  : dashboardTab === tab.number && `tab dashboard-tab`
              }`}
            >
              {tab.name}
            </div>
          </div>
        ))}
    </div>
  )
}
