import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { defaultImage } from 'App/Assets'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL } from 'App/Config'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory } from 'react-router'
import { fileResizer, loadZipCodes } from 'App/Services'
import { MaskField } from 'App/Components/Common/MaskField'
import { InputField } from 'App/Components/Common/InputField'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ValidateZip } from 'App/Services'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, Image, PageHeader, SubmitButton } from 'App/Styled'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import { useDebouncedCallback } from 'use-debounce/lib'
import { NotificationManager } from 'react-notifications'

export const UpdateProfile = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [agent, setAgent] = useState({
    img: ``,
    firstName: ``,
    middleName: ``,

    lastName: ``,
    gender: ``,
    phone: ``,
    workPhone: ``,
    ext: ``,
    fax: ``,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    streetNo: ``,
    city: ``,
    zip: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })
  const [errors, setErrors] = useState({
    formSubmit: false,
    dpError: ``,
    firstNameError: ``,
    lastNameError: ``,
    genderError: ``,
    phoneError: ``,
    workPhoneError: ``,
    extError: ``,
    faxError: ``,
    unitNoError: ``,
    streetNoError: ``,
    cityError: ``,
    zipError: ``,
    stateError: ``,
    coordinatesError: false,
  })

  const history = useHistory()
  const profileImageRef = useRef(null)

  const { agentProfile } = useSelector(({ agent }) => agent)
  // const user = useSelector(({ user: { user } }) => user)

  useEffect(() => {
    if (agentProfile) {
      setAgent((prevState) => ({
        ...prevState,
        img: agentProfile?.img,
        firstName: agentProfile?.firstName,
        middleName: agentProfile?.middleName ? agentProfile?.middleName : ``,
        lastName: agentProfile?.lastName,
        gender: { value: agentProfile?.gender, label: agentProfile.gender },
        phone: agentProfile?.contactInfo?.phone,
        workPhone: agentProfile?.contactInfo?.workPhone,
        ext: agentProfile?.contactInfo?.workPhoneExt,
        fax: agentProfile?.contactInfo?.fax,
      }))
      setAddress((prevState) => ({
        ...prevState,
        unitNo: agentProfile?.address?.unitNo,
        streetNo: agentProfile?.address?.street,
        city: agentProfile?.address?.city,
        zip: {
          value: agentProfile?.address?.zip,
          label: agentProfile?.address?.zip,
        },
        state: agentProfile?.address?.state,
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        lat: agentProfile?.address?.coordinates?.lat,
        lng: agentProfile.address?.coordinates?.long,
      }))
    }
  }, [agentProfile])

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.dpError.length &&
      !errors.firstNameError.length &&
      !errors.lastNameError.length &&
      !errors.genderError.length &&
      !errors.extError.length &&
      !errors.phoneError.length &&
      !errors.workPhoneError.length &&
      !errors.faxError.length &&
      !errors.zipError.length &&
      !errors.streetNoError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.coordinatesError
    ) {
      updateAgent(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}
    if (agent.img !== agentProfile.img) {
      data.img = agent.img
    }
    if (agent.firstName !== agentProfile.firstName) {
      data.firstName = agent.firstName
    }

    if (
      (agent.middleName || agentProfile.middleName) &&
      agent.middleName !== agentProfile.middleName
    ) {
      data.middleName = agent.middleName
    }
    if (agent.lastName !== agentProfile.lastName) {
      data.lastName = agent.lastName
    }

    if (agent?.gender?.value !== agentProfile.gender) {
      data.gender = agent?.gender?.value
    }
    if (
      agent.phone !== agentProfile.contactInfo?.phone ||
      agent.workPhone !== agentProfile.contactInfo?.workPhone ||
      agent.ext !== agentProfile?.contactInfo?.workPhoneExt ||
      agent.fax !== agentProfile?.contactInfo?.fax
    ) {
      data.contactInfo = {}
      if (agent.phone !== agentProfile.contactInfo?.phone) {
        data.contactInfo.phone = agent.phone
      }
      if (agent.workPhone !== agentProfile.contactInfo?.workPhone) {
        data.contactInfo.workPhone = agent.workPhone
      }
      if (agent.fax !== agentProfile?.contactInfo?.fax) {
        data.contactInfo.fax = agent.fax
      }

      if (agent.ext !== agentProfile?.contactInfo?.workPhoneExt) {
        data.contactInfo.workPhoneExt = agent.ext
      }
    }

    // address payload
    const addressPayload = {}
    let coordinatesPayload = {}

    if (address.unitNo !== agentProfile?.address?.unitNo) {
      addressPayload.unitNo = address?.unitNo
    }
    if (address.streetNo !== agentProfile?.address?.street) {
      addressPayload.street = address?.streetNo
    }
    if (address.city !== agentProfile?.address?.city) {
      addressPayload.city = address?.city
    }

    if (address?.zip?.value !== agentProfile?.address?.zip) {
      addressPayload.zip = address?.zip?.value
    }

    if (address?.zip?.value !== agentProfile?.address?.zip) {
      // addressPayload.zip = address?.zip?.value
      coordinatesPayload.lat = coordinates.lat.toString()
      coordinatesPayload.long = coordinates.lng.toString()
    }
    if (address.state !== agentProfile?.address?.state) {
      addressPayload.state = address.state
    }
    if (Object.keys(addressPayload).length !== 0) {
      addressPayload.coordinates = coordinatesPayload
      data.address = addressPayload
    }
    return data
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const {
    mutate: updateAgent,
    isLoading: updatingAgent,
    isError: updatingError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(`${AMS_BACKEND_API}/api/users/profile`, {
        ...payload,
      })
        .then((res) => {
          if (res.data) {
            history.push(`/profile`)
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            } else {
              NotificationManager.error(error?.response.data.error.error)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleAgentInput = useDebouncedCallback((name, value) => {
    setAgent((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleAddressInput = async (e, name) => {
    if (name) {
      setIsLoading(true)
      const data = await ValidateZip(e?.value)
      if (data) {
        setIsLoading(false)
        const res = data.address
        setAddress((prevState) => ({
          ...prevState,
          [`city`]: res.city,
          [`state`]: res.state,
        }))
        setAddress((prevState) => ({
          ...prevState,
          [name]: e,
        }))

        //setting coordinates
        setCoordinates((prevState) => ({
          ...prevState,
          [`lat`]: res.lat ? res.lat : ``,
          [`lng`]: res.long ? res.long : ``,
        }))
      }
    } else {
      const { name, value } = e.target
      setAddress((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  const loadGenderOptions = () => {
    const options = {
      options: [
        { value: `MALE`, label: `MALE` },
        { value: `FEMALE`, label: `FEMALE` },
        { value: `NON_BINARY`, label: `NON BINARY` },
      ],
      hasMore: false,
    }
    return options
  }
  const handleLogo = async (file) => {
    const base64 = await fileResizer(file[0])
    setAgent((prevState) => ({
      ...prevState,
      [`img`]: base64,
    }))
    profileImageRef.current.value = null
  }

  const ValidateForm = () => {
    if (!agent.img?.length) {
      handleErrors(`imgError`, `Upload Image`)
    } else {
      handleErrors(`imgError`, ``)
    }

    if (!agent.franchise?.value?.length) {
      handleErrors(`franchiseError`, `Select Franchise`)
    } else {
      handleErrors(`franchiseError`, ``)
    }

    if (!agent.locations?.length) {
      handleErrors(`locationsError`, `Select Locations`)
    } else {
      handleErrors(`locationsError`, ``)
    }

    if (!agent.firstName.length) {
      handleErrors(`firstNameError`, `Enter First name`)
    } else {
      handleErrors(`firstNameError`, ``)
    }

    if (!agent.lastName.length) {
      handleErrors(`lastNameError`, `Enter Last name`)
    } else {
      handleErrors(`lastNameError`, ``)
    }

    if (!agent.gender?.value?.length) {
      handleErrors(`genderError`, `Select Gender`)
    } else {
      handleErrors(`genderError`, ``)
    }

    if (!agent.ext.length) {
      handleErrors(`extError`, `Enter Extension`)
    } else if (agent.ext.length) {
      if (isNaN(agent.ext)) {
        handleErrors(`extError`, `Ext Should be a number`)
      } else if (agent.ext.length < 4) {
        handleErrors(`extError`, `Ext length should be less than 4`)
      } else {
        handleErrors(`extError`, ``)
      }
    } else {
      handleErrors(`extError`, ``)
    }
    if (
      agent?.phone !== agentProfile?.contactInfo?.phone &&
      agent?.phone.length < 12
    ) {
      handleErrors(`phoneError`, `Enter Valid Phone`)
    } else {
      handleErrors(`phoneError`, ``)
    }
    if (agent?.workPhone?.length && agent?.workPhone?.length < 12) {
      handleErrors(`workPhoneError`, `Enter Valid Phone`)
    } else {
      handleErrors(`workPhoneError`, ``)
    }

    if (agent?.fax?.length && agent?.fax?.length < 12) {
      handleErrors(`faxError`, `Enter Valid Fax No`)
    } else {
      handleErrors(`faxError`, ``)
    }
    // if (!address?.unitNo?.toString().length) {
    //   handleErrors(`unitNoError`, `Enter Unit No`)
    // } else {
    //   handleErrors(`unitNoError`, ``)
    // }

    if (!address.zip?.value?.length) {
      handleErrors(`zipError`, `Select Zip Code`)
    } else {
      handleErrors(`zipError`, ``)
    }

    if (!address.streetNo?.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }

    if (!address?.city?.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }

    if (!address?.state?.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, true)
    } else {
      handleErrors(`coordinatesError`, false)
    }
    handleErrors(`formSubmit`, true)
  }
  return (
    <Container>
      <PageHeader>Edit Profile</PageHeader>
      {updatingError && (
        <ReactNotification action="error" message={`Something went wrong`} />
      )}

      <Spinner loading={updatingAgent} />
      <div
        className={`p-4 bg-white ${
          updatingAgent && `opacity-30 pointer-events-none`
        } `}
      >
        {/* Locations basic form */}
        <div className="my-0 py-3">
          <Image
            alt="fImage"
            src={agent.img ? agent.img : defaultImage}
            onClick={() => {
              profileImageRef.current.click()
            }}
            className="mx-auto"
          />

          <input
            ref={profileImageRef}
            type="file"
            onChange={(e) => {
              handleLogo(e.target.files)
            }}
            className="hidden"
            accept="image/*"
          />
          {errors.dpError && (
            <p className="text-sm text-red-500 mb-1">
              {errors.dpError}
              <span className="text-red-500 required-dot">*</span>
            </p>
          )}
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="First Name *"
              placeholder="Enter First Name"
              errorMessage={errors.firstNameError}
              value={agent.firstName}
              setValue={(e) => handleAgentInput(`firstName`, e.target.value)}
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Middle Name"
              placeholder="Enter Middle Name"
              value={agent.middleName}
              setValue={(e) => handleAgentInput(`middleName`, e.target.value)}
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Last Name *"
              placeholder="Enter Last Name"
              errorMessage={errors.lastNameError}
              value={agent.lastName}
              setValue={(e) => handleAgentInput(`lastName`, e.target.value)}
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Gender *"
              placeholder="Select Gender"
              errorMessage={errors.genderError}
              value={agent.gender}
              setValue={(value) => handleAgentInput(`gender`, value)}
              isMulti={false}
              loadOptions={loadGenderOptions}
              isSearchable={true}
              disabled={true}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Phone *"
              placeholder="Enter Phone No"
              errorMessage={errors.phoneError}
              value={agent.phone}
              setValue={(e) => handleAgentInput(`phone`, e.target.value)}
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Work Phone"
              placeholder="Enter Phone No"
              errorMessage={errors.workPhoneError}
              value={agent.workPhone}
              setValue={(e) => handleAgentInput(`workPhone`, e.target.value)}
              mask="+19999999999"
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Extension"
              placeholder="Enter Extension"
              errorMessage={errors.extError}
              value={agent.ext}
              setValue={(e) => handleAgentInput(`ext`, e.target.value)}
              mask="9999"
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Fax"
              placeholder="Enter Fax"
              errorMessage={errors.faxError}
              value={agent.fax}
              setValue={(e) => handleAgentInput(`fax`, e.target.value)}
              mask="+19999999999"
              disabled={true}
            />
          </div>
        </div>

        {/* Location Address starts from here */}
        <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Address
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              name="unitNo"
              placeholder="Enter Unit No"
              errorMessage={errors.unitNoError}
              value={address.unitNo}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              placeholder="Select Zip"
              validating={isLoading}
              errorMessage={errors.zipError}
              value={address.zip}
              setValue={(e) => {
                handleAddressInput(e, `zip`)
              }}
              loadOptions={loadZipCodes}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street *"
              name="streetNo"
              placeholder="Enter Street"
              errorMessage={errors.streetNoError}
              value={address.streetNo}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="City *"
              name="city"
              placeholder="Enter City"
              errorMessage={errors.cityError}
              disabled={true}
              value={address.city}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="State *"
              name="state"
              placeholder="Enter State"
              errorMessage={errors.stateError}
              disabled={true}
              value={address.state}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
        </div>

        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView
              center={[
                coordinates?.lat ? coordinates?.lat : ``,
                coordinates?.lng ? coordinates?.lng : ``,
              ]}
              zoom={3}
            />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div>
        <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
          <SubmitButton onClick={ValidateForm} disabled={isLoading}>
            <div>Update Profile</div>
          </SubmitButton>
        </div>
      </div>
    </Container>
  )
}
