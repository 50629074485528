import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PDFViewer } from '@react-pdf/renderer'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useQuery } from 'react-query'
import { Pdf } from './pdf'

export const TopAgentByCount = () => {
  const { data, isLoading, error, refetch, isFetching } = useQuery(
    `TopAgentByCountApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/reports/top-agent-by-count`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  return (
    <div>
      {isLoading || isFetching ? (
        <div className="flex justify-center items-center">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : data?.length ? (
        <div className="px-2" style={{ minWidth: `50vw` }}>
          <PDFViewer width="100%" height="750px">
            <Pdf carrierData={data} />
          </PDFViewer>
        </div>
      ) : (
        <div className="text-center p-10">
          <p>No Report In Given Date Range</p>
        </div>
      )}
    </div>
  )
}
