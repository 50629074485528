import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveCommunicationsTab } from 'App/Redux/actions'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import { Message } from './Message'
import { TOne, TThree, TTwo } from 'App/Styled'
import { Call } from './Call'
import { Video } from './Video'
// import { Gmail } from './Gmail'

const communicationsTabs = [
  {
    tName: `SMS`,
    tNumber: 1,
    permission: [
      `customers:read`,
      `leads:read`,
      `quotes:read`,
      `data-transactions:read`,
      `call-center-user:*`,
    ],
  },
  {
    tName: `Call`,
    tNumber: 2,
    permission: [`customer-call:*`, `call-center-user:*`],
  },
  {
    tName: `Video`,
    tNumber: 3,
    permission: [
      `customers:read`,
      `leads:read`,
      `quotes:read`,
      `data-transactions:read`,
    ],
  },
  // {
  //   tName: `Gmail`,
  //   tNumber: 4,
  //   permission: [`customers:read`, `admin-franchise`, `admin-master-franchise`],
  // },
]

export const Communications = ({ nonCustomerCall, quoteOffers }) => {
  const { activeCommunicationTab } = useSelector(({ customer }) => customer)
  const dispatch = useDispatch()
  return (
    <>
      <div className="mt-4">
        <TabsHeaders
          openTab={activeCommunicationTab}
          tabs={communicationsTabs}
          getActiveTab={(val) => dispatch(setActiveCommunicationsTab(val))}
          isCustomColor={quoteOffers}
        />
      </div>

      <div className="tab-content tab-space">
        {/* tab one */}

        <TOne opentab={activeCommunicationTab}>
          <Message />
        </TOne>

        {/* tab 2 */}
        <TTwo opentab={activeCommunicationTab}>
          <Call nonCustomerCall={nonCustomerCall} />
        </TTwo>

        {/* tab 3 */}
        <TThree opentab={activeCommunicationTab}>
          <Video />
        </TThree>

        {/* tab 4 */}
        {/* <TFour opentab={activeCommunicationTab}>
          <Gmail />
        </TFour> */}
      </div>
    </>
  )
}
