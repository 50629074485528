/* eslint-disable camelcase */
import React, { useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Lottie from 'lottie-react'
import Mylogo from 'App/Assets/lotties/customLoader.json'
import { useMutation } from 'react-query'
import { OIDC_BACKEND_API } from 'App/Config'
import axios from 'axios'
import { setUser } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'

export const OidcRedirectPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isToken, setIsToken] = useState(true)

  const url = window.location.search
  const urlParams = new URLSearchParams(url)
  const authCode = urlParams.get(`authCode`)

  useLayoutEffect(() => {
    if (
      window.location.search.includes(`error`) &&
      window.location.search.includes(`error_description`)
    ) {
      history.push(`/login`)
    } else if (
      window.location.search.includes(`nonceId`) &&
      window.location.search.includes(`authCode`)
    ) {
      isToken && getTokens(localStorage.getItem(`nonce`))
    } else {
      history.push(`/404`)
    }
  }, [])

  const { mutate: getTokens } = useMutation(
    async (nonceId) =>
      await axios
        .get(`${OIDC_BACKEND_API}/api/user/get-tokkens/${authCode}/${nonceId}`)
        .then((res) => {
          return res.data.data
        })
        .catch((err) => err && history.push(`/login`)),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (res?.accessToken) {
          setIsToken(false)
          localStorage.setItem(`accessToken`, res?.accessToken)
          localStorage.setItem(`idToken`, res?.idToken)
          localStorage.setItem(`refreshToken`, res?.refreshToken)
          localStorage.setItem(`accessTokenExpiry`, res?.accessTokenExpiry)
          axios
            .get(`${OIDC_BACKEND_API}/api/user/me`, {
              headers: {
                authorization: res?.accessToken,
              },
            })
            .then((res2) => res2?.data?.data?.data)
            .then((res2) => {
              const user = {}
              user.accessToken = res?.accessToken
              user.profile = res2
              dispatch(setUser(user))
              res2 && res?.accessToken && history.push(`/dashboard`)
            })
        }
      },
    },
  )

  return (
    <div className="h-screen w-full grid place-content-center">
      <Lottie
        animationData={Mylogo}
        loop={true}
        autoplay={true}
        className="h-40"
      />
      <p className="-bottom-44 text-xl font-bold capitalize">
        Redirecting you to dashboard
      </p>
    </div>
  )
}
