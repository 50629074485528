import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TOne, TThree, TTwo } from 'App/Styled'
import { setQuotesActivityActiveTab } from 'App/Redux/actions'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { Communications } from 'App/Components/Communications'
import { QuoteNote } from '../../QuoteNote'
import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { permissionGranted } from 'App/Services'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  {
    name: `Logs`,
    number: 2,
  },
  {
    name: `Customer Communications`,
    number: 3,
  },
]
const activityTabs2 = [
  {
    name: `Logs`,
    number: 1,
  },
  {
    name: `Customer Communications`,
    number: 2,
  },
]

export const QuotesActivity = () => {
  const { activeActivityTab, currentQuote } = useSelector(
    ({ myquotes }) => myquotes,
  )
  const dispatch = useDispatch()
  return (
    <div className="px-2">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([
            `notes:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ])
            ? activityTabs
            : activityTabs2
        }
        getActiveTab={(val) => dispatch(setQuotesActivityActiveTab(val))}
      />
      {permissionGranted(
        [`notes:read`, `corporate-manager:*`, `manager:*`, `admin:*`] || [
          `notes:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      ) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <QuoteNote />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <UpdateLogs
              logs={currentQuote?.updateLogs ? currentQuote?.updateLogs : []}
            />
          </TTwo>
          <TThree opentab={activeActivityTab}>
            <Communications nonCustomerCall={true} />
          </TThree>
        </div>
      ) : (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <UpdateLogs
              logs={currentQuote?.updateLogs ? currentQuote?.updateLogs : []}
            />
          </TOne>
          <TTwo opentab={activeActivityTab}>
            <Communications nonCustomerCall={true} />
          </TTwo>
        </div>
      )}
    </div>
  )
}
