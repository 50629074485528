import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadBoolean } from 'App/Services'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { useMutation } from 'react-query'
import { loadZipCodes } from 'App/Services'
import { AQS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Spinner } from 'App/Components/Common/Spinner'
import { CardField } from 'App/Components/Common/CardField'
import { NotificationManager } from 'react-notifications'

export const QuickEstimate = () => {
  const [estimatedQuote, setEstimatedQuote] = useState(false)

  const [estimate, setEstimate] = useState({
    violations: ``,
    accident: ``,
    insured: ``,
    married: ``,
    homeowner: ``,
    zipcode: ``,
    drivers: ``,
    cars: ``,
  })
  const [errors, setErrors] = useState({
    violationsError: ``,
    accidentError: ``,
    insuredError: ``,
    marriedError: ``,
    homeownerError: ``,
    zipcodeError: ``,
    driversError: ``,
    carsError: ``,
    formSubmit: false,
  })

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const {
    data: estimatedQuoteData,
    isLoading,
    mutate: estimateQuoteMutation,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AQS_BACKEND_API}/quotes/estimate`, {
        ...payload,
      })
        .then((res) => {
          NotificationManager.success(`Operation Successful`)
          setEstimatedQuote(true)
          return res.data.data
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            NotificationManager.error(
              `Cannot processed your request with this zip code`,
            )
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const ValidateForm = () => {
    if (!estimate?.violations?.value?.length) {
      handleErrors(`violationsError`, `Select Value`)
    } else {
      handleErrors(`violationsError`, ``)
    }
    if (!estimate?.accident?.value?.length) {
      handleErrors(`accidentError`, `Select Value`)
    } else {
      handleErrors(`accidentError`, ``)
    }
    if (!estimate?.insured?.value?.length) {
      handleErrors(`insuredError`, `Select Value`)
    } else {
      handleErrors(`insuredError`, ``)
    }
    if (!estimate?.married?.value?.length) {
      handleErrors(`marriedError`, `Select Locations`)
    } else {
      handleErrors(`marriedError`, ``)
    }

    if (!estimate?.homeowner?.value?.length) {
      handleErrors(`homeownerError`, `Enter Name`)
    } else {
      handleErrors(`homeownerError`, ``)
    }

    if (!estimate?.zipcode?.value?.length) {
      handleErrors(`zipcodeError`, `Select Zip Code`)
    } else {
      handleErrors(`zipcodeError`, ``)
    }
    if (!estimate?.drivers) {
      handleErrors(`driversError`, `Enter Value`)
    } else if (estimate?.drivers > 5) {
      handleErrors(`driversError`, `Drivers must be less than 5 `)
    } else if (estimate?.drivers < 1) {
      handleErrors(`driversError`, `Drivers must be greater than 0 `)
    } else {
      handleErrors(`driversError`, ``)
    }
    if (!estimate?.cars) {
      handleErrors(`carsError`, `Enter Value`)
    } else if (estimate?.cars > 5) {
      handleErrors(`carsError`, `Cars must be less than 5`)
    } else if (estimate?.cars < 1) {
      handleErrors(`carsError`, `Cars must be greater than 0 `)
    } else {
      handleErrors(`carsError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.violationsError.length &&
      !errors.accidentError.length &&
      !errors.insuredError.length &&
      !errors.marriedError.length &&
      !errors.homeownerError.length &&
      !errors.zipcodeError.length &&
      !errors.driversError.length &&
      !errors.carsError.length
    ) {
      estimateQuoteMutation({
        hasViolations: Boolean(estimate?.violations?.value),
        hasAccidents: Boolean(estimate?.accident?.value),
        isCurrentlyInsured: Boolean(estimate?.insured?.value),
        isMarried: Boolean(estimate?.married?.value),
        isHomeOwner: Boolean(estimate.homeowner?.value),
        zipcode: estimate.zipcode?.value,
        drivers: estimate.drivers,
        cars: estimate.cars,
      })
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  useEffect(() => {
    isLoading && setEstimatedQuote(false)
  }, [isLoading])

  return (
    <>
      <Container>
        <Spinner loading={isLoading} />
        <PageHeader padding="true">Quick Estimate</PageHeader>
        <div
          className={`p-4 bg-white ${
            isLoading && ` opacity-30 pointer-events-none`
          } `}
        >
          <div className="flex gap-6 mt-2">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Violations *"
                placeholder="Select Violation"
                errorMessage={errors.violationsError}
                value={estimate.violations}
                setValue={(value) => {
                  setEstimate((prevState) => ({
                    ...prevState,
                    [`violations`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadBoolean}
                isSearchable={false}
                disabled={false}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Accident *"
                placeholder="Select "
                errorMessage={errors.accidentError}
                value={estimate.accident}
                setValue={(value) => {
                  setEstimate((prevState) => ({
                    ...prevState,
                    [`accident`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadBoolean}
                isSearchable={true}
                disabled={false}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Insured *"
                placeholder="Select Insured"
                errorMessage={errors.insuredError}
                value={estimate.insured}
                setValue={(value) => {
                  setEstimate((prevState) => ({
                    ...prevState,
                    [`insured`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadBoolean}
                isSearchable={true}
                disabled={false}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Married *"
                placeholder="Select "
                errorMessage={errors.marriedError}
                value={estimate.married}
                setValue={(value) => {
                  setEstimate((prevState) => ({
                    ...prevState,
                    [`married`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadBoolean}
                isSearchable={true}
                disabled={false}
              />
            </div>
          </div>
          <div className="flex gap-6 mt-2">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Home Owner *"
                errorMessage={errors.homeownerError}
                value={estimate.homeowner}
                setValue={(value) => {
                  setEstimate((prevState) => ({
                    ...prevState,
                    [`homeowner`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadBoolean}
                isSearchable={true}
                disabled={false}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Zip *"
                placeholder="Select Zip"
                errorMessage={errors.zipcodeError}
                isMulti={false}
                value={estimate.zipcode}
                setValue={(value) => {
                  setEstimate((prevState) => ({
                    ...prevState,
                    zipcode: value,
                  }))
                }}
                loadOptions={loadZipCodes}
                isSearchable={true}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="number"
                title="Drivers *"
                placeholder="Enter Drivers"
                errorMessage={errors.driversError}
                value={estimate.drivers}
                setValue={(e) => {
                  setEstimate((prevState) => ({
                    ...prevState,
                    [`drivers`]: e.target.value,
                  }))
                }}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="number"
                title="Cars *"
                placeholder="Enter Cars "
                errorMessage={errors.carsError}
                value={estimate.cars}
                setValue={(e) => {
                  setEstimate((prevState) => ({
                    ...prevState,
                    [`cars`]: e.target.value,
                  }))
                }}
              />
            </div>
          </div>

          <div className="flex justify-center">
            <div className="text-center w-96 mt-5">
              <SubmitButton onClick={ValidateForm}>
                <div>Get Estimate</div>
              </SubmitButton>
            </div>
          </div>
        </div>
      </Container>
      {estimatedQuote && (
        <Container>
          <PageHeader>
            <div>Estimated Quote</div>
            <div
              className="px-1 rounded-md cursor-pointer font-bold"
              onClick={() => setEstimatedQuote(false)}
            >
              ×
            </div>
          </PageHeader>

          <div className="flex flex-row px-4">
            <div className="flex-1">
              <CardField
                title="Average Premium"
                value={`$${estimatedQuoteData?.premiumEstimate?.averagePremium}`}
              />
              <CardField
                title="Confidence"
                isGray="true"
                value={`${
                  estimatedQuoteData?.premiumEstimate?.confidence * 100
                }%`}
              />
              <CardField
                title="Estimated Monthly Premium"
                value={`$${estimatedQuoteData?.premiumEstimate?.estimatedMonthlyPremium}`}
              />
            </div>
            <div className="flex-1 ">
              <CardField
                title="Max Premium"
                value={`$${estimatedQuoteData?.premiumEstimate?.maxPremium}`}
              />
              <CardField
                isGray="true"
                title="Min Premium"
                value={`$${estimatedQuoteData?.premiumEstimate?.minPremium}`}
              />
              <CardField
                title="Variation"
                value={`$${estimatedQuoteData?.premiumEstimate?.variation}`}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  )
}
