import React from 'react'

export const TextArea = ({
  value,
  title,
  name,
  id,
  placeholder,
  errorMessage,
  setValue,
  rows = 3,
  cols = 5,
  px = px - 4,
  disabled,
  autoFocus,

  // cols = 5,
}) => {
  return (
    <>
      <label className="text-gray-700 font-medium text-sm">{title}</label>
      <textarea
        className={`flex-1 appearance-none border border-gray-300 resize-none w-full py-2 ${px} my-1 ${
          disabled ? `bg-gray-100 text-gray-400 cursor-not-allowed` : `bg-white`
        } 
        text-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 
        focus:border-transparent text-sm placeholder-gray-400 `}
        type="text"
        autoFocus={autoFocus}
        id={id}
        value={value}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
        onChange={setValue}
        disabled={disabled}
        name={name}
      />

      {errorMessage && (
        <span className=" text-sm text-red-500 mb-1 ">{errorMessage}</span>
      )}
    </>
  )
}
