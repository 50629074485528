import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentPolicy: ``,
  activeActivityTab: 1,
  activeTab: 1,
}

const policySlice = createSlice({
  name: `policy`,

  initialState,
  reducers: {
    setCurrentPolicy: (state, { payload }) => {
      state.currentPolicy = payload
    },
    setPolicyActiveActivityTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
    setPolicyActiveTab: (state, { payload }) => {
      state.activeTab = payload
    },
  },
})

export const {
  setCurrentPolicy,
  setPolicyActiveActivityTab,
  setPolicyActiveTab,
} = policySlice.actions

export const policy = policySlice.reducer
