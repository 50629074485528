import React, { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCheckDouble,
  faExclamationCircle,
  faFilePdf,
  faPhoneAlt,
  faArrowCircleDown,
  faPlusCircle,
  faPaperPlane,
  faFileWord,
  faFileExcel,
  faFileCsv,
  faSpinner,
  faFilePowerpoint,
  faMicrophone,
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { TimeAgo } from 'App/Components/Common/TimeAgo'
import FileSaver from 'file-saver'
import { newSocket } from 'App/HOC/MainLayout'
import { NotificationManager } from 'react-notifications'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { fileToBase64 } from 'App/Services'
import { ChatRoom } from '..'
import { getNonce } from 'App/Services/Auth/getNonce'
import { InputFieldRef } from 'App/Components/Common/InputFieldRef'
import { useReactMediaRecorder } from 'react-media-recorder'
import { Spinner } from 'App/Components/Common/Spinner'
import moment from 'moment'
import { setChatRoomId } from 'App/Redux/actions'

const chatStatusToIcons = [
  {
    status: `sending`,
    icon: faCheck,
    color: `text-gray-600`,
  },
  {
    status: `QUEUED`,
    icon: faCheck,
    color: `text-gray-600`,
  },
  {
    status: `sent`,
    icon: faCheckDouble,
    color: `text-gray-600`,
  },
  {
    status: `delivered`,
    icon: faCheckDouble,
    color: `text-green-600`,
  },
  {
    status: `FAILED`,
    icon: faExclamationCircle,
    color: `text-red-500`,
  },
  {
    status: `RECEIVED`,
    icon: faCheckDouble,
    color: `text-green-600`,
  },
  {
    status: `completed`,
    icon: faPhoneAlt,
    color: `text-green-600`,
  },
  {
    status: `in-progress`,
    icon: faPhoneAlt,
    color: `text-green-600`,
  },
  {
    status: `answered`,
    icon: faPhoneAlt,
    color: `text-green-600`,
  },
  {
    status: `no-answer`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
  {
    status: `busy`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
  {
    status: `canceled`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
  {
    status: `failed`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
]

export const Conversation = () => {
  const {
    chatRoom,
    setChatRoom,
    agentDetail,
    setConversation,
    conversation,
    conversationLoader,
    setNotification,
  } = useContext(ChatRoom)
  // to initialize the mic form when component is loaded
  // let device = navigator?.mediaDevices.getUserMedia({ audio: true })
  const timeout = useRef(null)
  const dispatch = useDispatch()
  const [seconds, setSeconds] = useState(0)
  const { status, startRecording, stopRecording } = useReactMediaRecorder({
    audio: true,
    mediaBlob: {
      type: `audio/wav`,
    },
    onStart: () => {
      setSeconds(0)
      setIsRecording(true)
      timeout.current = setInterval(() => {
        setSeconds((seconds) => seconds + 1)
      }, 1000)
    },
    onStop: (e) => {
      setIsRecording(false)
      handleFileUpload(e, `voiceMsg`)
      clearInterval(timeout.current)
      setSeconds(0)
    },
  })

  const messagesEndRef = useRef(null)
  let fileRef

  const { user, preferredLocation } = useSelector(({ user }) => user)

  const newMessage = useRef(null)

  const [preview, setPreview] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [currentImage, setCurrentImage] = useState(``)

  const { mutate: getMediaUrl } = useMutation(
    `CustomerChatHistory`,
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/cloudinary/upload/chat`,
        {
          ...payload,
        },
      ).then((res) => {
        return res?.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        newSocket?.emit(`AGENTS_MESSAGE`, {
          roomId: res?.roomId,
          chats: {
            mediaUrl: res.mediaUrl,
            type: `file`,
            agentName: user?.profile?.full_name,
            agentId: user?.profile?.sub,
            fileType: res?.fileType,
            fileName: res?.fileName,
          },
          _location: preferredLocation?._id,
        })
      },
      onError: (error) => {
        if (
          error?.response?.data?.error?.error.includes(
            `file' failed custom validation`,
          )
        ) {
          NotificationManager.error(`File format is not allowed`)
        }
      },
    },
  )

  const filesProps = {
    'application/pdf': { icon: faFilePdf, color: `red-600`, isAllowed: true },
    'application/msword': {
      icon: faFileWord,
      color: `blue-600`,
      isAllowed: true,
    },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      icon: faFileWord,
      color: `blue-600`,
      isAllowed: true,
    },
    'application/vnd.ms-excel': {
      icon: faFileExcel,
      color: `green-600`,
      isAllowed: true,
    },
    'text/csv': { icon: faFileCsv, color: `green-700`, isAllowed: true },
    'text/plain': {
      icon: faFilePowerpoint,
      color: `gray-500`,
      isAllowed: true,
    },
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      { icon: faFilePowerpoint, color: `orange-600`, isAllowed: true },
  }

  const handleFileUpload = async (file, type) => {
    if (file?.length && type !== `voiceMsg`) {
      if ((file[0]?.size / (1024 * 1024)).toFixed(2) > 5) {
        NotificationManager.error(`File is too big.`)
      } else if (file[0]?.type.includes(`image`)) {
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            sendingMMS(base64, `image`, file[0]?.name)
          }
        })

        // const base64 = await fileResizer(file[0])
        // if (base64) {
        //   sendingMMS(base64, `image`, file[0]?.name)
        // }
      } else if (filesProps[file[0].type]?.isAllowed) {
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            sendingMMS(base64, file[0]?.type, file[0]?.name)
          }
        })
      } else if (file[0].type.includes(`video`)) {
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            sendingMMS(base64, `video`, file[0]?.name)
          }
        })
      } else if (file[0].type.includes(`audio`)) {
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            sendingMMS(base64, `audio`, file[0]?.name)
          }
        })
      } else {
        NotificationManager.error(`File Is not Allowed to send!`)
      }
    } else if (type === `voiceMsg`) {
      let response = await fetch(file)
      let data = await response.blob()
      if (data.type !== `audio/wav`) {
        NotificationManager.error(`SomeThing went Wrong`)
      } else if (data?.size > 10000) {
        fileToBase64(data, (err, base64) => {
          if (base64) {
            sendingMMS(base64, `audio`, `voiceMsg`)
          }
        })
      }
    }
  }

  const sendingSMS = () => {
    if (newMessage?.current?.value) {
      newSocket?.emit(`AGENTS_MESSAGE`, {
        roomId: chatRoom,
        chats: {
          message: newMessage?.current?.value,
          type: `TEXT`,
          agentName: user?.profile?.full_name,
          agentId: user?.profile?.sub,
        },
        _location: preferredLocation?._id,
      })
    }
  }
  const sendingMMS = (base64, fileType, fileName) => {
    if (base64) {
      const _fileName = `${getNonce(3, `aA0`)}-${fileName}`
      const fileBase64 = base64?.includes(`codecs=opus;`)
        ? base64?.replace(`codecs=opus;`, ``)
        : base64
      setConversation((prev) => [
        ...prev,
        {
          type: `file`,
          fileType: fileType,
          mediaUrl: fileBase64,
          agentId: user?.profile?.sub,
          agentName: user?.profile?.full_name,
          fileName: _fileName,
          isLoading: true,
        },
      ])
      getMediaUrl({
        path: `${user?.profile?.sub}/Rooms/${chatRoom}`,
        file: fileBase64,
        roomId: chatRoom,
        fileType: fileType,
        fileName: _fileName,
      })
    }
  }

  useEffect(() => {
    if (newSocket) {
      newSocket?.on(`AGENT_MESSAGE_RES`, (message) => {
        if (message?.roomId === chatRoom) {
          newSocket?.emit(`FETCH_ROOM`)
          if (message?.fileName) {
            setConversation((prev) => {
              let index = prev?.findIndex(
                (item) => message?.fileName === item?.fileName,
              )
              if (index === -1) {
                return [...prev, message]
              } else {
                prev[index] = message
                return [...prev]
              }
            })
          } else {
            setConversation((prev) => [...prev, message])
          }
        }
      })
    }
    newSocket?.on(`ROOM_NEW_MSG_NOTIFICATION`, (roomId) => {
      // give room id where new msg is received
      if (roomId) {
        newSocket?.emit(`FETCH_ROOM`)
        setNotification((prev) => [...prev, roomId])
      }
    })
    return () => {
      newSocket?.off(`AGENT_MESSAGE_RES`)
      newSocket?.off(`ROOM_NEW_MSG_NOTIFICATION`)
    }
  }, [newSocket])

  useEffect(() => {
    if (messagesEndRef?.current)
      messagesEndRef.current.scrollTop = messagesEndRef?.current?.scrollHeight
  }, [conversation])

  const manageStatus = (incommingStatus) => {
    return chatStatusToIcons.map((chat, index) => {
      if (chat.status === incommingStatus) {
        return (
          <FontAwesomeIcon
            icon={chat.icon}
            className={`text-white mt-0.5 ${chat.color}`}
            size="sm"
            key={`font` + index}
          />
        )
      }
    })
  }

  const UploadLoader = ({ isLoading }) =>
    isLoading ? (
      <FontAwesomeIcon
        icon={faSpinner}
        spin={isLoading}
        className="ml-1 z-50 cursor-pointer absolute top-1/2 left-1/2 text-red-600 tooltip"
      />
    ) : null

  const timeAgo = (date) => {
    return moment.utc(date).local().startOf(`seconds`).fromNow()
  }

  useEffect(() => {
    timeAgo()
  }, [])

  return (
    chatRoom && (
      <>
        <Spinner loading={conversationLoader} />
        <div
          className={`mt-1 flex flex-col h-90vh ${
            conversationLoader &&
            `opacity-30 pointer-events-none overflow-y-scroll hide-scroller hide-scroller-firefox`
          } `}
        >
          <div className="h-14 bg-red-500 rounded-t-md text-white font-bold flex justify-between px-2">
            <div className="py-4 px-2">{agentDetail?.agentName}</div>
            <button
              className="bg-transparent border-0 text-black float-right text-4xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => {
                setChatRoom(``)
                setConversation(``)
                newSocket?.emit(`LEAVE_MESSENGER`)
                dispatch(setChatRoomId(``))
              }}
            >
              <span className="text-white h-6 w-6 block outline-none focus:outline-none -mt-4">
                ×
              </span>
            </button>
          </div>

          <div
            className="mx-3 overflow-y-scroll hide-scroller hide-scroller-firefox"
            ref={messagesEndRef}
          >
            <div className="flex flex-col justify-end rounded-md px-2">
              {conversation?.length < 1 && (
                <div className="h-80 flex justify-center items-center">
                  <p>Start a conversation</p>
                </div>
              )}
              {conversation?.length > 0 &&
                conversation?.map((message, index) => (
                  <div key={index}>
                    <div className={` relative p-1 `}>
                      <span className="pl-2 pt-0.5 w-72">
                        <div className={`flex  w-full`}>
                          <div className="w-full">
                            <div className={`text-blue-500 font-medium`}>
                              <p
                                className={`capitalize ${
                                  message?.agentId === user?.profile?.sub
                                    ? `text-right`
                                    : `text-left`
                                }`}
                              >
                                {message?.agentName?.toLowerCase() ||
                                  message?.customerPhone}
                              </p>
                            </div>
                          </div>
                        </div>

                        {message?.type.toLowerCase() == `text` ? (
                          <div
                            className={`${
                              message?.agentId === user?.profile?.sub
                                ? `bg-green-500 float-right`
                                : `bg-blue-500 float-left`
                            } py-1 px-2 rounded-md shadow-sm border-1 min-w-20 max-w-md relative`}
                          >
                            <p
                              className="text-white min-w-20 max-w-md break-words"
                              // style={{ wordWrap: `break-word` }}
                            >
                              {message?.message}
                            </p>
                            <div className="float-right">
                              <span
                                className={`text-2xs capitalize absolute pt-2 ${
                                  message.direction === `OUTBOUND`
                                    ? `left-1`
                                    : `right-1`
                                }`}
                              >
                                {manageStatus(message?.status)}
                                <div className="flex float-right pl-2 text-2xs">
                                  <TimeAgo timeStamp={message?.createdAt} />
                                </div>
                              </span>
                            </div>
                          </div>
                        ) : message?.type === `file` &&
                          message?.fileType?.includes(`image`) ? (
                          <div
                            className={`relative ${
                              message?.agentId === user?.profile?.sub
                                ? `float-right`
                                : `float-left`
                            } ${
                              message?.isLoading &&
                              `opacity-60 pointer-events-none`
                            }`}
                          >
                            <img
                              src={message?.mediaUrl}
                              alt="Image Loading Failed"
                              className={`rounded-md relative`}
                              style={{
                                height: `30vh`,
                                width: `100%`,
                                cursor: `pointer`,
                              }}
                              onClick={() => {
                                setPreview(true)
                                setCurrentImage(message?.mediaUrl)
                              }}
                            />

                            <UploadLoader isLoading={message?.isLoading} />

                            <div className="float-right">
                              <span
                                className={`text-2xs capitalize absolute pt-2 ${
                                  message?.agentId === user?.profile?.sub
                                    ? `right-1`
                                    : `left-1`
                                }`}
                              >
                                {manageStatus(message?.status)}
                                <div className="flex float-right pl-2 text-2xs">
                                  <TimeAgo timeStamp={message?.createdAt} />
                                </div>
                              </span>
                            </div>
                          </div>
                        ) : message?.fileType === `video` &&
                          message?.type === `file` ? (
                          <div
                            className={`relative ${
                              message?.agentId === user?.profile?.sub
                                ? `float-right`
                                : `float-left`
                            } ${
                              message?.isLoading &&
                              `opacity-30 pointer-events-none`
                            }`}
                          >
                            <video
                              width="650"
                              height="300"
                              controls
                              className="relative"
                            >
                              <source
                                src={message?.mediaUrl}
                                type="video/mp4"
                              />
                            </video>
                            <div className="float-right">
                              <span
                                className={`text-2xs capitalize absolute pt-2 ${
                                  message?.agentId === user?.profile?.sub
                                    ? `right-1`
                                    : `left-1`
                                }`}
                              >
                                {manageStatus(message?.status)}
                                <div className="flex float-right pl-2 text-2xs">
                                  <TimeAgo timeStamp={message?.createdAt} />
                                </div>
                              </span>
                            </div>
                            <UploadLoader isLoading={message?.isLoading} />
                          </div>
                        ) : filesProps[message?.fileType]?.isAllowed &&
                          message?.type === `file` ? (
                          <div
                            className={`relative ${
                              message?.agentId === user?.profile?.sub
                                ? `float-right`
                                : `float-left`
                            }`}
                          >
                            <div className="flex items-center gap-1 max-w-xs truncate bg-gray-100 shadow-md px-4 py-2 rounded-3xl">
                              <span className="truncate w-4/5">
                                {message?.fileName}
                              </span>
                              <span className="w-1/5 text-right">
                                <FontAwesomeIcon
                                  icon={filesProps[message?.fileType]?.icon}
                                  // size="sm"
                                  className={`text-${
                                    filesProps[message?.fileType]?.color
                                  }`}
                                />
                                <FontAwesomeIcon
                                  data-tip="Download File"
                                  icon={
                                    message?.isLoading
                                      ? faSpinner
                                      : faArrowCircleDown
                                  }
                                  spin={message?.isLoading}
                                  className="ml-1 cursor-pointer hover:text-gray-600 tooltip"
                                  onClick={() =>
                                    FileSaver.saveAs(
                                      message?.mediaUrl,
                                      message?.fileName,
                                    )
                                  }
                                />
                              </span>
                            </div>
                            <div className="float-right">
                              <span
                                className={`text-2xs capitalize absolute pt-2 ${
                                  message?.agentId === user?.profile?.sub
                                    ? `right-1`
                                    : `left-1`
                                }`}
                              >
                                {manageStatus(message?.status)}
                                <div className="flex float-left pl-2 text-2xs">
                                  <TimeAgo timeStamp={message?.createdAt} />
                                </div>
                              </span>
                            </div>
                          </div>
                        ) : (
                          message?.type === `file` &&
                          message?.fileType === `audio` && (
                            <div
                              className={`relative ${
                                message?.agentId === user?.profile?.sub
                                  ? `float-right`
                                  : `float-left`
                              }`}
                            >
                              <div className="flex items-center max-w-xs truncate bg-gray-100 shadow-md px-1  rounded-full">
                                <audio controls className="">
                                  <source
                                    src={message?.mediaUrl}
                                    type="audio/wav"
                                  />
                                </audio>
                              </div>
                              <div className="float-right">
                                <span
                                  className={`text-2xs capitalize absolute pt-2 ${
                                    message?.agentId === user?.profile?.sub
                                      ? `right-1`
                                      : `left-1`
                                  }`}
                                >
                                  {manageStatus(message?.status)}
                                  <div className="flex float-right pl-2 text-2xs">
                                    <TimeAgo timeStamp={message?.createdAt} />
                                  </div>
                                </span>
                              </div>
                            </div>
                          )
                        )}
                      </span>
                    </div>
                    {/* {preview && currentImage && (
                      <ReactModal
                        title="Photo"
                        closeModal={() => {
                          setPreview(false)
                          setCurrentImage(``)
                        }}
                        disabled={true}
                        loading={false}
                        callMutation={() => {
                          FileSaver.saveAs(currentImage)
                        }}
                        icon={faArrowCircleDown}
                        toolTip="Save File"
                        className="p-2"
                      >
                        <div className="items-center my-0">
                          <img src={currentImage} alt="img" />
                        </div>
                      </ReactModal>
                    )} */}
                  </div>
                ))}
              {preview && currentImage && (
                <div
                  id="modal"
                  className="fixed top-0 left-0 z-50 w-screen h-screen bg-gray-900 bg-opacity-50 flex justify-center items-center"
                >
                  <a
                    className="fixed z-50 top-6 right-6 text-white text-5xl font-bold"
                    href="javascript:void(0)"
                    onClick={() => {
                      setPreview(false)
                      setCurrentImage(``)
                    }}
                  >
                    &times;
                  </a>
                  <img
                    id="modal-img"
                    src={currentImage}
                    alt="Image Not Found"
                    className="max-w-3xl max-h-50 object-cover"
                  />
                  {/* <img
                   
                    className="max-w-[800px] max-h-[600px] object-cover"
                  /> */}
                </div>
              )}
              {/* {preview && currentImage && (
                <ReactModal
                  title="Download Image"
                  closeModal={() => {
                    setPreview(false)
                    setCurrentImage(``)
                  }}
                  // scroll={true}
                  // height="96"
                  disabled={true}
                  loading={false}
                  callMutation={() => {
                    FileSaver.saveAs(currentImage)
                  }}
                  icon={faArrowCircleDown}
                  toolTip="Save File"
                  className="p-2"
                >
                  <div className="items-center">
                    <img
                      src={currentImage}
                      alt="Image Not Found"
                      className="max-w-[800px] max-h-[600px] object-cover"
                    />
                  </div>
                </ReactModal>
              )} */}
            </div>
          </div>

          {/* message text */}
          <div className="flex gap-6 pb-2 mx-3 mt-auto">
            <div className="flex-1 relative mt-2">
              <InputFieldRef
                inputRef={newMessage}
                className="pl-8 pr-20"
                type="text"
                disabled={isRecording}
                placeholder={
                  status === `recording`
                    ? `Recording Voice Message.. ${seconds}`
                    : `Type Here ...`
                }
                onEnter={(event) => {
                  if (event.key === `Enter`) {
                    sendingSMS()
                    newMessage.current.value = ``
                  }
                }}
              />
              {!isRecording && (
                <>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    onClick={() => fileRef.click()}
                    className="absolute text-gray-700 left-3 mt-3 cursor-pointer"
                  />
                  <input
                    className="hidden"
                    type="file"
                    name="attachment"
                    ref={(refParam) => (fileRef = refParam)}
                    key={getNonce(3, `aA0`)}
                    onChange={(e) => {
                      handleFileUpload(e.target.files)
                    }}
                    accept="application/pdf, image/png, image/gif, image/jpeg video/*, audio/*,.txt, .doc,.csv,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                </>
              )}
              <span className={`absolute right-3 top-1 flex gap-x-5 `}>
                <FontAwesomeIcon
                  title="Send"
                  icon={faPaperPlane}
                  onClick={() => {
                    if (newMessage?.current?.value && !isRecording) {
                      sendingSMS()
                      newMessage.current.value = ``
                    }
                    if (isRecording) {
                      stopRecording()
                    }
                  }}
                  className={`${
                    isRecording ? `text-green-500` : `text-gray-700`
                  } mt-2 pl-2 cursor-pointer border-l-2 border-red-500`}
                />

                <FontAwesomeIcon
                  icon={faMicrophone}
                  onClick={() => {
                    !isRecording && startRecording()
                  }}
                  className={`${
                    isRecording ? `text-red-500` : `text-gray-700`
                  } mt-2 cursor-pointer`}
                  title={`${!isRecording ? `Start Recording` : `Recording...`}`}
                />
              </span>
            </div>
          </div>
        </div>
      </>
    )
  )
}
