import React from 'react'
import { TOne } from 'App/Styled'
import { TaskMediaFiles } from './MediaFiles'
export const MediaFiles = () => {
  return (
    <>
      <div className="tab-content tab-space">
        <TOne opentab={1}>
          <TaskMediaFiles />
        </TOne>
      </div>
    </>
  )
}
