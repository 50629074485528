import {
  AMS_BACKEND_API,
  AxiosInstance,
  allowedSystemPermissions,
} from 'App/Config'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { NotificationManager } from 'react-notifications'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledButton } from 'App/Styled'
import { loadAgents, loadFranchises, permissionGranted } from 'App/Services'
import { useSelector } from 'react-redux'
export const AddTicketCategory = ({ closeModal, categoryUpdated }) => {
  const [categories, setCategories] = useState({
    name: ``,
    assignedTo: ``,
    franchise: ``,
    location: ``,
  })

  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)

  const {
    user: {
      profile: { _locations },
    },
  } = useSelector(({ user }) => user)

  const loadAgentLocations = () => {
    const options = _locations.map((item) => {
      return { value: item._id, label: item.name }
    })

    return {
      options,
    }
  }

  const [errors, setErrors] = useState({
    nameError: ``,
    assignedToError: ``,
    franchiseError: ``,
    locationError: ``,
  })

  const { mutate, isLoading } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/ticket-category`, {
        ...payload,
      })
        .then((res) => {
          if (res?.data) {
            NotificationManager.success(`Created Successfully`)
            closeModal()
            categoryUpdated()
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Ticket-Category creation failed due to duplicate categoryName.`,
              )
            ) {
              NotificationManager.error(`Duplicate Category Name`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  //get all locations by franchise

  const loadLocations = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        categories?.franchise?.value
          ? categories?.franchise?.value
          : _franchise?._id
      }/locations/list/?search=${search && search}&page=${page}&offset=10`,
    )
    if (data) {
      return {
        options: data.locations,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  // const loadAgents = async (search, loadedOptions, { page }) => {
  //   try {
  //     const {
  //       data: { data },
  //     } = await AxiosInstance.post(
  //       `${AMS_BACKEND_API}/api/users/v2/list/?search={"query":"${
  //         search.length ? search : ``
  //       }"}&page=${page}&offset=10`,
  //       {
  //         franchiseId: categories?.franchise?.value
  //           ? categories?.franchise?.value
  //           : _franchise?._id,
  //         locations: [categories?.location?.value],
  //         permissions: allowedSystemPermissions.ticket,
  //       },
  //     )

  //     if (data) {
  //       return {
  //         options: data.agents,
  //         hasMore: data.totalPages - page > 0,
  //         additional: {
  //           page: page + 1,
  //         },
  //       }
  //     }
  //   } catch (error) {
  //     if (error.response?.data?.hasError) {
  //       return {
  //         options: [],
  //         hasMore: false,
  //         additional: {
  //           page: page,
  //         },
  //       }
  //     }
  //   }
  // }
  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.franchiseError.length &&
      !errors.locationError.length &&
      !errors.assignedToError.length
    ) {
      mutate(prepareload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])
  const prepareload = () => {
    let data = {}
    data.categoryName = categories?.name
    data.assignTo = categories?.assignedTo.map((item) => item.value)
    if (categories?.franchise?.value?.length) {
      data._franchise = categories?.franchise?.value
    } else {
      data._franchise = _franchise?._id
    }
    if (categories?.location?.value?.length) {
      data._location = categories?.location?.value
    }
    return data
  }
  const validateForm = () => {
    if (!categories?.assignedTo.length) {
      handleErrors(`assignedToError`, `Select User`)
    } else {
      handleErrors(`assignedToError`, ``)
    }
    if (!categories?.name?.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }
    if (!categories?.location?.value?.length) {
      handleErrors(`locationError`, `Select Location`)
    } else {
      handleErrors(`locationError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-col px-4">
          <div className="flex gap-4">
            <div className="flex-1 relative w-60">
              <InputField
                type="text"
                name="Name"
                title="Name*"
                placeholder="Enter Name"
                value={categories.name}
                setValue={(e) => {
                  setCategories((prevState) => ({
                    ...prevState,
                    [`name`]: e.target.value,
                  }))
                }}
                errorMessage={errors.nameError}
              />
            </div>
            {permissionGranted([`super-admin:*`]) && (
              <>
                <div className="flex-1 relative w-60">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Franchise *"
                      placeholder="Select Franchise"
                      setValue={(value) => {
                        setCategories((preState) => ({
                          ...preState,
                          franchise: value,
                          assignedTo: ``,
                          location: ``,
                        }))
                      }}
                      defaultValue={{
                        label: _franchise?.name,
                        value: _franchise?._id,
                      }}
                      isMulti={false}
                      loadOptions={loadFranchises}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="flex gap-4">
            <div className="flex gap-4">
              <div className="flex-1 relative w-60">
                <div className="flex-1 relative">
                  <ReactSelectField
                    title="Location *"
                    placeholder="Select Location"
                    errorMessage={errors.locationError}
                    value={categories?.location}
                    setValue={(value) => {
                      setCategories((preState) => ({
                        ...preState,
                        location: value,
                        assignedTo: ``,
                      }))
                    }}
                    isMulti={false}
                    loadOptions={
                      permissionGranted([
                        `super-admin:*`,
                        `corporate-manager:*`,
                        `manager:*`,
                      ])
                        ? loadLocations
                        : loadAgentLocations
                    }
                    isSearchable={true}
                    cacheUniqs={categories?.franchise}
                  />
                </div>
              </div>
            </div>

            {categories.location?.length !== 0 && (
              <div className="flex gap-4">
                <div className="flex-1 relative w-60">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Assign To *"
                      placeholder="Select User"
                      value={categories.assignedTo}
                      errorMessage={errors.assignedToError}
                      setValue={(value) => {
                        setCategories((preState) => ({
                          ...preState,
                          assignedTo: value,
                        }))
                      }}
                      isMulti={true}
                      loadOptions={
                        categories?.location?.value ? loadAgents : ``
                      }
                      isSearchable={true}
                      cacheUniqs={categories?.location}
                      additionalFilters={{
                        franchiseId: categories?.franchise?.value
                          ? categories?.franchise?.value
                          : _franchise?._id,
                        locations: [categories?.location?.value],
                        permissions: allowedSystemPermissions.ticket,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="pb-2 px-4 text-right">
          <StyledButton onClick={validateForm}>
            <div className="flex gap-2 text-white">
              <span>Add</span>
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </StyledButton>
        </div>
      </div>
    </div>
  )
}
