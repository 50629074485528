export const WINDMIL_THEME = {
  badge: {
    base: `inline-flex px-2 text-xs font-medium leading-5 rounded truncate`,
  },
  button: {
    primary: {
      base: `text-white bg-blue-500 border border-transparent`,
      active: `active:bg-blue-500 hover:bg-blue-600 focus:ring focus:ring-blue-300`,
      disabled: `opacity-50 cursor-not-allowed`,
    },
  },
}
