/* eslint-disable no-unused-vars */
import { AQS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadResidenceStatus = async () => {
  return {
    options: [
      { value: `OWN`, label: `Own` },
      { value: `RENT`, label: `Rent` },
      { value: `LEASE`, label: `Lease` },
    ],
    hasMore: false,
  }
}

export const loadResidenceType = async () => {
  return {
    options: [
      { value: `HOME`, label: `Home` },
      { value: `APARTMENT`, label: `Apartment` },
      { value: `CONDO`, label: `Condo` },
      { value: `MOBILE HOME`, label: `Mobile Home` },
      { value: `FIXED MOBILE HOME`, label: `Fixed Mobile Home` },
    ],
    hasMore: false,
  }
}

export const loadRelationship = async () => {
  return {
    options: [
      { value: `CHILD`, label: `CHILD` },
      { value: `SPOUSE`, label: `SPOUSE` },
      { value: `PARENT`, label: `PARENT` },
    ],
    hasMore: false,
  }
}

export const loadBinaryOptions = async () => {
  return {
    options: [
      { value: `YES`, label: `YES` },
      { value: `NO`, label: `NO` },
    ],
    hasMore: false,
  }
}

export const loadMaritalStatus = async () => {
  return {
    options: [
      { value: `MARRIED`, label: `MARRIED` },
      { value: `SINGLE`, label: `SINGLE` },
      { value: `DIVORCED`, label: `DIVORCED` },
      { value: `WIDOWED`, label: `WIDOWED` },
      { value: `SEPARATED`, label: `SEPARATED` },
    ],
    hasMore: false,
  }
}

export const loadGender = async () => {
  return {
    options: [
      { value: `MALE`, label: `MALE` },
      { value: `FEMALE`, label: `FEMALE` },
      { value: `OTHER`, label: `OTHER` },
    ],
    hasMore: false,
  }
}

export const loadPriorLiabilityLimits = async () => {
  return {
    options: [
      { value: `30/60/25`, label: `30/60/25` },
      { value: `500/1000/300`, label: ` 500/1000/300` },
      { value: `500/1000/500`, label: `500/1000/500` },
      { value: `1000/1000/500`, label: ` 1000/1000/500` },
    ],
    hasMore: false,
  }
}

export const loadPriorTransferLevel = async () => {
  return {
    options: [
      { value: `PRIOR TRANSFER LEVEL 1`, label: `Prior Transfer Level 1` },
      { value: `PRIOR TRANSFER LEVEL 2`, label: `Prior Transfer Level 2` },
      { value: `PRIOR TRANSFER LEVEL 3`, label: `Prior Transfer Level 3` },
      { value: `PRIOR TRANSFER LEVEL 4`, label: `Prior Transfer Level 4` },
      { value: `PRIOR TRANSFER LEVEL 5`, label: `Prior Transfer Level 5` },
      { value: `PRIOR TRANSFER LEVEL 6`, label: `Prior Transfer Level 6` },
      { value: `PRIOR TRANSFER LEVEL 7`, label: `Prior Transfer Level 7` },
      { value: `PRIOR TRANSFER LEVEL 8`, label: `Prior Transfer Level 8` },
      { value: `PRIOR TRANSFER LEVEL 9`, label: `Prior Transfer Level 9` },
    ],
    hasMore: false,
  }
}

export const loadForeignLicensed = async () => {
  return {
    options: [
      { value: `none`, label: `None` },
      { value: `INTERNATIONAL`, label: `International` },
      { value: `CANADA`, label: `Canada` },
      { value: `MEXICO`, label: `Mexico` },
      { value: `MATRICULA`, label: `Matricula` },
      { value: `OTHER`, label: `Other` },
    ],
    hasMore: false,
  }
}

export const loadSR22 = async () => {
  return {
    options: [
      { value: `AL`, label: `Alabama` },
      { value: `AK`, label: `Alaska` },
      { value: `AS`, label: `America Samao` },
      { value: `AZ`, label: `Arizona` },
      { value: `AR`, label: `Arkansas` },
      { value: `CA`, label: `California` },
      { value: `CO`, label: `Colorado` },
      { value: `CT`, label: `Connecticut` },
      { value: `DE`, label: `Delaware` },
      { value: `DC`, label: `District of Columbia` },
      { value: `FL`, label: `Florida` },
      { value: `GA`, label: `Georgia` },
      { value: `GU`, label: `Guam` },
      { value: `HI`, label: `Hawaii` },
      { value: `ID`, label: `Idaho` },
      { value: `IL`, label: `Illinois` },
      { value: `IN`, label: `Indiana` },
      { value: `IA`, label: `Iowa` },
      { value: `KS`, label: `Kansas` },
      { value: `KY`, label: `Kentucky` },
      { value: `LA`, label: `Louisiana` },
      { value: `ME`, label: `Maine` },
      { value: `MD`, label: `MaryLand` },
      { value: `MA`, label: `Massachusetts` },
      { value: `MI`, label: `Michigan` },

      { value: `MN`, label: `Minnesato` },
      { value: `MS`, label: `Mississippi` },
      { value: `MO`, label: `Missouri` },
      { value: `MT`, label: `Montana` },
      { value: `NE`, label: `Nebraska` },
      { value: `NV`, label: `Nevada` },
      { value: `NH`, label: `New Hampshire` },
      { value: `NJ`, label: `New Jersey` },
      { value: `NM`, label: `New Mexico` },
      { value: `NY`, label: `New York` },
      { value: `NC`, label: `North Calorina` },

      { value: `ND`, label: `North Dakota` },
      { value: `OH`, label: `Ohio` },
      { value: `OK`, label: `Oklahoma` },
      { value: `OR`, label: `Oregon` },
      { value: `PA`, label: `Pennsylvania` },
      { value: `PR`, label: `Puerto Rico` },
      { value: `RI`, label: `Rhode Island` },
      { value: `SC`, label: `South Calorina` },
      { value: `SD`, label: `South Dakota` },
      { value: `TN`, label: `Tennessee` },
      { value: `TX`, label: `Texas` },

      { value: `UT`, label: `Utah` },
      { value: `VT`, label: `Vermont` },
      { value: `VI`, label: `Virgin Islands` },
      { value: `VA`, label: `Virginia` },

      { value: `WA`, label: `Washington` },
      { value: `WV`, label: `West Virginia` },
      { value: `WI`, label: `Wisconsin` },
      { value: `WY`, label: `Wyoming` },
    ],
    hasMore: false,
  }
}

export const loadReasonFilling = async () => {
  return {
    options: [
      { value: `NO INSURANCE`, label: `No Insurance` },
      { value: `ACCIDENT WITH DEATH`, label: `Accident with death` },
      { value: `MULTIPLE RECKLESS/MAJORS`, label: `Multiple Reckless/Majors` },
      { value: `DRUG/ALCOHOL RELATED`, label: `Drug Alcohol Related` },
      { value: `OTHER`, label: `Other` },
    ],
    hasMore: false,
  }
}

export const loadEmploymentStatus = async () => {
  return {
    options: [
      { value: `EMPLOYED`, label: `EMPLOYED` },
      { value: `UNEMPLOYED`, label: `UNEMPLOYED` },
      { value: `MILITARY`, label: `MILITARY` },
      { value: `RETIRED`, label: `RETIRED` },
      { value: `STUDENT`, label: `STUDENT` },
      { value: `HOME-MAKER`, label: `HOME MAKER` },
      { value: `SELF EMPLOYED`, label: `SELF EMPLOYED` },
    ],
    hasMore: false,
  }
}

export const loadEducationLevel = async () => {
  return {
    options: [
      { value: `BACHELORS DEGREE`, label: `Bachelors Degree` },
      { value: `MASTERS DEGREE`, label: `Masters Degree` },
      { value: `PHD/DOCTORATE`, label: `PHD/DOCTORATE` },
      { value: `MEDICAL DEGREE`, label: `Medical Degree` },
      { value: `LAW DEGREE`, label: `Law Degree` },
      { value: `NO HIGH SCHOOL DIPLOMA`, label: `No High School Diploma` },
      { value: `HIGH SCHOOL DIPLOMA`, label: `High School Diploma` },
      { value: `ASSOCIATE DEGREE`, label: `Associates Degree` },
      { value: `SOME COLLEGE, NO DEGREE`, label: `Some College,No Degree` },
      {
        value: `VOCATIONAL/TECHNICAL DEGREE`,
        label: `VOCATIONAL/TECHNICAL Degree`,
      },
    ],
    hasMore: false,
  }
}

export const loadDriverType = async () => {
  return {
    options: [{ value: `Rated`, label: `Rated` }],
    hasMore: false,
  }
}

export const loadMakeYears = async (search, loadedOptions, { page }) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AQS_BACKEND_API}/get/cars/years?search=${search && search
    }&page=${page}&offset=10`,
  )
  if (data?.supportedModelYears) {
    const formattedMakeYears = data?.supportedModelYears?.map((year) => {
      return {
        value: year.value.toString(),
        label: year.name,
      }
    })
    return {
      options: formattedMakeYears,
      hasMore: data.totalPages - page > 0,
      additional: {
        page: page + 1,
      },
    }
  }
}

export const loadMakes = async (search, loadedOptions, { page, additionalFilters }) => {
  const {
    data: { data },
  } = await AxiosInstance.get(`${AQS_BACKEND_API}/get/cars/makes/${additionalFilters?.year}`)

  if (data?.supportedCarMakes) {
    const formattedCarMake = data?.supportedCarMakes?.map((model) => {
      return {
        value: model.value,
        label: model.name,
      }
    })
    return {
      options: formattedCarMake,
      hasMore: false,
    }
  }
}

export const loadModel = async (search, loadedOptions, { additionalFilters }) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AQS_BACKEND_API}/get/cars/models/${additionalFilters?.year}/${additionalFilters?.make}`,
  )
  if (data?.supportedCarModels) {
    const formattedModel = data?.supportedCarModels.map((make) => {
      return {
        value: make.value,
        label: make.name,
      }
    })

    return {
      options: formattedModel,
      hasMore: false,
    }
  }
}

export const loadCarriers = async (search, loadedOptions, { page }) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AQS_BACKEND_API}/get/carriers/list?search=${search ? search : ``
    }&page=${page}&offset=10`,
  )

  return {
    options: data.carriers,
    hasMore: data.totalPages - page > 0,
    additional: {
      page: page + 1,
    },
  }
}

export const loadBumpLimits = async () => {
  return {
    options: [
      { value: `UP`, label: `UP` },
      { value: `DOWN`, label: `DOWN` },
    ],
    hasMore: false,
  }
}

export const loadPolicyTerm = async () => {
  return {
    options: [
      { value: `1`, label: `Monthly` },
      { value: `12`, label: `Annual` },
      { value: `3`, label: `Quarterly` },
      { value: `6`, label: `Semi Annual` },
      { value: `2`, label: `Two Month` },
    ],
    hasMore: false,
  }
}

export const loadPaymentOption = async () => {
  return {
    options: [
      { value: `INSTALLMENT`, label: `Installments` },
      { value: `INSTALLMENT EFT`, label: `Installments EFT` },
      { value: `FULL`, label: `Paid in Full` },
      { value: `FULL EFT`, label: `Paid in Full EFT` },
    ],
    hasMore: false,
  }
}

export const loadExclusion = async () => {
  return {
    options: [
      { value: `0`, label: `0` },
      { value: `1`, label: `1` },
      { value: `2`, label: `2` },
      { value: `3`, label: `3` },
      { value: `4`, label: `4` },
      { value: `5`, label: `5` },
      { value: `6`, label: `6` },
      { value: `7`, label: `7` },
      { value: `8`, label: `8` },
      { value: `9`, label: `9` },
      { value: `10`, label: `10` },
      { value: `11`, label: `11` },
      { value: `12`, label: `12` },
      { value: `13`, label: `13` },
      { value: `14`, label: `14` },
      { value: `15`, label: `15` },
      { value: `16`, label: `16` },
      { value: `17`, label: `17` },
      { value: `18`, label: `18` },
      { value: `19`, label: `19` },
      { value: `20`, label: `20` },
    ],
    hasMore: false,
  }
}

export const loadLossType = async () => {
  return {
    options: [
      { value: `NONE`, label: `NONE` },
      { value: `LEASE`, label: `Lease` },
      { value: `LOAN`, label: `Loan` },
    ],
    hasMore: false,
  }
}

export const loadLossPayeeName = async () => {
  return {
    options: [
      { value: `exeterFinancial`, label: `Exeter Financial` },
      { value: `kiaMotorsFinance`, label: `Kia motors finance` },
      { value: `nissanMotorAcceptance`, label: `Nissan Motor Acceptance` },
    ],
    hasMore: false,
  }
}

export const loadUsage = async () => {
  return {
    options: [
      { value: `ARTISAN USE`, label: `Artisan Use` },
      { value: `BUSINESS USE`, label: `Business Use` },
      { value: `FARM`, label: `Farm` },
      { value: `PLEASURE`, label: `Pleasure` },
      { value: `WORK/SCHOOL`, label: `Work/School` },
    ],
    hasMore: false,
  }
}

export const loadAntiLockBrakes = async () => {
  return {
    options: [
      { value: `NO ANTI-LOCK`, label: `No Anti-Lock` },
      { value: `FRONT ANTI-LOCK`, label: `Front Anti-Lock` },
      { value: `REAR ANTI-LOCK`, label: `Rear Anti-Lock` },
      { value: `ALL ANTI-LOCK`, label: `All Anti-Lock` },
    ],
    hasMore: false,
  }
}

export const loadPassiveRestraint = async () => {
  return {
    options: [
      { value: `NO PASSIVE RESTRAINT`, label: `No Passive Restraint` },
      { value: `DRIVER SIDE`, label: `Driver Side` },
      { value: `BOTH SIDES`, label: `Both Sides` },
    ],
    hasMore: false,
  }
}

export const loadAirBags = async () => {
  return {
    options: [
      { value: `NO AIR BAG`, label: `No Air Bags` },
      { value: `DRIVER SIDE`, label: `Driver Side` },
      { value: `BOTH SIDES`, label: `Both Sides` },
    ],
    hasMore: false,
  }
}

export const loadFuelType = async () => {
  return {
    options: [
      { value: `GAS`, label: `Gas` },
      { value: `GASOLINE`, label: `Gasoline` },
      { value: `DIESEL`, label: `Diesel` },
      { value: `ELECTRIC`, label: `Electric` },
      { value: `FLEXIBLE`, label: `Flexible` },
      { value: `ETHANOL`, label: `Ethanol` },
      { value: `METHANDOL`, label: `Methanol` },
      { value: `NATURALGAS`, label: `Natural Gas` },
      { value: `PROPANE`, label: `Propane` },
      { value: `HYBRID`, label: `Hybrid` },
    ],
    hasMore: false,
  }
}

export const loadTruckSize = async () => {
  return {
    options: [
      { value: `1/4 TON`, label: `Quater Ton` },
      { value: `1/2 TON`, label: `Half Ton` },
      { value: `3/4 TON`, label: `Three Quater Ton` },
      { value: `1 TON`, label: `One Ton` },
      { value: `> 1 TON`, label: `Over One Ton` },
    ],
    hasMore: false,
  }
}

export const loadVehicleType = async () => {
  return {
    options: [
      { value: `TRUCK`, label: `Truck` },
      { value: `CAR`, label: `Car` },
      { value: `STATION WAGON`, label: `Station Wagon` },
      { value: `UTILITY`, label: `Utility` },
      { value: `MINI VAN`, label: `Mini Van` },
      { value: `VAN`, label: `Van` },
    ],
    hasMore: false,
  }
}

export const loadAntiTheft = async () => {
  return {
    options: [
      { value: `NO ANTI-THEFT`, label: `No Anti-Theft` },
      { value: `ANTI-THEFT LEVEL 1`, label: `Anti-Theft Level 1` },
      { value: `ANTI-THEFT LEVEL 2`, label: `Anti-Theft Level 2` },
      { value: `ANTI-THEFT LEVEL 3`, label: `Anti-Theft Level 3` },
      { value: `ANTI-THEFT LEVEL 4`, label: `Anti-Theft Level 4` },
      { value: `ANTI-THEFT LEVEL 5`, label: `Anti-Theft Level 5` },
      { value: `ANTI-THEFT LEVEL 6`, label: `Anti-Theft Level 6` },
      { value: `ANTI-THEFT LEVEL 7`, label: `Anti-Theft Level 7` },
      { value: `ANTI-THEFT LEVEL 8`, label: `Anti-Theft Level 8` },
      { value: `ANTI-THEFT LEVEL 9`, label: `Anti-Theft Level 9` },
    ],
    hasMore: false,
  }
}

export const loadVehicleCondition = async () => {
  return {
    options: [
      { value: `NEW`, label: `New` },
      { value: `USED`, label: `Used` },
    ],
    hasMore: false,
  }
}

export const loadColors = async () => {
  return {
    options: [
      { value: `RED`, label: `RED` },
      { value: `GREEN`, label: `GREEN` },
      { value: `BLUE`, label: `BLUE` },
      { value: `WHITE`, label: `WHITE` },
      { value: `BLACK`, label: `BLACK` },
      { value: `GREY`, label: `GREY` },
      { value: `PURPLE`, label: `PURPLE` },
    ],
    hasMore: false,
  }
}
