import React from 'react'
import { smallLogo } from 'App/Assets'
import { FImage, FContainer } from './style'
import { Container } from 'App/Styled'
import { Skelton } from '../Skelton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

export const Header = ({
  image,
  name,
  phone,
  address,
  email,
  isLoading,
  remainingBalancePolicies,
  remainingBalanceInvoices,
  noImage = true,
  noBalance = false,
  locationName,
}) => {
  return (
    <Container display={`true`}>
      <FContainer>
        {isLoading ? (
          <Skelton photo={!noImage} />
        ) : (
          <div className=" flex justify-center items-center">
            {noImage && (
              <FImage
                alt="fImage"
                src={image ? image : smallLogo}
                className="border-2 "
              />
            )}

            <div className="text-sm font-bold px-6">
              <div className="flex flex-col capitalize">
                <div>
                  <FontAwesomeIcon
                    icon={faUser}
                    className="text-gray-400 "
                    size="sm"
                  />
                  &nbsp;
                  {name && name?.toLowerCase()}
                </div>

                {phone && (
                  <div>
                    <FontAwesomeIcon
                      icon={faPhoneAlt}
                      className="text-gray-400 mt-0.5"
                      size="sm"
                    />
                    &nbsp;
                    {phone}
                  </div>
                )}

                {email && (
                  <div>
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-gray-400 mt-0.5"
                      size="sm"
                    />
                    &nbsp;
                    {email}
                  </div>
                )}
              </div>

              {address &&
                (address.city ||
                  address.street ||
                  address.unitNo ||
                  address.state) && (
                  <div className="capitalize">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-gray-400 mt-0.5"
                      size="sm"
                    />
                    &nbsp;
                    {address.unitNo && address.unitNo?.toLowerCase() + `, `}
                    {address.street && address.street?.toLowerCase() + `, `}
                    {address.city && address.city?.toLowerCase() + `, `}
                    {address.state && address.state + `, `}
                    {address.zip}
                  </div>
                )}
            </div>
          </div>
        )}
      </FContainer>
      {isLoading ? (
        !noBalance && <Skelton photo={true} />
      ) : (
        <div className="w-56 flex flex-col">
          {remainingBalancePolicies !== undefined && (
            <div className="font-bold text-sm text-red-600">
              Policies Balance:
              <span className="text-black"> ${remainingBalancePolicies}</span>
            </div>
          )}
          {remainingBalanceInvoices !== undefined && (
            <div className="font-bold text-sm text-red-600">
              Invoices Balance:
              <span className="text-black">${remainingBalanceInvoices}</span>
            </div>
          )}
          {locationName !== undefined && (
            <div className="font-bold text-sm text-red-600 capitalize mt-3">
              Primary Office:
              <span className="text-black"> {locationName?.toLowerCase()}</span>
            </div>
          )}
        </div>
      )}
    </Container>
  )
}
