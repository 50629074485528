import React from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import FileSaver from 'file-saver'
import { faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ImageModal } from 'App/Components/Common/ImageModal'
export const ClaimsMediaFiles = () => {
  const [preview, setPreview] = useState(false)
  const [currentImage, setCurrentImage] = useState(``)
  const { claimMedia } = useSelector(({ claim }) => claim)
  return (
    // ext !== ".jpg" && ext !== ".jpeg" && ext !== ".png" &&
    // ext !== ".pdf" && ext !== ".mp3" && ext !== ".docx" && ext !== ".doc"
    <>
      {preview && currentImage && (
        <ImageModal
          currentImage={currentImage}
          setPreview={setPreview}
          setCurrentImage={setCurrentImage}
        />
      )}
      <div className="mt-2 h-350 overflow-y-scroll hide-scroller hide-scroller-firefox">
        <div className="flex justify-center flex-col  my-0">
          {claimMedia?.links?.map((link) => (
            <div key={link} className="">
              {(link?.includes(`.jpg`) ||
                link?.includes(`.jpeg`) ||
                link?.includes(`.png`)) && (
                <img
                  src={link}
                  alt="Image failed to load"
                  className="rounded-md h-24vh w-17vw cursor-pointer mt-2 "
                  onClick={() => {
                    setPreview(true)
                    setCurrentImage(link)
                  }}
                />
              )}
              {link?.includes(`.mp3`) && (
                <audio
                  src={link}
                  controls
                  // autoPlay
                  className="w-56 h-8  mt-4 "
                />
              )}

              {link?.includes(`.pdf`) && (
                <div className="block mb-2">
                  <div
                    className={`flex items-center justify-between  gap-1 w-auto text-red-500`}
                  >
                    {/* <span>Document</span> */}
                    <div
                      className="flex gap-2 tooltip tooltip-right"
                      data-tip="Download file"
                    >
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        size="10x"
                        onClick={() => FileSaver.saveAs(link)}
                        className="cursor-pointer shadow-md hover:text-red-700 "
                      />
                    </div>
                  </div>
                </div>
              )}
              {(link?.includes(`.doc`) || link?.includes(`.docx`)) && (
                <div className="block mb-2">
                  <div
                    className={`flex items-center justify-between  gap-1 w-auto text-blue-500`}
                  >
                    {/* <span>Document</span> */}
                    <div
                      className="flex gap-2 tooltip tooltip-right"
                      data-tip="Download file"
                    >
                      <FontAwesomeIcon
                        icon={faFileWord}
                        size="10x"
                        onClick={() => FileSaver.saveAs(link)}
                        className="cursor-pointer shadow-md hover:text-blue-700 "
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* <div className="flex justify-center flex-col  my-0">
          {claimMedia?.links?.map((audio) => (
            <audio
              src={audio.secure_url}
              controls
              // autoPlay
              className="w-56 h-8  mt-4 "
            />
          ))}
        </div> */}
      </div>
    </>
  )
}
