import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentCredential: ``,
  currentCredentialGroup: ``,
  activeActivityTab: 1,
}

const crendetialsSlice = createSlice({
  name: `credentials`,

  initialState,
  reducers: {
    setCurrentCredential: (state, { payload }) => {
      state.currentCredential = payload
    },
    setCredentialActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
    setCurrentGroupCredential: (state, { payload }) => {
      state.currentCredentialGroup = payload
    },
  },
})

export const {
  setCurrentCredential,
  setCredentialActivityActiveTab,
  setCurrentGroupCredential,
} = crendetialsSlice.actions

export const credentials = crendetialsSlice.reducer
