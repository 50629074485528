import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  participantsLength: ``,
}

const videoCallSlice = createSlice({
  name: `video`,
  initialState,

  reducers: {
    setParticipantsLength: (state, { payload }) => {
      state.participantsLength = payload
    },
  },
})

export const { setParticipantsLength } = videoCallSlice.actions

export const video = videoCallSlice.reducer
