import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const ValidateDuplicateCustomerEmail = async (email) => {
  return await AxiosInstance.post(
    `${AMS_BACKEND_API}/api/validate/customer-payloads`,
    {
      email: email,
    },
  )
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error.response.data
    })
}
