import React, { useEffect, useState, Fragment } from 'react'
import {
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Badge,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@windmill/react-ui'
import { useHistory } from 'react-router-dom'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { Reload } from 'App/Components/Common/Reload'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from '../Common/Pagination'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { loadOffset } from 'App/Services/General/loadOffset'
import { ReactSelectField } from '../Common/ReactSelect'
import { NotificationManager } from 'react-notifications'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dateFormat from 'App/Services/General/dateFormat'
import FiltersSystemRole from './FiltersSystemRole'

export const SystemRoles = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const { sub: currentUser } = useSelector(({ user }) => user?.user?.profile)

  const [isOffset, setIsOffset] = useState(``)
  const { url } = useRouteMatch()
  const history = useHistory()
  const tableColumns = [
    { label: `Name`, value: `name` },
    { label: `Description`, value: `description` },
    { label: `Created At`, value: `createdAt` },
    { label: `Created By`, value: `createdBy.label` },
  ]
  const [systemRoleFilters, setSystemRoleFilters] = useState({
    name: ``,
  })
  //  date range picker
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
    },
  ])
  const {
    isLoading,
    data: systemRolesData,
    error,
    refetch: getAllSystemRoles,
    isFetching,
  } = useQuery(
    `SystemRolesApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/system-roles?search=${searchFilters()}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    mutate,
    isLoading: deletingRole,
    isError: deletionError,
    error: reponseError,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/system-roles/${id}`,
      ).then((res) => {
        res.data && getAllSystemRoles()
        NotificationManager.success(`Successfully Deleted`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllSystemRoles()
    }
  }, [sort, currentPage, isOffset, systemRoleFilters])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    mutate(id)
  }

  const handleResetFilters = () => {
    setSystemRoleFilters({
      name: ``,
    })
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: `selection`,
      },
    ])
  }

  const handleFiltersChange = (filters) => {
    setCurrentPage(1)
    setSystemRoleFilters(filters)
  }

  const handleDateRange = (range) => {
    setDateRange(range)
  }

  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }

    if (systemRoleFilters?.name?.length) {
      data.filters.name = systemRoleFilters?.name
    }

    if (dateRange[0].isSelected) {
      data.filters.createdAt = [
        dateFormat(dateRange[0].startDate),
        dateFormat(dateRange[0].endDate),
      ]
    }
    return JSON.stringify(data)
  }

  return (
    <Container>
      {deletionError && (
        <ReactNotification
          action="error"
          message={reponseError?.response?.data?.message}
        />
      )}
      <Spinner loading={isLoading || isFetching || deletingRole} />
      <PageHeader>
        System Roles
        <div className="flex items-center">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
        </div>
      </PageHeader>
      {permissionGranted([
        `system-roles:create`,
        `corporate-manager:*`,
        `manager:*`,
        `admin:*`,
      ]) && (
        <div className="flex justify-end items-center px-2">
          <StyledButton onClick={() => history.push(`/admin/system-roles/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        </div>
      )}

      <FiltersSystemRole
        filtersProps={systemRoleFilters}
        handleFilters={handleFiltersChange}
        resetFilters={handleResetFilters}
        _dateRange={dateRange}
        handleDateChange={handleDateRange}
        isLoading={isLoading || isFetching}
      />

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center p-2 ${
          (isLoading || deletingRole || isFetching) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllSystemRoles()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className=" cursor-pointer select-none text-sm flex   ">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-2 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort === `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}

                    {permissionGranted([
                      `system-roles:delete`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) && (
                      <TableCell className="select-none text-sm float-right px-6">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!systemRolesData?.systemRoles?.length ? (
                    isLoading || isFetching || deletingRole ? null : (
                      <tr>
                        <td colSpan="12" className="text-center p-6">
                          <div>No Role Found</div>
                        </td>
                      </tr>
                    )
                  ) : (
                    systemRolesData?.systemRoles?.map((role, index) => (
                      <Fragment key={index}>
                        <TableRow
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            if (
                              permissionGranted([
                                `system-roles:read`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ])
                            ) {
                              history.push(`${url}/${role._id}`)
                            }
                          }}
                        >
                          <td className="px-4 py-0.5 z-0 capitalize truncate text-sm">
                            {role?.name ? (
                              role?.name?.toLowerCase()
                            ) : (
                              <Badge type="primary">N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm">
                            <p className="truncate w-48 capitalize">
                              {role?.description?.length ? (
                                role?.description?.toLowerCase()
                              ) : (
                                <Badge type="warning">N/A</Badge>
                              )}
                            </p>
                          </td>

                          <td className="px-6 py-0.5 z-0 text-sm">
                            <span>{dateFormat(role.createdAt)}</span>
                          </td>
                          <td className="px-8 py-0.5 z-0 capitalize truncate text-sm">
                            {role?.createdBy?.label ? (
                              role?.createdBy?.label?.toLowerCase()
                            ) : (
                              <Badge type="primary">N/A</Badge>
                            )}
                          </td>
                          {/* Actions TD */}
                          {permissionGranted([
                            `system-roles:delete`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ]) ? (
                            <td className="px-6 py-0.5 z-0 text-sm flex float-right text-gray-400">
                              <span
                                data-tip={
                                  currentUser === role?.createdBy?.value
                                    ? `Delete`
                                    : `Not Allowed`
                                }
                                className="tooltip"
                              >
                                {
                                  <FontAwesomeIcon
                                    className={`mx-1 mr-4 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 ${
                                      currentUser === role?.createdBy?.value
                                        ? `cursor-pointer`
                                        : `cursor-not-allowed`
                                    } `}
                                    icon={faTrash}
                                    size="1x"
                                    onClick={(e) => {
                                      currentUser === role?.createdBy?.value
                                        ? handleDelete(role._id, e)
                                        : e &&
                                          e.stopPropagation &&
                                          e.stopPropagation() &&
                                          history.push(`${url}`)
                                    }}
                                  />
                                }
                              </span>
                            </td>
                          ) : null}
                        </TableRow>
                      </Fragment>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            {!isFetching && (
              <div className="p-4">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={systemRolesData?.totalPages}
                  totalRecords={systemRolesData?.totalRecords}
                  curerntPageRecords={systemRolesData?.currentPageRecords}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  )
}
