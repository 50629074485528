import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentPayment: ``,
  allPayments: [],
  activeActivityTab: 1,
}

const paymentSlice = createSlice({
  name: `payment`,

  initialState,
  reducers: {
    setCurrentPayment: (state, { payload }) => {
      state.currentPayment = payload
    },
    addPayments: (state, { payload }) => {
      state.allPayments.push(payload)
    },
    setPaymentActiveActivityTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
  },
})

export const { setCurrentPayment, addPayments, setPaymentActiveActivityTab } =
  paymentSlice.actions

export const payment = paymentSlice.reducer
