import React, { useEffect, useState } from 'react'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL } from 'App/Config'
import { loadCarTypes } from 'App/Services'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { InputField } from 'App/Components/Common/InputField'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import {
  loadBodyTypes,
  loadGaragingTypes,
  loadBoolean,
  loadUseTypes,
  loadZipCodes,
  ValidateZip,
  loadPerformance,
  loadLienHolderTypes,
  loadLienHolderInterests,
  loadRegistrationStates,
  loadYears,
  loadDrivers,
} from 'App/Services'
import { useHistory, useRouteMatch } from 'react-router'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useDebouncedCallback } from 'use-debounce'

export const AddVehicle = () => {
  const [asset, setAsset] = useState({
    drivers: ``,
    type: ``,
    registrationState: ``,
    vinNumber: ``,
    make: ``,
    year: ``,
    model: ``,
    registrationExpirationDate: ``,
    usage: ``,
    value: ``,
    deductable: ``,
    deductableCollision: ``,
    description: ``,
    isVisible: false,
    garaging: ``,
    bodyType: ``,
    garagingRadius: ``,
    costNew: ``,
    newVehicle: ``,
    alteredVehicle: ``,
    alterationsAmount: ``,
    length: ``,
    licensePlateNumber: ``,
    fullTermPremium: ``,
    vehicleWeight: ``,
    numberOfCylinders: ``,
    displacement: ``,
    rateClassCode: ``,
    physicaldemagerateClass: ``,
    leased: ``,
    isSeenCar: ``,
    multiCarDisount: ``,
    damageLiability: ``,
    bumberDiscount: ``,
    performance: ``,
    seatBeltType: ``,
    airBagType: ``,
    milesToWork: ``,
    use: [],
    daysDriverPerWeek: ``,
    carPool: ``,
    odoMeterReading: ``,
    estimatedAnnualMiles: ``,
    existingUnrepairedDemage: ``,
    residualMarketFacility: ``,
    antiTheftPercent: ``,
    antiLockBrake: ``,
    numberOfAxis: ``,
    maximumSpeed: ``,
    numberOfYouthfulOperators: ``,
    enginetype: ``,
    inspectionStatus: ``,
    collisionSymbol: ``,
    comprehensiveSymbol: ``,
    isRegistered: false,
    isNonOwned: false,
    isAntiThefdevice: false,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    zip: ``,
    street: ``,
    city: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    driversError: ``,
    typeError: ``,
    registrationStateError: ``,
    vinNumberError: ``,
    makeError: ``,
    yearError: ``,
    modelError: ``,
    registrationExpirationDateError: ``,
    usageError: ``,
    valueError: ``,
    deductableError: ``,
    deductableCollisionError: ``,
    descriptionError: ``,
    isVisibleError: ``,
    garagingError: ``,
    bodyTypeError: ``,
    garagingRadiusError: ``,
    costNewError: ``,
    newVehicleError: ``,
    alteredVehicleError: ``,
    alterationsAmountError: ``,
    lengthError: ``,
    licensePlateNumberError: ``,
    fullTermPremiumError: ``,
    vehicleWeightError: ``,
    numberOfCylindersError: ``,
    displacementError: ``,
    rateClassCodeError: ``,
    physicaldemagerateClassError: ``,
    leasedError: ``,
    isSeenCarError: ``,
    multiCarDisountError: ``,
    damageLiabilityError: ``,
    bumberDiscountError: ``,
    performanceError: ``,
    seatBeltTypeError: ``,
    airBagTypeError: ``,
    milesToWorkError: ``,
    useError: ``,
    daysDriverPerWeekError: ``,
    carPoolError: ``,
    odoMeterReadingError: ``,
    estimatedAnnualMilesError: ``,
    existingUnrepairedDemageError: ``,
    residualMarketFacilityError: ``,
    antiTheftPercentError: ``,
    antiLockBrakeError: ``,
    numberOfAxisError: ``,
    maximumSpeedError: ``,
    numberOfYouthfulOperatorsError: ``,
    enginetypeError: ``,
    inspectionStatusError: ``,
    collisionSymbolError: ``,
    comprehensiveSymbolError: ``,
    isRegisteredError: ``,
    isNonOwnedError: ``,
    isAntiThefdeviceError: ``,
    unitNoError: ``,
    zipError: ``,
    streetError: ``,
    cityError: ``,
    stateError: ``,
    coordinatesError: false,
  })

  const [lienHolders, setLienHolders] = useState([
    {
      type: ``,
      typeError: ``,
      natureOfInterest: ``,
      natureOfInterestError: ``,
    },
  ])

  const [policyCoverages, setPolicyCoverages] = useState([])

  const [vehicleCoverages, setVehicleCoverages] = useState([])
  const history = useHistory()
  const {
    params: { customerId },
  } = useRouteMatch()

  //vehicle add mutation
  const {
    mutate: addVehicle,
    isLoading: addingVehicle,
    isError: addingError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/assets/${customerId}/type/vehicles`,
        {
          vehicle: { ...payload },
        },
      ).then((res) => {
        res.data && history.push(`/customers/${customerId}`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  // eslint-disable-next-line no-unused-vars
  const { isLoading } = useQuery(
    `PolicyCoveragesApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/get/policy-coverages`),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: ({
        data: {
          data: { coverages },
        },
      }) => {
        let policyCoverages = []
        coverages.policyScoped.map((policy) => {
          policyCoverages.push({
            coverage: policy.name,
            loadOptions: policy.limits,
            limit: ``,
            limitError: ``,
            premium: ``,
            premiumError: ``,
            scope: policy.scope,
          })
        })
        setPolicyCoverages([...policyCoverages])
      },
    },
  )

  // eslint-disable-next-line no-unused-vars
  const { isLoading: vehicleCoveragesLoading } = useQuery(
    `VehicleCoveragesApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/get/vehicle-coverages`),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: ({
        data: {
          data: { coverages },
        },
      }) => {
        let vehicleCoverages = []
        coverages.vehicleScoped.map((policy) => {
          vehicleCoverages.push({
            coverage: policy.name,
            loadOptions: policy.limits,
            scope: policy.scope,
            limit: ``,
            limitError: ``,
            premium: ``,
            premiumError: ``,
          })
        })
        setVehicleCoverages([...vehicleCoverages])
      },
    },
  )

  const removeLienHolderForm = (index) => {
    if (lienHolders.length <= 1) {
      return
    }
    const lienHolder = [...lienHolders]
    lienHolder.splice(index, 1)
    setLienHolders(lienHolder)
  }

  const handleAssetChange = useDebouncedCallback((name, value) => {
    setAsset((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleAddressChange = useDebouncedCallback((name, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  //handle zip code

  const handleZipChange = useDebouncedCallback(async (val) => {
    const data = await ValidateZip(val.value)
    if (data) {
      const res = data.address

      setAddress((prevState) => ({
        ...prevState,
        [`city`]: res.city,
        [`state`]: res.state,
        [`zip`]: { value: res.zipCode, label: res.zipCode },
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        [`lat`]: res.lat,
        [`lng`]: res.long,
      }))
    }
  }, 5)

  const handlePolicyCoverageChange = useDebouncedCallback(
    (value, name, ind) => {
      const newCoverages = policyCoverages?.map((coverage, index) => {
        if (index === ind) {
          coverage[name] = value
        }
        return coverage
      })
      setPolicyCoverages(newCoverages)
    },
    5,
  )

  const handleVehicleCoverageChange = useDebouncedCallback(
    (value, name, ind) => {
      const newCoverages = policyCoverages?.map((coverage, index) => {
        if (index === ind) {
          coverage[name] = value
        }
        return coverage
      })
      setVehicleCoverages(newCoverages)
    },
    5,
  )

  const handleLienHoldersChange = useDebouncedCallback((value, name, ind) => {
    const newLienHolders = lienHolders?.map((holders, index) => {
      if (index === ind) {
        holders[name] = value
      }
      return holders
    })
    setLienHolders(newLienHolders)
  }, 5)
  // handle map
  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  // const handlePolicyCoverageErrors = (ind, name, value) => {
  //   const newCoverages = policyCoverages?.map((coverage, index) => {
  //     if (index == ind) {
  //       coverage[name] = value
  //     }
  //     return coverage
  //   })
  //   setPolicyCoverages(newCoverages)
  // }

  // const handleVehicleCoverageErrors = (ind, name, value) => {
  //   const newCoverages = vehicleCoverages?.map((coverage, index) => {
  //     if (index == ind) {
  //       coverage[name] = value
  //     }
  //     return coverage
  //   })
  //   setVehicleCoverages(newCoverages)
  // }

  const handleLienHoldersErrors = useDebouncedCallback((ind, name, value) => {
    const newLienHolder = lienHolders?.map((holder, index) => {
      if (index == ind) {
        holder[name] = value
      }
      return holder
    })
    setLienHolders(newLienHolder)
  }, 5)

  const ValidateForm = () => {
    if (!asset.drivers?.length) {
      handleErrors(`driversError`, `Select Driver`)
    } else {
      handleErrors(`driversError`, ``)
    }
    if (!asset.type?.value?.length) {
      handleErrors(`typeError`, `Select Car Type`)
    } else {
      handleErrors(`typeError`, ``)
    }

    if (!asset.registrationState?.value?.length) {
      handleErrors(`registrationStateError`, `Select State`)
    } else {
      handleErrors(`registrationStateError`, ``)
    }

    if (!asset.vinNumber.length) {
      handleErrors(`vinNumberError`, `Enter Number`)
    } else {
      handleErrors(`vinNumberError`, ``)
    }

    if (!asset.make.length) {
      handleErrors(`makeError`, `Enter Make`)
    } else {
      handleErrors(`makeError`, ``)
    }
    if (!asset.year?.value) {
      handleErrors(`yearError`, `Enter Year`)
    } else {
      handleErrors(`yearError`, ``)
    }

    if (!asset.model.length) {
      handleErrors(`modelError`, `Enter Modal`)
    } else {
      handleErrors(`modelError`, ``)
    }

    if (!asset.registrationExpirationDate) {
      handleErrors(`registrationExpirationDateError`, `Select Data`)
    } else {
      handleErrors(`registrationExpirationDateError`, ``)
    }

    if (!asset.usage.length) {
      handleErrors(`usageError`, `Enter Usage`)
    } else {
      handleErrors(`usageError`, ``)
    }
    if (!asset.value.length) {
      handleErrors(`valueError`, `Enter Value`)
    } else {
      handleErrors(`valueError`, ``)
    }

    if (!asset.deductable?.length) {
      handleErrors(`deductableError`, `Enter Amount`)
    } else {
      handleErrors(`deductableError`, ``)
    }

    if (!asset.deductableCollision?.length) {
      handleErrors(`deductableCollisionError`, `Enter Collision`)
    } else {
      handleErrors(`deductableCollisionError`, ``)
    }

    if (!asset.description?.length) {
      handleErrors(`descriptionError`, `Select Business Type`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    if (!asset.garaging?.value?.length) {
      handleErrors(`garagingError`, `Select Garaging`)
    } else {
      handleErrors(`garagingError`, ``)
    }

    if (!asset.bodyType?.value?.length) {
      handleErrors(`bodyTypeError`, `Select Body Type`)
    } else {
      handleErrors(`bodyTypeError`, ``)
    }

    if (!asset.garagingRadius?.length) {
      handleErrors(`garagingRadiusError`, `Enter Radius`)
    } else {
      handleErrors(`garagingRadiusError`, ``)
    }

    if (!asset.newVehicle?.value?.length) {
      handleErrors(`newVehicleError`, `Select Option`)
    } else {
      handleErrors(`newVehicleError`, ``)
    }

    if (!asset.costNew?.length) {
      handleErrors(`costNewError`, `Enter Cost`)
    } else {
      handleErrors(`costNewError`, ``)
    }

    if (!asset.alteredVehicle?.value?.length) {
      handleErrors(`alteredVehicleError`, `Select Option`)
    } else {
      handleErrors(`alteredVehicleError`, ``)
    }

    if (!asset.alterationsAmount?.length) {
      handleErrors(`alterationsAmountError`, `Enter Amount`)
    } else {
      handleErrors(`alterationsAmountError`, ``)
    }
    if (!asset.length?.length) {
      handleErrors(`lengthError`, `Enter Length`)
    } else {
      handleErrors(`lengthError`, ``)
    }

    if (!asset.licensePlateNumber?.length) {
      handleErrors(`licensePlateNumberError`, `Enter Number`)
    } else {
      handleErrors(`licensePlateNumberError`, ``)
    }

    if (!asset.fullTermPremium?.length) {
      handleErrors(`fullTermPremiumError`, `Enter Premium`)
    } else {
      handleErrors(`fullTermPremiumError`, ``)
    }

    if (!asset.vehicleWeight?.length) {
      handleErrors(`vehicleWeightError`, `Enter Weight`)
    } else {
      handleErrors(`vehicleWeightError`, ``)
    }

    if (!asset.numberOfCylinders?.length) {
      handleErrors(`numberOfCylindersError`, `Enter Number`)
    } else {
      handleErrors(`numberOfCylindersError`, ``)
    }

    if (!asset.displacement?.length) {
      handleErrors(`displacementError`, `Enter Displacement`)
    } else {
      handleErrors(`displacementError`, ``)
    }

    if (!asset.rateClassCode?.length) {
      handleErrors(`rateClassCodeError`, `Enter Code`)
    } else {
      handleErrors(`rateClassCodeError`, ``)
    }

    if (!asset.physicaldemagerateClass?.length) {
      handleErrors(`physicaldemagerateClassError`, `Enter Damage Rate`)
    } else {
      handleErrors(`physicaldemagerateClassError`, ``)
    }

    if (!asset.leased?.value?.length) {
      handleErrors(`leasedError`, `Select Leased`)
    } else {
      handleErrors(`leasedError`, ``)
    }

    if (!asset.isSeenCar?.value?.length) {
      handleErrors(`isSeenCarError`, `Choose Option`)
    } else {
      handleErrors(`isSeenCarError`, ``)
    }

    if (!asset.multiCarDisount?.value?.length) {
      handleErrors(`multiCarDisountError`, `Choose Option`)
    } else {
      handleErrors(`multiCarDisountError`, ``)
    }

    if (!asset.damageLiability?.length) {
      handleErrors(`damageLiabilityError`, `Enter Damage Liability`)
    } else {
      handleErrors(`damageLiabilityError`, ``)
    }

    if (!asset.bumberDiscount?.length) {
      handleErrors(`bumberDiscountError`, `Enter Bumber Discount`)
    } else {
      handleErrors(`bumberDiscountError`, ``)
    }

    if (!asset.performance?.value?.length) {
      handleErrors(`performanceError`, `Select Performance`)
    } else {
      handleErrors(`performanceError`, ``)
    }

    if (!asset.seatBeltType?.length) {
      handleErrors(`seatBeltTypeError`, `Enter Type`)
    } else {
      handleErrors(`seatBeltTypeError`, ``)
    }

    if (!asset.airBagType?.length) {
      handleErrors(`airBagTypeError`, `Enter Type`)
    } else {
      handleErrors(`airBagTypeError`, ``)
    }
    if (!asset.milesToWork?.length) {
      handleErrors(`milesToWorkError`, `Enter Miles`)
    } else {
      handleErrors(`milesToWorkError`, ``)
    }

    if (!asset.use?.value?.length) {
      handleErrors(`useError`, `Select Use Type`)
    } else {
      handleErrors(`useError`, ``)
    }

    if (!asset.daysDriverPerWeek?.length) {
      handleErrors(`daysDriverPerWeekError`, `Enter Days`)
    } else {
      handleErrors(`daysDriverPerWeekError`, ``)
    }

    if (!asset.carPool?.length) {
      handleErrors(`carPoolError`, `Enter Car Pool`)
    } else {
      handleErrors(`carPoolError`, ``)
    }

    if (!asset.odoMeterReading?.length) {
      handleErrors(`odoMeterReadingError`, `Enter ODO Meter Reading`)
    } else {
      handleErrors(`odoMeterReadingError`, ``)
    }
    if (!asset.estimatedAnnualMiles?.length) {
      handleErrors(`estimatedAnnualMilesError`, `Enter Annual Miles`)
    } else {
      handleErrors(`estimatedAnnualMilesError`, ``)
    }

    if (!asset.existingUnrepairedDemage?.length) {
      handleErrors(`existingUnrepairedDemageError`, `Enter Value`)
    } else {
      handleErrors(`existingUnrepairedDemageError`, ``)
    }

    if (!asset.residualMarketFacility?.length) {
      handleErrors(`residualMarketFacilityError`, `Enter Value`)
    } else {
      handleErrors(`residualMarketFacilityError`, ``)
    }

    if (!asset.antiTheftPercent?.length) {
      handleErrors(`antiTheftPercentError`, `Enter Percent`)
    } else {
      handleErrors(`antiTheftPercentError`, ``)
    }

    if (!asset.antiLockBrake?.value?.length) {
      handleErrors(`antiLockBrakeError`, `Choose Option`)
    } else {
      handleErrors(`antiLockBrakeError`, ``)
    }

    if (!asset.numberOfAxis?.length) {
      handleErrors(`numberOfAxisError`, `Enter Value`)
    } else {
      handleErrors(`numberOfAxisError`, ``)
    }

    if (!asset.maximumSpeed?.length) {
      handleErrors(`maximumSpeedError`, `Enter Speed`)
    } else {
      handleErrors(`maximumSpeedError`, ``)
    }

    if (!asset.numberOfYouthfulOperators?.length) {
      handleErrors(`numberOfYouthfulOperatorsError`, `Enter No`)
    } else {
      handleErrors(`numberOfYouthfulOperatorsError`, ``)
    }

    if (!asset.enginetype?.length) {
      handleErrors(`enginetypeError`, `Enter Type`)
    } else {
      handleErrors(`enginetypeError`, ``)
    }

    if (!asset.inspectionStatus?.length) {
      handleErrors(`inspectionStatusError`, `Enter Status`)
    } else {
      handleErrors(`inspectionStatusError`, ``)
    }

    if (!asset.collisionSymbol?.length) {
      handleErrors(`collisionSymbolError`, `Enter Symbol`)
    } else {
      handleErrors(`collisionSymbolError`, ``)
    }

    if (!asset.comprehensiveSymbol?.length) {
      handleErrors(`comprehensiveSymbolError`, `Enter Comprehensive Symbol`)
    } else {
      handleErrors(`comprehensiveSymbolError`, ``)
    }

    if (!address.unitNo.length) {
      handleErrors(`unitNoError`, `Enter Unit No`)
    } else {
      handleErrors(`unitNoError`, ``)
    }
    if (!address.zip?.value?.length) {
      handleErrors(`zipError`, `Enter Zip`)
    } else {
      handleErrors(`zipError`, ``)
    }

    if (!address.street.length) {
      handleErrors(`streetError`, `Enter Street`)
    } else {
      handleErrors(`streetError`, ``)
    }

    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }

    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, true)
    } else {
      handleErrors(`coordinatesError`, false)
    }

    // for (const index in policyCoverages) {
    //   const coverage = policyCoverages[index]

    //   if (!coverage.limit?.value?.length) {
    //     handlePolicyCoverageErrors(index, `limitError`, `Select Limit`)
    //   } else handlePolicyCoverageErrors(index, `limitError`, ``)

    //   if (!coverage.premium?.value?.length) {
    //     handlePolicyCoverageErrors(index, `premiumError`, `Select Premium`)
    //   } else handlePolicyCoverageErrors(index, `premiumError`, ``)
    // }

    // for (const index in vehicleCoverages) {
    //   const coverage = vehicleCoverages[index]

    //   if (!coverage.limit?.value?.length) {
    //     handleVehicleCoverageErrors(index, `limitError`, `Select Limit`)
    //   } else handleVehicleCoverageErrors(index, `limitError`, ``)

    //   if (!coverage.premium?.value?.length) {
    //     handleVehicleCoverageErrors(index, `premiumError`, `Select Premium`)
    //   } else handleVehicleCoverageErrors(index, `premiumError`, ``)
    // }

    for (const index in lienHolders) {
      const holder = lienHolders[index]

      if (!holder.type?.value?.length) {
        handleLienHoldersErrors(index, `typeError`, `Select Type`)
      } else handleLienHoldersErrors(index, `typeError`, ``)

      if (!holder.natureOfInterest?.value?.length) {
        handleLienHoldersErrors(
          index,
          `natureOfInterestError`,
          `Select Interest`,
        )
      } else handleLienHoldersErrors(index, `natureOfInterestError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    let formValidated = false

    if (
      errors.formSubmit &&
      !errors.driversError.length &&
      !errors.typeError.length &&
      !errors.registrationStateError.length &&
      !errors.vinNumberError.length &&
      !errors.makeError.length &&
      !errors.yearError.length &&
      !errors.modelError.length &&
      !errors.registrationExpirationDateError.length &&
      !errors.usageError.length &&
      !errors.valueError.length &&
      !errors.deductableError.length &&
      !errors.deductableCollisionError.length &&
      !errors.isVisibleError.length &&
      !errors.bodyTypeError.length &&
      !errors.garagingRadiusError.length &&
      !errors.costNewError.length &&
      !errors.newVehicleError.length &&
      !errors.alteredVehicleError.length &&
      !errors.alterationsAmountError.length &&
      !errors.lengthError.length &&
      !errors.licensePlateNumberError.length &&
      !errors.fullTermPremiumError.length &&
      !errors.vehicleWeightError.length &&
      !errors.numberOfCylindersError.length &&
      !errors.displacementError.length &&
      !errors.rateClassCodeError.length &&
      !errors.physicaldemagerateClassError.length &&
      !errors.leasedError.length &&
      !errors.isSeenCarError.length &&
      !errors.multiCarDisountError.length &&
      !errors.damageLiabilityError.length &&
      !errors.bumberDiscountError.length &&
      !errors.performanceError.length &&
      !errors.seatBeltTypeError.length &&
      !errors.airBagTypeError.length &&
      !errors.milesToWorkError.length &&
      !errors.useError.length &&
      !errors.daysDriverPerWeekError.length &&
      !errors.carPoolError.length &&
      !errors.odoMeterReadingError.length &&
      !errors.estimatedAnnualMilesError.length &&
      !errors.existingUnrepairedDemageError.length &&
      !errors.residualMarketFacilityError.length &&
      !errors.antiTheftPercentError.length &&
      !errors.antiLockBrakeError.length &&
      !errors.numberOfAxisError.length &&
      !errors.maximumSpeedError.length &&
      !errors.numberOfYouthfulOperatorsError.length &&
      !errors.enginetypeError.length &&
      !errors.inspectionStatusError.length &&
      !errors.collisionSymbolError.length &&
      !errors.comprehensiveSymbolError.length &&
      !errors.isRegisteredError.length &&
      !errors.isNonOwnedError.length &&
      !errors.isAntiThefdeviceError.length &&
      !errors.unitNoError.length &&
      !errors.zipError.length &&
      !errors.streetError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.coordinatesError
    ) {
      lienHolders.forEach((lienholder) => {
        if (!lienholder.typeError.length && !lienholder.natureOfInterestError) {
          formValidated = true
          handleErrors(`formSubmit`, false)
        } else {
          formValidated = false
          handleErrors(`formSubmit`, false)
        }
      })

      if (formValidated) {
        //call API
        const policyCoveragesPayload = policyCoverages?.map((coverage) => {
          return {
            coverage: coverage.coverage,
            limit: coverage.limit.value,
            premium: coverage.premium,
            scope: coverage.scope,
          }
        })
        const vehicleCoveragesPayload = vehicleCoverages?.map((coverage) => {
          return {
            coverage: coverage.coverage,
            limit: coverage.limit.value,
            premium: coverage.premium,
            scope: coverage.scope,
          }
        })
        const lienHoldersPayload = lienHolders?.map((holder) => {
          return {
            type: holder.type.value,
            natureOfInterest: holder.natureOfInterest.value,
          }
        })
        const gragingAddress = {
          unitNo: address.unitNo,
          zip: address.zip.value,
          street: address.street,
          city: address.city,
          state: address.state,
          coordinates: {
            lat: coordinates.lat.toString(),
            long: coordinates.lng.toString(),
          },
        }

        if (
          policyCoveragesPayload?.length &&
          vehicleCoveragesPayload.length &&
          lienHoldersPayload?.length
        ) {
          addVehicle({
            drivers: asset.drivers.map((driver) => driver.value),
            type: asset.type.value,
            registrationState: asset.registrationState.value,
            vinNumber: asset.vinNumber,
            make: asset.make,
            year: asset.year.value,
            model: asset.model,
            registrationExpirationDate: asset.registrationExpirationDate,
            usage: asset.usage,
            value: asset.value,
            deductable: asset.deductable,
            deductableCollision: asset.deductableCollision,
            description: asset.description,
            isVisible: asset.isVisible,
            //additional
            garaging: asset.garaging.value,
            bodyType: asset.bodyType.value,
            garagingRadius: asset.garagingRadius,
            newVehicle: asset.newVehicle.value,
            costNew: asset.costNew,
            alteredVehicle: asset.alteredVehicle.value,
            alterationsAmount: asset.alterationsAmount,
            length: asset.length,
            licensePlateNumber: asset.licensePlateNumber,
            fullTermPremium: asset.fullTermPremium,
            vehicleWeight: asset.vehicleWeight,
            numberOfCylinders: asset.numberOfCylinders,
            displacement: asset.displacement,
            rateClassCode: asset.rateClassCode,
            physicaldemagerateClass: asset.physicaldemagerateClass,
            leased: asset.leased.value,
            isSeenCar: asset.isSeenCar.value,
            multiCarDisount: asset.multiCarDisount.value,
            damageLiability: asset.damageLiability,
            bumberDiscount: asset.bumberDiscount,
            performance: asset.performance.value,
            seatBeltType: asset.seatBeltType,
            airBagType: asset.airBagType,
            milesToWork: asset.milesToWork,
            use: asset.use?.value,
            daysDriverPerWeek: asset.daysDriverPerWeek,
            carPool: asset.carPool,
            odoMeterReading: asset.odoMeterReading,
            estimatedAnnualMiles: asset.estimatedAnnualMiles,
            existingUnrepairedDemage: asset.existingUnrepairedDemage,
            residualMarketFacility: asset.residualMarketFacility,
            antiTheftPercent: asset.antiTheftPercent,
            antiLockBrake: asset.antiLockBrake.value,
            numberOfAxis: asset.numberOfAxis,
            maximumSpeed: asset.maximumSpeed,
            numberOfYouthfulOperators: asset.numberOfYouthfulOperators,
            enginetype: asset.enginetype,
            inspectionStatus: asset.inspectionStatus,
            collisionSymbol: asset.collisionSymbol,
            comprehensiveSymbol: asset.comprehensiveSymbol,
            isRegistered: asset.isRegistered,
            isNonOwned: asset.isNonOwned,
            isAntiThefdevice: asset.isAntiThefdevice,
            garageAddress: gragingAddress,
            Coverages: [...policyCoveragesPayload, ...vehicleCoveragesPayload],
            lienHolders: lienHoldersPayload,
          })
        }
      }
    }
  }, [errors])

  return (
    <Container>
      <PageHeader>
        <div>Add Vehicle</div>
      </PageHeader>
      <Spinner loading={addingVehicle} />
      {addingError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <div
        className={`px-8 py-1 ${
          addingVehicle && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Driver"
              placeholder="Select"
              value={asset.drivers}
              errorMessage={errors.driversError}
              setValue={(value) =>
                setAsset((prevState) => ({
                  ...prevState,
                  [`drivers`]: value,
                }))
              }
              isMulti={true}
              loadOptions={loadDrivers}
              isSearchable={true}
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="Car Type"
              placeholder="Select Type"
              value={asset.type}
              errorMessage={errors.typeError}
              setValue={(value) =>
                setAsset((prevState) => ({
                  ...prevState,
                  [`type`]: value,
                }))
              }
              loadOptions={loadCarTypes}
              isSearchable={false}
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="State"
              placeholder="Select State"
              value={asset.registrationState}
              errorMessage={errors.registrationStateError}
              setValue={(value) =>
                setAsset((prevState) => ({
                  ...prevState,
                  [`registrationState`]: value,
                }))
              }
              isMulti={false}
              loadOptions={loadRegistrationStates}
              isSearchable={true}
            />
          </div>
          <div className=" flex-1 relative">
            <InputField
              type="text"
              title="VIN No"
              placeholder="Enter Vin Number"
              tipdata="vehicle identification number"
              value={asset.vinNumber}
              errorMessage={errors.vinNumberError}
              setValue={(e) => handleAssetChange(`vinNumber`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Make"
              name="make"
              placeholder="Enter Make"
              value={asset.make}
              errorMessage={errors.makeError}
              setValue={(e) => handleAssetChange(`make`, e.target.value)}
            />
          </div>
        </div>
        {/* row 2 */}
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Year"
              placeholder="Select Year"
              value={asset.year}
              errorMessage={errors.yearError}
              setValue={(value) =>
                setAsset((prevState) => ({
                  ...prevState,
                  [`year`]: value,
                }))
              }
              isMulti={false}
              loadOptions={loadYears}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="model"
              title="Model"
              placeholder="Enter Model No"
              value={asset.model}
              errorMessage={errors.modelError}
              setValue={(e) => handleAssetChange(`model`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <DatePickerField
              title="Reg Expiration Date"
              name="registrationExpirationDate"
              placeholder="Enter Regestration Expiration Date"
              value={asset.registrationExpirationDate}
              errorMessage={errors.registrationExpirationDateError}
              setValue={(date) => {
                setAsset((prevState) => ({
                  ...prevState,
                  [`registrationExpirationDate`]: date,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="usage"
              title="Usage"
              placeholder="Enter Asset Usage"
              value={asset.usage}
              errorMessage={errors.usageError}
              setValue={(e) => handleAssetChange(`usage`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              title="Value"
              name="value"
              placeholder="Enter Value"
              value={asset.value}
              errorMessage={errors.valueError}
              setValue={(e) => handleAssetChange(`value`, e.target.value)}
            />
          </div>
        </div>
        {/* row 3 */}
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="deductable"
              title="Deductable"
              placeholder="Enter Deductable Amount"
              value={asset.deductable}
              errorMessage={errors.deductableError}
              setValue={(e) => handleAssetChange(`deductable`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="deductableCollision"
              title="Deductable Collision"
              placeholder="Enter Deductable Collision"
              value={asset.deductableCollision}
              errorMessage={errors.deductableCollisionError}
              setValue={(e) =>
                handleAssetChange(`deductableCollision`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="description"
              title="Description"
              placeholder="Enter Description"
              value={asset.description}
              errorMessage={errors.descriptionError}
              setValue={(e) => handleAssetChange(`description`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative mt-6">
            <Checkbox
              type="checkbox"
              name="isVisible"
              title="Is Visible"
              value={asset.isVisible}
              errorMessage={errors.isVisibleError}
              setValue={() =>
                setAsset((prevState) => ({
                  ...prevState,
                  [`isVisible`]: !asset.isVisible,
                }))
              }
            />
          </div>
          <div className="flex-1 relative mt-6"></div>
        </div>
        {/* ADDITIONAL */}
        <div className="my-4">
          <Collapsable title="additional">
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Garaging"
                  placeholder="Select Garaging"
                  value={asset.garaging}
                  errorMessage={errors.garagingError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`garaging`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadGaragingTypes}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Body Type"
                  placeholder="Select Body Type"
                  value={asset.bodyType}
                  errorMessage={errors.bodyTypeError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`bodyType`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBodyTypes}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="garagingRadius"
                  title="Garaging Raduis"
                  placeholder="Enter Garaging Raduis"
                  value={asset.garagingRadius}
                  errorMessage={errors.garagingRadiusError}
                  setValue={(e) =>
                    handleAssetChange(`garagingRadius`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="New Vehicle"
                  placeholder="Select Vehicle"
                  value={asset.newVehicle}
                  errorMessage={errors.newVehicleError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`newVehicle`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="costNew"
                  title="Cost New"
                  placeholder="Enter Cost New"
                  value={asset.costNew}
                  errorMessage={errors.costNewError}
                  setValue={(e) => handleAssetChange(`costNew`, e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Altered Vehicle"
                  placeholder="Select Altered"
                  value={asset.alteredVehicle}
                  errorMessage={errors.alteredVehicleError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`alteredVehicle`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="alterationsAmount"
                  title="Alteratoins Amount"
                  placeholder="Enter Alteratoins Amount"
                  value={asset.alterationsAmount}
                  errorMessage={errors.alterationsAmountError}
                  setValue={(e) =>
                    handleAssetChange(`alterationsAmount`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="length"
                  title="Length"
                  placeholder="Enter Length"
                  value={asset.length}
                  errorMessage={errors.lengthError}
                  setValue={(e) => handleAssetChange(`length`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative ">
                <InputField
                  type="text"
                  name="licensePlateNumber"
                  title="License PlateNumber"
                  placeholder="Enter License PlateNumber"
                  value={asset.licensePlateNumber}
                  errorMessage={errors.licensePlateNumberError}
                  setValue={(e) =>
                    handleAssetChange(`licensePlateNumber`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="fullTermPremium"
                  title="Full Term Premium"
                  placeholder="Enter Full Term Premium"
                  value={asset.fullTermPremium}
                  errorMessage={errors.fullTermPremiumError}
                  setValue={(e) =>
                    handleAssetChange(`fullTermPremium`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="vehicleWeight"
                  title="Vehicle Weight"
                  placeholder="Enter Vehicle Weight"
                  value={asset.vehicleWeight}
                  errorMessage={errors.vehicleWeightError}
                  setValue={(e) =>
                    handleAssetChange(`vehicleWeight`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="numberOfCylinders"
                  title="Number Of Cylinders"
                  placeholder="Enter Number Of Cylinders"
                  value={asset.numberOfCylinders}
                  errorMessage={errors.numberOfCylindersError}
                  setValue={(e) =>
                    handleAssetChange(`numberOfCylinders`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative ">
                <InputField
                  type="text"
                  name="displacement"
                  title="Displacement"
                  placeholder="Enter Displacement"
                  value={asset.displacement}
                  errorMessage={errors.displacementError}
                  setValue={(e) =>
                    handleAssetChange(`displacement`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="rateClassCode"
                  title="Rate Class Code"
                  placeholder="Enter Code"
                  value={asset.rateClassCode}
                  errorMessage={errors.rateClassCodeError}
                  setValue={(e) =>
                    handleAssetChange(`rateClassCode`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="physicaldemagerateClass"
                  title="Physical Demage Rate Class"
                  placeholder="Enter Physical Demage RateClass"
                  value={asset.physicaldemagerateClass}
                  errorMessage={errors.physicaldemagerateClassError}
                  setValue={(e) =>
                    handleAssetChange(`physicaldemagerateClass`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Leased"
                  placeholder="Select Leased"
                  value={asset.leased}
                  errorMessage={errors.leasedError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`leased`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative ">
                <ReactSelectField
                  title="Seen Car"
                  placeholder="Choose Option"
                  value={asset.isSeenCar}
                  errorMessage={errors.isSeenCarError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`isSeenCar`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Multi Car Disount"
                  placeholder="Choose Option"
                  value={asset.multiCarDisount}
                  errorMessage={errors.multiCarDisountError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`multiCarDisount`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="damageLiability"
                  title="Damage Liability"
                  placeholder="Enter Damage Liability"
                  value={asset.damageLiability}
                  errorMessage={errors.damageLiabilityError}
                  setValue={(e) =>
                    handleAssetChange(`damageLiability`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="bumberDiscount"
                  title="Bumber Discount"
                  placeholder="Enter Bumber Discount"
                  value={asset.bumberDiscount}
                  errorMessage={errors.bumberDiscountError}
                  setValue={(e) =>
                    handleAssetChange(`bumberDiscount`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative ">
                <ReactSelectField
                  title="Performance"
                  placeholder="Choose Option"
                  value={asset.performance}
                  errorMessage={errors.performanceError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`performance`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadPerformance}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="seatBeltType"
                  title="Seat Belt Type"
                  placeholder="Enter SeatBelt Type"
                  value={asset.seatBeltType}
                  errorMessage={errors.seatBeltTypeError}
                  setValue={(e) =>
                    handleAssetChange(`seatBeltType`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="airBagType"
                  title="AirBag Type"
                  placeholder="Enter AirBag Type"
                  value={asset.airBagType}
                  errorMessage={errors.airBagTypeError}
                  setValue={(e) =>
                    handleAssetChange(`airBagType`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="milesToWork"
                  title="Miles To Work"
                  placeholder="Enter Miles To Work"
                  value={asset.milesToWork}
                  errorMessage={errors.milesToWorkError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative ">
                <ReactSelectField
                  title="Use"
                  placeholder="Select Use Type"
                  value={asset.use}
                  errorMessage={errors.useError}
                  loadOptions={loadUseTypes}
                  isSearchable={false}
                  isMulti={false}
                  setValue={(e) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`use`]: e,
                    }))
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="daysDriverPerWeek"
                  title="Days Driver Per Week"
                  placeholder="Enter Days Driver Per Week"
                  value={asset.daysDriverPerWeek}
                  errorMessage={errors.daysDriverPerWeekError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="carPool"
                  title="Car Pool"
                  placeholder="Enter Car Pool"
                  value={asset.carPool}
                  errorMessage={errors.carPoolError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="odoMeterReading"
                  title="ODO Meter Reading"
                  placeholder="Enter ODO Reading"
                  value={asset.odoMeterReading}
                  errorMessage={errors.odoMeterReadingError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative ">
                <InputField
                  type="number"
                  name="estimatedAnnualMiles"
                  title="Estimated Annual Miles"
                  placeholder="Enter Estimated Annual Miles"
                  value={asset.estimatedAnnualMiles}
                  errorMessage={errors.estimatedAnnualMilesError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="existingUnrepairedDemage"
                  title="Existing Unrepaired Demage"
                  placeholder="Enter Existing Unrepaired Demage"
                  value={asset.existingUnrepairedDemage}
                  errorMessage={errors.existingUnrepairedDemageError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="residualMarketFacility"
                  title="Residual Market Facility"
                  placeholder="Enter Residual Market Facility"
                  value={asset.residualMarketFacility}
                  errorMessage={errors.residualMarketFacilityError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="antiTheftPercent"
                  title="Anti Theft Percent"
                  placeholder="Enter Anti Theft Percent"
                  value={asset.antiTheftPercent}
                  errorMessage={errors.antiTheftPercentError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative ">
                <ReactSelectField
                  title="Anti Lock Brake"
                  placeholder="Choose Option"
                  value={asset.antiLockBrake}
                  errorMessage={errors.antiLockBrakeError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`antiLockBrake`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="numberOfAxis"
                  title="Number Of Axis"
                  placeholder="Enter Number Of Axis"
                  value={asset.numberOfAxis}
                  errorMessage={errors.numberOfAxisError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="maximumSpeed"
                  title="Maximum Speed in Miles"
                  placeholder="Enter Maximum Speed"
                  value={asset.maximumSpeed}
                  errorMessage={errors.maximumSpeedError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="numberOfYouthfulOperators"
                  title="Number Of Youthful Operators"
                  placeholder="Enter Number Of Youthful Operators"
                  value={asset.numberOfYouthfulOperators}
                  errorMessage={errors.numberOfYouthfulOperatorsError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative ">
                <InputField
                  type="text"
                  name="enginetype"
                  title="Engine Type"
                  placeholder="Enter Engine Type"
                  value={asset.enginetype}
                  errorMessage={errors.enginetypeError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="inspectionStatus"
                  title="Inspection Status"
                  placeholder="Enter Inspection Status"
                  value={asset.inspectionStatus}
                  errorMessage={errors.inspectionStatusError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="collisionSymbol"
                  title="Collision Symbol"
                  placeholder="Enter Collision Symbol"
                  value={asset.collisionSymbol}
                  errorMessage={errors.collisionSymbolError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="comprehensiveSymbol"
                  title="Comprehensive Symbol"
                  placeholder="Enter Comprehensive Symbol"
                  value={asset.comprehensiveSymbol}
                  errorMessage={errors.comprehensiveSymbolError}
                  setValue={(e) => handleAssetChange(`model`, e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative ">
                <Checkbox
                  title="Registered"
                  name="isRegistered"
                  value={asset.isRegistered}
                  errorMessage={errors.isRegisteredError}
                  setValue={() =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`isRegistered`]: !asset.isRegistered,
                    }))
                  }
                />
              </div>
              <div className="flex-1 relative">
                <Checkbox
                  title="Non Owned"
                  name="isNonOwned"
                  value={asset.isNonOwned}
                  errorMessage={errors.isNonOwnedError}
                  setValue={() =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`isNonOwned`]: !asset.isNonOwned,
                    }))
                  }
                />
              </div>
              <div className="flex-1 relative">
                <Checkbox
                  title="Anti Thef device"
                  name="isAntiThefdevice"
                  value={asset.isAntiThefdevice}
                  errorMessage={errors.isAntiThefdeviceError}
                  setValue={() =>
                    setAsset((prevState) => ({
                      ...prevState,
                      [`isAntiThefdevice`]: !asset.isAntiThefdevice,
                    }))
                  }
                />
              </div>
              <div className="flex-1 relative"></div>
              <div className="flex-1 relative"></div>
            </div>

            <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
              Address
            </div>
            <div className="flex gap-6 mt-2 text-xs">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="unitNo"
                  title="Unit No"
                  placeholder="Enter Unit No"
                  value={address.unitNo}
                  errorMessage={errors.unitNoError}
                  setValue={(e) =>
                    handleAddressChange(`unitNo`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Zip"
                  name="zip"
                  placeholder="Select Zip"
                  isMulti={false}
                  value={address.zip}
                  errorMessage={errors.zipError}
                  setValue={(e) => handleZipChange(e)}
                  loadOptions={loadZipCodes}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="street"
                  title="Street"
                  placeholder="Enter Street"
                  value={address.street}
                  errorMessage={errors.streetError}
                  setValue={(e) =>
                    handleAddressChange(`street`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="city"
                  title="City"
                  placeholder="Enter City"
                  value={address.city}
                  errorMessage={errors.cityError}
                  disabled={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="state"
                  title="State"
                  placeholder="Enter State"
                  value={address.state}
                  errorMessage={errors.stateError}
                  id="lAddress"
                  disabled={true}
                />
              </div>
            </div>
            <div className="rounded-md grid grid-cols-1">
              <label className="text-sm mt-2 text-gray-700 font-medium">
                Locate Yourself
                {errors.coordinatesError && (
                  <span className="text-red-500 required-dot ml-2">*</span>
                )}
              </label>
              <MapContainer
                center={[coordinates.lat, coordinates.lng]}
                zoom={3}
                scrollWheelZoom={true}
              >
                <ChangeView
                  center={[coordinates.lat, coordinates.lng]}
                  zoom={3}
                />
                <TileLayer url={LEAFLET_URL} />
                <DraggableMarker
                  coord={coordinates}
                  setCoordinates={(e) => setCoordinates(e)}
                />
              </MapContainer>
            </div>
          </Collapsable>
        </div>
        {/* End Additional */}
        {/* coverages */}
        <div className="my-4">
          <Collapsable title="Coverages">
            <>
              <div className="font-semibold my-2">Policy Scopped</div>
              {policyCoverages?.map((coverage, index) => (
                <div key={index}>
                  <div className="flex gap-6 ">
                    <div className="flex-1 relative">
                      <InputField
                        title="Coverage"
                        value={coverage.coverage}
                        disabled={true}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Limit"
                        placeholder="Select Limit"
                        value={coverage.limit}
                        loadOptions={() => ({
                          options: coverage.loadOptions,
                          hasMore: false,
                        })}
                        errorMessage={coverage.limitError}
                        setValue={(value) =>
                          handlePolicyCoverageChange(value, `limit`, index)
                        }
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="number"
                        name="premium"
                        title="Premium"
                        placeholder="Enter Premium"
                        value={coverage.premium}
                        errorMessage={errors.premiumError}
                        setValue={(e) =>
                          handlePolicyCoverageChange(
                            e.target.value,
                            `premium`,
                            index,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="font-semibold my-4">Vehicle Scopped</div>
              {vehicleCoverages?.map((coverage, index) => (
                <div key={index}>
                  <div className=" flex gap-6 ">
                    <div className="flex-1 relative">
                      <InputField
                        title="Coverage"
                        value={coverage.coverage}
                        disabled={true}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Limit"
                        placeholder="Select Limit"
                        value={coverage.limit}
                        loadOptions={() => ({
                          options: coverage.loadOptions,
                          hasMore: false,
                        })}
                        errorMessage={coverage.limitError}
                        setValue={(value) =>
                          handleVehicleCoverageChange(value, `limit`, index)
                        }
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="text"
                        name="premium"
                        title="Premium"
                        placeholder="Enter Premium"
                        value={address.premium}
                        errorMessage={errors.premiumError}
                        setValue={(e) =>
                          handleVehicleCoverageChange(
                            e.target.value,
                            `premium`,
                            index,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          </Collapsable>
        </div>
        {/*end coverages */}
        {/* Lean Holders */}
        <div className="my-4">
          <Collapsable title="Lien Holders">
            {lienHolders?.map((lienHolder, index) => (
              <div key={index} className="grid grid-cols-12">
                <div className="col-span-10 flex gap-6 ">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Type"
                      placeholder="Select Type"
                      value={lienHolder?.type}
                      errorMessage={lienHolder?.typeError}
                      loadOptions={loadLienHolderTypes}
                      isSearchable={true}
                      setValue={(value) =>
                        handleLienHoldersChange(value, `type`, index)
                      }
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Nature Of Interest"
                      placeholder="Select Interest"
                      value={lienHolder?.natureOfInterest}
                      errorMessage={lienHolder?.natureOfInterestError}
                      loadOptions={loadLienHolderInterests}
                      isSearchable={true}
                      setValue={(value) =>
                        handleLienHoldersChange(
                          value,
                          `natureOfInterest`,
                          index,
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-span-2 mt-7">
                  <div className="flex items-center justify-center float-right">
                    <span
                      onClick={() =>
                        setLienHolders([
                          ...lienHolders,
                          {
                            type: ``,
                            typeError: ``,
                            natureOfInterest: ``,
                            natureOfInterestError: ``,
                          },
                        ])
                      }
                      data-tip="Add Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500 cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="transform transition  hover:-translate-y-0 hover:scale-105"
                      />
                    </span>
                    <span
                      onClick={() => removeLienHolderForm(index)}
                      data-tip="Remove Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500 cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="transform transition  hover:-translate-y-0 hover:scale-105"
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Collapsable>
        </div>
        {/* Lean Holders End */}
        <div className="text-center xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
          <SubmitButton
            onClick={() => {
              ValidateForm()
            }}
          >
            Create Vehicle
          </SubmitButton>
        </div>
      </div>
    </Container>
  )
}
