import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'

import React from 'react'
import { useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router'
import { Container, EditButton, PageHeader } from 'App/Styled'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentLocation } from 'App/Redux/actions'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { Header } from 'App/Components/Common/Header'

export const AboutLocation = () => {
  const dispatch = useDispatch()
  const franchiseStatus = useSelector(
    ({ location }) => location?.currentLocation?.status,
  )
  const {
    params: { lId },
  } = useRouteMatch()
  const { url } = useRouteMatch()
  const history = useHistory()

  const {
    data: previewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `LocationPreviewApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/locations/${lId}`).then(
        (res) => {
          let location = res.data.data.location
          let roundedCoordinates = {
            lat: location.address?.coordinates?.lat.toFixed(3),
            long: location.address?.coordinates?.long.toFixed(3),
          }
          if (location.address?.coordinates) {
            location.address.coordinates = roundedCoordinates
          }
          return location
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentLocation(res))
      },
    },
  )
  return (
    <Container>
      <Header
        image={previewData?.image}
        name={previewData?.name}
        phone={previewData?.phone}
        email={previewData?.email}
        address={previewData?.address}
        isLoading={isLoading || isFetching}
      />
      <PageHeader>
        <div>Location Info</div>
        {permissionGranted([
          `locations:update`,
          `corporate-manager:*`,
          `manager:*`,
        ]) && (
          <EditButton
            onClick={() => history.push(`${url}/update`)}
            disabled={
              (franchiseStatus !== `INACTIVE` ? false : true) ||
              isLoading ||
              isFetching
            }
          >
            <span>Edit</span>
            <FontAwesomeIcon
              icon={faPen}
              className="text-white mt-0.5"
              size="sm"
            />
          </EditButton>
        )}
      </PageHeader>

      <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md">
        {isLoading || isFetching ? (
          <div className="text-center p-6">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : previewData?.location?.length ? (
          <p className=" text-center p-6">No Data Found</p>
        ) : (
          <div className="rounded-md overflow-hidden py-4">
            <div className="flex flex-col md:flex-row  items-start justify-evenly">
              <div className="flex-1 mx-2  rounded">
                {/* <div className="grid grid-cols-12 px-2 py-2">
                  <div className="col-span-6 text-xl">Location Info</div>
                </div> */}
                <hr />
                <div className="px-3">
                  <CardField
                    title="Location ID"
                    value={previewData?._locationId}
                  />
                  <CardField title="Location Name" value={previewData?.name} />
                  <CardField
                    title="Business Name"
                    value={previewData?.businessName}
                  />
                  <CardField
                    title="Franchise Name"
                    value={previewData?._franchise.name}
                  />
                  <CardField
                    title="Commission"
                    value={`$${previewData?.earningsFromCommission}`}
                  />
                  {previewData?.phone ? (
                    <CardField
                      title="Phone Number"
                      value={previewData?.phone}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.workPhone ? (
                    <CardField
                      title="Work Phone"
                      value={previewData?.workPhone}
                    />
                  ) : (
                    ``
                  )}

                  {previewData?.email ? (
                    <CardField title="Email" value={previewData?.email} />
                  ) : (
                    ``
                  )}

                  {previewData?.ext ? (
                    <CardField title="Extension" value={previewData?.ext} />
                  ) : (
                    ``
                  )}
                  {previewData?.fax ? (
                    <CardField title="Fax" value={previewData?.fax} />
                  ) : (
                    ``
                  )}
                  {previewData?._credentialGroup ? (
                    <CardField
                      title="Credential Group"
                      value={previewData?._credentialGroup?.label}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.lobs.length ? (
                    <CardField
                      title="Lobs"
                      value={previewData?.lobs}
                      multiple={true}
                    />
                  ) : (
                    ``
                  )}
                </div>
              </div>
              <div className="flex-1 mx-2  rounded">
                {/* <div className="grid grid-cols-12 px-2 py-2">
                  <div className="col-span-6 text-xl">Address</div>
                </div> */}
                <hr />
                <div className="px-3">
                  {previewData?.address?.unitNo ? (
                    <CardField
                      title="Unit No"
                      value={previewData?.address?.unitNo}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.address?.zip ? (
                    <CardField
                      title="Zip Code"
                      value={previewData?.address?.zip}
                      badge={true}
                      multiple={true}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.address?.street ? (
                    <CardField
                      title="Street"
                      value={previewData?.address?.street}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.address?.city ? (
                    <CardField
                      title="City"
                      value={previewData?.address?.city}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.address?.state ? (
                    <CardField
                      title="State"
                      value={previewData?.address?.state}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.address?.coordinates ? (
                    <CardField
                      title="Coordinates"
                      value={previewData?.address?.coordinates}
                      coord={true}
                    />
                  ) : (
                    ``
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}
