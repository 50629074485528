/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { useMutation } from 'react-query'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'

import {
  ValidateZip,
  loadCustomerTypes,
  loadBusinessTypes,
  ValidateDuplicateCustomerPhone,
  ValidateDuplicateCustomerEmail,
  loadGenderOptions,
  fileResizer,
  loadZipCodes,
  loadOccupations,
} from 'App/Services'
import { MaskField } from 'App/Components/Common/MaskField'
import { useHistory, useRouteMatch } from 'react-router'
import { defaultImage } from 'App/Assets'
import {
  Image,
  Label,
  Container,
  PageHeader,
  SubmitButton,
  SecondaryHeading,
} from 'App/Styled'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce/lib'
import { NotificationManager } from 'react-notifications'
import { loadEducationLevel, loadMaritalStatus } from 'App/Services/quoteForm'
import {
  loadLanguages,
  loadPreferredLanguages,
} from 'App/Services/quoteForm/quoteDetail'
import moment from 'moment'

export const UpdateCustomer = () => {
  const [isFormChanged, setIsFormChanged] = useState(false)
  const {
    params: { fId, customerId },
    url,
  } = useRouteMatch()
  const [customer, setCustomer] = useState({
    type: ``,
    firstName: ``,
    middleName: ``,
    lastName: ``,
    gender: ``,
    maritalStatus: ``,
    educationLevel: ``,
    occupation: ``,
    fein: ``,
    sic: ``,
    acquisitionDate: ``,
    dob: ``,
    preferredLanguage: ``,
    yearBusinessStarted: ``,
    typeOfBusiness: ``,
    workPhone: ``,
    fax: ``,
    email: ``,
    dp: ``,
  })

  const [customerAddress, setCustomerAddress] = useState({
    unitNo: ``,
    streetNo: ``,
    city: ``,
    zip: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    typeError: ``,
    firstNameError: ``,
    lastNameError: ``,
    middleNameError: ``,
    genderError: ``,
    feinError: ``,
    sicError: ``,
    acquisitionDateError: ``,
    dobError: ``,
    preferredLanguageError: ``,
    yearBusinessStartedError: ``,
    typeOfBusinessError: ``,
    workPhoneError: ``,
    faxError: ``,
    streetNoError: ``,
    cityError: ``,
    zipError: ``,
    stateError: ``,
    coordinatesError: ``,
  })

  const { currentCustomer } = useSelector(({ customer }) => customer)

  useEffect(() => {
    if (currentCustomer) {
      setCustomer((prevState) => ({
        ...prevState,
        dp: currentCustomer?.dp,
        type: currentCustomer?.type
          ? {
              value: currentCustomer?.type,
              label: currentCustomer?.type,
            }
          : ``,

        gender: currentCustomer?.gender
          ? {
              label: currentCustomer?.gender?.replace(`_`, ` `),
              value: currentCustomer?.gender,
            }
          : ``,
        firstName: currentCustomer?.firstName,
        lastName: currentCustomer?.lastName,
        middleName: currentCustomer?.middleName,
        occupation: currentCustomer?.occupation,
        maritalStatus: currentCustomer?.maritalStatus
          ? {
              label: currentCustomer?.maritalStatus,
              value: currentCustomer?.maritalStatus,
            }
          : ``,
        educationLevel: currentCustomer?.educationLevel
          ? {
              label: currentCustomer?.educationLevel,
              value: currentCustomer?.educationLevel,
            }
          : ``,
        fein: currentCustomer?.fein,
        sic: currentCustomer?.sic,
        preferredLanguage: currentCustomer?.preferredLanguage
          ? {
              value: currentCustomer?.preferredLanguage,
              label: currentCustomer?.preferredLanguage,
            }
          : ``,
        ext: currentCustomer?.contactInfo?.workPhoneExt,
        workPhone: currentCustomer?.contactInfo?.workPhone,
        fax: currentCustomer?.contactInfo?.fax,

        typeOfBusiness: currentCustomer?.typeOfBusiness
          ? {
              value: currentCustomer?.typeOfBusiness,
              label: currentCustomer?.typeOfBusiness,
            }
          : ``,
        yearBusinessStarted: currentCustomer?.yearBusinessStarted?.toString(),
        acquisitionDate: currentCustomer?.acquisitionDate
          ? new Date(currentCustomer?.acquisitionDate)
          : ``,
        dob: currentCustomer?.dob ? new Date(currentCustomer?.dob) : ``,
      }))

      setCustomerAddress((prevState) => ({
        ...prevState,
        unitNo: currentCustomer?.address?.unitNo,
        zip: currentCustomer?.address?.zip
          ? {
              value: currentCustomer?.address?.zip,
              label: currentCustomer?.address?.zip,
            }
          : ``,
        streetNo: currentCustomer?.address?.street,
        city: currentCustomer?.address?.city,
        state: currentCustomer?.address?.state,
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        lat: currentCustomer?.address?.coordinates?.lat ?? 0,
        lng: currentCustomer?.address?.coordinates?.long ?? 0,
      }))
    }
  }, [currentCustomer])

  const history = useHistory()

  const handleCustomerChange = useDebouncedCallback((name, value) => {
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleCustomerAddressChange = useDebouncedCallback((name, value) => {
    setCustomerAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleCustomerZipChange = async (e, index, contact) => {
    const data = await ValidateZip(e?.value)
    if (data) {
      const res = data.address
      if (contact) {
        const newContacts = contacts?.map((i, ind) => {
          if (index === ind) {
            i.address.city = res.city
            i.address.state = res.state
            i.address.zip = { value: res.zipCode, label: res.zipCode }
            i.address.coordinates.lat = res.lat
            i.address.coordinates.lng = res.long
          }
          return i
        })
        setContacts(newContacts)
      } else {
        setCustomerAddress((prevState) => ({
          ...prevState,
          city: res.city,
          state: res.state,
          zip: { value: res.zipCode, label: res.zipCode },
        }))
        //setting coordinates
        setCoordinates((prevState) => ({
          ...prevState,
          lat: res.lat,
          lng: res.long,
        }))
      }
    }
  }

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/customers/${customerId}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          if (
            res?.data.error?.error.includes(
              `Incoming request body can't be empty.`,
            )
          ) {
            setIsFormChanged(true)
          } else if (res.data) {
            NotificationManager.success(`Operation successful`)
            if (url.includes(`franchises`)) {
              history.push(`/admin/franchises/${fId}/customers/${customerId}`)
            } else {
              history.push(`/customers/${customerId}`)
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            err.response.data.error.error.includes(
              `Incoming request body can't be empty.`,
            ) && NotificationManager.error(`No field Changed`)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  //validate form on click
  const ValidateForm = () => {
    if (!customer?.firstName?.length) {
      handleErrors(`firstNameError`, `Enter Greeting Name`)
    } else {
      handleErrors(`firstNameError`, ``)
    }

    if (!customer?.lastName?.length) {
      handleErrors(`lastNameError`, `Enter Greeting Name`)
    } else {
      handleErrors(`lastNameError`, ``)
    }

    if (!customer.gender?.value?.length) {
      handleErrors(`genderError`, `Select Gender`)
    } else {
      handleErrors(`genderError`, ``)
    }
    if (!customer.preferredLanguage?.value?.length) {
      handleErrors(`preferredLanguageError`, `Select Preferred Language`)
    } else {
      handleErrors(`preferredLanguageError`, ``)
    }

    if (customer?.workPhone?.length)
      if (
        customer?.workPhone !== currentCustomer.contactInfo?.workPhone &&
        customer?.workPhone.length < 12
      ) {
        handleErrors(`workPhoneError`, `Enter Valid Phone`)
      } else {
        handleErrors(`workPhoneError`, ``)
      }

    if (customer?.ext?.length)
      if (
        customer?.ext !== currentCustomer.contactInfo?.workExt &&
        customer?.ext.length < 4
      ) {
        handleErrors(`extError`, `Ext Should Be 4 Digit`)
      } else {
        handleErrors(`extError`, ``)
      }

    if (customer?.fax?.length)
      if (
        customer?.fax !== currentCustomer?.contactInfo?.fax &&
        customer?.fax?.length < 12
      ) {
        handleErrors(`faxError`, `Enter Valid Fax`)
      } else {
        handleErrors(`faxError`, ``)
      }

    if (
      customer?.yearBusinessStarted?.length &&
      customer?.yearBusinessStarted?.length < 4
    ) {
      handleErrors(`yearBusinessStartedError`, `Should be a Year`)
    } else if (customer?.yearBusinessStarted > new Date().getFullYear()) {
      handleErrors(
        `yearBusinessStartedError`,
        `Less from ${new Date().getFullYear() + 1}`,
      )
    } else {
      handleErrors(`yearBusinessStartedError`, ``)
    }

    if (!customer.acquisitionDate) {
      handleErrors(`acquisitionDateError`, `Select Date`)
    } else if (customer?.acquisitionDate) {
      var selectedDate = new Date(customer?.acquisitionDate)
      var nowDate = new Date()
      if (selectedDate > nowDate) {
        handleErrors(`acquisitionDateError`, `Enter Valid date`)
      } else {
        handleErrors(`acquisitionDateError`, ``)
      }
    }

    if (!customer.dob) {
      handleErrors(`dobError`, `Select Date`)
    } else if (customer.dob) {
      var selectDate = new Date(customer.dob)
      var currentDate = new Date()
      if (selectDate > currentDate) {
        handleErrors(`dobError`, `Enter Valid date`)
      } else {
        handleErrors(`dobError`, ``)
      }
    }

    if (!customerAddress.zip?.value?.length) {
      handleErrors(`zipError`, `Enter Zip`)
    } else {
      handleErrors(`zipError`, ``)
    }
    if (!customerAddress?.streetNo?.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }

    if (!customerAddress?.city?.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }

    if (!customerAddress?.state?.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, true)
    } else {
      handleErrors(`coordinatesError`, false)
    }
    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.firstNameError?.length &&
      !errors.lastNameError?.length &&
      !errors.genderError?.length &&
      !errors.workPhoneError?.length &&
      !errors.extError?.length &&
      !errors.faxError?.length &&
      !errors.yearBusinessStartedError?.length &&
      !errors.acquisitionDateError?.length &&
      !errors.dobError?.length &&
      !errors.zipError?.length &&
      !errors.streetNoError?.length &&
      !errors.cityError?.length &&
      !errors.stateError?.length &&
      !errors.preferredLanguageError?.length &&
      !errors.coordinatesError?.length
    ) {
      const updatedPayload = updatePayload()

      //call API
      mutate(updatedPayload)
      setErrors((prevState) => ({
        ...prevState,
        [`formSubmit`]: false,
      }))
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}

    if (customer?.type?.value !== currentCustomer.type) {
      data.type = customer?.type?.value
    }
    if (customer?.gender?.value !== currentCustomer.gender) {
      data.gender = customer?.gender?.value
    }

    if (customer.firstName !== currentCustomer?.firstName) {
      data.firstName = customer.firstName
    }
    if (
      (customer?.middleName?.length || currentCustomer?.middleName?.length) &&
      customer.middleName !== currentCustomer?.middleName
    ) {
      data.middleName = customer.middleName
    }
    if (customer.lastName !== currentCustomer?.lastName) {
      data.lastName = customer.lastName
    }
    if (customer.occupation !== currentCustomer?.occupation) {
      data.occupation = customer.occupation
    }
    if (customer.fein !== currentCustomer.fein) {
      data.fein = customer.fein
    }
    if (customer.sic !== currentCustomer.sic) {
      data.sic = customer.sic
    }
    if (
      customer.acquisitionDate.toString() !==
      new Date(currentCustomer.acquisitionDate).toString()
    ) {
      data.acquisitionDate = customer.acquisitionDate
    }
    if (customer.dob.toString() !== new Date(currentCustomer.dob).toString()) {
      data.dob = new Date(customer.dob)
    }

    if (
      customer?.preferredLanguage?.value !== currentCustomer.preferredLanguage
    ) {
      data.preferredLanguage = customer?.preferredLanguage?.value
    }
    if (customer?.maritalStatus?.value !== currentCustomer.maritalStatus) {
      data.maritalStatus = customer?.maritalStatus?.value
    }
    if (customer?.educationLevel?.value !== currentCustomer.educationLevel) {
      data.educationLevel = customer?.educationLevel?.value
    }
    if (
      customer.yearBusinessStarted !==
      currentCustomer?.yearBusinessStarted?.toString()
    ) {
      data.yearBusinessStarted = customer.yearBusinessStarted
    }
    if (customer?.typeOfBusiness?.value !== currentCustomer.typeOfBusiness) {
      data.typeOfBusiness = customer?.typeOfBusiness?.value
    }
    if (
      (customer.workPhone?.length &&
        customer.workPhone !== currentCustomer?.contactInfo?.workPhone) ||
      (customer?.fax?.length &&
        customer?.fax !== currentCustomer?.contactInfo?.fax) ||
      (customer?.ext?.length &&
        customer?.ext !== currentCustomer?.contactInfo?.workPhoneExt)
    ) {
      data.contactInfo = {}
      if (
        customer.workPhone?.length &&
        customer.workPhone !== currentCustomer?.contactInfo?.workPhone
      ) {
        data.contactInfo.workPhone = customer.workPhone
      }
      if (
        customer?.fax?.length &&
        customer?.fax !== currentCustomer?.contactInfo?.fax
      ) {
        data.contactInfo.fax = customer?.fax
      }
      if (
        customer?.ext?.length &&
        customer?.ext !== currentCustomer?.contactInfo?.workPhoneExt
      ) {
        data.contactInfo.workPhoneExt = customer?.ext
      }
    }

    // address payload
    const addressPayload = {}
    let coordinatesPayload = {}

    if (customerAddress?.unitNo !== currentCustomer?.address?.unitNo) {
      addressPayload.unitNo = customerAddress?.unitNo
    }
    if (customerAddress?.streetNo !== currentCustomer?.address?.street) {
      addressPayload.street = customerAddress.streetNo
    }
    if (customerAddress?.city !== currentCustomer?.address?.city) {
      addressPayload.city = customerAddress?.city
    }
    if (customerAddress?.zip?.value !== currentCustomer?.address?.zip) {
      addressPayload.zip = customerAddress?.zip?.value
      coordinatesPayload.lat = coordinates.lat.toString()
      coordinatesPayload.long = coordinates.lng.toString()
    }
    if (customerAddress.state !== currentCustomer?.address?.state) {
      addressPayload.state = customerAddress?.state
    }
    if (Object.keys(addressPayload).length !== 0) {
      addressPayload.coordinates = coordinatesPayload
      data.address = addressPayload
    }
    setIsFormChanged(false)
    return data
  }

  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  const handleDp = async (file) => {
    const base64 = await fileResizer(file[0])
    setCustomer((prevState) => ({
      ...prevState,
      dp: base64,
    }))
  }

  return (
    <Container className="relative pb-4">
      <PageHeader>
        <div>Update Customer</div>
      </PageHeader>
      {/* {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )} */}
      {isFormChanged && (
        <ReactNotification action="error" message="No Field Changed" />
      )}
      <Spinner loading={isLoading} />

      <div
        className={`px-8 py-1 ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="items-center my-0 mx-96">
          <Label flogo={customer.dp}>
            <Image
              alt="fImage"
              src={customer.dp ? customer.dp : defaultImage}
            />

            <input
              type="file"
              onChange={(e) => handleDp(e.target.files)}
              className="hidden"
              accept="image/*"
            />
            {errors.dpError && (
              <>
                <p className=" text-sm text-red-500 mb-1">
                  {errors.dpError}
                  <span className="text-red-500 required-dot">*</span>
                </p>
              </>
            )}
          </Label>
        </div>
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Type"
              placeholder="Select Type"
              errorMessage={errors.typeError}
              value={customer.type}
              setValue={(e) =>
                setCustomer((prevState) => ({
                  ...prevState,
                  type: e,
                }))
              }
              isMulti={false}
              loadOptions={loadCustomerTypes}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="First Name *"
              placeholder="First Name"
              errorMessage={errors.firstNameError}
              value={customer.firstName}
              setValue={(e) =>
                handleCustomerChange(`firstName`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Middle Name"
              placeholder="Middle Name"
              errorMessage={errors.middleNameError}
              value={customer.middleName}
              setValue={(e) =>
                handleCustomerChange(`middleName`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Last Name *"
              placeholder="Last Name"
              errorMessage={errors.lastNameError}
              value={customer.lastName}
              setValue={(e) => handleCustomerChange(`lastName`, e.target.value)}
            />
          </div>
        </div>

        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <DatePickerField
              title="Date of birth *"
              placeholder="Select Date"
              value={customer.dob}
              errorMessage={errors.dobError}
              setValue={(date) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  dob: date,
                }))
              }}
              maxDate={new Date(moment().subtract(16, `years`))}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Gender *"
              placeholder="Select Gender"
              errorMessage={errors.genderError}
              value={customer.gender}
              setValue={(value) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  gender: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadGenderOptions}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Marital Status"
              placeholder="Select Status"
              value={customer.maritalStatus}
              setValue={(value) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  maritalStatus: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadMaritalStatus}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Education"
              placeholder="Select Education"
              value={customer.educationLevel}
              setValue={(value) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  educationLevel: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadEducationLevel}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Occupation"
              placeholder="Occupation"
              value={customer.occupation}
              setValue={(e) =>
                setCustomer((prevState) => ({
                  ...prevState,
                  occupation: e.target.value,
                }))
              }
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="Preferred Language *"
              placeholder="Preferred Language"
              errorMessage={errors.preferredLanguageError}
              value={customer.preferredLanguage}
              setValue={(e) =>
                setCustomer((prevState) => ({
                  ...prevState,
                  preferredLanguage: e,
                }))
              }
              isMulti={false}
              loadOptions={loadLanguages}
              isSearchable={false}
            />
          </div>

          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Work Phone"
              placeholder="Enter Mobile No"
              errorMessage={errors.workPhoneError}
              name="workPhone"
              value={customer.workPhone}
              setValue={(e) =>
                handleCustomerChange(`workPhone`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Fax"
              placeholder="Enter Fax"
              errorMessage={errors.faxError}
              value={customer?.fax}
              setValue={(e) => handleCustomerChange(`fax`, e.target.value)}
            />
          </div>
        </div>

        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Extension"
              placeholder="Enter Ext"
              errorMessage={errors.extError}
              name="workExt"
              value={customer.ext}
              setValue={(e) => handleCustomerChange(`ext`, e.target.value)}
              mask="9999"
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Business Type"
              placeholder="Select Business Type"
              errorMessage={errors.typeOfBusinessError}
              value={customer.typeOfBusiness}
              setValue={(value) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  typeOfBusiness: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadBusinessTypes}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Years Business Started"
              placeholder="Enter Year"
              errorMessage={errors.yearBusinessStartedError}
              value={customer.yearBusinessStarted}
              setValue={(e) =>
                handleCustomerChange(`yearBusinessStarted`, e.target.value)
              }
              mask="9999"
            />
          </div>
          <div className="flex-1 relative">
            <DatePickerField
              title="Acquisition Date *"
              placeholder="Select Date"
              value={customer.acquisitionDate}
              errorMessage={errors.acquisitionDateError}
              setValue={(date) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  acquisitionDate: date,
                }))
              }}
              maxDate={new Date()}
            />
          </div>
        </div>

        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Fein"
              placeholder="Enter Fein"
              errorMessage={errors.feinError}
              value={customer.fein}
              setValue={(e) => handleCustomerChange(`fein`, e.target.value)}
              mask="999999999"
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Sic"
              placeholder="Enter Sic"
              errorMessage={errors.sicError}
              value={customer.sic}
              setValue={(e) => handleCustomerChange(`sic`, e.target.value)}
              mask="9999"
            />
          </div>
          <div className="flex-1 relative"></div>
          <div className="flex-1 relative"></div>
        </div>

        <SecondaryHeading>Address</SecondaryHeading>

        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street *"
              placeholder="Enter Street"
              errorMessage={errors.streetNoError}
              value={customerAddress.streetNo}
              setValue={(e) =>
                handleCustomerAddressChange(`streetNo`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              placeholder="Select Zip"
              errorMessage={errors.zipError}
              value={customerAddress.zip}
              setValue={(e) => handleCustomerZipChange(e)}
              isMulti={false}
              loadOptions={loadZipCodes}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              placeholder="Enter Unit No"
              value={customerAddress.unitNo}
              setValue={(e) =>
                handleCustomerAddressChange(`unitNo`, e.target.value)
              }
            />
          </div>

          <div className="flex-1 relative">
            <InputField
              type="text"
              title="City"
              name="city"
              placeholder="Enter City"
              errorMessage={errors.cityError}
              disabled={true}
              value={customerAddress.city}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="State"
              name="state"
              placeholder="Enter State"
              errorMessage={errors.stateError}
              disabled={true}
              value={customerAddress.state}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
        </div>
        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates?.lat, coordinates?.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView
              center={[coordinates?.lat, coordinates?.lng]}
              zoom={3}
            />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div>

        <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
          <SubmitButton onClick={ValidateForm}>Update Customer</SubmitButton>
        </div>
      </div>
    </Container>
  )
}
