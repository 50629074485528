import React, { useState, useRef, useMemo, useEffect } from 'react'
import { Marker, Popup, useMapEvents } from 'react-leaflet'

export const DraggableMarker = ({ coord, setCoordinates }) => {
  const markerRef = useRef(null)
  const [position, setPosition] = useState([0, 0])
  useEffect(() => {
    if (coord.lat && coord.lng) {
      const position = [coord.lat, coord.lng]
      setPosition(position)
    }
  }, [coord.lat])

  useMapEvents({
    click(e) {
      setPosition(e.latlng)
      const coordinates = {
        lat: e.latlng.lat,
        lng: e.latlng.lng,
      }
      setCoordinates(coordinates)
    },
  })

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          const coordinates = {
            lat: marker.getLatLng().lat,
            lng: marker.getLatLng().lng,
          }
          setCoordinates(coordinates)
          setPosition(marker.getLatLng())
        }
      },
    }),
    [],
  )

  return position === null ? null : (
    <Marker
      position={position}
      ref={markerRef}
      eventHandlers={eventHandlers}
      draggable={true}
    >
      <Popup>Your coordinates are {position && JSON.stringify(position)}</Popup>
    </Marker>
  )
}
