/* eslint-disable no-unused-vars */

import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export const PdfFieldsMapModal = ({ setModalValue, handleClose }) => {

  const [directoryName, setDirectoryName] = useState(``)
  const [fieldName, setFieldName] = useState(``)
  const [nestedFieldName, setNestedFieldName] = useState(``)
  const [secondNestedFieldName, setSecondNestedFieldName] = useState(``)

  const inBoundValue = async () => {
    
    setFieldName(``)
    return {
      options: [
        { value: `Agent`, label: `Agent` },
        { value: `Receipt`, label: `Receipt` },
        { value: `Location`, label: `Location` },
        { value: `Policy`, label: `Policy` },
        { value: `Customer`, label: `Customer` },
        { value: `Vehicle`, label: `Vehicle` },
        { value: `Driver`, label: `Driver` },
      ],
      hasMore: false,
    }
    
  }


    let capitalizeMyString = (str) => {
   
       let resultIs = ``
       if (!str.length) return 

       let removeSymbString = str.replace(/[^a-zA-Z0-9 ]/g, ``)
       let firstLetterCap = removeSymbString[0].toUpperCase()
       resultIs = firstLetterCap +  removeSymbString.substring(1) 
       return resultIs
    }

  const loadSchemaList = async () => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/get/schema?search=${directoryName?.value}`,
    )
    const filteredItem = data?.map((_eachObject) => {
      return { value: _eachObject, label:capitalizeMyString(_eachObject)}
    })
   
    if (filteredItem) {
      return {
        options: filteredItem,
        hasMore: false,
      }
    }
  }
  const loadNestedfields = async () => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/get/schema/?search=${directoryName?.value + `:` + fieldName?.value?.charAt(0).toUpperCase() + fieldName?.value?.slice(1)}`,
    )
    setNestedFieldName(``)
    const filteredNestedItems = data?.map((_eachObject) => {
      return { value: _eachObject, label:capitalizeMyString(_eachObject) }
    })
    if (filteredNestedItems) {
      return {
        options: filteredNestedItems,
        hasMore: false,
      }
    }
  }
  const loadSecondNestedfields = async () => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/get/schema/?search=${directoryName?.value + `:` + fieldName?.value?.charAt(0).toUpperCase() + fieldName?.value?.slice(1) + `:` + nestedFieldName?.value?.charAt(0).toUpperCase() + nestedFieldName?.value?.slice(1)}`,
    )

    const filteredNestedItems = data?.map((_eachObject) => {
      return { value: _eachObject, label:capitalizeMyString(_eachObject)}
    })
    if (filteredNestedItems) {
      return {
        options: filteredNestedItems,
        hasMore: false,
      }
    }
  }

  //   getting all fieldValues
  const {
    data: fieldValueData,
    refetch: getAllFieldValues,
  } = useQuery(
    `FieldValueApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/get/schema/?search=${directoryName?.value + `:` + fieldName?.value?.charAt(0).toUpperCase() + fieldName?.value?.slice(1)}`,
      ).then((res) => {
        return res?.data?.data
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      staleTime: Infinity
    },
  )

  const {
    data: nestedFieldValueData,
    refetch: getAllNestedFieldValues,
  } = useQuery(
    `NestedFieldValueApis`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/get/schema/?search=${directoryName?.value + `:` + fieldName?.value?.charAt(0).toUpperCase() + fieldName?.value?.slice(1) + `:` + nestedFieldName?.value?.charAt(0).toUpperCase() + nestedFieldName?.value?.slice(1)}`,
      ).then((res) => {
        return res?.data?.data
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      staleTime: Infinity
    },
  )


  useEffect(() => {
    getAllFieldValues()
  }, [fieldName])

  useEffect(() => {
    getAllNestedFieldValues()
  }, [nestedFieldName])

  return (
    <>
      <div
        className={`flex justify-center  mt-10 ml-8 m-auto items-center  overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}
      >
        <div className={`relative w-5/12 my-2 mx-auto max-w-screen-2xl`}>
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-2 bg-red-500 text-white border-b border-solid border-blueGray-200 rounded-t-lg px-4">
              Map Fields
              <button
                className="bg-transparent border-0 text-black float-right text-4xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => {
                  setModalValue(`empty`)
                  handleClose()
                }}
              >
                <span className="text-white h-6 w-6 block outline-none focus:outline-none -mt-2">
                  ×
                </span>
              </button>
            </div>
            <div className="relative w-full">
              <div className="px-3 py-4">
                <div className="relative flex flex-col gap-2 p-Customer2 w-full h-full">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Select Directory"
                      placeholder="Select Directory"
                      value={directoryName}
                      setValue={(e) => setDirectoryName(e)}
                      isMulti={false}
                      loadOptions={inBoundValue}
                      cacheUniqs={directoryName?.value}
                      disabled={false}
                    />
                  </div>
                  {directoryName?.value && (
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Select Field"
                        placeholder="Select Field"
                        value={fieldName}
                        setValue={(e) => setFieldName(e)}
                        isMulti={false}
                        loadOptions={loadSchemaList}
                        cacheUniqs={directoryName?.value}
                        disabled={false}
                      />
                    </div>
                  )}
                  {
                    fieldValueData?.length > 0 &&
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Select Value"
                        placeholder="Select value"
                        value={nestedFieldName}
                        setValue={(e) => setNestedFieldName(e)}
                        isMulti={false}
                        loadOptions={loadNestedfields}
                        cacheUniqs={fieldName?.value}
                      />
                    </div>
                  }
                  {
                    nestedFieldValueData?.length > 0 &&
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Select value"
                        placeholder="Select value"
                        setValue={(e) => setSecondNestedFieldName(e)}
                        isMulti={false}
                        loadOptions={loadSecondNestedfields}
                        cacheUniqs={nestedFieldName?.value}
                      />
                    </div>
                  }

                </div>
              </div>
            </div>
            <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className={`${fieldName?.value?.length
                  ? `bg-red-500`
                  : `bg-gray-500 cursor-not-allowed`
                  } text-white active:bg-emerald-400 font-semibold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                // className={`bg-blue-600 flex gap-2 text-white active:bg-emerald-400 font-semibold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={() => {
                  setModalValue(directoryName?.value + `:` + fieldName?.value + `:` + nestedFieldName?.value + `:` + secondNestedFieldName?.value)
                  handleClose()
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
