import React, { useEffect, useState, Fragment } from 'react'
import { useMutation, useQuery } from 'react-query'
// import { Toggle } from 'App/Components/Common/Toggle'
import { useHistory, useRouteMatch } from 'react-router'
import { Spinner } from 'App/Components/Common/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  // faPlusCircle,
  faSortUp,
  faSortDown,
  faChevronCircleDown,
  faEdit,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'
import { Toggle } from 'App/Components/Common/Toggle'
import { ReactModal } from 'App/Components/Common/Modal'
import { loadCarrier } from 'App/Services/Customer/loadCarriers'

const tableColumns = [
  { label: `Name`, value: `name` },
  { label: `STATE`, value: `address.state` },
  { label: `NAIC`, value: `naic` },
  { label: `SIC`, value: `sic` },
  { label: `FEIN`, value: `fein` },
  { label: `Lobs`, value: `lobs` },
  { label: `Status`, value: `isActive` },
  { label: `Created`, value: `created` },
]

export const AllCarriers = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const isImportedCarrier = url.includes(`/imported-carrier`)
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)

  const [collapseIndex, setCollapseIndex] = useState(``)
  const [currentCarrier, setCurrentCarrier] = useState(``)
  const [parentCarrier, setParentCarrier] = useState(``)
  const [openModal, setOpenModal] = useState(``)
  const [active, setActive] = useState(false)
  const [isImported, setIsImported] = useState(false)
  // const [isMasterFrenchise, setIsMasterFrenchise] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)

  //   getting all Carriers
  const {
    isLoading,
    data: carrierData,
    refetch: getAllCarriers,
    isFetching,
    error,
  } = useQuery(
    isImportedCarrier ? `ImportedCarriersApi` : `CarriersApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/${
          isImportedCarrier ? `imported-carriers` : `carriers`
        }?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${
          sort?.length > 0 ? sort : ``
        }"&imported=${isImported}&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )
  const { isLoading: deletingCarrier, isError: deletionError } = useMutation(
    async (cId) =>
      await AxiosInstance.delete(`${AMS_BACKEND_API}/api/carriers/${cId}`).then(
        (res) => {
          NotificationManager.success(`Deleted successfully`)
          res.data && getAllCarriers()
        },
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  useEffect(() => {
    if (!customSearching) {
      getAllCarriers()
    }
  }, [sort, currentPage, isOffset, isImported])

  const callCarriersQuery = useDebouncedCallback(() => {
    getAllCarriers()
  }, 700)

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (collapseIndex !== id) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }
  const handleUpdate = (carrier, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    setCurrentCarrier(carrier)
    setOpenModal(true)
  }

  const { mutate: updateCarrier, isLoading: updateCarrierLoading } =
    useMutation(
      async (payload) =>
        await AxiosInstance.patch(
          `${AMS_BACKEND_API}/api/imported-carriers/${currentCarrier?._id}`,
          {
            ...payload,
          },
        )
          .then(() => {
            NotificationManager.success(`Operation successful`)
            setCurrentCarrier(``)
            setOpenModal(false)
            getAllCarriers()
            setParentCarrier(``)
          })
          .catch(() => {
            NotificationManager.error(`No field Changed`)
          }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
      },
    )

  return (
    <Container>
      {openModal && (
        <ReactModal
          title="Update Carrier"
          closeModal={() => setOpenModal(false)}
        >
          <div className="w-96 px-2 py-6">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Parent Carrier"
                name="parentCarrier"
                placeholder="Select Carrier"
                isMulti={false}
                value={parentCarrier}
                setValue={(e) => setParentCarrier(e)}
                loadOptions={loadCarrier}
                isSearchable={true}
              />
            </div>
            <div className="text-center mt-4 w-1/3 float-right">
              <SubmitButton
                onClick={() =>
                  updateCarrier({
                    _parentId: parentCarrier?.value,
                  })
                }
                disabled={!parentCarrier?.value || updateCarrierLoading}
              >
                {updateCarrierLoading && (
                  <FontAwesomeIcon icon={faSpinner} spin />
                )}
                Update
              </SubmitButton>
            </div>
          </div>
        </ReactModal>
      )}
      <Spinner loading={deletingCarrier || isLoading || isFetching} />
      {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <PageHeader>
        <div>Carrier</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          {!isImportedCarrier && (
            <Toggle
              value={isImported}
              setValue={(e) => {
                setCurrentPage(1)
                setIsImported(e)
              }}
              toggleValue={[`M`, `I`]}
              disabled={isLoading || isFetching}
            />
          )}
          <div className="text-end ">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callCarriersQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>
      {/* {permissionGranted([
        `carriers:create`,
        `admin-franchise`,
        `admin-master-franchise`,
      ]) && (
        <div className="flex justify-end items-center p-2">
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        </div>
      )} */}

      <div
        className={`bg-white mb-1 sm:mb-0 justify-between w-full px-2 ${
          (deletingCarrier || isLoading || isFetching) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllCarriers()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md mt-10">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item?.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {isImportedCarrier && <TableCell>Parent Carrier</TableCell>}
                    {(permissionGranted([
                      `carriers:read`,
                      `credential-master:*`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) ||
                      permissionGranted([
                        `carriers:delete`,
                        `credential-master:*`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ])) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !carrierData?.carriers?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Carrier Found</div>
                      </td>
                    </tr>
                  ) : (
                    carrierData?.carriers?.map((_carrier, index) => (
                      <Fragment key={index}>
                        <TableRow
                          key={index}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            if (
                              permissionGranted([
                                `carriers:read`,
                                `credential-master:*`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ]) &&
                              !isImportedCarrier
                            ) {
                              history.push(`${url}/${_carrier._id}`)
                            }
                          }}
                        >
                          <td className="px-4 py-0.5 capitalize text-sm">
                            {_carrier.name?.toLowerCase()}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {_carrier.address?.state ? (
                              _carrier.address?.state
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {_carrier.naic ? _carrier.naic : <Badge>N/A</Badge>}
                          </td>

                          <td className="px-4 py-0.5 w-auto text-sm">
                            {_carrier.sic ? _carrier.sic : <Badge>N/A</Badge>}
                          </td>
                          <td className="px-4 py-0.5 w-auto text-sm">
                            {_carrier.fein ? _carrier.fein : <Badge>N/A</Badge>}
                          </td>

                          <td className="px-4 py-0.5 z-0">
                            {_carrier?.lobs.length ? (
                              <div className="flex flex-row flex-wrap">
                                <Badge type="success">
                                  {_carrier?.lobs[0]}
                                </Badge>
                              </div>
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 z-0">
                            <Badge
                              type={`${
                                _carrier?.status === `INACTIVE`
                                  ? `danger`
                                  : `success`
                              }`}
                            >
                              {_carrier?.status}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 w-auto text-sm">
                            {dateFormat(_carrier.createdAt)}
                          </td>
                          <td className="px-4 py-0.5 w-auto text-sm">
                            {_carrier?.parent || <Badge>N/A</Badge>}
                          </td>
                          {/* Actions TD */}
                          <td className="px-6 py-0.5 text-sm z-0 flex float-right text-gray-400">
                            {_carrier?.lobs?.length > 1 && (
                              <span data-tip="Show" className="tooltip">
                                <FontAwesomeIcon
                                  className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                    collapseIndex === index &&
                                    `rotate-180 text-blue-600`
                                  }`}
                                  icon={faChevronCircleDown}
                                  onClick={(e) => {
                                    setActive(!active)
                                    handleCollapse(index, e)
                                  }}
                                />
                              </span>
                            )}
                            {isImportedCarrier && (
                              <span data-tip="Update" className="tooltip">
                                <FontAwesomeIcon
                                  className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                  icon={faEdit}
                                  size="1x"
                                  onClick={(e) => handleUpdate(_carrier, e)}
                                />
                              </span>
                            )}
                          </td>
                        </TableRow>
                        {collapseIndex === index && (
                          <TableRow>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="px-4 py-0.5 text-sm z-0 max-w-sm">
                              {_carrier?.lobs?.map((lob, idx) => (
                                <div className="flex flex-row flex-wrap">
                                  <Badge
                                    type="success"
                                    key={`r${idx}`}
                                    className="ml-1 mt-2"
                                  >
                                    {lob}
                                  </Badge>
                                </div>
                              ))}
                            </td>
                          </TableRow>
                        )}
                      </Fragment>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="p-4">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={carrierData?.totalPages}
                totalRecords={carrierData?.totalRecords}
                curerntPageRecords={carrierData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
