import { setVideoActive } from 'App/Redux/actions'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

export const VideoCall = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(({ user }) => user)
  const { videoActive } = useSelector(({ customer }) => customer)
  return (
    <>
      {videoActive && (
        <>
          <div className="justify-center items-center flex   fixed inset-0 z-40 outline-none  focus:outline-none">
            <div className="relative   mx-auto ">
              {/*content*/}

              <div className="border-1  rounded-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}

                {videoActive && (
                  <div>
                    <div className="px-10 py-5">
                      <p className=" rounded-sm p-5 underline">
                        <a
                          onClick={() => {
                            if (videoActive) {
                              dispatch(setVideoActive(!videoActive))
                            }
                          }}
                          target="_blank"
                          href={`${window.location.origin}/video-call?roomId=${user?.profile?.sub}`}
                          rel="noreferrer"
                        >
                          Open Chat Room
                        </a>
                        <span className="tooltip" data-tip="Copy">
                          <FontAwesomeIcon
                            icon={faCopy}
                            className=" cursor-pointer ml-2 text-gray-400  hover:text-gray-600"
                            size="lg"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                `${window.location.origin}/video-call?roomId=${user?.profile?.sub}`,
                              )
                            }
                          />
                        </span>
                      </p>
                    </div>
                  </div>
                )}

                {/*footer*/}
                <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      if (videoActive) {
                        dispatch(setVideoActive(!videoActive))
                      }
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
