import React from 'react'

export const DashboardCardField = ({ title, value, value2, isGray }) => {
  return (
    <div
      className={`grid grid-cols-12 py-1.5 justify-items-start place-content-center text-sm ${
        isGray ? `bg-gray-100` : ``
      }  `}
    >
      <div className={`col-span-7 text-gray-500 font-medium capitalize`}>
        {title}
      </div>
      {/* <div className="col-span-1">-</div> */}
      <div className={`col-span-3 flex flex-wrap font-medium my-auto`}>
        {value2 ? value2 : `Not Provided`}
      </div>
      <div className={`col-span-2 flex flex-wrap font-medium my-auto`}>
        {value ? value : `Not Provided`}
      </div>
    </div>
  )
}
