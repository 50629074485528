import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeTab: 1,
  assetId: ``,
  driverAssets: ``,
  vehicleAsset: ``,
  customerContacts: ``,
  contactActiveActivityTab: 1,
}

const sliceSlice = createSlice({
  name: `asset`,
  initialState,
  reducers: {
    setAssetActiveTab: (state, { payload }) => {
      state.activeTab = payload
    },
    setAssetId: (state, { payload }) => {
      state.assetId = payload
    },
    setDriverAsset: (state, action) => {
      state.driverAssets = action.payload
    },
    setVehicleAsset: (state, { payload }) => {
      state.vehicleAsset = payload
    },
    setCustomerContacts: (state, { payload }) => {
      state.customerContacts = payload
    },
    setContactActivityActiveTab: (state, { payload }) => {
      state.contactActiveActivityTab = payload
    },
  },
})

export const {
  setAssetActiveTab,
  setAssetId,
  setDriverAsset,
  setVehicleAsset,
  setCustomerContacts,
  setContactActivityActiveTab,
} = sliceSlice.actions

export const asset = sliceSlice.reducer
