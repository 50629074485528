import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import { InputField } from 'App/Components/Common/InputField'
import { ReactModal } from 'App/Components/Common/Modal'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useMutation } from 'react-query'

export const RenameFile = ({ open, setOpen, folderPath, refetch, oldName }) => {
  const [rename, setRename] = useState(``)

  useEffect(() => {
    if (oldName) {
      setRename(oldName.split(`.`)[0])
    }
  }, [oldName])

  const { mutate, isLoading } = useMutation(
    async () =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/cloudinary/rename`, {
        path: folderPath,
        reName: rename,
        oldName: oldName,
      }).then(({ data }) => {
        if (!data.hasError) {
          NotificationManager.success(`File Renamed Successfully`)
          setRename(``)
          setOpen(false)
          refetch()
        } else {
          NotificationManager.error(`Something went wrong`)
          setRename(``)
          setOpen(false)
          refetch()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  return open ? (
    <>
      <ReactModal
        title="Rename File"
        closeModal={() => setOpen(false)}
        disabled={rename}
        loading={isLoading}
        callMutation={mutate}
        icon={faArrowAltCircleUp}
        toolTip="Rename File"
        className="py-2 px-10"
      >
        <InputField
          type="text"
          placeholder="File Name"
          tipdata="Rename File"
          value={rename}
          setValue={(e) => {
            setRename(e.target.value)
          }}
        />
      </ReactModal>
      {/* <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-2 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">Rename</h3>
              <button
                className=" bg-transparent border-0 text-black float-right text-4xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setOpen(false)}
              >
                <span className="text-black h-6 w-6 block outline-none focus:outline-none -mt-2">
                  ×
                </span>
              </button>
            </div>
            <div className="relative flex w-full flex-wrap items-stretch py-2 px-10">
              <InputField
                type="text"
                placeholder="Folder Name"
                tipdata="Create Folder"
                value={rename}
                setValue={(e) => {
                  setRename(e.target.value)
                }}
              />
            </div>
            <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className={`${
                  rename ? `bg-red-500` : `bg-gray-500 cursor-not-allowed`
                } text-white active:bg-emerald-400 font-semibold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                type="button"
                onClick={mutate}
                disabled={rename ? false : true}
              >
                <div className="flex gap-2 text-white">
                  <span>Rename </span>
                  <FontAwesomeIcon
                    icon={
                      isLoading
                        ? faSpinner
                        : faArrowAltCircleUp
                    }
                    size="lg"
                    spin={isLoading}
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
    </>
  ) : (
    <></>
  )
}
