/* eslint-disable no-unused-vars */
import { Card } from 'App/Components/Common/Card'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import {
  setPreviousLength,
  setLatestLength,
  setNotificationIsRead,
} from 'App/Redux/actions'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useHistory, useLocation } from 'react-router-dom'
import { Reload } from 'App/Components/Common/Reload'
import InfiniteScroll from 'react-infinite-scroller'
import { NotificationManager } from 'react-notifications'
import { permissionGranted } from 'App/Services'
import { NotificationCardkelton } from './notificationCardSkelton'
export const PushNotification = () => {
  const dispatch = useDispatch()
  const { latestLength } = useSelector(({ notification }) => notification)
  const user = useSelector(({ user: { user } }) => user)

  const location = useLocation()
  const history = useHistory()
  const [currentIndex, setCurrentIndex] = useState(``)
  const [currentPage, setCurrentPage] = useState(1)

  const [systemNotificationData, setsystemNotificationData] = useState([])
  const {
    isLoading,
    data: _systemNotificationData,
    refetch: getAllSystemNotifications,
    isRefetching,
    error,
  } = useQuery(
    `SystemNotificationApi${currentPage}`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/notifications/unread/all?page=${currentPage}&offset=15&query=${`systemNotifications`}`,
      )
        .then((res) => {
          // dispatch(setPreviousLength(latestLength))
          dispatch(setLatestLength(res.data.data.unreadRecords))
          return res.data.data
        })
        .catch((err) => {
          if (err) NotificationManager.error(`Something went wrong`)
        }),
    {
      // enabled: latestLength > 0,
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setsystemNotificationData((prev) => [
          ...prev,
          ...res?.systemNotifications,
        ])
      },
    },
  )
  // useEffect(() => {
  //   if (
  //     user.accessToken &&
  //     location.pathname !== `/auth/oidc-logout-redirect`
  //   ) {
  //     getAllSystemNotifications()
  //   }
  // }, [])

  useEffect(() => {
    currentPage > 1 && getAllSystemNotifications()
  }, [currentPage])

  const loadMoreNotifications = () => {
    if (!isLoading && !isRefetching) {
      setCurrentPage(currentPage + 1)
      // getAllSystemNotifications();
    }
  }

  const { mutate: readNotification, isLoading: readingNotification } =
    useMutation(
      async (payload) => {
        if (!payload.isAlreadyRead || payload.isDeleted) {
          await AxiosInstance.patch(
            `${AMS_BACKEND_API}/api/notifications/${payload.id}`,
            {
              isRead: payload.isRead,
              isDeleted: payload.isDeleted,
            },
          ).then(() => {
            setCurrentIndex(``)
            // setsystemNotificationData((prev) =>
            //   prev.filter((notification) => notification._id !== payload.id),
            // )
            setsystemNotificationData([])
            // getAllSystemNotifications()
            // dispatch(setLatestLength(latestLength - 1))
          })
        }
      },
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: () => {
          getAllSystemNotifications()
        },
      },
    )

  // const handleClickNotification = () => {
  //   if (
  //     permissionGranted([
  //       `notifications:read`,
  //       `dashboard:read`,
  //       `admin-franchise`,
  //       `admin-master-franchise`,
  //     ]) &&
  //     notification?.receiver?._id &&
  //     !readingNotification &&
  //     !isRefetching
  //   ) {
  //     if (notification?.source === `TASK-MANAGER`)
  //       history.location.pathname !== `/taskmanager/${notification?._taskId}`
  //         ? history.push(`/taskmanager/${notification?._taskId}`)
  //         : dispatch(setNotificationIsRead(Math.random()))
  //     else if (notification?.source === `TICKET-CENTER`)
  //       history.location.pathname !==
  //         `/ticketscenter/${notification?._ticketId}`
  //         ? history.push(`/ticketscenter/${notification?._ticketId}`)
  //         : dispatch(setNotificationIsRead(Math.random()))
  //     else if (notification?.source === `PAYMENT`)
  //       history.push(`/customer/${notification?._customerId}`)
  //     else if (notification?.source === `ASSESSMENT`)
  //       history.location.pathname !== `/assessment/weeks`
  //         ? history.push(`/assessment/weeks`)
  //         : dispatch(setNotificationIsRead(Math.random()))
  //     else if (notification?.source === `CAMPAIGN`)
  //       history.location.pathname !==
  //         `/broadcasts/campaigns/groupsdetail/${notification?._broadcastId}`
  //         ? history.push(
  //           `/broadcasts/campaigns/groupsdetail/${notification?._broadcastId}`,
  //         )
  //         : dispatch(setNotificationIsRead(Math.random()))
  //   }
  //   readNotification({
  //     id: notification._id,
  //     isRead: true,
  //   })
  // }

  return (
    <div className="w-full">
      {/* <div className="px-1.5 py-0.5 bg-red-500 rounded-t-md text-white font-medium text-sm">
        System Notifications
      </div> */}
      {error ? (
        <Reload refetch={() => getAllSystemNotifications()} />
      ) : (
        <>
          <div className="w-full py-1 gap-1 px-1 h-72  overflow-y-scroll hide-scroller hide-scroller-firefox ">
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMoreNotifications}
              hasMore={
                systemNotificationData?.length > 0 &&
                systemNotificationData?.length <
                  _systemNotificationData?.totalRecords
              }
              threshold={192}
              loader={<NotificationCardkelton />}
              useWindow={false}
            >
              {/* {isLoading && <NotificationCardkelton />} */}

              {systemNotificationData?.length < 1 ? (
                isLoading || isRefetching ? (
                  <NotificationCardkelton />
                ) : (
                  <div className="text-center text-xs font-md text-gray-500 my-2">
                    No System Notifications Available.
                  </div>
                )
              ) : (
                systemNotificationData?.map((notification, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (
                        permissionGranted([
                          `notifications:read`,
                          `dashboard:read`,
                          `corporate-manager:*`,
                          `manager:*`,
                          `admin:*`,
                        ]) &&
                        notification?.receiver?._id &&
                        !readingNotification &&
                        !isRefetching
                      ) {
                        if (notification?.source === `TASK-MANAGER`)
                          history.location.pathname !==
                            `/taskmanager/${notification?._taskId}` &&
                            history.push(
                              `/taskmanager/${notification?._taskId}`,
                            )
                        // : dispatch(setNotificationIsRead(Math.random()))
                        else if (notification?.source === `TICKET-CENTER`)
                          history.location.pathname !==
                            `/ticketscenter/${notification?._ticketId}` &&
                            history.push(
                              `/ticketscenter/${notification?._ticketId}`,
                            )
                        // : dispatch(setNotificationIsRead(Math.random()))
                        else if (notification?.source === `GOALS`)
                          history.location.pathname !==
                            `/admin/monthlygoals/${notification?._goal}` &&
                            history.push(
                              `/admin/monthlygoals/${notification?._goal}`,
                            )
                        // : dispatch(setNotificationIsRead(Math.random()))
                        else if (notification?.source === `PAYMENT`)
                          history.push(`/customer/${notification?._customerId}`)
                        else if (notification?.source === `ASSESSMENT`)
                          history.location.pathname !== `/assessment/weeks` &&
                            history.push(`/assessment/weeks`)
                        // : dispatch(setNotificationIsRead(Math.random()))
                        else if (notification?.source === `CAMPAIGNS`)
                          history.location.pathname !==
                            `/broadcasts/campaigns/groupsdetail/${notification?._broadcastId}` &&
                            history.push(
                              `/broadcasts/campaigns/groupsdetail/${notification?._broadcastId}`,
                            )
                        // : dispatch(setNotificationIsRead(Math.random()))
                      }
                      readNotification({
                        id: notification._id,
                        isRead: true,
                        isAlreadyRead: notification.isRead,
                      })
                      // setLatestLength(latestLength - 1)
                    }}
                    className={` ${
                      !readingNotification && !isRefetching
                        ? `cursor-pointer`
                        : `cursor-not-allowed`
                    }`}
                  >
                    <Card
                      source={notification.source}
                      title={` ${notification?.receiver?.agentName}`}
                      message={
                        notification.description || notification?.resourceType
                      }
                      curruentLocation={
                        notification?.location ? notification?.location : ``
                      }
                      createdAt={notification.createdAt}
                      deleteCard={() => {
                        readNotification({
                          id: notification._id,
                          isDeleted: true,
                          isRead: true,
                          isAlreadyRead: notification.isRead,
                        })
                      }}
                      index={index}
                      isDeleting={readingNotification || isRefetching}
                      currentIndex={currentIndex}
                      setCurrentIndex={setCurrentIndex}
                      readCard={!notification?.isRead}
                    />
                  </div>
                ))
              )}
            </InfiniteScroll>
          </div>
        </>
      )}
    </div>
  )
}
