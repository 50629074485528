/* eslint-disable no-unused-vars */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import { AQS_BACKEND_API, AxiosInstance } from 'App/Config'

import {
  loadDriverType,
  loadLossType,
  loadLossPayeeName,
  loadMakes,
  loadMakeYears,
  loadModel,
  loadBinaryOptions,
  loadUsage,
  loadPassiveRestraint,
  loadAntiLockBrakes,
  loadAirBags,
  loadFuelType,
  loadTruckSize,
  loadVehicleType,
  loadAntiTheft,
  loadVehicleCondition,
  loadColors,
} from 'App/Services/quoteForm'
import cloneDeep from 'clone-deep'
import { loadZipCodes, ValidateZip } from 'App/Services'
import { FormTab } from 'App/Styled'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AddFormButton, RemoveDriver } from '../style'
import moment from 'moment'
import { InputFieldRef } from 'App/Components/Common/InputFieldRef'
import { AddressValidate } from 'App/Components/Common/AddressValidateInput'

import { useMutation } from 'react-query'
import { ReactModal } from 'App/Components/Common/Modal'
import { MaskField } from 'App/Components/Common/MaskField'
import { NotificationManager } from 'react-notifications'
import { setVehicleData } from 'App/Redux/actions'

export const VehicleForm = forwardRef(
  ({ getData, selectedState, carrierQuestion, reRated }, ref) => {
    const { drivers, firstDriverInfo, vehicleCoverage } = useSelector(
      ({ quoteForm }) => quoteForm,
    )
    const dispatch = useDispatch()
    const { selectedRatingTemplate } = useSelector(
      ({ ratingTemplate }) => ratingTemplate,
    )
    const { leadData } = useSelector(({ lead }) => lead)
    const [garageAddressValidate, setGarageAddressValidate] = useState(false)
    const [activeVehicleTab, setActiveVehicleTab] = useState(1)
    const [lossPayeeAddressValidate, setLossPayeeAddressValidate] =
      useState(false)
    const getSelectedVehicleScope = () => {
      if (selectedRatingTemplate) {
        return selectedRatingTemplate?.template?.vehicleCoverages.map(
          (vehicle) => ({
            code: vehicle?.code,
            _deductibleOptions: vehicle?.deductibleOptions.length
              ? vehicle?.deductibleOptions.map((deductible) => ({
                  value: deductible.key,
                  label: deductible.name,
                }))
              : [],
            deductibleOption: () => ({
              options: vehicle?.deductibleOptions.length
                ? vehicle?.deductibleOptions.map((deductible) => ({
                    value: deductible.key,
                    label: deductible.name,
                  }))
                : [],
              hasMore: false,
            }),
            deductible: vehicle?.deductible
              ? {
                  value: vehicle?.deductible,
                  label: vehicle?.deductible,
                }
              : `Disabled`,
            deductibleError: ``,
            emptyDeductibles: vehicle?.deductibleOptions?.length ? false : true,
            free: vehicle.free,
            name: vehicle.name,
            required: vehicle.required,
            limit: vehicle?.limits
              ? { value: vehicle?.limits, label: vehicle?.limits }
              : `Disabled`,
            limitError: ``,
            _limitOptions: vehicle?.limitsOptions?.length
              ? vehicle?.limitsOptions.map((limit) => ({
                  value: limit.name,
                  label: limit.name,
                }))
              : [],
            limitsOptions: () => ({
              options: vehicle?.limitsOptions?.length
                ? vehicle?.limitsOptions.map((limit) => ({
                    value: limit.name,
                    label: limit.name,
                  }))
                : [],
              hasMore: false,
            }),
            emptyLimits: vehicle?.limitsOptions?.length ? false : true,
          }),
        )
      } else {
        return null
      }
    }

    const loadPrimary = () => {
      return {
        options: drivers.filter((element) => {
          return element !== undefined || null
        }),
        hasMore: false,
      }
    }
    const isVinCalledFromModel = useRef(null)
    const isYearCalledFromVin = useRef(null)
    const [gotVinNumbers, setGotVinNumbers] = useState(false)

    const licensePlateNoRef = useRef([])
    const alternateGarageStreetRef = useRef([])
    const milesDrivenToWorkRef = useRef([])
    const annualMilesDrivenRef = useRef([])
    const lostPayeeStreetRef = useRef([])

    const [rerateQuoteData, setRerateQuoteData] = useState(null)
    const [rerateQuote, setRerateQuote] = useState(false)

    const [vehicles, setVehicles] = useState([
      {
        carType: { value: `Rated`, label: `Rated` },
        modelYear: ``,
        make: ``,
        model: ``,
        vin: ``,
        licensePlateNoError: ``,
        alternateGarage: { value: `NO`, label: `NO` },
        zip: ``,
        city: ``,
        state: ``,
        street: ``,
        alternateGarageAddress: {
          zip: ``,
          city: ``,
          state: ``,
        },
        comp: ``,
        coll: ``,
        tl: ``,
        rreim: ``,
        av: ``,
        usage: { value: `WORK/SCHOOL`, label: `Work/School` },
        colors: ``,
        rideShare: { value: `NO`, label: `NO` },
        primaryOperator: { value: 0, label: `Driver 1` },
        primaryOperatorError: ``,
        monitoringDevice: { value: `NO`, label: `NO` },
        milesDrivenToWorkError: ``,
        annualMilesDrivenError: ``,
        odometerError: ``,
        msrpError: ``,
        msrp: ``,
        odometer: `0`,
        purchaseCost: `0`,
        purchaseCostError: ``,
        acvError: ``,
        acv: `0`,
        numberOfCylindersError: ``,
        numberOfCylinders: `4`,
        purchaseDate: ``,
        newUsed: { value: `USED`, label: `Used` },
        leasedVehicle: { value: `NO`, label: `NO` },
        salvaged: { value: `NO`, label: `NO` },
        antiTheft: { value: `NO ANTI-THEFT`, label: `No Anti-Theft` },
        greyMarket: { value: `NO`, label: `NO` },
        vehicleType: { value: `CAR`, label: `Car` },
        truckSize: { value: `1/4 TON`, label: `Quater Ton` },
        dualie: { value: `NO`, label: `NO` },
        turboCharged: { value: `NO`, label: `NO` },
        fuelType: { value: `GAS`, label: `Gas` },
        fourWheelDrive: { value: `NO`, label: `NO` },
        fourWheelSteering: { value: `NO`, label: `NO` },
        airBags: { value: `NO AIR BAG`, label: `No Air Bags` },
        lossPayeeTypeName: ``,
        lossPayeeTypeAddress: {
          city: ``,
          state: ``,
          zip: ``,
        },
        lossPayeeType: { value: `NONE`, label: `NONE` },
        passiveRestraint: {
          value: `NO PASSIVE RESTRAINT`,
          label: `No Passive Restraint`,
        },
        runningLights: { value: `NO`, label: `NO` },
        antiLockBrakes: { value: `NO ANTI-LOCK`, label: `No Anti-Lock` },
        hoodLockingDevice: { value: `NO`, label: `NO` },
        vinEtching: { value: `NO`, label: `NO` },
        homingDevice: { value: `NO`, label: `NO` },
        makeError: ``,
        modelError: ``,
        vinError: ``,
        zipError: ``,
        percentDrivenToWork: `50`,
        alternateGarageError: ``,
        streetErrorLossPayee: ``,
        purchaseDateError: ``,
        vehicleScope: getSelectedVehicleScope()
          ? getSelectedVehicleScope()
          : cloneDeep(vehicleCoverage),
        carrierQuestion: carrierQuestion,
      },
    ])

    let addNewVehicle = {
      carType: { value: `Rated`, label: `Rated` },
      modelYear: ``,
      make: ``,
      model: ``,
      vin: ``,
      licensePlateNoError: ``,
      alternateGarage: { value: `NO`, label: `NO` },
      zip: ``,
      city: ``,
      state: ``,
      street: ``,
      alternateGarageAddress: {
        zip: ``,
        city: ``,
        state: ``,
      },
      comp: ``,
      coll: ``,
      tl: ``,
      rreim: ``,
      av: ``,
      usage: { value: `WORK/SCHOOL`, label: `Work/School` },
      colors: ``,
      rideShare: { value: `NO`, label: `NO` },
      primaryOperator: { value: 0, label: `Driver 1` },
      primaryOperatorError: ``,
      monitoringDevice: { value: `NO`, label: `NO` },
      milesDrivenToWorkError: ``,
      annualMilesDrivenError: ``,
      odometerError: ``,
      msrpError: ``,
      msrp: ``,
      odometer: `0`,
      purchaseCost: `0`,
      purchaseCostError: ``,
      acvError: ``,
      acv: `0`,
      numberOfCylindersError: ``,
      numberOfCylinders: `4`,
      purchaseDate: ``,
      newUsed: { value: `USED`, label: `Used` },
      leasedVehicle: { value: `NO`, label: `NO` },
      salvaged: { value: `NO`, label: `NO` },
      antiTheft: { value: `NO ANTI-THEFT`, label: `No Anti-Theft` },
      greyMarket: { value: `NO`, label: `NO` },
      vehicleType: { value: `CAR`, label: `Car` },
      truckSize: { value: `1/4 TON`, label: `Quater Ton` },
      dualie: { value: `NO`, label: `NO` },
      turboCharged: { value: `NO`, label: `NO` },
      fuelType: { value: `GAS`, label: `Gas` },
      fourWheelDrive: { value: `NO`, label: `NO` },
      fourWheelSteering: { value: `NO`, label: `NO` },
      airBags: { value: `NO AIR BAG`, label: `No Air Bags` },
      lossPayeeTypeName: ``,
      lossPayeeTypeAddress: {
        city: ``,
        state: ``,
        zip: ``,
      },
      lossPayeeType: { value: `NONE`, label: `NONE` },
      passiveRestraint: {
        value: `NO PASSIVE RESTRAINT`,
        label: `No Passive Restraint`,
      },
      runningLights: { value: `NO`, label: `NO` },
      antiLockBrakes: { value: `NO ANTI-LOCK`, label: `No Anti-Lock` },
      hoodLockingDevice: { value: `NO`, label: `NO` },
      vinEtching: { value: `NO`, label: `NO` },
      homingDevice: { value: `NO`, label: `NO` },
      makeError: ``,
      modelError: ``,
      vinError: ``,
      zipError: ``,
      percentDrivenToWork: `50`,
      alternateGarageError: ``,
      streetErrorLossPayee: ``,
      purchaseDateError: ``,
      vehicleScope: getSelectedVehicleScope()
        ? getSelectedVehicleScope()
        : cloneDeep(vehicleCoverage),
      carrierQuestion: carrierQuestion,
    }
    let updateVehicle = {
      carType: { value: `Rated`, label: `Rated` },
      modelYear: ``,
      make: ``,
      model: ``,
      vin: ``,
      licensePlateNoError: ``,
      alternateGarage: { value: `NO`, label: `NO` },
      zip: ``,
      city: ``,
      state: ``,
      street: ``,
      alternateGarageAddress: {
        zip: ``,
        city: ``,
        state: ``,
      },
      comp: ``,
      coll: ``,
      tl: ``,
      rreim: ``,
      av: ``,
      usage: { value: `WORK/SCHOOL`, label: `Work/School` },
      colors: ``,
      rideShare: { value: `NO`, label: `NO` },
      primaryOperator: { value: 0, label: `Driver 1` },
      primaryOperatorError: ``,
      monitoringDevice: { value: `NO`, label: `NO` },
      milesDrivenToWorkError: ``,
      annualMilesDrivenError: ``,
      odometerError: ``,
      msrpError: ``,
      msrp: ``,
      odometer: `0`,
      purchaseCost: `0`,
      purchaseCostError: ``,
      acvError: ``,
      acv: `0`,
      numberOfCylindersError: ``,
      numberOfCylinders: `4`,
      purchaseDate: ``,
      newUsed: { value: `USED`, label: `Used` },
      leasedVehicle: { value: `NO`, label: `NO` },
      salvaged: { value: `NO`, label: `NO` },
      antiTheft: { value: `NO ANTI-THEFT`, label: `No Anti-Theft` },
      greyMarket: { value: `NO`, label: `NO` },
      vehicleType: { value: `CAR`, label: `Car` },
      truckSize: { value: `1/4 TON`, label: `Quater Ton` },
      dualie: { value: `NO`, label: `NO` },
      turboCharged: { value: `NO`, label: `NO` },
      fuelType: { value: `GAS`, label: `Gas` },
      fourWheelDrive: { value: `NO`, label: `NO` },
      fourWheelSteering: { value: `NO`, label: `NO` },
      airBags: { value: `NO AIR BAG`, label: `No Air Bags` },
      lossPayeeTypeName: ``,
      lossPayeeTypeAddress: {
        city: ``,
        state: ``,
        zip: ``,
      },
      lossPayeeType: { value: `NONE`, label: `NONE` },
      passiveRestraint: {
        value: `NO PASSIVE RESTRAINT`,
        label: `No Passive Restraint`,
      },
      runningLights: { value: `NO`, label: `NO` },
      antiLockBrakes: { value: `NO ANTI-LOCK`, label: `No Anti-Lock` },
      hoodLockingDevice: { value: `NO`, label: `NO` },
      vinEtching: { value: `NO`, label: `NO` },
      homingDevice: { value: `NO`, label: `NO` },
      makeError: ``,
      modelError: ``,
      vinError: ``,
      zipError: ``,
      percentDrivenToWork: `50`,
      alternateGarageError: ``,
      streetErrorLossPayee: ``,
      purchaseDateError: ``,
      vehicleScope: [],
      carrierQuestion: carrierQuestion,
    }

    useEffect(() => {
      if (selectedRatingTemplate && !reRated) {
        const newVehicle = vehicles.map((vehicle) => {
          vehicle.vehicleScope = getSelectedVehicleScope()
            ? getSelectedVehicleScope()
            : cloneDeep(vehicleCoverage)
          return vehicle
        })
        setVehicles(newVehicle)
      }
    }, [selectedRatingTemplate])

    useEffect(() => {
      setCarrierQuestionDefaultValues()
    }, [carrierQuestion])

    // Set Default values for Carrier Questions
    const setCarrierQuestionDefaultValues = () => {
      const newVehicle = vehicles.map((_vehicle) => {
        const newCarrierQuestions = {}
        const vehicle = cloneDeep(_vehicle)
        for (let cQuestion in carrierQuestion) {
          newCarrierQuestions[cQuestion] = carrierQuestion[cQuestion].map(
            (item) => ({
              question: item.question,
              FieldName: item.name,
              options: item.options,
              selectedValue: { value: item.default, label: item.default },
              mask: item.mask ?? ``,
              default: item.default ?? ``,
              scope: item.scope,
            }),
          )
        }
        vehicle.carrierQuestion = newCarrierQuestions
        return vehicle
      })
      setVehicles(newVehicle)
    }

    useEffect(() => {
      annualMilesDrivenRef?.current.forEach((node) => {
        if (node && node.value === ``) node.value = 10000
      })
      milesDrivenToWorkRef?.current.forEach((node) => {
        if (node && node.value === ``) node.value = 15
      })
      setCarrierQuestionDefaultValues()
    }, [vehicles.length])

    // **********************************
    //  set Selected VAlue for carrier questions
    // **********************************
    // useEffect(() => {
    //   const newVehicle = vehicles.map((_vehicle) => {
    //     const newCarrierQuestions = {}
    //     const vehicle = cloneDeep(_vehicle)

    //     vehicle.carrierQuestion = newCarrierQuestions
    //     return vehicle
    //   })
    //   setVehicles(newVehicle)
    // }, [vehicles.length])

    useEffect(() => {
      if (firstDriverInfo?.address?.zip?.value) {
        const newVehicle = vehicles.map((_vehicle) => {
          const vehicle = cloneDeep(_vehicle)
          const newCarrierQuestions = {}
          vehicle.zip = firstDriverInfo?.address?.zip ?? ``
          vehicle.state = firstDriverInfo?.address?.state ?? ``
          vehicle.city = firstDriverInfo?.address?.city ?? ``
          vehicle.street = firstDriverInfo?.address?.city ?? ``
          for (let cQuestion in vehicle?.carrierQuestion) {
            newCarrierQuestions[cQuestion] = vehicle?.carrierQuestion[
              cQuestion
            ].map((item, index) => {
              return {
                question: item.question,
                FieldName: item.name,
                options: item.options,
                selectedValue: item?.selectedValue
                  ? item?.selectedValue
                  : { value: item.default, label: item.default },
                mask: item.mask ?? ``,
                default: item.default ?? ``,
                scope: item.scope,
              }
            })
          }
          vehicle.carrierQuestion = newCarrierQuestions
          return vehicle
        })
        setVehicles(newVehicle)
      }
    }, [firstDriverInfo?.address?.zip?.value, vehicles?.length])
    const [vTabs, setVTabs] = useState([
      {
        tName: `Vehicle 1`,
        tNumber: 1,
        permission: [`quotes:create`],
      },
    ])

    const removeReferenceFields = (ind) => {
      // Remove Instance of Ref

      licensePlateNoRef.current.forEach((item, index) => {
        if (index >= ind && licensePlateNoRef.current[index + 1]) {
          licensePlateNoRef.current[index].value =
            licensePlateNoRef.current[index + 1]?.value
        }
      })
      alternateGarageStreetRef.current.forEach((item, index) => {
        if (index >= ind && alternateGarageStreetRef.current[index + 1]) {
          alternateGarageStreetRef.current[index].value =
            alternateGarageStreetRef.current[index + 1]?.value
        }
      })

      milesDrivenToWorkRef.current.forEach((item, index) => {
        if (index >= ind && milesDrivenToWorkRef.current[index + 1]) {
          milesDrivenToWorkRef.current[index].value =
            milesDrivenToWorkRef.current[index + 1]?.value
        }
      })
      annualMilesDrivenRef.current.forEach((item, index) => {
        if (index >= ind && annualMilesDrivenRef.current[index + 1]) {
          annualMilesDrivenRef.current[index].value =
            annualMilesDrivenRef.current[index + 1]?.value
        }
      })

      lostPayeeStreetRef.current.forEach((item, index) => {
        if (index >= ind && lostPayeeStreetRef?.current[index + 1]) {
          lostPayeeStreetRef.current[index].value =
            lostPayeeStreetRef?.current[index + 1]?.value
        }
      })
    }

    const handleRemoveVehicle = (ind) => {
      removeReferenceFields(ind)

      const newVehicle = [...vehicles]
      if (newVehicle.length != 1) {
        newVehicle.splice(ind, 1)
        setVehicles(newVehicle)
      }
      const newVehicleTab = [...vTabs]
      if (newVehicleTab.length !== 1) {
        newVehicleTab.pop()
        setVTabs(newVehicleTab)
      }
    }

    const handleQuestionChange = (value, index, carrier, q, vindex) => {
      const newVehicle = vehicles.map((vehicle, ind) => {
        if (vindex === ind) {
          const newCarrierQuestions = {}
          for (let cQuestion in vehicle?.carrierQuestion) {
            newCarrierQuestions[cQuestion] = vehicle?.carrierQuestion[
              cQuestion
            ].map((item, qind) => {
              if (index === qind && carrier === cQuestion) {
                return {
                  ...item,
                  selectedValue: q === item.question && value,
                }
              } else return item
            })
          }
          vehicle.carrierQuestion = newCarrierQuestions
        }

        return vehicle
      })
      setVehicles(newVehicle)
    }

    const handleVehicleChange = useDebouncedCallback(
      (name, value, ind, vindex) => {
        const newVehicle = vehicles?.map((_vehicle, index) => {
          const vehicle = cloneDeep(_vehicle)
          if (index === ind) {
            if (vindex !== undefined) {
              vehicle?.vehicleScope.map((item, vidx) => {
                if (vindex === vidx) {
                  item[name] = value
                }
                return item
              })
            } else {
              if (name === `modelYear`) {
                vehicle.model = ``
                vehicle.make = ``
              }
              vehicle[name] = value
            }
          }
          return vehicle
        })
        setVehicles(newVehicle)
      },
      10,
    )

    const handleVinNumber = useDebouncedCallback((name, result, ind) => {
      const newVehicle = vehicles?.map((_vehicle, index) => {
        const vehicle = cloneDeep(_vehicle)
        if (index === ind) {
          vehicle.vin = result?.vin ?? ``
          vehicle.msrp = result?.msrp ?? ``
          vehicle.numberOfCylinders = result?.cylinders ?? ``
          vehicle.modelYear = { value: result.year, label: result.year }
          vehicle.model = { value: result.model, label: result.model }
          vehicle.make = { value: result.make, label: result.make }
          vehicle.fuelType = result.fuelType
            ? {
                value: result.fuelType,
                label: result.fuelType,
              }
            : ``
          vehicle.turboCharged = result.turbo
            ? { value: result.turbo, label: result.turbo }
            : ``
          vehicle.passiveRestraint = result.passiveRestraint
            ? {
                value: result.passiveRestraint,
                label: result.passiveRestraint,
              }
            : ``
          vehicle.truckSize = result.truckSize
            ? {
                value: result.truckSize,
                label: result.truckSize,
              }
            : ``
          vehicle.antiLockBrakes = result.antiLock
            ? {
                value: result.antiLock,
                label: result.antiLock,
              }
            : ``
          vehicle.vehicleType = result.vehicleType
            ? {
                value: result.vehicleType,
                label: result.vehicleType,
              }
            : ``
          vehicle.fourWheelDrive = result[`4wd`]
            ? {
                value: result[`4wd`],
                label: result[`4wd`],
              }
            : ``
        }
        return vehicle
      })
      setVehicles(newVehicle)

      if (result.length === 17) {
        getVehicleDetailsQuery(result, ind)
      }
    }, 10)

    const getVehicleDetailsQuery = useDebouncedCallback((value, ind) => {
      if (!isVinCalledFromModel.current) {
        getVehicleDetails({ value: value, ind: ind })
      }
    }, 500)

    const { mutate: getVehicleDetails, isLoading: vinLoading } = useMutation(
      async (payload) =>
        await AxiosInstance.post(`${AQS_BACKEND_API}/analyze/car/vin`, {
          vin: payload.value,
        })
          .then((res) => {
            isYearCalledFromVin.current = true
            const result = res.data.data.result
            const newVehicle = vehicles?.map((_vehicle, index) => {
              const vehicle = cloneDeep(_vehicle)
              if (index === payload.ind) {
                vehicle.vin = payload.value ?? ``
                vehicle.msrp = result?.msrp ?? ``
                vehicle.numberOfCylinders = result?.cylinders ?? ``
                vehicle.modelYear = { value: result.year, label: result.year }
                vehicle.model = { value: result.model, label: result.model }
                vehicle.make = { value: result.make, label: result.make }
                vehicle.fuelType = result.fuelType
                  ? {
                      value: result.fuelType,
                      label: result.fuelType,
                    }
                  : ``
                vehicle.turboCharged = result.turbo
                  ? { value: result.turbo, label: result.turbo }
                  : { value: `NO`, label: `NO` }
                vehicle.passiveRestraint = result.passiveRestraint
                  ? {
                      value: result.passiveRestraint,
                      label: result.passiveRestraint,
                    }
                  : ``
                vehicle.truckSize = result.truckSize
                  ? {
                      value: result.truckSize,
                      label: result.truckSize,
                    }
                  : ``
                vehicle.antiLockBrakes = result.antiLock
                  ? {
                      value: result.antiLock,
                      label: result.antiLock,
                    }
                  : ``
                vehicle.vehicleType = result.vehicleType
                  ? {
                      value: result.vehicleType,
                      label: result.vehicleType,
                    }
                  : ``
                vehicle.fourWheelDrive = result[`4wd`]
                  ? {
                      value: result[`4wd`],
                      label: result[`4wd`],
                    }
                  : {
                      value: `NO`,
                      label: `NO`,
                    }
              }
              return vehicle
            })
            setVehicles(newVehicle)
            // callVehiclesVinNumberQuery(vehicles[0].model.value, 0)
          })
          .catch((error) => {
            // NotificationManager.error(error.response.data.error.error)
            NotificationManager.error(`Enter valid VIN Number`)
          }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
      },
    )

    const handleCarModel = (name, value, ind) => {
      const newVehicle = vehicles?.map((_vehicle, index) => {
        const vehicle = cloneDeep(_vehicle)
        if (index === ind) {
          vehicle[name] = value
        }

        if (
          vehicles[ind].modelYear?.value &&
          vehicles[ind].make?.label &&
          value.value
        ) {
          callVehiclesVinNumberQuery(value, ind)
        } else if (
          vehicle.vin?.length === 17 &&
          !isVinCalledFromModel.current
        ) {
          getVehicleDetails({ value: value, ind: ind })
        }

        return vehicle
      })

      setVehicles(newVehicle)
    }

    const callVehiclesVinNumberQuery = useDebouncedCallback((model, ind) => {
      if (!isYearCalledFromVin.current) {
        getVehicleVinNumvers({ value: model.value, ind: ind })
      }
    }, 50)

    const {
      mutate: getVehicleVinNumvers,
      data: vinNumbersData,
      isLoading: gotVinLoading,
    } = useMutation(
      async (payload) =>
        await AxiosInstance.post(`${AQS_BACKEND_API}/analyze/car/ymm`, {
          year: `` + vehicles[payload.ind]?.modelYear?.value,
          make: vehicles[payload.ind]?.make?.label,
          model: vehicles[payload.ind]?.model?.label,
        })
          .then((res) => {
            isVinCalledFromModel.current = true
            if (res.data.data.result.length) {
              setGotVinNumbers(payload.ind)
            }
            return res.data.data.result
          })
          .catch((error) => {
            NotificationManager.error(error?.response?.data?.message)
          }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
      },
    )

    const addVehicle = () => {
      if (vehicles.length < 6) {
        setVTabs([
          ...vTabs,
          {
            tName: `Vehicle ${vTabs.length + 1}`,
            tNumber: vTabs.length + 1,
            permission: [`quotes:create`],
          },
        ])

        setVehicles([...vehicles, addNewVehicle])
      }
    }

    const [errors, setErrors] = useState({
      formSubmit: false,
    })
    const handleVehicleErrors = (ind, name, val) => {
      // @param prev to get state to mpulate it
      const newVehicle = (prev) =>
        prev?.map((_vehicle, index) => {
          const vehicle = cloneDeep(_vehicle)
          if (index == ind) {
            vehicle[name] = val
          }
          return vehicle
        })
      setVehicles(newVehicle)
    }

    const handleErrors = (name, val) => {
      setErrors((preSTate) => ({
        ...preSTate,
        [name]: val,
      }))
    }

    useEffect(() => {
      if (rerateQuote) {
        // Setting alternate garage street address
        if (alternateGarageStreetRef.current) {
          rerateQuoteData?.map((el, index) => {
            if (alternateGarageStreetRef.current[index]) {
              alternateGarageStreetRef.current[index].value =
                el?.garage?.location?.street
            }
          })
        }

        if (lostPayeeStreetRef.current) {
          rerateQuoteData?.map((el, index) => {
            if (lostPayeeStreetRef.current[index]) {
              lostPayeeStreetRef.current[index].value =
                el?.lienholder?.location?.street
            }
          })
        }
      }
    }, [alternateGarageStreetRef?.current, rerateQuoteData])

    useImperativeHandle(ref, () => ({
      validateForm() {
        for (const index in vehicles) {
          const vehicle = vehicles[index]
          if (!vehicle?.vin?.length) {
            handleVehicleErrors(index, `vinError`, `Enter VIN`)
          } else {
            handleVehicleErrors(index, `vinError`, ``)
          }
          if (!vehicle?.zip?.value?.length) {
            handleVehicleErrors(index, `zipError`, `Enter Zip`)
          } else {
            handleVehicleErrors(index, `zipError`, ``)
          }
          if (!vehicle?.make?.value?.length) {
            handleVehicleErrors(index, `makeError`, `Select Make`)
          } else {
            handleVehicleErrors(index, `makeError`, ``)
          }
          if (!vehicle?.model?.value?.length) {
            handleVehicleErrors(index, `modelError`, `Select Model`)
          } else {
            handleVehicleErrors(index, `modelError`, ``)
          }

          if (!vehicle?.modelYear?.value?.toString()?.length) {
            handleVehicleErrors(index, `modelYearError`, `Select Model Year`)
          } else {
            handleVehicleErrors(index, `modelYearError`, ``)
          }
          if (!vehicle?.alternateGarage?.value?.length) {
            handleVehicleErrors(
              index,
              `alternateGarageError`,
              `Select Alternate Garage`,
            )
          } else {
            handleVehicleErrors(index, `alternateGarageError`, ``)
          }
          if (!vehicle?.purchaseDate) {
            handleVehicleErrors(index, `purchaseDateError`, `Select Date`)
          } else {
            handleVehicleErrors(index, `purchaseDateError`, ``)
          }

          if (!vehicle?.primaryOperator?.value?.toString()?.length) {
            handleVehicleErrors(
              index,
              `primaryOperatorError`,
              `Select Operator`,
            )
          } else {
            handleVehicleErrors(index, `primaryOperatorError`, ``)
          }

          if (vehicle?.acv?.length) {
            if (parseInt(vehicle?.acv) < 0) {
              handleVehicleErrors(index, `acvError`, `Should be More than 1`)
            } else {
              handleVehicleErrors(index, `acvError`, ``)
            }
          } else {
            handleVehicleErrors(index, `acvError`, ``)
          }
          if (vehicle?.numberOfCylinders?.length) {
            if (parseInt(vehicle?.numberOfCylinders) < 0) {
              handleVehicleErrors(
                index,
                `numberOfCylindersError`,
                `Should be More than 1`,
              )
            } else {
              handleVehicleErrors(index, `numberOfCylindersError`, ``)
            }
          } else {
            handleVehicleErrors(index, `numberOfCylindersError`, ``)
          }
          if (vehicle?.odometer?.length) {
            if (parseInt(vehicle?.odometer) < 0) {
              handleVehicleErrors(
                index,
                `odometerError`,
                `Should be More than 1`,
              )
            } else {
              handleVehicleErrors(index, `odometerError`, ``)
            }
          } else {
            handleVehicleErrors(index, `odometerError`, ``)
          }
          if (!vehicle?.msrp?.toString()?.length) {
            handleVehicleErrors(index, `msrpError`, `Enter Value`)
          } else if (parseInt(vehicle?.msrp) < 1) {
            handleVehicleErrors(index, `msrpError`, `Should be More than 0`)
          } else {
            handleVehicleErrors(index, `msrpError`, ``)
          }

          if (vehicle?.purchaseCost?.length) {
            if (parseInt(vehicle?.purchaseCost) < 0) {
              handleVehicleErrors(
                index,
                `purchaseCostError`,
                `Should be More than 1`,
              )
            } else {
              handleVehicleErrors(index, `purchaseCostError`, ``)
            }
          } else {
            handleVehicleErrors(index, `purchaseCostError`, ``)
          }

          if (licensePlateNoRef?.current[index]?.value?.length) {
            if (licensePlateNoRef?.current[index]?.value?.length > 8) {
              handleVehicleErrors(
                index,
                `licensePlateNoError`,
                `Should be less than 8 Digits`,
              )
            } else if (licensePlateNoRef?.current[index]?.value.length < 0) {
              handleVehicleErrors(
                index,
                `licensePlateNoError`,
                `Should be More than 1`,
              )
            } else {
              handleVehicleErrors(index, `licensePlateNoError`, ``)
            }
          }

          if (milesDrivenToWorkRef?.current[index]?.value) {
            if (milesDrivenToWorkRef?.current[index]?.value > 999) {
              handleVehicleErrors(
                index,
                `milesDrivenToWorkError`,
                `Should be less than 1000`,
              )
            } else if (milesDrivenToWorkRef?.current[index]?.value < 0) {
              handleVehicleErrors(
                index,
                `milesDrivenToWorkError`,
                `Should be More than 1`,
              )
            } else {
              handleVehicleErrors(index, `milesDrivenToWorkError`, ``)
            }
          }

          if (
            annualMilesDrivenRef?.current[index]?.value &&
            annualMilesDrivenRef?.current[index]?.value < 0
          ) {
            handleVehicleErrors(
              index,
              `annualMilesDrivenError`,
              `Should be More than 1`,
            )
          } else {
            handleVehicleErrors(index, `annualMilesDrivenError`, ``)
          }

          for (const idx in vehicle?.vehicleScope) {
            const _vehicle = vehicle?.vehicleScope[idx]
            if (_vehicle.required === `YES`) {
              if (!_vehicle.emptyDeductibles) {
                if (!_vehicle.deductible?.value?.length) {
                  handleVehicleCoveragesErrors(
                    `deductibleError`,
                    `Select Options`,
                    idx,
                    index,
                  )
                } else {
                  handleVehicleCoveragesErrors(
                    `deductibleError`,
                    ``,
                    idx,
                    index,
                  )
                }
              }
              if (!_vehicle.emptyLimits) {
                if (!_vehicle.limit?.value?.length) {
                  handleVehicleCoveragesErrors(
                    `limitError`,
                    `Select Options`,
                    idx,
                    index,
                  )
                } else {
                  handleVehicleCoveragesErrors(`limitError`, ``, idx, index)
                }
              }
            }
          }
        }
        handleErrors(`formSubmit`, true)
      },
      updateFormValues(data) {
        setRerateQuote(true)
        setRerateQuoteData(data)
        const originalVehiclesObject = JSON.parse(JSON.stringify(updateVehicle))
        const updatedVehicles = data.map((el, index) => {
          if (licensePlateNoRef.current[index]) {
            licensePlateNoRef.current[index].value = el?.licensePlateNo ?? ``
          }

          if (lostPayeeStreetRef.current[index]) {
            lostPayeeStreetRef.current[index].value =
              el?.lienholder?.location?.street
          }

          if (alternateGarageStreetRef.current[index]) {
            alternateGarageStreetRef.current[index].value =
              el?.garage?.location?.street
          }

          if (milesDrivenToWorkRef.current[index]) {
            milesDrivenToWorkRef.current[index].value =
              el?.usage?.milesDrivenToWork
          }

          if (annualMilesDrivenRef.current[index]) {
            annualMilesDrivenRef.current[index].value = el?.usage?.annualMileage
          }

          setVTabs(
            data.map((vehicle, index) => ({
              tName: `Vehicle ${index + 1}`,
              tNumber: index + 1,
              permission: [`quotes:create`],
            })),
          )

          originalVehiclesObject.vehicleScope = el?.coverages?.map(
            (vehicle) => ({
              code: vehicle?.code,
              _deductibleOptions: vehicle?.deductibleOptions,
              _limitOptions: vehicle?.limitsOptions,
              deductibleOption: () => ({
                options: vehicle?.deductibleOptions.length
                  ? vehicle?.deductibleOptions.map((deductible) => ({
                      ...deductible,
                    }))
                  : [],
                hasMore: false,
              }),
              deductible: vehicle?.deductible
                ? {
                    value: vehicle?.deductible,
                    label: vehicle?.deductible,
                  }
                : `Disabled`,
              deductibleError: ``,
              emptyDeductibles: vehicle?.deductibleOptions?.length
                ? false
                : true,
              free: vehicle.free,
              name: vehicle.name,
              required: vehicle.required,
              limit: vehicle?.limits
                ? { value: vehicle?.limits, label: vehicle?.limits }
                : `Disabled`,
              limitError: ``,
              limitsOptions: () => ({
                options: vehicle?.limitsOptions?.length
                  ? vehicle?.limitsOptions.map((limit) => ({
                      ...limit,
                    }))
                  : [],
                hasMore: false,
              }),
              emptyLimits: vehicle?.limitsOptions?.length ? false : true,
            }),
          )

          return {
            ...originalVehiclesObject,
            ...el,
            carType: {
              value: `RATED`,
              label: `RATED`,
            },
            modelYear: {
              value: el?.year,
              label: el?.year,
            },
            make: {
              value: el?.make,
              label: el?.make,
            },
            model: {
              value: el?.model,
              label: el?.model,
            },
            alternateGarage: {
              value: el?.garage?.isGaraged,
              label: el?.garage?.isGaraged,
            },
            zip: {
              value: el?.location?.zip ?? ``,
              label: el?.location?.zip ?? ``,
            },
            city: el?.location?.city,
            state: el?.location?.state,
            alternateGarageAddress: {
              zip: {
                value: el?.garage?.location?.zip ?? ``,
                label: el?.garage?.location?.zip ?? ``,
              },
              city: el?.garage?.location?.city,
              state: el?.garage?.location?.state,
            },

            lossPayeeTypeName: {
              value: el?.lienholder?.name,
              label: el?.lienholder?.name,
            },
            lossPayeeTypeAddress: {
              city: el?.lienholder?.location?.city,
              state: el?.lienholder?.location?.state,
              zip: {
                value: el?.lienholder?.location?.zip,
                label: el?.lienholder?.location?.zip,
              },
              street: el?.lienholder?.location?.street,
            },
            lossPayeeType: {
              value: el?.lienholder?.type ?? `NONE`,

              label: el?.lienholder?.type ?? `NONE`,
            },
            usage: { value: el?.usage?.basicUse, label: el?.usage?.basicUse },
            colors: {
              value: el?.modelDetails?.color,
              label: el?.modelDetails?.color,
            },
            rideShare: {
              value: el?.usage?.isUsedForRideSharing,
              label: el?.usage?.isUsedForRideSharing,
            },
            primaryOperator: {
              value: el?.usage?.primaryOperator,
              label: el?.usage?.primaryOperator,
            },
            monitoringDevice: {
              value: el?.modelDetails?.hasMonitoringDevice,
              label: el?.modelDetails?.hasMonitoringDevice,
            },
            odometer: el?.usage?.odometerReading,
            purchaseCost: el?.purchase?.cost,
            percentDrivenToWork: el?.usage?.percentDrivenToWork,
            purchaseDate: el?.purchase?.date,
            newUsed: { value: el?.purchase?.type, label: el?.purchase?.type },
            leasedVehicle: {
              value: el?.modelDetails?.isLeased,
              label: el?.modelDetails?.isLeased,
            },
            salvaged: {
              value: el?.modelDetails?.isSalvaged,
              label: el?.modelDetails?.isSalvaged,
            },
            antiTheft: {
              value: el?.modelDetails?.antiTheft,
              label: el?.modelDetails?.antiTheft,
            },
            greyMarket: {
              value: el?.modelDetails?.isGreyMarketVehicle,
              label: el?.modelDetails?.isGreyMarketVehicle,
            },

            vehicleType: {
              value: el?.modelDetails?.vehicleType,
              label: el?.modelDetails?.vehicleType,
            },
            truckSize: {
              value: el?.modelDetails?.truckSize,
              label: el?.modelDetails?.truckSize,
            },
            dualie: {
              value: el?.modelDetails?.isDualie,
              label: el?.modelDetails?.isDualie,
            },
            turboCharged: {
              value: el?.modelDetails?.isTurboCharged,
              label: el?.modelDetails?.isTurboCharged,
            },
            fuelType: {
              value: el?.modelDetails?.fuelType,
              label: el?.modelDetails?.fuelType,
            },
            fourWheelDrive: {
              value: el?.modelDetails?.is4wd,
              label: el?.modelDetails?.is4wd,
            },
            fourWheelSteering: {
              value: el?.modelDetails?.is4ws,
              label: el?.modelDetails?.is4ws,
            },
            airBags: {
              value: el?.modelDetails?.airBags,
              label: el?.modelDetails?.airBags,
            },

            passiveRestraint: {
              value: el?.modelDetails?.passiveRestraint,
              label: el?.modelDetails?.passiveRestraint,
            },
            runningLights: {
              value: el?.modelDetails?.hasRunningLights,
              label: el?.modelDetails?.hasRunningLights,
            },
            antiLockBrakes: {
              value: el?.modelDetails?.antiLock,
              label: el?.modelDetails?.antiLock,
            },
            hoodLockingDevice: {
              value: el?.modelDetails?.hasHoodLockingDevice,
              label: el?.modelDetails?.hasHoodLockingDevice,
            },
            vinEtching: {
              value: el?.modelDetails?.hasVinEtching,
              label: el?.modelDetails?.hasVinEtching,
            },
            homingDevice: {
              value: el?.modelDetails?.hasHomingDevice,
              label: el?.modelDetails?.hasHomingDevice,
            },

            msrp: el?.modelDetails?.msrp ?? null,
          }
        })

        setVehicles(updatedVehicles)
      },
    }))

    const handleVehicleCoveragesErrors = (name, val, ind, vIndex) => {
      const newVehicle = vehicles?.map((vehicle, index) => {
        if (vIndex == index) {
          for (const idx in vehicle.vehicleScope) {
            const _vehicle = vehicle?.vehicleScope[idx]
            if (ind === idx) _vehicle[name] = val
          }
        }
        return vehicle
      })
      setVehicles(newVehicle)
    }

    const handleVehicleZipChange = async (e, index, zipType) => {
      const data = await ValidateZip(e?.value)
      if (data) {
        const res = data.address
        if (zipType === `lossPayee`) {
          const newVehicle = vehicles?.map((i, ind) => {
            if (index === ind) {
              i.lossPayeeTypeAddress[`city`] = res.city
              i.lossPayeeTypeAddress[`state`] = res.state
              i.lossPayeeTypeAddress[`zip`] = {
                value: res.zipCode,
                label: res.zipCode,
              }
            }
            return i
          })
          setVehicles(newVehicle)
        } else if (zipType === `vehicle`) {
          const newVehicle = vehicles?.map((i, ind) => {
            if (index === ind) {
              i[`city`] = res.city
              i[`state`] = res.state
              i[`zip`] = {
                value: res.zipCode,
                label: res.zipCode,
              }
            }
            return i
          })
          setVehicles(newVehicle)
        } else {
          const newVehicle = vehicles?.map((i, ind) => {
            if (index === ind) {
              i.alternateGarageAddress[`city`] = res.city
              i.alternateGarageAddress[`state`] = res.state
              i.alternateGarageAddress[`zip`] = {
                value: res.zipCode,
                label: res.zipCode,
              }
            }
            return i
          })
          setVehicles(newVehicle)
        }
        //setting coordinates alternateGarageAddress
      }
    }

    const ValidateStreetAddress = async (address) => {
      try {
        const {
          data: { data },
        } = await AxiosInstance.post(`${AQS_BACKEND_API}/validate/address`, {
          address: {
            street: address?.street,
            city: address?.city,
            zip: address?.zip?.value,
            state: address?.state,
          },
        })
        return data
      } catch (error) {
        NotificationManager.error(error?.response?.data?.message)
      }
    }

    const validateGarageStreet = useDebouncedCallback(
      async (index, addressType) => {
        if (alternateGarageStreetRef?.current[index]?.value) {
          setGarageAddressValidate(true)
          const data = await ValidateStreetAddress({
            street: alternateGarageStreetRef?.current[index]?.value,
            city: vehicles[index][addressType]?.city,
            state: vehicles[index][addressType]?.state,
            zip: vehicles[index][addressType]?.zip,
          })
          if (!data?.isAddressValid) {
            setGarageAddressValidate(false)
            handleErrors(`streetNoError`, `Enter Valid Address`)
          } else {
            setGarageAddressValidate(false)
            handleErrors(`streetNoError`, ``)
          }
        }
      },
      300,
    )

    const validateLossPayeeStreet = useDebouncedCallback(
      async (index, addressType) => {
        if (lostPayeeStreetRef?.current[index]?.value) {
          setLossPayeeAddressValidate(true)
          const data = await ValidateStreetAddress({
            street: lostPayeeStreetRef?.current[index]?.value,
            city: vehicles[index][addressType]?.city,
            state: vehicles[index][addressType]?.state,
            zip: vehicles[index][addressType]?.zip,
          })
          if (!data?.isAddressValid) {
            setLossPayeeAddressValidate(false)
            handleErrors(`streetErrorLossPayee`, `Enter Valid Address`)
          } else {
            setLossPayeeAddressValidate(false)
            handleErrors(`streetErrorLossPayee`, ``)
          }
        }
      },
      300,
    )

    const handleStreetAddress = useDebouncedCallback((index, addressType) => {
      if (addressType === `alternateGarageAddress`) {
        handleErrors(`streetNoError`, ``)
      } else if (addressType === `lossPayeeTypeAddress`) {
        handleErrors(`streetErrorLossPayee`, ``)
      }

      if (addressType === `alternateGarageAddress`) {
        validateGarageStreet(index, addressType)
      } else if (addressType === `lossPayeeTypeAddress`) {
        validateLossPayeeStreet(index, addressType)
      }
    }, 5)

    const preparedVehiclePayload = () => {
      if (vehicles?.length > 0) {
        return vehicles.map((vehicle, index) => {
          let payload = {
            garage: { location: {} },
            location: {},
            usage: {},
            purchase: {},
            modelDetails: {},
            coverages: [],
            lienholder: { location: {} },
          }
          if (vehicle?.modelYear?.value?.toString()?.length) {
            payload.year = vehicle?.modelYear?.value
          }
          if (vehicle?.make?.value?.length) {
            payload.make = vehicle?.make?.value
          }
          if (vehicle?.model?.value?.length) {
            payload.model = vehicle?.model?.value
          }
          if (vehicle?.vin?.length) {
            payload.vin = vehicle?.vin
          }
          if (licensePlateNoRef?.current[index]?.value.length) {
            payload.licensePlateNo = licensePlateNoRef?.current[index]?.value
          }
          if (vehicle?.zip?.value?.length) {
            payload.location.zip = vehicle?.zip?.value
          }
          if (vehicle?.city?.length) {
            payload.location.city = vehicle?.city
          }
          if (vehicle?.state?.length) {
            payload.location.state = vehicle?.state
          }
          if (vehicle?.alternateGarage?.value?.length) {
            payload.garage.isGaraged = vehicle?.alternateGarage?.value
          }
          if (vehicle?.alternateGarage?.value === `YES`) {
            if (vehicle?.alternateGarageAddress?.zip?.value?.length) {
              payload.garage.location.zip =
                vehicle?.alternateGarageAddress?.zip?.value
            }
            if (vehicle?.alternateGarageAddress?.city?.length) {
              payload.garage.location.city =
                vehicle?.alternateGarageAddress?.city
            }
            if (vehicle?.alternateGarageAddress?.state?.length) {
              payload.garage.location.state =
                vehicle?.alternateGarageAddress?.state
            }
            if (alternateGarageStreetRef?.current[index]?.value?.length) {
              payload.garage.location.street =
                alternateGarageStreetRef?.current[index]?.value
            }
          } else {
            if (vehicle?.zip?.value?.length) {
              payload.garage.location.zip = vehicle?.zip?.value
            }
            if (vehicle?.city?.length) {
              payload.garage.location.city = vehicle?.city
            }
            if (vehicle?.state?.length) {
              payload.garage.location.state = vehicle?.state
            }
            if (alternateGarageStreetRef?.current[index]?.value?.length) {
              payload.garage.location.street = firstDriverInfo?.address?.street
                .length
                ? firstDriverInfo?.address?.street
                : alternateGarageStreetRef?.current[index]?.value
            }
          }

          if (vehicle?.lossPayeeType?.value !== `NONE`) {
            payload.lienholder.hasLienholder = `YES`
            if (vehicle?.lossPayeeTypeName?.value?.length) {
              payload.lienholder.name = vehicle.lossPayeeTypeName.value
            }
            if (vehicle?.lossPayeeType?.value?.length) {
              payload.lienholder.type = vehicle.lossPayeeType.value
            }

            if (vehicle?.lossPayeeTypeAddress?.zip?.value?.length) {
              payload.lienholder.location.zip =
                vehicle?.lossPayeeTypeAddress?.zip?.value
            } else {
              payload.lienholder.hasLienholder = `NO`
            }

            if (lostPayeeStreetRef?.current[index]?.value?.length) {
              payload.lienholder.location.street =
                lostPayeeStreetRef?.current[index]?.value
            }
            if (vehicle.lossPayeeTypeAddress?.city?.length) {
              payload.lienholder.location.city =
                vehicle.lossPayeeTypeAddress?.city
            }
            if (vehicle.lossPayeeTypeAddress?.state?.length) {
              payload.lienholder.location.state =
                vehicle.lossPayeeTypeAddress?.state
            }
          }

          if (vehicle?.usage?.value?.length) {
            payload.usage.basicUse = vehicle?.usage?.value
          }
          if (vehicle?.rideShare?.value?.length) {
            payload.usage.isUsedForRideSharing = vehicle?.rideShare?.value
          }
          if (vehicle?.primaryOperator?.value?.toString()?.length) {
            payload.usage.primaryOperator = parseInt(
              vehicle?.primaryOperator?.value,
            )
          }

          if (vehicle?.percentDrivenToWork?.length) {
            payload.usage.percentDrivenToWork = vehicle?.percentDrivenToWork
          }
          if (milesDrivenToWorkRef?.current[index]?.value?.toString()?.length) {
            payload.usage.milesDrivenToWork =
              milesDrivenToWorkRef?.current[index]?.value
          }
          if (annualMilesDrivenRef?.current[index]?.value?.toString()?.length) {
            payload.usage.annualMileage =
              annualMilesDrivenRef?.current[index]?.value
          }
          if (vehicle?.odometer?.length) {
            payload.usage.odometerReading = vehicle?.odometer
          }
          if (vehicle?.purchaseCost?.length) {
            payload.purchase.cost = vehicle?.purchaseCost
          }
          if (vehicle?.purchaseDate) {
            payload.purchase.date = vehicle?.purchaseDate
          }
          if (vehicle?.newUsed?.value?.length) {
            payload.purchase.type = vehicle?.newUsed?.value
          }
          if (vehicle?.acv?.length) {
            payload.actualCashValue = vehicle?.acv
          }
          if (vehicle?.vehicleType?.value?.length) {
            payload.modelDetails.vehicleType = vehicle?.vehicleType?.value
          }
          if (vehicle?.fuelType?.value?.length) {
            payload.modelDetails.fuelType = vehicle?.fuelType?.value
          }
          if (vehicle?.truckSize?.value?.length) {
            payload.modelDetails.truckSize = vehicle?.truckSize?.value
          }
          if (vehicle?.numberOfCylinders?.length) {
            payload.modelDetails.cylinders = vehicle?.numberOfCylinders
          }
          if (vehicle?.fourWheelDrive?.value?.length) {
            payload.modelDetails.is4wd = vehicle?.fourWheelDrive?.value
          }
          if (vehicle?.fourWheelSteering?.value?.length) {
            payload.modelDetails.is4ws = vehicle?.fourWheelSteering?.value
          }
          if (vehicle?.turboCharged?.value?.length) {
            payload.modelDetails.isTurboCharged = vehicle?.turboCharged?.value
          }
          if (vehicle?.airBags?.value?.length) {
            payload.modelDetails.airBags = vehicle?.airBags?.value
          }
          if (vehicle?.antiLockBrakes?.value?.length) {
            payload.modelDetails.antiLock = vehicle?.antiLockBrakes?.value
          }
          if (vehicle?.antiTheft?.value?.length) {
            payload.modelDetails.antiTheft = vehicle?.antiTheft?.value
          }
          if (vehicle?.passiveRestraint?.value?.length) {
            payload.modelDetails.passiveRestraint =
              vehicle?.passiveRestraint?.value
          }
          if (vehicle?.leasedVehicle?.value?.length) {
            payload.modelDetails.isLeased = vehicle?.leasedVehicle?.value
          }
          if (vehicle?.salvaged?.value?.length) {
            payload.modelDetails.isSalvaged = vehicle?.salvaged?.value
          }
          if (vehicle?.greyMarket?.value?.length) {
            payload.modelDetails.isGreyMarketVehicle =
              vehicle?.greyMarket?.value
          }
          if (vehicle?.monitoringDevice?.value?.length) {
            payload.modelDetails.hasMonitoringDevice =
              vehicle?.monitoringDevice?.value
          }
          if (vehicle?.dualie?.value?.length) {
            payload.modelDetails.isDualie = vehicle?.dualie?.value
          }
          if (vehicle?.runningLights?.value?.length) {
            payload.modelDetails.hasRunningLights =
              vehicle?.runningLights?.value
          }
          if (vehicle?.hoodLockingDevice?.value?.length) {
            payload.modelDetails.hasHoodLockingDevice =
              vehicle?.hoodLockingDevice?.value
          }
          if (vehicle?.homingDevice?.value?.length) {
            payload.modelDetails.hasHomingDevice = vehicle?.homingDevice?.value
          }
          if (vehicle?.vinEtching?.value?.length) {
            payload.modelDetails.hasVinEtching = vehicle?.vinEtching?.value
          }
          if (vehicle?.colors?.value?.length) {
            payload.modelDetails.color = vehicle?.colors?.value
          }
          if (vehicle?.msrp?.toString()?.length) {
            payload.modelDetails.msrp = vehicle?.msrp
          }
          if (vehicle?.vehicleScope.length) {
            const prepareCoverages = vehicle?.vehicleScope?.map((coverage) => ({
              deductibleOptions:
                coverage?._deductibleOptions === 0
                  ? []
                  : coverage?._deductibleOptions,
              limitsOptions:
                coverage?._limitOptions === 0 ? [] : coverage?._limitOptions,
              rated:
                !coverage.limit?.value?.includes(`NONE`) ||
                !coverage.deductible?.value?.includes(`NONE`)
                  ? `YES`
                  : `NO`,
              name: coverage.code,
              code: coverage.code,
              limits:
                // selectedState?.value === `UT` && coverage.code === `UMPD`
                //   ? `3500`
                coverage.limit?.value,
              deductible: coverage.deductible?.value,
            }))
            payload.coverages = prepareCoverages
          }
          payload.questions = vehicle.carrierQuestion
          return payload
        })
      }
    }
    useEffect(() => {
      let formValidated = []
      let scopes = []
      if (errors.formSubmit) {
        formValidated = vehicles.map((vehicle) => {
          if (
            !vehicle?.vinError?.length &&
            !vehicle?.zipError?.length &&
            !vehicle?.makeError?.length &&
            !vehicle?.modelError?.length &&
            !vehicle?.modelYearError?.length &&
            !vehicle?.alternateGarageError?.length &&
            !vehicle?.purchaseDateError?.length &&
            !vehicle?.primaryOperatorError?.length &&
            !vehicle?.purchaseCostError?.length &&
            !vehicle?.acvError?.length &&
            !vehicle?.numberOfCylindersError?.length &&
            !vehicle?.milesDrivenToWorkError?.length &&
            !vehicle?.annualMilesDrivenError?.length &&
            !vehicle?.licensePlateNoError?.length &&
            !vehicle?.odometerError?.length &&
            !vehicle?.msrpError?.length
          ) {
            if (!scopes.includes(false)) {
              scopes = vehicle?.vehicleScope?.map((vehicleScope) => {
                if (
                  !vehicleScope.limitError?.length &&
                  !vehicleScope.deductibleError?.length
                ) {
                  return true
                } else {
                  return false
                }
              })
            }
            return true
          }
        })
        if (!formValidated?.includes(undefined) && !scopes.includes(false)) {
          getData(`vehicles`, preparedVehiclePayload())
          dispatch(setVehicleData(vehicles))
          handleErrors(`formSubmit`, false)
        } else {
          getData(`vehicles`, null)
          dispatch(setVehicleData([]))
          handleErrors(`formSubmit`, false)
        }
      }
    }, [errors])

    useEffect(() => {
      if (vehicleCoverage && !reRated) {
        const newVehicels = (prev) =>
          prev?.map((_vehicle) => {
            const vehicle = cloneDeep(_vehicle)
            vehicle.vehicleScope = getSelectedVehicleScope()
              ? getSelectedVehicleScope()
              : cloneDeep(vehicleCoverage)

            return vehicle
          })
        setVehicles(newVehicels)
      }
    }, [vehicleCoverage])

    useEffect(() => {
      setActiveVehicleTab(vTabs.length)
    }, [vTabs.length])

    useEffect(() => {
      if (leadData && leadData?.cars?.length && !reRated) {
        const preparedLeadVehicles = leadData?.cars.map((car) => {
          let vehicle = cloneDeep(addNewVehicle)

          vehicle.modelYear = { value: car.year, label: car.year }
          vehicle.make = { value: car.make, label: car.make }
          vehicle.model = { value: car.model, label: car.model }
          vehicle.vin = car.vin
          vehicle.street = leadData?.customerData?.address?.street
          //

          const newCarrierQuestions = {}
          for (let cQuestion in carrierQuestion) {
            newCarrierQuestions[cQuestion] = carrierQuestion[cQuestion].map(
              (item) => ({
                question: item.question,
                FieldName: item.name,
                options: item.options,
                selectedValue: { value: item.default, label: item.default },
                mask: item.mask ?? ``,
                default: item.default ?? ``,
                scope: item.scope,
              }),
            )
          }
          vehicle.carrierQuestion = newCarrierQuestions

          return vehicle
        })

        setVTabs(() =>
          leadData?.cars?.map((item, index) => ({
            tName: `Vehicle ${index + 1}`,
            tNumber: index + 1,
          })),
        )
        setVehicles(preparedLeadVehicles)
      }
    }, [leadData])
    return (
      <div className="">
        {gotVinNumbers !== false && (
          <ReactModal
            title="Vin Numbers"
            closeModal={() => setGotVinNumbers(false)}
            width="3/4"
          >
            <div className="flex w-full text-center p-2 font-md text-md">
              <div className="flex-1">Vin</div>
              <div className="flex-1">Type</div>
              <div className="flex-1">Feul</div>
              <div className="flex-1">Cylinders</div>
              <div className="flex-1">Ton Rating</div>
              <div className="flex-1">MSRP</div>
              <div className="flex-1"></div>
            </div>

            {vinNumbersData?.map((vinNumber, index) => (
              <div
                className="flex w-full text-center p-2 text-sm border-t-1"
                key={index}
              >
                <div className="flex-1">{vinNumber.vin}</div>
                <div className="flex-1">{vinNumber.vehicleType}</div>
                <div className="flex-1">{vinNumber.fuelType}</div>
                <div className="flex-1">{vinNumber.cylinders}</div>
                <div className="flex-1">{vinNumber.truckSize}</div>
                <div className="flex-1">{vinNumber.msrp}</div>
                <div className="flex-1">
                  <span
                    className="px-2 py-1 bg-blue-500 rounded-md text-white cursor-pointer"
                    onClick={() => {
                      handleVinNumber(`vin`, vinNumber, gotVinNumbers)
                      setGotVinNumbers(false)
                    }}
                  >
                    Add
                  </span>
                </div>
              </div>
            ))}
          </ReactModal>
        )}
        <CollapsableRef title="Vehicle Information" isActive={true}>
          {/* ?Vehicle Tabs Start */}
          <div className="flex items-center">
            <TabsHeaders
              openTab={activeVehicleTab}
              tabs={vTabs}
              getActiveTab={(val) => setActiveVehicleTab(val)}
              customTabs="YES"
            />
            {!vinLoading && !gotVinLoading && (
              <div className="px-2">
                <AddFormButton onClick={() => addVehicle()}>
                  <FontAwesomeIcon icon={faPlus} size="lg" />
                </AddFormButton>
              </div>
            )}
          </div>
          {/* Vehicle Tabs End */}
          <>
            {/* Vehicle Form  */}
            {vehicles.length > 0 &&
              vehicles?.map((vehicle, index) => (
                <FormTab
                  key={index}
                  currenttab={index + 1}
                  opentab={activeVehicleTab}
                  className="border-2 border-gray-100 shadow-sm rounded-md relative"
                >
                  <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                    <div className="flex-1">Vehicle {index + 1}</div>
                    <div className="flex-1">
                      {index > 0 && (
                        <div className="float-right">
                          <RemoveDriver
                            onClick={() => {
                              handleRemoveVehicle(index)
                              setActiveVehicleTab(index)
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                          </RemoveDriver>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-x-6 gap-y-2 p-2">
                    <div className="grid grid-cols-2 gap-x-6">
                      <div className="relative">
                        <ReactSelectField
                          title="Car Type"
                          placeholder="Car Type"
                          isMulti={false}
                          value={vehicle?.carType}
                          setValue={(value) =>
                            handleVehicleChange(`carType`, value, index)
                          }
                          loadOptions={loadDriverType}
                          isSearchable={true}
                        />
                      </div>
                      <div className="relative">
                        <ReactSelectField
                          title="Model Year *"
                          placeholder="Model Year"
                          isMulti={false}
                          value={vehicle?.modelYear}
                          requiredValue={!vehicle?.modelYear?.value}
                          errorMessage={vehicle?.modelYearError}
                          setValue={(value) => {
                            isYearCalledFromVin.current = false
                            handleVehicleChange(`modelYear`, value, index)
                          }}
                          loadOptions={loadMakeYears}
                          isSearchable={true}
                        />
                      </div>
                      <div className="relative">
                        <ReactSelectField
                          title="Make *"
                          placeholder="Make"
                          isMulti={false}
                          value={vehicle?.make}
                          requiredValue={!vehicle?.make?.value?.length}
                          errorMessage={vehicle?.makeError}
                          setValue={(value) => {
                            isYearCalledFromVin.current = false
                            handleVehicleChange(`make`, value, index)
                          }}
                          loadOptions={vehicle?.modelYear?.value && loadMakes}
                          additionalFilters={{
                            year: vehicle?.modelYear?.value,
                          }}
                          cacheUniqs={vehicle?.modelYear.value}
                          isSearchable={true}
                        />
                      </div>
                      <div className="relative">
                        <ReactSelectField
                          title="Model *"
                          placeholder="Model"
                          isMulti={false}
                          value={vehicle?.model}
                          requiredValue={!vehicle?.model?.value?.length}
                          errorMessage={vehicle?.modelError}
                          setValue={(value) => {
                            isYearCalledFromVin.current = false
                            handleCarModel(`model`, value, index)
                          }}
                          loadOptions={
                            vehicle?.modelYear.value &&
                            vehicle?.make.label &&
                            loadModel
                          }
                          additionalFilters={{
                            year: vehicle?.modelYear?.value,
                            make: vehicle?.make?.label,
                          }}
                          cacheUniqs={vehicle?.make.label}
                          isSearchable={true}
                        />
                      </div>
                      <div className="relative">
                        <InputField
                          title="VIN *"
                          type="text"
                          name="vin"
                          placeholder="Enter Vin"
                          value={vehicle?.vin}
                          validating={vinLoading}
                          errorMessage={vehicle?.vinError}
                          requiredValue={!vehicle?.vin?.length}
                          setValue={(e) => {
                            // vehicle.msrp = ``
                            // vehicle.numberOfCylinders = ``
                            // vehicle.fuelType = ``
                            // vehicle.msrp = ``
                            isVinCalledFromModel.current = false
                            handleCarModel(`vin`, e.target.value, index)
                          }}
                        />
                      </div>

                      <div className="relative">
                        <InputFieldRef
                          title="License Plate No."
                          placeholder="License Plate No."
                          type="text"
                          inputRef={(element) => {
                            licensePlateNoRef.current[index] = element
                          }}
                          errorMessage={vehicle?.licensePlateNoError}
                        />
                      </div>
                      <div className="relative">
                        <ReactSelectField
                          title="Zip *"
                          placeholder="Select Zip Code"
                          errorMessage={vehicle?.zipError}
                          isMulti={false}
                          value={vehicle?.zip}
                          setValue={(e) =>
                            handleVehicleZipChange(e, index, `vehicle`)
                          }
                          loadOptions={loadZipCodes}
                          isSearchable={true}
                          disabled={true}
                        />
                      </div>
                      <div className="relative">
                        <InputField
                          type="text"
                          title="City"
                          placeholder="Enter City"
                          errorMessage={vehicle.cityError}
                          disabled={true}
                          name="city"
                          value={vehicle?.city}
                        />
                      </div>
                      <div className="relative">
                        <InputField
                          type="text"
                          title="State"
                          placeholder="Enter State"
                          errorMessage={vehicle.stateError}
                          disabled={true}
                          name="state"
                          value={vehicle?.state}
                        />
                      </div>

                      <div className="relative">
                        <ReactSelectField
                          title="Alternate Garage"
                          placeholder="Alternate Garage"
                          isMulti={false}
                          value={vehicle?.alternateGarage}
                          errorMessage={vehicle?.alternateGarageError}
                          setValue={(value) =>
                            handleVehicleChange(`alternateGarage`, value, index)
                          }
                          loadOptions={loadBinaryOptions}
                          isSearchable={true}
                        />
                      </div>
                      {vehicle?.alternateGarage?.value === `YES` ||
                      !vehicle?.alternateGarage?.value === `` ? (
                        <>
                          <div className="flex-1 relative">
                            <ReactSelectField
                              title="Zip"
                              placeholder="Select Zip"
                              isMulti={false}
                              value={vehicle?.alternateGarageAddress?.zip}
                              setValue={(e) =>
                                handleVehicleZipChange(
                                  e,
                                  index,
                                  `alternateGarage`,
                                )
                              }
                              loadOptions={loadZipCodes}
                              isSearchable={true}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <AddressValidate
                              type="text"
                              title="Street"
                              placeholder="Enter Street"
                              validating={garageAddressValidate}
                              // errorMessage={errors.streetNoError}
                              inputRef={(element) => {
                                alternateGarageStreetRef.current[index] =
                                  element
                              }}
                              setValue={() =>
                                handleStreetAddress(
                                  index,
                                  `alternateGarageAddress`,
                                )
                              }
                            />
                          </div>
                          <div className="flex-1 relative">
                            <InputField
                              type="text"
                              title="City"
                              placeholder="Enter City"
                              disabled={true}
                              name="city"
                              value={vehicle?.alternateGarageAddress?.city}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <InputField
                              type="text"
                              title="State"
                              placeholder="Enter State"
                              disabled={true}
                              name="state"
                              value={vehicle?.alternateGarageAddress?.state}
                            />
                          </div>
                        </>
                      ) : null}
                      <div className="relative">
                        <ReactSelectField
                          title="Loss Payee Type"
                          placeholder="Loss Payee Type"
                          isMulti={false}
                          value={vehicle?.lossPayeeType}
                          setValue={(value) =>
                            handleVehicleChange(`lossPayeeType`, value, index)
                          }
                          loadOptions={loadLossType}
                          isSearchable={true}
                        />
                      </div>
                      {vehicle?.lossPayeeType?.value === `LEASE` ||
                      vehicle?.lossPayeeType?.value === `LOAN` ? (
                        <>
                          <div className="relative">
                            <ReactSelectField
                              title="Name"
                              placeholder="Name"
                              isMulti={false}
                              value={vehicle?.lossPayeeTypeName}
                              setValue={(value) =>
                                handleVehicleChange(
                                  `lossPayeeTypeName`,
                                  value,
                                  index,
                                )
                              }
                              loadOptions={loadLossPayeeName}
                              isSearchable={true}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <ReactSelectField
                              title="Zip"
                              placeholder="Select Zip"
                              isMulti={false}
                              value={vehicle?.lossPayeeTypeAddress?.zip}
                              setValue={(e) =>
                                handleVehicleZipChange(e, index, `lossPayee`)
                              }
                              loadOptions={loadZipCodes}
                              isSearchable={true}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <AddressValidate
                              type="text"
                              title="Street"
                              placeholder="Enter Street"
                              validating={lossPayeeAddressValidate}
                              inputRef={(element) => {
                                lostPayeeStreetRef.current[index] = element
                              }}
                              setValue={() =>
                                handleStreetAddress(
                                  index,
                                  `lossPayeeTypeAddress`,
                                )
                              }
                            />
                          </div>
                          <div className="flex-1 relative">
                            <InputField
                              type="text"
                              title="City"
                              placeholder="Enter City"
                              disabled={true}
                              name="city"
                              value={vehicle?.lossPayeeTypeAddress?.city}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <InputField
                              type="text"
                              title="State"
                              placeholder="Enter State"
                              disabled={true}
                              name="state"
                              value={vehicle?.lossPayeeTypeAddress?.state}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div>
                      <div className="p-2 grid grid-cols-2 gap-x-2">
                        {vehicleCoverage?.length > 0 &&
                          vehicle?.vehicleScope?.map(
                            (vehicle, vidx) =>
                              (!vehicle.emptyLimits ||
                                !vehicle.emptyDeductibles) && (
                                <div className="relative pt-1" key={vidx}>
                                  <div className="text-sm font-medium">
                                    {vehicle.code}
                                  </div>
                                  <div className="flex gap-x-6">
                                    {!vehicle.emptyLimits && (
                                      <div className="flex-1 relative">
                                        <ReactSelectField
                                          placeholder="Limits"
                                          errorMessage={vehicle.limitError}
                                          value={vehicle.limit}
                                          setValue={(value) =>
                                            handleVehicleChange(
                                              `limit`,
                                              value,
                                              index,
                                              vidx,
                                            )
                                          }
                                          loadOptions={vehicle.limitsOptions}
                                          isSearchable={false}
                                          disabled={vehicle.emptyLimits}
                                          noTitle={true}
                                          cacheUniqs={vehicleCoverage[
                                            vidx
                                          ]?.limitsOptions()}
                                        />
                                      </div>
                                    )}
                                    {!vehicle.emptyDeductibles && (
                                      <div className="flex-1 relative">
                                        <ReactSelectField
                                          placeholder="Deductibles"
                                          errorMessage={vehicle.deductibleError}
                                          value={vehicle.deductible}
                                          setValue={(value) =>
                                            handleVehicleChange(
                                              `deductible`,
                                              value,
                                              index,
                                              vidx,
                                            )
                                          }
                                          cacheUniqs={vehicleCoverage[
                                            vidx
                                          ]?.deductibleOption()}
                                          loadOptions={vehicle.deductibleOption}
                                          isSearchable={false}
                                          disabled={vehicle.emptyDeductibles}
                                          noTitle={true}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ),
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="text-sm font-medium py-1 px-4 mx-2 bg-red-500  rounded-t-md text-white flex my-2">
                    <div className="flex-1">Vehicles Attribute</div>
                  </div>
                  <div className="grid grid-cols-4 gap-x-6 gap-y-2 p-2">
                    <div className="relative">
                      <ReactSelectField
                        title="Usage"
                        placeholder="Usage"
                        isMulti={false}
                        value={vehicle?.usage}
                        setValue={(value) =>
                          handleVehicleChange(`usage`, value, index)
                        }
                        loadOptions={loadUsage}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Colors"
                        placeholder="Colors"
                        isMulti={false}
                        value={vehicle?.colors}
                        setValue={(value) =>
                          handleVehicleChange(`colors`, value, index)
                        }
                        loadOptions={loadColors}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Ride Share"
                        placeholder="Ride Share"
                        isMulti={false}
                        value={vehicle?.rideShare}
                        setValue={(value) =>
                          handleVehicleChange(`rideShare`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Monitoring Device"
                        placeholder="Monitoring Device"
                        isMulti={false}
                        value={vehicle?.monitoringDevice}
                        setValue={(value) =>
                          handleVehicleChange(`monitoringDevice`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Primary Operator"
                        placeholder="Primary Operator"
                        value={vehicle?.primaryOperator}
                        setValue={(value) =>
                          handleVehicleChange(`primaryOperator`, value, index)
                        }
                        errorMessage={vehicle?.primaryOperatorError}
                        loadOptions={loadPrimary}
                        cacheUniqs={drivers}
                      />
                    </div>
                    <div className="relative">
                      <MaskField
                        type="text"
                        mask="99"
                        title="Percent Driven to Work"
                        placeholder="Percent"
                        value={vehicle?.percentDrivenToWork}
                        setValue={(e) =>
                          handleVehicleChange(
                            `percentDrivenToWork`,
                            e.target.value,
                            index,
                          )
                        }
                      />
                    </div>

                    <div className="relative">
                      <InputFieldRef
                        title="Miles Driven to Work"
                        placeholder="Miles"
                        type="number"
                        inputRef={(element) => {
                          milesDrivenToWorkRef.current[index] = element
                        }}
                        errorMessage={vehicle?.milesDrivenToWorkError}
                      />
                    </div>
                    <div className="relative">
                      <InputFieldRef
                        title="Annual Miles Driven *"
                        placeholder="Miles"
                        type="number"
                        inputRef={(element) => {
                          annualMilesDrivenRef.current[index] = element
                        }}
                        requiredValue={
                          !annualMilesDrivenRef.current[index]?.value.length
                        }
                        errorMessage={vehicle?.annualMilesDrivenError}
                      />
                    </div>
                    <div className="relative">
                      <MaskField
                        type="text"
                        mask="9999999"
                        title="Odometer"
                        placeholder="Odometer"
                        errorMessage={vehicle?.odometerError}
                        value={vehicle?.odometer}
                        setValue={(e) =>
                          handleVehicleChange(`odometer`, e.target.value, index)
                        }
                      />
                    </div>
                    <div className="relative">
                      <MaskField
                        type="text"
                        mask="9999999"
                        title="Purchase Cost"
                        placeholder="Purchase Cost"
                        value={vehicle?.purchaseCost}
                        errorMessage={vehicle?.purchaseCostError}
                        setValue={(e) =>
                          handleVehicleChange(
                            `purchaseCost`,
                            e.target.value,
                            index,
                          )
                        }
                      />
                    </div>
                    <div className="relative">
                      <MaskField
                        type="text"
                        mask="9999999"
                        title="MSRP *"
                        placeholder="MSRP"
                        errorMessage={vehicle?.msrpError}
                        requiredValue={!vehicle?.msrp?.length}
                        value={vehicle?.msrp}
                        setValue={(e) =>
                          handleVehicleChange(`msrp`, e.target.value, index)
                        }
                      />
                    </div>
                    <div className="relative">
                      <MaskField
                        type="text"
                        mask="9999999"
                        title="ACV"
                        placeholder="ACV"
                        errorMessage={vehicle?.acvError}
                        value={vehicle?.acv}
                        setValue={(e) =>
                          handleVehicleChange(`acv`, e.target.value, index)
                        }
                      />
                    </div>

                    <div className="relative">
                      <DatePickerField
                        title="Purchase Date *"
                        placeholder="Purchase Date"
                        value={
                          vehicle?.purchaseDate !== ``
                            ? new Date(vehicle?.purchaseDate)
                            : ``
                        }
                        errorMessage={vehicle?.purchaseDateError}
                        requiredValue={!vehicle?.purchaseDate}
                        maxDate={new Date(moment().subtract(1, `week`))}
                        setValue={(e) => {
                          const date = e === null ? `` : e
                          handleVehicleChange(`purchaseDate`, date, index)
                        }}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="New/Used"
                        placeholder="New/Used"
                        isMulti={false}
                        value={vehicle?.newUsed}
                        setValue={(value) =>
                          handleVehicleChange(`newUsed`, value, index)
                        }
                        loadOptions={loadVehicleCondition}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Leased Vehicle"
                        placeholder="Leased Vehicle"
                        isMulti={false}
                        value={vehicle?.leasedVehicle}
                        setValue={(value) =>
                          handleVehicleChange(`leasedVehicle`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Salvaged"
                        placeholder="Salvaged"
                        isMulti={false}
                        value={vehicle?.salvaged}
                        setValue={(value) =>
                          handleVehicleChange(`salvaged`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Anti Theft"
                        placeholder="Anti Theft"
                        isMulti={false}
                        value={vehicle?.antiTheft}
                        setValue={(value) =>
                          handleVehicleChange(`antiTheft`, value, index)
                        }
                        loadOptions={loadAntiTheft}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Grey Market"
                        placeholder="Grey Market"
                        isMulti={false}
                        value={vehicle?.greyMarket}
                        setValue={(value) =>
                          handleVehicleChange(`greyMarket`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="text-sm font-medium py-1 px-4 mx-2 bg-red-500  rounded-t-md text-white flex my-2">
                    <div className="flex-1">Additional Attributes</div>
                  </div>
                  <div className="grid grid-cols-4 gap-x-6 gap-y-2 p-2">
                    <div className="relative">
                      <ReactSelectField
                        title="Vehicle Type"
                        placeholder="Vehicle Type"
                        isMulti={false}
                        value={vehicle?.vehicleType}
                        setValue={(value) =>
                          handleVehicleChange(`vehicleType`, value, index)
                        }
                        loadOptions={loadVehicleType}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <MaskField
                        title="Number of Cylinders"
                        placeholder="Number of Cylinders"
                        type="text"
                        mask="9999999"
                        errorMessage={vehicle?.numberOfCylindersError}
                        value={vehicle?.numberOfCylinders}
                        setValue={(e) =>
                          handleVehicleChange(
                            `numberOfCylinders`,
                            e.target.value,
                            index,
                          )
                        }
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Truck Size"
                        placeholder="Truck Size"
                        isMulti={false}
                        value={vehicle?.truckSize}
                        setValue={(value) =>
                          handleVehicleChange(`truckSize`, value, index)
                        }
                        loadOptions={loadTruckSize}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Dualie"
                        placeholder="Dualie"
                        isMulti={false}
                        value={vehicle?.dualie}
                        setValue={(value) =>
                          handleVehicleChange(`dualie`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Turbo Charged"
                        placeholder="Turbo Charged"
                        isMulti={false}
                        value={vehicle?.turboCharged}
                        setValue={(value) =>
                          handleVehicleChange(`turboCharged`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Fuel Type"
                        placeholder="Fuel Type"
                        isMulti={false}
                        value={vehicle?.fuelType}
                        setValue={(value) =>
                          handleVehicleChange(`fuelType`, value, index)
                        }
                        loadOptions={loadFuelType}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Four Wheel Drive"
                        placeholder="Four Wheel Drive"
                        isMulti={false}
                        value={vehicle?.fourWheelDrive}
                        setValue={(value) =>
                          handleVehicleChange(`fourWheelDrive`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Four Wheel Steering"
                        placeholder="Four Wheel Steering"
                        isMulti={false}
                        value={vehicle?.fourWheelSteering}
                        setValue={(value) =>
                          handleVehicleChange(`fourWheelSteering`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Air Bags"
                        placeholder="Air Bags"
                        isMulti={false}
                        value={vehicle?.airBags}
                        setValue={(value) =>
                          handleVehicleChange(`airBags`, value, index)
                        }
                        loadOptions={loadAirBags}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Passive Restraint"
                        placeholder="Passive Restraint"
                        isMulti={false}
                        value={vehicle?.passiveRestraint}
                        setValue={(value) =>
                          handleVehicleChange(`passiveRestraint`, value, index)
                        }
                        loadOptions={loadPassiveRestraint}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Running Lights"
                        placeholder="Running Lights"
                        isMulti={false}
                        value={vehicle?.runningLights}
                        setValue={(value) =>
                          handleVehicleChange(`runningLights`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Anti-lock Brakes"
                        placeholder="Anti-lock Brakes"
                        isMulti={false}
                        value={vehicle?.antiLockBrakes}
                        setValue={(value) =>
                          handleVehicleChange(`antiLockBrakes`, value, index)
                        }
                        loadOptions={loadAntiLockBrakes}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Hood Locking Device"
                        placeholder="Hood Locking Device"
                        isMulti={false}
                        value={vehicle?.hoodLockingDevice}
                        setValue={(value) =>
                          handleVehicleChange(`hoodLockingDevice`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="Homing Device"
                        placeholder="Homing Device"
                        isMulti={false}
                        value={vehicle?.homingDevice}
                        setValue={(value) =>
                          handleVehicleChange(`homingDevice`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                    <div className="relative">
                      <ReactSelectField
                        title="VIN Etching"
                        placeholder="VIN Etching"
                        isMulti={false}
                        value={vehicle?.vinEtching}
                        setValue={(value) =>
                          handleVehicleChange(`vinEtching`, value, index)
                        }
                        loadOptions={loadBinaryOptions}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="text-sm font-medium py-1 px-4 mx-2 bg-red-500  rounded-t-md text-white flex my-2">
                    <div className="flex-1">Carrier Questions</div>
                  </div>
                  {vehicle?.carrierQuestion &&
                  Object.keys(vehicle.carrierQuestion).length > 0 ? (
                    Object.keys(vehicle?.carrierQuestion).map(
                      (carrier, cindex) => (
                        <div className="px-4" key={cindex}>
                          <div className="pl-2 my-1">
                            <span className="text-sm bg-red-500 text-white px-2 py-1 rounded-md">
                              {carrier}
                            </span>
                          </div>
                          <span className="grid grid-cols-2">
                            {vehicle?.carrierQuestion[carrier]?.map(
                              (q, qindex) => (
                                <div
                                  className="grid grid-cols-12 my-2 mx-2"
                                  key={qindex}
                                >
                                  <div className="flex  items-center col-span-6">
                                    {q?.question}
                                  </div>
                                  {!q?.mask && (
                                    <div className="relative mx-4  col-span-6">
                                      <ReactSelectField
                                        // title="Car Type"
                                        // placeholder="Car Type"
                                        isMulti={false}
                                        value={q?.selectedValue}
                                        setValue={(value) =>
                                          handleQuestionChange(
                                            value,
                                            qindex,
                                            carrier,
                                            q.question,
                                            index,
                                          )
                                        }
                                        loadOptions={() => ({
                                          options: q?.options,
                                          hasMore: false,
                                        })}
                                        cacheUniqs={q?.options}
                                        isSearchable={true}
                                      />
                                    </div>
                                  )}
                                </div>
                              ),
                            )}
                          </span>
                        </div>
                      ),
                    )
                  ) : (
                    <div className="text-center p-6">
                      No carrier questions found
                    </div>
                  )}
                </FormTab>
              ))}
          </>
        </CollapsableRef>
      </div>
    )
  },
)

VehicleForm.displayName = `VehicleForm`
