import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { Container, PageHeader } from 'App/Styled'
import { TableHeader, TableBody, TableRow, TableCell } from '@windmill/react-ui'
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { Spinner } from 'App/Components/Common/Spinner'
import dateFormat from 'App/Services/General/dateFormat'

const tableColumns = [
  { label: `Invoice Id`, value: `_invoiceId` },
  { label: `Policy #`, value: `policyNo` },
  { label: `Agent Name`, value: `agentName` },
  { label: `Amount`, value: `amount` },
  { label: `Schedule Date`, value: `scheduledDate` },
  { label: `Language`, value: `language` },
]

export const SchedulePayments = ({ customerId }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)

  const {
    isLoading,
    data: schedulePaymentsData,
    error,
    refetch: getAllSchedulePayments,
    isFetching,
  } = useQuery(
    `schedulePaymentsDataApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/invoices/schedulePayment?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }"}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${offset}&customerId=${customerId}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllSchedulePayments()
    }
  }, [sort, currentPage])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const callInvoicesQuery = useDebouncedCallback(() => {
    getAllSchedulePayments()
  }, 2500)

  return (
    <Container shadow="yes">
      <PageHeader bgcolor="bg-white">
        <div className="flex items-center">
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              setValue={(e) => {
                setSearchQuery(e.target.value)
                setCurrentPage(1)
                setCustomSearching(true)
                callInvoicesQuery()
              }}
            />
          </div>
        </div>
      </PageHeader>

      <Spinner loading={isLoading || isFetching} />

      {error ? (
        <Reload refetch={() => getAllSchedulePayments()} />
      ) : (
        <div
          className={`my-1 sm:mb-0 justify-between ${
            (isLoading || isFetching) && ` opacity-30 pointer-events-none`
          } `}
        >
          <div className="overflow-x-auto  rounded-t-md">
            <table className="w-full border-collapse">
              <TableHeader>
                <TableRow>
                  {tableColumns.map((item, idx) => {
                    return (
                      <TableCell key={idx}>
                        <div className="cursor-pointer select-none text-sm flex justify-center truncate">
                          <div
                            onClick={() => {
                              handleSorting(item.value)
                            }}
                            className={`flex ${
                              item.value === sort ? `text-red-500` : ``
                            }`}
                          >
                            {item.label}
                          </div>
                          <div
                            className="ml-1 rounded-sm cursor-pointer flex relative"
                            onClick={() => handleSorting(`-` + item.value)}
                          >
                            <FontAwesomeIcon
                              icon={
                                sort.includes(`-`) && sort == `-` + item.value
                                  ? faSortUp
                                  : faSortDown
                              }
                              size="lg"
                              className={`${
                                sort.includes(`-`) && sort == `-` + item.value
                                  ? `text-red-500`
                                  : `-mt-1.5`
                              }`}
                            />
                          </div>
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHeader>
              <TableBody>
                {schedulePaymentsData &&
                schedulePaymentsData.payments &&
                schedulePaymentsData.payments.length !== 0 ? (
                  schedulePaymentsData.payments.map((invoice, index) => (
                    <TableRow
                      key={index.toString()}
                      className="hover:bg-gray-100 cursor-pointers"
                      // Uncomment the following lines if you need to handle row click and permission checks
                      // onClick={() => {
                      //   if (permissionGranted([
                      //     'payments:read',
                      //     'corporate-manager:*',
                      //     'manager:*',
                      //     'admin:*',
                      //   ])) {
                      //     history.push(`${url}/payments/${invoice._id}`)
                      //   }
                      // }}
                    >
                      <td className="py-2.5 text-sm text-center z-0 break-words max-w-px-140 capitalize truncate">
                        {invoice?._invoiceId || `N/A`}
                      </td>
                      <td className="py-2.5 text-sm text-center z-0 break-words max-w-px-140 capitalize truncate">
                        {invoice?.policyNo || `N/A`}
                      </td>
                      <td className="py-2.5 text-sm text-center z-0 break-words max-w-px-140 capitalize truncate">
                        {invoice?.agentName
                          ? invoice?.agentName.toLowerCase()
                          : `N/A`}
                      </td>
                      <td className="py-2.5 text-sm text-center z-0 break-words max-w-px-140 capitalize truncate">
                        ${invoice?.amount || `N/A`}
                      </td>
                      <td className="py-2.5 text-sm text-center z-0 truncate">
                        {dateFormat(invoice?.scheduledDate) || `N/A`}
                      </td>
                      <td className="py-2.5 text-sm text-center z-0 truncate">
                        {invoice?.language
                          ? invoice?.language.charAt(0).toUpperCase() +
                            invoice?.language.slice(1)
                          : `N/A`}
                      </td>
                    </TableRow>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center p-6">
                      <div>No Schedule Payments Found</div>
                    </td>
                  </tr>
                )}
              </TableBody>
            </table>
          </div>
          <div className="px-4 py-2">
            <ReactPagination
              offset={offset}
              currentPage={currentPage}
              totalPages={schedulePaymentsData?.totalPages}
              totalRecords={schedulePaymentsData?.totalRecords}
              curerntPageRecords={schedulePaymentsData?.currentPageRecords}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      )}
    </Container>
  )
}
