export const loadStates = () => {
  const options = {
    options: [
      { value: `CA`, label: `CALIFORNIA` },
      { value: `FL`, label: `FLORIDA` },
      { value: `TX`, label: `TEXAS` },
    ],
    hasMore: false,
  }
  return options
}
