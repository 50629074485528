/* eslint-disable no-unused-vars */
import {
  faEnvelope,
  faMapMarkerAlt,
  faPen,
  faPhoneAlt,
  faSpinner,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Badge,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@windmill/react-ui'
import { CardField } from 'App/Components/Common/CardField'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { setCurrentAllMonthlyGoals } from 'App/Redux/actions'
import { permissionGranted } from 'App/Services'
import dateFormat from 'App/Services/General/dateFormat'
import { convertCamelCaseToString } from 'App/Services/utils/convertCamelCase'
import { Container, EditStyledButton, PageHeader } from 'App/Styled'
import React from 'react'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

const tableColumnsLeads = [
  { label: `ID`, value: `leadId` },
  { label: `First Name`, value: `customerfirstName` },
  { label: `Last Name`, value: `customerlastName` },
  { label: `Status`, value: `verificationStatus` },
]
const tableColumnsPayment = [
  { label: `ID`, value: `_invoiceId` },
  { label: `Amount`, value: `amount` },
  { label: `Status`, value: `verificationStatus` },
]
const tableColumnsAgents = [
  { label: `Name`, value: `name` },
  { label: `Policy`, value: `policy` },
  { label: `Leads`, value: `leads` },
  { label: `Payment`, value: `payment` },
]
const tableColumnsPolicy = [
  { label: `Policy No`, value: `policyNo` },
  { label: `Effective Date`, value: `effectiveDate` },
  { label: `Expiry Date`, value: `expiryDate` },
  { label: `Status`, value: `verificationStatus` },
]

export const AboutMonthlyGoal = () => {
  const {
    url,
    params: { id },
  } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const { sub: userId } = useSelector(({ user }) => user?.user?.profile)

  const {
    data: monthlyGoalPreviewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `monthlyGoalPreviewApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/goals/${id}`).then(
        (res) => {
          return res?.data?.data?.goal
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentAllMonthlyGoals(res))
      },
    },
  )
  useEffect(() => {
    refetch()
  }, [id])

  return (
    <Container>
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <>
          <div className="flex flex-row m-3  ">
            <div className="flex-1">
              <div className="rounded-md overflow-hidden py-4">
                <div className="flex items-start justify-evenly">
                  <div className="flex-1 mx-2 rounded">
                    <div className="grid grid-cols-12 px-2 py-2">
                      <div className="col-span-6 text-xl">Goal Info</div>
                      <div className="col-span-6 place-self-end">
                        {permissionGranted([
                          `monthly-goals:update`,
                          `corporate-manager:*`,
                          `manager:*`,
                          `admin:*`,
                        ]) && (
                          <EditStyledButton
                            onClick={() => {
                              if (
                                userId ==
                                monthlyGoalPreviewData?.createdBy?.value
                              )
                                history.push(`${url}/update`)
                            }}
                            disabled={
                              userId !==
                              monthlyGoalPreviewData?.createdBy?.value
                            }
                          >
                            <span>Edit</span>
                            <FontAwesomeIcon
                              icon={faPen}
                              className="text-white mt-0.5"
                              size="sm"
                            />
                          </EditStyledButton>
                        )}
                      </div>
                    </div>
                    <hr />

                    <div className="flex flex-col md:flex-row px-2">
                      <div className="flex-1">
                        <CardField
                          title="Title"
                          value={monthlyGoalPreviewData?.title}
                        />
                        <CardField
                          title="Total Payment"
                          value={monthlyGoalPreviewData?.targetPayment}
                        />
                        <CardField
                          title="Total Leads"
                          value={monthlyGoalPreviewData?.targetLeads}
                        />
                        <CardField
                          title="Total Policies"
                          value={monthlyGoalPreviewData?.targetPolicies}
                        />
                        <CardField
                          title="Updated"
                          value={dateFormat(monthlyGoalPreviewData?.updatedAt)}
                        />
                        {monthlyGoalPreviewData?.totalAgentsProgress?.verifiedLeads?.toString() && (
                          <CardField
                            title="Total Verified Leads"
                            value={(monthlyGoalPreviewData?.totalAgentsProgress?.verifiedLeads).toString()}
                          />
                        )}
                        {monthlyGoalPreviewData?.totalAgentsProgress?.verifiedPayment?.toString() && (
                          <CardField
                            title="Total Verified Payment"
                            value={(monthlyGoalPreviewData?.totalAgentsProgress?.verifiedPayment).toString()}
                          />
                        )}
                        {monthlyGoalPreviewData?.totalAgentsProgress?.verifiedPolicies?.toString() && (
                          <CardField
                            title="Total Verified Leads"
                            value={(monthlyGoalPreviewData?.totalAgentsProgress?.verifiedPolicies).toString()}
                          />
                        )}
                      </div>
                      <div className="flex-1">
                        <CardField
                          title="Status"
                          value={
                            <Badge
                              type={`${
                                monthlyGoalPreviewData?.goalStatus === `STARTED`
                                  ? `primary`
                                  : `success`
                              }`}
                            >
                              {monthlyGoalPreviewData?.goalStatus}
                            </Badge>
                          }
                        />

                        <CardField
                          title="Created"
                          value={dateFormat(monthlyGoalPreviewData?.createdAt)}
                        />
                        <CardField
                          title="Assigned To"
                          value={monthlyGoalPreviewData?.assignedTo?.label}
                        />
                        <CardField
                          title="Duration"
                          value={
                            dateFormat(monthlyGoalPreviewData?.startDate) +
                            `\t  -  \t ` +
                            dateFormat(monthlyGoalPreviewData?.endDate)
                          }
                        />
                        {monthlyGoalPreviewData?.commission ? (
                          <CardField
                            title="Commission"
                            value={`$${monthlyGoalPreviewData?.commission}`}
                          />
                        ) : null}
                        {monthlyGoalPreviewData?.commissionPercentage ? (
                          <CardField
                            title="Commission Percentage"
                            value={monthlyGoalPreviewData?.commissionPercentage}
                          />
                        ) : null}
                      </div>
                    </div>

                    {monthlyGoalPreviewData?.agentsProgress && (
                      <>
                        <div className="col-span-6 text-xl">Progress</div>
                        <hr />
                        <div className="grid grid-cols-3">
                          {Object.keys(
                            monthlyGoalPreviewData?.agentsProgress,
                          ).map((item) => (
                            <CardField
                              key={item}
                              title={convertCamelCaseToString(item)}
                              value={monthlyGoalPreviewData?.agentsProgress[
                                item
                              ]?.toString()}
                            />
                          ))}
                        </div>
                      </>
                    )}

                    {monthlyGoalPreviewData?.agentsFranchiseGoals?.length >
                      0 && (
                      <>
                        <div className="col-span-6 text-xl">
                          Agents Progress
                        </div>
                        <hr />

                        <div className="overflow-x-auto rounded-t-md mt-1">
                          <table className="w-full border-collapse bg-slate-50">
                            <TableHeader>
                              <TableRow>
                                {tableColumnsAgents.map((item, idx) => {
                                  return (
                                    <TableCell key={idx}>
                                      <div className="cursor-pointer select-none text-sm flex">
                                        {item.label}
                                      </div>
                                    </TableCell>
                                  )
                                })}
                              </TableRow>
                            </TableHeader>
                            <TableBody>
                              {monthlyGoalPreviewData?.agentsFranchiseGoals?.map(
                                (agents, index) => (
                                  <TableRow
                                    key={index}
                                    className={`hover:bg-gray-100 `}
                                  >
                                    <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                                      {agents?.assignedTo?.label}
                                    </td>

                                    <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                                      {agents?.agentsProgress?.verifiedPolicies}
                                      /{agents?.targetPolicies}
                                    </td>
                                    <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                                      {agents?.agentsProgress?.verifiedLeads}/
                                      {agents?.targetLeads}
                                    </td>
                                    <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                                      ${agents?.agentsProgress?.verifiedPayment}
                                      / ${agents?.targetPayment}
                                    </td>
                                  </TableRow>
                                ),
                              )}
                            </TableBody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            {monthlyGoalPreviewData?.leads?.length > 0 && (
              <div className="mx-4">
                <div className="col-span-6 text-xl">Leads</div>
                <hr />
                <table className="w-full border-collapse border-2">
                  <TableHeader>
                    <TableRow>
                      {tableColumnsLeads.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className="cursor-pointer select-none text-sm flex">
                              {item.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {monthlyGoalPreviewData?.leads?.map((lead, index) => (
                      <TableRow
                        key={index}
                        className={`hover:bg-gray-100 cursor-pointer`}
                        onClick={() => {
                          history.push(`/crm/leads/${lead?._id}`)
                        }}
                      >
                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          {lead?.leadId}
                        </td>
                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          {lead?.customerfirstName}
                        </td>
                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          {lead?.customerlastName}
                        </td>
                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          <Badge
                            type={
                              lead?.verificationStatus?.includes(`SUCCESS`)
                                ? `success`
                                : lead?.verificationStatus?.includes(`REJECT`)
                                ? `danger`
                                : `warning`
                            }
                          >
                            {lead?.verificationStatus}
                          </Badge>
                        </td>
                      </TableRow>
                    ))}
                  </TableBody>
                </table>
              </div>
            )}

            {monthlyGoalPreviewData?.policies?.length > 0 && (
              <div className="m-4">
                <div className="col-span-6 text-xl">Policies</div>
                <hr />
                <table className="w-full border-collapse border-1">
                  <TableHeader>
                    <TableRow>
                      {tableColumnsPolicy.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className="cursor-pointer select-none text-sm flex">
                              {item.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {monthlyGoalPreviewData?.policies?.map((policy, index) => (
                      <TableRow
                        key={index}
                        className={`hover:bg-gray-100 cursor-pointer`}
                        onClick={() => {
                          history.push(`/policies/${policy?._id}`)
                        }}
                      >
                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          {policy?.policyNo}
                        </td>
                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          {dateFormat(policy?.effectiveDate)}
                        </td>
                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          {dateFormat(policy?.expiryDate)}
                        </td>
                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          <Badge
                            type={
                              policy?.verificationStatus?.includes(`SUCCESS`)
                                ? `success`
                                : policy?.verificationStatus?.includes(`REJECT`)
                                ? `danger`
                                : `warning`
                            }
                          >
                            {policy?.verificationStatus}
                          </Badge>
                        </td>
                      </TableRow>
                    ))}
                  </TableBody>
                </table>
              </div>
            )}
            {monthlyGoalPreviewData?.payment?.length > 0 && (
              <div className="m-4">
                <div className="col-span-6 text-xl">Payment</div>
                <hr />
                <table className="w-full border-collapse border-1">
                  <TableHeader>
                    <TableRow>
                      {tableColumnsPayment.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className="cursor-pointer select-none text-sm flex">
                              {item.label}
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {monthlyGoalPreviewData?.payment?.map((payment, index) => (
                      <TableRow
                        key={index}
                        className={`hover:bg-gray-100 cursor-pointer`}
                        onClick={() => {
                          history.push(
                            `/customers/${payment?._insured}/payments/${payment?._id}`,
                          )
                        }}
                      >
                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          {payment?._invoiceId}
                        </td>
                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          {payment?.totalAmount}
                        </td>

                        <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                          <Badge
                            type={
                              payment?.verificationStatus?.includes(`SUCCESS`)
                                ? `success`
                                : payment?.verificationStatus?.includes(
                                    `REJECT`,
                                  )
                                ? `danger`
                                : `warning`
                            }
                          >
                            {payment?.verificationStatus}
                          </Badge>
                        </td>
                      </TableRow>
                    ))}
                  </TableBody>
                </table>
              </div>
            )}
          </div>
        </>
      )}
    </Container>
  )
}
