import React from 'react'
import { TOne, TThree, TTwo } from 'App/Styled'
import { useDispatch, useSelector } from 'react-redux'
import { setActivityActiveTab } from 'App/Redux/actions'
import { CustomerNote } from 'App/Components/Customers/CustomerNote'
import { Communications } from 'App/Components/Communications'
import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { permissionGranted } from 'App/Services'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  {
    name: `Logs`,
    number: 2,
  },
  {
    name: `Customer Communications`,
    number: 3,
  },
]
const activityTabs2 = [
  {
    name: `Logs`,
    number: 1,
  },
  {
    name: `Customer Communications`,
    number: 2,
  },
]

export const Activity = () => {
  const { currentCustomer, activeActivityTab } = useSelector(
    ({ customer }) => customer,
  )
  const dispatch = useDispatch()
  return (
    <div className="px-2 z-0">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([
            `notes:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ])
            ? activityTabs
            : activityTabs2
        }
        getActiveTab={(val) => dispatch(setActivityActiveTab(val))}
      />
      {permissionGranted(
        [`notes:read`, `corporate-manager:*`, `manager:*`, `admin:*`] || [
          `notes:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      ) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <CustomerNote />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <UpdateLogs
              logs={
                currentCustomer?.updateLogs ? currentCustomer?.updateLogs : []
              }
            />
          </TTwo>

          <TThree opentab={activeActivityTab}>
            <Communications />
          </TThree>
        </div>
      ) : (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <UpdateLogs
              logs={
                currentCustomer?.updateLogs ? currentCustomer?.updateLogs : []
              }
            />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <Communications />
          </TTwo>
        </div>
      )}
    </div>
  )
}
