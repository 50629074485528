import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { useMutation } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { loadZipCodes, ValidateZip } from 'App/Services'
import { useHistory, useRouteMatch } from 'react-router'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { MaskField } from 'App/Components/Common/MaskField'
import { InputField } from 'App/Components/Common/InputField'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import { useDebouncedCallback } from 'use-debounce/lib'
import { loadRelationship } from 'App/Services/quoteForm'
import { NotificationManager } from 'react-notifications'

export const UpdateContact = () => {
  const { customerContacts } = useSelector(({ asset }) => asset)

  const [contact, setContact] = useState({
    firstName: ``,
    lastName: ``,
    relation: ``,
    phone: ``,
    workPhone: ``,
    email: ``,
    isDriver: false,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    streetNo: ``,
    city: ``,
    zip: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    firstNameError: ``,
    lastNameError: ``,
    phoneError: ``,
    relationError: ``,
    workPhoneError: ``,
    emailError: ``,
    unitNoError: ``,
    streetNoError: ``,
    cityError: ``,
    zipError: ``,
    stateError: ``,
    coordinatesError: ``,
  })

  const history = useHistory()
  const {
    params: { customerId, contactId },
  } = useRouteMatch()

  const handleCustomerZipChange = async (e) => {
    const data = await ValidateZip(e?.value)
    if (data) {
      const res = data.address
      setAddress((prevState) => ({
        ...prevState,
        city: res.city,
        state: res.state,
        zip: { value: res.zipCode, label: res.zipCode },
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        lat: res.lat,
        lng: res.long,
      }))
    }
  }

  const handleContactChange = useDebouncedCallback((name, value) => {
    setContact((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleAddressChange = useDebouncedCallback((name, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const {
    mutate: updateContact,
    isLoading: updatingContact,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/contacts/${contactId}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          res.data &&
            history.push(`/customers/${customerId}/contact/${contactId}`)
        })
        .catch((err) => {
          const error = err?.response?.data.error?.error
          if (error?.includes(`Incoming request body can't be empty.`)) {
            NotificationManager.error(`No field was changed!`)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  //validate form on click
  const ValidateForm = () => {
    if (!contact.firstName.length) {
      handleErrors(`firstNameError`, `Enter First Name`)
    } else if (contact.firstName.length && contact.firstName.length < 3) {
      handleErrors(`firstNameError`, `First Name Length must be 3`)
    } else handleErrors(`firstNameError`, ``)

    if (!contact.lastName.length) {
      handleErrors(`lastNameError`, `Enter last Name`)
    } else if (contact.lastName.length && contact.lastName.length < 3) {
      handleErrors(`lastNameError`, `last Name Length must be 3`)
    } else handleErrors(`lastNameError`, ``)

    if (!contact.phone.length) {
      handleErrors(`phoneError`, `Enter Phone`)
    } else if (contact.phone.length && contact.phone.length < 12) {
      handleErrors(`phoneError`, `Enter Valid Phone`)
    } else handleErrors(`phoneError`, ``)

    if (contact?.workPhone?.length && contact?.workPhone?.length < 12) {
      handleErrors(`workPhoneError`, `Enter Valid Phone`)
    } else handleErrors(`workPhoneError`, ``)

    if (contact?.email?.length) {
      if (!regEx.test(contact?.email?.toLowerCase())) {
        handleErrors(`emailError`, `Enter Valid Email`)
      } else {
        handleErrors(`emailError`, ``)
      }
    } else {
      handleErrors(`emailError`, ``)
    }

    if (!address.unitNo.length) {
      handleErrors(`unitNoError`, `Enter Unit No`, true)
    } else {
      handleErrors(`unitNoError`, ``, true)
    }

    if (!address.zip?.value?.length) {
      handleErrors(`zipError`, `Select Zip`, true)
    } else {
      handleErrors(`zipError`, ``, true)
    }

    if (!contact?.relation?.value?.length) {
      handleErrors(`relationError`, `Select Relation`)
    } else handleErrors(`relationError`, ``)

    if (!address.streetNo.length) {
      handleErrors(`streetNoError`, `Enter Street`, true)
    } else {
      handleErrors(`streetNoError`, ``, true)
    }

    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`, true)
    } else {
      handleErrors(`cityError`, ``, true)
    }

    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`, true)
    } else {
      handleErrors(`stateError`, ``, true)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, `Enter Coordinates`, true)
    } else {
      handleErrors(`coordinatesError`, ``, true)
    }

    handleErrors(`formSubmit`, true)
  }
  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.firstNameError?.length &&
      !errors.lastNameError?.length &&
      !errors.relationError?.length &&
      !errors.phoneError?.length &&
      !errors.emailError?.length &&
      !errors.unitNoError?.length &&
      !errors.zipError?.length &&
      !errors.streetNoError?.length &&
      !errors.cityError?.length &&
      !errors.stateError?.length &&
      !errors.coordinatesError?.length
    ) {
      //call API
      updateContact(updatePayload())
      setErrors((prevState) => ({
        ...prevState,
        [`formSubmit`]: false,
      }))
    }
  }, [errors])
  useEffect(() => {
    if (customerContacts) {
      setContact((prevState) => ({
        ...prevState,
        firstName: customerContacts.firstName,
        lastName: customerContacts.lastName,
        phone: customerContacts.phone,
        workPhone: customerContacts.workPhone,
        email: customerContacts.email,
        relation: {
          value: customerContacts.relation,
          label: customerContacts.relation,
        },
        isDriver: customerContacts.isDriver,
      }))
      setAddress((prevState) => ({
        ...prevState,
        unitNo: customerContacts.address.unitNo,
        streetNo: customerContacts.address.street,
        city: customerContacts.address.city,
        zip: {
          value: customerContacts.address.zip,
          label: customerContacts.address.zip,
        },
        state: customerContacts.address.state,
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        lat: customerContacts.address?.coordinates?.lat,
        lng: customerContacts.address?.coordinates?.long,
      }))
    }
  }, [customerContacts])

  const updatePayload = () => {
    let data = {}

    data._module = {}

    data._module.value = customerId
    data._module.label = `customer`

    if (contact.firstName !== customerContacts.firstName) {
      data.firstName = contact.firstName
    }
    if (contact.lastName !== customerContacts.lastName) {
      data.lastName = contact.lastName
    }
    if (contact.phone !== customerContacts.phone) {
      data.phone = contact.phone
    }
    if (contact.workPhone !== customerContacts.workPhone) {
      data.workPhone = contact.workPhone
    }
    if (contact.email !== customerContacts.email) {
      data.email = contact.email
    }
    if (contact.relation.label !== customerContacts.relation) {
      data.relation = contact.relation.value
    }

    if (contact.isDriver !== customerContacts.isDriver) {
      data.isDriver = contact.isDriver
    }

    // address payload
    const addressPayload = {}
    let coordinatesPayload = {}

    if (address.unitNo !== customerContacts.address.unitNo) {
      addressPayload.unitNo = address.unitNo
    }
    if (address.streetNo !== customerContacts.address.street) {
      addressPayload.street = address.streetNo
    }
    if (address.city !== customerContacts.address.city) {
      addressPayload.city = address.city
    }
    if (address?.zip?.value !== customerContacts.address.zip) {
      addressPayload.zip = address?.zip?.value
      coordinatesPayload.lat = coordinates.lat.toString()
      coordinatesPayload.long = coordinates.lng.toString()
    }
    if (address.state !== customerContacts.address.state) {
      addressPayload.state = address.state
    }
    if (Object.keys(addressPayload).length !== 0) {
      addressPayload.coordinates = coordinatesPayload
      data.address = addressPayload
    }
    return data
  }
  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  return (
    <Container className="relative pb-4">
      <PageHeader>
        <div>Update Contact</div>
      </PageHeader>
      {updationError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <Spinner loading={updatingContact} />
      <div
        className={`p-3 ${
          updatingContact && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="First Name *"
              placeholder="Enter Name First"
              errorMessage={errors.firstNameError}
              value={contact.firstName}
              setValue={(e) => handleContactChange(`firstName`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Last Name *"
              placeholder="Enter Name Last"
              errorMessage={errors.lastNameError}
              value={contact.lastName}
              setValue={(e) => handleContactChange(`lastName`, e.target.value)}
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="Relation *"
              placeholder="Select relation"
              errorMessage={errors.relationError}
              value={contact?.relation}
              setValue={(value) => handleContactChange(`relation`, value)}
              loadOptions={loadRelationship}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Phone *"
              placeholder="Enter Phone No"
              errorMessage={errors.phoneError}
              value={contact.phone}
              setValue={(e) => handleContactChange(`phone`, e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Work Phone"
              placeholder="Enter work phone"
              errorMessage={errors.workPhoneError}
              value={contact.workPhone}
              setValue={(e) => handleContactChange(`workPhone`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="email"
              title="Email"
              placeholder="Enter Email"
              errorMessage={errors.emailError}
              value={contact.email}
              setValue={(e) => handleContactChange(`email`, e.target.value)}
            />
          </div>
          <div className="flex-1 mt-5 relative">
            <Checkbox
              title="Is Driver"
              name="isDriver"
              value={contact.isDriver}
              setValue={(e) =>
                setContact((prevState) => ({
                  ...prevState,
                  [e.target.name]: !contact.isDriver,
                }))
              }
            />
          </div>
          <div className="flex-1 mt-5 relative"></div>
        </div>

        <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Address
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              placeholder="Enter Unit No"
              errorMessage={errors.unitNoError}
              value={address.unitNo}
              setValue={(e) => handleAddressChange(`unitNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip"
              placeholder="Select Zip"
              errorMessage={errors.zipError}
              isMulti={false}
              value={address.zip}
              setValue={(e) => handleCustomerZipChange(e)}
              loadOptions={loadZipCodes}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street"
              placeholder="Enter Street"
              errorMessage={errors.streetNoError}
              value={address.streetNo}
              setValue={(e) => handleAddressChange(`streetNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="City"
              placeholder="Enter City"
              errorMessage={errors.cityError}
              disabled={true}
              name="city"
              value={address.city}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="State"
              placeholder="Enter State"
              errorMessage={errors.stateError}
              disabled={true}
              name="state"
              value={address.state}
            />
          </div>
        </div>
        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError.length !== 0 && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView center={[coordinates.lat, coordinates.lng]} zoom={3} />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => handleContactChange(e, true)}
            />
          </MapContainer>
        </div>
      </div>
      <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
        <SubmitButton onClick={ValidateForm} disabled={updatingContact}>
          Update Contact
        </SubmitButton>
      </div>
    </Container>
  )
}
