import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const ValidateDuplicateFranchise = async (key, value) => {
  if (value) {
    return await AxiosInstance.post(
      `${AMS_BACKEND_API}/api/validate/franchise-payloads`,
      {
        [key]: value,
      },
    )
      .then((response) => {
        return response?.data
      })
      .catch((error) => {
        return error?.response?.data
      })
  }
}
