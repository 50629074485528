import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TOne, TTwo } from 'App/Styled'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { setGroupCarrierActivityActiveTab } from 'App/Redux/actions'
import { permissionGranted } from 'App/Services'
import { GroupCarrierNote } from '../../GroupCarrierNote'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  {
    name: `Logs`,
    number: 2,
  },
]
const activityTabs2 = [
  {
    name: `Logs`,
    number: 1,
  },
]

export const GroupCarrierActivity = () => {
  const { currentGroupCarrier, groupCarrierActiveActivityTab } = useSelector(
    ({ carrier }) => carrier,
  )
  const dispatch = useDispatch()
  return (
    <div className="px-2">
      <Tabs2
        openTab={groupCarrierActiveActivityTab}
        tabs={
          permissionGranted([
            `notes:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ])
            ? activityTabs
            : activityTabs2
        }
        getActiveTab={(val) => dispatch(setGroupCarrierActivityActiveTab(val))}
      />
      {permissionGranted([
        `notes:read`,
        `corporate-manager:*`,
        `manager:*`,
        `admin:*`,
      ]) ? (
        <div className="tab-content tab-space">
          <TOne opentab={groupCarrierActiveActivityTab}>
            <GroupCarrierNote />
          </TOne>

          <TTwo opentab={groupCarrierActiveActivityTab}>
            <UpdateLogs
              logs={
                currentGroupCarrier?.group?.updateLogs
                  ? currentGroupCarrier?.group?.updateLogs
                  : []
              }
            />
          </TTwo>
        </div>
      ) : (
        <div className="tab-content tab-space">
          <TOne opentab={groupCarrierActiveActivityTab}>
            <UpdateLogs
              logs={
                currentGroupCarrier?.group?.updateLogs
                  ? currentGroupCarrier?.group?.updateLogs
                  : []
              }
            />
          </TOne>
        </div>
      )}
    </div>
  )
}
