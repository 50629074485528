/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react'
import { defaultImage } from 'App/Assets'
import { useDispatch, useSelector } from 'react-redux'
import {
  setChatLatestLength,
  setLatestLength,
  setSwitchLocation,
  setTwilioDevice,
} from 'App/Redux/actions'
import {
  ProfileMenuItem,
  UserImage,
  DropDown,
  SecondayProfileMenuItem,
} from './style'
import {
  setCurrentIncomingCallStatus,
  setGlobalCallModalActive,
} from 'App/Redux/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faBell,
  faPowerOff,
  faListAlt,
  faPhoneVolume,
  faPhone,
  faEnvelope,
  faArrowAltCircleRight,
  faLocationArrow,
} from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { CallBlock } from 'App/Styled'
import { newSocket } from 'App/HOC/MainLayout'
import { ReactModal } from 'App/Components/Common/Modal'
import { Call } from 'App/Components/Communications/Call'
import { Device } from '@twilio/voice-sdk'
import {
  AMS_BACKEND_API,
  APP_BASEURL,
  AxiosInstance,
  IDP_FRONTEND,
} from 'App/Config'
import { useMutation, useQuery } from 'react-query'
import { MonthlyGoalsMenu } from '../MonthlyGoalsMenu'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useNotification } from '../useNotifications'
import { PreferredLocation } from '../PreferredLocation'
import { setPreferredLocation } from 'App/Redux/actions'
import { isSuperUser, permissionGranted } from 'App/Services'
import BreadCrumbs from '../BreadCrumbs'
import { AllNotification } from '../AllNotifications'
import { setGeneralNotificationsLength } from 'App/Redux/Slices/Notifications'
import { NotificationManager } from 'react-notifications'
// import { PaymentCall } from 'App/Components/Communications/PaymentCall'

export let device
export const Topbar = () => {
  useNotification()

  const [allNotificationsModal, setAllNotificationsModal] = useState(false)
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const [goalsMenuOpen, setGoalsMenuOpen] = useState(false)
  const [pushNotificationOpen, setPushNotificationOpen] = useState(false)
  const [chatNotificationOpen, setChatNotificationOpen] = useState(false)
  const [incomingCall, setIncomingCall] = useState(``)
  const [switchLocation, setSwitchLocationModal] = useState(false)
  // const [oldToken, setOldToken] = useState(``)
  const [latestToken, setLatestToken] = useState(``)
  const [device, setDeviceConnected] = useState(``)
  const [globalIncomingCall, setGlobalIncomingCall] = useState(false)
  const [callerInfo, setCallerInfo] = useState(``)
  const [globalIncomingCallModel, setGlobalIncomingCallModel] = useState(false)
  const { user, preferredLocation } = useSelector(({ user }) => user)
  const { latestLength, chatLatestLength, generalNotificationsLength } =
    useSelector(({ notification }) => notification)
  const { unknownCaller } = useSelector(
    ({ globalCommunications }) => globalCommunications,
  )

  const history = useHistory()
  const menuRef = useRef(null)
  const monthlyGoalRef = useRef(null)
  const pushNotificationRef = useRef(null)
  const chatNotificationRef = useRef(null)
  const notificationsRef = useRef(null)

  // const oidcAuth = useAuth()
  const dispatch = useDispatch()

  const currentPaymentInvoice = useSelector(
    (state) => state?.invoice?.currentInvoice,
  )

  const { globalCallModalActive, activeCall } = useSelector(
    ({ customer }) => customer,
  )
  const { currentIncomingCall, currentActiveCall } = useSelector(
    ({ currentCommunication }) => currentCommunication,
  )

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setProfileMenuOpen(false)
      }
      // if (appMenuRef.current && !appMenuRef.current.contains(event.target)) {
      //   setCampaign(false)
      // }
    }
    document.addEventListener(`click`, handleClickOutside)
  }, [menuRef])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        monthlyGoalRef.current &&
        !monthlyGoalRef.current.contains(event.target)
      )
        setGoalsMenuOpen(false)
    }
    document.addEventListener(`click`, handleClickOutside)
  }, [goalsMenuOpen])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        pushNotificationRef.current &&
        !pushNotificationRef.current.contains(event.target)
      )
        setPushNotificationOpen(false)
    }
    document.addEventListener(`click`, handleClickOutside)
  }, [pushNotificationOpen])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        chatNotificationRef.current &&
        !chatNotificationRef.current.contains(event.target)
      )
        setChatNotificationOpen(false)
    }
    document.addEventListener(`click`, handleClickOutside)
  }, [chatNotificationOpen])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      )
        setAllNotificationsModal(false)
    }
    document.addEventListener(`click`, handleOutsideClick)
    return () => {
      document.removeEventListener(`click`, handleOutsideClick)
    }
  }, [allNotificationsModal])

  /* Sockets and twilio device setup */

  const { mutate: GenerateTokenApi } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/customers-chat/token/generate`,
        {
          ...payload,
        },
      ).then((res) => {
        return res?.data?.data?.token
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (token) => {
        if (latestToken) {
          if (token !== device?._token) {
            device.updateToken(token)
            // setOldToken(token)
          }
        } else {
          setLatestToken(token)
        }
      },
    },
  )

  useEffect(() => {
    preferredLocation?._id &&
      GenerateTokenApi(
        permissionGranted([
          `customer-call:*`,
          `call-center-user:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ])
          ? { clientId: preferredLocation?._id }
          : { clientId: `` },
      )
  }, [preferredLocation?._id])

  useEffect(() => {
    if (latestToken) {
      const _device = new Device(latestToken)
      setDeviceConnected(_device)
      dispatch(setTwilioDevice(_device))
    }
  }, [latestToken])

  useEffect(() => {
    incomingCall &&
      setCallerInfo(
        JSON?.parse(incomingCall?.customParameters?.get(`callInfo`)),
      )
  }, [incomingCall])
  useEffect(() => {
    if (device && currentActiveCall?.status !== `in-progress`) {
      device.on(`incoming`, (call) => {
        if (!device?.isBusy) {
          setIncomingCall(call)
        } else {
          call.reject()
        }
        setGlobalIncomingCallModel(true)
        setGlobalIncomingCall(true)
      })
    }

    if (device) {
      if (device.state === `unregistered`) {
        device.register()
      }
      device.on(`registered`, () => {
        console.log(`Twilio.Device Registered: `)
      })
      device?.on(`tokenWillExpire`, () => {
        preferredLocation?._id &&
          GenerateTokenApi({ clientId: preferredLocation?._id })
      })

      device?.on(`error`, (error) => {
        console.log(`Twilio.Device Error: ` + error.message)
      })
    }
  }, [device])

  useEffect(() => {
    if (user?.profile?.sub)
      document.cookie = `userId=${user?.profile?.sub}; path=/`
  }, [])
  useEffect(() => {
    if (newSocket?.connected) {
      newSocket?.on(`INBOUND_VOICE_CALL_RES`, (message) => {
        if (!device?.isBusy || message?.status === `in-progress`)
          dispatch(setCurrentIncomingCallStatus(message?.status))

        if (
          (message?.status === `no-answer` ||
            message?.status === `completed` ||
            message?.status === `busy` ||
            message?.status === `failed` ||
            message?.status === `cancelled`) &&
          incomingCall?._status === `closed`
        ) {
          setGlobalIncomingCall(false)
          setGlobalIncomingCallModel(false)
          dispatch(setCurrentIncomingCallStatus(``))
        }
      })
    }
    return () => {
      newSocket?.off(`INBOUND_VOICE_CALL_RES`)
    }
  }, [newSocket?.connected, incomingCall?._status])

  const handleSignout = () => {
    if (preferredLocation?.always) {
      localStorage.setItem(
        `preferredLocation`,
        JSON.stringify({ ...preferredLocation }),
      )
    } else {
      localStorage.setItem(
        `preferredLocation`,
        JSON.stringify({ ...preferredLocation, currentlySet: false }),
      )
    }
    localStorage.setItem(`isLogOut`, true)
    window.location.assign(`${IDP_FRONTEND}?logout=${APP_BASEURL}`)
  }

  const {
    // isLoading,
    // data: chatNotificationData,
    mutate: getAllNotifications,
    // isRefetching,
    // error,
  } = useMutation(async (payload) => {
    return (
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/notifications/unread/all?page=1&offset=5&query=${payload}`,
      ).then((res) => {
        // dispatch(setChatPreviousLength(chatLatestLength))
        payload === `chatNotifications`
          ? dispatch(setChatLatestLength(res.data.data.unreadRecords))
          : dispatch(setLatestLength(res.data.data.unreadRecords))

        // dispatch(setPreviousLength(latestLength))
        // dispatch(setLatestLength(res.data.data.totalSystemNotifications))
        return res.data.data?.chatNotifications
      }),
      {
        refetchOnWindowFocus: false,
        cacheTime: 1,
      }
    )
  })

  //General Notification api handler
  const { refetch: getAllGeneralNotifications } = useQuery(
    `GeneralNotificationApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/general-notifications/unread/all?page=1&offset=5`,
      )
        .then((res) => {
          dispatch(setGeneralNotificationsLength(res.data.data.unreadRecords))
          return res.data.data
        })
        .catch((err) => {
          if (err)
            NotificationManager.error(`Error getting general notifications`)
        }),
    {
      // enabled: latestLength > 0,
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  useEffect(() => {
    if (newSocket) {
      newSocket?.on(`SYSTEM_NOTIFICATIONS`, () => {
        getAllNotifications(`systemNotifications`)
      })
      newSocket?.on(`CHAT_NOTIFICATIONS`, () => {
        getAllNotifications(`chatNotifications`)
      })
      newSocket?.on(`GENERAL_NOTIFICATIONS`, () => {
        getAllGeneralNotifications()
      })
    }

    return () => {
      newSocket?.off(`SYSTEM_NOTIFICATIONS`)
      newSocket?.off(`CHAT_NOTIFICATIONS`)
      newSocket?.off(`GENERAL_NOTIFICATIONS`)
    }
  }, [newSocket])

  return (
    <div className="bg-sideBarColor shadow-sm sticky top-0 z-50">
      <PreferredLocation />
      <div className="flex h-16">
        {/* breadcrumbs preview */}
        <BreadCrumbs />
        <div className="flex-1 px-3">
          <div className="flex gap-2 float-right h-16  align-middle py-3">
            {/* OutBound Call Top Bar Icon*/}
            {activeCall ? (
              <div className="flex">
                <span
                  className="py-2"
                  onClick={() => dispatch(setGlobalCallModalActive(true))}
                >
                  <FontAwesomeIcon
                    icon={faPhoneVolume}
                    className={`cursor-pointer text-white  fa-rotate-45   ${
                      activeCall ? `animate-pulse` : ``
                    } `}
                    size="lg"
                  />
                </span>
              </div>
            ) : null}
            {/* OutBound Call Model*/}
            {globalCallModalActive && !currentPaymentInvoice && (
              <ReactModal
                width="96"
                title="Start Call"
                className="justify-center"
                closeModal={() => dispatch(setGlobalCallModalActive(false))}
              >
                <Call
                  customClass="px-12"
                  topBar={true}
                  unknownCaller={unknownCaller}
                />
              </ReactModal>
            )}
            {/* {globalCallModalActive && currentPaymentInvoice && (
              <ReactModal
                width="96"
                title="Start Call"
                className="justify-center"
                closeModal={() => {
                  dispatch(setGlobalCallModalActive(false))
                }}
              >
                <PaymentCall
                  customClass="px-12"
                  topBar={true}
                  invoiceCall={currentPaymentInvoice}
                  closeModal={() => {
                    dispatch(setGlobalCallModalActive(false))
                  }}
                />
              </ReactModal>
            )} */}
            {/* InBound Call Top Bar Icon*/}
            {globalIncomingCall &&
            (incomingCall?._status === `pending` ||
              incomingCall?._status === `open`) ? (
              <div className="flex">
                <span
                  className="py-2"
                  onClick={() => setGlobalIncomingCallModel(true)}
                >
                  <FontAwesomeIcon
                    icon={faPhoneVolume}
                    className={`cursor-pointer text-white fa-rotate-45   ${
                      activeCall ? `animate-pulse` : ``
                    } `}
                    size="lg"
                  />
                </span>
              </div>
            ) : null}
            {/* InBound Call Model*/}
            {globalIncomingCallModel &&
            (incomingCall?._status === `pending` ||
              incomingCall?._status === `open` ||
              !incomingCall?._isCancelled) ? (
              <ReactModal
                width="72"
                title="Incoming Call"
                className="justify-center"
                closeModal={() => setGlobalIncomingCallModel(false)}
              >
                <div
                  className={`flex flex-col 
                    w-80
                  justify-center items-center gap-8 pt-14 `}
                >
                  <div className="text-center">
                    <p
                      onClick={() => {
                        if (callerInfo?.customerName) {
                          history.push(`/customers/${callerInfo?._customerId}`)
                          setGlobalIncomingCallModel(true)
                        }
                      }}
                      className={` ${
                        callerInfo?.customerName
                          ? `border-1 text-green-500 cursor-pointer px-1.5 py-0.5 text-sm rounded-sm hover:bg-blue-500 hover:text-white`
                          : `text-red-500`
                      }  `}
                    >
                      {callerInfo?.customerName || `UNKNOWN`}
                    </p>
                    <p className="text-2xs mt-1">
                      {incomingCall?.parameters?.From}
                    </p>
                  </div>
                  <p className="capitalize">
                    {currentIncomingCall === `completed`
                      ? `in-progress`
                      : currentIncomingCall}
                  </p>
                  {/* call is not picked   */}
                  <div className="flex flex-col items-center m-1 px-6 gap-6 w-auto">
                    {currentIncomingCall !== `in-progress` &&
                    !device?.isBusy &&
                    incomingCall?._status !== `closed` ? (
                      <div className="flex gap-8 mb-3">
                        <CallBlock
                          data-tip="Pick Up"
                          className="hover:text-gray-600 tooltip"
                          onClick={() => {
                            incomingCall.accept()
                            newSocket?.emit(`CALL_PICKED`, {
                              attendeeId: user?.profile?.sub,
                              locationId: preferredLocation?._id,
                            })
                            setGlobalIncomingCallModel(true)
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPhone}
                            className="cursor-pointer text-green-600"
                            size="lg"
                          />
                          <div className="absolute bottom-2 right-2 mx-auto  h-8 w-8 rounded-full bg-gray-500 animate-call "></div>
                        </CallBlock>

                        <CallBlock
                          data-tip="HangUp"
                          className="hover:text-gray-600 tooltip"
                          onClick={() => {
                            incomingCall.reject()
                            setGlobalIncomingCall(false)
                            setGlobalIncomingCallModel(false)
                            setIncomingCall(``)
                            newSocket?.emit(`CALL_PICKED`, {
                              attendeeId: user?.profile?.sub,
                              locationId: preferredLocation?._id,
                            })
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPhone}
                            className="cursor-pointer text-red-600"
                            size="lg"
                          />
                          <div className="absolute mx-auto bottom-2 right-2 h-8 w-8 rounded-full bg-gray-500  animate-call"></div>
                        </CallBlock>
                      </div>
                    ) : (
                      <>
                        <div className="flex gap-3">
                          <CallBlock
                            data-tip="Hang Up"
                            className="hover:text-gray-600 tooltip"
                            onClick={() => {
                              incomingCall.disconnect()
                              setGlobalIncomingCall(false)
                              setGlobalIncomingCallModel(false)
                              dispatch(setCurrentIncomingCallStatus(``))
                              newSocket?.emit(`CALL_PICKED`, {
                                attendeeId: user?.profile?.sub,
                                locationId: preferredLocation?._id,
                              })
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPhone}
                              className="cursor-pointer text-red-600"
                              size="lg"
                            />

                            <div className="absolute mx-auto bottom-2 right-2 h-8 w-8 rounded-full bg-gray-500  animate-call"></div>
                          </CallBlock>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </ReactModal>
            ) : null}

            {/* <div className="flex" ref={appMenuRef}>
              <span
                className="py-2 tooltip tooltip-bottom"
                data-tip="Broadcast Status"
              >
                <FontAwesomeIcon
                  icon={faBell}
                  className="cursor-pointer text-gray-300 hover:text-red-500 "
                  onClick={() => setCampaign(!campaign)}
                  size="lg"
                />
              </span>

              <div className="relative text-left bg-gray-700" id="menu-wrapper">
                {campaign && (
                  <AppMenu className="z-50">
                    <div className="w-72">
                      <PageHeader>Broadcast</PageHeader>

                      <div>
                        <Tabs2
                          openTab={activeStatusTab}
                          tabs={statusTab}
                          getActiveTab={(val) => setActiveStatusTab(val)}
                        />
                        <div className="tab-content tab-space">
                          <TOne opentab={activeStatusTab}>Completed</TOne>
                          <TTwo opentab={activeStatusTab}>Progress</TTwo>
                          <TThree opentab={activeStatusTab}>Progress</TThree>
                        </div>
                      </div>
                    </div>
                  </AppMenu>
                )}
              </div>
            </div> */}

            {/* <div className="flex">
              <span className="py-2" ref={appMenuRef}>
                <FontAwesomeIcon
                  icon={faTh}
                  onClick={() => setAppMenu(!appMenu)}
                  className="cursor-pointer text-gray-300 hover:text-red-500"
                  size="lg"
                />
              </span>
              <div
                className="relative text-left bg-gray-700 "
                id="menu-wrapper"
              >
                {appMenu && (
                  <AppMenu className="z-50">
                    <div>
                      <div className="flex m-2 gap-2 h-16 w-36">
                        <AppBlock data-tip="AQS" className="tooltip">
                          <img src={aqs} alt="aqs" />
                        </AppBlock>
                        <AppBlock data-tip="AMS" className="tooltip">
                          <img src={ams} alt="smd" />
                        </AppBlock>
                      </div>
                      <div className="flex gap-2 h-16 w-36 m-2">
                        <AppBlock data-tip="CRM" className="tooltip">
                          <img src={crm} alt="crm" />
                        </AppBlock>
                        <AppBlock data-tip="PPA" className="tooltip">
                          <img src={ppa} alt="ppa" />
                        </AppBlock>
                      </div>
                      <div className="flex gap-2 h-16 w-36 m-2">
                        <AppBlock data-tip="PQS" className="tooltip">
                          <img src={pqs} alt="pqs" />
                        </AppBlock>
                        <AppBlock></AppBlock>
                      </div>
                    </div>
                  </AppMenu>
                )}
              </div>
            </div> */}

            {/* Global Communication */}

            {permissionGranted([
              `global-communication:read`,
              `corporate-manager:*`,
              `manager:*`,
              `admin:*`,
            ]) && (
              <div className="flex relative">
                <div className="duration-300 text-blue-100 rounded ">
                  <div
                    onClick={() => history.push(`/communications`)}
                    className="flex justify-center items-center cursor-pointer"
                    style={{ height: `45px`, width: `50px` }}
                    ref={pushNotificationRef}
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-gray-200"
                      size="xl"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* <div className="flex">
              <span
                className="py-2 tooltip tooltip-bottom"
                data-tip="Communications"
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="cursor-pointer text-gray-300 hover:text-red-500 "
                  onClick={() => history.push(`/communications`)}
                  size="lg"
                />
              </span>
            </div> */}

            {/* Monthly Goals */}
            {permissionGranted([
              `monthly-goals:read`,
              `corporate-manager:*`,
              `manager:*`,
              `admin:*`,
            ]) &&
              !isSuperUser(`super-admin:*`) && (
                <div className="flex">
                  <div className="relative">
                    <div id="options-menu" className="block relative">
                      <div className="relative duration-300 text-blue-100 rounded">
                        <div
                          alt="profile"
                          onClick={() => setGoalsMenuOpen(!goalsMenuOpen)}
                          ref={monthlyGoalRef}
                          className="flex justify-center items-center cursor-pointer"
                          style={{ height: `45px` }}
                        >
                          <FontAwesomeIcon
                            icon={faListAlt}
                            className="text-gray-200"
                            size="xl"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="relative text-left bg-gray-700 "
                      id="menu-wrapper"
                    >
                      {goalsMenuOpen && (
                        <DropDown>
                          <MonthlyGoalsMenu />
                        </DropDown>
                      )}
                    </div>
                  </div>
                </div>
              )}

            {/* ********************************************************* */}
            {/*                     All Notifications                     */}
            {/* ********************************************************* */}
            <div className="flex relative" ref={notificationsRef}>
              <div className="duration-300 text-blue-100 rounded">
                <div
                  onClick={() => setAllNotificationsModal(true)}
                  className="flex justify-center items-center cursor-pointer"
                  style={{ height: `45px`, width: `40px` }}
                >
                  <FontAwesomeIcon
                    icon={faBell}
                    className="text-gray-200"
                    size="xl"
                  />
                  <span
                    id="latestNotification"
                    className="absolute top-0.5 right-1 -mr-0.5 px-1  bg-red-500 rounded-full text-xs text-white"
                  >
                    {/* Check first if its number or not maybe it's undefined  */}
                    {(Number(latestLength) || 0) +
                      (Number(chatLatestLength) || 0) +
                      (Number(generalNotificationsLength) || 0)}
                  </span>
                </div>
                {allNotificationsModal && (
                  <DropDown>
                    <AllNotification />
                  </DropDown>
                )}
              </div>
            </div>

            {/* Notification */}
            {/* {!(
              isHasPermission(`car-dealer-agent:*`) ||
              isHasPermission(`car-dealer-manager:*`)
            ) && (
              <div className="flex relative">
                <div className="duration-300 text-blue-100 rounded ">
                  <div
                    onClick={() => setPushNotificationOpen(true)}
                    className="flex justify-center items-center cursor-pointer"
                    style={{ height: `45px`, width: `40px` }}
                    ref={pushNotificationRef}
                  >
                    <FontAwesomeIcon
                      icon={faBell}
                      className="text-gray-200"
                      size="xl"
                    />
                    <span
                      id="latestNotification"
                      className="absolute top-0.5 right-1 -mr-0.5 px-1  bg-red-500 rounded-full text-xs text-white"
                    >
                      {latestLength > 0 ? latestLength : ``}
                    </span>
                  </div>
                  <div
                    className="relative text-left bg-gray-700 "
                    id="menu-wrapper"
                  >
                    {pushNotificationOpen && (
                      <DropDown>
                        <PushNotification />
                      </DropDown>
                    )}
                  </div>
                </div>
              </div>
            )} */}

            {/* {!(
              isHasPermission(`car-dealer-agent:*`) ||
              isHasPermission(`car-dealer-manager:*`)
            ) && (
              <div className="flex relative">
                <div className="duration-300 text-blue-100 rounded ">
                  <div
                    onClick={() => setChatNotificationOpen(true)}
                    className="flex justify-center items-center cursor-pointer"
                    style={{ height: `45px`, width: `40px` }}
                    ref={chatNotificationRef}
                  >
                    <img src={chatIcon} alt="" width="24px" />

                    <span
                      id="latestNotification"
                      className="absolute top-0.5 right-1 -mr-0.5 px-1  bg-red-500 rounded-full text-xs text-white"
                    >
                      {chatLatestLength > 0 ? chatLatestLength : ``}
                    </span>
                  </div>
                  <div
                    className="relative text-left bg-gray-700 "
                    id="menu-wrapper"
                  >
                    {chatNotificationOpen && (
                      <DropDown>
                        <ChatNotification />
                      </DropDown>
                    )}
                  </div>
                </div>
              </div>
            )} */}

            <div className="flex">
              <div className="relative">
                <div id="options-menu" className="block relative">
                  <div className="relative duration-300 text-blue-100 rounded">
                    <UserImage
                      alt="img"
                      src={
                        user?.profile?.img ? user?.profile?.img : defaultImage
                      }
                      onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                      // latest={
                      //   previousLength != null &&
                      //   latestLength != null &&
                      //   latestLength > previousLength
                      //     ? `true`
                      //     : `false`
                      // }
                      ref={menuRef}
                      style={{ height: `40px`, width: `40px` }}
                    />
                    {/* {previousLength != null &&
                      latestLength != null &&
                      latestLength > previousLength && (
                        <span className="absolute bg-red-500 px-2 py-1 text-xs font-bold rounded-full -top-2.5">
                          {latestLength - previousLength}
                        </span>
                      )} */}
                  </div>
                </div>

                <div
                  className="relative text-left bg-gray-700 "
                  id="menu-wrapper"
                >
                  {profileMenuOpen && (
                    <DropDown profile="yes">
                      <div className="w-48">
                        <ProfileMenuItem
                          onClick={() => history.push(`/profile`)}
                        >
                          <FontAwesomeIcon icon={faUser} />
                          <span>Profile</span>
                        </ProfileMenuItem>

                        {/* switch locations */}
                        <ProfileMenuItem
                          onClick={(e) => {
                            e.stopPropagation()
                            setSwitchLocationModal(!switchLocation)
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowAltCircleRight} />
                          <span>Switch Location</span>
                        </ProfileMenuItem>

                        {switchLocation &&
                          user?.profile?._locations.map((location, index) => (
                            <div key={`location` + index}>
                              <SecondayProfileMenuItem
                                onClick={(e) => {
                                  e.stopPropagation()
                                  dispatch(
                                    setPreferredLocation({
                                      ...location,
                                      always: false,
                                    }),
                                  )
                                  // document.cookie = `preferredLocation=${JSON.stringify(
                                  //   {
                                  //     ...location,
                                  //     always: !preferredLocation.always,
                                  //   },
                                  // )}; path=/`
                                  history.go(0)
                                  dispatch(setSwitchLocation(true))
                                }}
                              >
                                <FontAwesomeIcon icon={faLocationArrow} />
                                <span>{location?.name}</span>
                                {preferredLocation?._id === location._id && (
                                  <span className="h-2 w-2 bg-blue-500 rounded-full"></span>
                                )}
                              </SecondayProfileMenuItem>
                            </div>
                          ))}

                        {/* logout */}
                        <ProfileMenuItem
                          onClick={handleSignout}
                          // onClick={oidcAuth.signOut}
                        >
                          <FontAwesomeIcon icon={faPowerOff} />
                          <span>Logout</span>
                        </ProfileMenuItem>
                      </div>
                    </DropDown>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
