/* eslint-disable no-unused-vars */
import {
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Badge,
  Pagination,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@windmill/react-ui'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Reload } from 'App/Components/Common/Reload'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { Spinner } from 'App/Components/Common/Spinner'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { loadOffset } from 'App/Services/General/loadOffset'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce/lib'
import { ReactModal } from 'App/Components/Common/Modal'
import { UpdateDesignation } from '../UpdateDesignation'
import { AddDesignation } from '../AddDesignation'
import { NotificationManager } from 'react-notifications'

const tableColumns = [
  { label: `Name`, value: `name` },
  { label: `Commission Type`, value: `commission.type` },
  { label: `Commission Rate`, value: `commission.rate` },
]

export const AllDesignation = () => {
  const history = useHistory()
  const { url } = useRouteMatch()

  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [isOffset, setIsOffset] = useState(``)

  const [searchQuery, setSearchQuery] = useState(``)
  const [designationUpdate, setDesignationUpdate] = useState(false)
  const [currentDesignation, setCurrentDesignation] = useState(``)
  const [designationUpdated, setDesignationUpdated] = useState(false)
  const [addModal, setAddModal] = useState(false)
  //   getting all Designations

  const {
    isLoading,
    data: designationData,
    refetch: getAllDesignation,
    isFetching,
    error,
  } = useQuery(
    `allDesignationApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/designations?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }"}&sort="${sort.length > 0 ? sort : ``}"&page=1&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomSearching(false)
        if (res) {
          setDesignationUpdated(false)
          setAddModal(false)
        }
      },
    },
  )

  const {
    mutate: deleteDesignation,
    isLoading: deletingDesignation,
    isError: deleteDesignationError,
    error: deleteError,
  } = useMutation(
    async (Id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/designations/${Id}`,
      ).then((res) => {
        res.data && getAllDesignation()
        NotificationManager.success(`Successfully Deleted`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  useEffect(() => {
    if (designationUpdated) {
      getAllDesignation()
    }
  }, [designationUpdated])

  useEffect(() => {
    if (!customSearching) {
      getAllDesignation()
    }
  }, [sort, currentPage, isOffset])

  const callDesignationQuery = useDebouncedCallback(() => {
    getAllDesignation()
  }, 700)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteDesignation(id)
  }

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  return (
    <Container>
      <Spinner loading={isLoading || isFetching || deletingDesignation} />
      {deleteDesignationError && (
        <ReactNotification
          action="error"
          message={deleteError?.response?.data?.message}
        />
      )}

      {addModal && (
        <ReactModal
          title="Add Designation"
          closeModal={() => setAddModal(!addModal)}
        >
          <AddDesignation
            closeModal={() => setAddModal(false)}
            addDesignations={() => setDesignationUpdated(true)}
          />
        </ReactModal>
      )}
      {designationUpdate && (
        <ReactModal
          title="Update Designation"
          closeModal={() => setDesignationUpdate(false)}
        >
          <UpdateDesignation
            currentDesignation={currentDesignation}
            closeModal={() => setDesignationUpdate(false)}
            designationUpdated={() => setDesignationUpdated(true)}
          />
        </ReactModal>
      )}
      <PageHeader bgcolor="bg-white">
        <div>Designations</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>

          <div className="text-end ">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callDesignationQuery()
                }
              }}
              setValue={(e) => {
                const containsSpecialCharacters =
                  /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(e.target.value)
                if (containsSpecialCharacters) {
                  setSearchQuery(``)
                } else {
                  setSearchQuery(e.target.value)
                }
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>
      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `designation:read`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton onClick={() => setAddModal(!addModal)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (isLoading || isFetching || deletingDesignation) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllDesignation()} />
        ) : (
          <>
            <div className="overflow-x-auto rounded-t-md mt-1">
              <table className="w-full border-collapse bg-slate-50">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className=" cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item?.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {permissionGranted([
                      `designation:update`,
                      `designation:delete`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !designationData?.designations?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        No Designaiton Found
                      </td>
                    </tr>
                  ) : (
                    designationData?.designations?.map((designation, index) => (
                      <TableRow
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          if (
                            permissionGranted([
                              `designation:update`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])
                          ) {
                            setDesignationUpdate(true)
                            setCurrentDesignation(designation)
                          }
                        }}
                      >
                        <td className="px-4 py-0.5 capitalize text-sm">
                          {designation?.name ? (
                            designation?.name
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>

                        <td className="px-4 py-0.5 capitalize text-sm pl-6">
                          <Badge>
                            {designation?.commission?.type
                              ? designation?.commission?.type.toLowerCase()
                              : `N/A`}
                          </Badge>
                        </td>
                        <td className="px-4 py-0.5 capitalize text-sm pl-6">
                          <Badge type="danger">
                            {designation?.commission?.rate
                              ? designation?.commission?.rate
                              : `N/A`}
                          </Badge>
                        </td>
                        <td className="px-6 py-2.5 z-0 text-sm flex float-right text-gray-400">
                          {permissionGranted([
                            `designation:update`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ]) && (
                            <span data-tip="Update" className="tooltip">
                              <FontAwesomeIcon
                                className=" mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer "
                                icon={faEdit}
                              />
                            </span>
                          )}
                          {permissionGranted([
                            `designation:delete`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ]) && (
                            <span data-tip="Delete" className="tooltip">
                              <FontAwesomeIcon
                                className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                icon={faTrash}
                                size="1x"
                                onClick={(e) =>
                                  handleDelete(designation._id, e)
                                }
                              />
                            </span>
                          )}
                        </td>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={designationData?.totalPages}
                totalRecords={designationData?.totalRecords}
                curerntPageRecords={designationData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
