import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentRatingTemplate: ``,
  selectedRatingTemplate: ``,
  ratingTemplates: [],
}

const ratingTemplateSlice = createSlice({
  name: `ratingTemplates`,
  initialState,
  reducers: {
    setRatingTemplateToState: (state, { payload }) => {
      state.ratingTemplates.push(payload)
    },
    setCurrentRatingTemplate: (state, { payload }) => {
      state.currentRatingTemplate = payload
    },
    setSelectedRatingTemplate: (state, { payload }) => {
      state.selectedRatingTemplate = payload
    },
  },
})

export const {
  setRatingTemplateToState,
  setCurrentRatingTemplate,
  setSelectedRatingTemplate,
} = ratingTemplateSlice.actions

export const ratingTemplate = ratingTemplateSlice.reducer
