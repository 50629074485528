import React from 'react'
import { useDispatch } from 'react-redux'
import { Badge } from '@windmill/react-ui'
import { useQuery } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { setCurrentRatingTemplate } from 'App/Redux/actions'

export const RatingTemplate = ({ currentRatingTemplate }) => {
  // const { currentRatingTemplate } = useSelector(
  //   ({ ratingTemplate }) => ratingTemplate,
  // )
  const dispatch = useDispatch()
  const { data: ratingTemplateData, isLoading } = useQuery(
    `RatingTemplatePreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/rating-templates/${currentRatingTemplate}`,
      ).then((res) => {
        dispatch(setCurrentRatingTemplate(res.data.data))
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  return (
    <>
      {isLoading ? (
        <div className="text-center flex items-center justify-center py-6 h-96 w-680">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : (
        <div className="py-2 w-680">
          <div className="grid grid-cols-12 gap-3 text-center justify-self-center text-sm font-bold">
            <div className="col-span-3">
              State -
              <span className="text-red-500 px-2">
                {ratingTemplateData?.rateState}
              </span>
            </div>
            <div className="col-span-4">
              Name -
              <span className="text-red-500 px-2">
                {ratingTemplateData?.name}
              </span>
            </div>
            <div className="col-span-5">
              Description -
              <span className="text-red-500 px-3 mx-2 break-words max-w-px-140 ">
                {ratingTemplateData?.description}
              </span>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-3 px-2 text-center justify-self-center font-bold mt-4 border-1 mx-2 rounded-md p-2">
            <div className="col-span-6 ">
              <span>General Coverages</span>
              {ratingTemplateData?.template?.policyCoverages.map(
                (policy, index) => (
                  <div className="grid grid-cols-4 mt-2 text-sm" key={index}>
                    <div className="col-span-2 justify-self-start">
                      Limits -
                      <span className="text-red-500 px-2">
                        {policy?.limits?.length ? (
                          policy?.limits
                        ) : (
                          <Badge>N/A</Badge>
                        )}
                      </span>
                    </div>
                    <div className="col-span-2 justify-self-start">
                      Deductible -
                      <span className="text-red-500 px-2">
                        {policy?.deductible?.length ? (
                          policy?.deductible
                        ) : (
                          <Badge>N/A</Badge>
                        )}
                      </span>
                    </div>
                  </div>
                ),
              )}
            </div>
            <div className="col-span-6">
              <span>Vehicle Coverages</span>
              {ratingTemplateData?.template?.vehicleCoverages.map(
                (vehicle, index) => (
                  <div className="grid grid-cols-4 mt-2 text-sm" key={index}>
                    <div className="col-span-2 justify-self-start ">
                      Limits -
                      <span className="text-red-500 px-2">
                        {vehicle?.limits?.length ? (
                          vehicle?.limits
                        ) : (
                          <Badge>N/A</Badge>
                        )}
                      </span>
                    </div>
                    <div className="col-span-2 justify-self-start">
                      Deductible -
                      <span className="text-red-500 px-2">
                        {vehicle?.deductible?.length ? (
                          vehicle?.deductible
                        ) : (
                          <Badge>N/A</Badge>
                        )}
                      </span>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
