import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API } from 'App/Config/backendApis'
import { AxiosInstance } from 'App/Config'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import { TextArea } from 'App/Components/Common/TextArea'
import { useDebouncedCallback } from 'use-debounce'
import { loadCredentials } from 'App/Services'

const UpdateGroupCredential = () => {
  const history = useHistory()
  const {
    params: { groupCredentialId },
  } = useRouteMatch()

  const { currentCredentialGroup } = useSelector(
    ({ credentials }) => credentials,
  )

  const [credentialNameValidating, setCredentialNameValidating] =
    useState(false)

  const [credential, setCredential] = useState({
    name: ``,
    credentials: [],
    description: ``,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    nameError: ``,
    credentialsError: ``,
    descriptionError: ``,
  })

  useEffect(() => {
    if (currentCredentialGroup) {
      setCredential((preState) => ({
        ...preState,
        name: currentCredentialGroup?.name,

        credentials: currentCredentialGroup?.credentials
          ? [...currentCredentialGroup?.credentials]
          : [],
        description: currentCredentialGroup?.description,
      }))
    }
  }, [currentCredentialGroup])

  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/credentials-groups/${groupCredentialId}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          if (res.data) {
            NotificationManager.success(`Operation successful`)
            res.data &&
              history.push(`/admin/group-credentials/${groupCredentialId}`)
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            } else {
              NotificationManager.error(`Something went Wrong!`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const ValidateDuplicateCredentialName = async (payload) => {
    return await AxiosInstance.post(
      `${AMS_BACKEND_API}/api/validate/credentials-group-payloads`,
      payload,
    )
      .then((res) => {
        return res?.data
      })
      .catch((error) => {
        return error.response.data
      })
  }

  const validateCredentialName = useDebouncedCallback(async (value) => {
    setCredentialNameValidating(true)
    const response = await ValidateDuplicateCredentialName({ name: value })

    if (response?.hasError) {
      handleErrors(`formSubmit`, false)
      handleErrors(`nameError`, `Name Already Exists`)
    } else {
      handleErrors(`formSubmit`, false)
      handleErrors(`nameError`, ``)
    }
    setCredentialNameValidating(false)
  }, 2500)
  const validateForm = () => {
    if (!credential.name?.length) {
      handleErrors(`nameError`, `Select Name`)
    } else {
      if (errors.nameError !== `Name Already Exists`) {
        handleErrors(`nameError`, ``)
      }
    }

    if (!credential.credentials?.length) {
      handleErrors(`credentialsError`, `Select Credential`)
    } else {
      handleErrors(`credentialsError`, ``)
    }

    if (!credential?.description?.length) {
      handleErrors(`descriptionError`, `Enter Description`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.credentialsError.length &&
      !errors.descriptionError.length
    ) {
      mutate(preparePayload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const preparePayload = () => {
    let data = {}
    if (credential?.name !== currentCredentialGroup?.name) {
      data.name = credential.name
    }

    let isCredentialUpdated
    if (
      credential?.credentials?.length ===
      currentCredentialGroup?.credentials?.length
    ) {
      isCredentialUpdated = credential?.credentials?.some(
        (_eachCredential, index) =>
          _eachCredential?.value !==
          currentCredentialGroup?.credentials[index]?.value,
      )
    } else {
      isCredentialUpdated = true
    }

    // const _isCredentialUpdated =
    if (isCredentialUpdated) {
      data.credentials = credential?.credentials?.map((credential) => {
        return credential.value
      })
    }

    if (credential?.description !== currentCredentialGroup?.description) {
      data.description = credential.description
    }

    return data
  }
  return (
    <Container>
      <PageHeader padding="true">Update Group</PageHeader>

      {updationError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div
        className={`px-4 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        {/* titles basic form */}
        <div className="flex gap-6 my-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name *"
              placeholder="Enter Name"
              value={credential.name}
              errorMessage={errors.nameError}
              validating={credentialNameValidating}
              setValue={(e) => {
                validateCredentialName(e.target.value)
                setCredential((preState) => ({
                  ...preState,
                  name: e.target.value,
                }))
              }}
              maxlength={`100`}
            />
          </div>
        </div>
        <div className="flex gap-6 my-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Credentials *"
              placeholder="Select Credentials"
              value={credential.credentials}
              errorMessage={errors.credentialsError}
              setValue={(value) => {
                setCredential((preState) => ({
                  ...preState,
                  credentials: value,
                }))
              }}
              isMulti={true}
              loadOptions={loadCredentials}
              isSearchable={true}
            />
          </div>
        </div>
        <div className="flex gap-6 my-1 relative">
          <div className="flex-1 ">
            <TextArea
              type="textarea"
              title="Description *"
              placeholder="Enter description"
              autoFocus={false}
              value={credential?.description}
              errorMessage={errors?.descriptionError}
              setValue={(e) => {
                setCredential((preState) => ({
                  ...preState,
                  description: e.target.value,
                }))
              }}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="text-center w-96 my-5">
            <SubmitButton onClick={validateForm}>Update Group</SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default UpdateGroupCredential
