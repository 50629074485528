import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentClaim: ``,
  activeActivityTab: 1,
  activeMediaTab: 1,
  claimMedia: [],
}

const claimSlice = createSlice({
  name: `claim`,

  initialState,
  reducers: {
    setCurrentClaim: (state, { payload }) => {
      state.currentClaim = payload
    },

    setClaimActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
    setActiveMediaTab: (state, { payload }) => {
      state.activeMediaTab = payload
    },
    setClaimMedia: (state, { payload }) => {
      state.claimMedia = payload
    },
  },
})

export const {
  setCurrentClaim,
  setClaimActivityActiveTab,
  setActiveMediaTab,
  setClaimMedia,
} = claimSlice.actions

export const claim = claimSlice.reducer
