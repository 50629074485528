import React, { useEffect, useState, Fragment } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, StyledButton, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faEdit,
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { loadFranchises, permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { ReactModal } from 'App/Components/Common/Modal'
import { UpdateTasks } from '../UpdateTasks'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import { useSelector } from 'react-redux'
import searchQueryParser from 'App/Services/General/searchQueryParser'

const tableColumns = [
  { label: `ID`, value: `_taskId` },
  { label: `Title`, value: `title` },
  { label: `Type`, value: `taskType` },
  { label: `Priority`, value: `priority` },
  { label: `Status`, value: `status` },
  { label: `Due Date`, value: `dueDate` },
  { label: `Assigned To`, value: `assignedTo` },
  { label: `Assigned By`, value: `assignedBy` },
]

export const AllTasks = () => {
  const history = useHistory()
  let { url } = useRouteMatch()

  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [location, setLocation] = useState(``)
  const [franchise, setFranchise] = useState(``)

  //  sorting and filtering states
  const [sort, setSort] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)

  // update states
  const [taskUpdate, setTaskUpdate] = useState(false)
  const [currentTask, setCurrentTask] = useState(``)
  const [taskUpdated, setTaskUpdated] = useState(false)
  const { sub: currentUser } = useSelector(({ user }) => user?.user?.profile)
  //   getting all tasks
  const {
    isLoading,
    data: tasksData,
    refetch: getAllTasks,
    isFetching,
    error,
  } = useQuery(
    `TaskApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/task/all?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }&_franchise=${franchise ? franchise.value : ``}&_location=${
          location ? location.value : ``
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomSearching(false)

        if (res) {
          setTaskUpdated(false)
        }
      },
    },
  )
  const loadLocations = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        franchise.value ? franchise.value : _franchise?._id
      }/locations/list/?search=${search && search}&page=${page}&offset=10`,
    )
    if (data) {
      return {
        options: data.locations,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  const {
    isLoading: deletingTask,
    isError: deletionError,
    mutate: deleteTask,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(`${AMS_BACKEND_API}/api/task/${id}`)
        .then((res) => {
          res.data && getAllTasks()
          NotificationManager.success(`Deleted Successfully`)
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Requested task not found in database.`,
              )
            ) {
              NotificationManager.error(`Operation Fail,Task is closed`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (taskUpdated) {
      getAllTasks()
    }
  }, [taskUpdated])

  useEffect(() => {
    if (!customSearching) {
      getAllTasks()
    }
  }, [sort, currentPage, isOffset, location, franchise])

  const callTasksQuery = useDebouncedCallback(() => {
    getAllTasks()
  }, 700)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteTask(id)
  }

  const handleTask = (item, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (item.status !== `CLOSED`) {
      setTaskUpdate(true)
      setCurrentTask(item)
    }
  }

  const badgeColor = {
    LOW: `success`,
    MEDIUM: `warning`,
    HIGH: `danger`,
    OPENED: `success`,
    CLOSED: `danger`,
    OVERDUE: `warning`,
  }

  return (
    <Container>
      {taskUpdate && (
        <ReactModal
          title="Update Task"
          maxWidth="5xl"
          width="2/3"
          closeModal={() => setTaskUpdate(false)}
        >
          <UpdateTasks
            currentTask={currentTask}
            closeModal={() => setTaskUpdate(false)}
            taskUpdated={() => setTaskUpdated(true)}
          />
        </ReactModal>
      )}
      {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={deletingTask || isLoading || isFetching} />
      <PageHeader>
        <div>Task Manager</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end  mt-1">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callTasksQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>
      <div className="p-2">
        {permissionGranted([`super-admin:*`]) && (
          <CollapsableRef title="Filters">
            <div className="flex">
              <div className="w-1/2 mt-2">
                <ReactSelectField
                  placeholder="Select Franchise"
                  title="Franchise"
                  setValue={(value) => {
                    setFranchise(value)
                    setLocation(``)
                  }}
                  isClearable={true}
                  loadOptions={loadFranchises}
                />
              </div>
              {franchise?.value && (
                <div className="w-1/2 mt-2 ml-4">
                  <ReactSelectField
                    placeholder="Select Location"
                    title="Location"
                    setValue={(value) => {
                      setLocation(value)
                    }}
                    value={location}
                    isClearable={true}
                    loadOptions={loadLocations}
                    cacheUniqs={franchise}
                  />
                </div>
              )}
            </div>
          </CollapsableRef>
        )}
      </div>
      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `task:create`,
          `task:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <div
        className={`bg-white sm:mb-0 justify-between w-full px-2 ${
          (deletingTask || isLoading || isFetching) &&
          ` opacity-30 pointer-events-none`
        }  `}
      >
        {error ? (
          <Reload refetch={() => getAllTasks()} />
        ) : (
          <>
            <div className="rounded-t-md overflow-x-auto">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className=" cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-3 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item?.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(permissionGranted([
                      `task:*`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) ||
                      permissionGranted([
                        `task:update`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) ||
                      permissionGranted([
                        `task:delete`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ])) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !tasksData?.tasks?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Task Found</div>
                      </td>
                    </tr>
                  ) : (
                    tasksData?.tasks
                      ?.filter((task) => task.isDeleted !== true)
                      ?.map((task, index) => (
                        <Fragment key={index}>
                          <TableRow
                            key={index}
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              if (
                                permissionGranted([
                                  `task:read`,
                                  `corporate-manager:*`,
                                  `manager:*`,
                                  `admin:*`,
                                ]) ||
                                permissionGranted([
                                  `task:*`,
                                  `corporate-manager:*`,
                                  `manager:*`,
                                  `admin:*`,
                                ])
                              ) {
                                history.push(`${url}/${task._id}`)
                              }
                            }}
                          >
                            <td className="px-4 py-3 w-auto text-sm truncate max-w-xs">
                              {task?._taskId ? (
                                task?._taskId
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-3 w-auto text-sm truncate max-w-xs">
                              {task?.title ? task?.title : <Badge>N/A </Badge>}
                            </td>
                            <td className="px-2 py-3 w-auto text-sm truncate">
                              {task?.taskType ? (
                                task?.taskType
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-2 py-3 w-auto text-sm">
                              <Badge type={badgeColor[task?.priority]}>
                                {task?.priority ? (
                                  task?.priority
                                ) : (
                                  <Badge>N/A </Badge>
                                )}
                              </Badge>
                            </td>
                            <td className="px-2 py-3 w-auto text-sm">
                              <Badge type={badgeColor[task?.status]}>
                                {task?.status ? (
                                  task?.status
                                ) : (
                                  <Badge>N/A </Badge>
                                )}
                              </Badge>
                            </td>
                            <td className="px-4 py-3 w-auto text-sm font-semibold text-red-500 truncate">
                              {task.dueDate ? (
                                moment(task.dueDate).format(`MM-DD-YYYY hh:mmA`)
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>

                            <td className="px-4 py-3 w-auto text-sm truncate">
                              {task?.assignedTo ? (
                                task?.assignedTo
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-3 w-auto text-sm">
                              {task?.assignedBy?.label ? (
                                task?.assignedBy?.label
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>

                            {/* Actions TD */}

                            {(permissionGranted([
                              `task:delete`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ]) ||
                              permissionGranted([
                                `task:*`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ])) && (
                              <td className="px-4 py-3 w-auto text-sm z-0 flex float-right text-gray-400">
                                {(permissionGranted([
                                  `task:delete`,
                                  `corporate-manager:*`,
                                  `manager:*`,
                                  `admin:*`,
                                ]) ||
                                  permissionGranted([
                                    `task:*`,
                                    `corporate-manager:*`,
                                    `manager:*`,
                                    `admin:*`,
                                  ])) && (
                                  <span
                                    data-tip={
                                      currentUser === task?.assignedBy?.value
                                        ? `Delete`
                                        : `Not Allowed`
                                    }
                                    className="tooltip"
                                  >
                                    <FontAwesomeIcon
                                      className={`mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 ${
                                        currentUser === task?.assignedBy?.value
                                          ? `cursor-pointer`
                                          : `cursor-not-allowed`
                                      }`}
                                      icon={faTrash}
                                      size="1x"
                                      onClick={(e) => {
                                        currentUser === task?.assignedBy?.value
                                          ? handleDelete(task._id, e)
                                          : e &&
                                            e.stopPropagation &&
                                            e.stopPropagation() &&
                                            history.push(`${url}`)
                                      }}
                                    />
                                  </span>
                                )}
                                {(permissionGranted([
                                  `task:update`,
                                  `corporate-manager:*`,
                                  `manager:*`,
                                  `admin:*`,
                                ]) ||
                                  permissionGranted([
                                    `task:*`,
                                    `corporate-manager:*`,
                                    `manager:*`,
                                    `admin:*`,
                                  ])) && (
                                  <span
                                    data-tip={`${
                                      currentUser === task?.assignedBy?.value &&
                                      task.status !== `CLOSED`
                                        ? `Update`
                                        : currentUser !==
                                          task?.assignedBy?.value
                                        ? `Not Allowed`
                                        : `CLOSED`
                                    }`}
                                    className="tooltip"
                                  >
                                    <FontAwesomeIcon
                                      className={` mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer ${
                                        task?.status === `CLOSED` ||
                                        currentUser !== task?.assignedBy?.value
                                          ? `cursor-not-allowed`
                                          : `cursor-pointer`
                                      }`}
                                      icon={faEdit}
                                      onClick={(e) => {
                                        currentUser === task?.assignedBy?.value
                                          ? handleTask(task, e)
                                          : e &&
                                            e.stopPropagation &&
                                            e.stopPropagation() &&
                                            history.push(`${url}`)
                                      }}
                                    />
                                  </span>
                                )}
                              </td>
                            )}
                          </TableRow>
                        </Fragment>
                      ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={tasksData?.totalPages}
                totalRecords={tasksData?.totalRecords}
                curerntPageRecords={tasksData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
