import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentTicket: ``,
  activeActivityTab: 1,
}

const TicketsSlice = createSlice({
  name: `tickets`,

  initialState,
  reducers: {
    setCurrentTicket: (state, { payload }) => {
      state.currentTicket = payload
    },
    
    setTicketActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
  },
})

export const { setCurrentTicket, setTicketActivityActiveTab } =
  TicketsSlice.actions

export const tickets = TicketsSlice.reducer
