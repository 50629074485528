export const loadAllTicketsPriorities = () => {
  const options = {
    options: [
      { value: `LOW`, label: `LOW` },
      { value: `MEDIUM`, label: `MEDIUM` },
      { value: `HIGH`, label: ` HIGH` },
    ],
    hasMore: false,
  }
  return options
}
