import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentConversation: [],
  twilioDevice: ``,
}

const communicationSlice = createSlice({
  name: `communication`,

  initialState,
  reducers: {
    setSentMessage: (state, { payload }) => {
      state.currentConversation.push(payload)
    },
    setAllMessages: (state, { payload }) => {
      state.currentConversation = [...payload]
    },
    setOutboundMessages: (state, { payload }) => {
      let index = state.currentConversation.findIndex(
        (message) => message._id === payload._id,
      )
      if (index === -1) {
        state.currentConversation.push(payload)
      } else {
        state.currentConversation[index] = payload
      }
    },
    clearAllMessage: (state) => {
      state.currentConversation = []
    },
    setTwilioDevice: (state, { payload }) => {
      state.twilioDevice = payload
    },
  },
})

export const {
  setSentMessage,
  setAllMessages,
  setOutboundMessages,
  clearAllMessage,
  setTwilioDevice,
} = communicationSlice.actions

export const communication = communicationSlice.reducer
