import React from 'react'
import { TOne } from 'App/Styled'
import { useDispatch, useSelector } from 'react-redux'
import { setActivityActiveTab } from 'App/Redux/actions'
import { OtherCustomerNote } from 'App/Components/OtherCustomers/OtherCustomerNote'
// import { Communications } from 'App/Components/Communications'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { permissionGranted } from 'App/Services'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  // {
  //   name: `Customer Communications`,,
  //   number: 3,
  // },
]
// const activityTabs2 = [
//   {
//     name: `Customer Communications`,,
//     number: 2,
//   },
// ]

export const Activity = () => {
  const { activeActivityTab } = useSelector(({ customer }) => customer)
  const dispatch = useDispatch()
  return (
    <div className="px-2 z-0">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([
            `notes:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ])
            ? activityTabs
            : activityTabs2
        }
        getActiveTab={(val) => dispatch(setActivityActiveTab(val))}
      />
      {
        permissionGranted(
          [`notes:read`, `corporate-manager:*`, `manager:*`, `admin:*`] || [
            `notes:*`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ],
        ) && (
          <div className="tab-content tab-space">
            <TOne opentab={activeActivityTab}>
              <OtherCustomerNote />
            </TOne>

            {/* <TTwo opentab={activeActivityTab}>
            <Communications />
          </TTwo> */}
          </div>
        )
        //  : (
        //   <div className="tab-content tab-space">
        //     <TOne opentab={activeActivityTab}>
        //       <Communications />
        //     </TOne>
        //   </div>
        // )
      }
    </div>
  )
}
