import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from '../style'
import { PolicyReportPdfHeader } from 'App/Components/Common/PolicyReportPdfHeader'
import dateFormat from 'App/Services/General/dateFormat'

export const Pdf = ({ bookOfBusiness, dateRange = [] }) => {
  return (
    <Document subject="Report" author="Reports">
      <Page style={styles.page} size="A3" wrap>
        <View style={styles.section}>
          <Text style={styles.pageHeading}>Book Of Business</Text>
          <Text style={styles.smallSpace}></Text>
          <PolicyReportPdfHeader dateRange={dateRange} />
          <Text style={styles.smallSpace}></Text>

          <View style={styles.headingRow}>
            <Text style={styles.pageTitles}> Policy No</Text>
            <Text style={styles.pageTitles}> LOB</Text>
            <Text style={styles.pageTitles}> Insured</Text>
            <Text style={styles.pageTitles}> Eff. Date</Text>
            <Text style={styles.pageTitles}> Premium</Text>
            <Text style={styles.pageTitles}> Agency Com</Text>
          </View>

          {bookOfBusiness.length > 0 &&
            bookOfBusiness.map((bookOfBusiness, index) => (
              <View style={styles.agent} key={index}>
                <View style={styles.headingRow2}>
                  <Text style={styles.pageTitles2}>
                    {bookOfBusiness?.policyNo}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {bookOfBusiness?.lob ? bookOfBusiness?.lob : `N/A`}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {bookOfBusiness?.insured ? bookOfBusiness?.insured : `N/A`}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {dateFormat(bookOfBusiness?.effectiveDate)}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {bookOfBusiness?.totalPremium
                      ? `$ ${bookOfBusiness?.totalPremium?.toFixed(2)}`
                      : `-`}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {bookOfBusiness?.agencyCommission
                      ? `$ ${bookOfBusiness?.agencyCommission?.toFixed(2)}`
                      : `-`}
                  </Text>
                </View>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  )
}
