import { OIDC_BACKEND_API } from 'App/Config'
import { resetPersistantState, setUserAccessToken } from 'App/Redux/actions'
import { store } from 'App/Redux/store'
import axios from 'axios'

export const getAccessToken = async () => {
  const { user } = store.getState()

  if (
    new Date().getTime() >
    new Date(localStorage.getItem(`accessTokenExpiry`)).getTime()
  ) {
    try {
      await axios
        .get(`${OIDC_BACKEND_API}/api/user/get-access-token`, {
          headers: {
            authorization: `Bearer ${localStorage.getItem(`refreshToken`)}`,
          },
        })
        .then((res) => {
          const refreshAccessToken = res?.data?.data
          localStorage.setItem(`accessToken`, refreshAccessToken?.accessToken)
          localStorage.setItem(
            `accessTokenExpiry`,
            refreshAccessToken?.accessTokenExpiry,
          )
          store.dispatch(setUserAccessToken(refreshAccessToken?.accessToken))
          return user
        })
        .catch((err) => {
          if (err?.response?.status !== 401) {
            localStorage.clear()
            store.dispatch(resetPersistantState())
          }
          return err
        })
    } catch (error) {
      if (error?.response?.status !== 401) {
        localStorage.clear()
        store.dispatch(resetPersistantState())
      }
      return error
    }
  } else {
    return user
  }
}
