import { DatePickerField } from 'App/Components/Common/DatePicker'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Spinner } from 'App/Components/Common/Spinner'
import { AMS_BACKEND_API, AxiosInstance, policyTermLabels } from 'App/Config'
import { loadPolicyTerm } from 'App/Services/quoteForm'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min'
import { useDebouncedCallback } from 'use-debounce'

export const PolicyUpdate = () => {
  const {
    params: { policyId, customerId },
  } = useRouteMatch()
  const history = useHistory()
  const { currentPolicy } = useSelector(({ policy }) => policy)
  const [policyData, setPolicyData] = useState({
    effectiveDate: ``,
    expiryDate: ``,
    cancellationDate: ``,
    policyTerm: ``,
    policyNo: ``,
  })

  const [validatingPolicyNo, setValidatingPolicyNo] = useState(false)
  const [errors, setErrors] = useState({
    effectiveDateError: ``,
    expiryDateError: ``,
    formSubmit: false,
    policyTermError: ``,
    policyNoError: ``,
  })

  const handlePolicyDataChange = (name, value) => {
    setPolicyData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const handleErrors = (name, value) => {
    setErrors((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  useEffect(() => {
    if (currentPolicy) {
      setPolicyData({
        effectiveDate: new Date(currentPolicy?.policyData?.effectiveDate),
        expiryDate: new Date(currentPolicy?.policyData?.expiryDate),
        cancellationDate: currentPolicy?.policyData?.cancelDate
          ? new Date(currentPolicy?.policyData?.cancelDate)
          : ``,
        policyTerm: {
          label: policyTermLabels[currentPolicy?.policyData?.policyTerm],
          value: currentPolicy?.policyData?.policyTerm,
        },
        policyNo: currentPolicy?.policyNo,
      })
    }
  }, [currentPolicy])

  const validateForm = () => {
    if (!policyData.policyTerm?.value) {
      handleErrors(`policyTermError`, `Select Policy Term`)
    } else {
      handleErrors(`policyTermError`, ``)
    }
    if (!policyData.effectiveDate) {
      handleErrors(`effectiveDateError`, `Select Date`)
    } else {
      handleErrors(`effectiveDateError`, ``)
    }
    if (!policyData.expiryDate) {
      handleErrors(`expiryDateError`, `Select Date`)
    } else {
      handleErrors(`expiryDateError`, ``)
    }

    if (!policyData?.policyNo?.length) {
      handleErrors(`policyNoError`, `Enter Policy No`)
    } else if (
      policyData?.policyNo.length &&
      errors.policyNoError.includes(`Policy No Already Exists`)
    ) {
      handleErrors(`policyNoError`, `Policy No Already Exists`)
    } else {
      handleErrors(`policyNoError`, ``)
    }
    handleErrors(`formSubmit`, true)
  }

  const {
    mutate: updateAgent,
    isLoading: updatingAgent,
    // isError: updatingError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(`${AMS_BACKEND_API}/api/policies/${policyId}`, {
        ...payload,
      })
        .then((res) => {
          if (res.data) {
            history.push(
              customerId
                ? `/customers/${customerId}/policies/${policyId}`
                : `/policy/${policyId}`,
            )

            NotificationManager.success(`Operation successful`)
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const ValidateDuplicatePolicyNo = async (value) => {
    if (value) {
      return await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/validate/policy-payloads`,
        {
          policyNo: value,
        },
      )
        .then((response) => {
          return response?.data
        })
        .catch((error) => {
          return error?.response?.data
        })
    }
  }

  const validateName = useDebouncedCallback(async (value) => {
    if (value) {
      setValidatingPolicyNo(true)
      const response = await ValidateDuplicatePolicyNo(value)
      if (response?.hasError) {
        setValidatingPolicyNo(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`policyNoError`, `Policy No Already Exists`)
      } else {
        setValidatingPolicyNo(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`policyNoError`, ``)
      }
    }
  }, 1500)

  const handlePolicyNo = (value) => {
    const trimmedValue = value?.trim()
    handlePolicyDataChange(`policyNo`, value)
    if (trimmedValue && trimmedValue !== currentPolicy?.policyNo)
      validateName(trimmedValue)
  }

  const updatePayload = () => {
    const data = {}
    const _policyData = {}
    if (
      policyData?.policyTerm?.value?.toString() !==
      currentPolicy?.policyData?.policyTerm.toString()
    ) {
      _policyData.policyTerm = parseInt(policyData?.policyTerm?.value)
    }

    if (
      policyData?.expiryDate.getTime() !==
      new Date(currentPolicy?.policyData?.expiryDate).getTime()
    ) {
      _policyData.expiryDate = policyData?.expiryDate
    }

    if (
      policyData?.effectiveDate.getTime() !==
      new Date(currentPolicy?.policyData?.effectiveDate).getTime()
    ) {
      _policyData.effectiveDate = policyData?.effectiveDate
    }

    if (
      policyData?.cancellationDate &&
      policyData?.cancellationDate?.getTime() !==
        new Date(currentPolicy?.policyData?.cancelDate)?.getTime()
    ) {
      _policyData.cancelDate = policyData?.cancellationDate ?? ``
    }

    if (policyData?.policyNo !== currentPolicy?.policyNo) {
      data.policyNo = policyData?.policyNo
    }

    if (Object.keys(_policyData)?.length > 0) {
      data[`policyData`] = { ..._policyData }
    }

    return data
  }

  useEffect(() => {
    if (
      errors?.formSubmit &&
      !errors?.policyTermError?.length &&
      !errors?.effectiveDateError?.length &&
      !errors?.policyNoError?.length &&
      !errors?.expiryDateError?.length
    ) {
      updateAgent(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  return (
    <Container>
      <Spinner loading={updatingAgent} />
      <PageHeader>Update Policy</PageHeader>
      <div className="grid grid-cols-4 gap-4 px-4">
        <div className="flex-1 relative">
          <InputField
            type="text"
            title="Policy No *"
            placeholder="Enter Policy No"
            errorMessage={errors.policyNoError}
            value={policyData?.policyNo}
            setValue={(e) => handlePolicyNo(e.target.value)}
            maxLength={20}
            validating={validatingPolicyNo}
          />
        </div>
        <div className="relative">
          <ReactSelectField
            title="Policy Term"
            placeholder="Select Term"
            errorMessage={errors.policyTermError}
            value={policyData?.policyTerm}
            setValue={(e) => handlePolicyDataChange(`policyTerm`, e)}
            isMulti={false}
            loadOptions={loadPolicyTerm}
            isSearchable={false}
          />
        </div>
        <div className="relative">
          <DatePickerField
            title="Effective Date *"
            placeholder="Enter Date"
            value={policyData?.effectiveDate}
            errorMessage={errors.effectiveDateError}
            setValue={(date) => handlePolicyDataChange(`effectiveDate`, date)}
            minDate={new Date()}
          />
        </div>
        <div className="relative">
          <DatePickerField
            title="Expiry Date *"
            placeholder="Enter Date"
            value={policyData?.expiryDate}
            errorMessage={errors.expiryDateError}
            setValue={(date) => handlePolicyDataChange(`expiryDate`, date)}
            minDate={new Date()}
          />
        </div>
        <div className="relative">
          <DatePickerField
            title="Cancelation Date *"
            placeholder="Enter Date"
            value={policyData?.cancellationDate}
            //   errorMessage={errors.effectiveDateError}
            setValue={(date) =>
              handlePolicyDataChange(`cancellationDate`, date)
            }
            minDate={new Date()}
          />
        </div>
      </div>
      <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
        <SubmitButton
          onClick={validateForm}
          disabled={updatingAgent || validatingPolicyNo}
        >
          <div>Update Policy</div>
        </SubmitButton>
      </div>
    </Container>
  )
}
