import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { TOne, TThree, TTwo, TFour } from 'App/Styled'
import { error, warning } from 'App/Assets'
import { ReactModal } from 'App/Components/Common/Modal'
import { QuoteOfferPdf } from '../QuoteOffers/QuoteOfferModal'

const QuoteTabs = [
  {
    name: `Warnings`,
    number: 1,
  },
  {
    name: `Errors`,
    number: 2,
  },
  {
    name: `Fatal Errors`,
    number: 3,
  },
  {
    name: `Description`,
    number: 4,
  },
]

export const QuoteCard = ({ data }) => {
  const [activeTab, setActiveTab] = useState(1)
  const [collapse, setCollapse] = useState(false)
  const [quoteOfferSelectModal, setQuoteOfferSelectModal] = useState(false)

  return (
    <div
      className={`border-1 shadow-sm rounded-md p-2 mt-2 ${collapse && `h-96`}`}
    >
      <div className="grid grid-cols-4 gap-x-2 ">
        <div className="flex">
          <span className="mr-2">
            <FontAwesomeIcon
              className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                collapse && `rotate-180 text-blue-600`
              }`}
              icon={faChevronCircleDown}
              onClick={() => {
                setCollapse(!collapse)
              }}
            />
          </span>
          <span>{data?.carrier.length ? data?.carrier : `N/A`}</span>
        </div>
        <div className="align-middle">
          <span>{data?.plan?.amountPerPayment.toFixed(2)}</span>
          <span>/months ({data?.plan?.payments})</span>
        </div>
        <div>
          <p>
            Down Payment:
            <span className="text-red-500 font-semibold">
              $ {parseFloat(data?.plan?.downPayment?.toFixed(3))}
            </span>
          </p>
          <p>
            Total Payment:
            <span className="text-red-500 font-semibold">
              $ {data?.plan?.totalPremium}
            </span>
          </p>
        </div>
        <div className="flex flex-col items-center gap-y-1">
          <button
            onClick={() => setQuoteOfferSelectModal(true)}
            className="w-20 bg-red-500 text-white rounded-sm font-bold"
          >
            Select
          </button>

          <a href={data?.bridgeLink} target="_blank" rel="noreferrer">
            <button className="w-20 bg-red-500 text-white rounded-sm font-bold">
              Bridge
            </button>
          </a>

          {quoteOfferSelectModal && (
            <ReactModal
              title="Offer Details"
              width="full"
              height="auto"
              scroll={true}
              closeModal={() => {
                setQuoteOfferSelectModal(false)
              }}
            >
              <QuoteOfferPdf quoteOfferedData={data} />
            </ReactModal>
          )}
        </div>
      </div>
      {collapse && (
        <div className="px-2">
          <Tabs2
            openTab={activeTab}
            tabs={QuoteTabs}
            getActiveTab={(val) => setActiveTab(val)}
          />
          <div className="tab-content tab-space">
            <TOne opentab={activeTab}>
              {data.warnings.length > 0 ? (
                <div className="my-1 h-60 overflow-y-scroll hide-scroller hide-scroller-firefox">
                  {data.warnings.map((data, index) => (
                    <div
                      key={index}
                      className="w-full p-2 border-1 bg-white rounded-md"
                    >
                      <div className="flex">
                        <img src={warning} alt="img" className="h-5 w-5" />
                        <div className="ml-2">{data.Text}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center my-6 text-sm text-gray-400 font-bold">
                  No Warnings
                </div>
              )}
            </TOne>

            <TTwo opentab={activeTab}>
              {data.errors.length > 0 ? (
                <div className="my-1 h-60 overflow-y-scroll hide-scroller hide-scroller-firefox">
                  {data.errors.map((data, index) => (
                    <div
                      key={index}
                      className="w-full p-2 border-1 bg-white rounded-md"
                    >
                      <div className="flex">
                        <img src={error} alt="img" className="h-5 w-5" />
                        <div className="ml-2">{data.Text}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center my-6 text-sm text-gray-400 font-bold">
                  No Errors
                </div>
              )}
            </TTwo>

            <TThree opentab={activeTab}>
              {data.fatalErrors.length > 0 ? (
                <div className="my-1 h-60 overflow-y-scroll hide-scroller hide-scroller-firefox">
                  {data.fatalErrors.map((data, index) => (
                    <div
                      key={index}
                      className="w-full p-2 border-1 bg-white rounded-md"
                    >
                      <div className="flex">
                        <img src={error} alt="img" className="h-5 w-5" />
                        <div className="ml-2">{data.Text}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center my-6 text-sm text-gray-400 font-bold">
                  No Fatal Errors
                </div>
              )}
            </TThree>

            <TFour opentab={activeTab}>
              <div className="w-full p-2 border-1 bg-white rounded-md">
                {data?.plan?.description.length > 0
                  ? data?.plan?.description
                  : `No Description Available`}
              </div>
            </TFour>
          </div>
        </div>
      )}
    </div>
  )
}
