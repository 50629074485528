/* eslint-disable no-unused-vars */
import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TOne, TThree, TTwo } from 'App/Styled'
import { setDataTrasActivityActiveTab } from 'App/Redux/actions'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { Communications } from 'App/Components/Communications'
import { DataTransactionNotes } from '../../DataTransactionNotes'
import { permissionGranted } from 'App/Services'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  // {
  //   name: `Logs`,
  //   number: 2,
  // },
  {
    name: `Customer Communications`,
    number: 2,
  },
]
const activityTabs2 = [
  {
    name: `Customer Communications`,
    number: 1,
  },
]

export const DataTransactionActivity = () => {
  const { currentAgent, activeActivityTab } = useSelector(
    ({ dataTransactions }) => dataTransactions,
  )
  const dispatch = useDispatch()
  return (
    <div className="px-2">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([
            `notes:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ])
            ? activityTabs
            : activityTabs2
        }
        getActiveTab={(val) => dispatch(setDataTrasActivityActiveTab(val))}
      />
      {permissionGranted(
        [`notes:read`, `corporate-manager:*`, `manager:*`, `admin:*`] || [
          `notes:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      ) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <DataTransactionNotes />
          </TOne>

          {/* <TTwo opentab={activeActivityTab}> */}
          {/* <UpdateLogs
            logs={currentAgent?.updateLogs ? currentAgent?.updateLogs : []}
          /> */}
          {/* </TTwo> */}

          <TTwo opentab={activeActivityTab}>
            <Communications />
          </TTwo>
        </div>
      ) : (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <Communications />
          </TOne>
        </div>
      )}
    </div>
  )
}
