import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { Reload } from 'App/Components/Common/Reload'
import { AxiosInstance, offset } from 'App/Config'
import { AMS_BACKEND_API } from 'App/Config'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { isSuperUser, permissionGranted } from 'App/Services'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faSortUp,
  faSortDown,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
import dateFormat from 'App/Services/General/dateFormat'
import CredentialFilters from '../CredentialFilters'

const tableColumns = [
  { label: `Name`, value: `name` },
  { label: `Carrier`, value: `_carrierId.label` },
  // { label: `User Name`, value: `userName` },
  { label: `Producer Code`, value: `producerCode` },
  // { label: `Password`, value: `password` },
  { label: `Created`, value: `createdAt` },
]

export const AllCredentials = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [sort, setSort] = useState(``)
  const history = useHistory()
  const { url } = useRouteMatch()
  // const user = useSelector(({ user: { user } }) => user)
  const [credentialsFilters, setCredentialsFilters] = useState({
    name: ``,
    producerCode: ``,
  })
  // fetching all credentials
  const {
    isLoading,
    data: credentialsData,
    error,
    refetch: getAllDataCredentials,
    isFetching,
  } = useQuery(
    `CredentialsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/credentials/all?search=${searchFilters()}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  // delete franchise
  const {
    mutate: deleteCredential,
    isLoading: deletingFranchise,
    isError: deletionError,
    error: reponseError,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/credentials/${id}`,
      ).then((res) => {
        NotificationManager.success(`Deleted Successfully`)
        res.data && getAllDataCredentials()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllDataCredentials()
    }
  }, [sort, currentPage, isOffset, credentialsFilters])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteCredential(id)
  }

  const handleResetFilters = () => {
    setCredentialsFilters({
      name: ``,
      producerCode: ``,
    })
  }

  const handleFiltersChange = (filters) => {
    setCurrentPage(1)
    setCredentialsFilters(filters)
  }

  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }

    if (credentialsFilters?.name?.length) {
      data.filters.name = credentialsFilters?.name
    }

    if (credentialsFilters?.producerCode?.length) {
      data.filters.producerCode = credentialsFilters?.producerCode
    }

    return JSON.stringify(data)
  }

  return (
    <Container>
      <Spinner loading={isLoading || isFetching || deletingFranchise} />
      {deletionError && (
        <ReactNotification
          action="error"
          message={reponseError?.response?.data?.error?.error}
        />
      )}
      <PageHeader>
        <div>Credentials</div>
        <div className="flex items-center">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callFranchiseQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>
      <CredentialFilters
        filtersProps={credentialsFilters}
        handleFilters={handleFiltersChange}
        resetFilters={handleResetFilters}
        isLoading={isLoading || isFetching}
      />
      <div className="flex justify-end items-center p-2">
        {permissionGranted([`super-admin:*`, `credential-master:*`]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 pt-1 ${
          (isLoading || isFetching || deletingFranchise) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllDataCredentials()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(isSuperUser(`super-admin:*`) ||
                      isSuperUser(`credential-master:*`)) && (
                      <TableCell className="text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {credentialsData?.credentials?.length != 0 ? (
                    credentialsData?.credentials?.map((credential, index) => (
                      <TableRow
                        key={index.toString()}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          history.push(`${url}/${credential._id}`)
                        }}
                      >
                        <td className="px-4 py-0.5 text-sm z-0 capitalize truncate">
                          {credential?.name ? (
                            credential?.name
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 text-sm z-0 capitalize truncate">
                          {credential?._carrierId?.label ? (
                            credential?._carrierId?.label?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>

                        {/* <td className="px-4 py-0.5 text-sm z-0"></td> */}
                        <td className="px-4 py-0.5 text-sm z-0">
                          {credential?.producerCode ? (
                            <Badge type="success">
                              {credential?.producerCode}
                            </Badge>
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        {/* <td className="px-4 py-0.5 text-sm z-0">
                          {credential?.password}
                        </td> */}
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <span>{dateFormat(credential?.createdAt)}</span>
                        </td>
                        {/* Actions TD */}
                        {(isSuperUser(`super-admin:*`) ||
                          isSuperUser(`credential-master:*`)) && (
                          <td className="px-8 py-0.5 z-0 text-sm flex float-right text-gray-400">
                            <span data-tip="Delete" className="tooltip">
                              <FontAwesomeIcon
                                className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                icon={faTrash}
                                size="1x"
                                onClick={(e) => handleDelete(credential._id, e)}
                              />
                            </span>
                          </td>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Data Found</div>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </table>
            </div>

            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={credentialsData?.totalPages}
                totalRecords={credentialsData?.totalRecords}
                curerntPageRecords={credentialsData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
