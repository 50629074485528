import React from 'react'
import { CenterContainer } from './style'
import { useQuery } from 'react-query'
import { UNSPLASH_CLIENT_ID, UNSPLASH_ENDPOINT } from 'App/Config'
import { Login } from './login'
import { useRouteMatch } from 'react-router-dom'
import { FindUser } from './findUser'
import { SetPassword } from './setPassword'
import axios from 'axios'

export const Landing = () => {
  const { url } = useRouteMatch()
  const { data } = useQuery(
    `LoadImageApi`,
    async () =>
      await axios
        .get(
          `${UNSPLASH_ENDPOINT}/photos/random?query=city&client_id=${UNSPLASH_CLIENT_ID}`,
        )
        .then((res) => {
          return res.data
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  return (
    <div className="flex flex-wrap w-full">
      <div className="flex flex-col w-full md:w-1/2 sm-2:w-1/2">
        <CenterContainer>
          {(() => {
            switch (url) {
              case `/login`:
                return <Login />
              case `/find-user`:
                return <FindUser />
              case `/set-password`:
                return <SetPassword />
              default:
                return null
            }
          })()}
        </CenterContainer>
      </div>
      <div className="w-1/2 shadow-2xl md:w-1/2 sm-2:w-1/2">
        <img
          className="object-cover w-full h-screen"
          src={data?.urls?.regular}
        />
      </div>
    </div>
  )
}
