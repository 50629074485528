import tw from 'tailwind-styled-components'

export const PublicStyledButton = tw.button`
${({ disabled }) =>
  disabled ? `bg-gray-500 cursor-not-allowed` : `bg-red-500 hover:bg-red-700 `} 
    py-2
    px-4 
    w-full 
    flex 
    justify-center 
    items-center  
    
    focus:ring-red-500 
    focus:ring-offset-red-200 
    text-white transition 
    ease-in duration-200 
    text-center text-base 
    font-semibold 
    shadow-md 
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2 
    rounded-lg 
`
export const CenterContainer = tw.div`
    flex 
    flex-col 
    justify-center 
    w-full 
    px-16 
    pt-44
    my-auto 
    md:justify-start 
    md:pt-0 
    md:px-24 
    lg:px-52
`
