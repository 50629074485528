import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { VehicleNote } from '../VehicleNote'
import { setVehicleAsset } from 'App/Redux/actions'
import { Reload } from 'App/Components/Common/Reload'
import { useHistory, useRouteMatch } from 'react-router'
import { CardField } from 'App/Components/Common/CardField'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Container, EditStyledButton, PageHeader } from 'App/Styled'
import { Logs } from 'App/Components/Common/Logs'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { permissionGranted } from 'App/Services'
import dateFormat from 'App/Services/General/dateFormat'

export const Vehicle = () => {
  const {
    url,
    params: { vehicleId, customerId },
  } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    data: vehiclePreviewApi,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `VehcilePreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/assets/${customerId}/type/vehicles/item/${vehicleId}`,
      ).then((res) => {
        return res.data.data.item.vehicles
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setVehicleAsset(res))
      },
    },
  )

  return (
    <>
      <Container>
        <PageHeader>
          <div>Vehicle</div>
          {permissionGranted([
            `assets:update`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && (
            <EditStyledButton onClick={() => history.push(`${url}/update`)}>
              <span>Edit</span>
              <FontAwesomeIcon
                icon={faPen}
                className="text-white mt-0.5"
                size="sm"
              />
            </EditStyledButton>
          )}
        </PageHeader>

        {isLoading || isFetching ? (
          <div className="text-center py-10">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : (
          <>
            <div className="items-start flex lg:flex-row px-4 pt-4">
              {/* about */}
              <div className="flex-1 m-2 shadow-md">
                <Collapsable title="About">
                  <div className="px-2">
                    <CardField title="Make" value={vehiclePreviewApi.make} />
                    <CardField title="Model" value={vehiclePreviewApi.model} />
                    <CardField title="Year" value={vehiclePreviewApi.year} />
                    <CardField title="Type" value={vehiclePreviewApi.type} />
                    <CardField title="Value" value={vehiclePreviewApi.value} />
                    <CardField
                      title="State"
                      value={vehiclePreviewApi.registrationState}
                    />
                    <CardField
                      title="Vin Number"
                      value={vehiclePreviewApi.vinNumber}
                    />
                    <CardField
                      title="Expiration Date"
                      value={dateFormat(
                        vehiclePreviewApi.registrationExpirationDate,
                      )}
                    />
                    <CardField title="Usage" value={vehiclePreviewApi.usage} />
                    <CardField
                      title="Deductable"
                      value={vehiclePreviewApi.deductable}
                    />
                    <CardField
                      title="Deductable Collision"
                      value={vehiclePreviewApi.deductableCollision}
                    />
                    <CardField
                      title="Description"
                      value={vehiclePreviewApi.description}
                    />

                    <CardField
                      title="Visible"
                      value={vehiclePreviewApi.isVisible.toString()}
                    />
                  </div>
                </Collapsable>
              </div>
              {/* about end */}

              {/* address */}
              <div className="flex-1 m-2 shadow-md">
                <Collapsable title="Address">
                  <div className="px-2">
                    <CardField
                      title="Unit No"
                      value={vehiclePreviewApi?.garageAddress.unitNo}
                    />

                    <CardField
                      title="Zip Code"
                      value={vehiclePreviewApi?.garageAddress.zip}
                      badge={true}
                    />

                    <CardField
                      title="Street"
                      value={vehiclePreviewApi?.garageAddress.street}
                    />

                    <CardField
                      title="City"
                      value={vehiclePreviewApi?.garageAddress.city}
                    />

                    <CardField
                      title="State"
                      value={vehiclePreviewApi?.garageAddress.state}
                    />

                    <CardField
                      title="Coordinates"
                      value={vehiclePreviewApi?.garageAddress.coordinates}
                      coord={true}
                    />
                  </div>
                </Collapsable>
              </div>
            </div>
            <div className="items-start flex lg:flex-row px-4 ">
              <div className="flex-1 m-2 shadow-md">
                <Collapsable title="Additional">
                  <div className="px-2">
                    <CardField
                      title="Garaging"
                      value={vehiclePreviewApi.garaging}
                    />
                    <CardField
                      title="Garaging Radius"
                      value={vehiclePreviewApi.garaingRadius}
                    />
                    <CardField
                      title="Body Type"
                      value={vehiclePreviewApi.bodyType}
                    />
                    <CardField
                      title="New Vehicle"
                      value={vehiclePreviewApi.newVehicle}
                    />
                    <CardField
                      title="Cost New"
                      value={vehiclePreviewApi.costNew}
                    />
                    <CardField
                      title="Altered Vehicle"
                      value={vehiclePreviewApi.alteredVehicle}
                    />
                    <CardField
                      title="Alteratoins Amount"
                      value={vehiclePreviewApi.alterationAmount}
                    />
                    <CardField
                      title="Length"
                      value={vehiclePreviewApi.length}
                    />
                    <CardField
                      title="License Plate Number"
                      value={vehiclePreviewApi.licensePlateNumber}
                    />
                    <CardField
                      title="Full Term Premium"
                      value={vehiclePreviewApi.fullTermPremium}
                    />
                    <CardField
                      title="Vehicle Weight"
                      value={vehiclePreviewApi.vehicleWeight}
                    />
                    <CardField
                      title="Number Of Cylinders"
                      value={vehiclePreviewApi.numberOfCylinders}
                    />

                    <CardField
                      title="Displacement"
                      value={vehiclePreviewApi.displacement}
                    />

                    <CardField
                      title="Rate Class Code"
                      value={vehiclePreviewApi.rateClassCode}
                    />

                    <CardField
                      title="Physical Demage Rate Class"
                      value={vehiclePreviewApi.physicaldemagerateClass}
                    />
                    <CardField
                      title="Leased"
                      value={vehiclePreviewApi?.leased}
                    />

                    <CardField
                      title="Seen Car"
                      value={vehiclePreviewApi?.isSeenCar.toString()}
                    />

                    <CardField
                      title="Multi Car Disount"
                      value={vehiclePreviewApi?.multiCarDisount}
                    />

                    <CardField
                      title="Damage Liability"
                      value={vehiclePreviewApi?.damageLiability}
                    />

                    <CardField
                      title="Bumber Discount"
                      value={vehiclePreviewApi?.bumberDiscount}
                    />
                    <CardField
                      title="Performance"
                      value={vehiclePreviewApi?.performance}
                    />
                    <CardField
                      title="Seat Belt Type"
                      value={vehiclePreviewApi?.seatBeltType}
                    />
                    <CardField
                      title="AirBag Type"
                      value={vehiclePreviewApi?.airBagType}
                    />
                  </div>
                </Collapsable>
              </div>

              <div className="flex-1 m-2 shadow-md">
                <Collapsable title="Additional">
                  <div className="px-2">
                    <CardField
                      title="Miles To Work"
                      value={vehiclePreviewApi?.milesToWork}
                    />
                    <CardField title="Use" value={vehiclePreviewApi?.use} />
                    <CardField
                      title="Days Driver Per Week"
                      value={vehiclePreviewApi?.daysDriverPerWeek}
                    />
                    <CardField
                      title="Car Pool"
                      value={vehiclePreviewApi?.carPool}
                    />
                    <CardField
                      title="ODO Meter Reading"
                      value={vehiclePreviewApi?.odoMeterReading}
                    />
                    <CardField
                      title="Estimated Annual Miles"
                      value={vehiclePreviewApi?.estimatedAnnualMiles}
                    />
                    <CardField
                      title="Existing Unrepaired Demage"
                      value={vehiclePreviewApi?.existingUnrepairedDemage}
                    />
                    <CardField
                      title="Residual Market Facility"
                      value={vehiclePreviewApi?.residualMarketFacility}
                    />

                    <CardField
                      title="Anti Theft Percent"
                      value={vehiclePreviewApi?.antiTheftPercent}
                    />

                    <CardField
                      title="Anti Lock Brake"
                      value={vehiclePreviewApi?.antiLockBrake}
                    />

                    <CardField
                      title="Number Of Axis"
                      value={vehiclePreviewApi?.numberOfAxis}
                    />

                    <CardField
                      title="Maximum Speed in Miles"
                      value={vehiclePreviewApi?.maximumSpeed}
                    />
                    <CardField
                      title="Number Of Youthful Operators"
                      value={vehiclePreviewApi?.numberOfYouthfulOperators}
                    />
                    <CardField
                      title="Engine Type"
                      value={vehiclePreviewApi?.enginetype}
                    />
                    <CardField
                      title="Inspection Status"
                      value={vehiclePreviewApi?.inspectionStatus}
                    />
                    <CardField
                      title="Collision Symbol"
                      value={vehiclePreviewApi?.collisionSymbol}
                    />
                    <CardField
                      title="Comprehensive Symbol"
                      value={vehiclePreviewApi?.comprehensiveSymbol}
                    />
                    <CardField
                      title="Registered"
                      value={vehiclePreviewApi?.isRegistered.toString()}
                    />
                    <CardField
                      title="Non Owned"
                      value={vehiclePreviewApi?.isNonOwned.toString()}
                    />
                    <CardField
                      title="Anti Theft Device"
                      value={vehiclePreviewApi?.isAntiThefdevice.toString()}
                    />
                  </div>
                </Collapsable>
              </div>
            </div>
            <div className="items-start flex lg:flex-row px-4">
              <div className="flex-1 m-2 shadow-md">
                <Collapsable title="Coverages">
                  {vehiclePreviewApi?.Coverages.map((coverage, index) => (
                    <div key={index} className="px-2">
                      <div className="grid grid-cols-8 my-2 justify-items-start border-b-1 text-sm">
                        <div className="col-span-2 text-red-700">
                          {coverage.coverage}
                        </div>
                        <div className="col-span-1">-</div>
                        <div className=" col-span-2 flex flex-wrap  font-medium my-auto">
                          {coverage.limit ? coverage.limit : `Not Provided`}
                        </div>
                        <div className="col-span-1">-</div>
                        <div className=" col-span-2 flex flex-wrap  font-medium my-auto">
                          {coverage.premium ? coverage.premium : `Not Provided`}
                        </div>
                      </div>
                    </div>
                  ))}
                </Collapsable>
              </div>

              <div className="flex-1 m-2 shadow-md">
                <Collapsable title="Lien Holders">
                  {vehiclePreviewApi?.lienHolders.map((holder, index) => (
                    <div key={index} className="px-2">
                      <CardField
                        title={holder.type.replaceAll(`_`, ` `)}
                        value={holder.natureOfInterest.replaceAll(`_`, ` `)}
                      />
                    </div>
                  ))}
                </Collapsable>
              </div>
            </div>

            {/* <div className="items-start flex lg:flex-row px-4 pr-28">
              <div className="flex-1 m-2 shadow-md">
                <Collapsable title="Drivers">
                  {vehiclePreviewApi?.drivers.map((driver, index) => (
                    <div key={index} className="px-2">
                      <div className="grid grid-cols-8 my-2 justify-items-start border-b-1 text-sm">
                        <div className="col-span-1 text-red-700">
                          {driver.name}
                        </div>
                        <div className="col-span-1">-</div>
                        <div className=" col-span-2 flex flex-wrap  font-medium my-auto">
                          {driver.phone ? driver.phone : `Not Provided`}
                        </div>
                        <div className="col-span-1">-</div>
                        <div className=" col-span-3 flex  flex-wrap font-medium my-auto">
                          <span className="mx-0.5">
                            {driver.address.unitNo + `,  `}
                          </span>
                          <span className="mx-0.5">
                            {driver.address.street + `, `}
                          </span>
                          <span className="mx-0.5">
                            {driver.address.city + `, `}
                          </span>
                          <span className="mx-0.5">
                            {driver.address.state + `, `}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </Collapsable>
              </div>
            </div> */}
          </>
        )}
      </Container>
      <div className="flex -mt-3">
        <div className="flex-1">
          <VehicleNote />
        </div>
        <div className="flex-1">
          <Logs
            logs={
              vehiclePreviewApi?.updateLogs ? vehiclePreviewApi?.updateLogs : []
            }
          />
        </div>
      </div>
    </>
  )
}
