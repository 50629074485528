import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentGoal: ``,
  activeActivityTab: 1,
}

const monthlyGoalSlice = createSlice({
  name: `monthlyGoal`,

  initialState,
  reducers: {
    setCurrentAllMonthlyGoals: (state, { payload }) => {
      state.currentGoal = payload
    },
    setMonthlyGoalActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
  },
})

export const { setCurrentAllMonthlyGoals, setMonthlyGoalActivityActiveTab } =
  monthlyGoalSlice.actions

export const monthlyGoal = monthlyGoalSlice.reducer
