import React from 'react'

import { useBreadCrumbs } from 'App/hooks/breadCrumbs'
import { useHistory } from 'react-router-dom'
const BreadCrumbs = () => {
  const history = useHistory()
  const [breadCrumbs, setBreadCrumbs] = useBreadCrumbs()
  return (
    <div className="flex my-auto ml-0">
      <div className="ml-2">
        {breadCrumbs?.map((data, index) => (
          <button
            onClick={() => {
              history.push(data?.path)

              //   let index = state.breadCrumbs.findIndex(
              //     (data) => data.path === payload.path,
              //   )
              //   if (index === -1) {
              //     state.breadCrumbs.push(payload)
              //   }

              const breadCrumbs1 = [...breadCrumbs]
              breadCrumbs1?.splice(index + 1)
              setBreadCrumbs(breadCrumbs1)
            }}
            key={index}
            className={`text-cool-gray-50 ml-2 hover:bg-gray-600 rounded-full px-2 py-0.5  capitalize ${
              index == breadCrumbs?.length - 1 ? `bg-gray-600  p-2` : ``
            }`}
          >
            {data?.title?.toString()}
          </button>
        ))}
      </div>
    </div>
  )
}

export default BreadCrumbs
