import tw from 'tailwind-styled-components'

// transition-width duration-50 ease-in-out
export const SideBarWrapper = tw.div`
  top-0 
  left-0
  z-10  
  sm:block   
  shadow-sm
  sm-2:block
  bg-sideBarColor
  ${({ collapsed }) => (collapsed === `true` ? `w-20` : ` w-72`)}      
`

export const Brand = tw.div`
  relative 
  flex
  items-center
  justify-center
  h-16
  border-b-1
  border-gray-400
  bg-sideBarColor
`
// transition duration-300 ease-in-out
export const BrandImage = tw.img`
${({ collapsed }) => collapsed === `true` && `pt-5 pb-3`}      
`
export const UserImage = tw.img`
${({ latest }) => (latest === `true` ? `ring-red-500` : `ring-gray-300`)}  
  mx-auto 
  object-cover 
  my-2
  ring-offset-2 
  ring-1
  rounded-full 
  cursor-pointer
`

export const CollapseClip = tw.div`
  w-6 
  h-5 
  flex 
  justify-center 
  rounded 
  bg-red-500 
  cursor-pointer
`

export const gradientActiveClass = `
  bg-gradient-to-l from-red-500 to-red-600
  border-r-4 border-red-500 
  dark:from-gray-700 dark:to-gray-800
  border-r-4 border-red-500
`
export const SubMenu = tw.div`
  
  p-2
  flex-1
  text-sm
  text-gray-200
  transition-colors duration-100 ease-in-out
  `
