import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { useRouteMatch } from 'react-router'
import { CardField } from 'App/Components/Common/CardField'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Container, FormTab, PageHeader } from 'App/Styled'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { useDispatch } from 'react-redux'
import { setCommunicationCredentials } from 'App/Redux/actions'
import dateFormat from 'App/Services/General/dateFormat'

const Section = ({ data }) => {
  const isDate = (string) => {
    // Define a regular expression pattern for ISO 8601 dates
    const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/

    // Use test() to check if the string matches the pattern
    return iso8601Pattern.test(string)
  }

  return data
    ? Object.keys(data).map(
        (item, index) =>
          typeof data[item] === `string` && (
            <CardField
              key={index}
              title={item}
              value={isDate(data[item]) ? dateFormat(data[item]) : data[item]}
            />
          ),
      )
    : null
}

export const AboutDataTransaction = () => {
  const {
    params: { dataTransactionId },
  } = useRouteMatch()
  const dispatch = useDispatch()

  const [driversTabs, setDriversTab] = useState(``)
  const [activeDriverTab, setActiveDriverTab] = useState(1)

  const [vehiclesTabs, setVehiclesTab] = useState(``)
  const [activeVehicleTab, setActiveVehicleTab] = useState(1)

  const {
    data: transactionData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `DataTransactionPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/scrapes/${dataTransactionId}`,
      ).then((res) => res.data.data.scrape),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(
          setCommunicationCredentials({
            id: res._id,
            phone:
              res.data?.primaryInsured?.cellPhone ||
              res.data?.primaryInsured?.homePhone ||
              res.data?.primaryInsured?.workPhone,
            img: res.img,
            customerId: res._id,
            email: res.data?.primaryInsured?.emailAddress,
            // doNotContact: res?.doNotContact
          }),
        )
        // sort drivers tabs
        if (res.data.drivers.length > 0) {
          setDriversTab(
            res.data.drivers.map((driver, index) => {
              return {
                name: driver.firstName + ` ` + driver.lastName,
                number: index + 1,
              }
            }),
          )
        }

        // sort vehicles tabs
        if (res.data.cars.length > 0) {
          setVehiclesTab(
            res.data.cars.map((vehicle, index) => {
              return {
                name: vehicle.model,
                number: index + 1,
              }
            }),
          )
        }
      },
    },
  )

  return (
    <Container>
      <PageHeader>
        <div>Data Transaction</div>
      </PageHeader>
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <div className="p-2">
          <div className="flex items-start gap-2 justify-evenly pb-2">
            <div className="flex-1 rounded">
              <div className="grid grid-cols-12 px-4 py-2">
                <div className="col-span-6 text-xl">Policy Info</div>
              </div>
              <hr />
              <div className="px-4">
                <Section data={transactionData?.data?.policyInfo} />
                <Section data={transactionData?.data?.policyInfo?.billing} />
                {/* <CardField
                  title="Policy No"
                  value={transactionData?.data?.policyInfo?.policyNo}
                />
                <CardField
                  title="Location ID"
                  value={transactionData?.data?.policyInfo?.producer?.name}
                />
                <CardField
                  title="Policy Period"
                  value={
                    dateFormat(
                      transactionData?.data?.policyInfo?.effectiveDate,
                    ) +
                    ` - ` +
                    dateFormat(transactionData?.data?.policyInfo?.expiryDate)
                  }
                />
                <CardField
                  title="Written Premium"
                  value={
                    transactionData?.data?.policyInfo?.billing?.writtenPremium
                  }
                />
                <CardField
                  title="Due Date (Last/Current)"
                  value={dateFormat(
                    transactionData?.data?.policyInfo?.expiryDate,
                  )}
                /> */}
                {/* <CardField
                  title="Total Paid"
                  value={
                    transactionData?.data?.policyInfo?.billing?.totalPaidAmount
                  }
                />
                <CardField
                  title="Total Balance"
                  value={
                    transactionData?.data?.policyInfo?.billing
                      ?.totalBalanceAmount
                  }
                />
                <CardField
                  title="Last Billed Amount"
                  value={
                    transactionData?.data?.policyInfo?.billing?.lastBilledAmount
                  }
                />
                <CardField
                  title="Last Billed Date"
                  value={dateFormat(
                    transactionData?.data?.policyInfo?.billing?.lastBilledDate,
                  )}
                /> */}
              </div>
            </div>
            <div className="flex-1  rounded">
              <div className="grid grid-cols-12 px-4 py-2">
                <div className="col-span-6 text-xl">Primary Insured</div>
              </div>
              <hr />
              <div className="px-4">
                <Section data={transactionData?.data?.primaryInsured} />
                <Section
                  data={transactionData?.data?.primaryInsured?.postalAddress}
                />

                {/* <CardField
                  title="First Name"
                  value={transactionData.data.primaryInsured?.firstName}
                />

                <CardField
                  title="Last Name"
                  value={transactionData.data.primaryInsured?.lastName}
                />

                <CardField
                  title="Street"
                  value={
                    transactionData.data.primaryInsured?.postalAddress?.street
                  }
                />
                <CardField
                  title="City"
                  value={
                    transactionData.data.primaryInsured?.postalAddress?.city
                  }
                />

                <CardField
                  title="State"
                  value={
                    transactionData.data.primaryInsured?.postalAddress?.state
                  }
                />
                <CardField
                  title="Zip Code"
                  value={
                    transactionData.data.primaryInsured?.postalAddress?.zip
                  }
                />
                <CardField
                  title="Cell Phone"
                  value={transactionData.data.primaryInsured?.cellPhone}
                />
                <CardField
                  title="Home Phone"
                  value={transactionData.data.primaryInsured?.homePhone}
                />
                <CardField
                  title="Work Phone"
                  value={transactionData.data.primaryInsured?.workPhone}
                />
                <CardField
                  title="Email"
                  value={transactionData.data.primaryInsured?.email}
                /> */}
              </div>
            </div>
          </div>

          {transactionData?.data?.drivers?.length > 0 && (
            <div className="pb-1">
              <Collapsable title="Drivers">
                <Tabs2
                  openTab={activeDriverTab}
                  tabs={driversTabs}
                  getActiveTab={(val) => setActiveDriverTab(val)}
                />
                {transactionData.data?.drivers.map((driver, index) => (
                  <FormTab
                    key={index}
                    currenttab={index + 1}
                    opentab={activeDriverTab}
                    className="mt-2 shadow-sm rounded-md relative"
                  >
                    <Section data={driver} />

                    {/* <CardField title="First Name" value={driver?.firstName} />
                    <CardField title="Gender" value={driver?.gender} />
                    <CardField title="Relation" value={driver?.relation} />
                    <CardField
                      title="Marital Status"
                      value={driver?.maritalStatus}
                    />
                    <CardField title="Occupation" value={driver?.occupation} />
                    <CardField title="License No" value={driver?.licenseNo} />
                    <CardField
                      title="Date of Birth"
                      value={dateFormat(driver.dob)}
                    /> */}
                  </FormTab>
                ))}
              </Collapsable>
            </div>
          )}

          {transactionData.data?.cars.length > 0 && (
            <div className="pb-1">
              <Collapsable title="Vehicles">
                <Tabs2
                  openTab={activeVehicleTab}
                  tabs={vehiclesTabs}
                  getActiveTab={(val) => setActiveVehicleTab(val)}
                />
                {transactionData.data?.cars.map((vehicle, index) => (
                  <FormTab
                    key={index}
                    currenttab={index + 1}
                    opentab={activeVehicleTab}
                    className="mt-2 shadow-sm rounded-md relative"
                  >
                    <Section data={vehicle} />

                    {/* <CardField title="Vin" value={vehicle?.vin} />
                    <CardField
                      title="Annual Miles"
                      value={vehicle?.annualMiles}
                    />
                    <CardField title="Garaged" value={vehicle?.isGaraged} />
                    <CardField title="Symbol" value={vehicle?.symbol} />
                    <CardField title="Usage" value={vehicle?.usage} />
                    <CardField title="Condition" value={vehicle?.condition} />
                    <CardField title="Make" value={vehicle?.make} />
                    <CardField title="Model" value={vehicle?.model} />
                    <CardField title="Year" value={vehicle?.year} /> */}

                    <div>Coverages</div>
                    <div className="grid grid-cols-2 gap-2">
                      {vehicle?.coverages?.map((item, index) => (
                        <div key={index}>
                          <Collapsable title={item.type}>
                            <Section data={item} />
                          </Collapsable>
                        </div>
                      ))}
                    </div>
                  </FormTab>
                ))}
              </Collapsable>
            </div>
          )}
        </div>
      )}
    </Container>
  )
}
