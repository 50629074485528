/* eslint-disable no-unused-vars */
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Goal } from './goal'
import InfiniteScroll from 'react-infinite-scroller'
import { NotificationManager } from 'react-notifications'
import { GoalCardkelton } from './goalCardSkelton'

export const MonthlyGoalsMenu = () => {
  const { profile } = useSelector(({ user: { user } }) => user)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [monthlyGoals, setMonthlyGoals] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [error, setError] = useState(false)

  const loadMoreMonthlyGoals = async () => {
    setIsLoading(true)
    await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/goals/own?search={"query": ""}&page=${currentPage}&offset=1`,
    )
      .then((res) => {
        if (monthlyGoals?.length < res?.data?.data?.goals?.totalRecords) {
          setHasMore(true)
          setCurrentPage(res?.data?.data?.goals?.currentPage + 1)
        } else {
          setHasMore(false)
          setCurrentPage(null)
        }
        setError(false)
        setMonthlyGoals(
          [...monthlyGoals, ...res?.data?.data?.goals?.goals].reverse(),
        )
        setIsLoading(false)
      })
      .catch((err) => {
        if (err) {
          NotificationManager.error(`Something Went Wrong`)
        }
        setError(true)
        setIsLoading(false)
      })
  }
  return (
    <div style={{ width: `500px` }}>
      <div className="px-1.5 py-0.5 bg-red-500 rounded-t-md text-white font-medium text-sm">
        Goals
      </div>
      <hr />
      {error ? (
        <Reload refetch={() => loadMoreMonthlyGoals()} />
      ) : (
        <>
          <div className="w-full py-4 gap-1 px-4  max-h-96  overflow-y-scroll hide-scroller hide-scroller-firefox">
            <InfiniteScroll
              pageStart={0}
              loadMore={!isLoading && loadMoreMonthlyGoals}
              hasMore={hasMore}
              loader={<GoalCardkelton />}
              useWindow={false}
            >
              {monthlyGoals?.length > 0
                ? monthlyGoals.map((data, idx) => (
                  <div className="grid divide-y-2" key={idx}>
                    <Goal
                      goalsData={data}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <hr />
                  </div>
                ))
                : !isLoading && (
                  <div className="text-center my-4 text-sm text-gray-400 font-bold">
                    No Goals Found
                  </div>
                )}
            </InfiniteScroll>
          </div>
        </>
      )}
    </div>
  )
}
