import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentCampaign: ``,
}

const campaignGroupSlice = createSlice({
  name: `campaignGroup`,

  initialState,
  reducers: {
    setCurrentCampaign: (state, { payload }) => {
      state.currentCampaign = payload
    },
  },
})

export const { setCurrentCampaign } = campaignGroupSlice.actions

export const campaignGroup = campaignGroupSlice.reducer
