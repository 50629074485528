import React, { useEffect, useState } from 'react'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { StyledButton } from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosInstance } from 'App/Config'
import { useMutation } from 'react-query'
import { NotificationManager } from 'react-notifications'
import { AMS_BACKEND_API } from '../../../../Config/backendApis'
import { loadVerifyOptions } from 'App/Services/General/loadVerifyStatus'
import { InputField } from 'App/Components/Common/InputField'

export const UpdateLead = ({
  selectedLead,
  closeModal,
  setLeadUpdated,
  isVerifyLead,
}) => {
  const [leadStatus, setLeadStatus] = useState(
    selectedLead?.status
      ? { value: selectedLead.status, label: selectedLead.status }
      : ``,
  )
  const [leadVerify, setLeadVerify] = useState(
    selectedLead?.verificationStatus
      ? {
          value: selectedLead?.verificationStatus,
          label: selectedLead?.verificationStatus,
        }
      : ``,
  )
  const [priceSold, setPriceSold] = useState()

  const [errors, setErrors] = useState({
    formSubmit: false,
    leadStatusError: ``,
    leadVerifyError: ``,
    priceSoldError: ``,
  })

  const loadStatusOptions = () => {
    const options = {
      options: [
        {
          value: `PROSPECT`,
          label: `PROSPECT`,
        },
        {
          value: `QUOTE`,
          label: `QUOTE`,
        },
        {
          value: `SOLD`,
          label: `SOLD`,
        },
        {
          value: `NOT ANSWERED`,
          label: `NOT ANSWERED`,
        },
        {
          value: `LOST`,
          label: `LOST`,
        },
        {
          value: `IN PROGRESS`,
          label: `IN PROGRESS`,
        },
        {
          value: `MANUAL`,
          label: `MANUAL`,
        },
      ],
      hasMore: false,
    }
    return options
  }

  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/leads/${selectedLead._id}`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res.data) {
          closeModal()
          setLeadUpdated()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    if (updationError) {
      NotificationManager.error(`Something went wrong`)
    }
  }, [updationError])

  const ValidateForm = () => {
    if (!isVerifyLead) {
      if (leadStatus?.value?.length) {
        handleErrors(`leadStatusError`, ``)
      } else {
        handleErrors(`leadStatusError`, `Select Status`)
      }
    } else {
      if (leadVerify?.value?.length) {
        handleErrors(`leadVerifyError`, ``)
      } else {
        handleErrors(`leadVerifyError`, `Select Status`)
      }
    }

    if (leadStatus?.value === `SOLD`) {
      if (!priceSold?.length) {
        handleErrors(`priceSoldError`, `Enter  Amount`)
      } else if (priceSold?.length && parseInt(priceSold) < 1) {
        handleErrors(`priceSoldError`, `Enter Valid Amount`)
      } else {
        handleErrors(`priceSoldError`, ``)
      }
    } else {
      handleErrors(`priceSoldError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.leadStatusError.length &&
      !errors.priceSoldError.length &&
      !errors.leadVerifyError.length
    ) {
      //call API
      mutate(updatePayload())
      setErrors((prevState) => ({
        ...prevState,
        formSubmit: false,
      }))
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}

    if (leadStatus?.value !== selectedLead?.status) {
      data.status = leadStatus?.value
    }
    if (leadVerify?.value !== selectedLead?.verificationStatus) {
      data.verificationStatus = leadVerify?.value
    }

    if (leadStatus?.value === `SOLD`) {
      data.soldPrice = parseInt(priceSold)
    }

    return data
  }

  return (
    <>
      {/* Franchise basic forms */}
      <div className="w-96 px-4 py-1">
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Status"
              placeholder="Select Status"
              errorMessage={
                isVerifyLead ? errors?.leadVerifyError : errors.leadStatusError
              }
              value={isVerifyLead ? leadVerify : leadStatus}
              setValue={(e) =>
                isVerifyLead ? setLeadVerify(e) : setLeadStatus(e)
              }
              isMulti={false}
              loadOptions={isVerifyLead ? loadVerifyOptions : loadStatusOptions}
              isSearchable={false}
            />
          </div>
        </div>

        {leadStatus?.value === `SOLD` && (
          <div className="relative">
            <InputField
              type="number"
              title="Price *"
              name="priceSold"
              placeholder="Enter Amount"
              errorMessage={errors.priceSoldError}
              value={priceSold}
              setValue={(e) => setPriceSold(e.target.value)}
            />
          </div>
        )}

        <div className="pb-0.5 text-right">
          <StyledButton
            onClick={ValidateForm}
            disabled={
              isLoading ||
              (isVerifyLead
                ? leadVerify?.value === selectedLead?.verificationStatus
                : leadStatus?.value === selectedLead?.status)
            }
          >
            <div className="flex gap-2 text-white">
              <span>Update Lead</span>
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </StyledButton>
        </div>
      </div>
    </>
  )
}
