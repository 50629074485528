import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowAltCircleRight,
  faClipboardList,
} from '@fortawesome/free-solid-svg-icons'
import { PaymentReceipt } from '../PaymentReceipt'
import { ActivityWrapper } from 'App/Styled'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import {
  setCurrentPayment,
  setPaymentActiveActivityTab,
} from 'App/Redux/actions'
import { PaymentActivity } from './PaymentActivity'

export const PreviewPayment = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const {
    params: { paymentId },
  } = useRouteMatch()
  const dispatch = useDispatch()
  const {
    data: previewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `InvoicePreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/invoices/${paymentId}`,
      ).then((res) => {
        return res.data.data.invoice
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentPayment(res))
      },
    },
  )
  return (
    <>
      <div className="flex justify-end">
        <div className={`${isCollapsed ? `w-full` : `w-2/3`}`}>
          <PaymentReceipt
            previewData={previewData}
            isLoading={isLoading}
            error={error}
            refetch={refetch}
            isFetching={isFetching}
          />
        </div>
        <ActivityWrapper collapsed={isCollapsed.toString()}>
          <div
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="px-2 py-1 "
          >
            {/* <CollapseClip> */}
            <FontAwesomeIcon
              icon={faArrowAltCircleRight}
              className={`text-red-500 my-0.5 cursor-pointer transform transition duration-500 ${
                isCollapsed ? `rotate-180 ` : `rotate-0`
              }`}
            />
            {/* </CollapseClip> */}
          </div>
          {!isCollapsed ? (
            <PaymentActivity />
          ) : (
            <div
              className="text-center flex flex-col"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <div
                data-tip="Notes"
                className="mx-5 cursor-pointer tooltip tooltip-left"
                onClick={() => dispatch(setPaymentActiveActivityTab(1))}
              >
                <FontAwesomeIcon
                  icon={faClipboardList}
                  className="text-red-500"
                />
              </div>
            </div>
          )}
        </ActivityWrapper>
      </div>
    </>
  )
}
