import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
// import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Container } from 'App/Styled'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

export const BarChart = ({ chartData, title, label, className }) => {
  const [data, setData] = useState({
    // labels: [],
    datasets: [
      {
        payment: [],
        backgroundColor: [],
      },
    ],
    borderWidth: 1,
    barPercentage: 0.1,
    categoryPercentage: 0.1,
  })

  const randomRGBAColors = () => {
    return chartData?.map(
      () =>
        `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
          Math.random() * 255,
        )}, ${Math.floor(Math.random() * 255)}, 0.6)`,
    )
  }

  const options = {
    // indexAxis: `x`,
    scaleShowLabels: false,
    scales: {
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  useEffect(() => {
    const dataObject = {
      labels: chartData?.map((item) => item?.label),
      datasets: [
        {
          barThickness: 16,
          label: label,
          data: chartData?.map((item) =>
            item?.value ? item?.value?.toFixed(1) : 0,
          ),
          backgroundColor: randomRGBAColors(),
        },
      ],
      borderColor: chartData?.map(() => `rgb(0,255,0)`),
    }
    setData(dataObject)
  }, [chartData])

  return (
    <Container className={`relative ${className}`}>
      <div className="flex py-9 h-1/4 relative">
        <div className="w-1/2 absolute left-4 top-2">
          <span className="font-bold text-md">{title}</span>
        </div>
      </div>

      <div className="h-3/4">
        <div className="h-280 m-6">
          {data ? <Bar data={data} options={options} /> : null}
        </div>
      </div>
    </Container>
  )
}
