import React, { useEffect, useState, Fragment } from 'react'
import { useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader } from 'App/Styled'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { useDebouncedCallback } from 'use-debounce/lib'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'
import dateFormat from 'App/Services/General/dateFormat'

const tableColumns = [
  { label: `Policy No`, value: `PolicyNo` },
  { label: `Carrier`, value: `carrier` },
  { label: `Customer Id`, value: `customerId` },
  { label: `Customer`, value: `customerName` },
  { label: `Agent`, value: `payerName` },
  { label: `Amount`, value: `amount` },
  { label: `Created`, value: `createdAt`, isLast: true },
]

export const AllTransactions = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [isOffset, setIsOffset] = useState(``)

  const [searchQuery, setSearchQuery] = useState(``)
  // fetching all frenchises
  const {
    isLoading,
    data: transactionsData,
    error,
    refetch: getAllTransactions,
    isFetching,
  } = useQuery(
    `PaymentTransactions`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/payment-transactions?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }"}&sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllTransactions()
    }
  }, [sort, currentPage, isOffset])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const callTransactionQuery = useDebouncedCallback(() => {
    getAllTransactions()
  }, 700)

  return (
    <Container>
      <PageHeader>
        <div>Transactions</div>
        <div className="flex items-center">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callTransactionQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <Spinner loading={isLoading || isFetching} />

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (isLoading || isFetching) && `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllTransactions()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md mt-2">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div
                            className={`cursor-pointer select-none text-sm flex truncate ${
                              item?.isLast && `float-right`
                            }`}
                          >
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {transactionsData?.paymentTrxs?.length != 0 ? (
                    transactionsData?.paymentTrxs?.map((transaction, index) => (
                      <Fragment key={index}>
                        <TableRow
                          key={index.toString()}
                          className="hover:bg-gray-100 cursor-pointer"
                        >
                          <td className="px-4 py-0.5 z-0 text-sm capitalize truncate max-w-xs">
                            <span>{transaction?.policyNo}</span>
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm">
                            <Badge>
                              {transaction?.carrier
                                ? transaction?.carrier
                                : `N/A`}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm capitalize">
                            <Badge type="warning">
                              {transaction?.customerId}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            {transaction?.customerName}
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            {transaction?.payerName}
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            ${transaction?.amount}
                          </td>
                          <td className="px-8 py-0.5 z-0 text-sm float-right truncate">
                            {dateFormat(transaction.createdAt)}
                          </td>
                        </TableRow>
                      </Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Transaction Found</div>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </table>
            </div>

            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={transactionsData?.totalPages}
                totalRecords={transactionsData?.totalRecords}
                curerntPageRecords={transactionsData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
