import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Container } from 'App/Styled'
import { ReactSelectField } from '../../Common/ReactSelect'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
}

export const PolicyByCarrier = ({ policyByCarrier }) => {
  const [dataSet, setDataSet] = useState(``)
  const [totalCarrier, setTotalCarrier] = useState(``)
  const [selectCarrier, setSelectCarrier] = useState([])
  useEffect(() => {
    if (policyByCarrier?.length) {
      let labels = selectCarrier.length
        ? selectCarrier.map((item) => item.label)
        : policyByCarrier.map((item) => item.carrier)
      let values = selectCarrier.length
        ? selectCarrier.map((item) => item.policies)
        : policyByCarrier.map((item) => item.policies)
      let totalCarrier = values.reduce(
        (partialSum, carrier) => partialSum + carrier,
        0,
      )
      let backgroundColors = policyByCarrier.map(() => {
        let randomColor = Math.floor(Math.random() * 16777215).toString(16)
        return `#` + randomColor
      })
      if (labels.length && values.length && backgroundColors.length)
        setDataSet({
          labels: labels,
          datasets: [
            {
              label: `# of Votes`,
              data: values,
              backgroundColor: backgroundColors,
              borderWidth: 1,
            },
          ],
        })
      setTotalCarrier(totalCarrier)
    }
  }, [policyByCarrier, selectCarrier])
  const option = policyByCarrier?.map((item) => ({
    value: item?.carrier,
    policies: item.policies,
    label: item?.carrier,
  }))

  const loadCarrier = (search) => {
    const options = {
      options: search ? filterCarrier(search) : option,
      hasMore: false,
    }

    return options
  }

  const filterCarrier = (search) => {
    return (
      option
        //some labels are null that is why we used double filters
        .filter((item) => item.label !== null)
        .filter((item) => {
          return item.label.toLowerCase().includes(search.toLowerCase())
        })
    )
  }

  return (
    <Container className="relative">
      <div className="absolute font-bold text-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
        <div className="mt-16">{`${totalCarrier > 0 ? totalCarrier : `No Data`
          }`}</div>
      </div>

      <div className="flex py-9 h-1/4 relative">
        <div className="font-bold absolute left-4 2xl:left-6 top-5">
          <span className="font-bold text-md">Policy By Carriers</span>
        </div>
        <div className="w-1/2 absolute right-4 top-4 z-0">
          <ReactSelectField
            placeholder=" Select Carrier"
            value={selectCarrier}
            setValue={(e) => {
              setSelectCarrier(e)
            }}
            isMulti={true}
            loadOptions={loadCarrier}
            isSearchable={true}
            isScrollable={true}
          />
        </div>
      </div>

      <div className="text-center flex justify-center h-3/4">
        <div className="w-300 h-310">
          {dataSet && (
            <Doughnut
              data={dataSet}
              options={options}
              width={300}
              height={200}
            />
          )}
        </div>
      </div>
    </Container>
  )
}
