import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faPlusCircle,
  faSortUp,
  faSortDown,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { Fragment } from 'react'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
import { Spinner } from 'App/Components/Common/Spinner'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'

const tableColumns = [
  { label: `Name`, value: `name` },
  { label: `Location`, value: `_location.label` },
  { label: `Agent`, value: `_creator.label` },
  { label: `UpdatedAt`, value: `updatedAt` },
  { label: `CreatedAt`, value: `createdAt` },
]

export const AllPaymentTemplate = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [templateSearching, setPaymentSearching] = useState(false)
  const [sort, setSort] = useState(``)

  const [isOffset, setIsOffset] = useState(``)

  const [searchQuery, setSearchQuery] = useState(``)

  const history = useHistory()
  const { url } = useRouteMatch()

  // fetching all frenchises
  const {
    isLoading,
    data: paymentTemplates,
    error,
    refetch: getAllPaymentTemplates,
    isFetching,
  } = useQuery(
    `paymentTemplatesApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/payment-templates?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setPaymentSearching(false)
      },
    },
  )

  // delete template
  const {
    mutate: deleteMutation,
    isLoading: deletingPaymentTemplate,
    isError: deletionError,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/payment-templates/${id}`,
      ).then((res) => {
        res.data && getAllPaymentTemplates()
        NotificationManager.success(`Successfully Deleted`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (!templateSearching) {
      getAllPaymentTemplates()
    }
  }, [sort, currentPage, isOffset])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const callTemplatesQuerry = useDebouncedCallback(() => {
    getAllPaymentTemplates()
  }, 700)

  return (
    <Container shadow="yes">
      <Spinner loading={deletingPaymentTemplate || isLoading || isFetching} />
      {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <PageHeader bgcolor="bg-white">
        <div>Payment Templates</div>

        <div className="flex items-center">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setPaymentSearching(true)
                  callTemplatesQuerry()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `payments-templates:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      {error ? (
        <Reload refetch={() => getAllPaymentTemplates()} />
      ) : (
        <div
          className={`my-1 sm:mb-0 justify-between w-full${
            (deletingPaymentTemplate || isLoading || isFetching) &&
            ` opacity-30 pointer-events-none`
          } `}
        >
          <div className="overflow-x-auto  rounded-t-md">
            <table className="w-full border-collapse">
              <TableHeader>
                <TableRow>
                  {tableColumns.map((item, idx) => {
                    return (
                      <TableCell key={idx}>
                        <div className="cursor-pointer select-none text-sm flex truncate">
                          <div
                            onClick={() => {
                              handleSorting(item.value)
                            }}
                            className={`flex ${
                              item.value === sort ? `text-red-500` : ``
                            }`}
                          >
                            {item.label}
                          </div>
                          <div
                            className="ml-2 px-1 rounded-sm cursor-pointer flex"
                            onClick={() => handleSorting(`-` + item.value)}
                          >
                            <FontAwesomeIcon
                              icon={
                                sort.includes(`-`) && sort == `-` + item.value
                                  ? faSortUp
                                  : faSortDown
                              }
                              size="lg"
                              className={`${
                                sort.includes(`-`) && sort == `-` + item.value
                                  ? `text-red-500`
                                  : `-mt-1.5`
                              }`}
                            />
                          </div>
                        </div>
                      </TableCell>
                    )
                  })}
                  {permissionGranted([
                    `payments-templates:delete`,
                    `corporate-manager:*`,
                    `manager:*`,
                    `admin:*`,
                  ]) && (
                    <TableCell className="px-6 text-sm float-right">
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHeader>

              <TableBody>
                {!isLoading && !paymentTemplates?.paymentTemplates?.length ? (
                  <tr>
                    <td colSpan="12">
                      <div className="w-full text-center p-6  ">
                        <p>No Template Found</p>
                      </div>
                    </td>
                  </tr>
                ) : (
                  paymentTemplates?.paymentTemplates?.map((template, index) => (
                    <Fragment key={index}>
                      <TableRow
                        key={index}
                        className="hover:bg-gray-100 whitespace-nowrap cursor-pointer"
                        onClick={() => {
                          if (
                            permissionGranted([
                              `payments-templates:read`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])
                          ) {
                            history.push(`${url}/${template._id}`)
                          }
                        }}
                      >
                        <td className="px-4 py-0.5 capitalize text-sm">
                          {template?.name.length ? (
                            template?.name
                          ) : (
                            <Badge>N/A </Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 capitalize text-sm">
                          {template?._location?.label?.length ? (
                            template?._location?.label
                          ) : (
                            <Badge>N/A </Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 capitalize text-sm">
                          {template?._creator?.label?.length ? (
                            template?._creator?.label
                          ) : (
                            <Badge>N/A </Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <span>{dateFormat(template.updatedAt)}</span>
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <span>{dateFormat(template.createdAt)}</span>
                        </td>
                        {/* Actions TD */}
                        <td className="px-7 py-2 z-0 text-sm flex float-right text-gray-400">
                          {permissionGranted([
                            `payments-templates:delete`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ]) && (
                            <span data-tip="Delete" className="tooltip">
                              <FontAwesomeIcon
                                className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                icon={faTrash}
                                size="1x"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  deleteMutation(template._id)
                                }}
                              />
                            </span>
                          )}
                        </td>
                      </TableRow>
                    </Fragment>
                  ))
                )}
              </TableBody>
            </table>
          </div>
          <div className="px-4 py-2">
            <ReactPagination
              offset={isOffset?.value ? isOffset?.value : offset}
              currentPage={currentPage}
              totalPages={paymentTemplates?.totalPages}
              totalRecords={paymentTemplates?.totalRecords}
              curerntPageRecords={paymentTemplates?.currentPageRecords}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      )}
    </Container>
  )
}
