import React from 'react'
import { useSelector } from 'react-redux'
import { TOne } from 'App/Styled'
import { ClaimsMediaFiles } from './MediaFiles'
export const MediaFiles = () => {
  const { activeMediaTab } = useSelector(({ claim }) => claim)
  return (
    <>
      <div className="tab-content tab-space">
        <TOne opentab={activeMediaTab}>
          <ClaimsMediaFiles />
        </TOne>
      </div>
    </>
  )
}
