import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentAssessment: ``,
  currentEvaluation: ``,
  selectedAssessment: ``,
  currentAssessmentTemplate: ``,
  activeActivityTab: 1,
}

const assessmentSlice = createSlice({
  name: `assessment`,

  initialState,
  reducers: {
    setCurrentAssessment: (state, { payload }) => {
      state.currentAssessment = payload
    },
    setCurrentAssessmentTemplate: (state, { payload }) => {
      state.currentAssessmentTemplate = payload
    },
    setCurrentEvaluation: (state, { payload }) => {
      state.currentEvaluation = payload
    },
    setSelectedAssessmentId: (state, { payload }) => {
      state.currentAssessment = payload
    },
    setAssessmentActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
  },
})

export const {
  setCurrentAssessment,
  setAssessmentActivityActiveTab,
  setSelectedAssessmentId,
  setCurrentEvaluation,
  setCurrentAssessmentTemplate,
} = assessmentSlice.actions

export const assessment = assessmentSlice.reducer
