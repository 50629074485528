import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentAgent: ``,
  activeActivityTab: 1,
  agentProfile: ``,
}

const agentSlice = createSlice({
  name: `agent`,

  initialState,
  reducers: {
    setCurrentAgent: (state, { payload }) => {
      state.currentAgent = payload
    },
    setAgentActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
    setAgentProfile: (state, { payload }) => {
      state.agentProfile = payload
    },
  },
})

export const { setCurrentAgent, setAgentActivityActiveTab, setAgentProfile } =
  agentSlice.actions

export const agent = agentSlice.reducer
