import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadAllCarriers = async (search, loadedOptions, { page }) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AMS_BACKEND_API}/api/carriers/list?search=${
      search ? search : ``
    }&page=${page}&offset=10`,
  )

  return {
    options: data.carriers,
    hasMore: data.totalPages - page > 0,
    additional: {
      page: page + 1,
    },
  }
}
