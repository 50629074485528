import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { PageHeader, Container, EditStyledButton } from 'App/Styled'
import { Reload } from 'App/Components/Common/Reload'
import { setCustomerContacts } from 'App/Redux/actions'
import { useHistory, useRouteMatch } from 'react-router'
import { CardField } from 'App/Components/Common/CardField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faMapMarkerAlt,
  faPen,
  faPhoneAlt,
  faSpinner,
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { permissionGranted } from 'App/Services'
import dateFormat from 'App/Services/General/dateFormat'

export const AboutContact = () => {
  const {
    url,
    params: { contactId, customerId },
  } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    isLoading,
    data: previewData,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `ContactPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/contacts/${contactId}?_module={"value":"${customerId}", "label":"customer"}`,
      ).then((res) => {
        return res.data.data.contact
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => dispatch(setCustomerContacts(res)),
    },
  )

  return (
    <Container>
      <PageHeader>
        <div>Contact</div>
        {permissionGranted([`contacts:update`]) && (
          <EditStyledButton onClick={() => history.push(`${url}/update`)}>
            <span>Edit</span>
            <FontAwesomeIcon
              icon={faPen}
              className="text-white mt-0.5"
              size="sm"
            />
          </EditStyledButton>
        )}
      </PageHeader>

      <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md">
        {isLoading || isFetching ? (
          <div className="text-center p-6">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : !previewData ? (
          <p className=" text-center p-6">No Contact Found</p>
        ) : (
          <div className="rounded-md overflow-hidden py-4">
            {/* Basic Info Start name email and gender */}
            <div className="px-3 pb-2">
              <div className="text-sm py-0.5 font-semibold">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-gray-400 mt-0.5"
                  size="sm"
                />
                &nbsp;&nbsp;
                {`${previewData?.firstName} ${previewData?.lastName}`}
              </div>

              <div className="text-sm py-0.5 font-bold">
                <FontAwesomeIcon
                  icon={faPhoneAlt}
                  className="text-gray-400 mt-0.5"
                  size="sm"
                />
                &nbsp;&nbsp;
                {previewData?.phone}
              </div>

              {!!previewData?.email && (
                <div className="text-sm py-0.5 font-bold">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="text-gray-400 mt-0.5"
                    size="sm"
                  />
                  &nbsp;&nbsp;
                  {previewData?.email}
                </div>
              )}
              <div className="text-sm py-0.5 font-bold">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="text-gray-400 mt-0.5"
                  size="sm"
                />
                &nbsp;&nbsp;
                {previewData?.address.unitNo}, {previewData?.address.street},
                {` `}
                {previewData?.address.city}, {previewData?.address.state},{` `}
                {previewData?.address.zip}
              </div>
            </div>

            <div className="flex flex-col  gap-0">
              <div className="grid grid-cols-12 px-4 py-2">
                <div className="col-span-6 text-xl">Contact Info</div>
              </div>
              <hr />
              <div className="px-4 py-2">
                {previewData?.workPhone && (
                  <CardField
                    title="Work Phone"
                    value={previewData?.workPhone}
                  />
                )}
                <CardField
                  title="Is Driver"
                  value={previewData.isDriver ? `YES` : `NO`}
                />
                <CardField title="Relation" value={previewData.relation} />
                <CardField
                  title="Created"
                  value={dateFormat(previewData?.createdAt)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}
