import {
  faArrowAltCircleRight,
  faBox,
  faClipboardList,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setContactActivityActiveTab } from 'App/Redux/actions'
import { ActivityWrapper } from 'App/Styled'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AboutContact } from './About'
import { ContactActivity } from './Activity'

export const Contact = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const dispatch = useDispatch()

  return (
    <div className="flex justify-end">
      <div className={`${isCollapsed ? `w-full` : `w-2/3`}`}>
        <AboutContact />
      </div>
      <ActivityWrapper collapsed={isCollapsed.toString()}>
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="px-2 py-1 "
        >
          {/* <CollapseClip> */}
          <FontAwesomeIcon
            icon={faArrowAltCircleRight}
            className={`text-red-500 my-0.5 cursor-pointer transform transition duration-500 ${
              isCollapsed ? `rotate-180 ` : `rotate-0`
            }`}
          />
          {/* </CollapseClip> */}
        </div>
        {!isCollapsed ? (
          <ContactActivity />
        ) : (
          <div
            className="text-center flex flex-col"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <div
              data-tip="Notes"
              className="mx-5 cursor-pointer tooltip tooltip-left"
              onClick={() => dispatch(setContactActivityActiveTab(1))}
            >
              <FontAwesomeIcon
                icon={faClipboardList}
                className="text-red-500"
              />
            </div>
            <div
              data-tip="Logs"
              className="mt-4 mx-5 cursor-pointer tooltip tooltip-left"
              onClick={() => dispatch(setContactActivityActiveTab(1))}
            >
              <FontAwesomeIcon icon={faBox} className="text-red-500" />
            </div>
          </div>
        )}
      </ActivityWrapper>
    </div>
  )
}
