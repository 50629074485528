import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, SubmitButton } from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API } from 'App/Config/backendApis'
import { AxiosInstance } from 'App/Config'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
// import { loadAllCarriers } from 'App/Services'
import { NotificationManager } from 'react-notifications'

export const UpdateScrapingCredentials = ({
  selectedCredentials,
  closeModal,
}) => {
  // const user = useSelector(({ user: { user } }) => user)
  const [credentials, setCredentials] = useState({
    carrier: {
      value: selectedCredentials?.carrierId,
      label: selectedCredentials?.carrierName,
    },
    producerCode: selectedCredentials?.producerCode,
    userName: selectedCredentials?.userName,
    password: selectedCredentials?.password,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    carrierIdError: ``,
    userNameError: ``,
    passwordError: ``,
    producerCodeError: ``,
  })

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/scraping-credentials/${selectedCredentials?._id}`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res.data) {
          NotificationManager.success(`Operation successful`)
          closeModal()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const ValidateForm = () => {
    if (!credentials?.carrier?.value?.length) {
      handleErrors(`carrierIdError`, `Enter Option`)
    } else {
      handleErrors(`carrierIdError`, ``)
    }

    if (!credentials?.userName?.length) {
      handleErrors(`userNameError`, `Enter Name`)
    } else if (credentials?.userName?.length < 3) {
      handleErrors(`userNameError`, `Name can't be less than 3 characters`)
    } else {
      handleErrors(`userNameError`, ``)
    }

    if (!credentials?.password?.length) {
      handleErrors(`passwordError`, `Enter Password`)
    } else {
      handleErrors(`passwordError`, ``)
    }
    // if (!credentials?.producerCode?.length) {
    //   handleErrors(`producerCodeError`, `Enter Producer Code`)
    // } else {
    //   handleErrors(`producerCodeError`, ``)
    // }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.carrierIdError.length &&
      !errors.userNameError.length &&
      // !errors.producerCodeError.length &&
      !errors.passwordError.length
    ) {
      mutate(preparePayload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const preparePayload = () => {
    let data = {}

    // if (credentials.carrier?.value?.length) {
    //   data.carrierId = credentials?.carrier?.value
    // }
    // if (credentials.carrier?.value?.length) {
    //   data.carrierName = credentials?.carrier?.label
    // }

    if (credentials?.producerCode?.length) {
      data.producerCode = credentials?.producerCode
    }
    if (credentials.userName.length) {
      data.userName = credentials.userName
    }

    if (credentials.password.length) {
      data.password = credentials.password
    }

    return data
  }

  return (
    <Container>
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div
        className={`px-4 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        {/* Locations basic form */}
        <div className="flex gap-6 my-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Carrier *"
              placeholder="Select Carrier"
              errorMessage={errors.carrierIdError}
              value={credentials?.carrier}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  carrier: e,
                }))
              }}
              isMulti={false}
              //   loadOptions={loadAllCarriers}
              isSearchable={true}
              disabled
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Producer Code *"
              placeholder="Enter value"
              value={credentials.producerCode}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  producerCode: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="User Name *"
              placeholder="Enter Name"
              value={credentials.userName}
              errorMessage={errors.userNameError}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  userName: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="password"
              title="Password *"
              placeholder="Enter Password"
              value={credentials.password}
              errorMessage={errors.passwordError}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  password: e.target.value,
                }))
              }}
            />
          </div>
        </div>

        {/* Location Address starts from here */}
        <div className="flex justify-center">
          <div className="text-center w-96 my-5">
            <SubmitButton onClick={ValidateForm}>
              Update Credential
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
