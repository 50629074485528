import { Collapsable } from 'App/Components/Common/Collapsable'
import { InputField } from 'App/Components/Common/InputField'
import { ReactModal } from 'App/Components/Common/Modal'
import dateFormat from 'App/Services/General/dateFormat'
import { Input, StyledButton, SubmitButton } from 'App/Styled'
import React, { useState } from 'react'
import { DateRange } from 'react-date-range'

const Filters = ({
  ledgerFilters,
  handleFilters,
  resetFilters,
  _dateRange,
  handleDateChange,
  isLoading,
}) => {
  const [filters, setFilters] = useState({ ...ledgerFilters })
  const [previewModal, setPreviewModal] = useState(false)
  const [dateRange, setDateRange] = useState(_dateRange)

  const handleChangeFilters = (value, name) => {
    {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  return (
    <div
      className={`bg-white mt-2 mb-1 sm:mb-0 justify-center items-center px-2 pt-1 `}
    >
      {previewModal && (
        <ReactModal
          title="Date Range"
          closeModal={() => setPreviewModal(false)}
        >
          <div className="pb-2">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => {
                item.selection.isSelected = true
                setDateRange([item.selection])
                handleChangeFilters(item.selection, `createdAt`)
              }}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              className="w-full"
            />
            <div className="px-2 text-right">
              <StyledButton
                onClick={() => {
                  handleDateChange(dateRange)
                  setPreviewModal(false)
                }}
              >
                <div className="flex gap-2 text-white">
                  <span>Filter </span>
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}

      <Collapsable title="Filters">
        <div className="grid grid-cols-4 gap-4">
          <div className="relative">
            <InputField
              type="text"
              title="Name"
              placeholder="Enter Name"
              value={filters?.name ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `name`)
              }}
              policyNo
            />
          </div>
          <div className="relative">
            <InputField
              type="text"
              title="Email"
              placeholder="Enter email"
              value={filters?.email ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `email`)
              }}
            />
          </div>

          <div className="relative">
            <InputField
              type="number"
              title="Phone"
              placeholder="Enter phone"
              value={filters?.phone ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `phone`)
              }}
            />
          </div>
          <div className="relative">
            <InputField
              type="number"
              title="Customer ID"
              placeholder="Enter Id"
              value={filters?._customerId ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `_customerId`)
              }}
            />
          </div>

          <div className="relative">
            <InputField
              type="text"
              title="Policy No"
              placeholder="Enter no"
              value={filters?.policyNo ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `policyNo`)
              }}
            />
          </div>

          <div className="relative">
            <label className={`text-gray-700 font-medium text-sm`}>
              Created At
            </label>
            <div className="flex gap-x-2">
              <Input
                type="text"
                name="startRange"
                placeholder="Start Date"
                value={
                  dateRange?.[0]?.isSelected
                    ? `${dateFormat(dateRange?.[0]?.startDate)} - ${dateFormat(
                        dateRange?.[0]?.endDate,
                      )} `
                    : ``
                }
                onFocus={() => setPreviewModal(true)}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-4">
          <div className="flex  gap-4">
            <SubmitButton
              disabled={isLoading}
              onClick={() => {
                handleFilters(filters)
              }}
            >
              Search
            </SubmitButton>

            <SubmitButton
              disabled={isLoading}
              onClick={() => {
                if (
                  filters?.policyNo?.length ||
                  filters?.name?.length ||
                  filters?._customerId?.length ||
                  filters?.phone ||
                  filters?.email?.length ||
                  dateRange?.[0]?.isSelected
                ) {
                  setDateRange([
                    {
                      startDate: new Date(),
                      endDate: new Date(),
                      key: `selection`,
                    },
                  ])
                  setFilters({})
                  resetFilters()
                }
              }}
            >
              Reset
            </SubmitButton>
          </div>
        </div>
      </Collapsable>
    </div>
  )
}

export default Filters
