import { store } from 'App/Redux/store'

export const permissionGranted = (permission, menuItem) => {
  if (permission !== true) {
    const globalState = store.getState()
    let systemPermissions = globalState?.user?.user?.profile?.system_permissions
    if (typeof systemPermissions === `string`) {
      systemPermissions = [systemPermissions]
    }

    let authorizedRoles = [`super-admin:*`]

    // restricted system admin credentials and policy reports
    const checkRoute =
      window.location.href !== `/admin/credentials` &&
      !window.location.href.includes(`policy-reports`)
    if (checkRoute) {
      authorizedRoles.push(`system-admin:*`)
    }

    let completeModulePermission
    if (permission) {
      completeModulePermission = menuItem
        ? permission.map((item) => item.split(`:`)[0] + `:*`)
        : permission[0].split(`:`)[0] + `:*`
    }

    if (permission && completeModulePermission) {
      menuItem
        ? authorizedRoles.push(...completeModulePermission, ...permission)
        : authorizedRoles.push(completeModulePermission, ...permission)
    }

    for (let i = 0; i < systemPermissions?.length; i++) {
      for (let j = 0; j < authorizedRoles?.length; j++) {
        // console.log(`authorizedRoles[i]`, authorizedRoles[j])
        if (authorizedRoles[j] === systemPermissions[i]) {
          return true
        }
      }
    }

    return false
  } else {
    return true
  }
}

export const isSuperUser = (permission) => {
  const globalState = store.getState()
  let systemPermissions = globalState?.user?.user?.profile?.system_permissions
  if (typeof systemPermissions === `string`) {
    systemPermissions = [systemPermissions]
  }

  if (systemPermissions.includes(permission)) {
    return true
  } else return false
}
export const isHasPermission = (permission) => {
  const globalState = store.getState()
  let systemPermissions = globalState?.user?.user?.profile?.system_permissions
  if (typeof systemPermissions === `string`) {
    systemPermissions = [systemPermissions]
  }

  if (systemPermissions.includes(permission)) {
    return true
  } else return false
}
