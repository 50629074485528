import React from 'react'

export const GoalCardkelton = () => {
  return (
    <>
      <div
        className="w-full my-1 gap-2 animate-pulse flex h-12 items-center border-b-1 border-gray-100 pb-2"
        key={`goal` + 1}
      >
        <div className="bg-gray-100 rounded-md flex-1 h-10"></div>
        <div className="bg-gray-100 rounded-md flex-1 h-6"></div>
        <div className="bg-gray-100 rounded-md flex-1 h-6"></div>
        <div className="bg-gray-100 rounded-md flex-1 h-10"></div>
      </div>
      <div
        className="w-full my-1 gap-2 animate-pulse flex h-12 items-center border-b-1 border-gray-100 pb-2"
        key={`goal` + 2}
      >
        <div className="bg-gray-100 rounded-md flex-1 h-10"></div>
        <div className="bg-gray-100 rounded-md flex-1 h-6"></div>
        <div className="bg-gray-100 rounded-md flex-1 h-6"></div>
        <div className="bg-gray-100 rounded-md flex-1 h-10"></div>
      </div>
    </>
  )
}
