/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, AddButton, SubmitButton } from 'App/Styled'
import { newSocket } from 'App/HOC/MainLayout'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faPlusCircle,
  faSortUp,
  faSortDown,
  faPhone,
  faDollarSign,
} from '@fortawesome/free-solid-svg-icons'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactModal } from 'App/Components/Common/Modal'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { InputField } from 'App/Components/Common/InputField'
import { NotificationManager } from 'react-notifications'
import { PaymentCall } from 'App/Components/Communications/PaymentCall'
import { Spinner } from 'App/Components/Common/Spinner'
import dateFormat from 'App/Services/General/dateFormat'
import { useSelector } from 'react-redux'
import { DatePickerField } from 'App/Components/Common/DatePicker'
const tableColumns = [
  { label: `Carrier`, value: `_carrier.label` },
  { label: `Agent`, value: `_agent.label` },
  { label: `Policy`, value: `_policy.label` },
  { label: `Invoice`, value: `_invoiceId` },
  { label: `Void`, value: `void` },
  { label: `Total`, value: `totalAmount` },
  { label: `Paid`, value: `paidAmount` },
  { label: `Balance`, value: `remainingAmount` },
  { label: `Due Date`, value: `dueDate` },
]

export const AllPayments = ({ customerId }) => {
  const queryClient = useQueryClient()

  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [invoice, setInvoice] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)
  const [activeCall, setActiveCall] = useState(false)
  const [activeCall2, setActiveCall2] = useState(false)
  const history = useHistory()
  const { url } = useRouteMatch()
  const [language, setLanguage] = useState(``)
  const [paymentOption, setPaymentOption] = useState(``)
  const [amount, setAmount] = useState(``)
  const [paymentCallDate, setPaymentCallDate] = useState(``)
  const [errors, setErrors] = useState({
    amountError: ``,
    languageError: ``,
    paymentOptionError: ``,
    paymentCallDateError: ``,
    formSubmit: false,
  })

  const [createInvoice, setCreateInvoice] = useState(false)
  const { currentActiveCall } = useSelector(
    ({ currentCommunication }) => currentCommunication,
  )

  const {
    isLoading,
    data: invoicesData,
    error,
    refetch: getAllInvoices,
    isFetching,
  } = useQuery(
    `InvoicesDataApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/customers/${customerId}/invoices?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }"}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${offset}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const loadLanguages = async () => {
    return {
      options: [
        { value: `english`, label: `English` },
        { value: `spanish`, label: `Spanish` },
      ],
      hasMore: false,
    }
  }
  const paymentOptions = async () => {
    return {
      options: [
        { value: `INSTANT`, label: `INSTANT` },
        { value: `SCHEDULED`, label: `SCHEDULED` },
      ],
      hasMore: false,
    }
  }
  const { mutate: addAmount } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/customers-chat/generate-call`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res) {
          NotificationManager.success(`Payment In Progress`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: () => {
        NotificationManager.error(`Call Not forwarded`)
      },
      onSuccess: () => {
        // Invalidate the query with the tag 'schedulePaymentsDataApi'
        queryClient.invalidateQueries(`schedulePaymentsDataApi`)
      },
    },
  )
  // delete franchise
  const { isLoading: deletingPayment, isError: deletionError } = useMutation(
    async (id) =>
      await AxiosInstance.delete(`${AMS_BACKEND_API}/api/invoices/${id}`).then(
        (res) => {
          res.data && getAllInvoices()
        },
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllInvoices()
    }
  }, [sort, currentPage])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  //reset value of paymentCallDate when user first select Schedule payment and select payment call date and then
  // he select INSTANT option
  useEffect(() => {
    if (paymentOption.value === `INSTANT`) {
      setPaymentCallDate(``)
    }
  }, [paymentOption])

  const callInvoicesQuery = useDebouncedCallback(() => {
    getAllInvoices()
  }, 700)

  const validateFrom = () => {
    if (!amount?.length) {
      handleErrors(`amountError`, `Enter Amount`)
    } else if (!(invoice?.remainingAmount >= amount)) {
      handleErrors(
        `amountError`,
        `Amount should be ${invoice?.remainingAmount} or less`,
      )
    } else {
      handleErrors(`amountError`, ``)
    }
    if (!language?.value?.length) {
      handleErrors(`languageError`, `Select Language`)
    } else {
      handleErrors(`languageError`, ``)
    }
    if (!paymentOption?.value?.length) {
      handleErrors(`paymentOptionError`, `Select Payment Option`)
    } else {
      handleErrors(`paymentOptionError`, ``)
    }
    if (paymentCallDate === `` && paymentOption) {
      handleErrors(`paymentCallDateError`, `Select Date & Time`)
    } else {
      handleErrors(`paymentCallDateError`, ``)
    }

    setErrors((preState) => ({
      ...preState,
      formSubmit: true,
    }))
  }
  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.amountError.length &&
      !errors.languageError.length &&
      !(
        errors.paymentCallDateError.length &&
        paymentOption.value === `SCHEDULED`
      ) &&
      !errors.paymentOptionError.length
    ) {
      addAmount({
        amount: parseInt(amount),
        isScheduled: paymentOption.value === `INSTANT` ? false : true,
        scheduledDate: paymentCallDate || ``,
        customerId: customerId,
        timeZone:
          paymentOption.value === `INSTANT`
            ? ``
            : Intl.DateTimeFormat().resolvedOptions().timeZone,

        remainingAmount: parseInt(invoice?.remainingAmount),
        paidAmount: parseInt(invoice?.paidAmount),
        invoiceId: invoice?._id,
        language: language.value,
        callInfo: {
          To: invoice?._insured?.phone,
          From: preferredLocation.phone,

          _agentId: user?.profile?.sub,
          agentName: user?.profile?.full_name,
          agentImg: user?.profile?.dp,
          _franchise: user?.profile?._franchise?._id,
          _location: preferredLocation?._id,
          _direction: `OUTBOUND`,
        },
      })

      setActiveCall2(false)
      //reset all form values
      setLanguage(``)
      setAmount(``)
      setPaymentOption(``)
      setPaymentCallDate(``)
      setErrors((preState) => ({
        ...preState,
        formSubmit: false,
      }))
    }
  }, [errors])
  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const invoiceBalance = useSelector(
    ({ customer }) => customer?.currentCustomer?.totalRemainingOfInvoices,
  )
  const { user, preferredLocation } = useSelector(({ user }) => user)
  useEffect(() => {
    if (newSocket) {
      newSocket?.on(`PAYMENT_RES`, (message) => {
        NotificationManager.success(message.replace(`,`, ``))
        getAllInvoices()
      })
      newSocket?.on(`PAYMENT_CANCEL`, (message) => {
        NotificationManager.error(message.replace(`,`, ``))
        getAllInvoices()
      })
    }

    return () => {
      newSocket?.off(`PAYMENT_RES`)
      newSocket?.off(`PAYMENT_CANCEL`)
    }
  }, [newSocket])

  return (
    <Container shadow="yes">
      {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      {activeCall && (
        <ReactModal
          title="Payment Call"
          // closeModal={() => {
          //   setActiveCall(false)
          //   setCreateInvoice(false)
          // }}
          scroll={createInvoice}
          width={createInvoice ? `screen` : `auto`}
          closeButton={true}
          maxWidth="6xl"
        >
          <PaymentCall
            invoiceCall={invoice}
            customClass="px-12 w-80 justify-center"
            closeModal={() => {
              setActiveCall(false)
              setCreateInvoice(false)
            }}
            isCreateInvoice={createInvoice}
            refetchInvoices={getAllInvoices}
          />
        </ReactModal>
      )}
      {activeCall2 && (
        <ReactModal
          title="Schedule Payment Call"
          closeModal={() => setActiveCall2(false)}
          className={`p-3`}
        >
          <div className="px-5 space-y-2">
            <div className="relative w-96">
              <ReactSelectField
                title="Language*"
                placeholder="Select Language"
                setValue={(value) => {
                  setLanguage(value)
                }}
                errorMessage={errors.languageError}
                loadOptions={loadLanguages}
                isMulti={false}
                isSearchable={true}
              />
            </div>

            <div className={`relative w-96`}>
              <InputField
                title="Amount*"
                type="number"
                placeholder="Enter Amount"
                setValue={(e) => setAmount(e.target.value)}
                errorMessage={errors.amountError}
              />
            </div>

            <div className="relative w-96">
              <ReactSelectField
                title="Payment Options*"
                placeholder="Select Payment Option"
                setValue={(value) => {
                  setPaymentOption(value)
                }}
                errorMessage={errors.paymentOptionError}
                loadOptions={paymentOptions}
                isMulti={false}
                isSearchable={true}
              />
            </div>

            {paymentOption.value === `SCHEDULED` && (
              <div className={`relative w-96`}>
                <DatePickerField
                  title="Date & Time"
                  placeholder="Select Date & Time"
                  timePicker={true}
                  value={paymentCallDate}
                  errorMessage={errors.paymentCallDateError}
                  setValue={(e) => setPaymentCallDate(e)}
                  minDate={new Date()}
                  dateFormat={`Pp`}
                />
              </div>
            )}
          </div>

          {/* <div className="w-1/2"> */}
          <SubmitButton onClick={validateFrom} className="rounded-lg  mt-3">
            <span className="text-sm ">Add</span>
          </SubmitButton>
          {/* </div> */}
        </ReactModal>
      )}
      <PageHeader bgcolor="bg-white">
        {permissionGranted([
          `payments:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <div className="flex gap-3">
            <AddButton
              onClick={() => history.push(`${url}/addPayment`)}
              disabled={invoiceBalance !== 0}
            >
              <span
                data-tip={`${
                  invoiceBalance === 0
                    ? `Add Payment`
                    : `Invoice balance remaining`
                }`}
                className="tooltip tooltip-right"
              >
                Add
              </span>
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="text-white mt-0.5"
              />
            </AddButton>
            {/* {preferredContactMethods?.includes(`CALL`) && (
              <AddButton
                onClick={() => {
                  setActiveCall((prev) => !prev)
                  setCreateInvoice((prev) => !prev)
                }}
                disabled={invoiceBalance !== 0}
              >
                <span
                  data-tip={`${
                    invoiceBalance === 0
                      ? `Add Payment`
                      : `Invoice balance remaining`
                  }`}
                  className="tooltip tooltip-right"
                >
                  Call
                </span>
                <FontAwesomeIcon icon={faPhone} className="text-white mt-0.5" />
              </AddButton>
            )} */}
          </div>
        )}
        <div className="flex items-center">
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              setValue={(e) => {
                setSearchQuery(e.target.value)
                setCurrentPage(1)
                setCustomSearching(true)
                callInvoicesQuery()
              }}
            />
          </div>
        </div>
      </PageHeader>

      <Spinner loading={isLoading || isFetching || deletingPayment} />

      {error ? (
        <Reload refetch={() => getAllInvoices()} />
      ) : (
        <div
          className={`my-1 sm:mb-0 justify-between ${
            (deletingPayment || isLoading || isFetching) &&
            ` opacity-30 pointer-events-none`
          } `}
        >
          <div className="overflow-x-auto  rounded-t-md">
            <table className="w-full border-collapse">
              <TableHeader>
                <TableRow>
                  {tableColumns.map((item, idx) => {
                    return (
                      <TableCell key={idx}>
                        <div className="cursor-pointer select-none text-sm flex justify-center truncate">
                          <div
                            onClick={() => {
                              handleSorting(item.value)
                            }}
                            className={`flex ${
                              item.value === sort ? `text-red-500` : ``
                            }`}
                          >
                            {item.label}
                          </div>
                          <div
                            className="ml-1 rounded-sm cursor-pointer flex relative"
                            onClick={() => handleSorting(`-` + item.value)}
                          >
                            <FontAwesomeIcon
                              icon={
                                sort.includes(`-`) && sort == `-` + item.value
                                  ? faSortUp
                                  : faSortDown
                              }
                              size="lg"
                              className={`${
                                sort.includes(`-`) && sort == `-` + item.value
                                  ? `text-red-500`
                                  : `-mt-1.5`
                              }`}
                            />
                          </div>
                        </div>
                      </TableCell>
                    )
                  })}

                  {permissionGranted([
                    `corporate-account-payment:*`,
                    `franchise-account-payment:*`,
                    `corporate-manager:*`,
                    `manager:*`,
                    `admin:*`,
                    `payments:*`,
                  ]) && (
                    <TableCell className="float-right text-sm">
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                {invoicesData?.invoices?.length != 0 ? (
                  invoicesData?.invoices?.map((invoice, index) => (
                    <TableRow
                      key={index.toString()}
                      className="hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        if (
                          permissionGranted([
                            `payments:read`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ])
                        ) {
                          history.push(`${url}/payments/${invoice._id}`)
                        }
                      }}
                    >
                      <td className="py-0.5 text-sm text-center z-0 break-words max-w-px-140 capitalize truncate">
                        {invoice?._carrier?.label ? (
                          invoice?._carrier?.label?.toLowerCase()
                        ) : (
                          <Badge>N/A</Badge>
                        )}
                      </td>
                      <td className="py-0.5 text-sm text-center z-0 break-words max-w-px-140 capitalize truncate">
                        {invoice?._agent?.label ? (
                          invoice?._agent?.label?.toLowerCase()
                        ) : (
                          <Badge>N/A</Badge>
                        )}
                      </td>
                      <td className="py-0.5 text-sm text-center z-0 break-words max-w-px-140 capitalize truncate">
                        {invoice?._policy?.label}
                      </td>

                      <td className="py-0.5 text-sm text-center z-0 break-words max-w-px-140">
                        <Badge type="success">{invoice?._invoiceId}</Badge>
                      </td>
                      <td className="py-0.5 text-sm text-center z-0">
                        <Badge
                          type={`${invoice?.isVoid ? `success` : `danger`}`}
                        >
                          {invoice?.isVoid.toString().toUpperCase()}
                        </Badge>
                      </td>
                      <td className="py-0.5 text-sm text-center z-0">
                        {invoice?.totalAmount}
                      </td>
                      <td className="py-0.5 text-sm text-center z-0">
                        {invoice?.paidAmount}
                      </td>
                      <td className="py-0.5 text-sm text-center z-0">
                        {invoice?.remainingAmount}
                      </td>
                      <td className="py-0.5 text-sm text-center z-0  truncate">
                        {dateFormat(invoice?.dueDate)}
                      </td>

                      {/* Actions TD */}
                      {permissionGranted([
                        `corporate-account-payment:*`,
                        `franchise-account-payment:*`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                        `payments:*`,
                        `payments:update`,
                      ]) && (
                        <td className="px-2 py-2 text-sm z-0 flex float-right text-gray-400 ">
                          <span
                            data-tip={`${
                              invoice?.remainingAmount !== 0
                                ? `Add Remaining Amount`
                                : `No Remaining Amount `
                            }`}
                            className="tooltip tooltip-left "
                          >
                            <FontAwesomeIcon
                              className={`mx-1 transform transition ${
                                invoice?.remainingAmount !== 0
                                  ? `cursor-pointer`
                                  : `cursor-not-allowed`
                              }   hover:text-red-500  hover:-translate-y-0 hover:scale-105 `}
                              icon={faPlusCircle}
                              size="1x"
                              onClick={(e) => {
                                invoice?.remainingAmount !== 0 &&
                                  history.push(
                                    `${url}/payments/add-receipt/${invoice._id}`,
                                  )

                                e.stopPropagation()
                              }}
                            />
                          </span>
                          {/* <span
                            data-tip={`${
                              currentActiveCall?.activeCall
                                ? `Call is In progress`
                                : invoice?.preferredContactMethods?.includes(
                                    `CALL`,
                                  )
                                ? `call method`
                                : invoice?.remainingAmount !== 0
                                ? `Start Payment Call`
                                : `No Remaining Amount `
                            }`}
                            className="tooltip tooltip-left "
                          >
                            <FontAwesomeIcon
                              // className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                              className={`mx-1 transform transition ${
                                invoice?.remainingAmount !== 0 &&
                                !currentActiveCall?.activeCall
                                  ? `cursor-pointer`
                                  : `cursor-not-allowed`
                              }   hover:text-red-500  hover:-translate-y-0 hover:scale-105 `}
                              icon={faPhone}
                              size="1x"
                              onClick={(e) => {
                                invoice?.remainingAmount !== 0 &&
                                  !currentActiveCall?.activeCall &&
                                  (e.stopPropagation(),
                                  setInvoice(invoice),
                                  setActiveCall(true))
                              }}
                            />
                          </span>
                          <span
                            data-tip={`${
                              invoice?.remainingAmount !== 0
                                ? `Add Payment `
                                : `No Remaining Amount `
                            }`}
                            className="tooltip tooltip-left "
                          >
                            <FontAwesomeIcon
                              className={`mx-1 transform transition ${
                                invoice?.remainingAmount !== 0
                                  ? `cursor-pointer`
                                  : `cursor-not-allowed`
                              }   hover:text-red-500  hover:-translate-y-0 hover:scale-105 `}
                              icon={faDollarSign}
                              size="1x"
                              onClick={(e) => {
                                invoice?.remainingAmount !== 0 &&
                                  (e.stopPropagation(),
                                  setInvoice(invoice),
                                  setActiveCall2(true))
                              }}
                            />
                          </span> */}
                        </td>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center p-6">
                      <div>No Payment Found</div>
                    </td>
                  </tr>
                )}
              </TableBody>
            </table>
          </div>
          <div className="px-4 py-2">
            <ReactPagination
              offset={offset}
              currentPage={currentPage}
              totalPages={invoicesData?.totalPages}
              totalRecords={invoicesData?.totalRecords}
              curerntPageRecords={invoicesData?.currentPageRecords}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      )}
    </Container>
  )
}
