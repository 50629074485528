import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TOne } from 'App/Styled'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { setPolicyActiveActivityTab } from 'App/Redux/actions'
import { PolicyNotes } from 'App/Components/OtherPolicies/PolicyNotes'
import { permissionGranted } from 'App/Services'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
]

export const PolicyActivity = () => {
  const { activeActivityTab } = useSelector(({ policy }) => policy)
  const dispatch = useDispatch()

  return (
    <div className="px-2">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([
            `notes:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ])
            ? activityTabs
            : []
        }
        getActiveTab={(val) => dispatch(setPolicyActiveActivityTab(val))}
      />
      {permissionGranted(
        [`notes:read`, `corporate-manager:*`, `manager:*`, `admin:*`] || [
          `notes:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      ) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <PolicyNotes />
          </TOne>
        </div>
      ) : null}
    </div>
  )
}
