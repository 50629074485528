import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'
import React from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router'
import { Container } from 'App/Styled'
import { useDispatch } from 'react-redux'
import { setCurrentClaim } from 'App/Redux/actions'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

import { setClaimMedia } from 'App/Redux/actions'
import { timeFormatUTC } from 'App/Services'
import dateFormat from 'App/Services/General/dateFormat'

export const AboutClaim = () => {
  const dispatch = useDispatch()

  const {
    params: { cId },
  } = useRouteMatch()

  const {
    data: previewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `ClaimPreviewApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/claim/${cId}`).then(
        (res) => {
          return res.data.data.claim
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentClaim(res))
      },
    },
  )
  dispatch(setClaimMedia(previewData))

  return (
    <Container>
      <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md">
        {isLoading || isFetching ? (
          <div className="text-center p-6">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : previewData?.length ? (
          <p className=" text-center p-6">No Data Found</p>
        ) : (
          <div className="rounded-md overflow-hidden py-4">
            <div className="flex flex-col md:flex-row  items-start justify-evenly">
              <div className="flex-1 mx-2  rounded">
                <div className="grid grid-cols-12 px-2 py-2">
                  <div className="col-span-6 text-xl">Claim Info</div>
                </div>
                <hr />
                <div className="flex flex-col md:flex-row px-2">
                  <div className="flex-1">
                    <CardField title="Claim Id" value={previewData?.claimId} />
                    <CardField
                      title="Name"
                      value={previewData?.nameofCustomer}
                    />

                    <CardField
                      title="Policy No"
                      value={`${previewData?.policyNo}`}
                    />
                    <CardField title="Status" value={previewData?.status} />
                  </div>
                  <div className="flex-1">
                    <CardField
                      title="CreatedAt"
                      value={`${dateFormat(
                        previewData?.createdAt,
                      )} ${timeFormatUTC(previewData?.createdAt)}`}
                    />

                    <CardField
                      title="updatedAt"
                      value={`${dateFormat(
                        previewData?.updatedAt,
                      )} ${timeFormatUTC(previewData?.updatedAt)}`}
                    />

                    <CardField
                      title="Settled Amount"
                      value={
                        previewData?.amount ? `$${previewData?.amount}` : ``
                      }
                    />
                  </div>
                </div>
                <div className="px-2">
                  <CardField
                    title="Description"
                    value={previewData?.description}
                  />
                  <CardField
                    title="Agent's Remarks"
                    value={previewData?.agentResponse}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}
