import React, { createContext } from 'react'
import { Header } from 'App/Components/Common/Header'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import {
  setAssetId,
  setCommunicationCredentials,
  setCurrentCustomer,
} from 'App/Redux/actions'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Tabs } from './Tabs'

export const CustomerContext = createContext(null)

export const Customer = () => {
  const dispatch = useDispatch()

  const {
    params: { customerId },
  } = useRouteMatch()
  const {
    data: customerData,
    error,
    refetch,
    isFetching,
    isLoading,
  } = useQuery(
    `CustomerPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/customers/${customerId}`,
      ).then((res) => {
        return res.data.data.customer
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentCustomer(res))
        dispatch(setAssetId(res._assetId))
        dispatch(
          setCommunicationCredentials({
            id: res._id,
            phone: res.contactInfo?.phone,
            img: res.img,
            customerId: res._id,
            doNotContact: res?.doNotContact ? true : false,
            preferredContactMethods: res?.preferredContactMethods,
            email: res?.contactInfo?.email,
          }),
        )
      },
    },
  )
  return (
    <CustomerContext.Provider value={{ error, refetch, isLoading, isFetching }}>
      <Header
        image={customerData?.img}
        name={`${customerData?.firstName} ${customerData?.lastName}`}
        phone={customerData?.contactInfo?.phone}
        email={customerData?.contactInfo?.email}
        address={customerData?.address}
        // remainingBalancePolicies={customerData?.totalRemainingOfPolicies}
        remainingBalanceInvoices={customerData?.totalRemainingOfInvoices}
        noBalance={true}
        isLoading={isLoading || isFetching}
        communication={true}
        locationName={customerData?._location?.label}
      />

      <Tabs />
    </CustomerContext.Provider>
  )
}
