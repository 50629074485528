import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  generalNotificationId: ``,
}

const generalNotificationSlice = createSlice({
  name: `generalNotification`,

  initialState,
  reducers: {

    setGeneralNotificationId: (state, { payload }) => {
      state.generalNotificationId = payload
    },
  },
})

export const { setGeneralNotificationId } =
  generalNotificationSlice.actions

export const generalNotification = generalNotificationSlice.reducer
