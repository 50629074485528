import { Checkbox } from 'App/Components/Common/Checkbox'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { InputField } from 'App/Components/Common/InputField'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL } from 'App/Config'
import { loadLobs, loadZipCodes, ValidateZip } from 'App/Services'
import { Container, SubmitButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'

import { NotificationManager } from 'react-notifications'
import { useMutation } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Spinner } from 'App/Components/Common/Spinner'

import { useSelector } from 'react-redux'

export const UpdateCarrier = () => {
  const history = useHistory()
  const {
    params: { carrierId },
  } = useRouteMatch()

  const { currentCarrier } = useSelector((state) => state.carrier)
  const [carrier, setCarrier] = useState({
    name: ``,
    fein: ``,
    sic: ``,
    yearBusinessStarted: ``,
    naic: ``,
    financeCoSearchCode: ``,
    description: ``,
    isActive: ``,

    // businessinformation
    // minimumDownPayment: ``,
    // minimumDownPaymentType: ``,
    // interestRate: ``,
    // interestrateType: ``,
    // minimumEarned: ``,
    // minimumEarnedtype: ``,
    // remitBy: ``,
    // grossComissionDueWithin: ``,
    // remainingPayments: ``,
    // amBestRatingBCR: ``,
    // amBestRatingFSC: ``,
    // premiumSent: ``,
  })
  const [errors, setErrors] = useState({
    formSubmit: false,
    nameError: ``,
    feinError: ``,
    sicError: ``,
    yearBusinessStartedError: ``,
    naicError: ``,
    financeCoSearchCodeError: ``,
    descriptionError: ``,

    // minimumDownPaymentError: ``,
    // minimumDownPaymentTypeError: ``,
    // interestRateError: ``,
    // interestrateTypeError: ``,
    // minimumEarnedError: ``,
    // minimumEarnedtypeError: ``,
    // remitByError: ``,
    // grossComissionDueWithinError: ``,
    // remainingPaymentsError: ``,
    // amBestRatingBCRError: ``,
    // amBestRatingFSCError: ``,
    // premiumSentError: ``,

    //    address
    // unitNoError: ``,
    zipError: ``,
    streetNoError: ``,
    cityError: ``,
    stateError: ``,
    coordinatesError: ``,
  })

  const [lineOfBusinesses, setLineOfBusinesses] = useState({
    lineOfBusiness: ``,
    lineOfBusinessError: ``,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    zip: ``,
    streetNo: ``,
    city: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  // Dynamic Forms
  const handleAddressChange = (name, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //handle zip code
  const handleZipChange = async (val) => {
    const data = await ValidateZip(val.value)
    if (data) {
      const res = data.address

      setAddress((prevState) => ({
        ...prevState,
        [`city`]: res.city,
        [`state`]: res.state,
        [`zip`]: { value: res.zipCode, label: res.zipCode },
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        [`lat`]: res.lat,
        [`lng`]: res.long,
      }))
    }
  }
  // handle map
  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  // Form Controlled input handler
  // const handleCarrierChange = useDebouncedCallback((name, value) => {
  //   setCarrier((carrier) => ({ ...carrier, [name]: value }))
  // }, 5)
  //  Form Validations
  const ValidForm = () => {
    if (!carrier.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }
    if (!carrier.fein.length) {
      handleErrors(`feinError`, `Enter FEIN`)
    } else {
      handleErrors(`feinError`, ``)
    }
    if (!carrier.sic.length) {
      handleErrors(`sicError`, `Enter Value`)
    } else {
      handleErrors(`sicError`, ``)
    }

    if (!carrier.yearBusinessStarted.length) {
      handleErrors(`yearBusinessStartedError`, `Enter Value`)
    } else if (
      JSON.parse(carrier.yearBusinessStarted) > new Date().getFullYear()
    ) {
      handleErrors(
        `yearBusinessStartedError`,
        `Year must be less than current year`,
      )
    } else {
      handleErrors(`yearBusinessStartedError`, ``)
    }
    if (!carrier.naic.length) {
      handleErrors(`naicError`, `Enter Value`)
    } else {
      handleErrors(`naicError`, ``)
    }
    if (!carrier.financeCoSearchCode.length) {
      handleErrors(`financeCoSearchCodeError`, `Enter Code`)
    } else {
      handleErrors(`financeCoSearchCodeError`, ``)
    }
    if (!carrier.description.length) {
      handleErrors(`descriptionError`, `Enter Description`)
    } else {
      handleErrors(`descriptionError`, ``)
    }
    // business / payment information error

    // if (!carrier.minimumDownPayment.toString().length) {
    //   handleErrors(`minimumDownPaymentError`, `Enter Payment`)
    // } else {
    //   handleErrors(`minimumDownPaymentError`, ``)
    // }
    // if (!carrier.minimumDownPaymentType?.value) {
    //   handleErrors(`minimumDownPaymentTypeError`, `Select Type `)
    // } else {
    //   handleErrors(`minimumDownPaymentTypeError`, ``)
    // }
    // if (!carrier.interestRate.toString().length) {
    //   handleErrors(`interestRateError`, `Enter Value`)
    // } else {
    //   handleErrors(`interestRateError`, ``)
    // }
    // if (!carrier.interestrateType?.value) {
    //   handleErrors(`interestrateTypeError`, `Select Type`)
    // } else {
    //   handleErrors(`interestrateTypeError`, ``)
    // }
    // if (!carrier.minimumEarned.toString().length) {
    //   handleErrors(`minimumEarnedError`, `Enter Amount`)
    // } else {
    //   handleErrors(`minimumEarnedError`, ``)
    // }
    // if (!carrier.minimumEarnedtype?.value) {
    //   handleErrors(`minimumEarnedtypeError`, `Select Type`)
    // } else {
    //   handleErrors(`minimumEarnedtypeError`, ``)
    // }

    // if (!carrier.remitBy?.length) {
    //   handleErrors(`remitByError`, `Select Value`)
    // } else {
    //   handleErrors(`remitByError`, ``)
    // }

    // if (!carrier.grossComissionDueWithin) {
    //   handleErrors(`grossComissionDueWithinError`, `Select  Date`)
    // } else {
    //   handleErrors(`grossComissionDueWithinError`, ``)
    // }

    // if (!carrier.remainingPayments.toString().length) {
    //   handleErrors(`remainingPaymentsError`, `Enter Payments`)
    // } else {
    //   handleErrors(`remainingPaymentsError`, ``)
    // }

    // if (!carrier.amBestRatingBCR?.value) {
    //   handleErrors(`amBestRatingBCRError`, `Select Rating`)
    // } else {
    //   handleErrors(`amBestRatingBCRError`, ``)
    // }

    // if (!carrier.amBestRatingFSC?.value) {
    //   handleErrors(`amBestRatingFSCError`, `Select Rating`)
    // } else {
    //   handleErrors(`amBestRatingFSCError`, ``)
    // }

    // if (!carrier.premiumSent?.value) {
    //   handleErrors(`premiumSentError`, `Select Value`)
    // } else {
    //   handleErrors(`premiumSentError`, ``)
    // }

    // line of business Errors
    if (!lineOfBusinesses?.lineOfBusiness?.length) {
      handlelineOfBusinessErrors(`lineOfBusinessError`, `Select Lob`)
    } else {
      handlelineOfBusinessErrors(`lineOfBusinessError`, ``)
    }

    //    address error
    // if (!address?.unitNo?.length) {
    //   handleErrors(`unitNoError`, `Enter Unit No`)
    // } else {
    //   handleErrors(`unitNoError`, ``)
    // }

    if (!address.zip?.value?.length) {
      handleErrors(`zipError`, `Select Zip`)
    } else {
      handleErrors(`zipError`, ``)
    }
    if (!address.streetNo.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }
    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }
    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }
    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, `Select Coordinates`)
    } else {
      handleErrors(`coordinatesError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handlelineOfBusinessErrors = (name, val) => {
    setLineOfBusinesses((preLobs) => ({
      ...preLobs,
      [name]: val,
    }))
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.feinError.length &&
      !errors.sicError.length &&
      !errors.yearBusinessStartedError.length &&
      !errors.naicError.length &&
      !errors.financeCoSearchCodeError.length &&
      !errors.descriptionError.length &&
      !errors.yearBusinessStartedError.length &&
      !errors.nameError.length &&
      // !errors.minimumDownPaymentError.length &&
      // !errors.minimumDownPaymentTypeError.length &&
      // !errors.interestRateError.length &&
      // !errors.interestrateTypeError.length &&
      // !errors.minimumEarnedError.length &&
      // !errors.minimumEarnedtypeError.length &&
      // !errors.remitByError.length &&
      // !errors.grossComissionDueWithinError.length &&
      // !errors.remainingPaymentsError.length &&
      // !errors.amBestRatingBCRError.length &&
      // !errors.amBestRatingFSCError.length &&
      // !errors.premiumSentError.length &&
      // !errors.unitNoError.length &&
      !errors.zipError.length &&
      !errors.streetNoError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.coordinatesError.length &&
      !lineOfBusinesses.lineOfBusinessError.length
    ) {
      updateCarrier(updatePayload())
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}

    let isUpdatedLob
    if (
      lineOfBusinesses?.lineOfBusiness.length === currentCarrier?.lobs.length
    ) {
      isUpdatedLob = lineOfBusinesses?.lineOfBusiness.some(
        (eachLob, index) => eachLob.value !== currentCarrier?.lobs[index],
      )
    } else {
      isUpdatedLob = true
    }
    if (isUpdatedLob) {
      data.lobs = lineOfBusinesses?.lineOfBusiness.map((lob) => lob?.value)
    }

    if (carrier.name !== currentCarrier.name) {
      data.name = carrier.name
    }
    if (carrier.fein !== currentCarrier.fein) {
      data.fein = carrier.fein
    }
    if (carrier.sic !== currentCarrier.sic) {
      data.sic = carrier.sic
    }

    if (carrier.yearBusinessStarted !== currentCarrier.yearBusinessStarted) {
      data.yearBusinessStarted = carrier.yearBusinessStarted
    }
    if (carrier.naic !== currentCarrier.naic) {
      data.naic = carrier.naic
    }
    if (carrier.financeCoSearchCode !== currentCarrier.financeSearchCode) {
      data.financeSearchCode = carrier.financeCoSearchCode
    }
    if (carrier.description !== currentCarrier.description) {
      data.description = carrier.description
    }
    if (carrier.isActive !== currentCarrier.isActive) {
      data.isActive = carrier.isActive
      data.status = carrier.isActive ? `ACTIVE` : `INACTIVE`
    }
    // if (carrier.minimumDownPayment !== currentCarrier.minimumDownPayment) {
    //   data.minimumDownPayment = carrier.minimumDownPayment
    // }
    // if (
    //   carrier?.minimumDownPaymentType.value !==
    //   currentCarrier?.minimumDownPaymentType
    // ) {
    //   data.minimumDownPaymentType = carrier.minimumDownPaymentType.value
    // }
    // if (carrier.interestRate !== currentCarrier.interestRate) {
    //   data.interestRate = carrier.interestRate
    // }
    // if (carrier?.interestrateType.value !== currentCarrier?.interestrateType) {
    //   data.interestrateType = carrier?.interestrateType.value
    // }
    // if (carrier.minimumEarned !== currentCarrier.minimumEarned) {
    //   data.minimumEarned = carrier.minimumEarned
    // }
    // if (
    //   carrier?.minimumEarnedtype.value !== currentCarrier?.minimumEarnedtype
    // ) {
    //   data.minimumEarnedtype = carrier?.minimumEarnedtype.value
    // }
    // if (carrier.remitBy !== currentCarrier.remitBy) {
    //   data.remitBy = carrier.remitBy
    // }

    // if (
    //   carrier.grossComissionDueWithin.toString() !==
    //   new Date(currentCarrier.grossComissionDueWithin).toString()
    // ) {
    //   data.grossComissionDueWithin = carrier.grossComissionDueWithin
    // }

    // if (carrier.remainingPayments !== currentCarrier.remainingPayments) {
    //   data.remainingPayments = carrier.remainingPayments
    // }
    // if (carrier?.amBestRatingBCR.value !== currentCarrier?.amBestRatingBCR) {
    //   data.amBestRatingBCR = carrier?.amBestRatingBCR.value
    // }

    // if (carrier?.amBestRatingFSC.value !== currentCarrier?.amBestRatingFSC) {
    //   data.amBestRatingFSC = carrier?.amBestRatingFSC.value
    // }
    // if (carrier?.premiumSent.value !== currentCarrier?.premiumSent) {
    //   data.premiumSent = carrier?.premiumSent.value
    // }
    // address
    let adressPayload = {}
    let coordinatesPayload = {}
    if (address.unitNo !== currentCarrier?.address.unitNo) {
      adressPayload.unitNo = address.unitNo
    }
    if (address?.zip?.value !== currentCarrier?.address.zip) {
      adressPayload.zip = address.zip.value
      coordinatesPayload.lat = coordinates.lat.toString()
      coordinatesPayload.long = coordinates.lng.toString()
    }
    if (address.streetNo !== currentCarrier?.address.street) {
      adressPayload.street = address.streetNo
    }
    if (address.city !== currentCarrier?.address.city) {
      adressPayload.city = address.city
    }
    if (address.state !== currentCarrier?.address.state) {
      adressPayload.state = address.state
    }
    if (Object.keys(adressPayload).length !== 0) {
      adressPayload.coordinates = coordinatesPayload
      data.address = adressPayload
    }

    return data
  }

  useEffect(() => {
    if (currentCarrier) {
      setCarrier((preCarrier) => ({
        ...preCarrier,
        name: currentCarrier.name,
        fein: currentCarrier.fein,
        sic: currentCarrier.sic,
        yearBusinessStarted: currentCarrier.yearBusinessStarted?.toString(),
        naic: currentCarrier.naic,
        financeCoSearchCode: currentCarrier.financeSearchCode,
        description: currentCarrier.description,
        isActive: currentCarrier.isActive,

        // businessinformation
        // minimumDownPayment: currentCarrier.minimumDownPayment,
        // minimumDownPaymentType: {
        //   label: currentCarrier.minimumDownPaymentType,
        //   value: currentCarrier.minimumDownPaymentType,
        // },
        // interestRate: currentCarrier.interestRate,
        // interestrateType: {
        //   label: currentCarrier.interestrateType,
        //   value: currentCarrier.interestrateType,
        // },
        // minimumEarned: currentCarrier.minimumEarned,
        // minimumEarnedtype: {
        //   label: currentCarrier.minimumEarnedtype,
        //   value: currentCarrier.minimumEarnedtype,
        // },
        // remitBy: currentCarrier.remitBy,
        // grossComissionDueWithin: new Date(
        //   currentCarrier.grossComissionDueWithin,
        // ),
        // remainingPayments: currentCarrier.remainingPayments,
        // amBestRatingBCR: {
        //   label: currentCarrier.amBestRatingBCR,
        //   value: currentCarrier.amBestRatingBCR,
        // },
        // amBestRatingFSC: {
        //   label: currentCarrier.amBestRatingFSC,
        //   value: currentCarrier.amBestRatingFSC,
        // },
        // premiumSent: {
        //   label: currentCarrier.premiumSent,
        //   value: currentCarrier.premiumSent,
        // },
      }))
      setAddress((preAddress) => ({
        ...preAddress,
        unitNo: currentCarrier.address?.unitNo,
        zip: {
          label: currentCarrier.address?.zip,
          value: currentCarrier.address?.zip,
        },
        streetNo: currentCarrier.address?.street,
        city: currentCarrier.address?.city,
        state: currentCarrier.address?.state,
      }))
      setCoordinates((preAddress) => ({
        ...preAddress,
        lat: currentCarrier.address?.coordinates?.lat
          ? currentCarrier.address?.coordinates?.lat
          : 0,
        lng: currentCarrier.address?.coordinates?.long
          ? currentCarrier.address?.coordinates?.long
          : 0,
      }))

      let lobs = currentCarrier?.lobs.map((_lob) => ({
        value: _lob,
        label: _lob.replace(`_`, ` `),
      }))
      setLineOfBusinesses((preLobs) => ({
        ...preLobs,
        [`lineOfBusiness`]: lobs,
      }))
    }
  }, [currentCarrier])

  //carrier add mutation
  const {
    mutate: updateCarrier,
    isLoading,
    isError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/carriers/${carrierId}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          NotificationManager.success(`Operation successful`)
          !res.hasError && history.push(`/carriers/${carrierId}`)
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  return (
    <Container>
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <div className="text-xl font-medium py-2 px-4  bg-red-500 rounded-t-md text-white">
        Update Carrier
      </div>
      <Spinner loading={isLoading} />

      <div className={`p-4 ${isLoading && ` opacity-30 pointer-events-none`} `}>
        <div className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-6 gap-y-2 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name *"
              placeholder="Enter Name"
              value={carrier.name}
              errorMessage={errors.nameError}
              // setValue={(e) => handleCarrierChange(`name`, e.target.value)}
              setValue={(e) => {
                setCarrier((prevState) => ({
                  ...prevState,
                  [`name`]: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Federal Emp Identity No *"
              placeholder="Enter value"
              value={carrier.fein}
              errorMessage={errors.feinError}
              // setValue={(e) => handleCarrierChange(`fein`, e.target.value)}
              setValue={(e) => {
                setCarrier((prevState) => ({
                  ...prevState,
                  [`fein`]: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="SIC *"
              placeholder="Enter value"
              value={carrier.sic}
              errorMessage={errors.sicError}
              // setValue={(e) => handleCarrierChange(`sic`, e.target.value)}
              setValue={(e) => {
                setCarrier((prevState) => ({
                  ...prevState,
                  [`sic`]: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              title="Year Business Started *"
              placeholder="Enter value"
              value={carrier.yearBusinessStarted}
              errorMessage={errors.yearBusinessStartedError}
              // setValue={(e) =>
              //   handleCarrierChange(`yearBusinessStarted`, e.target.value)
              // }
              setValue={(e) => {
                setCarrier((prevState) => ({
                  ...prevState,
                  [`yearBusinessStarted`]: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="NAIC *"
              placeholder="Enter Value"
              value={carrier.naic}
              errorMessage={errors.naicError}
              // setValue={(e) => handleCarrierChange(`naic`, e.target.value)}
              setValue={(e) => {
                setCarrier((prevState) => ({
                  ...prevState,
                  [`naic`]: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title=" Finance Co-Search Code *"
              placeholder="Enter Code"
              value={carrier.financeCoSearchCode}
              errorMessage={errors.financeCoSearchCodeError}
              // setValue={(e) =>
              //   handleCarrierChange(`financeCoSearchCode`, e.target.value)
              // }
              setValue={(e) => {
                setCarrier((prevState) => ({
                  ...prevState,
                  [`financeCoSearchCode`]: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Description *"
              placeholder="Enter value"
              value={carrier.description}
              errorMessage={errors.descriptionError}
              // setValue={(e) =>
              //   handleCarrierChange(`description`, e.target.value)
              // }
              setValue={(e) => {
                setCarrier((prevState) => ({
                  ...prevState,
                  [`description`]: e.target.value,
                }))
              }}
            />
          </div>

          <div className="flex-1 relative">
            <div className="flex-1 relative mt-6">
              <Checkbox
                type="checkbox"
                name="isActive"
                title="Is Active *"
                value={carrier.isActive}
                errorMessage={errors.isActiveError}
                setValue={() =>
                  setCarrier((prevState) => ({
                    ...prevState,
                    [`isActive`]: !carrier.isActive,
                  }))
                }
              />
            </div>
          </div>
          <div className="flex-1 relative"></div>
          <div className="flex-1 relative"></div>
        </div>

        {/* Address and map */}
        <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Address
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="unitNo"
              title="Unit No"
              placeholder="Enter Unit No"
              value={address.unitNo}
              // errorMessage={errors.unitNoError}
              setValue={(e) => handleAddressChange(`unitNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              name="zip"
              placeholder="Select Zip"
              isMulti={false}
              value={address.zip}
              errorMessage={errors.zipError}
              setValue={(e) => handleZipChange(e)}
              loadOptions={loadZipCodes}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="streetNo"
              title="Street *"
              placeholder="Enter Street"
              value={address.streetNo}
              errorMessage={errors.streetNoError}
              setValue={(e) => handleAddressChange(`streetNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="city"
              title="City *"
              placeholder="Enter City"
              value={address.city}
              errorMessage={errors.cityError}
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="state"
              title="State *"
              placeholder="Enter State"
              value={address.state}
              errorMessage={errors.stateError}
              id="lAddress"
              disabled={true}
            />
          </div>
        </div>
        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError.length !== 0 && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView
              center={[coordinates?.lat, coordinates?.lng]}
              zoom={3}
            />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div>
        {/* Start Bussiness information */}
        {/* <div className="my-4">
          <Collapsable title="Business/Payment Information ">
            <div className="grid grid-cols-2 gap-16">
              <div className="col-1-1">
                <div className="flex gap-2 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Minimum Down Payment"
                      name="minimumDownPayment"
                      placeholder="Enter Payment"
                      errorMessage={errors.minimumDownPaymentError}
                      value={carrier.minimumDownPayment}
                      setValue={(e) => handleCarrierChange(e)}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Payment Type"
                      name="minimumDownPaymentType"
                      placeholder="Select Type"
                      isMulti={false}
                      value={carrier.minimumDownPaymentType}
                      errorMessage={errors.minimumDownPaymentTypeError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`minimumDownPaymentType`]: e,
                        }))
                      }
                      loadOptions={loadpaymentType}
                      isSearchable={true}
                    />
                  </div>
                </div>
                <div className="flex gap-2 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Interest Rate"
                      name="interestRate"
                      placeholder="Enter Value"
                      value={carrier.interestRate}
                      errorMessage={errors.interestRateError}
                      setValue={(e) => handleCarrierChange(e)}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Type"
                      name="interestrateType"
                      placeholder="Select Type"
                      isMulti={false}
                      value={carrier.interestrateType}
                      errorMessage={errors.interestrateTypeError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`interestrateType`]: e,
                        }))
                      }
                      loadOptions={loadpaymentType}
                      isSearchable={true}
                    />
                  </div>
                </div>
                <div className="flex gap-2 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Minimum Earned"
                      name="minimumEarned"
                      placeholder="Enter Value"
                      value={carrier.minimumEarned}
                      errorMessage={errors.minimumEarnedError}
                      setValue={(e) => handleCarrierChange(e)}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Type"
                      name="minimumEarnedtype"
                      placeholder="Select Type"
                      isMulti={false}
                      value={carrier.minimumEarnedtype}
                      errorMessage={errors.minimumEarnedtypeError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`minimumEarnedtype`]: e,
                        }))
                      }
                      loadOptions={loadpaymentType}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="flex gap-4 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Remit By"
                      name="remitBy"
                      placeholder="Enter Value"
                      errorMessage={errors.remitByError}
                      value={carrier.remitBy}
                      setValue={(e) => handleCarrierChange(e)}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <DatePickerField
                      name="grossComissionDueWithin"
                      title="Gross Comission Due Within"
                      placeholder="Select Date "
                      errorMessage={errors.grossComissionDueWithinError}
                      value={carrier.grossComissionDueWithin}
                      setValue={(date) => {
                        setCarrier((preState) => ({
                          ...preState,
                          [`grossComissionDueWithin`]: date,
                        }))
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Remaining Payments"
                      name="remainingPayments"
                      placeholder="Enter Payment"
                      errorMessage={errors.remainingPaymentsError}
                      value={carrier.remainingPayments}
                      setValue={(e) => handleCarrierChange(e)}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="A.M Best Rating BCR"
                      name="amBestRatingBCR"
                      placeholder="Select Rating"
                      isMulti={false}
                      value={carrier.amBestRatingBCR}
                      errorMessage={errors.amBestRatingBCRError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`amBestRatingBCR`]: e,
                        }))
                      }
                      loadOptions={loadbestRatingBCR}
                      isSearchable={true}
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="A.M Best Rating FSC"
                      name="amBestRatingFSC"
                      placeholder="Select Rating"
                      isMulti={false}
                      value={carrier.amBestRatingFSC}
                      errorMessage={errors.amBestRatingFSCError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`amBestRatingFSC`]: e,
                        }))
                      }
                      loadOptions={loadbestRatingFSC}
                      isSearchable={true}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Premium Sent"
                      name="premiumSent"
                      placeholder="Choose Type"
                      isMulti={false}
                      value={carrier.premiumSent}
                      errorMessage={errors.premiumSentError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`premiumSent`]: e,
                        }))
                      }
                      loadOptions={loadpremiumSent}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Collapsable>
        </div> */}
        {/* End Bussiness information */}

        {/*Start line of business */}
        <div className="my-4">
          <Collapsable title="Line of business">
            <div className="grid grid-cols-12">
              <div className="col-span-12 flex gap-6 ">
                <div className="flex-1 relative">
                  <ReactSelectField
                    title="Line Of Business"
                    placeholder="Select LOB"
                    value={lineOfBusinesses.lineOfBusiness}
                    errorMessage={lineOfBusinesses.lineOfBusinessError}
                    setValue={(e) =>
                      setLineOfBusinesses((prevState) => ({
                        ...prevState,
                        [`lineOfBusiness`]: e,
                      }))
                    }
                    isMulti={true}
                    loadOptions={loadLobs}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
          </Collapsable>
        </div>
        {/*End Agent Carrier Code */}
      </div>
      <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
        <SubmitButton onClick={ValidForm}>Update Carrier</SubmitButton>
      </div>
    </Container>
  )
}
