import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Container } from 'App/Styled'
import { ReactSelectField } from '../../Common/ReactSelect'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
}

export const CarrierByState = ({ carrierByState }) => {
  const [dataSet, setDataSet] = useState(``)
  const [totalCarriers, setTotalCarriers] = useState(``)
  const [selectState, setSelectState] = useState([])

  useEffect(() => {
    if (carrierByState?.length) {
      let labels = selectState.length
        ? selectState.map((item) => item.label)
        : carrierByState?.map((item) => item.state)
      let values = selectState.length
        ? selectState.map((item) => item.carriers)
        : carrierByState?.map((item) => item.carriers)

      let totalCarriers = values.reduce(
        (partialSum, carrier) => partialSum + carrier,
        0,
      )

      let backgroundColors = carrierByState?.map(() => {
        let randomColor = Math.floor(Math.random() * 16777215).toString(16)
        return `#` + randomColor
      })
      if (labels.length && values.length && backgroundColors.length)
        setDataSet({
          labels: labels,
          datasets: [
            {
              label: `# of Votes`,
              data: values,
              backgroundColor: backgroundColors,
              borderWidth: 1,
            },
          ],
        })
      setTotalCarriers(totalCarriers)
    }
  }, [carrierByState, selectState])

  const option = carrierByState?.map((item) => ({
    value: item.state,
    carriers: item.carriers,
    label: item.state,
  }))
  const loadState = (search) => {
    const options = {
      options: search ? filterState(search) : option,
      hasMore: false,
    }

    return options
  }

  const filterState = (search) => {
    return (
      option
        //some labels are null that is why we used double filters
        .filter((item) => item.label !== null)
        .filter((item) => {
          return item.label.toLowerCase().includes(search.toLowerCase())
        })
    )
  }

  return (
    <Container className="relative">
      <div className="absolute font-bold text-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
        <div className=" mt-16">{`${
          totalCarriers > 0 ? totalCarriers : `No Data`
        }`}</div>
      </div>

      <div className="flex py-9 h-1/4 relative">
        <div className="font-bold absolute left-4 2xl:left-6 top-5">
          <span className="font-bold text-md">Carrier By States</span>
        </div>
        <div className="w-1/2 absolute right-4 top-4 z-0">
          <ReactSelectField
            placeholder=" Select State"
            value={selectState}
            setValue={(e) => {
              // setIsSelected(e.length ? true : false)
              setSelectState(e)
            }}
            isMulti={true}
            loadOptions={loadState}
            isSearchable={true}
            isScrollable={true}
          />
        </div>
      </div>

      <div className="text-center flex justify-center h-3/4">
        <div className="w-300 h-310">
          {dataSet && (
            <Doughnut
              data={dataSet}
              options={options}
              width={300}
              height={200}
            />
          )}
        </div>
      </div>
    </Container>
  )
}
