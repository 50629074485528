import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadPaymentFeeTypes = async (search, loadedOptions, { page, additionalFilters }) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AMS_BACKEND_API}/api/get/fee-types/?search=${search && search
    }&page=${page}&offset=10`,
  )
  if (data) {
    // filtered selected fee type from drop down menu 
    const filterSelectedFeeType = (element) => {
      return additionalFilters?.length ? additionalFilters.every((fee) => fee?.feeType?.label !== element.label) : element;
    };
    return {
      options: data.feeTypes.filter(filterSelectedFeeType),
      hasMore: data.totalPages - page > 0,
      additional: {
        page: page + 1,
        additionalFilters,
      },
    }
  }
}
