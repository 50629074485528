/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCheckDouble,
  faExclamationCircle,
  faFilePdf,
  faPhoneAlt,
  faArrowCircleDown,
  faPhone,
  faVideo,
  faPlusCircle,
  faSpinner,
  faPlus,
  faPlay,
} from '@fortawesome/free-solid-svg-icons'
import { UserImage } from 'App/Components/Layout/Sidebar/style'
import { defaultImage } from 'App/Assets'
import {
  setCommunicationCredentials,
  setGlobalCallModalActive,
  setNonCustomerChatHistory,
  setNonCustomerNewMessage,
  setNonCustomerNumber,
  setUnknownCaller,
} from 'App/Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { TimeAgo } from 'App/Components/Common/TimeAgo'
import { MessageInput } from 'App/Components/Common/MessageInput'
import { ReactModal } from 'App/Components/Common/Modal'
import FileSaver from 'file-saver'
import { newSocket } from 'App/HOC/MainLayout'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { useMutation, useQuery } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Spinner } from 'App/Components/Common/Spinner'
import { Loader } from 'App/Components/Common/Loader'
import { fileResizer, fileToBase64 } from 'App/Services'
import { Call } from 'App/Components/Communications/Call'
import InfiniteScroll from 'react-infinite-scroller'
import { Skeleton } from 'App/Styled'
import { NotificationCardkelton } from 'App/Components/Layout/PushNotifications/notificationCardSkelton'
import { useRecording } from 'App/hooks/useRecording'

const chatStatusToIcons = [
  {
    status: `sending`,
    icon: faCheck,
    color: `text-gray-600`,
  },
  {
    status: `QUEUED`,
    icon: faCheck,
    color: `text-gray-600`,
  },
  {
    status: `sent`,
    icon: faCheckDouble,
    color: `text-gray-600`,
  },
  {
    status: `delivered`,
    icon: faCheckDouble,
    color: `text-green-600`,
  },
  {
    status: `FAILED`,
    icon: faExclamationCircle,
    color: `text-red-500`,
  },
  {
    status: `RECEIVED`,
    icon: faCheckDouble,
    color: `text-green-600`,
  },
  {
    status: `completed`,
    icon: faPhoneAlt,
    color: `text-green-600`,
  },
  {
    status: `in-progress`,
    icon: faPhoneAlt,
    color: `text-green-600`,
  },
  {
    status: `answered`,
    icon: faPhoneAlt,
    color: `text-green-600`,
  },
  {
    status: `no-answer`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
  {
    status: `busy`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
  {
    status: `canceled`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
  {
    status: `failed`,
    icon: faPhoneAlt,
    color: `text-red-500`,
  },
]

const ChatMessageSkeleton = ({ isInbound }) => {
  return (
    <div className={`flex items-start py-4 ${isInbound ? `` : `justify-end`}`}>
      <div className="flex-1 space-y-2">
        {isInbound && (
          <div className="w-1/5 h-3 bg-gray-300 rounded animate-pulse" />
        )}
        <div
          className={`bg-gray-300 w-1/3 ${
            !isInbound && `float-right`
          } p-2 rounded-lg animate-pulse`}
        >
          <div className="h-3 w-2/3 mb-2 bg-gray-300 rounded animate-pulse" />
          <div className="h-3 w-3/4 bg-gray-300 rounded animate-pulse" />
        </div>
      </div>
    </div>
  )
}

export const GlobalMessage = () => {
  const messagesEndRef = useRef(null)
  let fileRef

  const { globalCallModalActive, videoActive } = useSelector(
    ({ customer }) => customer,
  )
  const { user, preferredLocation } = useSelector(({ user }) => user)
  const { number, currentConversation, unknownCaller } = useSelector(
    ({ globalCommunications }) => globalCommunications,
  )
  const dispatch = useDispatch()

  const {
    fetchRecording,
    clearRecodedUrl,
    decodedRecordingUrl: recordingFile,
    isLoading: isLoadingRecordedUrl,
  } = useRecording()

  const [selectedChat, setSelectedChat] = useState(``)
  const [currentPage, setCurrentPage] = useState(1)
  const [newMessage, setNewMessage] = useState({
    message: ``,
  })

  const [preview, setPreview] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingMoreChat, setLoadingMoreChar] = useState(false)
  const [currentImage, setCurrentImage] = useState(``)

  const [recordingPlayed, setRecordingPlayed] = useState(``)
  const handleMessageChange = (name, value) => {
    setNewMessage((preState) => ({
      ...preState,
      [name]: value,
    }))
  }

  const { mutate: getChatHistory } = useMutation(
    `CustomerChatHistory`,
    async (payload) =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/customers-chat/chat-history/${number}?search={"query": ""}&sort=&page=${payload}&offset=10`,
      ).then((res) => {
        setSelectedChat(res?.data?.data)
        setCurrentPage(res?.data?.data?.currentPage)
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setLoading(false)
        setLoadingMoreChar(false)
        res?.currentPage === 1
          ? dispatch(setNonCustomerChatHistory([...res.chatHistory.reverse()]))
          : dispatch(
              setNonCustomerChatHistory([
                ...res.chatHistory.reverse(),
                ...currentConversation,
              ]),
            )
      },
    },
  )
  const handleFileUpload = async (file) => {
    const allowedFileTypes = [`.pdf`, `.jpg`, `.jpeg`, `.png`, `.mp4`]

    // Check if the selected file's extension is allowed
    const fileExtension = file[0]?.name.split(`.`).pop().toLowerCase()
    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      NotificationManager.error(
        `Invalid file type. Please select a valid file.`,
      )
      fileRef.value = null
      return
    }
    if (file.length) {
      if ((file[0]?.size / (1024 * 1024)).toFixed(2) > 5) {
        NotificationManager.error(`File is too big.`)
        fileRef.value = null
      } else if (file[0].type.includes(`image`)) {
        const base64 = await fileResizer(file[0])
        if (base64) {
          sendingMMS(base64, `image`)
        }
      } else if (file[0].type.includes(`pdf`)) {
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            sendingMMS(base64, `pdf`)
          }
        })
      } else if (file[0].type.includes(`video`)) {
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            sendingMMS(base64, `video`)
          }
        })
      }
    }
  }

  const sendingSMS = () => {
    if (newMessage.message.trim()) {
      const newMessageArray = {
        direction: `OUTBOUND`,
        status: `sending`,
        type: `SMS`,
        _franchiseId: user?.profile?._franchise?._id,
        _agentId: user?.profile?.sub,
        agentImg: user?.profile?.dp,
        agentName: user?.profile?.full_name,
        message: newMessage.message.trim(),
        customerPhone: number,
        locationPhone: preferredLocation?.phone,
      }
      newSocket?.emit(`OUTBOUND_MESSAGE`, newMessageArray)
      handleMessageChange(`message`, ``)
    }
  }

  const sendingMMS = (base64, fileType) => {
    if (base64) {
      const newMessageArray = {
        direction: `OUTBOUND`,
        status: `sending`,
        type: `MMS`,

        _franchiseId: user?.profile?._franchise?._id,
        _agentId: user?.profile?.sub,
        agentImg: user?.profile?.dp,
        agentName: user?.profile?.full_name,
        message: newMessage.message,
        customerPhone: number,
        locationPhone: preferredLocation?.phone,
        mediaType: fileType ? fileType : ``,
        mediaUrl: base64,
      }
      newSocket?.emit(`OUTBOUND_MESSAGE`, newMessageArray)

      // to clear the input field
      if (fileRef) {
        fileRef.value = null
      }
    }
  }

  const recievedMessage = (message) => {
    message?.customerPhone === number &&
      (dispatch(setNonCustomerNewMessage(message)), getChatHistory(currentPage))
  }
  useEffect(() => {
    if (newSocket) {
      // newSocket?.on(`CHAT_HISTORY_RES`, (message) => {
      //   if (message) {
      //     setIsLoading(false)
      //   }
      //   dispatch(setAllMessages(message))
      // })

      newSocket?.on(`OUTBOUND_MESSAGE_RES`, (message) => {
        dispatch(setNonCustomerNewMessage(message))
      })

      newSocket?.on(`MY_OUTBOUND_MMS`, (message) => {
        dispatch(setNonCustomerNewMessage(message))
      })

      newSocket?.on(`INBOUND_MESSAGE`, recievedMessage)
    }

    return () => {
      newSocket?.off(`CHAT_HISTORY`)
      newSocket?.off(`CHAT_HISTORY_RES`)
      newSocket?.off(`OUTBOUND_MESSAGE_RES`)
      newSocket?.off(`MY_OUTBOUND_MMS`)
      newSocket?.off(`INBOUND_MESSAGE`, recievedMessage)
    }
  }, [newSocket, number])
  useEffect(() => {
    if (messagesEndRef.current && currentPage === 1) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight
    } else {
      messagesEndRef.current && (messagesEndRef.current.scrollTop = 20)
    }
  }, [currentConversation])

  useEffect(() => {
    if (number) {
      setLoading(true)
      getChatHistory(1)
    }
  }, [number])

  useEffect(() => {
    setLoading(true)
    getChatHistory(1)
    return () => {
      dispatch(setNonCustomerNumber())
    }
  }, [])

  const handleScroll = () => {
    const element = messagesEndRef.current
    if (
      element &&
      element.scrollTop === 0 &&
      selectedChat?.currentPage < selectedChat?.totalPages
    ) {
      getChatHistory(currentPage + 1)
      setLoadingMoreChar(true)
    }
  }

  const manageStatus = (incomingStatus) => {
    return chatStatusToIcons.map((chat, index) => {
      if (chat.status === incomingStatus) {
        return (
          <FontAwesomeIcon
            icon={chat.icon}
            className={`text-white mt-0.5 ${chat.color}`}
            size="sm"
            key={`font` + index}
          />
        )
      }
    })
  }

  const CalculateTime = (sec) => {
    if (sec == 0) return `No answer`
    let h
    let m
    let s
    if (sec >= 0) {
      h = Math.floor(sec / 3600)
      m = Math.floor((sec % 3600) / 60)
      s = Math.floor((sec % 3600) % 60)
    } else {
      h = Math.ceil(sec / 3600)
      m = Math.ceil((sec % 3600) / 60)
      s = Math.ceil((sec % 3600) % 60)
    }

    let hDisplay =
      h !== 0
        ? h + (h == 1 ? ` hr, ` : ` hrs`) + (m != 0 || s > 0 ? `, ` : ``)
        : ``
    let mDisplay =
      m !== 0 ? m + (m == 1 ? ` min, ` : ` min`) + (s != 0 ? ` ` : ``) : ``
    let sDisplay = s !== 0 ? s + (s == 1 ? ` sec` : ` sec`) : ``
    return hDisplay + mDisplay + sDisplay
  }

  return currentConversation?.length > 0 ? (
    <div className="mt-1 flex flex-col h-88vh ">
      {unknownCaller && (
        <ReactModal
          width="96"
          title="Start Call"
          className="justify-center"
          closeModal={() => {
            dispatch(setUnknownCaller(false))
            dispatch(setCommunicationCredentials({}))
          }}
        >
          <Call customClass="px-12" globalCenter={true} />
        </ReactModal>
      )}
      <div className="h-14 bg-red-500 rounded-t-md text-white font-bold flex px-2">
        {/* <div>
          <UserImage
            alt="profile"
            src={defaultImage}
            style={{ height: `40px`, width: `40px` }}
          />
        </div> */}
        <div className="py-4 px-2">{number}</div>
        <div className="flex-1 ">
          <div className="flex justify-end items-center h-full gap-4">
            <div
              data-tip="Voice Call"
              className="tooltip tooltip-left mr-2"
              onClick={() => {
                // dispatch(setGlobalCallModalActive(!globalCallModalActive))
                dispatch(setUnknownCaller(true))
                dispatch(setCommunicationCredentials({}))
              }}
            >
              <FontAwesomeIcon
                icon={faPhone}
                size="lg"
                className="cursor-pointer"
              />
            </div>
            {/* <div
              data-tip="Video Call"
              className="tooltip tooltip-left"
              onClick={() => dispatch(setVideoActive(!videoActive))}
            >
              <FontAwesomeIcon
                icon={faVideo}
                size="lg"
                className="cursor-pointer"
              />
            </div> */}
          </div>
        </div>
      </div>
      {/* <Spinner loading={loading} /> */}

      <div
        className="mx-3 overflow-y-scroll h-full hide-scroller hide-scroller-firefox"
        ref={messagesEndRef}
        onScroll={handleScroll}
      >
        {loadingMoreChat && (
          <div className="mt-2">
            <ChatMessageSkeleton />
            <ChatMessageSkeleton isInbound={true} />
          </div>
        )}
        <div className="flex flex-col justify-end rounded-md px-2">
          {currentConversation?.length < 1 && loading && (
            <div className="h-80 flex justify-center items-center">
              <p>Start a conversation</p>
            </div>
          )}
          {loading ? (
            <>
              <ChatMessageSkeleton />
              <ChatMessageSkeleton isInbound={true} />
              <ChatMessageSkeleton />
              <ChatMessageSkeleton isInbound={true} />
              <ChatMessageSkeleton />
              <ChatMessageSkeleton isInbound={true} />
            </>
          ) : (
            currentConversation?.map((message, index) => (
              <div key={index}>
                <div className={` relative p-1 `}>
                  <span className="pl-2 pt-0.5 w-72">
                    <div className={`flex  w-full`}>
                      <div className="w-full">
                        <div className={`text-blue-500 font-medium`}>
                          <p
                            className={`capitalize ${
                              message.direction !== `OUTBOUND`
                                ? `text-right`
                                : `text-left`
                            }`}
                          >
                            {message?.agentName?.toLowerCase() ||
                              message?.customerPhone}
                          </p>
                        </div>
                      </div>
                    </div>
                    {message?.type === `SMS` ? (
                      <div
                        className={`${
                          message.direction !== `OUTBOUND`
                            ? `bg-green-500 float-right`
                            : `bg-blue-500 float-left`
                        } py-1 px-2 rounded-md shadow-md border-1 min-w-20 max-w-md relative`}
                      >
                        <p
                          className="text-white min-w-20 max-w-md"
                          // style={{ wordWrap: `break-word` }}
                        >
                          {message?.message}
                        </p>
                        <div className="float-right">
                          <span
                            className={`text-2xs capitalize absolute pt-2 ${
                              message.direction === `OUTBOUND`
                                ? `left-1`
                                : `right-1`
                            }`}
                          >
                            {manageStatus(message?.status)}
                            <div className="flex float-right pl-2 text-2xs">
                              <TimeAgo timeStamp={message?.createdAt} />
                            </div>
                          </span>
                        </div>
                      </div>
                    ) : message?.type === `VOICE_CALL` ? (
                      <div
                        className={` flex flex-col ${
                          message.direction !== `OUTBOUND`
                            ? ` float-right`
                            : ` float-left`
                        }
                      `}
                      >
                        <div
                          className={`bg-gray-100 h-auto py-1 px-2 rounded-md shadow-md  border-1 w-72 relative`}
                        >
                          <p className="text-sm">
                            {manageStatus(message?.status)}
                            <span className="mx-2 underline">
                              voice call at
                              <span className="text-blue-600 px-1">
                                {moment(message.createdAt).format(`HH:mm A`)}
                              </span>
                            </span>
                            <span className="text-xs text-gray-400">
                              ({CalculateTime(message?.callDuration)})
                            </span>
                            {message?.recordingUrl?.length > 0 && (
                              <div className="w-full m-2">
                                <span
                                  className={`px-1 text-sm text-gray-200 rounded shadow  ${
                                    recordingFile && recordingPlayed === index
                                      ? `font-semibold text-gray-700 hover:text-gray-900`
                                      : `text-red-500 hover:text-red-600`
                                  } ${
                                    isLoadingRecordedUrl &&
                                    recordingPlayed === index
                                      ? `cursor-not-allowed`
                                      : `cursor-pointer`
                                  } `}
                                  onClick={() => {
                                    if (!isLoadingRecordedUrl) {
                                      setRecordingPlayed((prev) =>
                                        prev === index ? null : index,
                                      )
                                      fetchRecording(message?.recordingUrl)
                                      clearRecodedUrl()
                                      if (
                                        recordingFile &&
                                        recordingPlayed === index
                                      ) {
                                        clearRecodedUrl()
                                      }
                                    }
                                  }}
                                >
                                  <span>
                                    {recordingPlayed === index && recordingFile
                                      ? `Stop`
                                      : `Play Recording`}
                                    <FontAwesomeIcon
                                      icon={
                                        recordingPlayed === index &&
                                        isLoadingRecordedUrl
                                          ? faSpinner
                                          : recordingPlayed === index &&
                                            recordingFile
                                          ? faPlus
                                          : faPlay
                                      }
                                      size="xs"
                                      className={`ml-2  ${
                                        recordingPlayed === index &&
                                        recordingFile &&
                                        `transform rotate-45`
                                      }`}
                                      spin={
                                        recordingPlayed === index &&
                                        isLoadingRecordedUrl
                                      }
                                    />
                                    {/* {isLoadingRecordedUrl &&
                                      recordingPlayed === index && (
                                        <FontAwesomeIcon
                                          icon={faSpinner}
                                          spin
                                          size="xs"
                                        />
                                      )} */}
                                  </span>
                                </span>
                              </div>
                            )}
                          </p>
                          <div className="float-right">
                            <span
                              className={`text-2xs capitalize absolute pt-2 ${
                                message.direction === `OUTBOUND`
                                  ? `left-1`
                                  : `right-1`
                              }`}
                            >
                              <div className="flex float-right  pl-2 text-2xs">
                                <TimeAgo timeStamp={message?.createdAt} />
                              </div>
                            </span>
                          </div>
                        </div>
                        {recordingFile && recordingPlayed === index && (
                          <div
                            className={`mt-4 flex-1  ${
                              message.direction === `OUTBOUND`
                                ? `left-1`
                                : `right-1`
                            }
                              `}
                          >
                            <audio
                              controls
                              src={recordingFile}
                              preload="auto"
                            />
                          </div>
                        )}
                      </div>
                    ) : message?.type === `MMS` &&
                      message?.mediaType?.includes(`image`) ? (
                      <div
                        className={`relative ${
                          message.direction !== `OUTBOUND`
                            ? `float-right`
                            : `float-left`
                        }`}
                      >
                        <img
                          src={message?.mediaUrl}
                          alt="Image Sending is Failed"
                          className={`rounded-md`}
                          style={{
                            height: `30vh`,
                            width: `15vw`,
                            cursor: `pointer`,
                          }}
                          onClick={() => {
                            setPreview(true)
                            setCurrentImage(message?.mediaUrl)
                          }}
                        />
                        <div className="float-right">
                          <span
                            className={`text-2xs capitalize absolute pt-2 ${
                              message.direction === `OUTBOUND`
                                ? `left-1`
                                : `right-1`
                            }`}
                          >
                            {manageStatus(message?.status)}
                            <div className="flex float-right pl-2 text-2xs">
                              <TimeAgo timeStamp={message?.createdAt} />
                            </div>
                          </span>
                        </div>
                      </div>
                    ) : message?.mediaType === `video` &&
                      message?.type === `MMS` ? (
                      <div className="relative">
                        <video width="650" height="300" controls>
                          <source src={message?.mediaUrl} type="video/mp4" />
                        </video>
                        <div className="float-right">
                          <span
                            className={`text-2xs capitalize absolute pt-2 ${
                              message.direction === `OUTBOUND`
                                ? `left-1`
                                : `right-1`
                            }`}
                          >
                            {manageStatus(message?.status)}
                            <div className="flex float-right pl-2 text-2xs">
                              <TimeAgo timeStamp={message?.createdAt} />
                            </div>
                          </span>
                        </div>
                      </div>
                    ) : message?.mediaType.includes(`pdf`) &&
                      message?.type === `MMS` ? (
                      <div className="relative">
                        <div
                          className={`flex items-center absolute gap-1 w-min bg-gray-100 shadow-md px-4 py-2 rounded-3xl ${
                            message.direction === `OUTBOUND`
                              ? `left-1`
                              : `right-1`
                          }`}
                        >
                          Document
                          <FontAwesomeIcon icon={faFilePdf} size="sm" />
                          <FontAwesomeIcon
                            icon={faArrowCircleDown}
                            className="ml-1 cursor-pointer hover:text-gray-300"
                            onClick={() => FileSaver.saveAs(message?.mediaUrl)}
                          />
                        </div>
                        <div className="float-right mt-10">
                          <span
                            className={`text-2xs capitalize absolute pt-2 ${
                              message.direction === `OUTBOUND`
                                ? `left-1`
                                : `right-1`
                            }`}
                          >
                            {manageStatus(message?.status)}
                            <div className="flex float-right pl-2 text-2xs">
                              <TimeAgo timeStamp={message?.createdAt} />
                            </div>
                          </span>
                        </div>
                      </div>
                    ) : (
                      message?.type === `audio` && (
                        <audio controls className="m-2">
                          <source
                            src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
                            type="audio/ogg"
                          />
                        </audio>
                      )
                    )}
                  </span>
                </div>
                {preview && currentImage && (
                  <ReactModal
                    title="Photo"
                    closeModal={() => {
                      setPreview(false)
                      setCurrentImage(``)
                    }}
                    disabled={true}
                    loading={false}
                    callMutation={() => {
                      FileSaver.saveAs(currentImage)
                    }}
                    icon={faArrowCircleDown}
                    toolTip="Save File"
                    className="p-2"
                  >
                    <div className="items-center my-0">
                      <img src={currentImage} alt="img" />
                    </div>
                  </ReactModal>
                )}
              </div>
            ))
          )}

          {preview && currentImage && (
            <ReactModal
              title="Download Image"
              closeModal={() => {
                setPreview(false)
                setCurrentImage(``)
              }}
              disabled={true}
              loading={false}
              callMutation={() => {
                FileSaver.saveAs(currentImage)
              }}
              icon={faArrowCircleDown}
              toolTip="Save File"
              className="p-2"
            >
              <div className="items-center">
                <img src={currentImage} alt="img nai mili" />
              </div>
            </ReactModal>
          )}
        </div>
      </div>

      {/* message text */}
      <div className="flex gap-6 pb-2 mx-3 mt-auto">
        <div className="flex-1 relative mt-2">
          <MessageInput
            disabled={loading}
            pl="pl-8"
            type="text"
            placeholder="Type Here ..."
            value={newMessage.message}
            setValue={(e) => {
              handleMessageChange(`message`, e.target.value)
            }}
            onEnter={(e) => {
              setCurrentPage(1)
              sendingSMS()
            }}
          />
          {
            <>
              <FontAwesomeIcon
                icon={!loading ? faPlusCircle : faSpinner}
                onClick={() => {
                  !loading && fileRef.click()
                }}
                className={`absolute text-gray-700 left-3 mt-3 ${
                  loading ? `cursor-not-allowed` : `cursor-pointer`
                }`}
              />
              <input
                className="hidden"
                type="file"
                name="attachment"
                ref={(refParam) => (fileRef = refParam)}
                onChange={(e) => {
                  handleFileUpload(e.target.files)
                  e.target.files = null
                }}
                accept="application/pdf, image/*, video/*"
              />
            </>
          }
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  )
}
