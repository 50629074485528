import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'
import React from 'react'
import { useHistory } from 'react-router'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router'
import { Container, EditStyledButton, PageHeader } from 'App/Styled'

import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { setCurrentAssessmentTemplate } from 'App/Redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import dateFormat from 'App/Services/General/dateFormat'

export const PreviewTemplate = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { sub: currentUser } = useSelector(({ user }) => user?.user?.profile)

  const {
    params: { assessmentTemplateId },
  } = useRouteMatch()

  const {
    data: previewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `AssessmentPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/assessment/template/${assessmentTemplateId}`,
      ).then((res) => {
        return res.data.data?.template
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentAssessmentTemplate(res))
      },
    },
  )

  return (
    <Container>
      <PageHeader className="mx-44">
        <div>Template</div>

        {permissionGranted([
          `assessment-template:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) &&
          currentUser === previewData?._creator && (
            <div className="flex">
              <EditStyledButton
                disabled={isFetching || isLoading}
                onClick={() =>
                  history.push(
                    `/assessment/template/${assessmentTemplateId}/update`,
                  )
                }
              >
                <span>Edit</span>
                <FontAwesomeIcon
                  icon={faPen}
                  className="text-white pt-1"
                  size="sm"
                />
              </EditStyledButton>
            </div>
          )}
      </PageHeader>

      <div className="bg-white sm:mb-0  w-full px-2 rounded-md">
        {isLoading || isFetching ? (
          <div className="text-center p-6">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : (
          <div className="rounded-md overflow-hidden py-4 w-2/3 mx-auto">
            <div className="flex flex-col md:flex-row border-1 items-start justify-evenly">
              <div className="flex-1 mx-2  rounded">
                <div className="grid grid-cols-2 px-2">
                  <CardField
                    title="Created By"
                    value={previewData?.creatorName}
                  />
                  <CardField
                    title="Template Name"
                    value={previewData?.templateName}
                  />
                </div>
                <div className="grid grid-cols-2 px-2">
                  <CardField
                    title="Recurring Type"
                    value={
                      previewData?.createdAfter?.recurringType === `CUSTOM`
                        ? previewData?.createdAfter?.recurringCustomType
                        : previewData?.createdAfter?.recurringType
                    }
                  />
                  <CardField
                    title="Team"
                    value={previewData?.assignTeams?.map((team) => {
                      return team?.teamName
                    })}
                    multiple={true}
                  />
                </div>
                <div className="grid grid-cols-2 px-2">
                  <CardField
                    title="Created At"
                    value={
                      previewData?.createdAt
                        ? moment(previewData?.createdAt).format(
                            `MM-DD-YYYY hh:mmA`,
                          )
                        : previewData?.createdAt
                    }
                  />
                  <CardField
                    title="Due Date"
                    value={dateFormat(previewData?.assessmentDueDate)}
                  />
                </div>

                <hr />
                <div>
                  {!previewData?.assessmentQuestions?.length ? (
                    <p className=" text-center p-6">No Data Found</p>
                  ) : (
                    <div className="px-2 py-5 gap-x-2">
                      {previewData?.assessmentQuestions?.map(
                        (questionArea, index) => (
                          <div className="py-1" key={index}>
                            <div>
                              <div className="py-2">
                                <div className="font-semibold text-sm bg-gray-500 text-gray-100 px-2 py-1 rounded-sm">
                                  {`${String.fromCharCode(index + 65)}) `}
                                  {questionArea?.category}
                                </div>
                              </div>
                              <hr />
                              {questionArea?.performanceIndicators?.map(
                                (indicator, index) => (
                                  <React.Fragment key={index}>
                                    <div className="flex gap-x-2">
                                      <div className="text-justify pr-2 pb-2 text-gray-600">
                                        {`${index + 1}) `}
                                        {indicator?.statement}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                ),
                              )}
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}
