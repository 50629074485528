import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { defaultImage } from 'App/Assets'
import { setCurrentAgent } from 'App/Redux/actions'
import { Reload } from 'App/Components/Common/Reload'
import { useHistory, useRouteMatch } from 'react-router'
import { CardField } from 'App/Components/Common/CardField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faMapMarkerAlt,
  faPen,
  faPhoneAlt,
  faSpinner,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { Container, EditButton, Image, PageHeader } from 'App/Styled'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Badge } from '@windmill/react-ui'
import { permissionGranted } from 'App/Services'
import { NotificationManager } from 'react-notifications'

export const AboutAgent = () => {
  const {
    url,
    params: { agentId },
  } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const agentAccountStatus = useSelector(
    ({ agent }) => agent?.currentAgent?.accountStatus,
  )
  const {
    data: agentPreviewApi,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `AgentPreviewApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/users/${agentId}`).then(
        (res) => {
          let user = res.data.data.user
          let roundedCoordinates = {
            lat: user.address?.coordinates?.lat.toFixed(3),
            long: user.address?.coordinates?.long.toFixed(3),
          }
          if (user.address?.coordinates) {
            user.address.coordinates = roundedCoordinates
          }
          return user
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentAgent(res))
      },
    },
  )
  const { isLoading: reInviting, mutate } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/users/reinvite`,
        payload,
      ).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        if (!res.hasError) {
          NotificationManager.success(`Reset Password Link sent to this Email`)
        }
      },
    },
  )

  const { mutate: updateAgent, isLoading: updatingAgent } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(`${AMS_BACKEND_API}/api/users/${agentId}`, {
        ...payload,
      })
        .then((res) => {
          if (res.data) {
            NotificationManager.success(`Operation successful`)
            // refetch preview api
            refetch()
          }
        })
        .catch((error) => {
          if (error.response) {
            NotificationManager.error(error.response.data.error.error)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  return (
    <Container>
      <PageHeader>
        <div>Agent</div>

        {permissionGranted([
          `users:update`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
          `car-dealer-manager:*`,
        ]) && (
          <div className="flex">
            {agentAccountStatus !== `INVITED` && (
              <EditButton
                onClick={() =>
                  // account status updated
                  updateAgent({
                    accountStatus:
                      agentAccountStatus === `ACTIVE` ? `INACTIVE` : `ACTIVE`,
                  })
                }
                disabled={
                  agentAccountStatus === `INVITED` ||
                  isFetching ||
                  isLoading ||
                  updatingAgent
                }
              >
                <span>
                  {agentPreviewApi?.accountStatus === `ACTIVE`
                    ? `Deactivate`
                    : `Active`}
                </span>
                {updatingAgent && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    className="my-auto"
                  />
                )}
              </EditButton>
            )}
            <EditButton
              onClick={() => mutate({ userId: agentId })}
              disabled={
                agentAccountStatus === `INACTIVE` || isFetching || isLoading
              }
            >
              <span>Reinvite</span>
              {reInviting && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </EditButton>
            <EditButton
              onClick={() => history.push(`${url}/update`)}
              disabled={
                agentAccountStatus === `INACTIVE` || isFetching || isLoading
              }
            >
              <span>Edit</span>
              <FontAwesomeIcon
                icon={faPen}
                className="text-white pt-1"
                size="sm"
              />
            </EditButton>
          </div>
        )}
      </PageHeader>
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <>
          <div className="flex flex-row m-3  ">
            <div className="p-1 mr-4">
              <Image
                alt="agentImage"
                src={agentPreviewApi?.img ? agentPreviewApi?.img : defaultImage}
              />
            </div>

            <div className="flex-1">
              {/* Basic Info Start name email and gender */}
              <div className="text-lg py-0.5 font-bold">
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-gray-400 mt-0.5"
                  size="sm"
                />
                &nbsp;&nbsp;
                {agentPreviewApi?.firstName +
                  ` ` +
                  (agentPreviewApi.middleName
                    ? agentPreviewApi.middleName
                    : ``) +
                  ` ` +
                  agentPreviewApi.lastName}
              </div>
              {agentPreviewApi?.contactInfo?.phone ? (
                <div className="text-sm py-0.5 font-bold">
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    className="text-gray-400 mt-0.5"
                    size="sm"
                  />
                  &nbsp;&nbsp;
                  {agentPreviewApi?.contactInfo?.phone}
                </div>
              ) : (
                ``
              )}
              {agentPreviewApi?.contactInfo?.email ? (
                <div className="text-sm py-0.5 font-bold">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="text-gray-400 mt-0.5"
                    size="sm"
                  />
                  &nbsp;&nbsp;
                  {agentPreviewApi?.contactInfo?.email}
                </div>
              ) : (
                ``
              )}
              {agentPreviewApi?.address?.unitNo ||
              agentPreviewApi?.address?.street ? (
                <div className="text-sm py-0.5 font-bold">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className="text-gray-400 mt-0.5"
                    size="sm"
                  />
                  &nbsp;&nbsp;
                  {agentPreviewApi?.address?.unitNo} ,
                  {agentPreviewApi?.address?.street},
                  {agentPreviewApi?.address?.city} ,
                  {agentPreviewApi?.address?.state},
                  {agentPreviewApi?.address?.zip}
                </div>
              ) : (
                ``
              )}
              {/* end basic Info */}

              <div className="rounded-md overflow-hidden py-4">
                <div className="flex items-start justify-evenly">
                  <div className="flex-1 mx-2 rounded">
                    <div className="grid grid-cols-12 px-4 py-2">
                      <div className="col-span-6 text-xl">Agent Info</div>
                    </div>
                    <hr />

                    <div className="flex flex-col md:flex-row px-2">
                      <div className="flex-1">
                        <CardField
                          title="Agent ID"
                          value={agentPreviewApi?._userId}
                        />
                        {agentPreviewApi?.franchiseName ? (
                          <CardField
                            title="Franchise"
                            value={agentPreviewApi?.franchiseName}
                          />
                        ) : (
                          ``
                        )}

                        {agentPreviewApi?.franchisePhone ? (
                          <CardField
                            title="Franchise Phone"
                            value={agentPreviewApi?.franchisePhone}
                          />
                        ) : (
                          ``
                        )}
                        {agentPreviewApi?.locationNames.length ? (
                          <CardField
                            title="Location"
                            value={agentPreviewApi?.locationNames?.map(
                              (loc) => {
                                return loc.label
                              },
                            )}
                            multiple={true}
                          />
                        ) : (
                          ``
                        )}

                        {agentPreviewApi?.gender ? (
                          <CardField
                            title="Gender"
                            value={agentPreviewApi?.gender}
                          />
                        ) : (
                          ``
                        )}

                        {agentPreviewApi?.designation?.label ? (
                          <CardField
                            title="Designation"
                            value={agentPreviewApi?.designation?.label}
                          />
                        ) : (
                          ``
                        )}

                        {agentPreviewApi?.contactInfo?.officePhoneExt ? (
                          <CardField
                            title="Extension"
                            value={agentPreviewApi?.contactInfo?.officePhoneExt}
                          />
                        ) : (
                          ``
                        )}
                        {agentPreviewApi?.contactInfo?.officePhone ? (
                          <CardField
                            title="Work Phone"
                            value={agentPreviewApi?.contactInfo?.officePhone}
                          />
                        ) : (
                          ``
                        )}
                      </div>
                      <div className="flex-1">
                        {agentPreviewApi?.contactInfo?.fax ? (
                          <CardField
                            title="Fax"
                            value={agentPreviewApi?.contactInfo?.fax}
                          />
                        ) : (
                          ``
                        )}

                        {agentPreviewApi?.evaluator?.label && (
                          <CardField
                            title="Evaluator"
                            value={[agentPreviewApi?.evaluator?.label]}
                            multiple={true}
                          />
                        )}

                        {agentPreviewApi?.systemRoles.length ? (
                          <CardField
                            title="Roles"
                            value={agentPreviewApi?.systemRoles?.map((role) => {
                              return role?.label
                            })}
                            multiple={true}
                          />
                        ) : (
                          ``
                        )}
                        {agentPreviewApi?.address?.coordinates ? (
                          <CardField
                            title="Coordinates"
                            value={agentPreviewApi?.address?.coordinates}
                            coord={true}
                          />
                        ) : (
                          ``
                        )}
                        {agentPreviewApi?.commission ? (
                          <CardField
                            title="Commission"
                            value={
                              agentPreviewApi?.commission ? (
                                agentPreviewApi?.commission?.rate +
                                ` ` +
                                agentPreviewApi?.commission?.type
                              ) : (
                                <Badge type="success">N/A</Badge>
                              )
                            }
                          />
                        ) : (
                          ``
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 mx-2 rounded">
                  <div className="grid grid-cols-12 px-4 py-2">
                    <div className="col-span-6 text-xl">Nowcert Data</div>
                  </div>
                  <hr />

                  <div className="flex flex-col md:flex-row px-2">
                    <div className="flex-1">
                      <CardField
                        title="NPN Number"
                        value={agentPreviewApi?.data?.NPN_Number}
                      />
                      <CardField
                        title="Agent Code"
                        value={agentPreviewApi?.data?.AgentCode}
                      />
                    </div>
                    <div className="flex-1">
                      <CardField
                        title="Phone"
                        value={agentPreviewApi?.data?.Phone}
                      />
                      <CardField
                        title="Agency Owner
                          "
                        value={agentPreviewApi?.data?.IsAgencyOwner}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  )
}
