import { InputField } from 'App/Components/Common/InputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { AMS_BACKEND_API, AQS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Container, PageHeader } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { SubmitButton } from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useDebouncedCallback } from 'use-debounce/lib'
import { useHistory } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'
import { isSuperUser } from 'App/Services'
import { useSelector } from 'react-redux'
import { ValidateDuplicateRatingTemplateName } from 'App/Services'
import { Reload } from 'App/Components/Common/Reload'
import { Spinner } from 'App/Components/Common/Spinner'

export const AddRatingTemplate = () => {
  const user = useSelector(({ user: { user } }) => user)
  const state = {
    value: user?.profile?.operatingState,
    label: user?.profile?.operatingState,
  }
  const [ratingTemplate, setRatingTemplate] = useState({
    state: ``,
    templateName: ``,
    description: ``,
  })
  const [errors, setErrors] = useState({
    stateError: ``,
    templateNameError: ``,
    descriptionError: ``,
  })

  const [policyScope, setPolicyScope] = useState([])
  const [vehicleScope, setVehicleScope] = useState([])
  const [ratingTemplateNameValidating, setRatingTemplateNameValidating] =
    useState(false)

  const history = useHistory()

  // Fetch All Coverages Data
  const {
    isError,
    isLoading,
    mutate: getAllCoverages,
    isFetching,
    data: stateCoverages,
    stateValue = isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)
      ? ratingTemplate.state.value
      : state?.value,
  } = useMutation(
    `StateCarriersApi`,
    async () =>
      await AxiosInstance.get(
        `${AQS_BACKEND_API}/get/fetchables/${stateValue}`,
      ).then((res) => {
        return res.data.data.supportedCoverages
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        const policyScopped = res?.policyScoped.map((policy) => ({
          code: policy.code,
          deductibleOption: () => ({
            options: policy.deductible?.length
              ? policy.deductible.map((deductible) => ({
                  value: deductible.key,
                  label: deductible.name,
                }))
              : [],
            hasMore: false,
          }),
          deductible: { value: `NONE`, label: `NONE` },
          deductibleError: ``,
          emptyDeductibles: policy.deductible?.length ? false : true,
          free: policy.free,
          name: policy.name,
          required: policy.required,
          limit:
            policy.code === `BI`
              ? { value: policy.limits[1].key, label: policy.limits[1].name }
              : policy.code === `PD`
              ? { value: policy.limits[1].key, label: policy.limits[1].name }
              : { value: `NONE`, label: `NONE` },
          limitError: ``,
          limitsOptions: () => ({
            options: policy.limits?.length
              ? policy.limits.map((limit) => ({
                  value: limit.key,
                  label: limit.name,
                }))
              : [],
            hasMore: false,
          }),
          emptyLimits: policy.limits?.length ? false : true,
          rated: `NO`,
        }))

        const vehicleScopped = res?.vehicleScoped.map((vehicle) => ({
          code: vehicle.code,
          deductibleOption: () => ({
            options: vehicle.deductible?.length
              ? vehicle.deductible.map((deductible) => ({
                  value: deductible.key,
                  label: deductible.name,
                }))
              : [],
            hasMore: false,
          }),
          deductible: { value: `NONE`, label: `NONE` },
          deductibleError: ``,
          emptyDeductibles: vehicle.deductible?.length ? false : true,
          free: vehicle.free,
          name: vehicle.name,
          required: vehicle.required,
          limit: { value: `NONE`, label: `NONE` },
          limitError: ``,
          limitsOptions: () => ({
            options: vehicle.limits?.length
              ? vehicle.limits.map((limit) => ({
                  value: limit.key,
                  label: limit.name,
                }))
              : [],
            hasMore: false,
          }),
          emptyLimits: vehicle.limits?.length ? false : true,
          rated: `NO`,
        }))

        setPolicyScope(policyScopped)
        setVehicleScope(vehicleScopped)
      },
    },
  )

  useEffect(() => {
    if (!(isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`))) {
      if (stateValue?.length) {
        getAllCoverages()
      }
    }
  }, [stateValue])

  // Add Rating Template Mutation
  const {
    isLoading: templateLoading,
    mutate: addRatingMutation,
    isError: addMutationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/rating-templates`, {
        ...payload,
      })
        .then((res) => {
          NotificationManager.success(`Operation Successful`)
          history.push(`/raters/ratingtemplates`)
          res.data.data
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.message && error?.response?.data?.message,
          )
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (response) => {
        if (response) {
          history.push(`/raters/ratingtemplates`)
        }
      },
    },
  )

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const handlePolicyErrors = (name, val, ind) => {
    const newPolicyScope = policyScope?.map((policy, index) => {
      if (index == ind) {
        policy[name] = val
      }
      return policy
    })
    setPolicyScope(newPolicyScope)
  }

  const handleVehicleErrors = (name, val, ind) => {
    const newVehicleScope = vehicleScope?.map((vehicle, index) => {
      if (index == ind) {
        vehicle[name] = val
      }
      return vehicle
    })
    setVehicleScope(newVehicleScope)
  }

  const handleTemplateChange = useDebouncedCallback((name, value) => {
    setRatingTemplate((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 10)

  //handle policy coverages input
  const handlePolicyCoverages = useDebouncedCallback((name, value, index) => {
    const newPolicyScope = policyScope?.map((i, ind) => {
      if (index === ind) {
        i[name] = value
        i.rated = `YES`
      }
      return i
    })
    setPolicyScope(newPolicyScope)
  }, 5)

  //handle policy coverages input
  const handleVehicleCoverages = useDebouncedCallback((name, value, index) => {
    const newVehicleScope = vehicleScope?.map((i, ind) => {
      if (index === ind) {
        i[name] = value
      }
      return i
    })
    setVehicleScope(newVehicleScope)
  }, 5)

  const ValidateForm = () => {
    if (isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)) {
      if (!ratingTemplate.state?.value?.length) {
        handleErrors(`stateError`, `Select Value`)
      } else {
        handleErrors(`stateError`, ``)
      }
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!ratingTemplate?.templateName?.length) {
      handleErrors(`templateNameError`, `Enter Value`)
    } else {
      if (errors?.templateNameError !== `Name Already Exists`) {
        handleErrors(`templateNameError`, ``)
      }
    }

    if (!ratingTemplate.description?.length) {
      handleErrors(`descriptionError`, `Enter Value`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    for (const index in policyScope) {
      const policy = policyScope[index]

      if (policy.required === `YES`) {
        if (!policy.emptyDeductibles) {
          if (!policy.deductible?.value?.length) {
            handlePolicyErrors(`deductibleError`, `Select Options`, index)
          } else {
            handlePolicyErrors(`deductibleError`, ``, index)
          }
        }

        if (!policy.emptyLimits) {
          if (!policy.limit?.value?.length) {
            handlePolicyErrors(`limitError`, `Select Options`, index)
          } else {
            handlePolicyErrors(`limitError`, ``, index)
          }
        }
      }
    }

    for (const index in vehicleScope) {
      const vehicle = vehicleScope[index]

      if (vehicle.required === `YES`) {
        if (!vehicle.emptyDeductibles) {
          if (!vehicle.deductible?.value?.length) {
            handleVehicleErrors(`deductibleError`, `Select Options`, index)
          } else {
            handleVehicleErrors(`deductibleError`, ``, index)
          }
        }

        if (!vehicle.emptyLimits) {
          if (!vehicle.limit?.value?.length) {
            handleVehicleErrors(`limitError`, `Select Options`, index)
          } else {
            handleVehicleErrors(`limitError`, ``, index)
          }
        }
      }
    }

    handleErrors(`formSubmit`, true)
  }

  // validate rating template name for duplication
  const validateRatingTemplateName = useDebouncedCallback(async (value) => {
    setRatingTemplateNameValidating(true)
    const response = await ValidateDuplicateRatingTemplateName({ name: value })

    if (response?.hasError) {
      handleErrors(`templateNameError`, `Name Already Exists`)
      handleErrors(`formSubmit`, false)
    } else {
      handleErrors(`templateNameError`, ``)
      handleErrors(`formSubmit`, false)
    }
    setRatingTemplateNameValidating(false)
  }, 2500)
  useEffect(() => {
    let policyScopeValidated = false
    let vehicleScopeValidated = false

    if (
      errors.formSubmit &&
      !errors.stateError?.length &&
      !errors.templateNameError?.length &&
      !errors.descriptionError?.length
    ) {
      const checkPolicyScopeError = (policy) =>
        !policy.deductibleError?.length && !policy.limitError?.length

      // {
      //   if (!policy.deductibleError?.length && !policy.limitError?.length) {
      //     return true
      //   } else {
      //     return false
      //   }
      // }
      policyScopeValidated = policyScope?.every(checkPolicyScopeError)

      const checkVehicleScopeError = (vehicle) =>
        !vehicle.deductibleError?.length && !vehicle.limitError?.length

      // {
      //   if (!vehicle.deductibleError?.length && !vehicle.limitError?.length) {
      //     return true
      //   } else {
      //     return false
      //   }
      // }

      vehicleScopeValidated = vehicleScope?.every(checkVehicleScopeError)
    }

    if (policyScopeValidated && vehicleScopeValidated) {
      addRatingMutation({
        rateState: !(
          isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)
        )
          ? state.value
          : ratingTemplate?.state?.value,
        name: ratingTemplate.templateName,
        description: ratingTemplate.description,
        template: {
          policyCoverages: handlePolicyObject(),
          vehicleCoverages: handleVehicleObject(),
        },
      })
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const handlePolicyObject = () => {
    return policyScope.map((policy, index) => {
      let data = {
        deductibleOptions: stateCoverages.policyScoped[index].deductible,
        limitsOptions: stateCoverages.policyScoped[index].limits,
      }
      if (policy.code?.length) {
        data.code = policy.code
      }
      if (policy.required?.length) {
        data.required = policy.required
      }

      if (policy.deductible?.value?.length) {
        data.deductible = policy.deductible.value
      }
      if (policy.limit?.value?.length) {
        data.limits =
          // ratingTemplate?.state?.value === `CA` && policy.code === `UMPD`
          //   ? `3500`
          //   : ratingTemplate?.state?.value === `UT` && policy.code === `UMPD`
          //   ? `250`
          //   : ratingTemplate?.state?.value === `TX` && policy.code === `UMPD`
          //   ? `25`
          //   : ratingTemplate?.state?.value === `CA` && policy.code === `PD`
          //   ? `5`
          //   : ratingTemplate?.state?.value === `FL` && policy.code === `PD`
          //   ? `10`
          policy.limit.value
      }

      if (policy.name?.length) {
        data.name = policy.name
      }
      if (policy.rated?.length) {
        data.rated =
          // (ratingTemplate?.state?.value === `CO` ||
          //   ratingTemplate?.state?.value === `FL`) &&
          // policy.code === `PIP`
          //   ? `NO`
          //   : (ratingTemplate?.state?.value === `CA` ||
          //       ratingTemplate?.state?.value === `TX`) &&
          //     policy.code === `UMPD`
          //   ? `YES`
          //   : (ratingTemplate?.state?.value === `CO` ||
          //       ratingTemplate?.state?.value === `CA`) &&
          //     policy.code === `UNDUM`
          //   ? `NO`
          //   : ratingTemplate?.state?.value === `UT` && policy.code === `UMPD`
          //   ? `NO`
          // :
          policy.limit?.value?.length || policy.deductible?.value?.length
            ? `YES`
            : `NO`
        // policy.rated
      }
      return data
    })
  }

  const handleVehicleObject = () => {
    return vehicleScope.map((vehicle, index) => {
      let data = {
        deductibleOptions: stateCoverages.vehicleScoped[index].deductible,
        limitsOptions: stateCoverages.vehicleScoped[index].limits,
      }
      if (vehicle.code?.length) {
        data.code = vehicle.code
      }

      if (vehicle.deductible?.value?.length) {
        data.deductible = vehicle.deductible.value
      }

      if (vehicle.limit?.value?.length) {
        data.limits = vehicle.limit.value
      }
      if (vehicle.name?.length) {
        data.name = vehicle.name
      }
      if (vehicle.rated?.length) {
        data.rated = vehicle.rated
      }

      return data
    })
  }

  useEffect(() => {
    if (ratingTemplate.state?.value?.length) {
      getAllCoverages()
    }
  }, [ratingTemplate.state])

  const loadStates = () => {
    const options = {
      options: [
        { value: `CA`, label: `CALIFORNIA` },
        { value: `FL`, label: `FLORIDA` },
        { value: `TX`, label: `TEXAS` },
      ],
      hasMore: false,
    }
    return options
  }

  return (
    <Container>
      <PageHeader padding="true">Add Rating Template</PageHeader>
      {addMutationError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <Spinner loading={templateLoading} />
      <div
        className={`sm:mb-0 justify-between w-full px-4 ${
          templateLoading && `opacity-30 pointer-events-none`
        }`}
      >
        <div className="flex gap-x-2 pt-2">
          {!(isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)) ? (
            <div className="flex-1 relative">
              <ReactSelectField
                title="Default State *"
                placeholder="Select State"
                value={state}
                isMulti={false}
                isSearchable={true}
                disabled={true}
              />
            </div>
          ) : (
            <div className="flex-1 relative">
              <ReactSelectField
                title="Default State *"
                placeholder="Select State"
                errorMessage={errors.stateError}
                value={ratingTemplate.state}
                setValue={(value) => handleTemplateChange(`state`, value)}
                isMulti={false}
                loadOptions={loadStates}
                isSearchable={true}
                disabled={false}
              />
            </div>
          )}
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Template Name *"
              placeholder="Enter Name"
              errorMessage={errors.templateNameError}
              value={ratingTemplate.templateName}
              validating={ratingTemplateNameValidating}
              setValue={(e) => {
                e?.target?.value && validateRatingTemplateName(e.target.value)
                handleTemplateChange(`templateName`, e.target.value)
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              maxLength={255}
              type="text"
              title="Description *"
              placeholder="Enter Description"
              errorMessage={errors.descriptionError}
              setValue={(e) =>
                handleTemplateChange(`description`, e.target.value)
              }
            />
          </div>
        </div>

        <div className="relative ">
          {isLoading ? (
            <div className="text-center p-6">
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                size="1x"
                className="text-red-500"
              />
            </div>
          ) : isError ? (
            <Reload refetch={() => getAllCoverages()} />
          ) : (
            <div className="grid grid-cols-2 gap-6 mt-4">
              {policyScope?.length > 0 && (
                <>
                  <div className="flex flex-col">
                    <PageHeader>Policy Coverages</PageHeader>
                    {policyScope?.map((policy, index) => (
                      <div className="py-0.5" key={index}>
                        <div className="text-sm font-medium py-0.5">
                          {policy.code}
                          {policy.required !== `NO` &&
                          (!policy.emptyLimits || !policy.emptyDeductibles)
                            ? ` *`
                            : ``}
                        </div>
                        <div className="flex gap-x-2">
                          <div className="flex-1 relative">
                            <ReactSelectField
                              placeholder="Limits"
                              errorMessage={policy.limitError}
                              value={policy.limit}
                              setValue={(value) =>
                                handlePolicyCoverages(`limit`, value, index)
                              }
                              loadOptions={policy.limitsOptions}
                              isSearchable={false}
                              disabled={policy.emptyLimits}
                              noTitle={true}
                              cacheUniqs={ratingTemplate.state}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <ReactSelectField
                              placeholder="Deductibles"
                              value={policy.deductible}
                              errorMessage={policy.deductibleError}
                              setValue={(value) =>
                                handlePolicyCoverages(
                                  `deductible`,
                                  value,
                                  index,
                                )
                              }
                              loadOptions={policy.deductibleOption}
                              isSearchable={false}
                              disabled={policy.emptyDeductibles}
                              noTitle={true}
                              cacheUniqs={ratingTemplate.state}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex flex-col">
                    <PageHeader>Vehicle Coverages</PageHeader>
                    {vehicleScope?.map((vehicle, index) => (
                      <div className="py-0.5" key={index}>
                        <div className="text-sm font-medium py-0.5">
                          {vehicle.code}
                          {vehicle.required !== `NO` &&
                          (!vehicle.emptyLimits || !vehicle.emptyDeductibles)
                            ? ` *`
                            : ``}
                        </div>
                        <div className="flex gap-x-2">
                          <div className="flex-1 relative">
                            <ReactSelectField
                              placeholder="Limits"
                              errorMessage={vehicle.limitError}
                              value={vehicle.limit}
                              setValue={(value) =>
                                handleVehicleCoverages(`limit`, value, index)
                              }
                              loadOptions={vehicle.limitsOptions}
                              isSearchable={false}
                              disabled={vehicle.emptyLimits}
                              noTitle={true}
                              cacheUniqs={ratingTemplate.state}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <ReactSelectField
                              placeholder="Deductibles"
                              errorMessage={vehicle.deductibleError}
                              value={vehicle.deductible}
                              setValue={(value) =>
                                handleVehicleCoverages(
                                  `deductible`,
                                  value,
                                  index,
                                )
                              }
                              loadOptions={vehicle.deductibleOption}
                              isSearchable={false}
                              disabled={vehicle.emptyDeductibles}
                              noTitle={true}
                              cacheUniqs={ratingTemplate.state}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <div className="text-center w-96 my-5">
            <SubmitButton
              onClick={ValidateForm}
              disabled={
                isLoading ||
                isFetching ||
                isError ||
                ratingTemplateNameValidating ||
                templateLoading
              }
            >
              Create Template
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
