import React, { useEffect, useRef } from 'react'
import { SideBar } from './Sidebar'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { UNAUTHENTICATED_ROUTES } from 'App/Config'
import { Topbar } from './Topbar'

export const Layout = ({ children }) => {
  const location = useLocation()

  const user = useSelector(({ user: { user } }) => user)

  const validRoute = !UNAUTHENTICATED_ROUTES.includes(location.pathname)

  // Restore scroll to top as div has scroll y axis auto so we need
  // to target that specific div instead of window object
  const layoutRef = useRef(null)

  useEffect(() => {
    layoutRef.current && layoutRef.current.scrollTo(0, 0)
  }, [location?.pathname])
  // End Of Scroll Restoration on route change
  if (user?.accessToken && validRoute) {
    return (
      <div className="flex h-screen bg-gray-100">
        <SideBar />
        <div
          className="w-full overflow-y-auto overflow-x-hidden"
          ref={layoutRef}
          id="divlayout"
        >
          <Topbar />
          {children}
        </div>
      </div>
    )
  } else {
    return children
  }
}
