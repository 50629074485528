import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Container, PageHeader, StyledButton, SubmitButton } from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { ReactModal } from 'App/Components/Common/Modal'
import { InputRange } from 'App/Components/Common/InputRange'
import { setCurrentEvaluation } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'
import { TextArea } from 'App/Components/Common/TextArea'
import { gradientActiveClass } from 'App/Components/Layout/Sidebar/style'
import { NotificationManager } from 'react-notifications'

export const Evaluate = () => {
  const history = useHistory()
  const {
    params: { eId, aId },
  } = useRouteMatch()
  const dispatch = useDispatch()

  const [assessments, setAssessments] = useState([])
  const [openTextAreaModal, setOpenTextAreaModal] = useState(false)
  const [questionRow, setQuestionRow] = useState(``)
  const [questionCol, setQuestionCol] = useState(``)
  const [remarksValue, setRemarksValue] = useState(``)

  const {
    isLoading,
    isFetching,
    data: evaluationData,
  } = useQuery(
    `AssessmentPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/assessment/${aId}/evaluations/${eId}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setAssessments(res?.assessmentQuestions)
        dispatch(setCurrentEvaluation(res))
        if (res?.status === `COMPLETED`)
          history.push(`/assessment/weeks/${aId}`)
      },
    },
  )

  const {
    isError,
    mutate,
    isLoading: loadingEvaluation,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/assessment/${aId}/evaluations/${eId}`,
        {
          assessmentQuestions: payload,
        },
      ).then((res) => {
        if (res.data) {
          NotificationManager.success(`Evaluation completed`)
          history.push(`/assessment/weeks/${aId}`)
        
        }
       
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const submitAssessment = () => {
    mutate(assessments)
  }

  const handleAssessmentChange = (
    item,
    indicatorIndex,
    assessmentIndex,
    isRemarks,
  ) => {
    const data = assessments?.map((_assessment, index) => {
      const newAssessment = { ..._assessment }
      newAssessment.performanceIndicators =
        newAssessment?.performanceIndicators?.map(
          (indicator, statementIndex) => {
            const newIndicator = { ...indicator }
            if (index === assessmentIndex && statementIndex === indicatorIndex)
              if (isRemarks) {
                newIndicator.remarks = item
              } else {
                newIndicator.score = item
              }
            return newIndicator
          },
        )
      return newAssessment
    })
    setAssessments(data)
  }

  const handleRemarks = (indicatorIndex, assessmentIndex) => {
    setOpenTextAreaModal(true)
    setQuestionRow(indicatorIndex)
    setQuestionCol(assessmentIndex)
  }

  return (
    <Container>
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <div className="flex">
        <PageHeader padding="true" className="flex justify-center capitalize">
          {`${
            evaluationData?.template
              ? `${evaluationData?.template
                  ?.toString()
                  ?.toLowerCase()} Evaluation`
              : ``
          }`}
        </PageHeader>
      </div>

      {openTextAreaModal && (
        <ReactModal
          title="Add Remarks"
          closeModal={() => setOpenTextAreaModal(false)}
          className="px-10"
          width="1/2"
        >
          <div className="w-full">
            <TextArea
              type="textarea"
              title="Remarks"
              placeholder="Remarks"
              value={remarksValue}
              setValue={(e) => setRemarksValue(e.target.value)}
            />
          </div>
          <div className="w-full flex justify-end pb-4">
            <StyledButton
              onClick={() => {
                handleAssessmentChange(
                  remarksValue,
                  questionRow,
                  questionCol,
                  `isRemarks`,
                )
                setOpenTextAreaModal(false)
              }}
            >
              Add
            </StyledButton>
          </div>
          {/* <ButtonSubmit>Add</ButtonSubmit> */}
        </ReactModal>
      )}

      <Spinner loading={isLoading || loadingEvaluation || isFetching} />

      <div
        className={`px-4 bg-white mt-8 ${
          (isLoading || loadingEvaluation || isFetching) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {assessments.map((assessment, assessmentIndex) => (
          <>
            <div
              className={`my-3 w-full ${gradientActiveClass} rounded-md`}
              key={assessmentIndex}
            >
              <span className="text-sm  text-white px-2 py-1 font-bold ">
                {`${String.fromCharCode(assessmentIndex + 65)}) `}
                {assessment?.category}
              </span>
            </div>
            <div className="grid grid-cols-2">
              {assessment?.performanceIndicators?.map(
                (indicator, indicatorIndex) => (
                  <div
                    className={`flex flex-col ${
                      indicatorIndex % 2 && `border-l-2`
                    } px-4`}
                    key={indicatorIndex}
                  >
                    <div className="flex justify-between">
                      <div className="flex-1">
                        <p className="my-auto">Q. {indicator?.statement}</p>
                        <div
                          className="relative"
                          key={`answer-range-${
                            indicatorIndex + assessmentIndex
                          }`}
                        >
                          <span
                            className="relative underline font-bold px-4 py-2 text-sm text-gray-400 cursor-pointer hover:text-gray-500 transition-colors"
                            onClick={() => {
                              handleRemarks(indicatorIndex, assessmentIndex)
                              setRemarksValue(indicator?.remarks)
                            }}
                          >
                            {indicator?.remarks ? `Edit` : `Add`} Remarks
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <InputRange
                          className="flex-1"
                          id={`answer-range-${indicatorIndex}${assessmentIndex}`}
                          value={indicator?.score}
                          setValue={(value) => {
                            handleAssessmentChange(
                              value,
                              indicatorIndex,
                              assessmentIndex,
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div className="font-bold px-4 py-2 max-w-xl xl:max-w-2xl break-words  text-sm text-gray-600 cursor-pointer transition-colors">
                      {indicator?.remarks}
                    </div>
                  </div>
                ),
              )}
            </div>
          </>
        ))}

        <div className="flex justify-center">
          <div className="text-center w-96 my-10">
            <SubmitButton onClick={submitAssessment}>
              <div>Evaluate</div>
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
