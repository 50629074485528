import { defaultImage } from 'App/Assets'
import { ReactModal } from 'App/Components/Common/Modal'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { UserImage } from 'App/Components/Layout/Sidebar/style'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

import { newSocket } from 'App/HOC/MainLayout'
import { SubmitButton } from 'App/Styled'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ChatRoom } from '..'
import { isSuperUser } from 'App/Services'

export const AllAgents = () => {
  const [agentsRoom, setAgentsRoom] = useState([])
  const [addNewRoom, setAddNewRoom] = useState(false)
  const { setAgentDetail, isRedirect } = useContext(ChatRoom)
  const { user } = useSelector(({ user }) => user)
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)

  const {
    setChatRoom,
    setConversation,
    setNotification,
    setLoading,
    setConversationLoader,
    notification,
    loading,
    chatRoom,
    setIsRedirect,
  } = useContext(ChatRoom)

  useEffect(() => {
    if (newSocket) {
      newSocket?.on(`CREATE_ROOM_RES`, (room) => {
        if (room?._chatRoomId) {
          setChatRoom(room?._chatRoomId)
          setAddNewRoom(false)
          setConversation(room?.chat)
          setConversationLoader(false)
          newSocket?.emit(`FETCH_ROOM`)
          newSocket.emit(`JOIN_ROOM`, { roomId: room?._chatRoomId })
        }
      })
      newSocket?.on(`FETCH_ROOM_RES`, (rooms) => {
        setAgentsRoom(rooms)
        setConversationLoader(false)
        setLoading(false)
      })

      newSocket?.on(`ROOM_NEW_MSG_NOTIFICATION`, (roomId) => {
        // give room id where new msg is received
        if (roomId) {
          newSocket?.emit(`FETCH_ROOM`)
          setNotification((prev) => [...prev, roomId])
        }
      })

      const roomIndex = agentsRoom.findIndex(
        (agent) => agent?._chatRoomId === chatRoom,
      )

      isRedirect && newSocket.emit(`JOIN_ROOM`, { roomId: chatRoom })
      setAgentDetail(agentsRoom[roomIndex]?.agent)
      // setConversationLoader(true)
    }
    return () => {
      newSocket?.off(`FETCH_ROOM_RES`)
      newSocket.off(`CREATE_ROOM_RES`)
      newSocket.off(`ROOM_NEW_MSG_NOTIFICATION`)
    }
  }, [newSocket, chatRoom, agentsRoom])

  useEffect(() => {
    if (agentsRoom.length) {
      newSocket?.on(`JOIN_ROOM_RES`, (room) => {
        setLoading(false)
        setConversationLoader(false)
        setConversation(room?.data?.chat)
        setIsRedirect(false)
      })
    }

    return () => {
      newSocket?.off(`JOIN_ROOM_RES`)
    }
  }, [agentsRoom.length])

  // useEffect(() => {
  //   let unique = notification.filter((item, i, ar) => ar.indexOf(item) === i)
  //   setNotification(unique)
  // }, [notification.length])

  const _loadAgents = async (search, loadedOptions, { page }) => {
    try {
      const {
        data: { data },
      } = await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/users/v2/list/?search={"query":"${
          search.length ? search : ``
        }"}&page=${page}&offset=10`,
        isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)
          ? {}
          : { franchiseId: _franchise?._id },
      )

      if (data) {
        return {
          options: data.agents?.filter(
            (item) => item?.value !== user?.profile?.sub,
          ),
          hasMore: data.totalPages - page > 0,
          additional: {
            page: page + 1,
          },
        }
      }
    } catch (error) {
      if (error.response?.data?.hasError) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        }
      }
    }
  }

  return (
    <div
      className={`${
        loading &&
        `opacity-30 pointer-events-none overflow-y-scroll hide-scroller hide-scroller-firefox`
      }`}
    >
      <div className="mx-2 pt-3">
        <SubmitButton onClick={() => setAddNewRoom(true)}>
          Start New Chat
        </SubmitButton>
      </div>
      <div className="h-85vh overflow-y-scroll hide-scroller hide-scroller-firefox">
        <div className={`px-2 py-2 relative `}>
          {addNewRoom && (
            <ReactModal
              title="Start New Chat"
              closeModal={() => {
                setAddNewRoom(false)
              }}
              disabled={true}
              loading={false}
              // callMutation={() => {
              //   FileSaver.saveAs(currentImage)
              // }}

              toolTip="Save File"
              className="p-2"
            >
              <div className="items-center w-96 pb-10">
                <ReactSelectField
                  title="Select Agent"
                  placeholder="Select Agent"
                  // errorMessage={errors.systemRolesError}
                  // value={agent.systemRoles}
                  setValue={(value) => {
                    newSocket?.emit(`LEAVE_MESSENGER`)
                    newSocket?.emit(`CREATE_ROOM`, { users: [value?.value] })
                    setAgentDetail((prev) => ({
                      ...prev,
                      agentName: value?.label,
                    }))
                  }}
                  isMulti={false}
                  loadOptions={_loadAgents}
                  isSearchable={true}
                />
              </div>
            </ReactModal>
          )}

          {agentsRoom?.length != 0 ? (
            agentsRoom?.map((room, index) => (
              <React.Fragment key={index}>
                <div
                  className={`relative h-14 my-2 shadow-sm border-1 rounded-md cursor-pointer font-bold flex px-2 hover:bg-blue-500 hover:text-white
                ${
                  index ===
                  agentsRoom?.findIndex(
                    (agent) => agent?._chatRoomId === chatRoom,
                  )
                    ? `bg-blue-500 text-white`
                    : ``
                } overflow-y-scroll hide-scroller hide-scroller-firefox`}
                  key={index}
                  onClick={() => {
                    newSocket.emit(`JOIN_ROOM`, { roomId: room?._chatRoomId })
                    setChatRoom(room?._chatRoomId)
                    setAgentDetail(room?.agent)
                    setConversationLoader(true)
                    let unique = notification.filter(
                      (item) => room?._chatRoomId !== item,
                    )
                    setNotification(unique)
                    setIsRedirect(false)
                  }}
                >
                  <div>
                    <UserImage
                      alt="profile"
                      src={room?.agent?.img ? room?.agent?.img : defaultImage}
                      style={{ height: `40px`, width: `40px` }}
                    />
                  </div>
                  <div className="py-4 px-2 capitalize">
                    {room?.agent?.agentName?.toLowerCase()}
                  </div>
                  {notification?.some(
                    (roomId) => roomId === room?._chatRoomId,
                  ) && (
                    <span className="absolute top-2 w-2 h-2 right-2 -mr-0.5 px-1  bg-red-500 rounded-full text-xs text-white"></span>
                  )}
                </div>
              </React.Fragment>
            ))
          ) : (
            <div className="text-center p-6 py-96 text-xs text-gray-400">
              No Communications
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
