import React from 'react'

export const Checkbox = ({
  value,
  name,
  title,
  disabled,
  setValue,
  defaultValue,
  defaultChecked,
  isCustom,
}) => {
  return (
    <div className="card">
      <div className="form-control">
        <label className="cursor-pointer label text-gray-700 font-medium text-sm">
          {title && <span className="label-text">{title}</span>}
          <input
            type="checkbox"
            className={isCustom ? `custom-checkbox` : `checkbox`}
            name={name}
            defaultValue={defaultValue}
            checked={value}
            defaultChecked={defaultChecked}
            onChange={setValue}
            disabled={disabled}
          />
        </label>
      </div>
    </div>
  )
}
