import axios from 'axios'
import { getAccessToken } from 'App/Services'

export const AxiosInstance = axios.create({
  headers: {
    Accept: `application/json, text/plain, */*`,
    'Content-Type': `application/json`,
  },
})
AxiosInstance.interceptors.request.use(
  async (config) => {
    const { user, preferredLocation } = await getAccessToken()
    if (user) {
      config.headers.authorization = `Bearer ${localStorage.getItem(
        `accessToken`,
      )}`
      config.headers.franchiseid = user?.profile?._franchise?._id
      config.headers.locationid = preferredLocation
        ? preferredLocation?._id
        : ``
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

AxiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.setItem(`accessTokenExpiry`, new Date())
    }
    return Promise.reject(error)
  },
)
