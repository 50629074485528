import React from 'react'
import { Container } from 'App/Styled'
import { Badge } from '@windmill/react-ui'
import { TimeAgo } from 'App/Components/Common/TimeAgo'
import { Collapsable } from 'App/Components/Common/Collapsable'

export const Logs = ({ logs }) => {
  const reversedLogs = [...logs]
  return (
    <Container>
      <Collapsable title="Logs">
        {!reversedLogs.length ? (
          <div className="text-center"> No Logs Found </div>
        ) : (
          reversedLogs?.reverse().map((log, index) => {
            return (
              <div className="grid grid-rows-2 mx-auto rounded-xl" key={index}>
                <div className="flex flex-row">
                  <div className="flex flex-row items-center justify-center">
                    <span className="font-semibold text-indigo-500 text-sm">
                      {log._updater}
                    </span>
                    <span className=" mx-1 text-xs flex items-center">
                      <TimeAgo timeStamp={log.updatedAt} />
                    </span>
                  </div>
                </div>

                <div className="relative py-2 rounded-sm shadow-md px-4 text-gray-600 dark:text-white">
                  <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-11">
                      changed <Badge type="primary">{log?.update?.field}</Badge>
                      &nbsp; to &nbsp;
                      <Badge type="success">{log?.update?.value}</Badge>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        )}
      </Collapsable>
    </Container>
  )
}
