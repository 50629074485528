export const loadAgentStatus = () => {
    const options = {
        options: [
            {
                value: `ACTIVE`,
                label: `ACTIVE`,
            },
            {
                value: `INACTIVE`,
                label: `INACTIVE`,
            },
            {
                value: `INVITED`,
                label: `INVITED`,
            },
        ],
    }
    return options
}
