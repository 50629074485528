import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router'
import { Container, StyledButton } from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { CardField } from 'App/Components/Common/CardField'
import { permissionGranted } from 'App/Services'
import { FranchiseContext } from '../../..'

export const AboutFranchise = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { currentFranchise } = useSelector(({ franchise }) => franchise)
  const { isLoading, isFetching, refetch, error } = useContext(FranchiseContext)

  return (
    <>
      {isLoading || isFetching ? (
        <div className="text-center py-10">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <Container>
          <div className="flex flex-col gap-0">
            <div className="grid grid-cols-12 px-4 py-2">
              <div className="col-span-6 text-xl">Franchise Info</div>
              <div className="col-span-6 justify-self-end">
                {permissionGranted([`corporate-manager:*`]) && (
                  <StyledButton onClick={() => history.push(`${url}/update`)}>
                    <span className="mx-2">Edit</span>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="text-white mt-0.5 "
                      size="1x"
                    />
                  </StyledButton>
                )}
              </div>
            </div>
            <hr />

            <div className="px-4 py-2">
              <CardField
                title="Franchise ID"
                value={currentFranchise._franchiseId}
              />
              <CardField title="Name" value={currentFranchise.name} />
              {currentFranchise.legacyId !== null && (
                <CardField
                  title="Legacy ID"
                  value={currentFranchise.legacyId}
                />
              )}
              {currentFranchise?.group !== null && (
                <CardField
                  title="Group Carrier"
                  value={currentFranchise?.group?.label}
                />
              )}

              <CardField
                title="Status"
                value={currentFranchise.status}
                badge={true}
              />

              <CardField
                title="State"
                value={
                  currentFranchise?.state?.length
                    ? currentFranchise?.state
                    : `Not Provided`
                }
                badge={true}
              />

              {!currentFranchise?.isCorporateFranchise && (
                <CardField
                  title="Regions"
                  value={currentFranchise?.regions}
                  multiple={true}
                />
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  )
}
