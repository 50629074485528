import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, StyledButton, PageHeader } from 'App/Styled'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AxiosInstance, AMS_BACKEND_API, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
const tableColumns = [
  { label: `BUSINESS NAME`, value: `businessName` },
  { label: `STATE`, value: `address.state` },
  { label: `COMMISSION TYPE`, value: `commission.type` },
  { label: `COMMISSION RATE`, value: `commission.rate` },
  { label: `EMAIL`, value: `contactInfo.email` },
  { label: `EARNINGS`, value: `earnings` },
]

export const AllCarDealers = () => {
  const history = useHistory()
  const { url } = useRouteMatch()

  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)
  // const [isMasterFrenchise, setIsMasterFrenchise] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)
  // Offset useStat
  const [isOffset, setIsOffset] = useState(``)

  //   getting all locations
  const {
    isLoading,
    data: carDealerResponse,
    refetch: getAllCarDealers,
    isFetching,
    error,
  } = useQuery(
    `CarDealersApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/car-dealers?search={"query": "${
          searchQuery.length
            ? encodeURIComponent(searchQuery.replace(/[?$^|*()"[\]\\]/g, `&`))
            : ``
        }", "onlyMasterAgents": ${false}}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    isLoading: deletingCarDealer,
    isError: deletionError,
    mutate: deleteCarDealer,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/car-dealers/${id}`,
      ).then((res) => {
        res.data && getAllCarDealers()
        NotificationManager.success(`Successfully Deleted`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  useEffect(() => {
    if (!customSearching) {
      getAllCarDealers()
    }
  }, [sort, currentPage, isOffset])

  const callAgentsQuery = useDebouncedCallback(() => {
    getAllCarDealers()
  }, 700)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteCarDealer(id)
  }

  return (
    <Container>
      {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <Spinner loading={deletingCarDealer || isLoading || isFetching} />
      <PageHeader>
        <div>Car Dealers</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end ">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callAgentsQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>
      <div className="flex justify-end items-center p-2">
        {permissionGranted([`car-dealer:create`]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <div
        className={`bg-white sm:mb-0 justify-between w-full px-2 ${
          (deletingCarDealer || isLoading || isFetching) &&
          ` opacity-30 pointer-events-none`
        }  `}
      >
        {error ? (
          <Reload refetch={() => getAllCarDealers()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(permissionGranted([`users:read`]) ||
                      permissionGranted([`users:delete`])) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !carDealerResponse?.carDealers?.length ? (
                    <tr>
                      <td colSpan="12">
                        <div className="w-full px-4 p-6 text-center ">
                          <p>No Dealer Found</p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    carDealerResponse?.carDealers?.map((dealer, index) => (
                      <TableRow
                        key={index}
                        className="hover:bg-gray-100 whitespace-nowrap cursor-pointer"
                        onClick={() => {
                          if (permissionGranted([`users:read`])) {
                            history.push(`${url}/${dealer._id}`)
                          }
                        }}
                      >
                        <td className="py-0.5 px-4 text-sm capitalize truncate">
                          {dealer?.businessName ? (
                            dealer?.businessName.toLowerCase()
                          ) : (
                            <Badge>N/A </Badge>
                          )}
                        </td>
                        <td className="py-0.5 px-4 text-sm">
                          <Badge type="danger">
                            {dealer?.address?.state ?? `N/A`}
                          </Badge>
                        </td>
                        <td className="py-0.5 px-4 text-sm capitalize truncate">
                          <Badge>
                            {dealer?.commission?.type === `FLAT_RATE`
                              ? `Flat`
                              : dealer?.commission?.type === `PERCENTAGE`
                              ? `%`
                              : `N/A`}
                          </Badge>
                        </td>
                        <td className="py-0.5 px-4 text-sm">
                          {dealer?.commission?.type === `FLAT_RATE` && `$`}
                          {dealer?.commission?.rate}
                        </td>
                        <td className="py-0.5 px-4 text-sm">
                          {dealer?.contactInfo?.email}
                        </td>
                        <td className="py-0.5 px-4 text-sm whitespace-nowrap ">
                          <Badge>$ {dealer?.earnings}</Badge>
                        </td>

                        {/* Actions TD */}
                        <td className="px-8 py-0.5 z-0 text-sm flex float-right text-gray-400">
                          {permissionGranted([`car-dealer:delete`]) && (
                            <span data-tip="Delete" className="tooltip ">
                              <FontAwesomeIcon
                                className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                icon={faTrash}
                                size="1x"
                                onClick={(e) => handleDelete(dealer._id, e)}
                              />
                            </span>
                          )}
                        </td>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={carDealerResponse?.totalPages}
                totalRecords={carDealerResponse?.totalRecords}
                curerntPageRecords={carDealerResponse?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
