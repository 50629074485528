import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  previousLength: null,
  latestLength: 0,
  chatPreviouLength: null,
  chatLatestLength: 0,
  generalNotificationsLength: 0,
  isRead: ``,
}

const notificationSlice = createSlice({
  name: `notification`,

  initialState,
  reducers: {
    setNotificationIsRead: (state, { payload }) => {
      state.isRead = payload
    },
    setPreviousLength: (state, { payload }) => {
      state.previousLength = payload
    },
    setLatestLength: (state, { payload }) => {
      state.latestLength = payload
    },
    setChatPreviousLength: (state, { payload }) => {
      state.chatPreviouLength = payload
    },
    setChatLatestLength: (state, { payload }) => {
      state.chatLatestLength = payload
    },
    setGeneralNotificationsLength: (state, { payload }) => {
      state.generalNotificationsLength = payload
    },
  },
})

export const {
  setNotificationIsRead,
  setPreviousLength,
  setLatestLength,
  setChatLatestLength,
  setChatPreviousLength,
  setGeneralNotificationsLength
} = notificationSlice.actions

export const notification = notificationSlice.reducer
