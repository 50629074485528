import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeActivityTab: 1,
  currentLead: ``,
  leadData: ``,
}

const leadSlice = createSlice({
  name: `lead`,

  initialState,
  reducers: {
    setLeadActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
    setLeadData: (state, { payload }) => {
      state.leadData = payload
    },
    setCurrentLead: (state, { payload }) => {
      state.currentLead = payload
    },
  },
})

export const { setLeadActivityActiveTab, setLeadData, setCurrentLead } =
  leadSlice.actions

export const lead = leadSlice.reducer
