import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { AMS_BACKEND_API, AQS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'App/Components/Common/Spinner'
import {
  loadPrefPaymentOptions,
  loadDirections,
  isSuperUser,
} from 'App/Services'

import { ReactTable } from '../ReactTable'
// import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { useHistory } from 'react-router-dom'
import { setPreferedCarriers } from 'App/Redux/actions'
import { ReactNotification } from 'App/Components/Common/ReactNotification'

export const UpdatePreferences = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { myPreferences, myPreferedCarriers } = useSelector(
    ({ preferences }) => preferences,
  )

  const user = useSelector(({ user: { user } }) => user)
  const [carrierPref, setCarrierPref] = useState([])
  const [preferences, setPreference] = useState({
    agencyFee: 0,
    paymentOption: ``,
    ratingType: ``,
    estimateTerm: ``,
    state: ``,
    allowCreditScore: ``,
    bumpLimits: ``,
  })

  const state = {
    value: user?.profile?.operatingState,
    label: user?.profile?.operatingState,
  }

  const [errors, setErrors] = useState({
    agencyFeeError: ``,
    paymentOptionError: ``,
    ratingTypeError: ``,
    estimateTermError: ``,
    stateError: ``,
    allowCreditScoreError: ``,
    bumpLimitsError: ``,
    formSubmit: false,
  })

  const {
    isLoading,
    error,
    mutate: getStateCarriersApi,
    isFetching,
    stateValue = isSuperUser(`super-admin:*`)
      ? preferences.state.value
      : state?.value,
  } = useMutation(
    `StateCarriersApi`,
    async () =>
      await AxiosInstance.get(
        `${AQS_BACKEND_API}/get/fetchables/${stateValue}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        handleModifyCarriersField(res.supportedCarriers)
      },
    },
  )

  const {
    isLoading: isPrefLoading,
    mutate: mutatePreferences,
    // error: addPrefMutationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(`${AMS_BACKEND_API}/api/quotes/prefs`, {
        ...payload,
      }).then((res) => res.data.data),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (response) => {
        if (response) {
          history.push(`/raters/preferences`)
        }
      },
    },
  )

  // will Update Each Carrier Field and dispatch action => redux store

  const handleModifyCarriersField = (carrierFields) => {
    const modifiedCarriers =
      carrierFields.length &&
      carrierFields?.map((carrier) => {
        return {
          ...carrier,
          rated: `YES`,
          orderCredit: `YES`,
          ratingType: carrier?.type,
        }
      })

    if (modifiedCarriers?.length) {
      dispatch(setPreferedCarriers(modifiedCarriers))
      setCarrierPref(modifiedCarriers)
    }
  }

  const ValidateForm = () => {
    if (!preferences.agencyFee?.toString().length) {
      handleErrors(`agencyFeeError`, `Select Value`)
    } else {
      handleErrors(`agencyFeeError`, ``)
    }

    if (!preferences.paymentOption?.value?.length) {
      handleErrors(`paymentOptionError`, `Select Value`)
    } else {
      handleErrors(`paymentOptionError`, ``)
    }
    if (!preferences.estimateTerm?.value?.length) {
      handleErrors(`estimateTermError`, `Select Value`)
    } else {
      handleErrors(`estimateTermError`, ``)
    }
    if (isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)) {
      if (!preferences.state?.value?.length) {
        handleErrors(`stateError`, `Select Value`)
      }
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!preferences.allowCreditScore?.value?.length) {
      handleErrors(`allowCreditScoreError`, `Select Value`)
    } else {
      handleErrors(`allowCreditScoreError`, ``)
    }
    if (!preferences.bumpLimits?.value?.length) {
      handleErrors(`bumpLimitsError`, `Select Value`)
    } else {
      handleErrors(`bumpLimitsError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  const updatePayload = () => {
    let data = {}
    if (preferences.agencyFee !== myPreferences.agencyFee) {
      data.agencyFee = preferences.agencyFee
    } else {
      data.agencyFee = preferences.agencyFee
    }

    if (preferences.estimateTerm?.value !== myPreferences.estimateTerm) {
      data.estimateTerm = preferences.estimateTerm?.value
    } else {
      data.estimateTerm = preferences.estimateTerm?.value
    }
    if (!(isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`))) {
      data.state = state.value
    } else {
      if (preferences.state?.label !== myPreferences.state) {
        data.state = preferences.state.value
      } else {
        data.state = preferences.state?.label
      }
    }

    if (
      preferences.allowCreditScore?.value !== myPreferences.allowCreditScore
    ) {
      data.allowCreditScore = preferences.allowCreditScore?.value
    } else {
      data.allowCreditScore = preferences.allowCreditScore?.value
    }
    if (preferences.bumpLimits?.value !== myPreferences.bumpLimits) {
      data.bumpLimits = preferences.bumpLimits?.value
    } else {
      data.bumpLimits = preferences.bumpLimits?.value
    }
    if (preferences.paymentOption?.value !== myPreferences.paymentOption) {
      data.paymentOption = preferences.paymentOption?.value
    } else {
      data.paymentOption = preferences.paymentOption?.value
    }
    const carrierPrefPayload = myPreferedCarriers?.map((crPref) => ({
      _carrier: crPref.id,
      ratingType: crPref?.ratingType?.toUpperCase(),
      rated: crPref?.rated,
      orderCredit: crPref.orderCredit?.toUpperCase(),
      paymentFilter: preferences.paymentOption?.value,
    }))
    if (!carrierPref?.carriers?.length) {
      data.carriers = carrierPrefPayload
    }
    return data
  }
  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.agencyFeeError.length &&
      !errors.paymentOptionError.length &&
      !errors.ratingTypeError.length &&
      !errors.estimateTermError.length &&
      !errors.stateError.length &&
      !errors.allowCreditScoreError.length &&
      !errors.bumpLimitsError.length
    ) {
      mutatePreferences(updatePayload())
      // updatePayload()
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  useEffect(() => {
    if (stateValue?.length) {
      getStateCarriersApi()
    }
  }, [preferences.state, stateValue])

  // load once if values are already available
  useEffect(() => {
    if (myPreferences) {
      setPreference((prePref) => ({
        ...prePref,
        agencyFee: myPreferences?.agencyFee ?? 0,
        estimateTerm: myPreferences?.estimateTerm
          ? {
              value: myPreferences?.estimateTerm,
              label: myPreferences?.estimateTerm,
            }
          : ``,
        state: myPreferences?.state
          ? {
              value: myPreferences?.state,
              label: myPreferences?.state,
            }
          : state,
        allowCreditScore: myPreferences?.allowCreditScore
          ? {
              value: myPreferences?.allowCreditScore,
              label: myPreferences?.allowCreditScore,
            }
          : ``,
        bumpLimits: myPreferences?.bumpLimits
          ? {
              value: myPreferences?.bumpLimits,
              label: myPreferences?.bumpLimits,
            }
          : ``,
        paymentOption: myPreferences?.paymentOption
          ? {
              value: myPreferences?.paymentOption,
              label: myPreferences?.paymentOption,
            }
          : ``,
      }))
    }
  }, [myPreferences])

  const loadStates = () => {
    const options = {
      options: [
        { value: `CA`, label: `CALIFORNIA` },
        { value: `FL`, label: `FLORIDA` },
        { value: `TX`, label: `TEXAS` },
      ],
      hasMore: false,
    }
    return options
  }

  const loadBooleanOptions = () => {
    const options = {
      options: [
        { value: `YES`, label: `YES` },
        { value: `NO`, label: `NO` },
      ],
      hasMore: false,
    }
    return options
  }

  return (
    <div>
      <Spinner loading={isLoading || isFetching || isPrefLoading} />
      {error && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <Container>
        <PageHeader>Preferences</PageHeader>
        <div
          className={`px-2 bg-white ${
            (isLoading || isPrefLoading) && ` opacity-30 pointer-events-none`
          } `}
        >
          <>
            {!error && (
              <div className="pb-2 bg-white ">
                <div className="flex gap-6 mt-2">
                  <div className="flex-1 relative">
                    <InputField
                      type="number"
                      title="Agency Fee *"
                      placeholder="Enter Fee"
                      errorMessage={errors.agencyFeeError}
                      value={preferences.agencyFee}
                      setValue={(e) => {
                        setPreference((prevState) => ({
                          ...prevState,
                          [`agencyFee`]: e.target.value,
                        }))
                      }}
                      disabled={
                        preferences?.state?.value === `FL` || state === `FL`
                      }
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Payment Option *"
                      placeholder="Select Options"
                      errorMessage={errors.paymentOptionError}
                      value={preferences.paymentOption}
                      setValue={(value) => {
                        setPreference((prevState) => ({
                          ...prevState,
                          [`paymentOption`]: value,
                        }))
                      }}
                      isMulti={false}
                      loadOptions={loadPrefPaymentOptions}
                      isSearchable={false}
                      disabled={false}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Estimate Term *"
                      placeholder="Select Options"
                      errorMessage={errors.estimateTermError}
                      value={preferences?.estimateTerm}
                      setValue={(value) => {
                        setPreference((prevState) => ({
                          ...prevState,
                          [`estimateTerm`]: value,
                        }))
                      }}
                      isMulti={false}
                      loadOptions={loadBooleanOptions}
                      isSearchable={true}
                      disabled={false}
                    />
                  </div>
                  {!(
                    isSuperUser(`super-admin:*`) ||
                    isSuperUser(`system-admin:*`)
                  ) ? (
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Default State *"
                        placeholder="Select State"
                        value={state}
                        isMulti={false}
                        isSearchable={true}
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Default State *"
                        placeholder="Select State"
                        errorMessage={errors.stateError}
                        value={preferences.state}
                        setValue={(value) => {
                          if (value?.value === `FL`) {
                            setPreference((prevState) => ({
                              ...prevState,
                              [`agencyFee`]: 0,
                            }))
                          }
                          setPreference((prevState) => ({
                            ...prevState,
                            [`state`]: value,
                          }))
                        }}
                        isMulti={false}
                        loadOptions={loadStates}
                        isSearchable={true}
                        disabled={false}
                      />
                    </div>
                  )}
                </div>

                <div className="flex gap-6 mt-2">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Allowed Credit Score *"
                      placeholder="Select Value"
                      errorMessage={errors.allowCreditScoreError}
                      value={preferences.allowCreditScore}
                      setValue={(value) => {
                        setPreference((prevState) => ({
                          ...prevState,
                          [`allowCreditScore`]: value,
                        }))
                      }}
                      isMulti={false}
                      loadOptions={loadBooleanOptions}
                      isSearchable={false}
                      disabled={false}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Bump Limits *"
                      placeholder="Select Value"
                      errorMessage={errors.bumpLimitsError}
                      value={preferences.bumpLimits}
                      setValue={(value) => {
                        setPreference((prevState) => ({
                          ...prevState,
                          [`bumpLimits`]: value,
                        }))
                      }}
                      isMulti={false}
                      loadOptions={loadDirections}
                      isSearchable={false}
                      disabled={false}
                    />
                  </div>
                  <div className="flex-1 relative"></div>
                  <div className="flex-1 relative"></div>
                </div>
              </div>
            )}
            {!error && (
              <Collapsable title="Carriers">
                {carrierPref?.length > 0 && (
                  <ReactTable
                    carriersdata={carrierPref}
                    statename={preferences.state.value}
                    width="w-full"
                  />
                )}
              </Collapsable>
            )}
            <div className="flex justify-center">
              <div className="text-center w-96 my-5">
                <SubmitButton onClick={ValidateForm} disabled={isLoading}>
                  <div>Set Preferences</div>
                </SubmitButton>
              </div>
            </div>
          </>
        </div>
      </Container>
    </div>
  )
}
