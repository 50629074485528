import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Container } from 'App/Styled'
import { ReactSelectField } from '../../Common/ReactSelect'

ChartJS.register(ArcElement, Tooltip, Legend)
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
}
export const LeadsByStatusDonut = ({ leadsByStatus }) => {
  const [dataSet, setDataSet] = useState(``)
  const [allLeads, setAllLeads] = useState(``)
  const [selectState, setSelectState] = useState([])

  useEffect(() => {
    if (leadsByStatus?.length) {
      let labels = selectState.length
        ? selectState.map((item) => item.label)
        : leadsByStatus.map((item) => item.status)
      let values = selectState.length
        ? selectState.map((item) => item.leads)
        : leadsByStatus.map((item) => item.count)

      let allLeads = values.reduce((partialSum, lead) => partialSum + lead, 0)

      let backgroundColors = leadsByStatus.map(() => {
        let randomColor = Math.floor(Math.random() * 16777215).toString(16)
        return `#` + randomColor
      })

      if (labels.length && values.length && backgroundColors.length)
        setDataSet({
          labels: labels,
          datasets: [
            {
              label: `# of Votes`,
              data: values,
              backgroundColor: backgroundColors,
              borderWidth: 1,
            },
          ],
        })
      setAllLeads(allLeads)
    }
  }, [leadsByStatus, selectState])

  const option = leadsByStatus?.map((item) => ({
    value: item.status,
    leads: item.count,
    label: item.status,
  }))
  const loadState = (search) => {
    const options = {
      options: filterState(search),
      hasMore: false,
    }

    return options
  }

  const filterState = (search) => {
    return (
      option
        //some labels are null that is why we used Double filter
        .filter((item) => item.label !== null)
        .filter((item) =>
          item.label.toLowerCase().includes(search.toLowerCase()),
        )
    )
  }
  return (
    <Container className="relative">
      <div className="absolute font-bold text-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
        <div className="mt-16 font-bold ">{`${
          allLeads > 0 ? allLeads : `No Data`
        }`}</div>
      </div>

      <div className="flex py-9 h-1/4 relative">
        <div className="font-bold absolute left-4 2xl:left-6 top-5">
          <span className="font-bold text-md">Leads By Status</span>
        </div>
        <div className="w-1/2 absolute right-4 top-4 z-0">
          <ReactSelectField
            placeholder="Select Status"
            value={selectState}
            setValue={(e) => {
              setSelectState(e)
            }}
            isMulti={true}
            loadOptions={loadState}
            isSearchable={true}
            isScrollable={true}
          />
        </div>
      </div>

      <div className="text-center flex justify-center h-3/4">
        <div className="w-1/2 h-96">
          {dataSet && (
            <Doughnut
              data={dataSet}
              options={options}
              width={300}
              height={200}
            />
          )}
        </div>
      </div>
    </Container>
  )
}
