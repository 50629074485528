import moment from 'moment'

export const loadYears = () => {
  const currentYear = moment().year()
  const prevFiftyYears = moment().subtract(50, `y`).year()

  let optionsArr = []

  for (let year = prevFiftyYears; year <= currentYear; year++) {
    optionsArr.push({ value: year, label: year })
  }

  return {
    options: optionsArr,
    hasMore: false,
  }
}
