import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadBodyTypes = async (search, loadedOptions, { page }) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AMS_BACKEND_API}/api/get/body-types?search=${
      search && search
    }&offset=10&page=${page}`,
  )

  return {
    options: data.bodyTypes,
    hasMore: data.totalPages - page > 0,
    additional: {
      page: page + 1,
    },
  }
}
