export const loadRisksReasonCode = () => {
  const options = {
    options: [
      { value: `Accident Related`, label: `Accident Related` },
      {
        value: `Driving While Under the Influence`,
        label: `Driving While Under the Influence`,
      },
      {
        value: `Operating Without Insurance`,
        label: `Operating Without Insurance`,
      },
      {
        value: `Operating Without a License`,
        label: `Operating Without a License`,
      },
      { value: `Other`, label: `Other` },
      { value: `Points`, label: `Points` },
      { value: `Speeding`, label: `Speeding` },
    ],
    hasMore: false,
  }
  return options
}
