import React from 'react'

export const InputRange = ({ id, value, setValue }) => {
  return (
    <label htmlFor={id} className="mt-1.5 mx-2">
      <input
        type="range"
        min="0"
        max="10"
        default="2"
        value={value}
        onChange={(event) => setValue(parseInt(event.target.value))}
        id={id}
        className="range input-range-appearance input-range-red tooltip tooltip-left"
        data-tip={value}
        step="2"
      />
    </label>
  )
}
