/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import {
  Container,
  FormTab,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
// import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useSelector } from 'react-redux'
import {
  lasLastSeenTypes,
  loadAgents,
  loadCustomerTypes,
  loadPaymentFeeTypes,
  loadPaymentLocations,
  loadSalesInTypes,
  loadSalesTypes,
  // loadPaymentMethods,
} from 'App/Services'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { InputField } from 'App/Components/Common/InputField'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import {
  AddFormButton,
  RemoveDriver,
} from 'App/Components/Raters/QuoteForm/style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

const generateValueLabelObj = (item) =>
  item
    ? {
        value: item,
        label: item,
      }
    : ``

export const UpdatePayment = () => {
  // hooks
  const history = useHistory()
  const {
    params: { customerId },
  } = useRouteMatch()
  const { currentPayment } = useSelector(({ payment }) => payment)
  // states
  const [transactions, setTransactions] = useState({
    transactionDate: new Date(currentPayment?.issueDate),
    agent: currentPayment?._creator,
    primaryOffice: currentPayment?._location,
  })

  // const [transactionTypes, setTransactionTypes] = useState([
  //   {
  //     paymentMethod: ``,
  //     paymentMethodError: ``,
  //     amount: ``,
  //     amountError: ``,
  //     confirmRefNo: ``,
  //     confirmRefNoError: ``,
  //     paymentNote: ``,
  //   },
  // ])

  const [additional, setAdditional] = useState({
    customer: generateValueLabelObj(currentPayment?.additional?.customer),
    salesIn: generateValueLabelObj(currentPayment?.additional?.salesIn),
    salesType: generateValueLabelObj(currentPayment?.additional?.salesType),
    lastSeen: generateValueLabelObj(currentPayment?.additional?.lastSeen),
  })
  const [activeFeeTab, setActiveFeeTab] = useState(1)
  const [fTabs, setFTabs] = useState(
    currentPayment?.items?.map((_, index) => ({
      tName: `Fee ${index + 1}`,
      tNumber: index + 1,
    })),
  )
  const [fees, setFees] = useState(
    currentPayment?.items?.map((item) => ({
      feeType: generateValueLabelObj(item?.feeType),
      feeTypeError: ``,
      amount: item?.amount ?? ``,
      amountError: ``,
    })),
  )

  const [errors, setErrors] = useState({
    formSubmit: false,
    agentError: ``,
    primaryOfficeError: ``,
  })

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const handleInvoice = (value, name, ind) => {
    const newFees = fees?.map((fee, index) => {
      if (index === ind) {
        fee[name] = value
      }
      return fee
    })
    setFees(newFees)
  }

  useEffect(() => {
    const newFee = currentPayment?.items?.map((item) => ({
      feeType: generateValueLabelObj(item?.feeType),
      amount: item.amount,
    }))

    setFees(newFee)
  }, [])

  // const handleTransactionTypesErros = (name, error, ind) => {
  //   if (ind !== undefined) {
  //     const newTransactionTypes = transactionTypes?.map(
  //       (transaction, index) => {
  //         if (index == ind) {
  //           transaction[name] = error
  //         }
  //         return transaction
  //       },
  //     )
  //     setTransactionTypes(newTransactionTypes)
  //   }
  // }

  // const addTransactionType = () => {
  //   setTransactionTypes([
  //     ...transactionTypes,
  //     {
  //       paymentMethod: ``,
  //       paymentMethodError: ``,
  //       amount: ``,
  //       amountError: ``,
  //       confirmRefNo: ``,
  //       confirmRefNoError: ``,
  //       paymentNote: ``,
  //     },
  //   ])
  // }

  // const removeTransactionType = (ind) => {
  //   const newTransactionType = [...transactionTypes]
  //   if (newTransactionType.length != 1) {
  //     newTransactionType.splice(ind, 1)
  //     setTransactionTypes(newTransactionType)
  //   }
  // }

  const handleTransactions = (value, name, ind) => {
    setTransactions((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/invoices/edit/${currentPayment?._id}`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res.data) {
          history.push(
            `/customers/${customerId}/payments/${currentPayment?._id}`,
          )
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    let formValidated = false
    if (
      errors.formSubmit &&
      !errors.agentError.length &&
      !errors.primaryOfficeError.length
    ) {
      fees?.forEach((transaction) => {
        if (
          !transaction.feeTypeError?.length &&
          !transaction.amountError?.length
        ) {
          formValidated = true
        } else {
          formValidated = false
        }
      })
    }

    if (formValidated) {
      //call API
      mutate(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  useEffect(() => {
    setActiveFeeTab(fTabs.length)
  }, [fTabs.length])

  const addFee = () => {
    setFees([
      ...fees,
      {
        feeType: ``,
        feeTypeError: ``,
        amount: ``,
        amountError: ``,
      },
    ])

    setFTabs([
      ...fTabs,
      {
        tName: `Fee ${fTabs.length + 1}`,
        tNumber: fTabs.length + 1,
      },
    ])
  }

  const removeFee = (ind) => {
    const newFees = [...fees]
    if (newFees.length != 1) {
      newFees.splice(ind, 1)
      setFees(newFees)
    }
    const newFeesTab = [...fTabs]
    if (newFeesTab.length !== 1) {
      newFeesTab.pop()
      setFTabs(newFeesTab)
    }
  }

  const handleFeesErrors = (name, error, ind) => {
    if (ind !== undefined) {
      const newFees = fees?.map((fee, index) => {
        if (index == ind) {
          fee[name] = error
        }
        return fee
      })
      setFees(newFees)
    }
  }

  const handleAdditional = (value, name) => {
    setAdditional((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const updatePayload = () => {
    let data = {
      // _invoice: currentPayment._id,
      payments: {},
    }
    if (transactions.transactionDate !== currentPayment?.issueDate) {
      data.payments.transactionDate = transactions.transactionDate
    }
    data.payments._agent = transactions.agent.value
    // if (transactions.agent.value !== currentPayment?._creator?.value) {
    // }

    data.payments._location = transactions.primaryOffice.value
    // if (
    //   transactions.primaryOffice?.value !== currentPayment?._location?.value
    // ) {
    // }

    const _additional = {}

    if (additional.customer.value !== currentPayment?.additional?.customer) {
      _additional.customer = additional.customer.value
    }

    if (additional.salesIn.value !== currentPayment?.additional?.salesIn) {
      _additional.salesIn = additional.salesIn.value
    }

    if (additional.salesType.value !== currentPayment?.additional?.salesType) {
      _additional.salesType = additional.salesType.value
    }

    if (additional.lastSeen.value !== currentPayment?.additional?.lastSeen) {
      _additional.lastSeen = additional.lastSeen.value
    }

    data.payments.items = fees.map((fee) => {
      return {
        feeType: fee?.feeType.value,
        amount: parseFloat(fee?.amount),
      }
    })
    if (Object.keys(_additional)?.length > 0) {
      data.additional = _additional
    }

    // data.paymentItems = transactionTypes.map((transactionType) => {
    //   return {
    //     paymentMethod: transactionType?.paymentMethod?.value,
    //     amount: transactionType?.amount,
    //     confirmRefNo: transactionType?.confirmRefNo,
    //     paymentNote: transactionType?.paymentNote,
    //   }
    // })

    return data
  }

  const ValidateForm = () => {
    if (!transactions.agent?.value?.length) {
      handleErrors(`agentError`, `Select Agent`)
    } else {
      handleErrors(`agentError`, ``)
    }

    if (!transactions.primaryOffice?.value?.length) {
      handleErrors(`primaryOfficeError`, `Select Office`)
    } else {
      handleErrors(`primaryOfficeError`, ``)
    }

    let totalFees = 0

    for (const index in fees) {
      const fee = fees[index]

      if (!fee.feeType?.value?.length) {
        handleFeesErrors(`feeTypeError`, `Select Fee Type`, index)
      } else {
        handleFeesErrors(`feeTypeError`, ``, index)
      }

      if (!fee.amount) {
        handleFeesErrors(`amountError`, `Enter Amount`, index)
      } else if (fee.amount && JSON.parse(fee.amount) <= 0) {
        handleFeesErrors(`amountError`, `Amount must be greater than 0`, index)
      } else {
        handleFeesErrors(`amountError`, ``, index)
        totalFees += parseFloat(fee.amount)
      }
    }

    // for (const index in transactionTypes) {
    //   const transactionType = transactionTypes[index]
    //   transactionAmount =
    //     transactionAmount +
    //     JSON.parse(transactionType?.amount ? transactionType?.amount : 0)

    //   if (!transactionType.paymentMethod?.value?.length) {
    //     handleTransactionTypesErros(`paymentMethodError`, `Select Type`, index)
    //   } else {
    //     handleTransactionTypesErros(`paymentMethodError`, ``, index)
    //   }

    //   if (!transactionType.amount) {
    //     handleTransactionTypesErros(`amountError`, `Enter Amount`, index)
    //   } else {
    //     handleTransactionTypesErros(`amountError`, ``, index)
    //   }

    //   if (!transactionType.confirmRefNo?.length) {
    //     handleTransactionTypesErros(`confirmRefNoError`, `Select Type`, index)
    //   } else {
    //     handleTransactionTypesErros(`confirmRefNoError`, ``, index)
    //   }
    // }
    // if (currentPayment.remainingAmount < transactionAmount) {
    //   handleTransactionTypesErros(
    //     `amountError`,
    //     `Amount is exceeding remaining amount`,
    //     transactionTypes.length - 1,
    //   )
    // }

    handleErrors(`formSubmit`, true)
  }

  return (
    <Container>
      <PageHeader>
        <div>Update Payment</div>
      </PageHeader>
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />

      <div
        className={`px-3 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        <SecondaryHeading>Fees</SecondaryHeading>
        {/* tabs here */}
        <div className="flex items-center">
          <TabsHeaders
            openTab={activeFeeTab}
            tabs={fTabs}
            getActiveTab={(val) => setActiveFeeTab(val)}
            customTabs="YES"
          />
          <div className="px-2">
            <AddFormButton
              onClick={() => {
                addFee()
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </AddFormButton>
          </div>
        </div>
        {/* tabs end herer */}
        {fees?.length > 0 &&
          fees?.map((item, index) => (
            <FormTab
              key={index}
              currenttab={index + 1}
              opentab={activeFeeTab}
              className="border-2 border-gray-100 shadow-sm rounded-md relative"
            >
              <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                <div className="flex-1">Fee {index + 1}</div>
                <div className="flex-1">
                  {!item?.confirmRefNo && !item?.transactionId && (
                    <div className="float-right">
                      <RemoveDriver
                        onClick={() => {
                          if (!item?.disabled) {
                            removeFee(index)
                            if (index > 0) setActiveFeeTab(index)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                      </RemoveDriver>
                    </div>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-12 px-2 pb-4">
                <div className="col-span-12 flex gap-6 ">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Memos Type*"
                      placeholder="Select Type"
                      value={item.feeType}
                      errorMessage={item.feeTypeError}
                      setValue={(e) => handleInvoice(e, `feeType`, index)}
                      isMulti={false}
                      loadOptions={loadPaymentFeeTypes}
                      isSearchable={true}
                      disabled={item?.disabled}
                      additionalFilters={fees}
                      cacheUniqs={fees}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <InputField
                      type="number"
                      title="Amount*"
                      placeholder="Enter Amount"
                      value={item.amount}
                      errorMessage={item.amountError}
                      setValue={(e) =>
                        handleInvoice(e.target.value, `amount`, index)
                      }
                    />
                  </div>
                </div>
              </div>
            </FormTab>
          ))}

        {/* transactions */}
        <PageHeader className="mt-4">
          <div>Transactions</div>
          <div className="text-sm">
            Remaining Amount: ${currentPayment.remainingAmount}
          </div>
        </PageHeader>
        <div className="px-2">
          <div className="flex gap-6 mt-2">
            <div className="flex-1 relative">
              <DatePickerField
                name="transactionDate"
                title="Transaction Date"
                placeholder="Select Date"
                value={transactions.transactionDate}
                setValue={(date) => handleTransactions(date, `transactionDate`)}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Agent"
                placeholder="Select Agent"
                errorMessage={errors.agentError}
                value={transactions.agent}
                setValue={(value) => handleTransactions(value, `agent`)}
                isMulti={false}
                loadOptions={loadAgents}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Primary Office"
                placeholder="Select Office"
                errorMessage={errors.primaryOfficeError}
                value={transactions.primaryOffice}
                setValue={(value) => handleTransactions(value, `primaryOffice`)}
                isMulti={false}
                loadOptions={loadPaymentLocations}
                isSearchable={true}
              />
            </div>
          </div>

          {/* {transactionTypes.length > 0 &&
            transactionTypes?.map((transaction, index) => (
              <div key={index} className="grid grid-cols-12 px-2">
                <div className="col-span-11 flex gap-6 ">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Payment Method"
                      placeholder="Select Method"
                      value={transaction.paymentMethod}
                      setValue={(e) =>
                        handleTransactions(e, `paymentMethod`, index)
                      }
                      errorMessage={transaction.paymentMethodError}
                      isMulti={false}
                      loadOptions={loadPaymentMethods}
                      isSearchable={true}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <InputField
                      type="number"
                      title="Amount"
                      placeholder="Enter Amount"
                      value={transaction.amount}
                      errorMessage={transaction.amountError}
                      setValue={(e) =>
                        handleTransactions(e.target.value, `amount`, index)
                      }
                    />
                  </div>

                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Confirm Ref No"
                      placeholder="Enter Number"
                      value={transaction.confirmRefNo}
                      errorMessage={transaction.confirmRefNoError}
                      setValue={(e) =>
                        handleTransactions(
                          e.target.value,
                          `confirmRefNo`,
                          index,
                        )
                      }
                    />
                  </div>

                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Payment Note"
                      placeholder="Enter..."
                      value={transaction.paymentNote}
                      setValue={(e) =>
                        handleTransactions(e.target.value, `paymentNote`, index)
                      }
                    />
                  </div>
                </div>
                <div className="col-span-1 mt-7">
                  <div className="flex items-center justify-center float-right">
                    <span
                      onClick={addTransactionType}
                      data-tip="Add Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500 cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="transform transition  hover:-translate-y-0 hover:scale-105"
                      />
                    </span>
                    <span
                      onClick={() => removeTransactionType(index)}
                      data-tip="Remove Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500 cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="transform transition  hover:-translate-y-0 hover:scale-105"
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))} */}
          {/* additionals */}
          {/* additionals */}
          <PageHeader className="mt-4">
            <div>Additional</div>
          </PageHeader>
          <div className="px-2">
            <div className="flex gap-6 mt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Customer*"
                  placeholder="Select Customer"
                  value={additional?.customer}
                  errorMessage={errors?.customerError}
                  setValue={(value) => handleAdditional(value, `customer`)}
                  isMulti={false}
                  loadOptions={loadCustomerTypes}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Sales In*"
                  placeholder="Select Option"
                  value={additional?.salesIn}
                  errorMessage={errors.salesInError}
                  setValue={(value) => handleAdditional(value, `salesIn`)}
                  isMulti={false}
                  loadOptions={loadSalesInTypes}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Where did customer remember seeing us last*"
                  placeholder="Select Option"
                  value={additional?.lastSeen}
                  errorMessage={errors?.lastSeenError}
                  setValue={(value) => handleAdditional(value, `lastSeen`)}
                  isMulti={false}
                  loadOptions={lasLastSeenTypes}
                />
              </div>
            </div>
            <div className="flex gap-6 mt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Sales Type*"
                  placeholder="Select Type"
                  value={additional?.salesType}
                  errorMessage={errors?.salesTypeError}
                  setValue={(value) => handleAdditional(value, `salesType`)}
                  isMulti={false}
                  loadOptions={loadSalesTypes}
                />
              </div>
              <div className="flex-1 relative"></div>
              <div className="flex-1 relative"></div>
            </div>
          </div>
        </div>

        <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
          <SubmitButton onClick={ValidateForm}>Pay</SubmitButton>
        </div>
      </div>
    </Container>
  )
}
