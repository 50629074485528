import React, { useEffect, useState } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { loadOffset } from 'App/Services/General/loadOffset'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'

import { Container, StyledButton, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faSortDown,
  faSortUp,
  faEdit,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AxiosInstance, offset, AMS_BACKEND_API } from 'App/Config'
import { ReactModal } from 'App/Components/Common/Modal'
import { AddBroadcastingTemplates } from '../AddBroadcastingTemplates'
import { UpdateBroadcastingTemplates } from '../UpdateBroadcastingTemplates'
import { useMutation, useQuery } from 'react-query'
import { FilePreview } from './FilePreview'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { permissionGranted } from 'App/Services'
import { NotificationManager } from 'react-notifications'
const tableColumns = [
  { label: `Template Name`, value: `name` },
  { label: `Template Body`, value: `templateBody` },
  { label: `Channel`, value: `channel` },
]
import { useSelector } from 'react-redux'
import searchQueryParser from 'App/Services/General/searchQueryParser'

export const AllBroadCastingTemplates = () => {
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)

  const { sub: currentUser } = useSelector(({ user }) => user?.user?.profile)

  const [searchQuery, setSearchQuery] = useState(``)

  const [currentTemplate, setCurrentTemplate] = useState(``)
  const [quoteUpdated, setQuoteUpdated] = useState(false)
  const [updatePreviewModal, setUpdatePreviewModal] = useState(false)
  const [addTemplateModal, setAddTemplateModal] = useState(false)
  //offset useState
  const [isOffset, setIsOffset] = useState(``)

  const {
    isLoading: gettingTemplate,
    data: broadCastingTemplateData,
    error: templateError,
    refetch: getAllTemplates,
    isFetching: templateFetching,
  } = useQuery(
    `broadCastingTemplateApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/templates?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res?.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    isLoading: deletingTemplate,
    isError: deletionError,
    mutate: deleteTemplate,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(`${AMS_BACKEND_API}/api/templates/${id}`).then(
        (res) => {
          res.data && getAllTemplates()
          NotificationManager.success(`Successfully Deleted`)
        },
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  useEffect(() => {
    if (!customSearching) {
      getAllTemplates()
    }
  }, [sort, currentPage, isOffset])

  useEffect(() => {
    if (quoteUpdated) {
      getAllTemplates()
      setQuoteUpdated(false)
    }
  }, [quoteUpdated])

  const callAgentsQuery = useDebouncedCallback(() => {
    getAllTemplates()
  }, 500)

  const handleStatus = (template, e) => {
    if (e && e.stopPropagation) e.stopPropagation()

    setUpdatePreviewModal(true)
    setCurrentTemplate(template)
  }
  function stripHtmlTags(html) {
    const tmpElement = document.createElement(`div`)
    tmpElement.innerHTML = html
    return tmpElement.textContent || tmpElement.innerText || ``
  }
  return (
    <Container>
      {updatePreviewModal && (
        <ReactModal
          title="Update Template"
          closeModal={() => setUpdatePreviewModal(false)}
        >
          <UpdateBroadcastingTemplates
            currentTemplate={currentTemplate}
            closeModal={() => setUpdatePreviewModal(false)}
            refetchTemplates={getAllTemplates}
          />
        </ReactModal>
      )}

      {addTemplateModal && (
        <ReactModal
          title="Broadcast Template"
          closeModal={() => setAddTemplateModal(false)}
        >
          <AddBroadcastingTemplates
            closeModal={() => setAddTemplateModal(false)}
            refetchTemplates={getAllTemplates}
          />
        </ReactModal>
      )}
      <Spinner loading={deletingTemplate || templateFetching} />
      <PageHeader>
        <div>My Templates</div>
        <div className="flex items-center ">
          <div className="text-end ">
            <div className="flex">
              <div className="py-1 px-2">
                <ReactSelectField
                  placeholder="10"
                  value={isOffset}
                  setValue={(e) => {
                    setCurrentPage(1)
                    setIsOffset(e)
                  }}
                  isMulti={false}
                  loadOptions={loadOffset}
                />
              </div>
              <SearchInputField
                type="text"
                placeholder="Type here..."
                value={searchQuery}
                onEnter={(e) => {
                  if (e.key === `Enter`) {
                    setCurrentPage(1)
                    setCustomSearching(true)
                    callAgentsQuery()
                  }
                }}
                setValue={(e) => {
                  setSearchQuery(e.target.value)
                }}
                disabled={templateFetching}
              />
            </div>
          </div>
        </div>
      </PageHeader>
      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `broadcast-templates:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton onClick={() => setAddTemplateModal(true)}>
            <div className="flex gap-2 text-white">
              <span>New Template </span>
            </div>
          </StyledButton>
        )}
      </div>

      {/* filters */}
      <>
        <div
          className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
            (gettingTemplate || deletingTemplate || templateFetching) &&
            ` opacity-30 pointer-events-none`
          }`}
        >
          {deletionError || templateError ? (
            <Reload refetch={() => getAllTemplates()} />
          ) : (
            <>
              <div className="overflow-x-auto  rounded-t-md">
                <table className="w-full border-collapse bg-gray-50">
                  <TableHeader>
                    <TableRow>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className="cursor-pointer select-none text-sm flex truncate">
                              <div
                                onClick={() => {
                                  handleSorting(item.value)
                                }}
                                className={`flex ${
                                  item.value === sort ? `text-red-500` : ``
                                }`}
                              >
                                {item.label}
                              </div>
                              <div
                                className="ml-2 px-1 rounded-sm cursor-pointer flex "
                                onClick={() => handleSorting(`-` + item?.value)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    sort.includes(`-`) &&
                                    sort == `-` + item?.value
                                      ? faSortUp
                                      : faSortDown
                                  }
                                  size="lg"
                                  className={`${
                                    sort.includes(`-`) &&
                                    sort == `-` + item?.value
                                      ? `text-red-500`
                                      : `-mt-1.5`
                                  }`}
                                />
                              </div>
                            </div>
                          </TableCell>
                        )
                      })}
                      {permissionGranted([
                        `broadcast-templates:*`,
                        `broadcast-templates:update`,
                        `broadcast-templates:delete`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) && (
                        <TableCell className="select-none text-sm float-right">
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {!gettingTemplate &&
                    !broadCastingTemplateData?.data?.templates?.length ? (
                      <tr>
                        <td colSpan="12" className="text-center p-6">
                          No Template Found
                        </td>
                      </tr>
                    ) : (
                      broadCastingTemplateData?.data?.templates?.map(
                        (template, index) => (
                          <TableRow
                            key={index}
                            className="hover:bg-gray-100 whitespace-nowrap cursor-pointer"
                          >
                            <td className="px-4 py-0.5 text-sm capitalize truncate">
                              {template?.name ? (
                                template?.name?.toLowerCase()
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 text-sm max-w-sm whitespace-pre-line overflow-hidden">
                              {template?.template ? (
                                <div>
                                  {template?.template?.includes(`data:`) ? (
                                    // <FontAwesomeIcon
                                    //   className="mx-1 cursor-pointer transform transition hover:text-red-500 hover:-translate-y-0 hover:scale-105"
                                    //   icon={faFile}
                                    //   size="1x"
                                    // />
                                    <FilePreview file={template?.template} />
                                  ) : (
                                    <div>
                                      <div className="truncate">
                                        {stripHtmlTags(template?.template)}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5">
                              <Badge> {template?.channel}</Badge>
                            </td>

                            {/* Actions TD */}
                            <td className="px-6 py-0.5 text-sm z-10 float-right text-gray-400">
                              {permissionGranted([
                                `broadcast-templates:update`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ]) && (
                                <span
                                  data-tip={
                                    currentUser === template?.createdBy
                                      ? `Edit`
                                      : `Not Allowed`
                                  }
                                  className="tooltip"
                                >
                                  <FontAwesomeIcon
                                    className=" mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer "
                                    icon={faEdit}
                                    onClick={(e) => {
                                      currentUser === template?.createdBy
                                        ? handleStatus(template, e)
                                        : e.stopPropagation()
                                    }}
                                  />
                                </span>
                              )}
                              {permissionGranted([
                                `broadcast-templates:delete`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ]) && (
                                <span
                                  data-tip={
                                    currentUser === template?.createdBy
                                      ? `Delete`
                                      : `Not Allowed`
                                  }
                                  className="tooltip tooltip-left"
                                >
                                  <FontAwesomeIcon
                                    className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                    icon={faTrash}
                                    size="1x"
                                    onClick={(e) => {
                                      currentUser === template?.createdBy
                                        ? deleteTemplate(template._id, e)
                                        : e.stopPropagation()
                                    }}
                                  />
                                </span>
                              )}
                            </td>
                          </TableRow>
                        ),
                      )
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={broadCastingTemplateData?.data?.totalPages}
                  totalRecords={broadCastingTemplateData?.data?.totalRecords}
                  curerntPageRecords={
                    broadCastingTemplateData?.data?.currentPageRecords
                  }
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          )}
        </div>
      </>
    </Container>
  )
}
