import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { loadCarTypes } from 'App/Services'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL } from 'App/Config'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { useSelector } from 'react-redux'
import {
  loadBodyTypes,
  loadGaragingTypes,
  loadBoolean,
  loadUseTypes,
  loadZipCodes,
  ValidateZip,
  loadPerformance,
  loadLienHolderTypes,
  loadLienHolderInterests,
  loadRegistrationStates,
  loadYears,
} from 'App/Services'
import { useHistory, useRouteMatch } from 'react-router'
import { useMutation } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useDebouncedCallback } from 'use-debounce/lib'

export const UpdateVehicle = () => {
  const [isFormChanged, setIsFormChanged] = useState(false)

  const [asset, setAsset] = useState({
    type: ``,
    registrationState: ``,
    vinNumber: ``,
    make: ``,
    year: ``,
    model: ``,
    registrationExpirationDate: ``,
    usage: ``,
    value: ``,
    deductable: ``,
    deductableCollision: ``,
    description: ``,
    isVisible: false,
    garaging: ``,
    bodyType: ``,
    garagingRadius: ``,
    costNew: ``,
    newVehicle: ``,
    alteredVehicle: ``,
    alterationsAmount: ``,
    length: ``,
    licensePlateNumber: ``,
    fullTermPremium: ``,
    vehicleWeight: ``,
    numberOfCylinders: ``,
    displacement: ``,
    rateClassCode: ``,
    physicaldemagerateClass: ``,
    leased: ``,
    isSeenCar: ``,
    multiCarDisount: ``,
    damageLiability: ``,
    bumberDiscount: ``,
    performance: ``,
    seatBeltType: ``,
    airBagType: ``,
    milesToWork: ``,
    use: ``,
    daysDriverPerWeek: ``,
    carPool: ``,
    odoMeterReading: ``,
    estimatedAnnualMiles: ``,
    existingUnrepairedDemage: ``,
    residualMarketFacility: ``,
    antiTheftPercent: ``,
    antiLockBrake: ``,
    numberOfAxis: ``,
    maximumSpeed: ``,
    numberOfYouthfulOperators: ``,
    enginetype: ``,
    inspectionStatus: ``,
    collisionSymbol: ``,
    comprehensiveSymbol: ``,
    isRegistered: false,
    isNonOwned: false,
    isAntiThefdevice: false,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    zip: ``,
    street: ``,
    city: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    typeError: ``,
    registrationStateError: ``,
    vinNumberError: ``,
    makeError: ``,
    yearError: ``,
    modelError: ``,
    registrationExpirationDateError: ``,
    usageError: ``,
    valueError: ``,
    deductableError: ``,
    deductableCollisionError: ``,
    descriptionError: ``,
    isVisibleError: ``,
    garagingError: ``,
    bodyTypeError: ``,
    garagingRadiusError: ``,
    costNewError: ``,
    newVehicleError: ``,
    alteredVehicleError: ``,
    alterationsAmountError: ``,
    lengthError: ``,
    licensePlateNumberError: ``,
    fullTermPremiumError: ``,
    vehicleWeightError: ``,
    numberOfCylindersError: ``,
    displacementError: ``,
    rateClassCodeError: ``,
    physicaldemagerateClassError: ``,
    leasedError: ``,
    isSeenCarError: ``,
    multiCarDisountError: ``,
    damageLiabilityError: ``,
    bumberDiscountError: ``,
    performanceError: ``,
    seatBeltTypeError: ``,
    airBagTypeError: ``,
    milesToWorkError: ``,
    useError: ``,
    daysDriverPerWeekError: ``,
    carPoolError: ``,
    odoMeterReadingError: ``,
    estimatedAnnualMilesError: ``,
    existingUnrepairedDemageError: ``,
    residualMarketFacilityError: ``,
    antiTheftPercentError: ``,
    antiLockBrakeError: ``,
    numberOfAxisError: ``,
    maximumSpeedError: ``,
    numberOfYouthfulOperatorsError: ``,
    enginetypeError: ``,
    inspectionStatusError: ``,
    collisionSymbolError: ``,
    comprehensiveSymbolError: ``,
    isRegisteredError: ``,
    isNonOwnedError: ``,
    isAntiThefdeviceError: ``,
    unitNoError: ``,
    zipError: ``,
    streetError: ``,
    cityError: ``,
    stateError: ``,
    coordinatesError: false,
  })

  const [lienHolders, setLienHolders] = useState([])

  const [policyCoverages, setPolicyCoverages] = useState([])
  const [vehicleCoverages, setVehicleCoverages] = useState([])
  const [coveragesLimitOptions] = useState([])

  const history = useHistory()
  const {
    params: { customerId, vehicleId },
  } = useRouteMatch()

  const { vehicleAsset } = useSelector(({ asset }) => asset)

  useEffect(() => {
    if (vehicleAsset) {
      setAsset((prevState) => ({
        ...prevState,
        type: { value: vehicleAsset.type, label: vehicleAsset.type },
        registrationState: {
          value: vehicleAsset.registrationState,
          label: vehicleAsset.registrationState,
        },
        vinNumber: vehicleAsset.vinNumber,
        make: vehicleAsset.make,
        year: { value: vehicleAsset.year, label: vehicleAsset.year },
        model: vehicleAsset.model,
        registrationExpirationDate: new Date(
          vehicleAsset.registrationExpirationDate,
        ),
        usage: vehicleAsset.usage,
        value: vehicleAsset.value.toString(),
        deductable: vehicleAsset.deductable.toString(),
        deductableCollision: vehicleAsset.deductableCollision.toString(),
        description: vehicleAsset.description,
        isVisible: vehicleAsset.isVisible,
        garaging: {
          value: vehicleAsset.garaging,
          label: vehicleAsset.garaging,
        },
        bodyType: {
          value: vehicleAsset.bodyType,
          label: vehicleAsset.bodyType,
        },
        garagingRadius: vehicleAsset?.garagingRadius,
        costNew: vehicleAsset.costNew.toString(),
        newVehicle: {
          value: vehicleAsset.newVehicle,
          label: vehicleAsset.newVehicle,
        },
        alteredVehicle: {
          value: vehicleAsset.alteredVehicle,
          label: vehicleAsset.alteredVehicle,
        },
        alterationsAmount: vehicleAsset.alterationsAmount?.toString(),
        length: vehicleAsset.length.toString(),
        licensePlateNumber: vehicleAsset.licensePlateNumber,
        fullTermPremium: vehicleAsset.fullTermPremium,
        vehicleWeight: vehicleAsset.vehicleWeight.toString(),
        numberOfCylinders: vehicleAsset.numberOfCylinders.toString(),
        displacement: vehicleAsset.displacement,
        rateClassCode: vehicleAsset.rateClassCode,
        physicaldemagerateClass: vehicleAsset.physicaldemagerateClass,
        leased: { value: vehicleAsset.leased, label: vehicleAsset.leased },
        isSeenCar: {
          value: vehicleAsset.isSeenCar,
          label: vehicleAsset.isSeenCar,
        },
        multiCarDisount: {
          value: vehicleAsset.multiCarDisount,
          label: vehicleAsset.multiCarDisount,
        },
        damageLiability: vehicleAsset.damageLiability,
        bumberDiscount: vehicleAsset.bumberDiscount.toString(),
        performance: {
          value: vehicleAsset.performance,
          label: vehicleAsset.performance,
        },
        seatBeltType: vehicleAsset.seatBeltType,
        airBagType: vehicleAsset.airBagType,
        milesToWork: vehicleAsset.milesToWork.toString(),
        use: { value: vehicleAsset.use, label: vehicleAsset.use },
        daysDriverPerWeek: vehicleAsset.daysDriverPerWeek.toString(),
        carPool: vehicleAsset.carPool,
        odoMeterReading: vehicleAsset.odoMeterReading,
        estimatedAnnualMiles: vehicleAsset.estimatedAnnualMiles.toString(),
        existingUnrepairedDemage: vehicleAsset.existingUnrepairedDemage,
        residualMarketFacility: vehicleAsset.residualMarketFacility,
        antiTheftPercent: vehicleAsset.antiTheftPercent.toString(),
        antiLockBrake: {
          value: vehicleAsset.antiLockBrake,
          label: vehicleAsset.antiLockBrake,
        },
        numberOfAxis: vehicleAsset.numberOfAxis,
        maximumSpeed: vehicleAsset.maximumSpeed.toString(),
        numberOfYouthfulOperators:
          vehicleAsset.numberOfYouthfulOperators.toString(),
        enginetype: vehicleAsset.enginetype,
        inspectionStatus: vehicleAsset.inspectionStatus,
        collisionSymbol: vehicleAsset.collisionSymbol,
        comprehensiveSymbol: vehicleAsset.comprehensiveSymbol,
        isRegistered: vehicleAsset.isRegistered,
        isNonOwned: vehicleAsset.isNonOwned,
        isAntiThefdevice: vehicleAsset.isAntiThefdevice,
      }))
      setAddress((prevState) => ({
        ...prevState,
        unitNo: vehicleAsset.garageAddress.unitNo,
        zip: {
          value: vehicleAsset.garageAddress.zip,
          label: vehicleAsset.garageAddress.zip,
        },
        street: vehicleAsset.garageAddress.street,
        city: vehicleAsset.garageAddress.city,
        state: vehicleAsset.garageAddress.state,
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        lat: JSON.parse(vehicleAsset.garageAddress.coordinates.lat),
        lng: JSON.parse(vehicleAsset.garageAddress.coordinates.long),
      }))
      let newLienHolders = []
      vehicleAsset.lienHolders.map((holders) => {
        newLienHolders.push({
          type: { value: holders.type, label: holders.type },
          typeError: ``,
          natureOfInterest: {
            value: holders.natureOfInterest,
            label: holders.natureOfInterest,
          },
          natureOfInterestError: ``,
        })
      })
      setLienHolders(newLienHolders)
      let policyCoverages = []
      vehicleAsset.Coverages.map((coverage) => {
        if (coverage.scope == `policyScoped`) {
          policyCoverages.push({
            coverage: coverage.coverage,
            loadOptions: { value: coverage.limit, label: coverage.limit },
            scope: coverage.scope,
            limit: ``,
            limitError: ``,
            premium: coverage.premium ? coverage?.premium.toString() : ``,
            premiumError: ``,
          })
        }
      })
      setPolicyCoverages([...policyCoverages])

      let vehicleCoverages = []
      vehicleAsset.Coverages.map((coverage) => {
        if (coverage.scope == `vehicleScoped`) {
          vehicleCoverages.push({
            coverage: coverage.coverage,
            loadOptions: { value: coverage.limit, label: coverage.limit },
            scope: coverage.scope,
            limit: ``,
            limitError: ``,
            premium: coverage.premium ? coverage.premium : ``,
            premiumError: ``,
          })
        }
      })
      setVehicleCoverages([...vehicleCoverages])
    }
  }, [vehicleAsset])

  //vehicle add mutation
  const {
    mutate: updateVehicle,
    isLoading: updatingVehicle,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/assets/${customerId}/type/vehicles/item/${vehicleId}`,
        {
          vehicle: { ...payload },
        },
      ).then((res) => {
        if (
          res?.error?.error.includes(`Incoming request body can't be empty.`)
        ) {
          setIsFormChanged(true)
        }
        res.data &&
          history.push(
            `/admin/customers/${customerId}/asset/vehicle/${vehicleId}`,
          )
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const removeLienHolderForm = (index) => {
    if (lienHolders.length <= 1) {
      return
    }
    const lienHolder = [...lienHolders]
    lienHolder.splice(index, 1)
    setLienHolders(lienHolder)
  }

  const handleAssetChange = useDebouncedCallback((name, value) => {
    setAsset((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)
  const handleAddressChange = useDebouncedCallback((name, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)
  //handle zip code
  const handleZipChange = async (val) => {
    const data = await ValidateZip(val.value)
    if (data) {
      const res = data.address

      setAddress((prevState) => ({
        ...prevState,
        city: res.city,
        state: res.state,
        zip: { value: res.zipCode, label: res.zipCode },
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        lat: res.lat,
        lng: res.long,
      }))
    }
  }

  const handlePolicyCoverageChange = useDebouncedCallback(
    (value, name, ind) => {
      const newCoverages = policyCoverages?.map((coverage, index) => {
        if (index === ind) {
          coverage[name] = value
        }
        return coverage
      })
      setPolicyCoverages(newCoverages)
    },
    5,
  )

  const handleVehicleCoverageChange = useDebouncedCallback(
    (value, name, ind) => {
      const newCoverages = vehicleCoverages?.map((coverage, index) => {
        if (index === ind) {
          coverage[name] = value
        }
        return coverage
      })
      setVehicleCoverages(newCoverages)
    },
    5,
  )

  const handleLienHoldersChange = useDebouncedCallback((value, name, ind) => {
    const newLienHolders = lienHolders?.map((holders, index) => {
      if (index === ind) {
        holders[name] = value
      }
      return holders
    })
    setLienHolders(newLienHolders)
  }, 5)
  // handle map
  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleLienHoldersErrors = (ind, name, value) => {
    const newLienHolder = lienHolders?.map((holder, index) => {
      if (index == ind) {
        holder[name] = value
      }
      return holder
    })
    setLienHolders(newLienHolder)
  }

  const ValidateForm = () => {
    if (!asset.type?.value?.length) {
      handleErrors(`typeError`, `Select Type`)
    } else {
      handleErrors(`typeError`, ``)
    }

    if (!asset.registrationState?.value?.length) {
      handleErrors(`registrationStateError`, `Enter Name`)
    } else {
      handleErrors(`registrationStateError`, ``)
    }

    if (!asset.vinNumber.length) {
      handleErrors(`vinNumberError`, `Enter Number`)
    } else {
      handleErrors(`vinNumberError`, ``)
    }

    if (!asset.make.length) {
      handleErrors(`makeError`, `Enter Make`)
    } else {
      handleErrors(`makeError`, ``)
    }

    if (!asset.year?.value) {
      handleErrors(`yearError`, `Enter Year`)
    } else {
      handleErrors(`yearError`, ``)
    }

    if (!asset.model.length) {
      handleErrors(`modelError`, `Enter Modal`)
    } else {
      handleErrors(`modelError`, ``)
    }

    if (!asset.registrationExpirationDate) {
      handleErrors(`registrationExpirationDateError`, `Select Data`)
    } else {
      handleErrors(`registrationExpirationDateError`, ``)
    }

    if (!asset.usage.length) {
      handleErrors(`usageError`, `Enter Usage`)
    } else {
      handleErrors(`usageError`, ``)
    }

    if (!asset.value.length) {
      handleErrors(`valueError`, `Enter Value`)
    } else {
      handleErrors(`valueError`, ``)
    }

    if (!asset.deductable?.length) {
      handleErrors(`deductableError`, `Enter Amount`)
    } else {
      handleErrors(`deductableError`, ``)
    }

    if (!asset.deductableCollision?.length) {
      handleErrors(`deductableCollisionError`, `Enter Collision`)
    } else {
      handleErrors(`deductableCollisionError`, ``)
    }

    if (!asset.description?.length) {
      handleErrors(`descriptionError`, `Select Business Type`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    if (!asset.garaging?.value?.length) {
      handleErrors(`garagingError`, `Select Garaging`)
    } else {
      handleErrors(`garagingError`, ``)
    }

    if (!asset.bodyType?.value?.length) {
      handleErrors(`bodyTypeError`, `Select Body Type`)
    } else {
      handleErrors(`bodyTypeError`, ``)
    }
    if (!asset.garagingRadius?.toString().length) {
      handleErrors(`garagingRadiusError`, `Enter Radius`)
    } else {
      handleErrors(`garagingRadiusError`, ``)
    }

    if (!asset.newVehicle?.value?.length) {
      handleErrors(`newVehicleError`, `Select Vehicle`)
    } else {
      handleErrors(`newVehicleError`, ``)
    }

    if (!asset.costNew?.length) {
      handleErrors(`costNewError`, `Enter Cost`)
    } else {
      handleErrors(`costNewError`, ``)
    }

    if (!asset.alteredVehicle?.value?.length) {
      handleErrors(`alteredVehicleError`, `Select Altered`)
    } else {
      handleErrors(`alteredVehicleError`, ``)
    }

    if (!asset.alterationsAmount?.length) {
      handleErrors(`alterationsAmountError`, `Enter Amount`)
    } else {
      handleErrors(`alterationsAmountError`, ``)
    }
    if (!asset.length?.length) {
      handleErrors(`lengthError`, `Enter Length`)
    } else {
      handleErrors(`lengthError`, ``)
    }

    if (!asset.licensePlateNumber?.length) {
      handleErrors(`licensePlateNumberError`, `Enter Number`)
    } else {
      handleErrors(`licensePlateNumberError`, ``)
    }

    if (!asset.fullTermPremium?.length) {
      handleErrors(`fullTermPremiumError`, `Enter Premium`)
    } else {
      handleErrors(`fullTermPremiumError`, ``)
    }

    if (!asset.vehicleWeight?.length) {
      handleErrors(`vehicleWeightError`, `Enter Weight`)
    } else {
      handleErrors(`vehicleWeightError`, ``)
    }

    if (!asset.numberOfCylinders?.length) {
      handleErrors(`numberOfCylindersError`, `Enter Number`)
    } else {
      handleErrors(`numberOfCylindersError`, ``)
    }

    if (!asset.displacement?.length) {
      handleErrors(`displacementError`, `Enter Displacement`)
    } else {
      handleErrors(`displacementError`, ``)
    }

    if (!asset.rateClassCode?.length) {
      handleErrors(`rateClassCodeError`, `Enter Code`)
    } else {
      handleErrors(`rateClassCodeError`, ``)
    }

    if (!asset.physicaldemagerateClass?.length) {
      handleErrors(`physicaldemagerateClassError`, `Enter Damage Rate`)
    } else {
      handleErrors(`physicaldemagerateClassError`, ``)
    }

    if (!asset.leased?.value?.length) {
      handleErrors(`leasedError`, `Select Leased`)
    } else {
      handleErrors(`leasedError`, ``)
    }

    if (!asset.isSeenCar?.value?.length) {
      handleErrors(`isSeenCarError`, `Choose Option`)
    } else {
      handleErrors(`isSeenCarError`, ``)
    }

    if (!asset.multiCarDisount?.value?.length) {
      handleErrors(`multiCarDisountError`, `Choose Option`)
    } else {
      handleErrors(`multiCarDisountError`, ``)
    }

    if (!asset.damageLiability?.length) {
      handleErrors(`damageLiabilityError`, `Enter Damage Liability`)
    } else {
      handleErrors(`damageLiabilityError`, ``)
    }

    if (!asset.bumberDiscount?.length) {
      handleErrors(`bumberDiscountError`, `Enter Bumber Discount`)
    } else {
      handleErrors(`bumberDiscountError`, ``)
    }

    if (!asset.performance?.value?.length) {
      handleErrors(`performanceError`, `Select Performance`)
    } else {
      handleErrors(`performanceError`, ``)
    }

    if (!asset.seatBeltType?.length) {
      handleErrors(`seatBeltTypeError`, `Enter Type`)
    } else {
      handleErrors(`seatBeltTypeError`, ``)
    }

    if (!asset.airBagType?.length) {
      handleErrors(`airBagTypeError`, `Enter Type`)
    } else {
      handleErrors(`airBagTypeError`, ``)
    }
    if (!asset.milesToWork?.length) {
      handleErrors(`milesToWorkError`, `Enter Miles`)
    } else {
      handleErrors(`milesToWorkError`, ``)
    }

    if (!asset.use?.value?.length) {
      handleErrors(`useError`, `Select Use Type`)
    } else {
      handleErrors(`useError`, ``)
    }

    if (!asset.daysDriverPerWeek?.length) {
      handleErrors(`daysDriverPerWeekError`, `Enter Value`)
    } else {
      handleErrors(`daysDriverPerWeekError`, ``)
    }

    if (!asset.carPool?.length) {
      handleErrors(`carPoolError`, `Enter Car Pool`)
    } else {
      handleErrors(`carPoolError`, ``)
    }

    if (!asset.odoMeterReading?.length) {
      handleErrors(`odoMeterReadingError`, `Enter ODO Meter Reading`)
    } else {
      handleErrors(`odoMeterReadingError`, ``)
    }
    if (!asset.estimatedAnnualMiles?.length) {
      handleErrors(`estimatedAnnualMilesError`, `Enter Annual Miles`)
    } else {
      handleErrors(`estimatedAnnualMilesError`, ``)
    }

    if (!asset.existingUnrepairedDemage?.length) {
      handleErrors(`existingUnrepairedDemageError`, `Enter Value`)
    } else {
      handleErrors(`existingUnrepairedDemageError`, ``)
    }

    if (!asset.residualMarketFacility?.length) {
      handleErrors(`residualMarketFacilityError`, `Enter Value`)
    } else {
      handleErrors(`residualMarketFacilityError`, ``)
    }

    if (!asset.antiTheftPercent?.length) {
      handleErrors(`antiTheftPercentError`, `Enter Percent`)
    } else {
      handleErrors(`antiTheftPercentError`, ``)
    }

    if (!asset.antiLockBrake?.value?.length) {
      handleErrors(`antiLockBrakeError`, `Enter Percent`)
    } else {
      handleErrors(`antiLockBrakeError`, ``)
    }

    if (!asset.numberOfAxis?.length) {
      handleErrors(`numberOfAxisError`, `Enter Value`)
    } else {
      handleErrors(`numberOfAxisError`, ``)
    }

    if (!asset.maximumSpeed?.length) {
      handleErrors(`maximumSpeedError`, `Enter Speed`)
    } else {
      handleErrors(`maximumSpeedError`, ``)
    }

    if (!asset.numberOfYouthfulOperators?.length) {
      handleErrors(`numberOfYouthfulOperatorsError`, `Enter Value`)
    } else {
      handleErrors(`numberOfYouthfulOperatorsError`, ``)
    }

    if (!asset.enginetype?.length) {
      handleErrors(`enginetypeError`, `Enter Type`)
    } else {
      handleErrors(`enginetypeError`, ``)
    }

    if (!asset.inspectionStatus?.length) {
      handleErrors(`inspectionStatusError`, `Enter Status`)
    } else {
      handleErrors(`inspectionStatusError`, ``)
    }

    if (!asset.collisionSymbol?.length) {
      handleErrors(`collisionSymbolError`, `Enter Symbol`)
    } else {
      handleErrors(`collisionSymbolError`, ``)
    }

    if (!asset.comprehensiveSymbol?.length) {
      handleErrors(`comprehensiveSymbolError`, `Enter Comprehensive Symbol`)
    } else {
      handleErrors(`comprehensiveSymbolError`, ``)
    }

    if (!address.unitNo.length) {
      handleErrors(`unitNoError`, `Enter Unit No`)
    } else {
      handleErrors(`unitNoError`, ``)
    }
    if (!address.zip?.value?.length) {
      handleErrors(`zipError`, `Enter Zip`)
    } else {
      handleErrors(`zipError`, ``)
    }

    if (!address.street.length) {
      handleErrors(`streetError`, `Enter Street`)
    } else {
      handleErrors(`streetError`, ``)
    }

    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }

    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, true)
    } else {
      handleErrors(`coordinatesError`, false)
    }

    for (const index in lienHolders) {
      const holder = lienHolders[index]

      if (!holder.type?.value?.length) {
        handleLienHoldersErrors(index, `typeError`, `Select Type`)
      } else handleLienHoldersErrors(index, `typeError`, ``)

      if (!holder.natureOfInterest?.value?.length) {
        handleLienHoldersErrors(
          index,
          `natureOfInterestError`,
          `Select Interest`,
        )
      } else handleLienHoldersErrors(index, `natureOfInterestError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    let formValidated = false

    if (
      errors.formSubmit &&
      !errors.typeError.length &&
      !errors.registrationStateError.length &&
      !errors.vinNumberError.length &&
      !errors.makeError.length &&
      !errors.yearError.length &&
      !errors.modelError.length &&
      !errors.registrationExpirationDateError.length &&
      !errors.usageError.length &&
      !errors.valueError.length &&
      !errors.deductableError.length &&
      !errors.deductableCollisionError.length &&
      !errors.isVisibleError.length &&
      !errors.bodyTypeError.length &&
      !errors.garagingRadiusError.length &&
      !errors.costNewError.length &&
      !errors.newVehicleError.length &&
      !errors.alteredVehicleError.length &&
      !errors.alterationsAmountError.length &&
      !errors.lengthError.length &&
      !errors.licensePlateNumberError.length &&
      !errors.fullTermPremiumError.length &&
      !errors.vehicleWeightError.length &&
      !errors.numberOfCylindersError.length &&
      !errors.displacementError.length &&
      !errors.rateClassCodeError.length &&
      !errors.physicaldemagerateClassError.length &&
      !errors.leasedError.length &&
      !errors.isSeenCarError.length &&
      !errors.multiCarDisountError.length &&
      !errors.damageLiabilityError.length &&
      !errors.bumberDiscountError.length &&
      !errors.performanceError.length &&
      !errors.seatBeltTypeError.length &&
      !errors.airBagTypeError.length &&
      !errors.milesToWorkError.length &&
      !errors.useError.length &&
      !errors.daysDriverPerWeekError.length &&
      !errors.carPoolError.length &&
      !errors.odoMeterReadingError.length &&
      !errors.estimatedAnnualMilesError.length &&
      !errors.existingUnrepairedDemageError.length &&
      !errors.residualMarketFacilityError.length &&
      !errors.antiTheftPercentError.length &&
      !errors.antiLockBrakeError.length &&
      !errors.numberOfAxisError.length &&
      !errors.maximumSpeedError.length &&
      !errors.numberOfYouthfulOperatorsError.length &&
      !errors.enginetypeError.length &&
      !errors.inspectionStatusError.length &&
      !errors.collisionSymbolError.length &&
      !errors.comprehensiveSymbolError.length &&
      !errors.isRegisteredError.length &&
      !errors.isNonOwnedError.length &&
      !errors.isAntiThefdeviceError.length &&
      !errors.unitNoError.length &&
      !errors.zipError.length &&
      !errors.streetError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.coordinatesError
    ) {
      lienHolders.forEach((lienholder) => {
        if (!lienholder.typeError.length && !lienholder.natureOfInterestError) {
          formValidated = true
        } else {
          formValidated = false
        }
      })

      if (formValidated) {
        //call API
        const policyCoveragesPayload = policyCoverages?.map((coverage) => {
          return {
            coverage: coverage.coverage,
            limit: coverage.limit.value,
            premium: coverage.premium,
          }
        })
        const vehicleCoveragesPayload = vehicleCoverages?.map((coverage) => {
          return {
            coverage: coverage.coverage,
            limit: coverage.limit.value,
            premium: coverage.premium,
          }
        })
        const lienHoldersPayload = lienHolders?.map((holder) => {
          return {
            type: holder.type.value,
            natureOfInterest: holder.natureOfInterest.value,
          }
        })
        // const gragingAddress = {
        //   unitNo: address.unitNo,
        //   zip: address.zip.value,
        //   street: address.street,
        //   city: address.city,
        //   state: address.state,
        //   coordinates: {
        //     lat: coordinates.lat.toString(),
        //     long: coordinates.lng.toString(),
        //   },
        // }

        if (
          policyCoveragesPayload?.length &&
          vehicleCoveragesPayload.length &&
          lienHoldersPayload?.length
        ) {
          updateVehicle(updatePayload())
        }
      }

      setErrors((prevState) => ({
        ...prevState,
        [`formSubmit`]: false,
      }))
    }
  }, [errors])
  const updatePayload = () => {
    let data = {}

    if (asset.type.value !== vehicleAsset.type) {
      data.type = asset.type.value
    }
    if (asset.registrationState.value !== vehicleAsset.registrationState) {
      data.registrationState = asset.registrationState.value
    }
    if (asset.vinNumber !== vehicleAsset.vinNumber) {
      data.vinNumber = asset.vinNumber
    }
    if (asset.make !== vehicleAsset.make) {
      data.make = asset.make
    }
    if (asset.year.value !== vehicleAsset.year) {
      data.year = asset.year.value
    }
    if (asset.model !== vehicleAsset.model) {
      data.model = asset.model
    }
    if (
      asset.registrationExpirationDate.toString() !==
      new Date(vehicleAsset.registrationExpirationDate).toString()
    ) {
      data.registrationExpirationDate = asset.registrationExpirationDate
    }
    if (asset.usage !== vehicleAsset.usage) {
      data.usage = asset.usage
    }
    if (asset.value.toString() !== vehicleAsset.value.toString()) {
      data.value = asset.value
    }
    if (asset.deductable.toString() !== vehicleAsset.deductable.toString()) {
      data.deductable = asset.deductable
    }
    if (
      asset.deductableCollision.toString() !==
      vehicleAsset.deductableCollision.toString()
    ) {
      data.deductableCollision = asset.deductableCollision
    }
    if (asset.description !== vehicleAsset.description) {
      data.description = asset.description
    }
    if (asset.isVisible !== vehicleAsset.isVisible) {
      data.isVisible = asset.isVisible
    }
    if (asset.garaging.value !== vehicleAsset.garaging) {
      data.garaging = asset.garaging.value
    }
    if (asset.bodyType.value !== vehicleAsset.bodyType) {
      data.bodyType = asset.bodyType.value
    }
    if (asset.garagingRadius !== vehicleAsset.garagingRadius) {
      data.garagingRadius = asset.garagingRadius
    }
    if (asset.newVehicle.value !== vehicleAsset.newVehicle) {
      data.newVehicle = asset.newVehicle.value
    }
    if (asset.costNew.toString() !== vehicleAsset.costNew.toString()) {
      data.costNew = asset.costNew
    }
    if (asset.alteredVehicle.value !== vehicleAsset.alteredVehicle) {
      data.alteredVehicle = asset.alteredVehicle.value
    }
    if (
      asset.alterationsAmount.toString() !==
      vehicleAsset.alterationsAmount.toString()
    ) {
      data.alterationsAmount = asset.alterationsAmount
    }
    if (asset.length.toString() !== vehicleAsset.length.toString()) {
      data.length = asset.length
    }
    if (asset.licensePlateNumber !== vehicleAsset.licensePlateNumber) {
      data.licensePlateNumber = asset.licensePlateNumber
    }
    if (asset.fullTermPremium !== vehicleAsset.fullTermPremium) {
      data.fullTermPremium = asset.fullTermPremium
    }
    if (
      asset.vehicleWeight.toString() !== vehicleAsset.vehicleWeight.toString()
    ) {
      data.vehicleWeight = asset.vehicleWeight
    }
    if (
      asset.numberOfCylinders.toString() !==
      vehicleAsset.numberOfCylinders.toString()
    ) {
      data.numberOfCylinders = asset.numberOfCylinders
    }
    if (asset.displacement !== vehicleAsset.displacement) {
      data.displacement = asset.displacement
    }
    if (asset.rateClassCode !== vehicleAsset.rateClassCode) {
      data.rateClassCode = asset.rateClassCode
    }
    if (
      asset.physicaldemagerateClass !== vehicleAsset.physicaldemagerateClass
    ) {
      data.physicaldemagerateClass = asset.physicaldemagerateClass
    }
    if (asset.leased.value !== vehicleAsset.leased) {
      data.leased = asset.leased.value
    }
    if (asset.isSeenCar.value !== vehicleAsset.isSeenCar) {
      data.isSeenCar = asset.isSeenCar.value
    }
    if (asset.multiCarDisount.value !== vehicleAsset.multiCarDisount) {
      data.multiCarDisount = asset.multiCarDisount.value
    }
    if (asset.damageLiability !== vehicleAsset.damageLiability) {
      data.damageLiability = asset.damageLiability
    }
    if (
      asset.bumberDiscount.toString() !== vehicleAsset.bumberDiscount.toString()
    ) {
      data.bumberDiscount = asset.bumberDiscount
    }
    if (asset.performance.value !== vehicleAsset.performance) {
      data.performance = asset.performance.value
    }
    if (asset.seatBeltType !== vehicleAsset.seatBeltType) {
      data.seatBeltType = asset.seatBeltType
    }
    if (asset.airBagType !== vehicleAsset.airBagType) {
      data.airBagType = asset.airBagType
    }
    if (asset.milesToWork.toString() !== vehicleAsset.milesToWork.toString()) {
      data.milesToWork = asset.milesToWork
    }
    if (asset.use.value !== vehicleAsset.use) {
      data.use = asset.use.value
    }
    if (
      asset.daysDriverPerWeek.toString() !==
      vehicleAsset.daysDriverPerWeek.toString()
    ) {
      data.daysDriverPerWeek = asset.daysDriverPerWeek
    }
    if (asset.carPool !== vehicleAsset.carPool) {
      data.carPool = asset.carPool
    }
    if (asset.odoMeterReading !== vehicleAsset.odoMeterReading) {
      data.odoMeterReading = asset.odoMeterReading
    }
    if (
      asset.estimatedAnnualMiles.toString() !==
      vehicleAsset.estimatedAnnualMiles.toString()
    ) {
      data.estimatedAnnualMiles = asset.estimatedAnnualMiles
    }
    if (
      asset.existingUnrepairedDemage !== vehicleAsset.existingUnrepairedDemage
    ) {
      data.existingUnrepairedDemage = asset.existingUnrepairedDemage
    }
    if (asset.residualMarketFacility !== vehicleAsset.residualMarketFacility) {
      data.residualMarketFacility = asset.residualMarketFacility
    }
    if (
      asset.antiTheftPercent.toString() !==
      vehicleAsset.antiTheftPercent.toString()
    ) {
      data.antiTheftPercent = asset.antiTheftPercent
    }
    if (asset.antiLockBrake.value !== vehicleAsset.antiLockBrake) {
      data.antiLockBrake = asset.antiLockBrake.value
    }
    if (asset.numberOfAxis !== vehicleAsset.numberOfAxis) {
      data.numberOfAxis = asset.numberOfAxis
    }
    if (
      asset.maximumSpeed.toString() !== vehicleAsset.maximumSpeed.toString()
    ) {
      data.maximumSpeed = asset.maximumSpeed
    }
    if (
      asset.numberOfYouthfulOperators.toString() !==
      vehicleAsset.numberOfYouthfulOperators.toString()
    ) {
      data.numberOfYouthfulOperators = asset.numberOfYouthfulOperators
    }
    if (asset.enginetype !== vehicleAsset.enginetype) {
      data.enginetype = asset.enginetype
    }
    if (asset.inspectionStatus !== vehicleAsset.inspectionStatus) {
      data.inspectionStatus = asset.inspectionStatus
    }
    if (asset.collisionSymbol !== vehicleAsset.collisionSymbol) {
      data.collisionSymbol = asset.collisionSymbol
    }
    if (asset.comprehensiveSymbol !== vehicleAsset.comprehensiveSymbol) {
      data.comprehensiveSymbol = asset.comprehensiveSymbol
    }
    if (asset.isRegistered !== vehicleAsset.isRegistered) {
      data.isRegistered = asset.isRegistered
    }
    if (asset.isNonOwned !== vehicleAsset.isNonOwned) {
      data.isNonOwned = asset.isNonOwned
    }
    if (asset.isAntiThefdevice !== vehicleAsset.isAntiThefdevice) {
      data.isAntiThefdevice = asset.isAntiThefdevice
    }

    const isHlTrue = lienHolders.every(
      (_lH, index) =>
        _lH[`type`].value === vehicleAsset.lienHolders[index].type &&
        _lH[`natureOfInterest`].value ===
          vehicleAsset.lienHolders[index].natureOfInterest,
    )
    const lienHoldersPayload = lienHolders?.map((holder) => {
      return {
        type: holder.type.value,
        natureOfInterest: holder.natureOfInterest.value,
      }
    })
    if (!isHlTrue) {
      data.lienHolders = lienHoldersPayload
    }
    // address payload
    const addressPayload = {}
    let coordinatesPayload = {}

    if (address.unitNo !== vehicleAsset.garageAddress.unitNo) {
      addressPayload.unitNo = address.unitNo
    }
    if (address.street !== vehicleAsset.garageAddress.street) {
      addressPayload.street = address.street
    }
    if (address.city !== vehicleAsset.garageAddress.city) {
      addressPayload.city = address.city
    }
    if (address?.zip?.value !== vehicleAsset.garageAddress.zip) {
      addressPayload.zip = address?.zip?.value
      coordinatesPayload.lat = coordinates.lat.toString()
      coordinatesPayload.long = coordinates.lng.toString()
    }
    if (address.state !== vehicleAsset.garageAddress.state) {
      addressPayload.state = address.state
    }
    if (Object.keys(addressPayload).length !== 0) {
      addressPayload.coordinates = coordinatesPayload
      data.garageAddress = addressPayload
    }

    setIsFormChanged(false)
    return data
  }
  return (
    <Container>
      <PageHeader>
        <div>Update Vehicle</div>
      </PageHeader>
      <Spinner loading={updatingVehicle} />
      {updationError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      {isFormChanged && (
        <ReactNotification action="error" message="No Field Changed" />
      )}
      <div
        className={`px-8 py-1 ${
          updatingVehicle && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Car Type"
              placeholder="Select Type"
              value={asset.type}
              errorMessage={errors.typeError}
              setValue={(value) =>
                setAsset((prevState) => ({
                  ...prevState,
                  [`type`]: value,
                }))
              }
              loadOptions={loadCarTypes}
              isSearchable={false}
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="State"
              placeholder="Select State"
              value={asset.registrationState}
              errorMessage={errors.registrationStateError}
              setValue={(value) =>
                setAsset((prevState) => ({
                  ...prevState,
                  [`registrationState`]: value,
                }))
              }
              isMulti={false}
              loadOptions={loadRegistrationStates}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="vinNumber"
              title="VIN No"
              placeholder="Enter Vin Number"
              value={asset.vinNumber}
              errorMessage={errors.vinNumberError}
              setValue={(e) => handleAssetChange(`vinNumber`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Make"
              name="make"
              placeholder="Enter Make"
              value={asset.make}
              errorMessage={errors.makeError}
              setValue={(e) => handleAssetChange(`make`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Year"
              placeholder="Select Year"
              value={asset.year}
              errorMessage={errors.yearError}
              setValue={(value) =>
                setAsset((prevState) => ({
                  ...prevState,
                  [`year`]: value,
                }))
              }
              isMulti={false}
              loadOptions={loadYears}
              isSearchable={true}
            />
          </div>
        </div>
        {/* row 2 */}
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="model"
              title="Model"
              placeholder="Enter Model No"
              value={asset.model}
              errorMessage={errors.modelError}
              setValue={(e) => handleAssetChange(`model`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <DatePickerField
              title="Reg Expiration Date"
              name="registrationExpirationDate"
              placeholder="Enter Regestration Expiration Date"
              value={asset.registrationExpirationDate}
              errorMessage={errors.registrationExpirationDateError}
              setValue={(date) => {
                setAsset((prevState) => ({
                  ...prevState,
                  [`registrationExpirationDate`]: date,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="usage"
              title="Usage"
              placeholder="Enter Asset Usage"
              value={asset.usage}
              errorMessage={errors.usageError}
              setValue={(e) => handleAssetChange(`usage`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              title="Value"
              name="value"
              placeholder="Enter Value"
              value={asset.value}
              errorMessage={errors.valueError}
              setValue={(e) => handleAssetChange(`value`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="deductable"
              title="Deductable"
              placeholder="Enter Deductable Amount"
              value={asset.deductable}
              errorMessage={errors.deductableError}
              setValue={(e) => handleAssetChange(`deductable`, e.target.value)}
            />
          </div>
        </div>
        {/* row 3 */}
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="deductableCollision"
              title="Deductable Collision"
              placeholder="Enter Deductable Collision"
              value={asset.deductableCollision}
              errorMessage={errors.deductableCollisionError}
              setValue={(e) =>
                handleAssetChange(`deductableCollision`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="description"
              title="Description"
              placeholder="Enter Description"
              value={asset.description}
              errorMessage={errors.descriptionError}
              setValue={(e) => handleAssetChange(`description`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative mt-6">
            <Checkbox
              type="checkbox"
              name="isVisible"
              title="Is Visible"
              value={asset.isVisible}
              errorMessage={errors.isVisibleError}
              setValue={() =>
                setAsset((prevState) => ({
                  ...prevState,
                  isVisible: !asset.isVisible,
                }))
              }
            />
          </div>
          <div className="flex-1 relative mt-6"></div>
          <div className="flex-1 relative mt-6"></div>
        </div>
        {/* ADDITIONAL */}
        <div className="my-4">
          <Collapsable title="additional">
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Garaging"
                  placeholder="Select Garaging"
                  value={asset.garaging}
                  errorMessage={errors.garagingError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      garaging: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadGaragingTypes}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Body Type"
                  placeholder="Select Body Type"
                  value={asset.bodyType}
                  errorMessage={errors.bodyTypeError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      bodyType: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBodyTypes}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="garagingRadius"
                  title="Garaging Raduis"
                  placeholder="Enter Garaging Raduis"
                  value={asset.garagingRadius}
                  errorMessage={errors.garagingRadiusError}
                  setValue={(e) =>
                    handleAssetChange(`garagingRadius`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="New Vehicle"
                  placeholder="Select Vehicle"
                  value={asset.newVehicle}
                  errorMessage={errors.newVehicleError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      newVehicle: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="costNew"
                  title="Cost New"
                  placeholder="Enter Cost New"
                  value={asset.costNew}
                  errorMessage={errors.costNewError}
                  setValue={(e) => handleAssetChange(`costNew`, e.target.value)}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Altered Vehicle"
                  placeholder="Select Altered"
                  value={asset.alteredVehicle}
                  errorMessage={errors.alteredVehicleError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      alteredVehicle: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="alterationsAmount"
                  title="Alteratoins Amount"
                  placeholder="Enter Alteratoins Amount"
                  value={asset.alterationsAmount}
                  errorMessage={errors.alterationsAmountError}
                  setValue={(e) =>
                    handleAssetChange(`alterationsAmount`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="length"
                  title="Length"
                  placeholder="Enter Length"
                  value={asset.length}
                  errorMessage={errors.lengthError}
                  setValue={(e) => handleAssetChange(`length`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative ">
                <InputField
                  type="text"
                  name="licensePlateNumber"
                  title="License PlateNumber"
                  placeholder="Enter License PlateNumber"
                  value={asset.licensePlateNumber}
                  errorMessage={errors.licensePlateNumberError}
                  setValue={(e) =>
                    handleAssetChange(`licensePlateNumber`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="fullTermPremium"
                  title="Full Term Premium"
                  placeholder="Enter Full Term Premium"
                  value={asset.fullTermPremium}
                  errorMessage={errors.fullTermPremiumError}
                  setValue={(e) =>
                    handleAssetChange(`fullTermPremium`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="vehicleWeight"
                  title="Vehicle Weight"
                  placeholder="Enter Vehicle Weight"
                  value={asset.vehicleWeight}
                  errorMessage={errors.vehicleWeightError}
                  setValue={(e) =>
                    handleAssetChange(`vehicleWeight`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="numberOfCylinders"
                  title="Number Of Cylinders"
                  placeholder="Enter Number Of Cylinders"
                  value={asset.numberOfCylinders}
                  errorMessage={errors.numberOfCylindersError}
                  setValue={(e) =>
                    handleAssetChange(`numberOfCylinders`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative ">
                <InputField
                  type="text"
                  name="displacement"
                  title="Displacement"
                  placeholder="Enter Displacement"
                  value={asset.displacement}
                  errorMessage={errors.displacementError}
                  setValue={(e) =>
                    handleAssetChange(`displacement`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="rateClassCode"
                  title="Rate Class Code"
                  placeholder="Enter Code"
                  value={asset.rateClassCode}
                  errorMessage={errors.rateClassCodeError}
                  setValue={(e) =>
                    handleAssetChange(`rateClassCode`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="physicaldemagerateClass"
                  title="Physical Demage Rate Class"
                  placeholder="Enter Physical Demage RateClass"
                  value={asset.physicaldemagerateClass}
                  errorMessage={errors.physicaldemagerateClassError}
                  setValue={(e) =>
                    handleAssetChange(`physicaldemagerateClass`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Leased"
                  placeholder="Select Leased"
                  value={asset.leased}
                  errorMessage={errors.leasedError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      leased: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative ">
                <ReactSelectField
                  title="Seen Car"
                  placeholder="Choose Option"
                  value={asset.isSeenCar}
                  errorMessage={errors.isSeenCarError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      isSeenCar: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Multi Car Disount"
                  placeholder="Choose Option"
                  value={asset.multiCarDisount}
                  errorMessage={errors.multiCarDisountError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      multiCarDisount: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="damageLiability"
                  title="Damage Liability"
                  placeholder="Enter Damage Liability"
                  value={asset.damageLiability}
                  errorMessage={errors.damageLiabilityError}
                  setValue={(e) =>
                    handleAssetChange(`damageLiability`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="bumberDiscount"
                  title="Bumber Discount"
                  placeholder="Enter Bumber Discount"
                  value={asset.bumberDiscount}
                  errorMessage={errors.bumberDiscountError}
                  setValue={(e) =>
                    handleAssetChange(`bumberDiscount`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative ">
                <ReactSelectField
                  title="Performance"
                  placeholder="Choose Option"
                  value={asset.performance}
                  errorMessage={errors.performanceError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      performance: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadPerformance}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="seatBeltType"
                  title="Seat Belt Type"
                  placeholder="Enter SeatBelt Type"
                  value={asset.seatBeltType}
                  errorMessage={errors.seatBeltTypeError}
                  setValue={(e) =>
                    handleAssetChange(`seatBeltType`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="airBagType"
                  title="AirBag Type"
                  placeholder="Enter AirBag Type"
                  value={asset.airBagType}
                  errorMessage={errors.airBagTypeError}
                  setValue={(e) =>
                    handleAssetChange(`airBagType`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="milesToWork"
                  title="Miles To Work"
                  placeholder="Enter Miles To Work"
                  value={asset.milesToWork}
                  errorMessage={errors.milesToWorkError}
                  setValue={(e) =>
                    handleAssetChange(`milesToWork`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative ">
                <ReactSelectField
                  title="Use"
                  placeholder="Select Use Type"
                  value={asset.use}
                  errorMessage={errors.useError}
                  loadOptions={loadUseTypes}
                  isSearchable={false}
                  isMulti={false}
                  setValue={(e) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      use: e,
                    }))
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="daysDriverPerWeek"
                  title="Days Driver Per Week"
                  placeholder="Enter Days Driver Per Week"
                  value={asset.daysDriverPerWeek}
                  errorMessage={errors.daysDriverPerWeekError}
                  setValue={(e) =>
                    handleAssetChange(`daysDriverPerWeek`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="carPool"
                  title="Car Pool"
                  placeholder="Enter Car Pool"
                  value={asset.carPool}
                  errorMessage={errors.carPoolError}
                  setValue={(e) => handleAssetChange(`carPool`, e.target.value)}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="odoMeterReading"
                  title="ODO Meter Reading"
                  placeholder="Enter ODO Reading"
                  value={asset.odoMeterReading}
                  errorMessage={errors.odoMeterReadingError}
                  setValue={(e) =>
                    handleAssetChange(`odoMeterReading`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative ">
                <InputField
                  type="number"
                  name="estimatedAnnualMiles"
                  title="Estimated Annual Miles"
                  placeholder="Enter Estimated Annual Miles"
                  value={asset.estimatedAnnualMiles}
                  errorMessage={errors.estimatedAnnualMilesError}
                  setValue={(e) =>
                    handleAssetChange(`estimatedAnnualMiles`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="existingUnrepairedDemage"
                  title="Existing Unrepaired Demage"
                  placeholder="Enter Existing Unrepaired Demage"
                  value={asset.existingUnrepairedDemage}
                  errorMessage={errors.existingUnrepairedDemageError}
                  setValue={(e) =>
                    handleAssetChange(
                      `existingUnrepairedDemage`,
                      e.target.value,
                    )
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="residualMarketFacility"
                  title="Residual Market Facility"
                  placeholder="Enter Residual Market Facility"
                  value={asset.residualMarketFacility}
                  errorMessage={errors.residualMarketFacilityError}
                  setValue={(e) =>
                    handleAssetChange(`residualMarketFacility`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="antiTheftPercent"
                  title="Anti Theft Percent"
                  placeholder="Enter Anti Theft Percent"
                  value={asset.antiTheftPercent}
                  errorMessage={errors.antiTheftPercentError}
                  setValue={(e) =>
                    handleAssetChange(`antiTheftPercent`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative ">
                <ReactSelectField
                  title="Anti Lock Brake"
                  placeholder="Choose Option"
                  value={asset.antiLockBrake}
                  errorMessage={errors.antiLockBrakeError}
                  setValue={(value) =>
                    setAsset((prevState) => ({
                      ...prevState,
                      antiLockBrake: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadBoolean}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="numberOfAxis"
                  title="Number Of Axis"
                  placeholder="Enter Number Of Axis"
                  value={asset.numberOfAxis}
                  errorMessage={errors.numberOfAxisError}
                  setValue={(e) =>
                    handleAssetChange(`numberOfAxis`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="maximumSpeed"
                  title="Maximum Speed in Miles"
                  placeholder="Enter Maximum Speed"
                  value={asset.maximumSpeed}
                  errorMessage={errors.maximumSpeedError}
                  setValue={(e) =>
                    handleAssetChange(`maximumSpeed`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  name="numberOfYouthfulOperators"
                  title="Number Of Youthful Operators"
                  placeholder="Enter Number Of Youthful Operators"
                  value={asset.numberOfYouthfulOperators}
                  errorMessage={errors.numberOfYouthfulOperatorsError}
                  setValue={(e) =>
                    handleAssetChange(
                      `numberOfYouthfulOperators`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative ">
                <InputField
                  type="text"
                  name="enginetype"
                  title="Engine Type"
                  placeholder="Enter Engine Type"
                  value={asset.enginetype}
                  errorMessage={errors.enginetypeError}
                  setValue={(e) =>
                    handleAssetChange(`enginetype`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="inspectionStatus"
                  title="Inspection Status"
                  placeholder="Enter Inspection Status"
                  value={asset.inspectionStatus}
                  errorMessage={errors.inspectionStatusError}
                  setValue={(e) =>
                    handleAssetChange(`inspectionStatus`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="collisionSymbol"
                  title="Collision Symbol"
                  placeholder="Enter Collision Symbol"
                  value={asset.collisionSymbol}
                  errorMessage={errors.collisionSymbolError}
                  setValue={(e) =>
                    handleAssetChange(`collisionSymbol`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="comprehensiveSymbol"
                  title="Comprehensive Symbol"
                  placeholder="Enter Comprehensive Symbol"
                  value={asset.comprehensiveSymbol}
                  errorMessage={errors.comprehensiveSymbolError}
                  setValue={(e) =>
                    handleAssetChange(`comprehensiveSymbol`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2 ">
              <div className="flex-1 relative ">
                <Checkbox
                  title="Registered"
                  name="isRegistered"
                  value={asset.isRegistered}
                  errorMessage={errors.isRegisteredError}
                  setValue={() =>
                    setAsset((prevState) => ({
                      ...prevState,
                      isRegistered: !asset.isRegistered,
                    }))
                  }
                />
              </div>
              <div className="flex-1 relative">
                <Checkbox
                  title="Non Owned"
                  name="isNonOwned"
                  value={asset.isNonOwned}
                  errorMessage={errors.isNonOwnedError}
                  setValue={() =>
                    setAsset((prevState) => ({
                      ...prevState,
                      isNonOwned: !asset.isNonOwned,
                    }))
                  }
                />
              </div>
              <div className="flex-1 relative">
                <Checkbox
                  title="Anti Thef device"
                  name="isAntiThefdevice"
                  value={asset.isAntiThefdevice}
                  errorMessage={errors.isAntiThefdeviceError}
                  setValue={() =>
                    setAsset((prevState) => ({
                      ...prevState,
                      isAntiThefdevice: !asset.isAntiThefdevice,
                    }))
                  }
                />
              </div>
              <div className="flex-1 relative"></div>
              <div className="flex-1 relative"></div>
            </div>

            <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
              Address
            </div>
            <div className="flex gap-6 mt-2 text-xs">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="unitNo"
                  title="Unit No"
                  placeholder="Enter Unit No"
                  value={address.unitNo}
                  errorMessage={errors.unitNoError}
                  setValue={(e) =>
                    handleAddressChange(`unitNo`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Zip"
                  name="zip"
                  placeholder="Select Zip"
                  isMulti={false}
                  value={address.zip}
                  errorMessage={errors.zipError}
                  setValue={(e) => handleZipChange(e)}
                  loadOptions={loadZipCodes}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="street"
                  title="Street"
                  placeholder="Enter Street"
                  value={address.street}
                  errorMessage={errors.streetError}
                  setValue={(e) =>
                    handleAddressChange(`street`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="city"
                  title="City"
                  placeholder="Enter City"
                  value={address.city}
                  errorMessage={errors.cityError}
                  disabled={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  name="state"
                  title="State"
                  placeholder="Enter State"
                  value={address.state}
                  errorMessage={errors.stateError}
                  id="lAddress"
                  disabled={true}
                />
              </div>
            </div>
            <div className="rounded-md grid grid-cols-1">
              <label className="text-sm mt-2 text-gray-700 font-medium">
                Locate Yourself
                {errors.coordinatesError && (
                  <span className="text-red-500 required-dot ml-2">*</span>
                )}
              </label>
              <MapContainer
                center={[coordinates.lat, coordinates.lng]}
                zoom={3}
                scrollWheelZoom={true}
              >
                <ChangeView
                  center={[coordinates.lat, coordinates.lng]}
                  zoom={3}
                />
                <TileLayer url={LEAFLET_URL} />
                <DraggableMarker
                  coord={coordinates}
                  setCoordinates={(e) => setCoordinates(e)}
                />
              </MapContainer>
            </div>
          </Collapsable>
        </div>
        {/* End Additional */}
        {/* coverages */}
        <div className="my-4">
          <Collapsable title="Coverages">
            <>
              <div className="font-semibold my-2">Policy Scopped</div>
              {policyCoverages?.map((coverage, index) => (
                <div key={index}>
                  <div className="flex gap-6 ">
                    <div className="flex-1 relative">
                      <InputField
                        title="Coverage"
                        value={coverage.coverage}
                        disabled={true}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Limit"
                        placeholder="Select Limit"
                        value={coverage.limit}
                        loadOptions={() => ({
                          options: coveragesLimitOptions[index].loadOptions,
                          hasMore: false,
                        })}
                        errorMessage={coverage.limitError}
                        setValue={(value) =>
                          handlePolicyCoverageChange(value, `limit`, index)
                        }
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="number"
                        name="premium"
                        title="Premium"
                        placeholder="Enter Premium"
                        value={coverage.premium}
                        errorMessage={errors.premiumError}
                        setValue={(e) =>
                          handlePolicyCoverageChange(
                            e.target.value,
                            `premium`,
                            index,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="font-semibold my-4">Vehicle Scopped</div>
              {vehicleCoverages?.map((coverage, index) => (
                <div key={index}>
                  <div className=" flex gap-6 ">
                    <div className="flex-1 relative">
                      <InputField
                        title="Coverage"
                        value={coverage.coverage}
                        disabled={true}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Limit"
                        placeholder="Select Limit"
                        value={coverage.limit}
                        loadOptions={() => ({
                          options: coveragesLimitOptions[index + 7].loadOptions,
                          hasMore: false,
                        })}
                        errorMessage={coverage.limitError}
                        setValue={(value) =>
                          handleVehicleCoverageChange(value, `limit`, index)
                        }
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="text"
                        name="premium"
                        title="Premium"
                        placeholder="Enter Premium"
                        value={coverage.premium}
                        errorMessage={errors.premiumError}
                        setValue={(e) =>
                          handleVehicleCoverageChange(
                            e.target.value,
                            `premium`,
                            index,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          </Collapsable>
        </div>
        {/*end coverages */}
        {/* Lean Holders */}
        <div className="my-4">
          <Collapsable title="Lien Holders">
            {lienHolders?.map((lienHolder, index) => (
              <div key={index} className="grid grid-cols-12">
                <div className="col-span-10 flex gap-6 ">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Type"
                      placeholder="Select Type"
                      value={lienHolder?.type}
                      errorMessage={lienHolder?.typeError}
                      loadOptions={loadLienHolderTypes}
                      isSearchable={true}
                      setValue={(value) =>
                        handleLienHoldersChange(value, `type`, index)
                      }
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Nature Of Interest"
                      placeholder="Select Interest"
                      value={lienHolder?.natureOfInterest}
                      errorMessage={lienHolder?.natureOfInterestError}
                      loadOptions={loadLienHolderInterests}
                      isSearchable={true}
                      setValue={(value) =>
                        handleLienHoldersChange(
                          value,
                          `natureOfInterest`,
                          index,
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-span-2 mt-7">
                  <div className="flex items-center justify-center float-right">
                    <span
                      onClick={() =>
                        setLienHolders([
                          ...lienHolders,
                          {
                            type: ``,
                            typeError: ``,
                            natureOfInterest: ``,
                            natureOfInterestError: ``,
                          },
                        ])
                      }
                      data-tip="Add Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500 cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="transform transition  hover:-translate-y-0 hover:scale-105"
                      />
                    </span>
                    <span
                      onClick={() => removeLienHolderForm(index)}
                      data-tip="Remove Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500 cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="transform transition  hover:-translate-y-0 hover:scale-105"
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Collapsable>
        </div>
        {/* Lean Holders End */}
        <div className="text-center xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
          <SubmitButton onClick={ValidateForm}>Update Vehicle</SubmitButton>
        </div>
      </div>
    </Container>
  )
}
