import React from 'react'

export const Skelton = ({ photo }) => {
  return (
    <div className="rounded-md w-72">
      <div className="animate-pulse flex space-x-4">
        {!photo && <div className="rounded-md bg-gray-100 h-16 w-16"></div>}
        <div className="flex-1 space-y-6 py-1">
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-6 bg-gray-100 rounded col-span-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
