import React, { useEffect, useState } from 'react'
import { defaultImage } from 'App/Assets'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, StyledButton, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Avatar,
} from '@windmill/react-ui'
import {
  faChevronCircleDown,
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'

const tableColumns = [
  { label: `Id`, value: `id` },
  { label: `First Name`, value: `firstName` },
  { label: `Last Name`, value: `lastName` },
  { label: `Email`, value: `email` },
  { label: `Phone`, value: `phone` },
  { label: `Franchise`, value: `_franchise.name` },
  { label: `Locations`, value: `_franchise.locations` },
  // { label: `Role`, value: `role` },
]

export const FAgents = () => {
  const history = useHistory()
  const {
    params: { id },
    url,
  } = useRouteMatch()
  const franchiseStatus = useSelector(
    ({ franchise }) => franchise?.currentFranchise?.status,
  )
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [collapseIndex, setCollapseIndex] = useState(``)
  const [active, setActive] = useState(false)
  // const [isMasterFrenchise, setIsMasterFrenchise] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)

  //   getting all locations
  const {
    isLoading,
    data: agentsResponse,
    refetch: getFAgents,
    isFetching,
    error,
  } = useQuery(
    `FAgentsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/franchises/${id}/agents?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }", "onlyMasterAgents": ${false}}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    mutate: deleteAgent,
    isLoading: deletingAgent,
    isError: deletionError,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(`${AMS_BACKEND_API}/api/users/${id}`).then(
        (res) => {
          NotificationManager.success(`Successfully Deleted`)
          res.data && getFAgents()
        },
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  useEffect(() => {
    if (!customSearching) {
      getFAgents()
    }
  }, [sort, currentPage, isOffset])

  const callAgentsQuery = useDebouncedCallback(() => {
    getFAgents()
  }, 700)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteAgent(id)
  }

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!active) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }

  return (
    <Container>
      <Spinner loading={deletingAgent || isLoading || isFetching} />
      {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <PageHeader>
        <div>Agents</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end ">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              setValue={(e) => {
                setSearchQuery(e.target.value)
                setCurrentPage(1)
                setCustomSearching(true)
                callAgentsQuery()
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>
      {permissionGranted([`corporate-manager:*`]) && (
        <div className="flex justify-end items-center p-2">
          <StyledButton
            onClick={() => history.push(`${url}/agents/add`)}
            disabled={franchiseStatus !== `INACTIVE` ? false : true}
          >
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        </div>
      )}

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (deletingAgent || isLoading || isFetching) &&
          ` opacity-30 pointer-events-none`
        }  `}
      >
        {error ? (
          <Reload refetch={() => getFAgents()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    <TableCell className="select-none text-sm">Image</TableCell>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item?.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {permissionGranted([`corporate-manager:*`]) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !agentsResponse?.users?.length ? (
                    <tr>
                      <td colSpan="12">
                        <div className="w-full text-center p-6  ">
                          <p>No Agent Found</p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    agentsResponse?.users?.map((user, index) => (
                      <>
                        <TableRow
                          key={index}
                          className="hover:bg-gray-100 whitespace-nowrap cursor-pointer"
                          onClick={() => {
                            if (permissionGranted([`corporate-manager:*`])) {
                              history.push(`${url}/agents/${user._id}`)
                            }
                          }}
                        >
                          <td className="px-4 py-2">
                            <div className="flex items-center ">
                              <Avatar
                                src={user.img ? user.img : defaultImage}
                                alt="Judith"
                              />
                            </div>
                          </td>
                          <td className="px-4 py-0.5 text-sm capitalize truncate">
                            {user._userId ? user._userId : <Badge>N/A</Badge>}
                          </td>
                          <td className="px-4 py-0.5 text-sm capitalize truncate">
                            {user.firstName ? (
                              user.firstName?.toLowerCase()
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm capitalize truncate">
                            {user.lastName ? (
                              user.lastName?.toLowerCase()
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {user?.contactInfo?.email ? (
                              user?.contactInfo?.email
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {user?.contactInfo?.phone ? (
                              user?.contactInfo?.phone
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm capitalize truncate">
                            {user?._franchise ? (
                              <Badge>
                                {user?._franchise?.name?.toLowerCase()}
                              </Badge>
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            <div className="flex flex-row max-w-xs flex-wrap">
                              {user._franchise.locations?.length ? (
                                <Badge type="success">
                                  {user._franchise.locations[0]}
                                </Badge>
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </div>
                          </td>
                          {/* <td className="px-5 py-0.5  ">
                        <div className="flex flex-row flex-wrap  ">
                          {user.systemRoles?.length ? (
                            user.systemRoles.map((systemRole, idx) => {
                              return (
                                <Badge type="warning" key={idx}>
                                  {systemRole}
                                </Badge>
                              )
                            })
                          ) : (
                            <Badge type="danger">N/A</Badge>
                          )}
                        </div>
                      </td> */}
                          {/* Actions TD */}
                          <td className="px-8 py-2.5 z-0 text-sm flex float-right text-gray-400">
                            {user._franchise.locations?.length > 1 ? (
                              <span data-tip="Expand" className="tooltip">
                                <FontAwesomeIcon
                                  className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                    collapseIndex === index &&
                                    `rotate-180 text-blue-600`
                                  }`}
                                  icon={faChevronCircleDown}
                                  onClick={(e) => {
                                    setActive(!active)
                                    handleCollapse(index, e)
                                  }}
                                />
                              </span>
                            ) : (
                              ``
                            )}
                            {permissionGranted([`corporate-manager:*`]) && (
                              <span data-tip="Delete" className="tooltip">
                                <FontAwesomeIcon
                                  className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                  icon={faTrash}
                                  size="1x"
                                  onClick={(e) => handleDelete(user._id, e)}
                                />
                              </span>
                            )}
                          </td>
                        </TableRow>
                        {collapseIndex === index && (
                          <TableRow>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="px-4 py-3 z-0 max-w-sm">
                              <div className="flex flex-row max-w-xs flex-wrap">
                                {user._franchise.locations?.length ? (
                                  user._franchise.locations.map(
                                    (location, idx) => {
                                      return (
                                        <Badge
                                          type="success"
                                          key={idx}
                                          className="ml-1"
                                        >
                                          {location}
                                        </Badge>
                                      )
                                    },
                                  )
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </div>
                            </td>
                            <td></td>
                          </TableRow>
                        )}
                      </>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={agentsResponse?.totalPages}
                totalRecords={agentsResponse?.totalRecords}
                curerntPageRecords={agentsResponse?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
