import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeTab: 1,
  activeActivityTab: 1,
  activeLocationTab: 1,
  currentFranchise: ``,
}

const franchiseSlice = createSlice({
  name: `franchise`,

  initialState,
  reducers: {
    setActiveFranchiseTab: (state, { payload }) => {
      state.activeTab = payload
    },
    setCurrentFranchise: (state, { payload }) => {
      state.currentFranchise = payload
    },
    setFranchiseActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
    setActiveLocationTab: (state, { payload }) => {
      state.activeLocationTab = payload
    },
  },
})

export const {
  setActiveFranchiseTab,
  setCurrentFranchise,
  setFranchiseActivityActiveTab,
} = franchiseSlice.actions

export const franchise = franchiseSlice.reducer
