import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadCarTypes = async () => {
  const {
    data: { data },
  } = await AxiosInstance.get(`${AMS_BACKEND_API}/api/get/car-types`)
  if (data) {
    return {
      options: data.carTypes,
      hasMore: false,
    }
  }
}
