export {
  setCurrentAgent,
  setAgentActivityActiveTab,
  setAgentProfile,
} from './Slices/Agent'
export {
  setCurrentPayment,
  addPayments,
  setPaymentActiveActivityTab,
} from './Slices/Payment'
export { setCurrentPaymentTemplate } from './Slices/PaymentTemplates'
export { setDataTrasActivityActiveTab } from './Slices/DataTransactions'
export { setCurrentCampaign } from './Slices/Campaigns'
export { setCurrentDailyLedger } from './Slices/DailyLedger'
export { setCurrentInvoice } from './Slices/Invoice'
export {
  setCommunicationCredentials,
  setCurrentCall,
  setCurrentCallStatus,
  setCurrentIncomingCallStatus,
} from './Slices/CurrentCommunicationCredential'
export {
  setCurrentPolicy,
  setPolicyActiveActivityTab,
  setPolicyActiveTab,
} from './Slices/Policy'

export {
  setCurrentCarrier,
  setCarrierActivityActiveTab,
  setCurrentGroupCarrier,
  setGroupCarrierActivityActiveTab,
} from './Slices/Carrier'

export {
  setCurrentLocation,
  setLocationActivityActiveTab,
} from './Slices/Location'
export { setCurrentRole, setRoleActivityActiveTab } from './Slices/SystemRoles'
export {
  setCurrentClaim,
  setClaimActivityActiveTab,
  setActiveMediaTab,
  setClaimMedia,
} from './Slices/Claim'
export {
  setCurrentAssessment,
  setAssessmentActivityActiveTab,
  setSelectedAssessmentId,
  setCurrentEvaluation,
  setCurrentAssessmentTemplate,
} from './Slices/Assessment'
export {
  setUser,
  unsetUser,
  loadingUser,
  setAuthorizationCode,
  setIsUserSignInWithGmail,
  resetPersistantState,
  setPreferredLocation,
  setSwitchLocation,
  setUserAccessToken,
} from './Slices/User'

export {
  setActiveCustomerTab,
  setCurrentCustomer,
  setMessageActive,
  setActivityActiveTab,
  setActiveCommunicationsTab,
  setActiveCall,
  setVideoActive,
  setGlobalCallModalActive,
  setOutGoingCall,
} from './Slices/Customer'

export {
  setActiveFranchiseTab,
  setCurrentFranchise,
  setFranchiseActivityActiveTab,
} from './Slices/Franchise'

export {
  setPreviousLength,
  setLatestLength,
  setChatLatestLength,
  setChatPreviousLength,
  setNotificationIsRead,
} from './Slices/Notifications'

export {
  setAssetId,
  setDriverAsset,
  setVehicleAsset,
  setAssetActiveTab,
  setCustomerContacts,
  setContactActivityActiveTab,
} from './Slices/Asset'

export {
  setRatingTemplateToState,
  setCurrentRatingTemplate,
  setSelectedRatingTemplate,
} from './Slices/Ratings/RatingTemplates'

export {
  setActiveDriverTab,
  setActiveViolationTab,
  setActiveVehicleTab,
  setVehicleCoverage,
  setDriversInfo,
  setDriversInsured,
  setFirstDriver,
  setRatingCarriers,
  setUpdateRatingCarrier,
  setVehicleData,
  setSupportedViolations
} from './Slices/Ratings/QuoteForm'

export {
  addFee,
  removeFee,
  addPayable,
  addPayment,
  addFeePayable,
  addReceivable,
  addDirectBill,
  removePayment,
  removePayable,
  addEndorsment,
  addAgentPayment,
  removeDirectBill,
  addFeeDirectBill,
  removeEndorsment,
  removeReceivable,
  removeFeePayable,
  handleDirectBill,
  addFeeReceivable,
  handleCommission,
  addPayablePayment,
  addAgentCommission,
  removeAgentPayment,
  handleEndorsmentFee,
  addAgencyCommission,
  saveAgentCommission,
  removeFeeDirectBill,
  removeFeeReceivable,
  addFeeAgencyRenevue,
  addFeePayablePayment,
  addReceivablePayment,
  saveAgencyCommission,
  removePayablePayment,
  removeAgentCommission,
  addFeeAgentCommission,
  handleAgentCommission,
  handleEdorsmentChange,
  handleEndorsmentError,
  removeFeeAgencyRevenue,
  removeAgencyCommission,
  removeFeePayablePayment,
  removeReceivablePayment,
  addFeeReceivablePayment,
  handleEndorsmentPayable,
  removeFeeAgentCommission,
  handleEndorsmentFeeError,
  addFeeAgencyRevenuePayment,
  handleEndorsmentReceivable,
  handleEndorsmentDirectBill,
  removeFeeReceivablePayment,
  handleEndorsmentFeePayables,
  removeFeeAgentRevenuePayment,
  removeFeeAgencyRevenuePayment,
  handleEndorsmentFeeDirectBill,
  addFeeAgencyCommissionPayment,
  handleEndorsmentFeeReceivables,
  handleEndorsmentAgentCommission,
  handleEndorsmentAgencyCommission,
  handleEndorsmentFeeAgencyRevenue,
  handleEndorsmentFeeAgentCommission,
} from './Slices/Endorsment'

export { setThreads, setCurrentThreads } from './Slices/Communication/gmails'

export {
  setNonCustomerChatHistory,
  setNonCustomerNumber,
  setNonCustomerNewMessage,
  setUnknownCaller,
} from './Slices/Communication/globalCommunication'
export { setParticipantsLength } from './Slices/Communication/video'
export {
  setSentMessage,
  setAllMessages,
  setOutboundMessages,
  clearAllMessage,
  setTwilioDevice,
} from './Slices/Communication'
export {
  setPreferences,
  setPreferedCarriers,
  setUpdateCarrierPreferences,
} from './Slices/Ratings/MyPreferences'
export {
  setMyAllQuotes,
  setQuotesActivityActiveTab,
  setCurrentQuote,
} from './Slices/Ratings/MyQuotes'
export {
  setCurrentCarDealer,
  setCarDealerActivityActiveTab,
} from './Slices/CarDealer'
export {
  setCurrentCredential,
  setCredentialActivityActiveTab,
  setCurrentGroupCredential,
} from './Slices/Credentials'
export {
  setLeadActivityActiveTab,
  setLeadData,
  setCurrentLead,
} from './Slices/Leads'
export {
  setQuoteOffers,
  setQuoteForm,
  setPolicyDataCoverages,
  setCurrentSavedQuotes,
} from './Slices/AqdQuote'
export {
  setGlobalSearchData,
  setIsDataLoading,
  setQuerySearch,
  setGlobalCustomerFilter,
} from './Slices/GlobalSearch'
export {
  setCurrentAllMonthlyGoals,
  setMonthlyGoalActivityActiveTab,
} from './Slices/MonthlyGoals'
export {
  setTicketActivityActiveTab,
  setCurrentTicket,
} from './Slices/TicketsCenter'
export {
  setTaskActivityActiveTab,
  setCurrentTask,
  setActiveTaskMediaTab,
} from './Slices/TaskManager'
export {
  setCurrentCategory,
  setCategoryActivityActiveTab,
} from './Slices/TicketCategory'
export { setChatRoomId } from './Slices/Messenger'

export { setCurrentPolicyRequest } from './Slices/PolicyRequest'
export { setGeneralNotificationId } from './Slices/GeneralNotification' 
