import { faCalendarWeek, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactModal } from 'App/Components/Common/Modal'
import {
  AMS_BACKEND_API,
  AxiosInstance,
  allowedSystemPermissions,
} from 'App/Config'
import { SubmitButton, PageHeader, StyledButton } from 'App/Styled'
import FileSaver from 'file-saver'
import React, { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  isSuperUser,
  loadAgents,
  loadFranchises,
  permissionGranted,
} from 'App/Services'
import { useSelector } from 'react-redux'
import { DropDown, ProfileMenuItem } from 'App/Components/Layout/Topbar/style'
import { useMutation } from 'react-query'

export function DailyLedgerFilter({
  ledgerFilters,
  handleFilters,
  resetFilters,
  ledgerData,
  daterange,
  handleDateChange,
  filtersInvoking,
}) {
  const [filters, setFilters] = useState({ ...ledgerFilters })
  const [dateRange, setDateRange] = useState(daterange)
  const [openExportFileModal, setOpenExportFileModal] = useState(false)
  const openExportFileRef = useRef(null)
  const [previewModal, setPreviewModal] = useState(false)
  const {
    user: {
      profile: { _franchise, _locations },
    },
  } = useSelector(({ user }) => user)

  const loadAgentLocations = () => {
    const options = _locations?.map((item) => {
      return { value: item?._id, label: item?.name }
    })

    return {
      options,
    }
  }

  const loadLocations = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        filters?.franchise?.value
      }/locations/list/?search=${search && search}&page=${page}&offset=10`,
    )
    if (data) {
      return {
        options: data.locations,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  const loadCarrier = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/carriers/list/?search=${
        search && search
      }&page=${page}&offset=10&franchiseId=${filters?.franchise?.value ?? ``}`,
    )

    if (data) {
      return {
        options: data.carriers,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        openExportFileRef.current &&
        !openExportFileRef.current.contains(event.target)
      )
        setOpenExportFileModal(false)
    }
    document.addEventListener(`click`, handleClickOutside)
  }, [openExportFileModal])

  const { mutate: getIffFile, isLoading: downLoadingFile } = useMutation(
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/reports/generate-iif/?search=${filtersInvoking?.searchFilters()}&sort=1&page=1&offset=200${filtersInvoking?.returnFiltersAsQueryParams()}`,
      ).then(async (res) => {
        return res?.data
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        const file = b64toFile(res, `encrypted.zip`, `application/zip`)
        FileSaver.saveAs(file, `encrypted.zip`)
      },
    },
  )

  function b64toFile(b64Data, filename, contentType) {
    // sliceSize: This variable determines the size
    // of each chunk (slice) of data that will be processed at a time
    let sliceSize = 512

    // byteCharacters: This variable is used to store the result of
    // decoding the base64-encoded string
    // "atob" is a built-in JavaScript function that decodes a base64 string.
    let byteCharacters = atob(b64Data)
    // byteArrays: This array will store each chunk of data as a Uint8Array.
    let byteArrays = []

    //  byteCharacters in chunks of sliceSize

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize)
      let byteNumbers = new Array(slice.length)

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      let byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
    let file = new File(byteArrays, filename, { type: contentType })
    return file
  }

  return (
    <>
      {previewModal && (
        <ReactModal
          title="Date Range"
          closeModal={() => setPreviewModal(false)}
        >
          <div className="pb-2">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              className="w-full"
            />
            <div className="px-2 text-right">
              <StyledButton
                onClick={() => {
                  handleDateChange(dateRange)
                  setPreviewModal(false)
                }}
              >
                <div className="flex gap-2 text-white">
                  <span>Filter </span>
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}

      <PageHeader>
        <div>Daily Ledger</div>

        <div className="flex items-end gap-x-4">
          {(ledgerData?.byPaymentMethod?.length ||
            ledgerData?.paymentsByType?.length ||
            ledgerData?.paymentsByAgent?.length ||
            ledgerData?.paymentsByFeeType?.length) && (
            <StyledButton
              onClick={() => {
                setOpenExportFileModal((prev) => !prev)
              }}
            >
              <div
                className="text-sm text-white"
                id="menu-item"
                ref={openExportFileRef}
              >
                <span>Export</span>
              </div>
            </StyledButton>
          )}
          <div id="menu-wrapper">
            {openExportFileModal && (
              <div>
                <DropDown profile="yes">
                  {ledgerData?.sheetsFile && (
                    <ProfileMenuItem
                      className="text-sm"
                      onClick={() => {
                        const file = b64toFile(
                          ledgerData?.sheetsFile,
                          `encrypted.zip`,
                          `application/zip`,
                        )
                        FileSaver.saveAs(file, `encrypted.zip`)

                        setOpenExportFileModal((prev) => !prev)
                      }}
                    >
                      <span>Sheets</span>
                    </ProfileMenuItem>
                  )}
                  <ProfileMenuItem
                    className="text-sm"
                    onClick={() => !downLoadingFile && getIffFile()}
                  >
                    <span>QuickBooks</span>
                    {downLoadingFile && (
                      <FontAwesomeIcon icon={faSpinner} spin={true} />
                    )}
                  </ProfileMenuItem>
                </DropDown>
              </div>
            )}
          </div>
          <div className="py-1 px-2" onClick={() => setPreviewModal(true)}>
            <FontAwesomeIcon
              icon={faCalendarWeek}
              className="text-blue-500 mt-0.5 cursor-pointer"
              size="sm"
            />
          </div>
        </div>
      </PageHeader>
      <div
        className={`bg-white mt-2 mb-1 sm:mb-0 justify-center items-center px-2 pt-1 `}
      >
        <Collapsable title="Filters">
          <div className="grid grid-cols-4 gap-4">
            {permissionGranted([
              `corporate-manager:*`,
              `accountant-master:*`,
            ]) && (
              <div className="relative">
                <ReactSelectField
                  title="Franchise"
                  placeholder="Franchise"
                  value={filters?.franchise ?? ``}
                  setValue={(value) => {
                    setFilters((prevState) => ({
                      ...prevState,
                      [`location`]: ``,
                    }))
                    setFilters((prevState) => ({
                      ...prevState,
                      [`carrier`]: ``,
                    }))
                    setFilters((prevState) => ({
                      ...prevState,
                      [`franchise`]: value,
                    }))
                  }}
                  isMulti={false}
                  loadOptions={loadFranchises}
                />
              </div>
            )}

            <div className="relative">
              <ReactSelectField
                title="Location"
                placeholder="Location"
                value={filters?.location ?? ``}
                setValue={(value) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    [`location`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={
                  filters?.franchise?.value &&
                  (permissionGranted([
                    `corporate-manager:*`,
                    `accountant-master:*`,
                    `manager:*`,
                    `accountant:*`,
                  ])
                    ? loadLocations
                    : loadAgentLocations)
                }
                disabled={!filters?.franchise?.value}
                cacheUniqs={filters?.franchise?.value}
              />
            </div>
            <div className="relative">
              <ReactSelectField
                title="Carrier"
                placeholder="Carrier"
                value={filters?.carrier ?? ``}
                setValue={(value) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    [`carrier`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadCarrier}
                // disabled={!filters?.franchise?.value}
                cacheUniqs={filters?.franchise?.value}
              />
            </div>
            <div className="relative">
              <ReactSelectField
                title="Agent"
                placeholder="Agent"
                value={filters?.agent ?? ``}
                setValue={(value) => {
                  setFilters((prevState) => ({
                    ...prevState,
                    [`agent`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={filters?.location?.value && loadAgents}
                cacheUniqs={filters?.location?.value}
                disabled={!filters?.location?.value}
                additionalFilters={{
                  franchiseId: filters?.franchise?.value
                    ? filters?.franchise?.value
                    : _franchise?._id,
                  locations: [filters?.location?.value],
                  permissions: allowedSystemPermissions.dailyLedger,
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 mt-4">
            <div className="flex  gap-4">
              <SubmitButton
                onClick={() => {
                  handleFilters(filters)
                }}
              >
                Search
              </SubmitButton>

              <SubmitButton
                onClick={() => {
                  if (
                    filters?.franchise?.value ||
                    filters?.location?.value ||
                    filters?.carrier?.value ||
                    filters?.agent?.value
                  ) {
                    if (
                      isSuperUser(`super-admin:*`) ||
                      isSuperUser(`system-admin:*`)
                    ) {
                      setFilters({})
                    } else {
                      setFilters({ franchise: filters?.franchise })
                    }
                    resetFilters()
                  }
                }}
              >
                Reset
              </SubmitButton>
            </div>
          </div>
        </Collapsable>
      </div>
    </>
  )
}
