import React from 'react'
import { AllAssessments } from 'App/Components/Assessment/AllAssessments'
// import { useSelector } from 'react-redux'
import { Assessments } from 'App/Components/Assessment/WeeklyAssessments'
import { permissionGranted } from 'App/Services'

export const AllAssessmentsPage = () => {
  // const { user } = useSelector(({ user }) => user)

  return permissionGranted([`manager:*`, `corporate-manager:*`, `admin:*`]) ? (
    <Assessments />
  ) : (
    <AllAssessments />
  )
}
