import React from 'react'
import { SearchInput } from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export const SearchInputField = ({
  type,
  value,
  disabled,
  placeholder,
  setValue,
  onEnter = () => {},
  validating,
  resetButton,
  resetValue = () => {},
}) => {
  return (
    <>
      <SearchInput
        type={type}
        disabled={disabled || validating}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e)}
        onKeyDown={(e) => onEnter(e)}
      />
      {validating && (
        <FontAwesomeIcon
          icon={faSpinner}
          className="absolute text-sideBarColor right-2 mt-3 mr-2.5 "
          spin={true}
        />
      )}
      {value?.length > 0 && !validating && resetButton && (
        <span
          onClick={() => resetValue(``)}
          className="absolute text-sideBarColor right-2 mt-1.5 mr-2 px-1 py-0.5 cursor-pointer font-bold "
        >
          ×
        </span>
      )}
    </>
  )
}
