import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  quoteForm: ``,
  currentQuoteOffers: ``,
  currentSavedQuotes: ``,
  policyDataCoverages: ``,
}

const quoteOffer = createSlice({
  name: `aqsQuote`,

  initialState,
  reducers: {
    setQuoteForm: (state, { payload }) => {
      state.quoteForm = payload
    },
    setQuoteOffers: (state, { payload }) => {
      state.currentQuoteOffers = payload
    },
    setPolicyDataCoverages: (state, { payload }) => {
      state.policyDataCoverages = payload
    },
    setCurrentSavedQuotes: (state, { payload }) => {
      state.currentSavedQuotes = payload
    },
  },
})

export const {
  setQuoteOffers,
  setQuoteForm,
  setPolicyDataCoverages,
  setCurrentSavedQuotes,
} = quoteOffer.actions

export const aqsQuote = quoteOffer.reducer
