/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { setSelectedRatingTemplate } from 'App/Redux/actions'
import {
  loadBinaryOptions,
  loadBumpLimits,
  loadPolicyTerm,
  loadPaymentOption,
  loadExclusion,
} from 'App/Services/quoteForm'

import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce/lib'

export const CoveragesForm = forwardRef(
  (
    {
      getData,
      policyScope,
      preference,
      selectedState,
      isTemplate,
      nonOwner,
      setNonOwner,
    },
    ref,
  ) => {
    const [policyScoped, setPolicyScoped] = useState([])
    const { selectedRatingTemplate } = useSelector(
      ({ ratingTemplate }) => ratingTemplate,
    )
    const dispatch = useDispatch()
    const [coverages, setCoverages] = useState({
      agencyFee: ``,
      estimateTerm: ``,
      bumpLimit: ``,
      effectiveDate: ``,
      policyTerm: ``,
      paymentOption: ``,
      allowedCredits: ``,
    })

    const [errors, setErrors] = useState({
      formSubmit: false,
      agencyFeeError: ``,
      estimateTermError: ``,
      bumpLimitError: ``,
      effectiveDateError: ``,
      policyTermError: ``,
      paymentOptionError: ``,
      allowedCreditsError: ``,
      pipError: ``,
      biError: ``,
      pdError: ``,
      umpdError: ``,
      umError: ``,
    })

    useEffect(() => {
      if (policyScope && !isTemplate) {
        setPolicyScoped(policyScope)
      }
    }, [policyScope])

    useEffect(() => {
      setCoverages({
        agencyFee:
          selectedState !== `FL`
            ? preference?.agencyFee
              ? preference?.agencyFee
              : 0
            : 0,
        estimateTerm: preference?.estimateTerm
          ? { value: preference?.estimateTerm, label: preference?.estimateTerm }
          : ``,
        bumpLimits: preference?.bumpLimits
          ? { value: preference?.bumpLimits, label: preference?.bumpLimits }
          : ``,
        effectiveDate: new Date(),
        policyTerm: { value: `6`, label: `Semi Annual` },
        paymentOption: preference?.paymentOption
          ? {
              value: preference?.paymentOption,
              label: preference?.paymentOption,
            }
          : { value: `INSTALLMENT EFT`, label: `Installments EFT` },
        exclusion: preference?.exclusion
          ? { value: preference?.exclusion, label: preference?.exclusion }
          : { value: `0`, label: `0` },
        allowedCredits: preference?.allowCreditScore
          ? {
              value: preference?.allowCreditScore,
              label: preference?.allowCreditScore,
            }
          : ``,
        nonOwner: preference?.nonOwner
          ? { value: preference?.nonOwner, label: preference?.nonOwner }
          : { value: `NO`, label: `NO` },
        broadForm: preference?.broadForm
          ? { value: preference?.broadForm, label: preference?.broadForm }
          : { value: `NO`, label: `NO` },
      })
    }, [preference])

    // useEffect(() => {
    //   // if (!isTemplate) {
    //   return () => {
    //     dispatch(setSelectedRatingTemplate([]))
    //     // }
    //   }
    // }, [])

    useEffect(() => {
      setCoverages((prev) => ({
        ...prev,
        agencyFee: selectedState !== `FL` ? preference?.agencyFee : 0,
      }))
    }, [selectedState])

    useEffect(() => {
      if (selectedRatingTemplate) {
        const _policyScoped =
          selectedRatingTemplate?.template?.policyCoverages.map((_policy) => ({
            code: _policy.code,
            _deductibleOptions:
              _policy?.deductibleOptions?.length &&
              _policy?.deductibleOptions.map((deductible) => ({
                value: deductible.key,
                label: deductible.name,
              })),

            deductibleOption: () => ({
              options: _policy?.deductibleOptions?.length
                ? _policy?.deductibleOptions.map((deductible) => ({
                    value: deductible.key,
                    label: deductible.name,
                  }))
                : [],
              hasMore: false,
            }),
            deductible: _policy?.deductible
              ? {
                  value: _policy?.deductible,
                  label: _policy?.deductible,
                }
              : `Disabled`,
            deductibleError: ``,
            emptyDeductibles: _policy?.deductibleOptions?.length ? false : true,
            free: _policy.free,
            name: _policy.name,
            required: _policy.required,
            limit: _policy?.limits?.length
              ? { value: _policy?.limits, label: _policy?.limits }
              : `Disabled`,
            limitError: ``,
            _limitOptions:
              _policy?.limitsOptions?.length &&
              _policy?.limitsOptions.map((deductible) => ({
                value: deductible.key,
                label: deductible.name,
              })),
            limitsOptions: () => ({
              options: _policy?.limitsOptions?.length
                ? _policy?.limitsOptions.map((limit) => ({
                    value: limit.key,
                    label: limit.name,
                  }))
                : [],
              hasMore: false,
            }),
            emptyLimits: _policy?.limitsOptions?.length ? false : true,
          }))

        setPolicyScoped(_policyScoped)
      }
    }, [selectedRatingTemplate?.template])
    useEffect(() => {
      if (errors.formSubmit) {
        let policyScopeValidated = false
        if (
          !errors.agencyFeeError.length &&
          !errors.estimateTermError.length &&
          !errors.bumpLimitError.length &&
          !errors.effectiveDateError.length &&
          !errors.policyTermError.length &&
          !errors.paymentOptionError.length &&
          !errors.allowedCreditsError.length
        ) {
          const checkPolicyScopeError = (policy) => {
            if (!policy.deductibleError.length && !policy.limitError.length) {
              return true
            } else {
              return false
            }
          }
          policyScopeValidated = policyScoped?.every(checkPolicyScopeError)
        }
        if (policyScopeValidated) {
          getData(`coverages`, coverages)
          getData(`policyCoverages`, policyScoped)
          handleErrors(`formSubmit`, false)
        } else {
          getData(`coverages`, null)
          getData(`policyCoverages`, null)
          handleErrors(`formSubmit`, false)
        }
      }
    }, [errors])

    useImperativeHandle(ref, () => ({
      validateForm() {
        if (!coverages.agencyFee.toString() && selectedState !== `FL`) {
          handleErrors(`agencyFeeError`, `Enter Fee`)
        } else if (coverages.agencyFee && coverages.agencyFee < 0) {
          handleErrors(`agencyFeeError`, `Should be More than 0`)
        } else {
          handleErrors(`agencyFeeError`, ``)
        }
        if (!coverages.estimateTerm?.value?.length) {
          handleErrors(`estimateTermError`, `Select Option`)
        } else {
          handleErrors(`estimateTermError`, ``)
        }
        if (!coverages.bumpLimits?.value?.length) {
          handleErrors(`bumpLimitError`, `Select Option`)
        } else {
          handleErrors(`bumpLimitError`, ``)
        }

        if (!coverages.effectiveDate) {
          handleErrors(`effectiveDateError`, `Select Date`)
        } else {
          handleErrors(`effectiveDateError`, ``)
        }

        if (!coverages.policyTerm?.value?.length) {
          handleErrors(`policyTermError`, `Select Option`)
        } else {
          handleErrors(`policyTermError`, ``)
        }

        if (!coverages.paymentOption?.value?.length) {
          handleErrors(`paymentOptionError`, `Select Option`)
        } else {
          handleErrors(`paymentOptionError`, ``)
        }

        if (!coverages.allowedCredits?.value?.length) {
          handleErrors(`allowedCreditsError`, `Select Fee`)
        } else {
          handleErrors(`allowedCreditsError`, ``)
        }

        for (const index in policyScoped) {
          const policy = policyScoped[index]
          if (policy.required === `YES`) {
            if (!policy.emptyDeductibles) {
              if (!policy.deductible?.value?.length) {
                handlePolicyErrors(`deductibleError`, `Select Options`, index)
              } else {
                handlePolicyErrors(`deductibleError`, ``, index)
              }
            }

            if (!policy.emptyLimits) {
              if (!policy.limit?.value?.length) {
                handlePolicyErrors(`limitError`, `Select Options`, index)
              } else {
                handlePolicyErrors(`limitError`, ``, index)
              }
            }
          }
        }
        handleErrors(`formSubmit`, true)
      },
      updateFormValues() {
        const _policyScoped = policyScoped.map((_policy) => ({
          code: _policy.code,
          deductibleOption: () => ({
            options: _policy?.deductibleOptions?.length
              ? _policy?.deductibleOptions.map((deductible) => ({
                  value: deductible.key,
                  label: deductible.name,
                }))
              : [],
            hasMore: false,
          }),
          deductible: _policy?.deductible
            ? {
                value: _policy?.deductible,
                label: _policy?.deductible,
              }
            : `Disabled`,
          deductibleError: ``,
          emptyDeductibles: _policy?.deductibleOptions?.length ? false : true,
          free: _policy.free,
          name: _policy.name,
          required: _policy.required,
          limit: _policy?.limits?.length
            ? { value: _policy?.limits, label: _policy?.limits }
            : `Disabled`,
          limitError: ``,
          limitsOptions: () => ({
            options: _policy?.limitsOptions?.length
              ? _policy?.limitsOptions.map((limit) => ({
                  value: limit.key,
                  label: limit.name,
                }))
              : [],
            hasMore: false,
          }),
          emptyLimits: _policy?.limitsOptions?.length ? false : true,
        }))

        setPolicyScoped(_policyScoped)
      },
    }))

    const handleErrors = (name, error) => {
      setErrors((prevState) => ({
        ...prevState,
        [name]: error,
      }))
    }
    const handlePolicyErrors = (name, val, ind) => {
      const newPolicyScope = policyScoped?.map((policy, index) => {
        if (index == ind) {
          policy[name] = val
        }
        return policy
      })
      setPolicyScoped(newPolicyScope)
    }
    const handleCoverageChange = useDebouncedCallback((name, value) => {
      setCoverages((preState) => ({
        ...preState,
        [name]: value,
      }))
    }, 30)

    const handlePolicyCoverages = useDebouncedCallback((name, value, index) => {
      const newPolicyScope = policyScoped?.map((i, ind) => {
        if (index === ind) {
          i[name] = value
        }
        return i
      })
      setPolicyScoped(newPolicyScope)
    }, 5)
    return (
      <CollapsableRef title="General Information/Coverages" isActive={true}>
        <div className="grid grid-cols-4 gap-x-6 gap-y-2 text-xs">
          <div className="relative">
            <InputField
              title="Agency Fee"
              placeholder="Agency Fee"
              type="number"
              value={coverages.agencyFee}
              errorMessage={errors.agencyFeeError}
              requiredValue={!coverages.agencyFee && selectedState !== `FL`}
              setValue={(e) =>
                handleCoverageChange(`agencyFee`, e.target.value)
              }
              disabled={selectedState === `FL`}
            />
          </div>

          <div className=" relative">
            <ReactSelectField
              title="Estimate Term"
              placeholder="Estimate Term"
              isMulti={false}
              value={coverages.estimateTerm}
              errorMessage={errors.estimateTermError}
              requiredValue={!coverages.estimateTerm?.value?.length}
              setValue={(e) => handleCoverageChange(`estimateTerm`, e)}
              loadOptions={loadBinaryOptions}
              isSearchable={true}
            />
          </div>
          <div className=" relative">
            <ReactSelectField
              title="Bump Limits"
              placeholder="Bump Limits"
              isMulti={false}
              value={coverages.bumpLimits}
              errorMessage={errors.bumpLimitError}
              requiredValue={!coverages.bumpLimits?.value?.length}
              setValue={(e) => handleCoverageChange(`bumpLimits`, e)}
              loadOptions={loadBumpLimits}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <DatePickerField
              title="Effective Date"
              placeholder="Effective Date"
              value={coverages.effectiveDate}
              errorMessage={errors.effectiveDateError}
              requiredValue={!coverages.effectiveDate}
              setValue={(e) => handleCoverageChange(`effectiveDate`, e)}
              minDate={new Date()}
            />
          </div>
          <div className=" relative">
            <ReactSelectField
              title="Policy Term"
              placeholder="Policy Term"
              isMulti={false}
              value={coverages.policyTerm}
              errorMessage={errors.policyTermError}
              requiredValue={!coverages.policyTerm?.value?.length}
              setValue={(e) => handleCoverageChange(`policyTerm`, e)}
              loadOptions={loadPolicyTerm}
              isSearchable={true}
            />
          </div>
          <div className=" relative">
            <ReactSelectField
              title="Payment Options"
              placeholder="Payment Options"
              isMulti={false}
              value={coverages.paymentOption}
              errorMessage={errors.paymentOptionError}
              requiredValue={!coverages.paymentOption?.value?.length}
              setValue={(e) => handleCoverageChange(`paymentOption`, e)}
              loadOptions={loadPaymentOption}
              isSearchable={true}
            />
          </div>
          <div className=" relative">
            <ReactSelectField
              title="Exclusion"
              placeholder="Exclusion"
              isMulti={false}
              value={coverages.exclusion}
              setValue={(e) => handleCoverageChange(`exclusion`, e)}
              loadOptions={loadExclusion}
              isSearchable={true}
            />
          </div>
          <div className=" relative">
            <ReactSelectField
              title="Allowed Credits"
              placeholder="Allowed Credits"
              isMulti={false}
              value={coverages.allowedCredits}
              errorMessage={errors.allowedCreditsError}
              requiredValue={!coverages.allowedCredits?.value?.length}
              setValue={(e) => handleCoverageChange(`allowedCredits`, e)}
              loadOptions={loadBinaryOptions}
              isSearchable={true}
            />
          </div>
          <div className=" relative">
            <ReactSelectField
              title="Non Owner"
              placeholder="Non Owner"
              isMulti={false}
              value={nonOwner}
              setValue={(e) => setNonOwner(e)}
              loadOptions={loadBinaryOptions}
              isSearchable={true}
            />
          </div>
          <div className=" relative">
            <ReactSelectField
              title="BroadForm"
              placeholder="BroadForm"
              isMulti={false}
              value={coverages.broadForm}
              setValue={(e) => handleCoverageChange(`broadForm`, e)}
              loadOptions={loadBinaryOptions}
              isSearchable={true}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-6 gap-y-2 text-xs mt-5">
          {policyScoped?.map(
            (policy, index) =>
              (!policy.emptyLimits || !policy.emptyDeductibles) && (
                <div className="" key={index}>
                  <div className="text-sm font-medium">
                    {policy.code}
                    {policy.required !== `NO` &&
                    (!policy.emptyLimits || !policy.emptyDeductibles)
                      ? ` *`
                      : ``}
                  </div>
                  <div className="gap-x-2">
                    {(!policy.emptyLimits || policy?.code === `CWAIV`) && (
                      <div className="flex-1 relative">
                        <ReactSelectField
                          placeholder="Limits"
                          errorMessage={policy.limitError}
                          value={
                            policy?.code === `CWAIV`
                              ? { label: policy?.rated, value: policy?.rated }
                              : policy.limit
                          }
                          setValue={(value) => {
                            handlePolicyCoverages(
                              policy?.code === `CWAIV` ? `rated` : `limit`,
                              value,
                              index,
                            )
                          }}
                          requiredValue={
                            policy.required === `YES` &&
                            !policy.emptyLimits &&
                            !policy?.limit?.value?.length
                          }
                          loadOptions={
                            policy?.code === `CWAIV`
                              ? loadBinaryOptions
                              : policy.limitsOptions
                          }
                          isSearchable={false}
                          disabled={
                            policy?.code === `CWAIV`
                              ? false
                              : policy.emptyLimits
                          }
                          noTitle={true}
                          cacheUniqs={policyScoped}
                        />
                      </div>
                    )}
                    {!policy.emptyDeductibles && (
                      <div className="flex-1 relative">
                        <ReactSelectField
                          placeholder="Deductibles"
                          value={policy.deductible}
                          errorMessage={policy.deductibleError}
                          setValue={(value) =>
                            handlePolicyCoverages(`deductible`, value, index)
                          }
                          requiredValue={
                            policy.required === `YES` &&
                            !policy.emptyDeductibles &&
                            !policy?.deductible?.value?.length
                          }
                          loadOptions={policy.deductibleOption}
                          isSearchable={false}
                          disabled={policy.emptyDeductibles}
                          noTitle={true}
                          cacheUniqs={policyScoped}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ),
          )}
        </div>
      </CollapsableRef>
    )
  },
)
