import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'

import React from 'react'
import { useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router'
import { Container, EditStyledButton, PageHeader } from 'App/Styled'
import { useDispatch } from 'react-redux'
import { Reload } from 'App/Components/Common/Reload'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { setPreferedCarriers, setPreferences } from 'App/Redux/actions'
import { ReactTable } from '../ReactTable'

export const Preference = () => {
  const { url } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    data: preferenceData,
    isLoading,
    error,
    refetch: getPreferencesApi,
    isFetching,
  } = useQuery(
    `PreferencesApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/quotes/prefs`).then((res) => {
        return res?.data?.data?.ratingPrefs
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (res) {
          dispatch(setPreferences(res))
          dispatch(setPreferedCarriers(res.carriers))
        }
      },
    },
  )
  return (
    <Container>
      <PageHeader>
        <div>Preference</div>
        <EditStyledButton onClick={() => history.push(`${url}/update`)}>
          <span>Edit</span>
          <FontAwesomeIcon
            icon={faPen}
            className="text-white mt-0.5"
            size="sm"
          />
        </EditStyledButton>
      </PageHeader>

      <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md">
        {isLoading || isFetching ? (
          <div className="text-center p-6">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => getPreferencesApi()} />
        ) : preferenceData?.ratingPrefs?.length ? (
          <p className=" text-center p-6">No Data Found</p>
        ) : (
          <div className="rounded-md overflow-hidden py-4">
            <div className="flex-1 mx-2 my-4 rounded">
              <div className="grid grid-cols-12 px-4 py-2">
                <div className="col-span-6 text-xl">Carrier Info</div>
              </div>
              <hr />
              <div className="flex px-4">
                <div className="flex-1">
                  <CardField title="State Name" value={preferenceData?.state} />
                  <CardField
                    title="Allow Credit Score Name"
                    value={preferenceData?.allowCreditScore}
                  />
                  <CardField
                    title="Agency Fee"
                    value={preferenceData?.agencyFee?.toString()}
                  />
                </div>
                <div className="flex-1">
                  <CardField
                    title="Bump Limits"
                    value={preferenceData?.bumpLimits}
                  />
                  <CardField
                    title="Estimate Term"
                    value={preferenceData?.estimateTerm}
                  />
                  <CardField
                    title="Payment Option"
                    value={preferenceData?.paymentOption}
                  />
                </div>
              </div>
            </div>
            <div className="flex-1 mx-2 shadow-lg rounded">
              <Collapsable title="Carriers">
                <ReactTable
                  carriersdata={preferenceData?.carriers}
                  isDisable={true}
                  width={`w-full`}
                  height={`h-full`}
                />
              </Collapsable>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}
