import React, { useCallback, useEffect, useState } from 'react'
import { Layout } from 'App/Components/Layout'
import { VideoCall } from '../VideoCall'
import { useDispatch, useSelector } from 'react-redux'
import { setIsUserSignInWithGmail } from 'App/Redux/actions'
import { io } from 'socket.io-client'
import { AMS_BACKEND_API } from 'App/Config'
import { getAccessToken } from 'App/Services'
export let newSocket
export const MainLayout = ({ children }) => {
  const accessToken = useSelector(
    ({
      user: {
        user: { accessToken },
      },
    }) => accessToken,
  )

  const [socket, setSocket] = useState(null)

  const { user, preferredLocation } = useSelector(({ user }) => user)
  const dispatch = useDispatch()
  // Token renewal
  // useEffect(() => {
  //   const refreshAccessToken = async () => {
  //     return await getAccessToken()
  //   }
  //   if (
  //     new Date().getTime() >
  //     new Date(localStorage.getItem(`accessTokenExpiry`)).getTime()
  //   )
  //     refreshAccessToken()
  //   // setupSocket()
  // }, [])

  // setting up socket connection
  const setupSocket = useCallback(() => {
    if (user && !socket?.connected && accessToken) {
      newSocket = io.connect(AMS_BACKEND_API, {
        auth: {
          token: accessToken ? accessToken : ``,
          locationId: preferredLocation?._id,
        },
        reconnection: true,
      })

      newSocket?.on(`connect_error`, async (err) => {
        if (err?.message) {
          if (err.message === `Authentication Failed!`) {
            await getAccessToken()
          }
          setTimeout(() => {
            if (newSocket) {
              newSocket.auth.token = localStorage.getItem(`accessToken`)
              newSocket.auth.locationId = preferredLocation?._id
              newSocket?.connect()
            }
          }, 1000)
        }
      })

      newSocket?.on(`connect`, () => {
        console.log(`socket is connected `)
        setSocket(newSocket)
      })

      newSocket?.on(`disconnect`, () => {
        console.log(`socket is disconnected `)
        setSocket(null)
      })

      // Gmail Events
      newSocket?.emit(`checkGoogleSignin`, { userId: user?.profile?.sub })

      newSocket?.on(`GoogleSigninStatus`, (feedback) => {
        dispatch(setIsUserSignInWithGmail(feedback))
      })
    }
  }, [accessToken])

  // calling socket functions
  // useEffect(() => {
  //   if (accessToken) setupSocket()
  // }, [])
  useEffect(() => {
    if (accessToken) {
      setupSocket()
    } else {
      newSocket = null
    }
  }, [accessToken])

  return (
    <>
      <Layout>{children}</Layout>
      <VideoCall />
    </>
  )
}
