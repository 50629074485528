/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { InputField } from 'App/Components/Common/InputField'
import { regEx } from 'App/Config'
import { PublicStyledButton } from './style'
import { brandLogo } from 'App/Assets/index'
import { useMutation } from 'react-query'
import { AxiosInstance } from 'App/Config'
import { AMS_BACKEND_API } from 'App/Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { NotificationManager } from 'react-notifications'
import { PasswordInput } from 'App/Components/Common/PasswordInput'
import axios from 'axios'
import { useHistory } from 'react-router'

export const SetPassword = () => {
  const history = useHistory()
  const passwordInput = useRef(null)
  const confirmPasswordInput = useRef(null)

  const search = useLocation().search
  const userId = new URLSearchParams(search).get(`user`)
  const token = new URLSearchParams(search).get(`token`)

  const [password, setPassword] = useState(``)
  const [confirmPassword, setConfirmPassword] = useState(``)

  const [errors, setErrors] = useState({
    formSubmit: false,
    passwordError: ``,
    confirmPasswordError: ``,
  })

  const handleConfirmPassword = (e) => {
    const { value } = e.target
    if (value !== password) {
      handleErrors(`confirmPasswordError`, `Password not match`)
      handleErrors(`formSubmit`, false)
    } else {
      handleErrors(`confirmPasswordError`, ``)
      handleErrors(`formSubmit`, false)
    }
    setConfirmPassword(value)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const { isLoading, mutate } = useMutation(
    async (payload) =>
      await axios
        .patch(`${AMS_BACKEND_API}/api/users/${userId}/set-password`, payload, {
          headers: {
            Accept: `application/json, text/plain, */*`,
            'Content-Type': `application/json`,
            authorization: token,
          },
        })
        .then((res) => {
          if (res.hasError) {
            NotificationManager.error(`Something Went Wrong`)
          } else {
            return res?.data
          }
        })
        .catch(() => {
          NotificationManager.error(`Something Went Wrong`)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        if (!res.hasError) {
          NotificationManager.success(`Password Successfully Set`)
          history.push(`/login`)
        }
      },
    },
  )

  const ValidateForm = () => {
    if (!passwordInput?.current?.value?.length) {
      handleErrors(`passwordError`, `Add Password`)
    } else if (passwordInput?.current?.value?.length < 8) {
      handleErrors(`passwordError`, `Password Length must be atleast 8`)
    } else {
      handleErrors(`passwordError`, ``)
    }

    if (!confirmPasswordInput?.current?.value?.length) {
      handleErrors(`confirmPasswordError`, `Add Confirm Password`)
    } else {
      if (confirmPassword !== password) {
        handleErrors(`confirmPasswordError`, `Password not match`)
      } else {
        handleErrors(`confirmPasswordError`, ``)
      }
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.passwordError.length &&
      !errors.confirmPasswordError.length
    ) {
      mutate({
        password: password,
      })
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  return (
    <>
      <img
        src={brandLogo}
        alt="Logo will go here"
        className="text-left w-60 h-36"
      />
      <div className="mt-1">
        <PasswordInput
          type="password"
          title="Password"
          placeholder="Enter Password"
          errorMessage={errors.passwordError}
          reference={passwordInput}
          setValue={(e) => {
            setPassword(e.target.value)
          }}
        />
      </div>
      <div className="mt-1">
        <PasswordInput
          type="password"
          title="Confirm Password"
          placeholder="Enter Confirm Password"
          errorMessage={errors.confirmPasswordError}
          reference={confirmPasswordInput}
          setValue={(value) => {
            handleConfirmPassword(value)
          }}
        />
      </div>
      <div className="flex justify-center">
        <div className="text-center w-96 my-4">
          <PublicStyledButton onClick={ValidateForm}>
            <div className="flex gap-2 text-white">
              <span>Submit</span>
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </PublicStyledButton>
        </div>
      </div>
    </>
  )
}
