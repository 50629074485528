import React from 'react'
import { GlobalDashboard } from './GlobalDashboard'
export const Dashboard = () => {
  // const [activeTab, setActiveTab] = useState(1)
  // const Tabs = [
  //   {
  //     name: `Gloabal`,
  //     number: 1,
  //   },
  //   {
  //     name: `Assessment`,
  //     number: 2,
  //   },
  // ]

  return (
    <div className="w-full mt-4">
      <div className=" overflow-auto hide-scroller hide-scroller-firefox">
        <div className="px-4 mb-2">
          <>
            <div>
              <GlobalDashboard />
            </div>
          </>
        </div>
      </div>
    </div>
  )
}
