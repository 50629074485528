import { Checkbox } from 'App/Components/Common/Checkbox'
import { RadioButton } from 'App/Components/Common/RadionButton'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
// import { TextArea } from 'App/Components/Common/TextArea'
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { loadMailServices, loadMailSizes, loadMailType } from 'App/Services'
import { RichTextEditor } from 'App/Components/Common/RichTextEditor'

export const Mail = forwardRef(({ getMailValue }, ref) => {
  const [mailOptions, setMailOptions] = useState({
    mailType: ``,
    mailSize: ``,
    mailService: ``,
    colorPrint: false,
    frontSideValue: ``,
    backSideValue: ``,
  })
  const [mailSide, setMailSide] = useState(`FrontSide`)
  const [errors, setErrors] = useState({
    formSubmit: false,
    mailTypeError: ``,
    mailSizeError: ``,
    mailServiceError: ``,
    colorPrintError: ``,
    frontSideValueError: ``,
    backSideValueError: ``,
  })

  const [frontSide, setFrontSide] = useState(``)
  const [backSide, setBackSide] = useState(``)

  useImperativeHandle(ref, () => ({
    validateForm() {
      if (!mailOptions?.mailType?.value?.length) {
        handleErrors(`mailTypeError`, `Select Mail Type`)
      } else {
        handleErrors(`mailTypeError`, ``)
      }

      if (mailOptions?.mailType && mailOptions.mailType?.value === `POSTCARD`) {
        if (!mailOptions?.mailSize?.value?.length) {
          handleErrors(`mailSizeError`, `Select Mail Size`)
        } else {
          handleErrors(`mailSizeError`, ``)
        }
      } else {
        handleErrors(`mailSizeError`, ``)
      }

      if (!mailOptions?.mailService?.value?.length) {
        handleErrors(`mailServiceError`, `Select Service`)
      } else {
        handleErrors(`mailServiceError`, ``)
      }

      if (!mailOptions?.mailType || mailOptions?.frontSideValue?.length <= 7) {
        handleErrors(`frontSideValueError`, `Enter Front Side Value`)
      }
      //  else if (
      //   mailOptions?.mailType?.value === `POSTCARD` &&
      //   !frontSide?.length
      // ) {
      //   handleErrors(`frontSideValueError`, `Enter Front Side Value`)
      // }
      else {
        handleErrors(`frontSideValueError`, ``)
      }

      // if (!mailOptions?.mailType || !backSide?.length) {
      //   handleErrors(`backSideValueError`, `Enter Value`)
      // } else

      if (
        !mailOptions?.mailType ||
        (mailOptions?.mailType?.value === `POSTCARD` &&
          mailOptions?.backSideValue?.length <= 7)
      ) {
        handleErrors(`backSideValueError`, `Enter Back Side Value`)
      } else {
        handleErrors(`backSideValueError`, ``)
      }

      // if (mailOptions.mailType === `LETTER` && !frontSide?.length) {
      //   handleErrors(`frontSideValueError`, `Enter Value`)
      // } else {
      //   handleErrors(`frontSideValueError`, ``)
      // }

      handleErrors(`formSubmit`, true)
    },
  }))

  useEffect(() => {
    setMailOptions((prevState) => ({
      ...prevState,
      [`frontSideValue`]: frontSide,
    }))
  }, [frontSide])

  useEffect(() => {
    setMailOptions((prevState) => ({
      ...prevState,
      [`backSideValue`]: backSide,
    }))
  }, [backSide])

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.mailTypeError.length &&
      !errors.mailSizeError.length &&
      !errors.mailServiceError.length &&
      !errors.colorPrintError.length &&
      !errors.frontSideValueError.length &&
      !errors.backSideValueError.length
    ) {
      getMailValue(mailOptions)
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  return (
    <div>
      <div className="mt-4">
        <span className="text-sm bg-red-500 text-white px-2 py-1  rounded-md">
          Mail Service
        </span>
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Select Mail Type"
              placeholder="Select Option"
              value={mailOptions?.mailType}
              setValue={(value) =>
                setMailOptions((prevState) => ({
                  ...prevState,
                  [`mailType`]: value,
                }))
              }
              errorMessage={errors.mailTypeError}
              loadOptions={loadMailType}
              isSearchable={false}
              isMulti={false}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Select Services"
              placeholder="Select Service"
              value={mailOptions?.mailService}
              setValue={(value) =>
                setMailOptions((prevState) => ({
                  ...prevState,
                  [`mailService`]: value,
                }))
              }
              errorMessage={errors.mailServiceError}
              loadOptions={loadMailServices}
              isSearchable={false}
              isMulti={false}
            />
          </div>
          <div className="flex-1 relative ">
            {mailOptions?.mailType?.value === `POSTCARD` && (
              <ReactSelectField
                title="Select Size"
                placeholder="Select Option"
                value={mailOptions?.mailSize}
                setValue={(value) =>
                  setMailOptions((prevState) => ({
                    ...prevState,
                    [`mailSize`]: value,
                  }))
                }
                errorMessage={errors.mailSizeError}
                loadOptions={loadMailSizes}
                isSearchable={false}
                isMulti={false}
              />
            )}
          </div>
        </div>
        <div className="flex justify-start gap-6 pt-2 ">
          <div className="flex-1 relative">
            <Checkbox
              title="Color"
              name="colorPrint"
              value={mailOptions.colorPrint}
              errorMessage={errors.colorPrintError}
              setValue={() =>
                setMailOptions((prevState) => ({
                  ...prevState,
                  [`colorPrint`]: !mailOptions.colorPrint,
                }))
              }
            />
          </div>
          <div className="flex-1 relative">
            <RadioButton
              title="Front"
              name="mailSide"
              checked={mailSide === `FrontSide` ? true : false}
              errorMessage={errors.mailSideError}
              setValue={() => setMailSide(`FrontSide`)}
            />
          </div>
          <div className="flex-1 relative">
            {mailOptions?.mailType?.value === `POSTCARD` && (
              <RadioButton
                title="Back"
                name="mailSide"
                checked={mailSide === `BackSide` ? true : false}
                disabled={mailOptions?.mailType?.value === `LETTER`}
                errorMessage={errors.mailSideError}
                setValue={() => setMailSide(`BackSide`)}
              />
            )}
          </div>
          <div className="flex-1 relative"></div>
        </div>

        <div className="flex-1 relative ">
          {mailSide === `FrontSide` && (
            <>
              <RichTextEditor
                setEditorValue={setFrontSide}
                defaultValue={frontSide}
              />
              {errors.frontSideValueError.length ||errors.backSideValueError.length  ? (
                <span className="text-red-500">
                     {errors.backSideValueError.length?`Back side value is required`:`Front side value is required`}
                </span>
              ) : null}
            </>
          )}
          {mailSide === `BackSide` && (
            <>
              <RichTextEditor
                setEditorValue={setBackSide}
                defaultValue={backSide}
              />
              {errors.backSideValueError.length || errors.frontSideValueError.length ? (
                <span className="text-red-500">
                  {errors.backSideValueError.length?`Back side value is required`:`Front side value is required`}
                </span>
              ) : null}
            </>
          )}
        </div>
        <div className="flex-1 relative "></div>
      </div>
    </div>
  )
})

Mail.displayName = `Mail`
