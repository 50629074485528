import moment from 'moment-timezone'

const dateFormat = (date) => {
  return date ? moment(date)
    // .tz(`America/Los_Angeles`)
    .format(`MM/DD/YYYY`) : ``
}

// extract time in 12 hours format
export const timeFormatUTC = (dateString) => {
  return dateString ? moment(dateString)
    // .tz(`America/Los_Angeles`)
    .format(`HH:mm A`) : ``
}
export const timeFormatPST = (dateString) => {
  return dateString ? moment(dateString)
    // .tz(`America/Los_Angeles`)
    .format(`MM/DD/YYYY HH:mm A`) : ``
}

export default dateFormat


