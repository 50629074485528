import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export function useBreadCrumbs() {
  const { _id: franciseId, name: franchiseName } = useSelector(
    ({ franchise }) => franchise?.currentFranchise,
  )

  const { _id: roleId, name: roleName } = useSelector(
    ({ systemRole }) => systemRole?.currentRole,
  )
  const {
    _franchise: franchiseCustomId,
    _id: locationId,
    name: locationName,
    _trxId: paymentHistory,
  } = useSelector(({ location }) => location?.currentLocation)

  const { _id: agentId, lastName: agentName } = useSelector(
    ({ agent }) => agent?.currentAgent,
  )

  const { _id: customerId, firstName: customerName } = useSelector(
    ({ customer }) => customer?.currentCustomer,
  )
  const { _id: contactId, firstName: contactName } = useSelector(
    ({ asset }) => asset?.customerContacts,
  )

  const { _id: carrierId, name: carrierName } = useSelector(
    ({ carrier }) => carrier?.currentCarrier,
  )

  const { _id: groupCarrierId, name: groupCarrierName } = useSelector(
    ({ carrier }) =>
      carrier?.currentGroupCarrier ? carrier?.currentGroupCarrier?.group : {},
  )

  const { _id: credentialsId, _carrierId: credentialsName } = useSelector(
    ({ credentials }) => credentials?.currentCredential,
  )
  const currentCredentialGroup = useSelector(
    ({ credentials }) => credentials?.currentCredentialGroup,
  )

  const { _id: goalId, title: goalName } = useSelector(
    ({ monthlyGoal }) => monthlyGoal?.currentGoal ?? {},
  )

  const { _id: paymentTemplateId, name: paymentTemplateName } = useSelector(
    ({ paymentTemplate }) => paymentTemplate?.paymentTemplate,
  )

  const { _id: quoteId, _quoteId: quoteCustomId } = useSelector(
    ({ myquotes }) => myquotes?.currentQuote,
  )

  const { _id: leadId, _leadId: leadCustomId } = useSelector(
    ({ lead }) => lead?.currentLead,
  )

  const { _id: carDealerId, businessName: carDealerName } = useSelector(
    ({ carDealer }) => carDealer?.currentDealer,
  )
  const dataTransactionId = useSelector(
    ({ dataTransactions }) => dataTransactions?.activeActivityTab?._id,
  )
  const dataTransactionName = useSelector(
    ({ dataTransactions }) =>
      dataTransactions?.activeActivityTab?.data?.primaryInsured?.lastName,
  )

  const campaignGroupId = useSelector(
    ({ campaignGroup }) => campaignGroup?.currentCampaign?._id,
  )

  const campaignGroupName = useSelector(
    ({ campaignGroup }) => campaignGroup?.currentCampaign?.name,
  )

  const { _id: policyId, _policyId: policyCustomId } = useSelector(
    ({ policy }) => policy?.currentPolicy,
  )

  const { _id: paymentId, _invoiceId: paymentCustomId } = useSelector(
    ({ payment }) => payment?.currentPayment,
  )
  const { _id: claimId, claimId: claimCustomId } = useSelector(
    ({ claim }) => claim?.currentClaim,
  )

  const { _id: ticketId } = useSelector(({ tickets }) => tickets?.currentTicket)
  const { _id: taskId } = useSelector(({ tasks }) => tasks?.currentTask)
  const { _id: ticketCategoryId } = useSelector(
    ({ ticketCategory }) => ticketCategory?.currentCategory,
  )
  const {
    dueDate: weeklyId,
    _assessmentId: assessmentId,
    _id: assessId,
  } = useSelector(({ assessment }) => assessment?.currentAssessment)

  const { _evaluationId: evaluationId, _id: evaluateId } = useSelector(
    ({ assessment }) => assessment?.currentEvaluation,
  )
  const { currentAssessmentTemplate } = useSelector(
    ({ assessment }) => assessment,
  )
  const { _id: requestId, _requestId } = useSelector(
    ({ policyRequest }) => policyRequest.currentPolicyRequest || {},
  )
  const generalNotificationId = useSelector(
    (state) => state.generalNotification.generalNotificationId,
  )

  const [breadCrumbs, setBreadCrumbs] = useState([])
  const history = useHistory()

  const Paths = {
    '/dashboard': {
      path: `/dashboard`,
      title: `Dashboard`,
    },
    '/profile': {
      path: `/profile`,
      title: `profile`,
    },
    '/profile/update': {
      path: `/profile/update`,
      title: `update`,
    },
    '/communications': {
      path: `/communications`,
      title: `communications`,
    },

    '/admin/system-roles': {
      path: `/admin/system-roles`,
      title: `system roles`,
    },
    '/admin/system-roles/add': {
      path: `/admin/system-roles/add`,
      title: `add`,
    },
    [`/admin/system-roles/${roleId}`]: {
      path: `/admin/system-roles/${roleId}`,
      title: roleName,
    },
    [`/admin/system-roles/${roleId}/update`]: {
      path: `/admin/system-roles/${roleId}/update`,
      title: `update`,
    },
    '/admin/franchises': {
      path: `/admin/franchises`,
      title: `franchises`,
    },
    '/admin/franchises/add': {
      path: `/admin/franchises/add`,
      title: `add`,
    },
    '/tickets/category': {
      path: `/tickets/category`,
      title: `category`,
    },

    '/assessment/teams': {
      path: `/assessment/teams`,
      title: `teams`,
    },
    [`/admin/franchises/${franciseId}`]: {
      path: `/admin/franchises/${franciseId}`,
      title: franchiseName,
    },
    [`/admin/franchises/${franciseId}/update`]: {
      path: `/admin/franchises/${franciseId}/update`,
      title: `update`,
    },

    [`/admin/franchises/${franciseId}/location/${locationId}`]: {
      path: `/admin/franchises/${franciseId}/location/${locationId}`,
      title: locationName,
    },
    [`/admin/franchises/${franciseId}/location/add`]: {
      path: `/admin/franchises/${franciseId}/location/add`,
      title: `add`,
    },
    [`/admin/franchises/${franciseId}/location/${locationId}/update`]: {
      path: `/admin/franchises/${franciseId}/location/${locationId}/update`,
      title: `update`,
    },

    [`/admin/franchises/${franciseId}/agents/${agentId}`]: {
      path: `/admin/franchises/${franciseId}/agents/${agentId}`,
      title: agentName,
    },
    [`/admin/franchises/${franciseId}/agents/add`]: {
      path: `/admin/franchises/${franciseId}/agents/add`,
      title: `add`,
    },
    [`/admin/franchises/${franciseId}/agents/${agentId}/update`]: {
      path: `/admin/franchises/${franciseId}/agents/${agentId}/update`,
      title: `update`,
    },
    [`/customers/${customerId}`]: {
      path: `/customers/${customerId}`,
      title: customerName,
    },
    [`/customers/${customerId}/policies/${policyId}`]: {
      path: `/customers/${customerId}/policies/${policyId}`,
      title: policyCustomId,
    },
    [`/customers/${customerId}/policies/${policyId}/update`]: {
      path: `/customers/${customerId}/policies/${policyId}/update`,
      title: `Update`,
    },
    [`/customers/${customerId}/payments/${paymentId}`]: {
      path: `/customers/${customerId}/payments/${paymentId}`,
      title: paymentCustomId,
    },

    [`/customers/${customerId}/payments/${paymentId}/add`]: {
      path: `/customers/${customerId}/payments/${paymentId}/add`,
      title: `add`,
    },
    [`/customers/${customerId}/payments/${paymentId}/updateInvoice`]: {
      path: `/customers/${customerId}/payments/${paymentId}/update`,
      title: `update`,
    },
    [`/customers/${customerId}/addPayment`]: {
      path: `/customers/${customerId}/addPayment`,
      title: `add`,
    },
    [`/customers/${customerId}/update`]: {
      path: `/customers/${customerId}/update`,
      title: `update`,
    },
    [`/customers/${customerId}/contact/add`]: {
      path: `/customers/${customerId}/contact/add`,
      title: `Add Contact`,
    },
    [`/customers/${customerId}/contact/${contactId}`]: {
      path: `/customers/${customerId}/contact/${contactId}`,
      title: contactName,
    },
    [`/customers/${customerId}/contact/${contactId}/update`]: {
      path: `/customers/${customerId}/contact/${contactId}/update`,
      title: `update`,
    },
    '/admin/locations': {
      path: `/admin/locations`,
      title: `Locations`,
    },
    '/admin/locations/add': {
      path: `/admin/locations/add`,
      title: `add`,
    },
    [`/admin/locations/${locationId}`]: {
      path: `/admin/locations/${locationId}`,
      title: locationName,
    },
    [`/admin/locations/${locationId}/update`]: {
      path: `/admin/locations${locationId}/update`,
      title: `update`,
    },
    '/admin/agents': {
      path: `/admin/agents`,
      title: `Agents`,
    },
    '/admin/agents/add': {
      path: `/admin/agents/add`,
      title: `Add`,
    },
    [`/admin/agents/${agentId}`]: {
      path: `/admin/agents/${agentId}`,
      title: agentName,
    },
    [`/admin/agents/${agentId}/update`]: {
      path: `/admin/agents/${agentId}/update`,
      title: `update`,
    },
    '/carriers': {
      path: `/carriers`,
      title: `carrier`,
    },
    '/carriers/add': {
      path: `/carriers/add`,
      title: `add`,
    },
    [`/carriers/${carrierId}`]: {
      path: `/carriers/${carrierId}`,
      title: carrierName,
    },
    [`/carriers/${carrierId}/update`]: {
      path: `/carriers/${carrierId}/update`,
      title: `update`,
    },
    '/group-carriers': {
      path: `/group-carriers`,
      title: `Group Carrier`,
    },
    '/group-carriers/add': {
      path: `/group-carriers/add`,
      title: `add`,
    },
    [`/group-carriers/${groupCarrierId}`]: {
      path: `/group-carriers/${groupCarrierId}`,
      title: groupCarrierName,
    },
    [`/group-carriers/${groupCarrierId}/update`]: {
      path: `/group-carriers/${groupCarrierId}/update`,
      title: `update`,
    },

    '/admin/credentials': {
      path: `/admin/credentials`,
      title: `credentials`,
    },
    '/admin/credentials/add': {
      path: `/admin/credentials/add`,
      title: `add`,
    },
    [`/admin/credentials/${credentialsId}`]: {
      path: `/admin/credentials/${credentialsId}`,
      title: credentialsName?.label ?? `credentialsName`,
    },
    [`/admin/credentials/${credentialsId}/update`]: {
      path: `/admin/credentials/${credentialsId}/update`,
      title: `update`,
    },

    '/admin/group-credentials': {
      path: `/admin/group-credentials`,
      title: `Group Carrier`,
    },
    '/admin/group-credentials/add': {
      path: `/admin/group-credentials/add`,
      title: `add`,
    },
    [`/admin/group-credentials/${currentCredentialGroup?._id}`]: {
      path: `/admin/group-credentials/${currentCredentialGroup?._id}`,
      title: currentCredentialGroup?.name,
    },
    [`/admin/group-credentials/${currentCredentialGroup?._id}/update`]: {
      path: `/admin/group-credentials/${currentCredentialGroup?._id}/update`,
      title: `update`,
    },

    '/admin/monthlygoals': {
      path: `/admin/monthlygoals`,
      title: `Goals`,
    },
    '/admin/monthlygoals/add': {
      path: `/admin/monthlygoals/add`,
      title: `add`,
    },
    [`/admin/monthlygoals/${goalId}`]: {
      path: `/admin/monthlygoals/${goalId}`,
      title: goalName,
    },
    [`/admin/monthlygoals/${goalId}/update`]: {
      path: `/admin/credentials/${goalId}/update`,
      title: `update`,
    },
    '/admin/designation': {
      path: `/admin/designation`,
      title: `designation`,
    },
    '/admin/paymenttemplates': {
      path: `/admin/paymenttemplates`,
      title: `PAYMENT TEMPLATE`,
    },
    '/admin/general-notifications': {
      path: `/admin/general-notifications`,
      title: `General Notifications`,
    },
    [`/admin/general-notifications/${generalNotificationId}`]: {
      path: `/admin/general-notifications/${generalNotificationId}`,
      title: `Detail`,
    },
    '/admin/paymenttemplates/add': {
      path: `/admin/paymenttemplates/add`,
      title: `add`,
    },
    [`/admin/paymenttemplates/${paymentTemplateId}`]: {
      path: `/admin/paymenttemplates/${paymentTemplateId}`,
      title: paymentTemplateName,
    },

    [`/admin/paymenttemplates/${paymentTemplateId}/update`]: {
      path: `/admin/paymenttemplates/${paymentTemplateId}/update`,
      title: `update`,
    },
    '/admin/claims': {
      path: `/admin/claims`,
      title: `Claims`,
    },
    [`/admin/claims/${claimId}`]: {
      path: `/admin/claims/${claimId}`,
      title: claimCustomId,
    },
    '/transaction/transactions-history': {
      path: `/transaction/transactions-history`,
      title: `HISTORY`,
    },
    '/admin/cs-logs': {
      path: `/admin/cs-logs`,
      title: `Logs`,
    },
    '/reports/payment-records': {
      path: `/reports/payment-records`,
      title: `Payment Records`,
    },
    [`/transaction/transactions-history/${locationId}`]: {
      path: `/transaction/transactions-history/${locationId}`,
      title: paymentHistory,
    },

    '/customers': {
      path: `/customers`,
      title: `customers`,
    },
    '/broadcasts/templates': {
      path: `/broadcasts/templates`,
      title: `Templates`,
    },
    '/broadcasts/campaigns': {
      path: `/broadcasts/campaigns`,
      title: `campaign`,
    },
    [`/broadcasts/campaigns/groupsdetail/${campaignGroupId}`]: {
      path: `/broadcasts/campaigns/groupsdetail/${campaignGroupId}`,
      title: campaignGroupName ? campaignGroupName : `N/A`,
    },
    '/raters/myquotes': {
      path: `/raters/myquotes`,
      title: `quotes`,
    },
    [`/raters/myquotes/${quoteId}`]: {
      path: `/raters/myquotes/${quoteId}`,
      title: quoteCustomId,
    },
    '/raters/preferences': {
      path: `/raters/preferences`,
      title: `preferences`,
    },
    '/raters/preferences/update': {
      path: `/raters/preferences/update`,
      title: `update`,
    },
    '/raters/ratingtemplates': {
      path: `/raters/ratingtemplates`,
      title: `RATING TEMPLATE`,
    },
    '/raters/ratingtemplates/add': {
      path: `/raters/ratingtemplates/add`,
      title: `add`,
    },
    '/crm/cardealers': {
      path: `/crm/cardealers`,
      title: `CAR DEALERS`,
    },
    '/crm/cardealers/add': {
      path: `/crm/cardealers/add`,
      title: `add`,
    },
    [`/crm/cardealers/${carDealerId}`]: {
      path: `/crm/cardealers/${carDealerId}`,
      title: carDealerName,
    },
    [`/crm/cardealers/${carDealerId}/update`]: {
      path: `/crm/cardealers/${carDealerId}/update`,
      title: `update`,
    },
    '/crm/leads': {
      path: `/crm/leads`,
      title: `Leads`,
    },

    [`/crm/leads/${leadId}`]: {
      path: `/crm/leads/${leadId}`,
      title: leadCustomId,
    },
    '/crm/dataTransactions': {
      path: `/crm/dataTransactions`,
      title: `DATA TRANSACTION`,
    },
    [`/crm/dataTransactions/${dataTransactionId}`]: {
      path: `/crm/dataTransactions/${dataTransactionId}`,
      title: dataTransactionName,
    },
    '/raters/quoteform': {
      path: `/raters/quoteform`,
      title: `QUOTE FORM`,
    },
    [`/raters/myquotes/${quoteId}/rerate`]: {
      path: `/raters/myquotes/${quoteId}`,
      title: `${quoteCustomId}`,
    },

    '/raters/quickestimate': {
      path: `/raters/quickestimate`,
      title: `QUICK ESTIMATE`,
    },
    '/reports/daily-ledger': {
      path: `/reports/daily-ledger`,
      title: `DAILY LEDGER`,
    },
    '/transaction/inspection': {
      path: `/transaction/inspection`,
      title: `CHECk`,
    },

    '/reports/policy-reports': {
      path: `/reports/policy-reports`,
      title: `Policy Reports`,
    },
    '/ticketscenter': {
      path: `/ticketscenter`,
      title: `Tickets Center`,
    },
    '/ticketscenter/add': {
      path: `/ticketscenter/add`,
      title: `Add`,
    },
    '/ticketscenter/update': {
      path: `/ticketscenter/update`,
      title: `update`,
    },
    [`/ticketscenter/${ticketId}`]: {
      path: `/ticketscenter/${ticketId}`,
      title: `Preview`,
    },
    '/tickets/allcategory': {
      path: `/tickets/allcategory`,
      title: `Categories`,
    },
    '/tickets/allcategory/add': {
      path: `/tickets/allcategory/add`,
      title: `Add`,
    },
    [`/tickets/category/${ticketCategoryId}`]: {
      path: `/tickets/category/${ticketCategoryId}`,
      title: `Preview`,
    },

    '/messenger': {
      path: `/messenger`,
      title: `Messenger`,
    },
    '/taskmanager': {
      path: `/taskmanager`,
      title: `Task Manager`,
    },
    '/taskmanager/add': {
      path: `/taskmanager/add`,
      title: `Add`,
    },
    [`/taskmanager/${taskId}`]: {
      path: `/taskmanager/${taskId}`,
      title: `Preview`,
    },
    '/assessment/analytics': {
      path: `/assessment/analytics`,
      title: `Assessment DashBoard`,
    },
    '/assessment/weeks': {
      path: `/assessment/weeks`,
      title: `Assessments`,
    },
    [`/assessment/weeks/super/${weeklyId}`]: {
      path: `/assessment/weeks/super/${weeklyId}`,
      title: `${weeklyId}`,
    },
    [`/assessment/weeks/${assessId}`]: {
      path: `/assessment/weeks/${assessId}`,
      title: `${assessmentId}`,
    },
    [`/assessment/weeks/${assessId}/evaluations/${evaluateId}`]: {
      path: `/assessment/weeks/${assessId}/evaluations/${evaluateId}`,
      title: `${evaluationId}`,
    },
    [`/assessment/weeks/${assessId}/evaluations/${evaluateId}/record`]: {
      path: `/assessment/weeks/${assessId}/evaluations/${evaluateId}/record`,
      title: `Print`,
    },
    '/assessment/template': {
      path: `/assessment/template`,
      title: `Assessment Template`,
    },
    '/assessment/template/add': {
      path: `/assessment/template/add`,
      title: `Add`,
    },
    [`/assessment/template/${currentAssessmentTemplate?._id}`]: {
      path: `/assessment/template/${currentAssessmentTemplate?._id}`,
      title: `${currentAssessmentTemplate?.templateName}`,
    },

    [`/assessment/template/${currentAssessmentTemplate?._id}/update`]: {
      path: `/assessment/template/${currentAssessmentTemplate?._id}/update`,
      title: `Update`,
    },
    '/other-customers': {
      path: `/other-customers`,
      title: `Other Customers`,
    },
    [`/other-customers/${customerId}`]: {
      path: `/other-customers/${customerId}`,
      title: customerName,
    },
    [`/other-customers/${customerId}/payments/${paymentId}/add`]: {
      path: `/other-customers/${customerId}/payments/${paymentId}/add`,
      title: `add`,
    },
    [`/other-customers/${customerId}/addPayment`]: {
      path: `/other-customers/${customerId}/addPayment`,
      title: `add`,
    },
    [`/other-customers/${customerId}/update`]: {
      path: `/customers/${customerId}/update`,
      title: `update`,
    },
    [`/policy`]: {
      path: `/policy`,
      title: `Polices`,
    },
    [`/policy/${policyId}`]: {
      path: `/policy/${policyId}`,
      title: policyCustomId,
    },
    [`/policy/${policyId}/update`]: {
      path: `/policy/${policyId}/update`,
      title: `Update`,
    },
    [`/policies/requests`]: {
      path: `/policies/requests`,
      title: `Policies Requests`,
    },
    [`/policies/requests/${requestId}`]: {
      path: `/policies/requests/${requestId}`,
      title: _requestId,
    },
  }

  useEffect(() => {
    setBreadCrumbs([])
    const navButton = history.location.pathname.split(`/`)
    for (let i = 1; i < navButton.length; i++) {
      const matchRoute = navButton.slice(0, i + 1).join(`/`)
      if (Paths[matchRoute]?.path) {
        setBreadCrumbs((prev) => [
          ...prev,
          {
            title: Paths[matchRoute]?.title,
            path: Paths[matchRoute]?.path,
          },
        ])
      }
    }
  }, [
    history.location.pathname,
    franciseId,
    franchiseName,
    locationId,
    locationName,
    customerId,
    credentialsId,
    currentCredentialGroup,
    carrierId,
    groupCarrierId,
    roleId,
    ticketId,
    agentId,
    franchiseCustomId,
    paymentTemplateId,
    goalId,
    quoteId,
    quoteCustomId,
    leadId,
    leadCustomId,
    carDealerId,
    carDealerName,
    dataTransactionId,
    policyId,
    campaignGroupId,
    policyCustomId,
    paymentCustomId,
    claimCustomId,
    evaluateId,
    weeklyId,
    assessId,
    evaluateId,
    taskId,
    currentAssessmentTemplate,
    ticketCategoryId,
    requestId,
    generalNotificationId,
    contactId,
  ])
  return [breadCrumbs, setBreadCrumbs]
}
