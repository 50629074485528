import {
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TableBody, TableCell, TableHeader, TableRow } from '@windmill/react-ui'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Reload } from 'App/Components/Common/Reload'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { Spinner } from 'App/Components/Common/Spinner'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { loadOffset } from 'App/Services/General/loadOffset'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce/lib'
import { UpdateCategory } from '../UpdateCategory'
import { ReactModal } from 'App/Components/Common/Modal'
import { AddTicketCategory } from '../AddCategory'
import { useSelector } from 'react-redux'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useHistory } from 'react-router-dom'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'
const tableColumns = [
  { label: `Category Name`, value: `label` },
  { label: `Created At`, value: `createdAt` },
  { label: `Created By`, value: `creator.label` },
]

export const AllTicketsCategory = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [updateCategory, setUpdateCategory] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)
  const [categoryUpdated, setCategoryUpdated] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [curruentCategory, setCurruentCategory] = useState(``)
  const history = useHistory()
  const { sub: currentUser } = useSelector(({ user }) => user?.user?.profile)
  const {
    isLoading,
    data: CategoriesData,
    refetch: getAllCategories,
    isFetching,
    error,
  } = useQuery(
    `allCategoriesApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/ticket-category?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomSearching(false)
        if (res) {
          setCategoryUpdated(false)
        }
      },
    },
  )

  const {
    mutate: deleteCategory,
    isLoading: deletingCategory,
    isError: deletionError,
  } = useMutation(
    async (categoryId) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/ticket-category/${categoryId}`,
      )
        .then((res) => {
          NotificationManager.success(`Deleted successfuly`)
          res.data && getAllCategories()
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.message.includes(
                `Category In Use, Can't Delete`,
              )
            ) {
              NotificationManager.error(`Category In Use, Can't Delete`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteCategory(id)
  }
  const updateHandler = (category, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    setUpdateCategory(true)
    setCurruentCategory(category?.value)
  }
  useEffect(() => {
    if (!customSearching) {
      getAllCategories()
    }
  }, [sort, currentPage, isOffset])
  useEffect(() => {
    if (categoryUpdated) {
      getAllCategories()
    }
  }, [categoryUpdated])

  const callCategoryQuery = useDebouncedCallback(() => {
    getAllCategories()
  }, 700)

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  return (
    <Container>
      {deletionError && (
        <ReactNotification action="error" message={`Something went wrong`} />
      )}

      {addModal && (
        <ReactModal
          title="Add New Category"
          closeModal={() => setAddModal(!addModal)}
        >
          <AddTicketCategory
            closeModal={() => setAddModal(!addModal)}
            categoryUpdated={() => setCategoryUpdated(true)}
          />
        </ReactModal>
      )}

      {/* update Category */}
      {updateCategory && (
        <ReactModal
          title="Update Catgory"
          closeModal={() => setUpdateCategory(false)}
        >
          <UpdateCategory
            categoryId={curruentCategory}
            closeModal={() => setUpdateCategory(false)}
            categoryUpdated={() => setCategoryUpdated(true)}
          />
        </ReactModal>
      )}

      <Spinner loading={isLoading || isFetching || deletingCategory} />
      {/* update Category ends here */}
      <PageHeader bgcolor="bg-white">
        <div>Tickets Category</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          {/* <Toggle value={isMasterFrenchise} setValue={setIsMasterFrenchise} /> */}
          <div className="text-end ">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callCategoryQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `ticket-category:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton onClick={() => setAddModal(!addModal)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (isLoading || isFetching || deletingCategory) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllCategories()} />
        ) : (
          <>
            <div className="overflow-x-auto rounded-t-md mt-1">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow className="hover:bg-gray-100 cursor-pointer ">
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className=" cursor-pointer select-none text-sm flex">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort === `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    <TableCell className="select-none text-sm float-right">
                      {permissionGranted([
                        `ticket-category:*`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) ||
                      permissionGranted([
                        `ticket-category:update`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) ||
                      permissionGranted([
                        `ticket-category:delete`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ])
                        ? `Action`
                        : ``}
                    </TableCell>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !CategoriesData?.categories?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        No Category Found
                      </td>
                    </tr>
                  ) : (
                    CategoriesData?.categories?.map((category, index) => (
                      <TableRow
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer  "
                        onClick={() => {
                          if (
                            permissionGranted([
                              `ticket-category:*`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])
                          ) {
                            history.push(`/tickets/category/${category.value}`)
                          }
                        }}
                      >
                        <td className="px-4 py-2 cursor-pointer capitalize truncate text-sm">
                          {category?.label}
                        </td>
                        <td className="px-4 py-2 cursor-pointer capitalize truncate text-sm">
                          {dateFormat(category?.createdAt)}
                        </td>
                        <td className="px-4 py-2 cursor-pointer capitalize truncate text-sm">
                          {category?.creator?.label}
                        </td>
                        {/* Actions TD */}

                        <td className="px-4 py-3 w-auto text-sm z-0 flex float-right text-gray-400">
                          {(permissionGranted([
                            `ticket-category:update`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ]) ||
                            permissionGranted([
                              `ticket-category:*`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])) && (
                            <span
                              data-tip={
                                currentUser === category?.creator?.value
                                  ? `Update`
                                  : `Not Allowed`
                              }
                              className="tooltip "
                            >
                              <FontAwesomeIcon
                                className={`mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  ${
                                  currentUser === category?.creator?.value
                                    ? `cursor-pointer`
                                    : `cursor-not-allowed`
                                }`}
                                icon={faEdit}
                                onClick={(e) => {
                                  currentUser === category?.creator?.value
                                    ? updateHandler(category, e)
                                    : e &&
                                      e.stopPropagation &&
                                      e.stopPropagation() &&
                                      history.push(`${url}`)
                                }}
                              />
                            </span>
                          )}
                          {(permissionGranted([
                            `ticket-category:delete`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ]) ||
                            permissionGranted([
                              `ticket-category:*`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])) && (
                            <span
                              data-tip={
                                currentUser === category?.creator?.value
                                  ? `Delete`
                                  : `Not Allowed`
                              }
                              className="tooltip"
                            >
                              <FontAwesomeIcon
                                className={`mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 ${
                                  currentUser === category?.creator?.value
                                    ? `cursor-pointer`
                                    : `cursor-not-allowed`
                                }`}
                                icon={faTrash}
                                size="1x"
                                onClick={(e) =>
                                  currentUser === category?.creator?.value
                                    ? handleDelete(category?.value, e)
                                    : e &&
                                      e.stopPropagation &&
                                      e.stopPropagation() &&
                                      history.push(`${url}`)
                                }
                              />
                            </span>
                          )}
                        </td>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={CategoriesData?.totalPages}
                totalRecords={CategoriesData?.totalRecords}
                curerntPageRecords={CategoriesData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
