import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadAgents = async (
  search,
  loadedOptions,
  { page, additionalFilters },
) => {
  try {
    const {
      data: { data },
    } = await AxiosInstance.post(
      `${AMS_BACKEND_API}/api/users/v2/list/?search={"query":"${
        search.length ? search : ``
      }"}&page=${page}&offset=10`,
      additionalFilters,
    )
    if (data) {
      return {
        options: data?.agents,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
          additionalFilters,
        },
      }
    }
  } catch (error) {
    if (error.response?.data?.hasError) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      }
    }
  }
}
