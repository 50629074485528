import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API } from 'App/Config/backendApis'
import { AxiosInstance, allowedSystemPermissions } from 'App/Config'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  loadAllCarriers,
  loadAgents,
  permissionGranted,
  // isSuperUser,
} from 'App/Services'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import { useDebouncedCallback } from 'use-debounce'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

export const UpdateCredential = () => {
  const history = useHistory()
  const {
    params: { credentialId },
  } = useRouteMatch()
  const { currentCredential } = useSelector(({ credentials }) => credentials)
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)
  const { preferredLocation: _locations } = useSelector(({ user }) => user)

  const [credentials, setCredentials] = useState({
    carrierId: ``,
    name: ``,
    producerCode: ``,
    userName: ``,
    password: ``,
    url: `https://`,
    comments: ``,
    users: ``,
    franchises: ``,
  })
  const [credentialNameValidating, setCredentialNameValidating] =
    useState(false)
  // const [assignedToSelf, setAssignedToSelf] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [isLoadingName, setIsLoadingName] = useState(false)
  const [errors, setErrors] = useState({
    formSubmit: false,
    nameError: ``,
    carrierIdError: ``,
    producerCodeError: ``,
    userNameError: ``,
    passwordError: ``,
    urlError: ``,
  })
  useEffect(() => {
    // @currentCredential stored in redux
    if (currentCredential) {
      setCredentials((preState) => ({
        ...preState,
        carrierId: currentCredential?._carrierId,
        producerCode: currentCredential?.producerCode,
        userName: currentCredential?.authData?.username,
        password: currentCredential?.authData?.password,
        name: currentCredential?.name,
        url: currentCredential?.url,
        comments: currentCredential?.comments,
        users: currentCredential?.users,
        franchises: currentCredential?.franchises[0],
      }))
    }
  }, [currentCredential])

  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/credentials/${credentialId}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          if (res.data) {
            NotificationManager.success(`Operation successful`)
            res.data && history.push(`/admin/credentials/${credentialId}`)
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            } else {
              NotificationManager.error(`Something went Wrong!`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const loadFranchises = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/list/?search=${
        search && search
      }&page=${page}&offset=10&state=${currentCredential?._carrierId?.state}`,
    )
    if (data) {
      return {
        options: data.franchises,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const ValidateForm = () => {
    if (!credentials?.carrierId?.value?.length) {
      handleErrors(`carrierIdError`, `Enter Option`)
    } else {
      handleErrors(`carrierIdError`, ``)
    }

    if (!credentials?.producerCode?.length) {
      handleErrors(`producerCodeError`, `Enter Producer`)
    } else {
      handleErrors(`producerCodeError`, ``)
    }
    if (!credentials.name?.length) {
      handleErrors(`nameError`, `Select Name`)
    } else {
      if (errors.nameError !== `Name Already Exists`) {
        handleErrors(`nameError`, ``)
      }
    }

    if (
      currentCredential?.authData?.username &&
      !credentials?.userName?.length
    ) {
      handleErrors(`userNameError`, `Enter Name`)
    } else {
      handleErrors(`userNameError`, ``)
    }

    if (
      currentCredential?.authData?.password &&
      !credentials?.password?.length
    ) {
      handleErrors(`passwordError`, `Enter Password`)
    } else {
      handleErrors(`passwordError`, ``)
    }

    if (credentials?.url === `https://` || !credentials?.url?.length) {
      handleErrors(`urlError`, `Enter Url`)
    } else {
      handleErrors(`urlError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.carrierIdError.length &&
      !errors.producerCodeError.length &&
      !errors.userNameError.length &&
      !errors.passwordError.length &&
      !errors.nameError.length &&
      !errors.urlError.length
    ) {
      mutate(preparePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const preparePayload = () => {
    let data = {}
    if (credentials.carrierId?.value !== currentCredential?._carrierId.value) {
      data._carrierId = credentials.carrierId.value
    }

    if (credentials.producerCode !== currentCredential?.producerCode) {
      data.producerCode = credentials.producerCode
    }
    if (credentials.name !== currentCredential?.name) {
      data.name = credentials.name
    }
    if (
      currentCredential?.authData?.username &&
      (credentials.userName !== currentCredential?.authData?.username ||
        credentials.password !== currentCredential?.authData?.password)
    ) {
      data.authData = {}
      data.authData.userName = credentials.userName
      data.authData.password = credentials.password
    }

    if (credentials.url !== currentCredential?.url) {
      data.url = credentials.url
    }

    if (credentials.comments !== currentCredential?.comments) {
      data.comments = credentials.comments
    }

    // Update Users
    let isUserUpdated
    if (credentials.users.length === currentCredential?.users.length) {
      isUserUpdated = credentials.users.some((eachUser, idx) => {
        return eachUser.value !== currentCredential?.users[idx].value
      })
    } else {
      isUserUpdated = true
    }

    if (isUserUpdated) {
      data.users = credentials?.users?.map((user) => user?.value)
    }

    if (
      credentials?.franchises?.value !== currentCredential?.franchises[0]?.value
    ) {
      data.franchises = [credentials?.franchises?.value]
    }

    // let isFranchisesUpdated
    // if (
    //   credentials?.franchises?.length === currentCredential?.franchises?.length
    // ) {
    //   isFranchisesUpdated = credentials.franchises?.some(
    //     (eachFranchise, idx) => {
    //       return (
    //         eachFranchise.value !== currentCredential?.franchises[idx].value
    //       )
    //     },
    //   )
    // } else {
    //   isFranchisesUpdated = true
    // }

    // if (isFranchisesUpdated) {
    //   data.franchises = credentials.franchises?.map((user) => user.value)
    // }

    return data
  }

  const ValidateDuplicateCredentialName = async (payload) => {
    setIsLoadingName(true)

    try {
      const res = await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/validate/credential-payloads`,
        payload,
      )
      setIsLoadingName(false)
      return res?.data
    } catch (error) {
      setIsLoadingName(false)
      return error.response.data
    }
  }
  const validateCredentialName = useDebouncedCallback(async (value) => {
    setCredentialNameValidating(true)
    const response = await ValidateDuplicateCredentialName({ name: value })

    if (response?.hasError) {
      handleErrors(`nameError`, `Name Already Exists`)
    } else {
      handleErrors(`nameError`, ``)
    }
    setCredentialNameValidating(false)
  }, 2500)
  return (
    <Container>
      <PageHeader>
        <div>Update Credentials</div>
      </PageHeader>
      {updationError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div
        className={`p-3 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="grid grid-cols-4 gap-4">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Carrier "
              placeholder="Select Carrier"
              errorMessage={errors.carrierIdError}
              value={credentials.carrierId}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  carrierId: e,
                }))
              }}
              isMulti={false}
              loadOptions={loadAllCarriers}
              isSearchable={true}
              disabled={true}
            />
          </div>

          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Producer Code *"
              placeholder="Enter value"
              value={credentials.producerCode}
              errorMessage={errors.producerCodeError}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  producerCode: e.target.value,
                }))
              }}
              disabled={
                !permissionGranted([`super-admin:*`, `credential-master:*`])
              }
            />
          </div>
          {permissionGranted([
            `credential-master:*`,
            `password:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && (
            <>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="User Name *"
                  placeholder="Enter Name"
                  value={credentials.userName}
                  errorMessage={errors.userNameError}
                  setValue={(e) => {
                    setCredentials((preState) => ({
                      ...preState,
                      userName: e.target.value,
                    }))
                  }}
                  disabled={
                    !permissionGranted([`super-admin:*`, `credential-master:*`])
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type={passwordShown ? `text` : `password`}
                  title="Password *"
                  placeholder="Enter Password"
                  value={credentials.password}
                  errorMessage={errors.passwordError}
                  setValue={(e) => {
                    setCredentials((preState) => ({
                      ...preState,
                      password: e.target.value,
                    }))
                  }}
                  disabled={
                    !permissionGranted([`super-admin:*`, `credential-master:*`])
                  }
                />
                {credentials?.password && (
                  <FontAwesomeIcon
                    icon={!passwordShown ? faEye : faEyeSlash}
                    className="absolute top-9 right-3 cursor-pointer w-4 dark:text-stone-800"
                    onClick={() => setPasswordShown(!passwordShown)}
                  />
                )}
              </div>
            </>
          )}

          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Credential Name *"
              placeholder="Enter Credential Name"
              value={credentials.name ?? ``}
              errorMessage={errors.nameError}
              validating={credentialNameValidating}
              setValue={(e) => {
                e.target.value && validateCredentialName(e.target.value)
                setCredentials((preState) => ({
                  ...preState,
                  name: e.target.value,
                }))
              }}
              disabled={
                !permissionGranted([`super-admin:*`, `credential-master:*`])
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Url *"
              placeholder="Enter Url"
              errorMessage={errors.urlError}
              value={credentials.url}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  url: e.target.value,
                }))
              }}
              disabled={
                !permissionGranted([`super-admin:*`, `credential-master:*`])
              }
            />
          </div>
          {/* {permissionGranted([`manager:*`, `corporate-manager:*`]) && (
            <div className="flex-1 relative mt-6">
              <Checkbox
                type="checkbox"
                name="assignedToSelf"
                title="Assigned to Self *"
                value={assignedToSelf}
                // errorMessage={errors.isActiveError}
                setValue={() => setAssignedToSelf((prevState) => !prevState)}
              />
            </div>
          )} */}
          <div className="flex-1 relative"></div>
          <div className="flex-1 relative"></div>
        </div>
        {/* Locations basic form */}

        <div className="flex gap-6 my-1">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Comments"
              placeholder="Enter Comments"
              value={credentials.comments}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  comments: e.target.value,
                }))
              }}
              disabled={
                !permissionGranted([`super-admin:*`, `credential-master:*`])
              }
            />
          </div>
        </div>
        <div className="flex gap-6 my-1">
          {permissionGranted([`super-admin:*`, `credential-master:*`]) ? (
            <>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Franchises"
                  placeholder="Select Franchises"
                  value={credentials?.franchises}
                  setValue={(value) => {
                    if (value.value !== credentials?.franchises?.value) {
                      setCredentials((preState) => ({
                        ...preState,
                        users: [],
                      }))
                    }
                    setCredentials((preState) => ({
                      ...preState,
                      franchises: value,
                    }))
                  }}
                  // isMulti={true}
                  loadOptions={loadFranchises}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Users"
                  placeholder="Select Users"
                  value={credentials.users}
                  setValue={(value) => {
                    setCredentials((preState) => ({
                      ...preState,
                      users: value,
                    }))
                  }}
                  isMulti={true}
                  loadOptions={credentials.franchises?.value && loadAgents}
                  isSearchable={true}
                  additionalFilters={{
                    franchiseId: credentials.franchises?.value,
                    // ...(!permissionGranted([
                    //   `corporate-manager`,
                    //   `credential-master:*`,
                    // ]) && {
                    //   locations: [_locations?._id],
                    // }),

                    permissions: allowedSystemPermissions?.credential,
                  }}
                  disabled={!credentials?.franchises?.value}
                  cacheUniqs={credentials?.franchises}
                />
              </div>
            </>
          ) : (
            <div className="flex-1 relative">
              <ReactSelectField
                title="Users"
                placeholder="Select Users"
                value={credentials.users}
                setValue={(value) => {
                  setCredentials((preState) => ({
                    ...preState,
                    users: value,
                  }))
                }}
                isMulti={true}
                loadOptions={loadAgents}
                isSearchable={true}
                additionalFilters={{
                  franchiseId: _franchise?._id,
                  ...(!permissionGranted([
                    `corporate-manager`,
                    `credential-master:*`,
                  ]) && {
                    locations: [_locations?._id],
                  }),

                  permissions: allowedSystemPermissions?.credential,
                }}
              />
            </div>
          )}
        </div>
        {/* Location Address starts from here */}
        <div className="flex justify-center">
          <div className="text-center w-96 my-5">
            <SubmitButton onClick={ValidateForm} disabled={isLoadingName}>
              Update Credential
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
