import React, { forwardRef, useEffect, useState } from 'react'
import { defaultImage } from 'App/Assets'
import {
  // LEAFLET_URL,
  regEx,
} from 'App/Config'
import {
  // loadZipCodes,
  ValidateDuplicateAgentEmail,
} from 'App/Services'
import { MaskField } from 'App/Components/Common/MaskField'
import { InputField } from 'App/Components/Common/InputField'
// import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  Image,
  Label,
  //  SecondaryHeading
} from 'App/Styled'
// import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import {
  loadGenderOptions,
  // ValidateZip,
  fileResizer,
} from 'App/Services'
import { useDebouncedCallback } from 'use-debounce/lib'
import { useImperativeHandle } from 'react'

export const AddManager = forwardRef(
  ({ getManagerPayload, agentLoadingHandler }, ref) => {
    //   const user = useSelector(({ user: { user } }) => user)
    const [agent, setAgent] = useState({
      img: ``,
      firstName: ``,
      middleName: ``,
      lastName: ``,
      gender: ``,
      phone: ``,
      officePhone: ``,
      ext: ``,
      fax: ``,
      email: ``,
    })

    // const [address, setAddress] = useState({
    //   unitNo: ``,
    //   streetNo: ``,
    //   city: ``,
    //   zip: ``,
    //   state: ``,
    // })

    // const [coordinates, setCoordinates] = useState({
    //   lat: 0,
    //   lng: 0,
    // })
    const [errors, setErrors] = useState({
      formSubmit: false,
      firstNameError: ``,
      lastNameError: ``,
      genderError: ``,
      phoneError: ``,
      officePhoneError: ``,
      extError: ``,
      faxError: ``,
      emailError: ``,
      streetNoError: ``,
      cityError: ``,
      zipError: ``,
      stateError: ``,
      coordinatesError: false,
    })

    const [agentEmailValidating, setAgentEmailValidating] = useState(false)
    const [agentPhoneValidating, setAgentPhoneValidating] = useState(false)
    // const [agentZipValidating, setAgentZipValidating] = useState(false)

    const payloadData = () => {
      const data = {
        firstName: agent.firstName,
        lastName: agent.lastName,
        gender: agent.gender.value,

        contactInfo: {
          email: agent.email,
        },
      }

      if (agent?.phone?.length) {
        data.contactInfo.phone = agent?.phone
      }

      if (agent?.ext?.length) {
        data.contactInfo.officePhoneExt = agent.ext
      }

      if (agent.img?.length) {
        data.img = agent.img
      }

      if (agent.middleName.length) {
        data.middleName = agent.middleName
      }

      if (agent.officePhone.length) {
        data.contactInfo.officePhone = agent.officePhone
      }
      if (agent.fax.length) {
        data.contactInfo.fax = agent.fax
      }

      return data
    }

    useEffect(() => {
      if (
        errors.formSubmit &&
        !errors.firstNameError.length &&
        !errors.lastNameError.length &&
        !errors.genderError.length &&
        !errors.emailError.length &&
        !errors.extError.length &&
        !errors.phoneError.length &&
        !errors.officePhoneError.length &&
        !errors.faxError.length
        // &&
        // !errors.zipError.length &&
        // !errors.streetNoError.length &&
        // !errors.cityError.length &&
        // !errors.stateError.length
        //  &&
        // !errors.coordinatesError
      ) {
        // getManagerPayload(payloadData())
        getManagerPayload.current = payloadData()
        //   mutate(payloadData())
        handleErrors(`formSubmit`, false)
      } else if (errors?.formSubmit) {
        // getManagerPayload(null)
        getManagerPayload.current = null
      }
    }, [errors])

    const handleErrors = (name, error) => {
      setErrors((prevState) => ({
        ...prevState,
        [name]: error,
      }))
    }

    const handleAgentInput = useDebouncedCallback((name, value) => {
      if (name === `phone`) {
        validateAgentPhone(value)
      }
      setAgent((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }, 5)

    const handleEmailInput = (e) => {
      const { name, value } = e.target
      setAgent((prevState) => ({
        ...prevState,
        [name]: value,
      }))
      if (value) {
        validateAgentEmail(value)
      }
    }

    const validateAgentEmail = useDebouncedCallback(async (value) => {
      if (!value) {
        return
      }
      setAgentEmailValidating(true)
      agentLoadingHandler(true)
      const response = await ValidateDuplicateAgentEmail({ email: value })
      if (response?.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`emailError`, `Email Already Exists`)
      } else {
        if (!regEx.test(value.toLowerCase())) {
          handleErrors(`formSubmit`, false)
          handleErrors(`emailError`, `Enter Valid Email`)
        } else {
          handleErrors(`formSubmit`, false)
          handleErrors(`emailError`, ``)
        }
      }
      setAgentEmailValidating(false)
      agentLoadingHandler(false)
    }, 2500)

    const validateAgentPhone = useDebouncedCallback(async (value) => {
      if (!value) {
        return
      }
      setAgentPhoneValidating(true)
      agentLoadingHandler(true)
      const response = await ValidateDuplicateAgentEmail({ phone: value })
      if (response?.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`phoneError`, `Phone Already Exists`)
      } else {
        handleErrors(`formSubmit`, false)
        handleErrors(`phoneError`, ``)
      }
      setAgentPhoneValidating(false)
      agentLoadingHandler(false)
    }, 2500)

    // const handleAddressInput = async (e, name) => {
    //   if (name) {
    //     setAgentZipValidating(true)
    //     const data = await ValidateZip(e?.value)
    //     if (data) {
    //       setAgentZipValidating(false)
    //       const res = data.address
    //       setAddress((prevState) => ({
    //         ...prevState,
    //         [`city`]: res.city,
    //         [`state`]: res.state,
    //         [`zip`]: e,
    //       }))

    //       //setting coordinates
    //       setCoordinates((prevState) => ({
    //         ...prevState,
    //         [`lat`]: res.lat,
    //         [`lng`]: res.long,
    //       }))
    //     }
    //   } else {
    //     const { name, value } = e.target
    //     setAddress((prevState) => ({
    //       ...prevState,
    //       [name]: value,
    //     }))
    //   }
    // }

    // const ChangeView = ({ center, zoom }) => {
    //   useMap().setView(center, zoom)
    //   return null
    // }

    const handleLogo = async (file) => {
      const base64 = await fileResizer(file[0])
      setAgent((prevState) => ({
        ...prevState,
        [`img`]: base64,
      }))
    }

    useImperativeHandle(ref, () => ({
      validateForm: () => {
        if (!agent.firstName.length) {
          handleErrors(`firstNameError`, `Enter First name`)
        } else {
          handleErrors(`firstNameError`, ``)
        }

        if (!agent.lastName.length) {
          handleErrors(`lastNameError`, `Enter Last name`)
        } else {
          handleErrors(`lastNameError`, ``)
        }

        if (!agent.gender?.value?.length) {
          handleErrors(`genderError`, `Select Gender`)
        } else {
          handleErrors(`genderError`, ``)
        }

        if (!agent.email.length) {
          handleErrors(`emailError`, `Enter Email`)
        } else if (agent.email.length) {
          if (
            errors.emailError.length &&
            errors.emailError.includes(`Email Already Exists`)
          ) {
            handleErrors(`emailError`, `Email Already Exists`)
          } else {
            if (!regEx.test(agent.email.toLowerCase())) {
              handleErrors(`emailError`, `Enter Valid Email`)
            } else {
              handleErrors(`emailError`, ``)
            }
          }
        } else {
          handleErrors(`emailError`, ``)
        }

        if (agent?.ext?.length) {
          if (isNaN(agent?.ext)) {
            handleErrors(`extError`, `Ext Should be a number`)
          } else if (agent?.ext?.length !== 4) {
            handleErrors(`extError`, `Ext should be 4 digit`)
          } else {
            handleErrors(`extError`, ``)
          }
        } else {
          handleErrors(`extError`, ``)
        }

        // if (!agent.phone.length) {
        //   handleErrors(`phoneError`, `Enter Phone No`)
        // } else
        if (agent.phone?.length && agent.phone?.length < 12) {
          handleErrors(`phoneError`, `Enter Valid Phone No`)
        } else {
          handleErrors(`phoneError`, ``)
        }

        if (agent?.officePhone?.length && agent?.officePhone?.length < 12) {
          handleErrors(`officePhoneError`, `Enter Valid Phone No`)
        } else {
          handleErrors(`officePhoneError`, ``)
        }

        if (agent.fax.length && agent.fax.length < 12) {
          handleErrors(`faxError`, `Enter Valid Fax No`)
        } else {
          handleErrors(`faxError`, ``)
        }

        // if (!address.zip?.length && !Object.keys(address.zip).length) {
        //   handleErrors(`zipError`, `Select Zip Code`)
        // } else {
        //   handleErrors(`zipError`, ``)
        // }

        // if (!address.streetNo.length) {
        //   handleErrors(`streetNoError`, `Enter Street`)
        // } else {
        //   handleErrors(`streetNoError`, ``)
        // }

        // if (!address.city.length) {
        //   handleErrors(`cityError`, `Enter City`)
        // } else {
        //   handleErrors(`cityError`, ``)
        // }

        // if (!address.state.length) {
        //   handleErrors(`stateError`, `Enter State`)
        // } else {
        //   handleErrors(`stateError`, ``)
        // }

        handleErrors(`formSubmit`, true)
      },
    }))

    return (
      <div className="border-2 border-gray-100 shadow-sm rounded-md relative">
        <div className={`p-2 bg-white`}>
          {/* Locations basic form */}
          <div className="flex justify-center items-center my-0 py-3 ">
            <div className="w-50">
              <Label>
                <Image
                  alt="fImage"
                  className="items-center my-0 py-3"
                  src={agent.img ? agent.img : defaultImage}
                />

                <input
                  type="file"
                  onChange={(e) => handleLogo(e.target.files)}
                  className="hidden"
                  accept="image/*"
                />
              </Label>
            </div>
          </div>

          <div className="grid grid-cols-4 gap-4">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="First Name *"
                placeholder="First Name"
                errorMessage={errors.firstNameError}
                value={agent.firstName}
                setValue={(e) => handleAgentInput(`firstName`, e.target.value)}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Middle Name"
                placeholder="Middle Name"
                value={agent.middleName}
                setValue={(e) => handleAgentInput(`middleName`, e.target.value)}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Last Name *"
                placeholder="Last Name"
                errorMessage={errors.lastNameError}
                value={agent.lastName}
                setValue={(e) => handleAgentInput(`lastName`, e.target.value)}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Gender *"
                placeholder="Select Gender"
                errorMessage={errors.genderError}
                value={agent.gender}
                setValue={(value) => {
                  setAgent((prevState) => ({
                    ...prevState,
                    [`gender`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadGenderOptions}
                isSearchable={false}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="email"
                title="Email *"
                name="email"
                placeholder="Email"
                errorMessage={errors.emailError}
                validating={agentEmailValidating}
                value={agent.email}
                setValue={(e) => handleEmailInput(e)}
              />
            </div>

            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Phone"
                placeholder="Phone"
                errorMessage={errors.phoneError}
                validating={agentPhoneValidating}
                value={agent.phone}
                setValue={(e) => handleAgentInput(`phone`, e.target.value)}
              />
            </div>
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Office Phone"
                placeholder="Office Phone"
                errorMessage={errors.officePhoneError}
                value={agent.officePhone}
                setValue={(e) =>
                  handleAgentInput(`officePhone`, e.target.value)
                }
              />
            </div>
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Extension"
                placeholder="Extension"
                errorMessage={errors.extError}
                value={agent.ext}
                setValue={(e) => handleAgentInput(`ext`, e.target.value)}
                mask="9999"
              />
            </div>
            <div className="flex-1 relative">
              <MaskField
                type="text"
                title="Fax"
                placeholder="Fax"
                errorMessage={errors.faxError}
                value={agent.fax}
                setValue={(e) => handleAgentInput(`fax`, e.target.value)}
              />
            </div>
          </div>

          {/* Location Address starts from here */}
          {/* <SecondaryHeading>Address</SecondaryHeading>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street *"
              name="streetNo"
              placeholder="Street"
              errorMessage={errors.streetNoError}
              value={address.streetNo}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              placeholder="Select Zip"
              errorMessage={errors.zipError}
              value={address.zip}
              setValue={
                (e) => handleAddressInput(e, `zip`)
                // setAddress((prevState) => ({
                //   ...prevState,
                //   zip: e,
                // }))
              }
              loadOptions={loadZipCodes}
              isSearchable={true}
              cacheUniqs={agent.franchise}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              name="unitNo"
              placeholder="Unit No"
              value={address.unitNo}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>

          <div className="flex-1 relative">
            <InputField
              type="text"
              title="City *"
              name="city"
              placeholder="City"
              errorMessage={errors.cityError}
              validating={agentZipValidating}
              disabled={true}
              value={address.city}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="State *"
              name="state"
              placeholder="State"
              errorMessage={errors.stateError}
              validating={agentZipValidating}
              disabled={true}
              value={address.state}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
        </div>
        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView center={[coordinates.lat, coordinates.lng]} zoom={3} />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div> */}
          {/* <div className="flex justify-center">
          <div className="text-center w-96 my-4">
            <SubmitButton onClick={ValidateForm} disabled={agentZipValidating}>
              <div>Create Agent</div>
            </SubmitButton>
          </div>
        </div> */}
        </div>
      </div>
    )
  },
)

// used due to forward ref
AddManager.displayName = `AddManager`
