import React, { useEffect, useState } from 'react'
import { Spinner } from 'App/Components/Common/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
// import { AQS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
// import { useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useQuery } from 'react-query'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { useDispatch } from 'react-redux'
import { setCurrentCampaign } from 'App/Redux/actions'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'

const tableColumns = [
  { label: `BroadCaster`, value: `_broadcaster.firstName` },
  { label: `Name`, value: `name` },
  { label: `Status`, value: `status` },
  { label: `Channel`, value: `channel` },
  { label: `Scope`, value: `Scope` },
  { label: `Initialize Date`, value: `initializeDate` },
  { label: `Recipient`, value: `totalRecipients` },
  { label: `Progress`, value: `progress` },
]

export const AllCampaignGroups = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const dispatch = useDispatch()
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)

  //  sorting and filtering states
  const [sort, setSort] = useState(``)

  const [searchQuery, setSearchQuery] = useState(``)

  const [quoteUpdated, setQuoteUpdated] = useState(false)
  //Offset
  const [isOffset, setIsOffset] = useState(``)

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (quoteUpdated) {
      getAllCampaignGroups()
      setQuoteUpdated(false)
    }
  }, [quoteUpdated])

  const callAgentsQuery = useDebouncedCallback(() => {
    getAllCampaignGroups()
  }, 700)

  // const searchFilters = () => {
  //   let data = {
  //     query: ``,
  //   }
  //   if (searchQuery.length) {
  //     data.query = searchQuery
  //   }

  //   if (dateRange[0].startDate) {
  //     data.dateRange = `${moment(dateRange[0].startDate).format(
  //       `YYYY-MM-DD`,
  //     )},${moment(dateRange[0].endDate).format(`YYYY-MM-DD`)}`
  //   }
  //   return JSON.stringify(data)
  // }

  const progressAggregate = (score, total) => {
    return (score / total) * 100
  }

  const {
    data: campaignGroupsData,
    refetch: getAllCampaignGroups,
    isLoading,
    isFetching: fetchingCampaign,
    error: fetchingCampaignError,
  } = useQuery(
    `CampaignGroups`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/broadcasts/all?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllCampaignGroups()
    }
  }, [sort, currentPage, isOffset])

  return (
    <Container>
      <Spinner loading={fetchingCampaign} />
      <PageHeader>
        <div>My Campaigns</div>
        <div className="flex items-center ">
          <div className="text-end ">
            <div className="flex">
              <div className="py-1 px-2">
                <ReactSelectField
                  placeholder="10"
                  value={isOffset}
                  setValue={(e) => {
                    setCurrentPage(1)
                    setIsOffset(e)
                  }}
                  isMulti={false}
                  loadOptions={loadOffset}
                />
              </div>
              <SearchInputField
                type="text"
                placeholder="Type here..."
                value={searchQuery}
                onEnter={(e) => {
                  if (e.key === `Enter`) {
                    setCurrentPage(1)
                    setCustomSearching(true)
                    callAgentsQuery()
                  }
                }}
                setValue={(e) => {
                  setSearchQuery(e.target.value)
                }}
                disabled={fetchingCampaign}
              />
            </div>
          </div>
        </div>
      </PageHeader>

      <>
        <div
          className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
            (isLoading || fetchingCampaign) && ` opacity-30 pointer-events-none`
          }`}
        >
          {fetchingCampaignError ? (
            <Reload refetch={() => getAllCampaignGroups()} />
          ) : (
            <>
              <div className="overflow-x-auto mt-10 rounded-t-md">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className="cursor-pointer select-none text-sm flex truncate">
                              <div
                                onClick={() => {
                                  handleSorting(item.value)
                                }}
                                className={`flex ${
                                  item.value === sort ? `text-red-500` : ``
                                }`}
                              >
                                {item.label}
                              </div>
                              <div
                                className="ml-2 px-1 rounded-sm cursor-pointer flex"
                                onClick={() => handleSorting(`-` + item?.value)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    sort.includes(`-`) &&
                                    sort == `-` + item?.value
                                      ? faSortUp
                                      : faSortDown
                                  }
                                  size="lg"
                                  className={`${
                                    sort.includes(`-`) &&
                                    sort == `-` + item?.value
                                      ? `text-red-500`
                                      : `-mt-1.5`
                                  }`}
                                />
                              </div>
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {campaignGroupsData?.broadcasts?.length != 0 ? (
                      campaignGroupsData?.broadcasts?.map((campaign, index) => (
                        <TableRow
                          key={index}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            history.push(`${url}/groupsdetail/${campaign?._id}`)
                            dispatch(setCurrentCampaign(campaign))
                          }}
                        >
                          <td className="px-4 py-0.5 text-sm max-w-sm capitalize truncate">
                            {campaign?._broadcaster.firstName ? (
                              campaign?._broadcaster?.firstName?.toLowerCase() +
                              ` ` +
                              campaign?._broadcaster?.lastName?.toLowerCase()
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm max-w-sm capitalize truncate">
                            {campaign?.name ? (
                              campaign?.name
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm max-w-sm truncate">
                            {campaign?.status ? (
                              <Badge>{campaign?.status} </Badge>
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm max-w-sm truncate">
                            {campaign?.channel ? (
                              <Badge>{campaign?.channel} </Badge>
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm max-w-sm truncate">
                            {campaign?.scope ? (
                              <Badge>{campaign?.scope} </Badge>
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>

                          <td className="px-4 py-0.5 text-sm truncate">
                            {campaign?.initializedAt ? (
                              dateFormat(campaign?.initializedAt)
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm truncate">
                            <Badge>{campaign?.totalRecipients}</Badge>
                          </td>
                          <td className="pr-5 py-0.5 text-white">
                            <span className={`w-full px-5 h-4 flex`}>
                              {!campaign?.successfulRecipients &&
                              !campaign?.skippedRecipients &&
                              !campaign?.failedRecipients ? (
                                <div
                                  data-tip={`Recipients ${campaign.totalRecipients}`}
                                  className="bg-gray-400 tooltip"
                                  style={{
                                    width: `${progressAggregate(
                                      campaign?.totalRecipients,
                                      campaign?.totalRecipients,
                                    )}%`,
                                  }}
                                ></div>
                              ) : (
                                <>
                                  <div
                                    data-tip={`Completed ${campaign?.successfulRecipients}`}
                                    className="bg-green-400 tooltip"
                                    style={{
                                      width: `${progressAggregate(
                                        campaign?.successfulRecipients,
                                        campaign?.totalRecipients,
                                      )}%`,
                                    }}
                                  ></div>
                                  <div
                                    data-tip={`Skipped ${campaign.skippedRecipients}`}
                                    className="bg-purple-500 tooltip"
                                    style={{
                                      width: `${progressAggregate(
                                        campaign?.skippedRecipients,
                                        campaign?.totalRecipients,
                                      )}%`,
                                    }}
                                  ></div>
                                  <div
                                    data-tip={`Failed ${campaign?.failedRecipients}`}
                                    className="bg-red-500 tooltip"
                                    style={{
                                      width: `${progressAggregate(
                                        campaign?.failedRecipients,
                                        campaign?.totalRecipients,
                                      )}%`,
                                    }}
                                  ></div>
                                </>
                              )}
                            </span>
                          </td>
                        </TableRow>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="12">
                          <div className="w-full text-center p-6  ">
                            <p>No Campaign Found</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={campaignGroupsData?.totalPages}
                  totalRecords={campaignGroupsData?.totalRecords}
                  curerntPageRecords={campaignGroupsData?.currentPageRecords}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          )}
        </div>
      </>
    </Container>
  )
}
