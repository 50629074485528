/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { regEx } from 'App/Config'
import { PublicStyledButton } from './style'
import { brandLogo } from 'App/Assets/index'
import { useMutation } from 'react-query'
import { AxiosInstance } from 'App/Config'
import { AMS_BACKEND_API } from 'App/Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { NotificationManager } from 'react-notifications'
import axios from 'axios'

export const FindUser = () => {
  const [email, setEmail] = useState(``)
  const [errors, setErrors] = useState({
    formSubmit: false,
    emailError: ``,
  })

  const handleEmailInput = (e) => {
    const { value } = e.target
    setEmail(value)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const { isLoading, mutate } = useMutation(
    async (payload) =>
      await axios
        .post(`${AMS_BACKEND_API}/api/users/request-pw-reset`, payload)
        .then((res) => {
          if (res.hasError) {
            NotificationManager.error(`Something Went Wrong`)
          } else {
            return res?.data
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        if (!res.hasError) {
          NotificationManager.success(
            `Thanks! If there's an account associated with this email, we'll send the password reset instructions immediately.`,
          )
        }
      },
    },
  )

  const ValidateForm = () => {
    if (!email.length) {
      handleErrors(`emailError`, `Enter Email`)
    } else if (email.length) {
      if (!regEx.test(email.toLowerCase())) {
        handleErrors(`emailError`, `Enter Valid Email`)
      } else {
        handleErrors(`emailError`, ``)
      }
    } else {
      handleErrors(`emailError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (errors.formSubmit && !errors.emailError.length) {
      mutate({
        email: email,
      })
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  return (
    <>
      <img
        src={brandLogo}
        alt="Logo will go here"
        className="text-left w-60 h-36"
      />
      <InputField
        type="email"
        title="Email"
        name="email"
        placeholder="Enter Email"
        errorMessage={errors.emailError}
        value={email}
        setValue={(e) => handleEmailInput(e)}
        setKeyDownValue={(e) => {
          if (e.key === `Enter`) ValidateForm()
        }}
      />
      <div className="flex justify-center">
        <div className="text-center w-96 my-4">
          <PublicStyledButton
            onClick={ValidateForm}
            disabled={isLoading || !email}
          >
            <div className="flex gap-2 text-white">
              <span>Find Me</span>
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </PublicStyledButton>
        </div>
      </div>
    </>
  )
}
