import React from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const PdfModal = ({
  showModal,
  setShowModal,
  saveFile,
  isFileLoaded,
  updatingApi,
  isReadOnly,
  disableSaveButton,
  children,
}) => {
  return (
    <>
      {showModal && (
        <>
          <div className="justify-center items-center flex   fixed inset-0 z-40 outline-none focus:outline-none">
            <div className="relative   mx-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal()}
                  >
                    ×
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-2 w-full h-full">{children}</div>
                {/*footer*/}
                <div className="flex items-center justify-end p-2 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal()}
                  >
                    Close
                  </button>

                { !disableSaveButton &&  <div className="flex justify-start items-center">
                    {!isReadOnly && (
                      <button
                        className={` ${
                          !isFileLoaded && `disabled opacity-70 text-gray-500`
                        } bg-emerald-500   active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                        type="button"
                        onClick={() => saveFile()}
                      >
                        {updatingApi ? `Saving.. ` : `Save Changes`}
                        {updatingApi && (
                          <span className="ml-2">
                            <FontAwesomeIcon
                              icon={faSpinner}
                              color="red"
                              spin={updatingApi}
                            />
                          </span>
                        )}
                      </button>
                    )}
                  </div> }
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
