import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'
import React from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router'
import { Container } from 'App/Styled'
import { useDispatch } from 'react-redux'
import { setCurrentEvaluation } from 'App/Redux/actions'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useRef, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Badge } from '@windmill/react-ui'
import moment from 'moment'
import dateFormat from 'App/Services/General/dateFormat'

export const EvaluationReport = () => {
  const dispatch = useDispatch()
  const {
    params: { aId, eId },
  } = useRouteMatch()
  const {
    data: previewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `AssessmentPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/assessment/${aId}/evaluations/${eId}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentEvaluation(res))
      },
    },
  )

  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey) {
        e.preventDefault()
        handlePrint()
      }
    }
    document.addEventListener(`keydown`, handleKeyPress)

    return () => {
      document.removeEventListener(`keydown`, handleKeyPress)
    }
  }, [])

  return (
    <Container>
      <div className="m-4 text-right">
        <button
          onClick={handlePrint}
          className="bg-red-500 text-white rounded-md px-2 mt-2"
        >
          Print
        </button>
      </div>
      <div className="bg-white sm:mb-0  w-full px-2 rounded-md">
        {isLoading || isFetching ? (
          <div className="text-center p-6">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : previewData?.length ? (
          <p className=" text-center p-6">No Data Found</p>
        ) : (
          <div className="rounded-md overflow-hidden py-4 w-2/3 mx-auto">
            <div
              className="flex flex-col md:flex-row border-1 items-start justify-evenly"
              ref={componentRef}
            >
              <div className="flex-1 mx-2  rounded">
                <div className="grid grid-cols-12 px-2 py-2">
                  <div className="col-span-6 text-xl">Evaluation Report</div>
                </div>
                <hr />
                <div className="grid grid-cols-2 px-2">
                  <CardField
                    title="Report Id"
                    value={previewData?._evaluationId}
                  />
                  <CardField
                    title="Period"
                    value={
                      dateFormat(previewData?.evaluationStart)
                      + ` to ` +
                      dateFormat(previewData?.evaluationEnd)
                    }
                  />

                  <CardField
                    title="Created At"
                    value={moment(previewData?.createdAt).format(
                      `YYYY-MM-DD hh:mmA`,
                    )}

                  />
                  <CardField
                    title="Evaluated"
                    value={`${previewData?.evaluatee}`}
                  />
                  <CardField
                    title="Evaluator"
                    value={`${previewData?.evaluator}`}
                  />
                  <CardField
                    title="Score"
                    value={
                      previewData?.obtainedScore ? (
                        <Badge>
                          {previewData?.obtainedScore?.toString()}/
                          {previewData?.totalScore}
                        </Badge>
                      ) : (
                        `N/A`
                      )
                    }
                  />
                  <CardField
                    title="Percentage"
                    value={
                      previewData?.percentage ? (
                        <Badge
                          type={
                            previewData?.percentage <= 2
                              ? `danger`
                              : previewData?.percentage <= 5
                                ? `warning`
                                : previewData?.percentage <= 8
                                  ? `primary`
                                  : `success`
                          }
                        >
                          {previewData?.percentage?.toFixed(1)?.toString() +
                            `%`}
                        </Badge>
                      ) : (
                        `N/A`
                      )
                    }
                  />
                  <CardField
                    title="Average Score"
                    value={
                      <Badge
                        type={
                          previewData?.averageScore <= 2
                            ? `danger`
                            : previewData?.averageScore <= 5
                              ? `warning`
                              : previewData?.averageScore <= 8
                                ? `primary`
                                : `success`
                        }
                      >
                        {previewData?.averageScore?.toFixed(1)?.toString()}
                      </Badge>
                    }
                  />
                </div>

                <hr />
                <div>
                  <div className="px-2 gap-x-2">
                    {previewData?.assessmentQuestions?.map(
                      (questionArea, index) => (
                        <div className="py-1">
                          <div>
                            <div className="py-2">
                              <div className="font-semibold text-sm bg-gray-500 text-gray-100 px-2 py-1 rounded-sm">
                                {`${String.fromCharCode(index + 65)}) `}
                                {questionArea?.category}
                              </div>
                            </div>
                            <hr />
                            {questionArea?.performanceIndicators?.map(
                              (indicator) => (
                                <>
                                  <div className="flex gap-x-2">
                                    <div>
                                      <Badge
                                        type={
                                          indicator?.score <= 2
                                            ? `danger`
                                            : indicator?.score <= 5
                                              ? `warning`
                                              : indicator?.score <= 8
                                                ? `primary`
                                                : `success`
                                        }
                                      >
                                        {indicator?.score.toFixed(1)}
                                      </Badge>
                                    </div>
                                    <div className="text-justify pr-2 text-gray-600 my-1">
                                      {indicator?.statement}
                                    </div>
                                  </div>
                                  <div className="text-justify pr-2 max-w-xl xl:max-w-none break-words text-sm px-12 ">
                                    {indicator?.remarks && (
                                      <span>
                                        <span className="font-semibold py-2 text-gray-600">
                                          Remarks:
                                        </span>
                                        <span className="text-gray-500 ml-1">
                                          {indicator?.remarks.trim()}
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                </>
                              ),
                            )}
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                  {/* <div className="flex justify-center  ">
                      {presentation?.length > 0 && (
                        <div className="pb-1 w-full">
                          <Collapsable title="Presentation">
                            {presentation?.map((item) => (
                              <div className="flex justify-between ">
                                <div>{item?.description}</div>

                                <div className="mt-2">
                                  <span
                                    className={`text-sm ${
                                      item?.answer === `YES`
                                        ? `bg-green-500`
                                        : `bg-red-500`
                                    } bg-red-500 text-white px-2 py-1 rounded-md`}
                                  >
                                    {item?.answer}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </Collapsable>
                        </div>
                      )}
                    </div> */}
                  {/* <div className="flex justify-center">
                      {productivity?.length > 0 && (
                        <div className="pb-1 w-full">
                          <Collapsable title="Productivity">
                            {productivity?.map((item) => (
                              <div className="flex justify-between ">
                                <div>{item?.description}</div>

                                <div className="mt-2">
                                  <span
                                    className={`text-sm ${
                                      item?.answer === `YES`
                                        ? `bg-green-500`
                                        : `bg-red-500`
                                    } bg-red-500 text-white px-2 py-1 rounded-md`}
                                  >
                                    {item?.answer}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </Collapsable>
                        </div>
                      )}
                    </div> */}
                  {/* <div className="flex justify-center">
                      {calls?.length > 0 && (
                        <div className="pb-1 w-full">
                          <Collapsable title="Calls">
                            {calls?.map((item) => (
                              <div className="flex justify-between ">
                                <div>{item?.description}</div>

                                <div className="mt-2">
                                  <span
                                    className={`text-sm ${
                                      item?.answer === `YES`
                                        ? `bg-green-500`
                                        : `bg-red-500`
                                    } bg-red-500 text-white px-2 py-1 rounded-md`}
                                  >
                                    {item?.answer}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </Collapsable>
                        </div>
                      )}
                    </div> */}
                  {/* <div className="flex justify-center">
                      {referrals?.length > 0 && (
                        <div className="pb-1  w-full">
                          <Collapsable title="Referrals">
                            {referrals?.map((item) => (
                              <div className="flex justify-between ">
                                <div>{item?.description}</div>

                                <div className="mt-2">
                                  <span
                                    className={`text-sm ${
                                      item?.answer === `YES`
                                        ? `bg-green-500`
                                        : `bg-red-500`
                                    } bg-red-500 text-white px-2 py-1 rounded-md`}
                                  >
                                    {item?.answer}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </Collapsable>
                        </div>
                      )}
                    </div> */}
                  {/* <div className="flex justify-center">
                      {receipts?.length > 0 && (
                        <div className="pb-1  w-full">
                          <Collapsable title="Reciepts">
                            {receipts?.map((item) => (
                              <div className="flex justify-between ">
                                <div>{item?.description}</div>

                                <div className="mt-2">
                                  <span
                                    className={`text-sm ${
                                      item?.answer === `YES`
                                        ? `bg-green-500`
                                        : `bg-red-500`
                                    } bg-red-500 text-white px-2 py-1 rounded-md`}
                                  >
                                    {item?.answer}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </Collapsable>
                        </div> */}
                  {/* )}
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}
