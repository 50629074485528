import React from 'react'
import { SearchInput } from 'App/Styled'

export const MessageInput = ({
  type,
  value,
  disabled,
  placeholder,
  setValue,
  onEnter,
  pl = ``,
}) => {
  return (
    <>
      <SearchInput
        className={pl}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e)}
        onKeyDown={(e) => {
          if (e.key === `Enter`) {
            e.preventDefault()
            onEnter()
          }
        }}
      />
    </>
  )
}
