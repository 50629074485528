import {
  faChevronCircleDown,
  faPencilAlt,
  faSpinner,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

export const CollapsableRef = ({
  title,
  editAble,
  deleteAble,
  isLoading,
  isActive,
  children,
}) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (isActive) {
      setActive(true)
    }
  }, [isActive])
  return (
    <>
      <div
        className={`flex flex-row justify-between bg-white py-1.5 border-1 items-center px-3 cursor-pointer  ${
          active ? `rounded-t-md ` : `rounded-md `
        }  `}
        onClick={() => {
          setActive(!active)
        }}
      >
        <div className="text-md font-medium text-black rounded-t-md capitalize">
          {title}
        </div>
        <div className="flex gap-3 cursor-pointer">
          {editAble && (
            <div>
              <FontAwesomeIcon
                onClick={editAble}
                icon={faPencilAlt}
                className={`text-white`}
              />
            </div>
          )}
          {deleteAble && (
            <div>
              <FontAwesomeIcon
                onClick={deleteAble}
                icon={isLoading ? faSpinner : faTrashAlt}
                spin={isLoading}
                className={`text-white `}
              />
            </div>
          )}
          <div>
            <FontAwesomeIcon
              onClick={() => setActive(!active)}
              icon={faChevronCircleDown}
              className={` text-red-500 transform transition duration-100 ${
                active && `rotate-180 `
              }`}
            />
          </div>
        </div>
      </div>
      <div className="left-0 rounded-md shadow-md">
        <div
          className={`py-2 px-4 bg-white rounded-md  ${
            active ? `block` : `hidden`
          }`}
        >
          {children}
        </div>
      </div>
    </>
  )
}
