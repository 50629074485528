import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { NotificationManager } from 'react-notifications'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StyledButton } from 'App/Styled'
import { loadFranchises, permissionGranted } from 'App/Services'
import { useSelector } from 'react-redux'
export const AddTeam = ({ closeModal, teamUpdated }) => {
  const [teams, setTeams] = useState({
    name: ``,
    teamMembers: ``,
    franchise: ``,
    location: ``,
  })

  const [errors, setErrors] = useState({
    nameError: ``,
    teamMembersError: ``,
    locationError: ``,
  })
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)
  const {
    user: {
      profile: { _locations },
    },
  } = useSelector(({ user }) => user)

  const loadAgentLocations = () => {
    const options = _locations.map((item) => {
      return { value: item._id, label: item.name }
    })

    return {
      options,
    }
  }
  //get all locations by franchise

  const loadLocations = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        teams?.franchise?.value ? teams?.franchise?.value : _franchise?._id
      }/locations/list/?search=${search ? search : ``}&page=${page}&offset=10`,
    )
    if (data) {
      return {
        options: data.locations,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  const loadAgents = async (search, loadedOptions, { page }) => {
    try {
      const {
        data: { data },
      } = await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/users/v2/list/?search={"query":"${
          search.length ? search : ``
        }"}&page=${page}&offset=10&evaluator=true`,
        {
          franchiseId: teams?.franchise?.value
            ? teams?.franchise?.value
            : _franchise?._id,
          locations: [teams?.location?.value],
        },
      )

      if (data) {
        return {
          options: data.agents,
          hasMore: data.totalPages - page > 0,
          additional: {
            page: page + 1,
          },
        }
      }
    } catch (error) {
      if (error.response?.data?.hasError) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        }
      }
    }
  }

  const { mutate, isLoading } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/assessment-team`, {
        ...payload,
      })
        .then((res) => {
          if (res?.data) {
            NotificationManager.success(`Created Successfully`)
            closeModal()
            teamUpdated()
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Team creation failed due to duplicate teamName.`,
              )
            ) {
              NotificationManager.error(`Duplicate team Name`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.teamMembersError.length
    ) {
      mutate(prepareload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const prepareload = () => {
    let data = {}
    data.teamName = teams?.name
    data.teamMembers = teams?.teamMembers.map((item) => item.value)
    if (teams?.franchise?.value?.length) {
      data._franchise = teams?.franchise?.value
    } else {
      data._franchise = _franchise?._id
    }
    if (teams?.location?.value?.length) {
      data._location = teams?.location?.value
    }
    return data
  }
  const validateForm = () => {
    if (teams?.location?.value?.length)
      if (!teams?.teamMembers.length) {
        handleErrors(`teamMembersError`, `Select Members`)
      } else {
        handleErrors(`teamMembersError`, ``)
      }
    if (!teams?.name?.length) {
      handleErrors(`nameError`, `Enter name`)
    } else {
      handleErrors(`nameError`, ``)
    }
    if (!teams?.location?.value?.length) {
      handleErrors(`locationError`, `Select Location`)
    } else {
      handleErrors(`locationError`, ``)
    }
    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-col px-4">
          <div className="flex gap-4">
            <div className="flex-1 relative w-48">
              <InputField
                type="text"
                name="Name"
                title="Name*"
                placeholder="Enter Name"
                value={teams.name}
                setValue={(e) => {
                  setTeams((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }}
                errorMessage={errors.nameError}
              />
            </div>
            {permissionGranted([`super-admin:*`]) && (
              <>
                <div className="flex-1 relative w-60">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Franchise *"
                      placeholder="Select Franchise"
                      setValue={(value) => {
                        setTeams((preState) => ({
                          ...preState,
                          franchise: value,
                          teamMembers: [],
                          location: ``,
                        }))
                      }}
                      defaultValue={{
                        label: _franchise?.name,
                        value: _franchise?._id,
                      }}
                      isMulti={false}
                      loadOptions={loadFranchises}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="flex gap-4">
            <div className="flex gap-4">
              <div className="flex-1 relative w-56">
                <ReactSelectField
                  title="Location *"
                  placeholder="Select Location"
                  errorMessage={errors.locationError}
                  value={teams?.location}
                  setValue={(value) => {
                    setTeams((preState) => ({
                      ...preState,
                      location: value,
                      teamMembers: [],
                    }))
                  }}
                  isMulti={false}
                  loadOptions={
                    permissionGranted([
                      `super-admin:*`,
                      `corporate-manager:*`,
                      `manager:*`,
                    ])
                      ? loadLocations
                      : loadAgentLocations
                  }
                  isSearchable={true}
                  cacheUniqs={teams?.franchise}
                />
              </div>
              {teams?.location?.length !== 0 && (
                <div className="flex-1 relative w-60">
                  <ReactSelectField
                    title="Select Members*"
                    placeholder="Select Members"
                    value={teams.teamMembers}
                    errorMessage={errors.teamMembersError}
                    setValue={(value) => {
                      setTeams((preState) => ({
                        ...preState,
                        teamMembers: value,
                      }))
                    }}
                    isMulti={true}
                    loadOptions={teams?.location?.value ? loadAgents : ``}
                    isSearchable={true}
                    cacheUniqs={teams?.location}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="pb-2 px-4 text-right">
          <StyledButton onClick={validateForm}>
            <div className="flex gap-2 text-white">
              <span>Add</span>
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </StyledButton>
        </div>
      </div>
    </div>
  )
}
