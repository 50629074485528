import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { useHistory } from 'react-router'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import dateFormat from 'App/Services/General/dateFormat'

const tableColumns = [
  { label: `Carrier`, value: `_carrier` },
  { label: `Agent`, value: `_agent` },
  { label: `Policy`, value: `_poliocy` },
  { label: `Invoice`, value: `_invoiceId` },
  { label: `Void`, value: `void` },
  { label: `Total`, value: `tota` },
  { label: `Paid`, value: `pai` },
  { label: `Balance`, value: `balance` },
  { label: `Due`, value: `dueDate` },
]

export const SearchPayments = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { searchQuery } = useSelector(({ globalSearch }) => globalSearch)
  const [isOffset, setIsOffset] = useState(``)
  const history = useHistory()

  const {
    data: currentSearch,
    error,
    isLoading,
    isFetching,
    refetch: getSearchPayments,
  } = useQuery(
    `invoicesApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/invoices?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }", "onlyMasterInvoices":""}&sort=""&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  useEffect(() => {
    getSearchPayments()
  }, [currentPage, isOffset])

  return (
    <Container shadow="yes">
      <div className="relative">
        {error ? (
          <Reload refetch={() => getSearchPayments()} />
        ) : (
          <>
            {(isLoading || isFetching) && (
              <div className="text-center py-10 absolute top-1/3 left-1/2 z-40">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="1x"
                  className="text-red-600"
                />
              </div>
            )}
            <PageHeader bgcolor="bg-white">
              <div className="flex items-center ">
                <div className="py-1 px-2">
                  <ReactSelectField
                    placeholder="10"
                    value={isOffset}
                    setValue={(e) => {
                      setCurrentPage(1)
                      setIsOffset(e)
                    }}
                    isMulti={false}
                    loadOptions={loadOffset}
                  />
                </div>
              </div>
            </PageHeader>
            <div
              className={`bg-white mb-1 sm:mb-0 ${
                (isLoading || isFetching) && ` opacity-30 pointer-events-none`
              }`}
            >
              <div className="overflow-x-auto  rounded-t-md">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div className="cursor-pointer select-none text-sm flex truncate">
                              <div className={`flex`}>{item.label}</div>
                            </div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentSearch?.invoices?.length > 0 ? (
                      currentSearch?.invoices?.map((invoice, index) => (
                        <TableRow
                          key={index.toString()}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            if (
                              permissionGranted([
                                `payments:read`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ])
                            ) {
                              history.push(
                                `/customers/${invoice?._insured?.value}/payments/${invoice._id}`,
                              )
                            }
                          }}
                        >
                          <td className="px-4 py-0.5 text-sm  z-0 break-words max-w-px-140 capitalize truncate">
                            {invoice?._carrier?.label ? (
                              invoice?._carrier?.label?.toLowerCase()
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm  z-0 break-words max-w-px-140 capitalize truncate">
                            {invoice?._agent?.label ? (
                              invoice?._agent?.label?.toLowerCase()
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm  z-0 break-words max-w-px-140 capitalize truncate">
                            {invoice?._policy?.label}
                          </td>

                          <td className="px-4 py-0.5 text-sm  z-0 break-words max-w-px-140">
                            <Badge type="success">{invoice?._invoiceId}</Badge>
                          </td>
                          <td className="px-4 py-0.5 text-sm  z-0">
                            <Badge
                              type={`${invoice?.isVoid ? `success` : `danger`}`}
                            >
                              {invoice?.isVoid.toString().toUpperCase()}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 text-sm  z-0">
                            {invoice?.totalAmount}
                          </td>
                          <td className="px-4 py-0.5 text-sm  z-0">
                            {invoice?.paidAmount}
                          </td>
                          <td className="px-4 py-0.5 text-sm  z-0">
                            {invoice?.remainingAmount}
                          </td>
                          <td className="px-4 py-0.5 text-sm  z-0  truncate">
                            {dateFormat(invoice?.dueDate)}
                          </td>
                          {/* Actions TD */}
                        </TableRow>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="12" className="text-center p-6">
                          <div>No Payment Found</div>
                        </td>
                      </tr>
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={currentSearch?.totalPages}
                  totalRecords={currentSearch?.totalRecords}
                  curerntPageRecords={currentSearch?.currentPageRecords}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
