import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from '../style'
import { PolicyReportPdfHeader } from 'App/Components/Common/PolicyReportPdfHeader'
import dateFormat from 'App/Services/General/dateFormat'

export const Pdf = ({ reports }) => {
  return (
    <Document subject="Report" author="Reports">
      <Page style={styles.page} size="A3" wrap>
        <View style={styles.section}>
          <Text style={styles.pageHeading}>Top 10 Agent by Commission</Text>
          <Text style={styles.smallSpace}></Text>
          <PolicyReportPdfHeader showTransactionDate={false} />
          <Text style={styles.smallSpace}></Text>

          <View style={styles.headingRow}>
            <Text style={styles.pageTitles}> Agent</Text>
            <Text style={styles.pageTitles}> Policy No</Text>
            <Text style={styles.pageTitles}> Total Premium</Text>
            <Text style={styles.pageTitles}> Agent Commission</Text>
            <Text style={styles.pageTitles}> Agency Commission</Text>
            <Text style={styles.pageTitles}> Effective Date</Text>
          </View>

          {reports.length > 0 &&
            reports.map((policy, index) => (
              <View style={styles.agent} key={index}>
                <View style={styles.headingRow2}>
                  <Text style={styles.pageTitles2}>
                    {policy?.agentName ? policy?.agentName : `N/A`}
                  </Text>
                  <Text style={styles.pageTitles2}>{policy?.policyNo}</Text>
                  <Text style={styles.pageTitles2}>
                    {policy?.totalPremium
                      ? `$ ${policy?.totalPremium?.toFixed(2)}`
                      : `-`}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {policy?.agentCommission
                      ? `$ ${policy?.agentCommission?.toFixed(2)}`
                      : `-`}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {policy?.agencyCommission
                      ? `$ ${policy?.agencyCommission?.toFixed(2)}`
                      : `-`}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {dateFormat(policy?.effectiveDate)}
                  </Text>
                </View>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  )
}
