import './index.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'App/Router'
import { Windmill } from '@windmill/react-ui'
import { AuthProvider } from 'App/AuthProvider'
import { MainLayout } from 'App/HOC/MainLayout'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { store, persistor } from 'App/Redux/store'
import { Provider as ReduxProvider } from 'react-redux'
import { ReactQueryDevtools } from 'react-query/devtools'
import { PersistGate } from 'redux-persist/integration/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import NotificationContainer from 'react-notifications/lib/NotificationContainer'
import { WINDMIL_THEME } from './customWindmil.js'

const queryClient = new QueryClient()

ReactDOM.render(
  <>
    <Windmill theme={WINDMIL_THEME}>

      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <AuthProvider>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                <MainLayout>
                  <NotificationContainer />
                  <Router />
                </MainLayout>
              </BrowserRouter>
            </PersistGate>
          </AuthProvider>
        </ReduxProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Windmill>
  </>,
  document.getElementById(`root`),
)
reportWebVitals()
