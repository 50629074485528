import { CardField } from 'App/Components/Common/CardField'
import React from 'react'
import { Container, PageHeader } from 'App/Styled'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { useQuery } from 'react-query'
import { AMS_BACKEND_API } from 'App/Config'

export const BroadcastingTemplate = () => {
  const { data: templatePreviewData } = useQuery(
    `PreferencesApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/templates/:templateId`,
      ).then((res) => {
        return res?.data?.data?.ratingPrefs
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (res) {
          dispatch(setPreferences(res))
        }
      },
    },
  )

  return (
    <Container>
      <PageHeader>
        <div>Template</div>
      </PageHeader>

      <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md">
        {!templatePreviewData?.data?.length ? (
          <p className=" text-center p-6">No Data Found</p>
        ) : (
          <div className="rounded-md overflow-hidden py-4">
            <div className="flex items-start justify-evenly">
              <div className="flex-1 mx-2 shadow-lg rounded">
                <Collapsable title="About">
                  <CardField title="Quote Title" value={previewData?.title} />
                  <CardField
                    title="Quote Status"
                    value={[previewData?.quoteStatus, previewData?._quoteNo]}
                    multiple={true}
                  />
                  <CardField title="Quote No" value={previewData?._quoteNo} />
                  <CardField
                    title="Price Sold"
                    value={previewData?.priceSold}
                  />
                  <CardField
                    title="Quote Type"
                    value={previewData?.quoteType}
                  />
                  <CardField
                    title="Turbo Rater ID"
                    value={previewData?._turboRaterId}
                    badge={true}
                  />
                  <CardField
                    title="Turbo Rater URL"
                    value={previewData?.turboRaterUrl}
                    link={true}
                  />
                </Collapsable>
              </div>
              <div className="flex-1 mx-2 shadow-lg rounded">
                <Collapsable title="Quote">
                  <CardField
                    title="Contact Method"
                    value={previewData?.quoteData?.contactMethod}
                  />
                  <CardField
                    title="Lead Source"
                    value={previewData?.quoteData?.leadSource}
                  />
                  <CardField
                    title="Description"
                    value={previewData?.quoteData?.quoteDescription}
                  />
                </Collapsable>
              </div>
            </div>
            <div className="flex items-start justify-evenly my-4">
              <div className="flex-1 mx-2 shadow-lg rounded">
                <Collapsable title="Policy Data">
                  <CardField
                    title="Customer"
                    value={previewData?.policyData?._customer}
                  />
                  <CardField
                    title="Policy Term"
                    value={previewData?.policyData?.policyTerm}
                  />
                  <CardField
                    title="Non Owner"
                    value={previewData?.policyData?.nonOwner}
                  />
                  <CardField
                    title="Exclusions"
                    value={previewData?.policyData?.exclusions}
                  />
                </Collapsable>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}
