import React, { useState } from 'react'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { convertToHTML } from 'draft-convert'

export const RichTextEditor = ({
  setEditorValue,
  defaultValue,
  disabled,
  className,
}) => {
  const [editorState, setEditorState] = useState(() =>
    defaultValue
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(defaultValue)),
        )
      : EditorState.createEmpty(),
  )
  const handleEditorChange = (state) => {
    setEditorState(state)
    convertContentToHTML()
  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent())
    setEditorValue(currentContentAsHTML)
  }
  return (
    <Editor
      disabled={disabled ? true : false}
      placeholder="Body"
      editorState={editorState}
      onEditorStateChange={handleEditorChange}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName={className}
      toolbar={{
        options: [`inline`],
      }}
      editorStyle={{ height: 200 }}
      wrapperStyle={{ padding: 0 }}
      hashtag={{
        separator: ` `,
        trigger: `#`,
      }}
      mention={{
        separator: ` `,
        trigger: `@`,
        suggestions: [
          { text: `Phone`, value: `phone`, url: `phone` },
          { text: `First Name`, value: `firstName`, url: `firstName` },
          { text: `Last Name`, value: `lastName`, url: `lastName` },
          { text: `Gender`, value: `gender`, url: `gender` },
          { text: `Email`, value: `email`, url: `email` },
          { text: `Street`, value: `street`, url: `street` },
          { text: `Zip`, value: `zip`, url: `zip` },
          { text: `City`, value: `city`, url: `city` },
          { text: `State`, value: `state`, url: `state` },
          { text: `Drivers`, value: `drivers`, url: `drivers` },
          {
            text: `Cars`,
            value: `cars`,
            url: `cars`,
          },
          { text: `DOB`, value: `dob`, url: `dob` },
        ],
      }}
    />
  )
}
