export const loadDriverRelationtoApplic = () => {
  const options = {
    options: [
      { value: `Domestic Partner`, label: `Domestic Partner` },
      {
        value: `Officer of Corporation`,
        label: `Officer of Corporation`,
      },
      {
        value: `Resident Relative other than Spouse or Child`,
        label: `Resident Relative other than Spouse or Child`,
      },
      {
        value: `Third Party`,
        label: `Third Party`,
      },
      {
        value: `Significant Other`,
        label: `Significant Other`,
      },
      {
        value: `Brother/Sister`,
        label: `Brother/Sister`,
      },
      {
        value: `Child`,
        label: `Child`,
      },
      {
        value: `Additional Insured`,
        label: `Additional Insured`,
      },
      {
        value: `Employee`,
        label: `Employee`,
      },
      {
        value: `Husband`,
        label: `Husband`,
      },
      {
        value: `Insured`,
        label: `Insured`,
      },
      {
        value: `Parent`,
        label: `Parent`,
      },
      {
        value: `Relative`,
        label: `Relative`,
      },
      {
        value: `Spouse`,
        label: `Spouse`,
      },
      {
        value: `Wife`,
        label: `Wife`,
      },
      {
        value: `Other`,
        label: `Other`,
      },
    ],
    hasMore: false,
  }
  return options
}
