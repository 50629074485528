import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeActivityTab: 1,
}

const dataTransactionSlice = createSlice({
  name: `dataTransactions`,

  initialState,
  reducers: {
    setDataTrasActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
  },
})

export const { setDataTrasActivityActiveTab } = dataTransactionSlice.actions

export const dataTransactions = dataTransactionSlice.reducer
