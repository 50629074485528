/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  FormTab,
  StyledButton,
  SecondaryHeading,
  Container,
  PageHeader,
} from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AMS_BACKEND_API, AxiosInstance, STRIPE_PUBLIC_KEY } from 'App/Config'
import { useMutation, useQuery } from 'react-query'
import { NotificationManager } from 'react-notifications'
import {
  loadAllCarriers,
  loadPaymentFeeTypes,
  loadPaymentMethods,
} from 'App/Services'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { AddFormButton, RemoveDriver } from '../../QuoteForm/style'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'
import { paymentAmountTotal } from 'App/hooks'
import { Spinner } from 'App/Components/Common/Spinner'
import { Reload } from 'App/Components/Common/Reload'
import { loadStripe } from '@stripe/stripe-js'
import { StripeForm } from 'App/Components/Transactions/Inspection/Stripe'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

export const QuoteSold = () => {
  const [quoteStatus, setQuoteStatus] = useState(``)

  const {
    params: { quoteId },
  } = useRouteMatch()
  const history = useHistory()
  const [policyNo, setPolicyNo] = useState(``)
  const [expiryDate, setExpiryDate] = useState(``)
  const [effectiveDate, setEffectiveDate] = useState(``)
  const [issueDate, setIssueDate] = useState(``)
  const [dueDate, setdueDate] = useState(``)
  const [carrier, setCarrier] = useState(``)
  const [policyPremium, setPolicyPremium] = useState(``)

  const [confirmStripeLoader, setConfirmStripeLoader] = useState(false)
  const [items, setItems] = useState([
    {
      name: ``,
      nameError: ``,
      premiumError: ``,
      feeType: ``,
      feeTypeError: ``,
      amount: ``,
      amountError: ``,
      paidAmount: ``,
      paidAmountError: ``,
      paymentMethod: ``,
      paymentMethodError: ``,
      confirmRefNo: ``,
      confirmRefNoError: ``,
      paymentNote: ``,
    },
  ])

  const [paymentItems, setPaymentItems] = useState([
    {
      amount: ``,
      amountError: ``,
      paymentMethod: ``,
      paymentMethodError: ``,
      confirmRefNo: ``,
      confirmRefNoError: ``,
      paymentNote: ``,
    },
  ])

  const [errors, setErrors] = useState({
    formSubmit: false,
    quoteStatusError: ``,
    policyNoError: ``,
    premiumError: ``,
    priceSoldError: ``,
    expiryDateError: ``,
    effectiveDateError: ``,
    issueDateError: ``,
    dueDateError: ``,
    carrierError: ``,
    amountMatchError: ``,
  })

  const [fTabs, setFTabs] = useState([
    {
      tName: `Fee 1`,
      tNumber: 1,
      permission: [`quotes:update`],
    },
  ])

  const [pTabs, setPTabs] = useState([
    {
      tName: `Method 1`,
      tNumber: 1,
      permission: [`quotes:update`],
    },
  ])

  const [stripeToken, setStripeToken] = useState([])

  const [activeFeeTab, setActiveFeeTab] = useState(1)
  const [activePaymentMethodTab, setActivePaymentMethodTab] = useState(1)
  const [validatingPolicyNo, setValidatingPolicyNo] = useState(false)

  const isPaymentDoneRef = useRef(null)

  const loadStatusOptions = () => {
    const options = {
      options: [
        {
          value: `QUOTE`,
          label: `QUOTE`,
        },
        {
          value: `SOLD`,
          label: `SOLD`,
        },
        {
          value: `NOT ANSWERED`,
          label: `NOT ANSWERED`,
        },
        {
          value: `LOST`,
          label: `LOST`,
        },
        {
          value: `IN PROGRESS`,
          label: `IN PROGRESS`,
        },
        {
          value: `MANUAL`,
          label: `MANUAL`,
        },
      ],
      hasMore: false,
    }
    return options
  }

  const {
    data: previewData,
    isLoading: loadingQuote,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `GetQuotePreviewApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/quotes/${quoteId}`).then(
        (res) => res?.data?.data?.quote,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (quote) => {
        setQuoteStatus({ value: quote?.status, label: quote?.status })
      },
    },
  )

  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(`${AMS_BACKEND_API}/api/quotes/${quoteId}`, {
        ...payload,
      }).then((res) => {
        NotificationManager.success(`Quote ${quoteStatus?.value}`)
        history.push(`/raters/myquotes/${quoteId}`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const { isLoading: loadingStripe, mutate: initiateStripe } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/payments/stripe`,
        payload,
      )
        .then((res) => {
          return res.data.data
        })
        .catch(() => NotificationManager.error(`Something is went wrong!`)),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        const tokenArray = res?.tokens?.map((res, ind) => ({
          clientSecret: res.token,
          _amount: res?.amount,
          isPaymentDone: false,
          transactionIndex: paymentByCreditCard[ind]?.index,
          transactionId: res?.transactionId,
        }))
        setStripeToken(tokenArray)
      },
    },
  )

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleItemsErrors = (name, error, ind) => {
    if (ind !== undefined) {
      const newItems = items?.map((fee, index) => {
        if (index == ind) {
          fee[name] = error
        }
        return fee
      })
      setItems(newItems)
    }
  }

  const addItem = () => {
    setItems([
      ...items,
      {
        name: ``,
        nameError: ``,
        feeType: ``,
        feeTypeError: ``,
        amount: ``,
        amountError: ``,
      },
    ])

    setFTabs([
      ...fTabs,
      {
        tName: `Fee ${fTabs.length + 1}`,
        tNumber: fTabs.length + 1,
        permission: [`quotes:update`],
      },
    ])
  }

  const removeItem = (ind) => {
    const newItems = [...items]
    if (newItems.length != 1) {
      newItems.splice(ind, 1)
      setItems(newItems)
    }
    const newFeesTab = [...fTabs]
    if (newFeesTab.length !== 1) {
      newFeesTab.pop()
      setFTabs(newFeesTab)
    }
  }

  const handlePaymentItemsErrors = (name, error, ind) => {
    if (ind !== undefined) {
      const newPaymentItems = paymentItems?.map((transaction, index) => {
        if (index == ind) {
          transaction[name] = error
        }
        return transaction
      })
      setPaymentItems(newPaymentItems)
    }
  }

  const addPaymentItem = () => {
    setPaymentItems([
      ...paymentItems,
      {
        paymentMethod: ``,
        paymentMethodError: ``,
        amount: ``,
        amountError: ``,
        confirmRefNo: ``,
        confirmRefNoError: ``,
        paymentNote: ``,
      },
    ])

    setPTabs([
      ...pTabs,
      {
        tName: `Method ${pTabs.length + 1}`,
        tNumber: pTabs.length + 1,
        permission: [`quotes:update`],
      },
    ])
  }

  const removePaymentItem = (ind) => {
    const newPaymentItem = [...paymentItems]
    if (newPaymentItem.length != 1) {
      newPaymentItem.splice(ind, 1)
      setPaymentItems(newPaymentItem)
    }
    const newPaymentTabs = [...pTabs]
    if (newPaymentTabs.length !== 1) {
      newPaymentTabs.pop()
      setPTabs(newPaymentTabs)
    }
  }

  const handleItems = (value, name, ind) => {
    const newItems = items?.map((fee, index) => {
      if (index === ind) {
        fee[name] = value
      }
      return fee
    })
    setItems(newItems)
  }

  const handlePaymentItems = (value, name, ind) => {
    const newPaymentItems = paymentItems?.map((paymentItem, index) => {
      if (index === ind) {
        paymentItem[name] = value
      }
      return paymentItem
    })
    setPaymentItems(newPaymentItems)
  }
  useEffect(() => {
    if (updationError) {
      NotificationManager.error(`Something went wrong`)
    }
  }, [updationError])

  const ValidateForm = () => {
    if (quoteStatus?.value?.length) {
      if (quoteStatus.value === `SOLD`) {
        let invoiceAmount = 0
        let transactionAmount = 0

        if (!policyNo?.length) {
          handleErrors(`policyNoError`, `Enter Policy No`)
        } else if (
          policyNo.length &&
          errors.policyNoError.includes(`Policy No Already Exists`)
        ) {
          handleErrors(`policyNoError`, `Policy No Already Exists`)
        } else {
          handleErrors(`policyNoError`, ``)
        }
        if (!policyPremium?.length) {
          handleErrors(`premiumError`, `Enter Premium`)
        } else if (policyPremium && parseFloat(policyPremium) <= 0) {
          handleErrors(`premiumError`, `Amount must be greater than 0`)
        } else {
          handleErrors(`premiumError`, ``)
        }

        for (const index in items) {
          const fee = items[index]
          invoiceAmount =
            invoiceAmount + parseFloat(fee.amount ? fee.amount : 0)

          // TODO: if needed we will reuse this code for name of fee
          // if (!fee.name.length) {
          //   handleItemsErrors(`nameError`, `Enter Name`, index)
          // } else {
          //   handleItemsErrors(`nameError`, ``, index)
          // }

          if (!fee.feeType?.value?.length) {
            handleItemsErrors(`feeTypeError`, `Select Fee Type`, index)
          } else {
            handleItemsErrors(`feeTypeError`, ``, index)
          }

          if (!fee.amount) {
            handleItemsErrors(`amountError`, `Enter Amount`, index)
          } else if (fee.amount && parseFloat(fee.amount) < 1) {
            handleItemsErrors(
              `amountError`,
              `Amount must be greater than 0`,
              index,
            )
          } else {
            handleItemsErrors(`amountError`, ``, index)
          }
          if (
            fee.amount &&
            premium?.includes(fee?.feeType?.value) &&
            parseFloat(fee?.amount) > parseFloat(policyPremium)
          ) {
            handleItemsErrors(
              `amountError`,
              `Payment Amount Must be less than total Premium`,
              index,
            )
          }

          if (
            fee.paidAmount &&
            parseFloat(fee?.amount) !== parseFloat(fee?.paidAmount)
          ) {
            handleItemsErrors(
              `paidAmountError`,
              `Payment Amount Must be Equal to amount`,
              index,
            )
          }
        }

        for (const index in paymentItems) {
          const method = paymentItems[index]

          if (!method.paymentMethod?.value?.length) {
            handlePaymentItemsErrors(`paymentMethodError`, `Select Type`, index)
          } else {
            handlePaymentItemsErrors(`paymentMethodError`, ``, index)
          }

          if (!method.amount) {
            handlePaymentItemsErrors(`paidAmountError`, `Enter Amount`, index)
          } else if (method.amount && parseFloat(method.amount) <= 0) {
            handlePaymentItemsErrors(
              `paidAmountError`,
              `Amount must be greater than 0`,
              index,
            )
          } else {
            transactionAmount += parseFloat(method.amount)
            handlePaymentItemsErrors(`paidAmountError`, ``, index)
          }
          if (
            !method.confirmRefNo?.length &&
            method?.paymentMethod?.value !== `CASH` &&
            !method?.paymentMethod?.value?.includes(`CREDIT`)
          ) {
            handlePaymentItemsErrors(`confirmRefNoError`, `Enter Value`, index)
          } else {
            handlePaymentItemsErrors(`confirmRefNoError`, ``, index)
          }
        }

        if (invoiceAmount < transactionAmount) {
          NotificationManager.error(`Paid amount must not be greater than fee.`)
          handleErrors(`amountMatchError`, `error`)
        } else {
          handleErrors(`amountMatchError`, ``)
        }

        if (!expiryDate) {
          handleErrors(`expiryDateError`, `Enter Date`)
        } else {
          handleErrors(`expiryDateError`, ``)
        }

        if (!effectiveDate) {
          handleErrors(`effectiveDateError`, `Enter Date`)
        } else {
          handleErrors(`effectiveDateError`, ``)
        }

        if (!issueDate) {
          handleErrors(`issueDateError`, `Enter Date`)
        } else {
          handleErrors(`issueDateError`, ``)
        }

        if (!dueDate) {
          handleErrors(`dueDateError`, `Enter Date`)
        } else {
          handleErrors(`dueDateError`, ``)
        }

        if (!carrier?.value?.length) {
          handleErrors(`carrierError`, `Select Carrier`)
        } else {
          handleErrors(`carrierError`, ``)
        }
      }
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    let formValidated = []
    if (quoteStatus.value === `SOLD`) {
      if (
        errors.formSubmit &&
        !errors.quoteStatusError.length &&
        !errors.policyNoError.length &&
        !errors.premiumError.length &&
        !errors.expiryDateError.length &&
        !errors.effectiveDateError.length &&
        !errors.issueDateError.length &&
        !errors.dueDateError.length &&
        !errors.carrierError.length &&
        !errors.amountMatchError.length
      ) {
        let itemsErrorCheck = items?.map((item) => {
          if (!item.feeTypeError.length && !item.amountError.length) {
            return true
          }
        })

        let paymentItemsErrorCheck = paymentItems.map((item) => {
          if (
            !item.paymentMethodError.length &&
            !item.paidAmountError.length &&
            !item.confirmRefNoError.length
          ) {
            return true
          }
        })

        formValidated = [...paymentItemsErrorCheck, ...itemsErrorCheck]

        if (!formValidated.includes(undefined)) {
          if (paymentByCreditCard?.length) {
            const stripePayLoad = paymentByCreditCard.map((types) => ({
              amount: parseInt(
                new Intl.NumberFormat()
                  .format(parseFloat(types.amount) * 100)
                  .replace(`,`, ``),
                10,
              ),
              customerId: previewData?.policyData?._customer?._id,
            }))
            initiateStripe(stripePayLoad)
          } else {
            //call API

            mutate(updatePayload())
            setErrors((prevState) => ({
              ...prevState,
              formSubmit: false,
            }))
          }
        } else {
          quoteStatus.value !== `SOLD` && mutate(quoteStatus.value)
        }
      }
    } else if (
      errors.formSubmit &&
      (quoteStatus.value === `LEAD` ||
        quoteStatus.value === `QUOTE` ||
        quoteStatus.value === `NOT ANSWERED` ||
        quoteStatus.value === `LOST` ||
        quoteStatus.value === `IN PROGRESS` ||
        quoteStatus.value === `MANUAL`)
    ) {
      mutate(updatePayload())
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}
    data.customerId = previewData?.policyData?._customer?._id
    if (quoteStatus?.value?.length) {
      data.status = quoteStatus?.value
    }
    if (quoteStatus.value === `SOLD`) {
      if (policyNo?.length) {
        data.policyNo = policyNo
      }

      data.paidAmount = paymentItems.reduce(
        (previousValue, item) => previousValue + parseFloat(item.amount),
        0,
      )

      data.items = items.map((fee) => {
        return {
          // name: fee.name,
          feeType: fee.feeType.value,
          amount: parseFloat(fee.amount),
        }
      })

      data.paymentItems = paymentItems.map((item) => {
        const paymentItem = {
          paymentMethod: item?.paymentMethod?.value,
          amount: parseFloat(item?.amount),
          paymentNote: item?.paymentNote,
        }

        if (item?.confirmRefNo) {
          paymentItem.confirmRefNo = item.confirmRefNo
        }
        if (item?.transactionId) {
          paymentItem.transactionId = item.transactionId
        }

        return paymentItem
      })

      if (expiryDate) {
        data.expiryDate = moment(expiryDate).format(`YYYY-MM-DD`)
      }
      if (effectiveDate) {
        data.effectiveDate = moment(effectiveDate).format(`YYYY-MM-DD`)
      }

      if (issueDate) {
        data.issueDate = moment(issueDate).format(`YYYY-MM-DD`)
      }

      if (dueDate) {
        data.dueDate = moment(dueDate).format(`YYYY-MM-DD`)
      }
      if (policyPremium) {
        data.priceSold = parseFloat(policyPremium)
      }

      if (carrier?.value?.length) {
        data._carrier = carrier?.value
      }
    }
    return data
  }

  useEffect(() => {
    setActiveFeeTab(fTabs.length)
  }, [fTabs.length])

  useEffect(() => {
    setActivePaymentMethodTab(pTabs.length)
  }, [pTabs.length])

  const premium = [
    `NB EFT TO COMPANY`,
    `END HOMEOWNER PREM`,
    `NB HOMEOWNER PREMIUM`,
    `PREMIUM TO ROBERT MKORENO`,
    `END COMMERCIAL EFT`,
    `MP EFT TO COMPANY`,
    `MP HOMEOWNER PREMIUM`,
    `Other`,
    `MP COMMERCIAL EFT`,
    `NB COMMERCIAL CHECK TO CO`,
    `END EFT TO COMPANY`,
    `RENEWAL PREMIUM`,
    `END COMMERCIAL CHECK TO CO`,
    `INSURED REFUND PREMIUM`,
    `NB COMMERCIAL EFT`,
    `RE-INSTATE PREMIUM`,
  ]

  const handlePolicyNo = (value) => {
    setPolicyNo(value)
    if (value) validateName(value)
  }

  const ValidateDuplicatePolicyNo = async (value) => {
    if (value) {
      return await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/validate/policy-payloads`,
        {
          policyNo: value,
        },
      )
        .then((response) => {
          return response?.data
        })
        .catch((error) => {
          return error?.response?.data
        })
    }
  }

  const validateName = useDebouncedCallback(async (value) => {
    if (value) {
      setValidatingPolicyNo(true)
      const response = await ValidateDuplicatePolicyNo(value)
      if (response?.hasError) {
        setValidatingPolicyNo(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`policyNoError`, `Policy No Already Exists`)
      } else {
        setValidatingPolicyNo(false)
        handleErrors(`formSubmit`, false)
        handleErrors(`policyNoError`, ``)
      }
    }
  }, 2500)
  // call custom hook to get sum of fees amount
  const totalAmountToBePaid = paymentAmountTotal(items)

  const totalRemainingAmountToBePaid = paymentAmountTotal(paymentItems)
  const paymentByCreditCard = paymentItems.filter((types, ind) => {
    if (
      types.paymentMethod?.value?.includes(`CREDIT`) &&
      !types?.confirmRefNo?.length
    ) {
      types.index = ind
      return types
    }
  })

  useEffect(() => {
    if (stripeToken?.length) {
      const isCreditPaymentsDone = stripeToken.every(
        (item) => item?.paymentConfirmId?.length,
      )
      const transactionTypesDone = paymentItems.every((type) =>
        !type?.paymentMethod?.value?.includes(`CREDIT`)
          ? true
          : type?.paymentMethod?.value?.includes(`CREDIT`) &&
            type?.confirmRefNo?.length,
      )
      if (
        isCreditPaymentsDone &&
        transactionTypesDone &&
        !isPaymentDoneRef?.current
      ) {
        isPaymentDoneRef.current = true
        mutate(updatePayload())
      }
    }
  }, [stripeToken, paymentItems])

  return (
    <Container>
      <Spinner
        loading={
          loadingQuote ||
          isFetching ||
          loadingStripe ||
          isLoading ||
          confirmStripeLoader
        }
      />
      <PageHeader className="flex justify-between items-center px-6">
        <div>Update Quote Status</div>
      </PageHeader>
      {/* Franchise basic forms */}

      {error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <div
          className={`px-4 py-1 ${
            (loadingStripe ||
              isFetching ||
              loadingQuote ||
              isLoading ||
              confirmStripeLoader) &&
            ` opacity-30 pointer-events-none`
          }`}
        >
          <div className="flex gap-6 pt-2">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Status"
                placeholder="Select Status"
                errorMessage={errors.quoteStatusError}
                value={quoteStatus}
                setValue={(e) => setQuoteStatus(e)}
                isMulti={false}
                loadOptions={loadStatusOptions}
                isSearchable={false}
              />
            </div>
          </div>

          {quoteStatus?.value === `SOLD` && (
            <>
              <div className="flex gap-6 pt-2">
                <div className="flex-1 relative">
                  <InputField
                    type="text"
                    title="Policy No *"
                    placeholder="Enter Policy No"
                    errorMessage={errors.policyNoError}
                    value={policyNo}
                    setValue={(e) => handlePolicyNo(e.target.value)}
                    maxLength={20}
                    validating={validatingPolicyNo}
                  />
                </div>
                <div className="flex-1 relative">
                  <InputField
                    type="number"
                    title="Premium Amount *"
                    placeholder="Enter Premium"
                    errorMessage={errors.premiumError}
                    value={policyPremium}
                    setValue={(e) => setPolicyPremium(e.target.value)}
                  />
                </div>
                <div className="flex-1 relative">
                  <ReactSelectField
                    title="Carrier *"
                    placeholder="Select Carrier"
                    errorMessage={errors.carrierError}
                    value={carrier}
                    setValue={(e) => setCarrier(e)}
                    isMulti={false}
                    loadOptions={loadAllCarriers}
                    isSearchable={true}
                  />
                </div>
              </div>
              {/* Fees */}
              <div className="py-1 my-2">
                <SecondaryHeading>Fees</SecondaryHeading>
                {/* tabs here */}
                <div className="flex items-center">
                  <TabsHeaders
                    openTab={activeFeeTab}
                    tabs={fTabs}
                    getActiveTab={(val) => setActiveFeeTab(val)}
                    customTabs="YES"
                  />
                  <div className="px-2">
                    <AddFormButton
                      onClick={() => {
                        addItem()
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </AddFormButton>
                  </div>
                </div>
                {/* tabs end herer */}
                {items?.length > 0 &&
                  items?.map((item, index) => (
                    <FormTab
                      key={index}
                      currenttab={index + 1}
                      opentab={activeFeeTab}
                      className="border-2 border-gray-100 shadow-sm rounded-md relative"
                    >
                      <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                        <div className="flex-1">Fee {index + 1}</div>
                        <div className="flex-1">
                          <div className="float-right">
                            <RemoveDriver
                              onClick={() => {
                                if (!item?.disabled) {
                                  removeItem(index)
                                }
                                if (index > 0) setActiveFeeTab(index)
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} size="1x" />
                            </RemoveDriver>
                          </div>
                        </div>
                      </div>

                      <div
                        key={index}
                        className="grid grid-cols-2 px-2 pb-2 gap-6"
                      >
                        <div className="flex-1 relative">
                          <ReactSelectField
                            title="Memos Type *"
                            placeholder="Select Type"
                            value={item.feeType}
                            errorMessage={item.feeTypeError}
                            setValue={(e) => handleItems(e, `feeType`, index)}
                            isMulti={false}
                            loadOptions={loadPaymentFeeTypes}
                            isSearchable={true}
                            disabled={item?.disabled}
                            additionalFilters={items}
                          />
                        </div>
                        <div className="flex-1 relative">
                          <InputField
                            type="number"
                            title="Amount *"
                            placeholder="Enter Amount"
                            value={item.amount}
                            errorMessage={item.amountError}
                            setValue={(e) =>
                              handleItems(e.target.value, `amount`, index)
                            }
                            disabled={item?.disabled}
                          />
                        </div>
                      </div>
                    </FormTab>
                  ))}
                <div className="col-span-6 float-right ">
                  {totalAmountToBePaid ? (
                    <div>Total memos amount: ${totalAmountToBePaid}</div>
                  ) : null}
                  {/* </div> */}
                </div>
              </div>
              {/* Methods */}
              <div className="py-1 my-2">
                <SecondaryHeading>Payment Methods</SecondaryHeading>
                {/* tabs here */}
                <div className="flex items-center">
                  <TabsHeaders
                    openTab={activePaymentMethodTab}
                    tabs={pTabs}
                    getActiveTab={(val) => setActivePaymentMethodTab(val)}
                    customTabs="YES"
                  />
                  <div className="px-2">
                    <AddFormButton
                      onClick={() => {
                        addPaymentItem()
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </AddFormButton>
                  </div>
                </div>
                {/* tabs end herer */}
                {paymentItems?.length > 0 &&
                  paymentItems?.map((item, index) => (
                    <FormTab
                      key={index}
                      currenttab={index + 1}
                      opentab={activePaymentMethodTab}
                      className="border-2 border-gray-100 shadow-sm rounded-md relative"
                    >
                      <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                        <div className="flex-1">Method {index + 1}</div>
                        <div className="flex-1">
                          {!item?.confirmRefNo && !item?.transactionId && (
                            <div className="float-right">
                              <RemoveDriver
                                onClick={() => {
                                  if (!item?.disabled) {
                                    removePaymentItem(index)
                                  }
                                  if (index > 0)
                                    setActivePaymentMethodTab(index)
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} size="1x" />
                              </RemoveDriver>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        key={index}
                        className="grid grid-rows-2 px-2 pb-2 gap-2"
                      >
                        <div className="grid grid-cols-2 gap-6">
                          <div className="flex-1 relative">
                            <ReactSelectField
                              title="Payment Method *"
                              placeholder="Select Method"
                              value={item.paymentMethod}
                              setValue={(e) =>
                                handlePaymentItems(e, `paymentMethod`, index)
                              }
                              errorMessage={item.paymentMethodError}
                              isMulti={false}
                              loadOptions={loadPaymentMethods}
                              isSearchable={true}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <InputField
                              type="number"
                              title="Amount *"
                              placeholder="Enter Amount"
                              value={item?.amount}
                              errorMessage={item.paidAmountError}
                              setValue={(e) =>
                                handlePaymentItems(
                                  e.target.value,
                                  `amount`,
                                  index,
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-6">
                          <div className="flex-1 relative">
                            <InputField
                              type="text"
                              title={`${
                                item?.paymentMethod?.label == `CASH` ||
                                item?.paymentMethod?.label?.includes(`CREDIT`)
                                  ? `Confirm Ref No`
                                  : `Confirm Ref No *`
                              }`}
                              placeholder="Enter Number"
                              value={item.confirmRefNo}
                              errorMessage={item.confirmRefNoError}
                              setValue={(e) =>
                                handlePaymentItems(
                                  e.target.value,
                                  `confirmRefNo`,
                                  index,
                                )
                              }
                              maxLength={10}
                              disabled={item?.paymentMethod?.label?.includes(
                                `CREDIT`,
                              )}
                            />
                          </div>
                          <div className="flex-1 relative">
                            <InputField
                              type="text"
                              title="Payment Note"
                              placeholder="Enter..."
                              value={item.paymentNote}
                              setValue={(e) =>
                                handlePaymentItems(
                                  e.target.value,
                                  `paymentNote`,
                                  index,
                                )
                              }
                              maxLength={255}
                            />
                          </div>
                        </div>
                      </div>
                    </FormTab>
                  ))}
              </div>
              <div className="block text-right">
                {totalAmountToBePaid ? (
                  <div>
                    Remaining amount: $
                    {(
                      totalAmountToBePaid - totalRemainingAmountToBePaid
                    )?.toFixed(2)}
                  </div>
                ) : null}
                {/* </div> */}
              </div>
              <div className="flex gap-6 pt-2">
                <div className="flex-1 relative">
                  <DatePickerField
                    name="effectDate"
                    title="Effective Date *"
                    placeholder="Pick Date"
                    value={effectiveDate}
                    errorMessage={errors.effectiveDateError}
                    setValue={(date) => {
                      setEffectiveDate(date)
                    }}
                    maxDate={new Date()}
                  />
                </div>
                <div className="flex-1 relative">
                  <DatePickerField
                    name="expiryDate"
                    title="Expiry Date *"
                    placeholder="Pick Date"
                    value={expiryDate}
                    errorMessage={errors.expiryDateError}
                    setValue={(date) => {
                      setExpiryDate(date)
                    }}
                    minDate={new Date()}
                  />
                </div>
              </div>
              <div className="flex gap-6 pt-2">
                <div className="flex-1 relative">
                  <DatePickerField
                    title="Issue Date *"
                    placeholder="Pick Date"
                    value={issueDate}
                    errorMessage={errors.issueDateError}
                    setValue={(date) => {
                      setIssueDate(date)
                    }}
                    maxDate={dueDate}
                  />
                </div>
                <div className="flex-1 relative">
                  <DatePickerField
                    title="Due Date *"
                    placeholder="Pick Date"
                    value={dueDate}
                    errorMessage={errors.dueDateError}
                    setValue={(date) => {
                      setdueDate(date)
                    }}
                    minDate={issueDate}
                  />
                </div>
              </div>
            </>
          )}
          <div className="py-2 text-right">
            <StyledButton
              onClick={ValidateForm}
              disabled={
                isLoading || loadingQuote || previewData?.status == `POLICY`
              }
            >
              <div className="flex gap-2 text-white">
                <span>Update Quote</span>
                {isLoading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    className="my-auto"
                  />
                )}
              </div>
            </StyledButton>
          </div>
        </div>
      )}
      {stripeToken?.length > 0 &&
        stripeToken.map((token, index) => (
          <div key={index} className="flex">
            <Elements
              stripe={stripePromise}
              options={token}
              key={token?.clientSecret}
            >
              <StripeForm
                openModal={!token.isPaymentDone}
                setOpenModal={setStripeToken}
                stripeTokenIndex={index}
                stripeAmount={token?._amount}
                transactionId={token?.transactionId}
                setValue={(e) => {
                  // set confirmRefNo w.r.t its index store when generating tokens
                  if (e) {
                    handlePaymentItems(
                      e,
                      `confirmRefNo`,
                      token?.transactionIndex,
                    )
                    handlePaymentItems(
                      token?.transactionId,
                      `transactionId`,
                      token?.transactionIndex,
                    )
                  } else {
                    handleErrors(`formSubmit`, false)
                  }
                }}
                setConfirmStripeLoader={setConfirmStripeLoader}
              />
            </Elements>
          </div>
        ))}
    </Container>
  )
}
