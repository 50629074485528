import { PDFViewer } from '@react-pdf/renderer'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { setCurrentDailyLedger } from 'App/Redux/actions'
import { Container } from 'App/Styled'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { PdfPreview } from './PdfPreview'
import { DailyLedgerFilter } from './filters'
import { permissionGranted } from 'App/Services'
import { Spinner } from 'App/Components/Common/Spinner'
import { useEffect } from 'react'
import moment from 'moment'

export const DailyLedger = () => {
  const user = useSelector(({ user: { user } }) => user)
  const [ledgerData, setLedgerData] = useState(null)

  const franchise = {
    value: user?.profile?._franchise?._id ?? ``,
    label: user?.profile?._franchise?.name ?? ``,
  }

  const dispatch = useDispatch()

  const [quoteFilters, setQuoteFilters] = useState({
    franchise: permissionGranted([
      `super-admin:*`,
      `corporate-manager:*`,
      `accountant-master:*`,
    ])
      ? ``
      : franchise,
    location: ``,
    carrier: ``,
    agent: ``,
  })
  const [pdfViewerKey, setPdfViewerKey] = useState(0)

  const handleResetFilters = () => {
    setQuoteFilters({
      franchise: permissionGranted([
        `super-admin:*`,
        `corporate-manager:*`,
        `accountant-master:*`,
      ])
        ? ``
        : franchise,
      location: ``,
      carrier: ``,
      agent: ``,
    })
  }

  const handleFiltersChange = (filters) => {
    setQuoteFilters(filters)
  }

  const returnFiltersAsQueryParams = () => {
    return `&_franchise=${quoteFilters?.franchise?.value ?? ``}&_carrier=${
      quoteFilters?.carrier?.value ?? ``
    }&_location=${quoteFilters?.location?.value ?? ``}&_agentId=${
      quoteFilters?.agent?.value ?? ``
    }`
  }
  const {
    // data: ledgerData,
    isLoading,
    // error,
    isError,
    mutate: loadLedger,
    isFetching,
  } = useMutation(
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/reports/daily-ledger?search=${searchFilters()}&sort=1&page=1&offset=200${returnFiltersAsQueryParams()}`,
      ).then((res) => {
        setPdfViewerKey((prevState) => prevState + 1)
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setQuoteFilters
        setLedgerData(res)
        dispatch(setCurrentDailyLedger(res))
      },
    },
  )

  //  date range picker
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
    },
  ])

  const handleDateRange = (dateRange) => {
    setDateRange(dateRange)
  }

  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }

    if (dateRange[0].startDate) {
      data.filters.dateRange = [
        `${moment(dateRange[0].startDate).format(`YYYY-MM-DD`)}`,
        `${moment(dateRange[0].endDate).format(`YYYY-MM-DD`)}`,
      ]
    }
    return JSON.stringify(data)
  }

  useEffect(() => {
    loadLedger()
  }, [quoteFilters, dateRange])

  return (
    <div>
      <Container>
        <Spinner loading={isLoading || isFetching} />
        <DailyLedgerFilter
          ledgerFilters={quoteFilters}
          handleFilters={handleFiltersChange}
          resetFilters={handleResetFilters}
          ledgerData={ledgerData}
          daterange={dateRange}
          handleDateChange={handleDateRange}
          filtersInvoking={{ searchFilters, returnFiltersAsQueryParams }}
        />

        <>
          <div className="p-3 bg-white ">
            <div className="mt-2">
              {isError ? (
                <Reload refetch={() => loadLedger()} />
              ) : ledgerData?.byPaymentMethod?.length ||
                ledgerData?.paymentsByType?.length ||
                ledgerData?.paymentsByAgent?.length ||
                ledgerData?.paymentsByFeeType?.length ? (
                <div
                  className={`px-2 bg-white ${
                    (isLoading || isFetching) &&
                    ` opacity-30 pointer-events-none`
                  } `}
                >
                  <Spinner loading={isLoading || isFetching} />
                  <PDFViewer key={pdfViewerKey} width="100%" height="750px">
                    <PdfPreview
                      paymentsByPaymentMethod={ledgerData?.byPaymentMethod}
                      paymentsByType={ledgerData?.paymentByfeePN}
                      paymentsByAgent={ledgerData?.paymentByUser}
                      paymentsByFeeType={ledgerData?.paymentByfeePremium}
                      report2={ledgerData?.report2}
                      report3={ledgerData?.report3}
                      dateRange={dateRange}
                    />
                  </PDFViewer>
                </div>
              ) : (
                <div className="text-center p-10">
                  <p>No Report In Given Date Range</p>
                </div>
              )}
            </div>
          </div>
        </>
      </Container>
    </div>
  )
}
