import React, { useContext } from 'react'
import { Container, EditStyledButton } from 'App/Styled'
import { CardField } from 'App/Components/Common/CardField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouteMatch, useHistory } from 'react-router'
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { Reload } from 'App/Components/Common/Reload'
import { AllPayments } from 'App/Components/Payment/AllPayments'
import { AllPolicies } from 'App/Components/Policies/AllPolicies'
import { CustomerContext } from '../../..'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import { permissionGranted } from 'App/Services'
import dateFormat from 'App/Services/General/dateFormat'
import { SchedulePayments } from 'App/Components/Payment/SchedulePayments'

export const About = () => {
  const {
    url,
    params: { customerId },
  } = useRouteMatch()

  const history = useHistory()
  const { currentCustomer } = useSelector(({ customer }) => customer)
  const { isLoading, isFetching, refetch, error } = useContext(CustomerContext)
  return (
    <>
      <Container>
        <div className="flex flex-col gap-0 ml-16">
          <div className="grid grid-cols-12 px-4 py-2">
            <div className="col-span-6 text-xl">Customer Info</div>
            {(!isLoading || !isFetching) && (
              <div className="col-span-6 justify-self-end">
                {permissionGranted([
                  `customers:update`,
                  `corporate-manager:*`,
                  `manager:*`,
                  `admin:*`,
                ]) && (
                  <EditStyledButton
                    onClick={() => history.push(`${url}/update`)}
                  >
                    <span className="mx-2">Edit</span>
                    <FontAwesomeIcon
                      icon={faPen}
                      className="text-white mt-0.5 "
                      size="sm"
                    />
                  </EditStyledButton>
                )}
              </div>
            )}
          </div>
          <hr />

          <div className="flex flex-col px-2">
            {isLoading || isFetching ? (
              <div className="text-center py-10">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="1x"
                  className="text-red-500"
                />
              </div>
            ) : error ? (
              <Reload refetch={() => refetch()} />
            ) : (
              <div className="flex flex-row">
                <div className="flex-1">
                  <div className="rounded-md overflow-hidden py-2">
                    <div className="flex items-start justify-evenly">
                      <div className="flex-1 mx-2 rounded">
                        <div className="flex">
                          <div className="flex-1">
                            {currentCustomer?.gender ? (
                              <CardField
                                title="Gender"
                                value={currentCustomer?.gender?.replace(
                                  `_`,
                                  ` `,
                                )}
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.educationLevel ? (
                              <CardField
                                title="Education"
                                value={currentCustomer?.educationLevel}
                              />
                            ) : (
                              ``
                            )}

                            {currentCustomer?.insuredId ? (
                              <CardField
                                title="Insured Id"
                                value={currentCustomer?.insuredId}
                              />
                            ) : (
                              ``
                            )}

                            {/* <CardField
                              title="Franchise"
                              value={currentCustomer?._location?.franchiseName}
                            /> */}
                            {currentCustomer?.contactInfo?.workPhone ? (
                              <CardField
                                title="Work Phone"
                                value={currentCustomer?.contactInfo?.workPhone}
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.contactInfo?.workPhoneExt ? (
                              <CardField
                                title="Work Phone Ext"
                                value={
                                  currentCustomer?.contactInfo?.workPhoneExt
                                }
                              />
                            ) : (
                              ``
                            )}

                            {currentCustomer?.contactInfo?.fax ? (
                              <CardField
                                title="Fax"
                                value={currentCustomer?.contactInfo?.fax}
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.preferredLanguage ? (
                              <CardField
                                title="Language"
                                value={currentCustomer?.preferredLanguage}
                              />
                            ) : (
                              ``
                            )}

                            {currentCustomer?.dob ? (
                              <CardField
                                title="Date of birth"
                                value={
                                  currentCustomer?.dob &&
                                  dateFormat(currentCustomer?.dob)
                                }
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.preferredContactMethods.length ? (
                              <CardField
                                title="PCM"
                                value={currentCustomer?.preferredContactMethods}
                                multiple={true}
                              />
                            ) : (
                              ``
                            )}
                          </div>
                          <div className="flex-1">
                            {currentCustomer?.fein ? (
                              <CardField
                                title="FEIN"
                                value={currentCustomer?.fein}
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.sic ? (
                              <CardField
                                title="SIC"
                                value={currentCustomer?.sic}
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.typeOfBusiness ? (
                              <CardField
                                title="Type of Business"
                                value={currentCustomer?.typeOfBusiness}
                              />
                            ) : (
                              ``
                            )}

                            {currentCustomer?.maritalStatus ? (
                              <CardField
                                title="Marital Status"
                                value={currentCustomer?.maritalStatus}
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.legacyId ? (
                              <CardField
                                title="Nowcerts Id"
                                value={currentCustomer?.legacyId}
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.occupation ? (
                              <CardField
                                title="Occupation"
                                value={currentCustomer?.occupation}
                              />
                            ) : (
                              ``
                            )}
 
                            {currentCustomer?.type ? (
                              <CardField
                                title="Customer Type"
                                value={currentCustomer?.type}
                              />
                            ) : (
                              ``
                            )}

                            {currentCustomer?.yearBusinessStarted ? (
                              <CardField
                                title="Years of Business Started"
                                value={currentCustomer?.yearBusinessStarted}
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.address?.coordinates ? (
                              <CardField
                                title="Coordinates"
                                value={currentCustomer?.address?.coordinates}
                                coord={true}
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.acquisitionDate ? (
                              <CardField
                                title="Acquisition Date"
                                value={
                                  currentCustomer?.acquisitionDate &&
                                  dateFormat(currentCustomer?.acquisitionDate)
                                }
                              />
                            ) : (
                              ``
                            )}
                            {currentCustomer?.createdAt ? (
                              <CardField
                                title="Created"
                                value={dateFormat(currentCustomer?.createdAt)}
                              />
                            ) : (
                              ``
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="ml-12 mr-10 pb-3">
          <div className="mt-2 ">
            <CollapsableRef title="Policies">
              <AllPolicies headerColor="yes" />
            </CollapsableRef>
          </div>
          <div className="my-2 ">
            <CollapsableRef title="Payments">
              <AllPayments
                customerId={customerId}
                preferredContactMethods={
                  currentCustomer?.preferredContactMethods
                }
              />
            </CollapsableRef>
          </div>
          {/* Schedule Payments details */}
          <div className="my-2 ">
            <CollapsableRef title="Schedule Payments">
              <SchedulePayments customerId={customerId} />
            </CollapsableRef>
          </div>
        </div>
      </Container>
    </>
  )
}
