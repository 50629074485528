import React from 'react'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { useRouteMatch } from 'react-router'
import { CardField } from 'App/Components/Common/CardField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Container, PageHeader, EditStyledButton } from 'App/Styled'
import { AxiosInstance, AMS_BACKEND_API } from 'App/Config'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCurrentCredential } from 'App/Redux/actions'
import { permissionGranted } from 'App/Services'

export const AboutCredentials = () => {
  const {
    url,
    params: { credentialId },
  } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    data: credentialData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `CredentialPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/credentials/${credentialId}`,
      ).then((res) => res.data?.data?.credential),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentCredential(res))
      },
    },
  )
  return (
    <Container>
      <PageHeader>
        <div>Credential</div>
        {!isLoading &&
          permissionGranted([
            `credentials:update`,
            `credentials:*`,
            `credential-master:*`,
            `corporate-manager:*`,
            `manager:*`,
          ]) && (
            <EditStyledButton onClick={() => history.push(`${url}/update`)}>
              <span>Edit</span>
              <FontAwesomeIcon
                icon={faPen}
                className="text-white mt-0.5"
                size="sm"
              />
            </EditStyledButton>
          )}
      </PageHeader>
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <>
          <div className="grid grid-cols-12 px-4 py-2">
            <div className="col-span-6 text-xl">Info</div>
          </div>
          <hr />
          <div className="flex py-2 px-4">
            <div className="flex-1">
              <CardField
                title="Carrier"
                value={credentialData?._carrierId?.label}
              />
              {credentialData?.authData && (
                <CardField
                  title="User Name"
                  value={credentialData?.authData?.username}
                />
              )}

              {credentialData?.authData?.password &&
                permissionGranted([
                  `password:read`,
                  `corporate-manager:*`,
                  `manager:*`,
                  `admin:*`,
                ]) && (
                  <CardField
                    title="Password"
                    value={
                      credentialData?.authData?.password
                        ? credentialData.authData.password
                        : ``
                    }
                    isShowPassword={true}
                  />
                )}
              <CardField
                title="Producer Code"
                value={`${credentialData?.producerCode}`}
              />
              <CardField title="Comments" value={credentialData?.comments} />
            </div>

            <div className="flex-1">
              <CardField title="Credential Name" value={credentialData?.name} />
              <CardField title="Url" value={credentialData?.url} link={true} />

              <CardField
                title="Franchises"
                value={credentialData?.franchises[0]?.label}
              />

              <CardField
                title="Users"
                value={credentialData?.users?.map((user) => user.label)}
                multiple={true}
              />
            </div>
          </div>
        </>
      )}
    </Container>
  )
}
