import { permissionGranted } from 'App/Services'
import React from 'react'
import { TabUl, TabLi, TabItem } from './style'

export const TabsHeaders = ({
  openTab,
  tabs,
  getActiveTab,
  customTabs,
  isCustomColor,
}) => {
  return (
    <TabUl role="tablist" customtabs={customTabs}>
      {tabs?.map((tab, index) => {
        return (
          (permissionGranted([`corporate-manager:*`, `manager:*`, `admin:*`]) ||
            permissionGranted(tab?.permission)) && (
            <TabLi key={index} customtabs={customTabs}>
              <TabItem
                opentab={openTab}
                tnumber={tab.tNumber}
                onClick={(e) => {
                  e.preventDefault()
                  getActiveTab(tab.tNumber)
                }}
                data-toggle="tab"
                role="tablist"
                isCustomColor={isCustomColor}
              >
                {tab.tName}
              </TabItem>
            </TabLi>
          )
        )
      })}
    </TabUl>
  )
}
