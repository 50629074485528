import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { setContactActivityActiveTab } from 'App/Redux/actions'
import { TOne, TTwo } from 'App/Styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { ContactNote } from '../../ContactNotes'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  {
    name: `Logs`,
    number: 2,
  },
]

export const ContactActivity = () => {
  const { customerContacts, contactActiveActivityTab } = useSelector(
    ({ asset }) => asset,
  )
  const dispatch = useDispatch()
  return (
    <div className="px-2">
      <Tabs2
        openTab={contactActiveActivityTab}
        tabs={activityTabs}
        getActiveTab={(val) => dispatch(setContactActivityActiveTab(val))}
      />
      <div className="tab-content tab-space">
        <TOne opentab={contactActiveActivityTab}>
          <ContactNote />
        </TOne>
        <TTwo opentab={contactActiveActivityTab}>
          <UpdateLogs
            logs={
              customerContacts?.updateLogs ? customerContacts?.updateLogs : []
            }
          />
        </TTwo>
      </div>
    </div>
  )
}
