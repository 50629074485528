import React, { Fragment, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { useDebouncedCallback } from 'use-debounce/lib'
import { Reload } from 'App/Components/Common/Reload'
import { AxiosInstance, offset } from 'App/Config'
import { AMS_BACKEND_API } from 'App/Config'
import { isSuperUser, permissionGranted } from 'App/Services'
// import { useSelector } from 'react-redux'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faSortUp,
  faSortDown,
  faPlusCircle,
  faTrash,
  faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'

const tableColumns = [
  { label: `ID`, value: `_groupId` },
  { label: `Name`, value: `name` },
  { label: `Created`, value: `createdAt` },
]

const AllGroupCredentials = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [active, setActive] = useState(false)
  const [sort, setSort] = useState(``)
  const [collapseIndex, setCollapseIndex] = useState(``)

  const history = useHistory()
  const { url } = useRouteMatch()

  // fetching all group carrier
  const {
    isLoading,
    data: groupCredentialData,
    error,
    refetch: getAllGroupCredential,
    isFetching,
  } = useQuery(
    `GroupCredentialApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/credentials-groups?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  // delete franchise
  const {
    mutate: deleteGroupCredential,
    isLoading: deletingGroupCredential,
    // isError: deletionError,
    error: reponseError,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/credentials-groups/${id}`,
      ).then((res) => {
        NotificationManager.success(`Deleted Successfuly`)
        res.data && getAllGroupCredential()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (err) => {
        if (
          err?.response?.data?.message.includes(
            `Requested operation failed. Can't delete Group`,
          )
        )
          NotificationManager.error(
            `Operation Fail, Group Credential is currently in use`,
          )
        else
          NotificationManager.error(reponseError?.response?.data?.error?.error)
      },
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllGroupCredential()
    }
  }, [sort, currentPage, isOffset])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const callFranchiseQuery = useDebouncedCallback(() => {
    getAllGroupCredential()
  }, 700)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    {
      deleteGroupCredential(id)
    }
  }
  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (collapseIndex !== id) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }

  return (
    <Container>
      <Spinner loading={isLoading || isFetching || deletingGroupCredential} />
      <PageHeader>
        <div>Group Credentials</div>
        <div className="flex items-center">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callFranchiseQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `credentials-group:create`,
          `credentials-group:*`,
          `credential-master:*`,
        ]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 pt-1 ${
          (isLoading || isFetching || deletingGroupCredential) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllGroupCredential()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            {item.label === `Credentials` ? (
                              ``
                            ) : (
                              <div
                                className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                                onClick={() => handleSorting(`-` + item.value)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    sort.includes(`-`) &&
                                    sort == `-` + item.value
                                      ? faSortUp
                                      : faSortDown
                                  }
                                  size="lg"
                                  className={`${
                                    sort.includes(`-`) &&
                                    sort == `-` + item.value
                                      ? `text-red-500`
                                      : `-mt-1.5`
                                  }`}
                                />
                              </div>
                            )}
                          </div>
                        </TableCell>
                      )
                    })}
                    {(isSuperUser(`super-admin:*`) ||
                      isSuperUser(`system-admin:*`)) && (
                      <TableCell className="text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {groupCredentialData?.groups?.groups?.length != 0 ? (
                    groupCredentialData?.groups?.groups?.map((group, index) => (
                      <Fragment key={index}>
                        <TableRow
                          key={index.toString()}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            history.push(`${url}/${group._id}`)
                          }}
                        >
                          <td className="px-4 py-0.5 text-sm z-0 capitalize truncate">
                            {group?._groupId ? (
                              group?._groupId
                            ) : (
                              <Badge>{index + 1}</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm z-0 capitalize truncate">
                            {group?.name ? group?.name : <Badge>N/A</Badge>}
                          </td>

                          <td className="px-4 py-0.5 z-0 text-sm">
                            <span>{dateFormat(group.createdAt)}</span>
                          </td>
                          {/* Actions TD */}
                          <td className="px-8 py-0.5 z-0 text-sm flex float-right text-gray-400">
                            {permissionGranted([
                              `credentials-group:delete`,
                              `credentials-group:*`,
                              `credential-master:*`,
                            ]) && (
                              <td className=" py-3 z-0 text-sm flex float-right text-gray-400">
                                {group?.carriers?.length > 1 && (
                                  <span data-tip="Expand" className="tooltip">
                                    <FontAwesomeIcon
                                      className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                        collapseIndex === index &&
                                        `rotate-180 text-blue-600`
                                      }`}
                                      icon={faChevronCircleDown}
                                      onClick={(e) => {
                                        setActive(!active)
                                        handleCollapse(index, e)
                                      }}
                                    />
                                  </span>
                                )}
                                <span data-tip="Delete" className="tooltip">
                                  <FontAwesomeIcon
                                    className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                    icon={faTrash}
                                    size="1x"
                                    onClick={(e) => handleDelete(group._id, e)}
                                  />
                                </span>
                              </td>
                            )}
                          </td>
                        </TableRow>
                        {collapseIndex === index && (
                          <TableRow>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="px-4 py-0.5 text-sm z-0 max-w-sm">
                              {group?.carriers &&
                                group?.carriers?.map((carrier, idx) => (
                                  <Badge
                                    key={idx}
                                    type="success"
                                    className="ml-1 mt-2"
                                  >
                                    {carrier?.label}
                                  </Badge>
                                ))}
                            </td>
                          </TableRow>
                        )}
                      </Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Data Found</div>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </table>
            </div>

            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={groupCredentialData?.groups?.totalPages}
                totalRecords={groupCredentialData?.groups?.totalRecords}
                curerntPageRecords={
                  groupCredentialData?.groups?.currentPageRecords
                }
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
export default AllGroupCredentials
