export const loadVerifyOptions = () => {
    return {
        options: [
            {
                value: `PENDING`,
                label: `PENDING`,
            },
            {
                value: `SUCCESS`,
                label: `SUCCESS`,
            },
            {
                value: `REJECT`,
                label: `REJECT`,
            },
        ],
        hasMore: false,
    }
}