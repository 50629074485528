import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from '../style'
import { PolicyReportPdfHeader } from 'App/Components/Common/PolicyReportPdfHeader'
import dateFormat from 'App/Services/General/dateFormat'

export const Pdf = ({ expiredPolicies, dateRange }) => {
  return (
    <Document subject="Report" author="Reports">
      <Page style={styles.page} size="A3" wrap>
        <View style={styles.section}>
          <Text style={styles.pageHeading}>Expired Policies</Text>
          <Text style={styles.smallSpace}></Text>
          <PolicyReportPdfHeader dateRange={dateRange} />
          <Text style={styles.smallSpace}></Text>

          <View style={styles.headingRow}>
            <Text style={styles.pageTitles}> Insured</Text>
            <Text style={styles.pageTitles}> Policy Number</Text>
            <Text style={styles.pageTitles}> Exp. Date</Text>
            <Text style={styles.pageTitles}> Agency Comm</Text>
            <Text style={styles.pageTitles}> Carrier</Text>
            <Text style={styles.pageTitles}> Agents</Text>
          </View>

          {expiredPolicies.length > 0 &&
            expiredPolicies.map((expiredPolicies, index) => (
              <View style={styles.agent} key={index}>
                <View style={styles.headingRow2}>
                  <Text style={styles.pageTitles2}>
                    {expiredPolicies?.insured
                      ? expiredPolicies?.insured
                      : `N/A`}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {expiredPolicies?.policyNo}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {dateFormat(expiredPolicies?.expiryDate)}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {expiredPolicies?.companyCommission
                      ? `${expiredPolicies?.companyCommission?.toFixed(2)}`
                      : `-`}
                    {expiredPolicies?.companyCommission?.toFixed(2)}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {expiredPolicies?.carrier}
                  </Text>
                  <Text style={styles.pageTitles2}>
                    {expiredPolicies?.agencyCommission
                      ? `$ ${expiredPolicies?.agencyCommission?.toFixed(2)}`
                      : `-`}
                  </Text>
                </View>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  )
}
