export const loadMaritalStatusOptions = () => {
  const options = {
    options: [
      { value: `Married`, label: `Married` },
      { value: `Unmarried`, label: `Unmarried` },
    ],
    hasMore: false,
  }
  return options
}
