/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import {
  Container,
  FormTab,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import {
  loadAgents,
  loadPaymentFeeTypes,
  loadPaymentLocations,
  loadPaymentMethods,
  permissionGranted,
} from 'App/Services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { AMS_BACKEND_API, AxiosInstance, STRIPE_PUBLIC_KEY } from 'App/Config'
import { useDispatch } from 'react-redux'
import { setCurrentPayment } from 'App/Redux/actions'
import { loadFeePaymentTemplate } from 'App/Services/Payment/loadFeePaymentTemplate'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import {
  AddFormButton,
  RemoveDriver,
} from 'App/Components/Raters/QuoteForm/style'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import { paymentAmountTotal } from 'App/hooks'
import { StripeForm } from 'App/Components/Transactions/Inspection/Stripe'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

export const AddReciept = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  // const { system_permissions: systemRoles } = useSelector(
  //   ({ user }) => user?.user?.profile,
  // )
  // check if user is super admin or admin franchise
  // const isAdmin =
  //   systemRoles.includes(`admin-franchise`) ||
  //   systemRoles.includes(`super-admin:*`)
  const { firstName: firstName, lastName: lastName } = useSelector(
    ({ customer }) => customer?.currentCustomer,
  )
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)
  const customerName = {
    label: firstName + ` ${lastName}`,
    value: firstName + lastName,
  }
  const location = useSelector(({ user }) => user.preferredLocation)
  const preferredLocation = { label: location.name, value: location._id }
  const { sub: agentId, full_name: agentName } = useSelector(
    ({ user }) => user.user.profile,
  )
  let selectedAgent = { label: agentName, value: agentId }

  const [paymentTemplateList, setPaymentTemplateList] = useState([])
  const [stripeToken, setStripeToken] = useState([
    // {
    //   clientSecret: ``,
    // },
  ])
  const myDivRef = useRef(null)
  useEffect(() => {
    myDivRef.current.scrollTop = `-100px`
    // myDivRef.current.focus()
  }, [])

  const {
    params: { customerId, paymentId },
  } = useRouteMatch()

  const { data: previewData, isLoading: previewLoading } = useQuery(
    `InvoicePreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/invoices/${paymentId}`,
      ).then((res) => {
        return res.data.data.invoice
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (res?.paymentTempale) {
          setFeeTemplateList(res?.paymentTempale)
        } else {
          setFeeTemplateList({ value: 0, label: `NONE` })
        }
        dispatch(setCurrentPayment(res))
      },
    },
  )

  const [invoice, setInvoice] = useState({
    insured: ``,
    policy: ``,
    carrier: ``,
    dueDate: new Date(),
    description: ``,
  })

  const [feeTemplateList, setFeeTemplateList] = useState(``)
  const [policyAmount, setPolicyAmount] = useState(``)
  const [fees, setFees] = useState([
    {
      feeType: ``,
      feeTypeError: ``,
      amount: ``,
      amountError: ``,
    },
  ])
  const [confirmStripeLoader, setConfirmStripeLoader] = useState(false)

  const [transactions, setTransactions] = useState({
    transactionDate: new Date(),
    agent: selectedAgent,
    primaryOffice: preferredLocation,
  })

  const [transactionTypes, setTransactionTypes] = useState([
    {
      amount: ``,
      amountError: ``,
      paymentMethod: ``,
      paymentMethodError: ``,
      confirmRefNo: ``,
      confirmRefNoError: ``,
      paymentNote: ``,
    },
  ])

  // const [additionals, setAdditionals] = useState({
  //   customer: ``,
  //   salesIn: ``,
  //   salesType: ``,
  //   lastSeen: ``,
  // })

  const [errors, setErrors] = useState({
    formSubmit: false,
    insuredError: ``,
    policyError: ``,
    dueDateError: ``,
    carrierError: ``,
    agentError: ``,
    primaryOfficeError: ``,
    customerError: ``,
    salesInError: ``,
    salesTypeError: ``,
    lastSeenError: ``,
    amountMatchError: ``,
  })

  const [fTabs, setFTabs] = useState([
    {
      tName: `Fee 1`,
      tNumber: 1,
    },
  ])

  const [pTabs, setPTabs] = useState([
    {
      tName: `Method 1`,
      tNumber: 1,
    },
  ])

  const [activeFeeTab, setActiveFeeTab] = useState(1)
  const [activePaymentMethodeTab, setActivePaymentMethodeTab] = useState(1)

  const handleInvoice = (value, name, ind) => {
    if (ind !== undefined) {
      const newFees = fees?.map((fee, index) => {
        if (index === ind) {
          fee[name] = value
        }
        return fee
      })
      setFees(newFees)
    } else {
      setInvoice((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const { mutate: getPolicy, isLoading: loadingPolicy } = useMutation(
    `PolicyApi`,
    async (payload) =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/policies/minimal/${payload}`,
      ).then((res) => {
        return res.data.data.policy
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setPolicyAmount(res.remainingAmount)
        handleInvoice(
          { value: res?._creator?._id, label: res?._creator?.name },
          `agent`,
        )
        handleInvoice(
          { value: res?._customer?._id, label: res?._customer?.name },
          `insured`,
        )
        handleInvoice(
          { value: res?._carrier?._id, label: res?._carrier?.name },
          `carrier`,
        )
      },
    },
  )

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const handleFeesErrors = (name, error, ind) => {
    if (ind !== undefined) {
      const newFees = fees?.map((fee, index) => {
        if (index == ind) {
          fee[name] = error
        }
        return fee
      })
      setFees(newFees)
    }
  }
  const handleTransactionTypesErros = (name, error, ind) => {
    if (ind !== undefined) {
      const newTransactionTypes = transactionTypes?.map(
        (transaction, index) => {
          if (index == ind) {
            transaction[name] = error
          }
          return transaction
        },
      )
      setTransactionTypes(newTransactionTypes)
    }
  }

  const addTransactionType = () => {
    setTransactionTypes([
      ...transactionTypes,
      {
        paymentMethod: ``,
        paymentMethodError: ``,
        amount: ``,
        amountError: ``,
        confirmRefNo: ``,
        confirmRefNoError: ``,
        paymentNote: ``,
      },
    ])

    setPTabs([
      ...pTabs,
      {
        tName: `Method ${pTabs.length + 1}`,
        tNumber: pTabs.length + 1,
      },
    ])
  }

  const removeTransactionType = (ind) => {
    const newPaymentMethodeTab = [...pTabs]
    if (newPaymentMethodeTab.length !== 1) {
      newPaymentMethodeTab.pop()
      setPTabs(newPaymentMethodeTab)
    }
    const newTransactionType = [...transactionTypes]
    if (newTransactionType.length != 1) {
      newTransactionType.splice(ind, 1)
      setTransactionTypes(newTransactionType)
    }
  }

  const handleTransactions = (value, name, ind, transactionId) => {
    if (ind !== undefined) {
      const newTransactionTypes = transactionTypes?.map(
        (transactionType, index) => {
          if (index === ind) {
            transactionType[name] = value
            if (transactionId) {
              transactionType.transactionId = transactionId
            }
          }
          return transactionType
        },
      )
      setTransactionTypes(newTransactionTypes)
    } else {
      setTransactions((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/invoices/${paymentId}`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res.data) {
          NotificationManager.success(`Remaining Payment Added Successfully`)
          history.push(`/customers/${customerId}`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const { mutate: mutatePaymentTemplate, isLoading: isLoadingPaymentTemplate } =
    useMutation(
      async (templateId) =>
        await AxiosInstance.get(
          `${AMS_BACKEND_API}/api/payment-templates/${templateId}`,
        ).then((res) => {
          return res?.data?.data?.paymentTemplate
        }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (response) => {
          if (response) {
            setPaymentTemplateList(response?.templates)
          }
        },
      },
    )

  const { isLoading: loadingStripe, mutate: initiateStripe } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/payments/stripe`,
        payload,
      )
        .then((res) => {
          return res.data.data
        })
        .catch(() => NotificationManager.error(`Something is went wrong!`)),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        const tokenArray = res?.tokens?.map((res, ind) => ({
          clientSecret: res.token,
          _amount: res?.amount,
          isPaymentDone: false,
          transactionIndex: paymentByCreditCard[ind]?.index,
          transactionId: res?.transactionId,
        }))
        setStripeToken(tokenArray)
      },
    },
  )
  const updatePayload = () => {
    let data = {
      paidAmount: parseFloat(previewData?.paidAmount),
      remainingAmount: previewData?.remainingAmount,
      payments: {},
    }

    if (transactions.transactionDate) {
      data.payments.transactionDate = transactions.transactionDate
    }
    if (transactions?.agent?.value) {
      data.payments._agent = transactions.agent.value
    }
    //  else if (selectedAgent.value) {
    //   data.payments._agent = selectedAgent.value
    // }

    if (transactions.primaryOffice?.value) {
      data.payments._location = transactions.primaryOffice.value
    } else if (preferredLocation.value) {
      data.payments._location = preferredLocation.value
    }

    data.payments.paymentItems = transactionTypes.map((transactionType) => {
      const paymentItem = {
        paymentMethod: transactionType?.paymentMethod?.value,
        amount: parseFloat(transactionType?.amount),
        paymentNote: transactionType?.paymentNote,
      }

      if (transactionType?.confirmRefNo) {
        paymentItem.confirmRefNo = transactionType.confirmRefNo
      }
      if (transactionType?.transactionId) {
        paymentItem.transactionId = transactionType.transactionId
      }

      return paymentItem
    })
    return data
  }

  const ValidateForm = () => {
    let invoiceAmount = 0
    let transactionAmount = 0

    for (const index in fees) {
      const fee = fees[index]

      invoiceAmount = invoiceAmount + JSON.parse(fee.amount ? fee.amount : 0)

      if (fee.amount && invoiceAmount > previewData?.remainingAmount) {
        handleFeesErrors(
          `amountError`,
          `Payment Amount Must be less than Policy amount`,
          index,
        )
      }
    }

    if (!transactions.agent?.value?.length) {
      handleErrors(`agentError`, `Select Agent`)
    } else {
      handleErrors(`agentError`, ``)
    }

    if (
      !transactions.primaryOffice?.value?.length &&
      !preferredLocation.value
    ) {
      handleErrors(`primaryOfficeError`, `Select Office`)
    } else {
      handleErrors(`primaryOfficeError`, ``)
    }

    let transectionTotal = 0
    for (const index in transactionTypes) {
      const transactionType = transactionTypes[index]
      transactionAmount =
        transactionAmount +
        parseFloat(transactionType?.amount ? transactionType?.amount : 0)

      if (!transactionType.paymentMethod?.value?.length) {
        handleTransactionTypesErros(`paymentMethodError`, `Select Type`, index)
      } else {
        handleTransactionTypesErros(`paymentMethodError`, ``, index)
      }

      if (!transactionType.amount) {
        handleTransactionTypesErros(`amountError`, `Enter Amount`, index)
      } else if (
        transactionType.amount &&
        parseFloat(transactionType.amount) <= 0
      ) {
        handleTransactionTypesErros(`amountError`, `Enter Valid Amount`, index)
      } else {
        handleTransactionTypesErros(`amountError`, ``, index)
        transectionTotal += parseFloat(transactionType.amount)
      }

      if (
        !transactionType.confirmRefNo?.length &&
        transactionType?.paymentMethod?.value !== `CASH` &&
        !transactionType?.paymentMethod?.value?.includes(`CREDIT`)
      ) {
        handleTransactionTypesErros(`confirmRefNoError`, `Select Type`, index)
      } else {
        handleTransactionTypesErros(`confirmRefNoError`, ``, index)
      }
    }

    if (previewData?.remainingAmount < transectionTotal) {
      NotificationManager.error(`Paid amount must not be greater than fee.`)
      handleErrors(`amountMatchError`, `error`)
    } else {
      handleErrors(`amountMatchError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  const loadCustomerTypes = async () => {
    return {
      options: [
        { value: `new`, label: `New` },
        { value: `recurring`, label: `Recurring` },
      ],
      hasMore: false,
    }
  }

  const loadSalesInTypes = async () => {
    return {
      options: [
        { value: `inOffice`, label: `In Office` },
        { value: `call`, label: `Call` },
        { value: `sms`, label: `SMS` },
        { value: `online`, label: `Online` },
      ],
      hasMore: false,
    }
  }

  const loadSalesTypes = async () => {
    return {
      options: [
        { value: `inBound`, label: `In Bound` },
        { value: `outBound`, label: `Out Bound` },
      ],
      hasMore: false,
    }
  }

  const lasLastSeenTypes = async () => {
    return {
      options: [
        { value: `vieFriend`, label: `Via Friend` },
        { value: `vieAds`, label: `Via Ads` },
        { value: `vieSocialMedia`, label: `Via Social Media` },
      ],
      hasMore: false,
    }
  }

  useEffect(() => {
    if (
      feeTemplateList.label &&
      feeTemplateList?.label?.toUpperCase() != `NONE`
    ) {
      mutatePaymentTemplate(feeTemplateList?.value)
    } else {
      setFees([
        {
          feeType: ``,
          feeTypeError: ``,
          amount: ``,
          amountError: ``,
        },
      ])

      setFTabs([
        {
          tName: `Fee 1`,
          tNumber: 1,
        },
      ])
    }
  }, [feeTemplateList])

  useEffect(() => {
    let feeFormValidated = []
    let PaymentFormValidated = []
    let isFormValidated = false
    if (
      errors.formSubmit &&
      !errors.policyError?.length &&
      !errors.dueDateError?.length &&
      !errors.carrierError?.length &&
      !errors.invoiceAgentError?.length &&
      !errors.agentError?.length &&
      !errors.primaryOfficeError?.length &&
      !errors.customerError?.length &&
      !errors.salesInError?.length &&
      !errors.salesTypeError?.length &&
      !errors.lastSeenError?.length &&
      !errors.amountMatchError?.length
    ) {
      isFormValidated = true
    }

    if (isFormValidated) {
      feeFormValidated = fees?.map((fee) => {
        if (!fee.feeTypeError.length && !fee.amountError.length) {
          return true
        }
      })
      PaymentFormValidated = transactionTypes?.map((transaction) => {
        if (
          !transaction.paymentMethodError.length &&
          !transaction.amountError.length &&
          !transaction.confirmRefNoError.length
        ) {
          return true
        }
      })
    }

    if (
      !feeFormValidated.includes(undefined) &&
      !PaymentFormValidated.includes(undefined) &&
      isFormValidated
    ) {
      if (paymentByCreditCard?.length) {
        const stripePayLoad = paymentByCreditCard.map((types) => ({
          amount: parseInt(
            new Intl.NumberFormat()
              .format(parseFloat(types.amount) * 100)
              .replace(`,`, ``),
            10,
          ),
          customerId,
        }))
        initiateStripe(stripePayLoad)
      } else {
        //call API

        mutate(updatePayload())
        handleErrors(`formSubmit`, false)
      }
    }
  }, [errors])

  useEffect(() => {
    if (invoice.policy) {
      getPolicy(invoice?.policy?.value)
    }
  }, [invoice.policy])

  useEffect(() => {
    if (!paymentTemplateList.length) {
      setFees([
        {
          feeType: ``,
          feeTypeError: ``,
          amount: ``,
          amountError: ``,
        },
      ])

      setFTabs([
        {
          tName: `Fee 1`,
          tNumber: 1,
        },
      ])
    } else {
      const feesList = paymentTemplateList?.map((_fee) => ({
        feeType: { value: _fee?.type, label: _fee?.type },
        feeTypeError: ``,
        amount: _fee?.amount.toString(),
        amountError: ``,
        disabled: true,
      }))

      setFees(feesList)

      const feeTabs = previewData?.items?.map((_fee, index) => ({
        tName: `Fee ${index + 1}`,
        tNumber: index + 1,
      }))

      setFTabs([...feeTabs])
    }
  }, [paymentTemplateList?.length])
  let feeTabs = previewData?.items?.map((_fee, index) => ({
    tName: `Fee ${index + 1}`,
    tNumber: index + 1,
  }))

  useEffect(() => {
    setActiveFeeTab(fTabs.length)
  }, [fTabs.length])

  useEffect(() => {
    setActivePaymentMethodeTab(pTabs.length)
  }, [pTabs.length])
  // call custom hook to get sum of fees amount
  const totalAmountToBePaid = paymentAmountTotal(previewData?.items)

  const totalRemainingAmountToBePaid = paymentAmountTotal(transactionTypes)

  const paymentByCreditCard = transactionTypes.filter((types, ind) => {
    if (
      types.paymentMethod?.value?.includes(`CREDIT`) &&
      !types?.confirmRefNo?.length
    ) {
      types.index = ind
      return types
    }
  })

  useEffect(() => {
    if (stripeToken?.length) {
      const isCreditPaymentsDone = stripeToken.every(
        (item) => item?.paymentConfirmId?.length,
      )
      const transactionTypesDone = transactionTypes.every((type) =>
        !type?.paymentMethod?.value?.includes(`CREDIT`)
          ? true
          : type?.paymentMethod?.value?.includes(`CREDIT`) &&
            type?.confirmRefNo?.length,
      )
      if (isCreditPaymentsDone && transactionTypesDone) {
        mutate(updatePayload())
      }
    }
  }, [stripeToken, transactionTypes])

  return (
    <Container ref={myDivRef}>
      <Spinner
        loading={
          previewLoading || loadingStripe || isLoading || confirmStripeLoader
        }
      />
      <PageHeader>
        <div>Add Invoice Payment</div>
        <div className="col-span-6 justify-self-end text-base">
          {/* <div className="text-red-600">
            Total Amount:
            <span className="text-black"> ${previewData?.totalAmount}</span>
          </div> */}
          <div className="text-red-600">
            Remaining:
            <span className="text-black"> ${previewData?.remainingAmount}</span>
          </div>
        </div>
      </PageHeader>

      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <div
        className={`p-3 bg-white ${
          (previewLoading ||
            loadingStripe ||
            isLoading ||
            confirmStripeLoader) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        <PageHeader className="mt-4">
          <div>Invoice</div>
        </PageHeader>
        <div className="px-2">
          <div className="flex gap-6 mt-2">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Customer"
                placeholder="Select Customer"
                errorMessage={errors.insuredError}
                value={customerName}
                isMulti={false}
                // loadOptions={loadCustomers}
                disabled={true}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Policy*"
                placeholder="Select Policy"
                errorMessage={errors.policyError}
                value={previewData?._policy}
                setValue={(value) => handleInvoice(value, `policy`)}
                isMulti={false}
                // loadOptions={loadPaymentPolicies}
                validating={loadingPolicy}
                isSearchable={false}
                disabled={true}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Carrier*"
                placeholder="Select Carrier"
                errorMessage={errors.carrierError}
                value={previewData?._carrier}
                isMulti={false}
                disabled={true}
              />
            </div>

            <div className="flex-1 relative">
              <ReactSelectField
                title="Agent*"
                placeholder="Select Agent"
                errorMessage={errors.invoiceAgentError}
                value={previewData?._agent}
                isMulti={false}
                disabled={true}
              />
            </div>
          </div>
          <div className="flex gap-6 mt-2">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Description"
                value={previewData?.description}
                setValue={(e) => handleInvoice(e.target.value, `description`)}
                placeholder="Enter Value"
                disabled={true}
              />
            </div>
            <div className="flex-1 relative">
              <DatePickerField
                title="Due Date*"
                placeholder="Select Date"
                value={invoice.dueDate}
                errorMessage={errors.dueDateError}
                setValue={(date) => handleInvoice(date, `dueDate`)}
                minDate={new Date()}
                disabled={true}
              />
            </div>

            <div className="flex-1 relative">
              <ReactSelectField
                title="Fee Template"
                placeholder="Select Template"
                value={feeTemplateList}
                setValue={(value) => setFeeTemplateList(value)}
                isMulti={false}
                loadOptions={loadFeePaymentTemplate}
                validating={isLoadingPaymentTemplate}
                disabled={true}
              />
            </div>
            <div className="flex-1 relative"></div>
          </div>
          <SecondaryHeading>Fees</SecondaryHeading>
          {/* tabs here */}
          <div className="flex items-center">
            <TabsHeaders
              openTab={activeFeeTab}
              tabs={feeTabs}
              getActiveTab={(val) => setActiveFeeTab(val)}
              customTabs="YES"
            />
          </div>
          {/* tabs end herer */}
          {previewData?.items.length > 0 &&
            previewData?.items?.map((item, index) => (
              <FormTab
                key={index}
                currenttab={index + 1}
                opentab={activeFeeTab}
                className="border-2 border-gray-100 shadow-sm rounded-md relative"
              >
                <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                  <div className="flex-1">Fee {index + 1}</div>
                  <div className="flex-1">
                    <div className="float-right"></div>
                  </div>
                </div>

                <div className="grid grid-cols-12 px-2 pb-4">
                  <div className="col-span-12 flex gap-6 ">
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Memos Type*"
                        placeholder="Select Type"
                        value={{
                          label: item?.feeType,
                          value: item?.feeType,
                        }}
                        errorMessage={item.feeTypeError}
                        setValue={(e) => handleInvoice(e, `feeType`, index)}
                        isMulti={false}
                        loadOptions={loadPaymentFeeTypes}
                        isSearchable={true}
                        disabled={true}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="number"
                        title="Amount*"
                        placeholder="Enter Amount"
                        value={item?.amount}
                        errorMessage={item.amountError}
                        setValue={(e) =>
                          handleInvoice(e.target.value, `amount`, index)
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </FormTab>
            ))}

          <div className="col-span-6 float-right ">
            {totalAmountToBePaid ? (
              <div>Total memos amount: ${totalAmountToBePaid}</div>
            ) : null}
            {/* </div> */}
          </div>
        </div>

        <PageHeader className="mt-4">
          <div>Transactions</div>
        </PageHeader>
        <div className="px-2">
          <div className="flex gap-6 mt-2">
            <div className="flex-1 relative">
              <DatePickerField
                title="Transaction Date"
                placeholder="Select Date"
                value={transactions.transactionDate}
                setValue={(date) => handleTransactions(date, `transactionDate`)}
                disabled={true}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Agent *"
                placeholder="Select Agent"
                errorMessage={errors.agentError}
                value={transactions?.agent}
                setValue={(value) => handleTransactions(value, `agent`)}
                isMulti={false}
                loadOptions={permissionGranted([`admin:*`]) && loadAgents}
                cacheUniqs={transactions?.primaryOffice}
                disabled={!permissionGranted([`admin:*`])}
                additionalFilters={{
                  franchiseId: _franchise?._id,
                  locations: [transactions?.primaryOffice?.value],
                }}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Primary Office *"
                placeholder="Select Office"
                errorMessage={errors.primaryOfficeError}
                value={transactions?.primaryOffice}
                setValue={(value) => {
                  handleTransactions(value, `primaryOffice`)
                  handleTransactions(``, `agent`)
                }}
                isMulti={false}
                loadOptions={
                  permissionGranted([`admin:*`]) && loadPaymentLocations
                }
                isSearchable={true}
                disabled={!permissionGranted([`admin:*`])}
              />
            </div>
          </div>
        </div>

        <div className="px-2">
          <SecondaryHeading>Payment Methods</SecondaryHeading>
          {/* tabs here */}
          <div className="flex items-center">
            <TabsHeaders
              openTab={activePaymentMethodeTab}
              tabs={pTabs}
              getActiveTab={(val) => setActivePaymentMethodeTab(val)}
              customTabs="YES"
            />
            <div className="px-2">
              <AddFormButton
                onClick={() => {
                  addTransactionType()
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </AddFormButton>
            </div>
          </div>
          {/* tabs end herer */}
          {transactionTypes?.length > 0 &&
            transactionTypes?.map((item, index) => (
              <FormTab
                key={index}
                currenttab={index + 1}
                opentab={activePaymentMethodeTab}
                className="border-2 border-gray-100 shadow-sm rounded-md relative"
              >
                <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                  <div className="flex-1">Payment Method {index + 1}</div>
                  <div className="flex-1">
                    {!item?.confirmRefNo && !item?.transactionId && (
                      <div className="float-right">
                        <RemoveDriver
                          onClick={() => {
                            if (!item?.disabled) {
                              removeTransactionType(index)
                              if (index > 0) setActivePaymentMethodeTab(index)
                            }
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} size="1x" />
                        </RemoveDriver>
                      </div>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-12 px-2 pb-4">
                  <div className="col-span-12  flex gap-6 ">
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Payment Method*"
                        placeholder="Select Method"
                        value={item?.paymentMethod}
                        setValue={(e) =>
                          handleTransactions(e, `paymentMethod`, index)
                        }
                        errorMessage={item?.paymentMethodError}
                        isMulti={false}
                        loadOptions={loadPaymentMethods}
                        isSearchable={true}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="number"
                        title="Paid Amount*"
                        placeholder="Enter Amount"
                        value={item?.amount}
                        errorMessage={item?.amountError}
                        setValue={(e) =>
                          handleTransactions(e.target.value, `amount`, index)
                        }
                      />
                    </div>

                    <div className="flex-1 relative">
                      <InputField
                        type="text"
                        title={`${
                          item?.paymentMethod?.label == `CASH` ||
                          item?.paymentMethod?.label?.includes(`CREDIT`)
                            ? `Confirm Ref No`
                            : `Confirm Ref No *`
                        }`}
                        placeholder="Enter Number"
                        value={item?.confirmRefNo}
                        errorMessage={item?.confirmRefNoError}
                        setValue={(e) =>
                          handleTransactions(
                            e.target.value,
                            `confirmRefNo`,
                            index,
                          )
                        }
                        disabled={item?.paymentMethod?.label?.includes(
                          `CREDIT`,
                        )}
                      />
                    </div>

                    <div className="flex-1 relative">
                      <InputField
                        type="text"
                        title="Payment Note"
                        placeholder="Enter..."
                        value={item?.paymentNote}
                        setValue={(e) =>
                          handleTransactions(
                            e.target.value,
                            `paymentNote`,
                            index,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </FormTab>
            ))}
          <div className="col-span-6 float-right ">
            {previewData?.remainingAmount ? (
              <div>
                Remaining amount: $
                {(
                  previewData?.remainingAmount - totalRemainingAmountToBePaid
                ).toFixed(2)}
              </div>
            ) : null}
          </div>
        </div>

        {stripeToken?.length > 0 &&
          stripeToken.map((token, index) => (
            <div key={index} className="flex">
              <Elements
                stripe={stripePromise}
                options={token}
                key={token?.clientSecret}
              >
                <StripeForm
                  openModal={!token.isPaymentDone}
                  setOpenModal={setStripeToken}
                  stripeTokenIndex={index}
                  stripeAmount={token?._amount}
                  transactionId={token?.transactionId}
                  setValue={(e) => {
                    // set confirmRefNo w.r.t its index store when generating tokens

                    handleTransactions(
                      e,
                      `confirmRefNo`,
                      token?.transactionIndex,
                      token?.transactionId,
                    )
                  }}
                  setConfirmStripeLoader={setConfirmStripeLoader}
                />
              </Elements>
            </div>
          ))}

        {/* additionals */}
        <PageHeader className="mt-4">
          <div>Additional</div>
        </PageHeader>
        <div className="px-2">
          <div className="flex gap-6 mt-2">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Customer"
                placeholder="Select Customer"
                value={{
                  label: previewData?.additional?.customer
                    ? previewData?.additional?.customer
                    : `Disabled`,
                  value: previewData?.additional?.customer
                    ? previewData?.additional?.customer
                    : `Disabled`,
                }}
                errorMessage={errors.customerError}
                disabled={true}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Sales In"
                placeholder="Select Option"
                value={{
                  label: previewData?.additional?.salesIn
                    ? previewData?.additional?.salesIn
                    : `Disabled`,
                  value: previewData?.additional?.salesIn
                    ? previewData?.additional?.salesIn
                    : `Disabled`,
                }}
                errorMessage={errors.salesInError}
                disabled={true}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Where did customer remember seeing us last"
                placeholder="Select Option"
                value={{
                  label: previewData?.additional?.lastSeen
                    ? previewData?.additional?.lastSeen
                    : `Disabled`,
                  value: previewData?.additional?.lastSeen
                    ? previewData?.additional?.lastSeen
                    : `Disabled`,
                }}
                errorMessage={errors.lastSeenError}
                disabled={true}
              />
            </div>
          </div>
          <div className="flex gap-6 mt-2">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Sales Type"
                placeholder="Select Type"
                value={{
                  label: previewData?.additional?.salesType
                    ? previewData?.additional?.salesType
                    : `Disabled`,
                  value: previewData?.additional?.salesType
                    ? previewData?.additional?.salesType
                    : `Disabled`,
                }}
                disabled={true}
              />
            </div>
            <div className="flex-1 relative"></div>
            <div className="flex-1 relative"></div>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <div className="text-center w-96 my-4">
            <SubmitButton onClick={ValidateForm}>
              <div>Generate</div>
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
