import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTable } from 'react-table'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadRatingTypes } from 'App/Services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGripVertical, faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  setUpdateCarrierPreferences,
  // setUpdateRatingCarrier,
} from 'App/Redux/actions'
import { useDispatch } from 'react-redux'
import { loadDefaultValues } from 'App/Services/Preferences/loadDefaultValues'

export const ReactTable = ({
  carriersdata,
  statename,
  // isStateChange,
  isDisable,
  isLoading,
  width,
  height,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 500)
    return () => clearTimeout(timer)
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: `Carrier Name`,
        accessor: `carrierName`,
      },
      {
        Header: `Rate`,
        accessor: `rated`,
        Cell: (row) => (
          <Checkbox
            defaultChecked={row.value === `YES` ? true : false}
            disabled={isDisable}
            setValue={(e) =>
              // isStateChange
              //   ? dispatch(
              //       setUpdateRatingCarrier({
              //         fieldName: `rated`,
              //         stateName: statename,
              //         fieldValue: {
              //           value: e.target.checked === true ? `YES` : `NO`,
              //           label: e.target.checked === true ? `YES` : `NO`,
              //         },
              //         row: row?.row?.original,
              //       }),
              //     )
              //   :
              dispatch(
                setUpdateCarrierPreferences({
                  fieldName: `rated`,
                  stateName: statename,
                  fieldValue: {
                    value: e.target.checked === true ? `YES` : `NO`,
                    label: e.target.checked === true ? `YES` : `NO`,
                  },
                  row: row?.row?.original,
                }),
              )
            }
          />
        ),
      },
      {
        Header: `Rating Type`,
        accessor: `ratingType`,
        Cell: (row) => (
          <ReactSelectField
            defaultValue={{ value: row.value, label: row.value }}
            loadOptions={loadRatingTypes}
            disabled={isDisable}
            setValue={(value) =>
              // isStateChange
              //   ? dispatch(
              //       setUpdateRatingCarrier({
              //         fieldName: `ratingType`,
              //         stateName: statename,
              //         fieldValue: value,
              //         row: row?.row?.original,
              //       }),
              //     )
              //   :
              dispatch(
                setUpdateCarrierPreferences({
                  fieldName: `ratingType`,
                  stateName: statename,
                  fieldValue: value,
                  row: row?.row?.original,
                }),
              )
            }
          />
        ),
      },
      {
        Header: `Order Credit`,
        accessor: `orderCredit`,
        Cell: (row) => (
          <ReactSelectField
            defaultValue={{ value: row.value, label: row.value }}
            loadOptions={loadDefaultValues}
            disabled={isDisable}
            setValue={(value) =>
              // isStateChange
              //   ? dispatch(
              //       setUpdateRatingCarrier({
              //         fieldName: `orderCredit`,
              //         fieldValue: value,
              //         stateName: statename,
              //         row: row?.row?.original,
              //       }),
              //     )
              //   :
              dispatch(
                setUpdateCarrierPreferences({
                  fieldName: `orderCredit`,
                  fieldValue: value,
                  stateName: statename,
                  row: row?.row?.original,
                }),
              )
            }
          />
        ),
      },
    ],

    [],
  )

  return (
    <Table
      columns={columns}
      data={carriersdata}
      isLoading={isLoading || loading}
      width={width}
      height={height}
    />
  )
}

const Table = ({ columns, data, isLoading, width, height }) => {
  const [records, setRecords] = useState(``)

  useEffect(() => {
    if (data) {
      setRecords(data)
    }
  }, [data])

  const getRowId = useCallback((row) => {
    return row.id
  }, [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      data: records.length ? records : [],
      columns,
      getRowId,
    })

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex]
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      }),
    )
  }

  return isLoading ? (
    <div
      className={`flex justify-center items-center ${width ? width : `w-80`} ${
        height ? height : `h-80`
      }`}
    >
      <FontAwesomeIcon
        icon={faSpinner}
        spin={true}
        size="1x"
        className="text-red-500 z-50"
      />
    </div>
  ) : (
    <DndProvider backend={HTML5Backend}>
      <table className="w-full" {...getTableProps()}>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr key="sad" {...headerGroup.getHeaderGroupProps()}>
              <th></th>
              {headerGroup.headers?.map((column) => (
                <th
                  style={{ textAlign: `left` }}
                  key="sad"
                  {...column.getHeaderProps()}
                >
                  {column.render(`Header`)}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map(
            (row, index) =>
              prepareRow(row) || (
                <Row
                  index={index}
                  row={row}
                  moveRow={moveRow}
                  {...row.getRowProps()}
                />
              ),
          )}
        </tbody>
      </table>
    </DndProvider>
  )
}

const Row = ({ row, index, moveRow }) => {
  const dropRef = useRef(null)
  const dragRef = useRef(null)

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: `BOX`, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [, drop] = useDrop({
    accept: `BOX`,
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveRow(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)
  return (
    <tr
      ref={dropRef}
      style={{
        opacity,
        justifyContent: `flex-start`,
      }}
    >
      <td ref={dragRef}>
        <FontAwesomeIcon
          icon={faGripVertical}
          className="text-red-500 "
          size="lg"
        />
      </td>
      {row.cells.map((cell) => {
        return (
          <td key="sad" {...cell.getCellProps()}>
            {cell.render(`Cell`)}
          </td>
        )
      })}
    </tr>
  )
}
