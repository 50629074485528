/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { useDebouncedCallback } from 'use-debounce/lib'
import { ReactModal } from 'App/Components/Common/Modal'
import { UpdateLead } from 'App/Components/CRM/Leads/UpdateLead'
import { Reload } from 'App/Components/Common/Reload'
import { AxiosInstance, offset } from 'App/Config'
import { Collapsable } from 'App/Components/Common/Collapsable'
import {
  AMS_BACKEND_API,
  VERONICAS_QUOTE,
} from '../../../../Config/backendApis'
import { MaskField } from 'App/Components/Common/MaskField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { DateRange } from 'react-date-range'
import { InputFieldRef } from 'App/Components/Common/InputFieldRef'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { StartCampaign } from 'App/Components/StartCampaign'
import { Call } from 'App/Components/Communications/Call'
import { Toggle } from '../../../../Components/Common/Toggle'
import {
  Container,
  Input,
  PageHeader,
  StyledButton,
  SubmitButton,
} from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faSortUp,
  faSortDown,
  faEdit,
  faPhone,
  faShare,
  faBan,
  faSpinner,
  faBlenderPhone,
  faMobileAlt,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { setCommunicationCredentials, setLeadData } from 'App/Redux/actions'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { isSuperUser, permissionGranted } from 'App/Services'
import { NotificationManager } from 'react-notifications'
import { loadLeadPreferredContact } from 'App/Services/quoteForm'
import { utcToPst } from 'App/Services/utils/pstTimeZone'
import { copy } from 'App/Assets'
import { loadOffset } from 'App/Services/General/loadOffset'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'
import { isHasPermission } from 'App/Services/General/authorizaUser'

const tableColumns = [
  { label: `ID`, value: `_leadId` },
  { label: `Name`, value: `customerData.firstName` },
  { label: `LOB`, value: `lob` },
  { label: `Source`, value: `metadata.source` },
  { label: `State`, value: `customerData.address.state` },
  { label: `Type`, value: `type` },
  { label: `Status`, value: `status` },
  { label: `Contacted`, value: `contacted` },
  { label: `Created`, value: `createdAt` },
]

const loadSource = async () => {
  return {
    options: [
      { label: `NONE`, value: `` },
      { label: `WEB`, value: `WEB` },
      { label: `MESSENGER`, value: `MESSENGER` },
      { label: `PWA`, value: `PWA` },
      { label: `ANDROID`, value: `ANDROID` },
      { label: `IOS`, value: `IOS` },
      { label: `OTHER`, value: `OTHER` },
    ],
    hasMore: false,
  }
}
const loadQuoteStatus = async () => {
  return {
    options: [
      { label: `NONE`, value: `` },
      { value: `IN PROGRESS`, label: `IN PROGRESS` },
      { value: `MANUAL`, label: `MANUAL` },
      { value: `NOT ANSWERED`, label: `NOT ANSWERED` },
      { value: `LOST`, label: `LOST` },
      { value: `SOLD`, label: `SOLD` },
    ],
    hasMore: false,
  }
}
const loadQuoteType = async () => {
  return {
    options: [
      { label: `NONE`, value: `` },
      { value: `LEAD`, label: `LEAD` },
      { value: `PROSPECT`, label: `PROSPECT` },
      { value: `VISITOR`, label: `VISITOR` },
      { value: `REFERRAL`, label: `REFERRAL` },
    ],
    hasMore: false,
  }
}
const loadMaritalStatus = async () => {
  return {
    options: [
      { label: `NONE`, value: `` },
      { value: `SINGLE`, label: `SINGLE` },
      { value: `MARRIED`, label: `MARRIED` },
      { value: `DIVORCED`, label: `DIVORCED` },
      { value: `WIDOWED`, label: `WIDOWED` },
      { value: `COMPLICATED`, label: `COMPLICATED` },
    ],
    hasMore: false,
  }
}
// const loadFinanceMode = async () => {
//   return {
//     options: [
//       { label: `NONE`, value: `` },
//       { value: `PAID OFF`, label: `PAID` },
//       { value: `LEASING`, label: `LEASING` },
//     ],
//     hasMore: false,
//   }
// }
const loadGender = async () => {
  return {
    options: [
      { label: `NONE`, value: `` },
      { value: `MALE`, label: `MALE` },
      { value: `FEMALE`, label: `FEMALE` },
    ],
    hasMore: false,
  }
}
const loadEducation = async () => {
  return {
    options: [
      { label: `NONE`, value: `` },
      { value: `HIGH SCHOOL`, label: `HIGH SCHOOL` },
      { value: `ASSOCIATE DEGREE`, label: `ASSOCIATE DEGREE` },
      { value: `BACHELORS`, label: `BACHELORS` },
      { value: `MASTERS`, label: `MASTERS` },
    ],
    hasMore: false,
  }
}
const loadQuoteLob = async () => {
  return {
    options: [
      { label: `NONE`, value: `` },
      { value: `AUTO`, label: `AUTO` },
      { value: `COMMERCIAL`, label: `COMMERCIAL` },
      { value: `MOTORCYCLE`, label: `MOTORCYCLE` },
      { value: `HOME`, label: `HOME` },
      { value: `RENTERS`, label: `RENTERS` },
      { value: `BOAT`, label: `BOAT` },
      { value: `LIFE`, label: `LIFE` },
    ],
    hasMore: false,
  }
}
const loadState = async () => {
  return {
    options: [
      { label: `NONE`, value: `` },
      { value: `AL`, label: `Alabama` },
      { value: `AR`, label: `Arkansas` },
      { value: `AZ`, label: `Arizona` },
      { value: `CA`, label: `California` },
      { value: `CO`, label: `Colorado` },
      { value: `CT`, label: `Connecticut` },
      { value: `FL`, label: `Florida` },
      { value: `GA`, label: `Georgia` },
      { value: `ID`, label: `Idaho` },
      { value: `IL`, label: `Illinois` },
      { value: `IN`, label: `Indiana` },
      { value: `KS`, label: `Kansas` },
      { value: `KY`, label: `Kentucky` },
      { value: `LA`, label: `Louisiana` },
      { value: `MI`, label: `Michigan` },
      { value: `MN`, label: `Minnesota` },
      { value: `MO`, label: `Missouri` },
      { value: `MS`, label: `Mississippi` },
      { value: `NJ`, label: `New Jersey` },
      { value: `NM`, label: `New Mexico` },
      { value: `NV`, label: `Nevada` },
      { value: `OH`, label: `Ohio` },
      { value: `OK`, label: `Oklahoma` },
      { value: `OR`, label: `Oregon` },
      { value: `PA`, label: `Pennsylvania` },
      { value: `SC`, label: `South Carolina` },
      { value: `TN`, label: `Tennessee` },
      { value: `TX`, label: `Texas` },
      { value: `VA`, label: `Virginia` },
      { value: `WA`, label: `Washington` },
      { value: `WI`, label: `Wisconsin` },
    ],
    hasMore: false,
  }
}

export const loadBinary = async () => {
  return {
    options: [
      { label: `NONE`, value: `` },
      { value: `true`, label: `YES` },
      { value: `false`, label: `NO` },
    ],
    hasMore: false,
  }
}

export const AllLeads = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [inActiveCheckedAll, setinActiveCheckedAll] = useState([])
  const [currentLead, setLeadQuote] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)
  const [leadUpdated, setLeadUpdated] = useState(false)
  const [updateStatusModal, setUpdateStatusModal] = useState(false)
  const [updateContactMethod, setUpdateContactMethod] = useState(false)
  const [previewModal, setPreviewModal] = useState(false)
  const [startCampaignModal, setStartCampaignModal] = useState(false)
  const [activeCall, setActiveCall] = useState(false)
  const [isVisited, setIsVisited] = useState(false)
  const [lead, setLead] = useState(``)
  const [sort, setSort] = useState(``)
  const [leadContactMethods, setLeadContactMethods] = useState(``)
  const [customSearching, setCustomSearching] = useState(false)
  const [isOffset, setIsOffset] = useState(``)
  const [selectedChannel, setSelectedChannel] = useState(``)

  const dispatch = useDispatch()
  const {
    user: { profile },
  } = useSelector(({ user }) => user)

  const [quoteFilters, setQuoteFilters] = useState({
    // phone: ``,
    quoteSource: ``,
    quoteType: ``,
    quoteStatus: ``,
    // homeOwner: ``,
    // maritalStatus: ``,
    // createdAt: ``,
    // financeMode: ``,
    // isEmployed: ``,
    // education: ``,
    // gender: ``,
    quoteLob: ``,
    state: ``,
    reset: false,
  })

  // const [dateRange, setDateRange] = useState([
  //   {
  //     startDate: ``,
  //     endDate: ``,
  //     key: `selection`,
  //   },
  // ])

  // check boxes
  const [stateChecked, setStateChecked] = useState({
    allSelected: false,
    isCheckedAll: false,
    selected: [],
    unselected: [],
  })

  const quoteFiltersRef = {
    quoteId: useRef(null),
    firstName: useRef(null),
    lastName: useRef(null),
    // email: useRef(null),
    // year: useRef(null),
    // make: useRef(null),
    // model: useRef(null),
    // zip: useRef(null),
    // city: useRef(null),
  }

  const handleCopyLinkToClipboard = async () => {
    const url = `${VERONICAS_QUOTE}/?key=auto&carDealer=${profile?._carDealer}&creator=${profile?.sub}`
    await navigator.clipboard.writeText(url)
    // let newURL = url.replace(`&carDealer=`, ` &carDealer =`)
    // newURL = newURL.replace(`&creator=`, ` &creator =`)
    NotificationManager.success(`Referral Link Copied!`)
  }

  const handleResetFilters = () => {
    quoteFiltersRef.quoteId.current.value = ``
    quoteFiltersRef.firstName.current.value = ``
    quoteFiltersRef.lastName.current.value = ``
    // quoteFiltersRef.email.current.value = ``
    // quoteFiltersRef.year.current.value = ``
    // quoteFiltersRef.make.current.value = ``
    // quoteFiltersRef.model.current.value = ``
    // quoteFiltersRef.zip.current.value = ``
    // quoteFiltersRef.city.current.value = ``

    // setDateRange([
    //   {
    //     startDate: ``,
    //     endDate: ``,
    //     key: `selection`,
    //   },
    // ])

    setQuoteFilters(() => ({
      // phone: ``,
      quoteSource: ``,
      quoteType: ``,
      quoteStatus: ``,
      // homeOwner: ``,
      // maritalStatus: ``,
      // createdAt: ``,
      // financeMode: ``,
      // isEmployed: ``,
      // education: ``,
      // gender: ``,
      quoteLob: ``,
      state: ``,
      reset: true,
    }))
  }

  const handlePayload = () => {
    const searchFilters = {}
    if (quoteFiltersRef?.quoteId?.current?.value.length) {
      searchFilters._leadId = quoteFiltersRef?.quoteId?.current?.value
    }
    if (quoteFiltersRef?.firstName?.current?.value.length) {
      searchFilters.firstName = quoteFiltersRef?.firstName?.current?.value
    }
    if (quoteFiltersRef?.lastName?.current?.value.length) {
      searchFilters.lastName = quoteFiltersRef?.lastName?.current?.value
    }
    // if (quoteFiltersRef?.email?.current?.value.length) {
    //   searchFilters.email = quoteFiltersRef?.email?.current?.value
    // }
    // if (quoteFiltersRef?.year?.current?.value.length) {
    //   searchFilters.year = quoteFiltersRef?.year?.current?.value
    // }
    // if (quoteFiltersRef?.make?.current?.value.length) {
    //   searchFilters.make = quoteFiltersRef?.make?.current?.value
    // }
    // if (quoteFiltersRef?.model?.current?.value.length) {
    //   searchFilters.model = quoteFiltersRef?.model?.current?.value
    // }
    // if (quoteFiltersRef?.zip?.current?.value.length) {
    //   searchFilters.zip = quoteFiltersRef?.zip?.current?.value
    // }
    // if (quoteFiltersRef?.city?.current?.value.length) {
    //   searchFilters.city = quoteFiltersRef?.city?.current?.value
    // }
    // if (quoteFilters?.phone.length) {
    //   searchFilters.phone = quoteFilters?.phone
    // }
    if (quoteFilters?.quoteSource?.value?.length) {
      searchFilters.source = quoteFilters?.quoteSource?.value
    }
    if (quoteFilters?.quoteType?.value?.length) {
      searchFilters.type = quoteFilters?.quoteType?.value
    }
    if (quoteFilters?.quoteStatus?.value?.length) {
      searchFilters.status = quoteFilters?.quoteStatus?.value
    }
    // if (quoteFilters?.homeOwner?.value?.length) {
    //   searchFilters.residenceType = quoteFilters?.homeOwner?.value
    // }
    // if (quoteFilters?.maritalStatus?.value?.length) {
    //   searchFilters.maritalStatus = quoteFilters?.maritalStatus?.value
    // }
    // if (dateRange[0]?.startDate) {
    //   searchFilters.createdAt = dateRange
    // }

    // if (quoteFilters?.financeMode?.value?.length) {
    //   searchFilters.financeMode = quoteFilters?.financeMode?.value
    // }
    // if (quoteFilters?.isEmployed?.value?.length) {
    //   searchFilters.employmentStatus = quoteFilters?.isEmployed?.value
    // }

    // if (quoteFilters?.education?.value?.length) {
    //   searchFilters.educationLevel = quoteFilters?.education?.value
    // }

    // if (quoteFilters?.gender?.value?.length) {
    //   searchFilters.gender = quoteFilters?.gender?.value
    // }

    if (quoteFilters?.quoteLob?.value?.length) {
      searchFilters.lob = quoteFilters?.quoteLob?.value
    }

    if (quoteFilters?.state?.value?.length) {
      searchFilters.state = quoteFilters?.state?.value
    }
    return JSON.stringify(searchFilters)
  }
  const history = useHistory()
  const { url } = useRouteMatch()
  // fetching all leads
  const {
    isLoading,
    data: leadData,
    error,
    refetch: getAllLeads,
    isFetching,
  } = useQuery(
    `LeadsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/leads?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }filters=${handlePayload()}&includeVisited=${isVisited}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),

    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomSearching(false)
        setStateChecked((prevState) => ({
          ...prevState,
          isCheckedAll: handleIsCheckedAllSelected(res.leads),
        }))
        if (stateChecked.allSelected) {
          setStateChecked((prevState) => ({
            ...prevState,
            isCheckedAll: !inActiveCheckedAll.includes(currentPage),
            selected: handleAddSelected(res.leads),
          }))
        }
      },
    },
  )

  const { isLoading: isContacting, mutate: mutateContact } = useMutation(
    async (leadId) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/leads/update/isContacted/${leadId}`,
      ).then((res) => {
        if (res.data) {
          getAllLeads()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const {
    mutate: updateLead,
    isLoading: updateLoading,
    // isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/leads/${payload.leadId}`,
        {
          'customerData.preferredContactMethods':
            payload?.preferredContactMethods,
        },
      )
        .then((res) => {
          if (res.data) {
            getAllLeads()
            setUpdateContactMethod(false)
          }
        })
        .catch((err) => NotificationManager.error(`Something went wrong!`)),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  useEffect(() => {
    if (quoteFilters?.reset) {
      getAllLeads()
      setQuoteFilters((prev) => ({
        ...prev,
        reset: false,
      }))
    }
  }, [quoteFilters?.reset])

  useEffect(() => {
    if (!customSearching) {
      getAllLeads()
    }
  }, [isVisited, sort, currentPage, isOffset])

  useEffect(() => {
    if (!activeCall) {
      getAllLeads()
    }
  }, [activeCall])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (leadUpdated) {
      getAllLeads()
      setLeadUpdated(false)
    }
  }, [leadUpdated])
  useEffect(() => {
    setLeadContactMethods(
      currentLead.customerData?.preferredContactMethods?.map((method) => ({
        value: method,
        label: method.toUpperCase(),
      })),
    )
  }, [currentLead])

  useEffect(() => {
    if (stateChecked.allSelected) {
      setStateChecked({
        allSelected: true,
        isCheckedAll: true,
        selected: leadData?.leads?.map((lead) => lead._id),
        unselected: [],
      })
    } else {
      setStateChecked({
        allSelected: false,
        isCheckedAll: false,
        selected: [],
        unselected: [],
      })
    }
  }, [stateChecked.allSelected])

  const handleIsCheckedAllSelected = (currentLeads) => {
    const filterdSelected = currentLeads
      .map((lead) => {
        if (!stateChecked.selected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    if (filterdSelected.length) {
      return false
    } else {
      return true
    }
  }

  const handleAddSelected = (currentLeads) => {
    let filterdUnselected
    if (stateChecked.unselected.length) {
      filterdUnselected = currentLeads
        .map((lead) => {
          if (!stateChecked.unselected.includes(lead._id)) {
            return lead._id
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    } else {
      filterdUnselected = currentLeads.map((lead) => lead._id)
    }

    const filterdSelected = filterdUnselected
      .map((lead) => {
        if (!stateChecked.selected.includes(lead)) {
          return lead
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.selected, ...filterdSelected]
  }

  const handleIndividualChekbox = (e, id) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!e.target.checked) {
      setStateChecked((prevState) => ({
        ...prevState,
        selected: stateChecked.selected.filter((lead) => lead !== id),
        unselected: [...stateChecked.unselected, id],
      }))
    } else {
      setStateChecked((prevState) => ({
        ...prevState,
        selected: [...stateChecked.selected, id],
        unselected: stateChecked.unselected.filter((lead) => lead !== id),
      }))
    }
  }

  const handleCheckAll = (e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (e.target.checked) {
      setinActiveCheckedAll(
        inActiveCheckedAll.filter((el) => el !== currentPage),
      )
      setStateChecked((prevState) => ({
        ...prevState,
        isCheckedAll: true,
        selected: handleCheckedAllSelected(),
        unselected: removeOneArrayFromAnother(stateChecked.unselected),
      }))
    } else {
      setinActiveCheckedAll([...inActiveCheckedAll, currentPage])
      setStateChecked((prevState) => ({
        ...prevState,
        isCheckedAll: false,
        selected: removeOneArrayFromAnother(stateChecked.selected),
        unselected: handleRemvoeUnSelected(),
      }))
    }
  }

  const handleCheckedAllSelected = () => {
    const filterdSelected = leadData.leads
      .map((lead) => {
        if (!stateChecked.selected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.selected, ...filterdSelected]
  }

  const handleRemvoeUnSelected = () => {
    const filterdSelected = leadData.leads
      .map((lead) => {
        if (!stateChecked.unselected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.unselected, ...filterdSelected]
  }

  const removeOneArrayFromAnother = (removeFromArray) => {
    const leadsToBeRemoved = leadData.leads.map((lead) => lead._id)
    return removeFromArray.filter((el) => {
      return !leadsToBeRemoved.includes(el)
    })
  }

  const callLeadsQuery = useDebouncedCallback(() => {
    getAllLeads()
  }, 700)

  const handleStatus = (lead, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (lead?.status !== `SOLD`) {
      setLeadQuote(lead)
      setUpdateStatusModal(true)
    }
  }
  const handleContactMethods = (lead, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    setLeadQuote(lead)
    setUpdateContactMethod(true)
    setLeadContactMethods(
      currentLead.customerData?.preferredContactMethods?.map((method) => ({
        value: method,
        label: method.toUpperCase(),
      })),
    )
  }
  const handleTransfer = (lead, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    dispatch(setLeadData(lead))
    history.push(`/raters/quoteform`)
  }

  return (
    <Container>
      {updateStatusModal && (
        <ReactModal
          title="Update Lead"
          closeModal={() => setUpdateStatusModal(false)}
        >
          <UpdateLead
            selectedLead={currentLead}
            closeModal={() => setUpdateStatusModal(false)}
            setLeadUpdated={() => setLeadUpdated(true)}
          />
        </ReactModal>
      )}
      {updateContactMethod && (
        <ReactModal
          title="Update Contact Methods"
          closeModal={() => setUpdateContactMethod(false)}
        >
          <div className="w-96 px-4 py-1">
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Contact Methods"
                  placeholder="Select Methods"
                  // errorMessage={errors.leadStatusError}
                  value={leadContactMethods}
                  setValue={(e) => setLeadContactMethods(e)}
                  isMulti={true}
                  loadOptions={loadLeadPreferredContact}
                  isSearchable={false}
                  cacheUniqs={updateLoading}
                />
              </div>
            </div>
            <div className="pb-0.5 text-right">
              <StyledButton
                onClick={() =>
                  updateLead({
                    preferredContactMethods: leadContactMethods.map(
                      (method) => method.value,
                    ),
                    leadId: currentLead?._id,
                  })
                }
                disabled={!leadContactMethods?.length}
              >
                <div className="flex gap-2 text-white">
                  <span>Update</span>
                  {updateLoading && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin={true}
                      className="my-auto"
                    />
                  )}
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}
      {activeCall && (
        <ReactModal title="Start Call" closeModal={() => setActiveCall(false)}>
          <Call leadCall={lead} customClass="px-12" />
        </ReactModal>
      )}
      {startCampaignModal && (
        <ReactModal
          title="Start Campaign"
          closeModal={() => setStartCampaignModal(false)}
          scroll={selectedChannel === `MAIL` ? true : false}
        >
          <StartCampaign
            usersList={stateChecked}
            closeModal={() => setStartCampaignModal(false)}
            source="leads"
            count={leadData?.totalRecords - stateChecked.unselected.length}
            isVisited={isVisited}
            selectedChannel={setSelectedChannel}
          />
        </ReactModal>
      )}
      <Spinner loading={isLoading || isFetching || isContacting} />
      <PageHeader>
        <div>Leads</div>

        <div className="flex items-center">
          {profile?._carDealer && (
            <div
              data-tip="Copy Refelal Link"
              className="mr-2 cursor-pointer tooltip tooltip-left"
              onClick={handleCopyLinkToClipboard}
            >
              <img src={copy} alt="icon" className="w-6 h-6 stroke-cyan-500" />
            </div>
          )}
          <ReactSelectField
            placeholder="10"
            value={isOffset}
            setValue={(e) => {
              setCurrentPage(1)
              setIsOffset(e)
            }}
            isMulti={false}
            loadOptions={loadOffset}
          />
          <Toggle
            value={isVisited}
            setValue={(e) => {
              setCurrentPage(1)
              setIsVisited(e)
            }}
            toggleValue={[`P`, `V`]}
            disabled={isLoading || isFetching}
          />

          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callLeadsQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 pt-1 ${
          (isLoading || isFetching || isContacting) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        <Collapsable title="Filters">
          <div className="grid grid-cols-4 gap-4">
            <div className="relative">
              <InputFieldRef
                title="Lead Id"
                placeholder="Lead Id"
                type="text"
                inputRef={quoteFiltersRef?.quoteId}
                value={quoteFiltersRef?.quoteId?.current?.value}
              />
            </div>
            <div className="relative">
              <InputFieldRef
                title="First Name"
                placeholder="First Name"
                type="text"
                inputRef={quoteFiltersRef?.firstName}
                value={quoteFiltersRef?.firstName?.current?.value}
              />
            </div>
            <div className="relative">
              <InputFieldRef
                title="Last Name"
                placeholder="Last Name"
                type="text"
                inputRef={quoteFiltersRef?.lastName}
                value={quoteFiltersRef?.lastName?.current?.value}
              />
            </div>
            {/* <div className="relative">
              <InputFieldRef
                title="Email"
                placeholder="Email"
                type="text"
                inputRef={quoteFiltersRef?.email}
                value={quoteFiltersRef?.email?.current?.value}
              />
            </div>
            <div className="relative">
              <MaskField
                mask="+19999999999"
                type="text"
                title="Phone"
                placeholder="Phone"
                value={quoteFilters?.phone}
                setValue={(e) =>
                  setQuoteFilters((preState) => ({
                    ...preState,
                    [`phone`]: e.target.value,
                  }))
                }
              />
            </div> */}
            <div className="relative">
              <ReactSelectField
                title="Source"
                placeholder="Source"
                value={quoteFilters.quoteSource}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`quoteSource`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadSource}
              />
            </div>
            <div className="relative">
              <ReactSelectField
                title="Lob"
                placeholder="Choose"
                value={quoteFilters.quoteLob}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`quoteLob`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadQuoteLob}
              />
            </div>
            <div className="relative">
              <ReactSelectField
                title="Type"
                placeholder="Choose"
                value={quoteFilters.quoteType}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`quoteType`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadQuoteType}
              />
            </div>
            <div className="relative">
              <ReactSelectField
                title="Status"
                placeholder="Choose"
                value={quoteFilters.quoteStatus}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`quoteStatus`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadQuoteStatus}
              />
            </div>
            {/* <div className="relative">
              <ReactSelectField
                title="Home Owner"
                placeholder="Choose"
                value={quoteFilters.homeOwner}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`homeOwner`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadBinary}
              />
            </div>
            <div className="relative">
              <ReactSelectField
                title="Marital Status"
                placeholder="Choose"
                value={quoteFilters.maritalStatus}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`maritalStatus`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadMaritalStatus}
              />
            </div> */}
            {/* <div className="relative">
              <ReactSelectField
                title="Finance Mode"
                placeholder="Choose"
                value={quoteFilters.financeMode}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`financeMode`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadFinanceMode}
              />
            </div> */}

            {/* <div className="relative">
              <InputFieldRef
                title="Vehicle Year"
                placeholder="Vehicle Year"
                type="text"
                inputRef={quoteFiltersRef?.year}
                value={quoteFiltersRef?.year?.current?.value}
              />
            </div>
            <div className="relative">
              <InputFieldRef
                title="Vehicle Make"
                placeholder="Vehicle Make"
                type="text"
                inputRef={quoteFiltersRef?.make}
                value={quoteFiltersRef?.make?.current?.value}
              />
            </div>
            <div className="relative">
              <InputFieldRef
                title="Vehicle Model"
                placeholder="Vehicle Model"
                type="text"
                inputRef={quoteFiltersRef?.model}
                value={quoteFiltersRef?.model?.current?.value}
              />
            </div>
            <div className="relative">
              <InputFieldRef
                title="Zip"
                placeholder="Zip"
                type="text"
                inputRef={quoteFiltersRef?.zip}
                value={quoteFiltersRef?.zip?.current?.value}
              />
            </div>
            <div className="relative">
              <InputFieldRef
                title="City"
                placeholder="City"
                type="text"
                inputRef={quoteFiltersRef?.city}
                value={quoteFiltersRef?.city?.current?.value}
              />
            </div>

            <div className="relative">
              <ReactSelectField
                title="Employed"
                placeholder="Choose"
                value={quoteFilters.isEmployed}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`isEmployed`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadBinary}
              />
            </div>
            <div className="relative">
              <ReactSelectField
                title="Education"
                placeholder="Choose"
                value={quoteFilters.education}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`education`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadEducation}
              />
            </div>
            <div className="relative">
              <ReactSelectField
                title="Gender"
                placeholder="Choose"
                value={quoteFilters.gender}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`gender`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadGender}
              />
            </div> */}

            <div className="relative">
              <ReactSelectField
                title="State"
                placeholder="Choose"
                value={quoteFilters.state}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`state`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadState}
              />
            </div>
            {/* <div className="relative">
              <ReactSelectField
                title="Car Dealer"
                placeholder="Choose"
                // value={quoteFilters.}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`state`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadState}
              />
            </div>
            <div className="relative">
              <ReactSelectField
                title="Car Dealer Agent"
                placeholder="Choose"
                value={quoteFilters.state}
                setValue={(value) => {
                  setQuoteFilters((prevState) => ({
                    ...prevState,
                    [`state`]: value,
                  }))
                }}
                isMulti={false}
                loadOptions={loadState}
              />
            </div> */}
            {/* <div className="relative">
              <label className={`text-gray-700 font-medium text-sm`}>
                Date Range
              </label>
              <div className="flex gap-x-2">
                <Input
                  type="text"
                  name="startRange"
                  placeholder="Start Date"
                  value={
                    dateRange[0]?.startDate
                      ? dateFormat(dateRange[0].startDate)
                      : ``
                  }
                  onFocus={() => setPreviewModal(true)}
                />
                <Input
                  type="text"
                  name="endRange"
                  placeholder="End Date"
                  value={
                    dateRange[0]?.endDate
                      ? dateFormat(dateRange[0].endDate)
                      : ``
                  }
                  disabled={true}
                />
              </div>
            </div> */}

            {/* {previewModal && (
              <ReactModal
                title="Date Range"
                closeModal={() => setPreviewModal(false)}
              >
                <div className="pb-2">
                  <DateRange
                    editableDateInputs={true}
                    onChange={(item) => setDateRange([item.selection])}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    className="w-full"
                  />
                  <div className="px-2">
                    <StyledButton
                      onClick={() => {
                        setPreviewModal(false)
                      }}
                    >
                      <div className="flex gap-2 text-white">
                        <span>Select</span>
                      </div>
                    </StyledButton>
                  </div>
                </div>
              </ReactModal>
            )} */}
          </div>
          <div className="grid grid-cols-4 gap-4 mt-4">
            <div className="flex  gap-4">
              <SubmitButton onClick={() => getAllLeads()}>Search</SubmitButton>
              <SubmitButton
                onClick={() => {
                  handleResetFilters()
                }}
              >
                Reset
              </SubmitButton>
            </div>
          </div>
        </Collapsable>

        <div className="flex justify-end items-center p-2">
          {permissionGranted([
            `leads:create`,
            `car-dealer-agent:*`,
            `car-dealer-manager:*`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && (
            <StyledButton onClick={() => history.push(`${url}/add`)}>
              <div className="flex gap-2 text-white">
                <span>Add </span>
                <FontAwesomeIcon icon={faPlusCircle} size="lg" />
              </div>
            </StyledButton>
          )}
        </div>

        {permissionGranted([
          `broadcasts:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <div className="flex justify-between items-center pt-2">
            <StyledButton
              onClick={() =>
                setStateChecked((prevState) => ({
                  ...prevState,
                  allSelected: !stateChecked.allSelected,
                }))
              }
            >
              <div className="flex gap-2 text-white">
                <span>
                  {stateChecked?.allSelected ? `Selected ` : `Select All `}
                  {leadData?.totalRecords - stateChecked.unselected.length}
                </span>
              </div>
            </StyledButton>
            <StyledButton
              disabled={!stateChecked?.selected?.length}
              onClick={() => setStartCampaignModal(true)}
            >
              <div className="flex gap-2 text-white">
                <span>Start Campaign </span>
              </div>
            </StyledButton>
          </div>
        )}
        {error ? (
          <Reload refetch={() => getAllLeads()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md shadow mt-2">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {permissionGranted([
                      `broadcasts:create`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) && (
                      <TableCell className="text-center">
                        <div className="flex-1">
                          <Checkbox
                            name="checkAll"
                            value={stateChecked?.isCheckedAll}
                            setValue={(e) => handleCheckAll(e)}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          />
                        </div>
                      </TableCell>
                    )}
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {!(
                      isHasPermission(`car-dealer-agent:*`) ||
                      isHasPermission(`car-dealer-manager:*`)
                    ) && (
                      <TableCell className="select-none mt-2 text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {leadData?.leads?.length != 0 ? (
                    leadData?.leads?.map((lead, index) => (
                      <TableRow
                        key={index.toString()}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          history.push(`${url}/${lead._id}`)
                        }}
                      >
                        {permissionGranted([
                          `broadcasts:create`,
                          `corporate-manager:*`,
                          `manager:*`,
                          `admin:*`,
                        ]) && (
                          <td
                            className="px-4 text-sm"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              name={lead?._id}
                              value={stateChecked?.selected.includes(lead?._id)}
                              setValue={(e) =>
                                handleIndividualChekbox(e, lead?._id)
                              }
                            />
                          </td>
                        )}

                        <td className="px-4 py-0.5 z-0 text-sm">
                          {lead._leadId}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm capitalize truncate">
                          {lead?.customerData?.firstName ? (
                            lead?.customerData?.firstName?.toLowerCase() +
                            ` ` +
                            lead?.customerData?.lastName?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <Badge type="primary">
                            {lead.lob ? lead.lob : `N/A`}
                          </Badge>
                        </td>

                        <td className="px-4 py-0.5 z-0 text-sm">
                          <Badge type="warning">
                            {lead.metadata?.source
                              ? lead.metadata?.source
                              : `N/A`}
                          </Badge>
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <Badge type="primary">
                            {lead?.customerData?.address?.state
                              ? lead?.customerData?.address?.state
                              : `N/A`}
                          </Badge>
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <Badge
                            type={`${
                              lead?.type === `REFERRAL`
                                ? `danger`
                                : lead?.type === `VISITOR`
                                ? `neutral`
                                : lead?.type === `LEAD`
                                ? `success`
                                : `warning`
                            }`}
                          >
                            {lead?.type ? lead?.type : `N/A`}
                          </Badge>
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <Badge
                            type={`${lead?.status ? `success` : `neutral`}`}
                          >
                            {lead?.status ? lead?.status : `N/A`}
                          </Badge>
                        </td>
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <Badge
                            onClick={() => mutateContact(lead._id)}
                            className="cursor-pointer"
                          >
                            {lead.isContacted ? `YES` : `NO`}
                          </Badge>
                        </td>

                        <td className="px-4 py-0.5 z-0 text-sm truncate">
                          <span>
                            {/* {utcToPst(lead?.createdAt).split(` `)[0] + ` PST`} */}
                            {dateFormat(lead?.createdAt)}
                          </span>
                        </td>
                        {/* Actions TD */}
                        {!(
                          isHasPermission(`car-dealer-agent:*`) ||
                          isHasPermission(`car-dealer-manager:*`)
                        ) && (
                          <td className="px-4 pt-3 z-0 text-sm flex float-right  text-gray-400">
                            <span
                              data-tip={`${
                                lead?.customerData?.contactInfo?.phone
                                  ? lead?.customerData?.preferredContactMethods?.includes(
                                      `CALL`,
                                    )
                                    ? `Call`
                                    : `Not Preferred`
                                  : `Phone not Provided`
                              }`}
                              className="tooltip tooltip-left"
                            >
                              <FontAwesomeIcon
                                className={`mx-1 transform transition    hover:-translate-y-0 hover:scale-105  ${
                                  lead?.customerData?.contactInfo?.phone &&
                                  !lead?.customerData?.doNotContact &&
                                  lead?.customerData?.preferredContactMethods?.includes(
                                    `CALL`,
                                  )
                                    ? `cursor-pointer hover:text-green-500`
                                    : `cursor-not-allowed hover:text-red-500`
                                } `}
                                icon={faPhone}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  if (
                                    lead?.customerData?.contactInfo?.phone &&
                                    lead?.customerData?.preferredContactMethods?.includes(
                                      `CALL`,
                                    )
                                  ) {
                                    dispatch(
                                      setCommunicationCredentials({
                                        id: lead._id,
                                        phone:
                                          lead.customerData?.contactInfo?.phone,
                                        customerId: lead._customer,
                                        img: lead.img,
                                        doNotContact:
                                          lead?.customerData?.doNotContact,
                                        email:
                                          lead.customerData?.contactInfo?.email,
                                      }),
                                    )
                                    setLead({
                                      leadPhone:
                                        lead?.customerData?.contactInfo?.phone,
                                      leadId: lead._id,
                                    })
                                    setActiveCall(true)
                                  }
                                }}
                              />
                            </span>
                            {permissionGranted([
                              `leads:update`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ]) && (
                              <>
                                <span
                                  data-tip="Contact Methods"
                                  className="tooltip tooltip-left"
                                >
                                  {updateLoading &&
                                  currentLead?._id === lead?._id ? (
                                    <FontAwesomeIcon
                                      icon={faMobileAlt}
                                      spin={true}
                                      className="my-auto"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      className="mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                      icon={faMobileAlt}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleContactMethods(lead, e)
                                      }}
                                    />
                                  )}
                                </span>

                                <span
                                  data-tip={
                                    lead?.status === `SOLD`
                                      ? `Already Sold`
                                      : `Update`
                                  }
                                  className="tooltip"
                                >
                                  <FontAwesomeIcon
                                    className={`mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105 ${
                                      lead?.status === `SOLD`
                                        ? `cursor-not-allowed`
                                        : `cursor-pointer`
                                    }`}
                                    icon={faEdit}
                                    onClick={(e) => handleStatus(lead, e)}
                                  />
                                </span>
                              </>
                            )}
                            {permissionGranted([
                              `quotes:create`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ]) && (
                              <span
                                data-tip="Transfer to Quote Form"
                                className="tooltip tooltip-left"
                              >
                                <FontAwesomeIcon
                                  className=" mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer "
                                  icon={faShare}
                                  onClick={(e) => handleTransfer(lead, e)}
                                />
                              </span>
                            )}
                          </td>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Lead Found</div>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </table>
            </div>

            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={leadData?.totalPages}
                totalRecords={leadData?.totalRecords}
                curerntPageRecords={leadData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
