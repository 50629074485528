import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  Container,
  EditStyledButton,
  PageHeader,
  SecondaryHeading,
} from 'App/Styled'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

import { Reload } from 'App/Components/Common/Reload'
import { CardField } from 'App/Components/Common/CardField'
import { setCurrentPaymentTemplate } from 'App/Redux/actions'
import { permissionGranted } from 'App/Services'
import dateFormat from 'App/Services/General/dateFormat'

export const PreviewPaymentTemplate = () => {
  const {
    url,
    params: { templateId },
  } = useRouteMatch()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    data: previewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `paymentTemplatePreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/payment-templates/${templateId}`,
      ).then((res) => {
        return res?.data?.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentPaymentTemplate(res?.paymentTemplate))
      },
    },
  )
  return (
    <>
      <Container>
        <PageHeader>
          <div>Payment Template Info</div>
          {permissionGranted([
            `payments-templates:update`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && (
            <EditStyledButton
              onClick={() => history.push(`${url}/update`)}
              disabled={isLoading || isFetching}
            >
              <span>Edit</span>
              <FontAwesomeIcon
                icon={faPen}
                className="text-white mt-0.5"
                size="sm"
              />
            </EditStyledButton>
          )}
        </PageHeader>
        <div className="flex flex-col gap-0">
          <div className="flex flex-col px-2">
            {isLoading || isFetching ? (
              <div className="text-center py-10">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  size="1x"
                  className="text-red-500"
                />
              </div>
            ) : error ? (
              <Reload refetch={() => refetch()} />
            ) : (
              <div className="flex flex-col">
                <div className="flex-1">
                  <div className="rounded-md overflow-hidden py-2">
                    <div className="flex items-start justify-evenly">
                      <div className="flex-1 mx-2 rounded">
                        <div className="flex">
                          <div className="flex-1">
                            <CardField
                              title="Template Name"
                              value={previewData?.paymentTemplate?.name}
                            />
                            <CardField
                              title="Location"
                              value={
                                previewData?.paymentTemplate?._location?.label
                              }
                            />

                            <CardField
                              title="Agent Name"
                              value={
                                previewData?.paymentTemplate?._creator?.label
                              }
                            />
                          </div>
                          <div className="flex-1">
                            <CardField
                              title="Updated"
                              value={dateFormat(
                                previewData?.paymentTemplate?.updatedAt,
                              )}
                            />
                            <CardField
                              title="Created"
                              value={dateFormat(
                                previewData?.paymentTemplate?.createdAt,
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 mb-3">
                  <SecondaryHeading>Templates</SecondaryHeading>
                  <div className="grid grid-cols-12">
                    <div className="col-span-12">
                      <div className="grid grid-cols-12 font-medium text-gray-700py-1 capitalize">
                        <div className="col-span-4">Description</div>
                        <div className="col-span-4">Type</div>
                        <div className="col-span-4">Amount</div>
                      </div>
                      {previewData?.paymentTemplate?.templates?.length &&
                        previewData?.paymentTemplate?.templates?.map(
                          (eachTemplate, index) => {
                            return (
                              <div
                                key={index}
                                className={`grid ${
                                  index % 2 === 0 ? `bg-gray-100` : ``
                                } grid-cols-12 text-sm text-gray-500 my-1.5`}
                              >
                                <div className="col-span-4 px-1">
                                  {eachTemplate?.description
                                    ? eachTemplate?.description
                                    : `Not Provided`}
                                </div>
                                <div className="col-span-4 px-1">
                                  {eachTemplate?.type}
                                </div>
                                <div className="col-span-4 px-1">
                                  {eachTemplate?.amount &&
                                    `$` + eachTemplate?.amount?.toString()}
                                </div>
                              </div>
                            )
                          },
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  )
}
