import { useState } from 'react'

export const useRecording = () => {
  const [decodedRecordingUrl, setDecodedRecordingUrl] = useState(``)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(``)
  // download file from twilio api and convert it into blob to create file url object
  const fetchRecording = async (recordingUrl) => {
    setIsLoading(true)
    try {
      const audioResponse = await fetch(recordingUrl + `.wav`)
      if (!audioResponse.ok) {
        throw new Error(`Failed to fetch audio: ${audioResponse.statusText}`)
      }
      const audioBlob = await audioResponse.blob()
      setDecodedRecordingUrl(URL.createObjectURL(audioBlob))
      setIsLoading(false)
    } catch (error) {
      setIsError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const clearRecodedUrl = () => {
    if (decodedRecordingUrl) {
      URL.revokeObjectURL(decodedRecordingUrl)
    }

    setDecodedRecordingUrl(``)
  }

  return {
    fetchRecording,
    isLoading,
    clearRecodedUrl,
    decodedRecordingUrl,
    isError,
  }
}
