import React from 'react'
import { Container, PageHeader } from 'App/Styled'

import { ListCustomers } from './Customers'

import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
export const GlobalCustomers = () => {
  return (
    <Container>
      <PageHeader>
        <div>Your Search Result</div>
      </PageHeader>
      <div className="p-2">
        <CollapsableRef title="Customers">
          <ListCustomers />
        </CollapsableRef>
      </div>
    </Container>
  )
}
