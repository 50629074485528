import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Container,
  Input,
  PageHeader,
  StyledButton,
  SubmitButton,
} from 'App/Styled'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { useDebouncedCallback } from 'use-debounce/lib'
import { Reload } from 'App/Components/Common/Reload'
import { AxiosInstance, offset, AMS_BACKEND_API } from 'App/Config'
import { setDataTrasActivityActiveTab } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { DateRange } from 'react-date-range'
import { ReactModal } from 'App/Components/Common/Modal'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { InputFieldRef } from 'App/Components/Common/InputFieldRef'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { StartCampaign } from 'App/Components/StartCampaign'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadLocations, permissionGranted } from 'App/Services'
import { loadOffset } from 'App/Services/General/loadOffset'
import dateFormat from 'App/Services/General/dateFormat'

const tableColumns = [
  { label: `Policy No`, value: `data.policyInfo.policyNo` },
  { label: `Name`, value: `data.primaryInsured.firstName` },
  { label: `Data OP`, value: `dataOp` },
  { label: `ZIP`, value: `data.primaryInsured.postalAddress.zip` },
  { label: `Effective Date`, value: `effectiveDate`, isLast: true },
]

export const AllDataTransaction = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [inActiveCheckedAll, setinActiveCheckedAll] = useState([])
  const [startCampaignModal, setStartCampaignModal] = useState(false)
  const [isOffset, setIsOffset] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)
  const [previewModal, setPreviewModal] = useState(false)

  const [stateChecked, setStateChecked] = useState({
    allSelected: false,
    isCheckedAll: false,
    selected: [],
    unselected: [],
  })

  const [sort, setSort] = useState(``)
  const history = useHistory()
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const loadState = async () => {
    return {
      options: [
        { label: `NONE`, value: `` },
        { value: `AL`, label: `Alabama` },
        { value: `AR`, label: `Arkansas` },
        { value: `AZ`, label: `Arizona` },
        { value: `CA`, label: `California` },
        { value: `CO`, label: `Colorado` },
        { value: `CT`, label: `Connecticut` },
        { value: `FL`, label: `Florida` },
        { value: `GA`, label: `Georgia` },
        { value: `ID`, label: `Idaho` },
        { value: `IL`, label: `Illinois` },
        { value: `IN`, label: `Indiana` },
        { value: `KS`, label: `Kansas` },
        { value: `KY`, label: `Kentucky` },
        { value: `LA`, label: `Louisiana` },
        { value: `MI`, label: `Michigan` },
        { value: `MN`, label: `Minnesota` },
        { value: `MO`, label: `Missouri` },
        { value: `MS`, label: `Mississippi` },
        { value: `NJ`, label: `New Jersey` },
        { value: `NM`, label: `New Mexico` },
        { value: `NV`, label: `Nevada` },
        { value: `OH`, label: `Ohio` },
        { value: `OK`, label: `Oklahoma` },
        { value: `OR`, label: `Oregon` },
        { value: `PA`, label: `Pennsylvania` },
        { value: `SC`, label: `South Carolina` },
        { value: `TN`, label: `Tennessee` },
        { value: `TX`, label: `Texas` },
        { value: `VA`, label: `Virginia` },
        { value: `WA`, label: `Washington` },
        { value: `WI`, label: `Wisconsin` },
      ],
      hasMore: false,
    }
  }

  const loadDataOpp = async () => {
    return {
      options: [
        { value: `PCN`, label: `Pending Cancellation` },
        { value: `ED`, label: `Endorsement` },
        { value: `REN`, label: `Renewal Expiration` },
        { value: `RND`, label: `Renewal` },
        { value: `NBD`, label: `New Business` },
        { value: `INS`, label: `Installment Bills` },
        { value: `PLN`, label: `Policy Lapse` },
      ],
      hasMore: false,
    }
  }

  // const loadHasContactInfo = async () => {
  //   return {
  //     options: [
  //       { value: `HAS_PHONE`, label: `Phone No` },
  //       { value: `HAS_EMAIL`, label: `Email` },
  //       { value: `BOTH`, label: `Both` },
  //       { value: `NONE`, label: `None` },
  //     ],
  //     hasMore: false,
  //   }
  // }

  const transactionFiltersRef = {
    insuredName: useRef(null),
    driverName: useRef(null),
    zipCode: useRef(null),
    vin: useRef(null),
    year: useRef(null),
    make: useRef(null),
    model: useRef(null),
    policyNo: useRef(null),
    driverLicense: useRef(null),
  }

  const [transactionQuoteFilters, setTransactionQuoteFilters] = useState({
    createdAt: ``,
    hasContactInfo: ``,
    dataOp: ``,
    locationId: ``,
    state: ``,
    reset: false,
  })

  const [dateRange, setDateRange] = useState([
    {
      startDate: ``,
      endDate: ``,
      key: `selection`,
    },
  ])
  const handleResetFilters = () => {
    transactionFiltersRef.insuredName.current.value = ``
    transactionFiltersRef.driverName.current.value = ``
    transactionFiltersRef.zipCode.current.value = ``
    transactionFiltersRef.vin.current.value = ``
    transactionFiltersRef.year.current.value = ``
    transactionFiltersRef.make.current.value = ``
    transactionFiltersRef.model.current.value = ``
    transactionFiltersRef.policyNo.current.value = ``
    transactionFiltersRef.driverLicense.current.value = ``

    setDateRange([
      {
        startDate: ``,
        endDate: ``,
        key: `selection`,
      },
    ])

    setTransactionQuoteFilters(() => ({
      createdAt: ``,
      hasContactInfo: ``,
      dataOp: ``,
      locationId: ``,
      state: ``,
      reset: true,
    }))
  }

  const handlePayload = () => {
    const searchFilters = {}
    if (transactionFiltersRef?.insuredName?.current?.value.length) {
      searchFilters.insuredName =
        transactionFiltersRef?.insuredName?.current?.value
    }
    if (transactionFiltersRef?.driverName?.current?.value.length) {
      searchFilters.driverName =
        transactionFiltersRef?.driverName?.current?.value
    }
    if (transactionFiltersRef?.zipCode?.current?.value.length) {
      searchFilters.zipCode = transactionFiltersRef?.zipCode?.current?.value
    }
    if (transactionFiltersRef?.vin?.current?.value.length) {
      searchFilters.vin = transactionFiltersRef?.vin?.current?.value
    }
    if (transactionFiltersRef?.year?.current?.value.length) {
      searchFilters.year = transactionFiltersRef?.year?.current?.value
    }
    if (transactionFiltersRef?.make?.current?.value.length) {
      searchFilters.make = transactionFiltersRef?.make?.current?.value
    }
    if (transactionFiltersRef?.model?.current?.value.length) {
      searchFilters.model = transactionFiltersRef?.model?.current?.value
    }
    if (transactionFiltersRef?.policyNo?.current?.value.length) {
      searchFilters.policyNo = transactionFiltersRef?.policyNo?.current?.value
    }
    if (transactionFiltersRef?.driverLicense?.current?.value.length) {
      searchFilters.driverLicense =
        transactionFiltersRef?.driverLicense?.current?.value
    }
    if (transactionQuoteFilters?.hasContactInfo?.value?.length) {
      searchFilters.hasContactInfo =
        transactionQuoteFilters?.hasContactInfo?.value
    }
    if (transactionQuoteFilters?.DataOp?.value?.length) {
      searchFilters.dataOp = transactionQuoteFilters?.DataOp?.value
    }
    if (transactionQuoteFilters?.locationId?.value?.length) {
      searchFilters.locationId = transactionQuoteFilters?.locationId?.value
    }
    if (transactionQuoteFilters?.state?.value?.length) {
      searchFilters.state = transactionQuoteFilters?.state?.value
    }
    if (dateRange[0]?.startDate) {
      searchFilters.createdAt = dateRange
    }
    return JSON.stringify(searchFilters)
  }

  // fetching all frenchises
  const {
    isLoading,
    data: dataTransactionsData,
    error,
    refetch: getAllDataTransaction,
    isFetching,
  } = useQuery(
    `DataTransactionApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/scrapes?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }","searchFilters": ${handlePayload()}}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomSearching(false)
        setStateChecked((prevState) => ({
          ...prevState,
          isCheckedAll: handleIsCheckedAllSelected(res.scrapes),
        }))
        if (stateChecked.allSelected) {
          setStateChecked((prevState) => ({
            ...prevState,
            isCheckedAll: !inActiveCheckedAll.includes(currentPage),
            selected: handleAddSelected(res.scrapes),
          }))
        }
      },
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllDataTransaction()
    }
  }, [sort, currentPage, isOffset])
  useEffect(() => {
    if (transactionQuoteFilters?.reset) {
      getAllDataTransaction()
      setTransactionQuoteFilters((prev) => ({
        ...prev,
        reset: false,
      }))
    }
  }, [transactionQuoteFilters?.reset])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const callFranchiseQuery = useDebouncedCallback(() => {
    getAllDataTransaction()
  }, 700)

  useEffect(() => {
    if (stateChecked.allSelected) {
      setStateChecked({
        allSelected: true,
        isCheckedAll: true,
        selected: dataTransactionsData?.scrapes?.map((lead) => lead._id),
        unselected: [],
      })
    } else {
      setStateChecked({
        allSelected: false,
        isCheckedAll: false,
        selected: [],
        unselected: [],
      })
    }
  }, [stateChecked.allSelected])

  const handleIsCheckedAllSelected = (currentLeads) => {
    const filterdSelected = currentLeads
      .map((lead) => {
        if (!stateChecked.selected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    if (filterdSelected.length) {
      return false
    } else {
      return true
    }
  }

  const handleAddSelected = (currentLeads) => {
    let filterdUnselected
    if (stateChecked.unselected.length) {
      filterdUnselected = currentLeads
        .map((lead) => {
          if (!stateChecked.unselected.includes(lead._id)) {
            return lead._id
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    } else {
      filterdUnselected = currentLeads.map((lead) => lead._id)
    }

    const filterdSelected = filterdUnselected
      .map((lead) => {
        if (!stateChecked.selected.includes(lead)) {
          return lead
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.selected, ...filterdSelected]
  }

  const handleIndividualChekbox = (e, id) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!e.target.checked) {
      setStateChecked((prevState) => ({
        ...prevState,
        selected: stateChecked.selected.filter((lead) => lead !== id),
        unselected: [...stateChecked.unselected, id],
      }))
    } else {
      setStateChecked((prevState) => ({
        ...prevState,
        selected: [...stateChecked.selected, id],
        unselected: stateChecked.unselected.filter((lead) => lead !== id),
      }))
    }
  }

  const handleCheckAll = (e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (e.target.checked) {
      setinActiveCheckedAll(
        inActiveCheckedAll.filter((el) => el !== currentPage),
      )
      setStateChecked((prevState) => ({
        ...prevState,
        isCheckedAll: true,
        selected: handleCheckedAllSelected(),
        unselected: removeOneArrayFromAnother(stateChecked.unselected),
      }))
    } else {
      setinActiveCheckedAll([...inActiveCheckedAll, currentPage])
      setStateChecked((prevState) => ({
        ...prevState,
        isCheckedAll: false,
        selected: removeOneArrayFromAnother(stateChecked.selected),
        unselected: handleRemvoeUnSelected(),
      }))
    }
  }

  const handleCheckedAllSelected = () => {
    const filterdSelected = dataTransactionsData?.scrapes
      .map((lead) => {
        if (!stateChecked.selected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.selected, ...filterdSelected]
  }

  const handleRemvoeUnSelected = () => {
    const filterdSelected = dataTransactionsData?.scrapes
      .map((lead) => {
        if (!stateChecked.unselected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.unselected, ...filterdSelected]
  }

  const handleSearchFilter = useDebouncedCallback(() => {
    getAllDataTransaction()
  }, 200)

  const removeOneArrayFromAnother = (removeFromArray) => {
    const leadsToBeRemoved = dataTransactionsData?.scrapes.map(
      (lead) => lead._id,
    )
    return removeFromArray.filter((el) => {
      return !leadsToBeRemoved.includes(el)
    })
  }

  return (
    <Container>
      <Spinner loading={isLoading || isFetching} />
      {startCampaignModal && (
        <ReactModal
          title="Start Campaign"
          closeModal={() => setStartCampaignModal(false)}
        >
          <StartCampaign
            usersList={stateChecked}
            closeModal={() => setStartCampaignModal(false)}
            source="dataTrax"
          />
        </ReactModal>
      )}
      <PageHeader>
        <div>Data Transactions</div>
        <div className="flex items-center">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callFranchiseQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 pt-1 ${
          (isLoading || isFetching) && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="mb-4">
          <Collapsable title="Filters">
            <div className="grid grid-cols-4 gap-4">
              <div className="relative">
                <InputFieldRef
                  title="Primary Insured"
                  placeholder="Primary Insured"
                  type="text"
                  inputRef={transactionFiltersRef?.insuredName}
                  value={transactionFiltersRef?.insuredName?.current?.value}
                />
              </div>
              <div className="relative">
                <InputFieldRef
                  title="Driver"
                  placeholder="Driver"
                  type="text"
                  inputRef={transactionFiltersRef?.driverName}
                  value={transactionFiltersRef?.driverName?.current?.value}
                />
              </div>
              <div className="relative">
                <ReactSelectField
                  title="State"
                  placeholder="State"
                  value={transactionQuoteFilters.state}
                  setValue={(value) => {
                    setTransactionQuoteFilters((prevState) => ({
                      ...prevState,
                      [`state`]: value,
                    }))
                  }}
                  isMulti={false}
                  loadOptions={loadState}
                />
              </div>
              <div className="relative">
                <InputFieldRef
                  title="Zip Code"
                  placeholder="Zip Code"
                  type="text"
                  inputRef={transactionFiltersRef?.zipCode}
                  value={transactionFiltersRef?.zipCode?.current?.value}
                />
              </div>
              {permissionGranted([
                `corporate-manager:*`,
                `call-center-user:*`,
              ]) && (
                <div className="relative">
                  <ReactSelectField
                    title="Location"
                    placeholder="Location"
                    value={transactionQuoteFilters.locationId}
                    setValue={(value) => {
                      setTransactionQuoteFilters((prevState) => ({
                        ...prevState,
                        [`locationId`]: value,
                      }))
                    }}
                    isMulti={false}
                    loadOptions={loadLocations}
                  />
                </div>
              )}
              <div className="relative">
                <InputFieldRef
                  title="VIN"
                  placeholder="VIN"
                  type="text"
                  inputRef={transactionFiltersRef?.vin}
                  value={transactionFiltersRef?.vin?.current?.value}
                />
              </div>
              <div className="relative">
                <InputFieldRef
                  title="Policy No"
                  placeholder="Policy No"
                  type="text"
                  inputRef={transactionFiltersRef?.policyNo}
                  value={transactionFiltersRef?.policyNo?.current?.value}
                />
              </div>
              <div className="relative">
                <InputFieldRef
                  title="Driver License"
                  placeholder="Driver License"
                  type="text"
                  inputRef={transactionFiltersRef?.driverLicense}
                  value={transactionFiltersRef?.driverLicense?.current?.value}
                />
              </div>
              <div className="relative">
                <InputFieldRef
                  title="Vehicle Year"
                  placeholder="Vehicle Year"
                  type="text"
                  inputRef={transactionFiltersRef?.year}
                  value={transactionFiltersRef?.year?.current?.value}
                />
              </div>
              <div className="relative">
                <InputFieldRef
                  title="Vehicle Make"
                  placeholder="Vehicle Make"
                  type="text"
                  inputRef={transactionFiltersRef?.make}
                  value={transactionFiltersRef?.make?.current?.value}
                />
              </div>
              <div className="relative">
                <InputFieldRef
                  title="Vehicle Model"
                  placeholder="Vehicle Model"
                  type="text"
                  inputRef={transactionFiltersRef?.model}
                  value={transactionFiltersRef?.model?.current?.value}
                />
              </div>
              <div className="relative">
                <ReactSelectField
                  title="Data Op"
                  placeholder="Choose"
                  value={transactionQuoteFilters.DataOp}
                  setValue={(value) => {
                    setTransactionQuoteFilters((prevState) => ({
                      ...prevState,
                      [`DataOp`]: value,
                    }))
                  }}
                  isMulti={false}
                  loadOptions={loadDataOpp}
                />
              </div>
              {/* <div className="relative">
                <ReactSelectField
                  title="Has Contact Info"
                  placeholder="Choose"
                  value={transactionQuoteFilters.hasContactInfo}
                  setValue={(value) => {
                    setTransactionQuoteFilters((prevState) => ({
                      ...prevState,
                      [`hasContactInfo`]: value,
                    }))
                  }}
                  isMulti={false}
                  loadOptions={loadHasContactInfo}
                />
              </div> */}
              <div className="relative">
                <label className={`text-gray-700 font-medium text-sm`}>
                  Effect Date
                </label>
                <div className="flex gap-x-2">
                  <Input
                    type="text"
                    name="startRange"
                    placeholder="Start Date"
                    value={
                      dateRange[0]?.startDate
                        ? dateFormat(dateRange[0].startDate)
                        : ``
                    }
                    onFocus={() => setPreviewModal(true)}
                  />
                  <Input
                    type="text"
                    name="endRange"
                    placeholder="End Date"
                    value={
                      dateRange[0]?.endDate
                        ? dateFormat(dateRange[0].endDate)
                        : ``
                    }
                    disabled={true}
                  />
                </div>
              </div>
              {previewModal && (
                <ReactModal
                  title="Date Range"
                  closeModal={() => setPreviewModal(false)}
                >
                  <div className="pb-2">
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setDateRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={dateRange}
                      className="w-full"
                    />
                    <div className="px-2">
                      <StyledButton
                        onClick={() => {
                          setPreviewModal(false)
                        }}
                      >
                        <div className="flex gap-2 text-white">
                          <span>Select</span>
                        </div>
                      </StyledButton>
                    </div>
                  </div>
                </ReactModal>
              )}
            </div>
            <div className="grid grid-cols-4 gap-4 mt-4">
              <div className="flex  gap-4">
                <SubmitButton
                  onClick={() => {
                    setCurrentPage(1)
                    setCustomSearching(true)
                    handleSearchFilter()
                  }}
                >
                  Search
                </SubmitButton>
                <SubmitButton
                  onClick={() => {
                    setCurrentPage(1)
                    setCustomSearching(true)
                    handleResetFilters()
                  }}
                >
                  Reset
                </SubmitButton>
              </div>
            </div>
          </Collapsable>
        </div>
        <div className="flex justify-between items-center pt-2">
          <StyledButton
            onClick={() =>
              setStateChecked((prevState) => ({
                ...prevState,
                allSelected: !stateChecked.allSelected,
              }))
            }
          >
            <div className="flex gap-2 text-white">
              <span>
                {stateChecked?.allSelected ? `Selected ` : `Select All `}
                {dataTransactionsData?.totalRecords -
                  stateChecked.unselected.length}
              </span>
            </div>
          </StyledButton>
          {permissionGranted([
            `broadcasts:create`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && (
            <StyledButton
              disabled={!stateChecked?.selected?.length}
              onClick={() => setStartCampaignModal(true)}
            >
              <div className="flex gap-2 text-white">
                <span>Start Campaign </span>
              </div>
            </StyledButton>
          )}
        </div>
        {error ? (
          <Reload refetch={() => getAllDataTransaction()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    <TableCell className="text-center">
                      <div className="flex-1">
                        <Checkbox
                          name="checkAll"
                          value={stateChecked?.isCheckedAll}
                          setValue={(e) => handleCheckAll(e)}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        />
                      </div>
                    </TableCell>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div
                            className={`cursor-pointer select-none text-sm flex truncate ${
                              item?.isLast && `float-right`
                            }`}
                          >
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {dataTransactionsData?.scrapes?.length != 0 ? (
                    dataTransactionsData?.scrapes?.map(
                      (dataTransaction, index) => (
                        <TableRow
                          key={index.toString()}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            history.push(`${url}/${dataTransaction._id}`)
                            dispatch(
                              setDataTrasActivityActiveTab(dataTransaction),
                            )
                          }}
                        >
                          <td
                            className="px-4 text-sm"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              name={dataTransaction?._id}
                              value={stateChecked?.selected.includes(
                                dataTransaction?._id,
                              )}
                              setValue={(e) =>
                                handleIndividualChekbox(e, dataTransaction?._id)
                              }
                            />
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm">
                            {dataTransaction.data?.policyInfo?.policyNo}
                          </td>
                          <td className="px-4 py-0.5 z-0 truncate text-sm">
                            {dataTransaction.data?.primaryInsured.firstName +
                              ` ` +
                              dataTransaction.data?.primaryInsured.lastName}
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm">
                            <Badge type="primary">
                              {dataTransaction.dataOp
                                ? dataTransaction.dataOp
                                : `N/A`}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 z-0 text-sm truncate">
                            {
                              dataTransaction.data?.primaryInsured
                                ?.postalAddress?.zip
                            }
                          </td>
                          <td className="px-16 py-0.5 z-0 text-sm float-right truncate">
                            <span>
                              {dateFormat(dataTransaction.effectiveDate)}
                            </span>
                          </td>
                        </TableRow>
                      ),
                    )
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Data Found</div>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </table>
            </div>

            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={dataTransactionsData?.totalPages}
                totalRecords={dataTransactionsData?.totalRecords}
                curerntPageRecords={dataTransactionsData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
