import {
  faEdit,
  faMinus,
  faPlus,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { InputField } from 'App/Components/Common/InputField'
import { ReactModal } from 'App/Components/Common/Modal'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Reload } from 'App/Components/Common/Reload'
import { Spinner } from 'App/Components/Common/Spinner'
import { TextArea } from 'App/Components/Common/TextArea'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Container, PageHeader, StyledButton, SubmitButton } from 'App/Styled'

import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'

import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const AddTemplate = () => {
  const history = useHistory()

  const [template, setTemplate] = useState({
    duration: ``,
    frequency: ``,
    afterEvery: ``,
    title: ``,
    dueDate: ``,
    team: [],
  })

  const [customCategory, setCustomCategory] = useState({ category: `` })
  const [customQuestion, setCustomQuestion] = useState({
    questions: ``,
  })
  const [questionsList, setQuestionsList] = useState([])
  const [afterEveryTitle, setAfterEveryTitle] = useState(``)
  const [recurringValue, setRecurringValue] = useState(false)
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)
  const [openTextAreaModal, setOpenTextAreaModal] = useState(false)
  const [openCategoryModal, setOpenCategoryModal] = useState(false)
  const [customCategoryModal, setCustomCategoryModal] = useState(false)

  const {
    isLoading,
    isFetching,
    error: defaultQuestionsError,
    data: assessmentResponse,
    refetch: getAllDefaultQuestions,
  } = useQuery(
    `AssessmentGetApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/get/default-assessment-questions?search={"query":""}&offset=10&page=1&sort="-createdAfter"
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  const [defaultQuestionsArray, setDefaultQuestionsArray] = useState([])

  useEffect(() => {
    setDefaultQuestionsArray(() => {
      return assessmentResponse?.defaultQuestions?.map((response) => {
        return {
          categoryName: response.categoryName,
          questions: response?.questions,
          checked: new Array(response?.questions?.length).fill(false),
        }
      })
    })
  }, [assessmentResponse])

  const {
    mutate,
    isError,
    isLoading: LoadingTemplate,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/assessment/template`, {
        ...payload,
      })
        .then((res) => {
          if (res?.data) {
            NotificationManager.success(`Operation successful`)
            history.push(`/assessment/template`)
          }
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error?.error ?? `Something went wrong!`,
          )
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const preparePayload = () => {
    let data = {}
    data._franchise = _franchise._id
    if (template.title?.length) {
      data.templateName = template.title
    }

    if (questionsList?.length) {
      data.assessmentQuestions = questionsList?.map((list) => ({
        category: list.categoryName,
        performanceIndicators: list.questions?.map((question) => ({
          statement: question,
        })),
      }))
    }

    if (
      template.duration?.value?.length ||
      template.afterEvery?.value ||
      template.frequency?.value
    ) {
      data.createdAfter = {
        recurringType: template.duration.value,
        recurringAfter: template.afterEvery.value,
        recurringCustomType: template.frequency.value,
      }
    }
    if (template?.team?.length) {
      data.assignTo = template?.team.map((assignee) => assignee.value)
    }
    if (template?.team?.length) {
      data._locations = template?.team.map((assignee) => assignee.location)
    }
    if (template.dueDate) {
      data.assessmentDueDate = template.dueDate
    }
    return data
  }

  const loadTeam = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/assessment-team/list?search={"query":""}&page=${page}&offset=10`,
    )
    if (data) {
      return {
        options: data.teams,
        hasMore: data?.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  const loadDuration = () => {
    const options = {
      options: [
        { value: `NONE`, label: `NONE` },
        { value: `DAY`, label: `DAY` },
        { value: `WEEK`, label: `WEEK` },
        { value: `FORTNIGHT`, label: `FORTNIGHT` },
        { value: `MONTH`, label: `MONTH` },
        { value: `YEAR`, label: `YEAR` },
        { value: `CUSTOM`, label: `CUSTOM` },
      ],
      hasMore: false,
    }
    return options
  }
  const loadFrequency = () => {
    const options = {
      options: [
        { value: `DAY`, label: `DAY` },
        { value: `WEEK`, label: `WEEK` },
        { value: `MONTH`, label: `MONTH` },
        { value: `YEAR`, label: `YEAR` },
      ],
      hasMore: false,
    }
    return options
  }

  const afterEvery = () => {
    const options = []
    for (let i = 1; i <= 30; ++i) {
      options.push({ value: i, label: i.toString() })
    }
    return { options, hasMore: false }
  }

  const handleRecurringValue = (value, name) => {
    if (name === `duration`) {
      if (value.value === `CUSTOM`) setRecurringValue(true)
      else {
        setRecurringValue(false)
        setTemplate((prevState) => ({
          ...prevState,
          [name]: ``,
        }))
      }
    }

    if (name === `frequency`) {
      if (value.value === `DAY`) setAfterEveryTitle(`Day`)
      else if (value.value === `WEEK`) setAfterEveryTitle(`WEEK`)
      else if (value.value === `MONTH`) setAfterEveryTitle(`MONTH`)
      else if (value.value === `YEAR`) setAfterEveryTitle(`YEAR`)
    }

    setTemplate((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const [errors, setErrors] = useState({
    formSubmit: false,
    durationError: ``,
    afterEveryError: ``,
    frequencyError: ``,
    titleError: ``,
    dueDateError: ``,
    teamError: ``,
  })

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const ValidateForm = () => {
    if (!template?.title?.length) {
      handleErrors(`titleError`, `Select Template Title`)
    } else {
      handleErrors(`titleError`, ``)
    }
    if (!template.duration?.value?.length) {
      handleErrors(`durationError`, `Select Duration`)
    } else {
      handleErrors(`durationError`, ``)
    }
    if (
      template?.duration?.value === `CUSTOM` &&
      !template.frequency?.value?.length
    ) {
      handleErrors(`frequencyError`, `Select Frequency`)
    } else {
      handleErrors(`frequencyError`, ``)
    }
    if (!template.dueDate) {
      handleErrors(`dueDateError`, `Select Due Date `)
    } else {
      handleErrors(`dueDateError`, ``)
    }
    if (!template.team?.length) {
      handleErrors(`teamError`, `Select Team`)
    } else {
      handleErrors(`teamError`, ``)
    }

    if (template?.duration?.value === `CUSTOM` && !template.afterEvery?.value) {
      handleErrors(`afterEveryError`, `Select After Every `)
    } else {
      handleErrors(`afterEveryError`, ``)
    }

    let isCategoryQuestion = questionsList?.some(
      (question) => question?.questions?.length === 0,
    )
    if (isCategoryQuestion) {
      NotificationManager.error(`Category must not be added without question`)

      handleErrors(`formSubmit`, false)
    } else {
      handleErrors(`formSubmit`, true)
    }
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.durationError.length &&
      !errors.frequencyError.length &&
      !errors.afterEveryError.length &&
      !errors.titleError.length &&
      !errors.dueDateError.length &&
      !errors.teamError.length
    ) {
      handleErrors(`formSubmit`, false)
      mutate(preparePayload())
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const checkHandler = (e, defaultQuestion, categoryName) => {
    let categroyListIndex = defaultQuestionsArray?.findIndex(
      (questionList) => questionList.categoryName == categoryName,
    )
    let questionListIndex = defaultQuestionsArray[
      categroyListIndex
    ]?.questions?.findIndex((question) => question === defaultQuestion)

    setDefaultQuestionsArray((prevState) => {
      const newState = [...prevState]
      newState[categroyListIndex].checked[questionListIndex] =
        !newState[categroyListIndex].checked[questionListIndex]
      return newState
    })

    if (e.target.checked) {
      let categoryIndex = questionsList.findIndex(
        (item) => item.categoryName === categoryName,
      )
      if (categoryIndex === -1) {
        setQuestionsList((prevState) => [
          ...prevState,
          { categoryName, questions: [defaultQuestion] },
        ])
      } else {
        questionsList[categoryIndex].questions.push(defaultQuestion)
      }
    } else {
      let categroyQuestionListIndex = questionsList.findIndex(
        (questionList) => questionList.categoryName == categoryName,
      )
      let categroyQuestionList = questionsList[
        categroyQuestionListIndex
      ].questions.filter((question) => question !== question)

      let index = questionsList[categroyQuestionListIndex].questions.findIndex(
        (x) => x === defaultQuestion,
      )
      if (questionsList[categroyQuestionListIndex].questions.length == 1) {
        questionsList.splice(categroyQuestionListIndex, 1)
      } else {
        questionsList[categroyQuestionListIndex].questions.splice(index, 1)
      }
      if (!categroyQuestionList.length) {
        questionsList
      }
    }
  }

  ///delete question

  const defaultQuestionsResponse = assessmentResponse?.defaultQuestions?.map(
    (questions) => questions?.questions,
  )
  const defaultQuestion = defaultQuestionsResponse?.flatMap(
    (question) => question,
  )

  const customQuestionsResponse = questionsList?.flatMap(
    (custom) => custom?.questions,
  )

  const filteredCustomQuestion = customQuestionsResponse.filter((question) => {
    return defaultQuestion.indexOf(question) === -1
  })

  const handleDelete = (specificQuestion, categoryName) => {
    let categroyQuestionListIndex = questionsList.findIndex(
      (questionList) => questionList.categoryName == categoryName,
    )

    let categroyQuestionList = questionsList[
      categroyQuestionListIndex
    ].questions.filter((question) => question !== question)

    let index = questionsList[categroyQuestionListIndex].questions.findIndex(
      (x) => x === specificQuestion,
    )

    if (questionsList[categroyQuestionListIndex].questions.length == 1) {
      questionsList.splice(categroyQuestionListIndex, 1)
    } else {
      questionsList[categroyQuestionListIndex].questions.splice(index, 1)
    }

    setQuestionsList((prevState) => [...prevState])

    if (!categroyQuestionList.length) {
      questionsList
    }
  }

  const [defaultCategory, setDefaultCategory] = useState(``)
  const [openUpdateTextAreaModal, setUpdateTextAreaModal] = useState(false)
  const [updateCustomQuestion, setUpdateCustomQuestion] = useState({
    updateQuestion: ``,
  })
  const [updateCustomCategory, setUpdateCutomCategory] = useState({
    updateCategory: ``,
  })
  const handleUpdate = (question, categoryName) => {
    setUpdateTextAreaModal(true)

    setQuestionsList((prevState) => {
      const categoryIndex = prevState.findIndex(
        (category) => category.categoryName === categoryName,
      )

      if (categoryIndex !== -1) {
        const updatedQuestions = [...prevState[categoryIndex].questions]
        const questionIndex = updatedQuestions.findIndex(
          (updateQuestions) => updateQuestions === question,
        )

        if (questionIndex !== -1) {
          updatedQuestions[questionIndex] = updateCustomQuestion.updateQuestion

          if (!updatedQuestions[questionIndex]) {
            return prevState
          }
        }

        const updatedCategory = {
          ...prevState[categoryIndex],
          questions: updatedQuestions,
        }

        const newPrevState = [...prevState]
        newPrevState[categoryIndex] = updatedCategory

        return newPrevState
      }

      return prevState
    })
  }

  const handleDeleteCategory = (categoryName) => {
    setQuestionsList((prevState) => {
      return [
        ...prevState.filter(
          (category) => category.categoryName !== categoryName,
        ),
      ]
    })

    let categroyListindex = defaultQuestionsArray.findIndex(
      (questionList) => questionList.categoryName == categoryName,
    )

    if (categroyListindex !== -1) {
      setDefaultQuestionsArray((prevState) => {
        const newState = [...prevState]
        newState[categroyListindex].checked = [
          ...newState[categroyListindex].checked.fill(false),
        ]
        return [...newState]
      })
    }
  }

  const handleKeyDown = (event) => {
    const key = event.key
    // Prevent alphabetic characters from being entered
    if (/^[a-zA-Z]+$/.test(key)) {
      event.preventDefault()
    }
  }
  const handleUpdateCategory = (oldCategoryName, newCategoryName) => {
    if (!newCategoryName) {
      return
    }

    setCustomCategoryModal(true)

    setQuestionsList((prevState) => {
      const categoryIndex = prevState.findIndex(
        (category) => category.categoryName === oldCategoryName,
      )

      if (categoryIndex !== -1) {
        const updatedCategory = {
          ...prevState[categoryIndex],
          categoryName: newCategoryName,
        }

        const newPrevState = [...prevState]
        newPrevState[categoryIndex] = updatedCategory

        return newPrevState
      }

      return prevState
    })
  }

  const [specificQuestion, setSpecificQuestion] = useState(``)
  const [specificCategory, setSpecificCategory] = useState(``)
  const [specificCustomCategory, setSpecificCustomCategory] = useState(``)
  const hasQuestions = questionsList.some(
    (question) => question.questions?.length > 0,
  )

  const customCategoryList = defaultQuestionsArray?.map(
    (category) => category?.categoryName,
  )

  return (
    <div className="grid grid-cols-12 gap-2">
      <div
        className={`grid col-span-8 ${
          LoadingTemplate && `opacity-30 pointer-events-none`
        }  overflow-y-auto h-88vh hide-scroller hide-scroller-firefox`}
      >
        <Container>
          {isError && (
            <ReactNotification action="error" message="Something went wrong" />
          )}
          <Spinner loading={LoadingTemplate} />

          {openCategoryModal && (
            <ReactModal
              title="Add Custom Category"
              closeModal={() => {
                setOpenCategoryModal(false), setCustomCategory(``)
              }}
              className="px-10"
              width="1/2"
            >
              <div className="w-full">
                <InputField
                  type="text"
                  name="categoryName"
                  title="Category Name"
                  placeholder="Enter Category Name"
                  maxLength={100}
                  value={customCategory?.category}
                  setValue={(e) => {
                    setCustomCategory((prevState) => ({
                      ...prevState,
                      [`category`]: e.target.value,
                    }))
                  }}
                />
              </div>
              <div className="w-full flex justify-end pb-4">
                <StyledButton
                  onClick={() => {
                    if (customCategory?.category?.length) {
                      const defaultCategories =
                        assessmentResponse?.defaultQuestions?.some(
                          (category) =>
                            customCategory?.category?.toUpperCase() ===
                            category?.categoryName.trim(),
                        )
                      const customCategories = questionsList?.some(
                        (category) =>
                          customCategory?.category.toUpperCase() ===
                          category?.categoryName.toUpperCase().trim(),
                      )

                      if (defaultCategories || customCategories)
                        NotificationManager.error(`Category already defined`)
                      else {
                        setQuestionsList((prevState) => [
                          ...prevState,
                          {
                            categoryName: customCategory?.category,
                            questions: [],
                          },
                        ])
                        setOpenCategoryModal(false)
                        setCustomCategory(``)
                      }
                    } else {
                      setOpenCategoryModal(false)
                    }
                  }}
                >
                  Add
                </StyledButton>
              </div>
            </ReactModal>
          )}
          {customCategoryModal && (
            <ReactModal
              title="Update Custom Category"
              closeModal={() => {
                setCustomCategoryModal(false)
              }}
              className="px-10"
              width="1/2"
            >
              <div className="w-full">
                <InputField
                  type="text"
                  name="categoryName"
                  title="Category Name"
                  placeholder="Enter Category Name"
                  maxLength={100}
                  value={updateCustomCategory?.updateCategory}
                  setValue={(e) => {
                    setUpdateCutomCategory((prevState) => ({
                      ...prevState,
                      [`updateCategory`]: e.target.value,
                    }))
                  }}
                />
              </div>
              <div className="w-full flex justify-end pb-4">
                <StyledButton
                  onClick={() => {
                    handleUpdateCategory(
                      specificCustomCategory,
                      updateCustomCategory?.updateCategory,
                    )
                    setCustomCategoryModal(false)
                  }}
                >
                  Update
                </StyledButton>
              </div>
            </ReactModal>
          )}
          {openTextAreaModal && (
            <ReactModal
              title="Custom Question"
              closeModal={() => {
                setOpenTextAreaModal(false), setCustomQuestion(``)
              }}
              className="px-10"
              width="1/2"
            >
              <div className="w-full">
                <TextArea
                  type="textarea"
                  title="Add Question"
                  placeholder="Type Question..."
                  value={customQuestion?.questions}
                  setValue={(e) => {
                    setCustomQuestion((prevState) => ({
                      ...prevState,
                      [`questions`]: e.target.value,
                    }))
                  }}
                />
              </div>

              <div className="w-full flex justify-end pb-4">
                <StyledButton
                  onClick={() => {
                    if (customQuestion?.questions?.length) {
                      let categoryIndex = questionsList.findIndex(
                        (item) => item.categoryName === defaultCategory,
                      )
                      if (categoryIndex === -1) {
                        setQuestionsList((prevState) => [
                          ...prevState,
                          {
                            categoryName: defaultCategory,
                            questions: [customQuestion?.questions],
                          },
                        ])
                      } else {
                        questionsList[categoryIndex].questions.push(
                          customQuestion?.questions,
                        )
                      }
                      setOpenTextAreaModal(false)
                      setCustomQuestion(``)
                    } else {
                      setOpenTextAreaModal(false)
                    }
                  }}
                >
                  Add
                </StyledButton>
              </div>
            </ReactModal>
          )}

          {openUpdateTextAreaModal && (
            <ReactModal
              title="Update Custom Question"
              closeModal={() => {
                setUpdateTextAreaModal(false)
              }}
              className="px-10"
              width="1/2"
            >
              <div className="w-full">
                <TextArea
                  type="textarea"
                  title="Update Question"
                  placeholder="Type Question..."
                  value={updateCustomQuestion?.updateQuestion}
                  setValue={(e) => {
                    setUpdateCustomQuestion((prevState) => ({
                      ...prevState,
                      [`updateQuestion`]: e.target.value,
                    }))
                  }}
                />
              </div>

              <div className="w-full flex justify-end pb-4">
                <StyledButton
                  onClick={() => {
                    handleUpdate(specificQuestion, specificCategory)
                    setUpdateTextAreaModal(false)
                  }}
                >
                  Update
                </StyledButton>
              </div>
            </ReactModal>
          )}

          <div className="flex justify-between mx-9">
            <PageHeader>Add Assessment Template</PageHeader>
            <div className="flex justify-center items-center">
              <StyledButton
                onClick={() => {
                  setOpenCategoryModal(true)
                }}
              >
                Add Category <FontAwesomeIcon icon={faPlusCircle} size="lg" />
              </StyledButton>
            </div>
          </div>
          <div className="flex gap-6 mx-10">
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Template Title *"
                placeholder="Enter Template Title"
                maxLength={100}
                value={template.title}
                errorMessage={errors.titleError}
                setValue={(e) => {
                  handleRecurringValue(e.target.value, `title`)
                }}
              />
            </div>

            <div className="flex-1 relative">
              <ReactSelectField
                title="Team *"
                placeholder="Select Team"
                errorMessage={errors.teamError}
                value={template.team?.value}
                setValue={(value) => {
                  handleRecurringValue(value, `team`)
                }}
                isMulti={true}
                loadOptions={loadTeam}
                isSearchable={false}
              />
            </div>
          </div>
          <div className="flex gap-6 mx-10">
            <div className="flex-1 relative">
              <DatePickerField
                title="Due Date *"
                placeholder="Select Due Date"
                minDate={new Date()}
                value={template.dueDate}
                errorMessage={errors.dueDateError}
                setValue={(e) => {
                  handleRecurringValue(e, `dueDate`)
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="flex flex-col px-5 mx-5 my-10">
            {!questionsList?.length ? (
              <div className="text-center">No Question Found</div>
            ) : (
              questionsList?.map((items, key) => (
                <>
                  <span className="font-bold text-sm pl-2 py-0.5 bg-red-500 text-white rounded-sm">
                    {`${String.fromCharCode(key + 65)}) `}
                    {items?.categoryName.toUpperCase()}
                    <span
                      className="float-right cursor-pointer tooltip tooltip-top pr-4"
                      data-tip={`Delete Category`}
                      onClick={() => {
                        handleDeleteCategory(items?.categoryName)
                      }}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </span>
                    <span
                      className="float-right px-3 cursor-pointer tooltip tooltip-top"
                      onClick={() => {
                        setOpenTextAreaModal(true)
                        setDefaultCategory(items?.categoryName)
                      }}
                      data-tip={`Add Question`}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </span>

                    {customCategoryList?.includes(items?.categoryName) ? (
                      ``
                    ) : (
                      <span
                        className="float-right cursor-pointer tooltip tooltip-top"
                        data-tip={`Edit Category`}
                        onClick={() => {
                          setSpecificCustomCategory(items?.categoryName)
                          setUpdateCutomCategory({
                            updateCategory: items?.categoryName,
                          })
                          setCustomCategoryModal(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </span>
                    )}
                  </span>

                  <div className="grid gap-8 grid-cols-2 font-semibold text-xs py-5 ">
                    {items?.questions?.map((categoryQuestions, index) => (
                      <div className={`${index % 2 && `border-l-2`} px-4`}>
                        {filteredCustomQuestion?.includes(categoryQuestions) ? (
                          <div>
                            <span>Q. {categoryQuestions}&nbsp;</span>
                            <span className="float-right">
                              <span
                                className="tooltip tooltip-left"
                                data-tip="Update Question"
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  onClick={() => {
                                    setSpecificCategory(items?.categoryName)
                                    setSpecificQuestion(categoryQuestions)
                                    setUpdateCustomQuestion({
                                      updateQuestion: categoryQuestions,
                                    })
                                    setUpdateTextAreaModal(true)
                                  }}
                                  className="cursor-pointer text-gray-800 mx-0.5"
                                />
                              </span>
                              <span
                                className="tooltip tooltip-left"
                                data-tip="Delete Question"
                              >
                                <FontAwesomeIcon
                                  icon={faMinus}
                                  onClick={() => {
                                    handleDelete(
                                      categoryQuestions,
                                      items?.categoryName,
                                    )
                                  }}
                                  className="cursor-pointer text-gray-800 mx-0.5"
                                />
                              </span>
                            </span>
                          </div>
                        ) : (
                          <span> Q. {categoryQuestions}</span>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              ))
            )}
          </div>
          {hasQuestions && (
            <div className="mx-9 my-5">
              <SubmitButton onClick={() => ValidateForm()}>
                Add Template
              </SubmitButton>
            </div>
          )}
        </Container>
      </div>
      <div className="grid col-span-4 overflow-y-auto h-88vh hide-scroller-firefox">
        <Container>
          <PageHeader className="px-5">Questions</PageHeader>
          <div className="p-5 grid grid-cols-1 2xl:grid-cols-2 my-2">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Recurring *"
                placeholder="Select Recurring"
                errorMessage={errors.durationError}
                value={template.duration}
                setValue={(value) => {
                  handleRecurringValue(value, `duration`)
                }}
                isMulti={false}
                loadOptions={loadDuration}
                isSearchable={false}
              />
            </div>
            {recurringValue && (
              <>
                <div className="flex-1 relative">
                  <ReactSelectField
                    title="Frequency *"
                    placeholder="Select Frequency"
                    errorMessage={errors.frequencyError}
                    value={template.frequency}
                    setValue={(value) => {
                      handleRecurringValue(value, `frequency`)
                    }}
                    isMulti={false}
                    setRecurringValue={true}
                    loadOptions={loadFrequency}
                    isSearchable={false}
                  />
                </div>

                <div className="flex-1 relative">
                  <ReactSelectField
                    title={`After Every ${afterEveryTitle} *`}
                    placeholder="Select after Every"
                    errorMessage={errors.afterEveryError}
                    value={template.afterEvery}
                    setValue={(value) => {
                      handleRecurringValue(value, `afterEvery`)
                    }}
                    isMulti={false}
                    setRecurringValue={true}
                    loadOptions={afterEvery}
                    isSearchable={false}
                  />
                </div>
              </>
            )}
          </div>

          <div className="flex flex-col px-5">
            <Spinner loading={isLoading || isFetching} />
            {defaultQuestionsError ? (
              <Reload refetch={() => getAllDefaultQuestions()} />
            ) : (
              <>
                {defaultQuestionsArray?.map((response, index) => (
                  <>
                    <span className="font-bold text-sm pl-2">
                      {`${String.fromCharCode(index + 65)}) `}
                      {response?.categoryName}
                    </span>

                    {response?.questions?.map((categoryQuestions, key) => (
                      <div key={key} className="grid grid-cols-12">
                        <span
                          className="col-span-2"
                          onChange={(e) =>
                            checkHandler(
                              e,
                              categoryQuestions,
                              response?.categoryName,
                            )
                          }
                        >
                          <Checkbox
                            value={response.checked[key]}
                            isCustom={true}
                          />
                        </span>
                        <span className="col-span-10 py-2 text-sm">
                          {categoryQuestions}
                        </span>
                      </div>
                    ))}
                  </>
                ))}
              </>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}
