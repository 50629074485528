import React, { useContext, useEffect, useState } from 'react'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { StyledButton } from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useMutation } from 'react-query'
import { NotificationManager } from 'react-notifications'
import { loadVerifyOptions } from 'App/Services/General/loadVerifyStatus'
import { PolicyContext } from '../PolicyPreview'

export const VerifyPolicy = ({ selectedPolicy, closeModal }) => {
  const [leadVerify, setLeadVerify] = useState(
    selectedPolicy?.verificationStatus
      ? {
          value: selectedPolicy?.verificationStatus,
          label: selectedPolicy?.verificationStatus,
        }
      : ``,
  )

  const [errors, setErrors] = useState({
    formSubmit: false,

    leadVerifyError: ``,
  })
  const { refetch } = useContext(PolicyContext)

  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/policies/${selectedPolicy._id}`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res.data) {
          refetch()
          closeModal()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    if (updationError) {
      NotificationManager.error(`Something went wrong`)
    }
  }, [updationError])

  const ValidateForm = () => {
    if (leadVerify?.value?.length) {
      handleErrors(`leadVerifyError`, ``)
    } else {
      handleErrors(`leadVerifyError`, `Select Status`)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (errors.formSubmit && !errors.leadVerifyError.length) {
      //call API
      mutate(updatePayload())
      setErrors((prevState) => ({
        ...prevState,
        formSubmit: false,
      }))
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}

    if (leadVerify?.value?.length) {
      data.verificationStatus = leadVerify?.value
    }

    return data
  }

  return (
    <>
      <div className="w-96 px-4 py-1">
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Status"
              placeholder="Select Status"
              errorMessage={errors?.leadVerifyError}
              value={leadVerify}
              setValue={(e) => setLeadVerify(e)}
              isMulti={false}
              loadOptions={loadVerifyOptions}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="pb-0.5 text-right">
          <StyledButton onClick={ValidateForm} disabled={isLoading}>
            <div className="flex gap-2 text-white">
              <span>Verify Status</span>
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </StyledButton>
        </div>
      </div>
    </>
  )
}
