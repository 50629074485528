import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faSortDown,
  faSortUp,
  faSpinner,
  faMobileAlt,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { isSuperUser, loadFranchises, permissionGranted } from 'App/Services'
import { ReactModal } from 'App/Components/Common/Modal'
import { StartCampaign } from 'App/Components/StartCampaign'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadPreferredContact } from 'App/Services/quoteForm'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
import dateFormat from 'App/Services/General/dateFormat'
import Filters from '../FiltersCustomer'

const tableColumns = [
  { label: `ID`, value: `_customerId` },
  { label: `First Name`, value: `firstName` },
  { label: `Last Name`, value: `lastName` },
  // { label: `Type`, value: `type` },
  { label: `Email`, value: `contactInfo.email` },
  { label: `Phone`, value: `contactInfo.phone` },
  { label: `Policies`, value: `policyCount` },
  { label: `Policy No`, value: `policyNo` },
  { label: `Created`, value: `createdAt` },
  { label: `Updated`, value: `updatedAt` },
]

export const AllCustomers = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const [inActiveCheckedAll, setinActiveCheckedAll] = useState([])
  const [startCampaignModal, setStartCampaignModal] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState(``)
  const [updateContactMethod, setUpdateContactMethod] = useState(false)
  const [customerContactMethods, setCustomerContactMethods] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [selectedChannel, setSelectedChannel] = useState(``)

  const [stateChecked, setStateChecked] = useState({
    allSelected: false,
    isCheckedAll: false,
    selected: [],
    unselected: [],
  })
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [franchise, setFranchise] = useState(``)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)

  const [customerFilters, setCustomerFilters] = useState({
    name: ``,
    email: ``,
    phone: ``,
    policyNo: ``,
    _customerId: ``,
  })

  //  date range picker
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
    },
  ])
  //   getting all customers
  const {
    isLoading,
    data: customersData,
    refetch: getAllCustomers,
    isFetching,
    error,
  } = useQuery(
    `CustomersApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/customers/all?search=${searchFilters()}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }${franchise?.value?.length ? `&_franchise=${franchise?.value}` : ``}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomSearching(false)
        setStateChecked((prevState) => ({
          ...prevState,
          isCheckedAll: handleIsCheckedAllSelected(res?.customers),
        }))
        if (stateChecked.allSelected) {
          setStateChecked((prevState) => ({
            ...prevState,
            isCheckedAll: !inActiveCheckedAll.includes(currentPage),
            selected: handleAddSelected(res?.customers),
          }))
        }
      },
    },
  )

  const {
    mutate: updateCustomer,
    isLoading: updatingCustomer,
    isError: updateError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/customers/${payload.customerId}`,
        {
          preferredContactMethods: payload.preferredContactMethods,
        },
      ).then((res) => {
        if (res.data) {
          getAllCustomers()
          NotificationManager.success(`Operation Successful`)
          setUpdateContactMethod(false)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (!customSearching) {
      getAllCustomers()
    }
    const layOutDiv = document.getElementById(`divlayout`)
    if (layOutDiv && isOffset?.value > 10) {
      layOutDiv.scrollTo(0, 90)
      layOutDiv.style.scrollBehavior = `smooth`
    }
  }, [sort, currentPage, isOffset, franchise, customerFilters])

  const handleContactMethods = (customer, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    setCustomerContactMethods(
      customer?.preferredContactMethods?.map((method) => ({
        value: method,
        label: method.toUpperCase(),
      })),
    )

    setCurrentCustomer(customer)
    setUpdateContactMethod(true)
  }

  useEffect(() => {
    if (stateChecked.allSelected) {
      setStateChecked({
        allSelected: true,
        isCheckedAll: true,
        selected: customersData.customers?.map((lead) => lead._id),
        unselected: [],
      })
    } else {
      setStateChecked({
        allSelected: false,
        isCheckedAll: false,
        selected: [],
        unselected: [],
      })
    }
  }, [stateChecked.allSelected])

  const handleIsCheckedAllSelected = (currentLeads) => {
    const filterdSelected = currentLeads
      .map((lead) => {
        if (!stateChecked.selected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    if (filterdSelected.length) {
      return false
    } else {
      return true
    }
  }

  const handleAddSelected = (currentLeads) => {
    let filterdUnselected
    if (stateChecked.unselected.length) {
      filterdUnselected = currentLeads
        .map((lead) => {
          if (!stateChecked.unselected.includes(lead._id)) {
            return lead._id
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    } else {
      filterdUnselected = currentLeads.map((lead) => lead._id)
    }

    const filterdSelected = filterdUnselected
      .map((lead) => {
        if (!stateChecked.selected.includes(lead)) {
          return lead
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.selected, ...filterdSelected]
  }

  const handleIndividualChekbox = (e, id) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!e.target.checked) {
      setStateChecked((prevState) => ({
        ...prevState,
        selected: stateChecked.selected.filter((lead) => lead !== id),
        unselected: [...stateChecked.unselected, id],
      }))
    } else {
      setStateChecked((prevState) => ({
        ...prevState,
        selected: [...stateChecked.selected, id],
        unselected: stateChecked.unselected.filter((lead) => lead !== id),
      }))
    }
  }

  const handleCheckAll = (e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (e.target.checked) {
      setinActiveCheckedAll(
        inActiveCheckedAll.filter((el) => el !== currentPage),
      )
      setStateChecked((prevState) => ({
        ...prevState,
        isCheckedAll: true,
        selected: handleCheckedAllSelected(),
        unselected: removeOneArrayFromAnother(stateChecked.unselected),
      }))
    } else {
      setinActiveCheckedAll([...inActiveCheckedAll, currentPage])
      setStateChecked((prevState) => ({
        ...prevState,
        isCheckedAll: false,
        selected: removeOneArrayFromAnother(stateChecked.selected),
        unselected: handleRemvoeUnSelected(),
      }))
    }
  }

  const handleCheckedAllSelected = () => {
    const filterdSelected = customersData.customers
      .map((lead) => {
        if (!stateChecked.selected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.selected, ...filterdSelected]
  }

  const handleRemvoeUnSelected = () => {
    const filterdSelected = customersData.customers
      .map((lead) => {
        if (!stateChecked.unselected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.unselected, ...filterdSelected]
  }

  const removeOneArrayFromAnother = (removeFromArray) => {
    const leadsToBeRemoved = customersData.customers.map((lead) => lead._id)
    return removeFromArray.filter((el) => {
      return !leadsToBeRemoved.includes(el)
    })
  }

  const handleResetFilters = () => {
    setCustomerFilters({
      name: ``,
      email: ``,
      phone: ``,
      policyNo: ``,
      _customerId: ``,
    })
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: `selection`,
      },
    ])
  }

  const handleFiltersChange = (filters) => {
    setCurrentPage(1)
    setCustomerFilters(filters)
  }

  const handleDateRange = (range) => {
    setDateRange(range)
  }

  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }
    if (customerFilters?.name?.length) {
      data.filters.name = customerFilters?.name
    }
    if (customerFilters?.email?.length) {
      data.filters.email = customerFilters?.email
    }
    if (customerFilters?.policyNo?.length) {
      data.filters.policyNo = customerFilters?.policyNo
    }
    if (customerFilters?._customerId?.length) {
      data.filters._customerId = customerFilters?._customerId
    }
    if (customerFilters?.phone) {
      data.filters.phone = customerFilters?.phone
    }
    if (dateRange[0].isSelected) {
      data.filters.createdAt = [
        dateFormat(dateRange[0].startDate),
        dateFormat(dateRange[0].endDate),
      ]
    }
    return JSON.stringify(data)
  }

  return (
    <Container>
      {updateContactMethod && (
        <ReactModal
          title="Update Contact Methods"
          closeModal={() => setUpdateContactMethod(false)}
        >
          <div className="w-96 px-4 py-1">
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Contact Methods"
                  placeholder="Select Methods"
                  // errorMessage={errors.leadStatusError}
                  value={customerContactMethods}
                  setValue={(e) => setCustomerContactMethods(e)}
                  isMulti={true}
                  loadOptions={loadPreferredContact}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="pb-0.5 text-right">
              <StyledButton
                onClick={() =>
                  updateCustomer({
                    preferredContactMethods: customerContactMethods?.map(
                      (method) => method?.value,
                    ),
                    customerId: currentCustomer?._id,
                  })
                }
                disabled={!customerContactMethods?.length}
              >
                <div className="flex gap-2 text-white">
                  <span>Update</span>
                  {updatingCustomer && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin={true}
                      className="my-auto"
                    />
                  )}
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}
      {updateError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      {startCampaignModal && (
        <ReactModal
          title="Start Campaign"
          closeModal={() => setStartCampaignModal(false)}
          scroll={selectedChannel === `MAIL` ? true : false}
        >
          <StartCampaign
            usersList={stateChecked}
            closeModal={() => setStartCampaignModal(false)}
            source="customers"
            count={customersData?.totalRecords - stateChecked.unselected.length}
            selectedChannel={setSelectedChannel}
          />
        </ReactModal>
      )}
      <PageHeader bgcolor="bg-white">
        <div>Customers</div>
        <div className="flex items-center ">
          {/* <Toggle value={isMasterFrenchise} setValue={setIsMasterFrenchise} /> */}
          <div className="px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          {(isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)) && (
            <div className="px-2">
              <ReactSelectField
                placeholder="Select Franchise"
                value={franchise}
                setValue={(e) => {
                  setFranchise(e)
                }}
                isMulti={false}
                loadOptions={loadFranchises}
                isClearable={true}
              />
            </div>
          )}
          {permissionGranted([
            `customers:create`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && (
            <div className="flex justify-end items-center px-2 -mt-5">
              <StyledButton
                onClick={() => history.push(`${url}/policies/add`)}
                className="text-sm"
              >
                <div className="flex gap-2 text-white">
                  <span>Add Policy</span>
                  <FontAwesomeIcon icon={faPlusCircle} size="lg" />
                </div>
              </StyledButton>
            </div>
          )}
        </div>
      </PageHeader>

      <Filters
        ledgerFilters={customerFilters}
        handleFilters={handleFiltersChange}
        resetFilters={handleResetFilters}
        _dateRange={dateRange}
        handleDateChange={handleDateRange}
        isLoading={isLoading || isFetching}
      />

      <Spinner loading={isLoading || isFetching} />

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (isLoading || isFetching) && `opacity-30 pointer-events-none`
        } `}
      >
        {permissionGranted([
          `broadcasts:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <div className="flex justify-between items-center pt-2">
            <StyledButton
              onClick={() =>
                setStateChecked((prevState) => ({
                  ...prevState,
                  allSelected: !stateChecked.allSelected,
                }))
              }
            >
              <div className="flex gap-2 text-white">
                <span>
                  {stateChecked?.allSelected ? `Selected ` : `Select All `}
                  {customersData?.totalRecords - stateChecked.unselected.length}
                </span>
              </div>
            </StyledButton>
            <StyledButton
              disabled={!stateChecked?.selected?.length}
              onClick={() => setStartCampaignModal(true)}
            >
              <div className="flex gap-2 text-white">
                <span>Start Campaign </span>
              </div>
            </StyledButton>
          </div>
        )}

        {error ? (
          <Reload refetch={() => getAllCustomers()} />
        ) : (
          <>
            <div className="overflow-x-auto   rounded-t-md mt-1">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {permissionGranted([
                      `broadcasts:create`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) && (
                      <TableCell className="text-center">
                        <div className="flex-1">
                          <Checkbox
                            name="checkAll"
                            value={stateChecked?.isCheckedAll}
                            setValue={(e) => handleCheckAll(e)}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          />
                        </div>
                      </TableCell>
                    )}
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className=" cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item?.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(permissionGranted([
                      `customers:read`,
                      `call-center-user:*`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) ||
                      permissionGranted([
                        `customers:update`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) ||
                      permissionGranted([
                        `customers:edit`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) ||
                      permissionGranted([
                        `customers:delete`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ])) && (
                      <TableCell className="select-none text-sm float-right mt-2">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !customersData?.customers?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        No Customer Found
                      </td>
                    </tr>
                  ) : (
                    customersData?.customers?.map((_customer, index) => (
                      <TableRow
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          if (
                            permissionGranted([
                              `customers:read`,
                              `call-center-user:*`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])
                          ) {
                            history.push(`${url}/${_customer._id}`)
                          }
                        }}
                      >
                        {permissionGranted([
                          `broadcasts:create`,
                          `corporate-manager:*`,
                          `manager:*`,
                          `admin:*`,
                        ]) && (
                          <td
                            className="px-4 text-sm"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <Checkbox
                              name={_customer?._id}
                              value={stateChecked?.selected.includes(
                                _customer?._id,
                              )}
                              setValue={(e) =>
                                handleIndividualChekbox(e, _customer?._id)
                              }
                            />
                          </td>
                        )}

                        <td className="px-4 py-0.5 cursor-pointer  truncate text-sm">
                          {_customer._customerId ? (
                            _customer._customerId
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 cursor-pointer capitalize truncate text-sm">
                          {_customer.firstName ? (
                            _customer.firstName?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 cursor-pointer capitalize truncate text-sm">
                          {_customer.lastName ? (
                            _customer.lastName?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        {/* <td className="px-4 py-0.5 capitalize text-sm max-w-xs truncate">
                          {_customer.type ? (
                            _customer.type?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td> */}
                        <td className="px-4 py-0.5 text-sm">
                          {_customer.contactInfo?.email ? (
                            _customer.contactInfo.email
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-2 py-0.5 w-auto text-sm">
                          {_customer.contactInfo?.phone ? (
                            _customer.contactInfo?.phone
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 w-auto text-sm">
                          {_customer?.policyCount ? (
                            <Badge>{_customer?.policyCount}</Badge>
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className={`px-4  py-0.5 w-auto text-sm`}>
                          {_customer?.policyNo?.length > 0 ? (
                            _customer?.policyNo?.map((policy, index) => (
                              <span key={index} className="mr-1">
                                <Badge>{policy}</Badge>
                              </span>
                            ))
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 w-auto text-sm truncate">
                          {_customer.createdAt ? (
                            dateFormat(_customer.createdAt)
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 w-auto text-sm truncate">
                          {_customer.updatedAt ? (
                            dateFormat(_customer.updatedAt)
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        {/* Actions TD */}
                        <td className="px-6 py-3 text-sm z-0 flex float-right text-gray-400">
                          <span
                            data-tip="Update Contact Method"
                            className="tooltip tooltip-left"
                          >
                            {updatingCustomer &&
                            currentCustomer?._id === _customer?._id ? (
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin={true}
                                className="my-auto mx-1"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="mx-1 transform transition cursor-pointer  hover:text-red-500  hover:-translate-y-0 hover:scale-105"
                                icon={faMobileAlt}
                                size="1x"
                                onClick={(e) => {
                                  handleContactMethods(_customer, e)
                                }}
                              />
                            )}
                          </span>
                        </td>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={customersData?.totalPages}
                totalRecords={customersData?.totalRecords}
                curerntPageRecords={customersData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
