export const loadMailType = async () => {
  return {
    options: [
      {
        value: `POSTCARD`,
        label: `POST CARD`,
      },
      {
        value: `LETTER`,
        label: `LETTER`,
      },
    ],
    hasMore: false,
  }
}
