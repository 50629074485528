import React, { useEffect, useState, Fragment } from 'react'
import {
  Badge,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@windmill/react-ui'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { NotificationManager } from 'react-notifications'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { InputField } from 'App/Components/Common/InputField'
import { useSelector } from 'react-redux'
import { TextArea } from 'App/Components/Common/TextArea'
import { useHistory } from 'react-router-dom'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDebouncedCallback } from 'use-debounce/lib'
import searchQueryParser from 'App/Services/General/searchQueryParser'
export const AddSystemRoles = () => {
  const [searchQuery, setSearchQuery] = useState(``)
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [systemRoles, setSystemRoles] = useState({
    roleName: ``,
    roleDescription: ``,
    globalRole: false,
  })
  const [roleNameValidating, setRoleNameValidating] = useState(false)

  const history = useHistory()

  const [permissions, setPermissions] = useState([])

  const [errors, setErrors] = useState({
    roleNameError: ``,
    formSubmit: false,
  })
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)
  const tableColumns = [
    { label: `Name`, value: `name` },
    { label: `all`, value: `all` },
    { label: `create`, value: `create` },
    { label: `read`, value: `read` },
    { label: `update`, value: `update` },
    { label: `delete`, value: `delete` },
    { label: `list`, value: `list` },
  ]

  const {
    isLoading,
    data: systemRolesData,
    refetch: getAllSystemRoles,
    isFetching,
  } = useQuery(
    `SystemPermissionApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/get/system-permissions?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }page=${currentPage}&offset=${50}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const { mutate, isLoading: addRoleLOading } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/system-roles`, {
        ...payload,
      })
        .then((res) => {
          if (res.data) {
            NotificationManager.success(`Role added successfully`)
            history.push(`/admin/system-roles`)
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `'permissions' is required`,
              )
            ) {
              NotificationManager.error(`Permissions are required`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const ValidateDuplicateRoleName = async (payload) => {
    return await AxiosInstance.post(
      `${AMS_BACKEND_API}/api/validate/system-role-payloads`,
      payload,
    )
      .then((res) => {
        return res?.data
      })
      .catch((error) => {
        return error?.response?.data
      })
  }

  const validateRoleName = useDebouncedCallback(async (value) => {
    setRoleNameValidating(true)
    const response = await ValidateDuplicateRoleName({
      name: value.toUpperCase(),
    })

    if (response?.hasError) {
      setErrors((preState) => ({
        ...preState,
        roleNameError: `Name already exist`,
        formSubmit: false,
      }))
    } else {
      setErrors((preState) => ({
        ...preState,
        roleNameError: ``,
        formSubmit: false,
      }))
    }
    setRoleNameValidating(false)
  }, 2500)

  useEffect(() => {
    if (!customSearching) {
      getAllSystemRoles()
    }
  }, [currentPage])
  useEffect(() => {
    if (errors?.formSubmit && !errors?.roleNameError.length) {
      mutate(updatePayload())
      setErrors((preState) => ({
        ...preState,
        formSubmit: false,
      }))
    }
  }, [errors])

  const handleStericCheckbox = (event, index, permissionName) => {
    const isChecked = event.target.checked

    if (
      permissionName === `admin-master-franchise` ||
      permissionName === `admin-franchise` ||
      permissionName === `user-master-franchise`
    ) {
      permissionHandler(event, index, permissionName)
    }

    permissionName !== `admin-master-franchise` &&
    permissionName !== `admin-franchise` &&
    permissionName !== `user-master-franchise`
      ? isChecked
        ? setPermissions((prevArray) => [
            ...prevArray.filter(
              (item) => !item.startsWith(permissionName + `:`),
            ),
            permissionName + `:*`,
          ])
        : setPermissions(
            permissions?.filter(
              (item) => !item.startsWith(permissionName + `:*`),
            ),
          )
      : ``
  }
  const permissionHandler = (e, index, role) => {
    const filterRoles = role.split(`:`)

    const isChecked = e.target.checked
    let permission = isChecked
      ? [...permissions, role]
      : permissions?.filter((item) => !item?.startsWith(role))
    setPermissions(permission)
    const systemRole = Object?.values(systemRolesData?.systemPermissions[index])
    const newPermissions = systemRole.filter((item) => {
      return item !== filterRoles?.[0] && item !== filterRoles?.[0] + `:*`
    })
    // check all permissions is selected
    const isAllPermissions = newPermissions?.every((value) =>
      permission?.includes(value),
    )
    // if all permissions selected then steric checkbox automatic checked
    systemRole?.length > 2 &&
      isAllPermissions &&
      handleStericCheckbox(e, index, role?.split(`:`)?.[0])
  }

  const callRolesQuery = useDebouncedCallback(() => {
    getAllSystemRoles()
  }, 700)

  const validateFrom = () => {
    if (!systemRoles?.roleName?.length) {
      setErrors((preState) => ({
        ...preState,
        roleNameError: `Enter Name`,
      }))
    } else {
      if (errors.roleNameError !== `Name already exist`) {
        setErrors((preState) => ({
          ...preState,
          roleNameError: ``,
        }))
      }
    }

    setErrors((preState) => ({
      ...preState,
      formSubmit: true,
    }))
  }

  const updatePayload = () => {
    let data = {
      _franchise: _franchise?._id,
    }

    if (systemRoles?.roleName?.length) {
      data.name = systemRoles?.roleName
    }
    if (systemRoles?.globalRole) {
      data.globalRole = systemRoles?.globalRole
    }

    if (systemRoles?.roleDescription?.length) {
      data.description = systemRoles?.roleDescription
    }

    if (permissions?.length) {
      data.permissions = permissions
    }
    if (!data?.permissions?.includes(`dashboard:read`)) {
      data?.permissions?.push(`dashboard:read`)
    }
    return data
  }

  return (
    <Container>
      <Spinner loading={isLoading || isFetching} />
      <PageHeader>
        Add System Role
        <div className="flex items-center">
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callRolesQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (isLoading || isFetching) && `opacity-30 pointer-events-none`
        } `}
      >
        <>
          <div className="grid grid-cols-4 gap-4">
            <div className="flex-1 relative  pb-4">
              <InputField
                title="Role Name *"
                type="text"
                placeholder="Enter Role Name"
                errorMessage={errors.roleNameError}
                validating={roleNameValidating}
                setValue={(e) => {
                  validateRoleName(e.target.value)
                  setSystemRoles((preState) => ({
                    ...preState,
                    roleName: e.target.value,
                  }))
                }}
              />
            </div>
            <div className="relative mt-6">
              <Checkbox
                title="Global Role "
                name="globalRole"
                value={systemRoles?.globalRole}
                id="globalRole"
                checked={systemRoles?.globalRole}
                setValue={() => {
                  setSystemRoles((preState) => ({
                    ...preState,
                    globalRole: !preState?.globalRole,
                  }))
                }}
              />
            </div>
            <div className="col-span-4 relative w-auto pb-4">
              <TextArea
                title="Description"
                type="text"
                placeholder="Enter Role Description"
                setValue={(e) => {
                  setSystemRoles((preState) => ({
                    ...preState,
                    roleDescription: e.target.value,
                  }))
                }}
              />
            </div>
          </div>
          <div className="overflow-x-auto  rounded-t-md">
            <table className="w-full border-collapse">
              <TableHeader>
                <TableRow>
                  {tableColumns.map((item, idx) => {
                    return (
                      <TableCell key={idx}>
                        <div className=" cursor-pointer select-none text-sm flex">
                          <div>{item.label}</div>
                          <div className="ml-2 px-1 rounded-sm cursor-pointer flex"></div>
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHeader>
              <TableBody>
                {systemRolesData?.systemPermissions?.length ? (
                  systemRolesData?.systemPermissions?.map((role, index) => (
                    <Fragment key={index}>
                      <TableRow className="hover:bg-gray-100 cursor-pointer">
                        <td className="px-4 py-0.5 z-0 text-sm">
                          <p className="truncate w-48 capitalize">
                            {role?.permissionName?.toLowerCase()}
                          </p>
                        </td>
                        <td className="px-4 py-0.5 z-0 capitalize truncate text-sm">
                          {role?.all ? (
                            <>
                              <span
                                onChange={(event) =>
                                  handleStericCheckbox(
                                    event,
                                    index,
                                    role?.permissionName,
                                  )
                                }
                              >
                                <Checkbox
                                  value={
                                    role?.permissionName ===
                                      `admin-master-franchise` ||
                                    role?.permissionName ===
                                      `admin-franchise` ||
                                    role?.permissionName ===
                                      `user-master-franchise`
                                      ? permissions.includes(
                                          role?.permissionName,
                                        )
                                      : permissions.includes(
                                          role?.permissionName + `:*`,
                                        )
                                  }
                                />
                              </span>
                              <span className="col-span-10 py-2 text-xs hidden">
                                {role?.all?.toLowerCase()}
                              </span>
                            </>
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 capitalize truncate text-sm">
                          {role?.create ? (
                            <>
                              <span
                                onChange={(e) =>
                                  permissionHandler(e, index, role?.create)
                                }
                              >
                                <Checkbox
                                  value={
                                    permissions?.includes(role?.create)
                                      ? true
                                      : false
                                  }
                                  disabled={
                                    permissions?.includes(
                                      role?.permissionName + `:*`,
                                    )
                                      ? true
                                      : false
                                  }
                                />
                              </span>

                              <span className="col-span-10 py-2 text-xs hidden">
                                {role?.create?.toLowerCase()}
                              </span>
                            </>
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 capitalize truncate text-sm">
                          {role?.read ? (
                            <>
                              <span
                                onChange={(e) =>
                                  permissionHandler(e, index, role?.read)
                                }
                              >
                                <Checkbox
                                  disabled={
                                    permissions?.includes(
                                      role?.permissionName + `:*`,
                                    )
                                      ? true
                                      : false
                                  }
                                  value={
                                    permissions?.includes(role?.read)
                                      ? true
                                      : false
                                  }
                                />
                              </span>
                              <span className="col-span-10 py-2 text-xs hidden">
                                {role?.read?.toLowerCase()}
                              </span>
                            </>
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 capitalize truncate text-sm">
                          {role?.update ? (
                            <>
                              <span
                                onChange={(e) =>
                                  permissionHandler(e, index, role?.update)
                                }
                              >
                                <Checkbox
                                  disabled={
                                    permissions?.includes(
                                      role?.permissionName + `:*`,
                                    )
                                      ? true
                                      : false
                                  }
                                  value={
                                    permissions?.includes(role?.update)
                                      ? true
                                      : false
                                  }
                                />
                              </span>
                              <span className="col-span-10 py-2 text-xs hidden">
                                {role?.update?.toLowerCase()}
                              </span>
                            </>
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>

                        <td className="px-4 py-0.5 z-0 capitalize truncate text-sm">
                          {role?.delete ? (
                            <>
                              <span
                                onChange={(e) =>
                                  permissionHandler(e, index, role?.delete)
                                }
                              >
                                <Checkbox
                                  disabled={
                                    permissions?.includes(
                                      role?.permissionName + `:*`,
                                    )
                                      ? true
                                      : false //   checkedIndexes[0] !== index
                                  }
                                  value={
                                    permissions?.includes(role?.delete)
                                      ? true
                                      : false
                                  }
                                />
                              </span>
                              <span className="col-span-10 py-2 text-xs hidden">
                                {role?.delete?.toLowerCase()}
                              </span>
                            </>
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 z-0 capitalize truncate text-sm">
                          {role?.list ? (
                            <>
                              <span
                                onChange={(e) =>
                                  permissionHandler(e, index, role?.list)
                                }
                              >
                                <Checkbox
                                  disabled={
                                    permissions?.includes(
                                      role?.permissionName + `:*`,
                                    )
                                      ? true
                                      : false //   checkedIndexes[0] !== index
                                  }
                                  value={
                                    permissions?.includes(role?.list)
                                      ? true
                                      : false
                                  }
                                />
                              </span>
                              <span className="col-span-10 py-2 text-xs hidden">
                                {role?.list?.toLowerCase()}
                              </span>
                            </>
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                      </TableRow>
                    </Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center p-6">
                      <div>No Permission Found</div>
                    </td>
                  </tr>
                )}
              </TableBody>
            </table>
          </div>
          <div className=" p-4">
            <ReactPagination
              offset={50}
              currentPage={currentPage}
              totalPages={systemRolesData?.totalPages}
              totalRecords={systemRolesData?.totalRecords}
              curerntPageRecords={systemRolesData?.currentPageRecords}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
          <div className="pb-4 px-4 text-center">
            <StyledButton onClick={validateFrom}>
              <div className="flex gap-2 text-white mx-8">
                <span>Add Role</span>
                {addRoleLOading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    className="my-auto"
                  />
                )}
              </div>
            </StyledButton>
          </div>
        </>
      </div>
    </Container>
  )
}
