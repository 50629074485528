/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Fragment } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, StyledButton, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { loadFranchises, permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'

const tableColumns = [
  { label: `ID`, value: `_ticketId` },
  { label: `Created By`, value: `agentName` },
  { label: `Title`, value: `title` },
  { label: `Category`, value: `category.categoryName` },
  { label: `Priority`, value: `piriority` },
  { label: `Status`, value: `status` },
  { label: `Created At`, value: `createdAt` },
]

export const AllTickets = () => {
  const history = useHistory()
  const { url } = useRouteMatch()

  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [location, setLocation] = useState(``)
  const [franchise, setFranchise] = useState(``)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)

  //   getting all tickets
  const {
    isLoading,
    data: ticketsData,
    refetch: getAllTickets,
    isFetching,
    error,
  } = useQuery(
    `TicketsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/ticket-center?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }&_franchise=${franchise ? franchise.value : ``}&_location=${
          location && franchise ? location.value : ``
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomSearching(false)
      },
    },
  )
  const { sub: currentUser } = useSelector(({ user }) => user?.user?.profile)
  const loadLocations = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        franchise.value ? franchise.value : _franchise?._id
      }/locations/list/?search=${search && search}&page=${page}&offset=10`,
    )
    if (data) {
      return {
        options: data.locations,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }
  const {
    isLoading: deletingTicket,
    isError: deletionError,
    mutate: deleteTicket,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(`${AMS_BACKEND_API}/api/ticket-center/${id}`)
        .then((res) => {
          NotificationManager.success(`Deleted Successfully`)
          res.data && getAllTickets()
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `You are not authorized to delete ticket`,
              )
            ) {
              NotificationManager.error(
                `You are not allowed to delete  this ticket`,
              )
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (!customSearching) {
      getAllTickets()
    }
  }, [sort, currentPage, isOffset, franchise, location])

  const callTicketsQuery = useDebouncedCallback(() => {
    getAllTickets()
  }, 700)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    {
      deleteTicket(id)
    }
  }

  const badgeColor = {
    LOW: `success`,
    MEDIUM: `warning`,
    HIGH: `danger`,
    OPEN: `success`,
    CLOSE: `danger`,
  }
  return (
    <Container>
      {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={deletingTicket || isLoading || isFetching} />

      <PageHeader>
        <div>Tickets Center</div>
        <div className="flex items-center w-">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end  mt-1">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callTicketsQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>
      <div className="p-2">
        {permissionGranted([`super-admin:*`]) && (
          <CollapsableRef title="Filters">
            <div className="flex">
              <div className="w-1/2 mt-2">
                <ReactSelectField
                  placeholder="Select Franchise"
                  title="Franchise"
                  setValue={(value) => {
                    setFranchise(value)
                    setLocation(``)
                  }}
                  loadOptions={loadFranchises}
                />
              </div>
              {franchise?.value && (
                <div className="w-1/2 mt-2 ml-4">
                  <ReactSelectField
                    placeholder="Select Location"
                    title="Location"
                    setValue={(value) => {
                      setLocation(value)
                    }}
                    value={location}
                    loadOptions={loadLocations}
                    cacheUniqs={franchise}
                  />
                </div>
              )}
            </div>
          </CollapsableRef>
        )}
      </div>
      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `ticket:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>
      <div
        className={`bg-white sm:mb-0 justify-between w-full px-2 ${
          (deletingTicket || isLoading || isFetching) &&
          ` opacity-30 pointer-events-none`
        }  `}
      >
        {error ? (
          <Reload refetch={() => getAllTickets()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse truncate">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort === `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort === `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(permissionGranted([
                      `ticket:delete`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) ||
                      permissionGranted([
                        `ticket:*`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ])) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !ticketsData?.tickets?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Tickets Found</div>
                      </td>
                    </tr>
                  ) : (
                    ticketsData?.tickets
                      ?.filter((ticket) => ticket.isDeleted !== true)
                      ?.map((ticket, index) => (
                        <Fragment key={index}>
                          <TableRow
                            key={index}
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={(e) => {
                              if (
                                permissionGranted([
                                  `ticket:read`,
                                  `corporate-manager:*`,
                                  `manager:*`,
                                  `admin:*`,
                                ])
                              ) {
                                history.push(`${url}/${ticket._id}`)
                              }
                            }}
                          >
                            <td className="px-4 py-0.5 text-sm">
                              {ticket?._ticketId ? (
                                ticket?._ticketId
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-1 truncate">
                              <div className="flex items-center ">
                                {ticket?.agentName ? (
                                  ticket?.agentName
                                ) : (
                                  <Badge>N/A </Badge>
                                )}
                              </div>
                            </td>

                            <td className="px-4 py-0.5 text-sm truncate max-w-xs">
                              {ticket?.title ? (
                                ticket?.title
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 text-sm truncate">
                              {ticket?.category?.categoryName ? (
                                ticket?.category?.categoryName
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 text-sm">
                              <Badge type={badgeColor[ticket?.piriority]}>
                                {ticket?.piriority ? ticket?.piriority : `N/A`}
                              </Badge>
                            </td>

                            <td className="px-4 py-0.5 text-sm">
                              <Badge type={badgeColor[ticket?.status]}>
                                {ticket?.status ? ticket?.status : `N/A`}
                              </Badge>
                            </td>
                            <td className="px-4 py-0.5 w-auto text-sm truncate">
                              {ticket?.createdAt ? (
                                dateFormat(ticket.createdAt)
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>

                            {/* Actions TD */}

                            {permissionGranted([
                              `ticket:delete`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ]) && (
                              <td className="px-10 py-0.5 z-0 text-sm flex float-right text-gray-400">
                                {permissionGranted([
                                  `ticket:delete`,
                                  `corporate-manager:*`,
                                  `manager:*`,
                                  `admin:*`,
                                ]) && (
                                  <span
                                    data-tip={
                                      currentUser === ticket?.agentValue
                                        ? `Delete`
                                        : `Not Allowed`
                                    }
                                    className="tooltip"
                                  >
                                    <FontAwesomeIcon
                                      className={`mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 ${
                                        currentUser === ticket?.agentValue
                                          ? `cursor-pointer`
                                          : `cursor-not-allowed`
                                      }`}
                                      icon={faTrash}
                                      size="1x"
                                      onClick={(e) => {
                                        ticket?.agentValue === currentUser
                                          ? handleDelete(ticket._id, e)
                                          : e &&
                                            e.stopPropagation &&
                                            e.stopPropagation() &&
                                            history.push(`${url}`)
                                      }}
                                    />
                                  </span>
                                )}
                              </td>
                            )}
                          </TableRow>
                        </Fragment>
                      ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={ticketsData?.totalPages}
                totalRecords={ticketsData?.totalRecords}
                curerntPageRecords={ticketsData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
