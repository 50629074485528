/* eslint-disable no-unused-vars */
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Spinner } from 'App/Components/Common/Spinner'
import {
  Container,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { loadBinary } from '../AllLeads'
import { ValidateZip, loadZipCodes } from 'App/Services'
import { InputField } from 'App/Components/Common/InputField'
import { MaskField } from 'App/Components/Common/MaskField'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { loadMakeYears, loadMakes, loadModel } from 'App/Services/quoteForm'

export const AddLead = () => {
  const [lead, setLead] = useState({
    lob: { value: `AUTO`, label: `AUTO` },
    doSmoke: ``,
    firstName: ``,
    lastName: ``,
    dob: ``,
    phone: ``,
    modelYear: ``,
    model: ``,
    make: ``,
  })

  const [zipValidating, setZipValidating] = useState(false)
  const [address, setAddress] = useState({
    unitNo: ``,
    streetNo: ``,
    city: ``,
    zip: ``,
    state: ``,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    streetNoError: ``,
    cityError: ``,
    zipError: ``,
    stateError: ``,
    lobError: ``,
    lastNameError: ``,
    firstNameError: ``,
    dobError: ``,
    modelError: ``,
    makeError: ``,
    modelYearError: ``,
  })

  const loadLobs = () => {
    return {
      options: [
        { value: `LIFE`, label: `LIFE` },
        { value: `AUTO`, label: `AUTO` },
      ],
    }
  }

  const history = useHistory()

  const handleChange = (e, name) => {
    if (name) {
      setLead((prev) => ({
        ...prev,
        [name]: e,
      }))
    } else {
      const { name, value } = e.target
      setLead((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const handleAddressInput = async (e, name) => {
    if (name) {
      setAddress((prevState) => ({
        ...prevState,
        [name]: e,
      }))
      setZipValidating(true)

      const data = await ValidateZip(e?.value)

      if (data) {
        setZipValidating(false)
        const res = data.address
        setAddress((prevState) => ({
          ...prevState,
          city: res.city,
          state: res.state,
        }))
      }
    } else {
      const { name, value } = e.target
      setAddress((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const handleKeyDown = (event) => {
    const key = event.key
    // Prevent alphabetic characters from being entered
    if (/^[a-zA-Z]+$/.test(key)) {
      event.preventDefault()
    }
  }

  const { mutate, isError, isLoading } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/leads/create`, {
        ...payload,
      })
        .then((res) => {
          if (res?.data) {
            NotificationManager.success(`Operation successful`)
            history.push(`/crm/leads`)
          }
        })
        .catch((error) => {
          NotificationManager.error(
            error?.response?.data?.error?.error ?? `Something went wrong!`,
          )
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const preparePayload = () => {
    const data = {
      firstName: lead?.firstName,
      lastName: lead?.lastName,
      dob: lead?.dob,
      lob: lead?.lob?.value,
      phone: lead?.phone,
      verificationStatus: `PENDING`,
      ...(lead?.lob?.value === `LIFE`
        ? {
            doYouSmoke: lead?.doSmoke?.value,
            monthlyBudget: parseFloat(lead?.monthlyBudget),
            targetAmount: parseFloat(lead?.targetAmount),
          }
        : {
            year: lead?.modelYear?.value,
            model: lead?.model?.value,
            make: lead?.make?.value,
          }),

      address: {
        zip: address?.zip?.value,
        city: address?.city,
        state: address?.state,
        street: address?.streetNo,
      },
    }
    if (address?.unitNo) {
      data.address.unitNo = address?.unitNo
    }

    return data
  }

  const ValidateForm = () => {
    if (!lead.dob) {
      handleErrors(`dobError`, `Select Date`)
    } else {
      handleErrors(`dobError`, ``)
    }

    if (!lead.firstName.length) {
      handleErrors(`firstNameError`, `Enter First name`)
    } else {
      handleErrors(`firstNameError`, ``)
    }

    if (!lead.lastName.length) {
      handleErrors(`lastNameError`, `Enter Last name`)
    } else {
      handleErrors(`lastNameError`, ``)
    }

    if (lead?.lob?.value === `LIFE`) {
      if (!lead.doSmoke?.value?.length) {
        handleErrors(`doSmokeError`, `Select option`)
      } else {
        handleErrors(`doSmokeError`, ``)
      }
      if (!lead?.targetAmount?.length) {
        handleErrors(`targetAmountError`, `Enter  Amount`)
      } else if (
        lead?.targetAmount?.length &&
        parseInt(lead?.targetAmount) < 1
      ) {
        handleErrors(`targetAmountError`, `Enter Valid Amount`)
      } else {
        handleErrors(`targetAmountError`, ``)
      }
      if (!lead?.monthlyBudget?.length) {
        handleErrors(`monthlyBudgetError`, `Enter  Amount`)
      } else if (
        lead?.monthlyBudget?.length &&
        parseInt(lead?.monthlyBudget) < 1
      ) {
        handleErrors(`monthlyBudgetError`, `Enter Valid Amount`)
      } else {
        handleErrors(`monthlyBudgetError`, ``)
      }
      // if Lob is changed then neutralize the validation
      handleErrors(`modelYearError`, ``)
      handleErrors(`modelError`, ``)
      handleErrors(`makeError`, ``)
    } else {
      if (!lead.modelYear?.value?.length) {
        handleErrors(`modelYearError`, `Select option`)
      } else {
        handleErrors(`modelYearError`, ``)
      }
      if (!lead.model?.value?.length) {
        handleErrors(`modelError`, `Select option`)
      } else {
        handleErrors(`modelError`, ``)
      }
      if (!lead.make?.value?.length) {
        handleErrors(`makeError`, `Select option`)
      } else {
        handleErrors(`makeError`, ``)
      }
      // if Lob is changed then neutralize the validation

      handleErrors(`monthlyBudgetError`, ``)
      handleErrors(`targetAmountError`, ``)
      handleErrors(`doSmokeError`, ``)
    }

    if (!lead?.phone?.length) {
      handleErrors(`phoneError`, `Enter  Phone No`)
    } else if (lead?.phone?.length && lead?.phone?.length < 12) {
      handleErrors(`phoneError`, `Enter Valid Phone No`)
    } else {
      handleErrors(`phoneError`, ``)
    }

    if (!address.zip?.length && !Object.keys(address.zip).length) {
      handleErrors(`zipError`, `Select Zip Code`)
    } else {
      handleErrors(`zipError`, ``)
    }

    if (!address.streetNo.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }

    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }

    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.firstNameError.length &&
      !errors.lastNameError.length &&
      !errors.phoneError.length &&
      !errors.streetNoError.length &&
      !errors.dobError.length &&
      !errors.doSmokeError.length &&
      !errors.targetAmountError.length &&
      !errors.monthlyBudgetError?.length &&
      !errors.modelYearError?.length &&
      !errors.modelError?.length &&
      !errors.makeError?.length &&
      !errors.zipError.length
    ) {
      mutate(preparePayload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  return (
    <Container>
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <PageHeader padding="true">Add Lead</PageHeader>
      <Spinner loading={isLoading} />
      <div
        className={`px-4 bg-white ${
          isLoading && ` opacity-30 pointer-events-none`
        } `}
      >
        <div className="grid grid-cols-5 gap-4">
          <div className="relative">
            <ReactSelectField
              title="LOB"
              placeholder="Select LOB"
              name="lob"
              value={lead.lob}
              isMulti={false}
              loadOptions={loadLobs}
              setValue={(value) => handleChange(value, `lob`)}
              // disabled
            />
          </div>

          <div className="flex-1 relative">
            <InputField
              type="text"
              title="First Name *"
              name="firstName"
              placeholder="Enter First Name"
              errorMessage={errors.firstNameError}
              value={lead.firstName}
              setValue={(e) => handleChange(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Last Name *"
              name="lastName"
              placeholder="Enter Last Name"
              errorMessage={errors.lastNameError}
              value={lead.lastName}
              setValue={(e) => handleChange(e)}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Phone *"
              name="phone"
              placeholder="Phone"
              errorMessage={errors.phoneError}
              value={lead.phone}
              setValue={(e) => handleChange(e)}
            />
          </div>
          <div className="flex-1 relative">
            <DatePickerField
              title="DOB *"
              name="dob"
              placeholder="Select DOB"
              value={lead.dob}
              errorMessage={errors.dobError}
              setValue={(e) => {
                handleChange(e, `dob`)
              }}
              onKeyDown={handleKeyDown}
              maxDate={new Date(moment().subtract(16, `years`))}
            />
          </div>
          {lead?.lob?.value === `LIFE` ? (
            <>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  title="Target *"
                  name="targetAmount"
                  placeholder="Enter Amount"
                  errorMessage={errors.targetAmountError}
                  value={lead.targetAmount}
                  setValue={(e) => handleChange(e)}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="number"
                  title="Monthly Budget *"
                  name="monthlyBudget"
                  placeholder="Enter Amount"
                  errorMessage={errors.monthlyBudgetError}
                  value={lead.monthlyBudget}
                  setValue={(e) => handleChange(e)}
                />
              </div>
              <div className="relative">
                <ReactSelectField
                  title="Do Smoke *"
                  placeholder="Select option"
                  value={lead.doSmoke}
                  errorMessage={errors?.doSmokeError}
                  isMulti={false}
                  loadOptions={loadBinary}
                  setValue={(e) => handleChange(e, `doSmoke`)}
                />
              </div>
            </>
          ) : (
            <>
              <div className="relative">
                <ReactSelectField
                  title="Model Year *"
                  placeholder="Model Year"
                  isMulti={false}
                  value={lead?.modelYear}
                  errorMessage={errors?.modelYearError}
                  setValue={(value) => {
                    handleChange(value, `modelYear`)
                  }}
                  loadOptions={lead?.lob?.label === `AUTO` && loadMakeYears}
                  isSearchable={true}
                />
              </div>
              <div className="relative">
                <ReactSelectField
                  title="Make *"
                  placeholder="Make"
                  isMulti={false}
                  value={lead?.make}
                  disabled={!lead?.modelYear?.value?.length}
                  errorMessage={errors?.makeError}
                  setValue={(value) => {
                    handleChange(value, `make`)
                  }}
                  loadOptions={lead?.modelYear?.value && loadMakes}
                  additionalFilters={{
                    year: lead?.modelYear?.value,
                  }}
                  cacheUniqs={lead?.modelYear.value}
                  isSearchable={true}
                />
              </div>
              <div className="relative">
                <ReactSelectField
                  title="Model *"
                  placeholder="Model"
                  isMulti={false}
                  value={lead?.model}
                  disabled={!lead?.make?.value?.length}
                  errorMessage={errors?.modelError}
                  setValue={(value) => {
                    handleChange(value, `model`)
                  }}
                  loadOptions={
                    lead?.modelYear.value && lead?.make.label && loadModel
                  }
                  additionalFilters={{
                    year: lead?.modelYear?.value,
                    make: lead?.make?.label,
                  }}
                  cacheUniqs={lead?.make?.label}
                  isSearchable={true}
                />
              </div>
            </>
          )}
        </div>
        {/* Location Address starts from here */}
        <SecondaryHeading>Address</SecondaryHeading>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street *"
              name="streetNo"
              placeholder="Enter Street"
              errorMessage={errors.streetNoError}
              value={address.streetNo}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              placeholder="Select Zip"
              errorMessage={errors.zipError}
              value={address.zip}
              setValue={(e) => {
                handleAddressInput(e, `zip`)
              }}
              loadOptions={loadZipCodes}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              name="unitNo"
              placeholder="Enter Unit No"
              // errorMessage={errors.unitNoError}
              value={address.unitNo}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>

          <div className="flex-1 relative">
            <InputField
              type="text"
              title="City *"
              name="city"
              placeholder="Enter City"
              errorMessage={errors.cityError}
              validating={zipValidating}
              disabled={true}
              value={address.city}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="State *"
              name="state"
              placeholder="Enter State"
              errorMessage={errors.stateError}
              validating={zipValidating}
              disabled={true}
              value={address.state}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="text-center w-96 my-4">
            <SubmitButton onClick={ValidateForm} disabled={zipValidating}>
              <div>Create</div>
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
