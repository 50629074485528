import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { setChatLatestLength, setLatestLength } from 'App/Redux/actions'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useLocation } from 'react-router-dom'

export const useNotification = () => {
  const dispatch = useDispatch()
  const user = useSelector(({ user: { user } }) => user)
  const location = useLocation()

  const { data, mutate } = useMutation(
    `NotificationApi`,
    async (payload) =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/notifications/unread/all?page=1&offset=5&query=${payload}`,
      ).then((res) => {
        payload === `chatNotifications`
          ? dispatch(setChatLatestLength(res.data.data.unreadRecords))
          : dispatch(setLatestLength(res.data.data.unreadRecords))
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  useEffect(() => {
    if (
      user.accessToken &&
      location.pathname !== `/auth/oidc-logout-redirect`
    ) {
      mutate(`systemNotifications`)
      mutate(`chatNotifications`)
    }
  }, [user])

  return data?.length
}
