import {
  faSortDown,
  faSortUp,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from '@windmill/react-ui'
import { outgoingCall, receivedCall, receivedMsg, sentMsg } from 'App/Assets'
import { CardField } from 'App/Components/Common/CardField'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { DateRangeInput } from 'App/Components/Common/DateRange'
import { ReactModal } from 'App/Components/Common/Modal'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReadMoreText } from 'App/Components/Common/ReadMore'
import { Reload } from 'App/Components/Common/Reload'
import { Spinner } from 'App/Components/Common/Spinner'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { loadAgents, loadLocations, timeFormatUTC } from 'App/Services'
import dateFormat from 'App/Services/General/dateFormat'
import { SubmitButton } from 'App/Styled'
import { useRecording } from 'App/hooks/useRecording'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useLocation, useHistory } from 'react-router-dom'
// import audioFile from 'App/Assets/123.mp3'
const selectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: `selection`,
  isSelected: false,
}

const loadStatus = () => {
  const options = {
    options: [
      { value: `UNDELIVERED`, label: `Undelivered` },
      { value: `DELIVERED`, label: `Delivered` },
      { value: `FAILED`, label: `Failed` },
      { value: `NO-ANSWER`, label: `No Answer` },
      { value: `COMPLETED`, label: `Completed` },
      { value: `RECEIVED`, label: `Received` },
      { value: `SENT`, label: `Sent` },
      { value: `CANCELED`, label: `Canceled` },
    ],
    hasMore: false,
  }
  return options
}

const CommunicationLogs = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [communicationLogs, setCommunicationLogs] = useState([])

  const [sort, setSort] = useState(``)
  const [isReset, setIsReset] = useState(false)
  const [searchFilters, setSearchFilters] = useState({
    agent: ``,
    location: ``,
  })

  const [dateRange, setDateRange] = useState([selectionRange])

  const communicationLogsRef = useRef(null)

  const { search } = useLocation()

  const history = useHistory()

  const {
    fetchRecording,
    clearRecodedUrl,
    decodedRecordingUrl: recordingFile,
    isLoading: isLoadingRecordedUrl,
  } = useRecording()
  const searchParams = new URLSearchParams(search)
  const param = searchParams.get(`logId`)

  const {
    isLoading,
    data: communicationLogsData,
    refetch: getCommunicationLogs,
    isFetching,
    error,
  } = useQuery(
    `CommunicationLogs`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/customers-chat/logs?${
          searchFilters?.location?.value
            ? `_location=${searchFilters?.location?.value}&`
            : ``
        }${
          searchFilters?.agent?.value
            ? `_agent=${searchFilters?.agent?.value}&`
            : ``
        }${
          searchFilters?.status?.value
            ? `status=${searchFilters?.status?.value}&`
            : ``
        }${
          dateRange[0]?.isSelected
            ? `startDate=${dateFormat(
                dateRange[0]?.startDate,
              )}&endDate=${dateFormat(dateRange[0]?.endDate)}&`
            : ``
        }sort="${
          sort.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${25}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCommunicationLogs((prev) => [...prev, ...res.logs])
      },
    },
  )
  const {
    isLoading: loadingLog,
    data: logData,
    mutate: getLog,
    error: logError,
  } = useMutation(
    `CommunicationLogs`,
    async (param) =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/customers-chat/logs/${param}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  const handleScroll = () => {
    const element = communicationLogsRef.current
    const bottom =
      element.scrollHeight - element.scrollTop === element.clientHeight
    if (
      element &&
      bottom &&
      communicationLogsData?.currentPage < communicationLogsData?.totalPages
    ) {
      setCurrentPage((prev) => prev + 1)
    }
  }

  function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    // Add leading zeros if needed
    const hoursString = hours < 10 ? `0` + hours : hours.toString()
    const minutesString = minutes < 10 ? `0` + minutes : minutes.toString()
    const secondsString =
      remainingSeconds < 10
        ? `0` + remainingSeconds
        : remainingSeconds.toString()

    return hoursString + `:` + minutesString + `:` + secondsString
  }

  useEffect(() => {
    param && getLog(param)
  }, [param])

  useEffect(() => {
    getCommunicationLogs()
  }, [currentPage, sort])

  const handleResetFilters = () => {
    setSearchFilters({
      agent: ``,
      location: ``,
      status: ``,
    })

    setDateRange([setDateRange])
    setCurrentPage(1)
    setIsReset(true)
  }

  useEffect(() => {
    isReset && getCommunicationLogs()
  }, [isReset])

  return (
    <>
      {param && !loadingLog && !logError && logData && (
        <ReactModal
          title="Log Preview"
          closeModal={() => {
            history.replace({
              pathname: window.location.pathname,
              search: ``,
            })
            clearRecodedUrl()
          }}
          className="flex-col"
        >
          <div
            className="pt-3 flex gap-x-3 px-2 rounded bg-gradient-to-r 
              to-purple-50 from-gray-50 text-gray-600 text-sm capitalize"
          >
            <div className="w-72 truncate px-6">
              <CardField
                title="Agent"
                value={logData?.agent?.label?.toLowerCase()}
                path={`/admin/agents/${logData?.agent?.value}`}
              />
              <CardField
                title="Location"
                value={logData?.location?.label?.toLowerCase()}
              />
              {logData?.callDuration && (
                <CardField
                  title="Duration"
                  value={secondsToHMS(logData?.callDuration)}
                />
              )}
              <CardField title="Date" value={dateFormat(logData?.createdAt)} />
              <CardField
                title="Time"
                value={timeFormatUTC(logData?.createdAt)}
              />
              {logData?.mediaUrl && (
                <CardField
                  title="Attachment"
                  value={logData?.mediaUrl}
                  link={logData?.mediaUrl}
                />
              )}
            </div>
            <div className="w-72 truncate px-6">
              <CardField
                title="Customer"
                value={logData?.customer?.label?.toLowerCase()}
                path={`/customers/${logData?.customer?.value}`}
              />
              <CardField
                title="Type"
                value={logData?.type?.includes(`CALL`) ? `Call` : logData?.type}
              />
              <CardField title="Direction" value={logData?.direction} />
              <CardField title="Status" value={logData?.status} />
            </div>
          </div>
          {logData?.message && (
            <div
              className="px-8 max-w-2xl rounded bg-gradient-to-r 
              to-purple-50 from-gray-50 text-gray-600 text-sm "
            >
              <CardField title="Message" value=" " />
              <div>
                <ReadMoreText text={logData?.message} textLength={140} />
              </div>
            </div>
          )}
          {logData?.recordingUrl?.length > 0 && (
            <div className="w-full  mb-2">
              <span
                className={`mx-8  px-2 py-1 text-sm text-gray-200 rounded shadow ${
                  recordingFile
                    ? `cursor-not-allowed bg-gray-500 hover:bg-gray-600`
                    : `cursor-pointer bg-red-500 hover:bg-red-600`
                } `}
                onClick={() => {
                  if (!recordingFile) {
                    fetchRecording(logData?.recordingUrl)
                    clearRecodedUrl()
                  }
                }}
              >
                Recording
                {isLoadingRecordedUrl && (
                  <FontAwesomeIcon icon={faSpinner} spin />
                )}
              </span>
            </div>
          )}
          {recordingFile && (
            <audio
              controls
              src={recordingFile}
              preload="auto"
              className="m-2"
            />
          )}
        </ReactModal>
      )}

      <Spinner loading={isLoading || isFetching || loadingLog} />
      <div className="pl-3 pr-6 mt-4">
        <Collapsable title="Filters">
          <div className="flex gap-x-4">
            <div className="flex-1 relative">
              <ReactSelectField
                placeholder="Select Location"
                value={searchFilters?.location}
                setValue={(e) =>
                  setSearchFilters((prev) => ({ ...prev, location: e }))
                }
                loadOptions={loadLocations}
                isSearchable={true}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                placeholder="Select Agent"
                value={searchFilters?.agent}
                setValue={(e) =>
                  setSearchFilters((prev) => ({ ...prev, agent: e }))
                }
                loadOptions={loadAgents}
                isSearchable={true}
              />
            </div>

            <div className="flex-1 relative">
              <ReactSelectField
                placeholder="Select Status"
                value={searchFilters?.status}
                setValue={(e) =>
                  setSearchFilters((prev) => ({ ...prev, status: e }))
                }
                loadOptions={loadStatus}
              />
            </div>

            <div className="flex-1 relative">
              <DateRangeInput
                value={dateRange[0]?.isSelected ? dateRange : ``}
                setValue={(e) => setDateRange(e)}
              />
            </div>
          </div>
          <div className="flex gap-4 mt-2">
            <div className="flex-1"></div>
            <div className="flex-1"></div>
            <div className="flex-1"></div>
            <div className="flex-1 flex gap-4">
              <SubmitButton
                onClick={() => {
                  setCommunicationLogs([])
                  setCurrentPage(1)
                  getCommunicationLogs()
                  setIsReset(false)
                }}
              >
                Search
              </SubmitButton>
              <SubmitButton
                onClick={() => {
                  handleResetFilters()
                }}
              >
                Reset
              </SubmitButton>
            </div>
          </div>
        </Collapsable>
      </div>
      <div
        ref={communicationLogsRef}
        onScroll={handleScroll}
        className="mt-4 h-5/6  relative overflow-scroll"
      >
        <div
          className={`mb-1 sm:mb-0 justify-between w-full px-2  ${
            (isLoading || isFetching || loadingLog) &&
            ` opacity-30 pointer-events-none`
          }`}
        >
          {error ? (
            <Reload refetch={() => getCommunicationLogs()} />
          ) : (
            <div>
              <Header
                sort={sort}
                setSort={setSort}
                clearLogsState={() => {
                  setCurrentPage(1)
                  setCommunicationLogs([])
                }}
              />
              <div
                className={`bg-gradient-to-r 
              to-purple-50 from-blue-50
              shadow-md
                
          `}
              >
                {communicationLogs?.length > 0 ? (
                  communicationLogs?.map((log, index) => (
                    <CallLog {...log} index={index} key={log?._id + index} />
                  ))
                ) : isLoading || isFetching || loadingLog ? null : (
                  <p className="text-center">No Logs Found</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

// Header

function Header({ sort, setSort, clearLogsState }) {
  const handleSorting = (active) => {
    //clear logs to insert new one
    clearLogsState()
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  return (
    <header
      className={`flex justify-between items-center sticky top-0 z-40 gap-y-6 text-lg font-medium text-gray-50 px-2 h-10 mb-3 
      bg-gradient-to-r 
      to-blue-500 from-blue-500
                rounded shadow-md
                cursor-pointer
                `}
    >
      <div className="flex-1 truncate">Type</div>
      <div className="flex-1  truncate">
        <span
          onClick={() => handleSorting(`createdAt`)}
          className={`${sort === `createdAt` && `text-red-500`}`}
        >
          Date
        </span>
        <span
          className="ml-2 px-1 rounded-sm cursor-pointer inline-block  relative"
          onClick={() => handleSorting(`-createdAt`)}
        >
          <FontAwesomeIcon
            icon={
              sort.includes(`-`) && sort == `-` + `createdAt`
                ? faSortUp
                : faSortDown
            }
            size="lg"
            className={`${
              sort.includes(`-`) && sort == `-` + `createdAt`
                ? `text-red-500`
                : `-mt-1.5`
            }`}
          />
        </span>
      </div>
      <div className="flex-1">Direction</div>
      <div className="flex-1 truncate">Agent </div>
      <div className="flex-1 truncate">Customer </div>
      <div className="flex-1">Location</div>
      <div className="flex-1">Status</div>
    </header>
  )
}
// specific call log
function CallLog({
  type,
  agentName,
  createdAt,
  direction,
  customer,
  location,
  status,
  _id,
  error,
}) {
  const history = useHistory()
  const handleLogPreview = (_id) => {
    history.replace({
      pathname: window.location.pathname,
      search: `?logId=${_id}`,
    })
  }
  return (
    <div
      className={`flex justify-between items-center gap-y-6 text-sm font-normal text-gray-700 px-2 h-8 mb-1 
      bg-gradient-to-r 
      to-purple-50 from-blue-50
                rounded hover:to-purple-100 hover:from-blue-100
                cursor-pointer
                `}
      onClick={() => handleLogPreview(_id)}
    >
      <div className="flex-1">{type}</div>
      <div className="flex-1">
        {`${dateFormat(createdAt)}
        ${timeFormatUTC(createdAt)}`}
      </div>
      <div className="flex-1 flex ">
        <img
          src={
            direction?.includes(`INBOUND`)
              ? type?.includes(`CALL`)
                ? receivedCall
                : receivedMsg
              : type?.includes(`CALL`)
              ? outgoingCall
              : sentMsg
          }
          alt="icon"
          className="w-6 h-6"
        />

        {direction}
      </div>
      <div className="flex-1">{agentName}</div>
      <div className="flex-1">{customer?.label}</div>
      <div className="flex-1">{location?.label}</div>
      <div
        className={`flex-1 capitalize text-left ${
          error && `tooltip tooltip-left`
        }`}
        data-tip={error}
      >
        <span></span>
        <Badge
          type={
            status?.includes(`FAILED`) || status?.includes(`undelivered`)
              ? `danger`
              : status?.includes(`no-answer`)
              ? `warning`
              : `success`
          }
        >
          {status?.toLowerCase()}
        </Badge>
      </div>
    </div>
  )
}

export default CommunicationLogs
