export const loadChannel = async () => {
  return {
    options: [
      {
        value: `SMS`,
        label: `SMS`,
      },
      {
        value: `MMS`,
        label: `MMS`,
        disable: true,
      },
      {
        value: `MAIL`,
        label: `MAIL`,
      },
      {
        value: `PDF`,
        label: `PDF`,
        disable: true,
      },
      {
        value: `EMAIL`,
        label: `E-MAIL`,
        disable: true,
      },
    ],
    hasMore: false,
  }
}
