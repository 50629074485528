import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from '../style'
import { PolicyReportPdfHeader } from 'App/Components/Common/PolicyReportPdfHeader'

export const Pdf = ({ insured, dateRange = [] }) => {
  return (
    <Document subject="Report" author="Reports">
      <Page style={styles.page} size="A3" wrap>
        <View style={styles.section}>
          <Text style={styles.pageHeading}>Insured Referral Reports</Text>
          <Text style={styles.smallSpace}></Text>
          <PolicyReportPdfHeader dateRange={dateRange} />
          <Text style={styles.smallSpace}></Text>

          <View style={styles.headingRow}>
            <Text style={styles.pageTitles}> State</Text>
            <Text style={styles.pageTitles}> Phone Number</Text>
            <Text style={styles.pageTitles}> Name</Text>
            <Text style={styles.pageTitles}> Email</Text>
          </View>

          {insured?.length > 0 &&
            insured?.map((item, index) => (
              <View style={styles.agent} key={index}>
                <View style={styles.headingRow2}>
                  <Text style={styles.pageTitles2}>{item?.state}</Text>
                  <Text style={styles.pageTitles2}>{item?.phoneNumber}</Text>
                  <Text style={styles.pageTitles2}>{item?.name}</Text>
                  <Text style={styles.pageTitles2}>{item?.email}</Text>
                </View>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  )
}
