import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentCarrier: ``,
  currentGroupCarrier:``,
  activeActivityTab: 1,
  groupCarrierActiveActivityTab:1,
}

const carrierSlice = createSlice({
  name: `carrier`,

  initialState,
  reducers: {
    setCurrentCarrier: (state, { payload }) => {
      state.currentCarrier = payload
    },
    setCurrentGroupCarrier:(state,{payload})=>{
      state.currentGroupCarrier=payload
    },
    setCarrierActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
    setGroupCarrierActivityActiveTab: (state, { payload }) => {
      state.groupCarrierActiveActivityTab = payload
    },
  },
})

export const { setCurrentCarrier, setCarrierActivityActiveTab,setCurrentGroupCarrier,setGroupCarrierActivityActiveTab } =
  carrierSlice.actions

export const carrier = carrierSlice.reducer
