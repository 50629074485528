import React from 'react'
import { Overview } from './Overview'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveCustomerTab } from 'App/Redux/actions'
import { TOne } from 'App/Styled'

const cTabs = [
  {
    tName: `Overview`,
    tNumber: 1,
    permission: [
      `other-customers:read`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
]

export const Tabs = () => {
  const { activeTab } = useSelector(({ customer }) => customer)
  const dispatch = useDispatch()

  return (
    <div className="flex justify-between h-16">
      <div className="flex w-full">
        <div className="flex flex-wrap w-full">
          <div className="w-full">
            <TabsHeaders
              openTab={activeTab}
              tabs={cTabs}
              getActiveTab={(val) => dispatch(setActiveCustomerTab(val))}
            />
            <div className=" flex-auto">
              <div className="tab-content tab-space">
                {/* tab one */}
                <TOne opentab={activeTab}>
                  <Overview />
                </TOne>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
