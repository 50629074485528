// export const loadLocations = async (search, loadedOptions, { page }) => {
// const {data:{data}}  = await AxiosInstance.get(`${
//       AMS_BACKEND_API
//     }/api/franchise/locationTitles/?search=${
//       search && search
//     }&page=${page}&offset=10`)

//   return {
//     options: data.locations,
//     hasMore: data.totalPages - page > 0,
//     additional: {
//       page: page + 1,
//     },
//   }
// }

import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadLocations = async (search, loadedOptions, { page }) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AMS_BACKEND_API}/api/locations/list/?search=${
      search ? search : ``
    }&page=${page}&offset=10`,
  )

  if (data) {
    return {
      options: data.locations,
      hasMore: data.totalPages - page > 0,
      additional: {
        page: page + 1,
      },
    }
  }
}
