export const loadDirections = () => {
  const options = {
    options: [
      { value: `UP`, label: `UP` },
      { value: `DOWN`, label: `DOWN` },
    ],
    hasMore: false,
  }
  return options
}
