import {
  faArrowCircleUp,
  faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Label } from '@windmill/react-ui'
import React from 'react'
import Resizer from 'react-image-file-resizer'
import { useMutation } from 'react-query'
import { NotificationManager } from 'react-notifications'
import { ReactModal } from 'App/Components/Common/Modal'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { fileToBase64 } from 'App/Services'

export const UploadFile = ({ upload, setUpload, folderPath, refetch }) => {
  const [file, setFile] = React.useState(``)
  const [fileName, setFileName] = React.useState(``)
  const handleFile = async (file) => {
    if (file.length) {
      setFileName(file[0].name)
      if (file[0].type.includes(`image`)) {
        const base64 = await resizeFile(file[0])
        setFile(base64)
      } else if (file[0].type.includes(`pdf`)) {
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            setFile(base64)
          }
        })
      } else {
        NotificationManager.error(
          `Can not Upload file Other than PDF and Image`,
        )
      }
    }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        700,
        700,
        `JPEG`,
        80,
        0,
        (uri) => {
          resolve(uri)
        },
        `base64`,
      )
    })

  const { mutate, isLoading } = useMutation(
    async () =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/cloudinary/upload`, {
        path: folderPath,
        file: file,
        fileName: fileName,
      }).then(({ data }) => {
        if (!data.hasError) {
          NotificationManager.success(`File Uploaded Successfully`)
          setFile(``)
          setFileName(``)
          setUpload(false)
          refetch()
        } else {
          setFile(``)
          setFileName(``)
          setUpload(false)
          refetch()
          NotificationManager.error(`File Not Uploaded`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (err) => {
        if (err.message.includes(`409`))
          NotificationManager.error(`File is already exists`)
      },
    },
  )
  return upload ? (
    <>
      <ReactModal
        title="Upload File"
        closeModal={() => setUpload(false)}
        disabled={file}
        loading={isLoading}
        callMutation={mutate}
        icon={faArrowCircleUp}
        toolTip="Upload File"
        className="py-16 px-32"
      >
        <div className="items-center my-0">
          <Label flogo={file}>
            <div className="cursor-pointer text-center">
              <FontAwesomeIcon
                icon={faCloudUploadAlt}
                className={`${file ? `text-red-500` : `text-gray-400`} mt-0.5`}
                size="8x"
              />
              <div className=" text-sm">{file ? fileName : `Upload File`}</div>
            </div>

            <input
              type="file"
              id="upload-file"
              onChange={(e) => {
                handleFile(e.target.files)
              }}
              className="hidden"
            />
          </Label>
        </div>
      </ReactModal>
    </>
  ) : (
    <></>
  )
}
