/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react'
import { ContactForm } from './ContactForm'
import { CoveragesForm } from './CoveragesForm'
import { DriverForm } from './Driver'
import { QuoteDetails } from './QuoteDetails'
import { VehicleForm } from './Vehicle'

import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Container, PageHeader, StyledButton, SubmitButton } from 'App/Styled'
import { ReactModal } from 'App/Components/Common/Modal'
import { AMS_BACKEND_API, AQS_BACKEND_API, AxiosInstance } from 'App/Config'
import { ReactTable } from 'App/Components/Raters/Preferences/ReactTable'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  setCommunicationCredentials,
  setCurrentSavedQuotes,
  setLeadData,
  setPreferedCarriers,
  setPreferences,
  setQuoteForm,
  setQuoteOffers,
  setRatingCarriers,
  setSelectedRatingTemplate,
  setSupportedViolations,
  setVehicleCoverage,
} from 'App/Redux/actions'
import { useMutation, useQuery } from 'react-query'

import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { QuoteCard } from './QuoteCard'
import { Prompt } from 'react-router-dom'
import { QuoteOffers } from './QuoteOffers'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { Loader } from 'App/Components/Common/Loader'
import { CarrierQuestions } from './CarrierQuestions'
import { NotificationManager } from 'react-notifications'
import { permissionGranted } from 'App/Services'

const QuoteFormTabs = [
  {
    name: `Single Screen`,
    number: 1,
  },
  {
    name: `Client Contact Info`,
    number: 2,
  },
  {
    name: `General Info/Coverages`,
    number: 3,
  },
  {
    name: `Quote Details`,
    number: 4,
  },
  {
    name: `Drivers`,
    number: 5,
  },
  {
    name: `Vehicles`,
    number: 6,
  },
]

export const QuoteForm = () => {
  const user = useSelector(({ user: { user } }) => user)

  const [templateModal, setTemplateModal] = useState(true)
  const [tableModal, setTableModal] = useState(false)
  const [switchQuoteForm, setSwitchQuoteForm] = useState(false)
  const [nonOwner, setNonOwner] = useState({ value: `NO`, label: `NO` })

  useEffect(() => {
    if (nonOwner?.value?.includes(`YES`)) {
      QuoteFormTabs.pop()
      setActiveTab((prev) => prev)
      getData(`vehicles`, [])
    } else if (QuoteFormTabs?.length < 6) {
      QuoteFormTabs.push({ name: `Vehicles`, number: 6 })
      setActiveTab((prev) => prev)
    }
  }, [nonOwner])

  const contactRef1 = useRef(null)
  const coveragesRef1 = useRef(null)
  const quoteDetailRef1 = useRef(null)
  const driverRef1 = useRef(null)
  const vehicleRef1 = useRef(null)

  // const state =

  const [data, setData] = useState({})
  const [selectedState, setSelectedState] = useState(
    user?.profile?.operatingState
      ? {
          value: user?.profile?.operatingState,
          label: user?.profile?.operatingState,
        }
      : ``,
  )

  const [isStateChange, setIsStateChange] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [templatesData, setTemplatesData] = useState([])
  const [policyScope, setPolicyScope] = useState([])
  const [vehicleScope, setVehicleScope] = useState([])
  const [preferedCarriersSet, setPreferredCarrierSet] = useState(false)
  const [reRateQuote, setReRateQuote] = useState(false)
  const [stateLoading, setStateLoading] = useState(false)
  const [quoteOfferModel, setQuoteOfferModel] = useState(false)
  const [carrierQuestions, setCarrierQuestions] = useState(``)
  const [policyQuestions, setPolicyQuestions] = useState({})
  const [activeTab, setActiveTab] = useState(1)
  // const navigate = useNavigate()
  const allTimePreferedCarriers = useSelector(
    ({ preferences: { myPreferedCarriers } }) => myPreferedCarriers,
  )
  const { myPreferences } = useSelector(({ preferences }) => preferences)
  // const { ratingCarriers } = useSelector(({ quoteForm }) => quoteForm)
  const history = useHistory()

  const dispatch = useDispatch()

  // setting form data
  const getData = (name, data) => {
    setData((preState) => ({
      ...preState,
      [name]: data,
    }))
  }

  // validating form data
  useEffect(() => {
    // is carrier selected
    const isCarrierSelected = allTimePreferedCarriers?.some((rat) => {
      return rat?.rated === `YES`
    })
    if (data) {
      const keys = Object.keys(data)
      let dataReady = keys.every((key) => data[key] != null)
      if (dataReady && keys?.length == 7 && isCarrierSelected) {
        if (reRateQuote) {
          reRateQuoteFormApi(preparePayload())
        } else {
          quoteFormApi(preparePayload())
        }
        // is template modal to be closed
      } else if (!templateModal && nonOwner?.value?.includes(`NO`)) {
        NotificationManager.error(`Please fill required fields!`)
        !isCarrierSelected &&
          NotificationManager.error(`Please select at least one Carrier!`)
      }
    }
  }, [data])

  const {
    isError: quoteFormError,
    isLoading: quoteFormLoading,
    data: quoteFormData,
    mutate: quoteFormApi,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AQS_BACKEND_API}/quotes/rate`, {
        ...payload,
      })
        .then((res) => {
          if (res.data) {
            setQuoteOfferModel(true)
            return res?.data?.data
          }
        })
        .catch((error) => {
          error.response?.data?.error?.error &&
            NotificationManager.error(error.response?.data?.error?.error)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        if (res) {
          dispatch(setQuoteOffers(res?.quoteOffers))
          dispatch(setCurrentSavedQuotes(res?.savedQuote))
          setSwitchQuoteForm(true)
          setReRateQuote(true)
          dispatch(setLeadData(``))
          dispatch(
            setCommunicationCredentials({
              id: res?.savedQuote?._quoteId,
              phone: res.savedQuote?._customer?.contactInfo?.phone,
              customerId: res.savedQuote?._customer?._id,
              preferredContactMethods:
                res?.savedQuote?._customer?.preferredContactMethods,
            }),
          )
        }
      },
    },
  )

  const {
    isLoading: reRateQuoteFormLoading,
    data: reRateQuoteFormData,
    mutate: reRateQuoteFormApi,
    isError: reRateError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AQS_BACKEND_API}/quotes/rerate`, {
        ...payload,
        quoteId: quoteFormData?.savedQuote?._quoteId,
      })
        .then((res) => {
          if (res.data) {
            setQuoteOfferModel(true)
            return res?.data?.data
          }
        })
        .catch((error) => {
          error?.response?.data?.message &&
            NotificationManager.error(error?.response?.data?.message)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        if (res) {
          dispatch(setQuoteOffers(res?.quoteOffers))
          dispatch(setCurrentSavedQuotes(res?.updatedQuote))
          setSwitchQuoteForm(true)
          setReRateQuote(true)
          dispatch(setLeadData(``))
          dispatch(
            setCommunicationCredentials({
              id: res?.updatedQuote?._quoteId,
              phone: res.updatedQuote?._customer?.contactInfo?.phone,
              customerId: res.updatedQuote?._customer?._id,
              preferredContactMethods:
                res?.updatedQuote?._customer?.preferredContactMethods,
            }),
          )
        }
      },
    },
  )

  // api call for Carrier Questions

  const { mutate: getCarrierQuestions, isLoading: loadingQuestions } =
    useMutation(
      async (payload) =>
        await AxiosInstance.post(`${AQS_BACKEND_API}/quotes/carrierQuestions`, {
          ...payload,
        })
          .then((res) => {
            const carrierQuestions = res.data.data.carrierQuestions
            setCarrierQuestions(carrierQuestions)
            const newCarrierQuestions = {}
            if (
              carrierQuestions?.pol &&
              Object.keys(carrierQuestions?.pol)?.length > 0
            ) {
              for (let cQuestion in carrierQuestions?.pol) {
                newCarrierQuestions[cQuestion] = carrierQuestions.pol[
                  cQuestion
                ].map((item) => ({
                  question: item.question,
                  FieldName: item.name,
                  options: item.options,
                  selectedValue: { value: item.default, label: item.default },
                  mask: item.mask ?? ``,
                  default: item.default ?? ``,
                  scope: item.scope,
                }))
              }
              setPolicyQuestions(newCarrierQuestions)
            } else {
              setPolicyQuestions({})
            }
          })
          .catch((error) => {
            error?.response?.data?.message &&
              NotificationManager.error(error?.response?.data?.message)
          }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
      },
    )

  // apis calling
  // api to get coverages
  const { isLoading, mutate: getAllCoverages } = useMutation(
    `StateCarriersApi`,
    async (state) =>
      await AxiosInstance.get(
        `${AQS_BACKEND_API}/get/fetchables/${
          state ? state : selectedState?.value
        }`,
      )
        .then((res) => {
          setStateLoading(false)
          return res.data.data
        })
        .catch(() => {
          setStateLoading(false)
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (preferedCarriersSet) {
          handleModifyCarriersField(res.supportedCarriers)
        }
        if (!selectedTemplate) {
          const policyScopped = res?.supportedCoverages?.policyScoped.map(
            (policy) => ({
              code: policy.code,
              deductibleOption: () => ({
                options: policy.deductible?.length
                  ? policy.deductible.map((deductible) => ({
                      value: deductible.key,
                      label: deductible.name,
                    }))
                  : [],
                hasMore: false,
              }),
              _deductibleOptions:
                policy?.deductible?.length &&
                policy?.deductible.map((deductible) => ({
                  value: deductible.key,
                  label: deductible.name,
                })),
              deductible: { value: `NONE`, label: `NONE` },
              deductibleError: ``,
              emptyDeductibles: policy.deductible?.length ? false : true,
              free: policy.free,
              name: policy.name,
              required: policy.required,
              limit:
                policy.limits?.length > 0
                  ? policy.code === `BI`
                    ? {
                        value: policy.limits[1].key,
                        label: policy.limits[1].name,
                      }
                    : policy.code === `PD`
                    ? {
                        value: policy.limits[1].key,
                        label: policy.limits[1].name,
                      }
                    : policy.code === `PIP`
                    ? {
                        value: policy.limits[1].key,
                        label: policy.limits[1].name,
                      }
                    : policy.code === `UMPD`
                    ? {
                        value: policy.limits[1].key,
                        label: policy.limits[1].name,
                      }
                    : // ? selectedState?.value === `CA`
                      //   ? { value: `5`, label: `5` }
                      //   : selectedState?.value === `FL`
                      //   ? { value: `10`, label: `10` }
                      //   : { value: `25`, label: `25` }
                      { value: `NONE`, label: `NONE` }
                  : { value: `NONE`, label: `NONE` },
              limitError: ``,
              limitsOptions: () => ({
                options: policy.limits?.length
                  ? policy.limits.map((limit) => ({
                      value: limit.key,
                      label: limit.name,
                    }))
                  : [],
                hasMore: false,
              }),
              _limitOptions:
                policy?.limits?.length &&
                policy?.limits.map((limit) => ({
                  value: limit.key,
                  label: limit.name,
                })),
              emptyLimits: policy.limits?.length ? false : true,
            }),
          )
          const vehicleScopped = res?.supportedCoverages?.vehicleScoped.map(
            (vehicle) => ({
              code: vehicle.code,
              deductibleOption: () => ({
                options: vehicle.deductible?.length
                  ? vehicle.deductible.map((deductible) => ({
                      value: deductible.key,
                      label: deductible.name,
                    }))
                  : [],
                hasMore: false,
              }),
              _deductibleOptions:
                vehicle?.deductible?.length &&
                vehicle?.deductible.map((deductible) => ({
                  value: deductible.key,
                  label: deductible.name,
                })),
              deductible: { value: `NONE`, label: `NONE` },
              deductibleError: ``,
              emptyDeductibles: vehicle.deductible?.length ? false : true,
              free: vehicle.free,
              name: vehicle.name,
              required: vehicle.required,
              limit: { value: `NONE`, label: `NONE` },
              limitError: ``,
              limitsOptions: () => ({
                options: vehicle.limits?.length
                  ? vehicle.limits.map((limit) => ({
                      value: limit.key,
                      label: limit.name,
                    }))
                  : [],
                hasMore: false,
              }),
              _limitOptions:
                vehicle?.limits?.length &&
                vehicle?.limits.map((limit) => ({
                  value: limit.key,
                  label: limit.name,
                })),
              emptyLimits: vehicle.limits?.length ? false : true,
            }),
          )
          setPolicyScope(policyScopped)
          // setVehicleScope(vehicleScopped)
          dispatch(setVehicleCoverage(vehicleScopped))
        }
        setPreferredCarrierSet(true)
        dispatch(setSupportedViolations(res?.supportedViolations))
      },
    },
  )

  // api for getting preferences
  const {
    data: preferenceData,
    isLoading: isLoadingPref,
    refetch: refetchPref,
    isFetching: isFetchingPref,
  } = useQuery(
    `PreferencesApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/quotes/prefs`).then(
        (res) => {
          return res?.data?.data?.ratingPrefs
        },
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (res?.state) {
          setSelectedState({
            value: res?.state,
            label: res?.state,
          })
          getAllCoverages(res?.state)
          dispatch(setPreferences(res))
          dispatch(setPreferedCarriers(res.carriers))
        }
      },
    },
  )
  // preparing payload for api
  const preparePayload = () => {
    const {
      customer,
      customerAddress,
      coverages,
      policyCoverages,
      drivers,
      quoteDetail,
      vehicles,
    } = data

    let payload = {
      title: `Form 1`,
      ratingPrefs: {
        carriers:
          //  isStateChange
          //   ? ratingCarriers?.map((rat) => {
          //       return {
          //         _carrier: rat.id,
          //         rated: rat?.rated,
          //         ratingType: rat?.ratingType.toUpperCase(),
          //         orderCredit: rat?.orderCredit,
          //         paymentFilter: rat?.paymentFilter,
          //       }
          //     })
          //   :
          allTimePreferedCarriers?.map((rat) => {
            return {
              _carrier: rat.id,
              rated: rat?.rated,
              ratingType: rat?.ratingType.toUpperCase(),
              orderCredit: rat?.orderCredit,
              paymentFilter: rat?.paymentFilter,
            }
          }),
      },
      policyData: {
        coverages: [],
        customer: {
          contactInfo: {},
          address: {},
          priorAddress: {},
        },
      },
      quoteData: {},
      drivers: drivers,
      cars: nonOwner?.value?.includes(`NO`) ? vehicles : [],
      quoteType: `PERSONAL AUTO`,
    }
    if (coverages?.agencyFee.toString()) {
      payload.ratingPrefs.agencyFee = coverages?.agencyFee
    }
    if (preferenceData?.allowCreditScore?.length) {
      payload.ratingPrefs.allowCreditScore = preferenceData?.allowCreditScore
    }
    if (preferenceData?.estimateTerm) {
      payload.ratingPrefs.estimateTerm = preferenceData?.estimateTerm
    }
    if (preferenceData?.bumpLimits) {
      payload.ratingPrefs.bumpLimits = preferenceData?.bumpLimits
    }
    if (selectedState?.value?.length) {
      payload.ratingPrefs.state = selectedState?.value
    }

    // preparing policy data
    if (coverages?.effectiveDate) {
      payload.policyData.effectiveDate = coverages.effectiveDate
    }
    if (coverages?.policyTerm?.value?.length) {
      payload.policyData.policyTerm = coverages.policyTerm?.value
    }

    if (coverages?.exclusion?.value?.length) {
      payload.policyData.exclusions = coverages.exclusion?.value
    }

    if (coverages?.nonOwner.value?.length) {
      payload.policyData.nonOwner = nonOwner?.value
    }

    if (coverages?.broadForm.value?.length) {
      payload.policyData.broadform = coverages.broadForm.value
    }

    // customer starts from here
    if (customer?.firstName?.current?.value?.length) {
      payload.policyData.customer.firstName =
        customer?.firstName?.current?.value
    }

    if (customer?.middleName?.current?.value?.length) {
      payload.policyData.customer.middleName =
        customer.middleName?.current?.value
    }

    if (customer?.lastName?.current?.value?.length) {
      payload.policyData.customer.lastName = customer.lastName?.current?.value
    }
    if (customer?.residenceStatus?.value?.length) {
      payload.policyData.customer.residenceStatus =
        customer.residenceStatus?.value
    }
    if (customer?.residenceType?.value?.length) {
      payload.policyData.customer.residenceType = customer.residenceType?.value
    }
    // residence location

    if (customerAddress?.street?.current?.value?.length) {
      payload.policyData.customer.address.street =
        customerAddress.street?.current?.value
    }

    if (customerAddress?.city?.length) {
      payload.policyData.customer.address.city = customerAddress.city
    }

    if (customerAddress?.state?.length) {
      payload.policyData.customer.address.state = customerAddress.state
    }

    if (customerAddress?.zip?.value?.length) {
      payload.policyData.customer.address.zip = customerAddress.zip.value
    }
    // residence location ends here

    if (customer?.monthsAtCurrentLocation) {
      payload.policyData.customer.monthsAtCurrentAddress =
        customer.monthsAtCurrentLocation
    }

    if (customer?.isPriorAddress?.value?.length) {
      payload.policyData.customer.sameCurrentPriorAddress =
        customer.isPriorAddress.value
    }

    if (customer?.isPriorAddress?.value == `NO`) {
      payload.policyData.customer.priorAddress = {
        street: customerAddress?.street?.current?.value,
        city: customerAddress?.city,
        state: customerAddress?.state,
        zip: customerAddress?.zip.value,
      }
    } else {
      payload.policyData.customer.priorAddress = {
        street: customer?.priorStreetAddress?.current?.value,
        city: customer?.priorAddressCity,
        state: customer?.priorAddressState,
        zip: customer?.priorAddressZip.value,
      }
    }

    if (customer?.cellPhone?.length) {
      payload.policyData.customer.contactInfo.phone = customer?.cellPhone
    }

    if (customer?.workPhone?.length) {
      payload.policyData.customer.contactInfo.workPhone = customer?.workPhone
    }

    // if (customer?.homePhone?.length) {
    //   payload.policyData.customer.contactInfo.homePhone = customer?.homePhone
    // }

    if (customer?.workPhoneExt?.length) {
      payload.policyData.customer.contactInfo.workPhoneExt =
        customer?.workPhoneExt
    }

    if (customer?.fax?.length) {
      payload.policyData.customer.contactInfo.fax = customer?.fax
    }

    if (customer?.email?.current?.value?.length) {
      payload.policyData.customer.contactInfo.email =
        customer?.email?.current?.value
    }
    //  Prepared Vehicle Coverages
    if (policyCoverages.length) {
      const prepareCoverages = policyCoverages.map((coverage) => {
        return {
          deductibleOptions:
            coverage?._deductibleOptions === 0
              ? []
              : coverage?._deductibleOptions,
          limitsOptions:
            coverage?._limitOptions === 0 ? [] : coverage?._limitOptions,
          rated:
            (selectedState?.value === `CO` || selectedState?.value === `FL`) &&
            (coverage.code === `PIP` || coverage.limit.value == `NONE`)
              ? `NO`
              : (selectedState?.value === `TX` ||
                  selectedState?.value === `CA`) &&
                coverage.code === `UMPD`
              ? `YES`
              : (selectedState?.value === `CO` ||
                  selectedState?.value === `CA`) &&
                (coverage.code === `UNDUM` || coverage.limit.value == `NONE`)
              ? `NO`
              : selectedState?.value === `UT` &&
                (coverage.code === `UMPD` || coverage.limit.value == `NONE`)
              ? `NO`
              : selectedState?.value === `UT` && coverage.code === `PD`
              ? `YES`
              : coverage.deductible.value !== `NONE` ||
                coverage.limit.value !== `NONE`
              ? `YES`
              : `NO`,
          name: coverage.code,
          code: coverage.code,
          limits:
            selectedState?.value === `CA` && coverage.code === `UMPD`
              ? `3500`
              : // : selectedState?.value === `UT` && coverage.code === `UMPD`
                // ? `250`
                // : selectedState?.value === `TX` && coverage.code === `UMPD`
                // ? `25`
                // : selectedState?.value === `CA` && coverage.code === `PD`
                // ? `5`
                // : selectedState?.value === `FL` && coverage.code === `PD`
                // ? `10`
                coverage.limit?.value,
          deductible: coverage.deductible?.value,
        }
      })
      payload.policyData.coverages = prepareCoverages
    }
    payload.policyData.questions =
      quoteDetail?.questions === `` ? {} : quoteDetail?.questions
    // prepare quote details
    if (quoteDetail?.acquisitionBy?.value?.length) {
      payload.quoteData.acquisitionBy = quoteDetail.acquisitionBy?.value
    }

    if (quoteDetail?.contactMethod?.value?.length) {
      payload.quoteData.contactMethod = quoteDetail.contactMethod?.value
    }

    if (quoteDetail?.leadSource?.value?.length) {
      payload.quoteData.leadSource = quoteDetail.leadSource?.value
    }

    if (quoteDetail?.nativeLanguage?.value?.length) {
      payload.quoteData.nativeLanguage = quoteDetail.nativeLanguage?.value
    }

    if (quoteDetail?.paperlessDiscount?.value?.length) {
      payload.quoteData.paperlessDiscount = quoteDetail.paperlessDiscount?.value
    }

    if (quoteDetail?.preferredContact?.length) {
      payload.quoteData.preferredContactMethods =
        quoteDetail.preferredContact.map((item) => item.value)
    }

    if (quoteDetail?.quoteDescription?.value?.length) {
      payload.quoteData.quoteDescription = quoteDetail.quoteDescription?.value
    }

    dispatch(setQuoteForm(payload))
    return payload
  }
  useEffect(() => {
    window.addEventListener(`beforeunload`, alertUser)
    return () => {
      window.removeEventListener(`beforeunload`, alertUser)
      dispatch(setLeadData(``))
      dispatch(setSelectedRatingTemplate(``))
    }
  }, [])
  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ``
  }

  // manupilating carriers getting from api
  const handleModifyCarriersField = (carrierFields) => {
    const modifiedCarriers = carrierFields.map((carrier) => {
      return {
        ...carrier,
        rated: `YES`,
        orderCredit: `YES`,
        ratingType: carrier?.type,
      }
    })

    if (modifiedCarriers.length) {
      dispatch(setRatingCarriers(modifiedCarriers))
    }
  }

  // refetch coverages when state changes
  useEffect(() => {
    if (selectedState?.value?.length && preferedCarriersSet) {
      setStateLoading(true)
      getAllCoverages()
      setIsStateChange(true)
    }
    if (selectedState?.value?.length) {
      getCarrierQuestions({ state: selectedState?.value ?? `CA`, type: `ALL` })
    }
  }, [selectedState.value])

  //dispatch selected rating template coverages

  const { mutate: getRatingTemplate, isLoading: ratingLoading } = useMutation(
    `SelectedRatingTemplateApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/rating-templates/${selectedTemplate?.value}`,
      ).then((res) => {
        dispatch(setSelectedRatingTemplate(res?.data?.data))
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setSelectedState({
          value: res?.rateState,
          label: res?.rateState,
        })
      },
    },
  )

  useEffect(() => {
    if (selectedTemplate) getRatingTemplate()
  }, [selectedTemplate])

  // setting template selected
  // useEffect(() => {
  //   dispatch(
  //     setSelectedRatingTemplate(
  //       templatesData?.find(
  //         (template) => template?._id === selectedTemplate?.value,
  //       ),
  //     ),
  //   )
  //   templatesData.forEach((template) => {
  //     if (template?._id === selectedTemplate?.value) {
  //       setSelectedState({
  //         value: template?.rateState,
  //         label: template?.rateState,
  //       })
  //     }
  //   })
  // }, [selectedTemplate])

  // React select options

  // dropdown for ratingTemplate Select
  const loadAllRatingTemplates = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/rating-templates/list?search=${search}&state= ""&sort=""&page=${page}&offset=${10}`,
    )
    if (data?.ratingTemplates) {
      setTemplatesData(data?.ratingTemplates)
      const formattedModel = data?.ratingTemplates.map((template) => {
        return {
          label: template?.label,
          value: template?.value,
        }
      })
      return {
        options: formattedModel,
        hasMore: data?.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  // options for state
  const loadStates = () => {
    const options = {
      options: [
        { value: `CA`, label: `CA` },
        { value: `FL`, label: `FL` },
        { value: `TX`, label: `TXS` },
      ],
      hasMore: false,
    }
    return options
  }
  return (
    <Container>
      {quoteFormData && (
        <Prompt message={() => `Are you sure you want to leave this page?`} />
      )}
      {tableModal && (
        <ReactModal
          closeButton={false}
          closeModal={() => {
            getCarrierQuestions({
              state: selectedState?.value ?? `CA`,
              type: `ALL`,
              preferredCarriers: allTimePreferedCarriers,
            })
            setTableModal(!tableModal)
          }}
          title="Carriers"
        >
          <div className="px-6 py-2 h-80  overflow-auto w-full hide-scroller hide-scroller-firefox ">
            <ReactTable
              carriersdata={allTimePreferedCarriers}
              isStateChange={isStateChange}
              isLoading={isLoadingPref || isLoading}
            />
          </div>
        </ReactModal>
      )}

      {!isLoadingPref && !allTimePreferedCarriers?.length ? (
        <ReactModal
          title="Set Your Pref"
          closeButton={false}
          closeModal={() => {
            history.goBack()
          }}
          className={`flex-col z-0`}
          width="1/4"
        >
          {isLoadingPref ? (
            <Loader />
          ) : (
            <>
              <div className="w-auto mx-auto h-80 py-4">No Preference set</div>
              <div className="w-auto  py-1 mx-auto z-0">
                <SubmitButton
                  onClick={() => history.push(`/raters/preferences`)}
                >
                  Set Preference
                </SubmitButton>
              </div>
            </>
          )}
        </ReactModal>
      ) : templateModal &&
        permissionGranted([
          `rating-templates:read`,
          `rating-templates:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) ? (
        <ReactModal
          title="Chose Your Template"
          closeButton={false}
          closeModal={() => {
            history.goBack()
          }}
          className={`flex-col z-0`}
          width="1/4"
        >
          <>
            <div className="flex flex-col h-80 overflow-hidden w-full hide-scroller hide-scroller-firefox">
              <div className="w-full">
                <div className="p-4 z-50">
                  <ReactSelectField
                    placeholder="Rating Templates"
                    value={selectedTemplate}
                    setValue={(value) => {
                      setTemplateModal(!templateModal)
                      setSelectedTemplate(value)
                    }}
                    loadOptions={loadAllRatingTemplates}
                    isSearchable={true}
                    title="Select Rating Templates"
                  />
                </div>
                {!preferenceData && <Loader />}
              </div>
            </div>
            <div className="w-auto py-1 mx-auto z-0">
              <SubmitButton
                onClick={() => setTemplateModal(!templateModal)}
                disabled={!preferenceData}
              >
                Continue Without Selecting
              </SubmitButton>
            </div>
          </>
          {/* ) : isLoadingPref ? (
            <Loader />
          ) : (
            <>
              <div className="w-auto mx-auto h-80 py-4">No Preference set</div>
              <div className="w-auto  py-1 mx-auto z-0">
                <SubmitButton
                  onClick={() => history.push(`/raters/preferences`)}
                >
                  Set Preference
                </SubmitButton>
              </div>
            </>
          )} */}
        </ReactModal>
      ) : (
        <>
          {switchQuoteForm && (
            <QuoteOffers
              goBack={() => setSwitchQuoteForm(false)}
              carriersdata={allTimePreferedCarriers}
              isStateChange={isStateChange}
            />
          )}

          <div className={switchQuoteForm ? `hidden` : ``}>
            {reRateError && (
              <ReactNotification
                action="error"
                message="Something went wrong"
              />
            )}
            <Spinner
              loading={
                quoteFormLoading ||
                stateLoading ||
                reRateQuoteFormLoading ||
                loadingQuestions ||
                isLoadingPref ||
                ratingLoading
              }
            />
            <PageHeader>
              <div className="px-2">Quote Form</div>
              <div className="text-black py-1 pr-2 justify-items-end">
                <div className="relative">
                  <ReactSelectField
                    placeholder="Select State"
                    isMulti={false}
                    setValue={(value) => setSelectedState(value)}
                    loadOptions={loadStates}
                    isSearchable={true}
                    disabled={true}
                    value={selectedState}
                  />
                </div>
              </div>
            </PageHeader>
            <div
              className={`bg-white ${
                (quoteFormLoading ||
                  stateLoading ||
                  reRateQuoteFormLoading ||
                  ratingLoading ||
                  loadingQuestions) &&
                `opacity-30 pointer-events-none`
              } `}
            >
              <div className="flex relative items-center py-2 px-4">
                <div className=" justify-self-start">
                  <StyledButton
                    onClick={() => {
                      // refetchPref()
                      setTableModal(!tableModal)
                    }}
                  >
                    Carriers
                  </StyledButton>
                </div>
                <div className="fixed top-18 right-6 z-20">
                  <StyledButton
                    onClick={() => {
                      contactRef1.current.validateFrom()
                      coveragesRef1.current.validateForm()
                      quoteDetailRef1.current.validateForm()
                      driverRef1.current.validateForm()
                      if (nonOwner?.value?.includes(`NO`))
                        vehicleRef1.current.validateForm()
                    }}
                  >
                    Submit
                  </StyledButton>
                </div>
              </div>

              <div className="h-78vh overflow-auto hide-scroller hide-scroller-firefox">
                {/* tabs will go here */}
                <div className="px-4 mb-2">
                  <Tabs2
                    openTab={activeTab}
                    tabs={QuoteFormTabs}
                    getActiveTab={(val) => setActiveTab(val)}
                  />
                  <>
                    <div
                      className={
                        activeTab == 1 || activeTab == 2
                          ? `grid grid-cols-2 gap-2 h mt-2`
                          : `hidden`
                      }
                    >
                      <div>
                        <ContactForm
                          ref={contactRef1}
                          getData={getData}
                          selectedState={selectedState?.value}
                          reRateQuote={reRateQuote}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        activeTab == 1 || activeTab == 3
                          ? `grid grid-cols-1 gap-2 h mt-2`
                          : `hidden`
                      }
                    >
                      <div>
                        <CoveragesForm
                          ref={coveragesRef1}
                          getData={getData}
                          policyScope={policyScope}
                          preference={preferenceData}
                          selectedState={selectedState?.value}
                          isTemplate={selectedTemplate?.value}
                          nonOwner={nonOwner}
                          setNonOwner={setNonOwner}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        activeTab == 1 || activeTab == 4 ? `mt-2` : `hidden`
                      }
                    >
                      <QuoteDetails
                        ref={quoteDetailRef1}
                        getData={getData}
                        carrierQuestion={policyQuestions}
                      />
                    </div>
                  </>
                  <div
                    className={
                      activeTab == 1 || activeTab == 5 ? `mt-2` : `hidden`
                    }
                  >
                    <DriverForm
                      ref={driverRef1}
                      getData={getData}
                      selectedState={selectedState?.value}
                      carrierQuestion={carrierQuestions?.drv}
                    />
                  </div>
                  {nonOwner?.value?.includes(`NO`) && (
                    <div
                      className={
                        activeTab == 1 || activeTab == 6 ? `my-2` : `hidden`
                      }
                    >
                      <VehicleForm
                        ref={vehicleRef1}
                        getData={getData}
                        // vehicleScope={vehicleScope}
                        selectedState={selectedState}
                        carrierQuestion={carrierQuestions?.car}
                      />
                    </div>
                  )}
                  {/* <div className={activeTab == 7 ? `my-2` : `hidden`}>
                    <CarrierQuestions />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  )
}
