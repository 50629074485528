import React, { createContext, useState, useEffect } from 'react'
import { Container } from 'App/Styled'
import { AllAgents } from './Agents'
import { Conversation } from './Conversation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelopeOpenText,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { newSocket } from 'App/HOC/MainLayout'
import { useSelector } from 'react-redux'
import { setChatRoomId } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'

export const ChatRoom = createContext()

export const Messenger = () => {
  const { chatRoom: _chatRoom } = useSelector(({ messenger }) => messenger)
  const [chatRoom, setChatRoom] = useState(``)
  const [agentDetail, setAgentDetail] = useState(``)
  const [loading, setLoading] = useState(true)
  const [isRedirect, setIsRedirect] = useState(false)
  const [conversation, setConversation] = useState(``)
  const [conversationLoader, setConversationLoader] = useState(false)
  const [notification, setNotification] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (newSocket) {
        newSocket?.emit(`FETCH_ROOM`)
      }
    }, 2000)
    return () => {
      clearTimeout(timeOut)
      newSocket?.emit(`LEAVE_MESSENGER`)
      dispatch(setChatRoomId(``))
    }
  }, [])

  useEffect(() => {
    newSocket?.on(`ROOM_NEW_MSG_NOTIFICATION`, (roomId) => {
      // give room id where new msg is received
      if (roomId) {
        newSocket?.emit(`FETCH_ROOM`)
        setNotification((prev) => [...prev, roomId])
      }
    })
    return () => newSocket?.off(`ROOM_NEW_MSG_NOTIFICATION`)
  })

  useEffect(() => {
    if (_chatRoom) {
      setIsRedirect(true)
      setChatRoom(_chatRoom)
    }
  }, [_chatRoom])

  const initialState = {
    chatRoom,
    setChatRoom,
    agentDetail,
    setAgentDetail,
    loading,
    setLoading,
    conversation,
    conversationLoader,
    setConversation,
    setConversationLoader,
    setNotification,
    notification, isRedirect, setIsRedirect
  }
  useEffect(() => {
    let unique = notification.filter((item, i, ar) => ar.indexOf(item) === i)
    setNotification(unique)
  }, [notification.length])
  return (
    <>
      <div className="flex">
        <ChatRoom.Provider value={initialState}>
          <div className="w-1/4  relative">
            <Container>
              <AllAgents />
            </Container>
          </div>
          <div className="w-3/4">
            {chatRoom ? (
              <Container nomargin="true">
                <Conversation />
              </Container>
            ) : (
              <div className="flex flex-col justify-center items-center h-88vh">
                <FontAwesomeIcon
                  icon={loading ? faSpinner : faEnvelopeOpenText}
                  size="3x"
                  spin={loading}
                  className={`${loading ? `text-red-400` : `text-blue-500`
                    } cursor-pointer`}
                  title="Select Chat Thread"
                />
                <p className="text-gray-600 font-medium text-sm pt-4">
                  Send and Receive Messages within your Location
                </p>
              </div>
            )}
          </div>
        </ChatRoom.Provider>
      </div>
    </>
  )
}
