import React, { useEffect, useState } from 'react'
import { Container, FormTab, PageHeader } from 'App/Styled'
import { QuoteCard } from '../QuoteCard/index'
import { useSelector } from 'react-redux'
import { ReactTable } from '../../Preferences/ReactTable/index'
import { ReactSelectField } from '../../../Common/ReactSelect/index'
import { SubmitButton } from 'App/Styled'
import { useDebouncedCallback } from 'use-debounce'
import { useMutation } from 'react-query'
import { useQuery } from 'react-query'
import { AQS_BACKEND_API } from 'App/Config'
import { AxiosInstance } from 'App/Config'
import { useDispatch } from 'react-redux'
import cloneDeep from 'clone-deep'
import { Spinner } from 'App/Components/Common/Spinner'

import {
  setCommunicationCredentials,
  setCurrentSavedQuotes,
  setQuoteOffers,
} from 'App/Redux/actions'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { NotificationManager } from 'react-notifications'
import { error, warning } from 'App/Assets'
import { Communications } from 'App/Components/Communications'
import { loadPolicyTerm } from 'App/Services/quoteForm'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'

const policyTermLabels = {
  // 12: `ANNUAL`,
  1: `Monthly`,
  12: `Annual`,
  3: `Quarterly`,
  6: `Semi-Annual`,
  2: `Two Month`,
}

export const QuoteOffers = ({ goBack, carriersdata, isStateChange }) => {
  const aqsQuote = useSelector(({ aqsQuote }) => aqsQuote)
  const { vehiclesData, _drivers } = useSelector(({ quoteForm }) => quoteForm)
  const { selectedRatingTemplate } = useSelector(
    ({ ratingTemplate }) => ratingTemplate,
  )

  const insuredDriver = _drivers?.filter(
    (item) => item?.relation === `INSURED`,
  )[0]

  const [policyDataCoverages, setPolicyCoverages] = useState([])
  const [changeInsured, setChangeInsured] = useState({
    value: `${insuredDriver?.firstName} ${insuredDriver?.lastName}`,
    label: `${insuredDriver?.firstName} ${insuredDriver?.lastName}`,
  })

  const policyTerm = aqsQuote?.quoteForm?.policyData?.policyTerm
  const policyTermObj = {
    value: policyTerm,
    label: policyTermLabels[policyTerm],
  }
  const [vehicles, setVehicles] = useState(vehiclesData)
  const [_policyTerm, setPolicyTerm] = useState(policyTermObj)
  const [vTabs] = useState(
    vehiclesData.map((_, index) => ({
      tName: `Vehicle ${index + 1}`,
      tNumber: index + 1,
      permission: [`quotes:create`],
    })),
  )

  const [activeVehicleTab, setActiveVehicleTab] = useState(1)
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({
    formSubmit: false,
  })
  const { isLoading } = useQuery(
    `StateCarriersApi`,
    async () =>
      await AxiosInstance.get(
        `${AQS_BACKEND_API}/get/fetchables/${aqsQuote?.quoteForm?.policyData?.customer?.address?.state}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (!selectedRatingTemplate?.length) {
          const policyScopped = res?.supportedCoverages?.policyScoped.map(
            (policy, index) => ({
              code: policy.code,
              deductibleOption: () => ({
                options: policy.deductible.length
                  ? policy.deductible.map((deductible) => ({
                      value: deductible.key,
                      label: deductible.name,
                    }))
                  : [],
                hasMore: false,
              }),
              deductible: {
                value:
                  aqsQuote?.quoteForm?.policyData?.coverages[index]?.deductible,
                label:
                  aqsQuote?.quoteForm?.policyData?.coverages[index]?.deductible,
              },
              deductibleError: ``,
              emptyDeductibles: policy.deductible.length ? false : true,
              free: policy.free,
              name: policy.name,
              required: policy?.required,
              limit: {
                value:
                  aqsQuote?.quoteForm?.policyData?.coverages[index]?.limits,
                label:
                  aqsQuote?.quoteForm?.policyData?.coverages[index]?.limits,
              },
              limitError: ``,
              limitsOptions: () => ({
                options: policy.limits.length
                  ? policy.limits.map((limit) => ({
                      value: limit.key,
                      label: limit.name,
                    }))
                  : [],
                hasMore: false,
              }),
              emptyLimits: policy?.limits?.length ? false : true,
            }),
          )
          // const vehicleScopped = res?.supportedCoverages?.vehicleScoped.map(
          //   (vehicle) => ({
          //     code: vehicle.code,
          //     deductibleOption: () => ({
          //       options: vehicle.deductible.length
          //         ? vehicle.deductible.map((deductible) => ({
          //             value: deductible.key,
          //             label: deductible.name,
          //           }))
          //         : [],
          //       hasMore: false,
          //     }),
          //     deductible: ``,
          //     deductibleError: ``,
          //     emptyDeductibles: vehicle.deductible.length ? false : true,
          //     free: vehicle.free,
          //     name: vehicle.name,
          //     required: vehicle.required,
          //     limit: ``,
          //     limitError: ``,
          //     limitsOptions: () => ({
          //       options: vehicle.limits.length
          //         ? vehicle.limits.map((limit) => ({
          //             value: limit.key,
          //             label: limit.name,
          //           }))
          //         : [],
          //       hasMore: false,
          //     }),
          //     emptyLimits: vehicle.limits.length ? false : true,
          //   }),
          // )

          setPolicyCoverages(policyScopped)
          // console.log(`vehicleScopped`, vehicleScopped)
          // setVehicleCoverages(vehicleScopped)
        }
      },
    },
  )

  const { isLoading: reRateQuoteFormLoading, mutate: reRateQuoteFormApi } =
    useMutation(
      async (payload) =>
        await AxiosInstance.post(`${AQS_BACKEND_API}/quotes/rerate`, {
          ...payload,
          quoteId: aqsQuote?.currentSavedQuotes?._quoteId,
        })
          .then((res) => {
            if (res.data) {
              return res?.data?.data
            }
          })
          .catch((error) => {
            NotificationManager.error(error?.response?.data?.message)
          }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: (res) => {
          if (res) {
            dispatch(setQuoteOffers(res?.quoteOffers))
            dispatch(setCurrentSavedQuotes(res?.updatedQuote))
            dispatch(
              setCommunicationCredentials({
                id: res?.updatedQuote?._quoteId,
                phone: res.updatedQuote?._customer?.contactInfo?.phone,
                customerId: res.updatedQuote?._customer?._id,
                preferredContactMethods:
                  res?.updatedQuote?._customer?.preferredContactMethods,
              }),
            )
          }
        },
      },
    )

  useEffect(() => {
    if (aqsQuote.policyCoverages) {
      setPolicyCoverages(aqsQuote.policyCoverages)
    }
  }, [aqsQuote.policyCoverages])

  useEffect(() => {
    if (selectedRatingTemplate?.length) {
      const _policyScoped =
        selectedRatingTemplate?.template?.policyCoverages.map((policy) => ({
          code: policy.code,
          deductibleOption: () => ({
            options: [],
            hasMore: false,
          }),
          deductible: {
            value: policy?.deductible,
            label: policy?.deductible,
          },
          deductibleError: ``,
          emptyDeductibles: true,
          free: policy.free,
          name: policy.name,
          required: policy.required,
          limit: { value: policy?.limits, label: policy?.limits },
          limitError: ``,
          limitsOptions: () => ({
            options: [],
            hasMore: false,
          }),
          emptyLimits: true,
        }))
      setPolicyCoverages(_policyScoped)
    }
  }, [selectedRatingTemplate?.template?.policyCoverages?.length])

  const ValidateForm = () => {
    for (const index in policyDataCoverages) {
      const policy = policyDataCoverages[index]
      if (policy.required === `YES`) {
        if (!policy.emptyDeductibles) {
          if (!policy.deductible?.value?.length) {
            handlePolicyErrors(`deductibleError`, `Select Options`, index)
          } else {
            handlePolicyErrors(`deductibleError`, ``, index)
          }
        }

        if (!policy.emptyLimits) {
          if (!policy.limit?.value?.length) {
            handlePolicyErrors(`limitError`, `Select Options`, index)
          } else {
            handlePolicyErrors(`limitError`, ``, index)
          }
        }
      }
    }
    handleErrors(`formSubmit`, true)
  }

  const handlePolicyErrors = (name, val, ind) => {
    const newPolicyScope = policyDataCoverages?.map((policy, index) => {
      if (index == ind) {
        policy[name] = val
      }
      return policy
    })
    setPolicyCoverages(newPolicyScope)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const handlePolicyCoverages = useDebouncedCallback((name, value, index) => {
    const newPolicyScope = policyDataCoverages?.map((i, ind) => {
      if (index === ind) {
        i[name] = value
      }
      return i
    })
    setPolicyCoverages(newPolicyScope)
  }, 5)

  const handleVehicleCoverages = (name, value, ind, vindex) => {
    const newVehicle = vehicles?.map((_vehicle, index) => {
      const vehicle = cloneDeep(_vehicle)
      if (index === ind) {
        if (vindex !== undefined) {
          vehicle?.vehicleScope.map((item, vidx) => {
            if (vindex === vidx) {
              item[name] = value
            }
            return item
          })
        } else {
          vehicle[name] = value
        }
      }
      return vehicle
    })
    setVehicles(newVehicle)
  }

  useEffect(() => {
    if (errors.formSubmit) {
      let policyScopeValidated = false
      const checkPolicyScopeError = (policy) => {
        if (!policy.deductibleError.length && !policy.limitError.length) {
          return true
        } else {
          return false
        }
      }
      policyScopeValidated = policyDataCoverages?.every(checkPolicyScopeError)
      if (policyScopeValidated) {
        reRateQuoteFormApi(preparePayload())
        handleErrors(`formSubmit`, false)
      }
    }
  }, [errors])

  const preparePayload = () => {
    let payload = cloneDeep(aqsQuote.quoteForm)
    const selectedState = aqsQuote?.quoteForm?.ratingPrefs?.state
    const prepareCoverages = policyDataCoverages.map((coverage) => ({
      rated:
        (selectedState === `CO` || selectedState === `FL`) &&
        (coverage.code === `PIP` || coverage.limit.value == `NONE`)
          ? `NO`
          : (selectedState === `CA` || selectedState === `TX`) &&
            coverage.code === `UMPD`
          ? `YES`
          : (selectedState === `CO` || selectedState === `CA`) &&
            (coverage.code === `UNDUM` || coverage.limit.value == `NONE`)
          ? `NO`
          : selectedState === `UT` &&
            (coverage.code === `UMPD` || coverage.limit.value == `NONE`)
          ? `NO`
          : selectedState === `UT` && coverage.code === `PD`
          ? `YES`
          : coverage.deductible.value !== `NONE` ||
            coverage.limit.value !== `NONE`
          ? `YES`
          : `NO`,
      name: coverage.code,
      code: coverage.code,
      limits:
        selectedState?.value === `CA` && coverage.code === `UMPD`
          ? `3500`
          : // : selectedState?.value === `UT` && coverage.code === `UMPD`
            // ? `250`
            // : selectedState?.value === `TX` && coverage.code === `UMPD`
            // ? `25`
            // : selectedState?.value === `CA` && coverage.code === `PD`
            // ? `5`
            // : selectedState?.value === `FL` && coverage.code === `PD`
            // ? `10`
            coverage.limit?.value,
      deductible: coverage.deductible?.value,
    }))

    const preparedVehicleCoverages = vehicles.map((vehicle) =>
      vehicle?.vehicleScope?.map((coverage) => ({
        deductibleOptions:
          coverage?._deductibleOptions === 0
            ? []
            : coverage?._deductibleOptions,
        limitsOptions:
          coverage?._limitOptions === 0 ? [] : coverage?._limitOptions,
        rated:
          !coverage.limit?.value?.includes(`NONE`) ||
          !coverage.deductible?.value?.includes(`NONE`)
            ? `YES`
            : `NO`,
        name: coverage.code,
        code: coverage.code,
        limits:
          // selectedState?.value === `UT` && coverage.code === `UMPD`
          //   ? `3500`
          coverage.limit?.value,
        deductible: coverage.deductible?.value,
      })),
    )
    payload.policyData.policyTerm = _policyTerm?.value
    payload.policyData.coverages = prepareCoverages
    payload.drivers = aqsQuote.quoteForm?.drivers?.map((item) => {
      const newDriver = { ...item }
      const driverFullName = `${newDriver?.firstName} ${newDriver?.lastName}`
      if (driverFullName === changeInsured?.value) {
        newDriver.relation = `INSURED`
      } else {
        newDriver.relation = `SPOUSE`
      }
      return newDriver
    })
    payload.cars = aqsQuote.quoteForm?.cars.map((item, ind) => {
      const vehicle = cloneDeep(item)
      vehicle.coverages = preparedVehicleCoverages[ind]
      return vehicle
    })
    payload.ratingPrefs.carriers = carriersdata?.map((rat) => {
      return {
        _carrier: rat.id,
        rated: rat?.rated,
        ratingType: rat?.ratingType?.toUpperCase(),
        orderCredit: rat?.orderCredit,
        paymentFilter: rat?.paymentFilter,
      }
    })
    return payload
  }

  return (
    <div className="py-2">
      <Spinner loading={isLoading || reRateQuoteFormLoading} />
      <div className="flex justify-between px-4">
        <div>
          <SubmitButton
            // className="m-2 py-1 px-2 bg-blue-500 text-white rounded-sm cursor-pointer"
            onClick={() => goBack()}
          >
            Go Back
          </SubmitButton>
        </div>
        <div>
          <SubmitButton onClick={ValidateForm}>
            <div>Rerate</div>
          </SubmitButton>
        </div>
      </div>

      <div
        className={`bg-white ${
          (isLoading || reRateQuoteFormLoading) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        <div className="flex">
          {/* coverages */}
          <Container className="w-1/2">
            <PageHeader quote="true" className="bg-blue-500 text-white">
              Coverages
            </PageHeader>
            <div className="p-2 grid grid-cols-2 gap-x-2">
              {policyDataCoverages?.map(
                (policy, index) =>
                  (!policy.emptyLimits || !policy.emptyDeductibles) && (
                    <div className="" key={index}>
                      <div>
                        <div className="text-sm font-medium">{policy.code}</div>

                        <div className="flex gap-x-2">
                          {!policy.emptyLimits && (
                            <div className="flex-1 relative">
                              <ReactSelectField
                                placeholder="Limits"
                                errorMessage={policy.limitError}
                                value={policy.limit}
                                setValue={(value) =>
                                  handlePolicyCoverages(`limit`, value, index)
                                }
                                loadOptions={policy.limitsOptions}
                                isSearchable={false}
                                disabled={policy.emptyLimits}
                                noTitle={true}
                                cacheUniqs={policyDataCoverages}
                              />
                            </div>
                          )}
                          {!policy.emptyDeductibles && (
                            <div className="flex-1 relative">
                              <ReactSelectField
                                placeholder="Deductibles"
                                value={policy.deductible}
                                errorMessage={policy.deductibleError}
                                setValue={(value) =>
                                  handlePolicyCoverages(
                                    `deductible`,
                                    value,
                                    index,
                                  )
                                }
                                loadOptions={policy.deductibleOption}
                                isSearchable={false}
                                disabled={policy.emptyDeductibles}
                                noTitle={true}
                                cacheUniqs={policyDataCoverages}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ),
              )}
            </div>
            {vehicles[0]?.model?.value?.length >= 0 && (
              <PageHeader quote="true" className="bg-blue-500 text-white">
                Vehicle Coverages
              </PageHeader>
            )}

            <div className="flex items-center px-1 py-2">
              <TabsHeaders
                openTab={activeVehicleTab}
                tabs={vTabs}
                getActiveTab={(val) => setActiveVehicleTab(val)}
                customTabs="YES"
                isCustomColor
              />
            </div>
            {vehicles?.map((vehicle, index) => (
              <FormTab
                key={index}
                currenttab={index + 1}
                opentab={activeVehicleTab}
                className="border-2 border-gray-100 shadow-sm rounded-md relative px-2 mx-1"
              >
                {vehicle?.model?.value}

                <div className="p-2 grid grid-cols-2 gap-x-2">
                  {vehicle?.vehicleScope?.map(
                    (policy, vindex) =>
                      (!policy.emptyLimits || !policy.emptyDeductibles) && (
                        <div className="" key={vindex}>
                          <div>
                            <div className="text-sm font-medium">
                              {policy.code}
                            </div>

                            <div className="flex gap-x-2">
                              {!policy.emptyLimits && (
                                <div className="flex-1 relative">
                                  <ReactSelectField
                                    placeholder="Limits"
                                    errorMessage={policy.limitError}
                                    value={policy.limit}
                                    setValue={(value) =>
                                      handleVehicleCoverages(
                                        `limit`,
                                        value,
                                        index,
                                        vindex,
                                      )
                                    }
                                    loadOptions={policy.limitsOptions}
                                    isSearchable={false}
                                    disabled={policy.emptyLimits}
                                    noTitle={true}
                                    cacheUniqs={policyDataCoverages}
                                  />
                                </div>
                              )}
                              {!policy.emptyDeductibles && (
                                <div className="flex-1 relative">
                                  <ReactSelectField
                                    placeholder="Deductibles"
                                    value={policy.deductible}
                                    errorMessage={policy.deductibleError}
                                    setValue={(value) =>
                                      handleVehicleCoverages(
                                        `deductible`,
                                        value,
                                        index,
                                        vindex,
                                      )
                                    }
                                    loadOptions={policy.deductibleOption}
                                    isSearchable={false}
                                    disabled={policy.emptyDeductibles}
                                    noTitle={true}
                                    cacheUniqs={policyDataCoverages}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ),
                  )}
                </div>
              </FormTab>
            ))}

            {/* <div className="flex justify-center">
              <div className="text-center w-96 my-4">
                <SubmitButton onClick={ValidateForm}>
                  <div>Rerate</div>
                </SubmitButton>
              </div>
            </div> */}
          </Container>

          {/* prefered carriers */}
          <Container className="w-1/2">
            <PageHeader quote="true" className="bg-blue-500 text-white">
              Preferred Carriers
            </PageHeader>
            <div className="self-start h-50vh overflow-y-scroll hide-scroller hide-scroller-firefox p-3">
              <ReactTable
                carriersdata={carriersdata}
                isStateChange={isStateChange}
              />
            </div>
          </Container>
          <Container className="w-1/2 ">
            <PageHeader quote="true" className="bg-blue-500 text-white">
              Customer Communication
            </PageHeader>
            <div className="self-start  h-auto  overflow-y-scroll hide-scroller hide-scroller-firefox p-3">
              <Communications quoteOffers={true} />
            </div>
          </Container>
          {/* quote offers */}
        </div>

        <Container>
          <PageHeader quote="true" className="bg-blue-500 text-white">
            General Information
          </PageHeader>
          <div className="grid grid-cols-4 px-4 py-2 gap-4">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Policy Term"
                placeholder="Select Policy Term"
                value={_policyTerm}
                // errorMessage={errors?.policyTermError}
                setValue={(value) => setPolicyTerm(value)}
                isMulti={false}
                loadOptions={loadPolicyTerm}
                isSearchable={true}
              />
            </div>
            <div className="flex-1 relative">
              <ReactSelectField
                title="Change name insured to"
                placeholder="Select Policy Term"
                value={changeInsured}
                // errorMessage={errors?.policyTermError}
                setValue={(value) => setChangeInsured(value)}
                isMulti={false}
                loadOptions={() => {
                  return {
                    options: _drivers?.map((item) => ({
                      value: `${item?.firstName} ${item?.lastName}`,
                      label: `${item?.firstName} ${item?.lastName}`,
                    })),
                    // [
                    //   { value: `1`, label: `Monthly` },
                    //   { value: `12`, label: `Annual` },
                    //   { value: `3`, label: `Quarterly` },
                    //   { value: `6`, label: `Semi Annual` },
                    //   { value: `2`, label: `Two Month` },
                    // ],
                    hasMore: false,
                  }
                }}
                isSearchable={true}
              />
            </div>
          </div>
        </Container>
        <Container>
          <PageHeader quote="true" className="bg-blue-500 text-white py-2">
            <div className="flex justify-between w-full">
              <div className="flex-1">Quote Offers</div>
              <div className="font-medium cursor-pointer underline">
                <a
                  href={aqsQuote?.currentSavedQuotes?.turboRaterUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  Turbo Rater Url
                </a>
              </div>
            </div>
          </PageHeader>
          <div className="flex">
            <div className="flex-1"></div>
            <div className="flex-1 flex gap-6 items-center max-w-xs mr-40 pt-4 font-semibold">
              <div>Months</div>
              <div>Down Payment</div>
              <div>Total Payment</div>
            </div>
            {/* <div className="flex-1"></div> */}
          </div>
          {Object.keys(aqsQuote?.currentQuoteOffers).length !== 0 ? (
            <div className="p-2  overflow-y-scroll capitalize">
              {Object.keys(aqsQuote?.currentQuoteOffers)?.map(
                (quoteData, index) => (
                  <div className="my-1" key={index}>
                    <Collapsable
                      headerTitle={[
                        `$${aqsQuote?.currentQuoteOffers[
                          quoteData
                        ][0]?.plan?.amountPerPayment?.toFixed(2)}/(${
                          aqsQuote?.currentQuoteOffers[quoteData][0]?.plan
                            ?.payments
                        })`,
                        `$${aqsQuote?.currentQuoteOffers[quoteData][0]?.plan?.downPayment} `,
                        ` $${aqsQuote?.currentQuoteOffers[quoteData][0]?.plan?.totalPremium}`,
                      ]}
                      titleIcon={
                        aqsQuote?.currentQuoteOffers[quoteData][0]?.errors
                          ?.length > 0
                      }
                      title={
                        quoteData +
                        ` ` +
                        `(${aqsQuote?.currentQuoteOffers[quoteData]?.length})    `
                      }
                    >
                      <div className="flex gap-2">
                        {aqsQuote?.currentQuoteOffers[quoteData][0]?.warnings
                          ?.length > 0 && (
                          <div className="max-h-60 min-h-6 bg-yellow-50 shadow-sm rounded-sm py-2 overflow-y-scroll hide-scroller hide-scroller-firefox">
                            <img
                              src={warning}
                              alt="img"
                              className="ml-2 h-5 w-5"
                            />
                            {aqsQuote?.currentQuoteOffers[
                              quoteData
                            ][0]?.warnings.map((data, index) => (
                              <div key={index} className="w-full px-2 py-1">
                                <div className="flex">
                                  <div className="text-sm">{data.Text}</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {aqsQuote?.currentQuoteOffers[quoteData][0]?.errors
                          ?.length > 0 && (
                          <div className="max-h-60 min-h-6 bg-red-50 shadow-sm rounded-sm py-2 overflow-y-scroll hide-scroller hide-scroller-firefox">
                            <img
                              src={error}
                              alt="img"
                              className="ml-2 h-5 w-5"
                            />
                            {aqsQuote?.currentQuoteOffers[
                              quoteData
                            ][0]?.errors?.map((data, index) => (
                              <div key={index} className="w-full px-2 py-1">
                                <div className="flex">
                                  <div className="text-sm">{data.Text}</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {aqsQuote?.currentQuoteOffers[quoteData][0]?.fatalErrors
                          ?.length > 0 && (
                          <div className="max-h-60 min-h-6 bg-blue-50 shadow-sm rounded-sm py-2 overflow-y-scroll hide-scroller hide-scroller-firefox">
                            <img src={error} alt="img" className="h-5 w-5" />
                            {aqsQuote?.currentQuoteOffers[
                              quoteData
                            ][0]?.fatalErrors.map((data, index) => (
                              <div key={index} className="w-full px-2 py-1">
                                <div className="flex">
                                  <div className="text-sm">{data.Text}</div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {aqsQuote?.currentQuoteOffers[quoteData][0]?.plan
                          ?.description?.length > 0 && (
                          <div className="max-h-60 min-h-6 bg-blue-50 shadow-sm rounded-sm py-2 overflow-y-scroll hide-scroller hide-scroller-firefox">
                            <h2 className="text-md font-semibold px-2">
                              Description
                            </h2>
                            <div key={index} className="w-full px-2 py-1">
                              <div className="text-sm">
                                {
                                  aqsQuote?.currentQuoteOffers[quoteData][0]
                                    ?.plan?.description
                                }
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {aqsQuote?.currentQuoteOffers[quoteData]?.map(
                        (quote, idx) => (
                          <QuoteCard key={idx} data={quote} />
                        ),
                      )}
                    </Collapsable>
                  </div>
                ),
              )}
            </div>
          ) : (
            <div className="text-center p-0.5 ">
              No Quote Offer From Selected Carrier
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}
