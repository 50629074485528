/* eslint-disable no-unused-vars */
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { StyledButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { InputField } from '../../Common/InputField'
import { ReactSelectField } from '../../Common/ReactSelect'
import { useHistory } from 'react-router-dom'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NotificationManager } from 'react-notifications'

export const AddDesignation = ({ closeModal, addDesignations }) => {
  const history = useHistory()
  const [designation, setDesignation] = useState({
    name: ``,
    type: ``,
    rate: ``,
  })
  const [errors, setErrors] = useState({
    nameError: ``,
    commissionTypeError: ``,
    commissionRateError: ``,
  })

  //   const { profile } = useSelector(({ user: { user } }) => user)
  const loadTypes = async () => {
    return {
      options: [
        { value: `%`, label: `%` },
        { value: `FLAT`, label: `FLAT` },
      ],
      hasMore: false,
    }
  }

  const { mutate, isLoading, error } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/designations`, {
        ...payload,
      }).then((res) => {
        if (res?.data) {
          NotificationManager.success(`Operation successful`)
          history.push(`/admin/designation`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: () => {
        closeModal()
        addDesignations()
      },
    },
  )
  const ValidateForm = () => {
    if (!designation.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }

    if (designation?.type?.value?.length && designation?.type?.value === `%`) {
      if (!designation?.rate) {
        handleErrors(`commissionRateError`, `Enter Rate`)
      } else if (designation?.rate > 100) {
        handleErrors(`commissionRateError`, `Rate must be around 100`)
      } else if (designation?.rate < 1) {
        handleErrors(`commissionRateError`, `Rate must be more than 0`)
      } else {
        handleErrors(`commissionRateError`, ``)
      }
    } else {
      if (!designation?.rate) {
        handleErrors(`commissionRateError`, `Enter Rate`)
      } else if (designation?.rate < 1 || designation?.rate > 9999999) {
        handleErrors(`commissionRateError`, `Rate must be b/w 1 to 9999999`)
      } else {
        handleErrors(`commissionRateError`, ``)
      }
    }
    if (!designation?.type?.value?.length) {
      handleErrors(`commissionTypeError`, `Select Type`)
    } else {
      handleErrors(`commissionTypeError`, ``)
    }
    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.commissionTypeError.length &&
      !errors.commissionRateError.length
    ) {
      mutate({
        name: designation?.name,
        commission: {
          type: designation?.type?.value,
          rate: designation?.rate,
        },
      })
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  return (
    <div className="flex flex-col">
      <div className="flex flex-col px-4">
        <div className="flex-1 relative">
          <InputField
            type="text"
            name="name"
            title="Name *"
            placeholder="Enter Name"
            value={designation.name}
            setValue={(e) => {
              setDesignation((prevState) => ({
                ...prevState,
                [`name`]: e.target.value,
              }))
            }}
            errorMessage={errors.nameError}
          />
        </div>
        <div className="flex gap-4">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Commission Type *"
              placeholder="Select Type"
              errorMessage={errors.commissionTypeError}
              value={designation.type}
              setValue={(value) => {
                setDesignation((prevState) => ({
                  ...prevState,
                  [`type`]: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadTypes}
              isSearchable={false}
              disabled={false}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="rate"
              title="Rate *"
              placeholder="Enter Rate"
              value={designation.rate}
              setValue={(e) => {
                setDesignation((prevState) => ({
                  ...prevState,
                  [`rate`]: e.target.value,
                }))
              }}
              onKeyPress={(e) =>
                [`e`, `E`, `+`, `-`, `.`].includes(e.key) &&
                e.preventDefault()
              }
              errorMessage={errors.commissionRateError}
            />
          </div>
        </div>
      </div>
      <div className="pb-2 px-4 text-right">
        <StyledButton onClick={ValidateForm}>
          <div className="flex gap-2 text-white">
            <span>Add</span>
            {isLoading && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                className="my-auto"
              />
            )}
          </div>
        </StyledButton>
      </div>
    </div>
  )
}
