import { Collapsable } from 'App/Components/Common/Collapsable'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import React from 'react'
import { MyFiles } from './MyFiles'
import { AllTemplates } from './Template'
// import { AllTemplates } from './Template'

export const Documents = () => {
  return (
    <div className="my-2 mx-2 ">
      <Collapsable title="Files">
        <MyFiles />
      </Collapsable>
      <div className="my-2">
        <CollapsableRef title="Templates">
          <AllTemplates />
        </CollapsableRef>
      </div>
    </div>
  )
}
