import React from 'react'
import { faCheckCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export const DocumentsBadge = ({ value }) => {
  return value === null ? (
    <span>N/A</span>
  ) : value ? (
    <FontAwesomeIcon
      icon={faCheckCircle}
      size="1x"
      className=" text-green-600"
    />
  ) : (
    <FontAwesomeIcon
      icon={faPlusCircle}
      size="1x"
      className="fa-rotate-45 text-red-500"
    />
  )
}
