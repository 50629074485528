import React, { useEffect, useState, Fragment } from 'react'
import { defaultImage } from 'App/Assets'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, StyledButton, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Avatar,
} from '@windmill/react-ui'
import {
  faChevronCircleDown,
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { loadAgentStatus, permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
import FiltersAgents from '../FilterAgents'

const tableColumns = [
  { label: `ID`, value: `_userId` },
  { label: `First Name`, value: `firstName` },
  { label: `Last Name`, value: `lastName` },
  { label: `Email`, value: `contactInfo.email` },
  { label: `Phone`, value: `contactInfo.phone` },
  { label: `Franchise`, value: `_franchise.name` },
]

export const AllAgents = () => {
  const history = useHistory()
  const { url } = useRouteMatch()

  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [agentStatusFilter, setAgentStatusFilter] = useState(``)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)

  const [collapseIndex, setCollapseIndex] = useState(``)
  const [active, setActive] = useState(false)
  // const [isMasterFrenchise, setIsMasterFrenchise] = useState(false)
  const [isOffset, setIsOffset] = useState(``)

  const [agentsFilters, setAgentsFilters] = useState({
    name: ``,
    email: ``,
    phone: ``,
    _userId: ``,
  })

  //   getting all locations
  const {
    isLoading,
    data: agentsResponse,
    refetch: getAllAgents,
    isFetching,
    error,
  } = useQuery(
    `AgentsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/users/all?search=${searchFilters()}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }${
          agentStatusFilter?.value
            ? `&accountStatus=${agentStatusFilter?.value}`
            : ``
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    isLoading: deletingAgent,
    isError: deletionError,
    mutate: deleteAgent,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(`${AMS_BACKEND_API}/api/users/${id}`).then(
        (res) => {
          NotificationManager.success(`Successfully Deleted`)
          res.data && getAllAgents()
        },
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  useEffect(() => {
    if (!customSearching) {
      getAllAgents()
    }
  }, [sort, currentPage, isOffset, agentStatusFilter, agentsFilters])

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteAgent(id)
  }

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!active) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }

  const handleResetFilters = () => {
    setAgentsFilters({
      name: ``,
      email: ``,
      phone: ``,
      _userId: ``,
    })
  }

  const handleFiltersChange = (filters) => {
    setCurrentPage(1)
    setAgentsFilters(filters)
  }

  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }

    if (agentsFilters?.name?.length) {
      data.filters.name = agentsFilters?.name
    }
    if (agentsFilters?.phone?.length) {
      data.filters.phone = agentsFilters?.phone
    }
    if (agentsFilters?.email?.length) {
      data.filters.email = agentsFilters?.email
    }
    if (agentsFilters?._userId?.length) {
      data.filters._userId = agentsFilters?._userId
    }

    return JSON.stringify(data)
  }

  return (
    <Container>
      <Spinner loading={deletingAgent || isLoading || isFetching} />
      {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <PageHeader>
        <div>Agents</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="Active"
              value={agentStatusFilter}
              setValue={(e) => {
                setCurrentPage(1)
                setAgentStatusFilter(e)
              }}
              isMulti={false}
              loadOptions={loadAgentStatus}
            />
          </div>
        </div>
      </PageHeader>
      <div className="flex justify-end items-center px-2">
        {permissionGranted([
          `users:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
          `car-dealer-manager:*`,
        ]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <FiltersAgents
        filtersProps={agentsFilters}
        handleFilters={handleFiltersChange}
        resetFilters={handleResetFilters}
        isLoading={isLoading || isFetching}
      />
      <div
        className={`bg-white sm:mb-0 justify-between w-full p-2  ${
          (deletingAgent || isLoading || isFetching) &&
          ` opacity-30 pointer-events-none`
        }  `}
      >
        {error ? (
          <Reload refetch={() => getAllAgents()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse bg-gray-50">
                <TableHeader>
                  <TableRow>
                    <TableCell className="text-sm">Image</TableCell>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    <TableCell className="text-sm">Locations</TableCell>
                    {permissionGranted([
                      `users:delete`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) && (
                      <TableCell className="px-6 text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !agentsResponse?.users?.length ? (
                    <tr>
                      <td colSpan="12">
                        <div className="w-full text-center p-6  ">
                          <p>No Agent Found</p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    agentsResponse?.users?.map((user, index) => (
                      <Fragment key={index}>
                        <TableRow
                          key={index}
                          className="hover:bg-gray-100 whitespace-nowrap cursor-pointer"
                          onClick={() => {
                            if (
                              permissionGranted([
                                `users:read`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                                `car-dealer-manager:*`,
                              ])
                            ) {
                              history.push(`${url}/${user._id}`)
                            }
                          }}
                        >
                          <td className="px-4 py-1">
                            <div className="flex items-center ">
                              <Avatar
                                src={user.img ? user.img : defaultImage}
                                alt="Judith"
                              />
                            </div>
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {user?._userId ? (
                              user?._userId
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 capitalize text-sm">
                            {user?.firstName ? (
                              user?.firstName?.toLowerCase()
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 capitalize text-sm">
                            {user?.lastName ? (
                              user?.lastName?.toLowerCase()
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {user?.contactInfo?.email ? (
                              user?.contactInfo?.email
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {user?.contactInfo?.phone ? (
                              user?.contactInfo?.phone
                            ) : (
                              <Badge>N/A </Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5  whitespace-nowrap">
                            {user?._franchise ? (
                              <Badge>{user?._franchise?.name}</Badge>
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>

                          <td className="px-4 py-0.5 ">
                            <span>
                              {user._franchise?.locations?.length ? (
                                <Badge type="success">
                                  {user._franchise?.locations[0]}
                                </Badge>
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </span>
                          </td>
                          {/* <td className="px-5 py-0.5  ">
                        <div className="flex flex-row flex-wrap  ">
                          {user.systemRoles?.length ? (
                            user.systemRoles.map((systemRole, idx) => {
                              return (
                                <Badge type="warning" key={idx}>
                                  {systemRole}
                                </Badge>
                              )
                            })
                          ) : (
                            <Badge type="danger">N/A</Badge>
                          )}
                        </div>
                      </td> */}
                          {/* Actions TD */}
                          <td className="px-7 py-2 z-0 text-sm flex float-right text-gray-400">
                            {user._franchise?.locations?.length > 1 && (
                              <span data-tip="Expand" className="tooltip">
                                <FontAwesomeIcon
                                  className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                    collapseIndex === index &&
                                    `rotate-180 text-blue-600`
                                  }`}
                                  icon={faChevronCircleDown}
                                  onClick={(e) => {
                                    setActive(!active)
                                    handleCollapse(index, e)
                                  }}
                                />
                              </span>
                            )}
                            {permissionGranted([
                              `users:delete`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ]) && (
                              <span data-tip="Delete" className="tooltip">
                                <FontAwesomeIcon
                                  className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                  icon={faTrash}
                                  size="1x"
                                  onClick={(e) => handleDelete(user._id, e)}
                                />
                              </span>
                            )}
                          </td>
                        </TableRow>
                        {collapseIndex === index && (
                          <TableRow>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                            <td className="px-4 py-0.5 max-w-sm">
                              <span className="px-2">
                                {user._franchise?.locations?.length ? (
                                  user._franchise?.locations.map(
                                    (location, idx) => {
                                      return (
                                        <Badge type="success" key={idx}>
                                          {location}
                                        </Badge>
                                      )
                                    },
                                  )
                                ) : (
                                  <Badge>N/A</Badge>
                                )}
                              </span>
                            </td>
                          </TableRow>
                        )}
                      </Fragment>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={agentsResponse?.totalPages}
                totalRecords={agentsResponse?.totalRecords}
                curerntPageRecords={agentsResponse?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
