import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { setClaimActivityActiveTab } from 'App/Redux/actions'
import { TOne, TTwo, TThree } from 'App/Styled'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ClaimNote } from '../../ClaimNote'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { MediaFiles } from '../Media'
import { permissionGranted } from 'App/Services'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  {
    name: `Logs`,
    number: 2,
  },
  {
    name: `Media`,
    number: 3,
  },
]

const activityTabs2 = [
  {
    name: `Logs`,
    number: 1,
  },
  {
    name: `Media`,
    number: 2,
  },
]
export const ClaimActivity = () => {
  const { currentClaim, activeActivityTab } = useSelector(({ claim }) => claim)
  const statusLogs = currentClaim?.updateLogs?.filter(
    (item) => item?.update?.field === `STATUS`,
  )
  const dispatch = useDispatch()
  return (
    <div className="px-2">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([
            `notes:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ])
            ? activityTabs
            : activityTabs2
        }
        getActiveTab={(val) => dispatch(setClaimActivityActiveTab(val))}
      />
      {permissionGranted([
        `notes:read`,
        `corporate-manager:*`,
        `manager:*`,
        `admin:*`,
      ]) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <ClaimNote />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <UpdateLogs logs={statusLogs ? statusLogs : []} />
          </TTwo>
          <TThree opentab={activeActivityTab}>
            <MediaFiles />
          </TThree>
        </div>
      ) : (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <UpdateLogs logs={statusLogs ? statusLogs : []} />
          </TOne>
          <TTwo opentab={activeActivityTab}>
            <MediaFiles />
          </TTwo>
        </div>
      )}
    </div>
  )
}
