import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import {
  faPlusCircle,
  faSortUp,
  faSortDown,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import searchQueryParser from 'App/Services/General/searchQueryParser'

const tableColumns = [
  {
    label: `First Name`,
    value: `firstName`,
  },
  {
    label: `Last Name`,
    value: `lastName`,
  },
  {
    label: `Relation`,
    value: `relation`,
  },
  {
    label: `Phone`,
    value: `phone`,
  },
  {
    label: `State`,
    value: `state`,
  },
]

export const AllContacts = () => {
  const history = useHistory()
  const {
    params: { customerId },
    url,
  } = useRouteMatch()

  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  // const [isMasterFrenchise, setIsMasterFrenchise] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)

  const {
    isLoading: isLoading,
    data: contactsData,
    refetch: getAllContacts,
    isFetching,
    error,
  } = useQuery(
    `CustomerContactsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/contacts?search={"query": "${
          searchQuery.length ? searchQueryParser(searchQuery) : ``
        }"}&_module={"value":"${customerId}", "label":"customer"}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    mutate: deleteContact,
    isLoading: deletingContact,
    isError: deletionError,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/contacts/${id}?_module={"value":"${customerId}", "label":"customer"}`,
      ).then((res) => {
        res.data && getAllContacts()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (!customSearching) {
      getAllContacts()
    }
  }, [sort, currentPage, isOffset])

  const callContactsQuery = useDebouncedCallback(() => {
    getAllContacts()
  }, 700)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteContact(id)
  }

  return (
    <Container>
      {deletionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <PageHeader>
        <div>Contacts</div>

        <div className="flex items-center ">
          <div className="py-1 px-2 w-24">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>

          <div className="text-end ">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callContactsQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
              // resetButton={true}
              // resetValue={() => {
              //   setSearchQuery(``)
              //   // dispatch(setQuerySearch(``))
              //   callAgentsQuery()
              // }}
            />
          </div>
        </div>
      </PageHeader>
      {permissionGranted([`contacts:create`]) && (
        <div className="flex justify-end items-center p-2">
          <StyledButton onClick={() => history.push(`${url}/contact/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        </div>
      )}

      <Spinner loading={deletingContact || isLoading || isFetching} />
      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (deletingContact || isLoading || isFetching) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllContacts()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className=" cursor-pointer select-none text-sm flex">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item?.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(permissionGranted([`contacts:read`]) ||
                      permissionGranted([`contacts:delete`])) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !contactsData?.contacts?.length ? (
                    <tr>
                      <td colSpan="12" className=" text-center p-6">
                        No Contacts Found
                      </td>
                    </tr>
                  ) : (
                    contactsData?.contacts?.map((contact, index) => (
                      <TableRow
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          if (permissionGranted([`contacts:read`])) {
                            history.push(`${url}/contact/${contact._id}`)
                          }
                        }}
                      >
                        <td className="px-4 py-0.5 text-sm capitalize truncate">
                          {contact.firstName ? (
                            contact.firstName?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 text-sm capitalize truncate">
                          {contact.lastName ? (
                            contact.lastName?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 text-sm">
                          {contact.relation ? (
                            contact.relation
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 text-sm">
                          {contact.phone ? contact.phone : <Badge>N/A</Badge>}
                        </td>
                        <td className="px-4 py-0.5 text-sm">
                          <Badge>
                            {contact?.address.state
                              ? contact?.address.state
                              : `N/A`}
                          </Badge>
                        </td>
                        {/* Actions TD */}
                        <td className="px-8 py-0.5 text-sm z-0 flex float-right text-gray-400">
                          {permissionGranted([`contacts:delete`]) && (
                            <span data-tip="Delete" className="tooltip">
                              <FontAwesomeIcon
                                className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                icon={faTrash}
                                size="1x"
                                onClick={(e) => handleDelete(contact._id, e)}
                              />
                            </span>
                          )}
                        </td>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={contactsData?.totalPages}
                totalRecords={contactsData?.totalRecords}
                curerntPageRecords={contactsData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
