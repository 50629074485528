import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { Container } from 'App/Styled'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)
const newLegendClickHandler = (e, legendItem, legend) => {
  const index = legendItem.datasetIndex
  const ci = legend.chart

  let meta = ci.getDatasetMeta(index)

  // See controller.isDatasetVisible comment
  meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null

  ci.update()
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      onClick: newLegendClickHandler,
    },
  },
}

export const LeadsByStatusStackedGraph = ({ leadsByStatus }) => {
  const [dataSet, setDataSet] = useState({
    labels: [],
    datasets: [
      {
        data: [],
      },
    ],
    borderWidth: 1,
  })
  useEffect(() => {
    const labels = leadsByStatus?.map((item) => item.month)
    const values = leadsByStatus?.map((item) => item.leads)

    const inProgress = values?.map((item) => {
      return item.filter(
        (itemObject) => itemObject?.status === `IN PROGRESS`,
      )[0]?.leads
    })
    const lost = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.status === `LOST`)[0]
        ?.leads
    })
    const manual = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.status === `MANUAL`)[0]
        ?.leads
    })
    const notAnswered = values?.map((item) => {
      return item.filter(
        (itemObject) => itemObject?.status === `NOT ANSWERED`,
      )[0]?.leads
    })
    const prospect = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.status === `PROSPECT`)[0]
        ?.leads
    })
    const quote = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.status === `QUOTE`)[0]
        ?.leads
    })
    const sold = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.status === `SOLD`)[0]
        ?.leads
    })

    const monthNames = [
      `Jan`,
      `Feb`,
      `Mar`,
      `Apr`,
      `May`,
      `Jun`,
      `Jul`,
      `Aug`,
      `Sep`,
      `Oct`,
      `Nov`,
      `Dec`,
    ]
    const newMonthName = []
    for (let i = 0; i < labels?.length; i++) {
      newMonthName.push(monthNames[i])
    }

    const leadsInfo = {
      labels: newMonthName,
      datasets: [
        {
          label: `IN PROGRESS`,
          data: inProgress,
          backgroundColor: `#ffa800`,
          borderColor: `#ffa800`,
          fill: false,
          borderWidth: 1,
        },
        {
          label: `LOST`,
          data: lost,
          backgroundColor: `#8950fc`,
          borderColor: `#8950fc`,
          fill: false,
          borderWidth: 1,
        },
        {
          label: `MANUAL`,
          data: manual,
          backgroundColor: `#659f3b`,
          borderColor: `#659f3b`,
          fill: false,
          borderWidth: 1,
        },
        {
          label: `NOT ANSWERED`,
          data: notAnswered,
          backgroundColor: `#027d8b`,
          borderColor: `#027d8b`,
          fill: false,
          borderWidth: 1,
        },
        {
          label: `PROSPECT`,
          data: prospect,
          backgroundColor: `#00be8c`,
          borderColor: `#00be8c`,
          fill: false,
          borderWidth: 1,
        },
        {
          label: `QUOTE`,
          data: quote,
          backgroundColor: `#ff146f`,
          borderColor: `#ff146f`,
          fill: false,
          borderWidth: 1,
        },
        {
          label: `SOLD`,
          data: sold,
          backgroundColor: `#272a3c`,
          borderColor: `#272a3c`,
          fill: false,
          borderWidth: 1,
        },
      ],
    }
    setDataSet(leadsInfo)
  }, [leadsByStatus])
  return (
    <Container>
      <div className="py-9 relative">
        <div className="font-bold absolute left-4 2xl:left-6 top-5">
          Leads by Status
        </div>
      </div>

      <div className="h-280 m-6">
        {dataSet.labels != undefined ? (
          <Line data={dataSet} options={options} />
        ) : null}
      </div>
    </Container>
  )
}
