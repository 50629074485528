import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faImage } from '@fortawesome/free-solid-svg-icons'
import { ReactModal } from 'App/Components/Common/Modal'

export const FilePreview = ({ file }) => {
  const [imagePreModal, setImagePreModal] = useState(``)
  const [filePrevModal, setFilePrevModal] = useState(``)

  return (
    <div
      onClick={(e) => {
        if (e && e.stopPropagation) e.stopPropagation()
      }}
      className="cursor-default select-none"
    >
      {imagePreModal && (
        <ReactModal
          title="Photo"
          closeModal={(e) => {
            if (e && e.stopPropagation) e.stopPropagation()
            setImagePreModal(false)
          }}
          disabled={true}
          loading={false}
          toolTip="Preview File"
          className="p-2"
        >
          <div className="items-center my-0">
            <img src={file} alt="img" />
          </div>
        </ReactModal>
      )}
      {filePrevModal && (
        <ReactModal
          title="PDF"
          closeModal={(e) => {
            if (e && e.stopPropagation) e.stopPropagation()
            setFilePrevModal(false)
          }}
          disabled={true}
          loading={false}
          toolTip="Preview File"
          className="p-2"
        >
          <div
            style={{ width: `80vw`, height: `90vh` }}
            className="items-center my-0"
          >
            <embed src={file} className="w-full h-full" />
          </div>
        </ReactModal>
      )}
      <div>
        {file.includes(`data:image/`) ? (
          <div
            onClick={(e) => {
              if (e && e.stopPropagation) e.stopPropagation()
              setImagePreModal(true)
            }}
          >
            <FontAwesomeIcon
              className="mx-1 cursor-pointer transform transition hover:text-red-500 hover:-translate-y-0 hover:scale-105"
              icon={faImage}
              size="1x"
            />
          </div>
        ) : file.includes(`data:application/pdf`) ? (
          <div
            onClick={(e) => {
              if (e && e.stopPropagation) e.stopPropagation()
              setFilePrevModal(true)
            }}
          >
            <FontAwesomeIcon
              className="mx-1 cursor-pointer transform transition hover:text-red-500 hover:-translate-y-0 hover:scale-105"
              icon={faFilePdf}
              size="1x"
            />
          </div>
        ) : (
          ``
        )}
      </div>
    </div>
  )
}
