/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react'
import { useMutation } from 'react-query'
import {
  Container,
  FormTab,
  PageHeader,
  SecondaryHeading,
  SubmitButton,
} from 'App/Styled'
import { AxiosInstance, AMS_BACKEND_API, STRIPE_PUBLIC_KEY } from 'App/Config'
import { InputField } from 'App/Components/Common/InputField'
import {
  faPlus,
  faPlusCircle,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { StyledButton } from 'App/Styled'
import { NotificationManager } from 'react-notifications'
import { StripeForm } from './Stripe'
import { loadPaymentMethods } from 'App/Services'
import { CardField } from 'App/Components/Common/CardField'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import {
  AddFormButton,
  RemoveDriver,
} from 'App/Components/Raters/QuoteForm/style'
import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { paymentAmountTotal } from 'App/hooks'

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

export const TransactionInspection = () => {
  // const user = useSelector(({ user: { user } }) => user)

  //State to track the type of Payment i.e Cash, Credit Card etc
  const [paymentMethodType, setPaymentMethodType] = useState(``)
  const [isPaymentMethodClosed, setProcessingFeeTabIndex] = useState(``)
  const [stripeToken, setStripeToken] = useState([])

  const [confirmStripeLoader, setConfirmStripeLoader] = useState(false)
  const [carrier, setCarrier] = useState(``)
  const [policyNo, setPolicyNo] = useState(``)
  const [dueAmountData, setDueAmountData] = useState(``)
  const [initiatedPayment, setInitiatedPayment] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false)

  const [transactionTypes, setTransactionTypes] = useState([
    {
      amount: ``,
      amountError: ``,
      paymentMethod: ``,
      paymentMethodError: ``,
      confirmRefNo: ``,
      confirmRefNoError: ``,
      paymentNote: ``,
    },
  ])
  const [fees, setFees] = useState([
    {
      feeType: { value: `MP EFT TO COMPANY`, label: `MP EFT TO COMPANY` },
      feeTypeError: ``,
      amount: dueAmountData?.paymentInfo
        ? dueAmountData?.paymentInfo?.dueAmount
        : 5,
      amountError: ``,
    },
  ])

  const [activeFeeTab, setActiveFeeTab] = useState(1)
  const [fTabs, setFTabs] = useState([
    {
      tName: `Fee 1`,
      tNumber: 1,
    },
  ])

  const [activePaymentMethodeTab, setActivePaymentMethodeTab] = useState(1)

  const [pTabs, setPTabs] = useState([
    {
      tName: `Method 1`,
      tNumber: 1,
    },
  ])
  const [payScreen, setPayScreen] = useState(``)

  const [errors, setErrors] = useState({
    formSubmit: false,
    policyNoError: ``,
    carrierError: ``,
  })
  const [error, setError] = useState({
    formSubmit: false,
    amountMatchError: ``,
  })

  const addFee = () => {
    setFees([
      ...fees,
      {
        feeType: ``,
        feeTypeError: ``,
        amount: ``,
        amountError: ``,
      },
    ])

    setFTabs([
      ...fTabs,
      {
        tName: `Fee ${fTabs.length + 1}`,
        tNumber: fTabs.length + 1,
      },
    ])
  }
  const removeFee = (ind) => {
    const newFees = [...fees]
    if (newFees.length != 1) {
      newFees.splice(ind, 1)
      setFees(newFees)
    }
    const newFeesTab = [...fTabs]
    if (newFeesTab.length !== 1) {
      newFeesTab.pop()
      setFTabs(newFeesTab)
    }
  }
  useEffect(() => {
    setActiveFeeTab(fTabs.length)
  }, [fTabs.length])

  useEffect(() => {
    setActivePaymentMethodeTab(pTabs.length)
  }, [pTabs.length])

  const addTransactionType = () => {
    setTransactionTypes([
      ...transactionTypes,
      {
        paymentMethod: ``,
        paymentMethodError: ``,
        amount: ``,
        amountError: ``,
        confirmRefNo: ``,
        confirmRefNoError: ``,
        paymentNote: ``,
      },
    ])

    setPTabs([
      ...pTabs,
      {
        tName: `Method ${pTabs.length + 1}`,
        tNumber: pTabs.length + 1,
      },
    ])
  }
  const removeTransactionType = (ind) => {
    const newPaymentMethodeTab = [...pTabs]
    if (newPaymentMethodeTab.length !== 1) {
      newPaymentMethodeTab.pop()
      setPTabs(newPaymentMethodeTab)
    }
    const newTransactionType = [...transactionTypes]
    if (newTransactionType.length != 1) {
      newTransactionType.splice(ind, 1)
      setTransactionTypes(newTransactionType)
    }
  }

  const handleTransactions = (value, name, ind, transactionId) => {
    if (ind !== undefined) {
      const newTransactionTypes = transactionTypes?.map(
        (transactionType, index) => {
          if (index === ind) {
            transactionType[name] = value
            if (transactionId) {
              transactionType.transactionId = transactionId
            }
          }
          return transactionType
        },
      )
      setTransactionTypes(newTransactionTypes)
    }
  }

  const loadPaymentMethod = () => {
    const options = {
      options: [
        { value: `Processing fee`, label: `Processing Fee` },
        { value: `MP EFT to Company`, label: `MP EFT to Company` },
      ],
      hasMore: false,
    }
    if (options.value === `Processing fee`) {
      return {
        ...options,
        isDisabled: true,
      }
    }
    return options
  }

  const loadAllCarriers = () => {
    const options = {
      options: [
        {
          value: `6540ea184696ab3cd8b59ad6`,
          label: `ANCHOR`,
        },
        { value: `6540ea1e4696ab3cd8b59b2e`, label: `NATIONAL` },
        { value: `6540ea194696ab3cd8b59ae4`, label: `ASPIRE` },
        { value: `6540ec02241e713c84967a89`, label: `KEMPER` },
        { value: `6540ea1f4696ab3cd8b59b47`, label: `NATIONS` },
        { value: `6540ea1b4696ab3cd8b59af7`, label: `BRISTOL` },
        { value: `6540ea1a4696ab3cd8b59af3`, label: `BRIDGER` },
        { value: `6540ea204696ab3cd8b59b4e`, label: `RELIANT` },
      ],
      hasMore: false,
    }
    return options
  }
  const { isLoading: loadingStripe, mutate: initiateStripe } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/payments/stripe`,
        payload,
      )
        .then((res) => {
          setInitiatedPayment(true)
          return res.data.data
        })
        .catch(() => NotificationManager.error(`Something is went wrong!`)),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        const tokenArray = res?.tokens?.map((res, ind) => ({
          clientSecret: res.token,
          _amount: res?.amount,
          isPaymentDone: false,
          transactionIndex: paymentByCreditCard[ind]?.index,
          transactionId: res?.transactionId,
        }))
        setStripeToken(tokenArray)
      },
    },
  )
  const {
    mutate,
    isLoading,
    // isError: dueError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/monthly-payments/fetch-dues`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res.data) {
          setDueAmountData(res.data?.data)
        }
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (error) => {
        setPayScreen(``)
        if (error?.response?.status == 403 || error?.response?.status == 401) {
          NotificationManager.error(`Couldn't login on APIFY`)
        } else if (error?.response?.status == 404) {
          NotificationManager.error(
            `Couldn't fetch payment details against this policy`,
          )
        } else {
          NotificationManager.error(`Something Went Wrong`)
        }
      },
      onSuccess: (res) => {
        setPayScreen(``)
        setFees([
          {
            feeType: { value: `MP EFT TO COMPANY`, label: `MP EFT TO COMPANY` },
            feeTypeError: ``,
            amount: res?.paymentInfo ? res?.paymentInfo?.dueAmount : 15,
            amountError: ``,
          },
        ])
      },
    },
  )

  useEffect(() => {
    setDueAmountData(``)
    setPayScreen(``)
    setTransactionTypes([
      {
        amount: ``,
        amountError: ``,
        paymentMethod: ``,
        paymentMethodError: ``,
        confirmRefNo: ``,
        confirmRefNoError: ``,
        paymentNote: ``,
      },
    ])
  }, [carrier])

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const resetForm = () => {
    if (!isLoading) {
      setCarrier(``)
      setPolicyNo(``)
      setDueAmountData(``)
      // setStripeToken((prev) => ({ ...prev, clientSecret: `` }))
      setErrors({
        formSubmit: false,
        policyNoError: ``,
        carrierError: ``,
      })
      setTransactionTypes([
        {
          amount: ``,
          amountError: ``,
          paymentMethod: ``,
          paymentMethodError: ``,
          confirmRefNo: ``,
          confirmRefNoError: ``,
          paymentNote: ``,
        },
      ])

      setPTabs([
        {
          tName: `Method 1`,
          tNumber: 1,
        },
      ])

      setFees([
        {
          feeType: { value: `MP EFT TO COMPANY`, label: `MP EFT TO COMPANY` },
          feeTypeError: ``,
          amount: 15,
          amountError: ``,
        },
      ])
      setPayScreen(``)
    }
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.policyNoError.length &&
      !errors.carrierError.length
    ) {
      //call API
      mutate(updatePayload())
      setDueAmountData(``)
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}

    if (policyNo?.length) {
      data.policyNo = policyNo
    }

    if (carrier?.value?.length) {
      data.carrierId = carrier?.value
      data.carrierName = carrier?.label?.toUpperCase()
    }
    return data
  }

  const ValidateForm = () => {
    if (!policyNo?.length) {
      handleErrors(`policyNoError`, `Enter Policy No`)
    } else {
      handleErrors(`policyNoError`, ``)
    }

    if (!carrier?.value?.length) {
      handleErrors(`carrierError`, `Select Carrier`)
    } else {
      handleErrors(`carrierError`, ``)
    }

    handleErrors(`formSubmit`, true)
    // to reset data for new payment
    setTransactionTypes([
      {
        amount: ``,
        amountError: ``,
        paymentMethod: ``,
        paymentMethodError: ``,
        confirmRefNo: ``,
        confirmRefNoError: ``,
        paymentNote: ``,
      },
    ])
    setPTabs([
      {
        tName: `Method 1`,
        tNumber: 1,
      },
    ])
    setFees([
      {
        feeType: { value: `MP EFT TO COMPANY`, label: `MP EFT TO COMPANY` },
        feeTypeError: ``,
        amount: 15,
        amountError: ``,
      },
    ])
  }

  const handleInvoice = (value, name, ind) => {
    if (ind !== undefined && value.value !== `Processing fee`) {
      const newFees = fees?.map((fee, index) => {
        if (index === ind) {
          fee[name] = value
        }
        return fee
      })
      setFees(newFees)
    }
    // else {
    //   setInvoice((prevState) => ({
    //     ...prevState,
    //     [name]: value,
    //   }))
    // }
  }

  const {
    mutate: mutatePayment,
    isLoading: paymentLoading,
    isError: invoiceError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/monthly-payments/create-invoice`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res.data) {
          NotificationManager.success(`Invoice Created Successfully`)
          setPayScreen(res?.data?.data?.screenshot)
          setTransactionTypes([
            {
              amount: ``,
              amountError: ``,
              paymentMethod: ``,
              paymentMethodError: ``,
              confirmRefNo: ``,
              confirmRefNoError: ``,
              paymentNote: ``,
            },
          ])
          setPTabs([
            {
              tName: `Method 1`,
              tNumber: 1,
            },
          ])
          setFees([
            {
              feeType: {
                value: `MP EFT TO COMPANY`,
                label: `MP EFT TO COMPANY`,
              },
              feeTypeError: ``,
              amount: res?.paymentInfo ? res?.paymentInfo?.dueAmount : 15,
              amountError: ``,
            },
          ])
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const transactionPayload = () => {
    let data = {
      _insured: dueAmountData?.paymentInfo?._insured,
      _policy: dueAmountData?.paymentInfo?._policy,
      _carrier: dueAmountData?.paymentInfo?._carrier,
    }

    if (policyNo?.length) {
      data.policyNo = policyNo
    }

    if (carrier?.value?.length) {
      data.carrierId = carrier?.value
      data.carrierName = carrier?.label?.toUpperCase()
    }

    if (
      transactionTypes[0].amount &&
      transactionTypes[0].paymentMethod?.value
    ) {
      data.payments = {}
    }

    data.items = fees.map((fee) => {
      return {
        feeType: fee?.feeType.value,
        amount:
          fee?.feeType.value === `MP EFT TO COMPANY`
            ? dueAmountData?.paymentInfo?.dueAmount
            : parseFloat(fee?.amount),
      }
    })

    if (
      transactionTypes[0].amount &&
      transactionTypes[0].paymentMethod?.value
    ) {
      data.payments.paymentItems = transactionTypes.map((transactionType) => {
        const paymentItem = {
          paymentMethod: transactionType?.paymentMethod?.value,
          amount: parseFloat(transactionType?.amount),
          paymentNote: transactionType?.paymentNote,
        }

        if (transactionType?.confirmRefNo) {
          paymentItem.confirmRefNo = transactionType.confirmRefNo
        }
        if (transactionType?.transactionId) {
          paymentItem.transactionId = transactionType.transactionId
        }

        return paymentItem
      })
    }
    return data
  }

  const ValidateInvoice = () => {
    let invoiceAmount = 0
    let transactionAmount = 0
    let totalFees = 0

    for (const index in fees) {
      const fee = fees[index]
      invoiceAmount = invoiceAmount + JSON.parse(fee.amount ? fee.amount : 0)
      if (!fee.amount) {
        handleFeesErrors(`amountError`, `Enter Amount`, index)
      } else if (fee.amount && JSON.parse(fee.amount) <= 0) {
        handleFeesErrors(`amountError`, `Amount must be greater than 0`, index)
      } else {
        handleFeesErrors(`amountError`, ``, index)
        totalFees += parseFloat(fee.amount)
      }
      // if (fee.amount && (fee?.amount > dueAmountData?.paymentInfo?.dueAmount)) {
      //   handleFeesErrors(
      //     `amountError`,
      //     `Payment Amount Must be less than Due amount`,
      //     index
      //   );
      // }
    }

    let transectionTotal = 0
    for (const index in transactionTypes) {
      const transactionType = transactionTypes[index]
      transactionAmount =
        transactionAmount +
        parseFloat(transactionType?.amount ? transactionType?.amount : 0)
      if (!transactionType.amount) {
        handleTransactionTypesErros(`amountError`, `Enter Amount`, index)
      } else if (
        transactionType.amount &&
        parseFloat(transactionType.amount) <= 0
      ) {
        handleTransactionTypesErros(
          `amountError`,
          `Amount must be greater than 0`,
          index,
        )
      } else {
        handleTransactionTypesErros(`amountError`, ``, index)
        transectionTotal += parseFloat(transactionType.amount)
      }
      if (!transactionType.paymentMethod?.value?.length) {
        handleTransactionTypesErros(`paymentMethodError`, `Select Type`, index)
      } else {
        handleTransactionTypesErros(`paymentMethodError`, ``, index)
      }
      if (
        !transactionType.confirmRefNo?.length &&
        transactionType?.paymentMethod?.value !== `CASH` &&
        !transactionType?.paymentMethod?.value?.includes(`CREDIT`)
      ) {
        handleTransactionTypesErros(
          `confirmRefNoError`,
          `Select Ref No.`,
          index,
        )
      } else {
        handleTransactionTypesErros(`confirmRefNoError`, ``, index)
      }
    }
    if (transectionTotal > totalFees) {
      handleTransactionTypesErros(
        `amountError`,
        `Amount must not be greater than fee amount`,
      )
    }

    handleError(`formSubmit`, true)
  }

  const handleError = (name, error) => {
    setError((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  const handleFeesErrors = (name, error, ind) => {
    if (ind !== undefined) {
      const newFees = fees?.map((fee, index) => {
        if (index == ind) {
          fee[name] = error
        }
        return fee
      })
      setFees(newFees)
    }
  }
  const handleTransactionTypesErros = (name, error, ind) => {
    if (ind !== undefined) {
      const newTransactionTypes = transactionTypes?.map(
        (transaction, index) => {
          if (index == ind) {
            transaction[name] = error
          }
          return transaction
        },
      )
      setTransactionTypes(newTransactionTypes)
    }
  }
  useEffect(() => {
    let feeFormValidated = []
    let PaymentFormValidated = []
    let isFormValidated = false
    if (error.formSubmit && !error.amountMatchError?.length) {
      isFormValidated = true
    }
    if (isFormValidated) {
      feeFormValidated = fees?.map((fee) => {
        if (!fee.feeTypeError.length && !fee.amountError.length) {
          return true
        }
      })
      PaymentFormValidated = transactionTypes?.map((transaction) => {
        if (
          !transaction.paymentMethodError.length &&
          !transaction.amountError.length &&
          !transaction.confirmRefNoError.length
        ) {
          return true
        }
      })
    }

    if (
      !feeFormValidated.includes(undefined) &&
      !PaymentFormValidated.includes(undefined) &&
      isFormValidated
    ) {
      if (paymentByCreditCard?.length) {
        const stripePayLoad = paymentByCreditCard.map((types) => ({
          amount: parseInt(
            new Intl.NumberFormat()
              .format(parseFloat(types.amount) * 100)
              .replace(`,`, ``),
            10,
          ),
          customerId: dueAmountData?.paymentInfo?._insured,
        }))

        initiateStripe(stripePayLoad)
      } else {
        mutatePayment(transactionPayload())
        handleError(`formSubmit`, false)
      }
    }
  }, [error])

  // call custom hook to get sum of fees amount
  const totalAmountToBePaid = paymentAmountTotal(fees)

  const totalRemainingAmountToBePaid = paymentAmountTotal(transactionTypes)

  const paymentByCreditCard = transactionTypes.filter((types, ind) => {
    if (
      types.paymentMethod?.value?.includes(`CREDIT`) &&
      !types?.confirmRefNo?.length
    ) {
      types.index = ind
      return types
    }
  })

  useEffect(() => {
    if (stripeToken?.length) {
      const isCreditPaymentsDone = stripeToken.every(
        (item) => item?.paymentConfirmId?.length,
      )
      const transactionTypesDone = transactionTypes.every((type) =>
        !type?.paymentMethod?.value?.includes(`CREDIT`)
          ? true
          : type?.paymentMethod?.value?.includes(`CREDIT`) &&
          type?.confirmRefNo?.length,
      )
      if (isCreditPaymentsDone && transactionTypesDone) {
        mutatePayment(transactionPayload())
      }
    }
  }, [stripeToken, transactionTypes])

  //Adding credit card processing fee tab if credit card selected
  useEffect(() => {
    //Filter all transaction having type "CREDIT_DEBIT_CARD"
    const creditPaymentMethods = transactionTypes.filter(
      (transaction) => transaction.paymentMethod.value === `CREDIT_DEBIT_CARD`,
    )

    //check if payment method is "CREDIT_DEBIT_CARD" and also check if that method is not already available,
    // then create Memo tab for processing fee
    if (
      paymentMethodType === `CREDIT_DEBIT_CARD` &&
      creditPaymentMethods.length === 1
    ) {
      setFees([
        ...fees,
        {
          feeType: { value: `Processing fee`, label: `Processing Fee` },
          feeTypeError: ``,
          amount: 15,
          amountError: ``,
        },
      ])

      setFTabs([
        ...fTabs,
        {
          tName: `Fee ${fTabs.length + 1}`,
          tNumber: fTabs.length + 1,
        },
      ])
    }

    if (paymentMethodType !== `CREDIT_DEBIT_CARD`) {
      //Check if there is any CREDIT Payment method available in any Payment tab
      const hasCreditPaymentMethod = transactionTypes.some(
        (transaction) =>
          transaction.paymentMethod.value === `CREDIT_DEBIT_CARD`,
      )

      //Check if Processing Fee tab is available
      const hasProcessingFeeTab = fees.some(
        (fee) => fee.feeType.value === `Processing fee`,
      )

      //Now If there is No CREDIT payment method available and if the Processing fee tab available, then remove Processing Fee tab
      if (!hasCreditPaymentMethod && hasProcessingFeeTab) {
        // Remove the object with feeType: { value: `MP EFT TO COMPANY`, label: `MP EFT TO COMPANY` }
        setFees((prevFees) =>
          prevFees.filter(
            (fee) =>
              fee.feeType.value !== `Processing fee` &&
              fee.feeType.label !== `Processing Fee`,
          ),
        )
        //Remove Processing fee tab
        const newFeesTab = [...fTabs]
        if (newFeesTab.length !== 1) {
          newFeesTab.pop()
          setFTabs(newFeesTab)
        }
      }
    }
  }, [paymentMethodType, pTabs])

  return (
    <>
      <Container>
        <PageHeader>
          <div>Transaction Inspection</div>
        </PageHeader>
        {/* form to fetch payment details */}
        <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md pb-2">
          <div className="flex gap-6 pt-2">
            <div className="flex-1 relative">
              <ReactSelectField
                title="Carrier"
                placeholder="Select Carrier"
                errorMessage={errors.carrierError}
                value={carrier}
                setValue={(e) => setCarrier(e)}
                isMulti={false}
                loadOptions={loadAllCarriers}
                isSearchable={true}
              // operatingState={user?.profile?.operatingState}
              />
            </div>
            <div className="flex-1 relative">
              <InputField
                type="text"
                title="Policy No"
                placeholder="Enter Policy No"
                errorMessage={errors.policyNoError}
                value={policyNo}
                setValue={(e) => setPolicyNo(e.target.value)}
              />
            </div>

            <div className="relative">
              <StyledButton
                onClick={() => {
                  ValidateForm()
                  setDueAmountData(``)
                }}
                disabled={isLoading || paymentLoading}
              >
                <div className="flex gap-2 text-white">
                  <span>Submit</span>
                  {isLoading && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin={true}
                      className="my-auto"
                    />
                  )}
                </div>
              </StyledButton>
            </div>
            <div
              className="relative mt-7 tooltip"
              data-tip="Reset"
              onClick={resetForm}
            >
              <span
                className={`${!isLoading ? `bg-red-500` : `bg-gray-400`
                  } px-2 py-1.5 cursor-pointer rounded-md`}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  className="my-auto text-white transform transition duration-100 rotate-45"
                />
              </span>
            </div>
          </div>
          {stripeToken?.length > 0 &&
            stripeToken.map((token, index) => (
              <div key={index} className="flex">
                <Elements
                  stripe={stripePromise}
                  options={token}
                  key={token?.clientSecret}
                >
                  <StripeForm
                    openModal={!token.isPaymentDone}
                    setOpenModal={setStripeToken}
                    setIsPaymentDone={setIsPaymentDone}
                    stripeTokenIndex={index}
                    stripeAmount={token?._amount}
                    transactionId={token?.transactionId}
                    setValue={(e) => {
                      // set confirmRefNo w.r.t its index store when generating tokens

                      if (e) {
                        handleTransactions(
                          e,
                          `confirmRefNo`,
                          token?.transactionIndex,
                          token?.transactionId,
                        )
                      } else {
                        handleErrors(`formSubmit`, false)
                      }
                    }}
                    setConfirmStripeLoader={setConfirmStripeLoader}
                  />
                </Elements>
              </div>
            ))}
        </div>
      </Container>

      {payScreen ? (
        <div className="flex justify-center items-center mt-5">
          <img src={payScreen} alt="img" width={800} height={800} />
        </div>
      ) : (
        dueAmountData && (
          <Container>
            {!dueAmountData.hasDueAmount ? (
              <div className="text-center p-8 text-sm">
                No due amount against this policy no
              </div>
            ) : (
              <>
                {invoiceError && (
                  <ReactNotification
                    action="error"
                    message="Something went wrong"
                  />
                )}

                <div
                  className={`p-3 bg-white ${(paymentLoading || loadingStripe || confirmStripeLoader) &&
                    `opacity-30 pointer-events-none`
                    } `}
                >
                  <PageHeader>
                    <div>Payment Detail</div>
                  </PageHeader>

                  <div className="my-3 px-5">
                    {dueAmountData?.hasDueAmount && (
                      <div
                        className={`badge outline-none border-0  bg-green-400 `}
                        badge={true}
                      >
                        {dueAmountData?.hasDueAmount && `Pending Amount`}
                      </div>
                    )}
                    {dueAmountData?.isPolicyLapsed && (
                      <div
                        className={`badge outline-none border-0 bg-red-500 ml-14`}
                        badge={true}
                      >
                        {dueAmountData?.isPolicyLapsed && `Lapsed Policy`}
                      </div>
                    )}
                    {dueAmountData?.isCanceled && (
                      <div
                        className={`badge outline-none border-0  bg-yellow-400 ml-14`}
                        badge={true}
                      >
                        {dueAmountData?.isCanceled && `Cancelled`}
                      </div>
                    )}
                  </div>

                  <div className="px-4">
                    <div className="grid grid-cols-2">
                      <CardField
                        title="Insured Name"
                        value={dueAmountData?.paymentInfo?.insuredName}
                      />
                    </div>
                    <div className="grid grid-cols-2">
                      <CardField
                        title="Due Amount"
                        value={`$ ` + dueAmountData?.paymentInfo?.dueAmount}
                      />
                      {/* <div className="px-14">
                      <StyledButton
                        // disabled={
                        //   !paidAmount ||
                        //   isLoading ||
                        //   paidAmount > dueAmountData?.paymentInfo?.dueAmount
                        // }
                        disabled={isLoading}
                        onClick={() =>
                          initiateStripe({
                            amount: parseInt(
                              new Intl.NumberFormat()
                                .format(
                                  dueAmountData?.paymentInfo?.dueAmount * 100,
                                )
                                .replace(`,`, ``),
                              10,
                            ),
                            // cardNumber: `4242 4242 4242 4242`,
                            // expiryDate: `12/34`,
                            // cvc: 123
                          })
                        }
                      >
                        <div className="flex gap-2 text-white">
                          <span className="px-2">Pay</span>
                          {loadingStripe && (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin={true}
                              className="my-auto"
                            />
                          )}
                        </div>
                      </StyledButton>
                    </div> */}
                    </div>

                    <>
                      <SecondaryHeading>Fees</SecondaryHeading>
                      {/* tabs here */}
                      <div className="flex items-center">
                        <TabsHeaders
                          openTab={activeFeeTab}
                          tabs={fTabs}
                          getActiveTab={(val) => setActiveFeeTab(val)}
                          customTabs="YES"
                        />
                        <div className="px-2">
                          <AddFormButton
                            onClick={() => {
                              addFee()
                            }}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </AddFormButton>
                        </div>
                      </div>
                      {/* tabs end herer */}
                      {fees?.length > 0 &&
                        fees?.map((item, index) => (
                          <FormTab
                            key={index}
                            currenttab={index + 1}
                            opentab={activeFeeTab}
                            className="border-2 border-gray-100 shadow-sm rounded-md relative"
                          >
                            <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                              <div className="flex-1">Fee {index + 1}</div>
                              <div className="flex-1">
                                <div className="float-right">
                                  <RemoveDriver
                                    onClick={() => {
                                      if (
                                        index !== 0 &&
                                        item.feeType.value !== `Processing fee`
                                      ) {
                                        removeFee(index)
                                        if (index > 0) setActiveFeeTab(index)
                                      }
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTimes} size="1x" />
                                  </RemoveDriver>
                                </div>
                              </div>
                            </div>

                            <div className="grid grid-cols-12 px-2 pb-4">
                              <div className="col-span-12 flex gap-6 ">
                                <div className="flex-1 relative">
                                  <ReactSelectField
                                    title="Memos Type*"
                                    placeholder="Select Type"
                                    value={item.feeType}
                                    errorMessage={item.feeTypeError}
                                    setValue={(e) => {
                                      handleInvoice(e, `feeType`, index)
                                    }}
                                    isMulti={false}
                                    loadOptions={loadPaymentMethod}
                                    isSearchable={true}
                                    disabled={
                                      index === 0 ||
                                      item.feeType.value === `Processing fee`
                                    }
                                  />
                                </div>
                                <div className="flex-1 relative">
                                  <InputField
                                    type="number"
                                    title="Amount*"
                                    placeholder="Enter Amount"
                                    value={`${index === 0
                                        ? dueAmountData?.paymentInfo?.dueAmount
                                        : item?.amount
                                      }`}
                                    errorMessage={item.amountError}
                                    setValue={(e) =>
                                      handleInvoice(
                                        e.target.value,
                                        `amount`,
                                        index,
                                      )
                                    }
                                    disabled={
                                      index === 0 ||
                                      item.feeType.value === `Processing fee`
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </FormTab>
                        ))}
                      <div className="col-span-6 float-right ">
                        {totalAmountToBePaid ? (
                          <div>Total memos amount: ${totalAmountToBePaid}</div>
                        ) : null}
                        {/* </div> */}
                      </div>
                      <div className="px-2">
                        <SecondaryHeading>Payment Methods</SecondaryHeading>
                        {/* tabs here */}
                        <div className="flex items-center">
                          <TabsHeaders
                            openTab={activePaymentMethodeTab}
                            tabs={pTabs}
                            getActiveTab={(val) => {
                              setActivePaymentMethodeTab(val)
                              setPaymentMethodType(``)
                            }}
                            customTabs="YES"
                          />
                          <div className="px-2">
                            <AddFormButton
                              onClick={() => {
                                addTransactionType()
                                setPaymentMethodType(``)
                              }}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </AddFormButton>
                          </div>
                        </div>
                        {/* tabs end herer */}
                        {transactionTypes?.length > 0 &&
                          transactionTypes?.map((item, index) => (
                            <FormTab
                              key={index}
                              currenttab={index + 1}
                              opentab={activePaymentMethodeTab}
                              className="border-2 border-gray-100 shadow-sm rounded-md relative"
                            >
                              <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                                <div className="flex-1">
                                  Payment Method {index + 1}
                                </div>
                                <div className="flex-1">
                                  {!item?.confirmRefNo && !item?.transactionId && (
                                    <div className="float-right">
                                      <RemoveDriver
                                        onClick={() => {
                                          if (!item?.disabled) {
                                            removeTransactionType(index)
                                            setPaymentMethodType(``)
                                            if (index > 0)
                                              setActivePaymentMethodeTab(index)
                                          }
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          size="1x"
                                        />
                                      </RemoveDriver>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="grid grid-cols-12 px-2 pb-4">
                                <div className="col-span-12  flex gap-6 ">
                                  <div className="flex-1 relative">
                                    <ReactSelectField
                                      title="Payment Method *"
                                      placeholder="Select Method"
                                      value={item?.paymentMethod}
                                      setValue={(e) => {
                                        handleTransactions(
                                          e,
                                          `paymentMethod`,
                                          index,
                                        )
                                        setPaymentMethodType(e.value)
                                      }}
                                      errorMessage={item?.paymentMethodError}
                                      isMulti={false}
                                      loadOptions={loadPaymentMethods}
                                      isSearchable={true}
                                    />
                                  </div>
                                  <div className="flex-1 relative">
                                    <InputField
                                      type="number"
                                      title="Paid Amount * "
                                      placeholder="Enter Amount"
                                      value={item?.amount}
                                      errorMessage={item?.amountError}
                                      setValue={(e) =>
                                        handleTransactions(
                                          e.target.value,
                                          `amount`,
                                          index,
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="flex-1 relative">
                                    <InputField
                                      type="text"
                                      title={`${item?.paymentMethod?.label == `CASH` ||
                                          item?.paymentMethod?.label?.includes(
                                            `CREDIT`,
                                          )
                                          ? `Confirm Ref No`
                                          : `Confirm Ref No *`
                                        }`}
                                      placeholder="Enter Number"
                                      value={item?.confirmRefNo}
                                      errorMessage={item?.confirmRefNoError}
                                      setValue={(e) =>
                                        handleTransactions(
                                          e.target.value,
                                          `confirmRefNo`,
                                          index,
                                        )
                                      }
                                      maxLength={10}
                                      // disabled for CREDIT CARD
                                      disabled={item?.paymentMethod?.label?.includes(
                                        `CREDIT`,
                                      )}
                                    />
                                  </div>

                                  <div className="flex-1 relative">
                                    <InputField
                                      type="text"
                                      title="Payment Note"
                                      placeholder="Enter..."
                                      value={item?.paymentNote}
                                      setValue={(e) =>
                                        handleTransactions(
                                          e.target.value,
                                          `paymentNote`,
                                          index,
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </FormTab>
                          ))}
                        <div className="col-span-6 float-right ">
                          {totalAmountToBePaid ? (
                            <div>
                              Remaining amount: $
                              {(
                                totalAmountToBePaid -
                                totalRemainingAmountToBePaid
                              ).toFixed(2)}
                            </div>
                          ) : null}
                          {/* </div> */}
                        </div>
                      </div>
                      <Spinner
                        loading={
                          paymentLoading || loadingStripe || confirmStripeLoader
                        }
                      />

                      <div className="flex justify-center mt-10">
                        <div className="text-center w-96 my-4">
                          <SubmitButton
                            onClick={ValidateInvoice}
                            disabled={
                              paymentLoading ||
                              loadingStripe ||
                              confirmStripeLoader
                            }
                          >
                            <div className="flex gap-2 text-white">
                              <span>Create Invoice</span>
                              {(paymentLoading ||
                                loadingStripe ||
                                confirmStripeLoader) && (
                                  <FontAwesomeIcon
                                    icon={faSpinner}
                                    spin={true}
                                    className="my-auto"
                                  />
                                )}
                            </div>
                          </SubmitButton>
                        </div>
                      </div>
                    </>

                    <div className="flex justify-center items-center mt-5">
                      <img
                        src={`data:image;base64, ${dueAmountData?.paymentInfo?.screenshot}`}
                        alt="img"
                        width={800}
                        height={800}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </Container>
        )
      )}
    </>
  )
}
