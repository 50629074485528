import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from '../style'
import { PolicyReportPdfHeader } from 'App/Components/Common/PolicyReportPdfHeader'
import dateFormat from 'App/Services/General/dateFormat'

export const Pdf = ({ insured, dateRange = [] }) => {
  return (
    <Document subject="Report" author="Reports">
      <Page style={styles.page} size="A3" wrap>
        <View style={styles.section}>
          <Text style={styles.pageHeading}>Clients by Birth Report</Text>
          <Text style={styles.smallSpace}></Text>
          <PolicyReportPdfHeader dateRange={dateRange} />
          <Text style={styles.smallSpace}></Text>

          <View style={styles.headingRow}>
            <Text style={styles.pageTitles}> First Name</Text>
            <Text style={styles.pageTitles}> Last Name</Text>
            <Text style={styles.pageTitles}> Phone</Text>
            <Text style={styles.pageTitles}> Birthday</Text>
            <Text style={styles.pageTitles}> Email</Text>
          </View>

          {insured?.length > 0 &&
            insured?.map((item, index) => (
              <View style={styles.agent} key={index}>
                <View style={styles.headingRow2}>
                  <Text style={styles.pageTitles2}>{item?.firstName}</Text>
                  <Text style={styles.pageTitles2}>{item?.lastName}</Text>
                  <Text style={styles.pageTitles2}>{item?.phone}</Text>
                  <Text style={styles.pageTitles2}>
                    {dateFormat(item?.birthday)}
                  </Text>
                  <Text style={styles.pageTitles2}>{item?.email}</Text>
                </View>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  )
}
