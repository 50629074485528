/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import { useDebouncedCallback } from 'use-debounce/lib'
// import { PdfTron } from 'App/Services/PdfTron'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { Reload } from 'App/Components/Common/Reload'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { useSelector } from 'react-redux'
import { permissionGranted } from 'App/Services'
import { TableHeader, TableBody, TableRow, TableCell } from '@windmill/react-ui'
import {
  faPlusCircle,
  faSortDown,
  faSortUp,
  faFilePdf,
  faTrash,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { PdfFieldsMapModal } from 'App/Components/Common/PdfFieldsMapModal'
import { PdfModal } from 'App/Components/Common/PdfTronModal'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadOffset } from 'App/Services/General/loadOffset'
import dateFormat from 'App/Services/General/dateFormat'
const tableColumns = [
  { label: `Name`, value: `filename` },
  { label: `Type`, value: `type` },
  { label: `Created At`, value: `createdAt` },
  { label: `Uploaded At`, value: `uploadedAt` },
]

export const AllTemplates = () => {
  const fileref = useRef(null)
  const [instance, setInstance] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [popUp, setPopUp] = useState(false)
  const [modalValue, setModalValue] = useState(``)
  const [anotationList, setanotationList] = useState([])
  const [isUpdatingFile, setIsUpdatingFile] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedAnnotation, setSelectedAnnotation] = useState(false)
  const [isModifyingAnot, setIsModifyingAnot] = useState(``)

  const [fileLoaded, setFileLoaded] = useState(false)
  const [hasErrors, sethasErrors] = useState(false)
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)
  const [customSearching, setCustomSearching] = useState(false)
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const { profile } = useSelector(({ user: { user } }) => user)

  const handleClosePopUp = () => {
    setPopUp(false)
  }

  const openFile = () => {
    setFileLoaded(false)
    fileref.current.click()
  }

  const closeFile = () => {
    setFileLoaded(false)
    setShowModal(false)
    setPopUp(false)
  }
  useEffect(() => {
    if (instance) {
      const { documentViewer, annotationManager } = instance.Core
      documentViewer.addEventListener(`documentLoaded`, async () => {
        setFileLoaded(true)

        annotationManager.addEventListener(
          `annotationChanged`,
          (anot, action, { imported }) => {
            if (imported) {
              anot.forEach((eachWid) => {
                if (eachWid.fieldName === undefined) return
                if (
                  eachWid?.fieldName === `empty` ||
                  eachWid?.fieldName.includes(`TextFormField`)
                  // eachWid?.fieldName.includes(`SignatureFormField`)
                ) {
                  instance.Core.annotationManager.deleteAnnotation(eachWid)
                }
              })
              return
            }

            if (anot[0]?.ToolName === `SignatureFormFieldCreateTool`) {
              setSelectedAnnotation(anot[0])
              setIsModifyingAnot(`adding`)
              setModalValue(`signature-` + anot[0]?.Hh[`trn-form-field-name`])
              return
            }

            if (
              anot[0]?.ToolName === `ComboBoxFormFieldCreateTool` ||
              anot[0]?.ToolName === `ListBoxFormFieldCreateTool`
            ) {
              instance.UI.enableElements([
                `formFieldEditButton`,
                `formFieldEditPopup`,
              ])
            } else {
              instance.UI.disableElements([
                `formFieldEditButton`,
                `formFieldEditPopup`,
              ])
            }
            if (action === `add`) {
              if (
                !imported &&
                anot.length === 1 &&
                anot[0].Subject !== `Signature` &&
                anot[0].ToolName !== `ComboBoxFormFieldCreateTool` &&
                anot[0].ToolName !== `ListBoxFormFieldCreateTool` &&
                anot[0].Author !== undefined
              ) {
                setSelectedAnnotation(anot[0])
                setIsModifyingAnot(`adding`)
                setPopUp(true)
              }
            }
          },
        )

        annotationManager.addEventListener(
          `annotationSelected`,
          (annotations, action) => {
            if (action === `selected`) {
              if (annotations[0]?.ToolName === `SignatureFormFieldCreateTool`)
                return

              if (
                annotations[0]?.ToolName === `ComboBoxFormFieldCreateTool` ||
                annotations[0]?.ToolName === `ListBoxFormFieldCreateTool`
              ) {
                setSelectedAnnotation(annotations[0])
                setIsModifyingAnot(`adding`)
                setPopUp(true)
                return
              }
              setSelectedAnnotation(annotations[0])
              setIsModifyingAnot(`updating`)
              setPopUp(true)
            }
          },
        )
      })
    }
  }, [instance, isReadOnly])

  const blobToBase64 = (blob) => {
    return new Promise((resolve) => {
      const fileReader = new FileReader()

      fileReader.readAsDataURL(blob)
      fileReader.readAsArrayBuffer
      fileReader.onloadend = function () {
        resolve(fileReader.result)
      }
    })
  }
  const handleSaveFile = async () => {
    if (instance && instance.Core) {
      const manager = instance.Core.annotationManager.getAnnotationsList()

      anotationList.forEach((anottt) => {
        const fieldManager = instance.Core.annotationManager.getFieldManager()
        manager.forEach((widget) => {
          if (widget.fieldName === anottt.name) {
            const selectedAnnots = fieldManager.getField(widget?.fieldName)
            const isUpdated = fieldManager.updateFieldName(
              selectedAnnots,
              anottt.value,
            )
          }
        })
      })

      const doc = instance.Core.documentViewer.getDocument()
      const xfdfString =
        await instance.Core.annotationManager.exportAnnotations()
      const options = {
        xfdfString,
        downloadType: `pdf`,
      }
      const data = await doc.getFileData(options)
      const array = new Uint8Array(data)
      let blob = new Blob([array], { type: `application/pdf` })
      const file = new File([blob], selectedFile?.name, { type: blob.type })

      const base64File = await blobToBase64(blob)

      if (isUpdatingFile !== null) {
        updateFilesMutation({
          file: base64File,
          path: isUpdatingFile.path,
          fileName: isUpdatingFile.filename,
        })
      } else {
        fileUploadMutation({
          file: base64File,
          fileName: selectedFile?.name?.split(`.`)[0],
          path: `${profile._franchise?._id}/Files`,
        })
      }
      setIsUpdatingFile(null)
    }
  }

  const updateFieldValue = () => {
    if (!modalValue?.length || modalValue === `empty`) {
      return
    }
    const getFieldName = selectedAnnotation?.Hh
    // const fieldName = Object.values(getFieldName)
    const fieldName = Object.entries(getFieldName).find((e) =>
      e.includes(`trn-form-field-name`),
    )
    if (isModifyingAnot === `adding`) {
      setanotationList([
        ...anotationList,
        { id: selectedAnnotation?.Id, name: fieldName[1], value: modalValue },
      ])
    }
    if (isModifyingAnot === `updating`) {
      const anotarray = anotationList?.filter((anot) => {
        if (anot.id === selectedAnnotation.Id) {
          anot[`name`] = fieldName[1]
          anot[`value`] = modalValue
        }
        return anot
      })

      setanotationList(anotarray)
    }
  }

  const {
    mutate: fileUploadMutation,
    isLoading: fileUploading,
    isError: fileUploadError,
    error: fileUploadErrorMessage,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/cloudinary/upload`, {
        ...payload,
      }).then((res) => {
        if (res.hasError) {
          sethasErrors(true)
          setShowModal(false)
        } else {
          getAllTemplates()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const {
    refetch: getAllTemplates,
    data: allTemplates,
    isLoading: gettingFiles,
    isError: gettingFilesError,
    isFetching: templatesFetching,
  } = useQuery(
    `TemplatessApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/cloudinary/folder?path=${
          profile._franchise?._id
        }/Files&search={"query": "${
          searchQuery.length ? searchQuery : ``
        }"}&sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      )
        .then((res) => {
          if (!res.hasError) {
            setShowModal(false)
            setCustomSearching(false)
            return res.data.response
          }
        })
        .catch((err) => {
          console.log(`error`, err)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const {
    mutate: deleteFilesMutation,
    isLoading: deletingFiles,
    isError: deletionError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/cloudinary/delete`, {
        ...payload,
      })
        .then(() => {
          getAllTemplates()
        })
        .catch(() => {
          sethasErrors(true)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const { mutate: updateFilesMutation, isLoading: updatingFiles } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/cloudinary/update`, {
        ...payload,
      })
        .then(() => {
          getAllTemplates()
        })
        .catch(() => {
          sethasErrors(true)
        }),
    {
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (!popUp && modalValue != ``) {
      updateFieldValue()
    }
  }, [popUp, modalValue, isModifyingAnot])

  useEffect(() => {
    if (instance && selectedFile !== `` && selectedFile != null) {
      setShowModal(true)
      instance.UI.loadDocument(selectedFile, { filename: `myfile.pdf` })
    }
  }, [instance, selectedFile])

  useEffect(() => {
    if (!customSearching) {
      getAllTemplates()
    }
  }, [sort, currentPage, isOffset])

  const callTemplateQuery = useDebouncedCallback(() => {
    getAllTemplates()
  }, 700)

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  return (
    <div>
      <Container>
        {(deletionError || fileUploadError || hasErrors) && (
          <ReactNotification
            action="error"
            message={fileUploadErrorMessage?.response?.data?.error?.error}
          />
        )}
        <PageHeader bgcolor="bg-white">
          <div>Templates</div>
          <div className="flex items-center ">
            <div className="py-1 px-2">
              <ReactSelectField
                placeholder="10"
                value={isOffset}
                setValue={(e) => {
                  setCurrentPage(1)
                  setIsOffset(e)
                }}
                isMulti={false}
                loadOptions={loadOffset}
              />
            </div>
            <div className="text-end ">
              <SearchInputField
                type="text"
                placeholder="Type here..."
                value={searchQuery}
                setValue={(e) => {
                  setSearchQuery(e.target.value)
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callTemplateQuery()
                }}
                disabled={templatesFetching}
              />
            </div>
          </div>
        </PageHeader>

        <input
          type="file"
          name="file"
          ref={fileref}
          className="hidden"
          onChange={(e) => {
            setSelectedFile(e.target.files[0])
            setShowModal(true)
            e.target.value = null
          }}
          accept="application/pdf"
        />
        {permissionGranted([
          `pdf-templates:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <div className="flex justify-end items-center p-2">
            <StyledButton onClick={() => openFile()}>
              <div className="flex gap-2 text-white">
                <span>Add </span>
                <FontAwesomeIcon icon={faPlusCircle} size="lg" />
              </div>
            </StyledButton>
          </div>
        )}

        <Spinner
          loading={
            deletingFiles ||
            fileUploading ||
            updatingFiles ||
            gettingFiles ||
            templatesFetching
          }
        />
        <div
          className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
            (deletingFiles ||
              fileUploading ||
              updatingFiles ||
              gettingFiles ||
              templatesFetching) &&
            ` opacity-30 pointer-events-none`
          }  `}
        >
          {gettingFilesError ? (
            <Reload refetch={() => getAllTemplates()} />
          ) : (
            <>
              <div className="overflow-x-auto  rounded-t-md">
                <table className="w-full border-collapse">
                  <TableHeader>
                    <TableRow>
                      {tableColumns.map((item, idx) => {
                        return (
                          <TableCell key={idx}>
                            <div
                              className={`${
                                sort.activeSort === item.label
                                  ? `text-red-500`
                                  : ``
                              } cursor-pointer select-none text-sm flex truncate`}
                            >
                              <div
                                onClick={() => {
                                  handleSorting(item.value)
                                }}
                                className={`flex ${
                                  item.value === sort ? `text-red-500` : ``
                                }`}
                              >
                                {item.label}
                              </div>
                              <div
                                className="ml-2 px-1 rounded-sm cursor-pointer flex"
                                onClick={() => handleSorting(`-` + item.value)}
                              >
                                <div
                                  className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                                  onClick={() =>
                                    handleSorting(`-` + item.value)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      sort.includes(`-`) &&
                                      sort == `-` + item.value
                                        ? faSortUp
                                        : faSortDown
                                    }
                                    size="lg"
                                    className={`${
                                      sort.includes(`-`) &&
                                      sort == `-` + item.value
                                        ? `text-red-500`
                                        : `-mt-1.5`
                                    }`}
                                  />
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        )
                      })}
                      {(permissionGranted([
                        `pdf-templates:update`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ]) ||
                        permissionGranted([
                          `pdf-templates:read`,
                          `corporate-manager:*`,
                          `manager:*`,
                          `admin:*`,
                        ])) && (
                        <TableCell className="text-sm float-right">
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHeader>

                  <TableBody>
                    {!gettingFiles && !allTemplates?.docs?.length ? (
                      <tr>
                        <td colSpan="12">
                          <div className="w-full text-center p-6  ">
                            <p>No File Found</p>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      allTemplates?.docs?.map((fileObj, index) => (
                        <TableRow
                          key={index}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            if (
                              permissionGranted([
                                `pdf-templates:update`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ])
                            ) {
                              setIsUpdatingFile({
                                path: fileObj.folder,
                                filename: fileObj.filename,
                              })
                              setShowModal(true)
                              setSelectedFile(fileObj?.secure_url)
                              setIsReadOnly(false)
                            }
                          }}
                        >
                          <td className="px-4 py-0.5 text-sm capitalize truncate">
                            {fileObj?.filename?.toLowerCase()}
                          </td>
                          <td className="px-4 py-0.5 text-sm">
                            {fileObj?.format.toString() === `pdf` ? (
                              <span>
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  className="text-red-500"
                                  size="lg"
                                />
                              </span>
                            ) : (
                              ``
                            )}
                          </td>

                          <td className="px-4 py-0.5 text-sm truncate">
                            {dateFormat(fileObj.createdAt)}
                          </td>
                          <td className="px-4 py-0.5 text-sm truncate">
                            {dateFormat(fileObj.uploaded_at)}
                          </td>

                          {/* Actions TD */}
                          <td className="px-6 py-0.5 text-sm z-0 flex float-right text-gray-400">
                            {permissionGranted([
                              `pdf-templates:update`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ]) && (
                              <span data-tip="Update" className="tooltip">
                                <FontAwesomeIcon
                                  className=" mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer "
                                  icon={faEdit}
                                  onClick={() => {
                                    setIsUpdatingFile({
                                      path: fileObj.folder,
                                      filename: fileObj.filename,
                                    })
                                    setShowModal(true)
                                    setSelectedFile(fileObj?.secure_url)
                                    setIsReadOnly(false)
                                  }}
                                />
                              </span>
                            )}
                            {permissionGranted([
                              `pdf-templates:delete`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ]) && (
                              <span data-tip="Delete" className="tooltip">
                                <FontAwesomeIcon
                                  className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                  icon={faTrash}
                                  size="1x"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    deleteFilesMutation({
                                      path: fileObj.folder,
                                      files: [fileObj.public_id],
                                    })
                                  }}
                                />
                              </span>
                            )}
                          </td>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </table>
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  offset={isOffset?.value ? isOffset?.value : offset}
                  currentPage={currentPage}
                  totalPages={allTemplates ? allTemplates?.totalPages : 0}
                  totalRecords={allTemplates ? allTemplates?.totalRecords : 0}
                  curerntPageRecords={
                    allTemplates ? allTemplates?.currentPageRecords : 0
                  }
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          )}
        </div>
      </Container>

      <Container>
        <div>
          <PdfModal
            showModal={showModal}
            setShowModal={closeFile}
            saveFile={handleSaveFile}
            isFileLoaded={fileLoaded}
            isReadOnly={isReadOnly}
            updatingApi={
              gettingFiles ||
              fileUploading ||
              updatingFiles ||
              templatesFetching
            }
            disableSaveButton={false}
          >
            {popUp && (
              <PdfFieldsMapModal
                setModalValue={setModalValue}
                handleClose={handleClosePopUp}
              />
            )}
            {/* <PdfTron handleInstance={setInstance} /> */}
          </PdfModal>
        </div>
      </Container>
    </div>
  )
}
