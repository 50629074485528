import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { useMutation, useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { TimeAgo } from 'App/Components/Common/TimeAgo'
import { TextArea } from 'App/Components/Common/TextArea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonSecondary, StyledButton } from 'App/Styled'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import {
  faSpinner,
  faEdit,
  faTrash,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { permissionGranted } from 'App/Services'

export const ContactNote = () => {
  const [note, setNote] = useState(``)
  const [activeNote, setActiveNote] = useState(null)
  const [notification, setNotfication] = useState(false)
  const [editableNote, setEditableNote] = useState(null)
  const [editing, setEditing] = useState(``)
  const [errors, setErrors] = useState({
    formSubmit: false,
    noteError: ``,
  })

  const {
    params: { contactId },
  } = useRouteMatch()

  // Fetching All agent Notes
  const {
    data: agentNoteData,
    isLoading,
    isFetching,
    refetch: getAllANotes,
    error,
  } = useQuery(
    `ContactNoteApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/contacts/${contactId}/notes`,
      ).then((res) => {
        return res.data.data.notes.reverse()
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  //  add note
  const { isLoading: addNoteLoading, mutate: addNote } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/contacts/${contactId}/notes`,
        {
          ...payload,
        },
      ).then((res) => {
        setNote(``)
        res.data && getAllANotes()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  //edit Note
  const { isLoading: updateNoteLoading, mutate: updateNote } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/contacts/${contactId}/notes/${payload.id}`,
        {
          note: payload.note,
        },
      ).then((res) => {
        if (!res.hasError) {
          setEditing(false)
          setNote(``)
          res.data && getAllANotes()
        } else {
          setNotfication(true)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  // delete Note
  const { isLoading: deletingNote, mutate: deleteNote } = useMutation(
    async (payload) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/contacts/${contactId}/notes/${payload}`,
        {
          note: payload.note,
        },
      ).then((res) => {
        setNote(``)
        res.data && getAllANotes()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validateForm = () => {
    if (!note.length) {
      handleErrors(`noteError`, `Enter Note`)
    } else if (note.length && note.length > 150) {
      handleErrors(`noteError`, `Note length must be less than 150 characters`)
    } else {
      handleErrors(`noteError`, ``)
    }
    handleErrors(`formSubmit`, true)
  }

  const resetNote = () => {
    setEditing(false)
    setNote(``)
  }

  useEffect(() => {
    if (errors.formSubmit && !errors.noteError.length) {
      if (editing) {
        updateNote({
          id: editableNote._id,
          note: note,
        })
      } else {
        addNote({ note })
      }
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  return (
    <div className="mt-2 h-420 overflow-y-scroll hide-scroller hide-scroller-firefox">
      {notification && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <TextArea
        id="note"
        rows={5}
        value={note}
        placeholder="Add Note"
        errorMessage={errors.noteError}
        setValue={(e) => setNote(e.target.value)}
      />
      <div className="w-full flex justify-end">
        <StyledButton onClick={validateForm}>
          <div className="flex gap-2 text-white">
            {editing ? <span>Update Note </span> : <span>Add Note </span>}
            {(addNoteLoading || updateNoteLoading) && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                className="my-auto"
              />
            )}
          </div>
        </StyledButton>
        <ButtonSecondary onClick={() => resetNote()}>
          <span>Cancel</span>
        </ButtonSecondary>
      </div>
      {isLoading || isFetching ? (
        <div className="text-center py-10">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => getAllANotes()} />
      ) : (
        agentNoteData?.length > 0 &&
        agentNoteData?.map((note, index) => {
          const editedIndex = activeNote === index
          return (
            <div className="grid grid-rows-2 mx-auto rounded-xl" key={index}>
              <div className="flex flex-row">
                <div className="flex flex-row  items-center justify-center">
                  <span className="font-semibold text-indigo-500 text-sm">
                    {note._creator?.label}
                  </span>
                  {/* <span
                    className={`py-0.5 px-1 mx-1 flex-shrink text-sm rounded-md text-white bg-indigo-500`}
                  >
                    Roles
                  </span> */}
                  <span className=" mx-1 text-xs flex items-center">
                    <TimeAgo timeStamp={note.createdAt} />
                  </span>
                </div>
              </div>

              <div className="relative  py-2 rounded-sm  shadow-sm  px-4 flex flex-row justify-between flex-wrap text-gray-600 bg-blue-50 dark:text-white">
                <FontAwesomeIcon
                  className=" absolute  text-blue-50 -top-5 left-0"
                  size="2x"
                  icon={faCaretUp}
                />
                <div>
                  <span className="font-bold text-indigo-500 text-lg">“</span>
                  {note.note}
                  <span className="font-bold text-indigo-500 text-lg">”</span>
                </div>
                <div
                  onClick={() => setActiveNote(index)}
                  className="cursor-pointer"
                >
                  <div className="flex float-right">
                    {permissionGranted([`notes:update`]) && (
                      <span data-tip="Edit" className="tooltip">
                        <FontAwesomeIcon
                          className=" mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer "
                          icon={faEdit}
                          onClick={() => {
                            handleErrors(`formSubmit`, false)
                            setEditing(true)
                            setNote(note.note)
                            setEditableNote(note)
                          }}
                        />
                      </span>
                    )}
                    {permissionGranted([`notes:delete`]) && (
                      <span data-tip="Delete" className="tooltip">
                        <FontAwesomeIcon
                          className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 
                        hover:scale-105 
                        cursor-pointer "
                          icon={
                            editedIndex && deletingNote ? faSpinner : faTrash
                          }
                          spin={editedIndex && deletingNote}
                          size="1x"
                          onClick={() => deleteNote(note._id)}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}
