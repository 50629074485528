import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import { ReactModal } from 'App/Components/Common/Modal'
import FileSaver from 'file-saver'
import React from 'react'

export const PreviewFile = ({ preview, setPreview }) => {
  return preview.url ? (
    <>
      {preview.format === `pdf` ? (
        <ReactModal
          title="File"
          closeModal={() => setPreview(false)}
          disabled={true}
          loading={false}
          callMutation={() => {
            FileSaver.saveAs(preview.url, preview.fileName)
          }}
          icon={faArrowCircleDown}
          toolTip="Save File"
          className="p-2"
          width="screen"
          maxWidth="7xl"
        >
          <div className="items-center mx-auto my-0">
            <object
              width="1040"
              height="650"
              data={preview.url}
              // type="application/pdf"
            >
              Pdf Not Found
            </object>
          </div>
        </ReactModal>
      ) : (
        <ReactModal
          title="Image"
          closeModal={() => setPreview(false)}
          disabled={true}
          loading={false}
          callMutation={() => {
            FileSaver.saveAs(preview.url, preview.fileName)
          }}
          icon={faArrowCircleDown}
          toolTip="Save File"
          className="p-2"
        >
          <div className="items-center my-0">
            <img src={preview.url} alt="img" />
          </div>
        </ReactModal>
      )}
    </>
  ) : (
    <></>
  )
}
