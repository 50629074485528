import { Collapsable } from 'App/Components/Common/Collapsable'
import { InputField } from 'App/Components/Common/InputField'
import { ReactModal } from 'App/Components/Common/Modal'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import dateFormat from 'App/Services/General/dateFormat'
import { Input, StyledButton, SubmitButton } from 'App/Styled'
import React, { useState } from 'react'
import { DateRange } from 'react-date-range'

const Filters = ({
  requestFilters,
  handleFilters,
  resetFilters,
  _dateRange,
  handleDateChange,
}) => {
  const [filters, setFilters] = useState({ ...requestFilters })
  const [previewModal, setPreviewModal] = useState(false)
  const [dateRange, setDateRange] = useState(_dateRange)

  const handleChangeFilters = (value, name) => {
    {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const loadStatusType = async () => {
    return {
      options: [
        { value: `APPROVED`, label: `APPROVED` },
        { value: `REJECTED`, label: `REJECTED` },
        { value: `IN PROGRESS`, label: `IN PROGRESS` },
        { value: `PENDING`, label: `PENDING` },
      ],
      hasMore: false,
    }
  }

  const loadRequestType = async () => {
    return {
      options: [
        { value: `CAR`, label: `CAR` },
        { value: `DRIVER`, label: `DRIVER` },
        { value: `COVERAGE`, label: `COVERAGE` },
      ],
      hasMore: false,
    }
  }

  return (
    <div
      className={`bg-white mt-2 mb-1 sm:mb-0 justify-center items-center px-2 pt-1 `}
    >
      {previewModal && (
        <ReactModal
          title="Date Range"
          closeModal={() => setPreviewModal(false)}
        >
          <div className="pb-2">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => {
                item.selection.isSelected = true
                setDateRange([item.selection])
                handleChangeFilters(item.selection, `createdAt`)
              }}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              className="w-full"
            />
            <div className="px-2 text-right">
              <StyledButton
                onClick={() => {
                  handleDateChange(dateRange)
                  setPreviewModal(false)
                }}
              >
                <div className="flex gap-2 text-white">
                  <span>Filter </span>
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}
      <Collapsable title="Filters">
        <div className="grid grid-cols-4 gap-4">
          <div className="relative">
            <InputField
              type="text"
              title="Request No"
              placeholder="Enter No"
              value={filters?._requestId ?? ``}
              setValue={(e) => {
                handleChangeFilters(e.target.value, `_requestId`)
              }}
            />
          </div>
          <div className="relative">
            <ReactSelectField
              title="Status"
              placeholder="Choose Status"
              value={filters?.status}
              setValue={(value) => {
                handleChangeFilters(value, `status`)
              }}
              isMulti={false}
              loadOptions={loadStatusType}
            />
          </div>
          <div className="relative">
            <ReactSelectField
              title="Request For"
              placeholder="Choose Request For"
              value={filters?.requestFor}
              setValue={(value) => {
                handleChangeFilters(value, `requestFor`)
              }}
              isMulti={false}
              loadOptions={loadRequestType}
            />
          </div>
          <div className="relative">
            <label className={`text-gray-700 font-medium text-sm`}>
              Created Date
            </label>
            <div className="flex gap-x-2">
              <Input
                type="text"
                name="startRange"
                placeholder="Start Date"
                value={
                  dateRange?.[0]?.isSelected
                    ? `${dateFormat(dateRange?.[0]?.startDate)} - ${dateFormat(
                        dateRange?.[0]?.endDate,
                      )} `
                    : ``
                }
                onFocus={() => setPreviewModal(true)}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-4">
          <div className="flex  gap-4">
            <SubmitButton
              onClick={() => {
                handleFilters(filters)
              }}
            >
              Search
            </SubmitButton>

            <SubmitButton
              onClick={() => {
                if (
                  filters?._requestId?.length ||
                  filters?.status?.value?.length ||
                  filters?.requestFor?.value?.length ||
                  dateRange?.[0]?.isSelected
                ) {
                  setDateRange([
                    {
                      startDate: new Date(),
                      endDate: new Date(),
                      key: `selection`,
                    },
                  ])
                  setFilters({
                    _requestId: ``,
                    status: ``,
                    requestFor: ``,
                  })
                  resetFilters()
                }
              }}
            >
              Reset
            </SubmitButton>
          </div>
        </div>
      </Collapsable>
    </div>
  )
}

export default Filters
