import { Container } from 'App/Styled'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Reload } from 'App/Components/Common/Reload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { CarrierDownloadCard } from './carrierDownloadCard'
import { PolicyContext } from '../..'
import { ReactPagination } from 'App/Components/Common/Pagination'

const offset = 5
export const CarrierDownloads = () => {
  const { currentPolicy } = useSelector(({ policy }) => policy)
  const [currentCarrierDownload, setCurrentCarrierDownload] = useState([])
  const [currentIndex, setCurrentIndex] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const { isLoading, isFetching, refetch, error } = useContext(PolicyContext)
  const getNewCarrierDownloads = (page) => {
    const startIndex = (page - 1) * offset
    setCurrentIndex(startIndex)
    if (currentPolicy.carrierDownloads) {
      let allCarriers = [...currentPolicy.carrierDownloads]
      const newCarriers = allCarriers.splice(startIndex, startIndex + offset)
      setCurrentCarrierDownload(newCarriers)
    } else setCurrentCarrierDownload([])
  }
  useEffect(() => {
    getNewCarrierDownloads(currentPage)
  }, [currentPage, currentPolicy.carrierDownloads])
  return (
    <>
      {isLoading || isFetching ? (
        <div className="text-center py-10">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <Container>
          {currentCarrierDownload.length > 0 ? (
            <>
              <div className="p-2">
                {currentCarrierDownload?.map((carrier, index) => (
                  <div key={index} className="my-4">
                    <CarrierDownloadCard
                      carrier={carrier}
                      index={currentIndex + index}
                    />
                  </div>
                ))}
              </div>
              <div className="px-4 py-2">
                <ReactPagination
                  totalRecords={currentPolicy?.carrierDownloads?.length}
                  totalPages={Math.ceil(
                    currentPolicy?.carrierDownloads?.length / offset,
                  )}
                  curerntPageRecords={currentCarrierDownload?.length}
                  offset={offset}
                  currentPage={currentPage}
                  onChange={(page) => setCurrentPage(page)}
                />
              </div>
            </>
          ) : (
            <p className=" text-center p-6">No Carrier Found</p>
          )}
        </Container>
      )}
    </>
  )
}
