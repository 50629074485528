import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { ReactModal } from 'App/Components/Common/Modal'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { SubmitButton } from 'App/Styled'

import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useMutation } from 'react-query'
export const StripeForm = ({
  openModal,
  setOpenModal,
  stripeTokenIndex,
  stripeAmount,
  setValue,
  transactionId,
  setConfirmStripeLoader,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [isPaymentProgress, setIsPaymentProgress] = useState(false)
  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsPaymentProgress(true)

    if (!stripe || !elements) {
      setIsPaymentProgress(false)

      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: `if_required`,
    })
    if (result.error) {
      setIsPaymentProgress(false)
      NotificationManager.error(result.error.message)
      canceledPayment({ ...result?.error, transactionId: transactionId })
    } else {
      setIsPaymentProgress(false)
      stripeTokenIndex === 0 && setConfirmStripeLoader(true)
      // get confirmation code
      confirmPayment({
        paymentId: result?.paymentIntent?.id,
        transactionId: transactionId,
      })
      // setting state for Stripe form array
      setOpenModal((prev) => {
        const newSet = prev.map((it, ind) => {
          if (ind === stripeTokenIndex) {
            it.paymentConfirmId = result?.paymentIntent?.id
          }
          return it
        })
        return newSet
      })
      setOpenModal((prev) => {
        const newSet = prev.map((it, ind) => {
          if (ind === stripeTokenIndex) {
            it.isPaymentDone = true
          }
          return it
        })
        return newSet
      })
      NotificationManager.success(`Stripe Payment done!`)
    }
  }

  const { mutate: confirmPayment } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/payments/confirm`,
        payload,
      )
        .then((res) => {
          // setInitiatedPayment(true)

          return res.data.data
        })
        .catch(() => NotificationManager.error(`Something is went wrong!`)),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setValue(res)
        // array first element executed last
        if (stripeTokenIndex === 0) {
          setConfirmStripeLoader(false)
        }
      },
    },
  )
  const { mutate: canceledPayment } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/payments/cancel`,
        payload,
      )
        .then((res) => {
          // setInitiatedPayment(true)
          return res.data.data
        })
        .catch(() => NotificationManager.error(`Something is went wrong!`)),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        // array first element executed last
        NotificationManager.warning(`Retry please!!`)
      },
    },
  )

  return (
    <>
      {openModal && (
        <ReactModal
          title={`Pay for amount $${stripeAmount / 100}`}
          // closeButton={!result.error}
          closeModal={() => {
            // setValue(false)
            setOpenModal((prev) => {
              const newSet = prev.map((it, ind) => {
                if (ind === stripeTokenIndex) {
                  it.isPaymentDone = true
                }
                return it
              })
              return newSet
            })
          }}
        >
          <form onSubmit={handleSubmit} className="p-4 w-96 space-y-1">
            <PaymentElement />
            {elements && (
              <SubmitButton disabled={isPaymentProgress}>Submit</SubmitButton>
            )}
          </form>
        </ReactModal>
      )}
    </>
  )
}
