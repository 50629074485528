import tw from 'tailwind-styled-components'

export const RemoveDriver = tw.div`
    bg-red-500 
    text-center  
    transition 
    duration-500 
    ease-in-out 
    hover:bg-red-500 
    transform 
    hover:-translate-y-0
    hover:scale-125
    cursor-pointer
`

export const AddFormButton = tw.div`
    transition 
    duration-500 
    ease-in-out 
    transform 
    hover:-translate-y-0
    hover:scale-150
    cursor-pointer
`
