import React from 'react'
import { Overview } from './Overview'
import { Locations } from './Locations'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveFranchiseTab } from 'App/Redux/actions'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import { FAgents } from './Agents'
import { FCustomers } from './Customers'
import { TFour, TOne, TThree, TTwo } from 'App/Styled'

const fTabs = [
  {
    tName: `Overview`,
    tNumber: 1,
    permission: [`franchises:read`],
  },
  {
    tName: `Locations`,
    tNumber: 2,
    permission: [`locations:read`],
  },
  {
    tName: `Agents`,
    tNumber: 3,
    permission: [`users:read`],
  },
  {
    tName: `Customers`,
    tNumber: 4,
    permission: [`customers:read`],
  },
]

export const Tabs = () => {
  const { activeTab } = useSelector(({ franchise }) => franchise)
  const dispatch = useDispatch()
  return (
    <>
      <TabsHeaders
        openTab={activeTab}
        tabs={fTabs}
        getActiveTab={(val) => dispatch(setActiveFranchiseTab(val))}
      />
      <div className="tab-content tab-space">
        {/* tab one */}

        <TOne opentab={activeTab}>
          <Overview />
        </TOne>

        {/* tab 2 */}
        <TTwo opentab={activeTab}>
          <Locations />
        </TTwo>

        {/* tab 3 */}
        <TThree opentab={activeTab}>
          <FAgents />
        </TThree>

        {/* tab 4 */}
        <TFour opentab={activeTab}>
          <FCustomers />
        </TFour>
      </div>
    </>
  )
}
