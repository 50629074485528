import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from '../style'
import { PolicyReportPdfHeader } from 'App/Components/Common/PolicyReportPdfHeader'

export const Pdf = ({ insured, dateRange = [] }) => {
  return (
    <Document subject="Report" author="Reports">
      <Page style={styles.page} size="A3" wrap>
        <View style={styles.section}>
          <Text style={styles.pageHeading}>Invoices By Agent Report</Text>
          <Text style={styles.smallSpace}></Text>
          <PolicyReportPdfHeader dateRange={dateRange} />
          <Text style={styles.smallSpace}></Text>

          <View style={[styles.headingRow, { justifyContent: `space-between`, paddingHorizontal: 20, textOverflow: `ellipsis`, overflow: `hidden` }]}>
            <Text style={styles.pageTitles}> Agent</Text>
            <Text style={styles.pageTitles}> Total Invoices</Text>
            <Text style={[styles.pageTitles, { width: 120 }]}> Invoice Amount Due</Text>
          </View>

          {insured?.length > 0 &&
            insured?.map((item, index) => (
              <View style={styles.agent} key={index}>
                <View style={[styles.headingRow2, { justifyContent: `space-between`, paddingHorizontal: 20, textOverflow: `ellipsis`, overflow: `hidden` }]}>
                  <Text style={styles.pageTitles2}>{item?._id}</Text>
                  <Text style={styles.pageTitles2}>{item?.countInvoices}</Text>
                  <Text style={styles.pageTitles2}>
                    {item?.invoiceAmountDue}
                  </Text>
                </View>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  )
}
