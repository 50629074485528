import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

export const CardField = ({
  title,
  value,
  multiple,
  badge,
  coord,
  link,
  isGray,
  isDashboard,
  path,
  isShowPassword,
}) => {
  const history = useHistory()
  const [passwordShow, setPasswordShow] = useState(false)
  return (
    <div
      className={`grid ${
        isDashboard ? `grid-cols-12` : `grid-cols-5`
      } py-1.5 justify-items-start place-content-center text-sm ${
        isGray ? `bg-gray-100` : ``
      }  `}
    >
      <div
        className={`${
          isDashboard ? `col-span-10` : `col-span-2`
        } text-gray-500 font-medium capitalize`}
      >
        {title}
      </div>
      {/* <div className="col-span-1">-</div> */}
      <div
        className={`${
          isDashboard ? `col-span-2` : `col-span-3`
        } flex flex-wrap font-medium my-auto`}
      >
        {multiple ? (
          value?.length ? (
            value?.map((val, index) => {
              return (
                <span
                  key={index}
                  className={`m-0.5  py-1 px-1.5 break-words text-sm rounded-xl border shadow-sm`}
                >
                  {val}
                </span>
              )
            })
          ) : (
            `Not Provided`
          )
        ) : value ? (
          badge ? (
            <span
              className={`py-0.5 px-1.5 break-words max-w-px-180 whitespace-pre text-sm rounded-xl border border-gray-400`}
            >
              {value}
            </span>
          ) : coord ? (
            <div className="flex justify-evenly">
              [<p className="mr-0.5">{value.lat}</p>,
              <p className="ml-0.5">{value.long}</p> ]
            </div>
          ) : link ? (
            <a
              className="border-b-2 text-red-500 break-words max-w-px-180"
              href={value}
              target="_blank"
              rel="noreferrer"
            >
              {title}
            </a>
          ) : path ? (
            <span
              className={`underline text-blue-500 cursor-pointer hover:text-blue-700`}
              onClick={() => history.push(path)}
            >
              {value}
            </span>
          ) : isShowPassword ? (
            // View password for user
            <span
              className={`cursor-pointer hover:text-gray-700 tooltip tooltip-right`}
              data-tip={`${passwordShow ? `Hide` : `Show`} password`}
              onClick={() => setPasswordShow((prev) => !prev)}
            >
              {passwordShow ? value : `*`.repeat(value.length)}
              {/* {value} */}
            </span>
          ) : (
            value
          )
        ) : (
          `Not Provided`
        )}
      </div>
    </div>
  )
}
