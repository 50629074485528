import React, { useState } from 'react'
import { DateRangeInputField } from '../DateRangeInputField'
import { ReactModal } from '../Modal'
import { DateRange } from 'react-date-range'
import { StyledButton } from 'App/Styled'
import dateFormat from 'App/Services/General/dateFormat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons'

const selectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: `selection`,
  isSelected: false,
  isSearched: false,
}

export const DateRangeInput = ({ value, setValue, title, isIcon }) => {
  const [dateRangeModal, setDateRangeModal] = useState(false)

  const handleModalToggle = () => {
    setValue((prev) => {
      return prev.map((item) => ({
        ...item,
        isSearched: false,
      }))
    })
    setDateRangeModal(!dateRangeModal)
  }
  return (
    <>
      {dateRangeModal && (
        <ReactModal
          title="Date Range"
          closeModal={() => setDateRangeModal(false)}
        >
          <div className="pb-2">
            <DateRange
              editableDateInputs={true}
              onChange={(item) =>
                setValue([{ ...item.selection, isSelected: true }])
              }
              moveRangeOnFirstSelection={false}
              ranges={value ? value : [selectionRange]}
              className="w-full"
            />
            <div className="px-2 text-right">
              <StyledButton
                onClick={() => {
                  setDateRangeModal(false)
                }}
              >
                <div className="flex gap-2 text-white">
                  <span
                    onClick={() => {
                      setValue((prev) => {
                        return prev.map((item) => ({
                          ...item,
                          isSearched: true,
                        }))
                      })
                    }}
                  >
                    Select
                  </span>
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}

      {isIcon ? (
        <div
          className="py-1 px-2 tooltip tooltip-left"
          onClick={handleModalToggle}
          data-tip="Date Range"
        >
          <FontAwesomeIcon
            icon={faCalendarWeek}
            className="text-blue-500 mt-0.5 cursor-pointer"
            size="md"
          />
        </div>
      ) : (
        <div onClick={handleModalToggle} className="flex-1 relative">
          <DateRangeInputField
            type="text"
            title={title}
            name="target"
            placeholder="Select Date Range"
            value={
              value &&
              dateFormat(value[0]?.startDate) +
                `\t - \t` +
                dateFormat(value[0]?.endDate)
            }
            readOnly={true}
          />
        </div>
      )}
    </>
  )
}
