import React from 'react'

import { Notes } from 'App/Components/Common/Notes'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

export const PolicyNotes = () => {
  const {
    params: { policyId },
  } = useRouteMatch()

  return <Notes resourceId={policyId} apiType={`policy`} />
}
