import React from 'react'
import { Container, StickerSkelton } from 'App/Styled'

export const DashboardSkelton = () => {
  return (
    <>
      <Container>
        <div className="grid grid-cols-12 ">
          <StickerSkelton />

          <StickerSkelton />
          <StickerSkelton />
        </div>
      </Container>

      <div className=" grid gap-0 grid-cols-2 2xl:grid-cols-3    ">
        <div className="rounded-md text-center">
          <Container className="flex relative">
            {/* <div className=" w-1/2 absolute right-4 top-2">
              <div className="h-6  bg-gray-100  "></div>
            </div> */}
            <div className="flex ml-4 animate-pulse flex-col h- ">
              <div className="bg-gray-100 flex-1 m-1 w-4 rounded"></div>
            </div>
            <div className="flex animate-pulse  h-80 flex-col items-start">
              <div className="bg-gray-100 flex m-1 w-52 h-20 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-12 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-48 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-28 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-64 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-72 h-24 rounded-t-md"></div>
              {/* <div className="bg-gray-100 flex m-1 w-36 h-24 rounded-t-md"></div> */}
              {/* <div className="bg-gray-100 flex m-1 w-24 h-24 rounded-t-md"></div> */}
              {/* <div className="bg-gray-100 flex m-1 w-20 h-24 rounded-t-md"></div> */}
              <div className="bg-gray-100 flex m-1 w-32 h-24 rounded-t-md"></div>
              {/* <div className="bg-gray-100 flex m-1 w-20 h-24 rounded-t-md"></div> */}
              {/* <div className="bg-gray-100 flex m-1 w-6 h-11 rounded-t-md"></div> */}
              {/* <div className="bg-gray-100 flex m-1 w-6 h-16 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-5 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-24 rounded-t-md"></div> */}
            </div>
            {/* 
            <div className="flex mx-2 animate-pulse gap-2 h-8">
              <div className="bg-gray-100 flex-1 m-1 rounded"></div>
            </div> */}
          </Container>
        </div>
        <div className=" rounded-md text-center">
          <Container className="h-80 relative mx-auto">
            {/* <div className=" w-1/2 absolute right-4 top-2">
              <div className="h-6  bg-gray-100  "></div>
            </div> */}
            <div className="bg-cool-gray-800  ">
              <div className="flex justify-center gap-2 h-64 absolute  top-1/2 left-1/2 transform  -translate-x-1/2  -translate-y-1/2">
                <div className="bg-gray-200 animate-pulse w-60 m-1 rounded-full"></div>
              </div>
              <div className="flex justify-center  gap-2 h-32 absolute  top-1/2 left-1/2 transform -translate-x-1/2   -translate-y-1/2">
                <div className="bg-white  w-32 m-1 rounded-full"></div>
              </div>
            </div>
          </Container>
        </div>
        <div className="text-center">
          <Container className="relative">
            {/* <div className=" w-1/2 absolute right-4 top-2">
              <div className="h-6  bg-gray-100  "></div>
            </div> */}
            <div className="flex mx-2 animate-pulse gap-2 h-72  items-end">
              <div className="bg-gray-100 flex m-1 w-6 h-56 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-12 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-32 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-28 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-48 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-64 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-36 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-20 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-40 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-9 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-11 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-16 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-5 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-24 rounded-t-md"></div>
            </div>

            <div className="flex mx-2 animate-pulse gap-2 h-8">
              <div className="bg-gray-100 flex-1 m-1 rounded"></div>
            </div>
          </Container>
        </div>
        <div className="rounded-md text-center">
          <Container className="flex ">
            <div className="flex ml-4 animate-pulse flex-col  ">
              <div className="bg-gray-100 flex-1 m-1 w-4 rounded"></div>
            </div>
            <div className="flex animate-pulse  h-80 flex-col items-start">
              {/* <div className="bg-gray-100 flex m-1 w-56 h-20 rounded-t-md"></div> */}
              {/* <div className="bg-gray-100 flex m-1 w-12 h-24 rounded-t-md"></div> */}
              <div className="bg-gray-100 flex m-1 w-32 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-48 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-64 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-24 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-36 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-72 h-24 rounded-t-md"></div>
              {/* <div className="bg-gray-100 flex m-1 w-20 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-32 h-24 rounded-t-md"></div> */}
              {/* <div className="bg-gray-100 flex m-1 w-32 h-24 rounded-t-md"></div> */}
              {/* <div className="bg-gray-100 flex m-1 w-6 h-11 rounded-t-md"></div> */}
              {/* <div className="bg-gray-100 flex m-1 w-6 h-16 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-5 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-24 rounded-t-md"></div> */}
            </div>
            {/* 
            <div className="flex mx-2 animate-pulse gap-2 h-8">
              <div className="bg-gray-100 flex-1 m-1 rounded"></div>
            </div> */}
          </Container>
        </div>
        <div className="rounded-md text-center">
          <div className=" rounded-md text-center">
            <Container className="h-80 relative mx-auto">
              {/* <div className=" w-1/2 absolute right-4 top-2">
                <div className="h-6  bg-gray-100  "></div>
              </div> */}
              <div className="bg-cool-gray-800 ">
                <div className="flex justify-center gap-2 h-64 absolute  top-1/2 left-1/2 transform  -translate-x-1/2  -translate-y-1/2">
                  <div className="bg-gray-200 animate-pulse w-60 m-1 rounded-full"></div>
                </div>
                <div className="flex justify-center  gap-2 h-32 absolute  top-1/2 left-1/2 transform -translate-x-1/2   -translate-y-1/2">
                  <div className="bg-white  w-32 m-1 rounded-full"></div>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div className="text-center">
          <Container>
            <div className="flex mx-2 animate-pulse gap-2 h-72  items-end">
              <div className="bg-gray-100 flex m-1 w-6 h-12 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-11 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-32 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-48 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-56 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-32 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-36 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-9 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-40 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-20 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-24 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-56 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-48 rounded-t-md"></div>
              <div className="bg-gray-100 flex m-1 w-6 h-32 rounded-t-md"></div>
            </div>

            <div className="flex mx-2 animate-pulse gap-2 h-8">
              <div className="bg-gray-100 flex-1 m-1 rounded"></div>
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}
