import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { MaskField } from 'App/Components/Common/MaskField'
import { InputField } from 'App/Components/Common/InputField'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import {
  loadGenderOptions,
  loadMaritalStatusOptions,
  loadOccupations,
  loadZipCodes,
  ValidateZip,
} from 'App/Services'
import {
  loadDriverRelationtoApplic,
  loadYears,
  loadDriverTypeCode,
  loadRisksReasonCode,
  loadIndustries,
  loadDLStates,
} from 'App/Services'
import { useDebouncedCallback } from 'use-debounce/lib'

export const UpdateDriver = () => {
  const [isFormChanged, setIsFormChanged] = useState(false)

  const history = useHistory()
  const {
    params: { customerId, driverId },
  } = useRouteMatch()
  const { driverAssets } = useSelector((state) => state.asset)
  const [driverAsset, setDriverAsset] = useState({
    name: ``,
    prefix: ``,
    suffix: ``,
    gender: ``,
    dateOfBirth: ``,
    maritalStatus: ``,
    education: ``,
    email: ``,
    phone: ``,
    ext: ``,
    hireDate: ``,
    terminationDate: ``,
    DLYear: ``,
    DLNumber: ``,
    DLState: ``,
    ssn: ``,
    industry: ``,
    occupations: ``,
    companyDriverNumber: ``,
    countryCode: ``,
    firstLicensedCurrentState: ``,
    licensedDate: ``,
    assignedRiskReasonCode: ``,
    defensiveDriverDate: ``,
    defensiveDriverCode: ``,
    defensiveDriverExpirationDate: ``,
    distantStudentIndicator: ``,
    driverAssignedRiskIndicator: ``,
    driverRelationshipToApplicantCode: ``,
    driverTrainingCompletionDate: ``,
    driverTrainingIndicator: ``,
    driverTypeCode: ``,
    reinstatementdate: ``,
    restrictedIndicator: ``,
    suspensionRevocationDate: ``,
    suspensionRevocationReasonCode: ``,
    isExecuted: false,
    isCreateContact: false,
  })
  const [violations, setViolations] = useState([])
  const [errors, setErrors] = useState({
    formSubmit: false,
    nameError: ``,
    prefixError: ``,
    suffixError: ``,
    genderError: ``,
    dateOfBirthError: ``,
    maritalStatusError: ``,
    educationError: ``,
    emailError: ``,
    phoneError: ``,
    extError: ``,
    hireDateError: ``,
    terminationDateError: ``,
    DLYearError: ``,
    DLNumberError: ``,
    DLStateError: ``,
    ssnError: ``,
    industryError: ``,
    occupationsError: ``,
    companyDriverNumberError: ``,
    countryCodeError: ``,
    firstLicensedCurrentStateError: ``,
    licensedDateError: ``,
    assignedRiskReasonCodeError: ``,
    defensiveDriverDateError: ``,
    defensiveDriverCodeError: ``,
    defensiveDriverExpirationDateError: ``,
    distantStudentIndicatorError: ``,
    driverAssignedRiskIndicatorError: ``,
    driverRelationshipToApplicantCodeError: ``,
    driverTrainingCompletionDateError: ``,
    driverTrainingIndicatorError: ``,
    driverTypeCodeError: ``,
    reinstatementdateError: ``,
    restrictedIndicatorError: ``,
    suspensionRevocationDateError: ``,
    suspensionRevocationReasonCodeError: ``,
    isExecutedError: false,
    isCreateContactError: false,

    //    address
    unitNoError: ``,
    zipError: ``,
    streetNoError: ``,
    cityError: ``,
    stateError: ``,
    coordinatesError: ``,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    zip: ``,
    streetNo: ``,
    city: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const handleAddressChange = useDebouncedCallback((name, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  //handle zip code
  const handleZipChange = async (val) => {
    const data = await ValidateZip(val.value)
    if (data) {
      const res = data.address

      setAddress((prevState) => ({
        ...prevState,
        [`city`]: res.city,
        [`state`]: res.state,
        [`zip`]: { value: res.zipCode, label: res.zipCode },
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        [`lat`]: res.lat,
        [`lng`]: res.long,
      }))
    }
  }
  // handle map
  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  const handleDriverAssetChange = useDebouncedCallback((name, value) => {
    setDriverAsset((driverAsset) => ({ ...driverAsset, [name]: value }))
  }, 5)

  const handleViolationsFormChange = (value, name, ind) => {
    const newViolations = violations?.map((violation, index) => {
      if (index === ind) {
        violation[name] = value
      }
      return violation
    })
    setViolations(newViolations)
  }

  //   violation Form Handler
  const addViolationForm = () => {
    setViolations([
      ...violations,
      { driverViolationCode: ``, description: ``, violationDate: `` },
    ])
  }
  const removeViolationForm = (e, index) => {
    const violationsArr = [...violations]
    if (violations.length === 1) {
      return
    }
    violationsArr.splice(index, 1)
    setViolations(violationsArr)
  }
  //  Form Validations
  const ValidForm = () => {
    if (!driverAsset.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }

    if (!driverAsset.prefix.length) {
      handleErrors(`prefixError`, `Enter Prefix`)
    } else {
      handleErrors(`prefixError`, ``)
    }

    if (!driverAsset.suffix.length) {
      handleErrors(`suffixError`, `Enter Suffix`)
    } else {
      handleErrors(`suffixError`, ``)
    }

    if (!driverAsset.gender?.value?.length) {
      handleErrors(`genderError`, `Enter Gender`)
    } else {
      handleErrors(`genderError`, ``)
    }

    if (!driverAsset.dateOfBirth) {
      handleErrors(`dateOfBirthError`, `Select Date`)
    } else {
      handleErrors(`dateOfBirthError`, ``)
    }

    if (!driverAsset.maritalStatus?.value?.length) {
      handleErrors(`maritalStatusError`, `Select Status`)
    } else {
      handleErrors(`maritalStatusError`, ``)
    }

    if (!driverAsset.education.length) {
      handleErrors(`educationError`, `Enter Education`)
    } else {
      handleErrors(`educationError`, ``)
    }

    if (!driverAsset.email.length) {
      handleErrors(`emailError`, `Enter Email`)
    } else if (!regEx.test(driverAsset.email.toLowerCase())) {
      handleErrors(`emailError`, `Enter Valid Email`)
    } else {
      handleErrors(`emailError`, ``)
    }

    if (!driverAsset.phone.length) {
      handleErrors(`phoneError`, `Enter Phone`)
    } else if (
      driverAsset.phone !== driverAssets.phone &&
      driverAsset.phone.length < 12
    ) {
      handleErrors(`phoneError`, `Enter Valid Phone`)
    } else {
      handleErrors(`phoneError`, ``)
    }

    if (!driverAsset.ext.length) {
      handleErrors(`extError`, `Enter Extension`)
    } else {
      handleErrors(`extError`, ``)
    }

    //  General
    if (!driverAsset.hireDate) {
      handleErrors(`hireDateError`, `Select Date`)
    } else {
      handleErrors(`hireDateError`, ``)
    }
    if (!driverAsset.terminationDate) {
      handleErrors(`terminationDateError`, `Select Date`)
    } else {
      handleErrors(`terminationDateError`, ``)
    }

    if (!driverAsset.DLYear?.value) {
      handleErrors(`DLYearError`, `Select Year`)
    } else {
      handleErrors(`DLYearError`, ``)
    }
    if (!driverAsset.DLNumber.length) {
      handleErrors(`DLNumberError`, `Enter Number`)
    } else {
      handleErrors(`DLNumberError`, ``)
    }
    if (!driverAsset.DLState?.value) {
      handleErrors(`DLStateError`, `Select State`)
    } else {
      handleErrors(`DLStateError`, ``)
    }
    if (!driverAsset.ssn.length) {
      handleErrors(`ssnError`, `Enter Social Security No`)
    } else {
      handleErrors(`ssnError`, ``)
    }
    if (!driverAsset.industry.length) {
      handleErrors(`industryError`, `Enter Industry Name`)
    } else {
      handleErrors(`industryError`, ``)
    }
    if (!driverAsset.industry?.value) {
      handleErrors(`industryError`, `Enter Industry Name`)
    } else {
      handleErrors(`industryError`, ``)
    }
    if (!driverAsset.occupations?.length) {
      handleErrors(`occupationsError`, `Select Occupation`)
    } else {
      handleErrors(`occupationsError`, ``)
    }
    if (!driverAsset.companyDriverNumber.length) {
      handleErrors(`companyDriverNumberError`, `Enter Number`)
    } else {
      handleErrors(`companyDriverNumberError`, ``)
    }
    if (!driverAsset.countryCode.length) {
      handleErrors(`countryCodeError`, `Enter Code`)
    } else {
      handleErrors(`countryCodeError`, ``)
    }
    if (!driverAsset.firstLicensedCurrentState.length) {
      handleErrors(`firstLicensedCurrentStateError`, `Enter Current State`)
    } else {
      handleErrors(`firstLicensedCurrentStateError`, ``)
    }
    if (!driverAsset.licensedDate) {
      handleErrors(`licensedDateError`, `Select Date`)
    } else {
      handleErrors(`licensedDateError`, ``)
    }
    if (!driverAsset.assignedRiskReasonCode?.value) {
      handleErrors(`assignedRiskReasonCodeError`, `Select Code`)
    } else {
      handleErrors(`assignedRiskReasonCodeError`, ``)
    }
    if (!driverAsset.defensiveDriverDate) {
      handleErrors(`defensiveDriverDateError`, `Select Code`)
    } else {
      handleErrors(`defensiveDriverDateError`, ``)
    }
    if (!driverAsset.defensiveDriverCode.length) {
      handleErrors(`defensiveDriverCodeError`, `Enter Code`)
    } else {
      handleErrors(`defensiveDriverCodeError`, ``)
    }
    if (!driverAsset.defensiveDriverExpirationDate) {
      handleErrors(`defensiveDriverExpirationDateError`, `Select Date`)
    } else {
      handleErrors(`defensiveDriverExpirationDateError`, ``)
    }
    if (!driverAsset.distantStudentIndicator.length) {
      handleErrors(`distantStudentIndicatorError`, `Enter Value`)
    } else {
      handleErrors(`distantStudentIndicatorError`, ``)
    }
    if (!driverAsset.driverAssignedRiskIndicator.length) {
      handleErrors(`driverAssignedRiskIndicatorError`, `Enter Value`)
    } else {
      handleErrors(`driverAssignedRiskIndicatorError`, ``)
    }
    if (!driverAsset.driverRelationshipToApplicantCode?.value) {
      handleErrors(`driverRelationshipToApplicantCodeError`, `Enter Code`)
    } else {
      handleErrors(`driverRelationshipToApplicantCodeError`, ``)
    }
    if (!driverAsset.driverTrainingCompletionDate) {
      handleErrors(`driverTrainingCompletionDateError`, `Select Date`)
    } else {
      handleErrors(`driverTrainingCompletionDateError`, ``)
    }
    if (!driverAsset.driverTrainingIndicator.length) {
      handleErrors(`driverTrainingIndicatorError`, `Enter Value`)
    } else {
      handleErrors(`driverTrainingIndicatorError`, ``)
    }
    if (!driverAsset.driverTypeCode?.value) {
      handleErrors(`driverTypeCodeError`, `Select Code`)
    } else {
      handleErrors(`driverTypeCodeError`, ``)
    }
    if (!driverAsset.reinstatementdate) {
      handleErrors(`reinstatementdateError`, `Select Date`)
    } else {
      handleErrors(`reinstatementdateError`, ``)
    }
    if (!driverAsset.restrictedIndicator.length) {
      handleErrors(`restrictedIndicatorError`, `Enter Value`)
    } else {
      handleErrors(`restrictedIndicatorError`, ``)
    }
    if (!driverAsset.suspensionRevocationDate) {
      handleErrors(`suspensionRevocationDateError`, `Select Date`)
    } else {
      handleErrors(`suspensionRevocationDateError`, ``)
    }
    if (!driverAsset.suspensionRevocationReasonCode.length) {
      handleErrors(`suspensionRevocationReasonCodeError`, `Enter Code`)
    } else {
      handleErrors(`suspensionRevocationReasonCodeError`, ``)
    }

    //    address error
    if (!address.unitNo.length) {
      handleErrors(`unitNoError`, `Enter Unit No`)
    } else {
      handleErrors(`unitNoError`, ``)
    }

    if (!address.zip?.value?.length) {
      handleErrors(`zipError`, `Select Zip`)
    } else {
      handleErrors(`zipError`, ``)
    }
    if (!address.streetNo.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }
    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }
    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }
    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, `Select Coordinates`)
    } else {
      handleErrors(`coordinatesError`, ``)
    }

    for (const index in violations) {
      const violation = violations[index]

      if (!violation.driverViolationCode.length) {
        handleViolationsErrors(index, `driverViolationCodeError`, `Enter Value`)
      } else {
        handleViolationsErrors(index, `driverViolationCodeError`, ``)
      }

      if (!violation.description.length) {
        handleViolationsErrors(index, `descriptionError`, `Enter Description`)
      } else {
        handleViolationsErrors(index, `descriptionError`, ``)
      }

      if (!violation.violationDate) {
        handleViolationsErrors(index, `violationDateError`, `Enter Date`)
      } else {
        handleViolationsErrors(index, `violationDateError`, ``)
      }
    }
    handleErrors(`formSubmit`, true)
  }
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const handleViolationsErrors = (ind, name, val) => {
    const newViolations = violations?.map((violation, index) => {
      if (index == ind) {
        violation[name] = val
      }
      return violation
    })
    setViolations(newViolations)
  }

  useEffect(() => {
    let isFormvalidated = false
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.prefixError.length &&
      !errors.suffixError.length &&
      !errors.genderError.length &&
      !errors.dateOfBirthError.length &&
      !errors.maritalStatusError.length &&
      !errors.educationError.length &&
      !errors.emailError.length &&
      !errors.phoneError.length &&
      !errors.extError.length &&
      !errors.hireDateError.length &&
      !errors.terminationDateError.length &&
      !errors.DLYearError.length &&
      !errors.DLNumberError.length &&
      !errors.DLStateError.length &&
      !errors.ssnError.length &&
      !errors.industryError.length &&
      !errors.occupationsError.length &&
      !errors.companyDriverNumberError.length &&
      !errors.countryCodeError.length &&
      !errors.firstLicensedCurrentStateError.length &&
      !errors.licensedDateError.length &&
      !errors.assignedRiskReasonCodeError.length &&
      !errors.defensiveDriverDateError.length &&
      !errors.defensiveDriverCodeError.length &&
      !errors.defensiveDriverExpirationDateError.length &&
      !errors.distantStudentIndicatorError.length &&
      !errors.driverAssignedRiskIndicatorError.length &&
      !errors.driverRelationshipToApplicantCodeError.length &&
      !errors.driverTrainingCompletionDateError.length &&
      !errors.driverTrainingIndicatorError.length &&
      !errors.driverTypeCodeError.length &&
      !errors.reinstatementdateError.length &&
      !errors.suspensionRevocationDateError.length &&
      !errors.suspensionRevocationReasonCodeError.length &&
      !errors.isExecutedError.length &&
      !errors.isCreateContactError.length &&
      !errors.unitNoError.length &&
      !errors.zipError.length &&
      !errors.streetNoError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.coordinatesError.length
    ) {
      violations.forEach((_eachViolation) => {
        if (
          !_eachViolation.driverViolationCodeError.length &&
          !_eachViolation.descriptionError.length &&
          !_eachViolation.violationDateError
        ) {
          isFormvalidated = true
        } else {
          isFormvalidated = false
        }
      })
    }

    if (isFormvalidated) {
      mutateDriver(updatePayload())
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}

    if (driverAsset.name !== driverAssets.name) {
      data.name = driverAsset.name
    }
    if (driverAsset.prefix !== driverAssets.prefix) {
      data.prefix = driverAsset.prefix
    }
    if (driverAsset.suffix !== driverAssets.sufix) {
      data.sufix = driverAsset.suffix
    }
    if (driverAsset.gender.value !== driverAssets.gender) {
      data.gender = driverAsset.gender.value
    }
    if (
      driverAsset.dateOfBirth.toString() !==
      new Date(driverAssets.dateOfBirth).toString()
    ) {
      data.dateOfBirth = driverAsset.dateOfBirth
    }
    if (driverAsset.maritalStatus.value !== driverAssets.maritalStatus) {
      data.maritalStatus = driverAsset.maritalStatus.value
    }
    if (driverAsset.education !== driverAssets.education) {
      data.education = driverAsset.education
    }
    if (driverAsset.email !== driverAssets.email) {
      data.email = driverAsset.email
    }
    if (driverAsset.phone !== driverAssets.phone) {
      data.phone = driverAsset.phone
    }
    if (driverAsset.ext !== driverAssets.ext) {
      data.ext = driverAsset.ext
    }
    if (
      driverAsset.hireDate.toString() !==
      new Date(driverAssets.hireDate).toString()
    ) {
      data.hireDate = driverAsset.hireDate
    }
    if (
      driverAsset.terminationDate.toString() !==
      new Date(driverAssets.terminationDate).toString()
    ) {
      data.terminationDate = driverAsset.terminationDate
    }
    if (driverAsset.DLYear.value !== driverAssets.DLYear) {
      data.DLYear = driverAsset.DLYear.value
    }
    if (driverAsset.DLNumber !== driverAssets.DLNumber) {
      data.DLNumber = driverAsset.DLNumber
    }
    if (driverAsset.DLState.value !== driverAssets.DLState) {
      data.DLState = driverAsset.DLState.value
    }
    if (driverAsset.ssn !== driverAssets.ssn) {
      data.ssn = driverAsset.ssn
    }
    if (driverAsset.industry.value !== driverAssets.industry) {
      data.industry = driverAsset.industry.value
    }

    //occupations
    const occup = driverAsset.occupations.some(
      (_ocp, index) => _ocp.value !== driverAssets.occupations[index],
    )
    if (occup) {
      data.occupations = driverAsset.occupations.map((_oc) => _oc.value)
    }

    if (driverAsset.companyDriverNumber !== driverAssets.companyDriverNumber) {
      data.companyDriverNumber = driverAsset.companyDriverNumber
    }
    if (driverAsset.countryCode !== driverAssets.countryCode) {
      data.countryCode = driverAsset.countryCode
    }
    if (
      driverAsset.firstLicensedCurrentState !==
      driverAssets.firstLicensedCurrentState
    ) {
      data.firstLicensedCurrentState = driverAsset.firstLicensedCurrentState
    }
    if (
      driverAsset.licensedDate.toString() !==
      new Date(driverAssets.licensedDate).toString()
    ) {
      data.licensedDate = driverAsset.licensedDate
    }
    if (
      driverAsset.assignedRiskReasonCode.value !==
      driverAssets.assignedRiskReasonCode
    ) {
      data.assignedRiskReasonCode = driverAsset.assignedRiskReasonCode.value
    }
    if (
      driverAsset.defensiveDriverDate.toString() !==
      new Date(driverAssets.defensiveDriverDate).toString()
    ) {
      data.defensiveDriverDate = driverAsset.defensiveDriverDate
    }
    if (driverAsset.defensiveDriverCode !== driverAssets.defensiveDriverCode) {
      data.defensiveDriverCode = driverAsset.defensiveDriverCode
    }
    if (
      driverAsset.defensiveDriverExpirationDate.toString() !==
      new Date(driverAssets.defensiveDriverExpirationDate).toString()
    ) {
      data.defensiveDriverExpirationDate =
        driverAsset.defensiveDriverExpirationDate
    }
    if (
      driverAsset.distantStudentIndicator !==
      driverAssets.distantStudentIndicator
    ) {
      data.distantStudentIndicator = driverAsset.distantStudentIndicator
    }
    if (
      driverAsset.driverAssignedRiskIndicator !==
      driverAssets.driverAssignedRiskIndicator
    ) {
      data.driverAssignedRiskIndicator = driverAsset.driverAssignedRiskIndicator
    }
    if (
      driverAsset.driverRelationshipToApplicantCode.value !==
      driverAssets.driverRelationshipToApplicantCode
    ) {
      data.driverRelationshipToApplicantCode =
        driverAsset.driverRelationshipToApplicantCode.value
    }
    if (
      driverAsset.driverTrainingCompletionDate.toString() !==
      new Date(driverAssets.driverTrainingCompletionDate).toString()
    ) {
      data.driverTrainingCompletionDate =
        driverAsset.driverTrainingCompletionDate
    }
    if (
      driverAsset.driverTrainingIndicator !==
      driverAssets.driverTrainingIndicator
    ) {
      data.driverTrainingIndicator = driverAsset.driverTrainingIndicator
    }
    if (driverAsset.driverTypeCode.value !== driverAssets.driverTypeCode) {
      data.driverTypeCode = driverAsset.driverTypeCode.value
    }
    if (
      driverAsset.reinstatementdate.toString() !==
      new Date(driverAssets.reinstatementdate).toString()
    ) {
      data.reinstatementdate = driverAsset.reinstatementdate
    }
    if (driverAsset.restrictedIndicator !== driverAssets.restrictedIndicator) {
      data.restrictedIndicator = driverAsset.restrictedIndicator
    }
    if (
      driverAsset.suspensionRevocationDate.toString() !==
      new Date(driverAssets.suspensionRevocationDate).toString()
    ) {
      data.suspensionRevocationDate = driverAsset.suspensionRevocationDate
    }
    if (
      driverAsset.suspensionRevocationReasonCode !==
      driverAssets.suspensionRevocationReasonCode
    ) {
      data.suspensionRevocationReasonCode =
        driverAsset.suspensionRevocationReasonCode
    }
    if (driverAsset.isExecuted !== driverAssets.isExecuted) {
      data.isExecuted = driverAsset.isExecuted
    }
    if (driverAsset.isCreateContact !== driverAssets.isCreateContact) {
      data.isCreateContact = driverAsset.isCreateContact
    }

    // address payload
    const addressPayload = {}
    let coordinatesPayload = {}

    if (address.unitNo !== driverAssets.address.unitNo) {
      addressPayload.unitNo = address.unitNo
    }
    if (address.streetNo !== driverAssets.address.street) {
      addressPayload.street = address.streetNo
    }
    if (address.city !== driverAssets.address.city) {
      addressPayload.city = address.city
    }
    if (address?.zip?.value !== driverAssets.address.zip) {
      addressPayload.zip = address?.zip?.value
      coordinatesPayload.lat = coordinates.lat.toString()
      coordinatesPayload.long = coordinates.lng.toString()
    }
    if (address.state !== driverAssets.address.state) {
      addressPayload.state = address.state
    }
    if (Object.keys(addressPayload).length !== 0) {
      addressPayload.coordinates = coordinatesPayload
      data.address = addressPayload
    }

    // violations
    if (violations.length !== driverAssets.violations.length) {
      const violationsPayload = violations.map((_eachViolation) => {
        return {
          driverViolationCode: _eachViolation.driverViolationCode,
          description: _eachViolation.description,
          violationDate: _eachViolation.violationDate,
        }
      })
      data.violations = violationsPayload
    }
    setIsFormChanged(false)
    return data
  }

  useEffect(() => {
    if (driverAssets) {
      setDriverAsset((preState) => ({
        ...preState,
        name: driverAssets.name,
        prefix: driverAssets.prefix,
        suffix: driverAssets.sufix,
        gender: {
          value: driverAssets.gender,
          label: driverAssets.gender,
        },
        dateOfBirth: new Date(driverAssets.dateOfBirth),
        maritalStatus: {
          value: driverAssets.maritalStatus,
          label: driverAssets.maritalStatus,
        },
        education: driverAssets.education,
        email: driverAssets.email,
        phone: driverAssets.phone,
        ext: driverAssets.ext,
        hireDate: new Date(driverAssets.hireDate),
        terminationDate: new Date(driverAssets.terminationDate),
        DLYear: {
          value: driverAssets.DLYear,
          label: driverAssets.DLYear,
        },
        DLNumber: driverAssets.DLNumber,
        DLState: {
          value: driverAssets.DLState,
          label: driverAssets.DLState,
        },
        ssn: driverAssets.ssn,
        industry: {
          value: driverAssets.industry,
          label: driverAssets.industry,
        },
        occupations: driverAssets?.occupations.map((occupation) => ({
          value: occupation,
          label: occupation,
        })),

        companyDriverNumber: driverAssets.companyDriverNumber,
        countryCode: driverAssets.countryCode,
        firstLicensedCurrentState: driverAssets.firstLicensedCurrentState,
        licensedDate: new Date(driverAssets.licensedDate),
        assignedRiskReasonCode: {
          value: driverAssets.assignedRiskReasonCode,
          label: driverAssets.assignedRiskReasonCode,
        },
        defensiveDriverDate: new Date(driverAssets.defensiveDriverDate),
        defensiveDriverCode: driverAssets.defensiveDriverCode,
        defensiveDriverExpirationDate: new Date(
          driverAssets.defensiveDriverExpirationDate,
        ),
        distantStudentIndicator: driverAssets.distantStudentIndicator,
        driverAssignedRiskIndicator: driverAssets.driverAssignedRiskIndicator,
        driverRelationshipToApplicantCode: {
          value: driverAssets.driverRelationshipToApplicantCode,
          label: driverAssets.driverRelationshipToApplicantCode,
        },
        driverTrainingCompletionDate: new Date(
          driverAssets.driverTrainingCompletionDate,
        ),
        driverTrainingIndicator: driverAssets.driverTrainingIndicator,
        driverTypeCode: {
          value: driverAssets.driverTypeCode,
          label: driverAssets.driverTypeCode,
        },
        reinstatementdate: new Date(driverAssets.reinstatementdate),
        restrictedIndicator: driverAssets.restrictedIndicator,
        suspensionRevocationDate: new Date(
          driverAssets.suspensionRevocationDate,
        ),
        suspensionRevocationReasonCode:
          driverAssets.suspensionRevocationReasonCode,
        isExecuted: driverAssets.isExecuted,
        isCreateContact: driverAssets.isCreateContact,
      }))
      setAddress((preState) => ({
        ...preState,
        unitNo: driverAssets?.address?.unitNo,
        zip: {
          value: driverAssets?.address?.zip,
          label: driverAssets?.address?.zip,
        },
        streetNo: driverAssets?.address?.street,
        city: driverAssets?.address?.city,
        state: driverAssets?.address?.state,
      }))
      setCoordinates((preState) => ({
        ...preState,
        lat: driverAssets?.address?.coordinates?.lat,
        lng: driverAssets?.address?.coordinates?.long,
      }))

      let newViolations = []

      driverAssets?.violations?.map((_violation) => {
        newViolations.push({
          driverViolationCode: _violation.driverViolationCode,
          driverViolationCodeError: ``,
          description: _violation.description,
          descriptionError: ``,
          violationDate: new Date(_violation.violationDate),
          violationDateError: ``,
        })
      })

      setViolations(newViolations)
    }
  }, [driverAssets])
  //driver add mutation
  const {
    mutate: mutateDriver,
    isLoading: updatingDriver,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/assets/${customerId}/type/drivers/item/${driverId}`,
        {
          driver: { ...payload },
        },
      ).then((res) => {
        if (
          res?.error?.error.includes(`Incoming request body can't be empty.`)
        ) {
          setIsFormChanged(true)
        }
        !res.hasError &&
          history.push(
            `/admin/customers/${customerId}/asset/driver/${driverId}`,
          )
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  return (
    <Container>
      {updationError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      {isFormChanged && (
        <ReactNotification action="error" message="No Field Changed" />
      )}

      <PageHeader>
        <div>Update Driver</div>
      </PageHeader>
      <Spinner loading={updatingDriver} />

      <div
        className={`p-4 ${updatingDriver && `opacity-30 pointer-events-none`} `}
      >
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name"
              placeholder="Enter Name"
              value={driverAsset.name}
              errorMessage={errors.nameError}
              setValue={(e) => handleDriverAssetChange(`name`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Prefix"
              placeholder="Enter Name Prefix"
              value={driverAsset.prefix}
              errorMessage={errors.prefixError}
              setValue={(e) =>
                handleDriverAssetChange(`prefix`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Suffix"
              placeholder="Enter Name Suffix"
              value={driverAsset.suffix}
              errorMessage={errors.suffixError}
              setValue={(e) =>
                handleDriverAssetChange(`suffix`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Gender"
              placeholder="Select Gender"
              value={driverAsset.gender}
              errorMessage={errors.genderError}
              setValue={(value) =>
                setDriverAsset((prevState) => ({
                  ...prevState,
                  [`gender`]: value,
                }))
              }
              isMulti={false}
              loadOptions={loadGenderOptions}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <DatePickerField
              name="dateOfBirth"
              title="DOB"
              placeholder="Enter Date Of Birth"
              value={driverAsset.dateOfBirth}
              errorMessage={errors.dateOfBirthError}
              setValue={(date) => {
                setDriverAsset((preState) => ({
                  ...preState,
                  [`dateOfBirth`]: date,
                }))
              }}
            />
          </div>
        </div>
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Marital Status"
              placeholder="Select  Status"
              value={driverAsset.maritalStatus}
              errorMessage={errors.maritalStatusError}
              setValue={(value) =>
                setDriverAsset((prevState) => ({
                  ...prevState,
                  [`maritalStatus`]: value,
                }))
              }
              isMulti={false}
              loadOptions={loadMaritalStatusOptions}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Education"
              placeholder="Enter Education"
              value={driverAsset.education}
              errorMessage={errors.educationError}
              setValue={(e) =>
                handleDriverAssetChange(`education`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Email"
              placeholder="Enter Email"
              value={driverAsset.email}
              errorMessage={errors.emailError}
              setValue={(e) => handleDriverAssetChange(`email`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Phone"
              placeholder="Enter Phone No"
              value={driverAsset.phone}
              errorMessage={errors.phoneError}
              setValue={(e) => handleDriverAssetChange(`phone`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Extension"
              placeholder="Enter Extension"
              value={driverAsset.ext}
              errorMessage={errors.extError}
              setValue={(e) => handleDriverAssetChange(`ext`, e.target.value)}
            />
          </div>
        </div>

        <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Address
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              placeholder="Enter Unit No"
              value={address.unitNo}
              errorMessage={errors.unitNoError}
              setValue={(e) => handleAddressChange(`unitNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip"
              name="zip"
              placeholder="Select Zip"
              isMulti={false}
              value={address.zip}
              errorMessage={errors.zipError}
              setValue={(e) => handleZipChange(e)}
              loadOptions={loadZipCodes}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street"
              placeholder="Enter Street"
              value={address.streetNo}
              errorMessage={errors.streetNoError}
              setValue={(e) => handleAddressChange(`streetNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="city"
              title="City"
              placeholder="Enter City"
              value={address.city}
              errorMessage={errors.cityError}
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="state"
              title="State"
              placeholder="Enter State"
              value={address.state}
              errorMessage={errors.stateError}
              id="lAddress"
              disabled={true}
            />
          </div>
        </div>
        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError.length !== 0 && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView center={[coordinates.lat, coordinates.lng]} zoom={3} />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div>

        {/* Start General */}
        <div className="mt-4">
          <Collapsable title="General">
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <DatePickerField
                  name="hireDate"
                  title="Hire Date"
                  placeholder="Select Hire Date"
                  value={driverAsset.hireDate}
                  errorMessage={errors.hireDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`hireDate`]: date,
                    }))
                  }}
                />
              </div>

              <div className="flex-1 relative">
                <DatePickerField
                  name="terminationDate"
                  title="Termination Date"
                  placeholder="Select Termination Date"
                  value={driverAsset.terminationDate}
                  errorMessage={errors.terminationDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`terminationDate`]: date,
                    }))
                  }}
                />
              </div>

              <div className="flex-1 relative">
                <ReactSelectField
                  title="DL Year"
                  placeholder="Select Year"
                  value={driverAsset.DLYear}
                  errorMessage={errors.DLYearError}
                  setValue={(value) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`DLYear`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadYears}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="DL Number"
                  placeholder="Enter DLNumber"
                  value={driverAsset.DLNumber}
                  errorMessage={errors.DLNumberError}
                  setValue={(e) =>
                    handleDriverAssetChange(`DLNumber`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="DL State"
                  placeholder="Select DLState"
                  value={driverAsset.DLState}
                  errorMessage={errors.DLStateError}
                  setValue={(value) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`DLState`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadDLStates}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="Social Security No"
                  placeholder="Enter Social Security No"
                  value={driverAsset.ssn}
                  errorMessage={errors.ssnError}
                  setValue={(e) =>
                    handleDriverAssetChange(`ssn`, e.target.value)
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Industry"
                  placeholder="Select "
                  value={driverAsset.industry}
                  errorMessage={errors.industryError}
                  setValue={(industry) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`industry`]: industry,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadIndustries}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Occupation"
                  placeholder="Select Occupat"
                  value={driverAsset.occupations}
                  errorMessage={errors.occupationsError}
                  setValue={(occupation) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`occupations`]: occupation,
                    }))
                  }
                  isMulti={true}
                  loadOptions={loadOccupations}
                  isSearchable={true}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="Company Driver Number"
                  placeholder="Enter Company Driver Number"
                  value={driverAsset.companyDriverNumber}
                  errorMessage={errors.companyDriverNumberError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `companyDriverNumber`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="Country Code"
                  placeholder="Enter Country Code"
                  value={driverAsset.countryCode}
                  errorMessage={errors.countryCodeError}
                  setValue={(e) =>
                    handleDriverAssetChange(`countryCode`, e.target.value)
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="First Licensed  State"
                  placeholder="Enter First Licensed Current State"
                  value={driverAsset.firstLicensedCurrentState}
                  errorMessage={errors.firstLicensedCurrentStateError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `firstLicensedCurrentState`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <DatePickerField
                  title="Licensed Date"
                  name="licensedDate"
                  placeholder="Select License Year"
                  value={driverAsset.licensedDate}
                  errorMessage={errors.licensedDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`licensedDate`]: date,
                    }))
                  }}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Assigned Risk ReasonCode"
                  placeholder="Select Reason"
                  value={driverAsset.assignedRiskReasonCode}
                  errorMessage={errors.assignedRiskReasonCodeError}
                  setValue={(value) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`assignedRiskReasonCode`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadRisksReasonCode}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <DatePickerField
                  title="Defensive Driver Date"
                  name="defensiveDriverDate"
                  placeholder="Select Date"
                  value={driverAsset.defensiveDriverDate}
                  errorMessage={errors.defensiveDriverDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`defensiveDriverDate`]: date,
                    }))
                  }}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="Defensive Driver Code"
                  placeholder="Enter Defensive Driver Code"
                  value={driverAsset.defensiveDriverCode}
                  errorMessage={errors.defensiveDriverCodeError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `defensiveDriverCode`,
                      e.target.value,
                    )
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <DatePickerField
                  title="Defensive Driver Exp Date"
                  name="defensiveDriverExpirationDate"
                  placeholder="Select Date"
                  value={driverAsset.defensiveDriverExpirationDate}
                  errorMessage={errors.defensiveDriverExpirationDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`defensiveDriverExpirationDate`]: date,
                    }))
                  }}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="Distant Student Indicator"
                  placeholder="Enter Distant Student Indicator"
                  value={driverAsset.distantStudentIndicator}
                  errorMessage={errors.distantStudentIndicatorError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `distantStudentIndicator`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="Driver Assigned Risk Indicator"
                  placeholder="Enter Driver Assigned Risk Indicator"
                  value={driverAsset.driverAssignedRiskIndicator}
                  errorMessage={errors.driverAssignedRiskIndicatorError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `driverAssignedRiskIndicator`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Driver Relation To Applicant Code"
                  placeholder="Select Relation"
                  value={driverAsset.driverRelationshipToApplicantCode}
                  errorMessage={errors.driverRelationshipToApplicantCodeError}
                  setValue={(value) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`driverRelationshipToApplicantCode`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadDriverRelationtoApplic}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <DatePickerField
                  title="Driver Training Completion Date"
                  name="driverTrainingCompletionDate"
                  placeholder="Select Completion Date"
                  value={driverAsset.driverTrainingCompletionDate}
                  errorMessage={errors.driverTrainingCompletionDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`driverTrainingCompletionDate`]: date,
                    }))
                  }}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="Driver Training Indicator"
                  placeholder="Enter Driver Training Indicator"
                  value={driverAsset.driverTrainingIndicator}
                  errorMessage={errors.driverTrainingIndicatorError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `driverTrainingIndicator`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Driver Type Code"
                  placeholder="Select  Code"
                  value={driverAsset.driverTypeCode}
                  errorMessage={errors.driverTypeCodeError}
                  setValue={(value) =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`driverTypeCode`]: value,
                    }))
                  }
                  isMulti={false}
                  loadOptions={loadDriverTypeCode}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <DatePickerField
                  title="Reinstatement Date"
                  name="reinstatementdate"
                  placeholder="Select Date"
                  value={driverAsset.reinstatementdate}
                  errorMessage={errors.reinstatementdateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`reinstatementdate`]: date,
                    }))
                  }}
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="Restricted Indicator"
                  placeholder="Enter Restricted Indicator"
                  value={driverAsset.restrictedIndicator}
                  errorMessage={errors.restrictedIndicatorError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `restrictedIndicator`,
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className="flex-1 relative">
                <DatePickerField
                  title="Suspension Revocation Date"
                  name="suspensionRevocationDate"
                  placeholder="Select Date"
                  value={driverAsset.suspensionRevocationDate}
                  errorMessage={errors.suspensionRevocationDateError}
                  setValue={(date) => {
                    setDriverAsset((preState) => ({
                      ...preState,
                      [`suspensionRevocationDate`]: date,
                    }))
                  }}
                />
              </div>
              <div className="flex-1 relative">
                <InputField
                  type="text"
                  title="Suspension Revocation Reason Code"
                  placeholder="Enter Suspension Revocation Reason Code"
                  value={driverAsset.suspensionRevocationReasonCode}
                  errorMessage={errors.suspensionRevocationReasonCodeError}
                  setValue={(e) =>
                    handleDriverAssetChange(
                      `suspensionRevocationReasonCode`,
                      e.target.value,
                    )
                  }
                />
              </div>
            </div>
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative ">
                <Checkbox
                  title="isExecuted"
                  name="isExecuted"
                  value={driverAsset.isExecuted}
                  errorMessage={errors.isExecutedError}
                  setValue={() =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`isExecuted`]: !driverAsset.isExecuted,
                    }))
                  }
                />
              </div>
              <div className="flex-1 relative ">
                <Checkbox
                  title="isCreateContact"
                  name="isCreateContact"
                  value={driverAsset.isCreateContact}
                  errorMessage={errors.isCreateContactError}
                  setValue={() =>
                    setDriverAsset((prevState) => ({
                      ...prevState,
                      [`isCreateContact`]: !driverAsset.isCreateContact,
                    }))
                  }
                />
              </div>
              <div className="flex-1 relative "></div>
              <div className="flex-1 relative "></div>
            </div>
          </Collapsable>
        </div>
        {/* End General */}

        {/* Start Violations */}
        <div className="my-4">
          <Collapsable title="Violations">
            {violations?.map((violations, index) => (
              <div key={index} className="grid grid-cols-12">
                <div className="col-span-10 flex gap-6 ">
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Driver Violation Code"
                      placeholder="Enter Driver Violation Code"
                      value={violations.driverViolationCode}
                      errorMessage={violations.driverViolationCodeError}
                      setValue={(e) =>
                        handleViolationsFormChange(
                          e.target.value,
                          `driverViolationCode`,
                          index,
                        )
                      }
                    />
                  </div>
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Description"
                      placeholder="Enter Description"
                      value={violations.description}
                      errorMessage={violations.descriptionError}
                      setValue={(e) =>
                        handleViolationsFormChange(
                          e.target.value,
                          `description`,
                          index,
                        )
                      }
                    />
                  </div>
                  <div className="flex-1 relative">
                    <DatePickerField
                      title="Violation Date"
                      placeholder="Enter Violation Date"
                      value={violations.violationDate}
                      errorMessage={violations.violationDateError}
                      setValue={(e) =>
                        handleViolationsFormChange(e, `violationDate`, index)
                      }
                    />
                  </div>
                </div>
                <div className="col-span-2 mt-7">
                  <div className="flex items-center justify-center ">
                    <span
                      onClick={() => addViolationForm()}
                      data-tip="Add Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500  cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="transform transition  hover:-translate-y-0  hover:scale-105"
                      />
                    </span>
                    <span
                      onClick={() => removeViolationForm(index)}
                      data-tip="Remove Form"
                      className="tooltip px-2 py-1.5 hover:text-red-500  cursor-pointer "
                    >
                      <FontAwesomeIcon
                        icon={faMinus}
                        className="transform transition  hover:-translate-y-0  hover:scale-105"
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Collapsable>
        </div>
        {/*end Violations */}
      </div>
      <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
        <SubmitButton onClick={ValidForm}>Update Driver</SubmitButton>
      </div>
    </Container>
  )
}
