import {
  faChevronCircleDown,
  faPencilAlt,
  faSpinner,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Transition } from '@windmill/react-ui'
import { error } from 'App/Assets'
import React, { useState, useEffect } from 'react'

export const Collapsable = ({
  title,
  editAble,
  deleteAble,
  isLoading,
  isActive,
  children,
  unCollapse,
  isSticky,
  headerTitle,
  titleIcon,
}) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (isActive) {
      setActive(false)
    }
    if (unCollapse) {
      setActive(unCollapse)
    }
  }, [isActive, unCollapse])

  return (
    <>
      <div
        className={`flex flex-row justify-between bg-white py-1.5 border-1 items-center px-3 cursor-pointer  ${
          active ? `rounded-t-md` : `rounded-md`
        }  `}
        onClick={() => {
          setActive(!active)
        }}
      >
        <div
          className={`flex justify-between w-full text-md font-medium text-black
          rounded-t-md capitalize`}
        >
          <div className="flex-1 align-middle">
            {titleIcon && (
              <img src={error} alt="img" className="mr-2 h-4 w-4 inline" />
            )}
            {title}
          </div>
          {headerTitle?.length > 0 && (
            <div className="flex-1  flex justify-between text-sm max-w-xs mr-28 text-gray-600">
              {headerTitle?.map((item, ind) => (
                <span className="" key={ind}>
                  {item}
                </span>
              ))}
            </div>
          )}
        </div>
        <div className="flex gap-3 cursor-pointer">
          {editAble && (
            <div>
              <FontAwesomeIcon
                onClick={editAble}
                icon={faPencilAlt}
                className={`text-white`}
              />
            </div>
          )}
          {deleteAble && (
            <div>
              <FontAwesomeIcon
                onClick={deleteAble}
                icon={isLoading ? faSpinner : faTrashAlt}
                spin={isLoading}
                className={`text-white `}
              />
            </div>
          )}

          {!isSticky ? (
            <div>
              <FontAwesomeIcon
                onClick={() => setActive(!active)}
                icon={faChevronCircleDown}
                className={` text-red-500 transform transition duration-100 ${
                  active && `rotate-180 `
                }`}
              />
            </div>
          ) : null}
        </div>
      </div>
      <Transition
        show={active}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="left-0 rounded-md shadow-sm">
          <div className="py-2 px-4 bg-white rounded-md">{children}</div>
        </div>
      </Transition>
    </>
  )
}
