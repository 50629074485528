import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { Input } from 'App/Styled'

export const AddressValidate = ({
  type,
  name,
  title,
  id,
  disabled,
  placeholder,
  errorMessage,
  tipdata,
  validating,
  inputRef,
  setValue,
  checkFocus,
  setFocusOut,
  requiredValue,
}) => {
  return (
    <>
      <label
        data-tip={tipdata}
        className={` ${tipdata && `tooltip`} text-gray-700 font-medium text-sm`}
      >
        {title}
        {tipdata && <span className="tooltip"></span>}

        {/* {(errorMessage || requiredValue) && (
          <span className="text-red-500 required-dot">*</span>
        )} */}
      </label>
      <Input
        type={type}
        name={name}
        id={id}
        ref={typeof inputRef === `string` ? null : inputRef}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={() => {
          setValue()
          checkFocus && setFocusOut(true)
        }}
        onFocus={() => checkFocus && setFocusOut(false)}
        requiredvalue={requiredValue}
        className={`${requiredValue && `bg-yellow-50`} `}
      />
      {errorMessage && (
        <>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="absolute text-red-500 right-2 mt-3"
          />
          <p className="text-sm text-red-500">{errorMessage}</p>
        </>
      )}
      {validating && (
        <FontAwesomeIcon
          icon={faSpinner}
          className="absolute text-red-500 right-2 mt-3"
          spin={true}
        />
      )}
    </>
  )
}
