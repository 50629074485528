/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { CardField } from 'App/Components/Common/CardField'
import { useDispatch } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router'
import { Container, FormTab, PageHeader, StyledButton } from 'App/Styled'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import { setCommunicationCredentials, setCurrentQuote } from 'App/Redux/actions'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useQuery } from 'react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Reload } from 'App/Components/Common/Reload'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import dateFormat from 'App/Services/General/dateFormat'
import { permissionGranted } from 'App/Services'

export const AboutQuote = () => {
  const history = useHistory()

  const {
    params: { quoteId },
  } = useRouteMatch()
  const dispatch = useDispatch()
  const [driversTabs, setDriversTab] = useState(``)
  const [activeDriverTab, setActiveDriverTab] = useState(1)
  const [vehiclesTabs, setVehiclesTab] = useState(``)
  const [activeVehicleTab, setActiveVehicleTab] = useState(1)
  const {
    data: previewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `QuotePreviewApi`,
    async () =>
      await AxiosInstance.get(`${AMS_BACKEND_API}/api/quotes/${quoteId}`).then(
        (res) => res?.data?.data?.quote,
      ),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (res?.policyData?.drivers?.length > 0) {
          setDriversTab(
            res?.policyData?.drivers?.map((driver, index) => {
              return {
                name: driver?.firstName + ` ` + driver?.lastName,
                number: index + 1,
              }
            }),
          )
        }

        // sort vehicles tabs

        if (res?.policyData?.cars?.length > 0) {
          setVehiclesTab(
            res?.policyData?.cars?.map((vehicle, index) => {
              return {
                name: vehicle?.model,
                number: index + 1,
              }
            }),
          )
        }

        if (res) {
          dispatch(
            setCommunicationCredentials({
              id: res._id,
              phone: res.policyData?._customer?.contactInfo?.phone,
              customerId: res.policyData?._customer?._id,
              img: res.img,
              doNotContact: res?.metadata?.doNotContact ? true : false,
              preferredContactMethods: res?.metadata?.preferredContactMethods,
              email: res.policyData?._customer?.contactInfo?.email,
            }),
          )

          dispatch(setCurrentQuote(res))
        }
      },
    },
  )

  const rerateQuote = () => {
    history.push(`/raters/myquotes/${previewData?._id}/rerate`)
  }
  const updateQuote = () => {
    history.push(`/raters/myquotes/${previewData?._id}/update`)
  }

  return (
    <Container>
      <PageHeader className="flex justify-between items-center px-6">
        <div>My Quote</div>
        {!isLoading && (
          <>
            {permissionGranted([
              `quotes:update`,
              `corporate-manager:*`,
              `manager:*`,
              `admin:*`,
            ]) && (
              <div className="flex-1">
                <StyledButton
                  simple={true}
                  onClick={rerateQuote}
                  style={{ fontSize: `16px` }}
                  className="ml-3"
                >
                  Rerate
                </StyledButton>
                {previewData?.status !== `POLICY` && (
                  <StyledButton
                    simple={true}
                    onClick={updateQuote}
                    style={{ fontSize: `16px` }}
                  >
                    Update
                  </StyledButton>
                )}
              </div>
            )}
          </>
        )}
      </PageHeader>

      <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md">
        {isLoading || isFetching ? (
          <div className="text-center p-6">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-600"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : (
          <div className="rounded-md flex flex-col space-y-1 overflow-hidden mx-4 py-4">
            <div className="grid grid-cols-12 py-2">
              <div className="col-span-6 text-xl">About</div>
            </div>
            <hr />
            <div className="flex items-start justify-evenly">
              <div className="flex-1 px-4 rounded">
                {previewData?.status ? (
                  <CardField
                    title="Quote Status"
                    value={[previewData?.status]}
                    multiple={true}
                  />
                ) : (
                  ``
                )}
                {previewData?._quoteId ? (
                  <CardField title="Quote No" value={previewData?._quoteId} />
                ) : (
                  ``
                )}
                {previewData?.policyMetadata?.priceSold ? (
                  <CardField
                    title="Price Sold"
                    value={`$ ${previewData?.policyMetadata?.priceSold} `}
                  />
                ) : (
                  ``
                )}

                {previewData?.quoteType ? (
                  <CardField
                    title="Quote Type"
                    value={previewData?.quoteType}
                  />
                ) : (
                  ``
                )}
                {previewData?._turboRaterId ? (
                  <CardField
                    title="Turbo Rater ID"
                    value={previewData?._turboRaterId}
                    badge={true}
                  />
                ) : (
                  ``
                )}
                {previewData?.turboRaterUrl ? (
                  <CardField
                    title="Turbo Rater URL"
                    value={previewData?.turboRaterUrl}
                    link={true}
                  />
                ) : (
                  ``
                )}
              </div>
              <div className="flex-1 mx-2 rounded">
                {previewData?.metadata?.contactMethod ? (
                  <CardField
                    title="Contact Method"
                    value={previewData?.metadata?.contactMethod}
                  />
                ) : (
                  ``
                )}
                {previewData?.policyData?._customer?.preferredContactMethods
                  .length ? (
                  <CardField
                    title="Preferred Contact"
                    value={
                      previewData?.policyData?._customer
                        ?.preferredContactMethods
                    }
                    multiple={true}
                  />
                ) : (
                  ``
                )}
                {previewData?.metadata?.leadSource ? (
                  <CardField
                    title="Lead Source"
                    value={previewData?.metadata?.leadSource}
                  />
                ) : (
                  ``
                )}
                {previewData?.metadata?.quoteDescription ? (
                  <CardField
                    title="Description"
                    value={previewData?.metadata?.quoteDescription}
                  />
                ) : (
                  ``
                )}
              </div>
            </div>

            <div className="grid grid-cols-12 py-2">
              <div className="col-span-6 text-xl">Policy Data</div>
              {previewData?.policyMetadata?.priceSold?.length ? (
                <div className="col-span-6 text-xl">Policy Meta Data</div>
              ) : (
                ``
              )}
            </div>
            <hr />
            <div className="grid grid-cols-12 py-2">
              <div className="col-span-6 mx-4 rounded">
                {previewData?.policyData?.policyTerm ? (
                  <CardField
                    title="Policy Term"
                    value={previewData?.policyData?.policyTerm}
                  />
                ) : (
                  ``
                )}
                {previewData?.policyData?.nonOwner ? (
                  <CardField
                    title="Non Owner"
                    value={previewData?.policyData?.nonOwner}
                  />
                ) : (
                  ``
                )}
                {previewData?.policyData?.exclusions ? (
                  <CardField
                    title="Exclusions"
                    value={previewData?.policyData?.exclusions}
                  />
                ) : (
                  ``
                )}
                {previewData?.policyData?.broadform ? (
                  <CardField
                    title="BroadForm"
                    value={previewData?.policyData?.broadform}
                  />
                ) : (
                  ``
                )}
                {/* <CardField title="Coverages" value={``} /> */}
              </div>
              <div className="col-span-6">
                {previewData?.policyMetadata?.priceSold ? (
                  <CardField
                    title="policyNo"
                    value={previewData?.policyMetadata?.priceSold}
                  />
                ) : (
                  ``
                )}
                {previewData?.policyMetadata?.effectiveDate ? (
                  <CardField
                    title="effectiveDate"
                    value={dateFormat(
                      previewData?.policyMetadata?.effectiveDate,
                    )}
                  />
                ) : (
                  ``
                )}
                {previewData?.policyMetadata?.expiryDate ? (
                  <CardField
                    title="expiryDate"
                    value={dateFormat(previewData?.policyMetadata?.expiryDate)}
                  />
                ) : (
                  ``
                )}
                {/* {previewData?.updateLogs?.map((upLogs) => {
                  return (
                    <CardField
                      title={upLogs?.update?.field}
                      value={upLogs?.update?.value}
                    />
                  )
                })} */}
              </div>
            </div>
            <div className="grid grid-cols-12 py-2">
              <div className="col-span-6 text-xl">Creator</div>
            </div>
            <hr />
            <div className="flex items-start justify-evenly mx-4">
              <div className="flex-1 rounded">
                <div className="grid grid-cols-12 py-2">
                  <div className="col-span-6">
                    <CardField
                      title="Name"
                      value={`${previewData?._creator?.firstName} ${previewData?._creator?.lastName}`}
                    />

                    {/* <CardField
                      title="Last Name"
                      value={previewData?._creator?.lastName}
                    /> */}
                    {/* <CardField
                      title="Designation"
                      value={previewData?._creator?.designation?.name}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            <CollapsableRef title="Customer">
              <div className="grid grid-cols-12 py-2">
                <div className="col-span-6 mx-4 rounded">
                  {previewData?.policyData?._customer?.firstName ? (
                    <CardField
                      title="Name"
                      value={
                        previewData?.policyData?._customer?.firstName +
                        ` ` +
                        previewData?.policyData?._customer?.lastName
                      }
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.policyData?._customer?.contactInfo?.phone ? (
                    <CardField
                      title="Phone No"
                      value={
                        previewData?.policyData?._customer?.contactInfo?.phone
                      }
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.policyData?._customer?.contactInfo?.email ? (
                    <CardField
                      title="Email"
                      value={
                        previewData?.policyData?._customer?.contactInfo?.email
                      }
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.policyData?._customer?.dob ? (
                    <CardField
                      title="DOB"
                      value={dateFormat(
                        previewData?.policyData?._customer?.dob,
                      )}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.policyData?._customer?.residenceStatus ? (
                    <CardField
                      title="Residence Status"
                      value={
                        previewData?.policyData?._customer?.residenceStatus
                      }
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.policyData?._customer?.residenceType ? (
                    <CardField
                      title="residence Type"
                      value={previewData?.policyData?._customer?.residenceType}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.policyData?._customer?.createdAt ? (
                    <CardField
                      title="createdAt"
                      value={dateFormat(
                        previewData?.policyData?._customer?.createdAt,
                      )}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.policyData?._customer?.updatedAt ? (
                    <CardField
                      title="updatedAt"
                      value={dateFormat(
                        previewData?.policyData?._customer?.updatedAt,
                      )}
                    />
                  ) : (
                    ``
                  )}
                </div>
                <div className="col-span-6 mx-4 rounded">
                  {/* <CardField
                    title="Education"
                    value={previewData?.policyData?._customer?.educationLevel}
                  />
                  <CardField
                    title="gender"
                    value={previewData?.policyData?._customer?.gender}
                  />
                  <CardField
                    title="marital Status"
                    value={previewData?.policyData?._customer?.maritalStatus}
                  /> */}
                  {previewData?.policyData?._customer
                    ?.preferredContactMethods ? (
                    <CardField
                      title="preferred Contact Method"
                      value={
                        previewData?.policyData?._customer
                          ?.preferredContactMethods
                      }
                      multiple={true}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.policyData?._customer
                    ?.monthsAtCurrentAddress ? (
                    <CardField
                      title="monthsAt Current Address"
                      value={
                        previewData?.policyData?._customer
                          ?.monthsAtCurrentAddress
                      }
                    />
                  ) : (
                    ``
                  )}
                  <CardField
                    title="is Insured"
                    value={
                      previewData?.policyData?._customer?.isInsured
                        ? `True`
                        : `False`
                    }
                  />
                  <CardField
                    title="Don't Contact"
                    value={
                      previewData?.policyData?._customer?.doNotContact
                        ? `True`
                        : `False`
                    }
                  />
                  <CardField
                    title="employment Status"
                    value={
                      previewData?.policyData?._customer?.employmentStatus
                        ? `True`
                        : `False`
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 capitalize my-2">
                {previewData?.policyData?._customer?.address ? (
                  <div className="col-span-6 text-xl">Address </div>
                ) : (
                  ``
                )}
                {previewData?.policyData?._customer?.priorAddress ? (
                  <div className="col-span-6 text-xl">Prior Address</div>
                ) : (
                  ``
                )}
              </div>
              {previewData?.policyData?._customer?.address ||
              previewData?.policyData?._customer?.priorAddress ? (
                <hr />
              ) : (
                ``
              )}
              <div className="grid grid-cols-12 capitalize my-2">
                {previewData?.policyData?._customer?.address ? (
                  <div className="col-span-6 text-xl">
                    {previewData?.policyData?._customer?.address?.state ? (
                      <CardField
                        title="State"
                        value={
                          previewData?.policyData?._customer?.address?.state
                        }
                      />
                    ) : (
                      ``
                    )}
                    {previewData?.policyData?._customer?.address?.city ? (
                      <CardField
                        title="City"
                        value={
                          previewData?.policyData?._customer?.address?.city
                        }
                      />
                    ) : (
                      ``
                    )}
                    {previewData?.policyData?._customer?.address?.street ? (
                      <CardField
                        title="Street"
                        value={
                          previewData?.policyData?._customer?.address?.street
                        }
                      />
                    ) : (
                      ``
                    )}
                    {previewData?.policyData?._customer?.address?.zip ? (
                      <CardField
                        title="Zip"
                        value={previewData?.policyData?._customer?.address?.zip}
                      />
                    ) : (
                      ``
                    )}
                  </div>
                ) : (
                  ``
                )}
                {previewData?.policyData?._customer?.priorAddress ? (
                  <div className="col-span-6 text-xl">
                    {previewData?.policyData?._customer?.priorAddress?.state ? (
                      <CardField
                        title="State"
                        value={
                          previewData?.policyData?._customer?.priorAddress
                            ?.state
                        }
                      />
                    ) : (
                      ``
                    )}
                    {previewData?.policyData?._customer?.priorAddress?.city ? (
                      <CardField
                        title="City"
                        value={
                          previewData?.policyData?._customer?.priorAddress?.city
                        }
                      />
                    ) : (
                      ``
                    )}
                    {previewData?.policyData?._customer?.priorAddress
                      ?.street ? (
                      <CardField
                        title="Street"
                        value={
                          previewData?.policyData?._customer?.priorAddress
                            ?.street
                        }
                      />
                    ) : (
                      ``
                    )}
                    {previewData?.policyData?._customer?.priorAddress?.zip ? (
                      <CardField
                        title="Zip"
                        value={
                          previewData?.policyData?._customer?.priorAddress?.zip
                        }
                      />
                    ) : (
                      ``
                    )}
                  </div>
                ) : (
                  ``
                )}
              </div>
              <div className="grid grid-cols-12 capitalize my-2">
                {previewData?.policyData?.coverages.length ? (
                  <div className="col-span-4 text-xl">Coverages</div>
                ) : (
                  ``
                )}
              </div>
              <hr />

              {previewData?.policyData?.coverages.length ? (
                <div className="my-2">
                  <div className="grid grid-cols-12 font-medium text-gray-700">
                    <div className="col-span-3">Rated</div>
                    <div className="col-span-3">Name</div>
                    <div className="col-span-3">Code</div>
                    <div className="col-span-3">Limits</div>
                  </div>
                  {previewData?.policyData?.coverages.map(
                    (eachCoverage, index) => {
                      return (
                        <div
                          className="grid grid-cols-12 text-sm text-gray-500 my-1.5"
                          key={`coverage` + index}
                        >
                          <div className="col-span-3">
                            {eachCoverage?.rated}
                          </div>
                          <div className="col-span-3">{eachCoverage?.name}</div>
                          <div className="col-span-3">{eachCoverage?.code}</div>
                          <div className="col-span-3">
                            {eachCoverage?.limits
                              ? eachCoverage?.limits
                              : `NONE`}
                          </div>
                        </div>
                      )
                    },
                  )}
                </div>
              ) : (
                ``
              )}
            </CollapsableRef>

            {/* 3rd Row */}
            <CollapsableRef title="Cars">
              <Tabs2
                openTab={activeVehicleTab}
                tabs={vehiclesTabs}
                getActiveTab={(val) => setActiveVehicleTab(val)}
              />
              {previewData?.policyData?.cars?.map((car, index) => (
                <FormTab
                  key={index}
                  currenttab={index + 1}
                  opentab={activeVehicleTab}
                  className="mt-2 shadow-sm rounded-md relative"
                >
                  <div className="flex items-start justify-evenly mx-4">
                    <div className="flex-1 rounded">
                      {car?.model ? (
                        <CardField title="Model" value={car?.model} />
                      ) : (
                        ``
                      )}
                      {car?.make ? (
                        <CardField title="make" value={car?.make} />
                      ) : (
                        ``
                      )}
                      {car?.year ? (
                        <CardField title="year" value={car?.year} />
                      ) : (
                        ``
                      )}
                      {car?.vin ? (
                        <CardField title="vin" value={car?.vin} />
                      ) : (
                        ``
                      )}
                      {car?.licensePlateNo ? (
                        <CardField
                          title="license Plate No"
                          value={car?.licensePlateNo}
                        />
                      ) : (
                        ``
                      )}
                      {car?.actualCashValue ? (
                        <CardField
                          title="actual Cash Value"
                          value={car?.actualCashValue}
                        />
                      ) : (
                        ``
                      )}
                      {/* Cars Row 1 */}
                      <div className="grid grid-cols-12 capitalize my-2">
                        <div className="col-span-4 text-xl">usage</div>
                        <div className="col-span-4 text-xl">location</div>
                        <div className="col-span-4 text-xl">garage</div>
                      </div>
                      <hr />
                      <div className="grid grid-cols-12">
                        <div className="col-span-4">
                          {car?.usage?.basicUse ? (
                            <CardField
                              title="basic Use"
                              value={car?.usage?.basicUse}
                            />
                          ) : (
                            ``
                          )}
                          {car?.usage?.isUsedForRideSharing ? (
                            <CardField
                              title="For RideSharing"
                              value={car?.usage?.isUsedForRideSharing}
                            />
                          ) : (
                            ``
                          )}
                          {car?.usage?.percentDrivenToWork ? (
                            <CardField
                              title="percent Work"
                              value={car?.usage?.percentDrivenToWork}
                            />
                          ) : (
                            ``
                          )}
                          {car?.usage?.milesDrivenToWork ? (
                            <CardField
                              title="miles Work"
                              value={car?.usage?.milesDrivenToWork}
                            />
                          ) : (
                            ``
                          )}
                          {car?.usage?.annualMileage ? (
                            <CardField
                              title="annual Mileage"
                              value={car?.usage?.annualMileage}
                            />
                          ) : (
                            ``
                          )}
                          {car?.usage?.odometerReading ? (
                            <CardField
                              title="odometer Reading"
                              value={car?.usage?.odometerReading}
                            />
                          ) : (
                            ``
                          )}
                        </div>
                        <div className="col-span-4">
                          {car?.garage?.isGaraged ? (
                            <CardField
                              title="isGaraged"
                              value={car?.garage?.isGaraged}
                            />
                          ) : (
                            ``
                          )}
                          {car?.garage?.location?.zip ? (
                            <CardField
                              title="zip "
                              value={car?.garage?.location?.zip}
                            />
                          ) : (
                            ``
                          )}
                          {car?.garage?.location?.state ? (
                            <CardField
                              title=" state"
                              value={car?.garage?.location?.state}
                            />
                          ) : (
                            ``
                          )}
                          {car?.garage?.location?.city ? (
                            <CardField
                              title="city"
                              value={car?.garage?.location?.city}
                            />
                          ) : (
                            ``
                          )}
                          {car?.garage?.location?.street ? (
                            <CardField
                              title="street"
                              value={car?.garage?.location?.street}
                            />
                          ) : (
                            ``
                          )}
                        </div>
                        <div className="col-span-4">
                          {car?.location?.zip ? (
                            <CardField
                              title="zip "
                              value={car?.location?.zip}
                            />
                          ) : (
                            ``
                          )}
                          {car?.location?.state ? (
                            <CardField
                              title=" state"
                              value={car?.location?.state}
                            />
                          ) : (
                            ``
                          )}
                          {car?.location?.city ? (
                            <CardField
                              title="city"
                              value={car?.location?.city}
                            />
                          ) : (
                            ``
                          )}
                        </div>
                      </div>

                      {/* Cars Row 2 */}
                      <div className="grid grid-cols-12 capitalize my-2">
                        <div className="col-span-4 text-xl">Model Details</div>
                        <div className="col-span-4 text-xl"></div>
                      </div>
                      <hr />

                      <div className="grid grid-cols-12">
                        <div className="col-span-6">
                          {car?.modelDetails?.vehicleType ? (
                            <CardField
                              title="vehicle Type"
                              value={car?.modelDetails?.vehicleType}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.fuelType ? (
                            <CardField
                              title="fuel Type"
                              value={car?.modelDetails?.fuelType}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.truckSize ? (
                            <CardField
                              title="truck Size"
                              value={car?.modelDetails?.truckSize}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.cylinders ? (
                            <CardField
                              title="cylinders"
                              value={car?.modelDetails?.cylinders}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.is4wd ? (
                            <CardField
                              title="is4wd"
                              value={car?.modelDetails?.is4wd}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.is4ws ? (
                            <CardField
                              title="is4ws"
                              value={car?.modelDetails?.is4ws}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.isTurboCharged ? (
                            <CardField
                              title="isTurboCharged"
                              value={car?.modelDetails?.isTurboCharged}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.airBags ? (
                            <CardField
                              title="airBags"
                              value={car?.modelDetails?.airBags}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.antiLock ? (
                            <CardField
                              title="antiLock"
                              value={car?.modelDetails?.antiLock}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.antiTheft ? (
                            <CardField
                              title="antiTheft"
                              value={car?.modelDetails?.antiTheft}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.color ? (
                            <CardField
                              title="color"
                              value={car?.modelDetails?.color}
                            />
                          ) : (
                            ``
                          )}
                        </div>
                        <div className="col-span-6">
                          {car?.modelDetails?.passiveRestraint ? (
                            <CardField
                              title="passive Restraint"
                              value={car?.modelDetails?.passiveRestraint}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.isLeased ? (
                            <CardField
                              title="isLeased"
                              value={car?.modelDetails?.isLeased}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.isSalvaged ? (
                            <CardField
                              title="isSalvaged"
                              value={car?.modelDetails?.isSalvaged}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.isGreyMarketVehicle ? (
                            <CardField
                              title="isGrey Vehicle"
                              value={car?.modelDetails?.isGreyMarketVehicle}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.hasMonitoringDevice ? (
                            <CardField
                              title="Monitoring Device"
                              value={car?.modelDetails?.hasMonitoringDevice}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.isDualie ? (
                            <CardField
                              title="isDualie"
                              value={car?.modelDetails?.isDualie}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.hasRunningLights ? (
                            <CardField
                              title="Running Lights"
                              value={car?.modelDetails?.hasRunningLights}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.hasHoodLockingDevice ? (
                            <CardField
                              title="Hood Locking Device"
                              value={car?.modelDetails?.hasHoodLockingDevice}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.hasHomingDevice ? (
                            <CardField
                              title="Homing Device"
                              value={car?.modelDetails?.hasHomingDevice}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.hasVinEtching ? (
                            <CardField
                              title="Vin Etching"
                              value={car?.modelDetails?.hasVinEtching}
                            />
                          ) : (
                            ``
                          )}
                          {car?.modelDetails?.msrp ? (
                            <CardField
                              title="MSRP"
                              value={car?.modelDetails?.msrp}
                            />
                          ) : (
                            ``
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 capitalize my-2">
                        <div className="col-span-4 text-xl">Purchase</div>
                      </div>
                      <hr />
                      <div className="col-span-4 flex flex-col">
                        <div>
                          {car?.purchase?.cost ? (
                            <CardField
                              title="cost"
                              value={car?.purchase?.cost}
                            />
                          ) : (
                            ``
                          )}
                          {car?.purchase?.date ? (
                            <CardField
                              title="date"
                              value={dateFormat(car?.purchase?.date)}
                            />
                          ) : (
                            ``
                          )}
                          {car?.purchase?.type ? (
                            <CardField
                              title="type"
                              value={car?.purchase?.type}
                            />
                          ) : (
                            ``
                          )}
                        </div>
                        <div>
                          <div className="flex-1 rounded">
                            <div className="grid grid-cols-12 ">
                              <div className="col-span-6 text-xl">MetaData</div>
                            </div>
                            <hr />
                            {previewData?.metadata?.contactMethod ? (
                              <CardField
                                title="Contact Method"
                                value={previewData?.metadata?.contactMethod}
                              />
                            ) : (
                              ``
                            )}
                            {previewData?.policyData?._customer
                              ?.preferredContactMethods ? (
                              <CardField
                                title="preferred Contact"
                                value={
                                  previewData?.policyData?._customer
                                    ?.preferredContactMethods
                                }
                                multiple={true}
                              />
                            ) : (
                              ``
                            )}
                            {previewData?.metadata?.acquisitionBy ? (
                              <CardField
                                title="acquisition By"
                                value={previewData?.metadata?.acquisitionBy}
                              />
                            ) : (
                              ``
                            )}
                            {previewData?.metadata?.leadSource ? (
                              <CardField
                                title="lead Source"
                                value={previewData?.metadata?.leadSource}
                              />
                            ) : (
                              ``
                            )}
                            {previewData?.metadata?.quoteDescription ? (
                              <CardField
                                title="quote Description"
                                value={previewData?.metadata?.quoteDescription}
                              />
                            ) : (
                              ``
                            )}
                            {previewData?.metadata?.nativeLanguage ? (
                              <CardField
                                title="native Language"
                                value={previewData?.metadata?.nativeLanguage}
                              />
                            ) : (
                              ``
                            )}
                            {previewData?.metadata?.paperlessDiscount ? (
                              <CardField
                                title="paperless Discount"
                                value={previewData?.metadata?.paperlessDiscount}
                              />
                            ) : (
                              ``
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="grid grid-cols-12 capitalize my-2">
                        {car?.lienholder.length ? (
                          <div className="col-span-4 text-xl">Lien Holder</div>
                        ) : (
                          ``
                        )}
                        {car?.coverages.length ? (
                          <div className="col-span-4 text-xl">Coverages</div>
                        ) : (
                          ``
                        )}
                      </div>
                      <hr />
                      <div className="grid grid-cols-12">
                        {car?.lienholder.length ? (
                          <div className="col-span-4">
                            <CardField
                              title="name"
                              value={car?.lienholder?.name}
                            />
                            <CardField
                              title="Lien holder"
                              value={car?.lienholder?.hasLienholder}
                            />
                            <CardField
                              title="type"
                              value={car?.lienholder?.type}
                            />
                            <CardField
                              title="zip "
                              value={car?.lienholder?.location?.zip}
                            />
                            <CardField
                              title="state"
                              value={car?.lienholder?.location?.state}
                            />
                            <CardField
                              title="street"
                              value={car?.lienholder?.location?.street}
                            />
                            <CardField
                              title="city"
                              value={car?.lienholder?.location?.city}
                            />
                          </div>
                        ) : (
                          ``
                        )}
                        {car?.coverages.length ? (
                          <div className="col-span-12">
                            <div className="grid grid-cols-12 font-medium text-gray-700">
                              <div className="col-span-2">Rated</div>
                              <div className="col-span-2">Name</div>
                              <div className="col-span-2">Code</div>
                              <div className="col-span-2">Limits</div>
                              <div className="col-span-2">Deductibles</div>
                            </div>
                            {car?.coverages.map((eachCoverage, index) => {
                              return (
                                <div
                                  className="grid grid-cols-12 text-sm text-gray-500 my-1.5"
                                  key={`coverage` + index}
                                >
                                  <div className="col-span-2">
                                    {eachCoverage?.rated}
                                  </div>
                                  <div className="col-span-2">
                                    {eachCoverage?.name}
                                  </div>
                                  <div className="col-span-2">
                                    {eachCoverage?.code}
                                  </div>
                                  <div className="col-span-2">
                                    {eachCoverage?.limits
                                      ? eachCoverage?.limits
                                      : `NONE`}
                                  </div>
                                  <div className="col-span-2">
                                    {eachCoverage?.deductible
                                      ? eachCoverage?.deductible
                                      : `NONE`}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        ) : (
                          ``
                        )}
                      </div>
                    </div>
                  </div>
                </FormTab>
              ))}
            </CollapsableRef>

            {/* Drivers Policy Data */}
            <CollapsableRef title="Drivers">
              <Tabs2
                openTab={activeDriverTab}
                tabs={driversTabs}
                getActiveTab={(val) => setActiveDriverTab(val)}
              />
              {previewData?.policyData?.drivers?.map((driver, index) => (
                <FormTab
                  key={index}
                  currenttab={index + 1}
                  opentab={activeDriverTab}
                  className="mt-2 shadow-sm rounded-md relative"
                >
                  <div className="flex items-start justify-evenly mx-4">
                    <div className="flex-1 rounded">
                      <div className="grid grid-cols-12">
                        <div className="col-span-6">
                          {driver?.firstName ? (
                            <CardField
                              title="first Name"
                              value={driver?.firstName}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.middleName ? (
                            <CardField
                              title="middle Name"
                              value={driver?.middleName}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.lastName ? (
                            <CardField
                              title="last Name"
                              value={driver?.lastName}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.relation ? (
                            <CardField
                              title="relation"
                              value={driver?.relation}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.dob ? (
                            <CardField
                              title="dob"
                              value={dateFormat(driver?.dob)}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.gender ? (
                            <CardField title="gender" value={driver?.gender} />
                          ) : (
                            ``
                          )}
                          {driver?.maritalStatus ? (
                            <CardField
                              title="Marital Status"
                              value={driver?.maritalStatus}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.violations?.length ? (
                            <CardField
                              title="violations"
                              value={
                                !driver?.violations?.length
                                  ? `Not Available`
                                  : driver?.violations?.length
                              }
                            />
                          ) : (
                            ``
                          )}
                        </div>
                        <div className="col-span-6">
                          {driver?.isExcluded ? (
                            <CardField
                              title="isExcluded"
                              value={driver?.isExcluded}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.ssn ? (
                            <CardField title="SSN" value={driver?.ssn} />
                          ) : (
                            ``
                          )}
                          {driver?.milesToWork ? (
                            <CardField
                              title="miles To Work"
                              value={driver?.milesToWork}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.isOccasionalDriver ? (
                            <CardField
                              title="Occasional Driver"
                              value={driver?.isOccasionalDriver}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.isSingleParent ? (
                            <CardField
                              title="is Single Parent"
                              value={driver?.isSingleParent}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.hasPropertyInsurance ? (
                            <CardField
                              title="Property Insurance"
                              value={driver?.hasPropertyInsurance}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.priorInsurance?.wasInsured ? (
                            <CardField
                              title="priorInsurance"
                              value={driver?.priorInsurance?.wasInsured}
                            />
                          ) : (
                            ``
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-12 capitalize my-2">
                        <div className="col-span-6 text-xl">license</div>
                        <div className="col-span-6 text-xl">sr22</div>
                      </div>
                      <hr />

                      <div className="grid grid-cols-12">
                        <div className="col-span-6">
                          {driver?.license?.isLicensed ? (
                            <CardField
                              title="isLicensed"
                              value={driver?.license?.isLicensed}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.license?.licenseNo ? (
                            <CardField
                              title="licenseNo"
                              value={driver?.license?.licenseNo}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.license?.suspendedMonths ? (
                            <CardField
                              title="suspended Months"
                              value={driver?.license?.suspendedMonths}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.license?.usLicensedMonths ? (
                            <CardField
                              title="usLicensed Months"
                              value={driver?.license?.usLicensedMonths}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.license?.stateLicensedMonths ? (
                            <CardField
                              title="state Licensed Months"
                              value={driver?.license?.stateLicensedMonths}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.license?.usMVRExperienceMonths ? (
                            <CardField
                              title="usMVR xperience Months"
                              value={driver?.license?.usMVRExperienceMonths}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.license?.foreignLicensedMonths ? (
                            <CardField
                              title="foreign Licensed Months"
                              value={driver?.license?.foreignLicensedMonths}
                            />
                          ) : (
                            ``
                          )}

                          {driver?.license?.isForeignLicensed ? (
                            <CardField
                              title="isForeign Licensed"
                              value={driver?.license?.isForeignLicensed}
                            />
                          ) : (
                            ``
                          )}
                        </div>
                        <div className="col-span-6">
                          <div>
                            {driver?.sr22?.isFiled ? (
                              <CardField
                                title="isFiled"
                                value={driver?.sr22?.isFiled}
                              />
                            ) : (
                              ``
                            )}
                            {driver?.sr22?.filingState ? (
                              <CardField
                                title="filling State"
                                value={driver?.sr22?.filingState}
                              />
                            ) : (
                              ``
                            )}
                            {driver?.sr22?.reason ? (
                              <CardField
                                title="reason"
                                value={driver?.sr22?.reason}
                              />
                            ) : (
                              ``
                            )}
                            {driver?.sr22?.caseNo ? (
                              <CardField
                                title="caseNo"
                                value={driver?.sr22?.caseNo}
                              />
                            ) : (
                              ``
                            )}
                            {driver?.sr22?.filingDate ? (
                              <CardField
                                title="filling Date"
                                value={dateFormat(driver?.sr22?.filingDate)}
                              />
                            ) : (
                              ``
                            )}
                          </div>
                          <div></div>
                        </div>
                      </div>
                      <div className="grid grid-cols-12 capitalize my-2">
                        <div className="col-span-6 text-xl">
                          education employment
                        </div>
                      </div>
                      <hr />

                      <div className="grid grid-cols-12">
                        <div className="col-span-6">
                          {driver?.education?.educationLevel ? (
                            <CardField
                              title="education Level"
                              value={driver?.education?.educationLevel}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.education?.driverTraining ? (
                            <CardField
                              title="driver Training"
                              value={driver?.education?.driverTraining}
                            />
                          ) : (
                            ``
                          )}
                          {driver?.education?.drugAwareness ? (
                            <CardField
                              title="drug Awareness"
                              value={driver?.education?.drugAwareness}
                            />
                          ) : (
                            ``
                          )}
                          {/* <CardField
                        title="employer"
                        value={
                          driver?.employment
                            ?.employer 
                        }
                      /> */}
                          {driver?.employment?.employmentStatus ? (
                            <CardField
                              title="employment Status"
                              value={driver?.employment?.employmentStatus}
                            />
                          ) : (
                            ``
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </FormTab>
              ))}
            </CollapsableRef>

            <CollapsableRef title="Meta Data">
              <div className="grid grid-cols-12 my-4">
                <div className="col-span-6">
                  {previewData?.metadata?.quoteDescription ? (
                    <CardField
                      title="Quote Description"
                      value={previewData?.metadata?.quoteDescription}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.metadata?.nativeLanguage ? (
                    <CardField
                      title="Native Language"
                      value={previewData?.metadata?.nativeLanguage}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.metadata?.leadSource ? (
                    <CardField
                      title="lead Source"
                      value={previewData?.metadata?.leadSource}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.metadata?.paperlessDiscount ? (
                    <CardField
                      title="paperless Discount"
                      value={previewData?.metadata?.paperlessDiscount}
                    />
                  ) : (
                    ``
                  )}
                </div>
                <div className="col-span-6">
                  {previewData?.metadata?.preferredContactMethods ? (
                    <CardField
                      title="preferred Contact Methods"
                      value={previewData?.metadata?.preferredContactMethods}
                      multiple={true}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.metadata?.contactMethod ? (
                    <CardField
                      title="contact Method"
                      value={previewData?.metadata?.contactMethod}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.metadata?.acquisitionBy ? (
                    <CardField
                      title="acquisition By"
                      value={previewData?.metadata?.acquisitionBy}
                    />
                  ) : (
                    ``
                  )}
                </div>
              </div>
            </CollapsableRef>

            <CollapsableRef title="Rating Preferences">
              <div className="grid grid-cols-12 my-4">
                <div className="col-span-6">
                  {previewData?.ratingData?.ratingPrefs?.state ? (
                    <CardField
                      title="State"
                      value={previewData?.ratingData?.ratingPrefs?.state}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.ratingData?.ratingPrefs?.agencyFee ? (
                    <CardField
                      title="Agency Fee"
                      value={`$ ${previewData?.ratingData?.ratingPrefs?.agencyFee} `}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.ratingData?.ratingPrefs?.estimateTerm ? (
                    <CardField
                      title="Estimate Term"
                      value={previewData?.ratingData?.ratingPrefs?.estimateTerm}
                    />
                  ) : (
                    ``
                  )}
                </div>
                <div className="col-span-6">
                  {previewData?.ratingData?.ratingPrefs?.allowCreditScore ? (
                    <CardField
                      title="Allow Credit Score"
                      value={
                        previewData?.ratingData?.ratingPrefs?.allowCreditScore
                      }
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.ratingData?.ratingPrefs?.bumpLimits ? (
                    <CardField
                      title="Bump Limits"
                      value={previewData?.ratingData?.ratingPrefs?.bumpLimits}
                    />
                  ) : (
                    ``
                  )}
                  {previewData?.ratingData?.ratedAt ? (
                    <CardField
                      title="Rated At"
                      value={dateFormat(previewData?.ratingData?.ratedAt)}
                    />
                  ) : (
                    ``
                  )}
                </div>
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <div className="grid grid-cols-12 font-medium text-gray-700py-1 capitalize">
                    <div className="col-span-4">carrier</div>
                    <div className="col-span-2">rated</div>
                    <div className="col-span-2">ratingType</div>
                    <div className="col-span-2">orderCredit</div>
                    <div className="col-span-2 text-right pr-2">
                      paymentFilter
                    </div>
                  </div>
                  {previewData?.ratingData?.ratingPrefs?.carriers.map(
                    (eachRate, index) => {
                      return (
                        <div
                          key={index}
                          className={`grid ${
                            index % 2 === 0 ? `bg-gray-100` : ``
                          } grid-cols-12 text-sm text-gray-500 my-1.5`}
                        >
                          <div className="col-span-4 px-1">
                            {eachRate?.carrierName}
                          </div>
                          <div className="col-span-2 px-1">
                            {eachRate?.rated}
                          </div>
                          <div className="col-span-2 px-1">
                            {eachRate?.ratingType}
                          </div>
                          <div className="col-span-2 px-1">
                            {eachRate?.orderCredit}
                          </div>
                          <div className="col-span-2 text-right pr-6">
                            {eachRate?.paymentFilter}
                          </div>
                        </div>
                      )
                    },
                  )}
                </div>
              </div>
            </CollapsableRef>
            <CollapsableRef title="Offers">
              {previewData?.carrierQuotes?.length > 0 ? (
                <div className="grid grid-cols-3  gap-3 ">
                  {previewData?.carrierQuotes
                    ?.filter((item) => item?.carrier)
                    ?.map((item, ind) => (
                      <div
                        className="bg-gradient-to-tr to-gray-50 from-blue-50 shadow-sm rounded px-4"
                        key={ind}
                      >
                        <div className="flex justify-between  py-2">
                          <span className="font-semibold">
                            {item?.carrier ? item?.carrier : `UNKNOWN Carrier`}
                          </span>
                          <a
                            href={item?.bridgeLink}
                            target="_blank"
                            rel="noreferrer"
                            className="underline text-sm text-blue-500 font-medium"
                          >
                            Bridge
                          </a>
                        </div>
                        <CardField title="Phone Code" value={item?.phoneCode} />
                        {item?.plan && (
                          <>
                            <CardField
                              title="Description"
                              value={item?.plan?.description}
                            />

                            <CardField
                              title="Total Premium"
                              value={`$${item?.plan?.totalPremium}`}
                            />
                            <CardField
                              title="Down Payment"
                              value={`$${item?.plan?.downPayment}`}
                            />

                            <CardField
                              title="$ / Payment"
                              value={`$${item?.plan?.amountPerPayment}`}
                            />
                            <CardField
                              title="# of Payments"
                              value={`${item?.plan?.payments}`}
                            />
                          </>
                        )}
                      </div>
                    ))}
                </div>
              ) : (
                <div className="text-center font-semibold">No Offers </div>
              )}
            </CollapsableRef>
          </div>
        )}
      </div>
    </Container>
  )
}
