import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API } from 'App/Config/backendApis'
import { AxiosInstance } from 'App/Config'
import { useHistory } from 'react-router-dom'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  fileToBase64,
  loadAllTicketsPriorities,
  permissionGranted,
} from 'App/Services'
import { TextArea } from 'App/Components/Common/TextArea'
import { getNonce } from 'App/Services/Auth/getNonce'
import { NotificationManager } from 'react-notifications'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'
import { loadTicketCategories } from 'App/Services/General/loadTicketCategories'
export const AddTicket = () => {
  const history = useHistory()
  const [tickets, setTickets] = useState({
    piriority: ``,
    title: ``,
    category: ``,
    description: ``,
  })

  const [screenshotFile, setScreenshotFile] = useState(``)
  const [mediaName, setMediaName] = useState(``)

  let fileRef

  const [errors, setErrors] = useState({
    formSubmit: false,
    piriorityError: ``,
    titleError: ``,
    categoryError: ``,
    descriptionError: ``,
  })

  const { mutate: getFileUrl, isLoading: loadingFileUrl } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/cloudinary/file`, {
        ...payload,
      })
        .then((res) => {
          setScreenshotFile(res?.data.data)
        })
        .catch(() => {
          NotificationManager.error(`Operation Failed`)
          setMediaName(``)
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  const handleFileUpload = async (file) => {
    if (file[0]?.type.includes(`image`)) {
      fileToBase64(file[0], (err, base64) => {
        if (base64) {
          getFileUrl({
            file: base64,
            fileName: file[0]?.name,
            fileType: file[0].type,
            path: `Task-Management/media`,
          })
          setMediaName(file[0]?.name)
        }
      })
    }
  }

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/ticket-center`, {
        ...payload,
      }).then((res) => {
        if (res.data) {
          NotificationManager.success(`Created Successfully`)
          res.data && history.push(`/ticketscenter`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validateForm = () => {
    if (!tickets.piriority?.value?.length) {
      handleErrors(`piriorityError`, `Select Priority`)
    } else {
      handleErrors(`piriorityError`, ``)
    }
    if (!tickets.title?.length) {
      handleErrors(`titleError`, `Enter Title`)
    } else {
      handleErrors(`titleError`, ``)
    }

    if (!tickets.category?.value?.length) {
      handleErrors(`categoryError`, `Select Category`)
    } else {
      handleErrors(`categoryError`, ``)
    }

    if (!tickets?.description?.length) {
      handleErrors(`descriptionError`, `Enter Description`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.piriorityError.length &&
      !errors.titleError.length &&
      !errors.categoryError.length &&
      !errors.descriptionError.length
    ) {
      mutate(preparePayload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const preparePayload = () => {
    let data = {}

    if (tickets.piriority?.value?.length) {
      data.piriority = tickets.piriority.value
    }
    if (tickets.title?.length) {
      data.title = tickets.title
    }

    if (tickets.category?.value?.length) {
      data._category = tickets.category.value
      data._franchise = tickets.category._franchise
      data._location = tickets.category._location
    }

    if (tickets.description.length) {
      data.description = tickets.description
    }
    if (screenshotFile?.length) {
      data.mediaUrl = screenshotFile
    }

    return data
  }

  return (
    <Container>
      <PageHeader padding="true">Add Tickets</PageHeader>

      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div
        className={`px-4 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        {/* titles basic form */}
        <div className="flex gap-6 my-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Title *"
              placeholder="Enter title"
              value={tickets.title}
              errorMessage={errors.titleError}
              setValue={(e) => {
                setTickets((preState) => ({
                  ...preState,
                  title: e.target.value,
                }))
              }}
              maxlength={`100`}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Category *"
              placeholder="Select Category"
              errorMessage={errors.categoryError}
              value={tickets.category}
              setValue={(value) => {
                setTickets((prevState) => ({
                  ...prevState,
                  category: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadTicketCategories}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Priorities *"
              placeholder="Select Priorities"
              errorMessage={errors.piriorityError}
              value={tickets.piriority}
              setValue={(value) => {
                setTickets((prevState) => ({
                  ...prevState,
                  piriority: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadAllTicketsPriorities}
              isSearchable={true}
            />
          </div>
        </div>
        <div className="flex gap-6 my-1 relative">
          <div className="flex-1 ">
            <TextArea
              type="textarea"
              title="Description *"
              placeholder="Enter description"
              autoFocus={false}
              value={tickets?.description}
              errorMessage={errors?.descriptionError}
              setValue={(e) => {
                setTickets((preState) => ({
                  ...preState,
                  description: e.target.value,
                }))
              }}
            />
            {permissionGranted([
              `uploadFile:create`,
              `corporate-manager:*`,
              `manager:*`,
              `admin:*`,
            ]) && (
              <span
                data-tip="Add ScreenShot"
                className={`tooltip tooltip-left absolute text-blue-500 right-3 cursor-pointer ${
                  errors?.descriptionError ? `bottom-10` : `bottom-4`
                }`}
              >
                <FontAwesomeIcon
                  icon={faPaperclip}
                  onClick={() => fileRef.click()}
                />
                <input
                  className="hidden"
                  type="file"
                  name="attachment"
                  ref={(refParam) => (fileRef = refParam)}
                  key={getNonce(3, `aA0`)}
                  onChange={(e) => {
                    handleFileUpload(e.target.files)
                  }}
                  accept="image/jpg, image/jpeg, image/png"
                />
              </span>
            )}
          </div>
        </div>
        {loadingFileUrl
          ? `${mediaName} is Uploading`
          : mediaName
          ? `${mediaName} is attached`
          : ``}
        <div className="flex justify-center">
          <div className="text-center w-96 my-5">
            <SubmitButton onClick={validateForm} disabled={loadingFileUrl}>
              {loadingFileUrl ? `Uploading...` : `Add Ticket`}
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
