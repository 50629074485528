import React, { useEffect, useState } from 'react'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { LeadsByState } from '../leadsByState'
import { LeadsByType } from '../LeadsByType'
import { LeadsBySourceLineGraph } from '../LeadBySourceEachMonthThisYear'
import { LeadsByStatusStackedGraph } from '../LeadByStatusEachMonthThisYear'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { QuotesByStatus } from '../QuotesByStatus'
import { DashboardSkelton } from '../DashboardSkelton'
import { useSelector } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { Graph } from '../DragAndDrop'
import { PolicyByLocation } from '../PolicyByLocation'
import { PolicyByCarrier } from '../PolicyByCarrier'
import { StatisticCard } from '../Component/StatisticCard'
import { SalesByAgent } from '../SalesByAgent'
import { PendingPaymentBySourceLineGraph } from '../PendingPaymentByEachMonthThisYear'
import { CarrierByState } from '../CarrierByState'
import { MonthlyGoalsByStatus } from '../MonthlyGoalsByStatus'
import { EarningProgressByLocation } from '../EarningProgressByLocation'
import { PolicySalesByLocation } from '../PolicySalesByLocation'
import { QuotesByLocation } from '../QuotesByLocation'
import { isHasPermission, isSuperUser, permissionGranted } from 'App/Services'
import { LeadsByStatusDonut } from '../leadsByStatusDonut'
import { EarningsByAgents } from '../EarningsByAgentsDonut'

export const GlobalDashboard = () => {
  const preferredLocation = useSelector(
    ({ user: { preferredLocation } }) => preferredLocation,
  )

  const [graphs, setGraphs] = useState([])

  const carDealerQuery = permissionGranted([
    `car-dealer-agent:*`,
    `car-dealer-manager:*`,
  ])
    ? useQuery(
        `carDealerDasthicApi`,
        async () => {
          const response = await AxiosInstance.get(
            `${AMS_BACKEND_API}/api/dashboard/car-dealer-dashtics`,
          )
          return response.data.data
        },
        {
          refetchOnWindowFocus: false,
          cacheTime: 1,
        },
      )
    : null

  const {
    data: carDealerDashtics,
    refetch: getCarDealer,
    isLoading: carDealerLoading,
  } = carDealerQuery || {}

  const policiesQuery = permissionGranted([
    `policies:read`,
    `corporate-manager:*`,
    `manager:*`,
    `admin:*`,
  ])
    ? useQuery(
        `policiesDasthicApi`,
        async () => {
          const response = await AxiosInstance.get(
            `${AMS_BACKEND_API}/api/dashboard/policies-dashtics`,
          )
          return response.data.data
        },
        {
          refetchOnWindowFocus: false,
          cacheTime: 1,
        },
      )
    : null
  const {
    data: policiesDashtics,
    refetch: getPolicies,
    isLoading: policiesLoading,
  } = policiesQuery || {}

  const goalsQuery = permissionGranted([
    `monthly-goals:read`,
    `corporate-manager:*`,
    `manager:*`,
    `admin:*`,
  ])
    ? useQuery(
        `goalsDasthicApi`,
        async () =>
          await AxiosInstance.get(
            `${AMS_BACKEND_API}/api/dashboard/goals-dashtics`,
          ).then((res) => {
            return res.data.data
          }),
        {
          refetchOnWindowFocus: false,
          cacheTime: 1,
        },
      )
    : null
  const {
    data: goalsDasthics,
    refetch: getGoals,
    isLoading: goalsLoading,
  } = goalsQuery || {}

  const leadsQuery =
    permissionGranted([
      `leads:read`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ]) &&
    !(
      isHasPermission(`car-dealer-agent:*`) ||
      isHasPermission(`car-dealer-manager:*`)
    )
      ? useQuery(
          `LeadsDasthicApi`,
          async () =>
            await AxiosInstance.get(
              `${AMS_BACKEND_API}/api/dashboard/leads-dashtics`,
            ).then((res) => {
              return res.data.data
            }),
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,
          },
        )
      : null

  const {
    isLoading,
    data: leadsDasthics,
    refetch: getLeads,
    isFetching,
    isError,
  } = leadsQuery || {}
  const userQuery = permissionGranted([
    `corporate-manager:*`,
    `manager:*`,
    `admin:*`,
  ])
    ? useQuery(
        `userDasthicApi`,
        async () =>
          await AxiosInstance.get(
            `${AMS_BACKEND_API}/api/dashboard/users-dashtics`,
          ).then((res) => {
            return res.data.data
          }),
        {
          refetchOnWindowFocus: false,
          cacheTime: 1,
        },
      )
    : null
  const {
    data: usersDasthics,
    refetch: getUsers,
    isLoading: usersLoading,
  } = userQuery || {}

  const quotesQuery = permissionGranted([
    `quotes:read`,
    `corporate-manager:*`,
    `manager:*`,
    `admin:*`,
  ])
    ? useQuery(
        `quotesDasthicsApi`,
        async () =>
          await AxiosInstance.get(
            `${AMS_BACKEND_API}/api/dashboard/quotes-dashtics`,
          ).then((res) => {
            return res.data.data
          }),
        {
          refetchOnWindowFocus: false,
          cacheTime: 1,
        },
      )
    : null
  const {
    data: quotesDasthics,
    refetch: getQuotes,
    isLoading: quoteLoading,
  } = quotesQuery || {}

  const carrierQuery =
    isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)
      ? useQuery(
          `carrierDasthics`,
          async () =>
            await AxiosInstance.get(
              `${AMS_BACKEND_API}/api/dashboard/carriers-dashtics`,
            ).then((res) => {
              return res.data.data
            }),
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,
          },
        )
      : null
  const { data: carrierDasthics, refetch: getCarrier } = carrierQuery || {}

  const franchiseQueryQuery =
    isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)
      ? useQuery(
          `franchiseDasthics`,
          async () =>
            await AxiosInstance.get(
              `${AMS_BACKEND_API}/api/dashboard/franchise-dashtics`,
            ).then((res) => {
              return res.data.data
            }),
          {
            refetchOnWindowFocus: false,
            cacheTime: 1,
          },
        )
      : null

  const {
    data: franchiseDasthics,
    refetch: getfranchise,
    isLoading: franchiseLoading,
  } = franchiseQueryQuery || {}
  useEffect(() => {
    if (preferredLocation?._id?.length) {
      getGoals
      getfranchise
      getCarrier
      getQuotes
      getUsers
      getLeads
      getPolicies
    }
  }, [preferredLocation])

  useEffect(() => {
    if (
      policiesDashtics ||
      leadsDasthics ||
      usersDasthics ||
      quotesDasthics ||
      goalsDasthics ||
      franchiseDasthics ||
      carrierDasthics ||
      carDealerDashtics
    ) {
      setGraphs([
        {
          id: 1,
          LeadsGraph: leadsDasthics?.leadsDashtics
            ?.leadsBySourceEachMonthPastYear?.leadsBySourceEachMonthPastYear ? (
            <div className="  col-span-2 rounded-md text-center">
              <LeadsBySourceLineGraph
                leadsBySource={
                  leadsDasthics?.leadsDashtics?.leadsBySourceEachMonthPastYear
                    ?.leadsBySourceEachMonthPastYear
                }
              />
            </div>
          ) : null,
        },

        {
          id: 2,

          LeadsGraph: leadsDasthics?.leadsDashtics?.leadsByState ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <LeadsByState
                leadsByState={leadsDasthics?.leadsDashtics?.leadsByState}
              />
            </div>
          ) : null,
        },
        {
          id: 3,

          LeadsGraph: leadsDasthics?.leadsByStatusEachMonthPastYear
            ?.leadsByStatusEachMonthPastYear ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <LeadsByStatusStackedGraph
                leadsByStatus={
                  leadsDasthics?.leadsDashtics?.leadsByStatusEachMonthPastYear
                    ?.leadsByStatusEachMonthPastYear
                }
              />
            </div>
          ) : null,
        },
        {
          id: 4,

          LeadsGraph: leadsDasthics?.leadsDashtics?.leadsByType ? (
            <div className="md:col-span-4 col-span-12">
              <LeadsByType
                leadsByType={leadsDasthics?.leadsDashtics?.leadsByType}
              />
            </div>
          ) : null,
        },
        {
          id: 5,

          LeadsGraph: quotesDasthics?.qoutesDashtics?.quotesByStatus ? (
            <div className="col-span-6">
              <QuotesByStatus
                quotesByStatus={quotesDasthics?.qoutesDashtics?.quotesByStatus}
              />
            </div>
          ) : null,
        },
        {
          id: 6,

          LeadsGraph: goalsDasthics?.goalsDashtics?.goalsByStatus ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <MonthlyGoalsByStatus
                goalsByStatus={goalsDasthics?.goalsDashtics?.goalsByStatus}
              />
            </div>
          ) : null,
        },
        {
          id: 7,

          LeadsGraph: usersDasthics?.agentDashtics?.salesByAgents ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <SalesByAgent
                salesByAgent={usersDasthics?.agentDashtics?.salesByAgents}
              />
            </div>
          ) : null,
        },
        {
          id: 8,

          LeadsGraph: franchiseDasthics?.franchiseDashtics
            ?.earningsByLocations ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <EarningProgressByLocation
                earningProgress={
                  franchiseDasthics?.franchiseDashtics?.earningsByLocations
                }
              />
            </div>
          ) : null,
        },

        {
          id: 9,

          LeadsGraph: franchiseDasthics?.franchiseDashtics
            ?.pendingPaymentsByLocation ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <PendingPaymentBySourceLineGraph
                pendingPayment={
                  franchiseDasthics?.franchiseDashtics
                    ?.pendingPaymentsByLocation
                }
              />
            </div>
          ) : null,
        },

        {
          id: 10,

          LeadsGraph: franchiseDasthics?.franchiseDashtics
            ?.policiesByLocations ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <PolicyByLocation
                policyByLocation={
                  franchiseDasthics?.franchiseDashtics?.policiesByLocations
                }
              />
            </div>
          ) : null,
        },

        {
          id: 11,

          LeadsGraph: franchiseDasthics?.franchiseDashtics
            ?.quotesByLocations ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <QuotesByLocation
                quotesByLocation={
                  franchiseDasthics?.franchiseDashtics?.quotesByLocations
                }
              />
            </div>
          ) : null,
        },
        {
          id: 12,

          LeadsGraph: franchiseDasthics?.franchiseDashtics
            ?.policiesSalesByLocation ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <PolicySalesByLocation
                policySalesByLocation={
                  franchiseDasthics?.franchiseDashtics?.policiesSalesByLocation
                }
              />
            </div>
          ) : null,
        },
        {
          id: 13,

          LeadsGraph: policiesDashtics?.policiesDashtics?.policiesByCarrier ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <PolicyByCarrier
                policyByCarrier={
                  policiesDashtics?.policiesDashtics?.policiesByCarrier
                }
              />
            </div>
          ) : null,
        },

        {
          id: 14,

          LeadsGraph: carrierDasthics?.carriersDashtics?.carriersByState ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <CarrierByState
                carrierByState={
                  carrierDasthics?.carriersDashtics?.carriersByState
                }
              />
            </div>
          ) : null,
        },
        {
          id: 15,

          LeadsGraph: carDealerDashtics?.leadsDashtics ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <LeadsByStatusDonut
                leadsByStatus={carDealerDashtics?.leadsDashtics}
              />
            </div>
          ) : null,
        },
        {
          id: 16,

          LeadsGraph: carDealerDashtics?.earningsByAgent ? (
            <div className="md:col-span-8 col-span-12 rounded-md text-center">
              <EarningsByAgents
                earningsByAgent={carDealerDashtics?.earningsByAgent}
              />
            </div>
          ) : null,
        },
      ])
    }
  }, [
    leadsDasthics,
    policiesDashtics,
    quotesDasthics,
    usersDasthics,
    goalsDasthics,
    franchiseDasthics,
    carrierDasthics,
    carDealerDashtics,
  ])

  const MoveGraph = (dragIndex, hoverIndex) => {
    const dragGraph = graphs[dragIndex]
    setGraphs(
      update(graphs, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragGraph],
        ],
      }),
    )
  }

  return !preferredLocation?._id?.length ? (
    <DashboardSkelton />
  ) : franchiseLoading ||
    isLoading ||
    goalsLoading ||
    quoteLoading ||
    usersLoading ||
    policiesLoading ||
    carDealerLoading ||
    isFetching ? (
    //  || isLoadingAqsGraphs || fetchingAqsGraphs
    <DashboardSkelton />
  ) : isError ? (
    // || isAqsGraphError
    <Reload
      refetch={() => {
        getGoals
        getfranchise()
        getCarrier
        getQuotes
        getUsers
        getLeads
        getPolicies
        getCarDealer
      }}
    />
  ) : !(
      quotesDasthics ||
      leadsDasthics ||
      policiesDashtics ||
      goalsDasthics ||
      usersDasthics ||
      carDealerDashtics?.totalLeads ||
      franchiseDasthics?.franchiseDashtics?.pendingPaymentsByLocation
    ) ? (
    <div className="text-center items-centers">No Data Found</div>
  ) : (
    <>
      <div className="ml-2 py-2 rounded mt-3">
        <div className="grid grid-cols-12">
          <div className=" md:col-span-12 col-span-12 pr-2 gap-x-4 flex justify-between w-full rounded-md text-center ">
            {quotesDasthics?.qoutesDashtics?.quotes !== undefined ? (
              <StatisticCard
                quotes={quotesDasthics?.qoutesDashtics?.quotes}
                title={`Quotes`}
                colorPrimary={`bg-white`}
                gradient="gradient-1"
              />
            ) : null}
            {carDealerDashtics?.totalLeads !== undefined ||
            leadsDasthics?.leadsDashtics?.leads !== undefined ? (
              <StatisticCard
                leads={
                  leadsDasthics?.leadsDashtics?.leads ??
                  carDealerDashtics?.totalLeads
                }
                title={`Leads`}
                colorPrimary={`bg-white`}
                gradient="gradient-2"
              />
            ) : null}
            {franchiseDasthics?.franchiseDashtics?.totalSalesOfLocations !==
            undefined ? (
              <StatisticCard
                sales={
                  franchiseDasthics?.franchiseDashtics?.totalSalesOfLocations
                }
                title={`Sales`}
                colorPrimary={`bg-white`}
                gradient="gradient-3"
              />
            ) : null}
            {carDealerDashtics?.totalEarnings !== undefined ||
            franchiseDasthics?.franchiseDashtics?.totalEarnings !==
              undefined ? (
              <StatisticCard
                earning={
                  franchiseDasthics?.franchiseDashtics?.totalEarnings ??
                  carDealerDashtics?.totalEarnings
                }
                title={`Earnings`}
                colorPrimary={`bg-white`}
                gradient="gradient-4"
              />
            ) : null}
          </div>
          <div className="md:col-span-4 col-span-12"></div>
        </div>
      </div>

      <DndProvider backend={HTML5Backend}>
        <div
          className={`grid gap-0 grid-cols-2 ${
            carDealerDashtics ? `2xl:grid-cols-2` : `2xl:grid-cols-3`
          }`}
        >
          {graphs
            ?.filter((element) => {
              return element?.LeadsGraph !== null
            })
            ?.map((graph, index) => {
              return (
                <Graph
                  index={index}
                  key={graph.id}
                  MoveGraph={MoveGraph}
                  graph={graph}
                />
              )
            })}
        </div>
      </DndProvider>
    </>
  )
}
