import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { defaultImage } from 'App/Assets'
import { useMutation } from 'react-query'
import {
  AMS_BACKEND_API,
  AxiosInstance,
  // LEAFLET_URL,
  allowedSystemPermissions,
  regEx,
} from 'App/Config'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import {
  ValidateDuplicateAgentEmail,
  fileResizer,
  isHasPermission,
  // loadZipCodes,
} from 'App/Services'
import { MaskField } from 'App/Components/Common/MaskField'
import { InputField } from 'App/Components/Common/InputField'
// import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  loadFranchises,
  // ValidateZip
} from 'App/Services'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, Image, Label, PageHeader, SubmitButton } from 'App/Styled'
// import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import { useDebouncedCallback } from 'use-debounce/lib'
import { NotificationManager } from 'react-notifications'
import { Checkbox } from 'App/Components/Common/Checkbox'

export const UpdateAgent = () => {
  // const franchiseId = useSelector(
  //   ({ user }) => user?.user?.profile?._franchise?._id,
  // )
  const isCarDealer = isHasPermission(`car-dealer-manager:*`)
  const [agent, setAgent] = useState({
    img: ``,
    franchise: ``,
    locations: ``,
    firstName: ``,
    middleName: ``,
    lastName: ``,
    gender: ``,
    phone: ``,
    officePhone: ``,
    ext: ``,
    fax: ``,
    email: ``,
    evaluator: ``,
    isEvaluator: ``,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    streetNo: ``,
    city: ``,
    zip: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })
  const [validating, setValidating] = useState(false)
  const [phoneValidating, setPhoneValidating] = useState(false)
  // const [agentZipValidating, setAgentZipValidating] = useState(false)

  const [errors, setErrors] = useState({
    formSubmit: false,
    dpError: ``,
    franchiseError: ``,
    locationsError: ``,
    firstNameError: ``,
    lastNameError: ``,
    designationError: ``,
    genderError: ``,
    phoneError: ``,
    officePhoneError: ``,
    extError: ``,
    faxError: ``,
    emailError: ``,
    streetNoError: ``,
    cityError: ``,
    zipError: ``,
    stateError: ``,
    isEvaluator: ``,
    commissionTypeError: ``,
    systemRolesError: ``,
    commissionRateError: ``,
    coordinatesError: false,
  })

  const history = useHistory()
  const {
    params: { fId, agentId },
    url,
  } = useRouteMatch()

  const { currentAgent } = useSelector(({ agent }) => agent)
  useEffect(() => {
    if (currentAgent) {
      setAgent((prevState) => ({
        ...prevState,
        img: currentAgent?.img,
        franchise: {
          value: currentAgent?.franchiseId,
          label: currentAgent?.franchiseName,
        },
        locations: currentAgent.locationNames,
        firstName: currentAgent?.firstName,
        middleName: currentAgent?.middleName ? currentAgent?.middleName : ``,
        lastName: currentAgent?.lastName,
        evaluator: currentAgent?.evaluator,
        isEvaluator: currentAgent?.isEvaluator,
        gender: { value: currentAgent?.gender, label: currentAgent?.gender },
        systemRoles: currentAgent?.systemRoles,
        phone: currentAgent?.contactInfo?.phone,
        officePhone: currentAgent?.contactInfo?.officePhone,
        ext: currentAgent?.contactInfo?.officePhoneExt,
        fax: currentAgent?.contactInfo?.fax,
        email: currentAgent?.contactInfo?.email,
      }))
      setAddress((prevState) => ({
        ...prevState,
        unitNo: currentAgent?.address?.unitNo,
        streetNo: currentAgent.address?.street,
        city: currentAgent.address?.city,
        zip: {
          value: currentAgent?.address?.zip,
          label: currentAgent?.address?.zip,
        },

        state: currentAgent.address?.state,
      }))

      setCoordinates((prevState) => ({
        ...prevState,
        lat: currentAgent?.address?.coordinates?.lat,
        lng: currentAgent.address?.coordinates?.long,
      }))
    }
  }, [currentAgent])

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.dpError?.length &&
      !errors.franchiseError?.length &&
      !errors.locationsError?.length &&
      !errors.firstNameError?.length &&
      !errors.lastNameError?.length &&
      !errors.genderError?.length &&
      !errors.emailError?.length &&
      !errors.extError?.length &&
      !errors.phoneError?.length &&
      !errors.officePhoneError?.length &&
      !errors.faxError?.length &&
      // !errors.zipError?.length &&
      // !errors.streetNoError?.length &&
      // !errors.cityError?.length &&
      // !errors.stateError?.length &&
      !errors.systemRolesError?.length
      //  &&
      // !errors.coordinatesError
    ) {
      updateAgent(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}

    // Location
    if (currentAgent.isEvaluator != agent.isEvaluator) {
      data.isEvaluator = agent.isEvaluator
    }
    let isUpdatedLocation
    if (agent.locations?.length === currentAgent.locationNames?.length) {
      isUpdatedLocation = agent.locations.some((eachLocation, index) => {
        return eachLocation.value !== currentAgent.locationNames[index].value
      })
    } else {
      isUpdatedLocation = true
    }

    if (isUpdatedLocation) {
      data._locations = agent.locations.map((reg) => reg.value)
    }
    // SystemRoles

    let isSystemRoles
    if (agent?.systemRoles?.length === currentAgent?.systemRoles?.length) {
      isSystemRoles = agent.systemRoles.some((eachSystemRole, index) => {
        return eachSystemRole?.value !== currentAgent?.systemRoles[index]?.value
      })
    } else {
      isSystemRoles = true
    }

    if (isSystemRoles) {
      data.systemRoles = agent.systemRoles.map((reg) => reg.value)
    }

    if (agent.img !== currentAgent.img) {
      data.img = agent.img
    }
    if (agent.firstName !== currentAgent.firstName) {
      data.firstName = agent.firstName
    }

    if (
      (agent.middleName || currentAgent.middleName) &&
      agent.middleName !== currentAgent.middleName
    ) {
      data.middleName = agent.middleName
    }
    if (agent.lastName !== currentAgent.lastName) {
      data.lastName = agent.lastName
    }

    if (agent.evaluator?.value?.length !== currentAgent.evaluator?.value) {
      data.evaluator = agent.evaluator.value
    }
    if (agent?.gender?.value !== currentAgent.gender) {
      data.gender = agent?.gender?.value
    }
    if (
      agent.phone !== currentAgent.contactInfo?.phone ||
      (agent.officePhone?.length &&
        agent.officePhone !== currentAgent.contactInfo?.officePhone) ||
      agent.ext !== currentAgent?.contactInfo?.officePhoneExt ||
      (agent.fax?.length && agent.fax !== currentAgent?.contactInfo?.fax) ||
      agent.email !== currentAgent.contactInfo?.email
    ) {
      data.contactInfo = {}
      if (agent.phone !== currentAgent.contactInfo?.phone) {
        data.contactInfo.phone = agent.phone
      }

      if (
        agent.officePhone?.length &&
        agent.officePhone !== currentAgent.contactInfo?.officePhone
      ) {
        data.contactInfo.officePhone = agent.officePhone
      }
      if (agent.fax?.length && agent.fax !== currentAgent?.contactInfo?.fax) {
        data.contactInfo.fax = agent.fax
      }

      if (agent.ext !== currentAgent?.contactInfo?.officePhoneExt) {
        data.contactInfo.officePhoneExt = agent.ext
      }

      if (agent.email !== currentAgent.contactInfo?.email) {
        data.contactInfo.email = agent.email
      }
    }

    // Commission

    // address payload
    const addressPayload = {}
    let coordinatesPayload = {}

    if (address?.unitNo !== currentAgent?.address?.unitNo) {
      addressPayload.unitNo = address.unitNo
    }
    if (address?.streetNo !== currentAgent?.address?.street) {
      addressPayload.street = address.streetNo
    }

    if (address?.city?.toUpperCase() !== currentAgent?.address?.city) {
      addressPayload.city = address.city
    }
    if (address?.zip?.value !== currentAgent?.address?.zip) {
      coordinatesPayload.lat = coordinates.lat.toString()
      coordinatesPayload.long = coordinates.lng.toString()
    }

    if (currentAgent?.address?.zip !== address?.zip?.value) {
      addressPayload.zip = address?.zip.value
    }

    if (address?.state !== currentAgent?.address?.state) {
      addressPayload.state = address.state
    }
    if (Object.keys(addressPayload)?.length !== 0) {
      addressPayload.coordinates = coordinatesPayload
      data.address = addressPayload
    }
    return data
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const {
    mutate: updateAgent,
    isLoading: updatingAgent,
    isError: updatingError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(`${AMS_BACKEND_API}/api/users/${agentId}`, {
        ...payload,
      })
        .then((res) => {
          if (res.data) {
            if (url.includes(`franchises`)) {
              history.push(`/admin/franchises/${fId}/agents/${agentId}`)
            } else {
              history.push(`/admin/agents/${agentId}`)
            }
            NotificationManager.success(`Operation successful`)
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleAgentInput = useDebouncedCallback((name, value) => {
    setAgent((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleEmailInput = async (e) => {
    const { name, value } = e.target
    setAgent((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (value && currentAgent?.contactInfo?.email !== value) {
      validateAgentEmail(value)
    }
  }

  const handlePhoneInput = async (value) => {
    setAgent((prevState) => ({
      ...prevState,
      phone: value,
    }))

    if (value && currentAgent?.contactInfo?.phone !== value) {
      setPhoneValidating(true)
      const response = await ValidateDuplicateAgentEmail({ phone: value })
      if (response.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`phoneError`, `Phone Already Exists`)
        setPhoneValidating(false)
      } else {
        handleErrors(`formSubmit`, false)
        handleErrors(`phoneError`, ``)
        setPhoneValidating(false)
      }
    }
  }

  const validateAgentEmail = useDebouncedCallback(async (value) => {
    setValidating(true)
    const response = await ValidateDuplicateAgentEmail({ email: value })
    if (response.hasError) {
      handleErrors(`formSubmit`, false)
      handleErrors(`emailError`, `Email Already Exists`)
      setValidating(false)
    } else {
      if (!regEx.test(value.toLowerCase())) {
        handleErrors(`formSubmit`, false)
        setValidating(false)
        handleErrors(`emailError`, `Enter Valid Email`)
      } else {
        handleErrors(`formSubmit`, false)
        handleErrors(`emailError`, ``)
        setValidating(false)
      }
    }
  }, 2500)

  // const handleAddressInput = async (e, name) => {
  //   if (name) {
  //     setAgentZipValidating(true)
  //     const data = await ValidateZip(e?.value)
  //     if (data) {
  //       setAgentZipValidating(false)
  //       const res = data.address
  //       setAddress((prevState) => ({
  //         ...prevState,
  //         [`city`]: res.city,
  //         [`state`]: res.state,
  //       }))
  //       // setAddress((prevState) => ({
  //       //   ...prevState,
  //       //   [name]: e,
  //       // }))

  //       //setting coordinates
  //       setCoordinates((prevState) => ({
  //         ...prevState,
  //         [`lat`]: res.lat ? res.lat : ``,
  //         [`lng`]: res.long ? res.long : ``,
  //       }))
  //     }
  //   } else {
  //     const { name, value } = e.target
  //     setAddress((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }))
  //   }
  // }

  // const ChangeView = ({ center, zoom }) => {
  //   useMap().setView(center, zoom)
  //   return null
  // }

  const loadGenderOptions = () => {
    const options = {
      options: [
        { value: `MALE`, label: `MALE` },
        { value: `FEMALE`, label: `FEMALE` },
        { value: `NON_BINARY`, label: `NON BINARY` },
      ],
      hasMore: false,
    }
    return options
  }
  const handleLogo = async (file) => {
    const base64 = await fileResizer(file[0])
    setAgent((prevState) => ({
      ...prevState,
      [`img`]: base64,
    }))
  }

  const loadLocations = async (search, loadedOptions, { page }) => {
    try {
      const {
        data: { data },
      } = await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/franchises/${
          currentAgent?.franchiseId || ``
        }/locations/list/?search=${search && search}&page=${page}&offset=10`,
      )
      if (data) {
        return {
          options: data.locations,
          hasMore: data.totalPages - page > 0,
          additional: {
            page: page + 1,
          },
        }
      }
    } catch (error) {
      if (error?.response?.data?.hasError) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        }
      }
    }
  }

  const loadSystemRoles = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        currentAgent?.franchiseId
      }/system-roles/list/?search=${search && search}&page=${page}&offset=10`,
    )
    return {
      options: data.systemRoles,
      hasMore: data.totalPages - page > 0,
      additional: {
        page: page + 1,
      },
    }
  }

  const loadEvaluator = async (search, loadedOptions, { page }) => {
    const locationIds = agent?.locations.map((location) => location.value) || []
    try {
      const {
        data: { data },
      } = await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/users/v2/list?search={"query":"${
          search.length ? search : ``
        }"}&page=${page}&offset=10`,
        {
          franchiseId: agent?.franchise?.value
            ? agent?.franchise?.value
            : _franchise?._id,
          locations: locationIds,
          permissions: allowedSystemPermissions?.evaluator,
        },
      )
      return {
        options: data.agents,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      if (error.response?.data?.hasError) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        }
      }
    }
  }

  const ValidateForm = () => {
    if (!agent.img?.length) {
      handleErrors(`imgError`, `Upload Image`)
    } else {
      handleErrors(`imgError`, ``)
    }

    if (!agent.franchise?.value?.length) {
      handleErrors(`franchiseError`, `Select Franchise`)
    } else {
      handleErrors(`franchiseError`, ``)
    }

    if (!agent.locations?.length) {
      handleErrors(`locationsError`, `Select Locations`)
    } else {
      handleErrors(`locationsError`, ``)
    }

    if (!agent.firstName?.length) {
      handleErrors(`firstNameError`, `Enter First name`)
    } else {
      handleErrors(`firstNameError`, ``)
    }

    if (!agent.lastName?.length) {
      handleErrors(`lastNameError`, `Enter Last name`)
    } else {
      handleErrors(`lastNameError`, ``)
    }

    if (!agent.gender?.value?.length) {
      handleErrors(`genderError`, `Select Gender`)
    } else {
      handleErrors(`genderError`, ``)
    }

    if (!agent?.systemRoles?.length) {
      handleErrors(`systemRolesError`, `Select Roles`)
    } else {
      handleErrors(`systemRolesError`, ``)
    }

    if (!agent.email?.length) {
      handleErrors(`emailError`, `Enter Email`)
    } else if (agent.email?.length) {
      if (
        errors.emailError?.length &&
        errors.emailError.includes(`Email Already Exists`)
      ) {
        handleErrors(`emailError`, `Email Already Exists`)
      } else {
        if (!regEx.test(agent.email.toLowerCase())) {
          handleErrors(`emailError`, `Enter Valid Email`)
        } else {
          handleErrors(`emailError`, ``)
        }
      }
    } else {
      handleErrors(`emailError`, ``)
    }

    if (agent?.ext?.length) {
      if (isNaN(agent?.ext)) {
        handleErrors(`extError`, `Ext Should be a number`)
      } else if (agent?.ext?.length < 4) {
        handleErrors(`extError`, `Ext length should be less than 4`)
      } else {
        handleErrors(`extError`, ``)
      }
    } else {
      handleErrors(`extError`, ``)
    }
    // if (!agent?.phone?.toString()?.length) {
    //   handleErrors(`phoneError`, `Enter Phone No`)
    // } else
    if (
      agent?.phone?.length &&
      agent?.phone !== currentAgent?.contactInfo?.phone &&
      agent?.phone.length < 12
    ) {
      handleErrors(`phoneError`, `Enter Valid Phone No`)
    } else {
      handleErrors(`phoneError`, ``)
    }

    if (agent?.officePhone?.length && agent?.officePhone?.length < 12) {
      handleErrors(`officePhoneError`, `Enter Valid Phone No`)
    } else {
      handleErrors(`officePhoneError`, ``)
    }

    if (agent?.fax?.length && agent?.fax?.length < 12) {
      handleErrors(`faxError`, `Enter Valid Fax No`)
    } else {
      handleErrors(`faxError`, ``)
    }
    // if (!address?.unitNo?.toString()?.length) {
    //   handleErrors(`unitNoError`, `Enter Unit No`)
    // } else {
    //   handleErrors(`unitNoError`, ``)
    // }

    // if (!address?.zip?.length && !Object.keys(address?.zip)?.length) {
    //   handleErrors(`zipError`, `Select Zip Code`)
    // } else {
    //   handleErrors(`zipError`, ``)
    // }

    // if (!address?.streetNo?.length) {
    //   handleErrors(`streetNoError`, `Enter Street`)
    // } else {
    //   handleErrors(`streetNoError`, ``)
    // }

    // if (!address?.city?.length) {
    //   handleErrors(`cityError`, `Enter City`)
    // } else {
    //   handleErrors(`cityError`, ``)
    // }

    // if (!address?.state?.length) {
    //   handleErrors(`stateError`, `Enter State`)
    // } else {
    //   handleErrors(`stateError`, ``)
    // }

    // if (!coordinates?.lat && !coordinates?.lng) {
    //   handleErrors(`coordinatesError`, true)
    // } else {
    //   handleErrors(`coordinatesError`, false)
    // }
    handleErrors(`formSubmit`, true)
  }

  return (
    <Container>
      <PageHeader>Update Agent</PageHeader>
      {updatingError && (
        <ReactNotification action="error" message={`Something went wrong`} />
      )}

      <Spinner loading={updatingAgent} />
      <div
        className={`p-4 bg-white ${
          updatingAgent && `opacity-30 pointer-events-none`
        } `}
      >
        {/* Locations basic form */}
        <div className="flex justify-center items-center my-0 py-3">
          <div className="w-50">
            <Label>
              <Image alt="fImage" src={agent.img ? agent.img : defaultImage} />

              <input
                type="file"
                onChange={(e) => handleLogo(e.target.files)}
                className="hidden"
                accept="image/*"
              />
              {errors.dpError && (
                <p className="text-sm text-red-500 mb-1">
                  {errors.dpError}
                  <span className="text-red-500 required-dot">*</span>
                </p>
              )}
            </Label>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-4">
          <div className="relative">
            <ReactSelectField
              title="Franchise *"
              placeholder="Select Franchise"
              errorMessage={errors.franchiseError}
              value={agent.franchise}
              setValue={(value) => {
                setAgent((prevState) => ({
                  ...prevState,
                  [`franchise`]: value,
                }))
              }}
              isMulti={false}
              loadOptions={!isCarDealer && loadFranchises}
              isSearchable={true}
              disabled={true}
            />
          </div>
          <div className="relative">
            <ReactSelectField
              title="Locations *"
              placeholder="Select Locations"
              errorMessage={errors.locationsError}
              value={agent.locations}
              setValue={(value) => {
                setAgent((prevState) => ({
                  ...prevState,
                  [`locations`]: value,
                }))
              }}
              isMulti={true}
              loadOptions={!isCarDealer && loadLocations}
              isSearchable={true}
              disabled={isCarDealer}
            />
          </div>

          <div className="relative">
            <ReactSelectField
              title="System Roles *"
              placeholder="Select Roles"
              errorMessage={errors.systemRolesError}
              value={agent.systemRoles}
              setValue={(value) => {
                setAgent((prevState) => ({
                  ...prevState,
                  [`systemRoles`]: value,
                }))
              }}
              isMulti={true}
              loadOptions={loadSystemRoles}
              isSearchable={true}
              cacheUniqs={agent?.systemRoles}
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="Evaluator"
              placeholder="Select evaluator"
              value={agent.evaluator}
              setValue={(value) => {
                setAgent((prevState) => ({
                  ...prevState,
                  evaluator: value,
                }))
              }}
              loadOptions={!isCarDealer && loadEvaluator}
              isSearchable={true}
              cacheUniqs={agent?.locations}
              disabled={isCarDealer}
            />
          </div>

          <div className="relative">
            <InputField
              type="text"
              title="First Name *"
              placeholder="Enter First Name"
              errorMessage={errors.firstNameError}
              value={agent.firstName}
              setValue={(e) => handleAgentInput(`firstName`, e.target.value)}
            />
          </div>
          <div className="relative">
            <InputField
              type="text"
              title="Middle Name"
              placeholder="Enter Middle Name"
              value={agent.middleName}
              setValue={(e) => handleAgentInput(`middleName`, e.target.value)}
            />
          </div>
          <div className="relative">
            <InputField
              type="text"
              title="Last Name *"
              placeholder="Enter Last Name"
              errorMessage={errors.lastNameError}
              value={agent.lastName}
              setValue={(e) => handleAgentInput(`lastName`, e.target.value)}
            />
          </div>
          <div className="relative">
            <ReactSelectField
              title="Gender *"
              placeholder="Select Gender"
              errorMessage={errors.genderError}
              value={agent.gender}
              setValue={(value) => {
                setAgent((prevState) => ({
                  ...prevState,
                  [`gender`]: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadGenderOptions}
              isSearchable={true}
            />
          </div>
          <div className="relative">
            <InputField
              type="email"
              title="Email *"
              name="email"
              placeholder="Enter Email"
              errorMessage={errors.emailError}
              value={agent.email}
              setValue={(e) => handleEmailInput(e)}
              validating={validating}
            />
          </div>
          <div className="relative">
            <MaskField
              type="text"
              title="Phone"
              placeholder="Enter Phone No"
              errorMessage={errors.phoneError}
              value={agent.phone}
              setValue={(e) => handlePhoneInput(e.target.value)}
              validating={phoneValidating}
              mask="+19999999999"
            />
          </div>
          <div className="relative">
            <MaskField
              type="text"
              title="Office Phone"
              placeholder="Enter Phone No"
              errorMessage={errors.officePhoneError}
              value={agent.officePhone}
              setValue={(e) => {
                setAgent((prevState) => ({
                  ...prevState,
                  [`officePhone`]: e.target.value,
                }))
              }}
              mask="+19999999999"
            />
          </div>
          <div className="relative">
            <MaskField
              type="text"
              title="Fax"
              placeholder="Enter Fax"
              errorMessage={errors.faxError}
              value={agent.fax}
              setValue={(e) => {
                setAgent((prevState) => ({
                  ...prevState,
                  [`fax`]: e.target.value,
                }))
              }}
              mask="+19999999999"
            />
          </div>
          <div className="relative">
            <MaskField
              type="text"
              title="Extension"
              placeholder="Enter Extension"
              errorMessage={errors.extError}
              value={agent.ext}
              setValue={(e) => handleAgentInput(`ext`, e.target.value)}
              mask="9999"
            />
          </div>

          {!isCarDealer && (
            <div className="flex-1 mt-4 relative">
              <Checkbox
                title="Is Evaluator "
                name="isEvaluator"
                value={agent.isEvaluator}
                // defaultValue={agent.isEvaluator}
                // defaultChecked={agent.isEvaluator}
                id="isEvaluator"
                checked={agent.isEvaluator}
                setValue={() => {
                  setAgent((prevState) => ({
                    ...prevState,
                    isEvaluator: !agent.isEvaluator,
                  }))
                }}
              />
            </div>
          )}
        </div>

        {/* Location Address starts from here */}
        {/* <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Address
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street *"
              name="streetNo"
              placeholder="Enter Street"
              errorMessage={errors.streetNoError}
              value={address.streetNo}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              placeholder="Select Zip"
              errorMessage={errors.zipError}
              value={address.zip}
              setValue={(e) => {
                handleAddressInput(e, `zip`)
                setAddress((prevState) => ({
                  ...prevState,
                  [`zip`]: e,
                }))
              }}
              loadOptions={agent.franchise && loadZipCodes}
              isSearchable={true}
              cacheUniqs={agent.franchise}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              name="unitNo"
              placeholder="Enter Unit No"
              // errorMessage={errors.unitNoError}
              value={address.unitNo}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>

          <div className="flex-1 relative">
            <InputField
              type="text"
              title="City *"
              name="city"
              placeholder="Enter City"
              errorMessage={errors.cityError}
              validating={agentZipValidating}
              disabled={true}
              value={address.city}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="State *"
              name="state"
              placeholder="Enter State"
              errorMessage={errors.stateError}
              validating={agentZipValidating}
              disabled={true}
              value={address.state}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
        </div>

        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView
              center={[
                coordinates?.lat ? coordinates?.lat : ``,
                coordinates?.lng ? coordinates?.lng : ``,
              ]}
              zoom={3}
            />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div> */}
        <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
          <SubmitButton
            onClick={ValidateForm}
            // disabled={agentZipValidating}
          >
            <div>Update Agent</div>
          </SubmitButton>
        </div>
      </div>
    </Container>
  )
}
