import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const ValidateDuplicateLocation = async (location) => {
  if (location) {
    return await AxiosInstance.post(
      `${AMS_BACKEND_API}/api/validate/location-payloads`,
      {
        ...location
      },
    )
      .then((res) => {
        return res?.data
      })
      .catch((error) => {
        return error?.response?.data
      })
  }
}
