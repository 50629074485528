import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  endorsements: [
    {
      isCombineAll: false,
      disabled: false,
      type: ``,
      typeError: ``,
      date: new Date(),
      dateError: ``,
      amount: ``,
      amountError: ``,
      remainingAmount: ``,
      description: ``,
      descriptionError: ``,
      status: ``,
      statusError: ``,
      followUpDate: ``,
      followUpDateError: ``,
      followUpPerson: ``,
      followUpPersonError: ``,
      items: [
        `Fee`,
        `Agency Commission`,
        `Agent Commission`,
        `Receivable`,
        `Payable`,
        `Financing/Direct Bill`,
      ],
    },
  ],
}

const endorsmentSlice = createSlice({
  name: `endorsment`,

  initialState,
  reducers: {
    addEndorsment: (state) => {
      state.endorsements = [
        ...state.endorsements,
        {
          isCombineAll: false,
          disabled: false,
          type: ``,
          typeError: ``,
          date: new Date(),
          dateError: ``,
          amount: ``,
          amountError: ``,
          remainingAmount: ``,
          description: ``,
          descriptionError: ``,
          status: ``,
          statusError: ``,
          followUpDate: ``,
          followUpDateError: ``,
          followUpPerson: ``,
          followUpPersonError: ``,
          items: [
            `Fee`,
            `Agency Commission`,
            `Agent Commission`,
            `Receivable`,
            `Payable`,
            `Financing/Direct Bill`,
          ],
        },
      ]
    },

    removeEndorsment: (state, { payload }) => {
      state.endorsements.splice(payload, 1)
    },

    //directBill methods starts
    addDirectBill: (state, { payload: { item, ind } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          const newItems = endorsment.items.filter((_item) => _item !== item)
          endorsment.items = newItems
          endorsment.directBill = {
            type: ``,
            typeError: ``,
            dueDate: new Date(),
            dueDateError: ``,
            amount: ``,
            amountError: ``,
            amountType: { value: `%`, label: `%` },
            amountTypeError: ``,
            recievedAmount: ``,
            recievedAmountError: ``,
            financeAgreement: ``,
            financeAgreementError: ``,
            description: ``,
            descriptionError: ``,
            isPrecessedByAgency: false,
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeDirectBill: (state, { payload: ind }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.items.push(`Financing/Direct Bill`)
          delete endorsment.directBill
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentDirectBill: (state, { payload: { value, name, ind } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.directBill[name] = value
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleDirectBill: (state, { payload: { value, name, ind } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          if (value.length) {
            if (endorsment.directBill.amountType.value == `%`) {
              if (JSON.parse(value) > 100) {
                endorsment.directBill.amountError = `Amount must be less than 100%`
              } else {
                endorsment.directBill.amountError = ``
              }
            } else {
              if (JSON.parse(value) > JSON.parse(endorsment.remainingAmount)) {
                endorsment.directBill.amountError = `Amount must be less than ${endorsment.remainingAmount}`
              } else {
                endorsment.directBill.amountError = ``
              }
            }
            endorsment.directBill[name] = value
          } else {
            endorsment.directBill[name] = value
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    //agency commission methods starts
    addAgencyCommission: (state, { payload: { item, ind } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          const newItems = endorsment.items.filter((_item) => _item !== item)
          endorsment.items = newItems
          endorsment.agencyCommission = {
            commission: ``,
            commissionError: ``,
            commissionType: { value: `%`, label: `%` },
            commissionTypeError: ``,
            amount: ``,
            remainingAmount: `0`,
            reserveAmount: `0`,
            dueDate: new Date(),
            dueDateError: ``,
            type: ``,
            typeError: ``,
            disabled: false,
            payment: [],
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeAgencyCommission: (state, { payload: ind }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.items.splice(1, 0, `Agency Commission`)
          if (endorsment.agencyCommission.amount.length) {
            const remainingBalance =
              JSON.parse(endorsment.remainingAmount) +
              JSON.parse(endorsment.agencyCommission.amount)
            endorsment.remainingAmount = JSON.stringify(remainingBalance)
          }
          delete endorsment.agencyCommission
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentAgencyCommission: (
      state,
      { payload: { value, name, ind, payment, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          if (payment) {
            endorsment.agencyCommission.payment.map((_payment, pIndex) => {
              if (pIndex === paymentIndex) {
                endorsment.agencyCommission.payment[pIndex][name] = value
              }
            })
          } else {
            endorsment.agencyCommission[name] = value
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    saveAgencyCommission: (state, { payload: { value, name, ind } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.agencyCommission[name] = value
          if (endorsment.agencyCommission.amount.length) {
            endorsment.remainingAmount =
              JSON.parse(endorsment.remainingAmount) -
              JSON.parse(endorsment.agencyCommission.amount)
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleCommission: (
      state,
      { payload: { value, name, ind, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          if (paymentIndex != undefined) {
            endorsment.agencyCommission.payment.map((_payment, pIndex) => {
              if (value.length > 0) {
                if (pIndex == paymentIndex) {
                  let currentBalance = 0
                  if (paymentIndex > 0) {
                    if (
                      JSON.parse(value) >
                      JSON.parse(
                        endorsment.agencyCommission.payment[paymentIndex - 1]
                          .balance,
                      )
                    ) {
                      _payment.amountError = `Amount should be less than Remaining Balance`
                      currentBalance =
                        JSON.parse(
                          endorsment.agencyCommission.payment[paymentIndex - 1]
                            .balance,
                        ) - JSON.parse(value)
                    } else {
                      _payment.amountError = ``

                      currentBalance =
                        JSON.parse(
                          endorsment.agencyCommission.payment[paymentIndex - 1]
                            .balance,
                        ) - JSON.parse(value)
                    }
                  } else {
                    if (
                      JSON.parse(value) >
                      JSON.parse(endorsment.agencyCommission.reserveAmount)
                    ) {
                      _payment.amountError = `Amount should be less than ${endorsment.agencyCommission.reserveAmount}`
                      currentBalance =
                        JSON.parse(endorsment.agencyCommission.reserveAmount) -
                        JSON.parse(value)
                    } else {
                      _payment.amountError = ``
                      currentBalance =
                        JSON.parse(endorsment.agencyCommission.reserveAmount) -
                        JSON.parse(value)
                    }
                  }
                  endorsment.agencyCommission.payment[pIndex][name] = value
                  endorsment.agencyCommission.payment[pIndex].balance =
                    currentBalance.toString()

                  endorsment.agencyCommission.remainingAmount =
                    JSON.stringify(currentBalance)
                }
              } else {
                if (pIndex == paymentIndex) {
                  endorsment.agencyCommission.payment[pIndex][name] = ``
                  endorsment.agencyCommission.payment[pIndex].balance = ``
                }
              }
            })
          } else {
            if (value.length > 0) {
              let balance = 0
              if (endorsment.agencyCommission.commissionType.value == `%`) {
                if (JSON.parse(value) > 100) {
                  endorsment.agencyCommission[name] = value
                  endorsment.agencyCommission.commissionError = `Commission Must be less than 100 %`
                } else {
                  endorsment.agencyCommission.commissionError = ``
                  endorsment.agencyCommission[name] = value
                  balance =
                    (JSON.parse(endorsment.remainingAmount) *
                      JSON.parse(endorsment.agencyCommission.commission)) /
                    100
                }
              } else {
                if (
                  JSON.parse(value) > JSON.parse(endorsment.remainingAmount)
                ) {
                  endorsment.agencyCommission[name] = value
                  endorsment.agencyCommission.commissionError = `Commission Must be less than ${endorsment.remainingAmount}`
                } else {
                  endorsment.agencyCommission.commissionError = ``
                  endorsment.agencyCommission[name] = value
                  balance = JSON.parse(endorsment.agencyCommission.commission)
                }
              }
              endorsment.agencyCommission.amount = balance.toString()
              endorsment.agencyCommission.remainingAmount = balance.toString()
              endorsment.agencyCommission.reserveAmount = balance.toString()
            } else {
              endorsment.agencyCommission[name] = value
              endorsment.agencyCommission.amount = ``
              endorsment.agencyCommission.commissionError = ``
              endorsment.agencyCommission.remainingAmount = ``
            }
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addPayment: (state, { payload: ind }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.agencyCommission.payment.push({
            date: new Date(),
            dateError: ``,
            amount: ``,
            amountError: ``,
            balance: ``,
            balanceError: ``,
            disableAmount: false,
          })
          if (endorsment.agencyCommission.payment.length > 1) {
            const currentIndex = endorsment.agencyCommission.payment.length - 2
            endorsment.agencyCommission.payment[
              currentIndex
            ].disableAmount = true
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removePayment: (state, { payload: { ind, paymentIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          if (endorsment.agencyCommission.payment[paymentIndex].amount.length) {
            const remainingBalance =
              JSON.parse(endorsment.agencyCommission.remainingAmount) +
              JSON.parse(
                endorsment.agencyCommission.payment[paymentIndex].amount,
              )

            endorsment.agencyCommission.remainingAmount =
              JSON.stringify(remainingBalance)
            const remainingReserveBalance =
              JSON.parse(endorsment.agencyCommission.reserveAmount) +
              JSON.parse(
                endorsment.agencyCommission.payment[paymentIndex].amount,
              )
            endorsment.agencyCommission.reserveAmount = JSON.stringify(
              remainingReserveBalance,
            )
          }
          endorsment.agencyCommission.payment.splice(paymentIndex, 1)
          endorsment.agencyCommission.payment?.map((_payment, pIndex) => {
            if (pIndex > 0) {
              _payment.balance =
                endorsment.agencyCommission.payment[pIndex - 1].balance -
                _payment.amount
            } else {
              _payment.balance =
                endorsment.agencyCommission.reserveAmount - _payment.amount
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    // agent commision methods start here
    addAgentCommission: (state, { payload: ind }) => {
      const newState = state?.endorsements.map((endorsment, index) => {
        if (index === ind) {
          if (endorsment.agentCommissions) {
            endorsment.agentCommissions.push({
              agent: ``,
              agentError: ``,
              paymentType: ``,
              paymentTypeError: ``,
              commission: ``,
              commissionError: ``,
              commissionType: { value: `%`, label: `%` },
              commissionTypeError: ``,
              amount: ``,
              remainingAmount: ``,
              disabled: false,
              payment: [],
            })
          } else {
            endorsment.agentCommissions = [
              {
                agent: ``,
                agentError: ``,
                paymentType: ``,
                paymentTypeError: ``,
                commission: ``,
                commissionError: ``,
                commissionType: { value: `%`, label: `%` },
                commissionTypeError: ``,
                amount: ``,
                remainingAmount: ``,
                disabled: false,
                payment: [],
              },
            ]
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeAgentCommission: (state, { payload: { ind, agentIndex } }) => {
      const newState = state?.endorsements.map((endorsment, index) => {
        if (index === ind) {
          if (
            endorsment.agentCommissions[agentIndex].paymentType.value ===
            `From Agency Commission`
          ) {
            let amount = ``
            if (
              typeof endorsment.agentCommissions[agentIndex].amount !== `string`
            ) {
              amount = JSON.stringify(
                endorsment.agentCommissions[agentIndex].amount,
              )
            } else {
              amount = endorsment.agentCommissions[agentIndex].amount
            }
            if (amount.length) {
              const remainingBalance =
                JSON.parse(endorsment.agencyCommission.remainingAmount) +
                JSON.parse(amount)
              endorsment.agencyCommission.remainingAmount =
                JSON.stringify(remainingBalance)

              const remainingReserveBalance =
                JSON.parse(endorsment.agencyCommission.reserveAmount) +
                JSON.parse(amount)
              endorsment.agencyCommission.reserveAmount = JSON.stringify(
                remainingReserveBalance,
              )
            }
          } else {
            if (endorsment.agentCommissions[agentIndex].amount.length) {
              endorsment.remainingAmount =
                JSON.parse(endorsment.remainingAmount) +
                JSON.parse(endorsment.agentCommissions[agentIndex].amount)
            }
          }
          if (endorsment.agentCommissions.length > 1) {
            endorsment.agentCommissions.splice(agentIndex, 1)
          } else {
            delete endorsment.agentCommissions
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addAgentPayment: (state, { payload: { ind, agentIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.agentCommissions.map((agent, aIndex) => {
            if (aIndex === agentIndex) {
              agent.payment.push({
                date: new Date(),
                dateError: ``,
                amount: ``,
                amountError: ``,
                balance: ``,
                balanceError: ``,
                disableAmount: false,
              })

              if (agent.payment.length > 1) {
                const currentIndex =
                  endorsment.agentCommissions[agentIndex].payment.length - 2
                agent.payment[currentIndex].disableAmount = true
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeAgentPayment: (
      state,
      { payload: { ind, agentIndex, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.agentCommissions.map((agent, pIndex) => {
            if (agent.payment[paymentIndex].amount) {
              agent.remainingAmount =
                JSON.parse(agent.remainingAmount) +
                JSON.parse(agent.payment[paymentIndex].amount)
            }
            if (pIndex === agentIndex) {
              agent.payment.splice(paymentIndex, 1)
              agent.payment?.map((_payment, pIndex) => {
                if (pIndex > 0) {
                  _payment.balance =
                    agent.payment[pIndex - 1].balance - _payment.amount
                } else {
                  _payment.balance = agent.amount - _payment.amount
                }
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentAgentCommission: (
      state,
      { payload: { value, name, ind, agentIndex, payment, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.agentCommissions.map((agent, aIndex) => {
            if (aIndex === agentIndex) {
              if (payment) {
                agent.payment.map((_payment, pIndex) => {
                  if (pIndex === paymentIndex) {
                    agent.payment[pIndex][name] = value
                  }
                })
              } else {
                agent[name] = value
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    saveAgentCommission: (
      state,
      { payload: { value, name, ind, agentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.agentCommissions.map((agent, aIndex) => {
            if (aIndex === agentIndex) {
              agent[name] = value
              if (agent.paymentType.value === `From Agency Commission`) {
                const remainingBalance =
                  JSON.parse(endorsment.agencyCommission.remainingAmount) -
                  JSON.parse(agent.amount)
                endorsment.agencyCommission.remainingAmount =
                  JSON.stringify(remainingBalance)
                endorsment.agencyCommission.reserveAmount =
                  JSON.stringify(remainingBalance)
              } else {
                endorsment.remainingAmount =
                  JSON.parse(endorsment.remainingAmount) -
                  JSON.parse(agent.amount)
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleAgentCommission: (
      state,
      { payload: { value, name, ind, agentIndex, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.agentCommissions.map((agent, aIndex) => {
            if (aIndex === agentIndex) {
              if (paymentIndex != undefined) {
                agent.payment.map((_payment, pIndex) => {
                  if (pIndex === paymentIndex) {
                    if (value) {
                      if (agent.payment.length > 1) {
                        if (
                          JSON.parse(value) >
                          JSON.parse(agent.payment[pIndex - 1].balance)
                        ) {
                          _payment.amountError = `Amount Must be less than ${
                            agent.payment[pIndex - 1].balance
                          }`
                          _payment.balance =
                            JSON.parse(agent.payment[pIndex - 1].balance) -
                            JSON.parse(value)
                          agent.remainingAmount =
                            JSON.parse(agent.payment[pIndex - 1].balance) -
                            JSON.parse(value)
                        } else {
                          _payment.amountError = ``
                          _payment.balance =
                            JSON.parse(agent.payment[pIndex - 1].balance) -
                            JSON.parse(value)
                          agent.remainingAmount =
                            JSON.parse(agent.payment[pIndex - 1].balance) -
                            JSON.parse(value)
                        }
                      } else {
                        if (JSON.parse(value) > JSON.parse(agent.amount)) {
                          _payment.amountError = `Amount Must be less than ${agent.amount}`
                          _payment.balance =
                            JSON.parse(agent.amount) - JSON.parse(value)
                          agent.remainingAmount =
                            JSON.parse(agent.amount) - JSON.parse(value)
                        } else {
                          _payment.amountError = ``
                          _payment.balance =
                            JSON.parse(agent.amount) - JSON.parse(value)
                          agent.remainingAmount =
                            JSON.parse(agent.amount) - JSON.parse(value)
                        }
                      }
                      _payment[name] = value
                    } else {
                      agent.remainingAmount = 0
                      _payment[name] = value
                      _payment.amountError = value
                      _payment.balance = value
                    }
                  }
                })
              } else {
                if (value) {
                  let premium = 0
                  if (agent.paymentType.value === `From Agency Commission`) {
                    premium = endorsment.agencyCommission.remainingAmount
                  } else if (agent.paymentType.value === `From Base Premium`) {
                    premium = JSON.stringify(endorsment.remainingAmount)
                  } else {
                    premium = 0
                  }

                  if (agent.commissionType.value === `%`) {
                    if (JSON.parse(value) > 100) {
                      agent[name] = value
                      agent.commissionError = `Commissoin must be less than 100%`
                    } else {
                      agent.commissionError = ``
                      agent[name] = value
                      agent.amount =
                        (JSON.parse(premium) * JSON.parse(value)) / 100
                      agent.remainingAmount =
                        (JSON.parse(premium) * JSON.parse(value)) / 100
                    }
                  } else {
                    if (JSON.parse(value) > JSON.parse(premium)) {
                      agent.commissionError = `Commission Must be less than ${premium}`
                      agent.amount = ``
                      agent.remainingAmount = ``
                      agent[name] = value
                    } else {
                      agent.commissionError = ``
                      agent.amount = value
                      agent.remainingAmount = value
                      agent[name] = value
                    }
                  }
                } else {
                  agent[name] = value
                  agent.amount = value
                  agent.remainingAmount = value
                  agent.commissionError = ``
                }
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    // receivable methods starts here
    addReceivable: (state, { payload: ind }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          if (endorsment.receivables) {
            endorsment.receivables.push({
              isPayable: false,
              type: ``,
              typeError: ``,
              dueDate: new Date(),
              dueDateError: ``,
              amount: ``,
              amountError: ``,
              amountType: { value: `%`, label: `%` },
              amountTypeError: ``,
              processFee: ``,
              processFeeError: ``,
              payment: [],
              isGeneratePayments: false,
            })
          } else {
            endorsment.receivables = [
              {
                isPayable: false,
                type: ``,
                typeError: ``,
                dueDate: new Date(),
                dueDateError: ``,
                amount: ``,
                amountError: ``,
                amountType: { value: `%`, label: `%` },
                amountTypeError: ``,
                processFee: ``,
                processFeeError: ``,
                payment: [],
                isGeneratePayments: false,
              },
            ]
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeReceivable: (state, { payload: { ind, recIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          if (endorsment.receivables.length > 1) {
            endorsment.receivables.splice(recIndex, 1)
          } else {
            delete endorsment.receivables
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addReceivablePayment: (state, { payload: { ind, recIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.receivables.map((receivable, pIndex) => {
            if (pIndex === recIndex) {
              receivable.payment.push({
                date: new Date(),
                dateError: ``,
                amount: ``,
                amountError: ``,
                balance: ``,
                balanceError: ``,
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeReceivablePayment: (
      state,
      { payload: { ind, recIndex, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.receivables.map((receivable, pIndex) => {
            if (pIndex === recIndex) {
              receivable.payment.splice(paymentIndex, 1)
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentReceivable: (
      state,
      { payload: { value, name, ind, recIndex, payment, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.receivables.map((receivable, aIndex) => {
            if (aIndex === recIndex) {
              if (payment) {
                receivable.payment.map((_payment, pIndex) => {
                  if (pIndex === paymentIndex) {
                    receivable.payment[pIndex][name] = value
                  }
                })
              } else {
                receivable[name] = value
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    // fee methods starts here
    addFee: (state, { payload: ind }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          if (endorsment.fees) {
            endorsment.fees.push({
              type: ``,
              typeError: ``,
              date: new Date(),
              dateError: ``,
              amount: ``,
              amountError: ``,
              description: ``,
              descriptionError: ``,
              status: ``,
              statusError: ``,
              followUpDate: ``,
              followUpDateError: ``,
              followUpPerson: ``,
              followUpPersonError: ``,
              items: [
                `Direct Bill`,
                `Payable`,
                `Receivable`,
                `Agency Revenue`,
                `Agent Commission`,
              ],
            })
          } else {
            endorsment.fees = [
              {
                type: ``,
                typeError: ``,
                date: new Date(),
                dateError: ``,
                amount: ``,
                amountError: ``,
                description: ``,
                descriptionError: ``,
                status: ``,
                statusError: ``,
                followUpDate: ``,
                followUpDateError: ``,
                followUpPerson: ``,
                followUpPersonError: ``,
                items: [
                  `Direct Bill`,
                  `Payable`,
                  `Receivable`,
                  `Agency Revenue`,
                  `Agent Commission`,
                ],
              },
            ]
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeFee: (state, { payload: { ind, feeIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          if (endorsment.fees.length > 1) {
            endorsment.fees.splice(feeIndex, 1)
          } else {
            delete endorsment.fees
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentFee: (
      state,
      { payload: { value, name, ind, feeIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee[name] = value
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addFeeDirectBill: (state, { payload: { item, ind, feeIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              const newFeeItems = fee.items.filter((_item) => _item !== item)
              fee.items = newFeeItems
              fee.directBill = {
                type: ``,
                typeError: ``,
                dueDate: new Date(),
                dueDateError: ``,
                amount: ``,
                amountError: ``,
                isPrecessedByAgency: false,
                recievedAmount: ``,
                recievedAmountError: ``,
                financeAgreement: ``,
                financeAgreementError: ``,
                description: ``,
                descriptionError: ``,
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeFeeDirectBill: (state, { payload: { ind, feeIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.items.splice(0, 0, `Direct Bill`)
              delete fee.directBill
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentFeeDirectBill: (
      state,
      { payload: { value, name, ind, feeIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.directBill[name] = value
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addFeePayable: (state, { payload: { ind, feeIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              if (fee.payables) {
                fee.payables.push({
                  isPayable: false,
                  type: ``,
                  typeError: ``,
                  dueDate: new Date(),
                  dueDateError: ``,
                  amount: ``,
                  amountError: ``,
                  amountType: { value: `%`, label: `%` },
                  amountTypeError: ``,
                  remitTo: ``,
                  remitToError: ``,
                  payment: [],
                  isGeneratePayments: false,
                })
              } else {
                fee.payables = [
                  {
                    isPayable: false,
                    type: ``,
                    typeError: ``,
                    dueDate: new Date(),
                    dueDateError: ``,
                    amount: ``,
                    amountError: ``,
                    amountType: { value: `%`, label: `%` },
                    amountTypeError: ``,
                    remitTo: ``,
                    remitToError: ``,
                    payment: [],
                    isGeneratePayments: false,
                  },
                ]
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeFeePayable: (
      state,
      { payload: { ind, feeIndex, fPayableIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              if (fee.payables.length > 1) {
                fee.payables.splice(fPayableIndex, 1)
              } else {
                delete fee.payables
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addFeePayablePayment: (
      state,
      { payload: { ind, feeIndex, fPayableIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.payables.map((payable, payIndex) => {
                if (payIndex === fPayableIndex) {
                  payable.payment.push({
                    date: new Date(),
                    dateError: ``,
                    amount: ``,
                    amountError: ``,
                    balance: ``,
                    balanceError: ``,
                  })
                }
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeFeePayablePayment: (
      state,
      { payload: { ind, feeIndex, fPayableIndex, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.payables.map((payable, payIndex) => {
                if (payIndex === fPayableIndex) {
                  payable.payment.splice(paymentIndex, 1)
                }
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentFeePayables: (
      state,
      {
        payload: {
          value,
          name,
          ind,
          feeIndex,
          fPayableIndex,
          payment,
          paymentIndex,
        },
      },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.payables.map((payable, pIndex) => {
                if (pIndex === fPayableIndex) {
                  if (payment) {
                    payable.payment.map((_payment, pIndex) => {
                      if (pIndex === paymentIndex) {
                        payable.payment[pIndex][name] = value
                      }
                    })
                  } else {
                    payable[name] = value
                  }
                }
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addFeeReceivable: (state, { payload: { ind, feeIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              if (fee.receivables) {
                fee.receivables.push({
                  type: ``,
                  typeError: ``,
                  dueDate: new Date(),
                  dueDateError: ``,
                  amount: ``,
                  amountError: ``,
                  amountType: { value: `%`, label: `%` },
                  amountTypeError: ``,
                  processFee: ``,
                  processFeeError: ``,
                  payment: [],
                })
              } else {
                fee.receivables = [
                  {
                    type: ``,
                    typeError: ``,
                    dueDate: new Date(),
                    dueDateError: ``,
                    amount: ``,
                    amountError: ``,
                    amountType: { value: `%`, label: `%` },
                    amountTypeError: ``,
                    processFee: ``,
                    processFeeError: ``,
                    payment: [],
                  },
                ]
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeFeeReceivable: (
      state,
      { payload: { ind, feeIndex, fReceivableIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              if (fee.receivables.length > 1) {
                fee.receivables.splice(fReceivableIndex, 1)
              } else {
                delete fee.receivables
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addFeeReceivablePayment: (
      state,
      { payload: { ind, feeIndex, fReceivableIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.receivables.map((receivable, payIndex) => {
                if (payIndex === fReceivableIndex) {
                  receivable.payment.push({
                    date: new Date(),
                    dateError: ``,
                    amount: ``,
                    amountError: ``,
                    balance: ``,
                    balanceError: ``,
                  })
                }
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeFeeReceivablePayment: (
      state,
      { payload: { ind, feeIndex, fReceivableIndex, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.receivables.map((receivable, payIndex) => {
                if (payIndex === fReceivableIndex) {
                  receivable.payment.splice(paymentIndex, 1)
                }
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentFeeReceivables: (
      state,
      {
        payload: {
          value,
          name,
          ind,
          feeIndex,
          fReceivableIndex,
          payment,
          paymentIndex,
        },
      },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.receivables.map((receivable, pIndex) => {
                if (pIndex === fReceivableIndex) {
                  if (payment) {
                    receivable.payment.map((_payment, pIndex) => {
                      if (pIndex === paymentIndex) {
                        receivable.payment[pIndex][name] = value
                      }
                    })
                  } else {
                    receivable[name] = value
                  }
                }
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addFeeAgencyRenevue: (state, { payload: { item, ind, feeIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              const newFeeItems = fee.items.filter((_item) => _item !== item)
              fee.items = newFeeItems
              fee.agencyRevenue = {
                revenue: ``,
                revenueError: ``,
                revenueType: ``,
                revenueTypeError: ``,
                dueDate: new Date(),
                dueDateError: ``,
                payment: [],
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeFeeAgencyRevenue: (state, { payload: { ind, feeIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.items.splice(3, 0, `Agency Revenue`)
              delete fee.agencyRevenue
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addFeeAgencyRevenuePayment: (state, { payload: { ind, feeIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.agencyRevenue.payment.push({
                date: new Date(),
                dateError: ``,
                amount: ``,
                amountError: ``,
                balance: ``,
                balanceError: ``,
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeFeeAgencyRevenuePayment: (
      state,
      { payload: { ind, feeIndex, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.agencyRevenue.payment.splice(paymentIndex, 1)
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentFeeAgencyRevenue: (
      state,
      { payload: { value, name, ind, feeIndex, payment, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              if (payment) {
                fee.agencyRevenue.payment.map((_payment, pIndex) => {
                  if (pIndex === paymentIndex) {
                    fee.agencyRevenue.payment[pIndex][name] = value
                  }
                })
              } else {
                fee.agencyRevenue[name] = value
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addFeeAgentCommission: (state, { payload: { ind, feeIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              if (fee.agentCommissions) {
                fee.agentCommissions.push({
                  agents: ``,
                  agentsError: ``,
                  paymentType: ``,
                  paymentTypeError: ``,
                  commission: ``,
                  commissionError: ``,
                  commissionType: { value: `%`, label: `%` },
                  commissionTypeError: ``,
                  payment: [],
                })
              } else {
                fee.agentCommissions = [
                  {
                    agents: ``,
                    agentsError: ``,
                    paymentType: ``,
                    paymentTypeError: ``,
                    commission: ``,
                    commissionError: ``,
                    commissionType: { value: `%`, label: `%` },
                    commissionTypeError: ``,
                    payment: [],
                  },
                ]
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeFeeAgentCommission: (
      state,
      { payload: { ind, feeIndex, fACIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              if (fee.agentCommissions.length > 1) {
                fee.agentCommissions.splice(fACIndex, 1)
              } else {
                delete fee.agentCommissions
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addFeeAgencyCommissionPayment: (
      state,
      { payload: { ind, feeIndex, fACIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.agentCommissions.map((agentCommission, payIndex) => {
                if (payIndex === fACIndex) {
                  agentCommission.payment.push({
                    date: new Date(),
                    dateError: ``,
                    amount: ``,
                    amountError: ``,
                    balance: ``,
                    balanceError: ``,
                  })
                }
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removeFeeAgentRevenuePayment: (
      state,
      { payload: { ind, feeIndex, fACIndex, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.agentCommissions.map((agentCommission, payIndex) => {
                if (payIndex === fACIndex) {
                  agentCommission.payment.splice(paymentIndex, 1)
                }
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentFeeAgentCommission: (
      state,
      {
        payload: {
          value,
          name,
          ind,
          feeIndex,
          fACIndex,
          payment,
          paymentIndex,
        },
      },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.fees.map((fee, fIndex) => {
            if (fIndex === feeIndex) {
              fee.agentCommissions.map((agentCommission, pIndex) => {
                if (pIndex === fACIndex) {
                  if (payment) {
                    agentCommission.payment.map((_payment, pIndex) => {
                      if (pIndex === paymentIndex) {
                        agentCommission.payment[pIndex][name] = value
                      }
                    })
                  } else {
                    agentCommission[name] = value
                  }
                }
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addPayable: (state, { payload: ind }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          if (endorsment.payables) {
            endorsment.payables.push({
              isPayable: false,
              type: ``,
              typeError: ``,
              dueDate: new Date(),
              dueDateError: ``,
              amount: ``,
              amountError: ``,
              amountType: { value: `%`, label: `%` },
              amountTypeError: ``,
              remitTo: ``,
              remitToError: ``,
              payment: [],
              isGeneratePayments: false,
            })
          } else {
            endorsment.payables = [
              {
                isPayable: false,
                type: ``,
                typeError: ``,
                dueDate: new Date(),
                dueDateError: ``,
                amount: ``,
                amountError: ``,
                amountType: { value: `%`, label: `%` },
                amountTypeError: ``,
                remitTo: ``,
                remitToError: ``,
                payment: [],
                isGeneratePayments: false,
              },
            ]
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removePayable: (state, { payload: { ind, payIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          if (endorsment.payables.length > 1) {
            endorsment.payables.splice(payIndex, 1)
          } else {
            delete endorsment.payables
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    addPayablePayment: (state, { payload: { ind, payIndex } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.payables.map((payable, pIndex) => {
            if (pIndex === payIndex) {
              payable.payment.push({
                date: new Date(),
                dateError: ``,
                amount: ``,
                amountError: ``,
                balance: ``,
                balanceError: ``,
              })
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    removePayablePayment: (
      state,
      { payload: { ind, payIndex, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.payables.map((receivable, pIndex) => {
            if (pIndex === payIndex) {
              receivable.payment.splice(paymentIndex, 1)
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentPayable: (
      state,
      { payload: { value, name, ind, payIndex, payment, paymentIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment.payables.map((payable, aIndex) => {
            if (aIndex === payIndex) {
              if (payment) {
                payable.payment.map((_payment, pIndex) => {
                  if (pIndex === paymentIndex) {
                    payable.payment[pIndex][name] = value
                  }
                })
              } else {
                payable[name] = value
              }
            }
          })
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEdorsmentChange: (state, { payload: { value, name, ind } }) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index === ind) {
          endorsment[name] = value
        }
        return endorsment
      })

      state.endorsements = newState
    },

    handleEndorsmentError: (
      state,
      { payload: { name, value, ind, obj, payment, paymentIndex, objIndex } },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index == ind) {
          if (obj) {
            if (payment) {
              if (objIndex != undefined) {
                endorsment[obj].map((_obj, oIndex) => {
                  if (oIndex == objIndex) {
                    _obj.payment.map((_payment, oIndex) => {
                      if (oIndex == paymentIndex) {
                        _payment[name] = value
                      }
                    })
                  }
                })
              } else {
                endorsment[obj].payment.map((_payment, oIndex) => {
                  if (oIndex == paymentIndex) {
                    _payment[name] = value
                  }
                })
              }
            } else {
              if (objIndex != undefined) {
                endorsment[obj].map((_obj, oIndex) => {
                  if (oIndex == objIndex) {
                    _obj[name] = value
                  }
                })
              } else {
                endorsment[obj][name] = value
              }
            }
          } else {
            endorsment[name] = value
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },

    handleEndorsmentFeeError: (
      state,
      {
        payload: {
          name,
          value,
          ind,
          feeIndex,
          feeObj,
          feeObjIndex,
          feeObjPaymentIndex,
        },
      },
    ) => {
      const newState = state.endorsements?.map((endorsment, index) => {
        if (index == ind) {
          if (feeObj) {
            endorsment.fees.map((_fee, oIndex) => {
              if (oIndex == feeIndex) {
                if (feeObjIndex != undefined) {
                  _fee[feeObj].map((_feeObj, FOIndex) => {
                    if (FOIndex == feeObjIndex) {
                      if (feeObjPaymentIndex != undefined) {
                        _feeObj.payment.map((_payment, pIndex) => {
                          if (pIndex == feeObjPaymentIndex) {
                            _payment[name] = value
                          }
                        })
                      } else {
                        _feeObj[name] = value
                      }
                    }
                  })
                } else {
                  if (feeObjPaymentIndex != undefined) {
                    _fee[feeObj].payment.map((_payment, pIndex) => {
                      if (pIndex == feeObjPaymentIndex) {
                        _payment[name] = value
                      }
                    })
                  } else {
                    _fee[feeObj][name] = value
                  }
                }
              }
            })
          } else {
            endorsment.fees.map((_fee, oIndex) => {
              if (oIndex == feeIndex) {
                _fee[name] = value
              }
            })
          }
        }
        return endorsment
      })
      state.endorsements = newState
    },
  },
})

export const {
  addEndorsment,
  removeEndorsment,
  addDirectBill,
  removeDirectBill,
  handleEndorsmentDirectBill,
  addAgencyCommission,
  removeAgencyCommission,
  handleEndorsmentAgencyCommission,
  addPayment,
  removePayment,
  addAgentCommission,
  removeAgentCommission,
  addAgentPayment,
  removeAgentPayment,
  handleEndorsmentAgentCommission,
  addReceivable,
  removeReceivable,
  addReceivablePayment,
  removeReceivablePayment,
  handleEndorsmentReceivable,
  addFee,
  removeFee,
  handleEndorsmentFee,
  addFeeDirectBill,
  removeFeeDirectBill,
  handleEndorsmentFeeDirectBill,
  addFeePayable,
  removeFeePayable,
  addFeePayablePayment,
  removeFeePayablePayment,
  handleEndorsmentFeePayables,
  addFeeReceivable,
  removeFeeReceivable,
  addFeeReceivablePayment,
  removeFeeReceivablePayment,
  handleEndorsmentFeeReceivables,
  addFeeAgencyRenevue,
  removeFeeAgencyRevenue,
  addFeeAgencyRevenuePayment,
  removeFeeAgencyRevenuePayment,
  handleEndorsmentFeeAgencyRevenue,
  addFeeAgentCommission,
  removeFeeAgentCommission,
  addFeeAgencyCommissionPayment,
  removeFeeAgentRevenuePayment,
  handleEndorsmentFeeAgentCommission,
  addPayable,
  removePayable,
  addPayablePayment,
  removePayablePayment,
  handleEndorsmentPayable,
  handleEdorsmentChange,
  handleEndorsmentError,
  handleEndorsmentFeeError,
  handleCommission,
  handleAgentCommission,
  saveAgencyCommission,
  saveAgentCommission,
  handleDirectBill,
} = endorsmentSlice.actions

export const endorsements = endorsmentSlice.reducer
