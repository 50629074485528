import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setOutboundMessages } from 'App/Redux/actions'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { newSocket } from 'App/HOC/MainLayout'
import { VideoCallBlock } from 'App/Styled'
import { smallLogo } from 'App/Assets'

export const Video = () => {
  const {
    params: { customerId, leadId, dataTransactionId },
  } = useRouteMatch()
  const dispatch = useDispatch()
  const { communicationCredentials } = useSelector(
    ({ currentCommunication }) => currentCommunication,
  )
  const { user, preferredLocation } = useSelector(({ user }) => user)
  useEffect(() => {
    if (newSocket) {
      newSocket?.on(`OUTBOUND_MESSAGE_RES`, (message) => {
        if (
          (message?._id && customerId) ||
          leadId ||
          dataTransactionId == message?._customerId
        ) {
          dispatch(setOutboundMessages(message))
        }
      })
    }

    return () => {
      newSocket?.off(`OUTBOUND_MESSAGE_RES`)
    }
  }, [newSocket, customerId, leadId, dataTransactionId])

  const sendingSMS = () => {
    const newMessageArray = {
      direction: `OUTBOUND`,
      status: `sending`,
      type: `SMS`,
      _customerId: communicationCredentials?.customerId,
      _franchiseId: user?.profile?._franchise?._id,
      _agentId: user?.profile?.sub,
      agentImg: user?.profile?.dp,
      agentName: user?.profile?.full_name,
      message: `${window.location.origin}/video-call?roomId=${user?.profile?.sub}`,
      customerPhone: communicationCredentials?.phone,
      locationPhone: preferredLocation?.phone,
    }
    newSocket?.emit(`OUTBOUND_MESSAGE`, newMessageArray)
  }

  return (
    <>
      {communicationCredentials?.doNotContact ? (
        <div className="flex justify-center items-center h-96 max-h-xl font-semibold text-sm text-gray-500">
          <p>DNC enabled</p>
        </div>
      ) : communicationCredentials?.preferredContactMethods?.length &&
        !communicationCredentials?.preferredContactMethods.includes(`CALL`) ? (
        <div className="grid place-content-center place-items-center gap-y-2 h-96 max-h-xl font-semibold text-sm text-gray-500">
          <div className=" rounded-full bg-gray-200 p-4">
            <FontAwesomeIcon icon={faVideo} size="lg" />
          </div>
          <p>Not Preferred</p>
        </div>
      ) : communicationCredentials?.phone ? (
        <div className="flex justify-center items-center h-96 max-h-xl">
          <div className="flex flex-col justify-center items-center space-y-24">
            <img
              className="bg-gray-100 z-10 border-1 object-cover rounded-full h-20 w-20 "
              alt="fImage"
              src={smallLogo}
            />
            <div className="flex flex-col space-y-5 justify-center items-center">
              <VideoCallBlock
                data-tip="Video"
                className="tooltip hover:text-gray-600 "
                onClick={() => {
                  sendingSMS()
                  // handleVideoCall()
                  // dispatch(setVideoActive(!videoActive))
                }}
                target="_blank"
                href={`${window.location.origin}/video-call?roomId=${user?.profile?.sub}`}
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faVideo}
                  className="cursor-pointer  hover:text-red-500"
                  size="lg"
                />
              </VideoCallBlock>
              <span>Click to Video Call</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="grid place-content-center place-items-center gap-y-2 h-96 max-h-xl font-semibold text-sm text-gray-500">
          <div className=" rounded-full bg-gray-200 p-4">
            <FontAwesomeIcon icon={faVideo} size="lg" />
          </div>
          <p>Contact info is not provided</p>
        </div>
      )}
    </>
  )
}
