

export const loadSalesInTypes = async () => {
    return {
        options: [
            { value: `inOffice`, label: `In Office` },
            { value: `call`, label: `Call` },
            { value: `sms`, label: `SMS` },
            { value: `online`, label: `Online` },
        ],
        hasMore: false,
    }
}

export const loadSalesTypes = async () => {
    return {
        options: [
            { value: `inBound`, label: `In Bound` },
            { value: `outBound`, label: `Out Bound` },
        ],
        hasMore: false,
    }
}

export const lasLastSeenTypes = async () => {
    return {
        options: [
            { value: `vieFriend`, label: `Via Friend` },
            { value: `vieAds`, label: `Via Ads` },
            { value: `vieSocialMedia`, label: `Via Social Media` },
        ],
        hasMore: false,
    }
}