import React from 'react'
import { Overview } from './Overview'
import { AllContacts } from 'App/Components/Customers/Customer/Tabs/Contacts/AllContacts'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveCustomerTab } from 'App/Redux/actions'
import { TFive, TOne, TTwo } from 'App/Styled'
import { FileManager } from './FileManager'

const cTabs = [
  {
    tName: `Overview`,
    tNumber: 1,
    permission: [
      `customers:read`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
  {
    tName: `Contacts`,
    tNumber: 2,
    permission: [`contacts:read`, `admin-franchise`, `admin-master-franchise`],
  },
  // {
  //   tName: `Assets`,
  //   tNumber: 3,
  //   permission: `assets:read`,
  // },
  // {
  //   tName: `Payments`,
  //   tNumber: 4,
  // },
  {
    tName: `Files`,
    tNumber: 5,
    permission: [
      `customer-file:read`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
]

export const Tabs = () => {
  const { activeTab } = useSelector(({ customer }) => customer)
  const dispatch = useDispatch()

  return (
    <div className="flex justify-between h-16">
      <div className="flex w-full">
        <div className="flex flex-wrap w-full">
          <div className="w-full">
            <TabsHeaders
              openTab={activeTab}
              tabs={cTabs}
              getActiveTab={(val) => dispatch(setActiveCustomerTab(val))}
            />
            <div className=" flex-auto">
              <div className="tab-content tab-space">
                {/* tab one */}
                <TOne opentab={activeTab}>
                  <Overview />
                </TOne>

                {/* tab 2 */}
                <TTwo opentab={activeTab}>
                  <AllContacts />
                </TTwo>

                {/* <TThree opentab={activeTab}>
                  <Assets />
                </TThree> */}

                {/* <TFour opentab={activeTab}>
                  <AllPayments />
                </TFour> */}
                <TFive opentab={activeTab}>
                  <FileManager />
                </TFive>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
