import React from 'react'
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader } from 'App/Styled'
import { useSelector } from 'react-redux'
import { CollapsableRef } from '../Common/CollapsableRef'
import { SearchAgents } from './SearchAgents'
import { SearchCustomers } from './SearchCustomers'
import { SearchPayments } from './SearchPayment'
import { SearchPolicies } from './SearchPolicies'
import { SearchLeads } from './SearchLeads'
import { SearchQuotes } from './SearchQuotes'
import { GlobalCustomers } from './GlobalCustomers'

export const GlobalSearch = () => {
  const { searchedData, isLoading, searchQuery, globalCustomerFilter } =
    useSelector(({ globalSearch }) => globalSearch)
  return (
    <>
      {globalCustomerFilter && searchQuery ? (
        <GlobalCustomers />
      ) : isLoading ? (
        <div className="text-center py-10">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : !searchedData?.hasSearchData ? (
        <div className="h-5/6 grid place-content-center w-full text-center">
          <FontAwesomeIcon
            icon={faSearch}
            size="4x"
            className={`w-full text-gray-500`}
          />
          <p className="text-gray-500 ml-8 my-1 p-2 capitalize">
            no record found
          </p>
        </div>
      ) : globalCustomerFilter && searchQuery ? (
        <GlobalCustomers />
      ) : (
        <Container>
          <PageHeader>
            <div>Your Search Result</div>
          </PageHeader>
          <div className="m-1">
            {searchedData?.leads?.length > 0 && (
              <CollapsableRef title="Leads">
                <SearchLeads />
              </CollapsableRef>
            )}
          </div>
          <div className="m-1">
            {searchedData?.quotes?.length > 0 && (
              <CollapsableRef title="Quotes">
                <SearchQuotes />
              </CollapsableRef>
            )}
          </div>
          <div className="m-1">
            {searchedData?.customers?.length > 0 && (
              <CollapsableRef title="Customers">
                <SearchCustomers />
              </CollapsableRef>
            )}
          </div>
          <div className="m-1">
            {searchedData?.invoices?.length > 0 && (
              <CollapsableRef title="Payments">
                <SearchPayments />
              </CollapsableRef>
            )}
          </div>
          <div className="m-1">
            {searchedData?.user?.length > 0 && (
              <CollapsableRef title="Agents">
                <SearchAgents />
              </CollapsableRef>
            )}
          </div>
          <div className="m-1">
            {searchedData?.policies?.length > 0 && (
              <CollapsableRef title="Policies">
                <SearchPolicies />
              </CollapsableRef>
            )}
          </div>
          {/* <div className="m-1 pb-1">
            {searchedData?.franchises?.length > 0 && (
              <CollapsableRef title="Franchise">
                <SearchFranchise />
              </CollapsableRef>
            )}
          </div> */}
        </Container>
      )}
    </>
  )
}
