import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from '../style'
import { PolicyReportPdfHeader } from 'App/Components/Common/PolicyReportPdfHeader'
import dateFormat from 'App/Services/General/dateFormat'

export const Pdf = ({ insured, dateRange = [] }) => {
  return (
    <Document subject="Report" author="Reports">
      <Page style={styles.page} size="A3" wrap>
        <View style={styles.section}>
          <Text style={styles.pageHeading}>
            Invoices By Agent Details Report
          </Text>
          <Text style={styles.smallSpace}></Text>
          <PolicyReportPdfHeader dateRange={dateRange} />
          <Text style={styles.smallSpace}></Text>

          <View style={styles.headingRow}>
            <Text style={styles.pageTitles}> Carrier</Text>
            <Text style={styles.pageTitles}> Agent</Text>
            <Text style={styles.pageTitles}> Policy</Text>
            <Text style={styles.pageTitles}> Payment Method</Text>
            <Text style={styles.pageTitles}> Recieved</Text>
            <Text style={styles.pageTitles}> Due</Text>
            <Text style={styles.pageTitles}> Due Date</Text>
          </View>

          {insured?.length > 0 &&
            insured?.map((item, index) => (
              <View style={styles.agent} key={index}>
                <View style={styles.headingRow2}>
                  <Text style={styles.pageTitles2}>{item?._carrier}</Text>
                  <Text style={styles.pageTitles2}>{item?._agent}</Text>
                  <Text style={styles.pageTitles2}>{item?.policy}</Text>
                  <Text style={styles.pageTitles2}>
                    {item?.paymentMethod.map((item, ind) => {
                      if (ind > 0) {
                        return `,` + item
                      } else {
                        return item
                      }
                    })}
                  </Text>
                  <Text style={styles.pageTitles2}>{item?.recieved}</Text>
                  <Text style={styles.pageTitles2}>{item?.due}</Text>
                  <Text style={styles.pageTitles2}>
                    {dateFormat(item?.dueDate)}
                  </Text>
                </View>
              </View>
            ))}
        </View>
      </Page>
    </Document>
  )
}
