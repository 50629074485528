import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  section: {
    height: `auto`,
    backgroundColor: `#d4d2d2`,
    margin: `5px`,
    width: `100%`,
  },
  page: {
    flexDirection: `row`,
    backgroundColor: `#E4E4E4`,
    overflow: `auto`,
  },
  heading: {
    fontSize: `14px`,
    color: `white`,
    backgroundColor: `gray`,
    borderRadius: `2px`,
    padding: 8,
  },
  pageHeading: {
    fontSize: `16px`,
    color: `white`,
    backgroundColor: `gray`,
    borderRadius: `2px`,
    padding: 8,
    textAlign: `center`,
    fontWeight: `bold`,
  },
  text: {
    fontSize: `12px`,
    color: `black`,
    borderRadius: `2px`,
    padding: 2,
    width: `90%`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    marginHorizontal: 10,
  },

  total: {
    fontSize: `12px`,
    color: `black`,
    borderRadius: `2px`,
    padding: 2,
    width: `90%`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    marginHorizontal: 10,
    borderWidth: 1,
  },

  space: {
    height: `18px`,
    backgroundColor: `white`,
  },
  smallSpace: {
    height: `10px`,
    backgroundColor: `white`,
  },
  headingRow: {
    color: `white`,
    backgroundColor: `gray`,
    borderRadius: `2px`,
    padding: 8,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-evenly`,
  },

  pageTitles: {
    fontSize: `12px`,
    color: `white`,
    backgroundColor: `gray`,
    padding: 2,
    marginRight: `4px`,
    width: `95px`,
    fontWeight: `extrabold`,
  },

  headingRow2: {
    color: `black`,
    backgroundColor: `white`,
    paddingHorizontal: 8,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-evenly`,
  },
  pageTitles2: {
    fontSize: `10px`,
    color: `black`,
    textAlign: `left`,
    width: `95px`,
    backgroundColor: `white`,
    padding: 2,
  },

  agentName: {
    fontSize: `12px`,
    color: `white`,
    backgroundColor: `gray`,
    padding: 2,
    paddingHorizontal: 8,
  },
  agent: {
    marginVertical: `4px`,
  },
})
