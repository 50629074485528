import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { setLocationActivityActiveTab } from 'App/Redux/actions'
import { TOne, TTwo } from 'App/Styled'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { LocationNote } from '../../LocationNote'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { permissionGranted } from 'App/Services'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  {
    name: `Logs`,
    number: 2,
  },
]
const activityTabs2 = [
  {
    name: `Logs`,
    number: 1,
  },
]
export const LocationActivity = () => {
  const { currentLocation, activeActivityTab } = useSelector(
    ({ location }) => location,
  )
  const dispatch = useDispatch()
  return (
    <div className="px-2">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([`notes:read`, `corporate-manager:*`, `manager:*`])
            ? activityTabs
            : activityTabs2
        }
        getActiveTab={(val) => dispatch(setLocationActivityActiveTab(val))}
      />
      {permissionGranted(
        [`notes:read`, `corporate-manager:*`, `manager:*`] || [
          `notes:*`,
          `corporate-manager:*`,
          `manager:*`,
        ],
      ) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <LocationNote />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <UpdateLogs
              logs={
                currentLocation?.updateLogs ? currentLocation?.updateLogs : []
              }
            />
          </TTwo>
        </div>
      ) : (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <UpdateLogs
              logs={
                currentLocation?.updateLogs ? currentLocation?.updateLogs : []
              }
            />
          </TOne>
        </div>
      )}
    </div>
  )
}
