import { OIDC_BACKEND_API } from './backendApis'

export const OIDC_AUTHORITY = OIDC_BACKEND_API
export const OIDC_CLIENT_ID = process.env.REACT_APP_OIDC_CLIENT_ID
export const OIDC_REDIRECT_URI = process.env.REACT_APP_OIDC_REDIRECT_URI
export const OIDC_LOGOUT_REDIRECT_URI =
  process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI
export const OIDC_CLIENT_SECRET = process.env.REACT_APP_OIDC_CLIENT_SECRET

// qasims account unspash client id
export const UNSPLASH_CLIENT_ID = process.env.REACT_APP_UNSPLASH_CLIENT_ID
export const APP_BASEURL = process.env.REACT_APP_ENDPOINT
export const IDP_FRONTEND = process.env.REACT_APP_OIDC_FRONTEND_APP
// export const IDP_FRONTEND = `http://localhost:3002`
