import React, { useEffect, useState } from 'react'
import { defaultImage } from 'App/Assets'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Avatar,
} from '@windmill/react-ui'
import {
  faSortDown,
  faSortUp,
  faEdit,
  faSpinner,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { ReactModal } from 'App/Components/Common/Modal'
import { StartCampaign } from 'App/Components/StartCampaign'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadPreferredContact, loadStatuses } from 'App/Services/quoteForm'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'
import { Spinner } from 'App/Components/Common/Spinner'

const tableColumns = [
  { label: `Full Name`, value: `firstName` },
  { label: `Email`, value: `email` },
  { label: `Phone`, value: `contactInfo.phone` },
  { label: `Status`, value: `status` },
  // { label: `DCN`, value: `dcn` },
  // { label: `PCM`, value: `pcm` },
  // { label: `Policies`, value: `_policies` },
  { label: `Created`, value: `createdAt` },
  { label: `Updated`, value: `updatedAt` },
]

export const AllOtherCustomers = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const [inActiveCheckedAll, setinActiveCheckedAll] = useState([])
  const [startCampaignModal, setStartCampaignModal] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState(``)
  const [updateContactMethod, setUpdateContactMethod] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(false)
  const [customerContactMethods, setCustomerContactMethods] = useState(``)
  const [customerStatus, setCustomerStatus] = useState(``)
  const [isOffset, setIsOffset] = useState(``)

  const [stateChecked, setStateChecked] = useState({
    allSelected: false,
    isCheckedAll: false,
    selected: [],
    unselected: [],
  })
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)

  // const [isMasterFrenchise, setIsMasterFrenchise] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)

  //   getting all customers
  const {
    isLoading,
    data: customersData,
    refetch: getAllCustomers,
    isFetching,
    error,
  } = useQuery(
    `CustomersApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/other-customers/all?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomSearching(false)
        setStateChecked((prevState) => ({
          ...prevState,
          isCheckedAll: handleIsCheckedAllSelected(res?.customers),
        }))
        if (stateChecked.allSelected) {
          setStateChecked((prevState) => ({
            ...prevState,
            isCheckedAll: !inActiveCheckedAll.includes(currentPage),
            selected: handleAddSelected(res?.customers),
          }))
        }
      },
    },
  )

  const {
    mutate: updateCustomer,
    isLoading: updatingCustomer,
    isError: updateError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/other-customers/${payload.customerId}`,
        {
          status: payload.status,
          preferredContactMethods: payload.preferredContactMethods,
        },
      ).then((res) => {
        if (res.data) {
          getAllCustomers()
          NotificationManager.success(`Operation Successful`)
          setUpdateStatus(false)
          setUpdateContactMethod(false)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (!customSearching) {
      getAllCustomers()
    }
  }, [sort, currentPage, isOffset])

  const callCustomerQuery = useDebouncedCallback(() => {
    getAllCustomers()
  }, 1000)

  const handleContactMethods = (customer, e) => {
    if (e && e.stopPropagation) e.stopPropagation()

    setCustomerContactMethods(
      customer?.preferredContactMethods?.map((method) => ({
        value: method,
        label: method.toUpperCase(),
      })),
    )

    setCurrentCustomer(customer)
    setUpdateContactMethod(true)
  }

  const handleStatusUpdate = (customer, e) => {
    if (e && e.stopPropagation) e.stopPropagation()

    setCustomerStatus({
      label: customer?.status,
      value: customer?.status,
    })

    setCurrentCustomer(customer)
    setUpdateStatus(true)
  }

  useEffect(() => {
    if (stateChecked.allSelected) {
      setStateChecked({
        allSelected: true,
        isCheckedAll: true,
        selected: customersData.customers?.map((lead) => lead._id),
        unselected: [],
      })
    } else {
      setStateChecked({
        allSelected: false,
        isCheckedAll: false,
        selected: [],
        unselected: [],
      })
    }
  }, [stateChecked.allSelected])

  const handleIsCheckedAllSelected = (currentLeads) => {
    const filterdSelected = currentLeads
      ?.map((lead) => {
        if (!stateChecked.selected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    if (filterdSelected.length) {
      return false
    } else {
      return true
    }
  }

  const handleAddSelected = (currentLeads) => {
    let filterdUnselected
    if (stateChecked.unselected.length) {
      filterdUnselected = currentLeads
        ?.map((lead) => {
          if (!stateChecked.unselected.includes(lead._id)) {
            return lead._id
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    } else {
      filterdUnselected = currentLeads?.map((lead) => lead._id)
    }

    const filterdSelected = filterdUnselected
      ?.map((lead) => {
        if (!stateChecked.selected.includes(lead)) {
          return lead
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.selected, ...filterdSelected]
  }

  const handleIndividualChekbox = (e, id) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (!e.target.checked) {
      setStateChecked((prevState) => ({
        ...prevState,
        selected: stateChecked.selected.filter((lead) => lead !== id),
        unselected: [...stateChecked.unselected, id],
      }))
    } else {
      setStateChecked((prevState) => ({
        ...prevState,
        selected: [...stateChecked.selected, id],
        unselected: stateChecked.unselected.filter((lead) => lead !== id),
      }))
    }
  }

  const handleCheckAll = (e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (e.target.checked) {
      setinActiveCheckedAll(
        inActiveCheckedAll.filter((el) => el !== currentPage),
      )
      setStateChecked((prevState) => ({
        ...prevState,
        isCheckedAll: true,
        selected: handleCheckedAllSelected(),
        unselected: removeOneArrayFromAnother(stateChecked.unselected),
      }))
    } else {
      setinActiveCheckedAll([...inActiveCheckedAll, currentPage])
      setStateChecked((prevState) => ({
        ...prevState,
        isCheckedAll: false,
        selected: removeOneArrayFromAnother(stateChecked.selected),
        unselected: handleRemvoeUnSelected(),
      }))
    }
  }

  const handleCheckedAllSelected = () => {
    const filterdSelected = customersData?.customers
      ?.map((lead) => {
        if (!stateChecked.selected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.selected, ...filterdSelected]
  }

  const handleRemvoeUnSelected = () => {
    const filterdSelected = customersData?.customers
      ?.map((lead) => {
        if (!stateChecked.unselected.includes(lead._id)) {
          return lead._id
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)

    return [...stateChecked.unselected, ...filterdSelected]
  }

  const removeOneArrayFromAnother = (removeFromArray) => {
    const leadsToBeRemoved = customersData?.customers?.map((lead) => lead._id)
    return removeFromArray.filter((el) => {
      return !leadsToBeRemoved?.includes(el)
    })
  }

  return (
    <Container>
      {updateContactMethod && (
        <ReactModal
          title="Update Contact Methods"
          closeModal={() => setUpdateContactMethod(false)}
        >
          <div className="w-96 px-4 py-1">
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Contact Methods"
                  placeholder="Select Methods"
                  // errorMessage={errors.leadStatusError}
                  value={customerContactMethods}
                  setValue={(e) => setCustomerContactMethods(e)}
                  isMulti={true}
                  loadOptions={loadPreferredContact}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="pb-0.5 text-right">
              <StyledButton
                onClick={() =>
                  updateCustomer({
                    preferredContactMethods: customerContactMethods?.map(
                      (method) => method?.value,
                    ),
                    customerId: currentCustomer?._id,
                  })
                }
                disabled={!customerContactMethods?.length}
              >
                <div className="flex gap-2 text-white">
                  <span>Update</span>
                  {updatingCustomer && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin={true}
                      className="my-auto"
                    />
                  )}
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}
      {updateStatus && (
        <ReactModal
          title="Update Status"
          closeModal={() => setUpdateStatus(false)}
        >
          <div className="w-96 px-4 py-1">
            <div className="flex gap-6 pt-2">
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Status"
                  placeholder="Select Status"
                  // errorMessage={errors.leadStatusError}
                  value={customerStatus}
                  setValue={(value) => setCustomerStatus(value)}
                  loadOptions={loadStatuses}
                  isSearchable={false}
                />
              </div>
            </div>
            <div className="pb-0.5 text-right">
              <StyledButton
                onClick={() =>
                  updateCustomer({
                    status: customerStatus.value,
                    customerId: currentCustomer?._id,
                  })
                }
              >
                <div className="flex gap-2 text-white">
                  <span>Update</span>
                  {updatingCustomer && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin={true}
                      className="my-auto"
                    />
                  )}
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}
      {updateError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      {startCampaignModal && (
        <ReactModal
          title="Start Campaign"
          closeModal={() => setStartCampaignModal(false)}
        >
          <StartCampaign
            usersList={stateChecked}
            closeModal={() => setStartCampaignModal(false)}
            source="other_customer"
            count={customersData?.totalRecords - stateChecked.unselected.length}
          />
        </ReactModal>
      )}
      <Spinner loading={isLoading || isFetching} />
      <PageHeader bgcolor="bg-white">
        <div>Other Customers</div>
        <div className="flex items-center ">
          {/* <Toggle value={isMasterFrenchise} setValue={setIsMasterFrenchise} /> */}
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end ">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callCustomerQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (isLoading || isFetching) && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="flex justify-between items-center pt-2">
          <StyledButton
            onClick={() =>
              setStateChecked((prevState) => ({
                ...prevState,
                allSelected: !stateChecked.allSelected,
              }))
            }
          >
            <div className="flex gap-2 text-white">
              <span>
                {stateChecked?.allSelected ? `Selected ` : `Select All `}
                {customersData?.totalRecords - stateChecked.unselected.length}
              </span>
            </div>
          </StyledButton>
          {permissionGranted([
            `broadcasts:create`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && (
            <StyledButton
              disabled={!stateChecked?.selected?.length}
              onClick={() => setStartCampaignModal(true)}
            >
              <div className="flex gap-2 text-white">
                <span>Start Campaign </span>
              </div>
            </StyledButton>
          )}
        </div>
        {error ? (
          <Reload refetch={() => getAllCustomers()} />
        ) : (
          <>
            <div className="overflow-x-auto   rounded-t-md mt-1">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    <TableCell className="text-center">
                      <div className="flex-1">
                        <Checkbox
                          name="checkAll"
                          value={stateChecked?.isCheckedAll}
                          setValue={(e) => handleCheckAll(e)}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="select-none text-sm">Image</TableCell>
                    {tableColumns?.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className=" cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item?.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(permissionGranted([
                      `other-customers:read`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) ||
                      permissionGranted([
                        `other-customers:update`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ])) && (
                      <TableCell className="select-none text-sm">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !customersData?.customers?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        No Customer Found
                      </td>
                    </tr>
                  ) : (
                    customersData?.customers?.map((_customer, index) => (
                      <TableRow
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          if (
                            permissionGranted([
                              `other-customers:read`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])
                          ) {
                            history.push(`${url}/${_customer._id}`)
                          }
                        }}
                      >
                        <td
                          className="px-4 text-sm"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Checkbox
                            name={_customer?._id}
                            value={stateChecked?.selected.includes(
                              _customer?._id,
                            )}
                            setValue={(e) =>
                              handleIndividualChekbox(e, _customer?._id)
                            }
                          />
                        </td>
                        <td className="px-5 py-1.5 text-sm">
                          <div className="flex items-center text-sm">
                            <Avatar
                              src={_customer.img ? _customer.img : defaultImage}
                              alt="Judith"
                            />
                          </div>
                        </td>
                        <td
                          className="px-4 py-0.5 cursor-pointer capitalize truncate text-sm"
                          onClick={() => {
                            history.push(`${url}/${_customer._id}`)
                          }}
                        >
                          {_customer.firstName ? (
                            `${_customer.firstName?.toLowerCase()} ${_customer.lastName?.toLowerCase()}`
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>

                        <td className="px-4 py-0.5 text-sm">
                          {_customer.contactInfo?.email ? (
                            _customer.contactInfo.email
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-2 py-0.5 w-auto text-sm">
                          {}
                          {_customer.contactInfo?.phone ? (
                            _customer.contactInfo?.phone
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-2 py-0.5 w-auto text-sm">
                          {}
                          {_customer.status ? (
                            _customer.status
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        {/* <td className="px-2 py-0.5 w-auto text-sm">
                          <Badge type="warning">
                            {_customer?.doNotContact ? `ENABLED` : `DISABLED`}
                          </Badge>
                        </td> */}
                        {/* <td className="px-2 py-0.5 w-auto text-sm">
                          <Badge>
                            {_customer.preferredContactMethod
                              ? _customer.preferredContactMethod?.toUpperCase()
                              : `N/A`}
                          </Badge>
                        </td> */}
                        {/* <td className="px-4 py-0.5 w-auto text-sm">
                          {_customer._policies ? (
                            <Badge>{_customer._policies}</Badge>
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td> */}
                        <td className="px-4 py-0.5 w-auto text-sm truncate">
                          {_customer.createdAt ? (
                            dateFormat(_customer.createdAt)
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="px-4 py-0.5 w-auto text-sm truncate">
                          {_customer.updatedAt ? (
                            dateFormat(_customer.updatedAt)
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        {/* Actions TD */}
                        <td className="px-6 py-3 text-sm z-0 flex float-right text-gray-400">
                          {updatingCustomer &&
                          currentCustomer?._id === _customer?._id ? (
                            <FontAwesomeIcon
                              icon={faSpinner}
                              spin={true}
                              className="my-auto mx-1"
                            />
                          ) : (
                            <div className="flex">
                              <span
                                data-tip="Update Status"
                                className="tooltip tooltip-left"
                              ></span>
                              <FontAwesomeIcon
                                className="mx-1 transform transition cursor-pointer  hover:text-red-500  hover:-translate-y-0 hover:scale-105"
                                icon={faEdit}
                                size="1x"
                                onClick={(e) => {
                                  handleStatusUpdate(_customer, e)
                                }}
                              />
                              <FontAwesomeIcon
                                className="mx-1 transform transition cursor-pointer  hover:text-red-500  hover:-translate-y-0 hover:scale-105"
                                icon={faPhone}
                                size="1x"
                                onClick={(e) => {
                                  handleContactMethods(_customer, e)
                                }}
                              />
                            </div>
                          )}
                        </td>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={customersData?.totalPages}
                totalRecords={customersData?.totalRecords}
                curerntPageRecords={customersData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
