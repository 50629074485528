import React, { Fragment, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { Container, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faSortUp,
  faSortDown,
  faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { Spinner } from 'App/Components/Common/Spinner'
import dateFormat from 'App/Services/General/dateFormat'
import { useHistory } from 'react-router-dom'
import { DateRangeInput } from 'App/Components/Common/DateRange'
const tableColumns = [
  { label: `Receipt Id`, value: `_invoiceId` },
  { label: `Customer`, value: `customerName` },
  { label: `Agent`, value: `agentName` },
  { label: `Location`, value: `locationName` },
  { label: `Amount`, value: `amount` },
  { label: `Transaction Date`, value: `transactionDate` },
  { label: `Status`, value: `status` },
  // { label: `Description`, value: `description` },
]

const selectionRange = {
  startDate: new Date(),
  endDate: new Date(),
  key: `selection`,
  isSelected: false,
  isSearched: false,
}

export const PaymentRecords = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [sort, setSort] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)
  const [dateRange, setDateRange] = useState([selectionRange])
  // const [customSearching, setCustomSearching] = useState(false)
  const [collapseIndex, setCollapseIndex] = useState(``)
  const history = useHistory()
  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const {
    isLoading,
    data: invoicesData,
    error,
    refetch: getAllInvoices,
    isFetching,
  } = useQuery(
    `InvoicesDataApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/payments/fetch?${
          searchQuery.length ? `search=${searchQuery}&` : ``
        }sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${offset}${
          dateRange[0]?.isSearched
            ? `&dateRange=${dateFormat(dateRange[0]?.startDate)},${dateFormat(
                dateRange[0]?.endDate,
              )}`
            : ``
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )
  const handleCustomerRedirect = (customerId) => {
    history.push(`/customers/${customerId}`)
  }

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (collapseIndex !== id) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }
  useEffect(() => {
    dateRange[0]?.isSearched && getAllInvoices()
  }, [dateRange[0]?.isSearched])

  useEffect(() => {
    getAllInvoices()
  }, [sort, currentPage])

  return (
    <Container shadow="yes">
      <Spinner loading={isLoading || isFetching} />
      <PageHeader bgcolor="bg-white">
        <div>Records</div>
        <div className="flex">
          <DateRangeInput
            value={dateRange[0]?.isSelected ? dateRange : ``}
            setValue={(e) => setDateRange(e)}
            isIcon={true}
          />
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  // setCustomSearching(true)
                  getAllInvoices()
                }
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      {error ? (
        <Reload refetch={() => getAllInvoices()} />
      ) : (
        <div
          className={`my-1 sm:mb-0 justify-between ${
            (isLoading || isFetching) && ` opacity-30 pointer-events-none`
          } `}
        >
          <div className="overflow-x-auto  rounded-t-md">
            <table className="w-full border-collapse">
              <TableHeader>
                <TableRow>
                  {tableColumns.map((item, idx) => {
                    return (
                      <TableCell key={idx}>
                        <div className="cursor-pointer select-none text-sm flex justify-center truncate">
                          <div
                            onClick={() => {
                              handleSorting(item.value)
                            }}
                            className={`flex ${
                              item.value === sort ? `text-red-500` : ``
                            }`}
                          >
                            {item.label}
                          </div>
                          <div
                            className="ml-1 rounded-sm cursor-pointer flex relative"
                            onClick={() => handleSorting(`-` + item.value)}
                          >
                            <FontAwesomeIcon
                              icon={
                                sort.includes(`-`) && sort == `-` + item.value
                                  ? faSortUp
                                  : faSortDown
                              }
                              size="lg"
                              className={`${
                                sort.includes(`-`) && sort == `-` + item.value
                                  ? `text-red-500`
                                  : `-mt-1.5`
                              }`}
                            />
                          </div>
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHeader>
              <TableBody>
                {invoicesData?.payments?.length != 0 ? (
                  invoicesData?.payments?.map((invoice, index) => (
                    <Fragment key={index.toString()}>
                      <TableRow className="hover:bg-gray-100 cursor-pointer">
                        <td
                          className="px-4 py-0.5 z-0 text-center  capitalize truncate max-w-xs text-sm"
                          onClick={() =>
                            invoice?._invoiceId &&
                            handleCustomerRedirect(
                              `${invoice?._customerId}/payments/${invoice?._invoiceId}`,
                            )
                          }
                        >
                          <span className="hover:underline font-semibold hover:text-blue-500">
                            {invoice?._receiptId ?? <Badge>N/A</Badge>}
                          </span>
                        </td>
                        <td
                          className="py-0.5 text-sm text-center hover:underline z-0 break-words max-w-px-140 capitalize truncate"
                          onClick={() =>
                            handleCustomerRedirect(invoice?._customerId)
                          }
                        >
                          {invoice?.customerName ? (
                            invoice?.customerName
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="py-0.5 text-sm text-center z-0 break-words max-w-px-140 capitalize truncate">
                          {invoice?.agentName ? (
                            invoice?.agentName?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>
                        <td className="py-0.5 text-sm text-center z-0 break-words max-w-px-140 capitalize truncate">
                          {invoice?.locationName ? (
                            invoice?.locationName?.toLowerCase()
                          ) : (
                            <Badge>N/A</Badge>
                          )}
                        </td>

                        <td className="py-0.5 text-sm text-center z-0 break-words max-w-px-140">
                          <Badge type="success">${invoice?.amount}</Badge>
                        </td>
                        <td className="py-0.5 text-sm text-center z-0">
                          <Badge
                            type={`${
                              invoice?.transactionDate ? `success` : `danger`
                            }`}
                          >
                            {dateFormat(invoice?.transactionDate)}
                          </Badge>
                        </td>
                        <td className="py-0.5 text-sm text-center z-0 break-words max-w-px-140 capitalize truncate">
                          <Badge
                            type={`${
                              invoice?.status?.toLowerCase()?.includes(`failed`)
                                ? `danger`
                                : invoice?.status
                                    ?.toLowerCase()
                                    ?.includes(`pending`)
                                ? `warning`
                                : `success`
                            }`}
                          >
                            {invoice?.status}
                          </Badge>
                          {(invoice?.description ||
                            invoice?.confirmationNo) && (
                            <span data-tip="Expand" className="tooltip">
                              <FontAwesomeIcon
                                className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                  collapseIndex === index &&
                                  `rotate-180 text-blue-600`
                                }`}
                                icon={faChevronCircleDown}
                                onClick={(e) => {
                                  handleCollapse(index, e)
                                }}
                              />
                            </span>
                          )}
                        </td>
                      </TableRow>
                      {collapseIndex === index && (
                        <TableRow>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="py-0.5 text-sm text-center z-0 break-words max-w-px-140 ">
                            {invoice?.description}
                            {invoice?.confirmationNo}
                          </td>
                        </TableRow>
                      )}
                    </Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center p-6">
                      <div>No Record Found</div>
                    </td>
                  </tr>
                )}
              </TableBody>
            </table>
          </div>
          <div className="px-4 py-2">
            <ReactPagination
              offset={offset}
              currentPage={currentPage}
              totalPages={invoicesData?.totalPages}
              totalRecords={invoicesData?.totalRecords}
              curerntPageRecords={invoicesData?.currentPageRecords}
              onChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      )}
    </Container>
  )
}
