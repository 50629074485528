import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputField } from 'App/Components/Common/InputField'
import { ReactModal } from 'App/Components/Common/Modal'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { TextArea } from 'App/Components/Common/TextArea'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { loadFranchises } from 'App/Services'
import { SubmitButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useMutation, useQueryClient } from 'react-query'

export const GenerateNotificationsModal = ({
  setGenerateNotificationModal,
}) => {
  const queryClient = useQueryClient()

  //Local states
  const [franchises, setFranchises] = useState(``)
  const [notificationTitle, setNotificationTitle] = useState(``)
  const [notificationBody, setNotificationBody] = useState(``)

  //State to track "generate notification" modal fields errors
  const [errors, setErrors] = useState({
    franchisesError: ``,
    notificationTitleError: ``,
    notificationBodyError: ``,
    formSubmit: false,
  })

  const { isLoading, mutate: addGeneralNotifications } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/general-notifications/create`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res) {
          NotificationManager.success(`General notification sent successfully`)
          handleCloseNotificationModal()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: () => {
        handleCloseNotificationModal()
        NotificationManager.error(
          `Error! Failed to send general notifications.`,
        )
      },
      onSuccess: () => {
        // Invalidate the query with the tag 'schedulePaymentsDataApi'
        queryClient.invalidateQueries(`generalNotificationsDataApi`)
      },
    },
  )

  //Handler to validate generate notifications modal fields
  const validateFrom = () => {
    if (franchises?.length === 0) {
      handleErrors(`franchisesError`, `Select Franchises`)
    } else {
      handleErrors(`franchisesError`, ``)
    }
    if (notificationTitle === ``) {
      handleErrors(`notificationTitleError`, `Enter Notification Title`)
    } else {
      handleErrors(`notificationTitleError`, ``)
    }
    if (notificationBody === ``) {
      handleErrors(`notificationBodyError`, `Enter Notification Body`)
    } else {
      handleErrors(`notificationBodyError`, ``)
    }

    setErrors((preState) => ({
      ...preState,
      formSubmit: true,
    }))
  }

  //Handler to mutate errors state
  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  //Generate Notification Api handler
  useEffect(() => {
    if (
      errors.formSubmit &&
      franchises?.length !== 0 &&
      notificationTitle !== `` &&
      notificationBody !== ``
    ) {
      //api call logic goes here
      addGeneralNotifications({
        title: notificationTitle,
        description: notificationBody,
        franchiseIds: franchises.map((franchise) => franchise.value),
      })
      // setTimeout(() => {
      //   handleCloseNotificationModal()
      // }, 500)
    }
  }, [errors])

  //Close "Generate notification modal" handler
  const handleCloseNotificationModal = () => {
    setGenerateNotificationModal(false)
    //reset all states data as modal closed
    setFranchises(``)
    setNotificationTitle(``)
    setNotificationBody(``)
    //reset all errors state
    setErrors({
      franchisesError: ``,
      notificationTitleError: ``,
      notificationBodyError: ``,
      formSubmit: false,
    })
  }

  return (
    <ReactModal
      title="Generate Notifications"
      closeModal={() => handleCloseNotificationModal()}
      className={`p-3`}
    >
      <div className="px-1 space-y-2 w-96">
        <div className="flex-1 relative">
          <ReactSelectField
            title="Select Franchises *"
            placeholder="Select Franchise"
            value={franchises}
            setValue={(e) => setFranchises(e)}
            isMulti={true}
            loadOptions={loadFranchises}
            isSearchable={true}
            errorMessage={errors.franchisesError}
          />
        </div>
        <div className="flex-1 relative">
          <InputField
            type="text"
            title="Notification Title *"
            placeholder="Enter Notification Title"
            value={notificationTitle}
            setValue={(e) => setNotificationTitle(e.target.value)}
            errorMessage={errors.notificationTitleError}
          />
        </div>
        <div className="flex-1 relative">
          <TextArea
            title="Notification Body *"
            placeholder="Enter Notification detail"
            value={notificationBody}
            setValue={(e) => setNotificationBody(e.target.value)}
            rows={4}
            errorMessage={errors.notificationBodyError}
          />
        </div>
        <SubmitButton
          onClick={() => validateFrom()}
          className="rounded-lg mt-3"
          disabled={isLoading}
        >
          {isLoading ? (
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-white"
            />
          ) : (
            <span className="text-sm ">Generate</span>
          )}
        </SubmitButton>
      </div>
    </ReactModal>
  )
}
