import React from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { ReactModal } from 'App/Components/Common/Modal'
import FileSaver from 'file-saver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
export const TaskMediaFiles = () => {
  const [preview, setPreview] = useState(false)
  const [currentImage, setCurrentImage] = useState(``)
  const { attachments } = useSelector(({ tasks }) =>tasks?.currentTask)
  return (
   attachments?.length ? ( <>
    <div className="mt-2 h-420 overflow-y-scroll  hide-scroller-firefox">
      <div className="flex justify-center flex-col  my-0">
        {attachments?.map((file) => {
          const fileName  = file?.split(`/`)
          return file?.includes(`png`)|| file?.includes(`jpeg`) || file?.includes(`jpg`)? (<img
            src={file}
            alt="Image Sending is Failed"
            className="rounded-md h-24vh w-17vw cursor-pointer mt-2 "
            onClick={() => {
              setPreview(true)
              setCurrentImage(file)
            }}
          />): file?.includes(`video`)? <video src={file} controls className='mt-2'/>:
          <button
          onClick={() => {
            FileSaver.saveAs(file, `newFIle`)
          }}
          className="mt-2"
        >
          
         <div className='bg-blue-500 w-auto p-2 text-white flex items-center justify-center '>
          <h1 >{fileName[fileName?.length-1]}</h1>&nbsp;&nbsp;
          <FontAwesomeIcon icon={faArrowCircleDown} className="mt-1" /></div>
        </button>
})}
      </div>

    </div>

    {preview && currentImage && (
      <ReactModal
    
        title="Photo"
        closeModal={() => {
          setPreview(false)
          setCurrentImage(``)
        }}
        disabled={true}
        loading={false}
        callMutation={() => {
          FileSaver.saveAs(currentImage)
        }}
        icon={faArrowCircleDown}
        toolTip="Save File"
        className="p-2 "
      >
        <div className="items-center my-0 ">
          <img src={currentImage} alt="img"  className='max-h-96' />
        </div>
      </ReactModal>
    )}
  </>): (<div className="text-center my-6 text-sm text-gray-400 font-bold">
          No Media Found
        </div>)
  )
}
