import React, { useEffect, useState, Fragment } from 'react'
import { defaultImage } from 'App/Assets'
import { useQuery, useMutation } from 'react-query'
import { Toggle } from 'App/Components/Common/Toggle'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { useDebouncedCallback } from 'use-debounce/lib'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { isSuperUser, permissionGranted } from 'App/Services'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Badge,
} from '@windmill/react-ui'
import {
  faPlusCircle,
  faSortUp,
  faSortDown,
  faTrash,
  faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
import dateFormat from 'App/Services/General/dateFormat'

const tableColumns = [
  { label: `ID`, value: `_franchiseId` },
  { label: `Franchise`, value: `name` },
  { label: `Status`, value: `status` },
  { label: `Commission`, value: `commission` },
  { label: `State`, value: `state` },
  { label: `Locations`, value: `locations` },
  { label: `Created`, value: `createdAt` },
]

export const AllFranchise = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [isMasterFrenchise, setIsMasterFrenchise] = useState(true)
  const [searchQuery, setSearchQuery] = useState(``)
  const [collapseIndex, setCollapseIndex] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [active, setActive] = useState(false)
  const history = useHistory()
  const { url } = useRouteMatch()

  // fetching all frenchises
  const {
    isLoading,
    data: franchiseData,
    error,
    refetch: getAllFranchises,
    isFetching,
  } = useQuery(
    `FranchiseDataApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/franchises?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }", "onlyCorporateFranchises": ${
          isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)
            ? isMasterFrenchise
            : false
        }}&sort="${sort?.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  // delete franchise
  const {
    mutate: deleteFranchise,
    isLoading: deletingFranchise,
    isError: deletionError,
    // error: reponseError,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/franchises/${id}`,
      ).then((res) => {
        res.data && getAllFranchises()
        NotificationManager.success(`Successfully Deleted`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllFranchises()
    }
  }, [isMasterFrenchise, sort, currentPage, isOffset])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  const callFranchiseQuery = useDebouncedCallback(() => {
    getAllFranchises()
  }, 700)

  const handleDelete = (franchise, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    !franchise?.isCorporateFranchise && deleteFranchise(franchise._id)
  }

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (collapseIndex !== id) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }
  return (
    <Container>
      <Spinner loading={deletingFranchise || isLoading || isFetching} />
      {deletionError && (
        <ReactNotification
          action="error"
          message={`Operation Fail, Please delete agent first`}
        />
      )}
      <PageHeader>
        <div>Franchises</div>
        <div className="flex items-center">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          {(isSuperUser(`super-admin:*`) || isSuperUser(`system-admin:*`)) && (
            <Toggle
              value={!isMasterFrenchise}
              setValue={() => {
                setCurrentPage(1)
                setIsMasterFrenchise(!isMasterFrenchise)
              }}
              toggleValue={[`C`, `F`]}
            />
          )}

          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callFranchiseQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div className="flex justify-end items-center p-2">
        {permissionGranted([`corporate-manager:*`]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (deletingFranchise || isLoading || isFetching) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllFranchises()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    <TableCell className="text-sm">Image</TableCell>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div
                            className={`${
                              sort.activeSort === item.value && `text-red-500 `
                            } cursor-pointer select-none text-sm flex`}
                          >
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort === `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort === `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {permissionGranted([`corporate-manager:*`]) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {franchiseData?.franchises?.length != 0 ? (
                    franchiseData?.franchises?.map((franchise, index) => (
                      <Fragment key={index}>
                        <TableRow
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            if (permissionGranted([`corporate-manager`])) {
                              history.push(`${url}/${franchise._id}`)
                            }
                          }}
                        >
                          <td className="px-4 py-0.5 z-0">
                            <Avatar
                              src={
                                franchise.logo ? franchise.logo : defaultImage
                              }
                              alt="img"
                            />
                          </td>
                          <td className="px-4 py-0.5 z-0 capitalize truncate max-w-xs text-sm">
                            <span>{franchise._franchiseId}</span>
                          </td>
                          <td className="px-4 py-0.5 z-0 capitalize truncate max-w-xs text-sm">
                            <span>{franchise.name?.toLowerCase()}</span>
                          </td>
                          <td className="px-4 py-0.5 z-0">
                            <Badge
                              type={`${
                                franchise.status === `INACTIVE`
                                  ? `danger`
                                  : `success`
                              }`}
                            >
                              {franchise.status}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 z-0">
                            <Badge type="success">
                              {franchise?.commission ? (
                                franchise?.commission?.rate +
                                ` ` +
                                franchise.commission?.type
                              ) : (
                                <Badge type="success">N/A</Badge>
                              )}
                            </Badge>
                          </td>
                          <td className="px-4 py-0.5 z-0">
                            <Badge className="ml-1">{franchise?.state}</Badge>
                          </td>
                          <td className="px-4 py-0.5 z-0">
                            <Badge type="neutral" className="ml-1">
                              {franchise?.locations[0]?.name?.toLowerCase()}
                            </Badge>
                          </td>

                          <td className="px-4 py-0.5 z-0 text-sm">
                            <span>{dateFormat(franchise.createdAt)}</span>
                          </td>
                          {/* Actions TD */}
                          <td className="px-6 py-2.5 z-0 text-sm flex float-right text-gray-400">
                            {(franchise.regions?.length > 1 ||
                              franchise.locations?.length > 1) && (
                              <span data-tip="Expand" className="tooltip">
                                <FontAwesomeIcon
                                  className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                    collapseIndex === index &&
                                    `rotate-180 text-blue-600`
                                  }`}
                                  icon={faChevronCircleDown}
                                  onClick={(e) => {
                                    setActive(!active)
                                    handleCollapse(index, e)
                                  }}
                                />
                              </span>
                            )}
                            {permissionGranted([`corporate-manager:*`]) && (
                              <span
                                data-tip={
                                  franchise?.isCorporateFranchise
                                    ? `Not Allowed`
                                    : `Delete`
                                }
                                className="tooltip mr-2"
                              >
                                <FontAwesomeIcon
                                  className={`mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 ${
                                    franchise?.isCorporateFranchise
                                      ? `cursor-not-allowed`
                                      : `cursor-pointer`
                                  }`}
                                  icon={faTrash}
                                  size="1x"
                                  onClick={(e) => handleDelete(franchise, e)}
                                />
                              </span>
                            )}
                          </td>
                        </TableRow>
                        {collapseIndex === index && (
                          <TableRow>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            {/* <td
                              className={`px-4 py-0.5 text-sm z-0 max-w-sm ${
                                franchise.regions.length <
                                franchise.locations.length
                                  ? `grid place-content-start`
                                  : ``
                                }`}
                            >
                              {franchise.regions.length &&
                                franchise?.regions?.map((region, idx) => (
                                  <Badge key={`${idx}`} className="ml-1 mt-2">
                                    {region}
                                  </Badge>
                                ))}
                            </td> */}
                            <td
                              className={`px-4 py-0.5 text-sm z-0 max-w-sm ${
                                franchise.regions.length >=
                                franchise.locations.length
                                  ? `grid place-content-start`
                                  : ``
                              }`}
                            >
                              {franchise.locations.length &&
                                franchise?.locations?.map((location, idx) => (
                                  <Badge
                                    type="warning"
                                    key={idx}
                                    className="ml-1 mr-4 mt-2 w-max"
                                  >
                                    {location?.name
                                      ? location?.name?.toLowerCase()
                                      : `null`}
                                  </Badge>
                                ))}
                            </td>
                          </TableRow>
                        )}
                      </Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Franchise Found</div>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </table>
            </div>

            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={franchiseData?.totalPages}
                totalRecords={franchiseData?.totalRecords}
                curerntPageRecords={franchiseData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
