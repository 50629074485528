import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Container } from 'App/Styled'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

const options = {
  cutoutPercentage: 70,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {},
  },
}

export const MonthlyGoalsByStatus = ({ goalsByStatus }) => {
  const [dataSet, setDataSet] = useState({
    labels: [],
    datasets: [
      {
        data: [],
      },
    ],
    borderWidth: 1,
  })

  useEffect(() => {
    const labels = goalsByStatus?.map((item) => item.month)
    const values = goalsByStatus?.map((item) => item.goals)

    const expired = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.status === `EXPIRED`)[0]
        ?.goals
    })

    const completed = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.status === `COMPLETED`)[0]
        ?.goals
    })
    const started = values?.map((item) => {
      return item.filter((itemObject) => itemObject?.status === `STARTED`)[0]
        ?.goals
    })

    const monthNames = [
      `Jan`,
      `Feb`,
      `Mar`,
      `Apr`,
      `May`,
      `Jun`,
      `Jul`,
      `Aug`,
      `Sep`,
      `Oct`,
      `Nov`,
      `Dec`,
    ]
    const newMonthName = []

    labels?.forEach((item) => {
      let newArray = monthNames[item.month - 1]
      newMonthName.push(newArray)
    })

    const goalsInfo = {
      labels: newMonthName,
      datasets: [
        {
          label: `EXPIRED`,
          data: expired,
          backgroundColor: `#ffa800`,
          borderColor: `#ffa800`,
          borderWidth: 1,
          fill: false,
        },
        {
          label: `COMPLETED`,
          data: completed,
          backgroundColor: `#8950fc`,
          borderColor: `#8950fc`,
          borderWidth: 1,
          fill: false,
        },
        {
          label: `STARTED`,
          data: started,
          backgroundColor: `#3699ff`,
          borderColor: `#3699ff`,
          borderWidth: 1,
          fill: false,
        },
      ],
    }

    setDataSet(goalsInfo)
  }, [goalsByStatus])

  return (
    <Container>
      <div className="py-9 relative">
        <div className="font-bold absolute left-4 2xl:left-6 top-5">
          Monthly Goals By Status
        </div>
      </div>
      <div className="h-280 m-6">
        {dataSet?.labels !== undefined ? (
          <Bar data={dataSet} options={options} />
        ) : null}
      </div>
    </Container>
  )
}
