import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { Container, PageHeader, StyledButton, SubmitButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { InputField } from '../../Common/InputField'
import { ReactModal } from '../../Common/Modal'
import { Spinner } from '../../Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { DateRangeInputField } from 'App/Components/Common/DateRangeInputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { NotificationManager } from 'react-notifications'
import dateFormat from 'App/Services/General/dateFormat'

export const UpdateMonthlyGoals = () => {
  const history = useHistory()
  const {
    params: { id },
  } = useRouteMatch()
  const { currentGoal } = useSelector(({ monthlyGoal }) => monthlyGoal)

  const [monthlyGoals, setMonthlyGoals] = useState({
    targetPayment: ``,
    targetLeads: ``,
    targetPolicies: ``,
    title: ``,
    target: ``,
    // assignedTo: [],
  })
  //  date range picker
  const [goalsDuration, setGoalsDuration] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
    },
  ])

  const [dateRangeModal, setDateRangeModal] = useState(false)
  const [errors, setErrors] = useState({
    goalDurationError: ``,
    targetPoliciesError: ``,
    targetLeadsError: ``,
    titleError: ``,
    targetPaymentError: ``,
    // assignedToError: ``,
  })

  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(`${AMS_BACKEND_API}/api/goals/${id}`, {
        ...payload,
      })
        .then((res) => {
          if (res.data) {
            NotificationManager.success(`Operation successful`)
            res.data && history.push(`/admin/monthlygoals/${id}`)
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            } else {
              NotificationManager.error(error?.response?.data?.message)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.titleError.length &&
      !errors.targetPoliciesError.length &&
      !errors.targetPaymentError.length &&
      !errors.targetLeadsError.length
    ) {
      mutate(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  useEffect(() => {
    if (currentGoal) {
      setMonthlyGoals((preGoals) => ({
        ...preGoals,
        title: currentGoal?.title,
        targetPayment: currentGoal?.targetPayment?.toString(),
        targetLeads: currentGoal?.targetLeads?.toString(),
        targetPolicies: currentGoal?.targetPolicies?.toString(),
        // assignedTo: currentGoal?.assignedTo,
      }))

      const startDate = new Date(currentGoal?.startDate)
      const endDate = new Date(currentGoal?.endDate)
      setGoalsDuration(() => [
        {
          [`startDate`]: startDate,
          [`endDate`]: endDate,
          [`key`]: `selection`,
        },
      ])
    }
  }, [currentGoal])

  const updatePayload = () => {
    let data = {}

    if (monthlyGoals?.title !== currentGoal?.title) {
      data.title = monthlyGoals?.title
    }
    if (
      monthlyGoals?.targetPayment !== currentGoal?.targetPayment?.toString()
    ) {
      data.targetPayment = monthlyGoals?.targetPayment
    }
    if (monthlyGoals?.targetLeads !== currentGoal?.targetLeads?.toString()) {
      data.targetLeads = monthlyGoals?.targetLeads
    }
    if (
      monthlyGoals?.targetPolicies !== currentGoal?.targetPolicies?.toString()
    ) {
      data.targetPolicies = monthlyGoals?.targetPolicies
    }

    // let isUpdatedAssignee
    // if (monthlyGoals?.assignedTo?.length === currentGoal?.assignedTo?.length) {
    //   isUpdatedAssignee = monthlyGoals?.assignedTo.some(
    //     (eachAssignee, index) => {
    //       return eachAssignee.value !== currentGoal?.assignedTo[index].value
    //     },
    //   )
    // } else {
    //   isUpdatedAssignee = true
    // }

    // if (isUpdatedAssignee) {
    //   data.assignedTo = monthlyGoals?.assignedTo?.map((item) => item?.value)
    // }

    if (
      dateFormat(goalsDuration[0]?.startDate) !==
      dateFormat(currentGoal?.startDate)
    ) {
      data.startDate = goalsDuration[0]?.startDate
    }
    if (
      dateFormat(goalsDuration[0]?.endDate) !== dateFormat(currentGoal?.endDate)
    ) {
      data.endDate = goalsDuration[0]?.endDate
    }
    return data
  }

  const ValidateForm = () => {
    if (!monthlyGoals?.title.length) {
      handleErrors(`titleError`, `Enter title`)
    } else {
      handleErrors(`titleError`, ``)
    }

    // if (!monthlyGoals?.assignedTo.length) {
    //   handleErrors(`assignedToError`, `Select User`)
    // } else {
    //   handleErrors(`assignedToError`, ``)
    // }
    if (!monthlyGoals?.targetPayment?.length) {
      handleErrors(`targetPaymentError`, `Enter Payment`)
    } else if (
      monthlyGoals?.targetPayment?.length &&
      monthlyGoals?.targetPayment < 1
    ) {
      handleErrors(`targetPaymentError`, `Enter positive number`)
    } else {
      handleErrors(`targetPaymentError`, ``)
    }
    if (!monthlyGoals?.targetLeads?.length) {
      handleErrors(`targetLeadsError`, `Enter no`)
    } else if (
      monthlyGoals?.targetLeads?.length &&
      monthlyGoals?.targetLeads < 1
    ) {
      handleErrors(`targetLeadsError`, `Enter positive number`)
    } else {
      handleErrors(`targetLeadsError`, ``)
    }
    if (!monthlyGoals?.targetPolicies?.length) {
      handleErrors(`targetPoliciesError`, `Enter no`)
    } else if (
      monthlyGoals?.targetPolicies?.length &&
      monthlyGoals?.targetPolicies < 1
    ) {
      handleErrors(`targetPoliciesError`, `Enter positive number`)
    } else {
      handleErrors(`targetPoliciesError`, ``)
    }
    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  return (
    <Container>
      {updationError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      {dateRangeModal && (
        <ReactModal
          title="Date Range"
          closeModal={() => setDateRangeModal(false)}
        >
          <div className="pb-2">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setGoalsDuration([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={goalsDuration}
              minDate={new Date()}
              className="w-full"
            />
            <div className="px-2 text-right">
              <StyledButton
                onClick={() => {
                  setDateRangeModal(false)
                }}
              >
                <div className="flex gap-2 text-white">
                  <span>Set Date Range </span>
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}

      <PageHeader padding="true">Monthly Goals</PageHeader>
      <Spinner loading={isLoading} />

      <div
        className={`p-4 bg-white ${
          isLoading && ` opacity-30 pointer-events-none`
        } `}
      >
        <div className="grid grid-cols-4 gap-6 mt-2 px-4">
          <div
            onClick={() => setDateRangeModal(!dateRangeModal)}
            className="flex-1 relative "
          >
            <DateRangeInputField
              type="text"
              name="target"
              placeholder="Select Date Range"
              title="Select Duration"
              value={
                goalsDuration &&
                dateFormat(goalsDuration[0]?.startDate) +
                  `\t - \t` +
                  dateFormat(goalsDuration[0]?.endDate)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="title"
              title="Title *"
              placeholder="Enter title"
              value={monthlyGoals.title}
              setValue={(e) => {
                setMonthlyGoals((prevState) => ({
                  ...prevState,
                  [`title`]: e.target.value,
                }))
              }}
              errorMessage={errors.titleError}
            />
          </div>
          {/* <div className="flex-1 relative">
            <ReactSelectField
              title="Assign To *"
              placeholder="Select User"
              value={monthlyGoals.assignedTo}
              errorMessage={errors.assignedToError}
              setValue={(value) => {
                setMonthlyGoals((preState) => ({
                  ...preState,
                  assignedTo: value,
                }))
              }}
              isMulti={true}
              loadOptions={loadAgents}
              isSearchable={true}
              additionalFilters={{
                franchiseId: _franchise?._id,
                locations: [_locations?._id],
                permissions: allowedSystemPermissions?.goals,
              }}
            />
          </div> */}
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="target"
              title="Payment *"
              placeholder="Enter amount"
              value={monthlyGoals.targetPayment}
              setValue={(e) => {
                setMonthlyGoals((prevState) => ({
                  ...prevState,
                  [`targetPayment`]: e.target.value,
                }))
              }}
              onKeyPress={(e) =>
                [`e`, `E`, `+`, `-`, `.`].includes(e.key) && e.preventDefault()
              }
              errorMessage={errors.targetPaymentError}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="targetLeads"
              title="No of Leads *"
              placeholder="Enter no"
              value={monthlyGoals.targetLeads}
              setValue={(e) => {
                setMonthlyGoals((prevState) => ({
                  ...prevState,
                  [`targetLeads`]: e.target.value,
                }))
              }}
              onKeyPress={(e) =>
                [`e`, `E`, `+`, `-`, `.`].includes(e.key) && e.preventDefault()
              }
              errorMessage={errors?.targetLeadsError}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              name="targetPolicies"
              title="No Policies *"
              placeholder="Enter no"
              value={monthlyGoals.targetPolicies}
              setValue={(e) => {
                setMonthlyGoals((prevState) => ({
                  ...prevState,
                  [`targetPolicies`]: e.target.value,
                }))
              }}
              onKeyPress={(e) =>
                [`e`, `E`, `+`, `-`, `.`].includes(e.key) && e.preventDefault()
              }
              errorMessage={errors?.targetPoliciesError}
            />
          </div>
        </div>

        <div className="flex justify-center">
          <div className="text-center w-96 mt-5 my-2">
            <SubmitButton onClick={ValidateForm}>
              <div>Update Goals</div>
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
