import React from 'react'
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from '../style'
import { PolicyReportPdfHeader } from 'App/Components/Common/PolicyReportPdfHeader'
import dateFormat from 'App/Services/General/dateFormat'

export const Pdf = ({ carrierData, dateRange = [] }) => {
  return (
    <Document subject="Report" author="Reports">
      <Page style={styles.page} size="A2" wrap>
        <View style={styles.section}>
          <Text style={styles.pageHeading}>Insured Property Address</Text>
          <Text style={styles.smallSpace}></Text>
          <PolicyReportPdfHeader dateRange={dateRange} />
          <Text style={styles.smallSpace}></Text>

          <View style={styles.headingRow}>
            <Text style={styles.pageTitles}> Insured</Text>
            <Text style={styles.pageTitles}> Prop Address</Text>
            <Text style={styles.pageTitles}> Prop City</Text>
            <Text style={styles.pageTitles}> Prop State</Text>
            <Text style={styles.pageTitles}> Ins Phone</Text>
            <Text style={styles.pageTitles}> Ins Email</Text>
            <Text style={styles.pageTitles}> LOB</Text>
            <Text style={styles.pageTitles}> Policy No</Text>
            <Text style={styles.pageTitles}> Eff. Date</Text>
            <Text style={styles.pageTitles}> Exp. Date</Text>
            <Text style={styles.pageTitles}> Carrier</Text>
            <Text style={styles.pageTitles}> Premium</Text>
            <Text style={styles.pageTitles}> Agency Com</Text>
          </View>

          {carrierData.length > 0 &&
            carrierData.map((carrierData, index) => (
              <View style={styles.agent} key={index}>
                <View>
                  <Text style={styles.agentName}>
                    {carrierData._id} - {carrierData?.totalPremium}
                  </Text>
                </View>
                {carrierData.policies.length > 0 &&
                  carrierData.policies.map((policy) => (
                    <View style={styles.headingRow2}>
                      <Text
                        style={[styles.pageTitles2, { marginHorizontal: 5 }]}
                      >
                        {policy?.insuredName ? policy?.insuredName : `N/A`}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {policy?.propertyAddress
                          ? policy?.propertyAddress
                          : `N/A`}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {policy?.propertyCity ? policy?.propertyCity : `N/A`}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {policy?.propertyState ? policy?.propertyState : `N/A`}
                      </Text>
                      <Text
                        style={[styles.pageTitles2, { marginHorizontal: 5 }]}
                      >
                        {policy?.insuredPhone ? policy?.insuredPhone : `N/A`}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {policy?.insureedEmail ? policy?.insureedEmail : `N/A`}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {policy?.lob ? policy?.lob : `N/A`}
                      </Text>
                      <Text style={styles.pageTitles2}>{policy?.policyNo}</Text>
                      <Text style={styles.pageTitles2}>
                        {dateFormat(policy?.effectiveDate)}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {dateFormat(policy?.expiryDate)}
                      </Text>
                      <Text style={styles.pageTitles2}>{policy?.carrier}</Text>
                      <Text style={styles.pageTitles2}>
                        {policy?.totalPremium
                          ? `$ ${policy?.totalPremium?.toFixed(2)}`
                          : `-`}
                      </Text>
                      <Text style={styles.pageTitles2}>
                        {policy?.agencyCommission
                          ? `$ ${policy?.agencyCommission?.toFixed(2)}`
                          : `-`}
                      </Text>
                    </View>
                  ))}
              </View>
            ))}
        </View>
      </Page>
    </Document>
  )
}
