export const loadEducationLevelOptions = () => {
  const options = {
    options: [
      { value: `HIGH SCHOOL`, label: `HIGH SCHOOL` },
      { value: `ASSOCIATE DEGREE`, label: `ASSOCIATE DEGREE` },
      { value: `BACHELORS`, label: `BACHELORS` },
      { value: `MASTERS`, label: `MASTERS` },
    ],
    hasMore: false,
  }
  return options
}
