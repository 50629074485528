import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  quotes: [],
  activeActivityTab: 1,
  currentQuote: ``,
}

const myquotesSlice = createSlice({
  name: `myquotes`,

  initialState,
  reducers: {
    setMyAllQuotes: (state, { payload }) => {
      state.quotes = payload
    },
    setQuotesActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
    setCurrentQuote: (state, { payload }) => {
      state.currentQuote = payload
    },
  },
})

export const { setMyAllQuotes, setQuotesActivityActiveTab, setCurrentQuote } =
  myquotesSlice.actions

export const myquotes = myquotesSlice.reducer
