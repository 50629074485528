export const loadMailSizes = async () => {
  return {
    options: [
      {
        value: `4x6`,
        label: `4x6`,
      },
      {
        value: `6x9`,
        label: `6x9`,
      },
      {
        value: `6x11`,
        label: `6x11`,
      },
    ],
    hasMore: false,
  }
}
