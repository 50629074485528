import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { MaskField } from 'App/Components/Common/MaskField'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { useMutation } from 'react-query'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import {
  loadFranchises,
  ValidateZip,
  ValidateDuplicateLocEmail,
  ValidateDuplicateLocation,
  loadZipCodes,
  fileResizer,
} from 'App/Services'
import { useHistory, useRouteMatch } from 'react-router'
import { Container, Image, Label, PageHeader, SubmitButton } from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce/lib'
import { loadGroupCredentials } from 'App/Services/Credentials'
import { defaultImage } from 'App/Assets'
import { NotificationManager } from 'react-notifications'

export const UpdateLocation = () => {
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [franchise, setFranchise] = useState(``)
  const [location, setLocation] = useState({
    name: ``,
    businessName: ``,
    phone: ``,
    ext: ``,
    email: ``,
    fax: ``,
    lobs: ``,
    credentialGroup: ``,
  })
  const [firstZip, setFirstZip] = useState(``)
  const [address, setAddress] = useState({
    unitNo: ``,
    streetNo: ``,
    city: ``,
    zip: ``,
    state: ``,
  })
  const [currentLocName, setCurrentLocName] = useState(``)
  const [currentEmail, setCurrentEmail] = useState(``)
  const [locZipValidating, setLocZipValidating] = useState(false)
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })
  const [errors, setErrors] = useState({
    formSubmit: false,
    franchiseError: ``,
    nameError: ``,
    businessNameError: ``,
    phoneError: ``,
    extError: ``,
    emailError: ``,
    faxError: ``,
    lobsError: ``,
    unitNoError: ``,
    streetNoError: ``,
    cityError: ``,
    zipError: ``,
    stateError: ``,
    credentialGroupError: ``,
    coordinatesError: false,
  })
  const [validatingDuplicates, setValidatingDuplicates] = useState({
    email: false,
    name: false,
    businessName: false,
  })
  const history = useHistory()
  const {
    params: { fId, lId },
  } = useRouteMatch()
  const { url } = useRouteMatch()
  const { currentLocation } = useSelector(({ location }) => location)

  useEffect(() => {
    if (currentLocation) {
      setCurrentLocName(currentLocation?.name)
      setCurrentEmail(currentLocation?.email)
      setFranchise({
        value: currentLocation?._franchise?._id,
        label: currentLocation?._franchise?.name,
      })
      setLocation((prevState) => ({
        ...prevState,
        name: currentLocation?.name,
        image: currentLocation?.image,
        phone: currentLocation?.phone,
        ext: currentLocation?.ext,
        email: currentLocation?.email,
        fax: currentLocation?.fax,
        businessName: currentLocation?.businessName,
        lobs: currentLocation?.lobs.map((lob) => {
          return {
            value: lob,
            label: lob,
          }
        }),
        credentialGroup: currentLocation?._credentialGroup,
      }))
      setAddress((prevState) => ({
        ...prevState,
        unitNo: currentLocation?.address?.unitNo,
        streetNo: currentLocation?.address?.street,
        city: currentLocation?.address?.city,
        zip: currentLocation?.address?.zip.length
          ? currentLocation?.address?.zip?.map((zip) => ({
              value: zip,
              label: zip,
            }))
          : ``,
        state: currentLocation?.address?.state,
      }))
      currentLocation?.address?.coordinates &&
        setCoordinates((prevState) => ({
          ...prevState,
          lat: currentLocation?.address?.coordinates?.lat,
          lng: currentLocation?.address?.coordinates?.long,
        }))
    }
  }, [currentLocation])

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/locations/${payload.locationId}`,
        {
          ...payload.locationPayload,
        },
      )
        .then((res) => {
          if (res.data) {
            if (url.includes(`franchises`)) {
              history.push(`/admin/franchises/${fId}/location/${lId}`)
            } else {
              history.push(`/admin/locations/${lId}`)
            }
          }
        })
        .catch((error) => {
          if (
            error.response.data?.error?.error.includes(
              `Incoming request body can't be empty.`,
            )
          ) {
            setIsFormChanged(true)
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const handleLocationInput = useDebouncedCallback((name, value) => {
    setLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleValidating = (name, value) => {
    setValidatingDuplicates((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const validateLocationPayload = useDebouncedCallback(async (name, value) => {
    handleValidating(name, true)
    // e.g { businessName: "Test" }
    const response = await ValidateDuplicateLocation({ [name]: value })
    if (response?.hasError) {
      handleErrors(`formSubmit`, false)
      handleErrors(`${name}Error`, `Name Already Exists`)
      handleValidating(name, false)
    } else {
      handleErrors(`formSubmit`, false)
      handleErrors(`${name}Error`, ``)
      handleValidating(name, false)
    }
  }, 1500)

  const handleEmailInput = async (e) => {
    const { name, value } = e.target
    setLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (value != currentEmail && value) {
      handleValidating(`email`, true)
      const response = await ValidateDuplicateLocEmail(value)
      if (response.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`emailError`, `Email Already Exists`)
        handleValidating(`email`, false)
      } else {
        if (!regEx.test(value.toLowerCase())) {
          handleErrors(`formSubmit`, false)
          handleErrors(`emailError`, `Enter Valid Email`)
          handleValidating(`email`, false)
        } else {
          handleErrors(`formSubmit`, false)
          handleErrors(`emailError`, ``)
          handleValidating(`email`, false)
        }
      }
    }
  }

  const handleNameInput = async (e) => {
    const { name, value } = e.target
    setLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (value.toLowerCase() != currentLocName.toLowerCase() && value) {
      validateLocationPayload(name, value)
    }
  }
  const handleBusinessNameInput = async (e) => {
    const { name, value } = e.target
    setLocation((prevState) => ({
      ...prevState,
      [name]: value,
    }))

    if (value.toLowerCase() != currentLocName.toLowerCase() && value) {
      validateLocationPayload(name, value)
    }
  }

  const handleAddressInput = async (e, name) => {
    if (name) {
      setLocZipValidating(true)
      const data = await ValidateZip(e)
      if (data) {
        setLocZipValidating(false)
        const res = data.address
        setAddress((prevState) => ({
          ...prevState,
          [`city`]: res?.city,
          [`state`]: res?.state,
        }))

        //setting coordinates
        setCoordinates((prevState) => ({
          ...prevState,
          [`lat`]: res?.lat,
          [`lng`]: res?.long,
        }))
      }
    } else {
      const { name, value } = e.target
      setAddress((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  const handleLogo = async (file) => {
    try {
      const base64 = await fileResizer(file[0])
      handleLocationInput(`image`, base64)
    } catch (e) {
      NotificationManager.error(`Selected file should be image!`)
    }
    // setLogo(base64)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.franchiseError.length &&
      !errors.nameError.length &&
      !errors.businessNameError.length &&
      !errors.phoneError.length &&
      !errors.emailError.length &&
      !errors.faxError.length &&
      !errors.lobsError.length &&
      !errors.extError.length &&
      !errors.zipError.length &&
      !errors.streetNoError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.credentialGroupError.length &&
      !errors.coordinatesError
    ) {
      mutate(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const updatePayload = () => {
    let data = {
      locationPayload: {},
    }
    data.locationId = lId
    if (location.name !== currentLocation?.name) {
      data.locationPayload.name = location?.name
    }
    if (location.businessName !== currentLocation?.businessName) {
      data.locationPayload.businessName = location?.businessName
    }
    if (location.image && location.image !== currentLocation?.image) {
      data.locationPayload.image = location?.image
    }
    if (location.phone !== currentLocation?.phone) {
      data.locationPayload.phone = location?.phone
    }
    if (location.ext !== currentLocation?.ext) {
      data.locationPayload.ext = location?.ext
    }
    if (location.email !== currentLocation?.email) {
      data.locationPayload.email = location?.email
    }

    if (location.fax !== currentLocation?.fax) {
      data.locationPayload.fax = location?.fax
    }
    if (
      location.credentialGroup?.value !==
      currentLocation?._credentialGroup?.value
    ) {
      data.locationPayload._credentialGroup = location.credentialGroup?.value
    }
    // location.lobs
    const isLobUpdated = location?.lobs?.some(
      (_eachLob, index) => _eachLob.value !== currentLocation.lobs[index],
    )
    if (isLobUpdated) {
      data.locationPayload.lobs = location?.lobs?.map((lob) => {
        return lob.value
      })
    }

    // address payload
    const addressPayload = {}
    let coordinatesPayload = {}

    if (address?.unitNo !== currentLocation?.address?.unitNo) {
      addressPayload.unitNo = address?.unitNo
    }
    if (address.streetNo !== currentLocation?.address?.street) {
      addressPayload.street = address?.streetNo
    }
    if (address.city.toUpperCase() !== currentLocation?.address?.city) {
      addressPayload.city = address?.city
    }
    if (address?.zip[0]?.value !== currentLocation?.address?.zip[0]) {
      coordinatesPayload.lat = coordinates.lat.toString()
      coordinatesPayload.long = coordinates.lng.toString()
    }
    // location.zips
    const isZipUpdated = address?.zip?.some(
      (_eachZip, index) =>
        _eachZip.value !== currentLocation?.address?.zip[index],
    )
    if (isZipUpdated) {
      addressPayload.zip = address?.zip?.map((zip) => {
        return zip.value
      })
    }

    if (address.state !== currentLocation?.address?.state) {
      addressPayload.state = address.state
    }

    if (Object.keys(addressPayload).length !== 0) {
      addressPayload.coordinates = coordinatesPayload
      data.locationPayload.address = addressPayload
    }
    setIsFormChanged(false)
    return data
  }

  const ValidateForm = () => {
    if (!url.includes(`franchises`)) {
      if (!franchise?.value?.length) {
        handleErrors(`franchiseError`, `Select Franchise`)
      } else {
        handleErrors(`franchiseError`, ``)
      }
    }

    if (!location.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else if (location.name.length && errors.nameError.length) {
      return
    } else {
      handleErrors(`nameError`, ``)
    }
    if (!location?.businessName?.length) {
      handleErrors(`businessNameError`, `Enter Name`)
    } else if (
      location?.name?.businessName &&
      errors?.businessNameError?.length
    ) {
      return
    } else {
      handleErrors(`businessNameError`, ``)
    }

    if (!location.phone.length) {
      handleErrors(`phoneError`, `Enter Phone No`)
    } else if (
      location.phone !== currentLocation.phone &&
      location.phone.length < 12
    ) {
      handleErrors(`phoneError`, `Enter Valid Fax No`)
    } else {
      handleErrors(`phoneError`, ``)
    }

    if (!location?.ext?.length) {
      handleErrors(`extError`, `Enter Extension`)
    } else if (location?.ext?.length) {
      if (isNaN(location?.ext)) {
        handleErrors(`extError`, `Ext Should be a number`)
      } else if (location?.ext?.length < 4) {
        handleErrors(`extError`, `Ext length should be less than 4`)
      } else {
        handleErrors(`extError`, ``)
      }
    } else {
      handleErrors(`extError`, ``)
    }

    if (!location.email.length) {
      handleErrors(`emailError`, `Enter Email`)
    } else if (location.email.length) {
      if (
        errors.emailError.length &&
        errors.emailError.includes(`Email Already Exists`)
      ) {
        handleErrors(`emailError`, `Email Already Exists`)
      } else {
        if (!regEx.test(location.email.toLowerCase())) {
          handleErrors(`emailError`, `Enter Valid Email`)
        } else {
          handleErrors(`emailError`, ``)
        }
      }
    } else {
      handleErrors(`emailError`, ``)
    }

    if (!location.fax.length) {
      handleErrors(`faxError`, `Enter Fax`)
    } else if (
      location.fax !== currentLocation.fax &&
      location.fax.length < 12
    ) {
      handleErrors(`faxError`, `Enter Valid Fax No`)
    } else {
      handleErrors(`faxError`, ``)
    }
    if (!location.lobs?.length && !Object.keys(location.lobs).length) {
      handleErrors(`lobsError`, `Select Lobs`)
    } else {
      handleErrors(`lobsError`, ``)
    }

    // if (!address.unitNo.length) {
    //   handleErrors(`unitNoError`, `Enter Unit No`)
    // } else {
    //   handleErrors(`unitNoError`, ``)
    // }

    if (!address.zip?.length && !Object.keys(address.zip).length) {
      handleErrors(`zipError`, `Select Zip Code`)
    } else {
      handleErrors(`zipError`, ``)
    }

    if (!address.streetNo.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }

    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }

    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, true)
    } else {
      handleErrors(`coordinatesError`, false)
    }
    handleErrors(`formSubmit`, true)
  }

  // load zipcodes specific to the franchise
  const loadRegionZipCodes = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        franchise.value
      }/regions/list/?search=${search && search}&page=${page}&offset=10`,
    )

    if (data?.regions) {
      return {
        options: data?.regions,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  // load lobs specific to the franchise
  const loadLobs = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${franchise.value}/lobs/list/?search=${
        search && search
      }&page=${page}&offset=10`,
    )

    if (data?.lobs) {
      return {
        options: data?.lobs,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  useEffect(() => {
    currentLocation?.address?.zip?.length
      ? setFirstZip(currentLocation?.address?.zip[0]?.value)
      : setFirstZip(``)
  }, [])

  useEffect(() => {
    address?.zip?.length ? setFirstZip(address?.zip[0]?.value) : setFirstZip(``)
  }, [address?.zip?.length])

  useEffect(() => {
    if (firstZip) {
      handleAddressInput(firstZip, `zip`)
    } else {
      setAddress((prevState) => ({
        ...prevState,
        [`city`]: ``,
        [`state`]: ``,
      }))
    }
  }, [firstZip])

  return (
    <Container>
      <PageHeader>
        <div>Update Location</div>
      </PageHeader>
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      {isFormChanged && (
        <ReactNotification action="error" message="No Field Changed" />
      )}
      <Spinner loading={isLoading} />
      <div
        className={`p-4 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="flex justify-center items-center my-0 py-3">
          <div className="w-50">
            <Label>
              <Image
                alt="fImage"
                src={location?.image ? location?.image : defaultImage}
              />

              <input
                type="file"
                onChange={(e) => handleLogo(e.target.files)}
                className="hidden"
                accept="image/*"
              />
            </Label>
          </div>
        </div>
        {/* Locations basic form */}
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Franchise *"
              placeholder="Select Franchise"
              errorMessage={errors.franchiseError}
              value={franchise || ``}
              setValue={(e) => setFranchise(e)}
              isMulti={false}
              loadOptions={loadFranchises}
              isSearchable={true}
              disabled={url.includes(`update`)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name *"
              name="name"
              placeholder="Enter Name"
              errorMessage={errors.nameError}
              value={location.name || ``}
              setValue={(e) => handleNameInput(e)}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Phone *"
              placeholder="Enter Phone No"
              errorMessage={errors.phoneError}
              value={location.phone || ``}
              setValue={(e) => handleLocationInput(`phone`, e.target.value)}
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Extension *"
              placeholder="Enter Extension"
              value={location.ext || ``}
              errorMessage={errors.extError}
              setValue={(e) => handleLocationInput(`ext`, e.target.value)}
              mask="9999"
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="email"
              title="Email *"
              name="email"
              placeholder="Enter Email"
              errorMessage={errors.emailError}
              value={location.email || ``}
              setValue={(e) => handleEmailInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Fax *"
              placeholder="Enter Fax"
              errorMessage={errors.faxError}
              value={location.fax || ``}
              setValue={(e) => handleLocationInput(`fax`, e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Business Name *"
              name="businessName"
              placeholder="Enter Name"
              errorMessage={errors.businessNameError}
              value={location.businessName || ``}
              setValue={(e) => handleBusinessNameInput(e)}
              validating={validatingDuplicates?.businessName}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Group Credentials *"
              placeholder="Select Group"
              errorMessage={errors.credentialGroupError}
              value={location?.credentialGroup}
              setValue={(e) => handleLocationInput(`credentialGroup`, e)}
              isMulti={false}
              loadOptions={loadGroupCredentials}
              isSearchable={true}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Lobs *"
              placeholder="Select Lobs"
              errorMessage={errors.lobsError}
              value={location.lobs || ``}
              setValue={(e) => handleLocationInput(`lobs`, e)}
              isMulti={true}
              loadOptions={franchise.value && loadLobs}
              isSearchable={false}
              cacheUniqs={franchise.value}
            />
          </div>
        </div>

        {/* Location Address starts from here */}
        <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Address
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street *"
              name="streetNo"
              placeholder="Enter Street"
              errorMessage={errors.streetNoError}
              value={address.streetNo || ``}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              placeholder="Select Zip"
              errorMessage={errors.zipError}
              value={address.zip || ``}
              setValue={(e) =>
                setAddress((prevState) => ({
                  ...prevState,
                  [`zip`]: e,
                }))
              }
              isMulti={true}
              loadOptions={
                // show all zipcodes to US OFFIces franchise
                franchise &&
                (franchise?.label === `US OFFICES`
                  ? loadZipCodes
                  : loadRegionZipCodes)
              }
              isSearchable={true}
              cacheUniqs={franchise.value}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              name="unitNo"
              placeholder="Enter Unit No"
              // errorMessage={errors.unitNoError}
              value={address.unitNo || ``}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="City *"
              name="city"
              placeholder="Enter City"
              errorMessage={errors.cityError}
              validating={locZipValidating}
              disabled={true}
              value={address.city || ``}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="State *"
              name="state"
              placeholder="Enter State"
              errorMessage={errors.stateError}
              validating={locZipValidating}
              disabled={true}
              value={address.state || ``}
              setValue={(e) => handleAddressInput(e)}
            />
          </div>
        </div>

        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            {coordinates.lat && coordinates.lng && (
              <ChangeView
                center={[coordinates.lat, coordinates.lng]}
                zoom={3}
              />
            )}
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div>
        <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
          <SubmitButton onClick={ValidateForm} disabled={locZipValidating}>
            Update Location
          </SubmitButton>
        </div>
      </div>
    </Container>
  )
}
