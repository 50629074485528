import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router'
import { defaultImage } from 'App/Assets'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { RemoveLocation } from './style'
import { Spinner } from 'App/Components/Common/Spinner'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { MaskField } from 'App/Components/Common/MaskField'
import { InputField } from 'App/Components/Common/InputField'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Image,
  Label,
  Container,
  PageHeader,
  SubmitButton,
  AddTabButton,
} from 'App/Styled'
import { DatePickerField } from 'App/Components/Common/DatePicker'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import {
  loadZipCodes,
  ValidateZip,
  loadCustomerTypes,
  loadBusinessTypes,
  loadFranchises,
  ValidateDuplicateCustomerPhone,
  ValidateDuplicateCustomerEmail,
  loadGenderOptions,
  fileResizer,
} from 'App/Services'
import { useDebouncedCallback } from 'use-debounce/lib'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import { FormTab } from 'App/Styled'
import { NotificationManager } from 'react-notifications'

export const AddCustomer = () => {
  const {
    params: { fId },
    url,
  } = useRouteMatch()
  const [activeContactTab, setActiveContactTab] = useState(1)
  const [customer, setCustomer] = useState({
    type: ``,
    name: ``,
    location: ``,
    greetingName: ``,
    gender: ``,
    fein: ``,
    sic: ``,
    acquisitionDate: null,
    dob: null,
    preferredLanguage: ``,
    yearBusinessStarted: ``,
    typeOfBusiness: ``,
    mobile: ``,
    phone: ``,
    franchise: ``,
    fax: ``,
    dp: ``,
    email: ``,
  })

  const [customerAddress, setCustomerAddress] = useState({
    unitNo: ``,
    streetNo: ``,
    city: ``,
    zip: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    typeError: ``,
    nameError: ``,
    greetingNameError: ``,
    genderError: ``,
    feinError: ``,
    sicError: ``,
    acquisitionDateError: ``,
    dobError: ``,
    preferredLanguageError: ``,
    yearBusinessStartedError: ``,
    typeOfBusinessError: ``,
    mobileError: ``,
    franchiseError: ``,
    locationError: ``,
    phoneError: ``,
    faxError: ``,
    dpError: ``,
    emailError: ``,
    unitNoError: ``,
    streetNoError: ``,
    cityError: ``,
    zipError: ``,
    stateError: ``,
    coordinatesError: ``,
  })

  const [contacts, setContacts] = useState([
    {
      name: ``,
      nameError: ``,
      mobile: ``,
      mobileError: ``,
      phone: ``,
      phoneError: ``,
      fax: ``,
      faxError: ``,
      email: ``,
      emailError: ``,
      isDriver: false,
      address: {
        unitNo: ``,
        unitNoError: ``,
        streetNo: ``,
        streetNoError: ``,
        city: ``,
        cityError: ``,
        zip: ``,
        zipError: ``,
        state: ``,
        stateError: ``,
        coordinates: {
          lat: 0,
          lng: 0,
        },
        coordinatesError: ``,
      },
    },
  ])

  const [cTabs, setCTabs] = useState([
    {
      tName: `Contact 1`,
      tNumber: 1,
    },
  ])

  useEffect(() => {
    if (customer.franchise?.value) {
      setCustomer((prevState) => ({
        ...prevState,
        [`location`]: ``,
      }))
    }
  }, [customer.franchise?.value])

  useEffect(() => {
    if (url.includes(`franchises`)) {
      setCustomer((prevState) => ({
        ...prevState,
        franchise: { label: `Current Franchise`, value: fId },
      }))
    }
  }, [url])

  const history = useHistory()

  const handleCustomerChange = useDebouncedCallback((name, value) => {
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleCustomerAddressChange = useDebouncedCallback((name, value) => {
    setCustomerAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  // const handleCustomerAddressChange = (e) => {
  //   const { name, value } = e.target
  //   setCustomerAddress((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }))
  // }

  const handleCustomerZipChange = async (e, index, contact) => {
    const data = await ValidateZip(e?.value)
    if (data) {
      const res = data.address
      if (contact) {
        const newContacts = contacts?.map((i, ind) => {
          if (index === ind) {
            i.address[`city`] = res.city
            i.address[`state`] = res.state
            i.address[`zip`] = { value: res.zipCode, label: res.zipCode }
            i.address[`coordinates`][`lat`] = res.lat
            i.address[`coordinates`][`lng`] = res.long
          }
          return i
        })
        setContacts(newContacts)
      } else {
        setCustomerAddress((prevState) => ({
          ...prevState,
          [`city`]: res.city,
          [`state`]: res.state,
          [`zip`]: { value: res.zipCode, label: res.zipCode },
        }))
        //setting coordinates
        setCoordinates((prevState) => ({
          ...prevState,
          [`lat`]: res.lat,
          [`lng`]: res.long,
        }))
      }
    }
  }
  //handle change input
  const handleContactChange = useDebouncedCallback(
    (name, value, e, ind, coord) => {
      const newContacts = contacts?.map((contact, index) => {
        if (index === ind) {
          if (coord) {
            contact.address.coordinates = e
          } else if (e.target.id === `address`) {
            contact.address[name] = value
          } else {
            contact[name] = value
          }
        }
        return contact
      })
      setContacts(newContacts)
    },
    5,
  )
  const handleContactDriver = useDebouncedCallback((name, ind) => {
    const newContacts = contacts?.map((contact, index) => {
      if (index === ind) {
        contact[name] = !contact.isDriver
      }
      return contact
    })
    setContacts(newContacts)
  }, 5)

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleUniquePhone = async (name, value) => {
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (value.length > 13) {
      const response = await ValidateDuplicateCustomerPhone(value)
      if (response.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`phoneError`, `Phone Alredy Exists`)
      } else {
        handleErrors(`formSubmit`, false)
        handleErrors(`phoneError`, ``)
      }
    }
  }

  const handleUniqueMobile = useDebouncedCallback(async (name, value) => {
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (value.length > 13) {
      const response = await ValidateDuplicateCustomerPhone(value)
      if (response.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`mobileError`, `Number Alredy Exists`)
      } else {
        handleErrors(`formSubmit`, false)
        handleErrors(`mobileError`, ``)
      }
    }
  }, 5)

  const handleUniqueEmail = useDebouncedCallback(async (name, value) => {
    setCustomer((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (value) {
      const response = await ValidateDuplicateCustomerEmail(value)
      if (response.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`emailError`, `Email Already Exists`)
      } else {
        handleErrors(`formSubmit`, false)
        handleErrors(`emailError`, ``)
      }
    }
  }, 5)

  //remove location
  const handleRemoveContact = (ind) => {
    const newContacts = [...contacts]
    if (newContacts.length != 1) {
      newContacts.splice(ind, 1)
      setContacts(newContacts)
    }

    const newContactsTab = [...cTabs]
    if (newContactsTab.length !== 1) {
      newContactsTab.pop()
      setCTabs(newContactsTab)
    }
  }

  //add location
  const handleAddContact = () => {
    setCTabs([
      ...cTabs,
      {
        tName: `Contact ${cTabs.length + 1}`,
        tNumber: cTabs.length + 1,
      },
    ])
    setContacts([
      ...contacts,
      {
        name: ``,
        nameError: ``,
        mobile: ``,
        mobileError: ``,
        phone: ``,
        phoneError: ``,
        fax: ``,
        faxError: ``,
        email: ``,
        emailError: ``,
        isDriver: ``,
        isDriverError: ``,
        address: {
          unitNo: ``,
          unitNoError: ``,
          streetNo: ``,
          streetNoError: ``,
          city: ``,
          cityError: ``,
          zip: ``,
          zipError: ``,
          state: ``,
          stateError: ``,
          coordinates: {
            lat: 0,
            lng: 0,
          },
          coordinatesError: ``,
        },
      },
    ])
  }

  useEffect(() => {
    setActiveContactTab(cTabs.length)
  }, [cTabs.length])

  const handleContactsErrors = (ind, name, val, address) => {
    const newContacts = contacts?.map((contact, index) => {
      if (index == ind) {
        if (address) {
          contact[`address`][name] = val
        } else {
          contact[name] = val
        }
      }
      return contact
    })
    setContacts(newContacts)
  }

  const {
    mutate: addCustomer,
    isLoading: addingCustomer,
    isError: additionError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/customers`, {
        ...payload,
      }).then((res) => {
        if (res.data) {
          NotificationManager.success(`Operation Successful`)
          if (url.includes(`franchises`)) {
            history.push(`/admin/franchises/${fId}`)
          } else {
            history.push(`/admin/customers`)
          }
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  //validate form on click
  const ValidateForm = () => {
    if (!customer.dp.length) {
      handleErrors(`dpError`, `Upload Image`)
    } else {
      handleErrors(`dpError`, ``)
    }

    if (!customer.type?.value?.length) {
      handleErrors(`typeError`, `Select Type`)
    } else {
      handleErrors(`typeError`, ``)
    }

    if (!customer.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }

    if (!customer.greetingName.length) {
      handleErrors(`greetingNameError`, `Enter Greeting Name`)
    } else {
      handleErrors(`greetingNameError`, ``)
    }

    if (!customer.fein.length) {
      handleErrors(`feinError`, `Enter Fein`)
    } else {
      handleErrors(`feinError`, ``)
    }

    if (!customer.sic.length) {
      handleErrors(`sicError`, `Enter Sic`)
    } else {
      handleErrors(`sicError`, ``)
    }

    if (!customer.preferredLanguage?.value?.length) {
      handleErrors(`preferredLanguageError`, `Select Language`)
    } else {
      handleErrors(`preferredLanguageError`, ``)
    }

    if (!customer.gender?.value?.length) {
      handleErrors(`genderError`, `Select Gender`)
    } else {
      handleErrors(`genderError`, ``)
    }

    if (!customer.phone.length) {
      handleErrors(`phoneError`, `Enter Phone`)
    } else if (customer.phone.length < 12) {
      handleErrors(`phoneError`, `Enter Valid Phone`)
    } else {
      handleErrors(`phoneError`, ``)
    }

    if (!customer.mobile.length) {
      handleErrors(`mobileError`, `Enter Mobile`)
    } else if (customer.mobile.length && customer.mobile.length < 12) {
      handleErrors(`mobileError`, `Enter Valid Number`)
    } else {
      handleErrors(`mobileError`, ``)
    }

    if (!customer.fax.length) {
      handleErrors(`faxError`, `Enter Fax`)
    } else if (customer.fax.length && customer.fax.length < 12) {
      handleErrors(`faxError`, `Enter Valid Fax`)
    } else {
      handleErrors(`faxError`, ``)
    }

    if (!customer.franchise?.value?.length) {
      handleErrors(`franchiseError`, `Select Franchise`)
    } else {
      handleErrors(`franchiseError`, ``)
    }

    if (!customer.location?.value?.length) {
      handleErrors(`locationError`, `Select Location`)
    } else {
      handleErrors(`locationError`, ``)
    }

    if (!customer.typeOfBusiness?.value?.length) {
      handleErrors(`typeOfBusinessError`, `Select Business Type`)
    } else {
      handleErrors(`typeOfBusinessError`, ``)
    }

    if (!customer.yearBusinessStarted.length) {
      handleErrors(`yearBusinessStartedError`, `Enter Year`)
    } else if (
      customer.yearBusinessStarted.length &&
      isNaN(customer.yearBusinessStarted)
    ) {
      handleErrors(`yearBusinessStartedError`, `Value Should be a number`)
    } else if (customer.yearBusinessStarted.length) {
      const currentYear = new Date().getFullYear()
      if (JSON.parse(customer.yearBusinessStarted) > currentYear) {
        handleErrors(`yearBusinessStartedError`, `Year Should be valid`)
      } else {
        handleErrors(`yearBusinessStartedError`, ``)
      }
    } else {
      handleErrors(`yearBusinessStartedError`, ``)
    }

    if (!customer.email.length) {
      handleErrors(`emailError`, `Enter Email`)
    } else if (customer.email.length) {
      if (errors.emailError.includes(`Email Already Exists`)) {
        handleErrors(`emailError`, `Email Already Exists`)
      } else if (!regEx.test(customer.email.toLowerCase())) {
        handleErrors(`emailError`, `Enter Valid Email`)
      } else {
        handleErrors(`emailError`, ``)
      }
    } else {
      handleErrors(`emailError`, ``)
    }
    if (!customer.acquisitionDate) {
      handleErrors(`acquisitionDateError`, `Select Date`)
    } else if (customer.acquisitionDate) {
      var selectedDate = new Date(customer.acquisitionDate)
      var nowDate = new Date()
      if (selectedDate > nowDate) {
        handleErrors(`acquisitionDateError`, `Enter Valid date`)
      } else {
        handleErrors(`acquisitionDateError`, ``)
      }
    }

    if (!customer.dob) {
      handleErrors(`dobError`, `Select Date`)
    } else if (customer.dob) {
      var selectDate = new Date(customer.dob)
      var currentDate = new Date()
      if (selectDate > currentDate) {
        handleErrors(`dobError`, `Enter Valid date`)
      } else {
        handleErrors(`dobError`, ``)
      }
    }

    if (!customerAddress.unitNo.length) {
      handleErrors(`unitNoError`, `Enter Unit No`)
    } else {
      handleErrors(`unitNoError`, ``)
    }
    if (!customerAddress.zip?.value?.length) {
      handleErrors(`zipError`, `Enter Zip`)
    } else {
      handleErrors(`zipError`, ``)
    }

    if (!customerAddress.streetNo.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }

    if (!customerAddress.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }

    if (!customerAddress.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, true)
    } else {
      handleErrors(`coordinatesError`, false)
    }

    for (const index in contacts) {
      const contact = contacts[index]
      if (!contact.name.length) {
        handleContactsErrors(index, `nameError`, `Enter Name`)
      } else if (contact.name.length && contact.name.length < 3) {
        handleContactsErrors(index, `nameError`, `Name Length must be 3`)
      } else handleContactsErrors(index, `nameError`, ``)

      if (!contact.phone.length) {
        handleContactsErrors(index, `phoneError`, `Enter Phone`)
      } else if (contact.phone.length && contact.phone.length < 12) {
        handleContactsErrors(index, `phoneError`, `Enter Valid Phone`)
      } else handleContactsErrors(index, `phoneError`, ``)

      if (!contact.mobile.length) {
        handleContactsErrors(index, `mobileError`, `Enter Phone`)
      } else if (contact.mobile.length && contact.mobile.length < 12) {
        handleContactsErrors(index, `mobileError`, `Enter Valid Phone`)
      } else handleContactsErrors(index, `mobileError`, ``)

      if (!contact.fax.length) {
        handleContactsErrors(index, `faxError`, `Enter Fax`)
      } else if (contact.fax.length && contact.fax.length < 12) {
        handleContactsErrors(index, `faxError`, `Enter Valid Fax`)
      } else handleContactsErrors(index, `faxError`, ``)

      if (!contact.email.length) {
        handleContactsErrors(index, `emailError`, `Enter Email`)
      } else if (contact.email.length) {
        if (!regEx.test(contact.email.toLowerCase())) {
          handleContactsErrors(index, `emailError`, `Enter Valid Email`)
        } else {
          handleContactsErrors(index, `emailError`, ``)
        }
      } else {
        handleContactsErrors(index, `emailError`, ``)
      }

      if (!contact.address.unitNo.length) {
        handleContactsErrors(index, `unitNoError`, `Enter Unit No`, true)
      } else {
        handleContactsErrors(index, `unitNoError`, ``, true)
      }

      if (!contact.address.zip?.value?.length) {
        handleContactsErrors(index, `zipError`, `Select Zip`, true)
      } else {
        handleContactsErrors(index, `zipError`, ``, true)
      }

      if (!contact.address.streetNo.length) {
        handleContactsErrors(index, `streetNoError`, `Enter Street`, true)
      } else {
        handleContactsErrors(index, `streetNoError`, ``, true)
      }

      if (!contact.address.city.length) {
        handleContactsErrors(index, `cityError`, `Enter City`, true)
      } else {
        handleContactsErrors(index, `cityError`, ``, true)
      }

      if (!contact.address.state.length) {
        handleContactsErrors(index, `stateError`, `Enter State`, true)
      } else {
        handleContactsErrors(index, `stateError`, ``, true)
      }

      if (
        !contact.address.coordinates.lat &&
        !contact.address.coordinates.lng
      ) {
        handleContactsErrors(
          index,
          `coordinatesError`,
          `Enter Coordinates`,
          true,
        )
      } else {
        handleContactsErrors(index, `coordinatesError`, ``, true)
      }
    }
    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    let formValidated = false
    if (
      errors.formSubmit &&
      !errors.dpError.length &&
      !errors.typeError.length &&
      !errors.nameError.length &&
      !errors.feinError.length &&
      !errors.sicError.length &&
      !errors.preferredLanguageError.length &&
      !errors.genderError.length &&
      !errors.phoneError.length &&
      !errors.mobileError.length &&
      !errors.faxError.length &&
      !errors.franchiseError.length &&
      !errors.locationError.length &&
      !errors.typeOfBusinessError.length &&
      !errors.yearBusinessStartedError.length &&
      !errors.emailError.length &&
      !errors.acquisitionDateError.length &&
      !errors.dobError.length &&
      !errors.unitNoError.length &&
      !errors.zipError.length &&
      !errors.streetNoError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.coordinatesError.length
    ) {
      contacts?.forEach((contact) => {
        if (
          !contact.nameError.length &&
          !contact.phoneError.length &&
          !contact.mobileError.length &&
          !contact.faxError.length &&
          !contact.emailError.length &&
          !contact.address.unitNoError.length &&
          !contact.address.zipError.length &&
          !contact.address.streetNoError.length &&
          !contact.address.cityError.length &&
          !contact.address.stateError.length &&
          !contact.address.coordinatesError.length
        ) {
          formValidated = true
        } else {
          formValidated = false
        }
      })

      if (formValidated) {
        const payloadContacts = contacts?.map((contact) => {
          return {
            name: contact.name,
            mobile: contact.mobile,
            phone: contact.phone,
            fax: contact.fax,
            email: contact.email,
            isDriver: contact.isDriver,
            address: {
              unitNo: contact.address.unitNo,
              street: contact.address.streetNo,
              city: contact.address.city,
              zip: contact?.address?.zip?.value.toString(),
              state: contact.address.state,
              coordinates: {
                lat: contact.address.coordinates.lat.toString(),
                long: contact.address.coordinates.lng.toString(),
              },
            },
          }
        })
        if (payloadContacts?.length) {
          addCustomer({
            type: customer.type.value,
            name: customer.name,
            greetingName: customer.greetingName,
            fein: customer.fein,
            sic: customer.sic,
            acquisitionDate: customer.acquisitionDate,
            dob: customer.dob,
            preferredLanguage: customer.preferredLanguage.value.toUpperCase(),
            gender: customer.gender.value,
            yearBusinessStarted: customer.yearBusinessStarted,
            typeOfBusiness: customer.typeOfBusiness.value,
            mobile: customer.mobile,
            phone: customer.phone,
            _franchise: customer.franchise.value,
            _location: customer.location.value,
            fax: customer.fax,
            dp: customer.dp,
            email: customer.email,
            address: {
              unitNo: customerAddress.unitNo,
              street: customerAddress.streetNo,
              city: customerAddress.city,
              zip: customerAddress.zip.value.toString(),
              state: customerAddress.state,
              coordinates: {
                lat: coordinates.lat.toString(),
                long: coordinates.lng.toString(),
              },
            },
            contacts: payloadContacts,
          })
        }
      }
      setErrors((prevState) => ({
        ...prevState,
        [`formSubmit`]: false,
      }))
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  const handleDp = async (file) => {
    const base64 = await fileResizer(file[0])
    setCustomer((prevState) => ({
      ...prevState,
      [`dp`]: base64,
    }))
  }

  const loadPreferedLanguages = () => {
    const options = {
      options: [
        { value: `English`, label: `English` },
        { value: `Spanish`, label: `Spanish` },
      ],
      hasMore: false,
    }
    return options
  }

  const loadLocations = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        customer.franchise.value
      }/locations/list/?search=${search && search}&page=${page}&offset=10`,
    )
    if (data) {
      return {
        options: data.locations,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  // load zipcodes specific to the franchise
  const loadCustomerRegions = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        customer.franchise.value
      }/regions/list/?search=${search && search}&page=${page}&offset=10`,
    )

    if (data?.regions) {
      return {
        options: data?.regions,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  return (
    <Container className="relative pb-4">
      <PageHeader>
        <div>Add Customer</div>
      </PageHeader>
      {additionError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={addingCustomer} />

      <div
        className={`px-8 py-1 ${
          addingCustomer && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="items-center my-0">
          <Label flogo={customer.dp}>
            <Image
              alt="fImage"
              src={customer.dp ? customer.dp : defaultImage}
            />

            <input
              type="file"
              onChange={(e) => handleDp(e.target.files)}
              className="hidden"
              accept="image/*"
            />
            {errors.dpError && (
              <>
                <p className=" text-sm text-red-500 mb-1">
                  {errors.dpError}
                  <span className="text-red-500 required-dot">*</span>
                </p>
              </>
            )}
          </Label>
        </div>
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Type"
              placeholder="Select Type"
              errorMessage={errors.typeError}
              value={customer.type}
              setValue={(e) =>
                setCustomer((prevState) => ({
                  ...prevState,
                  [`type`]: e,
                }))
              }
              isMulti={false}
              loadOptions={loadCustomerTypes}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name"
              placeholder="Enter Name"
              name="name"
              errorMessage={errors.nameError}
              value={customer.name}
              setValue={(e) => handleCustomerChange(`name`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Greeting Name"
              placeholder="Enter Greeting Name"
              name="greetingName"
              errorMessage={errors.greetingNameError}
              value={customer.greetingName}
              setValue={(e) =>
                handleCustomerChange(`greetingName`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Gender"
              placeholder="Select Gender"
              errorMessage={errors.genderError}
              value={customer.gender}
              setValue={(value) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  [`gender`]: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadGenderOptions}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <DatePickerField
              title="Date of birth"
              placeholder="Select Date"
              value={customer.dob}
              errorMessage={errors.dobError}
              setValue={(date) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  [`dob`]: date,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Fein"
              placeholder="Enter Fein"
              name="fein"
              errorMessage={errors.feinError}
              value={customer.fein}
              setValue={(e) => handleCustomerChange(`fein`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Sic"
              placeholder="Enter Sic"
              name="sic"
              errorMessage={errors.sicError}
              value={customer.sic}
              setValue={(e) => handleCustomerChange(`sic`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Preferred Language"
              placeholder="Preferred Language"
              errorMessage={errors.preferredLanguageError}
              value={customer.preferredLanguage}
              setValue={(e) =>
                setCustomer((prevState) => ({
                  ...prevState,
                  [`preferredLanguage`]: e,
                }))
              }
              isMulti={false}
              loadOptions={loadPreferedLanguages}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Phone"
              placeholder="Enter Phone No"
              errorMessage={errors.phoneError}
              name="phone"
              value={customer.phone}
              setValue={(e) => handleUniquePhone(`phone`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Mobile"
              placeholder="Enter Mobile"
              errorMessage={errors.mobileError}
              name="mobile"
              value={customer.mobile}
              setValue={(e) => handleUniqueMobile(`mobile`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Fax"
              placeholder="Enter Fax"
              errorMessage={errors.faxError}
              name="fax"
              value={customer.fax}
              setValue={(e) => handleCustomerChange(`fax`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Franchise"
              placeholder="Select Franchise"
              errorMessage={errors.franchiseError}
              value={customer.franchise}
              setValue={(value) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  [`franchise`]: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadFranchises}
              isSearchable={true}
              disabled={url.includes(`franchises`)}
            />
          </div>
        </div>

        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Locations"
              placeholder="Select Locations"
              errorMessage={errors.locationError}
              value={customer.location}
              setValue={(value) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  [`location`]: value,
                }))
              }}
              isMulti={false}
              loadOptions={customer?.franchise?.value && loadLocations}
              isSearchable={true}
              cacheUniqs={customer.franchise?.value}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Business Type"
              placeholder="Select Business Type"
              errorMessage={errors.typeOfBusinessError}
              value={customer.typeOfBusiness}
              setValue={(value) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  [`typeOfBusiness`]: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadBusinessTypes}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Years Business Started"
              placeholder="Enter Year"
              name="yearBusinessStarted"
              errorMessage={errors.yearBusinessStartedError}
              value={customer.yearBusinessStarted}
              setValue={(e) =>
                handleCustomerChange(`yearBusinessStarted`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="email"
              title="Email"
              name="email"
              placeholder="Enter Email"
              errorMessage={errors.emailError}
              value={customer.email}
              setValue={(e) => handleUniqueEmail(`email`, e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <DatePickerField
              title="Acquisition Date"
              placeholder="Select Date"
              value={customer.acquisitionDate}
              errorMessage={errors.acquisitionDateError}
              setValue={(date) => {
                setCustomer((prevState) => ({
                  ...prevState,
                  [`acquisitionDate`]: date,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative"></div>
          <div className="flex-1 relative"></div>
          <div className="flex-1 relative"></div>
        </div>

        <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Address
        </div>

        <div className="flex gap-6 mt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              name="unitNo"
              placeholder="Enter Unit No"
              errorMessage={errors.unitNoError}
              value={customerAddress.unitNo}
              setValue={(e) =>
                handleCustomerAddressChange(`unitNo`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip"
              placeholder="Select Zip"
              errorMessage={errors.zipError}
              value={customerAddress.zip}
              setValue={(e) => handleCustomerZipChange(e)}
              isMulti={false}
              loadOptions={customer.franchise && loadCustomerRegions}
              isSearchable={true}
              cacheUniqs={customer.franchise}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street"
              name="streetNo"
              placeholder="Enter Street"
              errorMessage={errors.streetNoError}
              value={customerAddress.streetNo}
              setValue={(e) =>
                handleCustomerAddressChange(`streetNo`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="City"
              name="city"
              placeholder="Enter City"
              errorMessage={errors.cityError}
              disabled={true}
              value={customerAddress.city}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="State"
              name="state"
              placeholder="Enter State"
              errorMessage={errors.stateError}
              disabled={true}
              value={customerAddress.state}
            />
          </div>
        </div>
        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView center={[coordinates.lat, coordinates.lng]} zoom={3} />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div>

        {/* Contacts starts from here */}
        <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Contacts
        </div>

        {/* tabs here */}
        <div className="flex items-center">
          <TabsHeaders
            openTab={activeContactTab}
            tabs={cTabs}
            getActiveTab={(val) => setActiveContactTab(val)}
            customTabs="YES"
          />
          <div className="px-2">
            <AddTabButton onClick={() => handleAddContact()}>
              <FontAwesomeIcon icon={faPlus} />
            </AddTabButton>
          </div>
        </div>
        {/* tabs end herer */}
        <div className="grid grid-cols-1 gap-10 mt-4">
          {contacts.length > 0 &&
            contacts?.map((contact, index) => (
              <FormTab
                key={index}
                currenttab={index + 1}
                opentab={activeContactTab}
                className="border-2 border-gray-100 shadow-sm rounded-md relative"
              >
                <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                  <div className="flex-1">Contact {index + 1}</div>
                  <div className="flex-1">
                    <div className="float-right">
                      <RemoveLocation
                        onClick={() => {
                          handleRemoveContact(index)
                          if (index > 0) setActiveContactTab(index)
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} size="1x" />
                      </RemoveLocation>
                    </div>
                  </div>
                </div>
                <div className="p-4">
                  <div className="grid grid-cols-4 gap-6 mt-2 text-xs">
                    <div className="flex-1 relative">
                      <InputField
                        type="text"
                        title="Name"
                        placeholder="Enter Name"
                        errorMessage={contact.nameError}
                        name="name"
                        value={contact.name}
                        setValue={(e) =>
                          handleContactChange(`name`, e.target.value, e, index)
                        }
                      />
                    </div>
                    <div className="flex-1 relative">
                      <MaskField
                        type="text"
                        title="Phone"
                        placeholder="Enter Phone No"
                        errorMessage={contact.phoneError}
                        name="phone"
                        value={contact.phone}
                        setValue={(e) =>
                          handleContactChange(`phone`, e.target.value, e, index)
                        }
                      />
                    </div>
                    <div className="flex-1 relative">
                      <MaskField
                        type="text"
                        title="Mobile"
                        placeholder="Enter Mobile"
                        errorMessage={contact.mobileError}
                        name="mobile"
                        value={contact.mobile}
                        setValue={(e) =>
                          handleContactChange(
                            `mobile`,
                            e.target.value,
                            e,
                            index,
                          )
                        }
                      />
                    </div>
                    <div className="flex-1 relative">
                      <MaskField
                        type="text"
                        title="Fax"
                        placeholder="Enter Fax"
                        errorMessage={contact.faxError}
                        name="fax"
                        value={contact.fax}
                        setValue={(e) =>
                          handleContactChange(`fax`, e.target.value, e, index)
                        }
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="email"
                        title="Email"
                        placeholder="Enter Email"
                        name="email"
                        errorMessage={contact.emailError}
                        value={contact.email}
                        setValue={(e) =>
                          handleContactChange(`email`, e.target.value, e, index)
                        }
                      />
                    </div>
                    <div className="flex-1 relative mt-4 text-xs">
                      <Checkbox
                        title="Is Driver"
                        name="isDriver"
                        value={contact.isDriver}
                        setValue={() => handleContactDriver(`isDriver`, index)}
                      />
                    </div>
                  </div>

                  <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
                    Address
                  </div>
                  <div className="flex gap-6 mt-2 text-xs">
                    <div className="flex-1 relative">
                      <InputField
                        type="text"
                        title="Unit No"
                        placeholder="Enter Unit No"
                        errorMessage={contact.address.unitNoError}
                        id="address"
                        name="unitNo"
                        value={contact.address.unitNo}
                        setValue={(e) =>
                          handleContactChange(
                            `unitNo`,
                            e.target.value,
                            e,
                            index,
                          )
                        }
                      />
                    </div>
                    <div className="flex-1 relative">
                      <ReactSelectField
                        title="Zip"
                        placeholder="Select Zip"
                        errorMessage={contact.address.zipError}
                        isMulti={false}
                        value={contact.address.zip}
                        setValue={(e) =>
                          handleCustomerZipChange(e, index, true)
                        }
                        loadOptions={loadZipCodes}
                        isSearchable={true}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="text"
                        title="Street"
                        placeholder="Enter Street"
                        errorMessage={contact.address.streetNoError}
                        id="address"
                        name="streetNo"
                        value={contact.address.streetNo}
                        setValue={(e) =>
                          handleContactChange(
                            `streetNo`,
                            e.target.value,
                            e,
                            index,
                          )
                        }
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="text"
                        title="City"
                        placeholder="Enter City"
                        errorMessage={contact.address.cityError}
                        id="address"
                        disabled={true}
                        name="city"
                        value={contact.address.city}
                      />
                    </div>
                    <div className="flex-1 relative">
                      <InputField
                        type="text"
                        title="State"
                        placeholder="Enter State"
                        errorMessage={contact.address.stateError}
                        id="address"
                        disabled={true}
                        name="state"
                        value={contact.address.state}
                      />
                    </div>
                  </div>
                  <div className="rounded-md grid grid-cols-1">
                    <label className="text-sm mt-2 text-gray-700 font-medium">
                      Locate Yourself
                      {contact.address.coordinatesError.length !== 0 && (
                        <span className="text-red-500 required-dot ml-2">
                          *
                        </span>
                      )}
                    </label>
                    <MapContainer
                      center={[
                        contact.address.coordinates.lat,
                        contact.address.coordinates.lng,
                      ]}
                      zoom={3}
                      scrollWheelZoom={true}
                    >
                      <ChangeView
                        center={[
                          contact.address.coordinates.lat,
                          contact.address.coordinates.lng,
                        ]}
                        zoom={3}
                      />
                      <TileLayer url={LEAFLET_URL} />
                      <DraggableMarker
                        coord={contact.address.coordinates}
                        setCoordinates={(e) =>
                          handleContactChange(e, index, true)
                        }
                      />
                    </MapContainer>
                  </div>
                </div>
              </FormTab>
            ))}
        </div>
        <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
          <SubmitButton onClick={ValidateForm}>Create Customer</SubmitButton>
        </div>
      </div>
    </Container>
  )
}
