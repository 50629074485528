import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentInvoice: ``,
}

const invoiceSlice = createSlice({
  name: `invoice`,

  initialState,
  reducers: {
    setCurrentInvoice: (state, { payload }) => {
      state.currentInvoice = payload
    },
  },
})

export const { setCurrentInvoice } = invoiceSlice.actions

export const invoice = invoiceSlice.reducer
