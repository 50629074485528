import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ImageModal } from 'App/Components/Common/ImageModal'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { setCurrentTicket } from 'App/Redux/actions'
import { permissionGranted } from 'App/Services'
import { useRouteMatch } from 'react-router-dom'
import React, { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useMutation, useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import dateFormat from 'App/Services/General/dateFormat'
export const AboutTicket = () => {
  const [preview, setPreview] = useState(false)
  const [currentImage, setCurrentImage] = useState(``)
  const notificationRead = useSelector(
    ({ notification }) => notification?.isRead,
  )
  const {
    params: { id },
  } = useRouteMatch()
  const dispatch = useDispatch()
  const {
    data: ticketData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `TicketPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/ticket-center/${id}`,
      ).then((res) => {
        return res.data.data.ticket
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentTicket(res))
      },
    },
  )

  const { mutate: updateStatus, isLoading: statusUpdateLoading } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(`${AMS_BACKEND_API}/api/ticket-center/${id}`, {
        ...payload,
      }).then((res) => {
        refetch()
        if (res) {
          NotificationManager.success(`Ticket status updated`)
        }

        if (res.hasError) NotificationManager.error(hasError)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  useEffect(() => {
    refetch()
  }, [window.location.pathname, notificationRead])

  const updatePayload = () => {
    updateStatus({
      status: `CLOSE`,
    })
  }

  const badgeColor = {
    LOW: `bg-gray-400`,
    MEDIUM: `bg-yellow-400`,
    HIGH: `bg-red-500`,
    OPEN: `bg-green-400`,
    CLOSE: `bg-red-400`,
    createdBy: `bg-purple-400`,
    categoryName: `bg-purple-400`,
    _ticketId: `bg-yellow-400`,
  }
  return (
    <>
      {preview && currentImage && (
        <>
          <ImageModal
            setPreview={setPreview}
            setCurrentImage={setCurrentImage}
            currentImage={currentImage}
          />
        </>
      )}
      {isLoading || isFetching ? (
        <div className="text-center mx-auto py-6 h-96">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <div className="mx-auto h-96">
          <Reload refetch={() => refetch()} />
        </div>
      ) : (
        <div className="flex w-full mx-1 my-4 max-h-96 min-h-96 2xl:max-h-xl bg-blue">
          <div className="bg-white mr-4 sm:mb-0 justify-between w-full overflow-auto hide-scroller hide-scroller-firefox">
            <div className="rounded-md pb-4">
              <div className="bg-white sm:mb-0 justify-between w-full rounded-md">
                <div className="flex-1  rounded">
                  <div className="relative">
                    <div className="sticky bg-white top-0 flex justify-between py-4 rounded-sm px-2 shadow-md">
                      <div className="text-sm">
                        <div className="text-green-500 ">
                          <span className="font-bold">Created At:</span>
                          {ticketData?.createdAt
                            ? dateFormat(ticketData.createdAt)
                            : ticketData?.createdAt}
                        </div>
                        {ticketData?.status === `CLOSE` && (
                          <div className="text-red-500">
                            <span className="font-bold">Closed At:</span>
                            {ticketData?.updatedAt
                              ? dateFormat(ticketData?.updatedAt)
                              : ticketData?.updatedAt}
                          </div>
                        )}
                      </div>

                      <div className="flex my-auto gap-x-2">
                        <div className="font-bold flex justify-between">
                          {ticketData?.status === `OPEN` &&
                            permissionGranted([
                              `ticket:update`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ]) && (
                              <span
                                className={`badge outline-none border-0  ${
                                  statusUpdateLoading
                                    ? `cursor-not-allowed bg-gray-500`
                                    : `cursor-pointer bg-red-500`
                                } tooltip tooltip-left`}
                                data-tip="Change Ticket Status"
                                onClick={updatePayload}
                                disabled={statusUpdateLoading}
                              >
                                Close Ticket
                                {statusUpdateLoading && (
                                  <span className="text-center mx-auto py-6">
                                    <FontAwesomeIcon
                                      icon={faSpinner}
                                      spin={true}
                                      size="1x"
                                      className="text-white"
                                    />
                                  </span>
                                )}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="m-6">
                      <div className="flex my-auto gap-x-2">
                        <div
                          className={`badge outline-none border-0 tooltip tooltip-bottom ${
                            badgeColor[`_ticketId`]
                          }`}
                          data-tip="Ticket Id"
                        >
                          {ticketData?._ticketId}
                        </div>
                        {ticketData?.mediaUrl?.length && (
                          <div
                            className={`badge outline-none border-0 tooltip tooltip-bottom bg-blue-500 hover:scale-x-125 hover:bg-blue-600 cursor-pointer`}
                            data-tip="See Screen Shot"
                            onClick={() => {
                              setPreview(true)
                              setCurrentImage(ticketData?.mediaUrl)
                            }}
                          >
                            ATTACHMENT
                          </div>
                        )}

                        <div
                          className={`badge outline-none border-0 tooltip tooltip-bottom ${
                            badgeColor[ticketData?.createdBy]
                          }`}
                          data-tip="Created By"
                        >
                          {ticketData?.createdBy}
                        </div>

                        <div
                          className={`badge outline-none border-0 tooltip tooltip-bottom ${
                            badgeColor[`categoryName`]
                          }`}
                          data-tip="Ticket Category"
                        >
                          {ticketData?.categoryName}
                        </div>
                        <div
                          className={`badge outline-none border-0 tooltip tooltip-bottom ${
                            badgeColor[ticketData?.piriority]
                          }`}
                          data-tip="Ticket Priority"
                        >
                          {ticketData?.piriority}
                        </div>
                        <div
                          className={`badge outline-none border-0 tooltip tooltip-bottom ${
                            badgeColor[ticketData?.status]
                          }`}
                          data-tip="Ticket Status"
                        >
                          {ticketData?.status}
                        </div>
                      </div>
                      <div className="text-gray-600 px-4 mt-6 font-semibold text-base">
                        {ticketData?.title}
                      </div>

                      <div className="text-gray-600 font-semibold px-5 mx-1 py-1 text-sm max-w-lg word-break">
                        {ticketData?.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
