import Resizer from 'react-image-file-resizer'

export const fileResizer = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      `JPEG`,
      120,
      0,
      (uri) => {
        resolve(uri)
      },
      `base64`,
    )
  })
