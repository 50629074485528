import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentPolicyRequest: ``,
}

const policyRequestSlice = createSlice({
    name: `policyRequest`,

    initialState,
    reducers: {
        setCurrentPolicyRequest: (state, { payload }) => {
            state.currentPolicyRequest = payload
        },
    },
})

export const {
    setCurrentPolicyRequest,
} = policyRequestSlice.actions

export const policyRequest = policyRequestSlice.reducer
