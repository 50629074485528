import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ActivityWrapper } from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowAltCircleRight,
  faBox,
  faClipboardList,
} from '@fortawesome/free-solid-svg-icons'

import { PolicyAbout } from './About'
import { PolicyActivity } from './Activity'
import { setPolicyActiveActivityTab } from 'App/Redux/actions'
import { permissionGranted } from 'App/Services'

export const LocalPolicy = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const dispatch = useDispatch()

  return (
    <div className="flex justify-end">
      <div className={`${isCollapsed ? `w-full` : `w-2/3`}`}>
        <PolicyAbout />
      </div>

      {permissionGranted(
        [`notes:read`, `corporate-manager:*`, `manager:*`, `admin:*`] || [
          `notes:*`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ],
      ) && (
        <ActivityWrapper collapsed={isCollapsed.toString()}>
          <div
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="px-2 py-1 "
          >
            {/* <CollapseClip> */}
            <FontAwesomeIcon
              icon={faArrowAltCircleRight}
              className={`text-red-500 my-0.5 cursor-pointer transform transition duration-500 ${
                isCollapsed ? `rotate-180 ` : `rotate-0`
              }`}
            />
            {/* </CollapseClip> */}
          </div>
          {!isCollapsed ? (
            <PolicyActivity />
          ) : (
            <div
              className="text-center flex flex-col"
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {permissionGranted(
                [
                  `notes:read`,
                  `corporate-manager:*`,
                  `manager:*`,
                  `admin:*`,
                ] || [`notes:*`, `corporate-manager:*`, `manager:*`, `admin:*`],
              ) ? (
                <>
                  <div
                    data-tip="Notes"
                    className="mx-5 cursor-pointer tooltip tooltip-left"
                    onClick={() => dispatch(setPolicyActiveActivityTab(1))}
                  >
                    <FontAwesomeIcon
                      icon={faClipboardList}
                      className="text-red-500"
                    />
                  </div>
                  <div
                    data-tip="Logs"
                    className="mt-4 mx-5 mb-3 cursor-pointer tooltip tooltip-left"
                    onClick={() => dispatch(setPolicyActiveActivityTab(2))}
                  >
                    <FontAwesomeIcon icon={faBox} className="text-red-500" />
                  </div>
                </>
              ) : (
                <div
                  data-tip="Logs"
                  className="mt-2 mb-3 mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setPolicyActiveActivityTab(1))}
                >
                  <FontAwesomeIcon icon={faBox} className="text-red-500" />
                </div>
              )}
            </div>
          )}
        </ActivityWrapper>
      )}
    </div>
  )
}
