import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'

import React from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router'
import { Container, PageHeader } from 'App/Styled'
import { useDispatch } from 'react-redux'
import { setCurrentLocation } from 'App/Redux/actions'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const PreviewTransaction = () => {
  const dispatch = useDispatch()

  const {
    params: { transactionId },
  } = useRouteMatch()

  const {
    data: previewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `TransactionPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/payment-transactions/${transactionId}`,
      ).then((res) => {
        return res.data.data.paymentTrx
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentLocation(res))
      },
    },
  )

  return (
    <Container>
      <PageHeader>
        <div>Transaction</div>
      </PageHeader>

      <div className="bg-white sm:mb-0 justify-between w-full px-2 rounded-md">
        {isLoading || isFetching ? (
          <div className="text-center p-6">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-500"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : previewData?.location?.length ? (
          <p className=" text-center p-6">No Data Found</p>
        ) : (
          <div className="rounded-md overflow-hidden py-4">
            <div className="flex items-start justify-evenly">
              <div className="flex-1 mx-2  rounded">
                <div className="grid grid-cols-12 px-4 py-2">
                  <div className="col-span-6 text-xl">Info</div>
                </div>
                <hr />
                <div className="px-4">
                  <CardField title="Policy No" value={previewData?.policyNo} />
                  <CardField title="Carrier" value={previewData?.carrier} />
                  <CardField title="Channel" value={previewData?.channel} />

                  <CardField title="Amount" value={`$${previewData?.amount}`} />
                  <CardField
                    title="Transaction Id"
                    value={previewData?._trxId}
                  />
                  <CardField
                    title="Payer"
                    value={
                      previewData?._payer?.firstName +
                      ` ` +
                      previewData?._payer?.lastName
                    }
                  />

                  <CardField
                    title="Reference No"
                    value={previewData?.referenceNo}
                  />
                  <CardField
                    title="Description"
                    value={previewData?.description}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}
