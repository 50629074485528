import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadFeePaymentTemplate = async (
  search,
  loadedOptions,
  { page },
) => {
  try {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/payment-templates/list?search={"query": "${search}"}&page=${page}&offset=10`,
    )
    if (data) {
      const formatted = [
        {
          value: 0,
          label: `NONE`,
        },
      ].concat(data.paymentTemplates)

      return {
        options: formatted,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      const formattedWithError = [
        {
          value: 0,
          label: `NONE`,
        },
      ]
      return {
        options: formattedWithError,
        hasMore: false,
        additional: {
          page: page,
        },
      }
    }
  }
}
