import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const ValidateZip = async (code) => {
  const {
    data: { data },
  } = await AxiosInstance.post(`${AMS_BACKEND_API}/api/validate/zipCode`, {
    zipCode: code,
  }).then((res) => res)
  return data
}
