export const loadMailServices = async () => {
  return {
    options: [
      {
        value: `USPS_FIRST_CLASS`,
        label: `USPS FIRST CLASS`,
      },
      {
        value: `USPS_STANDARD`,
        label: `USPS STANDARD`,
      },
    ],
    hasMore: false,
  }
}
