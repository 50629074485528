import React from 'react'
import { brandLogo } from 'App/Assets/index'
import { PublicStyledButton } from './style'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { setPreferredLocation } from 'App/Redux/actions'
import { getNonce } from 'App/Services/Auth/getNonce'
import { IDP_FRONTEND } from 'App/Config'

export const Login = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const handleSignIn = () => {
    const nonceToken = getNonce(16, `aA0`)
    localStorage.setItem(`nonce`, nonceToken)
    window.location.assign(
      `${IDP_FRONTEND}?serviceId=${process.env.REACT_APP_OIDC_CLIENT_ID}&redirectUri=${process.env.REACT_APP_OIDC_REDIRECT_URI}&nonce=${nonceToken}`,
    )
    dispatch(
      setPreferredLocation(
        JSON.parse(localStorage.getItem(`preferredLocation`)),
      ),
    )
  }

  return (
    <div className="text-center">
      <img
        src={brandLogo}
        alt="Logo will go here"
        className="text-left w-60 h-36"
      />
      <PublicStyledButton
        onClick={() => {
          handleSignIn()
        }}
      >
        Sign in with Veronica
      </PublicStyledButton>
      <p className="text-gray-400 text-right text-sm mt-2">
        <span
          onClick={() => history.push(`/find-user`)}
          className="border-b-1 hover:border-blue-500 hover:text-blue-500 cursor-pointer"
        >
          Forgot Password
        </span>
      </p>
    </div>
  )
}
