export const loadRatingTypes = () => {
  const options = {
    options: [
      { value: `REALTIME`, label: `REALTIME` },
      { value: `MANUFACTURED`, label: `MANUFACTURED` },
      { value: `ALL`, label: `ALL` },
    ],
    hasMore: false,
  }
  return options
}
