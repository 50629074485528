import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CardField } from 'App/Components/Common/CardField'
import { Reload } from 'App/Components/Common/Reload'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { setCurrentCategory } from 'App/Redux/actions'
import dateFormat from 'App/Services/General/dateFormat'
import { Container, PageHeader } from 'App/Styled'
import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

export const AboutTicketCategory = () => {
  const {
    params: { ticketCategoryId },
  } = useRouteMatch()
  const dispatch = useDispatch()

  const {
    data: ticketCategoryPreviewData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `ticketCategoryPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/ticket-category/${ticketCategoryId}`,
      ).then((res) => {
        return res?.data?.data?.category
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentCategory(res))
      },
    },
  )

  return (
    <Container>
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <>
          <div className="flex flex-row m-3  ">
            <div className="flex-1">
              <div className="rounded-md overflow-hidden py-4">
                <div className="flex items-start justify-evenly">
                  <div className="flex-1 mx-2 rounded">
                    <PageHeader>Ticket Category Info</PageHeader>
                    <hr />

                    <div className="flex flex-col md:flex-row px-2">
                      <div className="flex-1">
                        <CardField
                          title="Category Name"
                          value={ticketCategoryPreviewData?.categoryName}
                        />
                        <CardField
                          title="Assign To"
                          value={ticketCategoryPreviewData?.users?.map(
                            (assignee) => {
                              return assignee.label
                            },
                          )}
                          multiple={true}
                        />
                      </div>
                      <div className="flex-1">
                        <CardField
                          title="Created At"
                          value={dateFormat(
                            ticketCategoryPreviewData?.createdAt,
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Container>
  )
}
