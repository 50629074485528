import React, { useEffect, useState, Fragment } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Toggle } from 'App/Components/Common/Toggle'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import { permissionGranted } from 'App/Services'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faChevronCircleDown,
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'

const tableColumns = [
  { label: `ID`, value: `_locationId` },
  { label: `Franchise`, value: `franchiseName` },
  { label: `Name`, value: `name` },
  { label: `Email`, value: `email` },
  { label: `Phone`, value: `phone` },
  { label: `Work Phone`, value: `workPhone` },
  { label: `Lobs`, value: `lobs` },
]

export const Locations = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  //  sorting and filtering states
  const [sort, setSort] = useState(``)
  const [collapseIndex, setCollapseIndex] = useState(``)
  const [active, setActive] = useState(false)
  const [isMasterFranchise, setIsMasterFranchise] = useState(true)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)

  //   getting all locations
  const {
    data: locationsResponse,
    isLoading,
    refetch: getAllLocations,
    isFetching,
    error,
  } = useQuery(
    `LocationsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/locations/all?search={"query": "${
          searchQuery.length ? searchQuery : ``
        }", "onlyMasterLocations": ${isMasterFranchise}}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    mutate: deleteLocation,
    isLoading: deletingLocation,
    isError: deletionError,
    // error: errorResponse,
  } = useMutation(
    async (ids) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/locations/${ids.lId}`,
      ).then((res) => {
        !res.hasError && getAllLocations()
        NotificationManager.success(`Deleted Successfully`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (!customSearching) {
      getAllLocations()
    }
  }, [isMasterFranchise, sort, currentPage, isOffset])

  const callLocationsQuery = useDebouncedCallback(() => {
    getAllLocations()
  }, 700)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteLocation(id)
  }

  const handleCollapse = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    if (collapseIndex !== id) {
      setCollapseIndex(id)
    } else {
      setCollapseIndex(``)
    }
  }
  return (
    <Container>
      <Spinner loading={deletingLocation || isLoading || isFetching} />
      {deletionError && (
        <ReactNotification
          action="error"
          message={`Operation Fail, Please delete agent first`}
        />
      )}
      <PageHeader>
        <div>Locations</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <Toggle
            value={!isMasterFranchise}
            setValue={() => {
              setCurrentPage(1)
              setIsMasterFranchise(!isMasterFranchise)
            }}
            disabled={isLoading || isFetching}
          />
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callLocationsQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `locations:create`,
          `corporate-manager:*`,
          `manager:*`,
        ]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <div
        className={` bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (deletingLocation || isLoading || isFetching) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllLocations()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse justify-center">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex ml-3">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>

                            {item.value != `lobs` ? (
                              <div
                                className="ml-2 px-1 rounded-sm cursor-pointer flex"
                                onClick={() => handleSorting(`-` + item.value)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    sort.includes(`-`) &&
                                    sort === `-` + item.value
                                      ? faSortUp
                                      : faSortDown
                                  }
                                  size="lg"
                                  className={`${
                                    sort.includes(`-`) &&
                                    sort === `-` + item.value
                                      ? `text-red-500`
                                      : `-mt-1.5`
                                  }`}
                                />
                              </div>
                            ) : (
                              ``
                            )}
                          </div>
                        </TableCell>
                      )
                    })}
                    {permissionGranted([
                      `locations:delete`,
                      `corporate-manager:*`,
                      `manager:*`,
                    ]) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!isLoading && !locationsResponse?.locations?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Location Found</div>
                      </td>
                    </tr>
                  ) : (
                    locationsResponse?.locations?.map((location, index) => (
                      <Fragment key={index}>
                        <TableRow
                          key={index}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => {
                            if (
                              permissionGranted([
                                `locations:read`,
                                `corporate-manager:*`,
                                `manager:*`,
                              ])
                            ) {
                              if (url.includes(`franchises`)) {
                                history.push(
                                  `${url}/${location._franchiseId}/location/${location._id}`,
                                )
                              } else {
                                history.push(`${url}/${location._id}`)
                              }
                            }
                          }}
                        >
                          <td className="px-4 py-3 capitalize truncate text-sm">
                            {location._locationId}
                          </td>
                          <td className="px-4 py-3 capitalize truncate text-sm">
                            {location.franchiseName ? (
                              location.franchiseName?.toLowerCase()
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-3 capitalize truncate text-sm">
                            {location.name ? (
                              location.name?.toLowerCase()
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-3 text-sm">
                            {location.email ? (
                              location.email
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-3 text-sm">
                            {location.phone ? (
                              location.phone
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-10 py-2 text-sm">
                            {location.workPhone ? (
                              location.workPhone
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-3">
                            {location?.lobs.length ? (
                              <div className="flex flex-row flex-wrap">
                                <Badge type="success">
                                  {location?.lobs[0]}
                                </Badge>
                              </div>
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          {/* Actions TD */}
                          {permissionGranted([
                            `locations:delete`,
                            `corporate-manager:*`,
                            `manager:*`,
                          ]) && (
                            <td className="px-6 py-3 z-0 text-sm flex float-right text-gray-400">
                              {location?.lobs?.length > 1 && (
                                <span data-tip="Expand" className="tooltip">
                                  <FontAwesomeIcon
                                    className={` transform transition duration-100 mx-1 hover:text-blue-500  hover:-translate-y-0 hover:scale-105  cursor-pointer  ${
                                      collapseIndex === index &&
                                      `rotate-180 text-blue-600`
                                    }`}
                                    icon={faChevronCircleDown}
                                    onClick={(e) => {
                                      setActive(!active)
                                      handleCollapse(index, e)
                                    }}
                                  />
                                </span>
                              )}
                              <span data-tip="Delete" className="tooltip">
                                <FontAwesomeIcon
                                  className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                  icon={faTrash}
                                  size="1x"
                                  onClick={(e) =>
                                    handleDelete(
                                      {
                                        fId: location._franchiseId,
                                        lId: location._id,
                                      },
                                      e,
                                    )
                                  }
                                />
                              </span>
                            </td>
                          )}
                        </TableRow>
                        {collapseIndex === index && (
                          <TableRow>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="px-4 py-0.5 text-sm z-0 max-w-sm">
                              {location.lobs &&
                                location.lobs?.map((lob, idx) => (
                                  <Badge
                                    key={idx}
                                    type="success"
                                    className="ml-1 mt-2"
                                  >
                                    {lob}
                                  </Badge>
                                ))}
                            </td>
                          </TableRow>
                        )}
                      </Fragment>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={locationsResponse?.totalPages}
                totalRecords={locationsResponse?.totalRecords}
                curerntPageRecords={locationsResponse?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
