import React, { useState, useEffect, useRef } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { StyledButton } from 'App/Styled'
import { loadCampaignTemplates, loadChannel } from 'App/Services/Broadcast'
import { AxiosInstance, AMS_BACKEND_API } from 'App/Config'
import { useMutation } from 'react-query'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { NotificationManager } from 'react-notifications'
import { Mail } from './MAIL'
// import { AQS_BACKEND_API } from 'App/Config'

export const StartCampaign = ({
  usersList,
  closeModal,
  source,
  count,
  isVisited,
  selectedChannel = () => {},
}) => {
  const [messageTemplate, setMessageTemplate] = useState({
    name: ``,
    channel: ``,
    templateList: ``,
  })
  const emailRef = useRef(null)
  const [mail] = useState(null)
  const [errors, setErrors] = useState({
    nameError: ``,
    channelError: ``,
    templateListError: ``,
    mailFormError: ``,
    formSubmit: false,
  })

  // const {
  //   mutate: addBroadCastingTemplate,
  //   isLoading: addingTemplate,
  //   isError: additionError,
  // } = useMutation(
  //   async (payload) =>
  //     await AxiosInstance.post(`${AMS_BACKEND_API}/api/templates/create`, {
  //       ...payload,
  //     }).then((res) => {
  //       if (!res.hasError) {
  //         refetchTemplates()
  //         closeModal()
  //       }
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //   },
  // )

  const ValidateForm = () => {
    if (!messageTemplate.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }

    if (!messageTemplate.channel?.value?.length) {
      handleErrors(`channelError`, `Select Channel`)
    } else {
      handleErrors(`channelError`, ``)
    }
    if (
      messageTemplate.channel &&
      !messageTemplate.templateList?.value?.length
    ) {
      handleErrors(`templateListError`, `Select Template`)
    } else {
      handleErrors(`templateListError`, ``)
    }

    if (messageTemplate.channel?.value === `MAIL`) {
      emailRef.current.validateForm()
      handleErrors(`mailFormError`, `mailformerror`)
    } else {
      handleErrors(`mailFormError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  const getMailValue = (mail) => {
    if (
      messageTemplate?.name?.length &&
      messageTemplate?.templateList?.value?.length
    ) {
      if (mail?.mailType?.value === `LETTER`) {
        campaignMutate(prepareLETTERPayload(mail))
      } else {
        campaignMutate(preparePOSTCARDPayload(mail))
      }
    }
  }

  useEffect(() => {
    setMessageTemplate((prevState) => ({
      ...prevState,
      [`templateList`]: ``,
    }))
  }, [messageTemplate.channel])

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors?.nameError?.length &&
      !errors?.channelError?.length &&
      !errors?.templateListError?.length
    ) {
      if (messageTemplate.channel?.value === `SMS`) {
        campaignMutate(prepareSMSPayload())
      }
      handleErrors(`formSubmit`, false)
    }
  }, [errors, mail])

  const preparePOSTCARDPayload = (mail) => {
    let data = {
      name: messageTemplate?.name,
      mailType: mail?.mailType?.value,
      colored: mail?.colorPrint,
      size: mail?.mailSize?.value?.toUpperCase(),
      mailService: mail?.mailService?.value,
      doubleSided: true,
      templateId: messageTemplate?.templateList?.value,
      template: {
        front: mail?.frontSideValue,
        back: mail?.backSideValue,
      },
    }

    if (source.toUpperCase() == `LEADS`) {
      data.isVisited = isVisited
    }
    if (usersList?.allSelected) {
      data.unselected = usersList.unselected
    } else {
      data.recipients = usersList?.selected
    }
    return data
  }

  const prepareLETTERPayload = (mail) => {
    let data = {
      name: messageTemplate?.name,
      mailType: mail?.mailType?.value,
      colored: mail?.colorPrint,
      mailService: mail?.mailService?.value,
      templateId: messageTemplate?.templateList?.value,
      template: {
        front: mail?.frontSideValue,
      },
    }
    if (source.toUpperCase() == `LEADS`) {
      data.isVisited = isVisited
    }
    if (usersList?.allSelected) {
      data.unselected = usersList.unselected
    } else {
      data.recipients = usersList?.selected
    }
    return data
  }

  const prepareSMSPayload = () => {
    let data = {
      name: messageTemplate?.name,
      templateId: messageTemplate?.templateList?.value,
    }

    if (source.toUpperCase() == `LEADS`) {
      data.isVisited = isVisited
    }

    if (usersList?.allSelected) {
      data.unselected = usersList.unselected
    } else {
      data.recipients = usersList?.selected
    }
    return data
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const {
    isLoading: addingCampaign,
    isError: capmaignStartError,
    mutate: campaignMutate,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/broadcasts/${messageTemplate?.channel?.value.toLowerCase()}${
          usersList?.allSelected ? `/all?count=${count}&` : `?`
        }scope=${source.toUpperCase()}`,
        {
          ...payload,
        },
      ).then((res) => {
        if (!res.hasError) {
          NotificationManager.success(`Campaign Started Successfully`)
          closeModal()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  return (
    <div
      className=" px-6 py-4"
      style={{
        width: `50vw`,
        minWidth: `40vw`,
      }}
    >
      {capmaignStartError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <div className="flex flex-col gap-2 mt-2 text-xs">
        <div className="flex flex-row gap-3">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name *"
              name="name"
              placeholder="Campaign Name"
              errorMessage={errors.nameError}
              value={messageTemplate.name}
              setValue={(e) =>
                setMessageTemplate((prevState) => ({
                  ...prevState,
                  [`name`]: e.target.value,
                }))
              }
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Select Channel"
              placeholder="Select Option *"
              value={messageTemplate.channel}
              setValue={(value) => {
                selectedChannel(value?.value)
                setMessageTemplate((prevState) => ({
                  ...prevState,
                  [`channel`]: value,
                }))
              }}
              errorMessage={errors.channelError}
              isOptionDisabled={true}
              loadOptions={loadChannel}
              isSearchable={false}
              isMulti={false}
            />
          </div>
        </div>

        <div className="flex-1 relative">
          <ReactSelectField
            title="Select Template"
            placeholder="Select Option"
            value={messageTemplate?.templateList}
            setValue={(value) => {
              setMessageTemplate((prevState) => ({
                ...prevState,
                [`templateList`]: value,
              }))
            }}
            errorMessage={errors.templateListError}
            loadOptions={loadCampaignTemplates}
            isSearchable={false}
            isMulti={false}
            cacheUniqs={messageTemplate?.channel}
          />
        </div>
      </div>
      {messageTemplate.channel?.value === `MAIL` && (
        <Mail ref={emailRef} getMailValue={getMailValue} />
      )}
      <div>
        <div className="flex justify-end items-center py-2">
          <StyledButton
            disabled={addingCampaign}
            onClick={() => {
              messageTemplate.channel?.value === `MAIL` &&
                emailRef.current.validateForm()

              ValidateForm()
            }}
          >
            <div className="flex  gap-2 text-white">
              <span>Start Campaign </span>
              {addingCampaign && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </StyledButton>
        </div>
      </div>
    </div>
  )
}
