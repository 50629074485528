import React from 'react'
import { useQuery } from 'react-query'
import { Reload } from 'App/Components/Common/Reload'
import { useRouteMatch } from 'react-router'
import { CardField } from 'App/Components/Common/CardField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Container, PageHeader, EditStyledButton } from 'App/Styled'

import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { useDispatch } from 'react-redux'
import { setCurrentCarDealer } from 'App/Redux/actions'
import { useHistory } from 'react-router-dom'
import { permissionGranted } from 'App/Services'
import dateFormat from 'App/Services/General/dateFormat'

export const AboutCarDealer = () => {
  const {
    url,
    params: { dealerId },
  } = useRouteMatch()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    data: carDealer,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `CarDealerPreviewApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/car-dealers/${dealerId}`,
      ).then((res) => res?.data?.data?.carDealer),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentCarDealer(res))
      },
    },
  )
  return (
    <Container>
      <PageHeader>
        <div>Car Dealer</div>
        {permissionGranted([`car-dealer:update`]) &&
          (!isFetching || !isLoading) && (
            <EditStyledButton onClick={() => history.push(`${url}/update`)}>
              <span>Edit</span>
              <FontAwesomeIcon
                icon={faPen}
                className="text-white mt-0.5"
                size="sm"
              />
            </EditStyledButton>
          )}
      </PageHeader>
      {isLoading || isFetching ? (
        <div className="text-center p-6">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="1x"
            className="text-red-500"
          />
        </div>
      ) : error ? (
        <Reload refetch={() => refetch()} />
      ) : (
        <div className="p-2">
          <div className="flex items-start gap-2 justify-evenly pb-2">
            <div className="flex-1 rounded">
              <div className="grid grid-cols-12 px-4 py-2">
                <div className="col-span-6 text-xl">Dealer Info</div>
              </div>
              <hr />
              <div className="px-4">
                <CardField
                  title="Business Name"
                  value={carDealer?.businessName}
                />
                <CardField
                  title="Email"
                  value={carDealer?.contactInfo?.email}
                />
                <CardField title="Fax" value={carDealer?.contactInfo?.fax} />
                <CardField
                  title="Phone"
                  value={carDealer?.contactInfo?.phone}
                />
                <CardField
                  title="commission Rate"
                  value={carDealer?.commission?.rate}
                />
                <CardField
                  title="commission Type"
                  value={
                    carDealer?.commission?.type === `FLAT_RATE`
                      ? `Flat`
                      : carDealer?.commission?.type === `PERCENTAGE`
                      ? `%`
                      : ``
                  }
                />

                <CardField
                  title="Created At"
                  value={dateFormat(carDealer.createdAt)}
                />
              </div>
            </div>
            <div className="flex-1 rounded">
              <div className="grid grid-cols-12 px-4 py-2">
                <div className="col-span-6 text-xl">Address</div>
              </div>
              <hr />
              <div className="px-4">
                <CardField title="City" value={carDealer?.address?.city} />
                <CardField title="State" value={carDealer?.address?.state} />
                <CardField title="Street" value={carDealer?.address?.street} />
                <CardField title="Unit No" value={carDealer?.address?.unitNo} />
                <CardField title="Zip" value={carDealer?.address?.zip} />
              </div>
            </div>
          </div>
          <div className="flex-1  rounded">
            <div className="grid grid-cols-12 px-4 py-2">
              <div className="col-span-6 text-xl">Contact Person</div>
            </div>
            <hr />
            <div className="flex px-4">
              <div className="flex-1">
                <CardField
                  title="First Name"
                  value={carDealer?.contactPerson?.firstName}
                />
                <CardField
                  title="Middle Name"
                  value={carDealer?.contactPerson?.middleName}
                />
                <CardField
                  title="Last Name"
                  value={carDealer?.contactPerson?.lastName}
                />
                <CardField
                  title="Last Name"
                  value={carDealer?.contactPerson?.gender}
                />
              </div>
              <div className="flex-1">
                <CardField
                  title="Fax"
                  value={carDealer?.contactPerson?.contactInfo?.fax}
                />
                <CardField
                  title="Phone"
                  value={carDealer?.contactPerson?.contactInfo?.phone}
                />
                <CardField
                  title="Email"
                  value={carDealer?.contactPerson?.contactInfo?.email}
                />
                <CardField
                  title="Created At"
                  value={dateFormat(carDealer.createdAt)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}
