import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeTab: 1,
  activeActivityTab: 1,
  activeCommunicationTab: 1,
  activeCall: false,
  videoActive: false,
  currentCustomer: ``,
  messageActive: false,
  globalCallModalActive: false,
  outGoingCall: ``,
}

const customerSlice = createSlice({
  name: `customer`,

  initialState,
  reducers: {
    setActiveCustomerTab: (state, { payload }) => {
      state.activeTab = payload
    },
    setActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
    setCurrentCustomer: (state, { payload }) => {
      state.currentCustomer = payload
    },
    setMessageActive: (state, { payload }) => {
      state.messageActive = payload
    },
    setActiveCommunicationsTab: (state, { payload }) => {
      state.activeCommunicationTab = payload
    },
    setActiveCall: (state, { payload }) => {
      state.activeCall = payload
    },
    setVideoActive: (state, { payload }) => {
      state.videoActive = payload
    },
    setGlobalCallModalActive: (state, { payload }) => {
      state.globalCallModalActive = payload
    },
    setOutGoingCall: (state, { payload }) => {
      state.outGoingCall = payload
    },
  },
})

export const {
  setActiveCustomerTab,
  setCurrentCustomer,
  setMessageActive,
  setActivityActiveTab,
  setActiveCommunicationsTab,
  setActiveCall,
  setVideoActive,
  setGlobalCallModalActive,
  setOutGoingCall,
} = customerSlice.actions

export const customer = customerSlice.reducer
