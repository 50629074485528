import {
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TableBody, TableCell, TableHeader, TableRow } from '@windmill/react-ui'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Reload } from 'App/Components/Common/Reload'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { Spinner } from 'App/Components/Common/Spinner'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { loadOffset } from 'App/Services/General/loadOffset'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce/lib'
import { ReactModal } from 'App/Components/Common/Modal'
import { AddTeam } from '../addTeam'
import { UpdateTeam } from '../updateTeam'
import { useSelector } from 'react-redux'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'
const tableColumns = [
  { label: `Team Name`, value: `teamName` },
  { label: `Location`, value: `location.label` },
  { label: `Created By`, value: `teamCreator.label` },
  { label: `Created At`, value: `createdAt` },
]

export const AllTeams = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [updateTeam, setUpdateTeam] = useState(false)
  const [searchQuery, setSearchQuery] = useState(``)
  const [teamUpdated, setTeamUpdated] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [currentTeam, setCurrentTeam] = useState(``)
  const { sub: currentUser } = useSelector(({ user }) => user?.user?.profile)
  const {
    isLoading,
    data: teamsData,
    refetch: getAllTeams,
    isFetching,
    error,
  } = useQuery(
    `allTeamsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/assessment-team?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort.length > 0 ? sort : ``}"&page=1&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        setCustomSearching(false)
        if (res) {
          setTeamUpdated(false)
        }
      },
    },
  )

  const {
    mutate: deleteTeam,
    isLoading: deletingTeam,
    isError: deletionTeamError,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/assessment-team/${id}`,
      ).then((res) => {
        NotificationManager.success(`Deleted Successfully`)
        res.data && getAllTeams()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  useEffect(() => {
    if (!customSearching) {
      getAllTeams()
    }
  }, [sort, currentPage, isOffset])
  useEffect(() => {
    if (teamUpdated) {
      getAllTeams()
    }
  }, [teamUpdated])

  const callTeamQuery = useDebouncedCallback(() => {
    getAllTeams()
  }, 700)
  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteTeam(id)
  }

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  return (
    <Container>
      {deletionTeamError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading || isFetching || deletingTeam} />
      {addModal && (
        <ReactModal
          title="Add New Team"
          closeModal={() => setAddModal(!addModal)}
        >
          <AddTeam
            closeModal={() => setAddModal(!addModal)}
            teamUpdated={() => setTeamUpdated(true)}
          />
        </ReactModal>
      )}

      {/* update team */}
      {updateTeam && (
        <ReactModal title="Update Team" closeModal={() => setUpdateTeam(false)}>
          <UpdateTeam
            teamId={currentTeam}
            closeModal={() => setUpdateTeam(false)}
            teamUpdated={() => setTeamUpdated(true)}
          />
        </ReactModal>
      )}
      {/* update team ends here */}
      <PageHeader bgcolor="bg-white">
        <div>Assessment Teams</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          {/* <Toggle value={isMasterFrenchise} setValue={setIsMasterFrenchise} /> */}
          <div className="text-end ">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callTeamQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>
      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `assessment-team:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton onClick={() => setAddModal(!addModal)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${
          (isLoading || isFetching || deletingTeam) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllTeams()} />
        ) : (
          <>
            <div className="overflow-x-auto rounded-t-md mt-1">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow className="hover:bg-gray-100 cursor-pointer">
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className=" cursor-pointer select-none text-sm flex">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) && sort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort === `-` + item.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(permissionGranted([
                      `assessment-team:create`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) ||
                      permissionGranted([
                        `assessment-team:delete`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ])) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {!isLoading && !teamsData?.teams?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        No Team Found
                      </td>
                    </tr>
                  ) : (
                    teamsData?.teams?.map((team, index) => (
                      <TableRow
                        key={index}
                        className="hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          if (
                            permissionGranted([
                              `assessment-team:update`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ])
                          ) {
                            team?._creator === currentUser &&
                              setUpdateTeam(true)
                            setCurrentTeam(team?._id)
                          }
                        }}
                      >
                        <td className="px-4 py-0.5 cursor-pointer capitalize truncate text-sm">
                          {team?.teamName}
                        </td>
                        <td className="px-4 py-0.5 cursor-pointer capitalize truncate text-sm">
                          {team?.location?.label}
                        </td>
                        <td className="px-4 py-0.5 cursor-pointer capitalize truncate text-sm">
                          {team?.teamCreator?.label}
                        </td>
                        <td className="px-4 py-0.5 cursor-pointer capitalize truncate text-sm">
                          {dateFormat(team?.createdAt)}
                        </td>
                        {/* Actions TD */}
                        <td className="px-6 py-2 text-sm z-0 flex float-right text-gray-400">
                          {permissionGranted([
                            `assessment-team:update`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ]) && (
                            <span
                              data-tip={
                                currentUser === team?._creator
                                  ? `Update`
                                  : `Not Allow`
                              }
                              className="tooltip"
                            >
                              <FontAwesomeIcon
                                className={` mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  ${
                                  currentUser === team?._creator
                                    ? `cursor-pointer`
                                    : `cursor-not-allowed`
                                } `}
                                icon={faEdit}
                              />
                            </span>
                          )}
                          {permissionGranted([
                            `assessment-team:delete`,
                            `corporate-manager:*`,
                            `manager:*`,
                            `admin:*`,
                          ]) && (
                            <span
                              data-tip={
                                currentUser === team?._creator
                                  ? `Delete`
                                  : `Not Allow`
                              }
                              className="tooltip"
                            >
                              <FontAwesomeIcon
                                className={`mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105  ${
                                  currentUser === team?._creator
                                    ? `cursor-pointer`
                                    : `cursor-not-allowed`
                                }`}
                                icon={faTrash}
                                size="1x"
                                onClick={(e) =>
                                  team?._creator === currentUser &&
                                  handleDelete(team?._id, e)
                                }
                              />
                            </span>
                          )}
                        </td>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={teamsData?.totalPages}
                totalRecords={teamsData?.totalRecords}
                curerntPageRecords={teamsData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
