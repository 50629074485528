import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { setCarrierActivityActiveTab } from 'App/Redux/actions'
import { TOne, TTwo } from 'App/Styled'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CarrierNote } from '../../CarrierNote'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { permissionGranted } from 'App/Services'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  {
    name: `Logs`,
    number: 2,
  },
]
const activityTabs2 = [
  {
    name: `Logs`,
    number: 1,
  },
]

export const CarrierActivity = () => {
  const { currentCarrier, activeActivityTab } = useSelector(
    ({ carrier }) => carrier,
  )
  const { url } = useRouteMatch()
  const isImportedCarrier = url.includes(`/imported-carrier`)
  const dispatch = useDispatch()

  return (
    <div className="px-2">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([
            `notes:read`,
            `corporate-manager:*`,
            `manager:*`,
            `admin:*`,
          ]) && !isImportedCarrier
            ? activityTabs
            : activityTabs2
        }
        getActiveTab={(val) => dispatch(setCarrierActivityActiveTab(val))}
      />
      {permissionGranted([
        `notes:read`,
        `corporate-manager:*`,
        `manager:*`,
        `admin:*`,
      ]) && !isImportedCarrier ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <CarrierNote />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <UpdateLogs
              logs={
                currentCarrier?.updateLogs ? currentCarrier?.updateLogs : []
              }
            />
          </TTwo>
        </div>
      ) : (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <UpdateLogs
              logs={
                currentCarrier?.updateLogs ? currentCarrier?.updateLogs : []
              }
            />
          </TOne>
        </div>
      )}
    </div>
  )
}
