import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Container } from 'App/Styled'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
)

const options = {
  indexAxis: `y`,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
}

export const EarningProgressByLocation = ({ earningProgress }) => {
  const [payment, setPayment] = useState({
    labels: [],
    datasets: [
      {
        payment: [],
      },
    ],
    borderWidth: 1,
  })
  const [selectLocation, setSelectLocation] = useState([])

  useEffect(() => {
    const earningInfo = {
      labels: [`Earning`],
      datasets: selectLocation.length
        ? selectLocation?.map((item) => {
          return {
            label: item?.label,
            data: [item?.earn],
            backgroundColor:
              `#` + Math.floor(Math.random() * 16777215).toString(16),
          }
        })
        : earningProgress?.map((item) => {
          return {
            label: item?.locations?.name,
            data: [item?.locations?.earningsFromCommission],
            backgroundColor:
              `#` + Math.floor(Math.random() * 16777215).toString(16),
          }
        }),
    }

    setPayment(earningInfo)
  }, [earningProgress, selectLocation])

  const option = earningProgress?.map((item) => ({
    value: item.locationName,
    earn: item.earnings,
    label: item.locationName,
  }))
  const loadLocation = (search) => {
    const options = {
      options: search ? filterLocation(search) : option,
      hasMore: false,
    }

    return options
  }

  const filterLocation = (search) => {
    return (
      option
        //some labels are null that is why we used double filters
        .filter((item) => item.label !== null)
        .filter((item) => {
          return item.label.toLowerCase().includes(search.toLowerCase())
        })
    )
  }
  return (
    <Container className="relative">
      <div className="flex py-9 h-1/4 relative ">
        <div className="font-bold absolute left-4 2xl:left-6 top-5 ">
          <span className="font-bold text-md">Location's By Earning</span>
        </div>
        <div className="w-1/2 absolute right-4 top-4 z-0">
          <ReactSelectField
            placeholder="Select Location"
            value={selectLocation}
            setValue={(e) => setSelectLocation(e)}
            isMulti={true}
            loadOptions={loadLocation}
            isSearchable={true}
            isScrollable={true}
          />
        </div>
      </div>

      <div className="h-3/4">
        <div className="h-280 m-6">
          {payment.labels !== undefined ? (
            <Bar data={payment} options={options} />
          ) : null}
        </div>
      </div>
    </Container>
  )
}
