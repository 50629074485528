import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API } from 'App/Config/backendApis'
import { AxiosInstance, allowedSystemPermissions } from 'App/Config'
import { useHistory } from 'react-router-dom'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { loadAgents, loadAllCarriers } from 'App/Services'
import { NotificationManager } from 'react-notifications'
import { permissionGranted } from 'App/Services'
import { useDebouncedCallback } from 'use-debounce'

export const AddCredential = () => {
  const history = useHistory()
  // const user = useSelector(({ user: { user } }) => user)
  const [credentials, setCredentials] = useState({
    carrierId: ``,
    name: ``,
    location: ``,
    producerCode: ``,
    userName: ``,
    password: ``,
    url: `https://`,
    comments: ``,
    franchises: ``,
    users: ``,
  })
  const [credentialNameValidating, setCredentialNameValidating] =
    useState(false)

  const [errors, setErrors] = useState({
    formSubmit: false,
    carrierIdError: ``,
    nameError: ``,
    locationError: ``,
    userNameError: ``,
    passwordError: ``,
    urlError: ``,
    producerCodeError: ``,
  })

  const loadFranchises = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/list/?search=${
        search && search
      }&page=${page}&offset=10&state=${credentials.carrierId.state}`,
    )
    if (data) {
      return {
        options: data.franchises,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/credentials`, {
        ...payload,
      }).then((res) => {
        if (res.data) {
          NotificationManager.success(`Operation successful`)
          res.data && history.push(`/admin/credentials`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const ValidateForm = () => {
    if (!credentials?.carrierId?.value?.length) {
      handleErrors(`carrierIdError`, `Enter Option`)
    } else {
      handleErrors(`carrierIdError`, ``)
    }

    if (!credentials.name?.length) {
      handleErrors(`nameError`, `Select Name`)
    } else {
      if (errors.nameError !== `Name Already Exists`) {
        handleErrors(`nameError`, ``)
      }
    }

    if (!credentials?.location?.value?.length) {
      handleErrors(`locationError`, `Enter Location`)
    } else {
      handleErrors(`locationError`, ``)
    }

    if (!credentials?.userName?.length) {
      handleErrors(`userNameError`, `Enter Name`)
    } else if (credentials?.userName?.length < 3) {
      handleErrors(`userNameError`, `Name can't be less than 3 characters`)
    } else {
      handleErrors(`userNameError`, ``)
    }

    if (!credentials?.password?.length) {
      handleErrors(`passwordError`, `Enter Password`)
    } else {
      handleErrors(`passwordError`, ``)
    }
    if (!credentials?.producerCode?.length) {
      handleErrors(`producerCodeError`, `Enter Producer Code`)
    } else {
      handleErrors(`producerCodeError`, ``)
    }

    if (credentials?.url === `https://` || !credentials?.url?.length) {
      handleErrors(`urlError`, `Enter Url`)
    } else {
      handleErrors(`urlError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  const validateCredentialName = useDebouncedCallback(async (value) => {
    setCredentialNameValidating(true)
    const response = await ValidateDuplicateCredentialName({ name: value })

    if (response?.hasError) {
      handleErrors(`nameError`, `Name Already Exists`)
    } else {
      handleErrors(`nameError`, ``)
    }
    setCredentialNameValidating(false)
  }, 2500)

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.carrierIdError.length &&
      !errors.userNameError.length &&
      !errors.producerCodeError.length &&
      !errors.passwordError.length &&
      !errors.nameError.length &&
      !errors.urlError.length
    ) {
      mutate(preparePayload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const preparePayload = () => {
    let data = {}

    if (credentials.carrierId?.value?.length) {
      data._carrierId = credentials.carrierId.value
    }

    if (credentials.location?.value?.length) {
      data._locationId = credentials.location.value
    }
    if (credentials?.producerCode?.length) {
      data.producerCode = credentials?.producerCode
    }
    if (credentials.userName.length) {
      data.userName = credentials.userName
    }

    if (credentials.password.length) {
      data.password = credentials.password
    }
    if (credentials.name.length) {
      data.name = credentials.name
    }

    if (credentials.url.length) {
      data.url = credentials.url
    }

    if (credentials.comments.length) {
      data.comments = credentials.comments
    }

    if (credentials.users.length) {
      data.users = credentials.users.map((user) => user.value)
    }

    if (credentials?.franchises?.value?.length) {
      data.franchises = [credentials.franchises.value]
    }

    return data
  }

  const [isLoadingName, setIsLoadingName] = useState(false)

  const ValidateDuplicateCredentialName = async (payload) => {
    setIsLoadingName(true)

    try {
      const res = await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/validate/credential-payloads`,
        payload,
      )
      setIsLoadingName(false)
      return res?.data
    } catch (error) {
      setIsLoadingName(false)
      return error.response.data
    }
  }

  return (
    <Container>
      <PageHeader padding="true">Add Credentials</PageHeader>
      {updationError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div
        className={`px-4 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        {/* Locations basic form */}
        <div className="flex gap-6 my-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Carrier *"
              placeholder="Select Carrier"
              errorMessage={errors.carrierIdError}
              value={credentials.carrierId}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  carrierId: e,
                }))
              }}
              isMulti={false}
              loadOptions={loadAllCarriers}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Producer Code *"
              placeholder="Enter value"
              value={credentials.producerCode}
              errorMessage={errors.producerCodeError}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  producerCode: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="User Name *"
              placeholder="Enter Name"
              value={credentials.userName}
              errorMessage={errors.userNameError}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  userName: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="password"
              title="Password *"
              placeholder="Enter Password"
              value={credentials.password}
              errorMessage={errors.passwordError}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  password: e.target.value,
                }))
              }}
            />
          </div>
        </div>

        <div className="flex gap-6 my-1">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Credential Name *"
              placeholder="Enter Credential Name"
              value={credentials.name}
              errorMessage={errors.nameError}
              validating={credentialNameValidating}
              setValue={(e) => {
                e.target.value && validateCredentialName(e.target.value)
                setCredentials((preState) => ({
                  ...preState,
                  name: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Url *"
              placeholder="Enter Url"
              value={credentials.url}
              errorMessage={errors.urlError}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  url: e.target.value,
                }))
              }}
            />
          </div>

          <div className="flex-1 relative"></div>
          <div className="flex-1 relative"></div>
          <div className="flex-1 relative"></div>
        </div>
        <div className="flex gap-6 my-1">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Comments"
              placeholder="Enter Comments"
              value={credentials.comments}
              setValue={(e) => {
                setCredentials((preState) => ({
                  ...preState,
                  comments: e.target.value,
                }))
              }}
            />
          </div>
        </div>
        <div className="flex gap-6 my-1">
          {permissionGranted([`super-admin:*`, `credential-master:*`]) && (
            <>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Franchises"
                  placeholder="Select Franchises"
                  value={credentials.franchises}
                  setValue={(value) => {
                    setCredentials((preState) => ({
                      ...preState,
                      franchises: value,
                    }))
                  }}
                  loadOptions={loadFranchises}
                  cacheUniqs={credentials.carrierId.state}
                  isSearchable={true}
                  disabled={!credentials.carrierId.state}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Users"
                  placeholder="Select Users"
                  value={credentials.users}
                  setValue={(value) => {
                    setCredentials((preState) => ({
                      ...preState,
                      users: value,
                    }))
                  }}
                  isMulti={true}
                  loadOptions={loadAgents}
                  isSearchable={true}
                  additionalFilters={{
                    franchiseId: credentials.franchises.value,
                    ...(!permissionGranted([
                      `corporate-manager`,
                      `credential-master:*`,
                    ]) && {
                      locations: [_locations?._id],
                    }),

                    permissions: allowedSystemPermissions?.credential,
                  }}
                  disabled={!credentials?.franchises}
                  cacheUniqs={credentials?.franchises}
                />
              </div>
            </>
          )}
        </div>
        {/* Location Address starts from here */}
        <div className="flex justify-center">
          <div className="text-center w-96 my-5">
            <SubmitButton onClick={ValidateForm} disabled={isLoadingName}>
              Add Credential
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
