import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API } from 'App/Config/backendApis'
import { AxiosInstance } from 'App/Config'
import { useHistory } from 'react-router-dom'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { TextArea } from 'App/Components/Common/TextArea'
import { NotificationManager } from 'react-notifications'
import { useDebouncedCallback } from 'use-debounce'
import { loadCredentials } from 'App/Services'
const AddGroupCredential = () => {
  const history = useHistory()
  const [credential, setCredential] = useState({
    name: ``,
    credentials: ``,
    description: ``,
  })
  const [credentialNameValidating, setCredentialNameValidating] =
    useState(false)

  const [errors, setErrors] = useState({
    formSubmit: false,
    nameError: ``,
    credentialsError: ``,
    descriptionError: ``,
  })

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/credentials-groups`, {
        ...payload,
      }).then((res) => {
        if (res.data) {
          NotificationManager.success(`Created Successfully`)
          res.data && history.push(`/admin/group-credentials`)
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const validateForm = () => {
    if (!credential.name?.length) {
      handleErrors(`nameError`, `Select Name`)
    } else {
      if (errors.nameError !== `Name Already Exists`) {
        handleErrors(`nameError`, ``)
      }
    }

    if (!credential.credentials?.length) {
      handleErrors(`credentialsError`, `Select Credential`)
    } else {
      handleErrors(`credentialsError`, ``)
    }

    if (!credential?.description?.length) {
      handleErrors(`descriptionError`, `Enter Description`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.credentialsError.length &&
      !errors.descriptionError.length
    ) {
      mutate(preparePayload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const preparePayload = () => {
    let data = {}
    const formattedCredential = credential?.credentials?.map((credential) => {
      return credential.value
    })

    if (credential.name?.length) {
      data.name = credential.name
    }

    if (credential?.credentials?.length) {
      data.credentials = formattedCredential
    }

    if (credential.description.length) {
      data.description = credential.description
    }

    return data
  }

  const ValidateDuplicateCredentialName = async (payload) => {
    return await AxiosInstance.post(
      `${AMS_BACKEND_API}/api/validate/credential-group-payloads`,
      payload,
    )
      .then((res) => {
        return res?.data
      })
      .catch((error) => {
        return error.response.data
      })
  }

  const validateCredentialName = useDebouncedCallback(async (value) => {
    if (value) {
      setCredentialNameValidating(true)
      const response = await ValidateDuplicateCredentialName({ name: value })

      if (response?.hasError) {
        handleErrors(`formSubmit`, false)
        handleErrors(`nameError`, `Name Already Exists`)
      } else {
        handleErrors(`formSubmit`, false)
        handleErrors(`nameError`, ``)
      }
      setCredentialNameValidating(false)
    }
  }, 2500)

  return (
    <Container>
      <PageHeader padding="true">Add Group</PageHeader>

      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div
        className={`px-4 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        {/* titles basic form */}
        <div className="flex gap-6 my-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name *"
              placeholder="Enter Name"
              value={credential.name}
              errorMessage={errors.nameError}
              validating={credentialNameValidating}
              setValue={(e) => {
                validateCredentialName(e.target.value)
                setCredential((preState) => ({
                  ...preState,
                  name: e.target.value,
                }))
              }}
              maxlength={`100`}
            />
          </div>
        </div>
        <div className="flex gap-6 my-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Credentials *"
              placeholder="Select Credentials"
              value={credential.credentials}
              errorMessage={errors.credentialsError}
              setValue={(value) => {
                setCredential((preState) => ({
                  ...preState,
                  credentials: value,
                }))
              }}
              isMulti={true}
              loadOptions={loadCredentials}
              isSearchable={true}
            />
          </div>
        </div>
        <div className="flex gap-6 my-1 relative">
          <div className="flex-1 ">
            <TextArea
              type="textarea"
              title="Description *"
              placeholder="Enter description"
              autoFocus={false}
              value={credential?.description}
              errorMessage={errors?.descriptionError}
              setValue={(e) => {
                setCredential((preState) => ({
                  ...preState,
                  description: e.target.value,
                }))
              }}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="text-center w-96 my-5">
            <SubmitButton
              onClick={validateForm}
              disabled={credentialNameValidating}
            >
              Create
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}
export default AddGroupCredential
