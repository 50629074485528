import React, { Fragment, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory, useRouteMatch } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, PageHeader, StyledButton } from 'App/Styled'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Reload } from 'App/Components/Common/Reload'
import { AxiosInstance, offset } from 'App/Config'
import { AMS_BACKEND_API } from 'App/Config'
import { permissionGranted } from 'App/Services'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faSortUp,
  faSortDown,
  faPlusCircle,
  faTrash,
  faEdit,
} from '@fortawesome/free-solid-svg-icons'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { NotificationManager } from 'react-notifications'
import dateFormat from 'App/Services/General/dateFormat'
import { UpdateScrapingCredentials } from '../UpdateCredentials'
import { ReactModal } from 'App/Components/Common/Modal'
import CredentialFilters from '../../CredentialFilters'

const tableColumns = [
  { label: `ID`, value: `_groupId` },
  { label: `Name`, value: `name` },
  { label: `Producer Code`, value: `producerCode` },
  { label: `User Name`, value: `userName` },
  { label: `Password`, value: `password` },
  { label: `Created`, value: `createdAt` },
]

export const AllScrapingCredentials = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [updateCredentialsModal, setUpdateCredentialsModal] = useState(false)
  const [selectedCredential, setSelectedCredential] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [sort, setSort] = useState(``)

  const history = useHistory()
  const { url } = useRouteMatch()
  const [scrapperFilters, setScrapperFilters] = useState({
    carrier: ``,
    userName: ``,
    producerCode: ``,
  })
  // fetching all group carrier
  const {
    isLoading,
    data: credentialData,
    error,
    refetch: getAllCredential,
    isFetching,
  } = useQuery(
    `ScarpingCredentialApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/scraping-credentials/all?search=${searchFilters()}&sort="${
          sort?.length > 0 ? sort : ``
        }"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )
  const {
    mutate: deleteCredential,
    isLoading: deletingCredential,
    // isError: deletionError,
    error: reponseError,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/scraping-credentials/${id}`,
      ).then((res) => {
        NotificationManager.success(`Deleted Successfuly`)
        res.data && getAllCredential()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: (err) => {
        if (
          err?.response?.data?.message.includes(
            `Requested operation failed. Can't delete Group`,
          )
        )
          NotificationManager.error(
            `Operation Fail, Group Credential is currently in use`,
          )
        else
          NotificationManager.error(reponseError?.response?.data?.error?.error)
      },
    },
  )

  useEffect(() => {
    if (!customSearching) {
      getAllCredential()
    }
  }, [sort, currentPage, isOffset, scrapperFilters])

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  const handleUpdate = (credential) => {
    setUpdateCredentialsModal(true)
    setSelectedCredential(credential)
  }

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    {
      deleteCredential(id)
    }
  }

  const handleResetFilters = () => {
    setScrapperFilters({
      carrier: ``,
      userName: ``,
      producerCode: ``,
    })
  }

  const handleFiltersChange = (filters) => {
    setCurrentPage(1)
    setScrapperFilters(filters)
  }

  const searchFilters = () => {
    let data = {
      query: ``,
      filters: {},
    }

    if (scrapperFilters?.carrier?.length) {
      data.filters.carrier = scrapperFilters?.carrier
    }
    if (scrapperFilters?.userName?.length) {
      data.filters.userName = scrapperFilters?.userName
    }

    if (scrapperFilters?.producerCode?.length) {
      data.filters.producerCode = scrapperFilters?.producerCode
    }

    return JSON.stringify(data)
  }

  return (
    <Container>
      <Spinner loading={isLoading || isFetching || deletingCredential} />
      <PageHeader>
        <div>Scraping Credentials</div>
        <div className="flex items-center">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
        </div>
      </PageHeader>
      <CredentialFilters
        filtersProps={scrapperFilters}
        handleFilters={handleFiltersChange}
        resetFilters={handleResetFilters}
        isLoading={isLoading || isFetching}
        isScrapper
      />
      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `scraping-credentials:create`,
          `scraping-credentials:*`,
        ]) && (
          <StyledButton onClick={() => history.push(`${url}/add`)}>
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 pt-1 ${
          (isLoading || isFetching || deletingCredential) &&
          `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllCredential()} />
        ) : (
          <>
            <div className="overflow-x-auto  rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            {item.label === `Credentials` ? (
                              ``
                            ) : (
                              <div
                                className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                                onClick={() => handleSorting(`-` + item.value)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    sort.includes(`-`) &&
                                    sort == `-` + item.value
                                      ? faSortUp
                                      : faSortDown
                                  }
                                  size="lg"
                                  className={`${
                                    sort.includes(`-`) &&
                                    sort == `-` + item.value
                                      ? `text-red-500`
                                      : `-mt-1.5`
                                  }`}
                                />
                              </div>
                            )}
                          </div>
                        </TableCell>
                      )
                    })}
                    {permissionGranted([
                      `scraping-credentials:update`,
                      `scraping-credentials:delete`,
                      `scraping-credentials:*`,
                    ]) && (
                      <TableCell className="text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {credentialData?.credentials?.length != 0 ? (
                    credentialData?.credentials?.map((credential, index) => (
                      <Fragment key={index}>
                        <TableRow
                          key={index.toString()}
                          className="hover:bg-gray-100 cursor-pointer"
                        >
                          <td className="px-4 py-0.5 text-sm z-0 capitalize truncate">
                            {credential?._groupId ? (
                              credential?._groupId
                            ) : (
                              <Badge>{index + 1}</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm z-0 capitalize truncate">
                            {credential?.carrierName ? (
                              credential?.carrierName
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm z-0 capitalize truncate">
                            {credential?.producerCode ? (
                              credential?.producerCode
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm z-0 capitalize truncate">
                            {credential?.userName ? (
                              credential?.userName
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>
                          <td className="px-4 py-0.5 text-sm z-0 capitalize truncate">
                            {credential?.password ? (
                              credential?.password
                            ) : (
                              <Badge>N/A</Badge>
                            )}
                          </td>

                          <td className="px-4 py-0.5 z-0 text-sm">
                            <span>{dateFormat(credential?.createdAt)}</span>
                          </td>
                          {/* Actions TD */}

                          <td className="px-6 py-3 z-0 text-sm flex float-right text-gray-400">
                            {permissionGranted([
                              `scraping-credentials:update`,
                              `scraping-credentials:*`,
                            ]) && (
                              <span data-tip="Update" className="tooltip">
                                <FontAwesomeIcon
                                  className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                  icon={faEdit}
                                  size="1x"
                                  onClick={() => handleUpdate(credential)}
                                />
                              </span>
                            )}
                            {permissionGranted([
                              `scraping-credentials:delete`,
                              `scraping-credentials:*`,
                            ]) && (
                              <span data-tip="Delete" className="tooltip">
                                <FontAwesomeIcon
                                  className="mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                                  icon={faTrash}
                                  size="1x"
                                  onClick={(e) =>
                                    handleDelete(credential._id, e)
                                  }
                                />
                              </span>
                            )}
                          </td>
                        </TableRow>
                      </Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Data Found</div>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </table>
            </div>

            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={credentialData?.groups?.totalPages}
                totalRecords={credentialData?.groups?.totalRecords}
                curerntPageRecords={credentialData?.groups?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
        {updateCredentialsModal && (
          <ReactModal
            title="Update"
            closeModal={() => setUpdateCredentialsModal(false)}
          >
            <UpdateScrapingCredentials
              selectedCredentials={selectedCredential}
              closeModal={() => {
                setUpdateCredentialsModal(false)
                getAllCredential()
              }}
            />
          </ReactModal>
        )}
      </div>
    </Container>
  )
}
