import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { AQS_BACKEND_API, AxiosInstance } from 'App/Config'
import { loadStates } from 'App/Services'
import { Container, PageHeader } from 'App/Styled'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { SubmitButton } from 'App/Styled'
export const UpdateRatingTemplate = () => {
  const [ratingTemplate, setRatingTemplate] = useState({
    state: ``,
    templateName: ``,
    description: ``,
  })
  const [errors, setErrors] = useState({
    stateError: ``,
    templateNameError: ``,
    descriptionError: ``,
  })

  const [policyScope, setPolicyScope] = useState({})
  const [vehicleScope, setVehicleScope] = useState({})

  const { data: coveragesData, refetch: getAllCoverages } = useQuery(
    `StateCarriersApi`,
    async () =>
      await AxiosInstance.get(
        `${AQS_BACKEND_API}/get/fetchables/${ratingTemplate.state.value}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )

  const handleVehicleChange = (e, code) => {
    setPolicyScope({ ...policyScope, [code]: e.target.value })
  }
  const handlePolicyChange = (e, code) => {
    setVehicleScope({ ...vehicleScope, [code]: e.target.value })
  }

  useEffect(() => {
    if (ratingTemplate.state.value != ``) {
      getAllCoverages()
    }
  }, [ratingTemplate.state])

  const ValidateForm = () => {
    if (!ratingTemplate.state?.value?.length) {
      handleErrors(`stateError`, `Select Value`)
    } else {
      handleErrors(`stateError`, ``)
    }
    if (!ratingTemplate?.templateName.length) {
      handleErrors(`templateNameError`, `Enter Value`)
    } else {
      handleErrors(`templateNameError`, ``)
    }
    if (!ratingTemplate.description.length) {
      handleErrors(`descriptionError`, `Enter Value`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.stateError.length &&
      !errors.templateNameError.length &&
      !errors.descriptionError.length
    ) {
      //   dispatch()
      // setRatingPreferences({
      //   bumpLimits: preferences.bumpLimits.value,
      // }),
      updatePayload()
      handleErrors(`formSubmit`, false)
    }
  }, [errors])
  let currentRatingTemplate = false
  useEffect(() => {
    if (currentRatingTemplate) {
      setRatingTemplate((prevState) => ({
        ...prevState,
        [`state`]: {
          label: currentRatingTemplate.state,
          value: currentRatingTemplate.state,
        },
        [`templateName`]: currentRatingTemplate.templateName,
        [`description`]: currentRatingTemplate.description,
      }))
      setPolicyScope((prevState) => ({
        ...prevState,
      }))
      setVehicleScope((prevState) => ({
        ...prevState,
      }))
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}
    if (ratingTemplate.state !== currentRatingTemplate.state) {
      data.state = ratingTemplate.state
    }
    if (ratingTemplate.templateName !== currentRatingTemplate.templateName) {
      data.templateName = ratingTemplate.templateName
    }
    if (ratingTemplate.description !== currentRatingTemplate.description) {
      data.description = ratingTemplate.description
    }

    setIsFormChanged(false)
    return data
  }
  return (
    <Container>
      <PageHeader>
        <div>Update Template</div>
      </PageHeader>
      {/* <Spinner mLoading={true} /> */}

      {/* <ReactNotification action="error" message="Something went wrong" /> */}
      <div className={`px-8 py-1 `}>
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <ReactSelectField
              title="Default State"
              placeholder="Select State"
              errorMessage={errors.stateError}
              value={ratingTemplate.state}
              setValue={(value) => {
                setRatingTemplate((prevState) => ({
                  ...prevState,
                  [`state`]: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadStates}
              isSearchable={true}
              disabled={false}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Template Name"
              placeholder="Enter Name"
              errorMessage={errors.templateNameError}
              value={ratingTemplate.templateName}
              setValue={(e) => {
                setRatingTemplate((prevState) => ({
                  ...prevState,
                  [`templateName`]: e.target.value,
                }))
              }}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Description"
              placeholder="Enter Description"
              errorMessage={errors.descriptionError}
              setValue={(e) => {
                setRatingTemplate((prevState) => ({
                  ...prevState,
                  [`description`]: e.target.value,
                }))
              }}
            />
          </div>
        </div>

        {/* dynamic inputs */}
        <div className="my-4">
          <PageHeader>
            <div className="grid grid-cols-2 w-full">
              <div>Policy Scoped</div>
              <div className="ml-2">Vehicle Scoped</div>
            </div>
          </PageHeader>
        </div>

        <div className="grid grid-cols-2 gap-6 mt-2">
          {coveragesData?.supportedCoverages.policyScoped.length > 0 ? (
            <>
              <div className="flex flex-col">
                {coveragesData?.supportedCoverages?.policyScoped.map(
                  (_policy) => {
                    return (
                      <div key="sdda" className="flex flex-col relative mt-3">
                        <label
                          className="text-gray-700 font-medium text-sm"
                          htmlFor="animals"
                        >
                          {_policy.code}
                        </label>

                        <select
                          className="py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                          name="policies"
                          onChange={(e) => handlePolicyChange(e, _policy.code)}
                        >
                          <option value="">
                            {_policy.limits.length
                              ? `Select Option`
                              : `No Data Available`}
                          </option>
                          {_policy.limits.map((limits) => {
                            return (
                              <option key={limits.key} value={limits.key}>
                                {limits.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    )
                  },
                )}
              </div>

              <div className="flex flex-col ">
                {coveragesData?.supportedCoverages?.vehicleScoped.map(
                  (_vehicle) => {
                    return (
                      <div key="sdda" className="flex flex-col mt-3">
                        <label
                          className="text-gray-700 font-medium text-sm"
                          htmlFor="animals"
                        >
                          {_vehicle.code}
                        </label>

                        <select
                          className="py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
                          name="vehicle"
                          onChange={(e) =>
                            handleVehicleChange(e, _vehicle.code)
                          }
                        >
                          <option value="">
                            {_vehicle.limits.length
                              ? `Select Option`
                              : `No Data Available`}
                          </option>
                          {_vehicle.limits.map((limits) => {
                            return (
                              <option key={limits.key} value={limits.key}>
                                {limits.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    )
                  },
                )}
              </div>
            </>
          ) : (
            <div className="w-full flex justify-end">
              <div className="font-medium">No Data Found</div>
            </div>
          )}
        </div>
      </div>
      <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
        <SubmitButton onClick={() => ValidateForm()}>
          Update Template
        </SubmitButton>
      </div>
    </Container>
  )
}
