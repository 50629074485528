import React, { useState, useEffect } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { StyledButton } from 'App/Styled'
import { loadChannel } from 'App/Services/Broadcast'
import { AxiosInstance, AMS_BACKEND_API } from 'App/Config'
import { useMutation } from 'react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons'
import { NotificationManager } from 'react-notifications'
import { fileResizer, fileToBase64 } from 'App/Services'
import { RichTextEditor } from 'App/Components/Common/RichTextEditor'
import { useDebouncedCallback } from 'use-debounce/lib'
import { ValidateDuplicateTemplateName } from 'App/Services'

export const AddBroadcastingTemplates = ({ closeModal, refetchTemplates }) => {
  let fileRef
  const [messageTemplate, setMessageTemplate] = useState({
    name: ``,
    channel: ``,
  })
  const [templateNameValidating, setTemplateNameValidating] = useState(false)

  const [templateBody, setTemplateBody] = useState(``)
  const [errors, setErrors] = useState({
    nameError: ``,
    channelError: ``,
    bodyError: ``,
    formSubmit: ``,
  })
  const { mutate: addBroadCastingTemplate, isLoading: addingTemplate } =
    useMutation(
      async (payload) =>
        await AxiosInstance.post(`${AMS_BACKEND_API}/api/templates/create`, {
          ...payload,
        }).then((res) => {
          if (res.hasError) {
            NotificationManager.error(res.message)
          } else {
            NotificationManager.success(`Operation Successful`)
            refetchTemplates()
            closeModal()
          }
        }),
      {
        refetchOnWindowFocus: false,
        enabled: false,
      },
    )

  const ValidateForm = () => {
    if (!messageTemplate.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      if (errors.nameError !== `Name Already Exists`) {
        handleErrors(`nameError`, ``)
      }
    }

    if (!messageTemplate.channel?.value?.length) {
      handleErrors(`channelError`, `Select Channel`)
    } else {
      handleErrors(`channelError`, ``)
    }
    if (messageTemplate.channel?.value === `MAIL`) {
      if (
        templateBody?.length == 7 ||
        templateBody?.length == 0 ||
        templateBody == undefined
      ) {
        handleErrors(`bodyError`, `Enter Body`)
      } else {
        handleErrors(`bodyError`, ``)
      }
    } else {
      if (!templateBody?.length) {
        handleErrors(`bodyError`, `Enter Body`)
      } else {
        handleErrors(`bodyError`, ``)
      }
    }

    handleErrors(`formSubmit`, true)
  }
  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }
  const validateTemplateName = useDebouncedCallback(async (value) => {
    setTemplateNameValidating(true)
    const response = await ValidateDuplicateTemplateName({ name: value })

    if (response?.hasError) {
      handleErrors(`formSubmit`, false)
      handleErrors(`nameError`, `Name Already Exists`)
    } else {
      handleErrors(`formSubmit`, false)
      handleErrors(`nameError`, ``)
    }
    setTemplateNameValidating(false)
  }, 2500)

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.bodyError.length &&
      !errors.nameError.length &&
      !errors.channelError.length
    ) {
      addBroadCastingTemplate({
        name: messageTemplate.name,
        channel: messageTemplate?.channel?.value,
        template:
          messageTemplate?.channel?.value === `SMS`
            ? templateBody.replace(/<[^>]*>/g, ``)
            : templateBody,
      })
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const handleFileUpload = async (file) => {
    if (file.length) {
      if ((file[0]?.size / (1024 * 1024)).toFixed(2) > 5) {
        NotificationManager.error(`File is too big.`)
      } else if (file[0].type.includes(`image`)) {
        const base64 = await fileResizer(file[0])
        if (base64) {
          setTemplateBody(base64)
        }
      } else if (file[0].type.includes(`pdf`)) {
        fileToBase64(file[0], (err, base64) => {
          if (base64) {
            setTemplateBody(base64)
          }
        })
      }
    }
  }
  useEffect(() => {
    return () => {
      setTemplateBody(null)
    }
  }, [])

  return (
    <div
      className=" px-6 py-4"
      style={{
        width: `50vw`,
        minWidth: `40vw`,
      }}
    >
      <div className="flex  flex-col gap-6 mt-2 text-xs">
        <div className="flex-1 relative">
          <InputField
            type="text"
            title="Name *"
            name="name"
            placeholder="Enter Name"
            errorMessage={errors.nameError}
            value={messageTemplate.name}
            validating={templateNameValidating}
            setValue={(e) => {
              validateTemplateName(e.target.value)
              setMessageTemplate((prevState) => ({
                ...prevState,
                [`name`]: e.target.value,
              }))
            }}
          />
        </div>
        <div className="flex-1 relative">
          <ReactSelectField
            title="Select Channel *"
            placeholder="Select Option"
            value={messageTemplate.channel}
            setValue={(value) => {
              setTemplateBody(``)
              setMessageTemplate((prevState) => ({
                ...prevState,
                [`channel`]: value,
              }))
            }}
            errorMessage={errors.channelError}
            isOptionDisabled={true}
            loadOptions={loadChannel}
            isSearchable={false}
            isMulti={false}
          />
        </div>
        <div className="flex-1 relative">
          {messageTemplate?.channel?.value === `SMS` && (
            <>
              <RichTextEditor
                setEditorValue={setTemplateBody}
                className="hide-toolbar"
              />
              {errors.bodyError?.length ? (
                <span className="text-red-500">Enter Body</span>
              ) : null}
            </>
          )}
          {messageTemplate?.channel?.value === `EMAIL` && (
            <>
              <RichTextEditor setEditorValue={setTemplateBody} />
              {errors.bodyError?.length ? (
                <span className="text-red-500">Enter Body</span>
              ) : null}
            </>
          )}
          {messageTemplate?.channel?.value === `MAIL` && (
            <>
              <RichTextEditor setEditorValue={setTemplateBody} />
              {errors.bodyError?.length ? (
                <span className="text-red-500">Enter Body</span>
              ) : null}
            </>
          )}
          {messageTemplate?.channel?.value === `MMS` && (
            <div
              className={`py-2 px-4 flex justify-center items-center ${
                templateBody
                  ? `bg-green-600 hover:bg-green-700 focus:ring-greenbg-green-500`
                  : `bg-red-500 hover:bg-red-700 focus:ring-red-500`
              }  focus:ring-offset-red-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 w-8 h-8 rounded-lg `}
            >
              <FontAwesomeIcon
                icon={faUpload}
                size="1x"
                onClick={() => fileRef.click()}
                className="text-gray-100 cursor-pointer "
              />
              <input
                className="hidden"
                type="file"
                name="attachment"
                ref={(refParam) => (fileRef = refParam)}
                onChange={(e) => {
                  handleFileUpload(e.target.files, `mms`)
                  e.target.files = null
                }}
                accept="image/*, video/*"
              />
            </div>
          )}
          {messageTemplate?.channel?.value === `PDF` && (
            <div
              className={` py-2 px-4 flex justify-center items-center   ${
                templateBody
                  ? `bg-green-600 hover:bg-green-700 focus:ring-greenbg-green-500`
                  : `bg-red-500 hover:bg-red-700 focus:ring-red-500`
              } focus:ring-offset-red-200 text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 w-8 h-8 rounded-lg `}
            >
              <FontAwesomeIcon
                icon={faUpload}
                size="1x"
                onClick={() => fileRef.click()}
                className="text-gray-100 cursor-pointer "
              />
              <input
                className="hidden"
                type="file"
                name="attachment"
                ref={(refParam) => (fileRef = refParam)}
                onChange={(e) => {
                  handleFileUpload(e.target.files, `pdf`)
                  e.target.files = null
                }}
                accept="application/pdf"
              />
            </div>
          )}
          {(messageTemplate?.channel?.value === `PDF` ||
            messageTemplate?.channel?.value === `MMS`) && (
            <label className="text-red-500 font-medium text-sm">
              {errors.bodyError}
              {errors.bodyError && (
                <span className="text-red-500 required-dot">*</span>
              )}
            </label>
          )}
        </div>
      </div>
      <div>
        <div className="flex justify-end items-center">
          <StyledButton onClick={() => ValidateForm()}>
            <div className="flex  gap-2 text-white">
              <span>{!addingTemplate ? `Add` : `Adding...`} </span>
              {addingTemplate && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </StyledButton>
        </div>
      </div>
    </div>
  )
}
