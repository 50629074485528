import React from 'react'

export const StatisticCard = ({
  sales,
  title,
  quotes,
  leads,
  assessmentTitle,
  earning,
  colorPrimary,
  gradient,
  assessmentScore,
}) => {
  const currencyConvert = (number) => {
    return new Intl.NumberFormat(`en-US`, {
      style: `currency`,
      currency: `USD`,
      notation: `compact`,
    }).format(number)
  }

  return (
    <div
      className={`flex flex-col text-white w-full shadow-sm rounded-md p-1 bg-gradient-to-r ${gradient}  m-auto`}
    >
      <div className="flex flex-row  items-center px-4 py-4 ">
        {leads !== undefined && (
          <>
            <div className=" -mt-12 ">
              <p className="text-lg">{title}</p>
            </div>
            <div className=" flex-1 justify-center  -ml-8 mt-6 mb-2">
              <p className="  text-2xl font-bold justify-center ">{leads}</p>
            </div>
          </>
        )}
        {quotes !== undefined && (
          <>
            <div className=" -mt-12 ">
              <p className="text-lg">{title}</p>
            </div>
            <div className=" flex-1 justify-center  -ml-8 mt-6 mb-2">
              <p className="  text-2xl font-bold justify-center ">{quotes}</p>
            </div>
          </>
          // <div>
          //   <p className="text-2xl font-bold">{quotes}</p>
          //   <p className="text-lg">{title}</p>
          // </div>
        )}
        {sales !== undefined && (
          <>
            <div className=" -mt-12 ">
              <p className="text-lg">{title}</p>
            </div>
            <div className=" flex-1 justify-center  -ml-8 mt-6 mb-2">
              <p
                data-tip={sales}
                className="  text-2xl font-bold tooltip justify-center "
              >
                {currencyConvert(sales)}
              </p>
            </div>
          </>
        )}
        {earning !== undefined && (
          <>
            <div className=" -mt-12 ">
              <p className="text-lg">{title}</p>
            </div>
            <div className=" flex-1 justify-center  -ml-10 mt-6 mb-2">
              <p
                data-tip={earning}
                className="  text-2xl font-bold tooltip justify-center "
              >
                {currencyConvert(earning)}
              </p>
            </div>
          </>
        )}
      </div>
      <div className="relative ">
        {assessmentScore !== undefined && (
          <>
            <div className="text-center capitalize">
              <p className="text-lg ">
                {assessmentTitle && assessmentTitle?.toLowerCase()}
              </p>
            </div>
            <div>
              <p className="  text-2xl font-bold py-6 -mt-3 ">
                {assessmentScore}
              </p>
            </div>
          </>
        )}
      </div>
      <div className={`${colorPrimary}-500 py-1 px-1`}>
        <div className="flex items-center justify-between "></div>
      </div>
    </div>
  )
}
