import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const loadDrivers = async (search, loadedOptions, { page }) => {
  const {
    data: { data },
  } = await AxiosInstance.get(
    `${AMS_BACKEND_API}/api/assets/${
      window.location.href.split(`/`)[5]
    }/drivers?search=${search && search}&page=${page}&offset=10`,
  )

  if (data) {
    return {
      options: data?.drivers,
      hasMore: data.totalPages - page > 0,
      additional: {
        page: page + 1,
      },
    }
  }
}
