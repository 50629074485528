
export const loadGoalFor = () => {
    const options = {
        options: [
            {
                value: `AGENT`,
                label: `AGENT`,
            },
            {
                value: `MANAGER`,
                label: `MANAGER`,
            },
        ],
    }
    return options
}
