export const loadDriverTypeCode = () => {
  const options = {
    options: [
      { value: `Deferred Driver`, label: `Deferred Driver` },
      { value: `Excluded`, label: `Excluded` },
      { value: `Unrated Driver`, label: `Unrated Driver` },
      { value: `Unlicensed`, label: `Unlicensed` },
    ],
    hasMore: false,
  }
  return options
}
