import {
  faArrowAltCircleRight,
  faBox,
  faClipboardList,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { setQuotesActivityActiveTab } from 'App/Redux/actions'
import { ActivityWrapper } from 'App/Styled'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AboutQuote } from './About'
import { QuotesActivity } from './Activity'
import { permissionGranted } from 'App/Services'

export const QuotePreview = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const dispatch = useDispatch()

  return (
    <div className="flex justify-end">
      <div className={`${isCollapsed ? `w-full` : `w-2/3`}`}>
        <AboutQuote />
      </div>
      <ActivityWrapper collapsed={isCollapsed.toString()}>
        <div
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="px-2 py-1 "
        >
          <FontAwesomeIcon
            icon={faArrowAltCircleRight}
            className={`text-red-500 my-0.5 cursor-pointer transform transition duration-500 ${
              isCollapsed ? `rotate-180 ` : `rotate-0`
            }`}
          />
        </div>
        {!isCollapsed ? (
          <QuotesActivity />
        ) : (
          <div
            className="text-center flex flex-col"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {permissionGranted(
              [`notes:read`, `corporate-manager:*`, `manager:*`, `admin:*`] || [
                `notes:*`,
                `corporate-manager:*`,
                `manager:*`,
                `admin:*`,
              ],
            ) ? (
              <>
                <div
                  data-tip="Notes"
                  className="mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setQuotesActivityActiveTab(1))}
                >
                  <FontAwesomeIcon
                    icon={faClipboardList}
                    className="text-red-500"
                  />
                </div>
                <div
                  data-tip="Logs"
                  className="mt-4 mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setQuotesActivityActiveTab(2))}
                >
                  <FontAwesomeIcon icon={faBox} className="text-red-500" />
                </div>
                <div
                  data-tip="Communications"
                  className="mt-4 mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setQuotesActivityActiveTab(3))}
                >
                  <FontAwesomeIcon
                    icon={faCommentDots}
                    className="text-red-500"
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  data-tip="Logs"
                  className="mt-1 mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setQuotesActivityActiveTab(1))}
                >
                  <FontAwesomeIcon icon={faBox} className="text-red-500" />
                </div>
                <div
                  data-tip="Communications"
                  className="mt-2 mb-3 mx-5 cursor-pointer tooltip tooltip-left"
                  onClick={() => dispatch(setQuotesActivityActiveTab(2))}
                >
                  <FontAwesomeIcon
                    icon={faCommentDots}
                    className="text-red-500"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </ActivityWrapper>
    </div>
  )
}
