import React, { useState } from 'react'
import {
  Container,
  ReportsSticker,
  PageHeader,
  StyledButton,
  Input,
} from 'App/Styled'
import { PoliciesByAgent } from './PoliciesByAgent'
import { PoliciesByCarrier } from './PoliciesByCarrier'
import { AgentCommissionByPolicies } from './AgentCommissionByPolicies'
import { BookOfBusiness } from './BookOfBusiness'
import { ExpiredPolicies } from './ExpiredPolicies'
import { ExpiredPoliciesByAgent } from './ExpiredPoliciesByAgent'
import { ExpiredPoliciesByCarrier } from './ExpiredPoliciesByCarrier'
import { InsuredPropertyAddress } from './InsuredPropertyAddress'
import { LineOfBusiness } from './LineOfBusiness'
import { PoliciesOverviewedByInsured } from './PoliciesOverviewedByInsured'
import { TopAgentByCount } from './TopAgentByCount'
import { InsuredReports } from './InsuredReports/index'
import { InsuredReferralReports } from './InsuredReferralReports/index'
import { ClientByAgeReports } from './ClientByAgeReport'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons'
import { ReactModal } from 'App/Components/Common/Modal'
import { DateRange } from 'react-date-range'
import { ClientByBithReports } from './ClientByBirthReport'
import { ClientByBithLabelReports } from './ClientsByBirthLabelReport'
import { LeadByReferralSourceReports } from './LeadsByReferralSourceReports'
import { LeadCustomDatesAndLob } from './LeadCustomDatesAndLobs'
import { CustomDatesAndLOB } from './CustomDatesAndLobs'
import { PoliciesByCarrierAndLob } from './PoliciesByCarrierAndLob'
import { InvoicesByAgentDetail } from './InovicesByAgentDetails'
import { InvoicesByAgent } from './InvoicesByAgent'
import { Top10AgentByComission } from './Top10AgentByCommission'
import moment from 'moment'

const policiesReports = [
  `Policies By Agents`,
  `Policies by Carriers`,
  `Expired Policies`,
  `Expired Policies By Agent`,
  `Expired Policies By Carrier`,
  `Book of Business`,
  `Line of Business`,
  `Policies Overviewed By Insured`,
  `Insured Property Address`,
  `Top Agent By Count`,
  `Agent Commission By Policies`,
  `Insured`,
  // `Insured Referral`,
  `Clients By Age`,
  `Clients By Birth`,
  `Clients By Birth Label`,
  `Lead By Referral Source`,
  `Lead Custom Dates And Lob`,
  `Custom Dates And Lob`,
  `Invoices By Agent Detail`,
  `Invoices By Agent`,
  `Top 10 Agent By Comission`,
  // `Policies By Carrier And Lob`
]

export const PoliciesReports = () => {
  const [currentReport, setCurrentReport] = useState(``)
  const [previewModal, setPreviewModal] = useState(false)

  //  date range picker
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
    },
  ])
  const [dateHolder, setDateHolder] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: `selection`,
    },
  ])

  return (
    <>
      {previewModal && (
        <ReactModal
          title="Date Range"
          closeModal={() => setPreviewModal(false)}
        >
          <div className="pb-2">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setDateHolder([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={dateHolder}
              className="w-full"
            />
            <div className="px-2 text-right">
              <StyledButton
                onClick={() => {
                  setDateRange(dateHolder)
                  setPreviewModal(false)
                }}
              >
                <div className="flex gap-2 text-white">
                  <span>Set Date Range </span>
                </div>
              </StyledButton>
            </div>
          </div>
        </ReactModal>
      )}
      <Container>
        <PageHeader>
          <div>Policy Report</div>
          <div className="relative flex">
            <div className="flex gap-x-2">
              <label className={`text-gray-700 font-medium text-sm pt-2`}>
                Start Date
              </label>
              <Input
                type="text"
                name="startRange"
                placeholder="Start Date"
                value={
                  dateRange[0]?.startDate
                    ? moment(dateRange[0].startDate).format(`YYYY-MM-DD`)
                    : ``
                }
                disabled={true}
              />
              <label className={`text-gray-700 font-medium text-sm pt-2`}>
                End Date
              </label>
              <Input
                type="text"
                name="endRange"
                placeholder="End Date"
                value={
                  dateRange[0]?.endDate
                    ? moment(dateRange[0].endDate).format(`YYYY-MM-DD`)
                    : ``
                }
                disabled={true}
              />
            </div>
            <div
              className="py-1 px-2 mx-2"
              onClick={() => setPreviewModal(true)}
            >
              <FontAwesomeIcon
                icon={faCalendarWeek}
                className="text-blue-500 mt-0.5 cursor-pointer"
                size="sm"
              />
            </div>
          </div>
        </PageHeader>
        <div className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-8 lg:gap-2 md:gap-0 p-2 items-center">
          {policiesReports.map((report, index) => (
            <ReportsSticker
              checked={currentReport === report ? `yes` : `no`}
              onClick={() => setCurrentReport(report)}
              key={index}
            >
              <div className="text-sm">{report}</div>
            </ReportsSticker>
          ))}
        </div>
      </Container>
      {currentReport && (
        <Container>
          <div className="py-3 px-2 bg-white ">
            <div className="mt-2">
              {(() => {
                switch (currentReport) {
                  case `Policies By Agents`:
                    return <PoliciesByAgent dateRange={dateRange} />
                  case `Policies by Carriers`:
                    return <PoliciesByCarrier dateRange={dateRange} />
                  case `Expired Policies`:
                    return <ExpiredPolicies dateRange={dateRange} />
                  case `Expired Policies By Agent`:
                    return <ExpiredPoliciesByAgent dateRange={dateRange} />
                  case `Expired Policies By Carrier`:
                    return <ExpiredPoliciesByCarrier dateRange={dateRange} />
                  case `Book of Business`:
                    return <BookOfBusiness dateRange={dateRange} />
                  case `Line of Business`:
                    return <LineOfBusiness dateRange={dateRange} />
                  case `Policies Overviewed By Insured`:
                    return <PoliciesOverviewedByInsured dateRange={dateRange} />
                  case `Insured Property Address`:
                    return <InsuredPropertyAddress dateRange={dateRange} />
                  case `Top Agent By Count`:
                    return <TopAgentByCount />
                  case `Agent Commission By Policies`:
                    return <AgentCommissionByPolicies dateRange={dateRange} />
                  case `Insured`:
                    return <InsuredReports dateRange={dateRange} />
                  case `Insured Referral`:
                    return <InsuredReferralReports dateRange={dateRange} />
                  case `Clients By Age`:
                    return <ClientByAgeReports />
                  case `Clients By Birth`:
                    return <ClientByBithReports dateRange={dateRange} />
                  case `Clients By Birth Label`:
                    return <ClientByBithLabelReports dateRange={dateRange} />
                  case `Lead By Referral Source`:
                    return <LeadByReferralSourceReports dateRange={dateRange} />
                  case `Lead Custom Dates And Lob`:
                    return <LeadCustomDatesAndLob dateRange={dateRange} />
                  case `Custom Dates And Lob`:
                    return <CustomDatesAndLOB dateRange={dateRange} />
                  case `Policies By Carrier And Lob`:
                    return <PoliciesByCarrierAndLob dateRange={dateRange} />
                  case `Invoices By Agent Detail`:
                    return <InvoicesByAgentDetail dateRange={dateRange} />
                  case `Invoices By Agent`:
                    return <InvoicesByAgent dateRange={dateRange} />
                  case `Top 10 Agent By Comission`:
                    return <Top10AgentByComission />
                  default:
                    return null
                }
              })()}
            </div>
          </div>
        </Container>
      )}
    </>
  )
}
