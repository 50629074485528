/* eslint-disable no-unused-vars */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setPolicyActiveTab } from 'App/Redux/actions'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import { LocalPolicy } from './Local'
import { CarrierDownloads } from './CarrierDownloads'
import { TOne, TTwo, TThree, TFour } from 'App/Styled'
import { Documents } from './Documents'
import { VerifyDocuments } from './VerifyDocuments'

const fTabs = [
  {
    tName: `Local`,
    tNumber: 1,
    permission: [
      `policies:read`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
  {
    tName: `Carrier Downloads`,
    tNumber: 2,
    permission: [
      `policies:read`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
  {
    tName: `Documents Verification`,
    tNumber: 3,
    permission: [
      `analyze-document:*`,
      `corporate-manager:*`,
      `manager:*`,
      `admin:*`,
    ],
  },
  {
    tName: `Documents`,
    tNumber: 4,
    permission: [`policies:read`, `admin-franchise`, `admin-master-franchise`],
  },
]

export const Tabs = () => {
  const { activeTab } = useSelector(({ policy }) => policy)
  const dispatch = useDispatch()

  return (
    <>
      <TabsHeaders
        openTab={activeTab}
        tabs={fTabs}
        getActiveTab={(val) => dispatch(setPolicyActiveTab(val))}
      />
      <div className="tab-content tab-space">
        <TOne opentab={activeTab}>
          <LocalPolicy />
        </TOne>

        <TTwo opentab={activeTab}>
          <CarrierDownloads />
        </TTwo>
        <TThree opentab={activeTab}>
          <VerifyDocuments />
        </TThree>
        <TFour opentab={activeTab}>
          <Documents />
        </TFour>
      </div>
    </>
  )
}
