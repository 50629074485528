import tw from 'tailwind-styled-components'

export const FImage = tw.img`
    bg-gray-300 
    object-cover 
    rounded-lg 
    h-16 
    w-16 
`
export const FContainer = tw.div`
    w-full
    flex 
    gap-6 
    relative
`

export const HNav = tw.nav`
    md:block 
    sm:block
`
