import React, { useEffect, useState } from 'react'
import {
  AddTabButton,
  Container,
  SubmitButton,
  FormTab,
  PageHeader,
  SecondaryHeading,
} from 'App/Styled'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import {
  AMS_BACKEND_API,
  AxiosInstance,
  LEAFLET_URL,
  regEx,
  urlRegex,
} from 'App/Config'
import { Spinner } from 'App/Components/Common/Spinner'
// import { Checkbox } from 'App/Components/Common/Checkbox'
import { MaskField } from 'App/Components/Common/MaskField'
import { InputField } from 'App/Components/Common/InputField'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import { RemoveLocation } from 'App/Components/Customers/AddCustomer/style'
import { loadLobs, loadZipCodes, ValidateZip } from 'App/Services'
import { useDebouncedCallback } from 'use-debounce/lib'
import { TabsHeaders } from 'App/Components/Common/TabsHeaders'
import { NotificationManager } from 'react-notifications'

export const AddCarrier = () => {
  const history = useHistory()

  // representative tabs
  const [repTabs, setRepTabs] = useState([
    {
      tName: `Representative 1`,
      tNumber: 1,
    },
  ])
  const [activeRepTab, setActiveRepTab] = useState(1)

  // contacts tabs
  // const [CTabs, setCTabs] = useState([
  //   {
  //     tName: `Contact 1`,
  //     tNumber: 1,
  //   },
  // ])
  // const [activeContactTab, setActiveContactTab] = useState(1)

  const [carrier, setCarrier] = useState({
    name: ``,
    fein: ``,
    sic: ``,
    yearBusinessStarted: ``,
    naic: ``,
    financeCoSearchCode: ``,
    description: ``,
    // producerCode: ``,
    // isActive: ``,

    // businessinformation
    // minimumDownPayment: ``,
    // minimumDownPaymentType: ``,
    // interestRate: ``,
    // interestrateType: ``,
    // minimumEarned: ``,
    // minimumEarnedtype: ``,
    // remitBy: ``,
    // grossComissionDueWithin: ``,
    // remainingPayments: ``,
    // amBestRatingBCR: ``,
    // amBestRatingFSC: ``,
    // premiumSent: ``,
  })
  const [errors, setErrors] = useState({
    formSubmit: false,
    nameError: ``,
    feinError: ``,
    sicError: ``,
    yearBusinessStartedError: ``,
    naicError: ``,
    financeCoSearchCodeError: ``,
    descriptionError: ``,
    // producerCodeError: ``,

    // minimumDownPaymentError: ``,
    // minimumDownPaymentTypeError: ``,
    // interestRateError: ``,
    // interestrateTypeError: ``,
    // minimumEarnedError: ``,
    // minimumEarnedtypeError: ``,
    // remitByError: ``,
    // grossComissionDueWithinError: ``,
    // remainingPaymentsError: ``,
    // amBestRatingBCRError: ``,
    // amBestRatingFSCError: ``,
    // premiumSentError: ``,

    //    address
    zipError: ``,
    streetNoError: ``,
    cityError: ``,
    stateError: ``,
    coordinatesError: ``,
  })

  const [lineOfBusinesses, setLineOfBusinesses] = useState({
    lineOfBusiness: ``,
    lineOfBusinessError: ``,
  })
  const [representative, setRepresentative] = useState([
    {
      mi: ``,
      miError: ``,
      firstName: ``,
      firstNameError: ``,
      lastName: ``,
      lastNameError: ``,
      remail: ``,
      remailError: ``,
      rphone: ``,
      rphoneError: ``,
      note: ``,
      noteError: ``,
    },
  ])
  const [carrierContacts, setCarrierContacts] = useState([
    {
      name: ``,
      nameError: ``,
      designation: ``,
      designationError: ``,
      mobile: ``,
      mobileError: ``,
      phone: ``,
      phoneError: ``,
      ext: ``,
      extError: ``,
      fax: ``,
      faxError: ``,
      email: ``,
      emailError: ``,
      mainWebsite: `https://`,
      mainWebsiteError: ``,
      agentPortal: `https://`,
      agentPortalError: ``,
      userName: ``,
      userNameError: ``,
      password: ``,
      passwordError: ``,
    },
  ])
  const [address, setAddress] = useState({
    unitNo: ``,
    zip: ``,
    streetNo: ``,
    city: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  //carrier add mutation
  const {
    mutate: addCarrier,
    isLoading,
    isError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/carriers`, {
        ...payload,
      }).then((res) => {
        NotificationManager.success(`Operation successful`)
        !res.hasError && history.push(`/carriers`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  // Dynamic Forms
  // const handleCarrierContactAddForm = () => {
  //   setCTabs([
  //     ...CTabs,
  //     {
  //       tName: `Contact ${CTabs.length + 1}`,
  //       tNumber: CTabs.length + 1,
  //     },
  //   ])
  //   setCarrierContacts([
  //     ...carrierContacts,
  //     {
  //       name: ``,
  //       nameError: ``,
  //       designation: ``,
  //       designationError: ``,
  //       mobile: ``,
  //       mobileError: ``,
  //       phone: ``,
  //       phoneError: ``,
  //       ext: ``,
  //       extError: ``,
  //       fax: ``,
  //       faxError: ``,
  //       email: ``,
  //       emailError: ``,
  //       mainWebsite: `https://`,
  //       mainWebsiteError: ``,
  //       agentPortal: `https://`,
  //       agentPortalError: ``,
  //       userName: ``,
  //       userNameError: ``,
  //       password: ``,
  //       passwordError: ``,
  //     },
  //   ])
  // }
  const [carrierZipValidate, setCarrierZipValidate] = useState(false)
  // useEffect(() => {
  //   setActiveContactTab(CTabs.length)
  // }, [CTabs.length])

  // const handleCarrierContactRemoveForm = (index) => {
  //   const carrierContactsArr = [...carrierContacts]
  //   if (carrierContactsArr.length === 1) {
  //     return
  //   }
  //   carrierContactsArr.splice(index, 1)
  //   setCarrierContacts(carrierContactsArr)

  //   const newTabs = [...CTabs]
  //   if (newTabs.length !== 1) {
  //     newTabs.pop()
  //     setCTabs(newTabs)
  //   }
  // }

  const addRepresentativeForm = () => {
    setRepTabs([
      ...repTabs,
      {
        tName: `Representative ${repTabs.length + 1}`,
        tNumber: repTabs.length + 1,
      },
    ])
    setRepresentative([
      ...representative,
      { mi: ``, firstName: ``, lastName: ``, remail: ``, rphone: ``, note: `` },
    ])
  }

  useEffect(() => {
    setActiveRepTab(repTabs.length)
  }, [repTabs.length])

  const removeRepresentativeForm = (index) => {
    const representativeArr = [...representative]
    if (representativeArr.length === 1) {
      return
    }
    representativeArr.splice(index, 1)
    setRepresentative(representativeArr)

    const newRepTabs = [...repTabs]
    if (newRepTabs.length !== 1) {
      newRepTabs.pop()
      setRepTabs(newRepTabs)
    }
  }

  const handleAddressChange = useDebouncedCallback((name, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  //handle zip code
  const handleZipChange = async (val) => {
    setCarrierZipValidate(true)
    const data = await ValidateZip(val.value)
    if (data) {
      setCarrierZipValidate(false)
      const res = data.address

      setAddress((prevState) => ({
        ...prevState,
        [`city`]: res.city,
        [`state`]: res.state,
        [`zip`]: { value: res.zipCode, label: res.zipCode },
      }))
      setCoordinates((prevState) => ({
        ...prevState,
        [`lat`]: res.lat,
        [`lng`]: res.long,
      }))
    }
  }
  // handle map
  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  // Form Controlled input handler
  const handleCarrierChange = useDebouncedCallback((name, value) => {
    setCarrier((carrier) => ({ ...carrier, [name]: value }))
  }, 5)

  const handleRepresentativeFormChange = useDebouncedCallback(
    (value, name, ind) => {
      const newRepresentative = representative?.map(
        (_representative, index) => {
          if (index === ind) {
            _representative[name] = value
          }
          return _representative
        },
      )
      setRepresentative(newRepresentative)
    },
    5,
  )
  // const handleCarrierContactFormChange = useDebouncedCallback(
  //   (value, name, ind) => {
  //     const newCarrierContact = carrierContacts?.map(
  //       (carrierContact, index) => {
  //         if (index === ind) {
  //           carrierContact[name] = value
  //         }
  //         return carrierContact
  //       },
  //     )
  //     setCarrierContacts(newCarrierContact)
  //   },
  //   5,
  // )

  //  Form Validations
  const ValidForm = () => {
    if (!carrier.name.length) {
      handleErrors(`nameError`, `Enter Name`)
    } else {
      handleErrors(`nameError`, ``)
    }
    if (!carrier.fein.length) {
      handleErrors(`feinError`, `Enter Emp No`)
    } else {
      handleErrors(`feinError`, ``)
    }
    if (!carrier.sic.length) {
      handleErrors(`sicError`, `Enter SIC`)
    } else {
      handleErrors(`sicError`, ``)
    }
    // if (!carrier.producerCode.length) {
    //   handleErrors(`producerCodeError`, `Enter Producer Code`)
    // } else {
    //   handleErrors(`producerCodeError`, ``)
    // }
    if (!carrier.yearBusinessStarted.length) {
      handleErrors(`yearBusinessStartedError`, `Enter Year`)
    } else if (
      JSON.parse(carrier.yearBusinessStarted) > new Date().getFullYear()
    ) {
      handleErrors(
        `yearBusinessStartedError`,
        `Year must not be Grater than current year`,
      )
    } else if (
      carrier.yearBusinessStarted.length &&
      carrier.yearBusinessStarted < 0
    ) {
      handleErrors(`yearBusinessStartedError`, `Enter Valid Year`)
    } else {
      handleErrors(`yearBusinessStartedError`, ``)
    }
    if (!carrier.naic.length) {
      handleErrors(`naicError`, `Enter Value`)
    } else {
      handleErrors(`naicError`, ``)
    }
    if (!carrier.financeCoSearchCode.length) {
      handleErrors(`financeCoSearchCodeError`, `Enter Code`)
    } else {
      handleErrors(`financeCoSearchCodeError`, ``)
    }
    if (!carrier.description.length) {
      handleErrors(`descriptionError`, `Enter Description`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    // business / payment information error
    // if (!carrier.minimumDownPayment.length) {
    //   handleErrors(`minimumDownPaymentError`, `Enter  Payment`)
    // } else {
    //   handleErrors(`minimumDownPaymentError`, ``)
    // }
    // if (!carrier.minimumDownPaymentType?.value) {
    //   handleErrors(`minimumDownPaymentTypeError`, `Select Type `)
    // } else {
    //   handleErrors(`minimumDownPaymentTypeError`, ``)
    // }
    // if (!carrier.interestRate.length) {
    //   handleErrors(`interestRateError`, `Enter Interest Rate`)
    // } else {
    //   handleErrors(`interestRateError`, ``)
    // }
    // if (!carrier.interestrateType?.value) {
    //   handleErrors(`interestrateTypeError`, `Select Type`)
    // } else {
    //   handleErrors(`interestrateTypeError`, ``)
    // }
    // if (!carrier.minimumEarned.length) {
    //   handleErrors(`minimumEarnedError`, `Enter Amount`)
    // } else {
    //   handleErrors(`minimumEarnedError`, ``)
    // }
    // if (!carrier.minimumEarnedtype?.value) {
    //   handleErrors(`minimumEarnedtypeError`, `Select Type`)
    // } else {
    //   handleErrors(`minimumEarnedtypeError`, ``)
    // }

    // if (!carrier.remitBy?.length) {
    //   handleErrors(`remitByError`, `Enter Value`)
    // } else {
    //   handleErrors(`remitByError`, ``)
    // }

    // if (!carrier.grossComissionDueWithin) {
    //   handleErrors(`grossComissionDueWithinError`, `Select Comission Date`)
    // } else {
    //   handleErrors(`grossComissionDueWithinError`, ``)
    // }

    // if (!carrier.remainingPayments?.length) {
    //   handleErrors(`remainingPaymentsError`, `Enter Payments`)
    // } else {
    //   handleErrors(`remainingPaymentsError`, ``)
    // }

    // if (!carrier.amBestRatingBCR?.value) {
    //   handleErrors(`amBestRatingBCRError`, `Select Rating`)
    // } else {
    //   handleErrors(`amBestRatingBCRError`, ``)
    // }

    // if (!carrier.amBestRatingFSC?.value) {
    //   handleErrors(`amBestRatingFSCError`, `Select Rating`)
    // } else {
    //   handleErrors(`amBestRatingFSCError`, ``)
    // }

    // if (!carrier.premiumSent?.value) {
    //   handleErrors(`premiumSentError`, `Select Value`)
    // } else {
    //   handleErrors(`premiumSentError`, ``)
    // }

    // Representative Errors
    for (const index in representative) {
      const _representative = representative[index]

      if (!_representative.mi?.length) {
        handleRepresentativeErrors(index, `miError`, `Enter MI`)
      } else {
        handleRepresentativeErrors(index, `miError`, ``)
      }

      if (!_representative.firstName?.length) {
        handleRepresentativeErrors(index, `firstNameError`, `Enter Name`)
      } else {
        handleRepresentativeErrors(index, `firstNameError`, ``)
      }

      if (!_representative.lastName?.length) {
        handleRepresentativeErrors(index, `lastNameError`, `Enter Last Name`)
      } else {
        handleRepresentativeErrors(index, `lastNameError`, ``)
      }
      if (!_representative.remail?.length) {
        handleRepresentativeErrors(index, `remailError`, `Enter Email`)
      } else if (!regEx.test(_representative.remail.toLowerCase())) {
        handleRepresentativeErrors(index, `remailError`, `Enter Valid Email`)
      } else {
        handleRepresentativeErrors(index, `remailError`, ``)
      }
      if (!_representative.rphone?.length) {
        handleRepresentativeErrors(index, `rphoneError`, `Enter Phone`)
      } else if (_representative.rphone.length < 12) {
        handleRepresentativeErrors(index, `rphoneError`, `Enter Valid Phone`)
      } else {
        handleRepresentativeErrors(index, `rphoneError`, ``)
      }
      if (!_representative.note?.length) {
        handleRepresentativeErrors(index, `noteError`, `Enter Note`)
      } else {
        handleRepresentativeErrors(index, `noteError`, ``)
      }
    }
    // Carrier Contacts Errors
    for (const index in carrierContacts) {
      const _carrierContacts = carrierContacts[index]

      if (!_carrierContacts.name.length) {
        handleCarrierContactsErrors(index, `nameError`, `Enter Name`)
      } else {
        handleCarrierContactsErrors(index, `nameError`, ``)
      }

      if (!_carrierContacts.designation.length) {
        handleCarrierContactsErrors(
          index,
          `designationError`,
          `Enter Designation`,
        )
      } else {
        handleCarrierContactsErrors(index, `designationError`, ``)
      }

      if (!_carrierContacts.mobile.length) {
        handleCarrierContactsErrors(index, `mobileError`, `Enter mobile`)
      } else if (_carrierContacts.mobile.length < 12) {
        handleCarrierContactsErrors(index, `mobileError`, `Enter Valid mobile`)
      } else {
        handleCarrierContactsErrors(index, `mobileError`, ``)
      }

      if (!_carrierContacts.phone.length) {
        handleCarrierContactsErrors(index, `phoneError`, `Enter Phone`)
      } else if (_carrierContacts.phone.length < 12) {
        handleCarrierContactsErrors(index, `phoneError`, `Enter Valid Phone`)
      } else {
        handleCarrierContactsErrors(index, `phoneError`, ``)
      }
      if (!_carrierContacts.ext.length) {
        handleCarrierContactsErrors(index, `extError`, `Enter Extension`)
      } else if (_carrierContacts.ext.length < 4) {
        handleCarrierContactsErrors(
          index,
          `extError`,
          `Ext length should be less than 4`,
        )
      } else {
        handleCarrierContactsErrors(index, `extError`, ``)
      }

      if (!_carrierContacts.fax.length) {
        handleCarrierContactsErrors(index, `faxError`, `Enter Fax`)
      } else if (_carrierContacts.fax.length < 12) {
        handleCarrierContactsErrors(index, `faxError`, `Enter Valid Fax`)
      } else {
        handleCarrierContactsErrors(index, `faxError`, ``)
      }

      if (!_carrierContacts.email.length) {
        handleCarrierContactsErrors(index, `emailError`, `Enter Email`)
      } else if (!regEx.test(_carrierContacts.email.toLowerCase())) {
        handleCarrierContactsErrors(index, `emailError`, `Enter Valid Email`)
      } else {
        handleCarrierContactsErrors(index, `emailError`, ``)
      }
      if (!_carrierContacts.mainWebsite.length) {
        handleCarrierContactsErrors(index, `mainWebsiteError`, `Enter WebSite`)
      } else if (!urlRegex.test(_carrierContacts.mainWebsite)) {
        handleCarrierContactsErrors(
          index,
          `mainWebsiteError`,
          `Enter valid url`,
        )
      } else {
        handleCarrierContactsErrors(index, `mainWebsiteError`, ``)
      }
      if (!_carrierContacts.agentPortal.length) {
        handleCarrierContactsErrors(index, `agentPortalError`, `Enter Value`)
      } else if (!urlRegex.test(_carrierContacts.agentPortal)) {
        handleCarrierContactsErrors(
          index,
          `agentPortalError`,
          `Enter valid url`,
        )
      } else {
        handleCarrierContactsErrors(index, `agentPortalError`, ``)
      }
      if (!_carrierContacts.userName.length) {
        handleCarrierContactsErrors(index, `userNameError`, `Enter User Name`)
      } else {
        handleCarrierContactsErrors(index, `userNameError`, ``)
      }
      if (!_carrierContacts.password.length) {
        handleCarrierContactsErrors(index, `passwordError`, `Enter Password`)
      } else {
        handleCarrierContactsErrors(index, `passwordError`, ``)
      }
    }
    // line of business Errors
    if (!lineOfBusinesses?.lineOfBusiness.length) {
      handlelineOfBusinessErrors(`lineOfBusinessError`, `Select Lob`)
    } else {
      handlelineOfBusinessErrors(`lineOfBusinessError`, ``)
    }

    //    address error
    // if (!address.unitNo.length) {
    //   handleErrors(`unitNoError`, `Enter Unit No`)
    // } else {
    //   handleErrors(`unitNoError`, ``)
    // }

    if (!address.zip?.value?.length) {
      handleErrors(`zipError`, `Select Zip`)
    } else {
      handleErrors(`zipError`, ``)
    }
    if (!address.streetNo.length) {
      handleErrors(`streetNoError`, `Enter Street`)
    } else {
      handleErrors(`streetNoError`, ``)
    }
    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`)
    } else {
      handleErrors(`cityError`, ``)
    }
    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`)
    } else {
      handleErrors(`stateError`, ``)
    }
    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, `Select Coordinates`)
    } else {
      handleErrors(`coordinatesError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const handleRepresentativeErrors = (ind, name, val) => {
    const newRepresentative = representative?.map((_representative, index) => {
      if (index == ind) {
        _representative[name] = val
      }
      return _representative
    })
    setRepresentative(newRepresentative)
  }
  const handleCarrierContactsErrors = (ind, name, val) => {
    const newCarrierContacts = carrierContacts?.map((carrierContact, index) => {
      if (index == ind) {
        carrierContact[name] = val
      }
      return carrierContact
    })
    setCarrierContacts(newCarrierContacts)
  }
  const handlelineOfBusinessErrors = (name, val) => {
    setLineOfBusinesses((preLobs) => ({
      ...preLobs,
      [name]: val,
    }))
  }

  useEffect(() => {
    // let isCarrierValidated = false
    let isRepresentativeValidated = false
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.feinError.length &&
      !errors.sicError.length &&
      !errors.yearBusinessStartedError.length &&
      !errors.naicError.length &&
      !errors.financeCoSearchCodeError.length &&
      !errors.descriptionError.length &&
      // !errors.producerCodeError.length &&
      // !errors.nameError.length &&
      // !errors.minimumDownPaymentError.length &&
      // !errors.minimumDownPaymentTypeError.length &&
      // !errors.interestRateError.length &&
      !errors.yearBusinessStartedError.length &&
      // !errors.interestrateTypeError.length &&
      // !errors.minimumEarnedError.length &&
      // !errors.minimumEarnedtypeError.length &&
      // !errors.remitByError.length &&
      // !errors.grossComissionDueWithinError.length &&
      // !errors.remainingPaymentsError.length &&
      // !errors.amBestRatingBCRError.length &&
      // !errors.amBestRatingFSCError.length &&
      // !errors.premiumSentError.length &&
      // !errors.unitNoError.length &&
      !errors.zipError.length &&
      !errors.streetNoError.length &&
      !errors.cityError.length &&
      !errors.stateError.length &&
      !errors.coordinatesError.length &&
      !lineOfBusinesses.lineOfBusinessError.length
    ) {
      representative.forEach((_representative) => {
        if (
          !_representative.miError.length &&
          !_representative.firstNameError.length &&
          !_representative.lastNameError.length &&
          !_representative.remailError.length &&
          !_representative.rphoneError.length &&
          !_representative.noteError.length
        ) {
          isRepresentativeValidated = true
        } else {
          isRepresentativeValidated = false
        }
      })
      // console.log(`carrierContacts`, carrierContacts)
      // carrierContacts.forEach((_carrierContact) => {
      //   if (
      //     // !_carrierContact.nameError.length &&
      //     // !_carrierContact.designationError.length &&
      //     // !_carrierContact.mobileError.length &&
      //     // !_carrierContact.phoneError.length &&
      //     // !_carrierContact.extError.length &&
      //     // !_carrierContact.faxError.length &&
      //     // !_carrierContact.emailError.length &&
      //     // !_carrierContact.mainWebsiteError.length &&
      //     // !_carrierContact.agentPortalError.length &&
      //     // !_carrierContact.userNameError.length &&
      //     // !_carrierContact.passwordError.length
      //   ) {
      //     isCarrierValidated = true
      //   } else {
      //     isCarrierValidated = false
      //   }
      //   console.log(`isCarrierValidated`, isCarrierValidated)
      // }
      // )
    }
    const carrierAddress = {
      zip: address.zip.value,
      street: address.streetNo,
      city: address.city,
      state: address.state,
      coordinates: {
        lat: coordinates.lat.toString(),
        long: coordinates.lng.toString(),
      },
    }

    if (address?.unitNo?.length) {
      carrierAddress.unitNo = address.unitNo
    }

    if (isRepresentativeValidated) {
      const representativePayload = representative.map((_representative) => {
        return {
          mi: _representative.mi,
          firstName: _representative.firstName,
          lastName: _representative.lastName,
          note: _representative.note,
          contactInfo: {
            phone: _representative.rphone,
            email: _representative.remail,
          },
        }
      })
      // const carrierContactPayload = carrierContacts.map((_carrierContact) => {
      //   return {
      //     // name: _carrierContact.name,
      //     designation: _carrierContact.designation,
      //     mobile: _carrierContact.mobile,
      //     phone: _carrierContact.phone,
      //     ext: _carrierContact.ext,
      //     fax: _carrierContact.fax,
      //     email: _carrierContact.email,
      //     mainWebsiteUrl: _carrierContact.mainWebsite,
      //     agentPortalUrl: _carrierContact.agentPortal,
      //     username: _carrierContact.userName,
      //     password: _carrierContact.password,
      //   }
      // })

      addCarrier({
        name: carrier.name,
        fein: carrier.fein,
        sic: carrier.sic,
        yearBusinessStarted: carrier.yearBusinessStarted,
        naic: carrier.naic,
        financeSearchCode: carrier.financeCoSearchCode,
        description: carrier.description,
        // producerCode: carrier.producerCode,
        // isActive: carrier.isActive,
        // minimumDownPayment: carrier.minimumDownPayment,
        // minimumDownPaymentType: carrier.minimumDownPaymentType.value,
        // interestRate: carrier.interestRate,
        // interestrateType: carrier.interestrateType.value,
        // minimumEarned: carrier.minimumEarned,
        // minimumEarnedtype: carrier.minimumEarnedtype.value,
        // remitBy: carrier.remitBy,
        // grossComissionDueWithin: carrier.grossComissionDueWithin,
        // remainingPayments: carrier.remainingPayments,
        // amBestRatingBCR: carrier.amBestRatingBCR.value,
        // amBestRatingFSC: carrier.amBestRatingFSC.value,
        // premiumSent: carrier.premiumSent.value,
        address: carrierAddress,
        representatives: representativePayload,
        // contacts: carrierContactPayload,
        lobs: lineOfBusinesses?.lineOfBusiness.map((lob) => lob?.value),
        // agentCarrierCode: agentCarrierCodesPayload,
      })
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  return (
    <Container>
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}

      <PageHeader padding="true">Add Carrier</PageHeader>
      <Spinner loading={isLoading} />

      <div className={`p-4 ${isLoading && `opacity-30 pointer-events-none`} `}>
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name *"
              placeholder="Enter Name"
              value={carrier.name}
              errorMessage={errors.nameError}
              setValue={(e) => handleCarrierChange(`name`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Federal Emp Identity No *"
              placeholder="Enter Value"
              value={carrier.fein}
              errorMessage={errors.feinError}
              setValue={(e) => handleCarrierChange(`fein`, e.target.value)}
              mask="999999999"
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="SIC *"
              placeholder="Enter Value"
              errorMessage={errors.sicError}
              value={carrier.sic}
              setValue={(e) => handleCarrierChange(`sic`, e.target.value)}
              mask="9999"
            />
            {/* <InputField
              type="text"
              title="SIC *"
              placeholder="Enter value"
              value={carrier.sic}
              errorMessage={errors.sicError}
              setValue={(e) => handleCarrierChange(`sic`, e.target.value)}
              pattern="[0-9]{3}"
            /> */}
          </div>
          <div className="flex-1 relative">
            <InputField
              type="number"
              title="Year Business Started *"
              placeholder="Enter Year"
              value={carrier.yearBusinessStarted}
              errorMessage={errors.yearBusinessStartedError}
              setValue={(e) =>
                handleCarrierChange(`yearBusinessStarted`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="NAIC *"
              placeholder="Enter Value"
              tipdata="National Association of Insurance Commissioners"
              value={carrier.naic}
              errorMessage={errors.naicError}
              setValue={(e) => handleCarrierChange(`naic`, e.target.value)}
              mask="99999"
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-6 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title=" Finance Co-Search Code *"
              placeholder="Enter Code"
              value={carrier.financeCoSearchCode}
              errorMessage={errors.financeCoSearchCodeError}
              setValue={(e) =>
                handleCarrierChange(`financeCoSearchCode`, e.target.value)
              }
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Description *"
              placeholder="Add Description"
              value={carrier.description}
              errorMessage={errors.descriptionError}
              setValue={(e) =>
                handleCarrierChange(`description`, e.target.value)
              }
            />
          </div>
          {/* <div className="flex-1 relative">
            <InputField
              type="text"
              title="Producer Code *"
              placeholder="Add Producer Code"
              value={carrier.producerCode}
              errorMessage={errors.producerCodeError}
              setValue={(e) =>
                handleCarrierChange(`producerCode`, e.target.value)
              }
            />
          </div> */}

          <div className="flex-1 relative">
            {/* <div className="flex-1 relative mt-6">
              <Checkbox
                type="checkbox"
                name="isActive"
                title="Is Active"
                value={carrier.isActive}
                errorMessage={errors.isActiveError}
                setValue={() =>
                  setCarrier((prevState) => ({
                    ...prevState,
                    [`isActive`]: !carrier.isActive,
                  }))
                }
              />
            </div> */}
          </div>
          <div className="flex-1 relative"></div>
          <div className="flex-1 relative"></div>
        </div>
        {/* Address and map */}
        <SecondaryHeading>Address</SecondaryHeading>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street *"
              placeholder="Enter Street"
              value={address.streetNo}
              errorMessage={errors.streetNoError}
              setValue={(e) => handleAddressChange(`streetNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              name="zip"
              placeholder="Select Zip"
              isMulti={false}
              value={address.zip}
              errorMessage={errors.zipError}
              validating={carrierZipValidate}
              setValue={(e) => handleZipChange(e)}
              loadOptions={loadZipCodes}
              isSearchable={true}
            />
          </div>

          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No"
              placeholder="Enter Unit No"
              value={address.unitNo}
              errorMessage={errors.unitNoError}
              setValue={(e) => handleAddressChange(`unitNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="city"
              title="City *"
              placeholder="Enter City"
              value={address.city}
              errorMessage={errors.cityError}
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              name="state"
              title="State *"
              placeholder="Enter State"
              value={address.state}
              errorMessage={errors.stateError}
              id="lAddress"
              disabled={true}
            />
          </div>
        </div>
        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError.length !== 0 && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView center={[coordinates.lat, coordinates.lng]} zoom={3} />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => setCoordinates(e)}
            />
          </MapContainer>
        </div>
        {/* Start Bussiness information */}
        {/* <div className="my-4">
          <Collapsable title="Business/Payment Information ">
            <div className="grid grid-cols-2 gap-16">
              <div className="col-1-1">
                <div className="flex gap-2 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <InputField
                      type="number"
                      title="Minimum Down Payment"
                      name="minimumDownPayment"
                      placeholder="Enter Payment"
                      errorMessage={errors.minimumDownPaymentError}
                      value={carrier.minimumDownPayment}
                      setValue={(e) => handleCarrierChange(`name`,e.target.value)}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Payment Type"
                      name="minimumDownPaymentType"
                      placeholder="Choose Option"
                      isMulti={false}
                      value={carrier.minimumDownPaymentType}
                      errorMessage={errors.minimumDownPaymentTypeError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`minimumDownPaymentType`]: e,
                        }))
                      }
                      loadOptions={loadpaymentType}
                      isSearchable={true}
                    />
                  </div>
                </div>
                <div className="flex gap-2 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <InputField
                      type="number"
                      title="Interest Rate"
                      name="interestRate"
                      placeholder="Enter Value"
                      value={carrier.interestRate}
                      errorMessage={errors.interestRateError}
                      setValue={(e) => handleCarrierChange(e)}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Type"
                      name="interestrateType"
                      placeholder="Choose Option"
                      isMulti={false}
                      value={carrier.interestrateType}
                      errorMessage={errors.interestrateTypeError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`interestrateType`]: e,
                        }))
                      }
                      loadOptions={loadpaymentType}
                      isSearchable={true}
                    />
                  </div>
                </div>
                <div className="flex gap-2 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <InputField
                      type="number"
                      title="Minimum Earned"
                      name="minimumEarned"
                      placeholder="Enter Value"
                      value={carrier.minimumEarned}
                      errorMessage={errors.minimumEarnedError}
                      setValue={(e) => handleCarrierChange(e)}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Type"
                      name="minimumEarnedtype"
                      placeholder="Choose Option"
                      isMulti={false}
                      value={carrier.minimumEarnedtype}
                      errorMessage={errors.minimumEarnedtypeError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`minimumEarnedtype`]: e,
                        }))
                      }
                      loadOptions={loadpaymentType}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="flex gap-4 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <InputField
                      type="text"
                      title="Remit By"
                      name="remitBy"
                      placeholder="Enter Value"
                      errorMessage={errors.remitByError}
                      value={carrier.remitBy}
                      setValue={(e) => handleCarrierChange(e)}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <DatePickerField
                      name="grossComissionDueWithin"
                      title="Gross Comission Due Within"
                      placeholder="Select Date "
                      errorMessage={errors.grossComissionDueWithinError}
                      value={carrier.grossComissionDueWithin}
                      setValue={(date) => {
                        setCarrier((preState) => ({
                          ...preState,
                          [`grossComissionDueWithin`]: date,
                        }))
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <InputField
                      type="number"
                      title="Remaining Payments"
                      name="remainingPayments"
                      placeholder="Enter Payment"
                      errorMessage={errors.remainingPaymentsError}
                      value={carrier.remainingPayments}
                      setValue={(e) => handleCarrierChange(e)}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="A.M Best Rating BCR"
                      name="amBestRatingBCR"
                      placeholder="Select Rating"
                      isMulti={false}
                      value={carrier.amBestRatingBCR}
                      errorMessage={errors.amBestRatingBCRError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`amBestRatingBCR`]: e,
                        }))
                      }
                      loadOptions={loadbestRatingBCR}
                      isSearchable={true}
                    />
                  </div>
                </div>
                <div className="flex gap-4 mt-2 text-xs">
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="A.M Best Rating FSC"
                      name="amBestRatingFSC"
                      placeholder="Select Rating"
                      isMulti={false}
                      value={carrier.amBestRatingFSC}
                      errorMessage={errors.amBestRatingFSCError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`amBestRatingFSC`]: e,
                        }))
                      }
                      loadOptions={loadbestRatingFSC}
                      isSearchable={true}
                    />
                  </div>
                  <div className="flex-1 relative">
                    <ReactSelectField
                      title="Premium Sent"
                      name="premiumSent"
                      placeholder="Choose Option"
                      isMulti={false}
                      value={carrier.premiumSent}
                      errorMessage={errors.premiumSentError}
                      setValue={(e) =>
                        setCarrier((prevState) => ({
                          ...prevState,
                          [`premiumSent`]: e,
                        }))
                      }
                      loadOptions={loadpremiumSent}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Collapsable>
        </div> */}
        {/* End Bussiness information */}

        {/*Start line of business */}
        <div className="my-4">
          <Collapsable title="Line of business">
            <div className="grid grid-cols-12">
              <div className="col-span-12 flex gap-6 ">
                <div className="flex-1 relative">
                  <ReactSelectField
                    title="Line Of Business *"
                    placeholder="Select LOB"
                    value={lineOfBusinesses.lineOfBusiness}
                    errorMessage={lineOfBusinesses.lineOfBusinessError}
                    setValue={(value) => {
                      if (value?.length > 0) {
                        handlelineOfBusinessErrors(`lineOfBusinessError`, ``)
                      }
                      setLineOfBusinesses((prevState) => ({
                        ...prevState,
                        [`lineOfBusiness`]: value,
                      }))
                    }}
                    isMulti={true}
                    loadOptions={loadLobs}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
          </Collapsable>
        </div>
        {/*End Lobs */}

        {/* Start Contacts */}
        {/* <div className="my-4">
          <Collapsable title="Contacts">
            <div className="flex items-center py-2">
              <TabsHeaders
                openTab={activeContactTab}
                tabs={CTabs}
                getActiveTab={(val) => setActiveContactTab(val)}
                customTabs="YES"
              />
              <div className="px-2">
                <AddTabButton onClick={() => handleCarrierContactAddForm()}>
                  <FontAwesomeIcon icon={faPlus} />
                </AddTabButton>
              </div>
            </div>
            <div className="grid gap-10">
              {carrierContacts?.map((_contact, index) => (
                <FormTab
                  key={index}
                  currenttab={index + 1}
                  opentab={activeContactTab}
                  className="border-2 border-gray-100 shadow-sm rounded-md relative"
                >
                  <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                    <div className="flex-1">Contact {index + 1}</div>
                    <div className="flex-1">
                      <div className="float-right">
                        <RemoveLocation
                          onClick={() => {
                            handleCarrierContactRemoveForm(index)
                            if (index > 0) setActiveContactTab(index)
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} size="1x" />
                        </RemoveLocation>
                      </div>
                    </div>
                  </div>
                  <div className="p-4"> */}
        {/* Carrier Contact basic form */}
        {/* <div className="flex gap-6 mt-2 text-xs">
                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          title="Name"
                          name="name"
                          placeholder="Enter Name"
                          errorMessage={_contact.nameError}
                          value={_contact.name}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `name`,
                              index,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          title="Designation"
                          name="designation"
                          placeholder="Enter value"
                          errorMessage={_contact.designationError}
                          value={_contact.designation}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `designation`,
                              index,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1 relative">
                        <MaskField
                          type="text"
                          title="Mobile"
                          name="mobile"
                          placeholder="Enter mobile"
                          errorMessage={_contact.mobileError}
                          value={_contact.mobile}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `mobile`,
                              index,
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="flex gap-6 mt-2 text-xs">
                      <div className="flex-1 relative">
                        <MaskField
                          type="text"
                          title="Phone"
                          name="phone"
                          placeholder="Enter phone"
                          errorMessage={_contact.phoneError}
                          value={_contact.phone}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `phone`,
                              index,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1 relative">
                        <MaskField
                          type="text"
                          title="Extension"
                          placeholder="Enter Ext"
                          errorMessage={_contact.extError}
                          value={_contact.ext}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `ext`,
                              index,
                            )
                          }
                          mask="9999"
                        />
                      </div>
                      <div className="flex-1 relative">
                        <MaskField
                          type="text"
                          title="Fax"
                          name="fax"
                          placeholder="Enter Fax"
                          value={_contact.fax}
                          errorMessage={_contact.faxError}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `fax`,
                              index,
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="flex gap-6 mt-2 text-xs">
                      <div className="flex-1 relative">
                        <InputField
                          type="email"
                          name="email"
                          title="Email"
                          placeholder="Enter Email "
                          value={_contact.email}
                          errorMessage={_contact.emailError}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `email`,
                              index,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          title="Website"
                          name="mainWebsite"
                          placeholder="Enter Website"
                          errorMessage={_contact.mainWebsiteError}
                          value={_contact.mainWebsite}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `mainWebsite`,
                              index,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          name="agentPortal"
                          title="Agent Portal"
                          placeholder="Enter value"
                          value={_contact.agentPortal}
                          errorMessage={_contact.agentPortalError}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `agentPortal`,
                              index,
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="flex gap-6 mt-2 text-xs">
                      <div className="flex-1 relative">
                        <InputField
                          type="text"
                          name="userName"
                          title="User Name"
                          placeholder="Enter User Name"
                          value={_contact.userName}
                          errorMessage={_contact.userNameError}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `userName`,
                              index,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1 relative">
                        <InputField
                          type="password"
                          name="password"
                          title="Password"
                          placeholder="Enter Password"
                          value={_contact.password}
                          errorMessage={_contact.passwordError}
                          setValue={(e) =>
                            handleCarrierContactFormChange(
                              e.target.value,
                              `password`,
                              index,
                            )
                          }
                        />
                      </div>
                      <div className="flex-1 relative"></div>
                    </div> */}

        {/* Location Address starts from here */}
        {/* </div>
                </FormTab>
              ))}
            </div>
          </Collapsable>
        </div> */}
        {/* End Contacts */}

        {/* Start Representatives */}
        <div className="my-4">
          <Collapsable title="Representative">
            <div className="flex items-center py-2">
              <TabsHeaders
                openTab={activeRepTab}
                tabs={repTabs}
                getActiveTab={(val) => setActiveRepTab(val)}
                customTabs="YES"
              />
              <div className="px-2">
                <AddTabButton onClick={() => addRepresentativeForm()}>
                  <FontAwesomeIcon icon={faPlus} />
                </AddTabButton>
              </div>
            </div>
            <div className="grid gap-10">
              {representative.length > 0 &&
                representative?.map((reps, index) => (
                  <FormTab
                    key={index}
                    currenttab={index + 1}
                    opentab={activeRepTab}
                    className="border-2 border-gray-100 shadow-sm rounded-md relative"
                  >
                    <div className="text-sm font-medium py-1 px-4 bg-red-500  rounded-t-md text-white flex">
                      <div className="flex-1">Representative {index + 1}</div>
                      <div className="flex-1">
                        <div className="float-right">
                          <RemoveLocation
                            onClick={() => {
                              removeRepresentativeForm(index)
                              if (index > 0) setActiveRepTab(index)
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} size="1x" />
                          </RemoveLocation>
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      {/* Locations basic form */}

                      <div className="flex gap-6 mt-2 text-xs">
                        <div className="flex-1 relative">
                          <InputField
                            type="text"
                            title="First Name *"
                            name="firstName"
                            placeholder="Enter Name"
                            value={reps.firstName}
                            errorMessage={reps.firstNameError}
                            setValue={(e) =>
                              handleRepresentativeFormChange(
                                e.target.value,
                                `firstName`,
                                index,
                              )
                            }
                          />
                        </div>
                        <div className="flex-1 relative">
                          <InputField
                            type="text"
                            title="Last Name *"
                            name="lastName"
                            placeholder="Enter Last Name"
                            value={reps.lastName}
                            errorMessage={reps.lastNameError}
                            setValue={(e) =>
                              handleRepresentativeFormChange(
                                e.target.value,
                                `lastName`,
                                index,
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-6 mt-2 text-xs">
                        <div className="flex-1 relative">
                          <InputField
                            type="text"
                            title="MI *"
                            name="mi"
                            placeholder="Enter MI"
                            value={reps.mi}
                            errorMessage={reps.miError}
                            setValue={(e) =>
                              handleRepresentativeFormChange(
                                e.target.value,
                                `mi`,
                                index,
                              )
                            }
                          />
                        </div>
                        <div className="flex-1 relative">
                          <InputField
                            type="text"
                            title="Email *"
                            name="remail"
                            placeholder="Enter Email"
                            value={reps.remail}
                            errorMessage={reps.remailError}
                            setValue={(e) =>
                              handleRepresentativeFormChange(
                                e.target.value,
                                `remail`,
                                index,
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="flex gap-6 mt-2 text-xs">
                        <div className="flex-1 relative">
                          <MaskField
                            type="text"
                            title="Cell Phone *"
                            name="rphone"
                            placeholder="Enter Phone No"
                            errorMessage={reps.rphoneError}
                            value={reps.rphone}
                            setValue={(e) =>
                              handleRepresentativeFormChange(
                                e.target.value,
                                `rphone`,
                                index,
                              )
                            }
                          />
                        </div>
                        <div className="flex-1 relative">
                          <InputField
                            type="text"
                            title="Note *"
                            name="note"
                            placeholder="Enter Note"
                            value={reps.note}
                            errorMessage={reps.noteError}
                            setValue={(e) =>
                              handleRepresentativeFormChange(
                                e.target.value,
                                `note`,
                                index,
                              )
                            }
                          />
                        </div>
                      </div>

                      {/* Location Address starts from here */}
                    </div>
                  </FormTab>
                ))}
            </div>
          </Collapsable>
        </div>
        {/* End Representatives */}
      </div>
      <div className="flex justify-center">
        <div className="text-center w-96 my-4">
          <SubmitButton onClick={ValidForm}>
            <div>Create Carrier</div>
          </SubmitButton>
        </div>
      </div>
    </Container>
  )
}
