import React, { useState, useEffect, useRef } from 'react'

const Participant = ({
  participant,
  isSinlge,
  mute,
  mobileParticipant,
  lessThanOne,
  moreThanFour,
}) => {
  const [videoTracks, setVideoTracks] = useState([])
  const [audioTracks, setAudioTracks] = useState([])

  // const [mute, setMute] = useState(false)
  // const [cameraOff, setCameraOff] = useState(false)
  const videoRef = useRef()
  const audioRef = useRef()

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null)

  useEffect(() => {
    const trackSubscribed = (track) => {
      if (track.kind === `video`) {
        setVideoTracks((videoTracks) => [...videoTracks, track])
        // setCameraOff(false)
      } else {
        setAudioTracks((audioTracks) => [...audioTracks, track])
        // setMute(false)
      }
    }

    const trackUnsubscribed = (track) => {
      if (track.kind === `video`) {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track))
        // setCameraOff(true)
      } else {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track))
        // setMute(true)
      }
    }

    setVideoTracks(trackpubsToTracks(participant.videoTracks))
    setAudioTracks(trackpubsToTracks(participant.audioTracks))

    participant.on(`trackSubscribed`, trackSubscribed)
    participant.on(`trackUnsubscribed`, trackUnsubscribed)

    return () => {
      setVideoTracks([])
      setAudioTracks([])
      participant.removeAllListeners()
    }
  }, [participant])

  useEffect(() => {
    const videoTrack = videoTracks[0]
    if (videoTrack) {
      videoTrack.attach(videoRef.current)
      return () => {
        videoTrack.detach()
      }
    }
  }, [videoTracks])

  useEffect(() => {
    const audioTrack = audioTracks[0]
    if (audioTrack) {
      audioTrack.attach(audioRef.current)
      return () => {
        audioTrack.detach()
      }
    }
  }, [audioTracks])

  return (
    <>
      {/* {cameraOff && (
        <h3 className="absolute top-1/2 left-1/2 text-2xl w-24 h-24 rounded-full flex justify-center items-center border-2 border-white font-medium capitalize text-red-600 z-30">
          {participant?.identity?.trim(``)[0]}
        </h3>
      )} */}

      {/* <div className='absolute capitalize px-3 py-2 bg-white font-semibold'>{participant?.identity}</div> */}
      <div
        className={` border-1
          ${isSinlge ? `video-wrapSingle` : `video-wrap`}
         ${
           mobileParticipant && isSinlge
             ? `singleParticipant`
             : moreThanFour
             ? `singleParticipant mb-10`
             : `video-wrap mb-10`
         }   
         
        `}
      >
        <video
          ref={videoRef}
          className={`relative object-contain ${
            mobileParticipant && lessThanOne && `mb-32`
          } `}
          autoPlay={true}
        ></video>
        <h3 className="absolute py-2 px-4 bg-gray-600 text-white text-base z-30">
          {participant?.identity?.trim(``)} {mute && <span>muted</span>}
        </h3>
      </div>
      <audio ref={audioRef} autoPlay={true} />
    </>
  )
}

export default Participant
