import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { Spinner } from 'App/Components/Common/Spinner'
import { useMutation } from 'react-query'
import { AMS_BACKEND_API } from 'App/Config/backendApis'
import { AxiosInstance } from 'App/Config'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { useSelector } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import { TextArea } from 'App/Components/Common/TextArea'
import { loadStates } from 'App/Services'
import { useDebouncedCallback } from 'use-debounce'

const UpdateGroupCarrier = () => {
  const history = useHistory()
  const {
    params: { groupCarrierId },
  } = useRouteMatch()
  const { currentGroupCarrier } = useSelector(({ carrier }) => carrier)
  const [carrierNameValidating, setCarrierNameValidating] = useState(false)

  const [carrier, setCarrier] = useState({
    name: ``,
    state: ``,
    carriers: ``,
    description: ``,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    nameError: ``,
    stateError: ``,
    carriersError: ``,
    descriptionError: ``,
  })

  useEffect(() => {
    if (currentGroupCarrier) {
      setCarrier((preState) => ({
        ...preState,
        name: currentGroupCarrier?.group?.name,
        state: {
          value: currentGroupCarrier?.group?.state,
          label: currentGroupCarrier?.group?.state,
        },
        carriers: currentGroupCarrier?.group?.carriers?.map((carrier) => {
          return carrier
        }),
        description: currentGroupCarrier?.group?.description,
      }))
    }
  }, [currentGroupCarrier])

  const {
    mutate,
    isLoading,
    isError: updationError,
  } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/carriers-groups/${groupCarrierId}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          if (res.data) {
            NotificationManager.success(`Operation successful`)
            res.data && history.push(`/group-carriers/${groupCarrierId}`)
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.error.error.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            } else {
              NotificationManager.error(`Something went Wrong!`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const ValidateDuplicateCarrierName = async (payload) => {
    return await AxiosInstance.post(
      `${AMS_BACKEND_API}/api/validate/carriers-group-payloads`,
      payload,
    )
      .then((res) => {
        return res?.data
      })
      .catch((error) => {
        return error.response.data
      })
  }

  const validateCarrierName = useDebouncedCallback(async (value) => {
    setCarrierNameValidating(true)
    const response = await ValidateDuplicateCarrierName({ name: value })

    if (response?.hasError) {
      handleErrors(`formSubmit`, false)
      handleErrors(`nameError`, `Name Already Exists`)
    } else {
      handleErrors(`formSubmit`, false)
      handleErrors(`nameError`, ``)
    }
    setCarrierNameValidating(false)
  }, 2500)
  const validateForm = () => {
    if (!carrier.name?.length) {
      handleErrors(`nameError`, `Select Name`)
    } else {
      if (errors.nameError !== `Name Already Exists`) {
        handleErrors(`nameError`, ``)
      }
    }
    if (!carrier.state?.value?.length) {
      handleErrors(`stateError`, `Select State`)
    } else {
      handleErrors(`stateError`, ``)
    }

    if (!carrier.carriers?.length) {
      handleErrors(`carriersError`, `Select Carrier`)
    } else {
      handleErrors(`carriersError`, ``)
    }

    if (!carrier?.description?.length) {
      handleErrors(`descriptionError`, `Enter Description`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.stateError.length &&
      !errors.carriersError.length &&
      !errors.descriptionError.length
    ) {
      mutate(preparePayload())
      handleErrors(`formSubmit`, false)
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const preparePayload = () => {
    let data = {}
    if (carrier?.name !== currentGroupCarrier?.group?.name) {
      data.name = carrier.name
    }
    if (carrier?.state?.value !== currentGroupCarrier?.group?.state) {
      data.state = carrier.state?.value
    }

    const isCarrierUpdated = carrier?.carriers?.some(
      (_eachCarrier, index) =>
        _eachCarrier.value !== currentGroupCarrier?.group?.carriers[index],
    )
    if (isCarrierUpdated) {
      data._carriers = carrier?.carriers?.map((carrier) => {
        return carrier.value
      })
    }

    if (carrier?.description !== currentGroupCarrier?.group?.description) {
      data.description = carrier.description
    }

    return data
  }

  const loadCarriers = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/carriers/list/all?state=${
        carrier?.state?.value
      }&search=${search ? search : ``}&page=${page}&offset=10`,
    )

    if (data) {
      return {
        options: data.carriers,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  return (
    <Container>
      <PageHeader padding="true">Update Group Carrier</PageHeader>

      {updationError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div
        className={`px-4 bg-white ${
          isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        {/* titles basic form */}
        <div className="flex gap-6 my-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name *"
              placeholder="Enter Name"
              value={carrier.name}
              errorMessage={errors.nameError}
              validating={carrierNameValidating}
              setValue={(e) => {
                validateCarrierName(e.target.value)
                setCarrier((preState) => ({
                  ...preState,
                  name: e.target.value,
                }))
              }}
              maxlength={`100`}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="State *"
              placeholder="Select State"
              errorMessage={errors.stateError}
              value={carrier.state}
              setValue={(value) => {
                setCarrier((prevState) => ({
                  ...prevState,
                  state: value,
                }))
              }}
              isMulti={false}
              loadOptions={loadStates}
              isSearchable={true}
              disabled={true}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Carriers *"
              placeholder="Select Carriers"
              value={carrier.carriers}
              errorMessage={errors.carriersError}
              setValue={(value) => {
                setCarrier((preState) => ({
                  ...preState,
                  carriers: value,
                }))
              }}
              isMulti={true}
              loadOptions={carrier?.state?.value ? loadCarriers : ``}
              isSearchable={true}
              cacheUniqs={carrier?.state}
            />
          </div>
        </div>
        <div className="flex gap-6 my-1 relative">
          <div className="flex-1 ">
            <TextArea
              type="textarea"
              title="Description *"
              placeholder="Enter description"
              autoFocus={false}
              value={carrier?.description}
              errorMessage={errors?.descriptionError}
              setValue={(e) => {
                setCarrier((preState) => ({
                  ...preState,
                  description: e.target.value,
                }))
              }}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="text-center w-96 my-5">
            <SubmitButton onClick={validateForm}>
              Update Group Carrier
            </SubmitButton>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default UpdateGroupCarrier
