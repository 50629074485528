import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect, useRef } from 'react'
import Video from 'twilio-video'
import Participant from '../Participant'
import {
  faMicrophoneSlash,
  faPhoneSlash,
  faVideoSlash,
} from '@fortawesome/free-solid-svg-icons'
import { Loader } from 'App/Components/Common/Loader'
import { CallBlock } from 'App/Styled'
import { useSelector } from 'react-redux'

const Room = ({ roomName, token, handleLogout }) => {
  const [room, setRoom] = useState(null)
  const [loading, setLoading] = useState(false)
  const [mute, setMute] = useState(false)
  const [cameraOff, setCameraOff] = useState(false)
  const [participants, setParticipants] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [maxGrid, setMaxGrid] = useState(4)
  const { user } = useSelector(({ user }) => user)

  const [localParticipant, setLocalParticipant] = useState(``)

  let widthRef = useRef()

  useEffect(() => {
    setLoading(true)
    Video.connect(token, {
      name: roomName,
    }).then((room) => {
      setRoom(room)

      setLoading(false)
      room.on(`participantConnected`, (participant) => {
        setParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ])
      })
      room.on(`participantDisconnected`, (participant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant),
        )
      })
      room.participants.forEach((participant) => {
        setParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ])
      })
    })

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === `connected`) {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication,
          ) {
            trackPublication.track.stop()
          })
          currentRoom.disconnect()
          return null
        } else {
          currentRoom?.disconnect()

          return currentRoom
        }
      })
      setParticipants([])
    }
  }, [token])

  const disconnectParticipant = () => {
    room.participants.forEach(() => {
      // remoteParticipant.disconnect()
    })
    room.disconnect()
  }

  useEffect(() => {
    setLocalParticipant(room?.localParticipant)
  }, [participants, room])

  useEffect(() => {
    localStorage.setItem(`participantLength`, participants.length)
  }, [participants.length])

  useEffect(() => {
    window.addEventListener(`beforeunload`, disconnectParticipant)
    window.addEventListener(`unload`, disconnectParticipant)
    return () => {
      window.removeEventListener(`beforeunload`, disconnectParticipant)
      window.removeEventListener(`unload`, disconnectParticipant)
    }
  })

  return (
    <div
      className={`relative ${
        loading ? `p-20` : ``
      } h-screen w-screen flex justify-center 
       bg-black `}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            ref={widthRef}
            className={` ${
              participants.length > 1 ? `h-5/6` : `h-auto`
            }  w-full p-1 my-auto ${
              !participants.length && `place-items-center`
            }`}
          >
            {/* DeskTop Participant */}
            <div
              className={`desktopParticipant flex-wrap justify-center items-center gap-2  ${
                participants.length === 0 ? `h-full` : `h-96`
              } `}
            >
              {/* <span className="local_participiants"> */}
              {localParticipant && (
                <Participant
                  key={localParticipant.sid}
                  participant={localParticipant}
                  isSinlge={participants.length === 0}
                  moreThanFour={participants.length > 3}
                  cameraOff={cameraOff}
                  localParticipant="local-participant"
                  mute={mute}
                />
              )}
              {/* </span> */}

              {participants?.map((participant) => (
                <Participant
                  key={participant.sid}
                  participant={participant}
                  isSinlge={participants.length === 0}
                  moreThanFour={participants.length > 3}
                  cameraOff={cameraOff}
                  // mute={mute}
                />
              ))}
            </div>

            <div
              className={`mobileParticipant flex-wrap justify-center ${
                participants.length === 0 ? `h-full` : `h-1/2`
              } `}
            >
              <span className="absolute bottom-7 right-0 h-72">
                {localParticipant && (
                  <Participant
                    key={localParticipant.sid}
                    participant={localParticipant}
                    isSinlge={participants.length > 0}
                    moreThanFour={participants.length > 2}
                    cameraOff={cameraOff}
                    localParticipant="local-participant"
                    mute={mute}
                    mobileParticipant={true}
                  />
                )}
              </span>

              {participants?.map((participant) => (
                <span
                  className={`videoParticipant ${
                    participants.length === 1 && `mb-16`
                  }`}
                  key={participant.sid}
                >
                  <Participant
                    participant={participant}
                    // isSinlge={participants.length === 0}
                    lessThanOne={participants.length === 1}
                    moreThanFour={participants.length > 1}
                    cameraOff={cameraOff}
                    // mute={mute}
                    mobileParticipant={true}
                  />
                </span>
              ))}
            </div>
            {/* <div
                style={{
                  display: `grid`,
                  gridTemplateColumns: `repeat(${
                    participants.length > maxGrid
                      ? maxGrid
                      : participants.length
                  },1fr)`,
                  gridTemplateRows: `repeat(${gridRows},1fr)`,
                  maxWidth: `${widthRef?.current?.offsetWidth}px`,
                  placeItems: `center`,
                  gap: `0.5rem`,
                }}
                className={`remote_participiants ${
                  participants.length ? `col-span-12 sm:col-span-9` : `w-0`
                } space-x-2 px-1 py-2`}
              >
                {participants?.map((participant) => (
                  <Participant
                    key={participant.sid}
                    participant={participant}
                    isSinlge={participants.length === 0}
                    cameraOff={cameraOff}
                    mute={mute}
                  />
                ))}
              </div> */}

            {/* <div
              className={`local_participiants ${
                !participants.length ? `col-span-12 ` : `col-span-2 ml-24`
              } flex justify-end items-end`}
            >
              <div className="p-2">
                {localParticipant && (
                  <Participant
                    key={localParticipant.sid}
                    participant={localParticipant}
                    isSinlge={participants.length === 0}
                    cameraOff={cameraOff}
                    localParticipant="local-participant"
                    mute={mute}
                  />
                )}
              </div>
            </div> */}
          </div>

          {/* controls */}
          <div
            style={{ height: `10%` }}
            className="flex justify-center items-center m-1 absolute bottom-0 left-1/2 transform -translate-x-1/2 px-6 gap-4 w-auto "
          >
            <div>
              <CallBlock
                data-tip={`${mute ? `Unmute` : `Mute`} `}
                className={`tooltip  ${
                  mute
                    ? `text-red-500 hover:text-red-800`
                    : `hover:text-gray-600`
                }`}
                onClick={() => {
                  room.localParticipant.audioTracks.forEach(({ track }) => {
                    if (track.isEnabled) {
                      track.disable()
                      setMute(true)
                    } else {
                      track.enable()
                      setMute(false)
                    }
                  })
                }}
              >
                <FontAwesomeIcon
                  icon={faMicrophoneSlash}
                  className="mt-0.5 cursor-pointer"
                  size="lg"
                />
              </CallBlock>
            </div>
            <div className="">
              <CallBlock
                data-tip={`${
                  cameraOff ? `Turn On Camera` : `Turn OFF Camera`
                } `}
                className={`tooltip ${
                  cameraOff
                    ? `text-red-500 hover:text-red-800`
                    : `hover:text-gray-600`
                }`}
                onClick={() => {
                  room.localParticipant.videoTracks.forEach(({ track }) => {
                    if (track.isEnabled) {
                      track.disable()
                      setCameraOff(true)
                    } else {
                      track.enable()
                      setCameraOff(false)
                    }
                  })
                }}
              >
                <FontAwesomeIcon
                  icon={faVideoSlash}
                  className="text-red mt-0.5 cursor-pointer"
                  size="lg"
                />
              </CallBlock>
            </div>
            <div>
              <CallBlock
                data-tip="HangUp"
                className="tooltip hover:text-gray-600"
                onClick={() => {
                  if (room.localParticipant) {
                    room.localParticipant.videoTracks.forEach(({ track }) => {
                      if (!track.isStopped) {
                        track.stop()
                      }
                    })
                  }
                  if (user?.profile?.sub) {
                    disconnectParticipant()
                  }
                  handleLogout()
                }}
              >
                <FontAwesomeIcon
                  icon={faPhoneSlash}
                  className="text-red-500 mt-0.5 cursor-pointer"
                  size="lg"
                />
              </CallBlock>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Room
