const moderators = [`corporate-manager:*`, `manager:*`, `admin:*`]
export const allowedSystemPermissions = {
  task: [
    `manager:*`,
    `admin:*`,
    `task:*`,
    `task:create`,
    `task:read`,
    `task:update`,
    `task:delete`,
  ],
  assessment: [
    `manager:*`,
    `admin:*`,
    `evaluate:create`,
    `evaluate:read`,
    `evaluate:update`,
    `evaluate:*`,
    `assessment-team:*`,
    `assessment-team:create`,
    `assessment-team:read`,
    `assessment-team:update`,
    `assessment-team:delete`,
    `assessment-template:*`,
    `assessment-template:create`,
    `assessment-template:read`,
    `assessment-template:update`,
    `assessment-template:delete`,
  ],
  goals: [
    `monthly-goals:*`,
    `monthly-goals:read`,
    `monthly-goals:create`,
    `monthly-goals:update`,
    `monthly-goals:delete`,
    `manager:*`,
    `admin:*`,
    `system-admin:*`
  ],
  ticket: [
    `manager:*`,
    `admin:*`,
    `ticket-category:*`,
    `ticket-category:create`,
    `ticket-category:read`,
    `ticket-category:update`,
    `ticket-category:delete`,
    `ticket:*`,
    `ticket:create`,
    `ticket:read`,
    `ticket:update`,
    `ticket:delete`,
  ],
  users: [
    `users:*`,
    `users:create`,
    `users:read`,
    `users:update`,
    `users:delete`,
    `users:list`,
  ],
  dailyLedger: [
    ...moderators,
    `payments:*`,
    `payments:create`,
    `payments:read`,
    `payments:update`,
  ],
  credential: [
    ...moderators,
    `credentials:*`,
    `credentials:read`,
    `credentials:update`,
    `admin:*`,
  ],
  evaluator: [
    ...moderators,
    `evaluate:create`,
    `evaluate:read`,
    `evaluate:update`,
    `evaluate:*`,
  ],
}
//   `admin:master-franchise`,
//   `user:master-franchise`,
//   `accountant`,
//   `admin:franchise`,
//   `analyze-document:*`,
//   `broadcasts:*`,
//   `broadcasts:create`,
//   `broadcasts:read`,
//   `broadcasts:update`,
//   `rating-templates:*`,
//   `rating-templates:read`,
//   `rating-templates:create`,
//   `rating-templates:update`,
//   `rating-templates:delete`,
//   // `broadcasts:delete`,
//   `corporate-account-payment:*`,
//   `franchise-account-payment:*`,
//   `customer-file:*`,
//   `customer-file:create`,
//   `customer-file:read`,
//   `customer-file:update`,
//   `customer-file:delete`,
//   `customers:*`,
//   `customers:create`,
//   `customers:read`,
//   `customers:update`,
//   `customers:delete`,
//   `claims:*`,
//   `claims:create`,
//   `claims:read`,
//   `claims:update`,
//   `claims:delete`,
//   `evaluate:create`,
//   `evaluate:read`,
//   `evaluate:update`,
//   `evaluate:*`,
//   `assessment-team:*`,
//   `assessment-team:create`,
//   `assessment-team:read`,
//   `assessment-team:update`,
//   `assessment-team:delete`,
//   `assessment-template:*`,
//   `assessment-template:create`,
//   `assessment-template:read`,
//   `assessment-template:update`,
//   `assessment-template:delete`,
//   `uploadFile:*`,
//   `uploadFile:create`,
//   `uploadFile:read`,
//   `ticket-category:*`,
//   `ticket-category:create`,
//   `ticket-category:read`,
//   `ticket-category:update`,
//   `ticket-category:delete`,

//   `ticket:*`,
//   `ticket:create`,
//   `ticket:read`,
//   `ticket:update`,
//   `ticket:delete`,
//   `credentials:*`,
//   `credentials:read`,
//   `credentials:update`,
//   `dashboard:read`,
//   `data-transaction:*`,
//   `data-transaction:read`,
//   `data-transaction:update`,
//   `designation:*`,
//   `designation:create`,
//   `designation:read`,
//   `designation:update`,
//   `designation:delete`,
//   `get:*`,
//   `global-communication:read`,
//   `locations:*`,
//   `locations:create`,
//   `locations:read`,
//   `locations:update`,
//   `locations:delete`,
//   `leads:*`,
//   `leads:read`,
//   `leads:update`,
//   `monthly-goals:*`,
//   `monthly-goals:read`,
//   `monthly-goals:create`,
//   `monthly-goals:update`,
//   `monthly-goals:delete`,
//   `messenger:read`,
//   `notes:*`,
//   `notes:create`,
//   `notes:read`,
//   `notes:update`,
//   `notes:delete`,
//   `payments:*`,
//   `payments:create`,
//   `payments:read`,
//   `payments:update`,
//   `payments-templates:*`,
//   `payments-templates:create`,
//   `payments-templates:read`,
//   `payments-templates:update`,
//   `payments-templates:delete`,
//   `policies:*`,
//   `policies:create`,
//   `policies:read`,
//   `quotes:*`,
//   `quotes:create`,
//   `quotes:read`,
//   `quotes:update`,
//   `quotes-estimate:create`,
//   `reports:*`,
//   `daily-ledger-reports:read`,
//   `policies-reports:read`,
//   `system-roles:*`,
//   `system-roles:create`,
//   `system-roles:read`,
//   `system-roles:update`,
//   `system-roles:delete`,
//   `broadcast-templates:*`,
//   `broadcast-templates:create`,
//   `broadcast-templates:read`,
//   `broadcast-templates:update`,
//   `broadcast-templates:delete`,
//   `users:*`,
//   `users:create`,
//   `users:read`,
//   `users:update`,
//   `users:delete`,
//   `users:list`,
//   `password:read`,
//   `other-customers:*`,
//   `other-customers:read`,
//   `other-customers:update`,
// ]
