import React from 'react'

export const NotificationCardkelton = () => {
  return (
    <>
      <div
        className="w-full my-1 gap-2 animate-pulse h-20 border-1 border-gray-100 pb-2 shadow-md rounded-md px-2 py-1"
        key={`notification` + 1}
      >
        <div className="bg-gray-100 rounded-md h-4 my-1 w-20 pb-1"></div>
        <div className="bg-gray-100 rounded-md h-4 my-1 w-full pb-1"></div>
        <div className="flex justify-end w-full">
          <div className="bg-gray-100 rounded-md h-4 my-1 w-12 pb-1"></div>
        </div>
      </div>
      <div
        className="w-full my-1 gap-2 animate-pulse h-20 border-1 border-gray-100 pb-2 shadow-md rounded-md px-2 py-1"
        key={`notification` + 2}
      >
        <div className="bg-gray-100 rounded-md h-4 my-1 w-20 pb-1"></div>
        <div className="bg-gray-100 rounded-md h-4 my-1 w-full pb-1"></div>
        <div className="flex justify-end w-full">
          <div className="bg-gray-100 rounded-md h-4 my-1 w-12 pb-1"></div>
        </div>
      </div>
      <div
        className="w-full my-1 gap-2 animate-pulse h-20 border-1 border-gray-100 pb-2 shadow-md rounded-md px-2 py-1"
        key={`notification` + 2}
      >
        <div className="bg-gray-100 rounded-md h-4 my-1 w-20 pb-1"></div>
        <div className="bg-gray-100 rounded-md h-4 my-1 w-full pb-1"></div>
        <div className="flex justify-end w-full">
          <div className="bg-gray-100 rounded-md h-4 my-1 w-12 pb-1"></div>
        </div>
      </div>
      <div
        className="w-full my-1 gap-2 animate-pulse h-20 border-1 border-gray-100 pb-2 shadow-md rounded-md px-2 py-1"
        key={`notification` + 2}
      >
        <div className="bg-gray-100 rounded-md h-4 my-1 w-20 pb-1"></div>
        <div className="bg-gray-100 rounded-md h-4 my-1 w-full pb-1"></div>
        <div className="flex justify-end w-full">
          <div className="bg-gray-100 rounded-md h-4 my-1 w-12 pb-1"></div>
        </div>
      </div>
    </>
  )
}
