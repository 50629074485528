import React, { useEffect, useState } from 'react'
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Svg,
  Line,
} from '@react-pdf/renderer'
import moment from 'moment'

const styles = StyleSheet.create({
  section: {
    height: `auto`,
    margin: `5px`,
    width: `98%`,
  },
  heading: {
    fontSize: `14px`,
    color: `white`,
    borderRadius: `2px`,
    padding: 8,
  },
  pageHeading: {
    fontSize: `18px`,
    color: `black`,
    backgroundColor: `white`,
    padding: 8,
    textAlign: `left`,
    fontWeight: `bold`,
  },
  pageSubHeading: {
    fontSize: `9px`,
    backgroundColor: `white`,
    textAlign: `left`,
  },
  pageFlex: {
    display: `grid`,
    gridTemplateColumns: `auto auto auto`,
    marginLeft: `20px`,
  },
  pageSubHeadingWidth: {
    width: 200,
  },
  text: {
    fontSize: `12px`,
    color: `black`,
    borderRadius: `2px`,
    width: `90%`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    marginHorizontal: 10,
  },
  textHeaders: {
    fontSize: `12px`,
    color: `white`,
    padding: 2,
    width: `100%`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    paddingHorizontal: 20,
    backgroundColor: `gray`,
  },
  textRows: {
    fontSize: `8px`,
    padding: 1.5,
    width: `100%`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    paddingHorizontal: 22,
  },
  textColumns: {
    fontSize: `8px`,
    borderRadius: `2px`,
    padding: 1.5,
    width: `100%`,
    display: `flex`,
    justifyContent: `space-between`,
    paddingHorizontal: 22,
  },
  total: {
    fontSize: `12px`,
    color: `black`,
    borderRadius: `2px`,
    padding: 2,
    width: `90%`,
    display: `flex`,
    flexDirection: `row`,
    justifyContent: `space-between`,
    marginHorizontal: 10,
    borderWidth: 1,
  },

  space: {
    height: `18px`,
    backgroundColor: `white`,
  },
  smallSpace: {
    height: `10px`,
    backgroundColor: `white`,
  },
  borderHorizontal: {
    borderTop: `1px solid black`,
    borderBottom: `1px solid black`,
  },
  pageNumber: {
    position: `absolute`,
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: `center`,
    color: `grey`,
  },
  headingWidth: {
    width: `50px`,
    textAlign: `center`,
  },
  headingWidth2: {
    width: `80px`,
    textAlign: `center`,
  },
})

export const PdfPreview = ({
  paymentsByPaymentMethod,
  paymentsByType,
  paymentsByAgent,
  paymentsByFeeType,
  dateRange,
  report2,
  report3,
}) => {
  const [paymentMethodTotal, setPaymentMethodTotal] = useState(``)
  const [paymentsByAgentTotal, setPaymentsByAgentTotal] = useState(``)
  const [paymentsByFeeTypeTotal, setPaymentsByFeeTypeTotal] = useState(``)
  const [premiumTotal, setPremiumTotal] = useState(``)
  const [nonPremiumTotal, setNonPremiumTotal] = useState(``)

  const currencyConvert = (number) => {
    return new Intl.NumberFormat(`en-US`, {
      style: `currency`,
      currency: `USD`,
    }).format(number)
  }
  useEffect(() => {
    if (
      paymentsByPaymentMethod ||
      paymentsByType ||
      paymentsByAgent ||
      paymentsByFeeType ||
      report2 ||
      report3
    ) {
      let totalPaymentMethod = 0

      let totalPaymentsByAgent = 0
      let totalPaymentsByFeeType = 0

      paymentsByPaymentMethod.map(
        (payment) => (totalPaymentMethod = totalPaymentMethod + payment.amount),
      )

      paymentsByAgent?.map(
        (payment) =>
          (totalPaymentsByAgent = totalPaymentsByAgent + payment.amountTotal),
      )
      paymentsByFeeType?.map((payment) => {
        return (totalPaymentsByFeeType =
          totalPaymentsByFeeType + payment.amountTotal)
      })

      const premiumAmountTotal = []
      const feeAmountTotal = []
      //returns the sum of premium and non premium
      //takes the value of an array one by one as previous , next
      report2.forEach((detail) => {
        detail.detailsofFee.forEach((value) => {
          if (value.typeP === `PREMIUM`) {
            premiumAmountTotal.push(value.feeAmount)
          }
          if (value.typeP === `NON-PREMIUM`) {
            feeAmountTotal.push(value.feeAmount)
          }
        })
      })
      let sumOfPremiumTotal = premiumAmountTotal.reduce(
        (previous, next) => previous + next,
        0,
      )
      let sumOfNonPremiumTotal = feeAmountTotal.reduce(
        (previous, next) => previous + next,
        0,
      )

      setPaymentMethodTotal(totalPaymentMethod)
      setPaymentsByAgentTotal(totalPaymentsByAgent)
      setPaymentsByFeeTypeTotal(totalPaymentsByFeeType)
      setPremiumTotal(sumOfPremiumTotal)
      setNonPremiumTotal(sumOfNonPremiumTotal)
    }
  }, [
    paymentsByPaymentMethod,
    paymentsByType,
    paymentsByAgent,
    paymentsByFeeType,
    report2,
    report3,
  ])

  const HorizontalLine = ({ start }) => (
    <Svg height="10" width="100%">
      <Line
        x1={start ? start : 0}
        y1="0"
        x2="2480px"
        y2="0"
        strokeWidth={1}
        stroke="rgb(0,0,0)"
      />
    </Svg>
  )

  return (
    <Document subject="Report" author="Reports ">
      <Page
        style={{
          ...styles.page,
          paddingHorizontal: 5,
          paddingTop: 25,
          paddingBottom: 65,
          position: `relative`,
        }}
        size="A4"
        orientation="landscape"
        wrap
      >
        <View style={styles.section} fixed>
          <Text style={styles.pageHeading}>Daily Ledger Report</Text>
          <Text style={styles.pageFlex}>
            <Text
              style={{
                ...styles.pageSubHeading,
                ...styles.pageSubHeadingWidth,
              }}
            >
              Execution Date: {`                              `}
            </Text>
            <Text style={styles.pageSubHeading}>
              {moment().format(`DD/MM/YYYY`)}
            </Text>
          </Text>
          <Text style={styles.pageFlex}>
            <Text
              style={{
                ...styles.pageSubHeading,
                ...styles.pageSubHeadingWidth,
                margin: 12,
              }}
            >
              Insurance Agency: {`                          `}
            </Text>
            <Text style={styles.pageSubHeading}>Veronica's Insurance</Text>
          </Text>
          <Text style={styles.pageFlex}>
            <Text
              style={{
                ...styles.pageSubHeading,
                ...styles.pageSubHeadingWidth,
              }}
            >
              Transaction Date Between: {`             `}
            </Text>
            <Text style={styles.pageSubHeading}>
              {moment(dateRange[0].startDate).format(`DD/MM/YYYY`)}--
              {moment(dateRange[0].endDate).format(`DD/MM/YYYY`)}
            </Text>
          </Text>
        </View>
        <HorizontalLine />
        <View style={styles.section}>
          <Text
            style={{
              ...styles.pageSubHeading,
              paddingVertical: 1,
              paddingHorizontal: 8,
              fontSize: 9.8,
              fontWeight: 800,
            }}
          >
            Break Down by Payment Method
          </Text>
          <HorizontalLine />
          <View style={styles.textHeaders}>
            <Text style={styles}>Payment Method</Text>
            <Text style={styles}>Amount</Text>
          </View>
          <View style={styles.textRows}>
            <Text></Text>
            <Text style={{ fontSize: 9.8 }}>
              Total: {currencyConvert(paymentMethodTotal)}
            </Text>
          </View>
          <HorizontalLine />
          {paymentsByPaymentMethod.map((payment, index) => (
            <>
              <View style={styles.textRows} key={index}>
                <Text style={styles}>{payment?.paymentMethod}</Text>
                <Text style={styles}>{currencyConvert(payment?.amount)}</Text>
              </View>
              <HorizontalLine />
            </>
          ))}
          <Text
            style={{
              ...styles.pageSubHeading,
              paddingVertical: 5,
              paddingHorizontal: 8,
              fontSize: 9.8,
              fontWeight: 800,
            }}
          >
            Break Down by Fee Type
          </Text>
          <HorizontalLine />
          <View style={styles.textHeaders}>
            <Text style={{ ...styles, flexWrap: `nowrap` }}>Premium Type</Text>
            <Text style={styles}>Fee Type</Text>
            <Text style={styles}>Amount</Text>
          </View>
          <View style={styles.textRows}>
            <Text
              style={{
                fontSize: 8.8,
                paddingLeft: 25,
                paddingTop: 1,
                paddingBottom: 1,
                fontWeight: `bold`,
              }}
            ></Text>

            <Text
              style={{
                fontSize: 9.8,
                paddingRight: 1,
                paddingTop: 2,
                fontWeight: `bold`,
              }}
            >
              Total:
              {currencyConvert(
                paymentsByFeeType.reduce(
                  (acc, payment) => payment?.amountTotal + acc,
                  0,
                ),
              )}
            </Text>
          </View>
          <HorizontalLine />
          {paymentsByFeeType.map((payment, index) => (
            <>
              <View key={index}>
                <View style={styles.textRows}>
                  <Text
                    style={{
                      fontSize: 8.8,
                      paddingTop: 1,
                      paddingBottom: 1,
                      fontWeight: `bold`,
                    }}
                  >
                    {payment?.type}
                  </Text>

                  <Text
                    style={{
                      fontSize: 9.8,
                      paddingRight: 1,
                      paddingTop: 2,
                      fontWeight: `bold`,
                    }}
                  >
                    Total: {currencyConvert(payment?.amountTotal)}
                  </Text>
                </View>
                <HorizontalLine />

                {payment?.detailsofFee?.map((fee, ind) => (
                  <View key={ind} style={styles.textRows}>
                    <Text style={styles}></Text>
                    <Text style={{ textAlign: `justify`, paddingLeft: 45 }}>
                      {fee?.feeType}
                    </Text>
                    <Text style={{ fontWeight: `bold` }}>
                      {currencyConvert(fee?.feeAmount)}
                    </Text>
                  </View>
                ))}
              </View>
              <HorizontalLine />
            </>
          ))}
          <Text
            style={{
              ...styles.pageSubHeading,
              paddingVertical: 5,
              paddingHorizontal: 8,
              fontSize: 9.8,
              fontWeight: 800,
            }}
          >
            Break Down by Type
          </Text>
          <HorizontalLine />
          <View style={styles.textHeaders}>
            <Text style={styles}>Fee</Text>
            <Text style={styles}>Amount</Text>
          </View>
          <View style={styles.textRows}>
            <Text></Text>
            <Text style={{ fontSize: 9.8 }}>
              Total: {currencyConvert(paymentsByFeeTypeTotal)}
            </Text>
          </View>
          <HorizontalLine />

          {paymentsByFeeType?.map((payment, index) => (
            <>
              <View key={index}>
                <View style={styles.textRows}>
                  <Text
                    style={{
                      fontSize: 8.8,
                      // paddingLeft: 25,
                      paddingTop: 1,
                      paddingBottom: 1,
                      fontWeight: `bold`,
                    }}
                  >
                    {payment?.type}
                  </Text>

                  <Text
                    style={{
                      fontSize: 9.8,
                      paddingRight: 1,
                      paddingTop: 2,
                      fontWeight: `bold`,
                    }}
                  >
                    {currencyConvert(payment?.amountTotal)}
                  </Text>
                </View>
              </View>
              <HorizontalLine />
            </>
          ))}
          <Text
            style={{
              ...styles.pageSubHeading,
              paddingVertical: 5,
              paddingHorizontal: 8,
              fontSize: 9.8,
              fontWeight: 800,
            }}
          >
            Break Down by User
          </Text>
          <HorizontalLine />
          <View style={styles.textHeaders}>
            <Text style={styles}>User Name</Text>
            <Text style={styles}>Payment Method</Text>
            <Text style={styles}>Amount</Text>
          </View>
          <View style={styles.textRows}>
            <Text
              style={{
                fontSize: 8.8,
                paddingLeft: 25,
                paddingTop: 1,
                paddingBottom: 1,
                fontWeight: `bold`,
              }}
            ></Text>

            <Text
              style={{
                fontSize: 9.8,
                paddingRight: 1,
                paddingTop: 2,
                fontWeight: `bold`,
              }}
            >
              Total: {currencyConvert(paymentsByAgentTotal)}
            </Text>
          </View>
          <HorizontalLine />
          {paymentsByAgent.map((payment, index) => (
            <>
              <View key={index}>
                <View style={styles.textRows}>
                  <Text
                    style={{
                      fontSize: 8.8,
                      // paddingLeft: 25,
                      paddingTop: 1,
                      paddingBottom: 1,
                      fontWeight: `bold`,
                    }}
                  >
                    {payment?.nameofAgent}
                  </Text>

                  <Text
                    style={{
                      fontSize: 9.8,
                      paddingRight: 1,
                      paddingTop: 2,
                      fontWeight: `bold`,
                    }}
                  >
                    {currencyConvert(payment?.amountTotal)}
                  </Text>
                </View>
                <HorizontalLine />

                {payment?.payments.map((fee, ind) => (
                  <View key={ind} style={styles.textRows}>
                    <Text style={styles}></Text>
                    <Text style={{ textAlign: `justify` }}>{fee?.payment}</Text>
                    <Text style={{ fontWeight: `bold` }}>
                      {currencyConvert(fee?.amount)}
                    </Text>
                  </View>
                ))}
              </View>
              <HorizontalLine />
            </>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page
        style={{
          ...styles.page,
          paddingHorizontal: 5,
          paddingTop: 25,
          paddingBottom: 65,
          position: `relative`,
        }}
        size="A4"
        orientation="landscape"
        wrap
      >
        <View style={styles.section} fixed>
          <Text style={styles.pageHeading}>Daily Ledger Report</Text>
          <Text style={styles.pageFlex}>
            <Text
              style={{
                ...styles.pageSubHeading,
                ...styles.pageSubHeadingWidth,
              }}
            >
              Execution Date: {`                              `}
            </Text>
            <Text style={styles.pageSubHeading}>
              {moment().format(`DD/MM/YYYY`)}
            </Text>
          </Text>
          <Text style={styles.pageFlex}>
            <Text
              style={{
                ...styles.pageSubHeading,
                ...styles.pageSubHeadingWidth,
                margin: 12,
              }}
            >
              Insurance Agency: {`                          `}
            </Text>
            <Text style={styles.pageSubHeading}>Veronica's Insurance</Text>
          </Text>
          <Text style={styles.pageFlex}>
            <Text
              style={{
                ...styles.pageSubHeading,
                ...styles.pageSubHeadingWidth,
              }}
            >
              Transaction Date Between: {`             `}
            </Text>
            <Text style={styles.pageSubHeading}>
              {moment(dateRange[0].startDate).format(`DD/MM/YYYY`)}--
              {moment(dateRange[0].endDate).format(`DD/MM/YYYY`)}
            </Text>
          </Text>
        </View>
        <HorizontalLine />
        {/* This is Payment section */}
        <View style={styles.section}>
          <View
            style={{
              ...styles.textHeaders,
              alignItems: `center`,
              paddingHorizontal: 8,
              fontSize: 11,
            }}
          >
            <Text style={styles.headingWidth}>Transaction Date</Text>
            <Text style={styles.headingWidth}>Insured</Text>
            <Text style={styles.headingWidth}>Insured ID</Text>
            {/* <Text style={styles.headingWidth}>Payment Method</Text> */}
            <Text style={{ width: `120px`, textAlign: `center` }}>
              Policy No
            </Text>
            <Text style={styles.headingWidth}>Payment processed by</Text>
            {/* <Text style={styles.headingWidth}>Franchise Name</Text> */}
            <Text style={styles.headingWidth}>Location Name</Text>
            <Text style={styles.headingWidth}>Carrier</Text>
            <Text style={styles.headingWidth}>Invoice Number</Text>
            <Text style={styles.headingWidth}>Due</Text>
            <Text style={styles.headingWidth}>Received</Text>
            <Text style={styles.headingWidth}>Premium</Text>
            <Text style={styles.headingWidth}>Type</Text>
            <Text style={styles.headingWidth}>Fee</Text>
            <Text style={styles.headingWidth}>Payment reason</Text>
          </View>

          {report2.length > 0 &&
            report2.map((item, index) => {
              return item?.detailsofFee.map((fee) => (
                <>
                  <View
                    style={{
                      ...styles.textRows,
                      alignItems: `center`,
                      paddingHorizontal: 8,
                    }}
                    key={index}
                  >
                    <Text style={styles.headingWidth}>
                      {moment(item.date).format(`MM/DD/YYYY`)}
                    </Text>
                    <Text style={styles.headingWidth}>
                      {item?.nameofCustomer}
                    </Text>
                    <Text style={styles.headingWidth}>
                      {item?.insuredCustomerId}
                    </Text>
                    {/* <Text style={{ ...styles.headingWidth }}>
                      {item?.paymentMethod[ind]?.paymentMethod
                        .split(`_`)
                        .join(`,`)}
                    </Text> */}
                    <Text style={{ width: `120px`, textAlign: `center` }}>
                      {item?.policyNo}
                    </Text>
                    <Text style={styles.headingWidth}>
                      {item?.agentWhoCreatedInvoice}
                    </Text>
                    {/* <Text style={styles.headingWidth}>
                      {item?.franchise}
                    </Text> */}
                    <Text style={styles.headingWidth}>
                      {item?.locationFranchise}
                    </Text>
                    <Text style={styles.headingWidth}>
                      {item?.nameofCarrier}
                    </Text>
                    <Text style={styles.headingWidth}>{item?.invoiceId}</Text>
                    <Text style={styles.headingWidth}>
                      {currencyConvert(item?.remainingTotal)}
                    </Text>
                    <Text style={styles.headingWidth}>
                      {currencyConvert(item?.receivedTotal)}
                    </Text>
                    <Text style={styles.headingWidth}>
                      {fee?.typeP === `PREMIUM`
                        ? currencyConvert(fee?.feeAmount)
                        : `$0.00`}
                    </Text>
                    <Text style={styles.headingWidth}>{fee?.typeP}</Text>
                    <Text style={styles.headingWidth}>
                      {fee?.typeP !== `PREMIUM`
                        ? currencyConvert(fee?.feeAmount)
                        : `$0.00`}
                    </Text>
                    <Text style={styles.headingWidth}>{fee?.feeType}</Text>
                  </View>
                  <HorizontalLine />
                </>
              ))
            })}
        </View>
        <View
          style={{
            ...styles.textRows,
            alignItems: `center`,
            paddingHorizontal: 8,
          }}
        >
          <Text style={styles.headingWidth}></Text>
          <Text style={styles.headingWidth}></Text>
          <Text style={{ ...styles.headingWidth, fontSize: `10` }}>Total</Text>
          <Text style={styles.headingWidth}></Text>
          <Text style={styles.headingWidth}></Text>
          <Text style={styles.headingWidth}></Text>
          <Text style={styles.headingWidth}></Text>
          <Text style={styles.headingWidth}></Text>
          {/* <Text style={styles.headingWidth}></Text> */}
          <Text
            style={{ ...styles.headingWidth, fontSize: `10`, paddingLeft: 10 }}
          >
            {currencyConvert(
              report2.reduce((accumulator, item) => {
                return item?.remainingTotal + accumulator
              }, 0),
            )}
          </Text>
          <Text style={{ ...styles.headingWidth, fontSize: `10` }}>
            {currencyConvert(
              report2.reduce((accumulator, item) => {
                return item?.receivedTotal + accumulator
              }, 0),
            )}
          </Text>

          <Text style={{ ...styles.headingWidth, fontSize: `10` }}>
            {currencyConvert(premiumTotal)}
          </Text>
          <Text style={styles.headingWidth}></Text>
          <Text
            style={{ ...styles.headingWidth, fontSize: `10`, paddingLeft: -5 }}
          >
            {currencyConvert(nonPremiumTotal)}
          </Text>
          <Text style={styles.headingWidth}></Text>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page
        style={{
          ...styles.page,
          paddingHorizontal: 5,
          paddingTop: 25,
          paddingBottom: 65,
          position: `relative`,
        }}
        size="A4"
        orientation="landscape"
        wrap
      >
        <View style={styles.section} fixed>
          <Text style={styles.pageHeading}>Daily Ledger Report</Text>
          <Text style={styles.pageFlex}>
            <Text
              style={{
                ...styles.pageSubHeading,
                ...styles.pageSubHeadingWidth,
              }}
            >
              Execution Date: {`                              `}
            </Text>
            <Text style={styles.pageSubHeading}>
              {moment().format(`DD/MM/YYYY`)}
            </Text>
          </Text>
          <Text style={styles.pageFlex}>
            <Text
              style={{
                ...styles.pageSubHeading,
                ...styles.pageSubHeadingWidth,
                margin: 12,
              }}
            >
              Insurance Agency: {`                          `}
            </Text>
            <Text style={styles.pageSubHeading}>Veronica's Insurance</Text>
          </Text>
          <Text style={styles.pageFlex}>
            <Text
              style={{
                ...styles.pageSubHeading,
                ...styles.pageSubHeadingWidth,
              }}
            >
              Transaction Date Between: {`             `}
            </Text>
            <Text style={styles.pageSubHeading}>
              {moment(dateRange[0].startDate).format(`DD/MM/YYYY`)}--
              {moment(dateRange[0].endDate).format(`DD/MM/YYYY`)}
            </Text>
          </Text>
        </View>
        <HorizontalLine />
        {/* This is Payment section */}
        <View style={styles.section}>
          {report3.length > 0 &&
            report3.map((item, index) => (
              <View key={index}>
                <View
                  style={{
                    ...styles.textHeaders,
                    alignItems: `center`,
                    paddingHorizontal: 8,
                    fontSize: 11,
                  }}
                >
                  <Text style={{ width: `80px`, textAlign: `center` }}>
                    Invoice #
                  </Text>
                  <Text style={{ width: `80px`, textAlign: `center` }}>
                    Invoice Balance
                  </Text>
                  <Text style={{ width: `80px`, textAlign: `center` }}>
                    Insured Name
                  </Text>
                  <Text style={{ width: `80px`, textAlign: `center` }}>
                    Policy No
                  </Text>
                  <Text style={{ ...styles.headingWidth, width: `auto` }}>
                    Agent Name
                  </Text>
                  <Text style={{ width: `80px`, textAlign: `center` }}>
                    Carrier
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: 9,
                    backgroundColor: `#D3D3D3`,
                    width: `100%`,
                    display: `flex`,
                    flexDirection: `row`,
                    justifyContent: `space-between`,
                    paddingHorizontal: 8,
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                >
                  <Text
                    style={{
                      width: `80px`,
                      textAlign: `center`,
                      marginVertical: `auto`,
                    }}
                  >
                    {item?._id}
                  </Text>
                  <Text
                    style={{
                      width: `80px`,
                      textAlign: `center`,
                      marginVertical: `auto`,
                    }}
                  >
                    {item?.requiredTotal}
                  </Text>
                  <Text
                    style={{
                      width: `80px`,
                      textAlign: `center`,
                      marginVertical: `auto`,
                    }}
                  >
                    {item?.nameofCustomer}
                  </Text>

                  <Text
                    style={{
                      width: `80px`,
                      textAlign: `center`,
                      marginVertical: `auto`,
                    }}
                  >
                    {item?.policyNo}
                  </Text>
                  <Text
                    style={{
                      width: `80px`,
                      textAlign: `center`,
                      marginVertical: `auto`,
                    }}
                  >
                    {item?.agentWhoCreatedInvoice}
                  </Text>

                  <Text
                    style={{
                      width: `80px`,
                      textAlign: `center`,
                      marginVertical: `auto`,
                    }}
                  >
                    {item?.nameofCarrier}
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: 11,
                    backgroundColor: `#F8F8F8`,
                    width: `100%`,
                    display: `flex`,
                    flexDirection: `row`,
                    justifyContent: `space-between`,
                    paddingHorizontal: 8,
                    paddingVertical: 8,
                  }}
                >
                  <Text style={{ width: `50px`, textAlign: `center` }}>
                    Receipt #
                  </Text>
                  <Text style={{ width: `50px`, textAlign: `center` }}>
                    Franchise
                  </Text>
                  <Text style={{ width: `50px`, textAlign: `center` }}>
                    Location
                  </Text>
                  <Text style={{ width: `50px`, textAlign: `center` }}>
                    Amount
                  </Text>
                  <Text style={styles.headingWidth2}>Payment processed by</Text>
                  <Text style={styles.headingWidth2}>Payment Method</Text>
                  <Text style={styles.headingWidth2}>Received Date</Text>
                  <Text style={styles.headingWidth2}>Receipt Received</Text>
                  <Text style={styles.headingWidth2}>Invoice Received</Text>
                  <Text style={styles.headingWidth2}>Remaining Balance</Text>
                </View>
                <HorizontalLine />
                <View
                  style={{
                    // ...styles.textRows,
                    alignItems: `center`,
                    paddingHorizontal: 8,
                    flexDirection: `column`,
                  }}
                  key={index}
                >
                  {item?.paymentMethod.map((paymentMethodItem, ind) => (
                    <>
                      <View
                        key={ind}
                        style={{
                          ...styles.textRows,
                          alignItems: `center`,
                          paddingHorizontal: 8,
                        }}
                      >
                        <Text
                          style={{ ...styles.headingWidth, maxWidth: `50px` }}
                        >
                          {paymentMethodItem.paymentItems._receiptId}
                        </Text>
                        <Text
                          style={{ ...styles.headingWidth, maxWidth: `50px` }}
                        >
                          {item.franchise}
                        </Text>

                        {/* <Text style={{ ...styles.headingWidth }}>
                              {currencyConvert(item?.receivedTotal)}
                            </Text> */}

                        <Text
                          style={{ ...styles.headingWidth, maxWidth: `50px` }}
                        >
                          {paymentMethodItem._location?.locationName
                            ? paymentMethodItem?._location?.locationName
                            : `N/A`}
                        </Text>
                        {/* <Text style={{ ...styles.headingWidth }}>
                              {currencyConvert(paymentMethodItem?.paymentItems?.amount)}
                            </Text> */}
                        <Text style={{ ...styles.headingWidth, width: `50px` }}>
                          {currencyConvert(
                            paymentMethodItem?.paymentItems?.amount
                              ? paymentMethodItem?.paymentItems?.amount
                              : 0,
                          )}
                        </Text>

                        {/* <Text style={{ ...styles.headingWidth, width: `auto` }}>{fee?.typeP}</Text> */}
                        {/* <Text style={{ ...styles.headingWidth, width: `auto` }}>{fee?.feeType}</Text> */}
                        <Text style={{ ...styles.headingWidth, width: `80px` }}>
                          {paymentMethodItem?._agent?.name
                            ? paymentMethodItem?._agent?.name
                            : `N/A`}
                        </Text>
                        <Text style={{ ...styles.headingWidth, width: `80px` }}>
                          {paymentMethodItem?.paymentItems?.paymentMethod
                            ? paymentMethodItem?.paymentItems?.paymentMethod
                            : `--`}
                        </Text>
                        <Text style={{ ...styles.headingWidth, width: `80px` }}>
                          {moment(paymentMethodItem.transactionDate).format(
                            `MM/DD/YYYY`,
                          )}
                        </Text>
                        {ind == 0 ? (
                          <>
                            <Text
                              style={{ ...styles.headingWidth, width: `80px` }}
                            >
                              {currencyConvert(
                                item?.receivedByReceipts
                                  ? item?.receivedByReceipts
                                  : 0,
                              )}
                            </Text>
                            <Text
                              style={{ ...styles.headingWidth, width: `80px` }}
                            >
                              {currencyConvert(
                                item?.receivedTotal ? item?.receivedTotal : 0,
                              )}
                            </Text>
                            <Text
                              style={{ ...styles.headingWidth, width: `80px` }}
                            >
                              {currencyConvert(
                                item?.remainingTotal ? item?.remainingTotal : 0,
                              )}
                            </Text>
                          </>
                        ) : (
                          <>
                            <Text
                              style={{ ...styles.headingWidth, width: `80px` }}
                            >
                              {`    --    `}
                            </Text>
                            <Text
                              style={{ ...styles.headingWidth, width: `80px` }}
                            >
                              {`    --    `}
                            </Text>
                            <Text
                              style={{ ...styles.headingWidth, width: `80px` }}
                            >
                              {`    --    `}
                            </Text>
                          </>
                        )}

                        {/* <Text style={styles.headingWidth}>
                          {moment(item.date).format(`MM/DD/YYYY`)}
                        </Text> */}
                      </View>
                      <HorizontalLine />
                    </>
                  ))}
                </View>
                {/* <HorizontalLine /> */}
              </View>
            ))}
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  )
}
