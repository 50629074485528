import { AMS_BACKEND_API } from 'App/Config'

import React, { useState, useCallback, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import Lobby from '../Lobby'
import Room from '../Room'

const VideoChat = () => {
  const [token, setToken] = useState(null)
  const [roomId, setRoomId] = useState(null)
  const [userName, setUserName] = useState(``)
  const [participantLength] = useState(
    localStorage.getItem(`participantLength`),
  )
  const { user } = useSelector(({ user }) => user)

  useEffect(() => {
    if (process.browser && window.location.search.includes(`roomId`)) {
      const roomId = window.location.search.split(`=`)[1]
      setRoomId(roomId)
    }
  }, [process.browser])
  const { mutate: getTokenPublic, isLoading } = useMutation(
    async () =>
      await fetch(`${AMS_BACKEND_API}/api/customers-chat/video-call/token`, {
        method: `POST`,
        headers: {
          'Content-Type': `application/json`,
        },
        body: JSON.stringify({
          clientName: userName,
          room: roomId ? roomId : user?.profile?.sub,
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json()
          } else {
            throw new Error(`Network response was not ok.`)
          }
        })
        .then((data) => {
          if (data.data.token) {
            return data.data.token
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        setToken(res)
      },
    },
  )

  // const { mutate: getTokenPublic, isLoading } = useMutation(
  //   async () =>
  //     await AxiosInstance.post(
  //       `${`${AMS_BACKEND_API}`}/api/customers-chat/video-call/token`,
  //       {
  //         clientName: userName,
  //         room: roomId ? roomId : user?.profile?.sub,
  //       },
  //     ).then((res) => {
  //       if (res.data.data.token) {
  //         return res.data.data.token
  //       }
  //     }),
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled: false,
  //     onSuccess: (res) => {
  //       setToken(res)
  //     },
  //   },
  // )

  useEffect(() => {
    if (userName?.length) getTokenPublic()
  }, [userName])

  const handleLogout = useCallback(() => {
    setToken(null)
  }, [])

  if (token) {
    return (
      <Room
        roomName={user?.profile?.sub}
        token={token}
        handleLogout={handleLogout}
      />
    )
  } else {
    return (
      <Lobby
        isLoadingRoom={isLoading}
        getUserName={(value) => setUserName(value)}
        participantLength={participantLength}
      />
    )
  }
}

export default VideoChat
