import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Container } from 'App/Styled'
import { ReactSelectField } from '../../Common/ReactSelect'

ChartJS.register(ArcElement, Tooltip, Legend)
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
}
export const EarningsByAgents = ({ earningsByAgent }) => {
  const [dataSet, setDataSet] = useState(``)
  const [allEarnings, setAllEarnings] = useState(``)
  const [selectAgent, setSelectAgent] = useState([])

  useEffect(() => {
    if (earningsByAgent?.length) {
      let labels = selectAgent.length
        ? selectAgent.map((item) => item.label)
        : earningsByAgent.map((item) => item.name)
      let values = selectAgent.length
        ? selectAgent.map((item) => item.earning)
        : earningsByAgent.map((item) => item.earning)

      let allEarnings = values.reduce(
        (partialSum, earning) => partialSum + earning,
        0,
      )

      let backgroundColors = earningsByAgent.map(() => {
        let randomColor = Math.floor(Math.random() * 16777215).toString(16)
        return `#` + randomColor
      })

      if (labels.length && values.length && backgroundColors.length)
        setDataSet({
          labels: labels,
          datasets: [
            {
              label: `# of Votes`,
              data: values,
              backgroundColor: backgroundColors,
              borderWidth: 1,
            },
          ],
        })
      setAllEarnings(allEarnings)
    }
  }, [earningsByAgent, selectAgent])

  const option = earningsByAgent?.map((item) => ({
    value: item.name,
    earning: item.earning,
    label: item.name,
  }))
  const loadAgents = (search) => {
    const options = {
      options: filterState(search),
      hasMore: false,
    }

    return options
  }

  const filterState = (search) => {
    return (
      option
        //some labels are null that is why we used Double filter
        .filter((item) => item.label !== null)
        .filter((item) =>
          item.label.toLowerCase().includes(search.toLowerCase()),
        )
    )
  }

  console.log(`allEarnings---`, allEarnings)
  return (
    <Container className="relative">
      <div className="absolute font-bold text-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
        <div className="mt-16 font-bold ">{`${
          allEarnings > 0 ? allEarnings : `No Data`
        }`}</div>
      </div>

      <div className="flex py-9 h-1/4 relative">
        <div className="font-bold absolute left-4 2xl:left-6 top-5">
          <span className="font-bold text-md">Earnings By Agents</span>
        </div>
        <div className="w-1/2 absolute right-4 top-4 z-0">
          <ReactSelectField
            placeholder=" Select Agent"
            value={selectAgent}
            setValue={(e) => {
              setSelectAgent(e)
            }}
            isMulti={true}
            loadOptions={loadAgents}
            isSearchable={true}
            isScrollable={true}
          />
        </div>
      </div>

      <div className="text-center flex justify-center h-3/4">
        <div className="w-1/2 h-96">
          {dataSet && (
            <Doughnut
              data={dataSet}
              options={options}
              width={300}
              height={200}
            />
          )}
        </div>
      </div>
    </Container>
  )
}
