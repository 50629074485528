import React, { useEffect, useState, Fragment } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useRouteMatch, useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Container, PageHeader } from 'App/Styled'
import { permissionGranted } from 'App/Services'
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Badge,
} from '@windmill/react-ui'
import {
  faPlus,
  faSortDown,
  faSortUp,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import { setCurrentEvaluation } from 'App/Redux/actions'
import { useDispatch } from 'react-redux'
import searchQueryParser from 'App/Services/General/searchQueryParser'
const tableColumns = [
  { label: `ID`, value: `_evaluationId` },
  { label: `Template Name`, value: `templateName` },
  { label: `Evaluee`, value: `evaluatee` },
  { label: `Evaluator`, value: `evaluator` },
  { label: `Avg. Score`, value: `averageScore` },
  { label: `Status`, value: `status` },
]

export const Evaluations = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    params: { aId },
  } = useRouteMatch()
  //  filters
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)
  const [sort, setSort] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)
  const [claimUpdated, setClaimUpdated] = useState(false)
  const [activeReport, setActiveReport] = useState(``)
  const {
    data: evaluationsData,
    isLoading,
    refetch: getAllAssessments,
    isFetching,
    error,
  } = useQuery(
    `EvaluationsApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/assessment/${aId}/evaluations?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort ? sort : `-createdAt`}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const { mutate: updateStatus, isLoading: updatingStatus } = useMutation(
    async (id) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/assessment/${aId}/evaluations/${id}`,
        {
          status: `CANCELED`,
        },
      ).then((res) => {
        !res.hasError && getAllAssessments()
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }
  useEffect(() => {
    if (claimUpdated) {
      getAllAssessments()
      setClaimUpdated(false)
    }
  }, [claimUpdated])
  useEffect(() => {
    if (!customSearching) {
      getAllAssessments()
    }
  }, [sort, currentPage, isOffset])

  const callClaimsQuery = useDebouncedCallback(() => {
    getAllAssessments()
  }, 700)

  const handleUpdateStatus = (item, e) => {
    e.stopPropagation()
    setActiveReport(item)
    updateStatus(item?._id)
  }

  return (
    <Container>
      <PageHeader>
        <div>Reports</div>
        <div className="flex items-center ">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>

          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callClaimsQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <Spinner loading={isLoading || isFetching} />
      <div
        className={` bg-white mb-1 sm:mb-0 justify-center items-center px-2 mt-8 ${
          (isLoading || isFetching) && `opacity-30 pointer-events-none`
        } `}
      >
        {error ? (
          <Reload refetch={() => getAllAssessments()} />
        ) : (
          <>
            <div className="overflow-x-auto xl:overflow-hidden rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className="cursor-pointer select-none text-sm flex">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              } truncate`}
                            >
                              {item.label}
                            </div>
                            {item.value !== `title` && (
                              <div
                                className="ml-2 px-1 rounded-sm cursor-pointer flex"
                                onClick={() => handleSorting(`-` + item.value)}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    sort.includes(`-`) &&
                                    sort === `-` + item.value
                                      ? faSortUp
                                      : faSortDown
                                  }
                                  size="lg"
                                  className={`${
                                    sort.includes(`-`) &&
                                    sort === `-` + item.value
                                      ? `text-red-500`
                                      : `-mt-1.5`
                                  }`}
                                />
                              </div>
                            )}
                          </div>
                        </TableCell>
                      )
                    })}
                    {permissionGranted([
                      `evaluate:update`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) && (
                      <TableCell className="select-none text-sm float-right">
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!isLoading && !evaluationsData?.evaluations?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Report Found</div>
                      </td>
                    </tr>
                  ) : (
                    evaluationsData?.evaluations?.map((item, index) => (
                      <Fragment key={index}>
                        <TableRow
                          key={index}
                          className={`hover:bg-gray-100 ${
                            item?.status === `CANCELED`
                              ? `cursor-not-allowed`
                              : `cursor-pointer`
                          } `}
                          onClick={() => {
                            dispatch(setCurrentEvaluation(item))
                            if (
                              permissionGranted([
                                `evaluate:read`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ]) &&
                              item?.status === `COMPLETED`
                            ) {
                              history.push(
                                `/assessment/weeks/${aId}/evaluations/${item._id}/record`,
                              )
                            } else if (
                              permissionGranted([
                                `evaluate:update`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ]) &&
                              item?.status === `PENDING`
                            ) {
                              history.push(
                                `/assessment/weeks/${aId}/evaluations/${item._id}`,
                              )
                            }
                          }}
                        >
                          <td className="px-4 py-0.5 capitalize truncate font-semibold text-sm">
                            {item?._evaluationId}
                          </td>
                          <td className="px-4 py-0.5 capitalize truncate text-sm">
                            {item?.template}
                          </td>
                          <td className="px-4 py-0.5 capitalize truncate text-sm">
                            {item?.evaluatee?.toLowerCase()}
                          </td>

                          <td className="px-4 py-0.5 capitalize truncate text-sm">
                            {item?.evaluator?.toLowerCase()}
                          </td>
                          <td className="px-4 py-0.5 capitalize truncate text-sm">
                            <Badge
                              type={
                                item?.averageScore <= 2
                                  ? `danger`
                                  : item?.averageScore <= 5
                                  ? `warning`
                                  : item?.averageScore <= 8
                                  ? `primary`
                                  : `success`
                              }
                            >{`${
                              item?.averageScore?.toString()
                                ? `${item?.averageScore?.toFixed(1)}`
                                : `-`
                            }`}</Badge>
                          </td>
                          <td className="px-4 py-0.5 capitalize truncate text-sm">
                            <Badge>{item?.status}</Badge>
                          </td>

                          {/* Actions TD */}
                          <td className="px-4 py-0.5 z-0 text-sm flex float-right text-gray-400">
                            {permissionGranted([
                              `evaluate:update`,
                              `corporate-manager:*`,
                              `manager:*`,
                              `admin:*`,
                            ]) && (
                              <span
                                data-tip={`${
                                  item?.status === `PENDING`
                                    ? `Cancel`
                                    : `Not Allowed`
                                }`}
                                className="tooltip tooltip-left"
                              >
                                <FontAwesomeIcon
                                  className={`transform transition rotate-45 hover:text-blue-500  hover:-translate-y-0 hover:scale-105 ${
                                    item?.status === `PENDING`
                                      ? `cursor-pointer`
                                      : `cursor-not-allowed`
                                  }`}
                                  icon={
                                    activeReport?._id === item?._id &&
                                    updatingStatus
                                      ? faSpinner
                                      : faPlus
                                  }
                                  size="1x"
                                  spin={
                                    activeReport?._id === item?._id &&
                                    updatingStatus
                                  }
                                  onClick={(e) =>
                                    item?.status === `PENDING` &&
                                    handleUpdateStatus(item, e)
                                  }
                                />
                              </span>
                            )}
                          </td>
                        </TableRow>
                      </Fragment>
                    ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={evaluationsData?.totalPages}
                totalRecords={evaluationsData?.totalRecords}
                curerntPageRecords={evaluationsData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
