import React from 'react'
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { AMS_BACKEND_API, allowedSystemPermissions } from 'App/Config'
import { AxiosInstance } from 'App/Config'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { StatisticCard } from 'App/Components/Dashboard/Component/StatisticCard'
import { Container } from 'App/Styled'
import { DashboardCardField } from 'App/Components/Common/DashboardCardField'
import { Badge } from '@windmill/react-ui'
import { BarChart } from 'App/Components/Common/BarChart'
import { loadFranchises, permissionGranted } from 'App/Services'
import { Spinner } from 'App/Components/Common/Spinner'
import { CollapsableRef } from 'App/Components/Common/CollapsableRef'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const AssessmentDashboard = () => {
  const history = useHistory()
  const {
    user: {
      profile: { _franchise },
    },
  } = useSelector(({ user }) => user)

  const [franchise, setFranchise] = useState(``)
  const [location, setLocation] = useState(``)
  const [evaluator, setEvaluator] = useState(``)

  const {
    data: assessmentAnalytics,
    isLoading,
    refetch,
    isFetching,
  } = useQuery(
    `AssessmentDataApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/dashboard/assessment-dashtics?franchise=${
          franchise.value ?? ``
        }&location=${location.value ?? ``}&evaluator=${evaluator.value ?? ``}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )
  const loadLocations = async (search, loadedOptions, { page }) => {
    const {
      data: { data },
    } = await AxiosInstance.get(
      `${AMS_BACKEND_API}/api/franchises/${
        franchise.value ? franchise.value : _franchise?._id
      }/locations/list/?search=${search && search}&page=${page}&offset=10`,
    )
    if (data) {
      return {
        options: data.locations,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    }
  }

  const loadEvaluators = async (search, loadedOptions, { page }) => {
    const locationIds = location?.value ? [location.value] : []
    try {
      const {
        data: { data },
      } = await AxiosInstance.post(
        `${AMS_BACKEND_API}/api/users/v2/list?search={"query":"${
          search.length ? search : ``
        }"}&page=${page}&offset=10&isEvaluator=${true}`,
        {
          franchiseId: franchise.value ? franchise.value : _franchise?._id,
          locations: locationIds,
          permissions: allowedSystemPermissions?.evaluator,
        },
      )
      return {
        options: data.agents,
        hasMore: data.totalPages - page > 0,
        additional: {
          page: page + 1,
        },
      }
    } catch (error) {
      if (error.response?.data?.hasError) {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: page,
          },
        }
      }
    }
  }

  const gradientArray = [
    `gradient-1`,
    `gradient-2`,
    `gradient-3`,
    `gradient-4`,
    `gradient-1`,
    `gradient-2`,
    `gradient-3`,
    `gradient-4`,
    `gradient-1`,
    `gradient-2`,
    `gradient-3`,
    `gradient-4`,
    `gradient-5`,
  ]

  useEffect(() => {
    refetch()
  }, [franchise, location, evaluator])

  return (
    <>
      <Spinner loading={isLoading || isFetching} />
      <div
        className={`${
          (isLoading || isFetching) && `opacity-30 pointer-events-none`
        }`}
      >
        {/* Filters */}

        <div className="px-2 py-6">
          {permissionGranted([`corporate-manager:*`, `manager:*`]) &&
            !permissionGranted([`super-admin:*`]) && (
              <CollapsableRef title="Filters">
                <div className="flex">
                  <div className="w-1/2 mt-2 ml-2">
                    <ReactSelectField
                      placeholder="Select"
                      title="Location"
                      setValue={(value) => {
                        if (value) {
                          setLocation(value)
                        } else {
                          setLocation(``)
                        }
                        setEvaluator(``)
                      }}
                      isClearable={true}
                      loadOptions={loadLocations}
                      cacheUniqs={franchise}
                    />
                  </div>

                  {location?.value ? (
                    <div className="w-1/2 mt-2 ml-2">
                      <ReactSelectField
                        placeholder="Select"
                        title="Evaluator"
                        setValue={(value) => {
                          if (value) {
                            setEvaluator(value)
                          } else {
                            setEvaluator(``)
                          }
                        }}
                        isClearable={true}
                        loadOptions={loadEvaluators}
                        cacheUniqs={location}
                      />
                    </div>
                  ) : null}
                </div>
              </CollapsableRef>
            )}
          {permissionGranted([`super-admin:*`]) && (
            <CollapsableRef title="Filters">
              <div className="flex">
                <div className="w-1/3 mt-2">
                  <ReactSelectField
                    placeholder="Select"
                    title="Franchise"
                    value={franchise}
                    setValue={(value) => {
                      if (value?.value !== franchise?.value) {
                        setFranchise(value)
                        setLocation(``)
                        setEvaluator(``)
                      }
                    }}
                    isClearable={true}
                    loadOptions={loadFranchises}
                  />
                </div>
                {franchise?.value ? (
                  <div className="w-1/3 mt-2 ml-4">
                    <ReactSelectField
                      placeholder="Select"
                      title="Location"
                      value={location}
                      setValue={(value) => {
                        if (value) {
                          setLocation(value)
                        } else {
                          setLocation(``)
                        }
                        setEvaluator(``)
                      }}
                      isClearable={true}
                      loadOptions={loadLocations}
                      cacheUniqs={franchise}
                    />
                  </div>
                ) : null}
                {location?.value ? (
                  <div className="w-1/3 mt-2 ml-4">
                    <ReactSelectField
                      placeholder="Select"
                      title="Evaluator"
                      setValue={(value) => {
                        if (value) {
                          setEvaluator(value)
                        } else {
                          setEvaluator(``)
                        }
                      }}
                      isClearable={true}
                      loadOptions={loadEvaluators}
                      cacheUniqs={location}
                    />
                  </div>
                ) : null}
              </div>
            </CollapsableRef>
          )}
        </div>

        {assessmentAnalytics?.totalEvalautees > 0 ? (
          <>
            <div className="ml-2 rounded">
              <div>
                {assessmentAnalytics && (
                  <div className="grid grid-cols-3 pr-2 gap-4 w-full  rounded-md text-center">
                    <StatisticCard
                      assessmentScore={
                        assessmentAnalytics?.totalEvalautees
                          ? assessmentAnalytics?.totalEvalautees
                          : 0
                      }
                      assessmentTitle={`Total Evaluatees`}
                      colorPrimary={`bg-white`}
                      gradient={gradientArray[0]}
                    />

                    {assessmentAnalytics?.evaluationsByStatus?.map(
                      (item, index) => (
                        <StatisticCard
                          key={index}
                          assessmentScore={item?.count ? item?.count : 0}
                          assessmentTitle={`${item?.status} Evaluations`}
                          colorPrimary={`bg-white`}
                          gradient={gradientArray[index + 1]}
                        />
                      ),
                    )}
                    <StatisticCard
                      assessmentScore={
                        assessmentAnalytics?.agentsneedHelp
                          ? assessmentAnalytics?.agentsneedHelp
                          : 0
                      }
                      assessmentTitle={`Agents Need Help`}
                      colorPrimary={`bg-white`}
                      gradient={`gradient-6`}
                    />
                    <StatisticCard
                      assessmentScore={
                        assessmentAnalytics?.rockStarAgents
                          ? assessmentAnalytics?.rockStarAgents
                          : 0
                      }
                      assessmentTitle={`Rock Star Agents`}
                      colorPrimary={`bg-white`}
                      gradient={`gradient-5`}
                    />
                  </div>
                )}
                <div className="md:col-span-4 col-span-12"></div>
              </div>
            </div>

            <div className="grid grid-cols-12">
              <Container className="grid col-span-4">
                <div className="grid grid-cols-12 px-2 py-2">
                  <div className="col-span-6 text-md py-5 font-bold">
                    Top Agents
                  </div>
                </div>
                <div className="grid grid-cols-1 px-2">
                  <div className="border-t-1 border-b-1 my-auto">
                    <DashboardCardField
                      title="Names"
                      value="Latest"
                      value2="Average"
                    />
                  </div>

                  {assessmentAnalytics?.topAgentPerformance?.length > 0 &&
                    assessmentAnalytics?.topAgentPerformance?.map(
                      (item, index) => (
                        <DashboardCardField
                          key={index}
                          title={`${index + 1}. ${item?.name}`}
                          value2={
                            <Badge
                              type={
                                item?.recentEvaluation <= 20
                                  ? `danger`
                                  : item?.recentEvaluation <= 50
                                  ? `warning`
                                  : item?.recentEvaluation <= 80
                                  ? `primary`
                                  : `success`
                              }
                            >
                              {`${item?.recentEvaluation?.toFixed(1)} %`}
                            </Badge>
                          }
                          value={
                            <Badge
                              type={
                                item?.averagePerformance <= 20
                                  ? `danger`
                                  : item?.averagePerformance <= 50
                                  ? `warning`
                                  : item?.averagePerformance <= 80
                                  ? `primary`
                                  : `success`
                              }
                            >
                              {`${item?.averagePerformance?.toFixed(1)} %`}
                            </Badge>
                          }
                        />
                      ),
                    )}
                  {assessmentAnalytics?.topAgentPerformance?.length > -1 &&
                    Array.apply(
                      null,
                      Array(
                        10 - assessmentAnalytics?.topAgentPerformance?.length,
                      ),
                    ).map((_, index) => (
                      <DashboardCardField
                        key={index}
                        title="---"
                        value="---"
                        value2="---"
                      />
                    ))}
                </div>
              </Container>

              {assessmentAnalytics?.franchiseWisePerformance ? (
                <div className="col-span-8">
                  <BarChart
                    className="py-5"
                    title="Franchise Wise performance"
                    chartData={assessmentAnalytics?.franchiseWisePerformance}
                    label="Franchise"
                  />
                </div>
              ) : assessmentAnalytics?.locationWisePerformance ? (
                <div className="col-span-8">
                  <BarChart
                    className="py-5"
                    title="Location Wise performance"
                    chartData={assessmentAnalytics?.locationWisePerformance}
                    label="Location"
                  />
                </div>
              ) : assessmentAnalytics?.evaluatorWisePerformance ? (
                <div className="col-span-8">
                  <BarChart
                    className="py-5"
                    title="Evaluators Wise performance"
                    chartData={assessmentAnalytics?.evaluatorWisePerformance}
                    label="Evaluator"
                  />
                </div>
              ) : (
                <div className="col-span-8">
                  <BarChart
                    className="py-5"
                    title="Agent Wise performance"
                    chartData={assessmentAnalytics?.agentWisePerformance}
                    label="Agents"
                  />
                </div>
              )}
            </div>
            {assessmentAnalytics?.recentEvaluation ? (
              <div className="grid grid-cols-12">
                <Container className="grid col-span-4">
                  <div className="grid grid-cols-12 px-2 py-2">
                    <div className="col-span-6 text-md py-5 font-bold">
                      Recent Evaluations
                    </div>
                  </div>
                  {assessmentAnalytics?.recentEvaluation.length > 0 ? (
                    <div className="px-10 h-80 overflow-y-scroll">
                      <ol className="relative  border-gray-200 ">
                        {assessmentAnalytics?.recentEvaluation.map(
                          (evaluation, index) => (
                            <div className="flex gap-x-2 ml-4 xl:ml-2">
                              <li className="mb-5 ml-4 relative" key={index}>
                                <div
                                  className={`absolute w-4 h-4  bg-transparent rounded-full -left-6 border-2 tooltip ${
                                    index === 0 && `tooltip-right`
                                  } ${
                                    evaluation?.status === `COMPLETED`
                                      ? `border-green-600`
                                      : `border-red-600`
                                  } `}
                                  data-tip={evaluation?.status.toLowerCase()}
                                ></div>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (evaluation?.status === `CANCELED`) {
                                      history.push(
                                        `/assessment/weeks/${evaluation?.assessmentId}`,
                                      )
                                    } else {
                                      history.push(
                                        `/assessment/weeks/${evaluation?.assessmentId}/evaluations/${evaluation?.evaluationId}/record`,
                                      )
                                    }
                                  }}
                                >
                                  <h6 className="text-sm font-normal text-gray-900">
                                    {evaluation?.name}'s Evaluation
                                  </h6>
                                </span>

                                {index !==
                                  assessmentAnalytics?.recentEvaluation.length -
                                    1 && (
                                  <div className="absolute h-6 border-1  bg-transparent top-4  -left-4 border-gray-400"></div>
                                )}
                              </li>
                            </div>
                          ),
                        )}
                      </ol>
                    </div>
                  ) : (
                    <div className="text-center h-80 text-gray-500 text-sm">
                      No Evaluations
                    </div>
                  )}
                </Container>

                {permissionGranted([`corporate-manager:*`, `manager:*`]) &&
                assessmentAnalytics?.evaluatorWisePerformance &&
                !evaluator?.value &&
                !location?.value ? (
                  <div className="col-span-8">
                    <BarChart
                      className="py-5"
                      title="Evaluators Wise performance"
                      chartData={assessmentAnalytics?.evaluatorWisePerformance}
                      label="Evaluator"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        ) : (
          <div className="text-center mt-36 p-10">
            <p>No Data Found</p>
          </div>
        )}
      </div>
    </>
  )
}
