import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputField } from 'App/Components/Common/InputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'
import { StyledButton } from 'App/Styled'
import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { NotificationManager } from 'react-notifications'
import { TextArea } from 'App/Components/Common/TextArea'
import { loadAllTicketsPriorities } from 'App/Services'
import moment from 'moment'
import { Spinner } from 'App/Components/Common/Spinner'
import { DatePickerField } from 'App/Components/Common/DatePicker'

export const UpdateTasks = ({ currentTask, closeModal, taskUpdated }) => {
  const [taskUpdate, setTaskUpdate] = useState({
    assignTo: ``,
    taskType: ``,
    priority: ``,
    dueDate: ``,
    alertTime: ``,
    recurring: ``,
    recurringStatus: ``,
    afterEvery: ``,
    title: ``,
    description: ``,
    frequency: ``,
  })
  const [afterEveryTitle, setAfterEveryTitle] = useState(``)

  const [errors, setErrors] = useState({
    assignToError: ``,
    taskTypeError: ``,
    priorityError: ``,
    dueDateError: ``,
    alertTimeError: ``,
    recurringError: ``,
    // recurringStatusError: ``,
    afterEveryError: ``,
    frequencyError: ``,
    titleError: ``,
    descriptionError: ``,
    formSubmit: false,
  })

  const [recurringValue, setRecurringValue] = useState(false)

  const { data: taskData, isLoading: updateLoading } = useQuery(
    `TaskUpdateApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/task/${currentTask._id}`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    },
  )
  useEffect(() => {
    if (taskData) {
      setTaskUpdate((prevState) => ({
        ...prevState,
        assignTo: {
          value: taskData?.assignTo,
          label: taskData?.assignTo,
        },
        taskType: {
          value: taskData?.taskType,
          label: taskData?.taskType,
        },
        priority: {
          value: taskData?.priority,
          label: taskData?.priority,
        },

        alertTime: {
          value: taskData?.alertTime,
          label: taskData?.alertTime + ` minutes before`,
        },
        recurring: {
          value: taskData?.recurring?.recurringType,
          label: taskData?.recurring?.recurringType,
        },
        recurringStatus: {
          value: taskData?.recurring?.recurringStatus,
          label: taskData?.recurring?.recurringStatus,
        },
        afterEvery: {
          value: taskData?.recurring?.recurringAfter,
          label: taskData?.recurring?.recurringAfter,
        },
        frequency: {
          value: taskData?.recurring?.recurringCustomType,
          label: taskData?.recurring?.recurringCustomType,
        },

        title: taskData?.title,
        dueDate: new Date(taskData?.dueDate),

        description: taskData?.description,
      }))
    }
    if (taskData?.recurring?.recurringType === `CUSTOM`) setRecurringValue(true)
  }, [taskData])

  const { mutate, isLoading } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/task/${currentTask._id}`,
        {
          ...payload,
        },
      )
        .then((res) => {
          if (res.data) {
            closeModal()
            taskUpdated()
            NotificationManager.success(`Updated Successfully`)
          }
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response?.data?.error?.error?.includes(
                `Incoming request body can't be empty.`,
              )
            ) {
              NotificationManager.error(`No field Changed`)
            }
          }
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const loadAllTaskTypes = () => {
    const options = {
      options: [
        { value: `EARNING GOALS`, label: `EARNING GOALS` },
        { value: `ASSESSMENT`, label: `  ASSESSMENT` },
        { value: `LEADS/ VISITORS CALL`, label: `LEADS/ VISITORS CALL` },
        { value: `RECALL CUSTOMERS`, label: `RECALL CUSTOMERS` },
        { value: `POLICY CHANGE`, label: `POLICY CHANGE` },
        { value: `RENEWAL`, label: `RENEWAL` },
        { value: `REQUEST SERVICE`, label: `REQUEST SERVICE` },
        { value: `TICKET CENTER`, label: `TICKET CENTER` },
        { value: `QUOTES`, label: `QUOTES` },
      ],
      hasMore: false,
    }
    return options
  }
  const loadAlertTime = () => {
    const options = {
      options: [
        { value: 5, label: `5 minutes before` },
        { value: 10, label: `10 minutes before` },
        { value: 15, label: `15 minutes before` },
        { value: 20, label: `20 minutes before` },
        { value: 25, label: `25 minutes before` },
        { value: 30, label: `30 minutes before` },
        { value: 45, label: `45 minutes before` },
        { value: 60, label: `One Hour before` },
        { value: 90, label: `90 minutes before` },
        { value: 120, label: `2 Hours before` },
      ],
      hasMore: false,
    }
    return options
  }

  const customRecurring = () => {
    const options = {
      options: [
        { value: `NONE`, label: `NONE` },
        { value: `DAY`, label: `DAY` },
        { value: `WEEK`, label: `WEEK` },
        { value: `MONTH`, label: `MONTH` },
        { value: `YEAR`, label: `YEAR` },
        { value: `CUSTOM`, label: `CUSTOM` },
      ],
      hasMore: false,
    }
    return options
  }
  const loadFrequency = () => {
    const options = {
      options: [
        { value: `DAY`, label: `DAY` },
        { value: `WEEK`, label: `WEEK` },
        { value: `MONTH`, label: `MONTH` },
        { value: `YEAR`, label: `YEAR` },
      ],
      hasMore: false,
    }
    return options
  }

  const afterEvery = () => {
    const options = []
    for (let i = 1; i <= 30; ++i) {
      options.push({ value: i, label: i.toString() })
    }

    return { options, hasMore: false }
  }

  const validateForm = () => {
    if (!taskUpdate?.assignTo?.value?.length) {
      handleErrors(`assignToError`, `Enter Agent Name`)
    } else {
      handleErrors(`assignToError`, ``)
    }

    if (!taskUpdate.taskType?.value?.length) {
      handleErrors(`taskTypeError`, `Select Task Type`)
    } else {
      handleErrors(`taskTypeError`, ``)
    }
    if (!taskUpdate.priority?.value?.length) {
      handleErrors(`priorityError`, `Select Task Priority`)
    } else {
      handleErrors(`priorityError`, ``)
    }
    if (!taskUpdate.dueDate) {
      handleErrors(`dueDateError`, `Select Due Date `)
    } else {
      handleErrors(`dueDateError`, ``)
    }
    if (!taskUpdate.alertTime?.value) {
      handleErrors(`alertTimeError`, `Select Alert Time`)
    } else {
      handleErrors(`alertTimeError`, ``)
    }
    if (!taskUpdate.recurring?.value?.length) {
      handleErrors(`recurringError`, `Select Task Recurring`)
    } else {
      handleErrors(`recurringError`, ``)
    }
    // if (!taskUpdate.recurringStatus?.value?.length) {
    //   handleErrors(`recurringStatusError`, `Select Recurring Status`)
    // } else {
    //   handleErrors(`recurringStatusError`, ``)
    // }
    if (
      taskUpdate.recurring?.value === `CUSTOM` &&
      !taskUpdate.frequency?.value?.length
    ) {
      handleErrors(`frequencyError`, `Select Frequency`)
    } else {
      handleErrors(`frequencyError`, ``)
    }
    if (
      taskUpdate.recurring?.value === `CUSTOM` &&
      !taskUpdate.afterEvery?.value
    ) {
      handleErrors(`afterEveryError`, `Select After Every `)
    } else {
      handleErrors(`afterEveryError`, ``)
    }
    if (!taskUpdate.title?.length) {
      handleErrors(`titleError`, `Enter Title`)
    } else {
      handleErrors(`titleError`, ``)
    }

    if (!taskUpdate?.description?.length) {
      handleErrors(`descriptionError`, `Enter Description`)
    } else {
      handleErrors(`descriptionError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }
  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.assignToError.length &&
      !errors.taskTypeError.length &&
      !errors.priorityError.length &&
      !errors.dueDateError.length &&
      !errors.alertTimeError.length &&
      !errors.recurringError.length &&
      // !errors.recurringStatusError.length &&
      !errors.afterEveryError.length &&
      !errors.titleError.length &&
      !errors.descriptionError.length
    ) {
      mutate(updatePayload())
      handleErrors(`formSubmit`, false)
    }
  }, [errors])

  const handleErrors = (name, error) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: error,
    }))
  }

  const updatePayload = () => {
    let data = {}
    if (taskUpdate?.assignTo?.value !== taskData.assignTo) {
      data.assignTo = taskUpdate?.taskData?.value
    }

    if (taskUpdate?.taskType?.value !== taskData.taskType) {
      data.taskType = taskUpdate?.taskType?.value
    }
    if (taskUpdate?.priority?.value !== taskData.priority) {
      data.priority = taskUpdate?.priority?.value
    }
    if (
      moment(taskData?.dueDate).format(`YYYY-MM-DD hh:mm:ss`) !==
      moment(taskUpdate?.dueDate).format(`YYYY-MM-DD hh:mm:ss`)
    ) {
      data.dueDate = taskUpdate?.dueDate
    }

    if (taskUpdate?.alertTime?.value !== taskData.alertTime) {
      data.alertTime = taskUpdate?.alertTime?.value
    }

    if (taskUpdate?.recurring?.value !== taskData.recurring?.recurringType) {
      data.recurring = {
        ...data.recurring,
        recurringType: taskUpdate?.recurring?.value,
      }
    }

    if (taskUpdate?.afterEvery?.value !== taskData.recurring.recurringAfter) {
      data.recurring = {
        ...data.recurring,
        recurringAfter: taskUpdate?.afterEvery?.value,
      }
    }
    if (
      taskUpdate?.recurringStatus?.value !== taskData.recurring.recurringStatus
    ) {
      data.recurring = {
        ...data.recurring,
        recurringStatus: taskUpdate?.recurringStatus?.value,
      }
    }

    if (
      taskUpdate?.frequency?.value !== taskData.recurring.recurringCustomType
    ) {
      data.recurring = {
        ...data.recurring,
        recurringCustomType: taskUpdate?.frequency?.value,
      }
    }

    if (taskUpdate?.title !== taskData.title) {
      data.title = taskUpdate?.title
    }
    if (taskUpdate?.description !== taskData.description) {
      data.description = taskUpdate?.description
    }
    return data
  }

  const handleTaskChange = (value, name) => {
    if (name === `recurring`) {
      if (value.value === `CUSTOM`) setRecurringValue(true)
      else setRecurringValue(false)
    }
    if (name === `frequency`) {
      if (value.value === `DAY`) setAfterEveryTitle(`Day`)
      else if (value.value === `WEEK`) setAfterEveryTitle(`WEEK`)
      else if (value.value === `MONTH`) setAfterEveryTitle(`MONTH`)
      else if (value.value === `YEAR`) setAfterEveryTitle(`YEAR`)
    }
    setTaskUpdate((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <div className="flex flex-col w-full">
      <Spinner loading={updateLoading} />
      <div className="flex gap-6 mx-4 my-1">
        <div className="flex-1 relative">
          <InputField
            type="text"
            title="Task Title *"
            placeholder="Enter task Title"
            errorMessage={errors.titleError}
            value={taskUpdate.title}
            setValue={(e) => {
              handleTaskChange(e.target.value, `title`)
            }}
          />
        </div>
      </div>
      <div className="flex flex-col px-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Assign To *"
              placeholder="Select agent"
              value={taskUpdate?.assignTo?.label}
              disabled
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="Task Type *"
              placeholder="Select task type"
              errorMessage={errors.taskTypeError}
              value={taskUpdate.taskType}
              setValue={(value) => {
                handleTaskChange(value, `taskType`)
              }}
              isMulti={false}
              loadOptions={loadAllTaskTypes}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Priorities *"
              placeholder="Select Priorities"
              errorMessage={errors.priorityError}
              value={taskUpdate.priority}
              setValue={(value) => {
                handleTaskChange(value, `priority`)
              }}
              isMulti={false}
              loadOptions={loadAllTicketsPriorities}
              isSearchable={false}
            />
          </div>
          <div className="flex-1 relative">
            <DatePickerField
              timePicker={true}
              title="Due *"
              errorMessage={errors.dueDateError}
              value={taskUpdate.dueDate || new Date()}
              setValue={(e) => {
                const selectedDateTime = new Date(e);
                const currentDateTime = new Date();
                if (selectedDateTime < currentDateTime) {
                  return;
                }
                handleTaskChange(e, `dueDate`);
              }}
              minDate={new Date()}
              dateFormat={`Pp`}
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="Alert Time *"
              placeholder="Select alert time"
              errorMessage={errors.alertTimeError}
              value={taskUpdate.alertTime}
              setValue={(value) => {
                handleTaskChange(value, `alertTime`)
              }}
              isMulti={false}
              loadOptions={loadAlertTime}
              isSearchable={false}
            />
          </div>

          <div className="flex-1 relative">
            <ReactSelectField
              title="Custom Recurring *"
              placeholder="Select recurring"
              errorMessage={errors.recurringError}
              value={taskUpdate.recurring}
              setValue={(value) => {
                handleTaskChange(value, `recurring`)
              }}
              isMulti={false}
              loadOptions={customRecurring}
              isSearchable={false}
            />
          </div>

          {recurringValue && (
            <>
              <div className="flex-1 relative">
                <ReactSelectField
                  title="Frequency *"
                  placeholder="Select Frequency"
                  errorMessage={errors.frequencyError}
                  value={taskUpdate.frequency}
                  setValue={(value) => {
                    handleTaskChange(value, `frequency`)
                  }}
                  isMulti={false}
                  setRecurringValue={true}
                  loadOptions={loadFrequency}
                  isSearchable={false}
                />
              </div>
              <div className="flex-1 relative">
                <ReactSelectField
                  title={`After Every ${afterEveryTitle} *`}
                  placeholder="Select after Every"
                  errorMessage={errors.afterEveryError}
                  value={taskUpdate?.afterEvery}
                  setValue={(value) => {
                    handleTaskChange(value, `afterEvery`)
                  }}
                  isMulti={false}
                  setRecurringValue={true}
                  loadOptions={afterEvery}
                  isSearchable={false}
                />
              </div>
            </>
          )}
        </div>
        {/* {recurringValue && (
          <div className="grid grid-cols-4 gap-6">
            <div className="relative flex-1">
              <ReactSelectField
                title="Recurring Status"
                placeholder="Select recurring status"
                errorMessage={errors.recurringStatusError}
                value={taskUpdate.recurringStatus}
                setValue={(value) => {
                  handleTaskChange(value, `recurringStatus`)
                }}
                isMulti={false}
                loadOptions={loadRecurringStatus}
                isSearchable={false}
              />
            </div>
          </div>
        )} */}

        <div className="flex gap-6 my-1">
          <div className="flex-1 relative">
            <TextArea
              type="textarea"
              title="Description *"
              placeholder="Enter description"
              errorMessage={errors.descriptionError}
              value={taskUpdate.description}
              setValue={(e) => {
                handleTaskChange(e.target.value, `description`)
              }}
            />
          </div>
        </div>
      </div>
      <div className="pb-2 px-4 text-right">
        <StyledButton onClick={validateForm}>
          <div className="flex gap-2 text-white">
            <span>Update Task</span>
            {isLoading && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin={true}
                className="my-auto"
              />
            )}
          </div>
        </StyledButton>
      </div>
    </div>
  )
}