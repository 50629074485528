import React from 'react'

import { Notes } from 'App/Components/Common/Notes'
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min'

export const QuoteNote = () => {
  const {
    params: { quoteId },
  } = useRouteMatch()

  return <Notes resourceId={quoteId} apiType={`quote`} />
}
