import { AMS_BACKEND_API, AxiosInstance } from 'App/Config'

export const ValidateDuplicateRatingTemplateName = async (payload) => {
  return await AxiosInstance.post(
    `${AMS_BACKEND_API}/api/validate/rating-template-payloads`,
    payload,
  )
    .then((res) => {
      return res?.data
    })
    .catch((error) => {
      return error?.response?.data
    })
}
