import React, { useEffect } from 'react'
import 'react-notifications/lib/notifications.css'
import { NotificationContainer, NotificationManager } from 'react-notifications'

export const ReactNotification = ({ action, message }) => {
  useEffect(() => {
    NotificationManager[action](message)
  }, [action])

  return <NotificationContainer />
}
