import React from 'react'
import { Collapsable } from 'App/Components/Common/Collapsable'
import { CardField } from 'App/Components/Common/CardField'
import dateFormat from 'App/Services/General/dateFormat'

const FormatedData = ({ data }) => {
  return data
    ? Object.keys(data).map(
        (item) =>
          typeof data[item] === `string` && (
            <CardField
              title={item}
              value={item === `dob` ? dateFormat(data[item]) : data[item]}
            />
          ),
      )
    : null
}

export const CarrierDownloadCard = ({ carrier, index }) => {
  return (
    <Collapsable title={`${index + 1}`}>
      <CardField title="ID" value={carrier?._id} badge={true} />
      <CardField title="Updated At" value={dateFormat(carrier?.updatedAt)} />
      <CardField title="Created At" value={dateFormat(carrier?.createdAt)} />
      {/* primary insured */}
      <div className="my-2">
        <Collapsable title="Primary Insured">
          {/* <CardField
            title="Name"
            value={
              carrier.primaryInsured?.firstName +
              ` ` +
              carrier.primaryInsured?.lastName
            }
          /> */}
          <FormatedData data={carrier?.primaryInsured} />
          {/* {Object.keys(carrier.primaryInsured).map(
            (item) =>
              typeof carrier?.primaryInsured[item] === `string` && (
                <CardField title={item} value={carrier?.primaryInsured[item]} />
              ),
          )} */}
          {/* <CardField title="Mobile" value={carrier.primaryInsured?.mobile} />
          <CardField title="Phone" value={carrier.primaryInsured?.phone} />
          <CardField
            title="Cell Phone"
            value={carrier.primaryInsured?.cellPhone}
          />
          <CardField
            title="Work Phone"
            value={carrier.primaryInsured?.workPhone}
          /> */}
          <Collapsable title="Address">
            <FormatedData data={carrier?.primaryInsured?.postalAddress} />
            {/* {Object.keys(carrier.primaryInsured?.postalAddress).map(
              (item) =>
                typeof carrier.primaryInsured?.postalAddress[item] ===
                  `string` && (
                  <CardField
                    title={item}
                    value={carrier.primaryInsured?.postalAddress[item]}
                  />
                ),
            )} */}
          </Collapsable>
        </Collapsable>
      </div>

      {/* policy info */}
      <div className="my-2">
        <Collapsable title="Policy Info">
          <FormatedData data={carrier?.policyInfo} />
          {/* <CardField title="Policy No" value={carrier.policyInfo?.policyNo} />
          <CardField
            title="Payment Method"
            value={carrier.policyInfo?.paymentMethod}
          />
          <CardField
            title="Expiry Date"
            value={dateFormat(carrier.policyInfo?.expiryDate)}
          />
          <CardField
            title="Effective Date"
            value={dateFormat(carrier.policyInfo?.effectiveDate)}
          /> */}
          <div className="flex items-start justify-evenly">
            <div className="flex-1 mx-2 rounded">
              <Collapsable title="Billing">
                <FormatedData data={carrier?.policyInfo?.billing} />
                {/* <CardField
                  title="Last Bill Amount"
                  value={carrier.policyInfo?.billing.lastBilledAmount}
                />
                <CardField
                  title="Total Balance Amount"
                  value={carrier.policyInfo?.billing.totalBalanceAmount}
                />
                <CardField
                  title="Total Paid Amount"
                  value={carrier.policyInfo?.billing.totalPaidAmount}
                />
                <CardField
                  title="Written Premium"
                  value={carrier.policyInfo?.billing.writtenPremium}
                />
                <CardField
                  title="Last Bill Date"
                  value={carrier.policyInfo?.billing.lastBillDate}
                />
                <CardField
                  title="Last Current Due Date"
                  value={dateFormat(
                    carrier.policyInfo?.billing.lastCurrentDueDate,
                  )}
                /> */}
              </Collapsable>
            </div>
            <div className="flex-1 mx-2 rounded">
              <Collapsable title="Producer">
                <FormatedData data={carrier?.policyInfo?.producer} />
                {/* <CardField
                  title="Name"
                  value={carrier.policyInfo?.producer?.name}
                />
                <CardField
                  title="Number"
                  value={carrier.policyInfo?.producer?.number}
                />
                <CardField
                  title="Master Code"
                  value={carrier.policyInfo?.producer?.masterCode}
                /> */}
              </Collapsable>
            </div>
          </div>
        </Collapsable>
      </div>

      {/* cars */}
      <div className="my-2">
        <Collapsable title="Cars">
          {carrier?.cars?.map((car, index) => (
            <div key={index}>
              <Collapsable title={car?.model}>
                <FormatedData data={car} />
                {/* <CardField title="Make" value={car?.make} />
                <CardField title="Model" value={car?.model} />
                <CardField
                  title="Endorsements"
                  value={
                    car?.endorsements?.length
                      ? car?.endorsements
                      : `Not Provided`
                  }
                  multiple={car?.endorsements?.length}
                />
                <CardField title="Annual Miles" value={car.annualMiles} />
                <CardField title="Condition" value={car.condition} />
                <CardField
                  title="Garaging Location"
                  value={car.garagingLocation}
                />
                <CardField title="Garaged" value={car.isGaraged} />
                <CardField title="Symbol" value={car.symbol} />
                <CardField title="Usage" value={car.usage} />
                <CardField title="VIN" value={car.vin} />
                <CardField title="Year" value={car.year} /> */}
                {/* coverage */}
                <Collapsable title="Coverages">
                  <div className="grid grid-cols-2 gap-10 mt-2">
                    {car?.coverages?.map((coverage, ind) => (
                      <div
                        key={ind}
                        className="flex items-start justify-evenly"
                      >
                        <div className="flex-1 mx-2 rounded">
                          <Collapsable title={coverage?.type}>
                            <FormatedData data={coverage} />
                            {/* <CardField title="Limits" value={coverage.limits} />
                            <CardField title="Name" value={coverage.name} />
                            <CardField
                              title="Premium"
                              value={coverage.premium}
                            />
                            <CardField
                              title="Deductable"
                              value={coverage.deductible}
                            />
                            <CardField
                              title="Co Pays"
                              value={coverage.coPays}
                            />
                            <CardField title="Type" value={coverage.type} /> */}
                          </Collapsable>
                        </div>
                      </div>
                    ))}
                  </div>
                </Collapsable>
              </Collapsable>
            </div>
          ))}
        </Collapsable>
      </div>

      {/* drivers */}
      <div className="my-2">
        <Collapsable title="Drivers">
          <div className="grid grid-cols-2 gap-10 mt-2">
            {carrier?.drivers?.map((driver, index) => (
              <div key={index} className="flex items-start justify-evenly">
                <div className="flex-1 mx-2 rounded">
                  <Collapsable
                    title={driver?.firstName + ` ` + driver?.lastName}
                  >
                    <FormatedData data={driver} />
                    {/* <CardField title="First Name" value={driver.firstName} />
                    <CardField title="Last Name" value={driver.lastName} />
                    <CardField title="Gender" value={driver.gender} />
                    <CardField
                      title="Assigned Vehicles"
                      value={driver.assignedVehicles}
                    />
                    <CardField
                      title="Marital Status"
                      value={driver.maritalStatus}
                    />
                    <CardField title="Licensed On" value={driver.licensedOn} />
                    <CardField title="License No" value={driver.licenseNo} />
                    <CardField title="Occupation" value={driver.occupation} />
                    <CardField title="Relation" value={driver.relation} />
                    <CardField
                      title="Date Of Birth"
                      value={dateFormat(driver.dob)}
                    /> */}
                  </Collapsable>
                </div>
              </div>
            ))}
          </div>
        </Collapsable>
      </div>
    </Collapsable>
  )
}
