import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  myPreferences: ``,
  myPreferedCarriers: [],
}

const myPreferencesSlice = createSlice({
  name: `preferences`,

  initialState,
  reducers: {
    setPreferences: (state, { payload }) => {
      state.myPreferences = payload
    },

    setPreferedCarriers: (state, { payload }) => {
      state.myPreferedCarriers = payload
    },

    setUpdateCarrierPreferences: (state, { payload }) => {
      const updatedCarriersPrefs = state.myPreferedCarriers.map(
        (_eachCarrier) => {
          if (
            _eachCarrier.carrierName === payload?.row?.carrierName &&
            _eachCarrier.id === payload?.row?.id
          ) {
            _eachCarrier[payload.fieldName] = payload.fieldValue.value
          }

          return _eachCarrier
        },
      )
      state.myPreferedCarriers = updatedCarriersPrefs
    },
  },
})

export const {
  setPreferences,
  setPreferedCarriers,
  setUpdateCarrierPreferences,
} = myPreferencesSlice.actions

export const preferences = myPreferencesSlice.reducer
