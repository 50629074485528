import React, { useEffect, useState } from 'react'
import { InputField } from 'App/Components/Common/InputField'
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { DraggableMarker } from 'App/Components/Common/Leaflet/DraggableMarker'
import { AMS_BACKEND_API, AxiosInstance, LEAFLET_URL, regEx } from 'App/Config'
import { useMutation } from 'react-query'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'

import { loadZipCodes, ValidateZip } from 'App/Services'
import { MaskField } from 'App/Components/Common/MaskField'
import { useHistory, useRouteMatch } from 'react-router'
import { Container, PageHeader, SubmitButton } from 'App/Styled'
import { Checkbox } from 'App/Components/Common/Checkbox'
import { Spinner } from 'App/Components/Common/Spinner'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useDebouncedCallback } from 'use-debounce/lib'
import { loadRelationship } from 'App/Services/quoteForm'
import { NotificationManager } from 'react-notifications'

export const AddContact = () => {
  const history = useHistory()
  const {
    params: { customerId },
  } = useRouteMatch()

  const [contact, setContact] = useState({
    firstName: ``,
    lastName: ``,
    relation: ``,
    phone: ``,
    workPhone: ``,
    email: ``,
    isDriver: false,
  })

  const [address, setAddress] = useState({
    unitNo: ``,
    streetNo: ``,
    city: ``,
    zip: ``,
    state: ``,
  })

  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    firstNameError: ``,
    lastNameError: ``,
    phoneError: ``,
    faxError: ``,
    relationError: ``,
    workPhoneError: ``,
    emailError: ``,
    unitNoError: ``,
    streetNoError: ``,
    cityError: ``,
    zipError: ``,
    stateError: ``,
    coordinatesError: ``,
  })

  const handleCustomerZipChange = async (e) => {
    const data = await ValidateZip(e?.value)
    if (data) {
      const res = data.address
      setAddress((prevState) => ({
        ...prevState,
        city: res.city,
        state: res.state,
        zip: { value: res.zipCode, label: res.zipCode },
      }))
      //setting coordinates
      setCoordinates((prevState) => ({
        ...prevState,
        lat: res.lat,
        lng: res.long,
      }))
    }
  }

  const handleContactChange = useDebouncedCallback((name, value) => {
    setContact((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleAddressChange = useDebouncedCallback((name, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }, 5)

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const { mutate, isLoading, isError } = useMutation(
    async (payload) =>
      await AxiosInstance.post(`${AMS_BACKEND_API}/api/contacts`, {
        ...payload,
      }).then((res) => {
        res.data && history.push(`/customers/${customerId}`)

        NotificationManager.success(`Operation Successful!`)
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  //validate form on click
  const ValidateForm = () => {
    if (!contact.firstName.length) {
      handleErrors(`firstNameError`, `Enter First Name`)
    } else if (contact.firstName.length && contact.firstName.length < 3) {
      handleErrors(`firstNameError`, `First Name Length must be 3`)
    } else handleErrors(`firstNameError`, ``)

    if (!contact.lastName.length) {
      handleErrors(`lastNameError`, `Enter last Name`)
    } else if (contact.lastName.length && contact.lastName.length < 3) {
      handleErrors(`lastNameError`, `last Name Length must be 3`)
    } else handleErrors(`lastNameError`, ``)

    if (!contact.phone.length) {
      handleErrors(`phoneError`, `Enter Phone`)
    } else if (contact.phone.length && contact.phone.length < 12) {
      handleErrors(`phoneError`, `Enter Valid Phone`)
    } else handleErrors(`phoneError`, ``)

    if (contact.workPhone.length && contact.workPhone.length < 12) {
      handleErrors(`workPhoneError`, `Enter Valid Phone`)
    } else handleErrors(`workPhoneError`, ``)

    if (!contact.relation?.value?.length) {
      handleErrors(`relationError`, `Select Relation`)
    } else handleErrors(`relationError`, ``)

    if (contact.email.length) {
      if (!regEx.test(contact.email.toLowerCase())) {
        handleErrors(`emailError`, `Enter Valid Email`)
      } else {
        handleErrors(`emailError`, ``)
      }
    } else {
      handleErrors(`emailError`, ``)
    }

    if (!address.unitNo.length) {
      handleErrors(`unitNoError`, `Enter Unit No`, true)
    } else {
      handleErrors(`unitNoError`, ``, true)
    }

    if (!address.zip?.value?.length) {
      handleErrors(`zipError`, `Select Zip`, true)
    } else {
      handleErrors(`zipError`, ``, true)
    }

    if (!address.streetNo.length) {
      handleErrors(`streetNoError`, `Enter Street`, true)
    } else {
      handleErrors(`streetNoError`, ``, true)
    }

    if (!address.city.length) {
      handleErrors(`cityError`, `Enter City`, true)
    } else {
      handleErrors(`cityError`, ``, true)
    }

    if (!address.state.length) {
      handleErrors(`stateError`, `Enter State`, true)
    } else {
      handleErrors(`stateError`, ``, true)
    }

    if (!coordinates.lat && !coordinates.lng) {
      handleErrors(`coordinatesError`, `Enter Coordinates`, true)
    } else {
      handleErrors(`coordinatesError`, ``, true)
    }

    handleErrors(`formSubmit`, true)
  }

  function preparedPayload() {
    const result = {}
    result._module = {}
    // module
    result._module.value = customerId
    result._module.label = `customer`

    if (contact.firstName) result.firstName = contact.firstName
    if (contact.lastName) result.lastName = contact.lastName
    if (contact.phone) result.phone = contact.phone
    if (contact.workPhone) result.workPhone = contact.workPhone
    if (contact.email) result.email = contact.email
    if (contact.isDriver !== undefined) result.isDriver = contact.isDriver
    if (contact.relation && contact.relation.value)
      result.relation = contact.relation.value

    result.address = {}
    if (address.unitNo) result.address.unitNo = address.unitNo
    if (address.streetNo) result.address.street = address.streetNo
    if (address.city) result.address.city = address.city
    if (address.zip && address.zip.value)
      result.address.zip = address.zip.value.toString()
    if (address.state) result.address.state = address.state

    result.address.coordinates = {}
    if (coordinates.lat)
      result.address.coordinates.lat = coordinates.lat.toString()
    if (coordinates.lng)
      result.address.coordinates.long = coordinates.lng.toString()

    return result
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.firstNameError?.length &&
      !errors.lastNameError?.length &&
      !errors.relationError?.length &&
      !errors.phoneError?.length &&
      !errors.emailError?.length &&
      !errors.unitNoError?.length &&
      !errors.zipError?.length &&
      !errors.streetNoError?.length &&
      !errors.cityError?.length &&
      !errors.stateError?.length &&
      !errors.coordinatesError?.length
    ) {
      //call API

      mutate(preparedPayload())

      setErrors((prevState) => ({
        ...prevState,
        [`formSubmit`]: false,
      }))
    } else if (errors?.formSubmit) {
      NotificationManager.error(
        `Action Failed! Please fill out all required fields`,
      )
    }
  }, [errors])

  const ChangeView = ({ center, zoom }) => {
    useMap().setView(center, zoom)
    return null
  }

  return (
    <Container className="relative">
      <PageHeader>
        <div>Add Contact</div>
      </PageHeader>
      {isError && (
        <ReactNotification action="error" message="Something went wrong" />
      )}
      <Spinner loading={isLoading} />
      <div className={`p-3 ${isLoading && `opacity-30 pointer-events-none`} `}>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="First Name *"
              placeholder="Enter Name"
              errorMessage={errors.firstNameError}
              value={contact.firstName}
              setValue={(e) => handleContactChange(`firstName`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Last Name *"
              placeholder="Enter Name"
              errorMessage={errors.lastNameError}
              value={contact.lastName}
              setValue={(e) => handleContactChange(`lastName`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Relation *"
              placeholder="Select relation"
              errorMessage={errors.relationError}
              value={contact?.relation}
              setValue={(value) => handleContactChange(`relation`, value)}
              loadOptions={loadRelationship}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Phone *"
              placeholder="Enter Phone No"
              errorMessage={errors.phoneError}
              value={contact.phone}
              setValue={(e) => handleContactChange(`phone`, e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <MaskField
              type="text"
              title="Work Phone"
              placeholder="Enter Work Phone"
              errorMessage={errors.workPhoneError}
              value={contact.workPhone}
              setValue={(e) => handleContactChange(`workPhone`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="email"
              title="Email"
              placeholder="Enter Email"
              errorMessage={errors.emailError}
              value={contact.email}
              setValue={(e) => handleContactChange(`email`, e.target.value)}
            />
          </div>
          <div className="flex-1 mt-5 relative">
            <Checkbox
              title="Is Driver"
              name="isDriver"
              value={contact.isDriver}
              setValue={(e) =>
                setContact((prevState) => ({
                  ...prevState,
                  [e.target.name]: !contact.isDriver,
                }))
              }
            />
          </div>
          <div className="flex-1 relative"></div>
        </div>

        <div className="text-sm bg-red-500 rounded-md px-3 py-1 text-white my-4">
          Address
        </div>
        <div className="flex gap-6 mt-2 text-xs">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Unit No *"
              placeholder="Enter Unit No"
              errorMessage={errors.unitNoError}
              value={address.unitNo}
              setValue={(e) => handleAddressChange(`unitNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <ReactSelectField
              title="Zip *"
              placeholder="Select Zip"
              errorMessage={errors.zipError}
              isMulti={false}
              value={address.zip}
              setValue={(e) => handleCustomerZipChange(e)}
              loadOptions={loadZipCodes}
              isSearchable={true}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Street *"
              placeholder="Enter Street"
              errorMessage={errors.streetNoError}
              value={address.streetNo}
              setValue={(e) => handleAddressChange(`streetNo`, e.target.value)}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="City"
              placeholder="Enter City"
              errorMessage={errors.cityError}
              disabled={true}
              name="city"
              value={address.city}
            />
          </div>
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="State"
              placeholder="Enter State"
              errorMessage={errors.stateError}
              disabled={true}
              name="state"
              value={address.state}
            />
          </div>
        </div>
        <div className="rounded-md grid grid-cols-1">
          <label className="text-sm mt-2 text-gray-700 font-medium">
            Locate Yourself
            {errors.coordinatesError.length !== 0 && (
              <span className="text-red-500 required-dot ml-2">*</span>
            )}
          </label>
          <MapContainer
            center={[coordinates.lat, coordinates.lng]}
            zoom={3}
            scrollWheelZoom={true}
          >
            <ChangeView center={[coordinates.lat, coordinates.lng]} zoom={3} />
            <TileLayer url={LEAFLET_URL} />
            <DraggableMarker
              coord={coordinates}
              setCoordinates={(e) => handleContactChange(e, true)}
            />
          </MapContainer>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="text-center w-96 my-2">
          <SubmitButton onClick={ValidateForm} disabled={isLoading}>
            <div>Create Contact</div>
          </SubmitButton>
        </div>
      </div>
    </Container>
  )
}
