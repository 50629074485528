/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { PushNotification } from '../PushNotifications'
import { ChatNotification } from '../ChatNotification'
import { useSelector } from 'react-redux'
import { GeneralNotification } from '../GeneralNotifications'

export const AllNotification = () => {
  //state to maintain the active tab status of notification tabs
  const [activeNotificationTab, setActiveNotificationTab] = useState(`general`)

  const { latestLength, chatLatestLength, generalNotificationsLength } =
    useSelector(({ notification }) => notification)
  return (
    <div id="all-notifications-container" className="w-80 h-72">
      <div className="bg-red-500 rounded-t-md text-white font-medium text-xs grid grid-cols-3  text-center border-b border-gray-200">
        <p
          className={`cursor-pointer relative p-2 hover:bg-white hover:text-red-500 transition duration-300 ease-in-out rounded-tl-md ${
            activeNotificationTab === `general` ? `bg-gray-50 text-red-500` : ``
          }`}
          onClick={() => setActiveNotificationTab(`general`)}
        >
          General
          <span
            id="latestNotification"
            className={`absolute top-0.5 right-3 h-4 w-4 rounded-full text-xs ${
              activeNotificationTab === `general`
                ? `bg-red-500 text-white`
                : `text-red-500 bg-white`
            }`}
          >
            {generalNotificationsLength || 0}
          </span>
        </p>
        <p
          className={`cursor-pointer relative p-2 hover:bg-white hover:text-red-500 transition duration-300 ease-in-out ${
            activeNotificationTab === `system` ? `bg-gray-50 text-red-500` : ``
          }`}
          onClick={() => setActiveNotificationTab(`system`)}
        >
          System
          <span
            id="latestNotification"
            className={`absolute top-0.5 right-4 h-4 w-4 rounded-full text-xs ${
              activeNotificationTab === `system`
                ? `bg-red-500 text-white`
                : `text-red-500 bg-white`
            }`}
          >
            {latestLength || 0}
          </span>
        </p>
        <p
          className={`cursor-pointer relative p-2 hover:bg-white hover:text-red-500 transition duration-300 ease-in-out rounded-tr-md ${
            activeNotificationTab === `chat` ? `bg-gray-50 text-red-500` : ``
          }`}
          onClick={() => setActiveNotificationTab(`chat`)}
        >
          Chat
          <span
            id="latestNotification"
            className={`absolute top-0.5 right-5 h-4 w-4 rounded-full text-xs ${
              activeNotificationTab === `chat`
                ? `bg-red-500 text-white`
                : `text-red-500 bg-white`
            }`}
          >
            {chatLatestLength || 0}
          </span>
        </p>
      </div>
      <div className="bg-white">
        {activeNotificationTab === `general` && <GeneralNotification />}
        {activeNotificationTab === `system` && <PushNotification />}
        {activeNotificationTab === `chat` && <ChatNotification />}
      </div>
    </div>
  )
}
