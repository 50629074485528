import React, { useEffect, useState } from 'react'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { StyledButton } from 'App/Styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosInstance } from 'App/Config'
import { useMutation } from 'react-query'
import { NotificationManager } from 'react-notifications'
import { AMS_BACKEND_API } from 'App/Config'
import { InputField } from 'App/Components/Common/InputField'
import { TextArea } from 'App/Components/Common/TextArea'
export const UpdateClaimStatus = ({
  selectedClaim,
  closeModal,
  setClaimUpdated,
}) => {
  const [claim, setClaim] = useState({
    status: { value: selectedClaim?.status, label: selectedClaim?.status },
    amount: selectedClaim?.amount ?? 0,
    agentResponse: selectedClaim?.agentResponse ?? ``,
  })

  const [errors, setErrors] = useState({
    formSubmit: false,
    claimStatusError: ``,
    agentResponseError: ``,
    amountError: ``,
  })

  const loadStatusOptions = () => {
    const options = {
      options: [
        {
          value: `IN-PROGRESS`,
          label: `IN PROGRESS`,
        },
        {
          value: `REJECTED`,
          label: `REJECTED`,
        },
        {
          value: `SETTLEMENT`,
          label: `SETTLEMENT`,
        },
      ],
      hasMore: false,
    }
    return options
  }

  const { mutate, isLoading } = useMutation(
    async (payload) =>
      await AxiosInstance.patch(
        `${AMS_BACKEND_API}/api/claim/${selectedClaim._id}`,
        {
          ...payload,
        },
      ).then((res) => {
        if (res.data) {
          closeModal()
          setClaimUpdated()
        }
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onError: () => {
        NotificationManager.error(`Something went wrong`)
      },
    },
  )
  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const ValidateForm = () => {
    if (claim?.status?.value?.length) {
      handleErrors(`claimStatusError`, ``)
    } else {
      handleErrors(`claimStatusError`, `Select Status`)
    }

    if (claim?.status?.value?.includes(`SETTLEMENT`)) {
      if (claim?.amount?.length) {
        handleErrors(`amountError`, ``)
      } else {
        handleErrors(`amountError`, `Enter Amount`)
      }

      if (claim?.agentResponse?.length) {
        handleErrors(`agentResponseError`, ``)
      } else {
        handleErrors(`agentResponseError`, `Add Remarks`)
      }
    } else {
      handleErrors(`agentResponseError`, ``)
      handleErrors(`amountError`, ``)
    }

    if (claim?.status?.value?.includes(`REJECTED`)) {
      if (claim?.agentResponse?.length) {
        handleErrors(`agentResponseError`, ``)
      } else {
        handleErrors(`agentResponseError`, `Add Remarks`)
      }
    } else {
      handleErrors(`agentResponseError`, ``)
    }

    handleErrors(`formSubmit`, true)
  }

  useEffect(() => {
    if (
      errors.formSubmit &&
      !errors.claimStatusError.length &&
      !errors.agentResponseError.length &&
      !errors.amountError.length
    ) {
      //call API
      mutate(updatePayload())
      setErrors((prevState) => ({
        ...prevState,
        formSubmit: false,
      }))
    }
  }, [errors])

  const updatePayload = () => {
    let data = {}
    if (claim?.status?.value?.length) {
      data.status = claim?.status?.value
    }

    if (claim?.status?.value?.includes(`IN-PROGRESS`)) return data
    if (claim?.agentResponse) {
      data.agentResponse = claim?.agentResponse
    }
    if (claim?.status?.value?.includes(`REJECTED`)) return data
    if (claim?.amount) {
      data.amount = parseInt(claim?.amount)
    }

    return data
  }

  const handleChange = (e, name) => {
    if (name) {
      setClaim((prev) => ({
        ...prev,
        [name]: e,
      }))
    } else {
      const { name, value } = e.target
      setClaim((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  return (
    <>
      {/* Franchise basic forms */}
      <div className="w-96 px-4 py-1">
        <div className="grid grid-cols-2 gap-6 pt-2">
          <div className={`relative`}>
            <ReactSelectField
              title="Status"
              placeholder="Select Status"
              errorMessage={errors.claimStatusError}
              value={claim?.status}
              setValue={(e) => handleChange(e, `status`)}
              isMulti={false}
              loadOptions={loadStatusOptions}
              isSearchable={false}
            />
          </div>

          <>
            <div className="relative">
              <InputField
                type="number"
                title="Settled Amount"
                name="amount"
                placeholder="Enter Amount"
                errorMessage={errors.amountError}
                value={claim.monthlyBudget}
                setValue={handleChange}
                disabled={!claim?.status?.value?.includes(`SETTLEMENT`)}
              />
            </div>
            <div className="col-span-2 relative">
              <TextArea
                value={claim?.agentResponse}
                title="Remarks"
                name="agentResponse"
                placeholder="Enter Remarks"
                errorMessage={errors?.agentResponseError}
                setValue={handleChange}
                disabled={
                  !claim?.status?.value?.includes(`SETTLEMENT`) &&
                  !claim?.status?.value?.includes(`REJECTED`)
                }
              />
            </div>
          </>
        </div>

        <div className="pb-0.5 text-right">
          <StyledButton onClick={ValidateForm} disabled={isLoading}>
            <div className="flex gap-2 text-white">
              <span>Update Claim</span>
              {isLoading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin={true}
                  className="my-auto"
                />
              )}
            </div>
          </StyledButton>
        </div>
      </div>
    </>
  )
}
