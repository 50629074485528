import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentTask: ``,
  activeActivityTab: 1,
  activeTaskMediaTab:1,
}

const TaskSlice = createSlice({
  name: `tasks`,

  initialState,
  reducers: {
    setCurrentTask: (state, { payload }) => {
      state.currentTask = payload
    },
    setActiveTaskMediaTab: (state, { payload }) => {
      state.activeTaskMediaTab = payload
    },
    setTaskActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
  },
})

export const { setCurrentTask, setTaskActivityActiveTab,setActiveTaskMediaTab } = TaskSlice.actions

export const tasks = TaskSlice.reducer
