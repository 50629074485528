import React, { useEffect, useState, Fragment } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'App/Components/Common/Spinner'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchInputField } from 'App/Components/Common/SearchInputField'
import { ReactSelectField } from 'App/Components/Common/ReactSelect'
import { Container, StyledButton, PageHeader } from 'App/Styled'
import {
  TableHeader,
  TableRow,
  TableCell,
  Badge,
  TableBody,
} from '@windmill/react-ui'
import {
  faPlusCircle,
  faSortDown,
  faSortUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { Reload } from 'App/Components/Common/Reload'
import { useDebouncedCallback } from 'use-debounce/lib'
import { AMS_BACKEND_API, AxiosInstance, offset } from 'App/Config'
import { permissionGranted } from 'App/Services'
import { ReactPagination } from 'App/Components/Common/Pagination'
import { loadOffset } from 'App/Services/General/loadOffset'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { ReactNotification } from 'App/Components/Common/ReactNotification'
import { useSelector } from 'react-redux'
import dateFormat from 'App/Services/General/dateFormat'
import searchQueryParser from 'App/Services/General/searchQueryParser'

const tableColumns = [
  { label: `Title`, value: `templateName` },
  { label: `Created At`, value: `createdAt` },
  { label: `Duration`, value: `createdAfter` },
  { label: `Due Date`, value: `assessmentDueDate` },
]

export const AllTemplates = () => {
  const history = useHistory()
  const { sub: currentUser } = useSelector(({ user }) => user?.user?.profile)
  const [currentPage, setCurrentPage] = useState(1)
  const [customSearching, setCustomSearching] = useState(false)

  const [sort, setSort] = useState(``)
  const [searchQuery, setSearchQuery] = useState(``)
  const [isOffset, setIsOffset] = useState(``)

  const {
    isLoading,
    data: templateData,
    refetch: getAllTemplate,
    isFetching,
    error,
  } = useQuery(
    `TemplateApi`,
    async () =>
      await AxiosInstance.get(
        `${AMS_BACKEND_API}/api/assessment/template/all?${
          searchQuery.length ? `search=${searchQueryParser(searchQuery)}&` : ``
        }sort="${sort.length > 0 ? sort : ``}"&page=${currentPage}&offset=${
          isOffset?.value ? isOffset?.value : offset
        }`,
      ).then((res) => {
        return res.data.data
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: () => {
        setCustomSearching(false)
      },
    },
  )

  const {
    isLoading: deletingAssessment,
    isError: deletionError,
    mutate: deleteTemplate,
  } = useMutation(
    async (id) =>
      await AxiosInstance.delete(
        `${AMS_BACKEND_API}/api/assessment/template/${id}`,
      )
        .then((res) => {
          NotificationManager.success(`Deleted Successfully`)
          res?.data && getAllTemplate()
        })
        .catch((error) => {
          NotificationManager.error(error?.response?.data?.error)
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  )

  const handleSorting = (active) => {
    if (active === sort) {
      setSort(``)
    } else {
      setSort(active)
    }
  }

  useEffect(() => {
    if (!customSearching) {
      getAllTemplate()
    }
  }, [sort, currentPage, isOffset])

  const callTemplateQuery = useDebouncedCallback(() => {
    getAllTemplate()
  }, 700)

  const handleDelete = (id, e) => {
    if (e && e.stopPropagation) e.stopPropagation()
    deleteTemplate(id)
  }

  return (
    <Container>
      <Spinner loading={deletingAssessment || isLoading || isFetching} />
      <PageHeader>
        <div>All Templates</div>
        <div className="flex items-center">
          <div className="py-1 px-2">
            <ReactSelectField
              placeholder="10"
              value={isOffset}
              setValue={(e) => {
                setCurrentPage(1)
                setIsOffset(e)
              }}
              isMulti={false}
              loadOptions={loadOffset}
            />
          </div>
          <div className="text-end  mt-1">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              onEnter={(e) => {
                if (e.key === `Enter`) {
                  setCurrentPage(1)
                  setCustomSearching(true)
                  callTemplateQuery()
                }
              }}
              setValue={(e) => {
                setSearchQuery(e.target.value)
              }}
              disabled={isFetching}
            />
          </div>
        </div>
      </PageHeader>

      <div className="flex justify-end items-center p-2">
        {permissionGranted([
          `assessment-template:create`,
          `corporate-manager:*`,
          `manager:*`,
          `admin:*`,
        ]) && (
          <StyledButton
            onClick={() => history.push(`/assessment/template/add`)}
          >
            <div className="flex gap-2 text-white">
              <span>Add </span>
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </div>
          </StyledButton>
        )}
      </div>

      {deletionError && (
        <ReactNotification action={error} message="Something went wrong" />
      )}

      <div
        className={`bg-white sm:mb-0 justify-between w-full px-2 ${
          (isLoading || isFetching || deletingAssessment) &&
          ` opacity-30 pointer-events-none`
        }  `}
      >
        {error ? (
          <Reload refetch={() => getAllTemplate()} />
        ) : (
          <>
            <div className=" rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div className=" cursor-pointer select-none text-sm flex truncate">
                            <div
                              onClick={() => {
                                handleSorting(item.value)
                              }}
                              className={`flex ${
                                item.value === sort ? `text-red-500` : ``
                              }`}
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-2 px-1 rounded-sm cursor-pointer flex"
                              onClick={() => handleSorting(`-` + item?.value)}
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${
                                  sort.includes(`-`) &&
                                  sort == `-` + item?.value
                                    ? `text-red-500`
                                    : `-mt-1.5`
                                }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      )
                    })}
                    {(permissionGranted([
                      `assessment-template:read`,
                      `corporate-manager:*`,
                      `manager:*`,
                      `admin:*`,
                    ]) ||
                      permissionGranted([
                        `assessment-template:delete`,
                        `corporate-manager:*`,
                        `manager:*`,
                        `admin:*`,
                      ])) && (
                      <TableCell className="text-sm float-right">
                        Action
                      </TableCell>
                    )}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!isLoading && !templateData?.templates?.length ? (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Template Found</div>
                      </td>
                    </tr>
                  ) : (
                    templateData?.templates
                      ?.filter((template) => template.isDeleted === false)
                      ?.map((template, index) => (
                        <Fragment key={index}>
                          <TableRow
                            key={index}
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() =>
                              history.push(
                                `/assessment/template/${template?._id}`,
                              )
                            }
                          >
                            <td className="px-4 py-0.5 w-auto text-sm truncate max-w-xs">
                              {template?.templateName ? (
                                template?.templateName
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>

                            <td className="px-4 py-0.5 w-auto text-sm font-bold truncate">
                              {template.createdAt ? (
                                <Badge type="danger">
                                  {moment(template.createdAt).format(
                                    `MM-DD-YYYY hh:mmA`,
                                  )}
                                </Badge>
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>

                            <td className="px-4 py-0.5 w-auto text-sm truncate">
                              {template?.createdAfter ? (
                                template?.createdAfter
                              ) : (
                                <Badge>N/A </Badge>
                              )}
                            </td>
                            <td className="px-4 py-0.5 w-auto text-sm truncate font-bold text-purple-700">
                              {template?.assessmentDueDate ? (
                                <Badge>
                                  {dateFormat(template.assessmentDueDate)}
                                </Badge>
                              ) : (
                                <Badge>N/A</Badge>
                              )}
                            </td>

                            {/* Actions TD */}

                            <td className="px-8 py-0.5 z-0 text-sm flex float-right text-gray-400">
                              {permissionGranted([
                                `assessment-template:delete`,
                                `corporate-manager:*`,
                                `manager:*`,
                                `admin:*`,
                              ]) && (
                                <span
                                  data-tip={
                                    currentUser === template?._creator
                                      ? `Delete`
                                      : `Not Allowed`
                                  }
                                  className="tooltip tooltip-left"
                                >
                                  <FontAwesomeIcon
                                    className={`mx-1 transform transition  hover:text-red-500  hover:-translate-y-0 hover:scale-105  ${
                                      currentUser === template?._creator
                                        ? `cursor-pointer`
                                        : `cursor-not-allowed`
                                    }`}
                                    icon={faTrash}
                                    size="1x"
                                    onClick={(e) => {
                                      currentUser === template?._creator
                                        ? handleDelete(template?._id, e)
                                        : e &&
                                          e.stopPropagation &&
                                          e.stopPropagation() &&
                                          history.push(`${url}`)
                                    }}
                                  />
                                </span>
                              )}
                            </td>
                          </TableRow>
                        </Fragment>
                      ))
                  )}
                </TableBody>
              </table>
            </div>
            <div className="px-4 py-2">
              <ReactPagination
                offset={isOffset?.value ? isOffset?.value : offset}
                currentPage={currentPage}
                totalPages={templateData?.totalPages}
                totalRecords={templateData?.totalRecords}
                curerntPageRecords={templateData?.currentPageRecords}
                onChange={(page) => setCurrentPage(page)}
              />
            </div>
          </>
        )}
      </div>
    </Container>
  )
}
