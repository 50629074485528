import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentDealer: ``,
  activeActivityTab: 1,
}

const carDealerSlice = createSlice({
  name: `carDealer`,

  initialState,
  reducers: {
    setCurrentCarDealer: (state, { payload }) => {
      state.currentDealer = payload
    },
    setCarDealerActivityActiveTab: (state, { payload }) => {
      state.activeActivityTab = payload
    },
  },
})

export const { setCurrentCarDealer, setCarDealerActivityActiveTab } =
  carDealerSlice.actions

export const carDealer = carDealerSlice.reducer
