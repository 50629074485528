import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  communicationCredentials: ``,
  currentIncomingCall: ``,
  currentActiveCall: {
    callerId: ``,
    activeCall: ``,
    status: `Calling...`,
  },
}

const CurrentCommunicationSlice = createSlice({
  name: `currentCommunication`,

  initialState,
  reducers: {
    setCommunicationCredentials: (state, { payload }) => {
      state.communicationCredentials = payload
    },
    setCurrentCall: (state, { payload }) => {
      state.currentActiveCall = payload
    },
    setCurrentCallStatus: (state, { payload }) => {
      state.currentActiveCall.status = payload
    },
    setCurrentIncomingCallStatus: (state, { payload }) => {
      state.currentIncomingCall = payload
    },
  },
})

export const {
  setCommunicationCredentials,
  setCurrentCall,
  setCurrentCallStatus,
  setCurrentIncomingCallStatus,
} = CurrentCommunicationSlice.actions

export const currentCommunication = CurrentCommunicationSlice.reducer
