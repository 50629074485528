import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FranchiseNote } from 'App/Components/Franchise/FranchiseNote'
import { TOne, TTwo } from 'App/Styled'
import { setFranchiseActivityActiveTab } from 'App/Redux/actions'
import { UpdateLogs } from 'App/Components/Common/UpdateLogs'
import { Tabs2 } from 'App/Components/Common/Tabs2'
import { permissionGranted } from 'App/Services'

const activityTabs = [
  {
    name: `Notes`,
    number: 1,
  },
  {
    name: `Logs`,
    number: 2,
  },
]
const activityTabs2 = [
  {
    name: `Logs`,
    number: 1,
  },
]

export const FranchiseActivity = () => {
  const { currentFranchise, activeActivityTab } = useSelector(
    ({ franchise }) => franchise,
  )
  const dispatch = useDispatch()

  return (
    <div className="px-2">
      <Tabs2
        openTab={activeActivityTab}
        tabs={
          permissionGranted([`corporate-manager:*`])
            ? activityTabs
            : activityTabs2
        }
        getActiveTab={(val) => dispatch(setFranchiseActivityActiveTab(val))}
      />
      {permissionGranted([`corporate-manager:*`]) ? (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <FranchiseNote />
          </TOne>

          <TTwo opentab={activeActivityTab}>
            <UpdateLogs
              logs={
                currentFranchise?.updateLogs?.length
                  ? currentFranchise?.updateLogs
                  : []
              }
            />
          </TTwo>
        </div>
      ) : (
        <div className="tab-content tab-space">
          <TOne opentab={activeActivityTab}>
            <UpdateLogs
              logs={
                currentFranchise?.updateLogs?.length
                  ? currentFranchise?.updateLogs
                  : []
              }
            />
          </TOne>
        </div>
      )}
    </div>
  )
}
